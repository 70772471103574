<template>
  <div class="conversion">
    <div class="title">
      <div class="txt_box">
        <div class="text">{{ title }}</div>
        <img src="@/assets/image/top.png" alt="" class="icon">
        <div class="tip">
          <img src="../../assets/image/wenhao.png" alt="">
          <div ref="poup" class="poup">
            <div class="txt">
              分析所选时间段内的每日转化率数据<br>
              *转换率=每日下单用户总数/每日访问总人数
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="conversion" />
  </div>
</template>
<script>
// 引入echarts
import * as echarts from 'echarts'
export default {
   data() {
      return {
        title: '转化率趋势分析'
      }
   },
   computed: {
   },
   created() {
   },
   methods: {
// 获取折线图数据图表
  getDataChart(time, average, conversion) {
    var chartDom = document.getElementById('conversion')
    var myChart = echarts.init(chartDom)
    window.addEventListener('resize', function() {
          myChart.resize()
      })
    var option
    const colors = ['rgba(37, 189, 151, 0.6)', 'rgba(43, 204, 255, 0.4)']
    var formatter = '{b0}'// 展示百分比
    var j = 0
    for (var k = 0; k < 2; k++) { // 折线数
        formatter += '<br/>{a' + j + '}: {c' + j + '}%'
        j++
    }
option = {
  color: colors,
  // title: {
  //   text: '转化率趋势分析',
  //   top: '5%',
  //   left:'4%',
  //   textStyle:{
  //     fontSize:16,//字体大小
  //     fontWeight: '600',
  //     color:'#fff',
  //   },
  // },
  grid: {
    right: '3%',
    top: '10%',
    bottom: '8%'
  },
  tooltip: {
    trigger: 'axis',
    formatter: formatter // 展示百分比
    // axisPointer: {
    //   type: 'cross'
    // }
  },
  legend: {
    show: false,
    top: '5%',
    right: '3%',
    itemWidth: 10, // 图例标记的图形宽度
    itemHeight: 10, // 图例标记的图形高度 在修改图例标记图形的时候可以用到。比如矩形变成直线
    itemGap: 13, // 间距
    data: [
      {
        name: '转化率',
        icon: 'circle'
      },
      {
        name: '转化率平均值',
        icon: 'rect'
      }
    ],
    textStyle: {
      fontSize: 12, // 文字大小
      padding: [0, 5, -2, 0], // 文字与图形之间的左右间距
      color: '#fff'
    }
  },
  xAxis: {
    type: 'category',
    data: time,
    axisLine: {
      show: false
      // lineStyle: {
          // type: 'solid',
          // color: '#2a89bc', //左边线的颜色
          // width: '3' //坐标线的宽度
      // }
    },
    axisLabel: {
        // x轴文字的配置
        show: true,
        interval: 0, // 使x轴文字显示全
        // color: '#fff',  //更改坐标轴文字颜色
     	  interval: time.length > 15 ? 1 : 0, // 间隔的个数, 超过15个才间隔1
        textStyle: {
            color: '#B0F9FD', // 更改坐标轴文字颜色
            fontSize: 14// 文字大小
        }
    },
    axisTick: {
        show: false
    }
  },
  yAxis: {
    type: 'value',
    splitLine: {
        show: true,
        lineStyle: {
          color: ['rgba(101, 165, 221, 0.08)'],
          width: 1,
          type: 'solid'
        }
    },
    axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value} %', // 纵坐标百分比
            textStyle: {
              color: '#65A5DD',
              fontSize: 12// 文字大小
            }
    },
    // 设置坐标轴字体颜色和宽度
    axisLine: {
      lineStyle: {
          color: '#fff'
      }
    }
  },
  series: [
    {
      name: '转化率',
      data: conversion,
      type: 'line',
      smooth: true,
      showSymbol: false
    },
    {
      name: '转化率平均值',
      data: average,
      type: 'line',
      smooth: true,
      showSymbol: false,
      markLine: {
            data: [
              [
                { name: '标线1起点', xAxis: 0, yAxis: average[0], symbol: 'none' },
                { name: '标线1终点', xAxis: time[time.length - 1], yAxis: average[0], symbol: 'none' }
              ]
            ],
            label: {
              normal: {
                show: true,
                position: 'insideEndBottom',
                formatter: '转化率平均值',
                textStyle: {
                    color: 'rgba(43, 204, 255, 0.4)', // 标注线终点文字颜色
                    fontSize: 12,
                    fontWeight: 400,
                    padding: [0, 0, 10, 0]// 文字间距
               }
              }
            },
            lineStyle: {// 标注线样式
              normal: {
                type: 'solid',
                color: 'transparent'// 标注线颜色
              }
            }
          }

    }
  ]
}
option && myChart.setOption(option)
  }
   }
}
</script>
<style lang="scss" scoped>
.conversion{
    width: 665px;
    height: 666px;
    // margin-top: 12px;
    background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/10/4cbdbc82-a802-4177-971c-8ce32f5e11fd.png") no-repeat center 0px;
    background-size: cover;
    .title {
      display: flex;
      justify-content: flex-end;
      padding: 27px 29px 0 0;
      .text {
        background-image: linear-gradient(0deg, #56FEFF, #E2F3FB);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        font-size: 16px;
      }
      .icon {
        width: 18px;
        height: 18px;
        margin-left: 10px;
      }
      .txt_box{
        display: flex;
        align-self: center;
        align-items: center;
        align-content: center;
        &:hover{
          .tip{
            .poup {
              display: flex;
            }
          }
        }
      }
    }
    #conversion{
        width: 100%;
        // height: 100%;
        // width: 620px;
        height: 600px;

    }
}

.tip{
  width: 28px;
  height: 28px;
  position: relative;
  margin-left: 10px;
  img{
    width: 100%;
    height: 100%;
  }
}
.poup{
  background: url('../../assets/image/tip.png') no-repeat center center;
  padding: 20px;
  text-align: left;
  right: 10px;
  z-index: 100;
  position: absolute;
  width: 302px;
  // height: 71px;
  color: #c0e2ff;
  font-weight: 400;
  font-size: 12px;
  font-face: Microsoft YaHei;
  line-height: 20px;
  display: none;
}
</style>
