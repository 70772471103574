<template>
  <div class="main-box">
    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="100">
              <template v-slot="scope">{{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="enterpriseName" label="企业名称" min-width="200" />
            <el-table-column prop="mchName" label="商户" min-width="120" />
            <el-table-column prop="phone" label="联系方式" min-width="120" />
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="edit(scope.row)">编辑</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
<!--        分页-->
<!--        <div class="pagination">-->
<!--          <el-pagination-->
<!--            background-->
<!--            :current-page="pageParam.pageNum"-->
<!--            :page-sizes="$api.pageSizes"-->
<!--            :page-size="pageParam.pageSize"-->
<!--            layout="total, sizes, prev, pager, next, jumper"-->
<!--            :total="pageParam.total"-->
<!--            @size-change="handleSizeChange"-->
<!--            @current-change="handleCurrentChange"-->
<!--          />-->
<!--        </div>-->
      </el-main>
    </div>
    <el-dialog
      title="企业信息"
      :visible.sync="dialogVisible_1"
      :width="'45%'"
      :modal="true"
      :close-on-click-modal="false"
    >
      <div class="Popout_content">
        <el-form
          ref="mchForm"
          :model="dialogData"
          :rules="formRule"
          size="medium"
          label-width="110px"
          label-position="left"
        >
          <el-form-item :required="true" label="企业名称：" prop="enterpriseName">
            <div>{{ dialogData.enterpriseName }}</div>
          </el-form-item>
          <el-form-item :required="true" label="商户名称：" prop="mchName">
            <div>{{ dialogData.mchName }}</div>
          </el-form-item>
          <el-form-item :required="true" label="微信商户号：" prop="mchId">
            <div>{{ dialogData.mchId }}</div>
          </el-form-item>
          <el-form-item prop="phone" :required="true" label="联系方式：">
            <div>
              <el-input v-model="dialogData.phone" placeholder="请输入联系方式" />
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer flex flex_row_center">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="dialogVisible_1 = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    const phoneRule = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('联系方式不能为空'))
      }
      const rep = /((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)/g
      if (!rep.test(value)) {
        callback(new Error('请输入正确联系方式'))
      } else {
        callback()
      }
    }
    return {
      pageParam: { // 分页参数
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false, // 表格加载
      tableData: [], // 表格数据
      dialogData: { // 弹窗数据
        mchName: '',
        enterpriseName: '',
        id: '',
        phone: '',
        mchId: ''
      },
      dialogVisible_1: false, // 弹窗显示
      formRule: { // 表单验证
        phone: [
          { validator: phoneRule, trigger: 'blur' }
        ],
        enterpriseName: [
          { required: false, trigger: 'blur' }
        ],
        mchName: [
          { required: false, trigger: 'blur' }
        ],
        mchId: [
          { required: false, trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      const param = this.pageParam
      this.loading = true
      this.$http.requestPost({
        url: '/weChatMerchant/getList',
        param: param
      }).then(res => {
        if (res.code === 200) {
          this.tableData = res.data
        }
        console.log(res, 'res')
        this.loading = false
      }).catch(err => {
        console.log('【获取列表错误】', err)
      })
    },

    // 编辑
    edit(row) {
      const data = JSON.parse(JSON.stringify(row))
      this.dialogData = {
        mchName: data.mchName,
        enterpriseName: data.enterpriseName,
        id: data.id,
        mchId: data.mchId,
        phone: data.phone
      }
      this.dialogVisible_1 = true
    },

    // 添加，修改提交
    submit() {
      this.$refs['mchForm'].validate((valid) => {
        if (valid) {
          this.$http.requestGet({
            url: `/weChatMerchant/updatePhone/${this.dialogData.id}/${this.dialogData.phone}`
          }).then(res => {
            this.dialogVisible_1 = false
            this.getTableList()
            this.$message({
              message: '操作成功',
              type: 'success'
            })
          }).catch(err => {
            console.log('【编辑失败】', err)
          })
        } else {

        }
      })
    }

  }
}
</script>

<style lang="scss" scoped>
.li-label {
  min-width: 114px;
  text-align: right;
}
</style>
