var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box", attrs: { id: "main-scroll" } },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav-bar bg_white" },
        _vm._l(_vm.navList, function (item, index) {
          return _c(
            "a",
            {
              key: item.id,
              class: { active: item.active },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.skip(index)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(item.name) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_1" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "common",
                attrs: {
                  size: "medium",
                  "label-width": "120px",
                  model: _vm.common,
                  rules: _vm.commonRules,
                },
              },
              [
                !_vm.isAdd
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "产品编号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "产品编号", readonly: "" },
                          model: {
                            value: _vm.common.productCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.common, "productCode", $$v)
                            },
                            expression: "common.productCode",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品名称", prop: "productName" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "30", placeholder: "产品名称" },
                      model: {
                        value: _vm.common.productName,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productName", $$v)
                        },
                        expression: "common.productName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "通用名称", prop: "productCommonName" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "30", placeholder: "通用名称" },
                      model: {
                        value: _vm.common.productCommonName,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productCommonName", $$v)
                        },
                        expression: "common.productCommonName",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "所有类别", prop: "productCategoryId" } },
                  [
                    _c("el-cascader", {
                      staticClass: "width-inherit",
                      attrs: { options: _vm.productTypeList, props: _vm.props },
                      model: {
                        value: _vm.common.productCategoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productCategoryId", $$v)
                        },
                        expression: "common.productCategoryId",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品亮点", prop: "productHighlight" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "30", placeholder: "产品亮点" },
                      model: {
                        value: _vm.common.productHighlight,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productHighlight", $$v)
                        },
                        expression: "common.productHighlight",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.isAdd
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "总库存" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "总库存", disabled: "" },
                          model: {
                            value: _vm.common.productInventoryTotal,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.common,
                                "productInventoryTotal",
                                _vm._n($$v)
                              )
                            },
                            expression: "common.productInventoryTotal",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    staticClass: "form-item",
                    attrs: { label: "单位", prop: "productUnitsId" },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "form-select",
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.common.productUnitsId,
                          callback: function ($$v) {
                            _vm.$set(_vm.common, "productUnitsId", $$v)
                          },
                          expression: "common.productUnitsId",
                        },
                      },
                      _vm._l(_vm.productUnitList, function (item) {
                        return _c("el-option", {
                          key: item.unitsId,
                          attrs: { label: item.unitsName, value: item.unitsId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "生产厂商", prop: "productManufacturer" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "50", placeholder: "生产厂商" },
                      model: {
                        value: _vm.common.productManufacturer,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productManufacturer", $$v)
                        },
                        expression: "common.productManufacturer",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "排序", prop: "productOrder" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "5", placeholder: "排序" },
                      model: {
                        value: _vm.common.productOrder,
                        callback: function ($$v) {
                          _vm.$set(_vm.common, "productOrder", _vm._n($$v))
                        },
                        expression: "common.productOrder",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_2" } }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "lease",
                attrs: {
                  size: "medium",
                  "label-width": "120px",
                  model: _vm.lease,
                  rules: _vm.leaseRules,
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "允许销售方式",
                      prop: _vm.whetherSalesManner,
                    },
                  },
                  [
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.salesEdit },
                        model: {
                          value: _vm.lease.whetherLease,
                          callback: function ($$v) {
                            _vm.$set(_vm.lease, "whetherLease", $$v)
                          },
                          expression: "lease.whetherLease",
                        },
                      },
                      [_vm._v("租赁")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.salesEdit },
                        model: {
                          value: _vm.lease.whetherBuyout,
                          callback: function ($$v) {
                            _vm.$set(_vm.lease, "whetherBuyout", $$v)
                          },
                          expression: "lease.whetherBuyout",
                        },
                      },
                      [_vm._v("买断")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-checkbox",
                      {
                        on: { change: _vm.salesEdit },
                        model: {
                          value: _vm.lease.whetherSales,
                          callback: function ($$v) {
                            _vm.$set(_vm.lease, "whetherSales", $$v)
                          },
                          expression: "lease.whetherSales",
                        },
                      },
                      [_vm._v("销售")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.lease.whetherLease
                  ? _c(
                      "el-form-item",
                      {
                        attrs: { label: "最低租赁时长", prop: "leaseTimeMin" },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-box" },
                          [
                            _c("el-input", {
                              staticClass: "width-140",
                              attrs: {
                                placeholder: "最低租赁时长",
                                maxlength: "10",
                                disabled:
                                  _vm.lease.prices[0].leaseTimeEnd !== "",
                              },
                              on: { change: _vm.leaseTimeChange },
                              model: {
                                value: _vm.lease.leaseTimeMin,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.lease,
                                    "leaseTimeMin",
                                    _vm._n($$v)
                                  )
                                },
                                expression: "lease.leaseTimeMin",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-size-12 box-m-l color_light-black",
                              },
                              [_vm._v("天")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.lease.whetherLease
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "租赁价", prop: "prices" } },
                      [
                        _c(
                          "div",
                          { staticClass: "size_box box-m-b" },
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "664px" },
                                attrs: { data: _vm.lease.prices, border: "" },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "date",
                                    label: "序号",
                                    width: "60",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(scope.$index + 1) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4080292729
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "",
                                    label: "租赁时长范围",
                                    "min-width": "300",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "flex-box" },
                                              [
                                                _c("el-input", {
                                                  staticClass: "width-110",
                                                  attrs: {
                                                    maxlength: "10",
                                                    placeholder: "开始",
                                                    disabled: "",
                                                  },
                                                  on: { change: _vm.salesEdit },
                                                  model: {
                                                    value:
                                                      scope.row.leaseTimeBegin,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "leaseTimeBegin",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.leaseTimeBegin",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "span-link" },
                                                  [_vm._v("-")]
                                                ),
                                                _vm._v(" "),
                                                _c("el-input", {
                                                  staticClass: "width-110",
                                                  attrs: {
                                                    maxlength: "10",
                                                    placeholder: "结束",
                                                    disabled:
                                                      !_vm.lease.leaseTimeMin ||
                                                      (_vm.lease.prices[
                                                        scope.$index + 1
                                                      ] &&
                                                        _vm.lease.prices[
                                                          scope.$index + 1
                                                        ].leaseTimeEnd !==
                                                          null),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.maxDayChange(
                                                        scope.row,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.leaseTimeEnd,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "leaseTimeEnd",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.leaseTimeEnd",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "span-link" },
                                                  [_vm._v("天")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1387054434
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "",
                                    label: "租赁价格",
                                    "min-width": "200",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "flex-box" },
                                              [
                                                _c("el-input", {
                                                  staticClass: "width-110",
                                                  attrs: {
                                                    maxlength: "10",
                                                    placeholder: "租赁价格",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      scope.row.leasePrice =
                                                        scope.row.leasePrice.replace(
                                                          /[^0-9.]/g,
                                                          ""
                                                        )
                                                    },
                                                    change: _vm.salesEdit,
                                                  },
                                                  model: {
                                                    value: scope.row.leasePrice,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "leasePrice",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.leasePrice",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  { staticClass: "span-link" },
                                                  [_vm._v("元 / 天")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    737354351
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "",
                                    label: "操作",
                                    "min-width": "100",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value:
                                                      scope.$index !== 0 &&
                                                      scope.$index ===
                                                        _vm.lease.prices
                                                          .length -
                                                          1,
                                                    expression:
                                                      "scope.$index !== 0 && scope.$index === lease.prices.length - 1",
                                                  },
                                                ],
                                                staticClass: "operation-btn",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteRow(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    删除\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    365221854
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: { click: _vm.addLeasePrices },
                                  },
                                  [_vm._v("增加售价范围")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.lease.whetherLease
                  ? _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "是否需要押金",
                          prop: "whetherDepositRequire",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.lease.whetherDepositRequire,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.lease,
                                  "whetherDepositRequire",
                                  $$v
                                )
                              },
                              expression: "lease.whetherDepositRequire",
                            },
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { label: true },
                                on: { change: _vm.salesEdit },
                              },
                              [_vm._v("是")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: false },
                                on: { change: _vm.salesEdit },
                              },
                              [_vm._v("否")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.lease.whetherLease && _vm.lease.whetherDepositRequire
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "租赁押金", prop: "depositPrice" } },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-box" },
                          [
                            _c("el-input", {
                              staticClass: "width-110",
                              attrs: {
                                placeholder: "租赁押金",
                                maxlength: "10",
                              },
                              on: { change: _vm.salesEdit },
                              model: {
                                value: _vm.lease.depositPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.lease, "depositPrice", $$v)
                                },
                                expression: "lease.depositPrice",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-size-12 box-m-l color_light-black",
                              },
                              [_vm._v("元")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.lease.whetherBuyout
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "买断价", prop: "buyoutPrice" } },
                      [
                        _c(
                          "div",
                          { staticClass: "flex-box" },
                          [
                            _c("el-input", {
                              staticClass: "width-110",
                              attrs: { placeholder: "买断价", maxlength: "10" },
                              on: { change: _vm.salesEdit },
                              model: {
                                value: _vm.lease.buyoutPrice,
                                callback: function ($$v) {
                                  _vm.$set(_vm.lease, "buyoutPrice", $$v)
                                },
                                expression: "lease.buyoutPrice",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "font-size-12 box-m-l color_light-black",
                              },
                              [_vm._v("元")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "销售价", prop: "salesPrice" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c("el-input", {
                          staticClass: "width-110",
                          attrs: { placeholder: "销售价", maxlength: "10" },
                          on: { change: _vm.salesEdit },
                          model: {
                            value: _vm.lease.salesPrice,
                            callback: function ($$v) {
                              _vm.$set(_vm.lease, "salesPrice", $$v)
                            },
                            expression: "lease.salesPrice",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-size-12 box-m-l color_light-black",
                          },
                          [_vm._v("元")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "库存", prop: "inventory" } },
                  [
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "库存", maxlength: "12" },
                      on: { change: _vm.salesEdit },
                      model: {
                        value: _vm.lease.inventory,
                        callback: function ($$v) {
                          _vm.$set(_vm.lease, "inventory", $$v)
                        },
                        expression: "lease.inventory",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "可安装时间段", prop: "time" } },
                  [
                    _c("el-time-picker", {
                      attrs: {
                        "is-range": "",
                        format: "HH:mm",
                        "value-format": "HH:mm",
                        "range-separator": "至",
                        "start-placeholder": "开始时间",
                        "end-placeholder": "结束时间",
                        placeholder: "选择时间范围",
                      },
                      on: { change: _vm.timeChange },
                      model: {
                        value: _vm.leaseTime,
                        callback: function ($$v) {
                          _vm.leaseTime = $$v
                        },
                        expression: "leaseTime",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "初始销量", prop: "initialSales" } },
                  [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c("el-input", {
                          staticClass: "width-110",
                          attrs: { placeholder: "初始销量", maxlength: "10" },
                          on: { change: _vm.salesEdit },
                          model: {
                            value: _vm.lease.initialSales,
                            callback: function ($$v) {
                              _vm.$set(_vm.lease, "initialSales", _vm._n($$v))
                            },
                            expression: "lease.initialSales",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "font-size-12 box-m-l color_light-black",
                          },
                          [
                            _vm._v(
                              "设置后，您的用户看到的销量=初始销量+最近30天真实销量"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "商家默认提成",
                      prop: "defaultMerchantBrokerage",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "商家默认提成" },
                      on: {
                        change: _vm.salesEdit,
                        input: function ($event) {
                          _vm.lease.defaultMerchantBrokerage =
                            _vm.lease.defaultMerchantBrokerage.replace(
                              /[^0-9.]/g,
                              ""
                            )
                        },
                      },
                      model: {
                        value: _vm.lease.defaultMerchantBrokerage,
                        callback: function ($$v) {
                          _vm.$set(_vm.lease, "defaultMerchantBrokerage", $$v)
                        },
                        expression: "lease.defaultMerchantBrokerage",
                      },
                    }),
                    _vm._v("\n          %\n        "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: "专员默认提成",
                      prop: "defaultAttacheBrokerage",
                    },
                  },
                  [
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "专员默认提成" },
                      on: {
                        change: _vm.salesEdit,
                        input: function ($event) {
                          _vm.lease.defaultAttacheBrokerage =
                            _vm.lease.defaultAttacheBrokerage.replace(
                              /[^0-9.]/g,
                              ""
                            )
                        },
                      },
                      model: {
                        value: _vm.lease.defaultAttacheBrokerage,
                        callback: function ($$v) {
                          _vm.$set(_vm.lease, "defaultAttacheBrokerage", $$v)
                        },
                        expression: "lease.defaultAttacheBrokerage",
                      },
                    }),
                    _vm._v("\n          %\n        "),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_3" } }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "graphic",
                attrs: {
                  size: "medium",
                  "label-width": "140px",
                  model: _vm.graphic,
                  rules: _vm.graphicRules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "产品图片(主图)", prop: "primaryImages" } },
                  [
                    _c(
                      "d-upload",
                      {
                        attrs: {
                          "upload-type": "picture-card",
                          "file-list": _vm.primaryImagesList,
                          accept: ".gif,.jpeg,.jpg,.png",
                        },
                        on: {
                          uploadSuccess: _vm.uploadSuccess,
                          removeFile: _vm.removeFile,
                        },
                      },
                      [
                        _c("div", { staticClass: "tip-box" }, [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v("建议360px*360px")]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品图片(次图)", prop: "images" } },
                  [
                    _c(
                      "d-upload",
                      {
                        attrs: {
                          "upload-type": "picture-card",
                          "file-list": _vm.imagesList,
                          limit: 9,
                          multiple: "",
                          accept: ".gif,.jpeg,.jpg,.png",
                        },
                        on: {
                          uploadSuccess: _vm.uploadSuccess_1,
                          removeFile: _vm.removeFile_1,
                        },
                      },
                      [
                        _c("div", { staticClass: "tip-box" }, [
                          _c("i", { staticClass: "el-icon-plus" }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [_vm._v("建议360px*360px")]
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "主图视频(默认)" } },
                  [
                    _c(
                      "d-upload",
                      {
                        attrs: {
                          size: 100,
                          "file-list": _vm.primaryVideosList,
                          autoUpload: _vm.autoUpload,
                          action: _vm.actionUrl,
                          accept:
                            ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                        },
                        on: {
                          uploadSuccess: _vm.uploadSuccess_2,
                          handleChange: _vm.handleChange_2,
                          removeFile: _vm.removeFile_2,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tip-box" },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("视频上传")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.progressFlag2
                      ? _c("el-progress", {
                          attrs: { percentage: _vm.percentage2 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "主图视频(医院)" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.openVideos },
                      },
                      [_vm._v("视频上传")]
                    ),
                    _vm._v(" "),
                    _c("d-upload", {
                      attrs: {
                        size: 100,
                        "file-list": _vm.hospitalFileList,
                        action: _vm.actionUrl,
                        accept:
                          ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                      },
                      on: {
                        uploadSuccess: _vm.uploadSuccess_3,
                        removeFile: _vm.removeFile_3,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "详情顶部视频" } },
                  [
                    _c(
                      "d-upload",
                      {
                        attrs: {
                          size: 500,
                          "file-list": _vm.graphic.topVideo,
                          autoUpload: _vm.autoUpload,
                          action: _vm.actionUrl,
                          accept:
                            ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                        },
                        on: {
                          uploadSuccess: _vm.uploadSuccess_5,
                          handleChange: _vm.handleChange_5,
                          removeFile: _vm.removeFile_5,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tip-box" },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("视频上传")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.progressFlag5
                      ? _c("el-progress", {
                          attrs: { percentage: _vm.percentage5 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "详情底部视频" } },
                  [
                    _c(
                      "d-upload",
                      {
                        attrs: {
                          size: 500,
                          "file-list": _vm.graphic.bottomVideo,
                          autoUpload: _vm.autoUpload,
                          action: _vm.actionUrl,
                          accept:
                            ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                        },
                        on: {
                          uploadSuccess: _vm.uploadSuccess_6,
                          handleChange: _vm.handleChange_6,
                          removeFile: _vm.removeFile_6,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tip-box" },
                          [
                            _c(
                              "el-button",
                              { attrs: { size: "small", type: "primary" } },
                              [_vm._v("视频上传")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.progressFlag6
                      ? _c("el-progress", {
                          attrs: { percentage: _vm.percentage6 },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品详情", prop: "detail" } },
                  [
                    _c(
                      "div",
                      { staticClass: "editor" },
                      [
                        _c("mavon-editor", {
                          ref: "md",
                          attrs: { toolbars: _vm.toolbars },
                          on: {
                            imgAdd: _vm.handleEditorImgAdd,
                            imgDel: _vm.handleEditorImgDel,
                            change: _vm.editorChange,
                          },
                          model: {
                            value: _vm.editor,
                            callback: function ($$v) {
                              _vm.editor = $$v
                            },
                            expression: "editor",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("el-form-item", { attrs: { label: "产品参数" } }, [
                  _c(
                    "div",
                    { staticClass: "form-list flex-box" },
                    [
                      _vm._l(_vm.graphic.param, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "form-item flex-box" },
                          [
                            _c("div", { staticClass: "form-item flex-box" }, [
                              _c(
                                "label",
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "10",
                                      placeholder: "参数名称",
                                    },
                                    model: {
                                      value: item.paramKey,
                                      callback: function ($$v) {
                                        _vm.$set(item, "paramKey", $$v)
                                      },
                                      expression: "item.paramKey",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-item-content" },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      maxlength: "10",
                                      placeholder: "参数值",
                                    },
                                    model: {
                                      value: item.paramValue,
                                      callback: function ($$v) {
                                        _vm.$set(item, "paramValue", $$v)
                                      },
                                      expression: "item.paramValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "close_ben",
                                  on: {
                                    click: function ($event) {
                                      return _vm.delectParam(index)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-circle-close",
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-item flex-box" }, [
                        _c(
                          "div",
                          { staticClass: "form-item-content" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary", size: "medium" },
                                on: { click: _vm.addParam },
                              },
                              [_vm._v("添加参数行")]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_4" } }, [
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "flex-box" },
            [
              _vm._l(_vm.formData.tags, function (tag, index) {
                return _c(
                  "el-tag",
                  {
                    key: tag.tagId ? tag.tagId : tag.tagName,
                    attrs: { closable: "" },
                    on: {
                      close: function ($event) {
                        return _vm.closeLabel(index)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(tag.tagName) + "\n        ")]
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addLabel },
                },
                [_vm._v("添加")]
              ),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_5" } }, [
        _vm._m(4),
        _vm._v(" "),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            { staticClass: "flex-box" },
            [
              _vm._l(_vm.formData.services, function (tag, index) {
                return _c(
                  "el-tag",
                  {
                    key: tag.serviceId ? tag.serviceId : tag.serviceName,
                    attrs: { closable: "" },
                    on: {
                      close: function ($event) {
                        return _vm.closeServe(index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n          " + _vm._s(tag.serviceName) + "\n        "
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.addServe },
                },
                [_vm._v("添加")]
              ),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_6" } }, [
        _vm._m(5),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              { attrs: { size: "medium", "label-width": "80px" } },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "上架" } },
                  [
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "on_shelves" },
                        model: {
                          value: _vm.formData.productStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "productStatus", $$v)
                          },
                          expression: "formData.productStatus",
                        },
                      },
                      [_vm._v("上架出售")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-radio",
                      {
                        attrs: { label: "for_sale" },
                        model: {
                          value: _vm.formData.productStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "productStatus", $$v)
                          },
                          expression: "formData.productStatus",
                        },
                      },
                      [_vm._v("放入待出售")]
                    ),
                    _vm._v(" "),
                    !_vm.isAdd
                      ? _c(
                          "el-radio",
                          {
                            attrs: { label: "off_shelves" },
                            model: {
                              value: _vm.formData.productStatus,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "productStatus", $$v)
                              },
                              expression: "formData.productStatus",
                            },
                          },
                          [_vm._v("下架")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.details
              ? _c(
                  "div",
                  { staticClass: "flex-box submit-btn bg_white" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.submitData },
                      },
                      [_vm._v("确 定")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.$router.go(-1)
                          },
                        },
                      },
                      [_vm._v("取 消")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加" + _vm.labelTitle,
            visible: _vm.dialogVisible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    size: "medium",
                    "label-width": "80px",
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.labelTitle, prop: "labels" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "12", placeholder: _vm.labelTitle },
                        model: {
                          value: _vm.submitForm.labels,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "labels", $$v)
                          },
                          expression: "submitForm.labels",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "上传视频",
            visible: _vm.dialogVisible_1,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitVideos",
                  attrs: {
                    size: "medium",
                    "label-width": "80px",
                    model: _vm.submitVideos,
                    rules: _vm.submitVideosRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择视频", prop: "videos" } },
                    [
                      _c(
                        "d-upload",
                        {
                          attrs: {
                            size: 100,
                            "file-list": _vm.hospitalVideosList,
                            autoUpload: _vm.autoUpload,
                            action: _vm.actionUrl,
                            accept:
                              ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                          },
                          on: {
                            uploadSuccess: _vm.uploadSuccess_4,
                            handleChange: _vm.handleChange_4,
                            removeFile: _vm.removeFile_4,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "tip-box" },
                            [
                              _c(
                                "el-button",
                                { attrs: { size: "small", type: "primary" } },
                                [_vm._v("视频上传")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.progressFlag4
                        ? _c("el-progress", {
                            attrs: { percentage: _vm.percentage4 },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "绑定医院", prop: "hospitalId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-inherit",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.submitVideos.hospitalId,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitVideos, "hospitalId", $$v)
                            },
                            expression: "submitVideos.hospitalId",
                          },
                        },
                        _vm._l(_vm.hospitalList, function (item) {
                          return _c("el-option", {
                            key: item.hospitalId,
                            attrs: {
                              label: item.hospitalName,
                              value: item.hospitalId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.videosConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("基本信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("销售信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("图文描述")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("搜索标签")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("服务保障")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("发布设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }