<template>
  <div class="main-box">
    <div class="head-search-row">
      <h3>配送服务时间设置</h3>
      <el-form label-width="80px" ref="submitForm" :model="submitForm" :rules="submitRules">
        <el-form-item label="" prop="selectableDeliveryTime">
          <div class="flex-box">
            <div class="box-p-r">下单时可选择配送时间为</div>
            <el-input v-model="submitForm.selectableDeliveryTime" placeholder="0" class="width-110"></el-input>
            <div class="box-p-l">小时后</div>
          </div>
        </el-form-item>

        <el-form-item label="" prop="deliveryTimeBegin">
          <div class="flex-box">
            <div class="box-p-r">配送时间可选范围</div>
            <el-time-picker
              is-range
              v-model="timePicker"
              format="HH:mm"
              value-format="HH:mm"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围">
            </el-time-picker>
          </div>
        </el-form-item>

        <h3>预计送达时间设置</h3>
        <el-form-item label="" prop="estimatedDeliveryTime">
          <div class="flex-box">
            <div class="box-p-r">下单后预计</div>
            <el-input v-model="submitForm.estimatedDeliveryTime" placeholder="0" class="width-110"></el-input>
            <div class="box-p-l">小时送达</div>
          </div>
        </el-form-item>

        <h3>营养餐发货时间设置</h3>
        <el-form-item label="" prop="shipTime">
          <div class="flex-box">
            <div class="box-p-r">下单后预计</div>
            <el-input v-model="submitForm.shipTime" placeholder="0" class="width-110"></el-input>
            <div class="box-p-l">日发货</div>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitAdd">保存</el-button>
        </el-form-item>
      </el-form>

    </div> 


  </div>
</template>

<script>
export default {
  data() {
    return {
      // 编辑
      submitForm: {
        deliveryTimeBegin:  '',
        deliveryTimeEnd:  '',
        selectableDeliveryTime:'',
        estimatedDeliveryTime:'',
        shipTime: ''
      },
      submitRules: {
        selectableDeliveryTime: [{required: true, message: '请输入配送时间', trigger: 'blur'}],
        estimatedDeliveryTime: [{required: true, message: '请输入预计送达时间', trigger: 'blur'}],
        deliveryTimeBegin: [{required: true, message: '请选择配送时间可选范围', trigger: 'blur'}],
        shipTime: [{required: true, message: '请选择营养餐发货时间', trigger: 'blur'}],
      },
      timePicker: ['00:00', '23:59'],
      
    };
  },
  created() {
    // 获取数据
    this.getPageData()
  },
  methods: {
    // 获取数据列表
    getPageData() {
      this.$http.requestGet({
        url: '/operation/parameter',
      }).then(res => {
        let data = res.data
        this.submitForm.selectableDeliveryTime = data.selectableDeliveryTime
        this.submitForm.estimatedDeliveryTime = data.estimatedDeliveryTime
        this.submitForm.shipTime = data.shipTime
        this.submitForm.deliveryTimeBegin = data.deliveryTimeBegin
        this.submitForm.deliveryTimeEnd = data.deliveryTimeEnd
        this.timePicker = [data.deliveryTimeBegin, data.deliveryTimeEnd]
      }).catch(err => {
        console.log(err)
      })
    },

    // 保存
    submitAdd() {
      if (!this.timePicker) this.timePicker = []
      this.submitForm.deliveryTimeBegin = this.timePicker[0] ? this.timePicker[0] : ''
      this.submitForm.deliveryTimeEnd = this.timePicker[1] ? this.timePicker[1] : ''
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          let param = {
            deliveryTimeBegin: this.submitForm.deliveryTimeBegin,
            deliveryTimeEnd: this.submitForm.deliveryTimeEnd,
            selectableDeliveryTime: this.submitForm.selectableDeliveryTime,
            estimatedDeliveryTime: this.submitForm.estimatedDeliveryTime,
            shipTime: this.submitForm.shipTime,
          };

          this.$http.requestPut({
            url: '/operation/parameter',
            param: param
          }).then(res => {
            this.$message.success('操作成功！')
          })
        } else {
          return false
        }
      })
    }

    

  },
};
</script>

<style lang="scss" scoped>
  h3 {
    padding: 10px 20px 20px;
  }
</style>
