import { getSessionStorage } from "@/utils/auth";

// 获取随机uid
function guid() {
    return Number(Math.random().toString().substr(3, 4) + Date.now()).toString(36)
}

// 将 html 转换成 markdown 格式  (turndown插件)
function parseHTML_ForMarkdown(data) {
    const TurndownService = require('turndown').default
    var turndownService = new TurndownService()
    return turndownService.turndown(data)
}

// 返回指定的日期 yyyy-MM-dd
// 今天：0 ， 昨天：-1
function getAssignDate(num) {
    var date1 = new Date()
    var date2 = new Date(date1);
    date2.setDate(date1.getDate() + num);
    var time2 = date2.getFullYear() +"-"+ disposeDate(date2.getMonth()+1) +"-"+ disposeDate(date2.getDate());
    return time2;
}
function disposeDate(num) {
    return Number(num) >= 10 ? num : '0' + num
}

// 返回指定月初，月底
// date：返回月份的日期 yyyy-MM-dd
function getAssignMonth(date) {
    var now = new Date(date); //当前日期
    var nowMonth = now.getMonth(); //当前月
    var nowYear = now.getFullYear(); //当前年
    //本月的开始时间
    var monthStartDate = new Date(nowYear, nowMonth, 1); 
    //本月的结束时间
    var monthEndDate = new Date(nowYear, nowMonth+1, 0);

    var start = monthStartDate.getFullYear() +"-"+ disposeDate(monthStartDate.getMonth()+1) +"-"+ disposeDate(monthStartDate.getDate());
    var end = monthEndDate.getFullYear() +"-"+ disposeDate(monthEndDate.getMonth()+1) +"-"+ disposeDate(monthEndDate.getDate());
    return [start, end]
}

// 返回指定几个月之间的月初月末  return yyyy-MM
function getAssignMonthScope(num) {
    var now = new Date();
    var nowMonth = now.getMonth(); //当前月
    var nowYear = now.getFullYear(); //当前年
    //月范围的开始时间
    var monthStartDate = new Date(now.setMonth(nowMonth + num)); 
    //月范围的结束时间
    var monthEndDate = new Date(nowYear, nowMonth+1, 0);
    var start = monthStartDate.getFullYear() +"-"+ disposeDate(monthStartDate.getMonth()+1);
    var end = monthEndDate.getFullYear() +"-"+ disposeDate(monthEndDate.getMonth()+1);
    return [start, end]
}

// 将时间戳转换成日期格式
function formDate (data) {
    var date = new Date(data)
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    return Y + M + D
  }
//   获取当前日期
  function gettime() {
    var date = new Date();
    var year = date.getFullYear();
    var moth = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    var dates = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    return year + '-' + moth + '-' + dates
}

// 补零函数
function doHandleZero(zero) {
    var date = zero;
    if (zero.toString().length == 1) {
        date = "0" + zero;
    }
    return date;
}
// 获取每个月一号
function getDate() {
    var myDate = new Date();
    var tYear = myDate.getFullYear()
    var tMonth = myDate.getMonth()
    tMonth = doHandleZero(tMonth + 1)
 
    return tYear + "-" + tMonth + "-01"
}

// 流文件下载
function downloadFile(res, file_name = "") {
  var blob = res.data
  // FileReader主要用于将文件内容读入内存
  var reader = new FileReader()
  console.log(reader)
  reader.readAsDataURL(blob)
  // onload当读取操作成功完成时调用
  reader.onload = function(e) {
    var a = document.createElement("a")
    // 获取文件名fileName
    var fileName = file_name ? file_name : res.headers.filename
    a.download = fileName
    a.href = e.target.result
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}
// UTF8编码转成汉字字符串
function revertUTF8(szInput) {
  var x, wch, wch1, wch2, uch = "", szRet = ""
  for (x = 0; x < szInput.length; x++) {
    if (szInput.charAt(x) == "%") {
      wch = parseInt(szInput.charAt(++x) + szInput.charAt(++x), 16)
      if (!wch) {
        break
      }
      if (!(wch & 0x80)) {
        wch = wch
      } else if (!(wch & 0x20)) {
        x++
        wch1 = parseInt(szInput.charAt(++x) + szInput.charAt(++x), 16)
        wch = (wch & 0x1f) << 6
        wch1 = wch1 & 0x3f
        wch = wch + wch1
      } else {
        x++
        wch1 = parseInt(szInput.charAt(++x) + szInput.charAt(++x), 16)
        x++
        wch2 = parseInt(szInput.charAt(++x) + szInput.charAt(++x), 16)
        wch = (wch & 0x0f) << 12
        wch1 = (wch1 & 0x3f) << 6
        wch2 = wch2 & 0x3f
        wch = wch + wch1 + wch2
      }
      szRet += String.fromCharCode(wch)
    } else {
      szRet += szInput.charAt(x)
    }
  }
  return szRet
}

// 是否为所有区域权限
function isAllAreaPower() {
  const userInfo = getSessionStorage("userInfo")
  if (userInfo.managerType === "all") {
    return true
  }
  return false
}

function isPowerNew() {
  const userInfo = getSessionStorage("userInfo")
  if (userInfo.managerType === "all") {
    return 1
  } else if (userInfo.managerType === "region") {
    return 2
  } else if (userInfo.managerType === "district") {
    return 3
  } else if (userInfo.managerType === "agency") {
    return 4
  }
}

// 用户计算动态宽高，大屏适配
function fontSize(res) {
  let docEl = document.documentElement,
    clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  if (!clientWidth) return
  let fontSize = 100 * (clientWidth / 23329)
  return res * fontSize
}

// 将对象数组转换成二维数组的方法
function transData(sourceData) {
  // 定义一个空数组 -最后处理后返回的还是一个数组，return出来的
  let newArr = []
  // 对源数据进行循环遍历
  sourceData.forEach(item => {
    // item 是遍历出来的每一条对象
    const arr = Object.values(item)
    // 将新的小数组添加到一个大数组里
    newArr.push(arr)
  })
  return newArr
}

// 判断传入的时间是否在指定的时间范围内
function judgmentTime(curDate, beginDateStr, endDateStr) {
  let beginDate = new Date(beginDateStr)
  let endDate = new Date(endDateStr)
  let current = new Date(curDate)
  if (current >= beginDate && current <= endDate) {
    return true
  }
  return false
}

// 获取当前日期，包括星期几
function getCurrentDate() {
    var myDate = new Date();
    var year = myDate.getFullYear(); //年
    var month = myDate.getMonth() + 1 < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
    var day = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
    var days = myDate.getDay();
    switch(days) {
        case 1:
            days = '星期一';
            break;

        case 2:
            days = '星期二';
            break;

        case 3:
            days = '星期三';
            break;

        case 4:
            days = '星期四';
            break;

        case 5:
            days = '星期五';
            break;

        case 6:
            days = '星期六';
            break;

        case 0:
            days = '星期日';
            break;
    }
    var str = year + "-" + month + "-" + day + " " + days;
    return str;

}

// 会员等级
function getVipLevel(value) {
    // 1.普通用户 2.银星会员 3.玉星会员 4.金星会员
    if(value == 1) {
        return '普通用户'
    } else if(value==2) {
        return '银星会员'
    } else if(value==3) {
        return '玉星会员'
    } else if(value==4) {
        return '金星会员'
    } else {
        return '-'
    }
}
export default {
    disposeDate,
    guid,
    parseHTML_ForMarkdown,
    getAssignDate,
    getAssignMonth,
    downloadFile,
    getAssignMonthScope,
    isAllAreaPower,
    isPowerNew,
    fontSize,
    formDate,
    gettime,
    doHandleZero,
    getDate,
    transData,
    judgmentTime,
    getCurrentDate,
    getVipLevel,
    revertUTF8
}
