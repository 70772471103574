var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "订单编号", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.queryData.orderCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryData, "orderCode", $$v)
                      },
                      expression: "queryData.orderCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "售后申请时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00", "23:59:59"],
                    },
                    model: {
                      value: _vm.datetimerange,
                      callback: function ($$v) {
                        _vm.datetimerange = $$v
                      },
                      expression: "datetimerange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableData.loading,
                        expression: "tableData.loading",
                      },
                    ],
                    ref: "tables",
                    staticStyle: { width: "100%" },
                    attrs: {
                      border: "",
                      data: _vm.tableData.list,
                      "expand-row-keys": _vm.expandList,
                      "row-key": "orderId",
                    },
                    on: { "expand-change": _vm.expandChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: scope.row.afterSalesOrderItemVos,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品信息",
                                      "min-width": "200",
                                      prop: "",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "flex-box" },
                                                [
                                                  scope.row.productImg
                                                    ? _c("img", {
                                                        staticClass: "img-box",
                                                        attrs: {
                                                          src: scope.row
                                                            .productImg,
                                                          alt: "",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "hospital-name",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "txt_box",
                                                          class: {
                                                            "flex-box":
                                                              !scope.row
                                                                .productImg,
                                                            "flex-center":
                                                              !scope.row
                                                                .productImg,
                                                          },
                                                        },
                                                        [
                                                          scope.row.isGifts
                                                            ? _c("img", {
                                                                staticClass:
                                                                  "icon_box",
                                                                class: {
                                                                  no_img:
                                                                    !scope.row
                                                                      .productImg,
                                                                },
                                                                attrs: {
                                                                  src: require("@/assets/image/gift_icon.png"),
                                                                  alt: "",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                scope.row
                                                                  .productName
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品编号",
                                      "min-width": "200",
                                      prop: "productCode",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品规格",
                                      "min-width": "120",
                                      prop: "productNorm",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    scope.row.productNorm || "/"
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "单价",
                                      "min-width": "120",
                                      prop: "itemPrice",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "￥" + scope.row.itemPrice
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "数量",
                                      "min-width": "120",
                                      prop: "itemNum",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "实付总额",
                                      "min-width": "120",
                                      prop: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "￥" +
                                                      scope.row.paidAmount.toFixed(
                                                        2
                                                      ) || "0"
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "订单编号",
                        "min-width": "200",
                        prop: "orderCode",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.orderCode))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "买家/收货人",
                        "min-width": "160",
                        prop: "receiver",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "总金额", "min-width": "120", prop: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" +
                                      scope.row.orderTotalPrice.toFixed(2) ||
                                      "0"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "运费", "min-width": "120", prop: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" + scope.row.freight.toFixed(2) || "0"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "售后申请时间",
                        "min-width": "160",
                        prop: "applyTime",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "订单状态",
                        "min-width": "120",
                        prop: "auditStatus",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$api.getValue(
                                      _vm.$api.afterSalesOrderStatus,
                                      scope.row.auditStatus,
                                      "name"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        "min-width": "160",
                        prop: "remark",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toDetail(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.auditStatus === 1
                                        ? "审核"
                                        : "详情"
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              scope.row.auditStatus === 2
                                ? _c("span", [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn_partition",
                                      },
                                      [_vm._v("/")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.getPurchasePostInfo(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("查看物流")]
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.queryData.pageNum,
                      "page-size": _vm.queryData.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.tableData.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("d-dialog", {
        ref: "logisticsDialog",
        attrs: {
          "logistics-list": _vm.logisticsData.list,
          "data-obj": _vm.logisticsData.dataObj,
          "route-info": _vm.logisticsData.routeInfo,
        },
        on: { handleClose: _vm.closeLogisticsDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }