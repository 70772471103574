var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-nav" },
    [
      _vm.windowWidth1260 ? _c("navbar") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "log" }, [_vm._v("638 管理中台")]),
      _vm._v(" "),
      _vm.routes.length > 0
        ? _c(
            "el-menu",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.windowWidth1260,
                  expression: "!windowWidth1260",
                },
              ],
              staticClass: "el-menu-box",
              staticStyle: { "min-width": "850px" },
              attrs: {
                id: "zoom-in",
                "default-active": _vm.activeMenu,
                mode: "horizontal",
              },
              on: { select: _vm.handleSelect },
            },
            _vm._l(_vm.routes, function (item) {
              return _c(
                "div",
                { key: item.path, staticClass: "nav-item" },
                [
                  _c(
                    "app-link",
                    { attrs: { to: _vm.resolvePath(item) } },
                    [
                      !item.hidden
                        ? _c("el-menu-item", { attrs: { index: item.path } }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(item.meta.title) +
                                "\n            "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.routes.length > 0
        ? _c(
            "el-menu",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.windowWidth1260,
                  expression: "windowWidth1260",
                },
              ],
              staticClass: "el-menu-box",
              staticStyle: { "min-width": "126px" },
              attrs: {
                id: "zoom-out",
                "default-active": _vm.activeMenu,
                mode: "horizontal",
              },
              on: { select: _vm.handleSelect },
            },
            [
              _c(
                "el-submenu",
                { attrs: { index: "0" } },
                [
                  _c("template", { slot: "title" }, [
                    _vm._v(_vm._s(_vm.menuTitle ? _vm.menuTitle : "选择模块")),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.routes, function (item) {
                    return _c(
                      "div",
                      { key: item.path, staticClass: "nav-item" },
                      [
                        _c(
                          "app-link",
                          { attrs: { to: _vm.resolvePath(item) } },
                          [
                            !item.hidden
                              ? _c(
                                  "el-menu-item",
                                  { attrs: { index: item.path } },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.meta.title) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "head_user_btn",
          staticStyle: {
            position: "absolute",
            right: "0%",
            display: "flex",
            height: "56px",
            "line-height": "56px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "el-dropdown",
                {
                  attrs: { trigger: _vm.windowWidth1260 ? "click" : "hover" },
                  on: { command: _vm.handleCommand },
                },
                [
                  _c(
                    "span",
                    { staticClass: "el-dropdown-link" },
                    [
                      _c(
                        "el-badge",
                        {
                          staticClass: "item",
                          staticStyle: { display: "initial" },
                          attrs: {
                            "is-dot": "",
                            hidden: _vm.$store.state.user.msgNum <= 0,
                          },
                        },
                        [_vm._v(_vm._s(_vm.userInfo.managerName || "admin"))]
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right",
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "personData" } },
                        [_vm._v("个人资料")]
                      ),
                      _vm._v(" "),
                      _vm.isPermission("msgCenter")
                        ? _c(
                            "el-dropdown-item",
                            { attrs: { command: "msgNum", divided: "" } },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/system/msgCenter" } },
                                [
                                  _vm._v(
                                    "\n              消息中心\n              "
                                  ),
                                  _c("el-badge", {
                                    staticClass: "mark",
                                    attrs: {
                                      hidden: _vm.$store.state.user.msgNum <= 0,
                                      value: _vm.$store.state.user.msgNum,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "modifyPwd", divided: "" } },
                        [_vm._v("修改密码")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "logout", divided: "" } },
                        [_vm._v("退出账号")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "exit_btn",
              staticStyle: { cursor: "pointer", padding: "0 12px" },
              on: { click: _vm.logout },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    display: "block",
                    "font-size": "12px !important",
                    color: "#409eff",
                  },
                },
                [_vm._v("\n          退出账号\n        ")]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.dialogVisible,
            width: _vm.windowWidth768 ? "90%" : "30%",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", [_vm._v("您确定要退出登录吗？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.logoutc } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.logoutx } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "个人档案",
            visible: _vm.dialogVisible_1,
            width: _vm.windowWidth768 ? "90%" : "30%",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                { attrs: { size: "medium", "label-width": "80px" } },
                [
                  _c("el-form-item", { attrs: { label: "账号" } }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.userInfo.managerPhoneNumber)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "姓名" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.userInfo.managerName))]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "权限组" } }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.userInfo.rightsGroupNames.join(","))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "区域权限" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.getManageType))]),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "手机" } }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.userInfo.managerPhoneNumber)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改密码",
            visible: _vm.dialogVisible_2,
            width: _vm.windowWidth768 ? "90%" : "30%",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "userForm",
                  attrs: {
                    size: "medium",
                    "label-width": "100px",
                    model: _vm.userForm,
                    rules: _vm.userRules,
                  },
                },
                [
                  _c("el-form-item", { attrs: { label: "用户名" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.userInfo.managerName) +
                        "\n          "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "原密码", prop: "oldPwd" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password", placeholder: "原密码" },
                        model: {
                          value: _vm.userForm.oldPwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.userForm, "oldPwd", $$v)
                          },
                          expression: "userForm.oldPwd",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "新密码", prop: "newPwd" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password", placeholder: "新密码" },
                        model: {
                          value: _vm.userForm.newPwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.userForm, "newPwd", $$v)
                          },
                          expression: "userForm.newPwd",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "重复新密码", prop: "newPwds" } },
                    [
                      _c("el-input", {
                        attrs: { type: "password", placeholder: "重复新密码" },
                        model: {
                          value: _vm.userForm.newPwds,
                          callback: function ($$v) {
                            _vm.$set(_vm.userForm, "newPwds", $$v)
                          },
                          expression: "userForm.newPwds",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.commitChanges },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }