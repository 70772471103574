var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_2 bg_white flex-box" }, [
        _c("div", { staticClass: "row_left" }, [
          _c("h4", [_vm._v("配送信息")]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v(
              "配送方式：" +
                _vm._s(
                  _vm.$api.getValue(
                    _vm.$api.deliveryMethod,
                    _vm.pageData.deliveryMethod,
                    "name"
                  )
                )
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("收货人姓名：" + _vm._s(_vm.pageData.recipient)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v(
              "收货人手机号：" + _vm._s(_vm.pageData.recipientPhoneNumber)
            ),
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "color_light-black" },
            [
              _vm._v("\n        收货人地址：\n        "),
              _c("span", [_vm._v(_vm._s(_vm.pageData.recipientAddressDetail))]),
              _vm._v(" "),
              _vm.pageData.orderStatus === "pending_payment" ||
              _vm.pageData.orderStatus === "pending_ship"
                ? _c(
                    "el-button",
                    {
                      staticStyle: { padding: "0" },
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible_4 = true
                        },
                      },
                    },
                    [_vm._v("\n          修改\n        ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isBuyOut
            ? _c("p", { staticStyle: { height: "12px" } })
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("备注信息：" + _vm._s(_vm.pageData.orderRemark || "无")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row_right" }, [
          _c("h4", [
            _vm._v("\n        订单状态：\n        "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _vm.$api.getValue(
                    _vm.$api.leaseOrderState,
                    _vm.pageData.orderStatus,
                    "name"
                  )
                )
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.pageData.orderStatus == "pending_payment"
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "medium" },
                      on: { click: _vm.cancelOrder },
                    },
                    [_vm._v("取消订单")]
                  ),
                ],
                1
              )
            : _vm.pageData.orderStatus == "pending_ship"
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "medium" },
                      on: { click: _vm.sendOut },
                    },
                    [_vm._v("发货")]
                  ),
                ],
                1
              )
            : _vm.pageData.orderStatus == "pending_receipt" && !_vm.isBuyOut
            ? _c(
                "div",
                [
                  _c("p", { staticClass: "color_light-black" }, [
                    _vm._v("起租时间：" + _vm._s("")),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticStyle: { height: "12px" } }),
                  _vm._v(" "),
                  _c("p", { staticClass: "color_light-black" }, [
                    _vm._v("到期时间：" + _vm._s("")),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticStyle: { height: "12px" } }),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "medium" },
                      on: { click: _vm.rent },
                    },
                    [_vm._v("展期")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_3 bg_white" }, [
        _c("h4", [_vm._v("商品信息")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.pageData.items, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "序号", prop: "date", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.$index + 1) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品信息",
                    "min-width": "220",
                    prop: "available",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "flex-box" }, [
                            scope.row.productImg
                              ? _c("img", {
                                  staticClass: "img-box",
                                  attrs: { src: scope.row.productImg, alt: "" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "hospital-name" }, [
                              _vm._v(_vm._s(scope.row.productName)),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "租赁单价",
                    "min-width": "120",
                    prop: "productPrice",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.leaseTime
                                  ? "￥" + scope.row.productPrice
                                  : "/"
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "商品单价",
                    "min-width": "120",
                    prop: "productPrice",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.leaseTime
                                  ? "/"
                                  : "￥" + scope.row.productPrice
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "租赁时长",
                    "min-width": "120",
                    prop: "leaseTime",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.leaseTime
                            ? _c("div", [_vm._v(_vm._s(scope.row.leaseTime))])
                            : _c("div", [_vm._v("/")]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "租赁区间", "min-width": "200", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.leaseTime
                            ? _c("div", [
                                _vm._v(
                                  _vm._s(
                                    scope.row.leaseTimeBegin +
                                      " ~ " +
                                      scope.row.leaseTimeEnd
                                  )
                                ),
                              ])
                            : _c("div", [_vm._v("/")]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "服务时间",
                    "min-width": "100",
                    prop: "serviceTime",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "提成比例",
                    "min-width": "100",
                    prop: "brokerageRate",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "提成金额",
                    "min-width": "100",
                    prop: "paidAmount",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.leaseTime
                                  ? "￥" +
                                      (
                                        scope.row.productPrice *
                                        scope.row.leaseTime *
                                        (scope.row.brokerageRate / 100)
                                      ).toFixed(2)
                                  : "￥" +
                                      (
                                        scope.row.productPrice *
                                        (scope.row.brokerageRate / 100)
                                      ).toFixed(2)
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "show-money" }, [
          _c("ul", [
            _c("li", [
              _vm._v("\n          商品金额：\n          "),
              _c("span", { staticClass: "block_span" }, [
                _vm._v("￥ " + _vm._s((_vm.goodsMoney || 0).toFixed(2))),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("\n          运费：\n          "),
              _c("span", { staticClass: "block_span" }, [
                _vm._v(
                  "+￥ " + _vm._s((_vm.pageData.shippingFee || 0).toFixed(2))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("li", [
              _vm._v("\n          押金：\n          "),
              _c("span", { staticClass: "block_span" }, [
                _vm._v(
                  "+￥ " + _vm._s((_vm.pageData.depositPrice || 0).toFixed(2))
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.pageData.orderStatus == "completed"
              ? _c("li", [
                  _vm._v("\n          已退押金：\n          "),
                  _c("span", { staticClass: "block_span" }, [
                    _vm._v(
                      "-￥ " +
                        _vm._s(
                          (_vm.pageData.depositPriceReturned || 0).toFixed(2)
                        )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("li", { staticClass: "line-box" }),
            _vm._v(" "),
            _c("li", { staticClass: "bold" }, [
              _vm._v("\n          总额：\n          "),
              _c("span", { staticClass: "block_span color_red" }, [
                _vm._v(
                  "\n            ￥\n            " +
                    _vm._s(
                      _vm.goodsMoney + _vm.pageData.shippingFee
                        ? (
                            _vm.goodsMoney +
                            _vm.pageData.shippingFee +
                            (_vm.pageData.depositPrice || 0) -
                            (_vm.pageData.depositPriceReturned || 0)
                          ).toFixed(2)
                        : "0.00"
                    ) +
                    "\n          "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_4 bg_white" }, [
        _c("h4", [_vm._v("商家/医院信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家名称：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.merchantName) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家编号：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.merchantCode) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("医院名称：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.hospitalName) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("医院编号：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.hospitalCode) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_4 bg_white" }, [
        _c("h4", [_vm._v("订单信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("订单类型：")]),
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$api.getValue(
                  _vm.$api.orderClassify,
                  _vm.pageData.orderClassify,
                  "name"
                )
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("订单编号：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.orderCode) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("下单时间：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.orderTime) + "\n    "),
        ]),
        _vm._v(" "),
        _vm.pageData.orderStatus !== "pending_payment" &&
        _vm.pageData.orderStatus !== "expired" &&
        _vm.pageData.orderStatus !== "pending_evaluate" &&
        _vm.pageData.orderStatus !== "completed" &&
        _vm.pageData.orderStatus !== "cancel"
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("付款时间：")]),
              _vm._v("\n      " + _vm._s(_vm.pageData.paymentTime) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.pageData.orderStatus == "pending_receipt" &&
        _vm.pageData.orderStatus == "leased"
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("发货时间：")]),
              _vm._v("\n      " + _vm._s(_vm.pageData.shipTime) + "\n    "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_5 row_4 bg_white" }, [
        _c("h4", [_vm._v("专员信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("专员姓名：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.attacheName || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("专员编号：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.attacheCode || "无") + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_5 row_4 bg_white" }, [
        _c("h4", [_vm._v("业务员信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("业务员姓名：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.intendantName || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("业务员编号：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.intendantCode || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("二维码编号：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.qrCodeSerial || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家端名称：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.qrMerchantName || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家端编号：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.qrMerchantCode || "无") + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_3,
            title: "展期",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_3 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm_3",
                  attrs: {
                    model: _vm.submitForm_3,
                    rules: _vm.submitRules_3,
                    "label-width": "80px",
                    size: "medium",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "订单编号" },
                        model: {
                          value: _vm.submitForm_3.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm_3, "name", $$v)
                          },
                          expression: "submitForm_3.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "到期时间" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "width-inherit",
                        attrs: {
                          disabled: "",
                          placeholder: "选择日期",
                          type: "date",
                        },
                        model: {
                          value: _vm.submitForm_3.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm_3, "name", $$v)
                          },
                          expression: "submitForm_3.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "展期时间", prop: "name" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "width-inherit",
                        attrs: { placeholder: "选择日期", type: "date" },
                        model: {
                          value: _vm.submitForm_3.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm_3, "name", $$v)
                          },
                          expression: "submitForm_3.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_3 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_3 = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_4,
            "before-close": _vm.closeAddresCPM,
            title: "修改收货地址",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_4 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "changeAddressForm",
                  attrs: {
                    model: _vm.changeAddressForm,
                    rules: _vm.changeAddressRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在地址", prop: "addressId" } },
                    [
                      _c("el-cascader", {
                        staticClass: "width-inherit",
                        attrs: {
                          props: _vm.props_add,
                          options: _vm.addressList_add,
                          placeholder: "请选项地址",
                        },
                        model: {
                          value: _vm.changeAddressForm.addressId,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeAddressForm, "addressId", $$v)
                          },
                          expression: "changeAddressForm.addressId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址详情", prop: "address" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          maxlength: "150",
                          placeholder: "地址详情",
                        },
                        model: {
                          value: _vm.changeAddressForm.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.changeAddressForm, "address", $$v)
                          },
                          expression: "changeAddressForm.address",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeAddresCPM } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.amendAddress } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }