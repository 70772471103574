<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        
        <el-form-item label="搜索词">
          <el-input v-model="inquire.searchDescription" placeholder="搜索词" @keyup.enter.native="searchList"></el-input>
        </el-form-item>

        <!-- <el-form-item label="所属用户端">
          <el-select v-model="inquire.searchBelong" placeholder="请选择" class="form-select" clearable>
            <el-option v-for="item in $api.terminalUnitList" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="新增时间">
          <div class="flex-box">
            <el-date-picker
              v-model="daterange"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增搜索词</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column> 
            <el-table-column prop="searchDescription" label="搜索词" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="searchBelong" label="所属用户端" min-width="120">
              <template slot-scope="scope">
                {{$api.getNeedValue($api.terminalUnitList, scope.row.searchBelong, 'value', 'name')}}
              </template>
            </el-table-column>
            <el-table-column prop="searchOrder" label="排序" min-width="120"></el-table-column>
            <el-table-column prop="searchHits" label="点击量" min-width="120"></el-table-column>
            <el-table-column prop="searchStatus" label="是否显示" min-width="100">
              <template slot-scope="scope">
                {{scope.row.searchStatus === 'display' ? '显示' : '隐藏'}}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="新增时间" min-width="160"></el-table-column>
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span> 
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleDel(scope.row)">删除</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleHide(scope.row, 's')" v-show="scope.row.searchStatus === 'hide'">显示</span>
                <span class="operation-btn" @click="handleHide(scope.row, 'h')" v-show="scope.row.searchStatus === 'display'">隐藏</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 添加弹框 -->
    <el-dialog
    :title="dialogTitle + '搜索词'"
    :visible.sync="dialogVisible"
    :before-close="closeDialog"
    width="650px"
    >
      <div class="Popout_content">
        <el-form label-width="120px" ref="submitForm" :model="submitForm" :rules="submitRules">
            <el-form-item label="所属用户端">会员</el-form-item>

            <el-form-item label="搜索词" prop="searchDescription">
              <el-input v-model="submitForm.searchDescription" placeholder="搜索词"></el-input>
            </el-form-item>

            <el-form-item label="排序" prop="searchOrder">
              <el-input v-model.number="submitForm.searchOrder" placeholder="排序"></el-input>
            </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        searchDescription: '',
        createTimeBegin: '',
        createTimeBegin: '',
        createTimeEnd: '',
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [], 

      // 编辑
      dialogVisible: false, // 编辑弹窗
      submitForm: {
        searchBelong: 'member', 
        searchDescription: '',
        searchOrder: '',
      },
      submitRules: {
        searchDescription: [{required: true, message: '请输入搜索词', trigger: 'blur'}],
        searchOrder: [{required: true, message: '请输入排序', trigger: 'blur'},
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ]
      },
      attacheTable: [],
      editRow: {},
      isAdd: true,
      dialogTitle: '', // 标题


    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    

    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : '';
      this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1]: '';
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/operation/hot/search/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    // 添加
    addition() { 
      this.dialogTitle = '添加';
      this.isAdd = true;
      this.dialogVisible = true;
    },

    // 修改
    handleEdit(row) {
      this.dialogTitle = '编辑';
      this.isAdd = false;

      this.submitForm = {
        searchBelong:  row.searchBelong || 'member',
        searchDescription: row.searchDescription,
        searchOrder: row.searchOrder
      }
      
      this.dialogVisible = true;
      this.editRow = row;
    },
    // 提交添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          let param = {
            searchBelong:  this.submitForm.searchBelong,
            searchDescription: this.submitForm.searchDescription,
            searchOrder: this.submitForm.searchOrder
          };

          if (!this.isAdd) {
            param.searchId = this.editRow.searchId;
          }

          this.$http.requestPost({
            url: '/operation/hot/search/save',
            param: param
          }).then(res => {
            this.getTableList()
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.closeDialog()
          })
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields());
      this.dialogVisible = false;
      this.submitForm = {
        searchBelong:  '',
        searchDescription: '',
        searchOrder: '',
      }
    },

    // 删除
    handleDel(row) {
      this.$confirm('确定删除该热门搜索词?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
          this.pageParam.pageNum--
        }
        this.$http.requestDel({url:'/operation/hot/search/del/' + row.searchId}).then(res => {
          this.getTableList();
          this.$message.success('删除成功!');
        })
      }).catch(err => {})
    },

    // 隐藏
    handleHide(row, type) {
      let name = type === 'h' ? '隐藏' : '显示';
      this.$confirm('确定'+ name +'该热门搜索词?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        let param = {
          searchId: row.searchId,
          searchStatus: type === 'h' ? 'hide' : 'display'
        }
        this.$http.requestPost({url:'/operation/hot/search/save', param:param}).then(res => {
          this.getTableList();
          this.$message.success('操作成功!');
        })
      }).catch(err => {})
    },

  },
};
</script>

<style lang="scss" scoped>

</style>
