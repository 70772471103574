<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form class="demo-form-inline" ref="submitForm" :model="submitForm" :rules="submitRules" size="medium">
        <el-form-item label="出库单编号" class="color_light-grey">出库单编号在出库成功后自动生产</el-form-item>

        <el-form-item label="出库仓库" prop="warehouseId">
          <el-select v-model="submitForm.warehouseId" placeholder="请选择" @change="selectChange">
            <el-option v-for="(item,index) in warehouseList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark" label-width="200">
          <el-input v-model="submitForm.remark" placeholder="" class="width-180"></el-input>
        </el-form-item>

        <el-form-item label="出库产品" prop="batchItems">
          <div style="display:flex; justify-content:flex-end;">
              <el-button type="primary" @click="handleSelectBatch">选择批次</el-button>
          </div>
        </el-form-item>


        <el-form-item label="">
          <el-table :data="submitForm.batchItems" border>
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam_1.pageNum - 1) * pageParam_1.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="inventoryId" label="SKU编号" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="productCode" label="SPU编号" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="productCategoryName" label="所属类别" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="count" label="出库数量" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="batchNo" label="批次" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="produceTime" label="生产日期" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="expireTime" label="到期时间" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="termOfValidity" label="有效期（天）" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template  v-slot="scope">
                <span class="operation-btn" @click="deleteProduct(scope.$index)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item>
          <div class="flex-box flex-justify submit-btn">
            <el-button type="primary" @click="submitData">保 存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

     <!-- 选择批次/ 添加批次弹窗 -->
    <el-dialog title="选择批次" :visible.sync="dialogVisible_1" :before-close="closeDialog" width="90%" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="">
            <div class="flex-box">
              <el-select v-model="selectKey_1" placeholder="请选择" @change="selectValue_1 = ''" class="width-140">
                <el-option label="批次" value="batchNo"></el-option>
                <el-option label="产品编号" value="productCode"></el-option>
                <el-option label="产品名称" value="productName"></el-option>
              </el-select>
              <el-input v-model="selectValue_1" placeholder="" class="width-180"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="" >
            <div class="flex-box">
              <el-select v-model="selectKey_2" placeholder="请选择" @change="selectValue_2 = ''" class="width-140">
                  <el-option label="生产时间" value="produceTime"></el-option>
                  <el-option label="出库时间" value="createTime"></el-option>
                  <el-option label="到期时间" value="expireTime"></el-option>
                </el-select>
              <div class="flex-box">
                <el-date-picker v-model="selectValue_2" type="date" value-format="yyyy-MM-dd"  />
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="searchList_1">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table ref="carTable" :data="batchList" @selection-change="handleProductChange_1"  v-loading="loading" :border="true" height="350px" style="width: 100%">
          <el-table-column type="selection" :selectable="selectable" width="55"></el-table-column>
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{( pageParam_1.pageNum - 1) * pageParam_1.pageSize + (scope.$index + 1) }}</template>
          </el-table-column>
          <el-table-column prop="inventoryId" label="SKU编号" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productCode" label="SPU编号" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="normName" label="规格" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productName" label="产品名称" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productCategoryName" label="所属类别" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="batchNo" label="批次" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="count" label="库存" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="produceTime" label="生产日期" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="expireTime" label="到期时间" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="termOfValidity" label="有效期" min-width="120"></el-table-column>
          <el-table-column prop="createTime" label="入库时间" min-width="180"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination background :current-page="pageParam_1.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam_1.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam_1.total" @size-change="handleSizeChange_1" @current-change="handleCurrentChange_1" />
        </div>
        <p style="padding-top: 30px"></p>

        <el-form ref="batchForm" :model="batchForm" :rules="batchFormRules" label-width="100px" class="form demo-form-inline">
          <el-form-item label="出库数量" prop="count">
            <el-input v-model="batchForm.count" @input="verifyInt($event, 'count')" max="8" placeholder="出库数量"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd_1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import {getSessionStorage} from '@/utils/auth.js'
export default {
  data() {
    return {
      // 录入生产单
      warehouseList: [],// 仓库列表
      submitForm: {
        warehouseId: '',
        batchItems: [],
        remark: '',
        managerId: '',
        managerName: '',
        warehouseName:''
      },
      submitRules: {
        warehouseId: [{ required: true, message: "请选择仓库", trigger: "change" }],
        batchItems: [{ required: true, message: "请添加产品", trigger: "change" }],
      },

      selectProduct: [],// 选择的产品sku列表
      loading: false,
      addProductForm: {
        id: '',
        productId: '',// 产品ID（SPU）
        inventoryId: '',// 库存ID（SKU）
        productName: '',
        no: '',
        count: '',
        produceTime: '',
        expireTime: ''
      },
      addProductRules: {
        no: [{ required: true, message: "请输入批次", trigger: "blur" }],
        count: [{ required: true, message: "请输入出库数量", trigger: "blur" }],
        expireTime: [{ required: true, message: "请选择到期时间", trigger: "change" }],
      },


      /* 选择批次  */
      dialogVisible_1:false,
      selectKey_1: 'batchNo', // 批次类别
      selectValue_1: '',
      selectKey_2:'produceTime',
      selectValue_2:'',
      batchForm:{
        count: '' 
      },
      batchFormRules: {
        count: [{ required: true, message: "请输入出库数量", trigger: "blur" }],
      },
      inquire_1:{
          typeKey:'',
          timeType:'',
          typeValue:'',
          batchNo:'',   // 批次编号
          productCode:'', // 产品编号
          productName:'',
          expireTime:'', // 到期时间
          createTime:'',  // 出库时间
          produceTime:'', // 生产时间
          warehouseId:'', // 仓库id
      },
      pageParam_1: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      batchList:[],
      daterange:[],
      batchId:'',  // 选择的批次id
      selectionRow:[]
    }
  },
  created() {
    this.getWarehouseList();
    this.getProductType();
    console.log(getSessionStorage('userInfo'))
    this.submitForm.managerId = getSessionStorage('userInfo').managerId;
    this.submitForm.managerName = getSessionStorage('userInfo').managerName;
    console.log(getSessionStorage('userInfo'))
  },
  methods: {
    // 保存
    submitData() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            warehouseName: this.submitForm.warehouseName,
            item: [],
            managerId:this.submitForm.managerId,
            managerName:this.submitForm.managerName,
            remark:this.submitForm.remark
          }
          this.submitForm.batchItems.map(v => {
            console.log(v)
            let obj = {
              batchNo: v.batchNo,
              batchId:v.batchId,
              expireTime: v.expireTime,
              productName: v.productName,
              productId: v.productId,
              count: v.count,
              inventoryId: v.inventoryId,
              produceTime: v.produceTime,
              productCode:v.productCode,
              productCategoryName:v.productCategoryName,
            }
            param.item.push(obj)
          })
          console.log(param)

          this.$http.requestPost({
            url: '/batchOutbound/save',
            param:param
          }).then(res => {
            
            this.$message.success("录入成功")
            setTimeout(() => {
              this.$router.push({
                path: '/warehouse/outbound/batchOutbound/getList',
                query: {}
              })
            }, 1500);
          })
        } else { return false }
      })

    },
    // 删除添加的产品
    deleteProduct(index) {
      // console.log(index)
      this.submitForm.batchItems.splice(index, 1)
    },

   
    // 添加产品 - 关闭弹窗
    closeDialog() {
      this.dialogVisible_1 = false;
      this.$refs.batchForm && this.$refs.batchForm.resetFields();
      this.inquire_1 = {
          typeKey:'',
          timeType:'',
          typeValue:'',
          batchNo:'',   // 批次编号
          productCode:'', // 产品编号
          expireTime:'', // 到期时间
          createTime:'',  // 出库时间
          produceTime:'', // 生产时间
          productName:'',
      }
      this.selectKey_1 = 'batchNo';
      this.selectValue_1 = '';
      this.selectKey_2 = 'produceTime';
      this.selectValue_2 = '';
    },
    // 添加产品弹窗 - 判断当前项是否可选
    selectable(row, index) {
      // 当有已选择产品sku，将已选择产品sku在新增弹窗禁止勾选
      if (this.submitForm.batchItems.length > 0) {
        let arr = this.submitForm.batchItems.map(item => item.batchId)
        if (arr.indexOf(row.batchId) != -1) {
          return false;
        }
      }
      return true
    },
    


    // ================ 其它 ================
    // 获取仓库列表
    getWarehouseList() {
      let data = {
        pageNum: 1,
        pageSize: 10000
      }
      this.$http.requestPost({
        url: '/warehouse/page',
        param: data,
        loading: false
      }).then(res => {
        this.warehouseList = res.data.list;
      })
    },
    // 获取产品类型
    getProductType() {
      this.$api.productTypeList({}).then(res => {
        this.productTypeList = res.data;
      })
    },
    // 校验是否正整数
    verifyInt(e, name) {
      let verify = this.$validate.verifyInt(e)
      if (!verify) {
        this.addProductForm[name] = '';
      }
    },


    /**===========  批次  ===============  */
    handleSelectBatch(){
        if(this.submitForm.warehouseId.length <= 0){
            return this.$message.error('请选择仓库');
        }
        
      this.closeDialog();
      this.dialogVisible_1 = true;
      console.log(1212121);
    //   this.batchList = [];
      this.getBatchList();
    },
    getBatchList(){
      let param = JSON.parse(JSON.stringify(this.inquire_1))
      param[this.selectKey_1] = this.selectValue_1;
      param[this.selectKey_2] = this.selectValue_2 || '';
      param.warehouseId = this.submitForm.warehouseId;
      let data = {
        pageNum: this.pageParam_1.pageNum,
        pageSize: this.pageParam_1.pageSize,
        param
      }
      this.$http.requestPost({
        url:'/warehouse/getBatchList',
        param:data
      }).then(res=>{
        const { list, total } = res.data;
        this.batchList = list;
        this.pageParam_1.total = total;
        this.loading = false;

        list.map(v => {
          let d = new Date(v.expireTime);
          let timeStamp = d - new Date(v.produceTime);
          let termOfValidity = timeStamp / (24 * 60 * 60 * 1000);

          v.termOfValidity = isNaN(termOfValidity) ? '' : termOfValidity.toFixed(0);
        })
      }).catch(err => {
        this.loading = false;
      })
    },
    // 页容量改变
    handleSizeChange_1(size) {
      this.pageParam_1.pageNum = 1;
      this.pageParam_1.pageSize = size;
      // 刷新(数据)
      this.getBatchList()
    },
    // 页码改变
    handleCurrentChange_1(page) {
      this.pageParam_1.pageNum = page;
      // 刷新(页码)
      this.getBatchList();
    },
    // 搜索
    searchList_1() {
      this.pageParam_1.pageNum = 1;
      this.getBatchList()
    },
    // 选择批次
    handleProductChange_1(selection) {
      this.selectionRow = selection;
    },
    submitAdd_1(){
      let that = this;
      that.$refs.batchForm.validate(valid => {
        if (valid) {
            console.log(this.selectionRow)
          if(that.selectionRow.length<=0){
            return that.$message.warning('请选择批次')
          };
          let form = this.batchForm;
          let arr = JSON.parse(JSON.stringify(this.selectionRow));
          arr.map(v => {
              v.count = Number(form.count);
              v.produceTime = v.produceTime ? (v.produceTime + ' 00:00:00') : '';
              v.expireTime  = v.expireTime ? (v.expireTime  + ' 00:00:00') : '';
          })
          this.submitForm.batchItems.push(...arr)
          console.log(this.submitForm)
          that.$refs.carTable.clearSelection();
          that.closeDialog();
        }
      })
    },
    selectChange(e){
        let iteminfo = this.warehouseList.filter(item=> {return item.id == e})
        this.submitForm.warehouseName = iteminfo[0].name;
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 400px;
}
</style>