<template>
  <div class="box">
    <div class="header">
      <span>营养师信息</span>
    </div>
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="border-section"></div>
    <el-form label-width="160px" ref="submitForm" :model="form" :rules="rules">
      <el-form-item label="营养师照片" :required="true">
        <d-upload upload-type="picture-card" :file-list="dietitianList" :limit="1" accept=".gif,.jpeg,.jpg,.png" @uploadSuccess="uploadSuccess1" @removeFile="removeFile1">
          <div class="tip-box">
            <i class="el-icon-plus" />
            <span slot="tip" class="tip">上传图片</span>
          </div>
        </d-upload>
      </el-form-item>
      <el-form-item label="营养师名称" prop="dietitianName">
        <el-input v-model="form.dietitianName" placeholder="请输入营养师名称" clearable style="width: 240px;" />
      </el-form-item>
      <el-form-item label="机构名称" >
        <el-input v-model="form.dietitianOrganization" placeholder="请输入医院/机构/企业名称" clearable style="width: 240px;" />
      </el-form-item>
      <el-form-item label="所属地区" prop="regionId">
        <el-cascader v-model="form.regionId" :options="addressList" :props="props_List" clearable="" placeholder="请选择地址" style="width: 240px" />
      </el-form-item>
      <el-form-item label="手机号码" prop="phoneNumber">
        <el-input v-model="form.phoneNumber" placeholder="请输入手机号码" maxlength="11" clearable style="width: 240px;" />
      </el-form-item>

      <div class="header">
        <span>身份证信息</span>
      </div>
      <div class="border-section"></div>
      <el-form-item label="身份证正反面照片" :required="true">
        <d-upload upload-type="picture-card" :file-list="idCardList" :limit="2" accept=".gif,.jpeg,.jpg,.png" @uploadSuccess="uploadSuccess2" @removeFile="removeFile2">
          <div class="tip-box">
            <i class="el-icon-plus" />
            <span slot="tip" class="tip">上传图片</span>
          </div>
        </d-upload>
      </el-form-item>
      <el-form-item label="手持身份证照片" :required="true">
        <d-upload upload-type="picture-card" :file-list="idHandList" :limit="1" accept=".gif,.jpeg,.jpg,.png" @uploadSuccess="uploadSuccess3" @removeFile="removeFile3">
          <div class="tip-box">
            <i class="el-icon-plus" />
            <span slot="tip" class="tip">上传图片</span>
          </div>
        </d-upload>
      </el-form-item>
      <div class="header">
        <span>资格认证</span>
      </div>
      <div class="border-section"></div>
      <el-form-item label="认证证书" :required="true">
        <d-upload upload-type="picture-card" :file-list="certificateList" :limit="1" accept=".gif,.jpeg,.jpg,.png" @uploadSuccess="uploadSuccess4" @removeFile="removeFile4">
          <div class="tip-box">
            <i class="el-icon-plus" />
            <span slot="tip" class="tip">上传图片</span>
          </div>
        </d-upload>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import dUpload from "@/components/d-upload/index";
export default {
  components: {
    dUpload,
  },
  data() {
    return {
      status: '',
      queryFrom: {},
      form: {
        // id:'',
        dietitianCode: '',
        dietitianPicture: '',
        dietitianName: '',
        dietitianOrganization: '',
        regionId: '',
        phoneNumber: '',
        idCardFrontUrl: '',
        idCardBackUrl: '',
        idCardHandUrl: '',
        certificateUrl: '',
        addressId:''
      },
      // 是否添加
      isAdd: false,
      dietitianList: [],
      idCardList: [],
      idHandList: [],
      certificateList: [],
      // 所属地区
      addressList: [],
      props_List: {
        label: "addressName",
        value: "regionId",
        children: "child",
        emitPath: false,
      },
      dialogVisible: false,
      dialogTitle: '',
      dialogContent: '',
      dialogRadio: 3,
      rules: {
        dietitianName: [{ required: true, message: "请输入营养师名称", trigger: "blur" }],
        regionId: [{ required: true, message: "请选择所属地区", trigger: "change" }],
        phoneNumber: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
      },
    }
  },
  created() {
    this.queryFrom = this.$route.params.queryFrom;
    this.gerAddress();
    this.getRecord();
  },
  methods: {
    uploadSuccess1(res, file, fileList) {
      this.dietitianList = []
      this.form.dietitianPicture = res.url
      this.dietitianList.push({ name: res.originalFilename, url: res.url })
    },
    uploadSuccess2(res, file, fileList) {
      this.idCardList = []
      if (fileList.length === 1) {
        this.form.idCardFrontUrl = res.url
        this.idCardList.push({ name: res.originalFilename, url: res.url })
      } else if (fileList.length === 2) {
        this.form.idCardBackUrl = res.url
        this.idCardList.push({ name: res.originalFilename, url: res.url })
      }
    },
    uploadSuccess3(res, file, fileList) {
      this.idHandList = []
      this.form.idCardHandUrl = res.url
      this.idHandList.push({ name: res.originalFilename, url: res.url })
    },
    uploadSuccess4(res, file, fileList) {
      this.certificateList = []
      this.form.certificateUrl = res.url
      this.certificateList.push({ name: res.originalFilename, url: res.url })
    },
    // 删除图片-主图
    removeFile1(file, fileList) {
      this.dietitianList = []
    },
    removeFile2(file, fileList) {
      this.idCardList = []
    },
    removeFile3(file, fileList) {
      this.idHandList = []
    },
    removeFile4(file, fileList) {
      this.certificateList = []
    },
    getImage() {
      if (this.dietitianList && this.dietitianList.length === 1) {
        this.form.dietitianPicture = this.dietitianList[0].url
      }
      if (this.idCardList && this.idCardList.length === 1) {
        this.form.idCardFrontUrl = this.idCardList[0].url
      } else if (this.idCardList && this.idCardList.length === 2) {
        this.form.idCardFrontUrl = this.idCardList[0].url
        this.form.idCardBackUrl = this.idCardList[1].url
      }
      if (this.idHandList && this.idHandList.length === 1) {
        this.form.idCardHandUrl = this.idHandList[0].url
      }
      if (this.certificateList && this.certificateList.length === 1) {
        this.form.certificateUrl = this.certificateList[0].url
      }
    },
    getRecord() {
      this.dietitianList = []
      this.idCardList = []
      this.idHandList = []
      this.certificateList = []
      this.$http
        .requestGet({ url: "/consult/dietitianRecord/" + this.queryFrom.dietitianCode})
        .then((res) => {
          console.log(res,'wwww')
        if (res.code === 200) {
          this.dietitianList.push({ name: res.data.dietitianPicture.substring(res.data.dietitianPicture.lastIndexOf("/") + 1), url: res.data.dietitianPicture })
          this.idCardList.push({ name: res.data.idCardFrontUrl.substring(res.data.idCardFrontUrl.lastIndexOf("/") + 1), url: res.data.idCardFrontUrl })
          this.idCardList.push({ name: res.data.idCardBackUrl.substring(res.data.idCardBackUrl.lastIndexOf("/") + 1), url: res.data.idCardBackUrl })
          this.idHandList.push({ name: res.data.idCardHandUrl.substring(res.data.idCardHandUrl.lastIndexOf("/") + 1), url: res.data.idCardHandUrl })
          this.certificateList.push({ name: res.data.certificateUrl.substring(res.data.certificateUrl.lastIndexOf("/") + 1), url: res.data.certificateUrl })
          this.form.dietitianName = res.data.dietitianName
          this.form.dietitianOrganization = res.data.dietitianOrganization
          this.form.regionId = res.data.regionId
          this.form.phoneNumber = res.data.phoneNumber
          this.getImage()
        }
      });
    },
    // 所属地区
    gerAddress() {
      this.$http
        .requestGet({ url: "/common/address/getDietitianAllWithTree", loading: false })
        .then((res) => {
          console.log(res,'122121');
          this.addressList = res.data;
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.box{
  background-color: #fff;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.border-section {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}
.tip-box {
  position: relative;
  .tip {
    position: absolute;
    top: 26px;
    left: 34%;
    font-size: 12px;
    color: #ccc;
  }
}
</style>