<template>
  <!-- 已发货货订单 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="selectKey" @change="selectValue = ''" placeholder="请选择">
              <el-option label="订单编号" value="orderCode"></el-option>
              <el-option label="收货人姓名" value="recipient"></el-option>
              <el-option label="专员姓名" value="attacheName"></el-option>
            </el-select>

            <el-input v-model="selectValue" placeholder="请输入"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="开票时间">
          <el-date-picker v-model="daterange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" range-separator="至" end-placeholder="结束日期" start-placeholder="开始日期" type="daterange">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="产品名称">
          <el-input v-model="inquire.name" placeholder="产品名称"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px;display: flex;align-items: center;">
      <el-button size="small" type="primary" @click="fnExportOrderList">导出</el-button>
      <div style="margin-left: 20px;">本月已完成开票：</div>
      <div style="font-size: 18px;">{{ybTotal}}</div>
      <div style="margin-left: 10px;">张</div>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table ref="tables" v-loading="loading" :data="tableData" :expand-row-keys="expands" border height="400px" row-key="invoiceId" style="width: 100%" @expand-change="expandChange">
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table :data="scope.row.items" border style="width: 100%">
                  <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex-box">
                        <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box">
                        <div class="hospital-name">{{ scope.row.productName }}</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="产品编号" min-width="160" prop="productCode" show-overflow-tooltip></el-table-column>
                  <el-table-column label="产品规格" min-width="120" prop="productNorms">
                    <template slot-scope="scope">{{ scope.row.productNorms || '/' }}</template>
                  </el-table-column>
                  <el-table-column label="单价" min-width="120" prop="itemPrice">
                    <template slot-scope="scope">{{ '￥' + scope.row.itemPrice }}</template>
                  </el-table-column>
                  <el-table-column label="数量" min-width="120" prop="itemNum"></el-table-column>
                  <el-table-column label="购买总价" min-width="120" prop="">
                    <template slot-scope="scope">
                      {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="订单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleParticulars(scope.row)">{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="买家/收货人" min-width="160" prop="">
              <template slot-scope="scope">
                {{ scope.row.customerOrder.recipient ? (scope.row.customerOrder.memberNickName + '/' + scope.row.customerOrder.recipient) : scope.row.customerOrder.memberNickName + '（自提）' }}
              </template>
            </el-table-column>
            <el-table-column label="开票金额" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + (scope.row.customerOrder.invoiceAmount ? scope.row.customerOrder.invoiceAmount.toFixed(2) : '0.00')}}
              </template>
            </el-table-column>
            <el-table-column label="运费" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + (scope.row.customerOrder.shippingFee ? scope.row.customerOrder.shippingFee.toFixed(2) : '0.00' ) }}
              </template>
            </el-table-column>
            <el-table-column label="开票时间" min-width="160" prop="updateTime"></el-table-column>
            <el-table-column label="订单状态" min-width="120" prop="orderStatus">
              <template slot-scope="scope">
                {{ $api.getValue($api.EcommerceOrderState, scope.row.customerOrder.orderStatus, 'name') }}
              </template>
            </el-table-column>
            <el-table-column label="所属专员" min-width="120" prop="remarkes">
              <template slot-scope="scope">
                {{ scope.row.customerOrder.attacheName }}
              </template>
            </el-table-column>
            <el-table-column label="大区" min-width="120" prop="regionName">
              <template slot-scope="scope">
                {{ scope.row.regionName }}
              </template>
            </el-table-column>
            <el-table-column label="片区" min-width="120" prop="districtName">
              <template slot-scope="scope">
                {{ scope.row.districtName }}
              </template>
            </el-table-column>
            <el-table-column label="办事处" min-width="120" prop="agencyName">
              <template slot-scope="scope">
                {{ scope.row.agencyName }}
              </template>
            </el-table-column>
            <el-table-column label="医院" min-width="120" prop="hospitalName">
              <template slot-scope="scope">
                {{ scope.row.hospitalName }}
              </template>
            </el-table-column>
            <el-table-column label="商家" min-width="120" prop="merchantName">
              <template slot-scope="scope">
                {{ scope.row.customerOrder.merchantName }}
              </template>
            </el-table-column>
            <el-table-column label="发票抬头" min-width="120" prop="invoiceName">
              <template slot-scope="scope">
                {{ scope.row.invoiceName }}
              </template>
            </el-table-column>
            <el-table-column label="开票状态" min-width="120" prop="invoiceStatus">
              <template slot-scope="scope">
                {{ scope.row.invoiceStatus }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="160" prop="remark">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleWait(scope.row)">发票详情</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleParticulars(scope.row)">订单详情</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination :current-page="pageParam.pageNum" :page-size="pageParam.pageSize" :page-sizes="$api.pageSizes" :total="pageParam.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 开票 -->
    <el-dialog :visible.sync="dialogVisible" title="开票" width="500px">
      <div class="Popout_content">
        <div class="wait-info">
          <ul>
            <li class="wait-li flex-box">
              <div class="lable">订单编号：</div>
              <div class="content">{{ waitInfo.orderCode }}</div>
            </li>
            <li class="wait-li flex-box">
              <div class="lable">开票金额：</div>
              <div class="content">{{ '￥' + waitInfo.orderTotalPrice }}</div>
            </li>
            <li class="wait-li flex-box">
              <div class="lable">抬头类型：</div>
              <div class="content">{{ waitInfo.invoiceType == 1 ? '企业' : '个人' }}</div>
            </li>
            <li class="wait-li flex-box">
              <div class="lable">发票抬头：</div>
              <div class="content">{{ waitInfo.invoiceName }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">税号：</div>
              <div class="content">{{ waitInfo.taxNumber }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">开户银行：</div>
              <div class="content">{{ waitInfo.openBankName || '无' }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">开户账号：</div>
              <div class="content">{{ waitInfo.openBankNnumber || '无' }}</div>
            </li>
            <li class="wait-li flex-box" v-if="waitInfo.invoiceType == 1">
              <div class="lable">企业地址：</div>
              <div class="content">{{ waitInfo.enterpriseAddress || '无' }}</div>
            </li>
          </ul>
        </div>

        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px" size="medium">
          <el-form-item label="发票图片：" prop="invoiceUrl">
            <d-upload upload-type="picture-card" :file-list="fileList" accept=".gif,.jpeg,.jpg,.png" @removeFile="removeFile" @uploadSuccess="uploadSuccess">
              <div class="tip-box">
                <i class="el-icon-plus" />
                <span class="tip" slot="tip">图片大小：60*60</span>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="备注：">
            <el-input v-model="submitForm.remark" :rows="3" maxlength="150" disabled placeholder="请输入内容" type="textarea">
            </el-input>
          </el-form-item>

        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button @click="confirm" type="primary">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import dUpload from "@/components/d-upload";
import { exportInvoice } from "@/api/order";
export default {
  components: {
    dUpload,
  },
  data() {
    return {
      inquire: {
        invoiceStatus: 1,
        createTimeBegin: "",
        createTimeEnd: "",
      },
      daterange: [],
      selectKey: "orderCode",
      selectValue: "",

      // 表格数据
      tableData: [],
      expands: [], // 当前展示行

      // 分页
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },

      // 开票
      waitInfo: {}, // 开票信息
      dialogVisible: false,
      submitForm: {
        invoiceUrl: "",
        remark: "",
      },
      submitRules: {
        invoiceUrl: [
          { required: true, message: "请选择发票图片", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
      fileList: [],
      ybTotal: "",
    };
  },
  created() {
    if (sessionStorage.getItem("cacheParamG")) {
      this.inquire = JSON.parse(sessionStorage.getItem("cacheParamG"));
      this.daterange[0] = this.inquire.createTimeBegin || "";
      this.daterange[1] = this.inquire.createTimeEnd || "";
    }
    this.getTableList();
    this.handleTotal();
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList();
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1;
      this.getTableList();
    },
    // table 手风琴
    expandChange(news, old) {
      if (old.length === 0) {
        return (this.expands = []);
      }
      this.expands = [news.invoiceId];
    },
    removeFile() {
      this.submitForm.invoiceUrl = "";
    },
    // 添加医院-添加图片文件
    uploadSuccess(res, file, fileList) {
      console.log(res);
      this.submitForm.invoiceUrl = file.response.data;
    },
    confirm() {
      this.$http
        .requestPost({
          url: "/customer/invoice/save",
          param: this.submitForm,
          loading: false,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.getTableList();
            this.closeDialog();
          }
        });
      console.log(this.submitForm);
    },
    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.createTimeBegin =
        this.daterange.length > 0 ? this.daterange[0] : "";
      this.inquire.createTimeEnd =
        this.daterange.length > 0 ? this.daterange[1] : "";
      // updateTime

      let urlParam =
        this.pageParam.pageNum + "&pageSize=" + this.pageParam.pageSize;
      var param = JSON.parse(JSON.stringify(this.inquire));
      if (this.selectValue) {
        param[this.selectKey] = this.selectValue;
      }

      this.loading = true;
      this.$http
        .requestPost({
          url: "/customer/invoice/orderList?pageNum=" + urlParam,
          param,
          loading: false,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.data.list;
          this.pageParam.total = res.data.data.total;
          sessionStorage.removeItem("cacheParamG");
        })
        .catch((err) => {
          this.loading = false;
          sessionStorage.removeItem("cacheParamG");
          console.log(err);
        });

      // if (!this.daterange) this.daterange = [];
      // this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : '';
      // this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : '';
      // let param = {
      //     pageNum: this.pageParam.pageNum,
      //     pageSize: this.pageParam.pageSize,
      //     param: this.inquire
      // }

      // let param = this.pageParam.pageNum + '&pageSize=' + this.pageParam.pageSize + '&invoiceStatus=' + this.inquire.invoiceStatus;
      // this.loading = true;
      // this.$http.requestGet({ url: '/customer/invoice/orderList?pageNum=' + param, loading:false }).then(res => {
      //     this.loading = false;
      //     this.tableData = res.data.list
      //     this.pageParam.total = res.data.total;
      // }).catch(err => {
      //     this.loading = false
      //     console.log(err);
      // })
    },
    // 导出
    fnExportOrderList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.createTimeBegin =
        this.daterange.length > 0 ? this.daterange[0] : "";
      this.inquire.createTimeEnd =
        this.daterange.length > 0 ? this.daterange[1] : "";
      // updateTime

      let urlParam =
        this.pageParam.pageNum + "&pageSize=" + this.pageParam.pageSize;
      var param = JSON.parse(JSON.stringify(this.inquire));
      if (this.selectValue) {
        param[this.selectKey] = this.selectValue;
      }

      this.loading = true;
      exportInvoice(param).then((res) => {
        this.$public.downloadFile(res);
        this.getTableList();
      });
    },

    // 开票
    handleWait(row) {
      this.fileList = [];
      this.$http
        .requestGet({ url: "/customer/invoice/get/" + row.invoiceId })
        .then((res) => {
          let data = res.data;
          this.waitInfo = data;
          this.waitInfo.orderTotalPrice = row.customerOrder.orderTotalPrice;
          this.dialogVisible = true;
          this.fileList = [
            {
              name: data.invoiceUrl.slice(data.invoiceUrl.lastIndexOf("/")),
              url: data.invoiceUrl,
            },
          ];
          console.log(data);
          this.submitForm.invoiceUrl = data.invoiceUrl;
          this.submitForm.invoiceId = data.invoiceId;
          this.submitForm.remark = data.remark;
          // this.$nextTick(() => {
          //     const upEl = document.getElementsByClassName('el-upload')[0]
          //     upEl.style.display = 'none'
          // })
        });
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && this.$refs.submitForm.resetFields();
      this.dialogVisible = false;
      this.submitForm = {
        invoiceUrl: "",
        remark: "",
      };
    },

    // 订单详情
    handleParticulars(row) {
      sessionStorage.setItem("cacheParamG", JSON.stringify(this.inquire));
      let path = "",
        index = 0;
      switch (row.customerOrder.orderClassify) {
        case "electronic":
          path = "/order/commerce/particulars";
          index = this.$api.EcommerceOrderState.findIndex(
            (item) => item.value === row.customerOrder.orderStatus
          );
          break;
        case "medicine":
          path = "/order/medical/hospitalDelivery/particulars";
          index =
            this.$api.orderState.findIndex(
              (item) => item.value === row.customerOrder.orderStatus
            ) + 1;
          break;
        case "lease":
          path = "/order/lease/particulars";
          index = this.$api.leaseOrderState.findIndex(
            (item) => item.value === row.customerOrder.orderStatus
          );
          break;
        case "nutrition":
          path = "/order/NutritionOrder/particulars";
          index = this.$api.nutritionOrderState.findIndex(
            (item) => item.value === row.customerOrder.orderStatus
          );
          break;
        default:
          return;
      }
      this.$router.push({
        path,
        query: {
          type: index,
          id: row.customerOrder.orderId,
        },
      });
    },

    // 统计-总的发票数量
    handleTotal() {
      this.$http
        .requestPost({
          url: "/customer/invoice/getCurrentMonthInvoice",
        })
        .then((res) => {
          if (res.code == 200) {
            this.ybTotal = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wait-info {
  padding-bottom: 20px;
}
.wait-li {
  padding-bottom: 10px;
  .lable {
    font-size: 14px;
    font-weight: bold;
    padding-right: 10px;
    width: 100px;
    text-align: right;
  }
  .content {
    flex: 1;
  }
}
</style>
