<template>
  <!-- 限购关联弹窗 -->
  <div class="quotaChooseProduct">
    <el-dialog title="选择关联产品" :visible.sync="dialogVisible" width="60%">
      <div class="quote_relate_search">
        <div class="search_item">
          <span class="item_label">搜索产品：</span>
          <div>
            <el-input v-model="searchInputVal" size="small" placeholder="请输入产品名称" clearable @keyup.enter.native="updateList" />
          </div>
        </div>
        <div class="search_item">
          <span class="item_label">产品类别：</span>
          <el-cascader v-model="searchSelectVal" :props="cascaderProps" :options="productTypeList" clearable />
        </div>
        <div class="search_item search_button">
          <el-button type="primary" size="small" @click="updateList">查询</el-button>
        </div>
      </div>
      <TableList
        ref="quoteRealtTable"
        class="tableList"
        height="350px"
        :is-show-index="false"
        :is-selection="true"
        :reserve-selection="true"
        row-key-name="productId"
        :table-header="tableHead"
        :table-data="list"
        :total="total"
        :loading="loading"
        @setPage="pageChange($event)"
        @setSize="sizeChange($event)"
        @delXuan="selectQuoteRealte"
        @handleSelectAll="selectQuoteRealte"
      >
        <template #sellingPice="{data}">
          ￥{{ data.productPriceMin }} ~ ￥{{ data.productPriceMax }}
        </template>
        <template #productStatus="{data}">
          {{ data.productStatus == 'on_shelves' ? '在售' : '-' }}
        </template>
      </TableList>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelSelect">取 消</el-button>
        <el-button type="primary" @click="confirmSelect">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { notLinkProduct } from '@/api/api.js'

export default {
  props: {
    linkProductIds: {
      // 已选关联的产品
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      productId: '', // 产品id
      // 产品分类下拉配置
      cascaderProps: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: false
      },
      productTypeList: [], // 产品分类数据

      // 限购关联产品数据
      dialogVisible: false, // 弹窗
      searchInputVal: '', // 搜索产品名称
      searchSelectVal: '', // 搜索产品类别
      productCategory: [], // 产品类别数据

      tableHead: [
        // 表头
        { label: '产品编号', prop: 'productCode' },
        { label: '产品名称', prop: 'productName' },
        { label: '产品类别', prop: 'productCategoryName' },
        { label: '销售价', type: 'slot', slotName: 'sellingPice' },
        { label: '状态', type: 'slot', slotName: 'productStatus' },
        { label: '产品库存', prop: 'productInventoryTotal' }
      ],
      // 请求数据
      queryData: {
        pageNum: 1,
        pageSize: 10,
        param: {
          productId: '', // 产品id
          productClassify: '', // 产品归类
          productStatus: 'on_shelves', // 产品状态
          productName: '', // 产品名称
          productCategoryId: '', // 产品类别id
          isLink: false // 是否关联 false：否
        }
      },
      list: [], // 数据
      selectList: [], // 选中的数据
      total: 0,
      loading: true
    }
  },

  mounted() {},

  methods: {
    // 打开限购设置关联产品弹窗
    open(productId) {
      this.dialogVisible = true
      // 恢复默认值
      this.searchInputVal = ''
      this.searchSelectVal = ''
      this.queryData.pageNum = 1
      this.queryData.pageSize = 10

      this.productId = productId
      this.getProductType()
      this.getNotLinkProduct()
    },
    // 获取产品类别
    getProductType() {
      this.$api.productTypeList({}).then(res => {
        this.productTypeList = res.data
      })
    },
    // 获取未关联产品列表
    getNotLinkProduct(isUpdate = false) {
      const paramsData = { ...this.queryData }
      paramsData.param.productId = this.productId
      paramsData.param.productName = this.searchInputVal
      paramsData.param.productCategoryId = this.searchSelectVal

      this.loading = true
      notLinkProduct(paramsData, false)
        .then(res => {
          if (res.code == 200) {
            this.list = res.data.list || []
            this.total = res.data.total
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
        })
      // 获取默认已勾选数据【可省略这一步操作，后端已经过滤掉已关联的产品】
      this.$nextTick(() => {
        if (!isUpdate) {
          this.$refs.quoteRealtTable.clearSelection()
        }
        if (this.linkProductIds.length) {
          this.linkProductIds.forEach(item => {
            this.$refs.quoteRealtTable.toggleRowSelection(item, true)
          })
        }
      })
    },
    // 刷新列表
    updateList() {
      this.queryData.pageNum = 1
      this.getNotLinkProduct(true)
    },
    // 修改页码
    pageChange(data) {
      this.queryData.pageNum = data
      this.getNotLinkProduct()
    },
    // 修改页数
    sizeChange(data) {
      this.queryData.pageSize = data
      this.getNotLinkProduct()
    },
    // 选择限购产品
    selectQuoteRealte(row) {
      this.selectList = row
    },
    // 提交已选限购产品
    confirmSelect() {
      this.$emit('confirmSelect', this.selectList)
      this.dialogVisible = false
    },
    // 限购关联产品弹窗取消按钮
    cancelSelect() {
      this.dialogVisible = false
      this.$refs.quoteRealtTable.clearSelection()
    }
  }
}
</script>

<style lang="scss" scoped>
.quotaChooseProduct {
  .quote_relate_search {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .search_item {
      width: max-content;
      display: flex;
      align-items: center;
      margin-right: 30px;
      .item_label {
        width: max-content;
      }
    }
  }
}
</style>
