var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.personDialogTitle,
        "close-on-click-modal": false,
        width: "550px",
        top: "10vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "Popout_content" },
        [
          _c(
            "el-form",
            {
              ref: "submitFormRef",
              attrs: {
                "label-width": "100px",
                model: _vm.submitForm,
                rules: _vm.ruleFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "人员姓名", prop: "memberNickname" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入人员姓名" },
                    model: {
                      value: _vm.submitForm.memberNickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.submitForm, "memberNickname", $$v)
                      },
                      expression: "submitForm.memberNickname",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "员工工号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入员工工号" },
                    model: {
                      value: _vm.submitForm.qyUserId,
                      callback: function ($$v) {
                        _vm.$set(_vm.submitForm, "qyUserId", $$v)
                      },
                      expression: "submitForm.qyUserId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "memberTelephone" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled: !!_vm.submitForm.memberId,
                      placeholder: "请输入手机号",
                      maxlength: "11",
                    },
                    model: {
                      value: _vm.submitForm.memberTelephone,
                      callback: function ($$v) {
                        _vm.$set(_vm.submitForm, "memberTelephone", $$v)
                      },
                      expression: "submitForm.memberTelephone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "所属分组", prop: "talentCategoryId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-select",
                      attrs: { placeholder: "请选择所属分组" },
                      model: {
                        value: _vm.submitForm.talentCategoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.submitForm, "talentCategoryId", $$v)
                        },
                        expression: "submitForm.talentCategoryId",
                      },
                    },
                    _vm._l(_vm.groupManagementList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.categoryName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.personButtonLoading },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.personButtonLoading, type: "primary" },
              on: { click: _vm.personSubmitAddOrEdit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }