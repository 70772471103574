<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        
        <el-form-item label="申购单编号">
          <el-input v-model="inquire.orderCode" placeholder="申购单编号" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item label="申购时间">
          <el-date-picker
            v-model="daterange"
            end-placeholder="结束日期"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>

        <el-form-item label="产品名称">
          <el-input v-model="inquire.productName" placeholder="产品名称" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item label="商家名称">
          <el-input v-model="inquire.merchantName" placeholder="商家名称" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item label="区域">
          <el-cascader v-model="inquire.searchAreaId" :props="areaProps" :options="areaList" clearable placeholder="请选择地址" v-if="$public.isAllAreaPower()" style="width: 300px;" />
          <d-area :values.sync="inquire.searchAreaId" v-else />
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="exportFile">导出</el-button>
      <el-button size="small" type="primary" @click="batchAudit">批量审核通过</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            ref="tables"
            :data="tableData"
            :expand-row-keys="expands"
            border
            height="400px"
            row-key="orderCode"
            style="width: 100%"
            @expand-change="expandChange"
          >
            <el-table-column type="expand" :resizable="false">
              <template slot-scope="scope">
                <el-table :data="scope.row.items" border style="width: 100%">
                  <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex-box">
                        <img
                          v-if="scope.row.productImg"
                          :src="scope.row.productImg"
                          alt=""
                          class="img-box"
                        >
                        <div class="hospital-name">{{ scope.row.productName }}</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="产品编号"
                    min-width="160"
                    prop="productCode"
                    show-overflow-tooltip
                  />
                  <el-table-column label="产品规格" min-width="120" prop="productNorms" />
                  <el-table-column label="申购单价" min-width="120" prop="itemPrice">
                    <template slot-scope="scope">{{ '￥' + scope.row.itemPrice }}</template>
                  </el-table-column>
                  <el-table-column label="申购数量" min-width="120" prop="itemNum" />
                  <el-table-column label="申购总价" min-width="120" prop="">
                    <template slot-scope="scope">
                      {{ '￥' + (scope.row.itemNum * scope.row.itemPrice).toFixed(2) }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="申购单编号" min-width="200" prop="orderCode" show-overflow-tooltip :resizable="false">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleAudit(scope.row)">{{scope.row.orderCode}}</span>
              </template>
            </el-table-column>
            <el-table-column label="商家" min-width="160" prop="merchantName" :resizable="false"/>
            <el-table-column label="商家编号" min-width="200" prop="merchantCode" :resizable="false"/>
            <el-table-column label="申购总价" min-width="120" prop="" :resizable="false">
              <template slot-scope="scope">
                {{ '￥' + getTotalPrices(scope.row.items) }}
              </template>
            </el-table-column>
            <el-table-column label="申购时间" min-width="160" prop="purchaseTime" :resizable="false"/>
            <el-table-column fixed="right" label="操作" min-width="120" prop="" :resizable="false">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleAudit(scope.row)">审核</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-size="pageParam.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog
      :visible.sync="dialogVisible_1"
      title="批量审核"
      width="650"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px">
          <el-form-item label="批量审核模板">
            <el-button size="small" type="primary">下载批量审核模板文件</el-button>
          </el-form-item>

          <el-form-item label="上传审核文件" prop="hospitalAvatar">
            <d-upload
              :file-list="fileList"
              accept=".xls,.xlsx"
              @removeFile="removeFile"
              @uploadSuccess="uploadSuccess"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </d-upload>
          </el-form-item>
        </el-form>

        <el-table :data="tableData_2" border style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">{{
              (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
            }}
            </template>
          </el-table-column>
          <el-table-column label="文件名" min-width="160" prop="" />
          <el-table-column label="文件格属" min-width="120" prop="" />
          <el-table-column label="成功发货订单数量" min-width="160" prop="" />
          <el-table-column label="发货失败数量" min-width="120" prop="" />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_1 = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 产品导出 -->
    <product-export
        ref="isProductExport"
        :showDialog="showExportDialog"
        :close.sync="showExportDialog"
        :purchaseStatus="inquire.purchaseStatus"
        productType="purchase"
        title="待审核申购单导出"
        sgDateTitle="申购时间"
    ></product-export>
  </div>
</template>

<script>
import dArea from "@/components/d-area";
import dUpload from '@/components/d-upload'
import { setSessionStorage, getSessionStorage } from "@/utils/auth";
import productExport from "@/components/productExport";

export default {
    components: {
        dUpload,
        productExport,
        dArea
    },
    data() {
        return {
            // 查询
            inquire: {
              purchaseStatus: 'auditing',
              purchaseTimeBegin: '',
              purchaseTimeEnd: '',
              productName: '',
              orderCode: '',
              merchantName: '',
              searchAreaId: ''
            },
            daterange: [],
            areaProps: {
              label: "areaName",
              value: "areaId",
              children: "childes",
              checkStrictly: true,
              emitPath: false,
            },
            areaList: [], //区域数据源
            
            // 分页
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            // 表格数据
            tableData: [],
            loading: false,
            expands: [], // 当前展示行

            stateList: [
                { name: '审核中', value: 'auditing' },
                { name: '已审核', value: 'audited' },
                { name: '未通过', value: 'unaudited' },
                { name: '已完成', value: 'completed' }
            ],

            // ============ 批量审核 ============
            dialogVisible_1: false, // 批量审核弹窗
            submitForm: {},
            submitRules: {},
            tableData_2: [],
            fileList: [], // 文件反显列表
            showExportDialog: false,
            userInfo: {}, //获取用户信息
        }
    },
    created() {
      this.userInfo = getSessionStorage("userInfo");
      let page = getSessionStorage('auditingPage')
      if (page && Object.keys(page).length > 0) {
        this.pageParam.pageNum = page.pageNum
        this.pageParam.pageSize = page.pageSize
      }
      if(sessionStorage.getItem("cacheParamLa")){
        this.inquire = JSON.parse(sessionStorage.getItem("cacheParamLa"))
        this.daterange[0] = this.inquire.purchaseTimeBegin || ''
        this.daterange[1] = this.inquire.purchaseTimeEnd || ''
      }

      this.getDataList()
      //获取区域
      this.getDistrictIdList()
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size
            this.getDataList()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page
            this.getDataList()
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1
            this.getDataList()
        },
        // 导出
        exportFile() {
            this.showExportDialog = true
            this.$refs.isProductExport.open()
        },
        // 获取区域级联列表
        getDistrictIdList() {
          this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
            if (this.userInfo.managerType === 'region') {
              this.areaList = res.data.filter(item => item.areaId == this.userInfo.areaId)
            } else {
              this.areaList = res.data;
            }
          })
        },
        // 添加医院-添加图片文件
        uploadSuccess(res, file, fileList) {
            this.submitForm.hospitalAvatar = file
        },
        // 添加医院-删除图片文件
        removeFile(file, fileList) {
            this.submitForm.hospitalAvatar = ''
        },
        // table 手风琴
        expandChange(news, old) {
            if (old.length === 0) {
                return this.expands = [];
            }
            this.expands = [news.orderCode];
        },
        // 计算总价
        getTotalPrices(arr = []) {
            let prices = 0
            arr.forEach(v => {
                prices += parseInt(v.itemNum) * v.itemPrice
            })
            return prices.toFixed(2)
        },

        // 获取数据列表
        getDataList() {
            if (!this.daterange) this.daterange = [];
            this.inquire.purchaseTimeBegin = this.daterange.length > 0 ? this.daterange[0] : '';
            this.inquire.purchaseTimeEnd = this.daterange.length > 0 ? this.daterange[1] : '';
            if(getSessionStorage('userInfo').managerType !== null && getSessionStorage('userInfo').managerType !== ''){
              this.inquire.managerType = getSessionStorage('userInfo').managerType;
            }
            const param = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: JSON.parse(JSON.stringify(this.inquire))
            }
            this.$http.requestPost({ url: '/customer/purchase/order/newPage', param: param, loading: false }).then(res => {
                this.loading = false
                this.tableData = res.data.list
                this.pageParam.total = res.data.total
                sessionStorage.removeItem("cacheParamLa")
            }).catch(err => {
                this.loading = false
                sessionStorage.removeItem("cacheParamLa")
                console.log(err)
            })
        },

        // 批量审核通过
        batchAudit(row) {
            this.dialogVisible_1 = true
        },

        // 审核
        handleAudit(row) {
            setSessionStorage('auditingPage', this.pageParam)
            sessionStorage.setItem("cacheParamLa", JSON.stringify(this.inquire))
            this.$router.push({
                path: '/form/purchaseRequisition/particulars',
                query: {
                    type: 1,
                    id: row.orderCode
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
    width: 375px;
}

.el-head-search-row {
    .flex-box {
        width: 326px;
    }
}
</style>
