var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "管理员名称/手机号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "管理员名称/手机号" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "name", $$v)
                      },
                      expression: "inquire.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addition },
            },
            [_vm._v("新增管理员")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "", height: "400px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "序号", prop: "date", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    (_vm.pageParam.pageNum - 1) *
                                      _vm.pageParam.pageSize +
                                      (scope.$index + 1)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "姓名",
                        "min-width": "120",
                        prop: "managerName",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "手机号",
                        "min-width": "120",
                        prop: "managerPhoneNumber",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "权限组",
                        "min-width": "300",
                        prop: "",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.managerId == "0"
                                ? _c("div", [_vm._v("超级管理员")])
                                : _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        scope.row.rightsGroupNames
                                          ? scope.row.rightsGroupNames.join()
                                          : scope.row.rightsGroupNames
                                      )
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "状态",
                        "min-width": "100",
                        prop: "accountStatus",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    scope.row.accountStatus === "normal"
                                      ? "启用"
                                      : "锁定"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "添加时间",
                        "min-width": "160",
                        prop: "createTime",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "添加人",
                        "min-width": "140",
                        prop: "addByName",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        "min-width": "200",
                        prop: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return scope.row.managerId !== "0"
                                ? [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleEdit(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("编辑")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn_partition",
                                      },
                                      [_vm._v(" / ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.resetPwd(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("重置密码")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn_partition",
                                      },
                                      [_vm._v(" / ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.adminListEMail(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("管理邮箱")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn_partition",
                                      },
                                      [_vm._v(" / ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              scope.row.accountStatus ===
                                              "normal",
                                            expression:
                                              "scope.row.accountStatus === 'normal'",
                                          },
                                        ],
                                        staticClass: "operation-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.stateSwitch(
                                              scope.row,
                                              "h"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("锁定")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              scope.row.accountStatus !==
                                              "normal",
                                            expression:
                                              "scope.row.accountStatus !== 'normal'",
                                          },
                                        ],
                                        staticClass: "operation-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.stateSwitch(
                                              scope.row,
                                              "s"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("启用")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn_partition",
                                      },
                                      [_vm._v(" / ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "operation-btn",
                                        on: {
                                          click: function ($event) {
                                            return _vm.loginHistory(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v("登录历史")]
                                    ),
                                  ]
                                : undefined
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam.pageNum,
                      "page-size": _vm.pageParam.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.pageParam.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            title: _vm.dialogTitle + "管理员",
            visible: _vm.dialogVisible,
            width: "650px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                    "label-width": "110px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "managerName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "姓名", maxlength: "10" },
                        model: {
                          value: _vm.submitForm.managerName,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "managerName", $$v)
                          },
                          expression: "submitForm.managerName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "手机号", prop: "managerPhoneNumber" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "11", placeholder: "手机号" },
                        model: {
                          value: _vm.submitForm.managerPhoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "managerPhoneNumber", $$v)
                          },
                          expression: "submitForm.managerPhoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "权限组", prop: "rightsGroupIds" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-inherit",
                          attrs: { multiple: "", placeholder: "请选择" },
                          model: {
                            value: _vm.submitForm.rightsGroupIds,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "rightsGroupIds", $$v)
                            },
                            expression: "submitForm.rightsGroupIds",
                          },
                        },
                        _vm._l(_vm.rightsList, function (item) {
                          return _c("el-option", {
                            key: item.groupId,
                            attrs: {
                              label: item.groupName,
                              value: item.groupId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdd,
                          expression: "isAdd",
                        },
                      ],
                      attrs: {
                        prop: _vm.isAdd ? "managerPassword" : "",
                        label: "密码",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "密码",
                          "show-password": "",
                          maxlength: "20",
                          type: "password",
                        },
                        model: {
                          value: _vm.submitForm.managerPassword,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "managerPassword", $$v)
                          },
                          expression: "submitForm.managerPassword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAdd,
                          expression: "isAdd",
                        },
                      ],
                      attrs: {
                        prop: _vm.isAdd ? "managerPasswordConfirm" : "",
                        label: "输入重复密码",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "输入重复密码",
                          "show-password": "",
                          maxlength: "20",
                          type: "password",
                        },
                        model: {
                          value: _vm.submitForm.managerPasswordConfirm,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.submitForm,
                              "managerPasswordConfirm",
                              $$v
                            )
                          },
                          expression: "submitForm.managerPasswordConfirm",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "区域权限", prop: "managerType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-inherit",
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.typeChange },
                          model: {
                            value: _vm.submitForm.managerType,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "managerType", $$v)
                            },
                            expression: "submitForm.managerType",
                          },
                        },
                        _vm._l(_vm.managerTypeList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showType !== 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showType > 0,
                                  expression: "showType > 0",
                                },
                              ],
                              staticClass: "width-164",
                              attrs: { placeholder: "请选择大区" },
                              on: {
                                change: function ($event) {
                                  return _vm.areaChanage($event, 1)
                                },
                              },
                              model: {
                                value: _vm.region,
                                callback: function ($$v) {
                                  _vm.region = $$v
                                },
                                expression: "region",
                              },
                            },
                            _vm._l(_vm.regionList, function (item) {
                              return _c("el-option", {
                                key: item.regionId,
                                attrs: {
                                  label: item.regionName,
                                  value: item.regionId,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showType > 1,
                                  expression: "showType > 1",
                                },
                              ],
                              staticClass: "width-164",
                              attrs: { placeholder: "请选择片区" },
                              on: {
                                change: function ($event) {
                                  return _vm.areaChanage($event, 2)
                                },
                              },
                              model: {
                                value: _vm.district,
                                callback: function ($$v) {
                                  _vm.district = $$v
                                },
                                expression: "district",
                              },
                            },
                            _vm._l(_vm.districtList, function (item) {
                              return _c("el-option", {
                                key: item.districtId,
                                attrs: {
                                  label: item.districtName,
                                  value: item.districtId,
                                },
                              })
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-select",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showType > 2,
                                  expression: "showType > 2",
                                },
                              ],
                              staticClass: "width-164",
                              attrs: { placeholder: "请选择办事处" },
                              model: {
                                value: _vm.agency,
                                callback: function ($$v) {
                                  _vm.agency = $$v
                                },
                                expression: "agency",
                              },
                            },
                            _vm._l(_vm.agencyList, function (item) {
                              return _c("el-option", {
                                key: item.agencyId,
                                attrs: {
                                  label: item.agencyName,
                                  value: item.agencyId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isAdd,
                          expression: "!isAdd",
                        },
                      ],
                      attrs: { label: "产品权限", prop: "managerType" },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.openProduct()
                            },
                          },
                        },
                        [_vm._v("管理产品权限")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.historyTitle,
            visible: _vm.dialogHistory,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogHistory = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "登录时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.daterange,
                      callback: function ($$v) {
                        _vm.daterange = $$v
                      },
                      expression: "daterange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleQueryForm },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.dataTable, border: "", height: "400px" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "60", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            (_vm.historyParam.pageNum - 1) *
                              _vm.historyParam.pageSize +
                              (scope.$index + 1)
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "登录时间",
                  prop: "loginTime",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "ip", prop: "ip", align: "center" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "登录人员",
                  prop: "managerName",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "浏览器型号",
                  prop: "browser",
                  align: "center",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "登录平台",
                  prop: "loginPlatform",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.historyParam.pageNum,
                  "page-size": _vm.historyParam.pageSize,
                  "page-sizes": [10, 20, 50, 100, 200, 1000],
                  total: _vm.historyParam.total,
                  background: "",
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "size-change": _vm.handleSizeHistory,
                  "current-change": _vm.handleCurrentHistory,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogHistory = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogHistory = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "管理员邮箱列表",
            visible: _vm.eMailListVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.eMailListVisible = $event
            },
          },
        },
        [
          _c(
            "el-button",
            {
              staticStyle: { margin: "20px 0" },
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.addEMailVisible = true
                },
              },
            },
            [_vm._v("添加邮箱地址")]
          ),
          _vm._v(" "),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.emailLoading,
                  expression: "emailLoading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: { data: _vm.EMailDataSource, border: "", height: "400px" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", width: "60", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          _vm._s(
                            (_vm.eMailListParam.pageNum - 1) *
                              _vm.eMailListParam.pageSize +
                              (scope.$index + 1)
                          )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "管理员",
                  prop: "createBy",
                  align: "center",
                  "min-width": "100",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "邮箱地址",
                  prop: "email",
                  align: "center",
                  "min-width": "180",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "添加时间",
                  prop: "createTime",
                  align: "center",
                  "min-width": "160",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "operation-btn",
                            on: {
                              click: function ($event) {
                                return _vm.delEMail(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.eMailListVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.eMailListVisible = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增邮箱",
            visible: _vm.addEMailVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addEMailVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "邮箱" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入", clearable: "" },
                    model: {
                      value: _vm.emailInfo,
                      callback: function ($$v) {
                        _vm.emailInfo = $$v
                      },
                      expression: "emailInfo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { attrs: { slot: "footer", align: "center" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addEMailVisible = false
                    },
                  },
                },
                [_vm._v("关 闭")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.addEMailChange },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-table", {
        ref: "productTable",
        attrs: {
          "dialog-show": _vm.productDialog,
          "dialog-visible": _vm.productDialog,
          title: _vm.productDialogTitle,
          "page-type": "system",
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.productDialog = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.productDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }