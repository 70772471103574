<template>
  <div class="d-preview">
    <el-dialog :title="title" :visible.sync="dialogVisible" :modal="false" width="500px" @open="open" @close="closeDialog">
      <div class="popup_content">
        <el-form :model="formData" label-width="90px">
          <el-form-item v-if="showRegion && userInfo.managerType != 'agency'" label="区域">
            <el-cascader
              v-if="$public.isAllAreaPower()"
              ref="cascaderAddr"
              v-model="formData.areaId"
              :props="addressProps"
              :options="addressList"
              clearable
              placeholder="请选择地址"
              @change="handleChangeArea"
            />
            <d-area v-else :values.sync="formData.areaId" />
          </el-form-item>

          <el-form-item v-if="showOrderType" label="订单类型">
            <el-select v-model="formData.orderStatus" class="form-select" placeholder="请选择" clearable @change="changeOrderStatus">
              <el-option-group v-for="group in orderStatusList" :key="group.name" :label="group.name">
                <el-option v-for="item in group.options" :key="item.value" :label="item.name" :value="item.value" />
              </el-option-group>
            </el-select>
          </el-form-item>

          <el-form-item label="下单时间">
            <el-date-picker
              v-model="datetimerange"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeDaterange('orderDate', $event)"
            />
          </el-form-item>
          <el-form-item label="发货时间">
            <el-date-picker
              v-model="deliveryDaterange"
              :disabled="showOrderType && !(formData.orderStatus === 'pending_receipt' || formData.orderStatus === 'completed')"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changeDaterange('deliveryDate', $event)"
            />
          </el-form-item>

          <el-form-item label="">
            <div class="flex-box">
              <el-cascader v-model="categoryId" :props="categoryProps" :options="productTypeList" clearable @change="categoryChange" />
              <el-select
                v-show="productList.length > 0"
                v-model="formData.productIds"
                multiple
                placeholder="请选择产品"
                class="width-260"
                @change="changeProduct"
              >
                <el-option v-for="item in productList" :key="item.productId" :label="item.productName" :value="item.productId" />
              </el-select>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportFile">导出</el-button>
        <el-button @click="closeDialog">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import dArea from '@/components/d-area'

export default {
  name: 'DExport',
  components: {
    dArea
  },
  props: {
    // 弹窗是否显示
    showDialog: {
      type: Boolean,
      default: false
    },
    // 弹窗标题
    title: {
      type: String,
      default: '订单导出'
    },
    // 产品订单类型
    productOrderType: {
      // 可选值：medicine(医品) electronic(电商品) lease(租赁品) nutrition(营养餐) external(经销品)
      type: String,
      default: ''
    },
    // 是否展示区域选项
    showRegion: {
      type: Boolean,
      default: true
    },
    // 是否展示订单类型选项
    showOrderType: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogVisible: false, // 弹窗
      userInfo: {}, // 用户信息
      // 区域选择下拉配置
      addressProps: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },
      // 区域数据
      addressList: [],

      // 产品选择下拉配置
      categoryProps: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: true
      },
      // 产品分类列表
      productTypeList: [],
      categoryId: '', // 分类id
      datetimerange: [], // 下单时间
      deliveryDaterange: [], // 发货时间
      orderStatusList: [], // 订单类型数据
      productList: [], // 产品列表数据

      // 电商品
      EOrderState: [
        {
          name: '普通订单',
          options: [
            { name: '待付款', value: 'pending_payment' },
            { name: '待发货', value: 'pending_ship' },
            { name: '待收货', value: 'pending_receipt' },
            { name: '已完成', value: 'completed' },
            { name: '已取消', value: 'cancel' }
          ]
        },
        {
          name: '退款订单',
          options: [
            { name: '待审核', value: 'auditing' },
            { name: '待退货', value: 'returning' },
            { name: '待签收', value: 'pending_sign' },
            { name: '已退款', value: 'returned' },
            { name: '未过审', value: 'unaudited' }
          ]
        }
      ],
      // 医品
      MOrderState: [
        {
          name: '普通订单',
          options: [
            { name: '待付款', value: 'pending_payment' },
            { name: '待发货', value: 'pending_ship' },
            { name: '待收货', value: 'pending_receipt' },
            { name: '待自提', value: 'pending_pick' },
            { name: '已完成', value: 'completed' },
            { name: '已取消', value: 'cancel' }
          ]
        },
        {
          name: '退款订单',
          options: [
            { name: '待审核', value: 'auditing' },
            { name: '待退货', value: 'returning' },
            { name: '已退款', value: 'returned' },
            { name: '未过审', value: 'unaudited' },
            { name: '待签收', value: 'pending_sign' }
          ]
        }
      ],
      // 租赁品
      LOrderState: [
        {
          name: '普通订单',
          options: [
            { name: '待付款', value: 'pending_payment' },
            { name: '待发货', value: 'pending_ship' },
            { name: '租赁中', value: 'leased' },
            { name: '已到期', value: 'expired' },
            { name: '已买断', value: 'buyout' },
            { name: '已完成', value: 'completed' },
            { name: '已取消', value: 'cancel' }
          ]
        }
      ],
      // 营养餐
      NOrderState: [
        {
          name: '普通订单',
          options: [
            { name: '待付款', value: 'pending_payment' },
            { name: '待发货', value: 'pending_ship' },
            { name: '待收货', value: 'pending_receipt' },
            { name: '已完成', value: 'completed' },
            { name: '已取消', value: 'cancel' }
          ]
        },
        {
          name: '退款订单',
          options: [
            { name: '待审核', value: 'auditing' },
            { name: '待退货', value: 'returning' },
            { name: '待签收', value: 'pending_sign' },
            { name: '已退款', value: 'returned' },
            { name: '未过审', value: 'unaudited' }
          ]
        }
      ],

      // 表单数据
      formData: {
        areaId: '', // 区域id
        orderStatus: '', // 订单类型
        addressHierarchy: null,
        productIds: [], // 产品id
        productNames: [], // 产品名称数组
        orderTimeBegin: '', // 下单开始时间
        orderTimeEnd: '', // 下单结束时间
        shipTimeBegin: '', // 发货开始时间
        shipTimeEnd: '' // 发货结束时间
      }
    }
  },
  watch: {
    showDialog: {
      immediate: true,
      handler(newVal, oldVal) {
        this.dialogVisible = newVal
      }
    }
  },
  created() {},
  methods: {
    open() {
      this.userInfo = getSessionStorage('userInfo')
      // 打开前先 清除选中的数据
      this.formData = Object.assign({}, this.$options.data().formData)
      this.datetimerange = []
      this.deliveryDaterange = []
      this.categoryId = ''
      this.productList = []

      if (this.$public.isAllAreaPower() && this.showRegion) {
        this.getArea()
      }
      this.getProductType()
      this.getOrderStatus(this.productOrderType)
    },
    // 关闭弹窗
    closeDialog() {
      this.$emit('update:showDialog', false)
    },
    // 获取订单状态
    getOrderStatus(e) {
      const orderStateMap = {
        medicine: this.MOrderState,
        electronic: this.EOrderState,
        lease: this.LOrderState,
        nutrition: this.NOrderState,
        external: this.EOrderState
      }
      this.orderStatusList = orderStateMap[e] || []
    },
    // 选择区域
    handleChangeArea(val) {
      this.$nextTick(() => {
        if (this.$refs['cascaderAddr'].presentText && this.$refs['cascaderAddr'].presentText.length > 0) {
          this.formData.addressHierarchy = (this.$refs['cascaderAddr'].presentText.match(/\//g) || []).length + 1
        } else {
          this.formData.addressHierarchy = null
        }
      })
    },
    // 选择订单类型
    changeOrderStatus(val) {
      // 重置发货时间
      this.deliveryDaterange = []
      this.formData.shipTimeBegin = ''
      this.formData.shipTimeEnd = ''
    },
    // 选择时间
    changeDaterange(type, val) {
      if (type === 'orderDate') {
        // 下单时间
        this.formData.orderTimeBegin = val ? val[0] : ''
        this.formData.orderTimeEnd = val ? val[1] : ''
      } else if (type === 'deliveryDate') {
        // 送货时间
        this.formData.shipTimeBegin = val ? val[0] : ''
        this.formData.shipTimeEnd = val ? val[1] : ''
      }
    },
    // 选择产品分类
    categoryChange(val) {
      this.productList = []
      this.formData.productIds = []
      if (val.length > 1) {
        this.getProductList(val[1])
      }
    },
    // 选择产品
    changeProduct(val) {
      const nameList = []
      val.forEach(item => {
        this.productList.forEach(item2 => {
          if (item === item2.productId) {
            nameList.push(item2.productName)
          }
        })
      })
      this.formData.productNames = nameList
    },
    // 获取区域级联数据
    getArea() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data
      })
    },
    // 获取产品类别
    getProductType() {
      this.$http.requestGet({ url: '/product/category', loading: false }).then(res => {
        this.productTypeList = res.data
      })
    },
    // 根据分类id获取产品列表
    getProductList(id) {
      this.$http.requestGet({ url: '/customer/order/category/' + id, loading: false }).then(res => {
        this.productList = res.data
      })
    },

    // 导出
    exportFile() {
      if (!this.formData.orderTimeBegin && !this.formData.orderTimeEnd && !this.formData.shipTimeBegin && !this.formData.shipTimeEnd) {
        return this.$message.error('请选择订单导出时间范围！')
      }
      this.$emit('exportFiles', { ...this.formData })
    }
  }
}
</script>

<style lang="scss" scoped></style>
