<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="inquire.typeKey" placeholder="请选择" class="width-140" @change="inquire.typeValue = ''">
              <el-option label="产品名称" value="productName" />
              <el-option label="入库单编号" value="produceNo" />
              <el-option label="批次" value="batchNo" />
              <el-option label="仓库名称" value="warehouseName" />
              <el-option label="仓库编号" value="warehouseNo" />
              <el-option label="操作人" value="operatorName" />
            </el-select>
            <el-input v-model="inquire.typeValue" placeholder="" class="width-180" @keyup.enter.native="searchList" />
          </div>
        </el-form-item>

        <el-form-item label="入库时间">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="no" label="生产单编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">{{ scope.row.no }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="allBatchNo" label="批次" min-width="120" show-overflow-tooltip />
            <el-table-column prop="productCount" label="产品数量" min-width="120" show-overflow-tooltip />
            <el-table-column prop="count" label="数量" min-width="120" show-overflow-tooltip />
            <el-table-column prop="productNames" label="产品名称" min-width="200" show-overflow-tooltip />
            <el-table-column prop="warehouse" label="仓库" min-width="200" show-overflow-tooltip />
            <el-table-column prop="operatorName" label="操作人" min-width="120" />
            <el-table-column prop="createTime" label="入库时间" min-width="160" />
            <el-table-column prop="status" label="状态" min-width="120" />
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog title="详情" :visible.sync="dialogVisible" width="80%" top="5vh">
      <div class="Popout_content">
        <el-form class="demo-form-inline" size="medium">
          <el-form-item label="入库仓库">{{ produceOrder.warehouse }}</el-form-item>

          <el-form-item label="入库产品">
            <el-table v-loading="produceOrderLoading" :data="produceOrder.produceRecordDetailItems" border height="400px" style="width: 100%">
              <el-table-column prop="date" label="序号" width="60">
                <template v-slot="scope">
                  {{ scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="skuNo" label="SKU编号" min-width="200" show-overflow-tooltip />
              <el-table-column prop="spuNo" label="SPU编号" min-width="200" show-overflow-tooltip />
              <el-table-column prop="productName" label="产品名称" min-width="200" show-overflow-tooltip />
              <el-table-column prop="productTypeName" label="所属类别" min-width="160" show-overflow-tooltip />
              <el-table-column prop="count" label="入库数量" min-width="120" show-overflow-tooltip />
              <el-table-column prop="batchNo" label="批次" min-width="120" show-overflow-tooltip />
              <el-table-column prop="produceTime" label="生产日期" min-width="160" />
              <el-table-column prop="expireTime" label="到期时间" min-width="160" />
              <el-table-column prop="effectiveDays" label="有效期(天)" min-width="120" />
            </el-table>
          </el-form-item>
          <p style="padding-top: 2px"></p>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 查询参数
      inquire: {
        typeKey: 'productName',
        typeValue: '',
        timeType: 'ruku',
        beginTime: '',
        endTime: ''
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      loading: false,

      // 详情弹窗
      dialogVisible: false,
      produceOrder: {}, // 生产单详情
      produceOrderLoading: false
    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取已入库生产单数据列表
    getTableList() {
      if (!this.daterange) this.daterange = []
      this.inquire.beginTime = this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire.endTime = this.daterange.length > 0 ? this.daterange[1] : ''
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http
        .requestPost({
          url: '/produce/record/page',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
        })
        .catch(err => {
          this.loading = false
          console.log('err', err)
        })
    },

    // 查看详情
    handleDetails(row) {
      this.dialogVisible = true
      this.produceOrderLoading = true
      this.$http
        .requestGet({ url: '/produce/record/get/' + row.id })
        .then(res => {
          this.produceOrderLoading = false
          this.produceOrder = res.data
          this.produceOrder.warehouse = row.warehouse
        })
        .catch(err => {
          this.produceOrderLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-score {
  height: 40px;
  line-height: 40px;
  align-items: center;
}

.img-box {
  width: 32px;
  height: 32px;
}

.img-box-show {
  margin: 0 5px 5px 0;
  cursor: pointer;
}
</style>
