var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { model: _vm.queryParams, size: "medium", inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "营养师名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "请输入营养师名称", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.dietitianName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "dietitianName", $$v)
                      },
                      expression: "queryParams.dietitianName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "营养师编号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "请输入营养师编号", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.dietitianCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "dietitianCode", $$v)
                      },
                      expression: "queryParams.dietitianCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "营养师手机号" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "210px" },
                    attrs: { placeholder: "请输入营养师手机号", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "phoneNumber", $$v)
                      },
                      expression: "queryParams.phoneNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { model: _vm.queryParams, size: "medium", inline: true },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "所属地区" } },
                [
                  _c("el-cascader", {
                    staticStyle: { width: "240px" },
                    attrs: {
                      options: _vm.addressList_add,
                      props: _vm.props_List,
                      clearable: "",
                      placeholder: "请选择地址",
                    },
                    model: {
                      value: _vm.queryParams.regionId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "regionId", $$v)
                      },
                      expression: "queryParams.regionId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "身份" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "240px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.queryParams.dietitianIdentity,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "dietitianIdentity", $$v)
                        },
                        expression: "queryParams.dietitianIdentity",
                      },
                    },
                    _vm._l(_vm.$api.identityListState, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addDietitian },
            },
            [_vm._v("添加营养师")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "", height: "400px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        width: "60",
                        align: "center",
                        type: "index",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "营养师编号",
                        align: "center",
                        prop: "dietitianCode",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "营养师名称",
                        align: "center",
                        prop: "dietitianName",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "营养师身份",
                        align: "center",
                        prop: "dietitianIdentityDesc",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "省份",
                        align: "center",
                        prop: "province",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "城市", align: "center", prop: "city" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "区/县",
                        align: "center",
                        prop: "district",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "状态",
                        align: "center",
                        prop: "serviceStatus",
                        formatter: _vm.formatter,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "手机号",
                        align: "center",
                        prop: "phoneNumber",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属组织",
                        align: "center",
                        prop: "dietitianOrganization",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "添加时间",
                        align: "center",
                        prop: "createTime",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        align: "center",
                        width: "250",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("营养师档案")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleStart(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.serviceStatus == 1
                                        ? "停用"
                                        : "启用"
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  attrs: {
                                    type: "text",
                                    size: "small",
                                    name: "list",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleProduct(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("产品列表")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      tableHeader: _vm.tableHeader,
                      "current-page": _vm.queryParams.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.queryParams.pageSize,
                      layout: " total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.handleClose,
            title: _vm.title,
            "destroy-on-close": "",
            visible: _vm.showDialog,
            width: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          this.title == "变更客服"
            ? _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "营养师名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "营养师名称" },
                        model: {
                          value: _vm.queryParams.dietitianName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryParams,
                              "dietitianName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryParams.dietitianName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "营养师编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "营养师编号" },
                        model: {
                          value: _vm.queryParams.dietitianCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.queryParams,
                              "dietitianCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "queryParams.dietitianCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属区域" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "240px" },
                        attrs: {
                          options: _vm.addressList_add,
                          props: _vm.props_List,
                          clearable: "",
                          placeholder: "请选择",
                        },
                        model: {
                          value: _vm.queryParams.regionId,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "regionId", $$v)
                          },
                          expression: "queryParams.regionId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.handleQuery },
                        },
                        [_vm._v("查找")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          this.title == "选择营养师"
            ? _c(
                "el-form",
                { attrs: { inline: true } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "营养师名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "营养师名称" },
                        model: {
                          value: _vm.formData.dietitianName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "dietitianName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.dietitianName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "营养师编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "营养师编号" },
                        model: {
                          value: _vm.formData.dietitianCode,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "dietitianCode",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.dietitianCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属区域" } },
                    [
                      _c("el-cascader", {
                        staticStyle: { width: "240px" },
                        attrs: {
                          options: _vm.addressList_add,
                          props: _vm.props_List,
                          clearable: "",
                          placeholder: "请选择",
                        },
                        model: {
                          value: _vm.formData.regionId,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "regionId", $$v)
                          },
                          expression: "formData.regionId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.handleQuery_1 },
                        },
                        [_vm._v("查找")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-table",
            {
              ref: "mainTable",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                "row-key": _vm.getRowKey,
                "highlight-current-row": "",
                data: _vm.tableList,
                height: "350",
                border: "",
                "header-cell-style": {
                  background: "#409eff",
                  color: "#ffffff",
                },
              },
              on: {
                "row-click": _vm.handleRowClick,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "",
                  align: "center",
                  width: "55",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-radio",
                          {
                            attrs: { label: scope.row.id },
                            on: {
                              change: function ($event) {
                                return _vm.changeRedio($event, scope.row)
                              },
                            },
                            model: {
                              value: _vm.currentRowId,
                              callback: function ($$v) {
                                _vm.currentRowId = $$v
                              },
                              expression: "currentRowId",
                            },
                          },
                          [
                            _c("span", {
                              staticStyle: { "margin-left": "-10px" },
                            }),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  width: "60",
                  align: "center",
                  type: "index",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "营养师编号",
                  align: "center",
                  prop: "dietitianCode",
                  width: "200",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "营养师名称",
                  align: "center",
                  prop: "dietitianName",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: {
                  label: "营养师身份",
                  align: "center",
                  prop: "dietitianIdentityDesc",
                },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "省份", align: "center", prop: "province" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "城市", align: "center", prop: "city" },
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "区/县", align: "center", prop: "district" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          this.title == "选择营养师"
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      tableHeader: _vm.tableHeader,
                      "current-page": _vm.formData.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.formData.pageSize,
                      layout: " total, sizes, prev, pager, next, jumper",
                      total: _vm.total_2,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_2,
                      "current-change": _vm.handleCurrentChange_2,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          this.title == "变更客服"
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      tableHeader: _vm.tableHeader,
                      "current-page": _vm.queryParams.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.queryParams.pageSize,
                      layout: " total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitDie } },
                [_vm._v(_vm._s(_vm.title == "变更客服" ? "变更" : "添加"))]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "产品列表",
            "before-close": _vm.handleClose_1,
            "destroy-on-close": "",
            visible: _vm.showProDialog,
            width: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showProDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "产品名称" },
                        model: {
                          value: _vm.productData.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.productData, "productName", $$v)
                          },
                          expression: "productData.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "产品编号" },
                        model: {
                          value: _vm.productData.productCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.productData, "productCode", $$v)
                          },
                          expression: "productData.productCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品类别" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.productData.productClassify,
                            callback: function ($$v) {
                              _vm.$set(_vm.productData, "productClassify", $$v)
                            },
                            expression: "productData.productClassify",
                          },
                        },
                        _vm._l(_vm.$api.orderClassify, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleQuery_2 },
                        },
                        [_vm._v("查找")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addProduct },
                        },
                        [_vm._v("添加产品")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "proTable",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    data: _vm.productList,
                    height: "350",
                    border: "",
                    "header-cell-style": {
                      background: "#409eff",
                      color: "#ffffff",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "",
                      align: "center",
                      type: "index",
                      prop: "productCode",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编号",
                      align: "center",
                      prop: "productCode",
                      width: "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      align: "center",
                      prop: "productName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "分类",
                      align: "center",
                      prop: "productClassify",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.$api.getValue(
                                  _vm.$api.orderClassify,
                                  scope.row.productClassify
                                )
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属类别",
                      align: "center",
                      prop: "productCategoryName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.proDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "operation-btn_partition" },
                              [_vm._v(" / ")]
                            ),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delProduct(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                            _vm._v(" "),
                            scope.row.productClassify === "medicine"
                              ? _c(
                                  "span",
                                  { staticClass: "operation-btn_partition" },
                                  [_vm._v("\n              /\n            ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.productClassify === "medicine"
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleBusiness(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("商家列表")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.productData.pageNum,
                      "page-size": _vm.productData.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.total_1,
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_1,
                      "current-change": _vm.handleCurrentChange_1,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose_1 } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleClose_1 },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加产品",
            "before-close": _vm.handleClose_4,
            "destroy-on-close": "",
            visible: _vm.showproductDialog,
            width: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showproductDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "产品名称" },
                        model: {
                          value: _vm.addProductData.productName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addProductData,
                              "productName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addProductData.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品编号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "产品编号" },
                        model: {
                          value: _vm.addProductData.productCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.addProductData, "productCode", $$v)
                          },
                          expression: "addProductData.productCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品类别" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.addProductData.productClassify,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.addProductData,
                                "productClassify",
                                $$v
                              )
                            },
                            expression: "addProductData.productClassify",
                          },
                        },
                        _vm._l(_vm.$api.orderClassify, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleQuery_4 },
                        },
                        [_vm._v("查找")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "proTable",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    data: _vm.addproductList,
                    height: "350",
                    border: "",
                    "header-cell-style": {
                      background: "#409eff",
                      color: "#ffffff",
                    },
                  },
                  on: { "selection-change": _vm.handleSelectionChange_1 },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "",
                      align: "center",
                      type: "index",
                      prop: "productCode",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编号",
                      align: "center",
                      prop: "productCode",
                      width: "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      align: "center",
                      prop: "productName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "分类",
                      align: "center",
                      prop: "productClassify",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                _vm.$api.getValue(
                                  _vm.$api.orderClassify,
                                  scope.row.productClassify
                                )
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属类别",
                      align: "center",
                      prop: "productCategoryName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.proDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.addProductData.pageNum,
                      "page-size": _vm.addProductData.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.total_4,
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_4,
                      "current-change": _vm.handleCurrentChange_4,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose_4 } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.determine } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商家管理",
            "before-close": _vm.handleClose_2,
            visible: _vm.showbusDialog,
            width: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showbusDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { label: "商家名称" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商家名称" },
                        model: {
                          value: _vm.businessDate.merchantName,
                          callback: function ($$v) {
                            _vm.$set(_vm.businessDate, "merchantName", $$v)
                          },
                          expression: "businessDate.merchantName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { label: "商家编号" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商家编号" },
                        model: {
                          value: _vm.businessDate.merchantCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.businessDate, "merchantCode", $$v)
                          },
                          expression: "businessDate.merchantCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "350px" },
                      attrs: { label: "所属地区" },
                    },
                    [
                      _vm.$public.isAllAreaPower()
                        ? _c("el-cascader", {
                            attrs: {
                              props: _vm.props,
                              options: _vm.addressList,
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.businessDate.agencyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.businessDate, "agencyId", $$v)
                              },
                              expression: "businessDate.agencyId",
                            },
                          })
                        : _c("d-area", {
                            attrs: { values: _vm.businessDate.areaId },
                            on: {
                              "update:values": function ($event) {
                                return _vm.$set(
                                  _vm.businessDate,
                                  "areaId",
                                  $event
                                )
                              },
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleQuery_3 },
                        },
                        [_vm._v("查找")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addbusines },
                        },
                        [_vm._v("添加商家")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "proTable",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    data: _vm.businessList,
                    height: "350",
                    border: "",
                    "header-cell-style": {
                      background: "#409eff",
                      color: "#ffffff",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      align: "center",
                      type: "index",
                      prop: "productId",
                      width: "60",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商家编号",
                      align: "center",
                      prop: "merchantCode",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商家名称",
                      align: "center",
                      prop: "merchantName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "大区",
                      align: "center",
                      prop: "regionName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "片区",
                      align: "center",
                      prop: "districtName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "办事处",
                      align: "center",
                      prop: "agencyName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { fixed: "right", label: "操作", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.delBus(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.businessDate.pageNum,
                      "page-size": _vm.businessDate.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.total_3,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_3,
                      "current-change": _vm.handleCurrentChange_3,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose_2 } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleClose_2 },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加商家",
            "before-close": _vm.handleClose_5,
            visible: _vm.showbusinDialog,
            width: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showbusinDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { label: "商家名称" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商家名称" },
                        model: {
                          value: _vm.addBusinessDate.merchantName,
                          callback: function ($$v) {
                            _vm.$set(_vm.addBusinessDate, "merchantName", $$v)
                          },
                          expression: "addBusinessDate.merchantName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "400px" },
                      attrs: { label: "商家编号" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商家编号" },
                        model: {
                          value: _vm.addBusinessDate.merchantCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.addBusinessDate, "merchantCode", $$v)
                          },
                          expression: "addBusinessDate.merchantCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "350px" },
                      attrs: { label: "所属地区" },
                    },
                    [
                      _vm.$public.isAllAreaPower()
                        ? _c("el-cascader", {
                            attrs: {
                              props: _vm.props,
                              options: _vm.addressList,
                              clearable: "",
                              placeholder: "请选择",
                            },
                            model: {
                              value: _vm.addBusinessDate.agencyId,
                              callback: function ($$v) {
                                _vm.$set(_vm.addBusinessDate, "agencyId", $$v)
                              },
                              expression: "addBusinessDate.agencyId",
                            },
                          })
                        : _c("d-area", {
                            attrs: { values: _vm.addBusinessDate.areaId },
                            on: {
                              "update:values": function ($event) {
                                return _vm.$set(
                                  _vm.addBusinessDate,
                                  "areaId",
                                  $event
                                )
                              },
                            },
                          }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleQuery_5 },
                        },
                        [_vm._v("查找")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "proTable",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    data: _vm.addbusinesList,
                    height: "350",
                    border: "",
                    "header-cell-style": {
                      background: "#409eff",
                      color: "#ffffff",
                    },
                  },
                  on: { "selection-change": _vm.handleSelectionChange_2 },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      align: "center",
                      type: "index",
                      prop: "productId",
                      width: "60",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商家编号",
                      align: "center",
                      prop: "merchantCode",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商家名称",
                      align: "center",
                      prop: "merchantName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "大区",
                      align: "center",
                      prop: "regionName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "片区",
                      align: "center",
                      prop: "districtName",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "办事处",
                      align: "center",
                      prop: "agencyName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.addBusinessDate.pageNum,
                      "page-size": _vm.addBusinessDate.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.total_5,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_5,
                      "current-change": _vm.handleCurrentChange_5,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleClose_5 } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitbus } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }