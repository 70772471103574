<template>
    <div class="d-preview">
        <el-dialog :title="title" :visible.sync="showDialog" :modal="false" :before-close="close" width="1200px">
            <div class="Popout_content">
                <el-form :inline="true" class="demo-form-inline" size="medium">
                    <el-form-item label="增加时间">
                        <div class="flex-box">
                            <el-date-picker
                                v-model="daterange"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                            />
                        </div>
                    </el-form-item>

                    <el-form-item label="">
                        <div class="flex-box">
                            <el-select
                                v-model="selectKey"
                                placeholder="请选择"
                                @change="selectValue = ''"
                                class="width-140"
                            >
                                <el-option label="医院名称" value="hospitalName"></el-option>
                                <el-option label="医院编号" value="hospitalCode"></el-option>
                                <!-- <el-option label="所属商家" value="merchantName"></el-option> -->
                            </el-select>
                            <el-input v-model="selectValue" placeholder="" class="width-180"></el-input>
                        </div>
                    </el-form-item>

                    <el-button type="primary" @click="exportFile">导出</el-button>
                </el-form>

                <div class="table">
                    <el-main>
                        <template>
                            <el-table
                                ref="tables"
                                v-loading="loading"
                                :data="tableData"
                                border
                                height="400px"
                                row-key="orderId"
                                style="width: 100%"
                            >
                                <el-table-column prop="date" label="序号" width="60">
                                    <template v-slot="scope">{{
                                        (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
                                    }}</template>
                                </el-table-column>
                                <el-table-column label="文件名" min-width="100" prop="fileName" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column label="文件地址" min-width="220" prop="fileUrl" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column label="导出时间" min-width="140" prop="createTime"></el-table-column>
                                <el-table-column label="状态" min-width="100" prop="">
                                    <template slot-scope="scope">
                                        {{ scope.row.statusValue }}
                                    </template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" min-width="120" prop="">
                                    <template slot-scope="scope">
                                        <span class="operation-btn" v-if="scope.row.status == 'KXZ'"
                                            ><a :href="scope.row.fileUrl" download>下载</a></span
                                        >
                                        <span class="operation-btn_partition" v-if="scope.row.status == 'KXZ'">
                                            /
                                        </span>

                                        <span class="operation-btn" @click="handleDelete(scope.row)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                        <div class="pagination">
                            <el-pagination
                                :current-page="pageParam.pageNum"
                                :page-size="pageParam.pageSize"
                                :page-sizes="$api.pageSizes"
                                :total="pageParam.total"
                                background
                                layout="total, sizes, prev, pager, next, jumper"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                            ></el-pagination>
                        </div>
                    </el-main>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getSessionStorage } from "@/utils/auth";
import { hospitalExportHospital1, deleteLogic } from "@/api/order";
import dArea from "@/components/d-area";

export default {
    name: "DExport",
    components: {
        dArea
    },
    props: {
        showDialog: {
            // 是否显示
            type: Boolean,
            default: false
        },
        title: {
            // 标题
            type: String,
            default: "订单导出"
        },
        productType: {
            // 产品类型
            type: String,
            default: ""
        }
    },
    data() {
        return {
            // 分页
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            tableData: [],
            // 查询
            daterange: [], // 增加时间
            inquire: {
                createTimeBegin: "",
                createTimeEnd: "",
                hospitalName: "",
                hospitalCode: ""
            },
            selectKey: "hospitalName",
            selectValue: "",
            loading: false,
            dialogVisible: false // 弹窗
        };
    },
    created() {
        this.inquire.type = this.productType;
        let page = getSessionStorage("completedPage");
        if (page && Object.keys(page).length > 0) {
            this.pageParam.pageNum = page.pageNum;
            this.pageParam.pageSize = page.pageSize;
        }
    },
    methods: {
        open() {
            this.clearData();
            this.getTableData();
        },
        clearData() {
            this.inquire.createTimeBegin = "";
            this.inquire.createTimeEnd = "";
            this.tableData = [];
            this.daterange = [];
            this.inquire.hospitalName = "";
            this.inquire.hospitalCode = "";
        },
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size;
            this.getTableData();
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page;
            this.getTableData();
        },
        // 导出
        async exportFile() {
            if (!this.daterange) this.daterange = [];
            this.inquire.hospitalName = "";
            this.inquire.hospitalCode = "";
            this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : "";
            this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : "";
            this.inquire[this.selectKey] = this.selectValue
            const param = JSON.parse(JSON.stringify(this.inquire));
            hospitalExportHospital1(param, "get").then(res => {
                //   this.$message.success("导出任务已经启动，请稍后到[导出文件列表]中下载文件");
                this.$public.downloadFile(res);
                this.$message.success("导出成功！");
                  setTimeout(() => {
                      this.clearData;
                      this.getTableData();
                  }, 200);
              })
            
        },

        // 关闭弹窗
        close() {
            this.$emit("update:close", false);
        },
        // 获取列表数据
        getTableData() {
            console.log(this.inquire);
            const param = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: JSON.parse(JSON.stringify(this.inquire))
            };
            param.param[this.selectKey] = this.selectValue;
            this.loading = true;
            this.$http
                .requestPost({
                    url: "/exportfile/page",
                    param: param,
                    loading: false
                })
                .then(res => {
                    this.loading = false;
                    console.log("list", res.data.list);
                    this.tableData = res.data.list;
                    this.pageParam.total = res.data.total;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        },
        // 文件删除
        handleDelete(row){
            this.$confirm('确定删除该文件?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    deleteLogic(row.id).then(res => {
                        this.$message.success('删除成功')
                        this.getTableData()
                    })
                }).catch(() => {});
            
        }
    }
};
</script>

<style lang="scss" scoped></style>

