<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="selectKey" placeholder="请选择" class="width-140" clearable>
              <el-option label="出库单编号" value="no"></el-option>
              <el-option label="批次" value="batchNo"></el-option>
              <el-option label="库存" value="warehouseName"></el-option>
              <el-option label="操作人" value="managerName"></el-option>
            </el-select>
            <el-input v-model="selectValue" placeholder="" class="width-180" @keyup.enter.native="searchList"></el-input>
          </div>
        </el-form-item>


        <el-form-item label="出库时间">
          <el-date-picker v-model="daterange" end-placeholder="结束日期" format="yyyy-MM-dd" start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="500px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60" align="center">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="no" label="出库单编号" min-width="140" align="center"></el-table-column>
            <el-table-column prop="batchNo" label="批次" min-width="140" align="center"></el-table-column>
            <el-table-column prop="productCount" label="产品数量" min-width="140" align="center"></el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="140" align="center"></el-table-column>
            <el-table-column prop="outboundCount" label="数量" min-width="100" align="center"></el-table-column>
            <el-table-column prop="warehouseName" label="仓库" min-width="140" align="center"></el-table-column>
            <el-table-column prop="managerName" label="操作人" min-width="140" align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" min-width="140" align="center"></el-table-column>
            <el-table-column prop="createTime" label="出库时间" min-width="140" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" min-width="140" align="center">
              <template slot-scope="scope">
                {{scope.row.status == 0 ? '已出库' : ''}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span> 
                <!-- <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleOutbound(scope.row)">出库</span>  -->
              </template>
            </el-table-column>
          </el-table>
          
          <div class="pagination">
            <el-pagination background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
          </div>
        </template>
      </el-main>
    </div>

    <!-- 详情弹框 -->
    <el-dialog
    title="详情"
    :visible.sync="dialogVisible"
    width="80%"
    top="5vh">
      <div class="Popout_content">
         <el-form class="demo-form-inline" ref="submitForm" :model="submitForm"  size="medium">
          <el-form-item label="出库仓库" prop="remark" :required="true" label-width="200">{{submitForm.warehouseName}}</el-form-item>
          <el-form-item label="备注" prop="remark" label-width="200">{{submitForm.remark}}</el-form-item>

          <el-form-item label="出库产品" :required="true" prop="batchItems">
            <el-table :data="submitForm.data" v-loading="loading" border height="400px" style="width: 100%">
                <el-table-column prop="date" label="序号" width="60" align="center">
                  <template v-slot="scope">{{( pageParam_1.pageNum - 1) * pageParam_1.pageSize + (scope.$index + 1) }}</template>
                </el-table-column>
                <el-table-column prop="inventoryId" label="SKU编号" min-width="140" align="center"></el-table-column>
                <el-table-column prop="productCode" label="SPU编号" min-width="140" align="center"></el-table-column>
                <el-table-column prop="productName" label="产品名称" min-width="140" align="center"></el-table-column>
                <el-table-column prop="productCategoryName" label="所属类别" min-width="100" align="center"></el-table-column>
                <el-table-column prop="batchNo" label="批次" min-width="140" align="center"></el-table-column>
                <el-table-column prop="produceTime" label="生产日期" min-width="140" align="center"></el-table-column>
                <el-table-column prop="expireTime" label="到期时间" min-width="140" align="center"></el-table-column>
                <el-table-column prop="count" label="出库数量" min-width="140" align="center"></el-table-column>
              </el-table>
              
              <div class="pagination">
                <el-pagination background :current-page="pageParam_1.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam_1.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam_1.total" @size-change="handleSizeChange_1" @current-change="handleCurrentChange_1" />
              </div>
          </el-form-item>
        </el-form>

        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>

  </div>
</template>

<script>
import dArea from '@/components/d-area'
import {getSessionStorage} from '@/utils/auth.js'
export default {
  components: {
    dArea
  },
  data() {
    return {
      // 产品分类
      productTypeList: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        // checkStrictly: true, 
        emitPath: true
      },
      categoryId: '',
      productList: [],
      selectKey:'no',
      selectValue:'',
      // 查询参数
      inquire: {
        no: "",
        batchNo: "",
        startTime: "",
        endTime: "",
        managerName: "",
        warehouseName: ""
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [], 
      commentId: '',// 主键id



      pageParam_1: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      submitForm:{
        warehouseName:'',
        remark:'',
        data:[]
      },
      tableData_1: [], 
      dialogVisible: false, // 出库弹窗
      // // 出库
      // detailInfo: {
      //   items: []
      // },

      // // 片区
      // addressList: [],
      // props_1: {
      //   label: 'areaName',
      //   value: 'areaId',
      //   children: 'childes',
      //   checkStrictly: true,
      //   emitPath: false
      // },
      
    };
  },
  created() {
    this.getProductType();
    // 获取数据列表
    this.getTableList();
    
    this.getDistrictIdList();
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.clearable()
      this.getTableList()
    },
    clearable(){
      this.pageParam.pageNum = 1;
      this.tableData = [];
      this.submitForm.data = [];
    },
    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.startTime =  this.daterange.length > 0 ? this.daterange[0] + ' 00:00:00' : '';
      this.inquire.endTime = this.daterange.length > 0 ? this.daterange[1] + ' 00:00:00': '';
      this.inquire[this.selectKey] = this.selectValue;
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/batchOutbound/getList',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log('err', err)
      })
    },
    // 获取产品类型
    getProductType() {
      this.$http.requestGet({ url: '/product/category', loading: false }).then(res => {
        this.productTypeList = res.data;
      })
    },
    // 产品分类改变
    categoryChange(e) {
      this.productList = []
      this.inquire.productIds = []
      if (e.length > 1) {
        this.getProductList(e[1])
      }
    },
    // 根据分类id获取产品列表
    getProductList(id) {
      this.$http.requestGet({ url: '/customer/order/category/' + id, loading: false }).then(res => {
        this.productList = res.data
      })
    },

    // 页容量改变
    handleSizeChange_1(size) {
      this.pageParam_1.pageNum = 1;
      this.pageParam_1.pageSize = size;
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange_1(page) {
      this.pageParam_1.pageNum = page;
      // 刷新(页码)
      this.clearable();
      this.handleDetails();
    },
    // 详情
    handleDetails(row) {
      this.dialogVisible = true;
      let userInfo = getSessionStorage('userInfo');
      console.log(userInfo)
      console.log(row)
      this.submitForm.warehouseName = row.warehouseName;
      this.submitForm.remark = row.remark;
      this.inquire.managerName = userInfo.managerName;
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/batchOutbound/getDetail/' + row.id,
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.submitForm.data = res.data.list;
        this.pageParam_1.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log('err', err)
      })
    },

    // 获取区域级联列表
    getDistrictIdList() {
        this.$http.requestGet({url: '/operation/area/agency/tree', loading: false}).then(res => {
            this.addressList = res.data;
        })
    },

  },
};
</script>

<style lang="scss" scoped>
  .table-box {
    display: initial
  }

  .img-box {
    width: 32px;
    height: 32px;
  }
</style>
