<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="">
          <el-date-picker
            v-model="inquire.year"
            type="year"
            value-format="yyyy"
            placeholder="年份">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="">
          <el-select v-model="inquire.month" placeholder="月份" clearable>
            <el-option v-for="item in monthList" :key="item.id" :label="item.name"  :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="区域等级" v-if="$public.isAllAreaPower()">
          <el-select v-model="inquire.regionType" placeholder="请选择">
            <el-option label="大区"  value="region_id"></el-option>
            <el-option label="分区"  value="district_id"></el-option>
            <el-option label="办事处"  value="agency_id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary">导出报表</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="regionName" label="大区名称" min-width="120" v-if="regionType === 'region_id'"></el-table-column>
            <el-table-column prop="districtName" label="分区名称" min-width="120" v-if="regionType === 'district_id'"></el-table-column>
            <el-table-column prop="agencyName" label="办事处名称" min-width="120" v-if="regionType === 'agency_id'"></el-table-column>
            <el-table-column prop="orderTotalPrice" label="销售金额" min-width="100">
              <template slot-scope="scope">{{ '￥' + scope.row.orderTotalPrice.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="count" label="订单数量" min-width="120"></el-table-column>
            <el-table-column prop="memberCount" label="交易会员数" min-width="120"></el-table-column>
            <!-- <el-table-column prop="amount" label="提成金额" min-width="120">
              <template slot-scope="scope">{{ '￥' + scope.row.amount.toFixed(2) }}</template>
            </el-table-column> -->
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="details(scope.row)">交易明细</span> 
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        year: new Date().getFullYear().toString(),
        month: new Date().getMonth() +1,
        regionType: 'region_id',
      },
      regionType: 'region_id',

      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [], 
      monthList:[
        { name: '1月', id: 1 },
        { name: '2月', id: 2 },
        { name: '3月', id: 3 },
        { name: '4月', id: 4 },
        { name: '5月', id: 5 },
        { name: '6月', id: 6 },
        { name: '7月', id: 7 },
        { name: '8月', id: 8 },
        { name: '9月', id: 9 },
        { name: '10月', id: 10 },
        { name: '11月', id: 11 },
        { name: '12月', id: 12 },
      ],

    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      var time = [];
      if (this.inquire.year) {
        if (this.inquire.month) {
          time = this.$public.getAssignMonth(this.inquire.year + '-' + this.$public.disposeDate(this.inquire.month) + '-01')
        } else {
          time = [this.inquire.year + '-01-01', this.inquire.year + '-12-31']
        }
      } else {
        this.inquire.year = new Date().getFullYear()
        time = [this.inquire.year + '-01-01', this.inquire.year + '-12-31']
      }
      var param = {
        payTime: time[0] + ' 00:00:00',
        payTimeEnd: time[1] + ' 23:59:59',
        regionType: this.inquire.regionType
      }
      this.regionType = this.inquire.regionType
      this.loading = true;
      this.$http.requestPost({
        url: '/wait/payment/merchant/commission/comprehensive/regionStatisticsList?pageNum=' + this.pageParam.pageNum + '&pageSize=' + this.pageParam.pageSize,
        param,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.data.list;
        this.pageParam.total = res.data.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    
    // 付款
    details(row) {
      let id = 0;
      if (this.regionType == 'region_id') {
        id = row.regionId
      } else if (this.regionType == 'district_id') {
        id = row.districtId
      } else if (this.regionType == 'agency_id') { 
        id = row.agencyId
      }
      
      this.$router.push({
        path:'/financial/financialStatement/regionalSalesStatisticsDetail',
        query: {
          regionId: id,
          regionType: this.regionType
        }
      })
    },

    // 添加，修改提交
    submit(param) {
      this.$http.requestPost({
        url: '',
        param: param
      }).then(res => {
        this.getTableList()
        this.dialogVisible = false;
        this.$message({
          message: '操作成功',
          type: 'success'
        })
      })
    },

  },
};
</script>

<style lang="scss" scoped>
  .address-list {
    flex-wrap: wrap;
    
    & > div {
      display: inline-flex;
    }
  }
  .text {
    padding: 0 5px;
  }
  .text-left {
    min-width: 125px;
    text-align: right;
  }
</style>
