<template>
  <!-- 二维码列表 -->
  <div class="main-box qrcode_list">
    <!-- 搜索 -->
    <div class="head-search-row search_box">
      <el-form class="demo-form-inline" :inline="true" size="medium">
        <el-form-item label="业务员姓名">
          <el-input v-model="queryData.intendantName" placeholder="业务员姓名" clearable @keyup.enter.native="updateList('qrcodeTable')" />
        </el-form-item>
        <el-form-item v-if="!thirdPartyPermissions" label="所属区域">
          <el-cascader
            v-if="$public.isAllAreaPower()"
            v-model="queryData.areaId"
            :props="addressConfigure"
            :options="addressList"
            clearable
            placeholder="请选择地址"
          />
          <d-area v-else :values.sync="queryData.areaId" />
        </el-form-item>
        <el-form-item v-if="!thirdPartyPermissions" label="商家端名称">
          <el-input v-model="queryData.merchantName" placeholder="商家端名称" clearable @keyup.enter.native="updateList('qrcodeTable')" />
        </el-form-item>
        <el-form-item label="状态">
          <div style="width: 120px">
            <el-select v-model="queryData.qrCodeStatus" clearable placeholder="请选择">
              <el-option label="启用" :value="1" />
              <el-option label="禁用" :value="2" />
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="业务员编号">
          <el-input v-model="queryData.intendantCode" placeholder="业务员编号" clearable @keyup.enter.native="updateList('qrcodeTable')" />
        </el-form-item>
        <el-form-item label="二维码编号">
          <el-input v-model="queryData.qrCode" placeholder="二维码编号" clearable @keyup.enter.native="updateList('qrcodeTable')" />
        </el-form-item>
        <el-form-item label="领取状态">
          <div style="width: 120px">
            <el-select v-model="queryData.claimStatus" clearable placeholder="请选择">
              <el-option label="已领取" :value="1" />
              <el-option label="未领取" :value="2" />
            </el-select>
          </div>
        </el-form-item>
      </el-form>
      <div style="margin-bottom: 20px">
        <el-button size="small" type="primary" @click="updateList('qrcodeTable')">查询</el-button>
        <el-button size="small" type="primary" @click="operateButton('modify_qrcode')">新增</el-button>
        <el-button size="small" type="primary" @click="operateButton('download_qrcode')">下载二维码</el-button>
        <el-button size="small" type="primary" @click="operateMerchants(2)">更换商家</el-button>
        <el-button size="small" type="primary" @click="operateButton('export_table')">导出</el-button>
      </div>
    </div>
    <!-- 表格 -->
    <div class="table_box">
      <TableList
        ref="qrcodeTable"
        class="tableList"
        :table-header="tableData.tableHeader"
        :is-selection="true"
        row-key-name="qrCodeId"
        :table-data="tableData.list"
        :total="tableData.total"
        @setPage="pageChange($event, 'qrcodeTable')"
        @setSize="sizeChange($event, 'qrcodeTable')"
        @delXuan="selectionChange"
      >
        <template #qrCodeSalePrice="{data}">
          <span>{{ data.qrCodeSalePrice ? "￥" + data.qrCodeSalePrice : "-" }}</span>
        </template>
        <template #qrCodeStatus="{data}">
          <span>{{ data.qrCodeStatus == 1 ? "启用" : "禁用" }}</span>
        </template>
        <template #claimStatus="{data}">
          <span>{{ data.claimStatus }}</span>
        </template>
        <template #qrCodeUserNum="{data}">
          <span>{{ data.qrCodeUserNum || 0 }}</span>
        </template>
        <template #operation="{data}">
          <el-button class="operate_btn" type="text" @click="operateButton('modify_qrcode', data)">二维码</el-button>
          <el-button class="operate_btn" type="text" @click="operateButton('sales_records', data)">销售记录</el-button>
          <el-button class="operate_btn" type="text" @click="operateButton('member_list', data)">会员列表</el-button>
          <template v-if="userInfo.rightsGroupNames.includes('超级管理员')">
            <el-button class="operate_btn" type="text" @click="operateMerchants(1, data)">更换商家</el-button>
            <!-- <el-button class="operate_btn" type="text" @click="operateButton('coupon_list', data)">优惠券列表</el-button> -->
            <el-button class="operate_btn" type="text" @click="operateButton('operate_enable', data)">
              {{ data.qrCodeStatus == 1 ? "禁用" : "启用" }}
            </el-button>
            <el-button v-if="data.claimStatus === '已领取'" class="operate_btn" type="text" @click="openRemark(data)">修改备注</el-button>
          </template>
        </template>
      </TableList>
    </div>

    <!-- ——————————————————————————弹窗相关 start—————————————————————————— -->
    <!-- 导出弹窗 -->
    <el-dialog title="二维码管理导出" :visible.sync="exportDialog" width="28%">
      <el-form :model="exportQueryData" label-width="100px" size="medium">
        <el-form-item label="所属区域">
          <el-cascader
            v-if="$public.isAllAreaPower()"
            v-model="exportQueryData.areaId"
            :props="addressConfigure"
            :options="exportAddressList"
            clearable
            placeholder="请选择地址"
          />
          <d-area v-else :values.sync="exportQueryData.areaId" />
        </el-form-item>
        <el-form-item label="领取状态">
          <div>
            <el-select v-model="exportQueryData.claimStatus" clearable placeholder="请选择">
              <el-option label="全部" :value="0" />
              <el-option label="已领取" :value="1" />
              <el-option label="未领取" :value="2" />
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="状态">
          <div>
            <el-select v-model="exportQueryData.qrCodeStatus" clearable placeholder="请选择">
              <el-option label="全部" :value="0" />
              <el-option label="启用" :value="1" />
              <el-option label="禁用" :value="2" />
            </el-select>
          </div>
        </el-form-item>
        <el-form-item style="text-align: right; padding-bottom: 20px; margin: 0;">
          <el-button type="primary" @click="exportQrcode">导出</el-button>
          <el-button @click="exportDialog = false">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 新增/编辑二维码弹窗 -->
    <el-dialog class="add_qrcode" :title="`${!qrcode_isEdit?'新增': currentClickData.qrCodeSerial+' '}二维码`" :visible.sync="addQrcode_dialog" width="50%" @close="closeDialog('addQrcode')">
      <el-form ref="qrCodeForm" :model="qrCodeForm" :rules="qrCodeRules" size="medium" label-width="100px">
        <el-form-item v-if="qrcode_isEdit" style="margin-bottom: 0px">
          <el-button type="text" @click="operateButton('qrcode_preview')">查看二维码</el-button>
        </el-form-item>
        <el-form-item v-if="qrcode_isEdit" :label="`商家`" required>
          <TableList
            :table-header="merchantsData.tableHeader"
            :table-data="merchantsData.selectList"
            :is-show-page="false"
            :is-show-index="false"
            :is-fix-height="false"
          />
        </el-form-item>
        <el-form-item label="指定路径" prop="specifiedPath">
          <el-select v-model="qrCodeForm.specifiedPath" class="url_select" placeholder="请选择" @change="changePath">
            <el-option v-for="item in pathsOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <div v-show="qrCodeForm.specifiedPath && qrCodeForm.specifiedPath != 1" style="margin-top: 10px">
            <!-- 产品类别 -->
            <el-cascader
              v-show="qrCodeForm.specifiedPath == 2"
              ref="productCategory_cascader"
              v-model="productCategoryIdVal"
              class="url_select"
              :props="productTypeProps"
              :options="productTypeList"
              @change="changeProductCategory"
            />
            <!-- 产品详情页-选择产品 -->
            <div v-show="qrCodeForm.specifiedPath == 3">
              <el-button type="primary" @click="operateButton('openSelect_product')">选择产品</el-button>
              <TableList
                :table-header="productData.tableHeader"
                :table-data="productData.selectList"
                :is-show-page="false"
                :is-show-index="false"
                :is-fix-height="false"
              >
                <template #productCategoryName="{data}">{{ data.productCategoryName || data.categoryName || '-' }}</template>
                <template #productStatus>上架</template>
              </TableList>
            </div>
          </div>
        </el-form-item>
        <el-form-item v-show="!qrcode_isEdit" label="数量" prop="createNum">
          <div style="width: 350px">
            <el-input v-model="qrCodeForm.createNum" l-input placeholder="生成二维码数量" clearable />
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addQrcode_dialog = false">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看二维码弹窗 -->
    <el-dialog class="preview_qrcodeImg" width="400px" :title="`查看二维码`" :visible.sync="qrcodePreview_dialog">
      <div class="preview_box">
        <img class="qrcode_img" :src="qrCodeDetailData.qrCodeUrl" alt="" />
      </div>
      <div slot="footer" class="preview_btn">
        <el-button type="primary" @click="operateButton('single_qrCode')">下载</el-button>
      </div>
    </el-dialog>

    <!-- 选择商家弹窗 -->
    <el-dialog class="select_merchants" title="选择商家" :visible.sync="merchantsData.dialog" width="50%" @close="closeDialog('merchantsTable')">
      <el-form class="search_box" :inline="true" size="medium">
        <el-form-item label="商家端名称">
          <el-input
            v-model="merchantsQueryData.param.merchantName"
            placeholder="商家端名称"
            clearable
            @keyup.enter.native="updateList('merchantsTable')"
          />
        </el-form-item>
        <el-form-item label="业务员姓名">
          <el-input
            v-model="merchantsQueryData.param.intendantName"
            placeholder="业务员姓名"
            clearable
            @keyup.enter.native="updateList('merchantsTable')"
          />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="updateList('merchantsTable')">查询</el-button>
        </el-form-item>
      </el-form>
      <TableList
        ref="merchantsTable"
        height="300px"
        :loading="merchantsData.loading"
        :table-header="merchantsData.tableHeader"
        :table-data="merchantsData.list"
        :total="merchantsData.total"
        :highlight-current-row="true"
        @setPage="pageChange($event, 'merchantsTable')"
        @setSize="sizeChange($event, 'merchantsTable')"
        @rowClick="currentRow($event, 'merchantsTable')"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="merchantsData.dialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmSelect('merchantsTable')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 选择产品弹窗 -->
    <el-dialog class="select_merchants" title="选择产品" :visible.sync="productData.dialog" width="50%" @close="closeDialog('productTable')">
      <el-form class="search_box" :inline="true" size="medium">
        <el-form-item label="搜索产品">
          <el-input
            v-model="productQueryData.param.productName"
            placeholder="请输入产品名称"
            clearable
            @keyup.enter.native="updateList('productTable')"
          />
        </el-form-item>
        <el-form-item label="产品类别">
          <el-select v-model="productQueryData.param.productClassify" clearable placeholder="请选择产品类别">
            <el-option v-for="item in $api.orderClassify" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="updateList('productTable')">查询</el-button>
        </el-form-item>
      </el-form>
      <TableList
        ref="productTable"
        height="300px"
        :loading="productData.loading"
        :table-header="productData.tableHeader"
        :table-data="productData.list"
        :total="productData.total"
        :highlight-current-row="true"
        @setPage="pageChange($event, 'productTable')"
        @setSize="sizeChange($event, 'productTable')"
        @rowClick="currentRow($event, 'productTable')"
      >
        <template #productCategoryName="{data}">{{ data.productCategoryName || data.categoryName || '-' }}</template>
        <template #productStatus>上架</template>
      </TableList>
      <span slot="footer" class="dialog-footer">
        <el-button @click="productData.dialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmSelect('productTable')">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 销售记录弹窗 -->
    <el-dialog :title="`${currentClickData.qrCodeSerial} 销售记录`" :visible.sync="salesRecordsData.dialog" width="50%" @close="closeDialog">
      <TableList
        height="400px"
        :loading="salesRecordsData.loading"
        :table-header="salesRecordsData.tableHeader"
        :table-data="salesRecordsData.list"
        :is-show-page="false"
      >
        <template #userName="{data}">
          <span>{{ `${data.memberNickName} / ${data.recipient}` }}</span>
        </template>
        <template #orderTotalPrice="{data}">
          <span>{{ data.orderTotalPrice ? "￥"+data.orderTotalPrice :'￥0.00' }}</span>
        </template>
        <template #orderStatus="{data}">
          <span>{{ $api.getValue($api.EcommerceOrderState, data.orderStatus, 'name') }}</span>
          <!-- <span>{{ formatOrderStatus(data.orderStatus) }}</span> -->
        </template>
        <template #operation="{data}">
          <el-button class="btn" type="text" @click="operateButton('salesRecords_detail', data)">订单详情</el-button>
        </template>
      </TableList>
    </el-dialog>

    <!-- 会员列表弹窗 -->
    <el-dialog :title="`${currentClickData.qrCodeSerial} 会员列表`" :visible.sync="memberListData.dialog" width="50%" @close="closeDialog">
      <TableList
        height="400px"
        :loading="memberListData.loading"
        :table-header="memberListData.tableHeader"
        :table-data="memberListData.list"
        :is-show-page="false"
      >
        <template #cumulativeConsumption="{data}">
          <span>{{ data.cumulativeConsumption ? "￥" + data.cumulativeConsumption : "-" }}</span>
        </template>
      </TableList>
    </el-dialog>

    <!-- 优惠券列表弹窗 -->
    <el-dialog :title="`${currentClickData.qrCodeSerial} 优惠券列表`" :visible.sync="couponListData.dialog" width="50%" @close="closeDialog">
      <TableList
        height="400px"
        :loading="couponListData.loading"
        :table-header="couponListData.tableHeader"
        :table-data="couponListData.list"
        :is-show-page="false"
      >
        <template #couponStatus="{data}">
          <span>{{ $api.getValue($api.couponStatus, data.couponStatus, 'name') }}</span>
        </template>
      </TableList>
    </el-dialog>

    <!-- 更换商家弹窗 -->
    <el-dialog :title="`${currentClickData.qrCodeSerial}`" :visible.sync="changeMerchant_dialog" width="50%">
      <el-form :model="qrCodeForm" size="medium" label-width="100px">
        <el-form-item style="margin-bottom: 0px">
          <el-button type="text" @click="operateButton('qrcode_preview')">查看二维码</el-button>
        </el-form-item>
        <el-form-item :label="`商家`" required>
          <div><el-button type="text" @click="operateButton('openSelect_merchants')">更换商家</el-button></div>
          <TableList
            :table-header="merchantsData.tableHeader"
            :table-data="merchantsData.selectList"
            :is-show-page="false"
            :is-show-index="false"
            :is-fix-height="false"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changeMerchant_dialog = false">取 消</el-button>
        <el-button type="primary" @click="changeMerchant_dialog = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 更换备注弹窗 -->
    <el-dialog title="修改备注" :visible.sync="remark_dialog" width="40%">
      <el-form :model="remarkParams" size="medium" class="remark_form">
        <el-form-item style="margin-bottom: 0px">
          <textarea v-model="remarkParams.remark" maxlength="50"></textarea>
          <div class="remark_num">{{ remarkParams.remark.length }}/50</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="remark_dialog = false">取 消</el-button>
        <el-button type="primary" @click="upDateRemark">确 定</el-button>
      </span>
    </el-dialog>
    <!-- ——————————————————————————弹窗相关 end———————————————————————————— -->
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import {
  queryQrCodeList,
  createQrCode,
  merchantPage,
  productPage,
  modifyQrCodeStatus,
  qrCodeDetail,
  modifyQrCode,
  qrCodeSaleRecord,
  qrCodeMemberList,
  changeQrCodeMerchant,
  updateRemark,
  qrCodeCouponList
} from '@/api/user.js'
export default {
  data() {
    return {
      userInfo: '', // 用户信息
      thirdPartyPermissions: false, // 是不是第三方权限组
      qrcode_isEdit: false, // false新增 true编辑

      addressConfigure: { // 区域选择下拉配置
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },
      // 区域数据
      addressList: [],
      exportAddressList: [], // 存放导出的区域选择数据
      // 请求数据
      queryData: {
        pageNum: 1,
        pageSize: 10,
        intendantName: '', // 业务员名称
        areaId: '', // 所属区域
        merchantName: '', // 商家名称
        qrCodeStatus: null, // 状态 1-启用 2-禁用
        intendantCode: '', // 业务员编号
        qrCode: '', // 二维码编号 状态
        claimStatus: '' // 领取状态 1-未领取 2-已领取
      },
      // 表格数据
      tableData: {
        tableHeader: [
          // 表头
          { label: '二维码编号', prop: 'qrCodeSerial', width: '200px' },
          { label: '业务员编号', prop: 'intendantCode', width: '200px' },
          { label: '业务员姓名', prop: 'intendantName', width: '160px' },
          { label: '业务员手机号', prop: 'intendantPhone', width: '160px' },
          { label: '销售额', type: 'slot', slotName: 'qrCodeSalePrice', width: '160px' },
          { label: '扫码记录（次）', prop: 'scanNum', width: '130px' },
          { label: '用户总数（位）', type: 'slot', slotName: 'qrCodeUserNum', width: '130px' },
          { label: '所属大区', prop: 'regionName', width: '160px' },
          { label: '所属片区', prop: 'districtName', width: '160px' },
          { label: '所属办事处', prop: 'agencyName', width: '160px' },
          { label: '商家端编号', prop: 'merchantCode', width: '200px' },
          { label: '商家端名称', prop: 'merchantName', width: '160px' },
          { label: '优惠券数量', prop: 'couponCount', width: '160px' },
          { label: '状态', type: 'slot', slotName: 'qrCodeStatus', width: '120px' },
          { label: '领取状态', type: 'slot', slotName: 'claimStatus', width: '140px' },
          { label: '备注', prop: 'remark', width: '200px' },
          { label: '操作', type: 'slot', slotName: 'operation', fixed: 'right', width: '260px' }
        ],
        list: [], // 返回的列表数据
        total: 0
      },

      selectData: [], // 多选数据

      currentClickData: {}, // 当前点击的数据
      qrCodeDetailData: {}, // 二维码详情数据
      qrcodePreview_dialog: false, // 查看二维码弹窗

      /* —————————————新增编辑二维码 start—————————————*/
      addQrcode_dialog: false,
      // 新增二维码表单
      qrCodeForm: {
        merchantId: '', // 商户id
        specifiedPath: 3, // 跳转路径 1.首页 2.产品列表 3.产品详情
        specifiedPathId: '', // 跳转路径id
        createNum: '' // 创建数量
      },
      qrCodeRules: {
        // 新增二维码表单验证规则
        specifiedPath: [{ required: true, message: '请选择路径', trigger: 'change' }],
        createNum: [{ validator: (rule, value, callback) => {
          if (!this.qrcode_isEdit) {
            const reg = /^[1-9]\d*$/
            if (!reg.test(value)) {
              return callback(new Error('请输入大于 0 的整数'))
            }
          }
          callback()
        }, required: true, trigger: ['blur', 'change'] }]
      },
      pathsOptions: [
        // 指定路径类型
        { label: '首页', value: 1 },
        { label: '产品类别', value: 2 },
        { label: '产品详情页', value: 3 }
      ],

      // 选择商家请求数据
      merchantsQueryData: {
        pageNum: 1,
        pageSize: 10,
        param: {
          isIntendantCode: 1, // 是否包含业务员编号 1.是 2.否
          merchantName: '', // 商家端名称
          intendantName: '', // 业务员姓名
          filterMerchantIds: [], // 商家id数组
          deleted: 0
        }
      },
      // 选择商家数据
      merchantsData: {
        dialog: false,
        loading: false,
        tableHeader: [
          { label: '商家端编号', prop: 'merchantCode' },
          { label: '商家端名称', prop: 'merchantName' },
          { label: '业务员编号', prop: 'intendantCode' },
          { label: '业务员姓名', prop: 'intendantName' },
          { label: '业务员手机号', prop: 'intendantPhone' }
        ],
        list: [],
        total: 0,

        currentSelect: '', // 当前选中的数据
        selectList: [] // 选中的数据
      },

      merchantIdList: [], // 当前选中的商家id数据
      qrCodeIdList: [], // 当前选中的商家二维码id
      changeMerchantFlag: 0, // 更换商家 1-单个更换商家 2-批量更换商家
      changeMerchant_dialog: false, // 更换商家弹窗

      // 产品类别
      productTypeProps: { // 产品类别联级选择器配置
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: false
      },
      productCategoryIdVal: '', // 产品类别
      productTypeList: [], // 产品类别数据

      // 选择产品请求数据
      productQueryData: {
        pageNum: 1,
        pageSize: 10,
        param: {
          productName: '', // 产品名称
          productClassify: '', // 产品类别
          productStatus: 'on_shelves'
        }
      },
      // 选择产品数据
      productData: {
        dialog: false,
        loading: false,
        productName: '', // 产品名称
        productCategoryId: '', // 产品类别
        tableHeader: [
          { label: '产品编号', prop: 'productCode' },
          { label: '产品名称', prop: 'productName' },
          { label: '所属类别', type: 'slot', slotName: 'productCategoryName' },
          { label: '状态', type: 'slot', slotName: 'productStatus' }
        ],
        list: [],
        total: 0,

        currentSelect: '', // 当前选中的数据
        selectList: [] // 选中的数据
      },
      /* —————————————新增编辑二维码 end———————————————*/

      // 销售记录请求数据
      salesRecordsQueryData: {
        pageNum: 1,
        pageSize: 10,
        param: {
          merchantId: '',
          orderCode: ''
        }
      },
      // 销售数据
      salesRecordsData: {
        dialog: false,
        loading: true,
        tableHeader: [
          { label: '订单编号', prop: 'orderCode' },
          { label: '卖家/收货人', type: 'slot', slotName: 'userName' },
          { label: '实付金额', type: 'slot', slotName: 'orderTotalPrice' },
          { label: '下单时间', prop: 'orderTime' },
          { label: '订单状态', type: 'slot', slotName: 'orderStatus' },
          { label: '操作', type: 'slot', slotName: 'operation' }
        ],
        list: []
      },

      // 会员列表请求数据
      memberQueryData: {
        qrCodeId: '', // 二维码id
        memberNickname: '', // 微信昵称
        memberCode: '', // 会员编号
        memberTelephone: '' // 注册手机号
      },
      // 会员列表数据
      memberListData: {
        dialog: false,
        loading: false,
        tableHeader: [
          { label: '微信昵称', prop: 'memberNickname' },
          { label: '会员编号', prop: 'memberCode' },
          { label: '注册手机号', prop: 'memberTelephone' },
          { label: '累计消费', type: 'slot', slotName: 'cumulativeConsumption' },
          { label: '消费次数', prop: 'consumptionFrequency' },
          { label: '注册时间', prop: 'registerTime' }
        ],
        list: []
      },

      // 优惠券列表请求数据
      couponQueryData: {},
      // 优惠券列表数据
      couponListData: {
        dialog: false,
        loading: false,
        tableHeader: [
          { label: '优惠券名称', prop: 'couponName' },
          { label: '优惠券ID', prop: 'couponCode' },
          { label: '状态', type: 'slot', slotName: 'couponStatus' },
          { label: '透出时间', prop: 'penetrateTime' }
        ],
        list: []
      },

      // 备注弹窗
      remark_dialog: false,
      // 备注弹窗参数
      remarkParams: {
        qrCodeId: '', // 二维码id
        remark: '' // 备注
      },

      exportDialog: false, // 导出弹窗
      exportQueryData: { // 导出参数
        intendantName: '', // 业务员名称
        areaId: '', // 所属区域
        merchantName: '', // 商家名称
        qrCodeStatus: '', // 二维码状态 1.启用 2.禁用
        intendantCode: '', // 业务员编号
        qrCode: '', // 二维码编号
        claimStatus: '' // 领取状态,1已领取，2未领取
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    // 获取用户信息
    const userInfo = getSessionStorage('userInfo') || {}
    this.userInfo = userInfo

    // console.log('userInfo====>>>>>', userInfo)
    // 判断是不是第三方权限
    this.thirdPartyPermissions = userInfo && userInfo.rightsGroupNames.length == 1 && '第三方权限组'.includes(userInfo.rightsGroupNames)
    if (this.thirdPartyPermissions) {
      this.handlerLimit()
    }

    if (userInfo.managerType === 'all') {
      this.getAreaList()
    }
    this.getQueryQrCodeList()
  },
  methods: {
    // 第三方权限限制
    handlerLimit() {
      /**
       * 对第三方管理权限组，二维码列表中需隐藏“所属区域”、“商家端名称”筛选条件，
       * 隐藏列表中“所属大区” “所属片区” “所属办事处”“商家端编号” “商家端名称”等字段；
      */
      const hideKeyName = ['regionName', 'districtName', 'agencyName', 'merchantCode', 'merchantName']
      hideKeyName.forEach(value1 => {
        this.tableData.tableHeader.some((value2, index2) => {
          if (value1 === value2.prop) {
            value2.isHide = true
          }
        })
      })
    },
    // 改变页码
    pageChange(pageVal, type) {
      switch (type) {
        case 'qrcodeTable': // 二维码列表
          this.queryData.pageNum = pageVal
          this.getQueryQrCodeList()
          break

        case 'merchantsTable': // 商家列表
          this.merchantsQueryData.pageNum = pageVal
          this.getMerchantPage()
          break

        case 'productTable': // 产品列表
          this.productQueryData.pageNum = pageVal
          this.getProductPage()
          break

        default:
          break
      }
    },
    // 改变页数
    sizeChange(sizeVal, type) {
      switch (type) {
        case 'qrcodeTable': // 二维码列表
          this.queryData.pageSize = sizeVal
          this.getQueryQrCodeList()
          break

        case 'merchantsTable': // 商家列表
          this.merchantsQueryData.pageSize = sizeVal
          this.getMerchantPage()
          break

        case 'productTable': // 产品列表
          this.productQueryData.pageSize = sizeVal
          this.getProductPage()
          break

        default:
          break
      }
    },
    // 搜索
    updateList(type) {
      switch (type) {
        case 'qrcodeTable': // 二维码列表
          this.queryData.pageNum = 1
          this.getQueryQrCodeList()
          break

        case 'merchantsTable': // 商家列表
          this.merchantsQueryData.pageNum = 1
          this.getMerchantPage()
          break

        case 'productTable': // 产品列表
          this.productQueryData.pageNum = 1
          this.getProductPage()
          break

        default:
          break
      }
    },
    // 多选
    selectionChange(rows) {
      this.selectData = rows
    },
    // 选中当前行
    currentRow(row, type) {
      switch (type) {
        case 'merchantsTable': // 商家列表
          this.merchantsData.currentSelect = row
          break

        case 'productTable': // 产品列表
          this.productData.currentSelect = row
          break

        default:
          break
      }
    },
    // 确定选中更换商家
    confirmSelect(type) {
      switch (type) {
        case 'merchantsTable': // 商家列表
          if (!this.merchantsData.currentSelect) {
            return this.$message.warning('请选择商家')
          }
          this.merchantsData.selectList = [this.merchantsData.currentSelect]
          // this.merchantIdList = [this.merchantsData.selectList[0].merchantId]
          this.merchantsData.dialog = false

          if (this.changeMerchantFlag) {
            this.getChangeQrCodeMerchant()
            // this.merchantsData.currentSelect = ''
          }
          break

        case 'productTable': // 产品列表
          this.productData.selectList = [this.productData.currentSelect]
          this.productData.dialog = false
          break

        default:
          break
      }
    },
    // 操作按钮
    operateButton(operateType, data) {
      switch (operateType) {
        case 'modify_qrcode': // 新增/编辑二维码
          this.qrcode_isEdit = !!data

          // 重置更换商家标识
          this.changeMerchantFlag = 0
          // 清空需要过滤的商家id
          this.merchantIdList = []
          // 清空批量选中的选项
          this.$refs.qrcodeTable.clearSelection()

          // 重置数据
          this.qrCodeForm = Object.assign({}, this.$options.data().qrCodeForm)
          this.merchantsData.selectList = []
          this.productData.selectList = []
          this.productCategoryIdVal = ''

          if (this.qrcode_isEdit) { // 预览/编辑状态
            this.currentClickData = data
            // 获取产品类别
            this.getProductType()
            // 获取产品详情
            this.getQrCodeDetail(data.qrCodeId)
          }
          this.addQrcode_dialog = true
          break

        case 'openSelect_merchants': // 添加商家弹窗
          this.merchantsData.dialog = true
          this.merchantsData.list = []
          this.getMerchantPage()
          break

        case 'openSelect_product': // 选择产品弹窗
          this.productData.dialog = true
          this.productData.list = []
          this.getProductPage()
          break

        case 'qrcode_preview': // 查看二维码
          this.qrcodePreview_dialog = true
          break

        case 'download_qrcode': // 下载二维码
          this.getDownloadQrCode()
          break

        case 'single_qrCode': // 下载单个二维码
          this.downloadSingleQrCode()
          break

        case 'sales_records': // 销售记录
          this.salesRecordsData.dialog = true
          this.currentClickData = data
          this.getSalesRecordsList(data.qrCodeId)
          break

        case 'salesRecords_detail': // 销售记录详情
          this.$router.push({
            path: '/order/ExternalProductOrder/particulars',
            query: { id: data.orderId }
          })
          break

        case 'member_list': // 会员列表
          this.memberListData.dialog = true
          this.currentClickData = data
          this.getQrCodeMemberList(data.qrCodeId)
          break

        case 'coupon_list': // 优惠券列表
          this.couponListData.dialog = true
          this.currentClickData = data
          this.getQrCodeCouponList(data.qrCodeId)
          break

        case 'operate_enable': // 禁用/启用
          // eslint-disable-next-line no-case-declarations
          const status = data.qrCodeStatus == 1 ? 2 : 1
          this.$confirm(`是否确定${status == 1 ? '启用' : '禁用'}该二维码？`, `确认${status == 1 ? '启用' : '禁用'}二维码`, {
            cancelButtonText: '取消',
            confirmButtonText: '确定',
            type: 'warning'
          })
            .then(async() => {
              const params = {
                qrCodeId: data.qrCodeId,
                qrCodeStatus: status
              }
              const res = await modifyQrCodeStatus(params)
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: `${status == 1 ? '启用' : '禁用'}成功`
                })
                this.getQueryQrCodeList()
              }
            })
            .catch(() => {})
          break

        case 'export_table': // 导出
          this.exportQueryData = Object.assign({}, this.$options.data().exportQueryData)
          this.exportDialog = true
          break

        default:
          break
      }
    },

    // 单个/批量更换商家
    operateMerchants(type, data) {
      // type：1-单个更换 2-批量更换
      this.changeMerchantFlag = type
      if (type === 1) { // 单个更换商家
        this.currentClickData = data
        // 重置数据
        this.merchantsData.selectList = []
        // 清空批量选中的选项
        this.$refs.qrcodeTable.clearSelection()

        // 保存需要过滤的商家id
        this.merchantIdList = data.merchantId ? [data.merchantId] : []
        // 保存需要更换的二维码id
        this.qrCodeIdList = [data.qrCodeId]

        // 获取详情，打开更换商家弹窗
        this.getQrCodeDetail(data.qrCodeId)
        this.changeMerchant_dialog = true
      } else if (type === 2) { // 批量更换商家
        if (!this.selectData.length) {
          return this.$message.warning('请选择二维码')
        }
        // 获取当前选中的商家id 和 二维码id
        const idList = []
        this.qrCodeIdList = []
        if (this.selectData.length) {
          this.selectData.forEach(value => {
            this.qrCodeIdList.push(value.qrCodeId)
            if (idList.indexOf(value.merchantId) === -1) {
              idList.push(value.merchantId)
            }
          })
        }
        this.merchantIdList = idList

        // 获取商家列表，打开商家列表弹窗
        this.getMerchantPage()
        this.merchantsData.dialog = true
      }
    },

    // 关闭弹窗
    closeDialog(closeType) {
      switch (closeType) {
        case 'addQrcode': // 新增/编辑 二维码弹窗
          this.$nextTick(() => {
            this.$refs.qrCodeForm.resetFields()
          })
          this.addQrcode_dialog = false
          // 重置选中的商家
          this.merchantsData.currentSelect = ''
          break

        case 'merchantsTable': // 商家列表
          this.merchantsData.dialog = false
          if (this.changeMerchantFlag) {
            // 当前操作是更换商家时，清空选中的商家
            this.merchantsData.currentSelect = ''
          } else {
            // 当前操作是添加商家，并且无选中商家时，清空选中的商家
            if (!this.merchantsData.selectList.length) {
              this.merchantsData.currentSelect = ''
            }
          }
          break

        case 'productTable': // 产品列表
          this.productData.dialog = false
          break

        default:
          break
      }
    },

    // 获取区域级联数据
    getAreaList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then((res) => {
        this.addressList = res.data
        this.exportAddressList = [...res.data]
        this.exportAddressList.unshift({ areaId: '0', areaName: '全部' })
      })
    },
    // 获取二维码列表
    async getQueryQrCodeList() {
      const params = { ...this.queryData }
      const res = await queryQrCodeList(params)
      if (res.code === 200) {
        this.tableData.list = res.data.list
        this.tableData.total = res.data.total
      }
    },
    // 获取产品类别
    getProductType() {
      this.$http.requestGet({ url: '/product/category', loading: false }).then((res) => {
        this.productTypeList = res.data
        this.productTypeList.unshift({ categoryId: '0', categoryName: '全部' })
      })
    },
    // 获取商家列表
    async getMerchantPage() {
      this.merchantsData.loading = true
      const queryParams = { ...this.merchantsQueryData }
      console.log(this.merchantIdList, 'this.merchantIdList')
      queryParams.param.filterMerchantIds = this.merchantIdList

      const res = await merchantPage(queryParams)
      if (res.code === 200) {
        this.merchantsData.list = res.data.list
        this.merchantsData.total = res.data.total
      }
      this.merchantsData.loading = false

      if (this.merchantsData.currentSelect) {
        // 高亮手动选中的数据
        const index = this.merchantsData.list.findIndex((item) => item.merchantCode == this.merchantsData.currentSelect.merchantCode)
        this.$refs.merchantsTable.setCurrentRow(this.merchantsData.list[index])
      } else {
        // 清空高亮数据
        this.$refs.merchantsTable.setCurrentRow()
      }
    },
    // 获取产品列表
    async getProductPage() {
      this.productData.loading = true
      const param = { ...this.productQueryData }
      const res = await productPage(param)
      if (res.code === 200) {
        this.productData.list = res.data.list
        this.productData.total = res.data.total
      }
      this.productData.loading = false

      if (this.productData.currentSelect) {
        // 高亮手动选中的数据
        const index = this.productData.list.findIndex((item) => item.productId == this.productData.currentSelect.productId)
        this.$refs.productTable.setCurrentRow(this.productData.list[index])
      } else {
        // 高亮默认已选中数据
        if (this.productData.selectList.length) {
          const index = this.productData.list.findIndex((item) => item.productId == this.productData.selectList[0].productId)
          this.$refs.productTable.setCurrentRow(this.productData.list[index])
        }
      }
    },
    // 改变指定路径
    changePath(val) {
      this.productCategoryIdVal = ''
      this.productData.selectList = []
      if (val == 2) {
        // 获取产品类别
        this.getProductType()
      }
    },
    // 改变产品类别
    changeProductCategory(val) {
      if (val == 0) {
        // 当点击全部时 折叠下拉选项
        this.$refs.productCategory_cascader.toggleDropDownVisible()
      }
    },
    // 新增二维码
    async submitAdd() {
      this.$refs.qrCodeForm.validate(async(valid) => {
        if (valid) {
          if (this.qrCodeForm.specifiedPath == 2 && !this.productCategoryIdVal) {
            return this.$message.warning('请选择产品类别')
          }
          if (this.qrCodeForm.specifiedPath == 3 && !this.productData.selectList.length) {
            return this.$message.warning('请选择指产品')
          }
          const specifiedPath = this.qrCodeForm.specifiedPath
          this.qrCodeForm.specifiedPathId = specifiedPath == 2 ? this.productCategoryIdVal : specifiedPath == 3 ? this.productData.selectList[0].productId : ''

          if (!this.qrcode_isEdit) { // 新增
            const res = await createQrCode(this.qrCodeForm)
            if (res.code === 200) {
              this.$message.success('新增成功')
              this.updateList('qrcodeTable')
            } else {
              console.log('新增出错了==>', res)
            }
          } else { // 编辑
            const params = {
              qrCodeId: this.currentClickData.qrCodeId, // 二维码id
              specifiedPath: this.qrCodeForm.specifiedPath, // 跳转路径 1.首页 2.产品列表 3.产品详情
              specifiedPathId: this.qrCodeForm.specifiedPathId // 跳转路径id
            }
            const res = await modifyQrCode(params)
            if (res.code === 200) {
              this.$message.success('编辑成功')
              this.updateList('qrcodeTable')
            } else {
              console.log('编辑出错了==>', res)
            }
          }
          // 关闭弹窗
          this.closeDialog('addQrcode')
        }
      })
    },

    // 下载二维码
    async getDownloadQrCode() {
      const requestUrl = '/customer/merchant/downloadQrCode'
      const fileName = `log_${new Date().getTime()}` // 下载的文件名称
      this.$http.download({
        url: requestUrl,
        param: { ...this.queryData },
        method: 'POST'
      }).then(res => {
        this.$public.downloadFile(res, fileName)
      }).catch(err => {
        this.$message.error(err)
        console.error('下载失败：', err)
      })
    },
    // 下载单个二维码
    downloadSingleQrCode() {
      // 文件下载地址
      const fileUrl = this.qrCodeDetailData.qrCodeUrl
      const myHeaders = new Headers({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/plain'
      })

      // 发起 Fetch 请求
      fetch(fileUrl, { method: 'GET', headers: myHeaders, mode: 'cors' })
        .then(response => response.blob())
        .then(blob => {
          const fileName = this.currentClickData.qrCodeSerial
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)// 将图片的src赋值给a节点的href
          a.download = fileName // 下载的文件名称
          a.click()
          a.remove()
        })
        .catch(error => console.error('下载失败：', error))
    },

    // 获取二维码详情
    async getQrCodeDetail(qrCodeId) {
      const res = await qrCodeDetail(qrCodeId)
      if (res.code === 200) {
        this.qrCodeDetailData = res.data || {}
        // 处理商家信息回显数据
        this.merchantsData.selectList = [{
          intendantCode: this.qrCodeDetailData.intendantCode,
          intendantName: this.qrCodeDetailData.intendantName,
          intendantPhone: this.qrCodeDetailData.intendantPhone,
          merchantId: this.qrCodeDetailData.merchantId,
          merchantCode: this.qrCodeDetailData.merchantCode,
          merchantName: this.qrCodeDetailData.merchantName
        }]

        // 处理指定路径回显数据
        this.qrCodeForm.specifiedPath = this.qrCodeDetailData.specifiedPath
        if (this.qrCodeDetailData.specifiedPath == 2) {
          this.productCategoryIdVal = this.qrCodeDetailData.specifiedPathId
        } else if (this.qrCodeDetailData.specifiedPath == 3) {
          this.productData.selectList = [this.qrCodeDetailData.productQrCodeDetailVo]
        }
      }
    },
    // 获取销售记录
    async getSalesRecordsList(qrCodeId) {
      this.salesRecordsData.loading = true
      const res = await qrCodeSaleRecord(qrCodeId)
      if (res.code === 200) {
        this.salesRecordsData.list = res.data
      }
      this.salesRecordsData.loading = false
    },
    // 格式化订单状态
    formatOrderStatus(orderStatus) {
      let orderStatusName = ''
      this.$api.EcommerceOrderState.some(item => {
        if (item.value === orderStatus) {
          orderStatusName = item.name
        }
      })
      return orderStatusName
    },

    // 获取会员列表接口
    async getQrCodeMemberList(qrCodeId) {
      this.memberListData.loading = true
      const params = { ...this.memberQueryData }
      params.qrCodeId = qrCodeId
      const res = await qrCodeMemberList(params)
      if (res.code === 200) {
        this.memberListData.list = res.data
      }
      this.memberListData.loading = false
    },

    // 获取优惠券列表接口
    async getQrCodeCouponList(qrCodeId) {
      this.couponListData.loading = true
      const res = await qrCodeCouponList(qrCodeId)
      if (res.code === 200) {
        this.couponListData.list = res.data
      }
      this.couponListData.loading = false
    },

    // 更换商家
    async getChangeQrCodeMerchant() {
      const params = {
        qrCodeIds: this.qrCodeIdList, // 需要更换的二维码id
        merchantId: this.merchantsData.selectList[0].merchantId // 更换后的商家id
      }
      const res = await changeQrCodeMerchant(params)
      if (res.code === 200) {
        this.$message.success('更换成功')
        // 清空批量选中的选项
        this.$refs.qrcodeTable.clearSelection()

        this.merchantIdList = [this.merchantsData.selectList[0].merchantId]
        this.getQueryQrCodeList()
      } else {
        this.$message.error('更换失败')
      }
    },

    // 打开备注弹窗
    openRemark(item) {
      this.remarkParams.qrCodeId = item.qrCodeId
      this.remarkParams.remark = item.remark ? item.remark : ''
      this.remark_dialog = true
    },
    // 更新备注
    upDateRemark() {
      updateRemark(this.remarkParams).then(res => {
        if (res.code === 200 && res.data) {
          this.remark_dialog = false
          this.$message.success('修改成功')
          this.getQueryQrCodeList()
        }
      })
    },

    // 二维码管理导出
    async exportQrcode() {
      if (this.exportQueryData.areaId === '' || this.exportQueryData.claimStatus === '' || this.exportQueryData.qrCodeStatus === '') {
        return this.$message.warning('请选择导出条件')
      }
      // console.log('this.exportQueryData===>>>>', this.exportQueryData)
      const requestUrl = '/customer/merchant/exportQrCode'
      const fileName = `二维码管理导出_${new Date().getTime()}`
      this.$http.download({
        url: requestUrl,
        param: { ...this.exportQueryData },
        method: 'POST'
      }).then(res => {
        this.$public.downloadFile(res, fileName)
        this.exportDialog = false
      }).catch(err => {
        this.$message.error(err)
        console.error('导出失败：', err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.qrcode_list {
  .search_box {
    /deep/ .el-form {
      :last-child {
        margin-right: 0;
      }
    }
  }
  .table {
    width: 100%;
    margin: 0;
    padding: 10px 0;
  }
  .table_box {
    .tableList {
      width: 100%;
      margin: 0;
      padding: 20px 20px 10px;
      /deep/ .el-button {
        padding: 0;
      }
      /deep/ .el-button+.el-button {
        margin: 0;
      }
      .operate_btn {
        &::before {
          content: '/';
          font-size: 12px;
          color: #4e9aef;
          vertical-align: top;
          margin-right: 5px;
        }
        &:first-child::before {
          content: '';
          margin: 0;
        }
      }
    }
  }

  .add_qrcode {
    .url_select {
      width: 350px;
    }
  }

  .preview_qrcodeImg {
    .preview_box {
      display: flex;
      align-items: center;
      justify-content: center;
      .qrcode_img {
        width: 250px;
      }
    }
    .preview_btn {
      text-align: center;
    }
  }

  .remark_form{
    position: relative;
    textarea{
      width: 100%;
      height: 150px;
      resize:none;
      padding: 10px;
    }
    .remark_num{
      position: absolute;
      right: 10px;
      bottom: 10px;
      z-index: 2;
    }
  }
}
</style>
