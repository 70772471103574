<template>
  <!--人才库-->
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {}
</script>

<style>
</style>
