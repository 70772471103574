import request from '@/utils/request'

// 锁定/开启二维码
export function merchantLockQrCode(param = {}) {
  return request.requestPut({
    url: '/customer/merchant/lockQrCode',
    param
  })
}

// 获取限购未关联产品列表
export function notLinkProduct(param = {}, loading) {
  return request.requestPost({
    url: '/product/notLinkProduct',
    param,
    loading
  })
}

// ------------------------------------------系统模块接口API start----------------------------------------
/**
 * 分页查询导出文档
 * @param { Number } pageNum - 分页参数
 * @param { Number } pageSize - 分页参数
 * @param { Number } param.fileNam - 文件名称
 * @param { String } param.type - 导出类型 // order-订单 purchase-申购 burial-埋点文档
 * @param { String } param.status - 状态 // CLZ-处理中；KXZ-可下载；DCSB-导出失败
 * @param { String } param.creatorNam - 操作者名称
 * @param { String } param.beginTime - 起始导出时间
 * @param { String } param.endTime - 结束导出时间
*/
export function exportFilePage(param) {
  return request.requestPost({
    url: '/exportfile/page',
    param,
    loading: false
  })
}
/**
 * 埋点文档导出
 * @param { Number } typeStatus - 导出类型状态 login-登录埋点  orderSee-订单查看  orderPush-订单推送
 * @param { String } type - 导出类型：order-订单 purchase-申购 burial-埋点文档
 * @param { String } beginTime - 操作时间 - 开始
 * @param { String } endTime - 操作时间 - 结束
 * @param { String } regionId - 区域ID
 * @param { String } orderCode - 订单编号
 * @param { String } accountName - 账号名称
*/
export function burialDataExport(param) {
  return request.requestPost({
    url: '/exportfile/burialDataExport',
    param,
    loading: false
  })
}

// ------------------------------------------系统模块接口API end------------------------------------------

// ------------------------------------------产品模块接口API start----------------------------------------
/**
 * 根据产品id获取商家（绑定or未绑定）
 * @param { String } param.productId - 产品id
 * @param { String } param.merchantName - 商家名称
 * @param { String } param.areaId - 区域id
 * @param { Boolean } param.isBind - 是否查询绑定商家 true：查询绑定商家 false：查询未绑定商家
 * @param { Number } pageNum - 分页参数
 * @param { Number } pageSize - 分页参数
*/
export function getMerchantByProductId(param) {
  return request.requestPost({
    url: '/product/getMerchantByProductId',
    param,
    loading: false
  })
}
/**
 * 绑定更多商家
 * @param { String } productId - 产品id
 * @param { String } merchantIds - 商家id（多个用逗号隔开）
*/
export function saveProductMerchant(productId, merchantIds) {
  return request.requestPut({
    url: `/product/saveProductMerchant/${productId}/${merchantIds}`,
    loading: true
  })
}
/**
 * 删除已绑定商家
 * @param { String } merchantId - 商家id
*/
export function delMerchantProduct(merchantId) {
  return request.requestPut({
    url: `/product/delMerchantProduct/${merchantId}`,
    loading: true
  })
}
// ------------------------------------------产品模块接口API end------------------------------------------
