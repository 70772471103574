var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              ref: "submitForm",
              staticClass: "demo-form-inline",
              attrs: {
                model: _vm.submitForm,
                rules: _vm.submitRules,
                size: "medium",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  staticClass: "color_light-grey",
                  attrs: { label: "生产单编号" },
                },
                [_vm._v("生产单编号在入库成功后自动生产")]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "入库仓库", prop: "warehouseId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.submitForm.warehouseId,
                        callback: function ($$v) {
                          _vm.$set(_vm.submitForm, "warehouseId", $$v)
                        },
                        expression: "submitForm.warehouseId",
                      },
                    },
                    _vm._l(_vm.warehouseList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "入库产品", prop: "batchItems" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-end",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSelectBatch },
                        },
                        [_vm._v("选择批次")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addProduct },
                        },
                        [_vm._v("添加批次")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.submitForm.batchItems, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "date", label: "序号", width: "60" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  _vm._s(
                                    (_vm.pageParam.pageNum - 1) *
                                      _vm.pageParam.pageSize +
                                      (scope.$index + 1)
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "inventoryId",
                          label: "SKU编号",
                          "min-width": "200",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productCode",
                          label: "SPU编号",
                          "min-width": "200",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productName",
                          label: "产品名称",
                          "min-width": "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "productCategoryName",
                          label: "所属类别",
                          "min-width": "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "count",
                          label: "入库数量",
                          "min-width": "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "no",
                          label: "批次",
                          "min-width": "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "produceTime",
                          label: "生产日期",
                          "min-width": "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "expireTime",
                          label: "到期时间",
                          "min-width": "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "termOfValidity",
                          label: "有效期（天）",
                          "min-width": "160",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "",
                          label: "操作",
                          "min-width": "100",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticClass: "operation-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteProduct(
                                          scope.row.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticClass: "flex-box flex-justify submit-btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.submitData },
                      },
                      [_vm._v("保 存")]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            "before-close": _vm.closeDialog,
            width: "80%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品类别" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: _vm.props,
                          options: _vm.productTypeList,
                          clearable: "",
                        },
                        model: {
                          value: _vm.productCategoryId,
                          callback: function ($$v) {
                            _vm.productCategoryId = $$v
                          },
                          expression: "productCategoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "增加时间" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "daterange",
                            "value-format": "yyyy-MM-dd",
                            "range-separator": "至",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                          },
                          model: {
                            value: _vm.daterange,
                            callback: function ($$v) {
                              _vm.daterange = $$v
                            },
                            expression: "daterange",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width-140",
                            attrs: { placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                _vm.selectValue = ""
                              },
                            },
                            model: {
                              value: _vm.selectKey,
                              callback: function ($$v) {
                                _vm.selectKey = $$v
                              },
                              expression: "selectKey",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "产品编号", value: "productNo" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label: "产品名称",
                                value: "productName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "width-180",
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.selectValue,
                            callback: function ($$v) {
                              _vm.selectValue = $$v
                            },
                            expression: "selectValue",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.searchList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "sku_list",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.sku_list, border: true, height: "300px" },
                  on: { "selection-change": _vm.handleProductChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      selectable: _vm.selectable,
                      width: "55",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                (_vm.pageParam.pageNum - 1) *
                                  _vm.pageParam.pageSize +
                                  (scope.$index + 1)
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventoryId",
                      label: "SKU编号",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "skuInfo",
                      label: "规格信息",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCode",
                      label: "产品编号",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCategoryName",
                      label: "所属类别",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productInventoryTotal",
                      label: "库存",
                      "min-width": "120",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { "padding-top": "30px" } }),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "addProductForm",
                  staticClass: "form demo-form-inline",
                  attrs: {
                    model: _vm.addProductForm,
                    rules: _vm.addProductRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "批次", prop: "no" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "50", placeholder: "批次" },
                        model: {
                          value: _vm.addProductForm.no,
                          callback: function ($$v) {
                            _vm.$set(_vm.addProductForm, "no", $$v)
                          },
                          expression: "addProductForm.no",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库数量", prop: "count" } },
                    [
                      _c("el-input", {
                        attrs: { max: "8", placeholder: "入库数量" },
                        on: {
                          input: function ($event) {
                            return _vm.verifyInt($event, "count")
                          },
                        },
                        model: {
                          value: _vm.addProductForm.count,
                          callback: function ($$v) {
                            _vm.$set(_vm.addProductForm, "count", $$v)
                          },
                          expression: "addProductForm.count",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "生产日期" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "width-inherit",
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.addProductForm.produceTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.addProductForm, "produceTime", $$v)
                          },
                          expression: "addProductForm.produceTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "到期时间", prop: "expireTime" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "width-inherit",
                        attrs: {
                          type: "datetime",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          placeholder: "选择日期",
                        },
                        model: {
                          value: _vm.addProductForm.expireTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.addProductForm, "expireTime", $$v)
                          },
                          expression: "addProductForm.expireTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible_1,
            "before-close": _vm.closeDialog,
            width: "90%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width-140",
                            attrs: { placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                _vm.selectValue_1 = ""
                              },
                            },
                            model: {
                              value: _vm.selectKey_1,
                              callback: function ($$v) {
                                _vm.selectKey_1 = $$v
                              },
                              expression: "selectKey_1",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { label: "批次", value: "batchNo" },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label: "产品编号",
                                value: "productCode",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label: "产品名称",
                                value: "productName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "width-180",
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.selectValue_1,
                            callback: function ($$v) {
                              _vm.selectValue_1 = $$v
                            },
                            expression: "selectValue_1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width-140",
                            attrs: { placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                _vm.selectValue_2 = ""
                              },
                            },
                            model: {
                              value: _vm.selectKey_2,
                              callback: function ($$v) {
                                _vm.selectKey_2 = $$v
                              },
                              expression: "selectKey_2",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: "生产时间",
                                value: "produceTime",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: { label: "到期时间", value: "expireTime" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex-box" },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "daterange",
                                "value-format": "yyyy-MM-dd",
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                              },
                              model: {
                                value: _vm.daterange,
                                callback: function ($$v) {
                                  _vm.daterange = $$v
                                },
                                expression: "daterange",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.searchList_1 },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "carTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.batchList, border: true, height: "350px" },
                  on: { "selection-change": _vm.handleProductChange_1 },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      selectable: _vm.selectable,
                      width: "55",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                (_vm.pageParam_1.pageNum - 1) *
                                  _vm.pageParam_1.pageSize +
                                  (scope.$index + 1)
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventoryId",
                      label: "SKU编号",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productId",
                      label: "SPU编号",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "norm",
                      label: "规格",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "categoryName",
                      label: "所属类别",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "batchNo",
                      label: "批次",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "produceTime",
                      label: "生产日期",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "expireTime",
                      label: "到期时间",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "termOfValidity",
                      label: "有效期",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "入库时间",
                      "min-width": "180",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_1.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_1.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_1.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_1,
                      "current-change": _vm.handleCurrentChange_1,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { "padding-top": "30px" } }),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "batchForm",
                  staticClass: "form demo-form-inline",
                  attrs: {
                    model: _vm.batchForm,
                    rules: _vm.batchFormRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "入库数量", prop: "count" } },
                    [
                      _c("el-input", {
                        attrs: { max: "8", placeholder: "入库数量" },
                        on: {
                          input: function ($event) {
                            return _vm.verifyInt($event, "count")
                          },
                        },
                        model: {
                          value: _vm.batchForm.count,
                          callback: function ($$v) {
                            _vm.$set(_vm.batchForm, "count", $$v)
                          },
                          expression: "batchForm.count",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd_1 } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }