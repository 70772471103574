<!-- 经销产品订单-已完成订单 -->
<template>
  <div class="main-box">
    <e-commerce orderStatus="completed" :externalProductFlag="true"></e-commerce>
  </div>
</template>

<script>
import eCommerce from '@/components/order/eCommerce.vue'

export default {
  components: {
    eCommerce
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped></style>
