<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="年份">
          <el-date-picker v-model="inquire.year" type="year" value-format="yyyy" placeholder="年份">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="年月">
          <el-date-picker v-model="daterange" type="monthrange" format="yyyy-MM" value-format="yyyy-MM" range-separator="至" start-placeholder="开始月份" end-placeholder="结束月份">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="营养师名称">
          <el-input v-model="inquire.dietitianName" placeholder="请输入营养师名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
          <el-button type="primary" size="small" @click="searchReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="exportFile">导出</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData1" v-loading="loading1" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60" align="center">
              <template v-slot="scope">{{( pageParam1.pageNum - 1) * pageParam1.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column label="营养师编号" align="center" prop="dietitianCode"></el-table-column>
            <el-table-column label="营养师名称" align="center" prop="dietitianName"></el-table-column>
            <el-table-column label="咨询人数" align="center" prop="consultNum"></el-table-column>
            <el-table-column label="评级人数" align="center" prop="evaluateNum"></el-table-column>
            <el-table-column label="评分（均分）" align="center" prop="averageScore"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetail1(scope.row)">查看详情</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1" background :current-page="pageParam1.pageNum" :page-size="pageParam1.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam1.total"></el-pagination>
        </div>
      </el-main>
    </div>
    <el-dialog title="评价列表" :visible.sync="dialogVisible1" width="1000px" :before-close="handleClose1">
      <div class="table">
        <el-main>
          <template>
            <el-table :data="tableData2" v-loading="loading2" border height="400px" style="width: 100%">
              <el-table-column prop="date" label="序号" width="60" align="center">
                <template v-slot="scope">{{( pageParam2.pageNum - 1) * pageParam2.pageSize + (scope.$index + 1) }}</template>
              </el-table-column>
              <el-table-column label="微信昵称" align="center" prop=""></el-table-column>
              <el-table-column label="评分" align="center" prop=""></el-table-column>
              <el-table-column label="评语" align="center" prop=""></el-table-column>
              <el-table-column label="状态" align="center" prop=""></el-table-column>
              <el-table-column label="评价时间" align="center" prop="createTime" min-width="160"></el-table-column>
              <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                  <span class="operation-btn" @click="handleDetail2(scope.row)">详情</span>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <div class="pagination">
            <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" background :current-page="pageParam2.pageNum" :page-size="pageParam2.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam2.total"></el-pagination>
          </div>
        </el-main>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" @click="handleClose1">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="详情" :visible.sync="dialogVisible2" width="600px" :before-close="handleClose2">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="详情:">
          <el-input style="width: 450px" type="textarea" :autosize="{ minRows: 6, maxRows: 6}" v-model="content" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose2">取 消</el-button>
        <el-button type="primary" @click="handleClose2">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        year: '',
        beginTime: '',
        endTime: '',
        dietitianName: '',
      },
      daterange: [],
      pageParam1: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading1: false,
      tableData1: [],
      pageParam2: {
        dietitianId:'',
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading2: false,
      tableData2: [],
      dialogVisible1: false,
      dialogVisible2: false,
      content: '',
      dietitianId: null
    };
  },
  created() {
    // 获取数据列表
    this.searchList()
  },
  methods: {
    // 页容量改变
    handleSizeChange1(size) {
      this.pageParam1.pageNum = 1;
      this.pageParam1.pageSize = size;
      this.getTableList1()
    },
    // 页码改变
    handleCurrentChange1(page) {
      this.pageParam1.pageNum = page;
      this.getTableList1();
    },
    // 刷新
    searchList() {
      this.pageParam1.pageNum = 1
      this.getTableList1()
    },

    // 获取数据列表
    getTableList1() {
      if (!this.daterange) this.daterange = [];
      this.inquire.beginTime = this.daterange.length > 0 ? this.daterange[0] : '';
      this.inquire.endTime = this.daterange.length > 0 ? this.daterange[1] : '';
      let data = {
        pageNum: this.pageParam1.pageNum,
        pageSize: this.pageParam1.pageSize,
        dietitianName: this.inquire.dietitianName,
        beginTime: this.inquire.beginTime,
        endTime: this.inquire.endTime,
        year: this.inquire.year
      }

      this.loading1 = true;
      this.$http.requestPost({
        url: '/consult/getEvaluateRecordList',
        param: data,
        loading: false
      }).then(res => {
        this.loading1 = false;
        this.tableData1 = res.data.list;
        this.pageParam1.total = res.data.total;
      }).catch(err => {
        this.loading1 = false;
        console.log(err)
      })
    },

    searchReset() {
      this.inquire.year = '';
      this.inquire.dietitianName = '';
      this.daterange = [];
    },

    exportFile() {
      const param = JSON.parse(JSON.stringify(this.inquire));
      this.$http
        .download({
          url: "/customer/xxxxxx/xxxxxxx",
          param,
          method: "POST",
        })
        .then((res) => {
          this.$public.downloadFile(res);
        });
    },

    handleDetail1(val) {
      this.dietitianId = val.dietitianId
      this.dialogVisible1 = true
      this.getTableList2()
    },
    handleClose1() {
      this.dialogVisible1 = false
    },
    handleDetail2(val) {
      console.log('详情2================', val);
      this.dialogVisible2 = true
    },

    handleSizeChange2(size) {
      this.pageParam2.pageNum = 1;
      this.pageParam2.pageSize = size;
      this.getTableList2()
    },
    // 页码改变
    handleCurrentChange2(page) {
      this.pageParam2.pageNum = page;
      this.getTableList2();
    },
    // 刷新
    searchList2() {
      this.pageParam2.pageNum = 1
      this.getTableList2()
    },

    // 点击查看详情获取数据列表
    getTableList2() {
      let data = {
        pageNum: this.pageParam2.pageNum,
        pageSize: this.pageParam2.pageSize,
        dietitianId: this.dietitianId
      }
      this.loading2 = true;
      this.$http.requestPost({
        url: '/consult/getEvaluateDetailList',
        param: data,
        loading: false
      }).then(res => {
        this.loading2 = false;
        this.tableData2 = res.data.list;
        this.pageParam2.total = res.data.total;
      }).catch(err => {
        this.loading2 = false;
        console.log(err)
      })
    },
    handleClose2() {
      this.dialogVisible2 = false
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
