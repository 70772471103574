<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="公司名称">
          <el-input v-model="inquire.companyName" placeholder="公司名称" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增物流公司</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="companyCode" label="物流公司编号" min-width="200" show-overflow-tooltip />
            <el-table-column prop="companyName" label="物流公司名称" min-width="140" show-overflow-tooltip />
            <el-table-column prop="companyContact" label="联系人" min-width="100" />
            <el-table-column prop="companyContactPhoneNumber" label="手机" min-width="140" />
            <el-table-column prop="whetherDefault" label="默认物流公司" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.whetherDefault ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="添加时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="200" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="freightSet(scope.row)">运费</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span v-show="!scope.row.whetherDefault" class="operation-btn_partition"> / </span>
                <span
                  v-show="!scope.row.whetherDefault"
                  class="operation-btn"
                  @click="handleDel(scope.row)"
                >删除</span>
                <span v-show="!scope.row.whetherDefault" class="operation-btn_partition"> / </span>
                <span
                  v-show="!scope.row.whetherDefault"
                  class="operation-btn"
                  @click="stateSwitch(scope.row)"
                >设为默认</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加弹框 -->
    <el-dialog
      :title="dialogTitle + '物流公司'"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="650px"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="120px" :model="submitForm" :rules="submitRules">
          <el-form-item label="物流公司名称" prop="companyName">
            <el-input v-model="submitForm.companyName" maxlength="30" placeholder="物流公司名称" />
          </el-form-item>
          <el-form-item label="联系人" prop="companyContact">
            <el-input v-model="submitForm.companyContact" maxlength="10" placeholder="联系人" />
          </el-form-item>
          <el-form-item label="手机号" prop="companyContactPhoneNumber">
            <el-input v-model="submitForm.companyContactPhoneNumber" maxlength="11" placeholder="手机号" />
          </el-form-item>
          <el-form-item v-show="!isAdd" label="默认物流公司">
            <el-checkbox v-model="submitForm.whetherDefault" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 运费管理 -->
    <el-dialog
      :title="dialogTitle + '运费设置'"
      :visible.sync="dialogVisible_2"
      :before-close="closeDialog_2"
      width="80%"
    >
      <div class="Popout_content">
        <el-form>
          <el-form-item label="运费方式" prop="name">按整个订单计算</el-form-item>
        </el-form>
        <el-form label-width="110px" label-position="top">
          <el-form-item label="收费标准" prop="name">
            <el-radio-group v-model="feeBool">
              <el-radio label="whole">全国地区</el-radio>
              <el-radio label="section">单个设置</el-radio>
            </el-radio-group>

            <div v-if="feeBool === 'whole'" class="flex-box">
              <div class="flex-box">
                <div class="text text-left">默认价</div>
                <el-input
                  v-model="provinceList[0].fee"
                  placeholder="必填"
                  class="width-110"
                  @blur="() => ($validate.verifyMoney(provinceList[0].fee)?'':provinceList[0].fee = '')"
                />
                <div class="text">元</div>
              </div>
            </div>
            <div v-else-if="feeBool === 'section'" class="address-list flex-box">
              <div v-for="(item,index) in provinceList" :key="item.addressId" class="flex-box">
                <div v-if="index !== 0" class="flex-box">
                  <div class="text text-left">{{ item.addressName }}</div>
                  <el-input
                    v-model="item.fee"
                    placeholder="选填"
                    class="width-110"
                    @input="() => ($validate.verifyMoney(item.fee)?'':item.fee = '')"
                  />
                  <div class="text">元</div>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog_2">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import province_ from '@/assets/address/province'

export default {
    data() {
        return {
            // 查询参数
            inquire: {
                companyName: ''
            },
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            loading: false,
            // 表格数据
            tableData: [],

            // 添加
            dialogTitle: '', // 添加弹窗标题
            dialogVisible: false, // 添加弹窗
            submitForm: {
                companyName: '',
                companyContact: '',
                companyContactPhoneNumber: '',
                whetherDefault: false
            },
            submitRules: {
                companyName: [{ required: true, message: '请输入物流公司名称', trigger: 'blur' }],
                companyContact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                companyContactPhoneNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' },
                  {pattern:/^1[3456789]\d{9}$/, message: '手机格式错误', trigger: 'blur'}
                ]
            },
            isAdd: true, // 是否为添加
            editRow: {},

            // ==== 运费 ====
            dialogVisible_2: false,
            feeBool: 'whole', // 收费标准
            provinceList: []// 34个省特区列表
            // china:[{  // 单个全国
            //     "addressId": "100000",
            //     "addressName": "全国",
            //     "fee": ""
            // }],

        }
    },
    created() {
        // 获取数据列表
        this.getTableList()
        this.provinceList = province_
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size
            // 刷新(数据)
            this.getTableList()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page
            // 刷新(页码)
            this.getTableList()
        },
        // 刷新
        searchList() {
          this.pageParam.pageNum = 1
          this.getTableList()
        },

        // 获取数据列表
        getTableList() {
            const data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            }

            this.loading = true
            this.$http.requestPost({
                url: '/system/logistics/company/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false
                this.tableData = res.data.list
                this.pageParam.total = res.data.total
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },

        // 添加
        addition() {
            this.dialogTitle = '新增'
            this.dialogVisible = true
            this.isAdd = true
        },
        // 修改
        handleEdit(row) {
            this.dialogTitle = '编辑'
            this.isAdd = false
            this.submitForm = {
                companyName: row.companyName,
                companyContact: row.companyContact,
                companyContactPhoneNumber: row.companyContactPhoneNumber,
                whetherDefault: row.whetherDefault
            }
            this.dialogVisible = true
            this.editRow = row
        },
        // 提交办事处添加
        submitAdd() {
            this.$refs.submitForm.validate(valid => {
                if (valid) {
                    const param = {
                        companyName: this.submitForm.companyName,
                        companyContact: this.submitForm.companyContact,
                        companyContactPhoneNumber: this.submitForm.companyContactPhoneNumber,
                        whetherDefault: this.submitForm.whetherDefault
                    }
                    if (!this.isAdd) {
                        param.companyId = this.editRow.companyId
                    }
                    this.submit(param)
                } else {
                    return false
                }
            })
        },
        // 添加，修改提交
        submit(param) {
            this.$http.requestPost({
                url: '/system/logistics/company/save',
                param: param
            }).then(res => {
                this.getTableList()
                this.$message({
                    message: '操作成功',
                    type: 'success'
                })
                this.closeDialog()
            })
        },

        // 关闭弹窗
        closeDialog() {
            this.$refs.submitForm && (this.$refs.submitForm.resetFields())
            this.dialogVisible = false
            this.submitForm = {
                companyName: '',
                companyContact: '',
                companyContactPhoneNumber: '',
                whetherDefault: false
            }
        },

        // 删除
        handleDel(row) {
            this.$confirm('确定删除该物流公司?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
                    this.pageParam.pageNum--
                }
                this.$http.requestDel({ url: '/system/logistics/company/del/' + row.companyId }).then(res => {
                    this.getTableList()
                    this.$message({ message: '操作成功', type: 'success' })
                })
                // eslint-disable-next-line handle-callback-err
            }).catch(err => {
            })
        },

        // ============ 运费 ============
        freightSet(row) {
            this.dialogTitle = row.companyName
            this.$http.requestGet({ url: '/system/logistics/company/fee/' + row.companyId, loading: false }).then(res => {
                this.feeBool = res.data.charges
                res.data.fees.forEach(v => {
                    let index = this.provinceList.findIndex(item => item.addressId === v.addressId)
                    this.provinceList[index].fee = v.fee
                })
            })

            this.dialogVisible_2 = true
            this.editRow = row
        },
        // 确认
        confirm() {
            const fees = []
            if (this.provinceList[0].fee === '') return this.$message.warning('全国地区默认价不能为空！')
            this.provinceList.forEach((v,i) => {
                if (v.fee || v.fee == '0') {
                    fees.push(v)
                }
            })
            var param = {
                charges: this.feeBool,
                fees: fees
            }
            this.$http.requestPut({ url: '/system/logistics/company/fee/' + this.editRow.companyId, param }).then(res => {
                this.$message.success('操作成功')
                this.closeDialog_2()
            })
        },
        // 关闭弹窗
        closeDialog_2() {
            this.dialogVisible_2 = false
            // eslint-disable-next-line no-return-assign
            this.provinceList.forEach(v => v.fee = '')
        },

        // ============ 设为默认 ============
        stateSwitch(row) {
            this.$confirm('确定将该公司设置为默认物流公司?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                const param = {
                    whetherDefault: true,
                    companyId: row.companyId
                }
                // 状态的值还不确定
                this.submit(param)
                // eslint-disable-next-line handle-callback-err
            }).catch(err => {
            })
        }

    }
}
</script>

<style lang="scss" scoped>
.address-list {
    flex-wrap: wrap;

    & > div {
        display: inline-flex;
    }
}

.text {
    padding: 0 5px;
}

.text-left {
    min-width: 125px;
    text-align: right;
}
</style>
