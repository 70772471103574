<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="问题">
          <el-input style="width: 300px" v-model="inquire.question" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
          <el-button type="primary" size="small" @click="handleDetail1">欢迎语查看</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60" align="center">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="question" label="问题" min-width="160" align="center"></el-table-column>
            <el-table-column prop="responseContent" label="回复内容" min-width="160" align="center"></el-table-column>
            <el-table-column prop="createTime" label="添加时间" min-width="160" align="center"></el-table-column>
            <el-table-column prop="autoResponseStatus" label="状态" min-width="120" align="center" :formatter="formatter"></el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetail2(scope.row)">查看</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleDetail3(scope.row)">关键词详情</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam.total"></el-pagination>
        </div>
      </el-main>
    </div>

    <el-dialog title="欢迎语查看" :visible.sync="dialogVisible1" width="600px" :before-close="handleClose1">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="欢迎语:" :required="true">
          <el-input style="width: 450px" type="textarea" :autosize="{ minRows: 6, maxRows: 6}" v-model="greeting" placeholder="欢迎欢迎热烈欢迎" disabled></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose1">取 消</el-button>
        <el-button type="primary" @click="handleClose1">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="问题详情" :visible.sync="dialogVisible2" width="600px" :before-close="handleClose2">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="问题:" label-width="100px" :required="true">
          <el-input style="width: 400px" type="text" v-model="xTitle" placeholder="请输入" disabled></el-input>
        </el-form-item>
        <el-form-item label="问题回复:" label-width="100px" :required="true">
          <el-input style="width: 400px" type="textarea" :autosize="{ minRows: 4, maxRows: 4}" v-model="xContent" placeholder="请输入" disabled></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose2">取 消</el-button>
        <el-button type="primary" @click="handleClose2">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="关键词设置" :visible.sync="dialogVisible3" width="600px">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="关键词:" label-width="100px" :required="true">
          <el-input style="width: 400px" type="text" v-model="keyword" placeholder="请输入" disabled></el-input>
        </el-form-item>
        <el-form-item label="是否启用:" label-width="100px">
          <el-radio-group v-model="enable" disabled>
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        question: '',
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [],
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      greeting: '',
      xTitle: '',
      xContent: '',
      keyword: '订单',
      enable: 1
    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    formatter(row) {
      const MAP = {
        1: "启用",
        2: "禁用",
      };
      return MAP[row.autoResponseStatus];
    },
    // 获取数据列表
    getTableList() {
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        question: this.inquire.question
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/consult/queryImAutoResponseList',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    handleDetail1() {
      this.dialogVisible1 = true
      this.$http.requestGet({ url: "/consult/getGreetingByPlatform", loading: false })
        .then((res) => {
          this.greeting = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDetail2(row) {
      this.xTitle = row.question
      this.xContent = row.responseContent
      this.dialogVisible2 = true
    },
    handleDetail3(row) {
      this.dialogVisible3 = true
      this.$http.requestPost({
        url: '/consult/getKeywordList/' + row.id,
        loading: false
      }).then(res => {
        this.keyword = res.data.map(item => item.keyword).join('、')
        this.enable = res.data[0].autoResponseKeywordStatus
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },
    handleClose1() {
      this.dialogVisible1 = false
    },
    handleClose2() {
      this.dialogVisible2 = false
    },

  },
};
</script>

<style lang="scss" scoped>
</style>
