<template>
  <!-- 经销品 售后单管理 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="订单编号">
          <el-input v-model="queryData.orderCode" placeholder="订单编号" clearable @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item label="售后申请时间">
          <el-date-picker
            v-model="datetimerange"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          />
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            ref="tables"
            v-loading="tableData.loading"
            style="width: 100%"
            border
            :data="tableData.list"
            :expand-row-keys="expandList"
            row-key="orderId"
            @expand-change="expandChange"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table :data="scope.row.afterSalesOrderItemVos" border style="width: 100%">
                  <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex-box">
                        <img v-if="scope.row.productImg" class="img-box" :src="scope.row.productImg" alt="" />
                        <div class="hospital-name">
                          <div class="txt_box" :class="{ 'flex-box': !scope.row.productImg, 'flex-center': !scope.row.productImg }">
                            <img
                              v-if="scope.row.isGifts"
                              class="icon_box"
                              :class="{ no_img: !scope.row.productImg }"
                              src="@/assets/image/gift_icon.png"
                              alt=""
                            />
                            {{ scope.row.productName }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="产品编号" min-width="200" prop="productCode" show-overflow-tooltip />
                  <el-table-column label="产品规格" min-width="120" prop="productNorm">
                    <template slot-scope="scope">
                      {{ scope.row.productNorm || '/' }}
                    </template>
                  </el-table-column>
                  <el-table-column label="单价" min-width="120" prop="itemPrice">
                    <template slot-scope="scope">
                      {{ '￥' + scope.row.itemPrice }}
                    </template>
                  </el-table-column>
                  <el-table-column label="数量" min-width="120" prop="itemNum" />
                  <el-table-column label="实付总额" min-width="120" prop="">
                    <template slot-scope="scope">
                      {{ '￥' + (scope.row.paidAmount).toFixed(2) || '0' }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="订单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="toDetail(scope.row)">{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="买家/收货人" min-width="160" prop="receiver" show-overflow-tooltip />
            <el-table-column label="总金额" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + scope.row.orderTotalPrice.toFixed(2) || '0' }}
              </template>
            </el-table-column>
            <el-table-column label="运费" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + scope.row.freight.toFixed(2) || '0' }}
              </template>
            </el-table-column>
            <el-table-column label="售后申请时间" min-width="160" prop="applyTime" />
            <el-table-column label="订单状态" min-width="120" prop="auditStatus">
              <template slot-scope="scope">
                {{ $api.getValue($api.afterSalesOrderStatus, scope.row.auditStatus, 'name') }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="160" prop="remark">
              <template slot-scope="scope">
                <span class="operation-btn" @click="toDetail(scope.row)">{{ scope.row.auditStatus === 1 ? '审核' : '详情' }}</span>
                <span v-if="scope.row.auditStatus===2">
                  <span class="operation-btn_partition">/</span>
                  <span class="operation-btn" @click="getPurchasePostInfo(scope.row)">查看物流</span>
                </span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :current-page="queryData.pageNum"
            :page-size="queryData.pageSize"
            :page-sizes="$api.pageSizes"
            :total="tableData.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 查看物流弹窗 -->
    <d-dialog
      ref="logisticsDialog"
      :logistics-list="logisticsData.list"
      :data-obj="logisticsData.dataObj"
      :route-info="logisticsData.routeInfo"
      @handleClose="closeLogisticsDialog"
    />
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import { reviewedAfterSalesOrder, getAfterSalesOrderLogisticsTrack } from '@/api/order'
import DDialog from '@/components/d-dialog'

export default {
  components: { DDialog },
  props: {
    auditStatus: { // 审核状态：1-待审核，2-审核通过，3-审核不通过
      type: Number,
      required: true,
      default: 2
    }
  },
  data() {
    return {
      datetimerange: [], // 售后申请时间范围
      queryData: {
        pageNum: 1, // 分页参数
        pageSize: 10, // 分页参数
        orderCode: '', // 订单编号
        applyBeginTime: '', // 售后申请开始时间
        applyEndTime: '', // 售后申请结束时间
        auditStatus: 1 // 审核状态：1-待审核，2-审核通过，3-审核不通过
      },
      // 表格数据
      tableData: {
        loading: false,
        list: [],
        total: 0
      },
      // 展开行数据
      expandList: [],

      // 物流数据
      logisticsData: {
        list: [], // 物流信息
        dataObj: {}, // 物流
        routeInfo: {}
      }
    }
  },
  created() {
    // eslint-disable-next-line no-unused-vars
    const userInfo = getSessionStorage('userInfo')
    this.queryData.auditStatus = this.auditStatus
    // 获取缓存参数数据并赋默认值
    if (sessionStorage.getItem('cacheAfterSalesOrderParam')) {
      this.queryData = JSON.parse(sessionStorage.getItem('cacheAfterSalesOrderParam'))
      if (!this.queryData.applyBeginTime || !this.queryData.applyEndTime) {
        this.datetimerange = []
      } else {
        this.datetimerange = [this.queryData.applyBeginTime, this.queryData.applyEndTime]
      }
    }
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.queryData.pageNum = 1
      this.queryData.pageSize = size
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.queryData.pageNum = page
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.queryData.pageNum = 1
      this.getTableList()
    },
    // table 手风琴
    expandChange(newVal, oldVal) {
      if (oldVal.length === 0) {
        return (this.expandList = [])
      }
      this.expandList = [newVal.orderId]
    },

    // 获取列表数据
    async getTableList() {
      this.tableData.loading = true
      if (!this.datetimerange) this.datetimerange = []
      this.queryData.applyBeginTime = this.datetimerange[0] || ''
      this.queryData.applyEndTime = this.datetimerange[1] || ''
      const res = await reviewedAfterSalesOrder({ ...this.queryData })
      if (res.code === 200) {
        if (!res.data.list.length && (this.queryData.orderCode || this.datetimerange.length)) {
          this.$alert('请检查输入订单号是否正确或业务类型是否匹配', '无订单提示', {
            confirmButtonText: '关闭'
          })
        }
        this.tableData.list = res.data.list
        this.tableData.total = res.data.total
        this.tableData.loading = false
        // 清除缓存参数数据
        sessionStorage.removeItem('cacheAfterSalesOrderParam')
      } else {
        this.tableData.loading = false
        // 清除缓存参数数据
        sessionStorage.removeItem('cacheAfterSalesOrderParam')
        console.log('报错了====>>>>>', res)
      }
    },

    // 订单详情
    toDetail(row) {
      sessionStorage.setItem('cacheAfterSalesOrderParam', JSON.stringify(this.queryData))
      const auditStatus = row.auditStatus
      this.$router.push({
        path: '/order/ExternalProductOrder/afterSalesOrder/orderDetail',
        query: {
          auditStatus: auditStatus,
          orderId: row.orderId
        }
      })
    },

    // 查看物流
    async getPurchasePostInfo(row) {
      const res = await getAfterSalesOrderLogisticsTrack(row.orderId)
      if (!res.data || (res.code == 200 && res.data.status != 200)) {
        this.$message({
          message: '查无结果，请检查单号和快递公司是否有误',
          type: 'warning',
          duration: 3000
        })
      } else {
        this.logisticsData.dataObj = res.data
        this.logisticsData.routeInfo = res.data.routeInfo
        this.logisticsData.list = res.data.data
        this.$refs.logisticsDialog.showDialog = true
      }
    },
    // 关闭物流弹窗
    closeLogisticsDialog() {
      this.$refs.logisticsDialog.showDialog = false
      //  关闭时置空
      this.logisticsData.list = []
    }
  }
}
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
  width: 375px;
}

.el-head-search-row {
  .flex-box {
    width: 326px;
  }
}

.img-box {
  width: 58px;
  height: 58px;
}

.icon_box {
  width: 21px;
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  &.no_img {
    margin-right: 3px;
    position: static;
  }
}

.txt_box {
  position: relative;
  line-height: 1;
}
</style>
