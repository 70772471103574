var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "submitForm",
          attrs: { "label-width": "120px", "label-position": "left" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "模板名称", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { "max-width": "600px" },
                attrs: {
                  maxlength: "20",
                  placeholder: "模板名称",
                  clearable: "",
                  disabled: "",
                },
                model: {
                  value: _vm.templateName,
                  callback: function ($$v) {
                    _vm.templateName = $$v
                  },
                  expression: "templateName",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            _vm._l(_vm.singleList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "flex-box flex-wrap" },
                [
                  _c(
                    "div",
                    { staticClass: "topic-box" },
                    [
                      [
                        _c("div", { staticClass: "topic-type" }, [
                          _c("label", [_vm._v(_vm._s(index + 1) + "、单选题")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "topic-row flex-box" }, [
                          _c("label", { staticClass: "label-box" }, [
                            _vm._v("题目"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "content-box" },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "30",
                                  placeholder: "题目",
                                  disabled: "",
                                },
                                model: {
                                  value: item.content,
                                  callback: function ($$v) {
                                    _vm.$set(item, "content", $$v)
                                  },
                                  expression: "item.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(item.optionList, function (option, optionIndex) {
                          return _c(
                            "div",
                            {
                              key: optionIndex,
                              staticClass: "topic-row flex-box",
                            },
                            [
                              _c("label", { staticClass: "label-box" }, [
                                _vm._v("选项"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "option-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "option-li flex-box" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        placeholder: "选项",
                                        disabled: "",
                                      },
                                      model: {
                                        value: option.content,
                                        callback: function ($$v) {
                                          _vm.$set(option, "content", $$v)
                                        },
                                        expression: "option.content",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        }),
                      ],
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            _vm._l(_vm.multipleList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "flex-box flex-wrap" },
                [
                  _c(
                    "div",
                    { staticClass: "topic-box" },
                    [
                      [
                        _c("div", { staticClass: "topic-type" }, [
                          _c("label", [_vm._v(_vm._s(index + 1) + "、多选题")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "topic-row flex-box" }, [
                          _c("label", { staticClass: "label-box" }, [
                            _vm._v("题目"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "content-box" },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "30",
                                  placeholder: "题目",
                                  disabled: "",
                                },
                                model: {
                                  value: item.content,
                                  callback: function ($$v) {
                                    _vm.$set(item, "content", $$v)
                                  },
                                  expression: "item.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(item.optionList, function (option, optionIndex) {
                          return _c(
                            "div",
                            {
                              key: optionIndex,
                              staticClass: "topic-row flex-box",
                            },
                            [
                              _c("label", { staticClass: "label-box" }, [
                                _vm._v("选项"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "option-box" }, [
                                _c(
                                  "div",
                                  { staticClass: "option-li flex-box" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        maxlength: "30",
                                        placeholder: "选项",
                                        disabled: "",
                                      },
                                      model: {
                                        value: option.content,
                                        callback: function ($$v) {
                                          _vm.$set(option, "content", $$v)
                                        },
                                        expression: "option.content",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          )
                        }),
                      ],
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            _vm._l(_vm.answerList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "flex-box flex-wrap" },
                [
                  _c(
                    "div",
                    { staticClass: "topic-box" },
                    [
                      [
                        _c("div", { staticClass: "topic-type" }, [
                          _c("label", [_vm._v(_vm._s(index + 1) + "、简答题")]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "topic-row flex-box" }, [
                          _c("label", { staticClass: "label-box" }, [
                            _vm._v("题目"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "content-box" },
                            [
                              _c("el-input", {
                                attrs: {
                                  maxlength: "30",
                                  placeholder: "题目",
                                  disabled: "",
                                },
                                model: {
                                  value: item.content,
                                  callback: function ($$v) {
                                    _vm.$set(item, "content", $$v)
                                  },
                                  expression: "item.content",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                    ],
                    2
                  ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }