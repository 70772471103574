import request from '@/utils/request'

// 导出
export function exportOrderList(param = {}, method = 'get') {
  return request.download({
    url: '/customer/order/exportOrders',
    param,
    method
  })
}
// 营养餐导出
export function exportNutritionList(param = {}, method = 'post') {
  return request.download({
    url: '/customer/order/exportNutritionalOrders',
    param,
    method
  })
}

// 开发票导出
export function exportInvoice(param = {}, method = 'post') {
  return request.download({
    url: '/customer/invoice/exportInvoice',
    param,
    method
  })
}

// 经销产品导出
export function externalExportOrdersList(param = {}, method = 'post') {
  return request.download({
    url: '/customer/order/externalExportOrders',
    param,
    method
  })
}

// 申购单导出
// 导出1.0接口
// export function exportPurchaseOrders(param = {}, method = 'post') {
//     return request.download({
//         url: '/customer/purchase/order/exportPurchaseOrders',
//         param,
//         method,
//     })
// }
// // 退货单导出
// export function exportReturnPurchaseOrders(param = {}, method = 'post') {
//     return request.download({
//         url: '/customer/return/order/exportReturnOrders',
//         param,
//         method,
//     })
// }
// 导出2.0接口
export function newExportPurchaseOrders(param = {}, method = 'post') {
  return request.download({
    url: '/customer/purchase/order/newExportPurchaseOrders',
    param,
    method
  })
}
export function newExportReturnPurchaseOrders(param = {}, method = 'post') {
  return request.download({
    url: '/customer/return/order/newExportReturnOrders',
    param,
    method
  })
}

// 医院导出
export function hospitalExportHospital(param = {}, method = 'post') {
  return request.download({
    url: '/hospital/medical/exportHospital',
    param,
    method
  })
}

// 医院导出
export function hospitalExportHospital1(param = {}, method = 'post') {
  return request.download({
    url: '/hospital/medical/exportHospital/1',
    param,
    method
  })
}

// 医院导出
export function hospitalExportHospital2(param = {}, method = 'post') {
  return request.download({
    url: '/hospital/medical/exportHospital/0',
    param,
    method
  })
}
// 医院地址导出
export function exportHospitalAddress(param = {}, method = 'post') {
  return request.download({
    url: '/hospital/shipping/address/exportHospitalAddress',
    param,
    method
  })
}
// 仓库 申购单出库导出
export function exportPurchaseOrderOutbound(param = {}, method = 'post') {
  return request.download({
    url: '/customer/purchase/order/exportPurchaseOrderOutbound',
    param,
    method
  })
}

// 文件单个下载
export function exportfileDownload(id, method = 'get') {
  return request.download({
    url: `/exportfile/download/${id}`,
    method
  })
}
// 批量下载
export function exportfileDownloadBatch(param, method = 'post') {
  return request.download({
    url: '/exportfile/downloadBatch',
    param,
    method
  })
}

// 文件删除
export function deleteLogic(id, method = 'get') {
  return request.requestGet({
    url: `/exportfile/deleteLogic/${id}`,
    method
  })
}

// 详情
export function getQuestionnaireDetailPlatform(id, method = 'get') {
  return request.requestGet({
    url: `/consult/getQuestionnaireDetailPlatform/${id}`,
    method
  })
}

export function exportMerchantInventoryRecords(id, method = 'get') {
  return request.download({
    url: `/customer/merchant/exportMerchantInventoryRecords/${id}`,
    method
  })
}

// 专员列表导出
export function zyExportDetails(param = {}, method = 'post') {
  return request.download({
    url: '/wait/payment/merchant/commission/report/exportDetails',
    param,
    method
  })
}

// 专员导出
export function exportSpecialistAddress(param = {}, method = 'post') {
  return request.download({
    url: '/customer/attache/exportSpecialistAddress',
    param,
    method
  })
}

/**
 * 经销品售后订单列表
 * @param { Number } pageNum - 分页参数
 * @param { Number } pageSize - 分页参数
 * @param { String } orderCode - 订单编号
 * @param { String } applyBeginTime - 售后申请开始时间
 * @param { String } applyEndTime - 售后申请结束时间
 * @param { Number } auditStatus - 审核状态：1-待审核，2-审核通过，3-审核不通过
 */
export function reviewedAfterSalesOrder(param) {
  return request.requestPost({
    url: `/customer/order/reviewedAfterSalesOrder`,
    method: 'post',
    param,
    loading: false
  })
}
/**
 * 经销品售后审核/驳回
 * @param { Number } orderId - 订单id
 * @param { Number } auditStatus - 审核状态：1-待审核，2-审核通过，3-审核不通过
 * @param { String } logisticsCompany - 物流公司（当auditStatus为3时不传）
 * @param { String } logisticsNo - 物流单号（当auditStatus为3时不传）
 * @param { String } rejectReason - 驳回原因（当auditStatus为2时不传）
 */
export function modifyAfterSalesOrderStatus(param) {
  return request.requestPut({
    url: `/customer/order/modifyAfterSalesOrderStatus`,
    method: 'put',
    param,
    loading: true
  })
}
/**
 * 获取经销品售后单物流轨迹
 * @param { Number } orderId - 订单id
 */
export function getAfterSalesOrderLogisticsTrack(orderId) {
  return request.requestGet({
    url: `/customer/order/getAfterSalesOrderLogisticsTrack/${orderId}`,
    method: 'get',
    loading: true
  })
}
