<template>
  <div class="main-box">
    <groupChatList :type="type"></groupChatList>
  </div>
</template>

<script>
  import groupChatList from '@/components/consult/groupChatList.vue'
export default {
  components:{
    groupChatList,
  },
  data() {
      return {
        type:'use',
      };
  },
  created() {
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

</style>
