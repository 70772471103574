<template>
  <div class="d-dialog">
    <el-dialog
        :visible.sync="showDialog"
        width="70%"
        :before-close="handleClose"
        class="roll_dialog"
        top="10px"
        @open="init()"
    >
      <!-- v4.4.5 -->
      <div class="infotitle">
        <div class="left_box">
          <div>运单号 {{ dataObj ? dataObj.nu : "" }}</div>
          <div class="city_box">
            <span>{{ from }}</span>
            <span
                v-if="routeInfo && routeInfo.to"
                class="iconfont icon"
            >&#xe7e4;</span>
            <span v-if="routeInfo && routeInfo.to">{{ to }}</span>
          </div>
        </div>
        <div class="right_box">
          <div v-if="isShow(dataObj ? dataObj.state : '')" class="time_box">
            <div>签收时间</div>
            <div>{{ time }}</div>
          </div>
          <div v-else class="status">{{ status }}</div>
        </div>
      </div>
      <div id="container" class="map"/>
      <div class="information_box">
        <div
            v-for="(item, index) in logisticsList"
            :key="index"
            class="information"
        >
          <div class="info">{{ item.status }}</div>
          <div class="time">{{ item.time }}</div>
          <div class="context">{{ item.context }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/javascript">
import icon from "@/assets/image/mapMarker-to.png";
import icon_2 from "@/assets/image/mapMarker-from.png";

const x_PI = 3.14159265358979324 * 3000.0 / 180.0
const PI = 3.14159265358979324
const a = 6378245.0
const ee = 0.00669342162296594323
export default {
  name: "DDialog",
  data() {
    return {
      showDialog: false,
      map: null,
      position: [],
      position_2: [],
      path: [], //折线路径
      marker: null, //终点
      marker_2: null, //起点
      skyMapType:false, // 终点是否为wgp84坐标系
    };
  },
  props: {
    logisticsList: {
      type: Array,
      default: () => [],
    },
    dataObj: {
      type: Object,
      default: () => {
      },
    },
    routeInfo: {
      type: Object,
      default: () => {
      },
    },
  },
  computed:   {
    from() {
      if (this.routeInfo && this.routeInfo.from) {
        let find = this.routeInfo.from.name.includes(",");
        if (find) {
          let arr = this.routeInfo.from.name.split(",");
          return arr[1];
        } else {
          return this.routeInfo.from.name;
        }
      }
    },
    to() {
      if (this.routeInfo && this.routeInfo.to) {
        let find = this.routeInfo.to.name.includes(",");
        if (find) {
          let arr = this.routeInfo.to.name.split(",");
          return arr[1];
        } else {
          return this.routeInfo.to.name;
        }
      }
    },
    status() {
      // 状态
      if (this.logisticsList.length) {
        return this.logisticsList[0].status;
      }
    },
    time() {
      if (this.logisticsList.length) {
        let arr = this.logisticsList[0].ftime.split("-");
        let arr1 = arr[2].split(" ");
        let m = arr[1].replace(/\b(0+)/gi, "");
        let d = arr1[0];
        let h = arr1[1].slice(0, 5);
        return `${m}月${d}日 ${h}`;
      }
    },
  },
  methods: {
    // 是否显示签收时间
    isShow(state) {
      if (state) {
        if (
            state == "3" ||
            state == "301" ||
            state == "302" ||
            state == "303" ||
            state == "304"
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    init() {
      this.$nextTick(() => {
        // 初始化地图对象
        // this.map = new T.Map("container")
        let zoom = 5
        // 设置显示地图的中心点和级别

        if(!this.map){
          this.map = new T.Map("container");
        }
        //设置显示地图的中心点和级别
        this.map.centerAndZoom(new T.LngLat(116.40969, 39.89945), zoom);
      });
      this.$nextTick(async () => {
        await this.addMarker();
        this.addMarker_2();
      });
    },
    // 销毁地图
    destroyMap() {
      this.map && this.map.clearOverLays();
      console.info("地图已销毁");
    },
    // 清除 marker
    clearMarker() {
      if (this.marker || this.marker_2) {
        this.marker = null
        this.marker_2 = null
      }
    },
    // 实例化点标记
    async addMarker() {
      // console.log('触发了吗')
      // 自定义标注图片
      let iconDom = new T.Icon({
        iconUrl: icon,
        iconSize: new T.Point(25, 25),
        iconAnchor: new T.Point(10, 25)
      })
      // 定义天地图返回参数
      const searchResult = (result) => {
        if (result.getStatus() == 0) {
          console.log(result.getLocationPoint().lng,result.getLocationPoint().lat,'tiaditu1')
          //创建标注对象
          this.marker = new T.Marker(result.getLocationPoint(), {icon: iconDom});
          //向地图上添加标注
          // this.$set(this.logisticsList[0].areaCenter,`${result.getLocationPoint().lng},${result.getLocationPoint().lat}`)
          this.logisticsList[0].areaCenter = `${result.getLocationPoint().lng},${result.getLocationPoint().lat}`
          this.map.addOverLay(this.marker);
          this.skyMapType = true
          this.doText();
          this.doline();
        } else {
          alert(result.getMsg());
        }
      }
      // 处理经纬度数据
      if (this.logisticsList.length) {
        console.log(this.logisticsList[1], "触发了吗");
        if (this.logisticsList[0].areaCenter) {
          this.position = this.logisticsList[0].areaCenter.split(",");
          if (this.routeInfo) {
            // 创建标注对象
            // 向地图上添加标注
            let lnglat = this.wgs84togcj02(Number(this.position[0]), Number(this.position[1]))
            this.marker = new T.Marker(new T.LngLat(lnglat[0], lnglat[1]), {icon: iconDom})
            this.map.addOverLay(this.marker)
            this.doText();
            this.doline();
          }
        } else {
          let geocoder = new T.Geocoder();
          await geocoder.getPoint(this.dataObj.recipientAddressDetail, searchResult);
        }
      }
    },
    // 实例化点标记
    addMarker_2() {
      // 处理经纬度数据
      if (this.logisticsList.length) {
        for (let i = this.logisticsList.length - 1  ; i >= 0; i--) {
          if (this.logisticsList[i].areaCenter) {
            this.position_2 = this.logisticsList[i].areaCenter.split(",");
            break
          }
        }
        if (this.routeInfo && this.routeInfo.from !== null) {
          let iconDom = new T.Icon({
            iconUrl: icon_2,
            iconSize: new T.Point(25, 25),
            iconAnchor: new T.Point(10, 25)
          })
          let lnglat = this.wgs84togcj02(Number(this.position_2[0]), Number(this.position_2[1]))
          this.marker_2 = new T.Marker(new T.LngLat(lnglat[0], lnglat[1]), {icon: iconDom})
          this.map.addOverLay(this.marker_2)
        }
      }
    },
    // 折现绘制
    doline() {
      if (this.logisticsList.length) {
        let path = this.logisticsList.filter((item) => item.areaCenter);
        console.log(path, 'path',this.logisticsList)
        this.path = path.map((element) => {
          element.areaCenter = element.areaCenter.split(",");
          return element.areaCenter;
        });
        let points = [];
        this.path.forEach((item,index) => {
          console.log(item,'index')
          let lnglat = [];
          if(this.skyMapType && index === 0){
            lnglat = item
          }else{
            lnglat = this.wgs84togcj02(Number(item[0]), Number(item[1]))
          }
          points.push(new T.LngLat(lnglat[0], lnglat[1]));
        })
        //创建线对象
        let line = new T.Polyline(points);
        //向地图上添加线
        this.map.addOverLay(line);
      }
    },
    // 文本标记
    doText() {
      // 创建纯文本标记
      let label = new T.Label({
        text: `${this.logisticsList[0].status}`,
        position: this.marker.getLngLat(),
        offset: new T.Point(3, -30),
      });
      label.setFontColor('#fff')
      label.setBackgroundColor('#000')
      label.setBorderColor('#000')
      this.map.addOverLay(label);
    },
    handleClose() {
      this.$emit("handleClose");
      this.destroyMap();
      this.clearMarker();
      this.path = [];
      this.position = [];
      this.position_2 = [];
    },
    /**
     * 判断是否在国内，不在国内则不做偏移
     * @param {*} lng
     * @param {*} lat
     */
    outOfChina(lng, lat) {
      return (lng < 72.004 || lng > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false)
    },

    /**
     * 经度转换
     * @param { Number } lng
     * @param { Number } lat
     */
    transformlat(lng, lat) {
      var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
      ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
      ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0
      ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0
      return ret
    },

    /**
     * 纬度转换
     * @param { Number } lng
     * @param { Number } lat
     */
    transformlng(lng, lat) {
      var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
      ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0
      ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0
      ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0
      return ret
    },
    /**
     * WGS84坐标系转火星坐标系GCj02 / 即WGS84 转谷歌、高德
     * @param { Number } lng:需要转换的经纬
     * @param { Number } lat:需要转换的纬度
     * @return { Array } result: 转换后的经纬度数组
     */
    wgs84togcj02(lng, lat) {
      if (this.outOfChina(lng, lat)) {
        return [lng, lat]
      } else {
        var dlat = this.transformlat(lng - 105.0, lat - 35.0)
        var dlng = this.transformlng(lng - 105.0, lat - 35.0)
        var radlat = lat / 180.0 * PI
        var magic = Math.sin(radlat)
        magic = 1 - ee * magic * magic
        var sqrtmagic = Math.sqrt(magic)
        dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
        dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
        const mglat = lat + dlat
        const mglng = lng + dlng
        return [mglng, mglat]
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.roll_dialog .el-dialog__body {
  text-align: center;

  .map {
    width: 100%;
    height: 600px;
    margin-top: 10px;
    // text-align: center;
    // line-height: 400px;
    ::v-deep .amap-icon {
      width: 25px;
      height: 25px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .infotitle {
    width: calc(100% - 200px);
    // top: -5px;
    background: #fff;
    opacity: 0.8;
    z-index: 999;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_box {
      flex: 1;

      div {
        font-weight: bold;
        color: #000;
      }

      .city_box {
        margin-top: 10px;
        font-size: 20px;

        .icon {
          margin: 0 40px;
        }
      }
    }

    .right_box {
      .time_box {
        font-weight: bold;
        color: #000;

        div {
          &:nth-child(2) {
            color: red;
            font-size: 20px;
            margin-top: 10px;
          }
        }
      }

      .status {
        color: #000;
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .information_box {
    height: 500px;
    overflow-y: auto;
    padding: 20px 30px;

    .information {
      display: flex;
      justify-content: flex-start;
      height: 32px;
      margin-bottom: 10px;

      .info {
        //  flex: 0.1;
        width: 50px;
      }

      .time {
        // flex: 0.2;
        text-align: center;
        margin: 0px 50px;
      }

      .context {
        flex: 1;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -moz-box;
        -moz-line-clamp: 2;
        -moz-box-orient: vertical;
        overflow-wrap: break-word;
        word-break: break-all;
        white-space: normal;
        overflow: hidden;
      }
    }
  }
}
</style>
