<template>
    <div class="main-box">
        <div class="head-search-row">
            <el-form :inline="true" class="demo-form-inline" size="medium">
                <el-form-item label="申购单编号">
                    <el-input v-model="inquire.orderCode" placeholder="申购单编号" @keyup.enter.native="searchList"/>
                </el-form-item>

                <el-form-item label="审核时间">
                    <el-date-picker
                        v-model="daterange"
                        end-placeholder="结束日期"
                        start-placeholder="开始日期"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                    />
                </el-form-item>

                <el-form-item label="产品名称">
                    <el-input v-model="inquire.productName" placeholder="产品名称" @keyup.enter.native="searchList"/>
                </el-form-item>

                <el-form-item label="商家名称">
                    <el-input v-model="inquire.merchantName" placeholder="商家名称" @keyup.enter.native="searchList"/>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" @click="searchList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div style="margin: 20px 0 0 20px">
            <el-button size="small" type="primary" @click="exportFile">导出</el-button>
        </div>

        <div class="table">
            <el-main>
                <template>
                    <el-table
                        ref="tables"
                        :data="tableData"
                        :expand-row-keys="expands"
                        border
                        height="400px"
                        row-key="orderCode"
                        style="width: 100%"
                        @expand-change="expandChange"
                    >
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-table :data="scope.row.items" border style="width: 100%">
                                    <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div class="flex-box">
                                                <img
                                                    v-if="scope.row.productImg"
                                                    :src="scope.row.productImg"
                                                    alt=""
                                                    class="img-box"
                                                />
                                                <div class="hospital-name">{{ scope.row.productName }}</div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        label="产品编号"
                                        min-width="160"
                                        prop="productCode"
                                        show-overflow-tooltip
                                    />
                                    <el-table-column label="产品规格" min-width="120" prop="productNorms" />
                                    <el-table-column label="申购单价" min-width="120" prop="itemPrice">
                                        <template slot-scope="scope">{{ "￥" + scope.row.itemPrice }}</template>
                                    </el-table-column>
                                    <el-table-column label="申购数量" min-width="120" prop="itemNum" />
                                    <el-table-column label="申购总价" min-width="120" prop="">
                                        <template slot-scope="scope">
                                            {{ "￥" + (scope.row.itemNum * scope.row.itemPrice).toFixed(2) }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column label="申购单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleDetails(scope.row)">{{
                                    scope.row.orderCode
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="商家" min-width="160" prop="merchantName" />
                        <el-table-column label="商家编号" min-width="200" prop="merchantCode" />
                        <el-table-column label="申购总价" min-width="120" prop="">
                            <template slot-scope="scope">
                                {{ "￥" + getTotalPrices(scope.row.items) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="审核时间" min-width="160" prop="auditTime" />
                        <el-table-column label="审核人" min-width="120" prop="auditByName" />
                        <el-table-column fixed="right" label="操作" min-width="120" prop="">
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination
                        :current-page="pageParam.pageNum"
                        :page-size="pageParam.pageSize"
                        :page-sizes="$api.pageSizes"
                        :total="pageParam.total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    />
                </div>
            </el-main>
        </div>
        <!-- 产品导出 -->
        <product-export
            ref="isProductExport"
            :showDialog="showExportDialog"
            :close.sync="showExportDialog"
            :purchaseStatus="inquire.purchaseStatus"
            productType="purchase"
            title="审核未通过申购单导出"
            sgDateTitle="申购时间"
        ></product-export>
    </div>
</template>

<script>
import { setSessionStorage, getSessionStorage } from "@/utils/auth";
import productExport from "@/components/productExport";
export default {
    components: {
        productExport
    },
    data() {
        return {
            // 查询
            inquire: {
                purchaseStatus: "unaudited",
                auditTimeBegin: "",
                auditTimeEnd: "",
                productName: "",
                orderCode: "",
                merchantName: ""
            },
            daterange: [],

            // 分页
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            // 表格数据
            tableData: [],
            loading: false,
            expands: [], // 当前展示行

            stateList: [
                { name: "审核中", value: "auditing" },
                { name: "已审核", value: "audited" },
                { name: "未通过", value: "unaudited" },
                { name: "已完成", value: "completed" }
            ],
            showExportDialog: false
        };
    },
    created() {
        let page = getSessionStorage("unauditedPage");
        if (page && Object.keys(page).length > 0) {
            this.pageParam.pageNum = page.pageNum;
            this.pageParam.pageSize = page.pageSize;
        }
        if(sessionStorage.getItem("cacheParamLc")){
            this.inquire = JSON.parse(sessionStorage.getItem("cacheParamLc"))
            this.daterange[0] = this.inquire.auditTimeBegin || ''
            this.daterange[1] = this.inquire.auditTimeEnd || ''
        }

        this.getDataList();
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size;
            this.getDataList();
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page;
            this.getDataList();
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1;
            this.getDataList();
        },
        // table 手风琴
        expandChange(news, old) {
            if (old.length === 0) {
                return (this.expands = []);
            }
            this.expands = [news.orderCode];
        },
        // 计算总价
        getTotalPrices(arr = []) {
            let prices = 0;
            arr.forEach(v => {
                prices += parseInt(v.itemNum) * v.itemPrice;
            });
            return prices.toFixed(2);
        },
        exportFile() {
            this.showExportDialog = true;
            this.$refs.isProductExport.open();
        },
        // 获取数据列表
        getDataList() {
            if (!this.daterange) this.daterange = [];
            this.inquire.auditTimeBegin = this.daterange.length > 0 ? this.daterange[0] : "";
            this.inquire.auditTimeEnd = this.daterange.length > 0 ? this.daterange[1] : "";
            if(getSessionStorage('userInfo').managerType !== null && getSessionStorage('userInfo').managerType !== ''){
              this.inquire.managerType = getSessionStorage('userInfo').managerType;
            }
            if(getSessionStorage('userInfo').areaId !== null && getSessionStorage('userInfo').areaId !== ''){
              this.inquire.areaId = getSessionStorage('userInfo').areaId
            }
            const param = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            };
            this.$http
                .requestPost({ url: "/customer/purchase/order/newPage", param: param, loading: false })
                .then(res => {
                    this.loading = false;
                    this.tableData = res.data.list;
                    this.pageParam.total = res.data.total;
                    sessionStorage.removeItem("cacheParamLc")
                })
                .catch(err => {
                    this.loading = false;
                    sessionStorage.removeItem("cacheParamLc")
                    console.log(err);
                });
        },

        // 详情
        handleDetails(row) {
            setSessionStorage("unauditedPage", this.pageParam);
            sessionStorage.setItem("cacheParamLc", JSON.stringify(this.inquire))
            this.$router.push({
                path: "/form/purchaseRequisition/particulars",
                query: {
                    type: 3,
                    id: row.orderCode
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
    width: 375px;
}

.el-head-search-row {
    .flex-box {
        width: 326px;
    }
}
</style>

