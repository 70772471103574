import dongbei from "./dongbei.json"
import dongnan from "./dongnan.json"
import huabei from "./huabei.json"
import huadong from "./huadong.json"
import huanan from "./huanan.json"
import huazhong from "./huazhong.json"
import neimeng from "./neimeng.json"
import xibei from "./xibei.json"
import xinan from "./xinan.json"
import zhongnan from "./zhongnan.json"
import china from "./china.json"



export const initjson = (type = 'china') => {

    switch (type) {
        case '东北大区':
            return dongbei
        case '东南大区':
            return dongnan
        case '华北大区':
            return huabei
        case '华东大区':
            return huadong
        case '华南大区':
            return huanan
        case '华中大区':
            return huazhong
        case '内蒙大区':
            return neimeng
        case '西北大区':
            return xibei
        case '西南大区':
            return xinan
        case '中南大区':
            return zhongnan
        default:
            return china
    }
}