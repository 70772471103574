var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              !_vm.thirdPartyPermissions
                ? _c("el-form-item", { attrs: { label: "订单类型" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width-140",
                            attrs: { placeholder: "请选择", clearable: "" },
                            model: {
                              value: _vm.inquire.orderClassify,
                              callback: function ($$v) {
                                _vm.$set(_vm.inquire, "orderClassify", $$v)
                              },
                              expression: "inquire.orderClassify",
                            },
                          },
                          _vm._l(
                            _vm.$api.orderClassify,
                            function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.value },
                              })
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "订单编号/微信昵称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "订单编号/微信昵称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.orderCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "orderCode", $$v)
                      },
                      expression: "inquire.orderCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下单时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.daterange,
                      callback: function ($$v) {
                        _vm.daterange = $$v
                      },
                      expression: "daterange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-cascader", {
                      attrs: {
                        props: _vm.props,
                        options: _vm.productTypeList,
                        clearable: "",
                      },
                      on: { change: _vm.categoryChange },
                      model: {
                        value: _vm.categoryId,
                        callback: function ($$v) {
                          _vm.categoryId = $$v
                        },
                        expression: "categoryId",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.productList.length > 0,
                            expression: "productList.length > 0",
                          },
                        ],
                        staticClass: "width-260",
                        attrs: { multiple: "", placeholder: "请选择产品" },
                        model: {
                          value: _vm.inquire.productIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "productIds", $$v)
                          },
                          expression: "inquire.productIds",
                        },
                      },
                      _vm._l(_vm.productList, function (item) {
                        return _c("el-option", {
                          key: item.productId,
                          attrs: {
                            label: item.productName,
                            value: item.productId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              !_vm.thirdPartyPermissions
                ? _c(
                    "el-form-item",
                    { attrs: { label: "区域" } },
                    [
                      _vm.$public.isAllAreaPower()
                        ? _c("el-cascader", {
                            attrs: {
                              props: _vm.props_1,
                              options: _vm.addressList,
                              clearable: "",
                              placeholder: "请选择地址",
                            },
                            model: {
                              value: _vm.inquire.areaIdSelect,
                              callback: function ($$v) {
                                _vm.$set(_vm.inquire, "areaIdSelect", $$v)
                              },
                              expression: "inquire.areaIdSelect",
                            },
                          })
                        : _c("d-area", {
                            attrs: { values: _vm.inquire.areaIdSelect },
                            on: {
                              "update:values": function ($event) {
                                return _vm.$set(
                                  _vm.inquire,
                                  "areaIdSelect",
                                  $event
                                )
                              },
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "", height: "400px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "序号", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderCode",
                        label: "订单编号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDetails(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.orderCode))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "memberNickName",
                        label: "微信昵称",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderClassify",
                        label: "订单类型",
                        "min-width": "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.orderClassifyText(
                                      scope.row.orderClassify
                                    )
                                  ) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderTotalPrice",
                        label: "总金额",
                        "min-width": "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    "￥" + scope.row.orderTotalPrice.toFixed(2)
                                  ) +
                                  "\n              "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          prop: "whetherSelection",
                          label: "订单状态",
                          "min-width": "120",
                        },
                      },
                      [_vm._v("待收货")]
                    ),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderTime",
                        label: "下单时间",
                        "min-width": "160",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "recipientAddressDetail",
                        label: "所属区域",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "100",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDetails(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("详情")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pagination" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "current-page": _vm.pageParam.pageNum,
                        "page-sizes": _vm.$api.pageSizes,
                        "page-size": _vm.pageParam.pageSize,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.pageParam.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "出库",
            visible: _vm.dialogVisible,
            width: "80%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                { staticClass: "demo-form-inline", attrs: { size: "medium" } },
                _vm._l(_vm.detailInfo.items, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "产品信息" } },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading",
                                },
                              ],
                              staticClass: "table-box",
                              attrs: {
                                data: [_vm.detailInfo.items[index]],
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "date",
                                  label: "序号",
                                  width: "60",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(_vm._s(scope.$index + 1)),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "产品信息",
                                  width: "200",
                                  prop: "",
                                  "show-overflow-tooltip": "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "div",
                                            { staticClass: "flex-box" },
                                            [
                                              scope.row.productImg
                                                ? _c("img", {
                                                    staticClass: "img-box",
                                                    attrs: {
                                                      src: scope.row.productImg,
                                                      alt: "",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "hospital-name",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "txt_box",
                                                      class: {
                                                        "flex-box":
                                                          !scope.row.productImg,
                                                        "flex-center":
                                                          !scope.row.productImg,
                                                      },
                                                    },
                                                    [
                                                      scope.row.isGifts
                                                        ? _c("img", {
                                                            staticClass:
                                                              "icon_box",
                                                            class: {
                                                              no_img:
                                                                !scope.row
                                                                  .productImg,
                                                            },
                                                            attrs: {
                                                              src: require("@/assets/image/gift_icon.png"),
                                                              alt: "",
                                                            },
                                                          })
                                                        : _vm._e(),
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            scope.row
                                                              .productName
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "单价（￥）",
                                  width: "120",
                                  prop: "productPrice",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "购买数量",
                                  width: "120",
                                  prop: "itemNum",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "退货数量",
                                  width: "120",
                                  prop: "returnNum",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                scope.row.returnNum || "0"
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "小计",
                                  width: "120",
                                  prop: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                "￥" +
                                                  (
                                                    parseInt(
                                                      scope.row.itemNum
                                                    ) * scope.row.productPrice
                                                  ).toFixed(2)
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "实收金额",
                                  width: "120",
                                  prop: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                "￥" +
                                                  (
                                                    (parseInt(
                                                      scope.row.itemNum
                                                    ) -
                                                      parseInt(
                                                        scope.row.returnNum || 0
                                                      )) *
                                                    scope.row.productPrice
                                                  ).toFixed(2)
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "批次信息" } },
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading",
                                },
                              ],
                              staticClass: "table-box",
                              attrs: {
                                data: _vm.detailInfo.items[index].batch,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "date",
                                  label: "序号",
                                  width: "60",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(_vm._s(scope.$index + 1)),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "批次",
                                  width: "150",
                                  prop: "batchNo",
                                  "show-overflow-tooltip": "",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "库存",
                                  width: "120",
                                  prop: "count",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "生产日期",
                                  width: "140",
                                  prop: "produceTime",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "到期日期",
                                  width: "140",
                                  prop: "expireTime",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "有效期（天）",
                                  width: "120",
                                  prop: "termOfValidity",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "入库时间",
                                  width: "140",
                                  prop: "createTime",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "出库数量",
                                  width: "100",
                                  prop: "deliverCount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }