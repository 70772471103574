<template>
  <div class="main">
    <!-- 商家档案 -->
    <el-dialog :before-close="closeDialog" :title="title" :visible.sync="dialogShow" top="5vh" width="600px" @open="openShop">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="120px">
          <el-form-item label="商家名称" prop="merchantName">
            <el-input v-model="submitForm.merchantName" maxlength="20" :disabled="disable" placeholder="商家名称"></el-input>
          </el-form-item>
          <el-form-item label="昵称" prop="merchantNickName">
            <el-input v-model="submitForm.merchantNickName" maxlength="20" :disabled="disable" placeholder="昵称"></el-input>
          </el-form-item>
          <el-form-item prop="merchantAccount" label="登录账号">
            <el-input v-model="submitForm.merchantAccount" maxlength="20" :disabled="disable || !isAdd" placeholder="登录账号"></el-input>
          </el-form-item>
          <el-form-item label="上门自提" prop="whetherPickUp">
            <el-radio v-model="submitForm.whetherPickUp" :disabled="disable" :label="true">支持</el-radio>
            <el-radio v-model="submitForm.whetherPickUp" :disabled="disable" :label="false">不支持</el-radio>
          </el-form-item>
          <el-form-item label="所属办事处" prop="areaId">
            <el-cascader v-model="submitForm.areaId" :options="addressList" :disabled="disable" :props="props_add" class="width-inherit" placeholder="请选择办事处"></el-cascader>
          </el-form-item>
          <!-- <el-form-item label="商家信息员" prop="messengerId">
            <div v-if="!disable">
              <el-button size="small" type="primary" @click="openMessengerList">选择信息员</el-button>
            </div>
            <div>
              <el-table :data="shopsMessenger" border style="width: 100%">
                <el-table-column label="信息员" min-width="100" prop="messengerName"></el-table-column>
                <el-table-column label="手机号" min-width="120" prop="messengerPhoneNumber"></el-table-column>
              </el-table>
            </div>
          </el-form-item> -->
          <el-form-item label="产品管理" prop="medicineIds" v-if="isAdd">
            <el-select v-model="submitForm.medicineIds" filterable multiple :disabled="disable" class="form-select" placeholder="请选择">
              <el-option v-for="item in productList" :key="item.productId" :label="item.productName" :value="item.productId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="规模">
            <el-select v-model="submitForm.merchantScale" class="form-select" placeholder="请选择" :disabled="disable">
              <el-option v-for="item in scaleList" :key="item.id" :label="item.name" :value="item.name"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="成立时间">
            <el-date-picker v-model="submitForm.merchantEstablishedTime" :disabled="disable" class="width-inherit" placeholder="选择日期" type="date" value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="公司网址">
            <el-input v-model="submitForm.merchantWebsite" maxlength="50" :disabled="disable" placeholder="公司网址"></el-input>
          </el-form-item>
          <el-form-item label="业务员姓名" prop="merchantContactsName">
            <el-input v-model="submitForm.merchantContactsName" maxlength="10" :disabled="disable" placeholder="联系人姓名"></el-input>
          </el-form-item>
          <el-form-item label="业务员手机号" prop="merchantContactsPhoneNumber">
            <el-input v-model.trim="submitForm.merchantContactsPhoneNumber" maxlength="15" :disabled="disable" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="业务员编号">
            <el-input v-model="submitForm.intendantCode" :disabled="disable" placeholder="业务员编号"></el-input>
          </el-form-item>
          <el-form-item label="微信">
            <el-input v-model="submitForm.merchantContactsWechat" maxlength="20" placeholder="微信" :disabled="disable"></el-input>
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="submitForm.merchantContactsEmail" maxlength="50" placeholder="邮箱" :disabled="disable"></el-input>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="submitForm.merchantDescription" :disabled="disable" :rows="2" maxlength="150" placeholder="请输入内容" type="textarea">
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd" v-if="!disable">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 商家信息员弹框 -->
    <el-dialog :before-close="() => dialogVisible_2 = false" :visible.sync="dialogVisible_2" title="选择信息员" width="800px">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="信息员姓名">
            <el-input v-model="inquire_2.messengerName" placeholder="信息员姓名"></el-input>
          </el-form-item>

          <el-form-item label="手机号">
            <el-input v-model="inquire_2.messengerPhoneNumber" placeholder="手机号"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button size="small" type="primary" @click="(pageParam_2.pageNum = 1, getMessengerList())">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table ref="msgTabel" v-loading="loading_2" :data="messengerTable" border height="400px" highlight-current-row style="width: 100%" @current-change="currentChange">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">{{
                            (pageParam_2.pageNum - 1) * pageParam_2.pageSize + (scope.$index + 1)
                        }}
            </template>
          </el-table-column>
          <el-table-column label="信息员姓名" min-width="120" prop="messengerName"></el-table-column>
          <el-table-column label="手机号" min-width="120" prop="messengerPhoneNumber"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination :current-page="pageParam_2.pageNum" :page-size="pageParam_2.pageSize" :page-sizes="$api.pageSizes" :total="pageParam_2.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange_2" @current-change="handleCurrentChange_2"></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'shopsDetails',
  props: {
    isAdd: {
      type: Boolean,
      default: false
    },
    dialogShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '新增商家'
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      props_add: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        emitPath: false
      },

      initData: {
        merchantName: '',
        merchantNickName:'',
        merchantAccount: '',
        whetherPickUp: false,
        areaId: '',
        merchantContactsName: '',
        merchantContactsPhoneNumber: '',
        messengerId: '',
        medicineIds: [],
        merchantScale: '',
        merchantEstablishedTime: '',
        merchantWebsite: '',
        merchantContactsWechat: '',
        merchantContactsEmail: '',
        merchantDescription: '',
        intendantCode: '', //业务员编号
      },
      submitForm: {

      },
      submitRules: {
        merchantName: [{ required: true, message: '请输入商家名称', trigger: 'blur' }],
        // merchantNickName: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
        merchantAccount: [{ required: true, message: '请输入商家账号', trigger: 'blur' }],
        whetherPickUp: [{ required: true, message: '请选择是否上门自提', trigger: 'blur' }],
        areaId: [{ required: true, message: '请选择所属办事处', trigger: 'change' }],
        // messengerId: [{ required: true, message: '请选择商家信息员', trigger: 'change' }],
        medicineIds: [{ required: true, message: '请选择产品', trigger: 'change' }],
        merchantContactsName: [{ required: true, message: '请输入商家联系人姓名', trigger: 'blur' }],
        merchantContactsPhoneNumber: [{ required: true, message: '请输入商家联系人手机号', trigger: 'blur' }],
      },
      shopsMessenger: [], // 商家信息员
      scaleList: [
        { name: '10人以下', id: '1' },
        { name: '10-50人', id: '2' },
        { name: '50-100人', id: '3' },
        { name: '100-300人', id: '4' },
        { name: '300-500人', id: '5' },
        { name: '500-1000人', id: '6' },
        { name: '1000人以上', id: '7' },
      ], // 规模
      productList: [],
      addressList: [],

      // ==================== 设置商家信息员 ====================
      dialogVisible_2: false, // 弹窗
      inquire_2: {
        messengerName: '',
        messengerPhoneNumber: ''
      },
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading_2: false,
      messengerTable: [], // 信息员列表
      selectList: [],// table的选择项
    }
  },
  created() {
    this.submitForm = JSON.parse(JSON.stringify(this.initData))
    this.getMerchantProductList()
    // this.getDistrictIdList()
  },
  methods: {
    openShop(){
      this.getDistrictIdList()
    },
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          let param = JSON.parse(JSON.stringify(this.submitForm));
          if (this.merchantAccount === param.merchantAccount) {
            param.merchantAccount = ''
          }
          this.$emit('submit', param)
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields());
      this.submitForm = {
        merchantName: '',
        merchantAccount: '',
        whetherPickUp: false,
        areaId: '',
        merchantContactsName: '',
        merchantContactsPhoneNumber: '',
        messengerId: '',
        medicineIds: [],
        merchantScale: '',
        merchantEstablishedTime: '',
        merchantWebsite: '',
        merchantContactsWechat: '',
        merchantContactsEmail: '',
        merchantDescription: '',
        intendantCode: '', //业务员编号
      }
      this.shopsMessenger = [];
      this.$emit('update:dialogVisible', false)
    },
    // 获取产品列表
    getMerchantProductList() {
      this.$http.requestGet({
        url: '/product/getMerchantProductList',
        loading: false
      }).then(res => {
        this.productList = res.data
      })
    },
    // 获取区域级联列表
    getDistrictIdList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data;
        this.$emit('update:addressList', this.addressList)
      })
    },
    // 获取详情
    getDetails(merchantId) {
      this.$http.requestGet({ url: '/customer/merchant/get/' + merchantId, loading: false }).then(res => {
        let data = res.data;
        this.merchantAccount = data.merchantAccount;
        this.submitForm = {
          merchantName: data.merchantName,
          merchantNickName:data.merchantNickName,
          merchantAccount: data.merchantAccount,
          whetherPickUp: data.whetherPickUp,
          merchantContactsName: data.merchantContactsName,
          merchantContactsPhoneNumber: data.merchantContactsPhoneNumber,
          messengerId: data.messengerId,
          merchantScale: data.merchantScale,
          merchantEstablishedTime: data.merchantEstablishedTime,
          merchantWebsite: data.merchantWebsite,
          merchantContactsWechat: data.merchantContactsWechat,
          merchantContactsEmail: data.merchantContactsEmail,
          merchantDescription: data.merchantDescription,
          areaId: data.agencyId,
          intendantCode: data.intendantCode, //业务员编号
        };
        this.shopsMessenger = [{
          messengerId: data.messengerId,
          messengerName: data.messengerName,
          messengerPhoneNumber: data.messengerPhoneNumber
        }]
      })
    },

    // 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1;
      this.pageParam_2.pageSize = size;
      // 刷新(数据)
      this.getMessengerList()
    },
    // 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page;
      // 刷新(页码)
      this.getMessengerList();
    },

    // 获取商家信息员列表
    getMessengerList() {
      this.loading_2 = true;
      let param = {
        pageNum: this.pageParam_2.pageNum,
        pageSize: this.pageParam_2.pageSize,
        param: this.inquire_2
      }
      this.$http.requestPost({ url: '/customer/messenger/page', param: param, loading: false }).then(res => {
        this.loading_2 = false;
        this.messengerTable = res.data.list;
        this.pageParam_2.total = res.data.total;
        if (this.shopsMessenger.length > 0) {
          let index = this.messengerTable.findIndex(item => item.messengerId === this.shopsMessenger[0].messengerId)
          this.$refs.msgTabel.setCurrentRow(this.messengerTable[index])
        }
      }).catch(err => {
        console.log(err)
        this.loading_2 = false;
      })
    },
    // 打开信息员列表
    openMessengerList() {
      this.getMessengerList()
      this.dialogVisible_2 = true;
    },
    // table单选
    currentChange(news, old) {
      this.selectList = news;
    },
    // 确定选择
    confirm() {
      this.submitForm.messengerId = this.selectList.messengerId;
      this.shopsMessenger = [this.selectList];
      this.dialogVisible_2 = false;
    },
  }
}
</script>

<style scoped></style>
