<template>
  <el-dialog :visible.sync="visible" :title="personDialogTitle" :close-on-click-modal="false"
             width="550px" top="10vh"
  >
    <div class="Popout_content">
      <el-form ref="submitFormRef" label-width="100px" :model="submitForm" :rules="ruleFormRules">
        <el-form-item label="人员姓名" prop="memberNickname">
          <el-input v-model="submitForm.memberNickname" placeholder="请输入人员姓名"/>
        </el-form-item>
        <el-form-item label="员工工号">
          <el-input v-model="submitForm.qyUserId" placeholder="请输入员工工号"/>
        </el-form-item>
        <el-form-item label="手机号" prop="memberTelephone">
          <el-input v-model="submitForm.memberTelephone" :disabled="!!submitForm.memberId" placeholder="请输入手机号" maxlength="11"/>
        </el-form-item>
        <el-form-item label="所属分组" prop="talentCategoryId">
          <el-select v-model="submitForm.talentCategoryId" placeholder="请选择所属分组" class="form-select">
            <el-option v-for="item in groupManagementList" :key="item.id" :label="item.categoryName"
                       :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
        <el-button :loading="personButtonLoading" @click="visible = false">取 消</el-button>
        <el-button :loading="personButtonLoading" type="primary" @click="personSubmitAddOrEdit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { saveOrUpdateTalentItem } from '@/api/user'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    editForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    groupManagementList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },

  data() {
    return {
      personButtonLoading: false,
      submitForm: {},
      personDialogTitle: '编辑人员'
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    ruleFormRules() {
      return {
        memberNickname: [{ required: true, message: '请输入人员姓名', trigger: 'blur' }],
        memberTelephone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        talentCategoryId: [{ required: true, message: '请选择所属分组', trigger: 'change' }]
      }
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.personCloseDialog() // 重置对话框数据
        this.personDialogTitle = this.editForm.memberId ? '编辑人员' : '新增人员'
        const talentCategoryId = this.editForm.categoryId ? this.editForm.categoryId : ''
        this.submitForm = { ...this.editForm, talentCategoryId }
        console.log('this.submitForm=>>', this.submitForm)
      }
    }
  },

  methods: {
    // 关闭弹窗-重置
    personCloseDialog() {
      this.personDialogTitle = '新增人员'
      this.submitForm = {
        memberNickname: '',
        qyUserId: '',
        memberTelephone: '',
        talentCategoryId: ''
      }
      this.$refs.submitFormRef && this.$refs.submitFormRef.resetFields()
    },
    // 新增或编辑人员
    personSubmitAddOrEdit() {
      this.$refs.submitFormRef.validate(async(valid) => {
        if (!valid) return false
        try {
          const param = {
            memberNickname: this.submitForm.memberNickname,
            qyUserId: this.submitForm.qyUserId,
            memberTelephone: this.submitForm.memberTelephone,
            talentCategoryId: this.submitForm.talentCategoryId
          }
          // 编辑必传memberId
          if (this.submitForm.memberId) {
            param.memberId = this.submitForm.memberId
          }
          this.personButtonLoading = true
          const res = await saveOrUpdateTalentItem(param)
          this.personButtonLoading = false
          if (res.code !== 200) {
            return
          }
          this.$message.success('操作成功!')
          this.visible = false
          this.$emit('edit-done')
        } catch (e) {
          console.log(e)
          this.personButtonLoading = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
