<template>
    <!-- 产品单位列表 -->
    <div class="main-box">
        <div class="head-search-row">
            <el-form :inline="true" class="demo-form-inline" size="medium">
                <el-form-item label="增加时间">
                    <div class="flex-box">
                        <el-date-picker
                            v-model="daterange"
                            type="daterange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </div>
                </el-form-item>

                <el-form-item label="">
                    <div class="flex-box">
                        <el-select
                            v-model="selectKey"
                            placeholder="请选择"
                            @change="selectValue = ''"
                            class="width-140"
                        >
                            <el-option label="所属医院" value="hospitaName"></el-option>
                            <el-option label="所属专员" value="attacheName"></el-option>
                            <el-option label="所属地区" value="addressName"></el-option>
                        </el-select>
                        <el-input v-model="selectValue" placeholder="" class="width-180" @keyup.enter.native="searchList"></el-input>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" size="small" @click="searchList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div style="margin: 20px 0 0 20px">
            <el-button size="small" type="primary" @click="addShippingAddress">添加配送地址</el-button>
            <el-button type="primary" size="small" @click="handleImportFile">导入</el-button>
            <el-button type="primary" size="small" @click="handleExportFile">导出</el-button>
        </div>

        <div class="table">
            <el-main>
                <template>
                    <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
                        <el-table-column prop="date" label="序号" width="60">
                            <template v-slot="scope">{{
                                (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
                            }}</template>
                        </el-table-column>
                        <el-table-column
                            prop="addressName"
                            label="所在地区"
                            min-width="200"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column prop="hospitalName" label="所属医院" min-width="200" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleShippingAddress(scope.row)">{{
                                    scope.row.hospitalName
                                }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="增加时间" min-width="160"></el-table-column>
                        <el-table-column prop="" label="操作" min-width="160" fixed="right">
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                                <span class="operation-btn_partition"> / </span>
                                <span class="operation-btn" @click="deleteData(scope.row)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        background
                        :current-page="pageParam.pageNum"
                        :page-sizes="$api.pageSizes"
                        :page-size="pageParam.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageParam.total"
                    ></el-pagination>
                </div>
            </el-main>
        </div>

        <!-- 添加配送地址弹框 -->
        <el-dialog
            :title="dialogTitle + '配送地址'"
            :visible.sync="dialogVisible"
            :before-close="closeDialog"
            width="75%"
            top="5vh"
        >
            <div class="Popout_content">
                <el-form label-width="110px" ref="submitForm" :model="submitForm" :rules="submitRules">
                    <el-form-item label="所属医院" prop="hospitalId">
                        <div>
                            <el-button type="primary" size="small" @click="openHospitalList">选择</el-button>
                        </div>
                        <div>
                            <el-table :data="hospitalTable" border style="width: 100%">
                                <el-table-column prop="hospitalCode" label="医院编号" min-width="160"></el-table-column>
                                <el-table-column prop="hospitalName" label="医院名称" min-width="160"></el-table-column>
                                <el-table-column prop="addressName" label="所在地区" min-width="160"></el-table-column>
                                <el-table-column prop="address" label="详细地址" min-width="150"></el-table-column>
                            </el-table>
                        </div>
                    </el-form-item>
                    <el-form-item label="具体地址">
                        <div class="flex-box address-hierarchy">
                            <el-input
                                v-for="(item, index) in addressHierarchy"
                                :key="index"
                                v-model="submitForm.address[index]"
                                :placeholder="item.hierarchyName"
                                class="width-140"
                            ></el-input>
                            <p
                                class="color_light-grey"
                                v-show="hospitalTable.length > 0 && addressHierarchy.length === 0"
                            >
                                该医院没有具体地址，请先给该医院设置具体地址
                            </p>
                            <p class="color_light-grey" v-show="hospitalTable.length === 0">请选择所属医院</p>
                        </div>
                    </el-form-item>
                    <!-- <el-form-item label="所属专员" prop="attacheId">
                        <div><el-button type="primary" size="small" @click="openAttacheList">选择</el-button></div>
                        <div>
                            <el-table :data="attacheTable" border style="width: 100%">
                                <el-table-column prop="attacheCode" label="专员编号" min-width="160"></el-table-column>
                                <el-table-column prop="attacheName" label="专员姓名" min-width="100"></el-table-column>
                                <el-table-column
                                    prop="attachePhoneNumber"
                                    label="手机号"
                                    min-width="120"
                                ></el-table-column>
                            </el-table>
                        </div>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="submitAdd">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 医院列表弹框 -->
        <el-dialog
            title="选择医院"
            :visible.sync="dialogVisible_2"
            :before-close="() => (dialogVisible_2 = false)"
            width="80%"
            top="5vh"
        >
            <div class="Popout_content">
                <el-form :inline="true" class="demo-form-inline" size="medium">
                    <el-form-item>
                        <el-input v-model="inquire_2.hospitalCode" placeholder="医院编号"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-input v-model="inquire_2.hospitalName" placeholder="医院名称"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-input v-model="inquire_2.merchantName" placeholder="商家名称"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="small" @click="getHospitalList">查询</el-button>
                    </el-form-item>
                </el-form>

                <el-table
                    ref="hospitalTable"
                    :data="tableData_2"
                    v-loading="loading_2"
                    border
                    height="400px"
                    style="width: 100%"
                    highlight-current-row
                    @current-change="handleSelectChange"
                >
                    <el-table-column prop="date" label="序号" width="60">
                        <template v-slot="scope">{{ scope.$index + 1 }}</template>
                    </el-table-column>
                    <el-table-column
                        prop="hospitalCode"
                        label="医院编号"
                        min-width="200"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        prop="hospitalName"
                        label="医院名称"
                        min-width="200"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        prop="regionName"
                        label="所属大区"
                        min-width="160"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        prop="addressName"
                        label="所在地区"
                        min-width="160"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        prop="address"
                        label="详细地址"
                        min-width="160"
                        show-overflow-tooltip
                    ></el-table-column>
                    <el-table-column
                        prop="merchantName"
                        label="所属商家"
                        min-width="160"
                        show-overflow-tooltip
                    ></el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange_2"
                        @current-change="handleCurrentChange_2"
                        background
                        :current-page="pageParam_2.pageNum"
                        :page-sizes="$api.pageSizes"
                        :page-size="pageParam_2.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageParam_2.total"
                    ></el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_2 = false">取 消</el-button>
                <el-button type="primary" @click="confirmHospital">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 专员列表弹框 -->
        <el-dialog
            title="选择专员"
            :visible.sync="dialogVisible_3"
            :before-close="() => (dialogVisible_3 = false)"
            width="750px"
            top="5vh"
        >
            <div class="Popout_content">
                <el-form :inline="true" class="demo-form-inline" size="medium">
                    <el-form-item>
                        <el-input v-model="inquire_3.attacheName" placeholder="专员姓名"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-input v-model="inquire_3.attacheCode" placeholder="编号"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-input v-model="inquire_3.attachePhoneNumber" placeholder="手机号"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="small" @click="getAttacheList">查询</el-button>
                    </el-form-item>
                </el-form>

                <el-table
                    ref="attacheTable"
                    :data="tableData_3"
                    v-loading="loading_3"
                    border
                    height="400px"
                    style="width: 100%"
                    highlight-current-row
                    @current-change="handleSelectChange"
                >
                    <el-table-column prop="date" label="序号" width="60">
                        <template v-slot="scope">{{
                            (pageParam_3.pageNum - 1) * pageParam_3.pageSize + (scope.$index + 1)
                        }}</template>
                    </el-table-column>
                    <el-table-column prop="attacheCode" label="专员编号" min-width="160"></el-table-column>
                    <el-table-column prop="attacheName" label="专员姓名" min-width="120"></el-table-column>
                    <el-table-column prop="attachePhoneNumber" label="手机号" min-width="120"></el-table-column>
                </el-table>

                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange_3"
                        @current-change="handleCurrentChange_3"
                        background
                        :current-page="pageParam_3.pageNum"
                        :page-sizes="$api.pageSizes"
                        :page-size="pageParam_3.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageParam_3.total"
                    ></el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_3 = false">取 消</el-button>
                <el-button type="primary" @click="confirmAttache">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 配送地址弹框 -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible_4"
            :before-close="() => (dialogVisible_4 = false)"
            width="650px"
        >
            <div class="Popout_content">
                <el-tree
                    node-key="shippingId"
                    lazy
                    :load="loadNode"
                    :expand-on-click-node="!showAll"
                    :props="defaultProps"
                    :render-content="renderContent"
                >
                </el-tree>
                <!-- default-expand-all 展开全部-->
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_4 = false">取 消</el-button>
            </span>
        </el-dialog>

        <!-- 编辑弹框 -->
        <el-dialog
            title="编辑配送地址"
            :visible.sync="dialogVisible_5"
            :before-close="() => (dialogVisible_5 = false)"
            width="650px"
        >
            <div class="Popout_content">
                <el-form label-width="110px" ref="amendForm" :model="amendForm" :rules="amendRules">
                    <el-form-item label="地址名称" prop="address">
                        <el-input v-model="amendForm.address" placeholder="地址名称"></el-input>
                    </el-form-item>

                    <!-- <el-form-item label="所属专员" prop="attacheId" v-if="isEnd">
                        <div><el-button type="primary" size="small" @click="openAttacheList">选择</el-button></div>
                        <div>
                            <el-table :data="attacheTable" border style="width: 100%">
                                <el-table-column prop="attacheCode" label="专员编号" min-width="160"></el-table-column>
                                <el-table-column prop="attacheName" label="专员姓名" min-width="100"></el-table-column>
                                <el-table-column
                                    prop="attachePhoneNumber"
                                    label="手机号"
                                    min-width="120"
                                ></el-table-column>
                            </el-table>
                        </div>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_5 = false">取 消</el-button>
                <el-button type="primary" @click="configAmend">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisible_6" title="导入" width="700px" :close-on-click-modal="false">
            <div class="Popout_content">
                <el-form ref="submitForm" label-width="100px">
                    <el-form-item label="导入模板">
                        <el-button size="small" type="primary" @click="downloadTemplate"
                            >下载批量导入模板文件</el-button
                        >
                    </el-form-item>

                    <el-form-item label="批量导入">
                        <d-upload
                            :action="'/hospital/shipping/address/importAddresses/' + managerId"
                            :show-file-list="false"
                            :file-list="fileList"
                            accept=".xls,.xlsx"
                            @beforeUpload="beforeUpload"
                            @uploadSuccess="importFile"
                            @uploadError="importError"
                        >
                            <el-button size="small" type="primary" :disabled="uploadDisabled">点击上传</el-button>
                        </d-upload>
                        <div>
                            <p class="progress color_success" v-if="importStatus === 1">
                                导入成功！
                            </p>
                            <p class="progress color_red" v-else-if="importStatus === 2">
                                {{importStatusContent}}
                            </p>
                            <p class="progress" v-else-if="importStatus === 3">
                                正在导入中...
                            </p>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeExport">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisible_7" title="导出" width="700px" :close-on-click-modal="false">
            <div class="address_box">
                <el-form :inline="false" class="demo-form-inline" size="medium">
                    <el-form-item label="区域">
                        <el-cascader
                            v-model="address"
                            :props="props"
                            :options="addressList"
                            clearable
                            placeholder="请选择地址"
                            @change="handleChange"
                            v-if="$public.isAllAreaPower()"
                        />
                        <d-area @changeHandle="changeHandle" v-else />
                    </el-form-item>

                    <el-form-item label="医院" v-if="areaId.length >= 1">
                        <el-select v-model="affiliatedHospital" placeholder="请选择">
                            <el-option
                                v-for="(item, index) in affiliatedHospitalList"
                                :key="index"
                                :label="item.hospitalName"
                                :value="item.hospitalId"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" size="small" @click="exportFile">导出</el-button>
                    </el-form-item>
                </el-form>
                <div class="table">
                    <el-main>
                        <template>
                            <el-table
                                ref="tables"
                                v-loading="loading_4"
                                :data="tableData_4"
                                border
                                height="200px"
                                row-key="orderId"
                                style="width: 100%"
                            >
                                <el-table-column prop="date" label="序号" width="60">
                                    <template v-slot="scope">{{
                                        (pageParam_4.pageNum - 1) * pageParam_4.pageSize + (scope.$index + 1)
                                    }}</template>
                                </el-table-column>
                                <el-table-column label="文件名" min-width="100" prop="fileName" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column label="文件地址" min-width="220" prop="fileUrl" show-overflow-tooltip>
                                </el-table-column>
                                <el-table-column label="导出时间" min-width="140" prop="createTime"></el-table-column>
                                <el-table-column label="状态" min-width="100" prop="">
                                    <template slot-scope="scope">
                                        {{ scope.row.statusValue }}
                                    </template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" min-width="120" prop="">
                                    <template slot-scope="scope">
                                        <span class="operation-btn" v-if="scope.row.status == 'KXZ'"
                                            ><a :href="scope.row.fileUrl" download>下载</a></span
                                        >
                                        <span class="operation-btn_partition" v-if="scope.row.status == 'KXZ'">
                                            /
                                        </span>

                                        <span class="operation-btn" @click="handleDelete_4(scope.row)">删除</span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                        <div class="pagination">
                            <el-pagination
                                :current-page="pageParam_4.pageNum"
                                :page-size="pageParam_4.pageSize"
                                :page-sizes="$api.pageSizes"
                                :total="pageParam_4.total"
                                background
                                layout="total, sizes, prev, pager, next, jumper"
                                @size-change="handleSizeChange_4"
                                @current-change="handleCurrentChange_4"
                            ></el-pagination>
                        </div>
                    </el-main>
                </div>
                <div></div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import dUpload from "@/components/d-upload";
import { getSessionStorage } from "@/utils/auth";
import { exportHospitalAddress, deleteLogic } from "@/api/order";
import dArea from "@/components/d-area";
export default {
    components: {
        dUpload,
        dArea
    },
    data() {
        return {
            // 查询参数
            daterange: [], // 增加时间
            inquire: {
                createTimeBegin: "",
                createTimeEnd: ""
            },
            selectKey: "hospitaName",
            selectValue: "",
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            // 表格数据
            tableData: [],
            showState: [{ name: "显示", value: "display" }, { name: "隐藏", value: "hide" }],

            // 添加配送地址
            dialogTitle: "添加", // 添加弹窗标题
            dialogVisible: false, // 添加弹窗
            submitForm: {
                hospitalId: "",
                address: [],
                attacheId: ""
            },
            submitRules: {
                hospitalId: [{ required: true, message: "请选择所属医院", trigger: "blur" }],
                attacheId: [{ required: true, message: "请选择所属专员", trigger: "blur" }]
            },
            hospitalTable: [],
            attacheTable: [],
            isAdd: true, // 是否为添加
            editRow: {},
            addressHierarchy: [], // 地址层级列表
            selectRow: {}, // 选择当前行

            // ==== 所属医院弹框 ====
            dialogVisible_2: false,
            inquire_2: {
                hospitalCode: "",
                hospitalName: "",
                merchantName: ""
            },
            pageParam_2: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            loading_2: false,
            tableData_2: [],

            // ==== 所属专员 ====
            dialogVisible_3: false,
            inquire_3: {
                attacheName: "",
                attacheCode: "",
                attachePhoneNumber: ""
            },
            loading_3: false,
            tableData_3: [],
            pageParam_3: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },

            // === 配送地址 ===
            dialogVisible_4: false,
            defaultProps: {
                children: "childes",
                label: "addressName",
                isLeaf: "leaf"
            },
            currentHospitalId: "",
            currentTreeNode: "",
            currentTreeResolve: "",

            // === 编辑 ===
            dialogVisible_5: false,
            showAll: false,
            amendForm: {
                address: "",
                shippingId: "",
                attacheId: ""
            },
            amendRules: {
                address: [{ required: true, message: "请输入地址名称", trigger: "blur" }],
                attacheId: [{ required: true, message: "请选择所属专员", trigger: "blur" }]
            },
            isEnd: false,
            // 导入
            dialogVisible_6: false,
            // 导出
            dialogVisible_7: false,
            fileList: [],
            loadingInstance: null,
            importStatus: 0,
            importStatusValue: "导入失败,请检查格式是否正确，是否有重复数据！",
            managerId: "",
            cycle: null,
            customColor: "#409eff",
            percentage: 0,
            uploadDisabled: false,
            importResult: {
                currentRow: 0,
                totalRow: 0
            },
            // 级联选项
            areaId: [], // 区域id
            addressList: [], // 区域
            address: [],
            props: {
                label: "areaName",
                value: "areaId",
                children: "childes",
                checkStrictly: false
                // emitPath: false
            },
            affiliatedHospital: "", // 所属医院
            affiliatedHospitalList: [],
            // ======== 导出 ======
            loading_4: false,
            tableData_4: [],
            pageParam_4: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            importStatusNew: '',
            importStatusContent: ''
        };
    },
    created() {
        this.address = [];
        // 获取产品列表
        this.getTableList();

        // 区域列表
        this.getArea();
        this.managerId = getSessionStorage("userInfo").managerId;
    },
    methods: {
        // 获取区域级联数据
        getArea() {
            this.$http.requestGet({ url: "/operation/area/agency/tree", loading: false }).then(res => {
                console.log("res", res);
                this.addressList = res.data;
            });
        },
        getAffiliatedHospitalList(areaId) {
            console.log(areaId);
            let param = {
                areaId: areaId
            };
            this.$http
                .requestGet({ url: "/hospital/medical/area/hospital?areaId=" + areaId, loading: false })
                .then(res => {
                    if (res.code == 200) {
                        this.affiliatedHospitalList = res.data;
                        console.log(this.affiliatedHospital);
                    }
                });
        },
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size;
            // 刷新(数据)
            this.getTableList();
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page;
            // 刷新(页码)
            this.getTableList();
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1;
            this.getTableList();
        },

        // 获取地址层级列表
        getAddressHierarchy() {
            this.$http
                .requestGet({ url: "/hospital/address/hierarchy/dynamic/" + this.selectRow.hospitalId, loading: false })
                .then(res => {
                    this.addressHierarchy = res.data;
                });
        },

        // 获取产品列表
        getTableList() {
            if (!this.daterange) this.daterange = [];
            this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : "";
            this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : "";
            let data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: JSON.parse(JSON.stringify(this.inquire))
            };
            data.param[this.selectKey] = this.selectValue;

            this.loading = true;
            this.$http
                .requestPost({
                    url: "/hospital/shipping/address/page",
                    param: data,
                    loading: false
                })
                .then(res => {
                    this.loading = false;
                    this.tableData = res.data.list;
                    this.pageParam.total = res.data.total;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                    this.$set(this, "loading", false);
                });
        },

        // 添加产品类别
        addShippingAddress() {
            this.dialogTitle = "添加";
            this.dialogVisible = true;
            this.isAdd = true;
        },
        // 提交类别添加
        submitAdd() {
            this.$refs.submitForm.validate(valid => {
                if (valid) {
                    let param = [];
                    if (this.addressHierarchy.length > 0) {
                        let verify = true;
                        let name = "";
                        for (let i in this.addressHierarchy) {
                            if (!this.submitForm.address[i]) {
                                verify = false;
                                name = this.addressHierarchy[i].hierarchyName;
                                break;
                            }
                            param.push({
                                hospitalId: this.submitForm.hospitalId,
                                // attacheId: this.submitForm.attacheId,
                                address: this.submitForm.address[i]
                            });
                        }
                        if (!verify)
                            return this.$message({ message: "具体地址的" + name + "不能为空!", type: "warning" });
                    } else {
                        this.$message.warning("该医院没有具体地址，请先给该医院设置具体地址");
                        return;
                    }
                    this.$http
                        .requestPost({
                            url: "/hospital/shipping/address/save2",
                            param: param
                        })
                        .then(res => {
                            this.getTableList();
                            this.$message({
                                message: "操作成功",
                                type: "success"
                            });
                            this.closeDialog();
                        });
                } else {
                    return false;
                }
            });
        },

        // 关闭弹窗
        closeDialog() {
            this.$refs.submitForm && this.$refs.submitForm.resetFields();
            this.dialogVisible = false;
            this.submitForm = {
                hospitalId: "",
                address: [],
                attacheId: ""
            };
            this.hospitalTable = [];
            this.attacheTable = [];
            this.addressHierarchy = [];
        },

        // 删除
        deleteData(row) {
            this.$confirm("确定删除该配送地址?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            }).then(() => {
                if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
                    this.pageParam.pageNum--;
                }
                this.$http
                    .requestDel({ url: "/hospital/shipping/address/deleteByHospitalId/" + row.hospitalId })
                    .then(res => {
                        this.getTableList();
                        this.$message({
                            type: "success",
                            message: "删除成功!"
                        });
                    });
            });
        },

        // ==================== 所属医院 ====================
        // 页容量改变
        handleSizeChange_2(size) {
            this.pageParam_2.pageNum = 1;
            this.pageParam_2.pageSize = size;
            // 刷新(数据)
            this.getHospitalList();
        },
        // 页码改变
        handleCurrentChange_2(page) {
            this.pageParam_2.pageNum = page;
            // 刷新(页码)
            this.getHospitalList();
        },
        // 打开医院列表
        openHospitalList() {
            this.getHospitalList();
            this.dialogVisible_2 = true;
        },

        // 查询医院列表
        getHospitalList() {
            this.loading_2 = true;
            let param = {
                pageNum: this.pageParam_2.pageNum,
                pageSize: this.pageParam_2.pageSize,
                param: this.inquire_2
            };
            this.$http
                .requestPost({
                    url: "/hospital/medical/page2",
                    param,
                    loading: false
                })
                .then(res => {
                    this.loading_2 = false;
                    this.tableData_2 = res.data.list;
                    this.pageParam_2.total = res.data.total;
                    if (this.hospitalTable.length > 0) {
                        let index = this.tableData_2.findIndex(
                            item => item.hospitalCode === this.hospitalTable[0].hospitalCode
                        );
                        this.$refs.hospitalTable.setCurrentRow(this.tableData_2[index]);
                    }
                })
                .catch(err => {
                    this.loading_2 = false;
                    console.log(err);
                });
        },
        // 确定医院的选择
        confirmHospital() {
            if (JSON.stringify(this.selectRow) == "{}") {
                return this.$message.warning("请选择所属医院");
            }
            this.hospitalTable = [];
            this.hospitalTable.push(this.selectRow);
            this.submitForm.hospitalId = this.selectRow.hospitalId;
            this.dialogVisible_2 = false;
            // 获取地址层级
            this.getAddressHierarchy();
        },

        // 表格单选
        handleSelectChange(news, old) {
            this.selectRow = news;
        },

        // ==================== 所属专员 ====================
        // 页容量改变
        handleSizeChange_3(size) {
            this.pageParam_3.pageNum = 1;
            this.pageParam_3.pageSize = size;
            // 刷新(数据)
            this.getAttacheList();
        },
        // 页码改变
        handleCurrentChange_3(page) {
            this.pageParam_3.pageNum = page;
            // 刷新(页码)
            this.getAttacheList();
        },

        // 打开医院列表
        // openAttacheList() {
        //     this.getAttacheList();
        //     this.dialogVisible_3 = true;
        // },

        // 查询专员列表
        getAttacheList() {
            let data = {
                pageNum: this.pageParam_3.pageNum,
                pageSize: this.pageParam_3.pageSize,
                param: this.inquire_3
            };

            this.loading_3 = true;
            this.$http
                .requestPost({
                    url: "/customer/attache/page",
                    param: data,
                    loading: false
                })
                .then(res => {
                    this.loading_3 = false;
                    this.tableData_3 = res.data.list;
                    this.pageParam_3.total = res.data.total;
                    if (this.attacheTable.length > 0) {
                        let index = this.tableData_3.findIndex(
                            item => item.attacheCode === this.attacheTable[0].attacheCode
                        );
                        this.$refs.attacheTable.setCurrentRow(this.tableData_3[index]);
                    }
                })
                .catch(err => {
                    this.loading_3 = false;
                    console.log(err);
                });
        },
        // 确定专员的选择
        confirmAttache() {
            if (JSON.stringify(this.selectRow) == "{}") {
                return this.$message.warning("请选择所属专员");
            }
            this.attacheTable = [];
            this.attacheTable.push(this.selectRow);
            this.submitForm.attacheId = this.selectRow.attacheId;
            this.dialogVisible_3 = false;
        },

        // ==================== 配送地址 ====================
        handleShippingAddress(row) {
            this.dialogTitle = row.hospitalName + " - 配送地址";
            this.showAll = false;
            this.editRow = row;

            if (row.hospitalId !== this.currentHospitalId && this.currentHospitalId !== "") this.removeTreeNode(row);
            this.dialogVisible_4 = true;
        },

        // 获取配送地址数据
        removeTreeNode(row) {
            this.currentTreeNode.childNodes = [];
            this.loadNode(this.currentTreeNode, this.currentTreeResolve);
        },

        // 懒加载医院配送地址
        loadNode(node, resolve) {
            if (node.level === 0) {
                this.currentTreeNode = node;
                this.currentTreeResolve = resolve;
                this.currentHospitalId = this.editRow.hospitalId;
                this.$http
                    .requestGet({
                        url: "/hospital/shipping/address/getTheFirstLevelAddresses/" + this.editRow.hospitalId
                    })
                    .then(res => {
                        let data = res.data;
                        return resolve(data);
                    });
            } else if (node.level > 0) {
                this.$http
                    .requestGet({
                        url: "/hospital/shipping/address/selectTheOtherLevelAddresses/" + node.data.shippingId
                    })
                    .then(res => {
                        let data = res.data;
                        data.map(e => {
                            e.shippingParentId = node.data.shippingId;
                            if (e.attacheName) {
                                e.addressName += " (" + e.attacheName + " - " + e.attacheCode + ")";
                                e.leaf = true;
                            }
                        });
                        resolve(data);
                    });
            }
        },

        // ==================== 编辑 ====================
        handleEdit(row) {
            this.dialogTitle = row.hospitalName + " - 编辑配送地址";
            this.showAll = true;
            this.editRow = row;
            if (row.hospitalId !== this.currentHospitalId && this.currentHospitalId !== "") this.removeTreeNode();
            this.dialogVisible_4 = true;
        },

        // 树形组件自定义功能渲染
        renderContent(h, { node, data, store }) {
            if (this.showAll) {
                return (
                    <span class="custom-tree-node flex-box custom-tree">
                        <span>{node.label}</span>
                        <span>
                            <el-button size="mini" type="text" on-click={() => this.amendAddress(data)}>
                                编辑
                            </el-button>
                            <el-button size="mini" type="text" on-click={() => this.removeAddress(node, data)}>
                                删除
                            </el-button>
                        </span>
                    </span>
                );
            } else {
                return (
                    <span class="custom-tree-node">
                        <span>{node.label}</span>
                    </span>
                );
            }
        },

        // 修改
        amendAddress(data) {
            this.isEnd = data.attacheName && data.attacheCode ? true : false;
            this.dialogVisible_5 = true;
            this.amendForm = {
                address: this.isEnd ? data.addressName.slice(0, data.addressName.lastIndexOf(" (")) : data.addressName,
                shippingId: data.shippingId,
                hospitalId: data.hospitalId,
                shippingParentId: data.shippingParentId ? data.shippingParentId : "",
                attacheId: data.attacheId ? data.attacheId : undefined
            };

            if (this.isEnd) {
                this.attacheTable = [
                    {
                        attacheId: data.attacheId ? data.attacheId : undefined,
                        attacheCode: data.attacheCode,
                        attacheName: data.attacheName,
                        attachePhoneNumber: data.attachePhoneNumber
                    }
                ];
            }
        },
        // 确定修改
        configAmend() {
            this.amendForm.attacheId = this.attacheTable.length > 0 ? this.attacheTable[0].attacheId : "";
            this.$refs.amendForm.validate(valid => {
                if (valid) {
                    let param = {
                        address: this.amendForm.address,
                        shippingId: this.amendForm.shippingId,
                        hospitalId: this.amendForm.hospitalId,
                        shippingParentId: this.amendForm.shippingParentId,
                        attacheId: this.amendForm.attacheId
                    };

                    this.$http.requestPut({ url: "/hospital/shipping/address/updateAdress", param }).then(res => {
                        this.removeTreeNode();
                        this.$message.success("操作成功！");
                        this.dialogVisible_5 = false;
                        this.attacheTable = [];
                    });
                } else {
                    return false;
                }
            });
        },
        // 删除
        removeAddress(node, data) {
            this.$confirm("确定删除该地址?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            }).then(async () => {
                let param = {
                    shippingId: node.data.shippingId,
                    shippingParentId: node.data.shippingParentId ? node.data.shippingParentId : ""
                };

                this.$http.requestDel({ url: "/hospital/shipping/address/deleteAddresses1", param }).then(res => {
                    this.removeTreeNode();
                    this.$message.success("删除成功!");
                });
            });
        },

        // 导入
        // importFile(response, file, fileList) {
        //     if (response.data) {
        //         this.percentage = 1;
        //         if (this.customColor != "#409eff") this.customColor = "#409eff";
        //         this.uploadDisabled = true;
        //         this.cycle = setInterval(() => {
        //             this.getUploadStatus();
        //         }, 1000 * 5);
        //     } else {
        //         this.$message.error("导入失败");
        //         this.importStatus = 2;
        //         this.fileList = [];
        //         this.uploadDisabled = false;
        //     }
        // },
        // 上传成功
        importFile(response, file, fileList) {
            if (response.code == 200) {
                this.importStatus = 1;
                this.$message.success("上传成功！");
            }
            this.fileList = [];
        },
        // 上传失败
        importError(response, file, fileList) {
            if(response.code !== 200){
                this.importStatus = 2;
                this.$message.error("上传失败");
                if(response.data){
                    window.location.href = response.data;
                }
                if(response.message){
                    this.importStatusContent = response.message || '导入医院失败，请检查导入模版是否正确！';
                }else{
                    this.importStatusContent = '导入医院失败，请检查导入模版是否正确！';
                }
            }
            this.fileList = [];
        },
        // 导入之前
        beforeUpload() {
            this.importStatus = 3;
        },
        // 获取导入状态
        getUploadStatus() {
            this.$http
                .requestGet({
                    url: "/hospital/shipping/address/getImportResult/" + this.managerId,
                    loading: false
                })
                .then(res => {
                    let data = res.data;
                    if (data.status === 2) {
                        this.percentage = 100;
                        this.importStatus = 1;
                        this.fileList = [];
                        clearInterval(this.cycle);
                        this.customColor = "#67c23a";
                        this.uploadDisabled = false;
                        if (this.importResult.currentRow != this.importResult.totalRow)
                            this.importResult.currentRow = this.importResult.totalRow;
                    } else if (data.status === 0) {
                        this.importStatus = 2;
                        this.importStatusValue = data.msg ? data.msg : "导入失败,请检查格式是否正确，是否有重复数据！";
                        this.fileList = [];
                        clearInterval(this.cycle);
                        this.customColor = "#ff0000";
                        this.uploadDisabled = false;
                    } else if (data.status === 1) {
                        this.importResult = {
                            currentRow: data.currentRow > data.totalRow ? data.totalRow : data.currentRow,
                            totalRow: data.totalRow
                        };
                        this.percentage = parseInt(((data.currentRow / data.totalRow) * 100).toFixed(0));
                    }
                })
                .catch(err => {
                    this.importStatus = 4;
                    this.fileList = [];
                    clearInterval(this.cycle);
                    this.customColor = "#ff0000";
                    this.uploadDisabled = false;
                });
        },
        // 进度条
        format(val) {
            if (this.importResult.currentRow) {
                return this.importResult.currentRow + "/" + this.importResult.totalRow;
            } else {
                return;
            }
        },
        // 关闭导入弹窗
        closeExport() {
            this.dialogVisible_6 = false;
            // this.importStatus = 0
            // this.percentage = 0
            // this.customColor = '#409eff'
        },
        // 下载导入模板
        downloadTemplate() {
            window.location.href = this.$http.domain + "/download/addressImportTemplate.xls";
        },
        // ================  导出  ==================
        // 区域变化状态
        changeHandle(e) {
            this.areaId = e;
            this.getAffiliatedHospitalList(this.areaId);
        },
        // 区域变化状态
        handleChange(e) {
            this.areaId = this.address[this.address.length - 1];
            this.getAffiliatedHospitalList(this.areaId);
        },
        // 导出
        exportFile() {
            if (this.areaId.length <= 0) {
                return this.$message({ message: "区域必选", type: "warning" });
            }
            let param = {
                baseAreaId: this.areaId,
                hospitalId: this.affiliatedHospital
            };
            exportHospitalAddress(param).then(res => {
                // this.$public.downloadFile(res);
                // this.$message.success("导出任务已经启动，请稍后到[导出文件列表]中下载文件");
                this.$public.downloadFile(res);
                this.$message.success("导出成功！");
                setTimeout(() =>{
                    this.clearData
                    this.getTableData()
                }, 200)
            });
        },
        handleImportFile(){
            this.importStatus = ''
            this.dialogVisible_6 = true
        },
        handleExportFile() {
          this.areaId = ''
          this.hospitalId = this.affiliatedHospital
          this.dialogVisible_7 = true
          this.getTableData()
        },
        // 页容量改变
        handleSizeChange_4(size) {
            this.pageParam_4.pageNum = 1;
            this.pageParam_4.pageSize = size;
            this.getTableData();
        },
        // 页码改变
        handleCurrentChange_4(page) {
            this.pageParam_4.pageNum = page;
            this.getTableData();
        },
        // 获取列表数据
        getTableData() {
            console.log(this.inquire)
            let query = {
              type: 'hospitalAddress'
            }
            const param = {
                pageNum: this.pageParam_4.pageNum,
                pageSize: this.pageParam_4.pageSize,
                param: JSON.parse(JSON.stringify(query))
            };
            this.loading_4 = true;
            this.$http
                .requestPost({
                    url: "/exportfile/page",
                    param: param,
                    loading: false
                })
                .then(res => {
                    this.loading_4 = false;
                    console.log('list', res.data.list)
                    this.tableData_4 = res.data.list;
                    this.pageParam_4.total = res.data.total;
                })
                .catch(err => {
                    this.loading_4 = false;
                    console.log(err);
                });
        },
        // 文件删除
        handleDelete_4(row){
            this.$confirm('确定删除该文件?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    deleteLogic(row.id).then(res => {
                        this.$message.success('删除成功')
                        this.getTableData()
                    })
                }).catch(() => {});
            
        }
    }
};
</script>

<style lang="scss" scoped>
.address-hierarchy {
    flex-wrap: wrap;
}

.el-tree {
    padding: 10px 0;

    & >>> .custom-tree {
        width: 100%;
        justify-content: space-between;
        align-items: center;

        & > span {
            display: inline-block;
            padding-right: 20px;
        }
    }
}
</style>

<style>
.el-progress .el-progress-bar {
    padding-right: 112px;
    margin-right: -112px;
}
.address_box {
    padding: 30px;
}
</style>

