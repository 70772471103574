<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        
        <el-form-item label="增加时间">
          <div class="flex-box">
            <el-date-picker
              v-model="daterange"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="所属商家">
          <el-input v-model="inquire.merchantName" placeholder="所属商家" @keyup.enter.native="searchList" ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addAddress">添加自提地址</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="addressName" label="所在地区" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="address" label="详细地址" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="merchantName" label="所属商家" min-width="160"></el-table-column>
            <el-table-column prop="createTime" label="增加时间" min-width="160"></el-table-column>
            <el-table-column prop="" label="操作" min-width="200" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span> 
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="deleteData(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 添加自提地址弹框 -->
    <el-dialog
    :title="dialogTitle + '自提地址'"
    :visible.sync="dialogVisible"
    :before-close="closeDialog"
    width="650px"
    top="5vh">
      <div class="Popout_content">
        <el-form label-width="110px" ref="submitForm" :model="submitForm" :rules="submitRules">
            <el-form-item label="所在地址" prop="addressId">
              <el-cascader v-model="submitForm.addressId" :props="props_add" :options="addressList_add" placeholder="请选项地址" class="width-inherit"></el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              <el-input v-model="submitForm.address" maxlength="30" placeholder="详细地址"></el-input>
            </el-form-item>
            <el-form-item label="所属商家" prop="merchantId">
              <div>
                <el-button type="primary" size="small" @click="openMerchantList">选择</el-button>
              </div>
              <div>
                <el-table :data="merchantTable" v-loading="loading" border style="width: 100%">
                  <el-table-column prop="merchantCode" label="商家编号" min-width="100"></el-table-column>
                  <el-table-column prop="merchantName" label="商家名称" min-width="100"></el-table-column>
                </el-table>
              </div>
            </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 商家列表弹框 -->
    <el-dialog
      title="选择商家"
      :visible.sync="dialogVisible_2"
      :before-close="() => dialogVisible_2 = false"
      width="70%"
      top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="" prop="address">
              <el-input v-model="inquire_2.merchantName" maxlength="30" placeholder="商家名称"></el-input>
          </el-form-item>
          <!-- <el-form-item label="配送地址">
            <div class="flex-box">
              <el-select v-model="inquire.name" placeholder="请选择" class="form-select">
                <el-option label="药房名称" value="药房名称"></el-option>
                <el-option label="联系人姓名" value="联系人姓名"></el-option>
                <el-option label="手机号" value="手机号"></el-option>
                <el-option label="电话" value="电话"></el-option>
                <el-option label="药房编号" value="药房编号"></el-option>
                <el-option label="所属商家" value="所属商家"></el-option>
                <el-option label="业务员姓名" value="业务员姓名"></el-option>
              </el-select>
              <el-input v-model="inquire.name" placeholder=""></el-input>
            </div>
          </el-form-item> -->

          <el-form-item>
            <el-button type="primary" size="small" @click="getMerchantList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table ref="merchantTabel" :data="tableData_2" v-loading="loading_2" border height="400px" style="width: 100%" highlight-current-row @current-change="currentChange">
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
          </el-table-column>
          <el-table-column prop="merchantCode" label="商家编号" min-width="120"></el-table-column>
          <el-table-column prop="merchantName" label="商家名称" min-width="160"></el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange_2"
            @current-change="handleCurrentChange_2"
            background
            :current-page="pageParam_2.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_2.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_2.total"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmMerchant">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      daterange: [], // 增加时间
      inquire: {
        createTimeBegin:'',
        createTimeEnd:'',
        merchantName:''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [], 

      // 添加配送地址
      dialogTitle: '添加', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      submitForm: {
        addressId:  '',
        address:  '',
        merchantId:  ''
      },
      submitRules: {
        addressId: [{required: true, message: '请选择所在地址', trigger: 'blur'}],
        address: [{required: true, message: '请输入详细地址', trigger: 'blur'}],
        merchantId: [{required: true, message: '请选择所属商家', trigger: 'blur'}],
      },
      merchantTable: [],
      attacheTable: [],
      isAdd: true, // 是否为添加 
      editRow: {},

      // 省市区
      addressList_add: [],
      props_add: { 
        label: 'addressName',
        value: 'addressId',
        children: 'child',
        // checkStrictly: true, 
        emitPath: false 
      },
      addressHierarchy:[],// 地址层级列表

      selectRow: {}, // 选择当前行
      // ==== 所属医院弹框 ====
      dialogVisible_2: false,
      inquire_2:{
        merchantName:''
      },
      loading_2: false,
      tableData_2: [],
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },

    };
  },
  created() {
    // 获取省市区
    this.getAddress()
    // 获取产品列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    // 获取省市区
    getAddress() {
      this.$http.requestGet({ url: '/common/address', loading:false}).then(res => {
        this.addressList_add = res.data
      })
    },
    

    // 获取自提地址列表
    getTableList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : '';
      this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : '';
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/hospital/pickup/address/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    
    // 添加产品类别
    addAddress() {
      this.dialogTitle = '添加';
      this.dialogVisible = true;
      this.isAdd = true;
    },
    // 修改类别
    handleEdit(row) {
      this.dialogTitle = '编辑';
      this.isAdd = false;
      this.submitForm = {
        addressId:  row.addressId,
        address:  row.address,
        merchantId:  row.merchantId,
      }
      this.dialogVisible = true;
      this.editRow = row;
      this.getDataInfo()
    },
    // 获取其详情数据
    getDataInfo() {
      this.$http.requestGet({url:'/hospital/pickup/address/get/' + this.editRow.pickUpId, loading:false}).then(res => {
        this.merchantTable = [res.data.merchant];
      })
    },
    // 提交类别添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          let param = {
            addressId: this.submitForm.addressId,
            address: this.submitForm.address,
            merchantId: this.submitForm.merchantId,
          };
          if (!this.isAdd) {
            param.pickUpId = this.editRow.pickUpId;
          }
          this.$http.requestPost({
            url: '/hospital/pickup/address/save',
            param: param
          }).then(res => {
            this.getTableList()
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.closeDialog()
          })
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields());
      this.dialogVisible = false;
      this.submitForm = {
        addressId:  '',
        address:  '',
        merchantId:  ''
      }
      this.merchantTable = []
    },

    // 删除
    deleteData(row) {
      this.$confirm('确定删除该自提地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
          this.pageParam.pageNum--
        }
        this.$http.requestDel({url:'/hospital/pickup/address/del/' + row.pickUpId}).then(res => {
          this.getTableList();
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        })
      })
    },


    // ========== 所属医院 ==========
    // 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1;
      this.pageParam_2.pageSize = size;
      // 刷新(数据)
      this.getMerchantList()
    },
    // 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page;
      // 刷新(页码)
      this.getMerchantList();
    },

    // 打开商家列表
    openMerchantList() {
      this.getMerchantList()
      this.dialogVisible_2 = true;
    },

    // 查询商家列表
    getMerchantList() {
      let data = {
        pageNum: this.pageParam_2.pageNum,
        pageSize: this.pageParam_2.pageSize,
        param: this.inquire_2
      }

      this.loading_2 = true;
      this.$http.requestPost({
        url: '/customer/merchant/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading_2 = false;
        this.tableData_2 = res.data.list;
        this.pageParam_2.total = res.data.total;
        if (this.merchantTable.length > 0) {
          let index = this.tableData_2.findIndex(item => item.merchantCode === this.merchantTable[0].merchantCode)
          this.$refs.merchantTabel.setCurrentRow(this.tableData_2[index])
        }
      }).catch(err => {
        this.loading_2 = false;
        console.log(err)
      })
    },
    // 确定商家的选择
    confirmMerchant() {
      this.merchantTable = [];
      this.merchantTable.push(this.selectRow)
      this.submitForm.merchantId = this.selectRow.merchantId;
      this.dialogVisible_2 = false;
    },

    // 表格单选
    currentChange(news, old) {
      this.selectRow = news;
    },

  },
};
</script>

<style lang="scss" scoped>
  .address-hierarchy {
    flex-wrap: wrap;
  }
</style>
