<!-- 产品库【经销产品管理 -- 在售产品列表】 -->
<template>
  <div class="main-box">
    <SaleProductList />
  </div>
</template>

<script>
import SaleProductList from '../components/saleProductList.vue'

export default {
  components: {
    SaleProductList
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped></style>