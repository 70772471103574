<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="订单编号">
          <el-input v-model="inquire.orderCode" placeholder="订单编号" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item :label="$api.getNeedValue(timeType, orderStatus, 'type', 'name')">
          <el-date-picker
            v-model="datetimerange"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>

        <el-form-item label="">
          <div class="flex-box">
            <el-cascader v-model="categoryId" :props="props" :options="productTypeList" clearable @change="categoryChange" />
            <el-select v-show="productList.length > 0" v-model="inquire.productIds" multiple placeholder="请选择产品" class="width-260">
              <el-option v-for="item in productList" :key="item.productId" :label="item.productName" :value="item.productId" />
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="优惠券ID">
          <el-input v-model.trim="inquire.couponCode" placeholder="优惠券ID" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="fnExportOrderList">导出</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            ref="tables"
            :data="tableData"
            :expand-row-keys="expands"
            border
            height="400px"
            row-key="orderId"
            style="width: 100%"
            @expand-change="expandChange"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table :data="scope.row.items" border style="width: 100%">
                  <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex-box">
                        <img
                          v-if="scope.row.productImg"
                          :src="scope.row.productImg"
                          alt=""
                          class="img-box"
                        >
                        <div class="hospital-name">{{ scope.row.productName }}</div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="产品编号"
                    min-width="200"
                    prop="productCode"
                    show-overflow-tooltip
                  />
                  <el-table-column label="产品规格" min-width="120" prop="productNorms">
                    <template slot-scope="scope">{{ scope.row.productNorms || '/' }}</template>
                  </el-table-column>
                  <el-table-column label="单价" min-width="120" prop="itemPrice">
                    <template slot-scope="scope">{{ '￥' + scope.row.itemPrice }}</template>
                  </el-table-column>
                  <el-table-column label="数量" min-width="120" prop="itemNum" />
                  <el-table-column label="购买总价" min-width="120" prop="">
                    <template slot-scope="scope">
                      {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="订单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
              <template slot-scope="scope">
                <span
                  class="operation-btn"
                  @click="handleParticulars(scope.row)"
                >{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="买家/收货人" min-width="160" prop="" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.memberNickName + '/' + scope.row.recipient }}
              </template>
            </el-table-column>
            <el-table-column label="总金额" min-width="120" prop="">
              <template slot-scope="scope">
                {{ scope.row.orderTotalPrice ? '￥' + scope.row.orderTotalPrice.toFixed(2) : '￥0.00' }}
              </template>
            </el-table-column>
            <el-table-column label="运费" min-width="120" prop="">
              <template slot-scope="scope">
                {{ scope.row.shippingFee ? '￥' + scope.row.shippingFee.toFixed(2) : '￥0.00' }}
              </template>
            </el-table-column>

            <el-table-column v-if="orderStatus =='auditing'" label="退货申请时间" min-width="160" prop="applyReturnTime" />
            <el-table-column v-if="orderStatus =='returned'" label="退货退款时间" min-width="160" prop="returnTime" />
            <el-table-column v-if="orderStatus == 'unaudited' || orderStatus == 'returning'" label="退货审核时间" min-width="160" prop="auditTime" />
            <el-table-column v-if="orderStatus == 'pending_sign'" label="退货发货时间" min-width="160" prop="returningTime" />

            <el-table-column label="订单状态" min-width="120" prop="orderStatus">
              <template slot-scope="scope">
                {{ $api.getValue($api.returnOrderState, scope.row.orderStatus, 'name') }} {{ scope.row.afterSalesRequestReport ? '(售后)' : '' }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" min-width="160" prop="remark">
              <template slot-scope="scope">
                <span v-show="scope.row.orderStatus === 'pending_sign'" class="operation-btn" @click="handleSignFor(scope.row)">签收</span>
                <span v-show="scope.row.orderStatus === 'pending_sign'" class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleParticulars(scope.row)">{{ scope.row.orderStatus === 'auditing' ? '审核' : '详情' }}</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-size="pageParam.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <el-dialog title="签收" :visible.sync="dialogVisible_2" width="70%">
      <div class="Popout_content">
        <el-form class="demo-form-inline" size="medium" label-position="top">

          <div v-for="(item,index) in detailInfo.items" :key="index">
            <el-form-item label="产品信息">
              <el-table :data="detailInfo.productList" border style="width:842px;">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="产品信息" width="200" prop="" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div class="flex-box">
                      <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box">
                      <div class="hospital-name">{{ scope.row.productName }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="单价（￥）" width="120" prop="itemPrice" />
                <el-table-column label="数量" width="120" prop="itemNum" />
                <el-table-column label="小计" width="120" prop="">
                  <template slot-scope="scope">
                    {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column label="实收金额" width="120" prop="">
                  <template slot-scope="scope">
                    {{ '￥' + (parseInt(scope.row.itemNum) * (scope.row.paidAmount || scope.row.itemPrice)).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="100" fixed="right">
                  <template>
                    <span class="operation-btn" @click="openSelectBatch(index)">选择批次</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="赠品信息" v-if="detailInfo.giftList.length > 0">
              <el-table :data="detailInfo.giftList" border style="width:842px;">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="产品信息" width="200" prop="" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div class="flex-box">
                      <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box">
                      <div class="hospital-name">{{ scope.row.productName }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="单价（￥）" width="120" prop="itemPrice" />
                <el-table-column label="数量" width="120" prop="itemNum" />
                <el-table-column label="小计" width="120" prop="">
                  <template slot-scope="scope">
                    {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column label="实收金额" width="120" prop="">
                  <template slot-scope="scope">
                    {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="100" fixed="right">
                  <template>
                    <span class="operation-btn" @click="openSelectBatch(index)">选择批次</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </div>
          <el-form-item label="退货信息">
            <div v-for="(item,index) in detailInfo.items" :key="index">

              <div style="width:100%;">{{ item.productName }}</div>
              <el-table :data="detailInfo.items[index].batchList" border style="width: 872px">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="批次" width="150" prop="batchNo" />
                <el-table-column label="有效期（天）" width="120" prop="termOfValidity" />
                <el-table-column label="出库数量" width="120" prop="deliverCount" />
                <el-table-column label="商品编号" width="140" prop="produceTime" />
                <el-table-column label="添加时间" width="140" prop="expireTime" />
                <el-table-column label="数量" width="160" prop="">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.num" @blur="verifyInt(scope.row.num, index, scope.$index)" />
                  </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="100" fixed="right">
                  <template slot-scope="scope">
                    <span class="operation-btn" @click="deleteBatch(index, scope.row.$index)">删除</span>

                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmRefund">确 认</el-button>
      </span>
    </el-dialog>

    <!-- 选择批次弹窗 -->
    <el-dialog title="选择批次" :visible.sync="dialogVisible_batch" width="80%" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">

          <el-form-item label="批次">
            <el-input v-model="inquire_batch.batchNo" placeholder="批次" clearable />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="searchList_batch">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table ref="batchTable" v-loading="loading" :data="batchTable" border height="400px" @selection-change="handleBatchChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{ ( pageParam_batch.pageNum - 1) * pageParam_batch.pageSize + (scope.$index + 1) }}</template>
          </el-table-column>
          <el-table-column label="批次" min-width="160" prop="batchNo" />
          <el-table-column label="库存" min-width="120" prop="count" />
          <el-table-column label="生产日期" min-width="160" prop="produceTime" />
          <el-table-column label="到期日期" min-width="160" prop="expireTime" />
          <el-table-column label="有效期（天）" min-width="120" prop="termOfValidity" />
          <el-table-column label="入库时间" min-width="160" prop="createTime" />
          <el-table-column label="操作人" min-width="160" prop="operatorName" />
        </el-table>
        <div class="pagination">
          <el-pagination background :current-page="pageParam_batch.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam_batch.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam_batch.total" @size-change="handleSizeChange_batch" @current-change="handleCurrentChange_batch" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_batch = false">取 消</el-button>
        <el-button type="primary" @click="confirmBatchSelect">确 定</el-button>
      </span>
    </el-dialog>
    <d-export :inquire="inquire" :show-dialog="showExportDialog" :close.sync="showExportDialog" />
  </div>
</template>

<script>
import dExport from '@/components/order/d-export'

export default {
    components: {
        dExport
    },
    props: {
        orderStatus: {
            type: String,
            default: 'auditing'
        },
        deliveryMethod: {
            type: String,
            default: 'hospital'
        },
        isAfterSale: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            timeType: [
                { name: '退货申请时间', type: 'auditing' },
                { name: '退货退款时间', type: 'returned' },
                { name: '退货审核时间', type: 'unaudited' },
                { name: '退货审核时间', type: 'returning' },
                { name: '退货发货时间', type: 'pending_sign' }
            ],

            // 产品分类
            productTypeList: [],
            props: {
                label: 'categoryName',
                value: 'categoryId',
                children: 'child',
                // checkStrictly: true,
                emitPath: true
            },
            productList: [],
            categoryId: '',

            inquire: {
                orderClassify: 'medicine',
                isReturnOrder: true,
                orderStatus: '',
                // applyReturnBegin: '',
                // applyReturnEnd: '',
                orderCode: '',
                productIds: [],
                categoryId: '',
                couponCode: ''
            },
            datetimerange: [],

            // 表格数据
            tableData: [{ name: 'aaa' }],
            loading: false,
            expands: [], // 当前展示行
            // 分页
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },

            // ========= 导出弹窗 =========
            showExportDialog: false,
            dialogVisible_2: false, // 签收弹窗
            detailInfo: '', // 签收item数据
            currentIndex: 0, // 选择批次的产品下标

            // 选择批次弹窗
            dialogVisible_batch: false,
            inquire_batch: {
                inventoryId: '',
                merchantId: '',
                batchNo: '',
                batchIds: []
            },
            pageParam_batch: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            batchTable: [],
            selectBatch: [] // 选择的批次
        }
    },
    watch: {
        tableData(val) {
            this.$nextTick(() => {
                this.$refs.tables.doLayout()
            })
        }
    },
    created() {
        if (sessionStorage.getItem('cacheParamD')) {
            this.inquire = JSON.parse(sessionStorage.getItem('cacheParamD'))
            if (this.inquire.applyReturnBegin && this.inquire.applyReturnEnd) {
                this.datetimerange[0] = this.inquire.applyReturnBegin || ''
                this.datetimerange[1] = this.inquire.applyReturnEnd || ''
            } else if (this.inquire.returnTimeBegin && this.inquire.returnTimeEnd) {
                this.datetimerange[0] = this.inquire.returnTimeBegin || ''
                this.datetimerange[1] = this.inquire.returnTimeEnd || ''
            } else if (this.inquire.auditTimeBegin && this.inquire.auditTimeEnd) {
                this.datetimerange[0] = this.inquire.auditTimeBegin || ''
                this.datetimerange[1] = this.inquire.auditTimeEnd || ''
            } else if (this.inquire.returningTimeBegin && this.inquire.returningTimeEnd) {
                this.datetimerange[0] = this.inquire.returningTimeBegin || ''
                this.datetimerange[1] = this.inquire.returningTimeEnd || ''
            }
        }
        this.inquire.orderStatus = this.orderStatus
        this.inquire.deliveryMethod = this.deliveryMethod
        this.getInitData()
        this.getProductType()
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1
            this.pageParam.pageSize = size
            this.getTableData()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page
            this.getTableData()
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1
            this.getTableData()
        },
        // table 手风琴
        expandChange(news, old) {
            if (old.length === 0) {
                return this.expands = []
            }
            this.expands = [news.orderId]
        },
        // 获取产品类型
        getProductType() {
            this.$http.requestGet({ url: '/product/category', loading: false }).then(res => {
                this.productTypeList = res.data
            })
        },
        // 产品分类改变
        categoryChange(e) {
            this.productList = []
            this.inquire.productIds = []
            if (e.length > 1) {
                this.getProductList(e[1])
                this.inquire.categoryId = ''
            } else {
                this.inquire.categoryId = e[0]
            }
        },
        // 根据分类id获取产品列表
        getProductList(id) {
            this.$http.requestGet({ url: '/customer/order/category/' + id, loading: false }).then(res => {
                this.productList = res.data
            })
        },
        // 导出
        fnExportOrderList() {
            if (!this.datetimerange) this.datetimerange = []
            this.inquire.orderTimeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : ''
            this.inquire.orderTimeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : ''
            this.inquire.categoryId = this.categoryId
            this.showExportDialog = true
        },

        getInitData() {
            if (!this.datetimerange) this.datetimerange = []
            var timeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : ''
            var timeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : ''

            switch (this.orderStatus) {
                case 'auditing':
                    this.inquire.applyReturnBegin = timeBegin
                    this.inquire.applyReturnEnd = timeEnd
                    break
                case 'returned':
                    this.inquire.returnTimeBegin = timeBegin
                    this.inquire.returnTimeEnd = timeEnd
                    break
                case 'pending_sign':
                    this.inquire.returningTimeBegin = timeBegin
                    this.inquire.returningTimeEnd = timeEnd
                    break
                case 'unaudited':
                    this.inquire.auditTimeBegin = timeBegin
                    this.inquire.auditTimeEnd = timeEnd
                    break
                case 'returning':
                    this.inquire.auditTimeBegin = timeBegin
                    this.inquire.auditTimeEnd = timeEnd
                    break
            }
            // this.inquire.applyReturnBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : '';
            // this.inquire.applyReturnEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : '';
            const data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            }
            if (this.isAfterSale && this.orderStatus == 'auditing') {
                data.param.isAfterSale = true
            } else if (!this.isAfterSale && this.orderStatus == 'auditing') {
                data.param.isAfterSale = false
            }

            this.loading = true
            this.$http.requestPost({
                url: '/customer/order/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false
                this.tableData = res.data.list
                this.pageParam.total = res.data.total
                sessionStorage.removeItem('cacheParamD')
            }).catch(err => {
                this.loading = false
                sessionStorage.removeItem('cacheParamD')
                console.log(err)
            })
        },

        // 获取列表数据
        getTableData() {
            if (!this.datetimerange) this.datetimerange = []
            var timeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : ''
            var timeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : ''

            switch (this.orderStatus) {
                case 'auditing':
                    this.inquire.applyReturnBegin = timeBegin
                    this.inquire.applyReturnEnd = timeEnd
                    break
                case 'returned':
                    this.inquire.returnTimeBegin = timeBegin
                    this.inquire.returnTimeEnd = timeEnd
                    break
                case 'pending_sign':
                    this.inquire.returningTimeBegin = timeBegin
                    this.inquire.returningTimeEnd = timeEnd
                    break
                case 'unaudited':
                    this.inquire.auditTimeBegin = timeBegin
                    this.inquire.auditTimeEnd = timeEnd
                    break
                case 'returning':
                    this.inquire.auditTimeBegin = timeBegin
                    this.inquire.auditTimeEnd = timeEnd
                    break
            }
            // this.inquire.applyReturnBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : '';
            // this.inquire.applyReturnEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : '';
            const data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            }
            if (this.isAfterSale && this.orderStatus == 'auditing') {
                data.param.isAfterSale = true
            } else if (!this.isAfterSale && this.orderStatus == 'auditing') {
                data.param.isAfterSale = false
            }

            this.loading = true
            this.$http.requestPost({
                url: '/customer/order/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false
                this.tableData = res.data.list
                this.pageParam.total = res.data.total
                if (res.data.list.length === 0) {
                    this.$alert('请检查输入订单号是否正确或业务类型是否匹配', '无订单提示', {
                        confirmButtonText: '关闭'
                    })
                }
                sessionStorage.removeItem('cacheParamD')
            }).catch(err => {
                this.loading = false
                sessionStorage.removeItem('cacheParamD')
                console.log(err)
            })
        },

        // 签收
        handleSignFor(row) {
            this.detailInfo = JSON.parse(JSON.stringify(row))
            this.detailInfo.items.map(v => {
                v.batchList = []
            })
            this.dialogVisible_2 = true
            this.inquire_batch.inventoryId = this.detailInfo.items[0].inventoryId
            this.inquire_batch.merchantId = this.detailInfo.deliveryMethod === 'hospital' ? this.detailInfo.merchantId : ''
            this.auditState = 1
          this.detailInfo.productList = []
          this.detailInfo.giftList = []
          this.detailInfo.items.forEach(item => {
            if (item.isGifts) {
              this.detailInfo.giftList.push(item)
            } else {
              this.detailInfo.productList.push(item)
            }
          })
        },

        // 订单详情
        handleParticulars(row) {
            sessionStorage.setItem('cacheParamD', JSON.stringify(this.inquire))
            const type = this.$api.returnOrderState.findIndex(item => item.value === row.orderStatus) + 1
            let url = ''
            if (this.deliveryMethod == 'hospital') {
                url = '/order/medical/hospitalDelivery/medicalReturnedGoods/medicalParticulars'
            } else {
                url = '/order/medical/nationwideDelivery/medicalReturnedGoods/medicalParticulars'
            }
            this.$router.push({
                path: url,
                query: {
                    type: type,
                    id: row.orderId
                }
            })
        },

         // ================ 选择批次 ================
        // 页容量改变
        handleSizeChange_batch(size) {
        this.pageParam_batch.pageNum = 1
        this.pageParam_batch.pageSize = size
        // 刷新(数据)
        this.getBatchList()
        },
        // 页码改变
        handleCurrentChange_batch(page) {
            this.pageParam_batch.pageNum = page
            // 刷新(页码)
            this.getBatchList()
        },
        // 批次列表查询
        searchList_batch() {
            this.pageParam_batch.pageNum = 1
            this.getBatchList()
        },
        // 获取批次列表
        getBatchList() {
            const data = {
                pageNum: this.pageParam_batch.pageNum,
                pageSize: this.pageParam_batch.pageSize,
                param: JSON.parse(JSON.stringify(this.inquire_batch))
            }
            // 获取要筛选掉的批次id
            const len = this.detailInfo.items[this.currentIndex].batchList ? this.detailInfo.items[this.currentIndex].batchList.length : 0
            if (len > 0) {
                const batchList = this.detailInfo.items[this.currentIndex].batchList
                data.param.batchIds = batchList.map(v => v.batchId)
            }
            data.param.itemId = this.detailInfo.items[this.currentIndex].itemId
            this.loading = true
            this.$http.requestPost({
                url: '/customer/order/pageStockBatch',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false
                const { list, total } = res.data
                list.map(v => {
                const d = new Date(v.expireTime)
                const timeStamp = d - new Date(v.produceTime)
                const termOfValidity = timeStamp / (24 * 60 * 60 * 1000)

                v.termOfValidity = termOfValidity.toFixed(0)
                })
                this.batchTable = list
                this.pageParam_batch.total = total
            }).catch(err => {
                this.loading = false
            })
        },
         // 出库数量输入值检测
        verifyInt(v, i1, i2) {
            // 判断是否为正整数
            if (!this.$validate.verifyInt(v)) {
                this.detailInfo.items[i1].batchList[i2].num = 0
                return
            }

            // 判断当前出库数量是否大于实际购买数量
            const itemNum = this.detailInfo.items[i1].itemNum
            const returnNum = this.detailInfo.items[i1].returnNum || 0
            // let num = itemNum - returnNum;
            // console.log('num',num)
            const totalNum = this.detailInfo.items[i1].batchList.reduce((t, n) => t + parseInt(n.num || 0), 0)
            console.log('totalNum', totalNum)
            if (totalNum > returnNum) {
                this.detailInfo.items[i1].batchList[i2].num = 0
                return
            }

            // 将类似 '01' 的字符串转换为 1
            this.detailInfo.items[i1].batchList[i2].num = parseInt(v)
        },
        // 打开选择批次弹窗
        openSelectBatch(index) {
            this.currentIndex = index
            this.dialogVisible_batch = true
            this.searchList_batch()
        },
        // 选择批次
        handleBatchChange(e) {
        this.selectBatch = e
        },
        // 确定批次的选择
        confirmBatchSelect() {
            const arr = JSON.parse(JSON.stringify(this.selectBatch))
            arr.map(v => v.num = 0)
            this.detailInfo.items[this.currentIndex].batchList.push(...arr)
            this.$refs.batchTable && this.$refs.batchTable.toggleRowSelection()
            this.dialogVisible_batch = false
        },

        confirmRefund() {
            const items = this.detailInfo.items
            let require = true
            const batch = []
            let deliver = false
            // 当 whetherDirectReturn 不为 1 时，需传批次 , 等于 1 时，仅退款
            if (this.detailInfo.whetherDirectReturn != 1) {
                for (let i = 0; i < items.length; i++) {
                // let itemNum = items[i].itemNum - parseInt((items[i].returnNum || 0));
                const itemNum = items[i].itemNum
                items[i].batchList.forEach((t, v) => {
                    if (t.deliverCount && parseInt(t.num) > parseInt(t.deliverCount)) {
                        t.num = 0
                        deliver = true
                    }
                })
                console.log('items[i].batchList', items[i].batchList)
                const outboundNum = items[i].batchList.reduce((t, v) => {
                    if (parseInt(v.num) > 0) {
                        const curBatch = {
                            customerOrderId: this.detailInfo.orderId,
                            batchId: v.batchId,
                            deliverCount: v.num,
                            customerOrderItemId: items[i].itemId
                        }
                        batch.push(curBatch)
                    }
                    console.log('t', v)
                    return t + parseInt(v.num ? v.num : 0)
                }, 0)
                console.log('outboundNum', outboundNum)
                console.log('itemNum', itemNum)
                if (deliver == true) {
                    this.$message.warning(`不能超出当前批次的出库数量`)
                    require = false
                    break
                }
                if (outboundNum < itemNum) {
                    this.$message.warning(`"${items[i].productName}" 产品的退回批次数量不足`)
                    require = false
                    break
                } else if (outboundNum > itemNum) {
                    this.$message.warning(`"${items[i].productName}" 产品的退回批次数量超出`)
                    require = false
                    break
                }
            }
            }

            if (require) {
                if (batch.length <= 0) {
                    return this.$message.warning(`请填写出库数量`)
                }
                this.submitAudit(batch)
            }
        },
        // 删除出库批次
        deleteBatch(index1, index2) {
            this.detailInfo.items[index1].batchList.splice(index2, 1)
        },
        // ================ 审核 - 驳回 ================
        reject() {
            this.auditState = 2
            this.dialogVisible_1 = true
        },
        // 审核驳回确定
        confirm() {
            this.$refs.submitForm.validate(valid => {
                if (valid) {
                this.submitAudit()
                } else {
                return false
                }
            })
        },
        // 提交审核信息
        submitAudit(batch) {
            var url = '/customer/order/return/audit/'
            var param = {}
            var that = this
            if (that.auditState === 1) {
                param = batch
            }
            // 驳回
            else if (that.auditState === 2) {
                url = '/customer/order/return/reject/'
                param = { rejectReason: this.submitForm.rejectReason }
            }

            that.$confirm('是否确认已签收？', '签收确认', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    that.$http.requestPost({ url: '/customer/order/return/sign/' + that.detailInfo.orderId, param }).then(res => {
                        console.log(res)
                        that.$message.success('操作成功')
                        that.$router.go(-1)
                    })
                }).catch(() => {

                })
        }
    }
}
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
    width: 375px;
}

.el-head-search-row {
    .flex-box {
        width: 326px;
    }
}

.show-money {
    padding: 10px 0;

    ul {
        li {
            display: flex;
            justify-content: flex-end;
            line-height: 30px;
            font-size: 16px;
        }

        .line-box {
            margin-left: 50%;
            border-bottom: 1px solid #ccc;
        }

        .bold {
            font-weight: bolder;
        }
    }
}

.block_span {
    display: inline-block;
    width: 108px;
    text-align: right;
}
</style>
