// import Cookies from 'js-cookie'

// const TokenKey = 'vue_admin_template_token'

// export function getToken() {
//   return Cookies.get(TokenKey)
// }

// export function setToken(token) {
//   return Cookies.set(TokenKey, token)
// }

// export function removeToken() {
//   return Cookies.remove(TokenKey)
// }

// 设置本地存储-localStorage
function setSessionStorage(key, value = '') {
    window.sessionStorage.setItem(key, JSON.stringify(value))
}

// 获取本地存储
function getSessionStorage(key) {
    return JSON.parse(window.sessionStorage.getItem(key))
}

// 删除指定本地存储
function removeToken(key) {
    window.sessionStorage.removeItem(key)
}

// 清空会话存储
function clearStorage() {
    window.sessionStorage.clear()
}

export {
    setSessionStorage,
    getSessionStorage,
    removeToken,
    clearStorage
}
