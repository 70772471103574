<template>
  <div class="main-box">
    <div class="search-row bg_white">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        
        <el-form-item label="时间">
          <el-button :type="active === 0 ? 'primary' : ''" @click="setTimeState(0 ,0)" size="small">今天</el-button>
          <el-button :type="active === 1 ? 'primary' : ''" @click="setTimeState(1, -1)" size="small">昨天</el-button>
          <el-button :type="active === 2 ? 'primary' : ''" @click="setTimeState(2, -6)" size="small">近7天</el-button>
          <el-button :type="active === 3 ? 'primary' : ''" @click="setTimeState(3, -29)" size="small">近30天</el-button>
          <el-button :type="active === 4 ? 'primary' : ''" @click="setTimeState(4, 'current')" size="small">这个月</el-button>
          <el-button :type="active === 5 ? 'primary' : ''" @click="setTimeState(5, 'last')" size="small">上个月</el-button>
          <el-button :type="active === 6 ? 'primary' : ''" @click="setTimeState(6, true)" size="small">自定义范围</el-button>
        </el-form-item>

        <el-form-item label="" v-show="customTimeShow">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        </br>

        <el-form-item label="业务范围">
          <!-- <el-button :type="computedBusinessState(0)" @click="setBusinessState(0, '')" size="small">全部</el-button> -->
          <el-button :type="computedBusinessState(1)" @click="setBusinessState(1, 0)" size="small">销售</el-button>
          <el-button :type="computedBusinessState(2)" @click="setBusinessState(2, 1)" size="small">租赁</el-button>
        </el-form-item>

        <el-form-item label="区域">
          <el-cascader v-model="district" :options="addressList" :props="props" clearable v-if="$public.isAllAreaPower()" ></el-cascader>
          <d-area ref="area_1" :values.sync="district" allVal v-else />
        </el-form-item>

        <!-- <el-form-item label="产品">
          <el-select v-model="inquire.name" placeholder="请选择">
            <el-option v-for="(item,index) in productList" :key="index" :label="item.name"  :value="item.id"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div class="row_box bg_white" >
      <div class="header">
        <span>收入概况</span>
      </div>
      <div class="content">
        <ul class="income-box flex-box">
          <li v-for="(item,index) in incomeList" :key="index">
            <div class="title_box">{{item.label}}</div>
            <div class="money">{{item.money}}<span class="unit">元</span></div>
          </li>
        </ul>
      </div>
    </div>

    <div class="row_box bg_white" >
      <div class="header">
        <span>营收趋势</span>
      </div>
      <div class="content">
        <div id="echars-box"></div>
      </div>
    </div>

  </div>
</template>

<script>
import { getSessionStorage } from "@/utils/auth";

import dArea from '@/components/d-area'
export default {
  components:{
    dArea
  },
  data() {
    return {
      active: 4,// 时间状态
      businessState: 1,// 业务状态
      
      // 查询参数
      inquire: {
        payTime: '',
        payTimeEnd: '',
        type: 0, 
        regionId: '',
        districtId: '',
        agencyId: '',
      },
      daterange: [],
      district: [],
      customTimeShow: false,

      productList: [],// 产品列表
      addressList:[],// 区域
      props: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        // emitPath: false
      },

      incomeList: [
        { label: '总收入', money: '0.00'},
        { label: '已收货', money: '0.00'},
        { label: '未收货', money: '0.00'},
        { label: '退款中', money: '0.00'},
      ],
      pageData:{},
      userInfo: {},// 用户信息
    };
  },
  created() {
    let date = this.$public.getAssignMonth(new Date())
    this.inquire.payTime = date[0];
    this.inquire.payTimeEnd = date[1];
    this.getArea()
    this.userInfo = getSessionStorage('userInfo')
    if (this.userInfo.managerType != 'all') {
      let areas = this.userInfo.areas

      if (this.userInfo.managerType == 'region') {
        this.district = [areas[0].regionId]
      } else if (this.userInfo.managerType == 'district') {
        this.district = [areas[0].regionId, areas[1].districtId]
      } else {
        this.district = [areas[0].regionId, areas[1].districtId, areas[2].agencyId]
      }
    }
    

    this.getPageData()
  },
  mounted() {

  },
  methods: {
    // 设置时间状态
    setTimeState(num, day) {
      this.active = num;
      if (typeof day === 'number'){
        if (day === -1) {
          this.inquire.payTime = this.$public.getAssignDate(day);
          this.inquire.payTimeEnd = this.$public.getAssignDate(day);
        } else {
          this.inquire.payTime = this.$public.getAssignDate(day);
          this.inquire.payTimeEnd = this.$public.getAssignDate(0);
        }
      } else if (typeof day === 'string'){
        if (day === 'current') {
          let date = this.$public.getAssignMonth(new Date())
          this.inquire.payTime = date[0];
          this.inquire.payTimeEnd = date[1];
        } else if (day === 'last') {
          // 获取上个月的日期
          let now = new Date();
          let year = now.getFullYear()
          let month = now.getMonth() + 1
          let day = now.getDate()
          month === 0 ? (year--, month = 12) : (month--)
          let date = this.$public.getAssignMonth(year +'-'+ month +'-'+ day )
          this.inquire.payTime = date[0];
          this.inquire.payTimeEnd = date[1];
        }
      } else {
        this.inquire.payTime = ''
        this.inquire.payTimeEnd = ''
        return this.customTimeShow = day
      }
      this.customTimeShow ? (this.customTimeShow = false, this.daterange = []) : ''
    },
    // 设置业务状态
    setBusinessState(num, type) {
      this.businessState = num;
      this.inquire.type = type
    },
    computedTimeState(num) {
      return this.active === num ? 'primary' : ''
    },
    computedBusinessState(num) {
      return this.businessState === num ? 'primary' : ''
    },
    // 获取区域级联数据
    getArea() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data
      })
    },
    // 获取产品集合
    getProductList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.productList = res.data
      })
    },

    // 获取数据列表
    getPageData() {
      if (this.district.length > 0) {
        if (this.district.length == 1) this.inquire.regionId = this.district[0]
        if (this.district.length == 2) this.inquire.districtId = this.district[1]
        if (this.district.length == 3) this.inquire.agencyId = this.district[2]
      } else {
        this.inquire.regionId = "";
        this.inquire.districtId = "";
        this.inquire.agencyId = "";
      }
      if (this.customTimeShow) {
        if (this.daterange.length < 1) return this.$message.warning('请选择时间范围！');
        this.inquire.payTime = this.daterange[0] ? this.daterange[0] : '';
        this.inquire.payTimeEnd = this.daterange[1] ? this.daterange[1] : '';
      }
      if (this.inquire.payTime.length === 10)  {
        this.inquire.payTime =  this.inquire.payTime + ' 00:00:00' ;
        this.inquire.payTimeEnd =  this.inquire.payTimeEnd + ' 23:59:59' ;
      }
      this.inquire.payTime = this.inquire.payTime.length > 10 ? this.inquire.payTime : '';
      this.inquire.payTimeEnd = this.inquire.payTimeEnd.length > 10 ? this.inquire.payTimeEnd : '';

      this.$http.requestPost({
        url: '/wait/payment/merchant/commission/comprehensive/operate',
        param: this.inquire,
        loading: false
      }).then(res => {
        let data = res.data;
        this.pageData = res.data;
        this.incomeList[0].money = data.sumMoney ? data.sumMoney.toFixed(2) : '0.00'
        this.incomeList[1].money = data.receiptGoods ? data.receiptGoods.toFixed(2) : '0.00'
        this.incomeList[2].money = data.unreceiptGoods ? data.unreceiptGoods.toFixed(2) : '0.00'
        this.incomeList[3].money = data.unreceiptGoodsBackCash ? data.unreceiptGoodsBackCash.toFixed(2) : '0.00'
      }).catch(err => {
        console.log(err)
      })

      this.renderingEcharts()

    },

    // 渲染柱状图表
    renderingEcharts() {
      var param = {
        mainAreaId: this.inquire.regionId,
        middleAreaId: this.inquire.districtId,
        baseAreaId: this.inquire.agencyId,
        startTime: this.inquire.payTime ? this.inquire.payTime : '',
        endTime: this.inquire.payTimeEnd ? this.inquire.payTimeEnd : '',
        productClassify: this.inquire.type
      }
      this.$http.requestPost({ 
        url: '/wait/payment/merchant/commission/financialManagement/statisticalAnalysis', 
        param,
        loading: false 
      }).then(res => {
        var list = res.data,
            xAxis = [],
            series = [];
        list.sumMoney.forEach(e => {
          xAxis.push(e.times)
        })
        Object.keys(list).forEach(e => {
          let data = [],
              name = '';
          switch (e) {
            case 'sumMoney':
                name = '总收入'
              break;
            case 'receiptGoods':
                name = '已收货'
              break;
            case 'unreceiptGoods':
                name = '未收货'
              break;
            default:
                name = "退款中"
              break;
          }
          list[e].forEach(o => {
            data.push(o.sales)
          })
          series.push({
              name,
              type: 'line',
              // stack: '总量',
              data
          })
        });

        this.$nextTick(() => {
          var echarts = require('echarts');

          // 基于准备好的dom，初始化echarts实例
          var myChart = echarts.init(document.getElementById('echars-box'));
          // 绘制图表
          myChart.setOption({
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['总收入', '已收货', '未收货', '退款中']
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            toolbox: {
                feature: {
                    saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: xAxis
            },
            yAxis: {
                type: 'value'
            },
            series: series
          }, true);
        })

      })
    },
  },
};
</script>

<style lang="scss" scoped>
  .main-box {
    background-color: #f5f5f5;
  }
  .search-row {
    padding: 20px 20px 20px;
    margin-bottom: 10px;
  }

  .row_box {
    margin-bottom: 10px;
    font-size: 16px;

    .header {
      font-weight: 700;
      padding: 18px 0px 10px;
      margin: 0 20px 0;
      border-bottom: 1px solid #cccccc;
    }

    .content {
      padding: 10px;

      .income-box {
        font-weight: bold;
        padding: 30px 20px 40px;
        justify-content: space-around;

        .title_box {
          font-size: 14px;
          padding: 10px 0 14px;
        }

        .money {
          font-size: 28px;

          .unit {
            font-size: 10px;
            padding-left: 5px;
          }
        }
      }
    }
  }

  #echars-box {
    width: 100%;
    height: 400px;
  }
</style>
