<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="交易时间">
          <el-date-picker
            v-model="daterange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <!-- <el-form-item label="关联单号">
          <el-input v-model="inquire.name"></el-input>
        </el-form-item> -->

        <!-- <el-form-item label="第三方交易单号">
          <el-input v-model="inquire.name"></el-input>
        </el-form-item> -->
        
        <!-- <el-form-item label="交易金额">
          <div class="flex-box">
            <el-input v-model="inquire.name" placeholder="0" class="width-110"></el-input>
            <span class="span-link"> - </span>
            <el-input v-model="inquire.name" placeholder="0" class="width-110"></el-input>
          </div>
        </el-form-item> -->

        <!-- <el-form-item label="收支类型">
          <el-select v-model="inquire.name" placeholder="请选择">
            <el-option label="全部" value="1"></el-option>
            <el-option label="收入" value="2"></el-option>
            <el-option label="支出" value="3"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item label="订单类型">
          <el-select v-model="inquire.orderClassify" placeholder="请选择">
            <el-option label="电商品订单" value="electronic"></el-option>
            <el-option label="医药品订单" value="medicine"></el-option>
            <el-option label="租赁品订单" value="lease"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary">导出报表</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="times" label="日期" min-width="160"></el-table-column>
            <el-table-column prop="orderTotalPrice" label="收款金额" min-width="120">
              <template slot-scope="scope">
                {{ '￥' + (scope.row.orderTotalPrice || 0) }}
              </template>
            </el-table-column>
            <el-table-column prop="orderCount" label="订单数量" min-width="100">
              <template slot-scope="scope">{{scope.row.orderCount || 0}}</template>
            </el-table-column>
            <el-table-column prop="memberCount" label="交易会员数" min-width="100">
              <template slot-scope="scope">{{scope.row.memberCount || 0}}</template>
            </el-table-column>
            <el-table-column prop="orderReturnTotalPrice" label="退款金额" min-width="120">
              <template slot-scope="scope">{{ '￥' + (scope.row.orderReturnTotalPrice || 0) }}</template>
            </el-table-column>
            <el-table-column prop="orderReturnCount" label="退款订单数" min-width="100">
              <template slot-scope="scope">{{scope.row.orderReturnCount || 0}}</template>
            </el-table-column>
            <el-table-column prop="memberReturnCount" label="退款会员数" min-width="100">
              <template slot-scope="scope">{{scope.row.memberReturnCount || 0}}</template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="120" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="details(scope.row)">交易明细</span> 
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        payTime: '',
        payTimeEnd: '',
        orderClassify: 'electronic',
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [],  
    };
  },
  created() {
    this.daterange = [this.$public.getAssignMonth(new Date())[0] + ' 00:00:00', this.$public.getAssignDate(0) + ' 23:59:59']
    // 获取产品列表
    this.getProductList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getProductList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getProductList()
    },

    // 获取产品列表
    getProductList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.payTime = this.daterange.length > 0 ? this.daterange[0] : '';
      this.inquire.payTimeEnd = this.daterange.length > 0 ? this.daterange[1] : '';

      this.loading = true;
      this.$http.requestPost({
        url: '/wait/payment/merchant/commission/comprehensive/everyDayStatistics?pageNum=' + this.pageParam.pageNum + '&pageSize=' + this.pageParam.pageSize,
        param: this.inquire,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.data.list;
        this.pageParam.total = res.data.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    // 详细
    details(row) {
      this.$router.push({
        path: '/financial/financialStatement/transactionDetail',
        query: {
          time: row.times,
          orderClassify: this.inquire.orderClassify
        }
      })
    }
    
  },
};
</script>

<style lang="scss" scoped>
  
</style>
