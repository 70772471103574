<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isRouterAlive:true,
    }
  },
  provide(){
    return{
      reload:this.reload
    }
  },
  created() {
    
  },
  methods:{
    reload () {
            this.isRouterAlive = false
            this.$nextTick(()=> {
            this.isRouterAlive = true
        })
  }
}
}
</script>
