<template>
  <div class="main-box">
    <!-- 成员列表 -->
    <div class="head-search-row">
      <el-form
        :model="queryParams"
        :inline="true"
        class="demo-form-inline"
        size="medium"
      >
        <el-form-item label="成员姓名">
          <el-input
            v-model="queryParams.memberName"
            placeholder="请输入成员姓名"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="会员编号">
          <el-input
            v-model="queryParams.memberCode"
            placeholder="请输入会员编号"
            clearable
            style="width: 240px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary"  @click="handleQuery"
            >查询</el-button
          >
          <el-button type="primary"  @click="showDialog = true"
          >导出</el-button
        >
        </el-form-item>
        
      </el-form>
    </div>
    <div class="table">
      <el-main>
        <template>
          <el-table
            :data="tableData"
            v-loading="loading"
            border
            height="400px"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              width="60"
              align="center"
              type="index"
            />
            <el-table-column
              label="最近更新时间"
              align="center"
              prop="updateTime"
            />
            <el-table-column
              label="成员编号"
              align="center"
              prop="memberCode"
            />
            <el-table-column
              label="成员姓名"
              align="center"
              prop="memberName"
            />
            <el-table-column label="年龄" align="center" prop="memberAge" />
            <el-table-column
              label="会员编码"
              align="center"
              prop="memberCode"
            />
            <el-table-column label="会员昵称" align="center" prop="nickName" />
            <el-table-column fixed="right" label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text"  @click="goDetail(scope.row)"
                  >健康信息</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :tableHeader="tableHeader"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNum"
            :page-sizes="[10, 40, 300, 400]"
            :page-size="queryParams.pageSize"
            layout=" total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 导出弹窗 -->
    <el-dialog
      title="成员列表导出"
      destroy-on-close
      :visible.sync="showDialog"
      width="50%"
      append-to-body
      @open="openEvent"
      @close="closeEvent"
      class="export-member"
    >
      <el-form label-width="100px" :inline="true">
        <el-form-item label="成员名称" style="width: 550px">
          <el-input
            v-model="formData.memberName"
            placeholder="成员名称"
            class="input"
          />
        </el-form-item>
        <el-form-item label="成员编号" style="width: 550px">
          <el-input
              v-model="formData.memberCode"
            placeholder="成员编号"
            class="input"
          />
        </el-form-item>
        <br />
        <el-form-item label="新增时间">
          <el-date-picker
            class="picker"
            v-model="formData.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="exportMember"> 导出 </el-button>
        <el-button @click="showDialog = false"> 关闭 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {exportMember} from "@/api/consult";
export default {
  data() {
    return {
      tableHeader: [
        {
          label: "最近更新时间",
          prop: "updateTime",
          width: "100",
        },
        {
          label: "成员姓名",
          prop: "memberName",
        },
        {
          label: "年龄",
          prop: "memberAge",
        },
        {
          label: "会员编码",
          prop: "memberCode",
        },
        {
          label: "会员昵称",
          prop: "nickName",
        },
      ],
      queryParams: {
        memberName: "",
        memberCode: "",
        pageNum: 1,
        pageSize: 10,
        memberName: "",
        nickName: "",
        beginCreateTime: "",
        endCreateTime: "",
      },
      tableData: [], //成员列表
      showDialog: false,
      // 弹窗表格
      formData: {
        memberCode: "",
        memberName: "",
        time: [],
      },
      loading: false,
      total: 0,
    };
  },
  created() {
    this.getMemberList();
  },
  methods: {
    // 成员列表
    getMemberList() {
      this.$http
        .requestPost({
          url: "/consult/queryMemberList",
          param: this.queryParams,
          loading: false,
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 导出成员列表
    exportMember() {
      let param = {
        memberName: this.formData.memberName,
        memberCode: this.formData.memberCode,
        beginCreateTime: this.formData.time.length
            ? dayjs(this.formData.time[0]).format("YYYY-MM-DD HH:mm:ss")
            : null, //新增开始时间
        endCreateTime: this.formData.time.length
            ? dayjs(this.formData.time[1]).format("YYYY-MM-DD HH:mm:ss")
            : null, //新增结束时间
      };

      exportMember(
          param,
          'post',
          `member_${new Date().getTime()}.xlsx`)
          .then((res) => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.showDialog = false;
          }).catch((err) => {
        console.error('导出报错', err)
      })

    },
    // 查询
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getMemberList();
    },
    // 设置每页几条
    setSize(val) {
      this.pageSize = val;
      this.getList();
    },
    // 设置第几页
    setPage(val) {
      this.pageNum = val;
      this.getList();
    },
    // 弹窗打开
    openEvent() {},
    // 弹窗关闭
    closeEvent() {
      // 数据重置
      this.formData = {
        memberCode: "",
        memberName: "",
        time: [],
      };
    },
    // 成员列表页码改变
    handleSizeChange(size) {
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = size;
      this.getMemberList();
    },
    handleCurrentChange(page) {
      this.queryParams.pageNum = page;
      this.getMemberList();
    },
    // 跳转健康信息详情
    goDetail(row) {
      this.$router.push({
        name: "healthInfoDetail",
        params: {
          id: row.id,
        },
      });
      console.log(row, "详情");
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 0 30px;
}
.search {
  //  height: 84px;
  padding: 10px 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div {
    margin-top: 20px;
    display: flex;
    align-items: center;
  }
  .input {
    width: 250px;
    margin: 0 20px;
  }
  .picker {
    margin: 0 20px;
  }
}

.export-member {
  .input {
    width: 400px;
  }
}
</style>
