<template>
  <!-- 商家 - 产品展示/处理 -->
  <div class="main">
    <!-- 产品列表 -->
    <el-dialog
      :title="title"
      :before-close="closeDialog"
      :visible.sync="dialogShow"
      top="12vh"
      width="75%"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="">
            <el-select v-model="selectKey" placeholder="请选择" class="width-140" @change="selectValue = ''">
              <el-option label="产品编号" value="productCode" />
              <el-option label="产品名称" value="productName" />
            </el-select>
            <el-input v-model="selectValue" placeholder="" class="width-180" clearable />
          </el-form-item>

          <el-form-item label="">
            <el-cascader
              v-model="inquire_1.productCategoryId"
              :props="productProps"
              :options="productTypeList"
              clearable
            />
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              @click="(pageParam_1.pageNum = 1, getHasLinkedProductListByMerchantId())"
            >查询
            </el-button>
          </el-form-item>

          <el-form-item>
            <el-button v-if="operation" size="small" type="primary" @click="addProduct">添加产品</el-button>
            <el-button v-if="operation && pageType === 'system'" size="small" type="primary" @click="delProduct">删除产品</el-button>
          </el-form-item>
        </el-form>
        <el-table ref="msgTabel" v-loading="loading_1" :data="productList" border height="400px" style="width: 100%" @selection-change="currentDelChange">
          <el-table-column v-if="pageType === 'system'" type="selection" width="55" />
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ (pageParam_1.pageNum - 1) * pageParam_1.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label="产品编号" min-width="170" prop="productCode" show-overflow-tooltip />
          <el-table-column label="产品名称" min-width="160" prop="productName" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="txt_box" :class="{'flex-box':!scope.row.productImg,'flex-center':!scope.row.productImg}">
                <img v-if="scope.row.isGifts" src="@/assets/image/gift_icon.png" alt="" class="icon_box" :class="{no_img:!scope.row.productImg}">
                {{ scope.row.productName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="所属归类" min-width="140" prop="productCategoryName" show-overflow-tooltip />
          <!-- <el-table-column label="销售价(￥)" min-width="140" prop="messengerPhoneNumber">
                    <template slot-scope="scope">
                        {{ scope.row.productPriceMin + ' ~ ' + scope.row.productPriceMax }}
                    </template>
                </el-table-column> -->
          <el-table-column v-if="pageType !== 'system'" label="库存" min-width="120" prop="productInventoryTotal" />
          <el-table-column label="操作" min-width="140" prop="">
            <template slot-scope="scope">
              <span class="operation-btn" @click="handleDetails(scope.row)">详情</span>
              <span v-if="operation" class="operation-btn_partition"> / </span>
              <span v-if="operation" class="operation-btn" @click="delProduct(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam_1.pageNum"
            :page-size="pageParam_1.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam_1.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_1"
            @current-change="handleCurrentChange_1"
          />
        </div>
        <div style="padding-bottom: 20px;" />
      </div>
    </el-dialog>

    <!-- 商家信息员弹框 -->
    <el-dialog
      :before-close="() => dialogVisible_2 = false"
      :visible.sync="dialogVisible_2"
      title="未选择产品列表"
      top="12vh"
      width="75%"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="">
            <el-select v-model="selectKey" placeholder="请选择" class="width-140" @change="selectValue = ''">
              <el-option label="产品编号" value="productCode" />
              <el-option label="产品名称" value="productName" />
            </el-select>
            <el-input v-model="selectValue" placeholder="" class="width-180" clearable />
          </el-form-item>

          <el-form-item label="">
            <el-cascader
              v-model="inquire_2.productCategoryId"
              :props="productProps"
              :options="productTypeList"
              clearable
            />
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              @click="(pageParam_2.pageNum = 1, getUnLinkedProductListByMerchantId())"
            >查询
            </el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="msgTabel"
          v-loading="loading_2"
          :data="productList_2"
          border
          height="400px"
          style="width: 100%"
          @selection-change="currentChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ (pageParam_2.pageNum - 1) * pageParam_2.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label="产品编号" min-width="170" prop="productCode" show-overflow-tooltip />
          <el-table-column label="产品名称" min-width="160" prop="productName" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="txt_box" :class="{'flex-box':!scope.row.productImg,'flex-center':!scope.row.productImg}">
                <img v-if="scope.row.isGifts" src="@/assets/image/gift_icon.png" alt="" class="icon_box" :class="{no_img:!scope.row.productImg}">
                {{ scope.row.productName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="所属归类" min-width="140" prop="productCategoryName" show-overflow-tooltip />
          <el-table-column v-if="pageType !== 'system'" label="销售价(￥)" min-width="140" prop="messengerPhoneNumber">
            <template slot-scope="scope">
              {{ scope.row.productPriceMin + ' ~ ' + scope.row.productPriceMax }}
            </template>
          </el-table-column>
          <el-table-column v-if="pageType !== 'system'" label="库存" min-width="120" prop="productInventoryTotal" />
          <el-table-column label="操作" min-width="140" prop="">
            <template slot-scope="scope">
              <span class="operation-btn" @click="handleDetails(scope.row)">详情</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam_2.pageNum"
            :page-size="pageParam_2.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam_2.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_2"
            @current-change="handleCurrentChange_2"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import fa from 'element-ui/src/locale/lang/fa'

export default {
  name: 'ProductTable',
  props: {
    dialogShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '产品列表'
    },
    operation: {
      type: Boolean,
      default: true
    },
    pageType: {
      type: String,
      default: 'user'
    }
  },
  data() {
    return {
      productProps: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: false
      },
      productTypeList: [],
      // 商家已绑定产品列表
      productList: [],
      inquire_1: {
        productCategoryId: '',
        merchantId: ''
      },
      // 管理员页面请求参数
      systemInquire_1: {
        managerId: ''
      },
      selectKey: 'productCode',
      selectValue: '',
      pageParam_1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading_1: false,

      // ==================== 设置商家信息员 ====================
      dialogVisible_2: false, // 弹窗
      inquire_2: {
        productCategoryId: ''
      },
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      productList_2: [], // 商家未绑定产品列表
      loading_2: false,
      selectList: [], // table的选择项
      selectDelList: []// table删除的选择项
    }
  },
  created() {
  },
  methods: {
    initData(id) {
      if (this.pageType === 'system') {
        // this.inquire_1.managerId = '415457960072843264'
        // this.inquire_2.managerId = '415457960072843264'
        this.inquire_1.managerId = id
        this.inquire_2.managerId = id
      } else {
        this.inquire_1.merchantId = id
        this.inquire_2.merchantId = id
      }
      this.pageParam_1.pageNum = 1
      console.log(id,'id')
      if (id) {
        this.getHasLinkedProductListByMerchantId()
      }
      this.getProductType()
    },
    // ================== 商家已添加产品 ==================
    // 页容量改变
    handleSizeChange_1(size) {
      this.pageParam_1.pageNum = 1
      this.pageParam_1.pageSize = size
      this.getHasLinkedProductListByMerchantId()
    },
    // 页码改变
    handleCurrentChange_1(page) {
      this.pageParam_1.pageNum = page
      this.getHasLinkedProductListByMerchantId()
    },

    // 商家已绑定产品列表
    getHasLinkedProductListByMerchantId() {
      const data = {
        param: JSON.parse(JSON.stringify(this.inquire_1)),
        pageNum: this.pageParam_1.pageNum,
        pageSize: this.pageParam_1.pageSize
      }
      this.loading_1 = true
      if (this.selectValue) data.param[this.selectKey] = this.selectValue
      // 判断属于哪个页面的产品弹窗
      let url = ''
      this.pageType === 'system' ? url = '/system/manager/getLinkedProductListByManagerId' : url = '/product/getHasLinkedProductListByMerchantId'
      this.$http.requestPost({
        url: url,
        param: data,
        loading: false
      }).then(res => {
        this.productList = res.data.list
        this.pageParam_1.total = res.data.total
        this.loading_1 = false
      }).catch(err => this.loading_1 = false)
    },

    closeDialog() {
      this.productList = []
      this.selectList = []
      this.$emit('update:dialogVisible', false)
    },

    // 新增产品
    addProduct() {
      this.getUnLinkedProductListByMerchantId()
      this.dialogVisible_2 = true
    },
    // 删除商家已绑定产品
    delProduct(row) {
      let title = '确定删除该产品?'
      let param = []
      console.log(this.selectdelList, Array.isArray(this.selectdelList), 'this.selectdelList')
      if (!row.id) {
        title = '确定已选中产品?'
        if (!this.selectdelList) {
            return this.$message.warning('请选择产品')
        } else {
          if (this.selectdelList.length < 1) {
            return this.$message.warning('请选择产品')
          }
        }
        param = this.selectdelList.map(item => item.id)
      } else {
        param.push(row.id)
      }

      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        if (this.pageType === 'system') {
          this.$http.requestPost({
            url: '/system/manager/delManagerProduct',
            param: param,
            loading: false
          }).then(res => {
            this.pageParam_1.pageNum = 1
            this.getHasLinkedProductListByMerchantId()
            this.$message.success('操作成功')
          }).catch(err => {
            this.$message.warning('删除失败，请重试')
          })
        } else {
          this.$http.requestPut({
            url: '/product/delMerchantProduct/' + row.id
          }).then(res => {
            if (res.data) {
              this.pageParam_1.pageNum = 1
              this.getHasLinkedProductListByMerchantId()
              this.$message.success('操作成功')
            } else {
              this.$message.warning('当前商品库存大于零，不可删除')
            }
          })
        }
      })
    },
    // 产品详情
    handleDetails(row) {
      const type = row.productCode.substring(0, 2)
      let path = ''
      if (type === 'LP') {
        path = '/product/lease/addProduct'
      } else if (type === 'MP') {
        path = '/product/medicine/addProduct'
      } else {
        return this.$message.warning('数据错误')
      }
      this.$router.push({
        path,
        query: {
          id: row.productId,
          details: true
        }
      })
    },

    // ================== 商家未添加产品 ==================
    // 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1
      this.pageParam_2.pageSize = size
      this.getUnLinkedProductListByMerchantId()
    },
    // 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page
      this.getUnLinkedProductListByMerchantId()
    },

    // 获取商家信未绑定产品列表
    getUnLinkedProductListByMerchantId() {
      this.loading_2 = true
      const data = {
        pageNum: this.pageParam_2.pageNum,
        pageSize: this.pageParam_2.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire_2))
      }
      if (this.selectValue) data.param[this.selectKey] = this.selectValue
      // 判断属于哪个页面的产品弹窗
      let url = ''
      this.pageType === 'system' ? url = '/system/manager/getUnLinkedProductListByManagerId' : url = '/product/getUnLinkedProductListByMerchantId'
      this.$http.requestPost({
        url: url,
        param: data,
        loading: false
      }).then(res => {
        this.loading_2 = false
        this.productList_2 = res.data.list
        this.pageParam_2.total = res.data.total
      }).catch(err => {
        console.log(err)
        this.loading_2 = false
      })
    },
    // 打开信息员列表
    openMessengerList() {
      this.getMessengerList()
      this.dialogVisible_2 = true
    },
    // table多选
    currentChange(val) {
      this.selectList = val
    },
    // table删除多选
    currentDelChange(val) {
      this.selectdelList = val
    },
    // 确定选择
    confirm() {
      if (this.selectList.length < 1) {
        return this.$message.warning('请选择产品')
      }

      if (this.pageType === 'system') {
        const param = {}
        param.productIds = this.selectList.map(item => {
          return item.productId
        })
        param.managerId = this.inquire_2.managerId
        this.$http.requestPost({
          url: '/system/manager/saveManagerProduct',
          param: param,
          loading: false
        }).then(res => {
          this.dialogVisible_2 = false
          this.$message.success('操作成功')
          this.pageParam_1.pageNum = 1
          this.getHasLinkedProductListByMerchantId()
        })
      } else {
        const ids = []
        this.selectList.forEach(v => {
          ids.push(v.productId)
        })
        this.$http.requestPut({
          url: '/product/saveMerchantProduct/' + this.inquire_2.merchantId + '/' + ids.join()
        }).then(res => {
          this.dialogVisible_2 = false
          this.$message.success('操作成功')
          this.pageParam_1.pageNum = 1
          this.getHasLinkedProductListByMerchantId()
        })
      }
    },

    // 获取药品类型
    getProductType() {
      this.$http.requestGet({
        url: '/product/category',
        loading: false
      }).then(res => {
        this.productTypeList = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.icon_box {
  width: 21px;
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  &.no_img{
    margin-right: 3px;
    position: static;
  }
}

.txt_box {
  position: relative;
  line-height: 1;
}
</style>
