<template>
  <div class="app_main">
    <div class="interval-box"></div>
    <section class="app-main">
      <transition name="fade-transform" mode="out-in">
        <router-view :key="key" />
      </transition>
    </section>
  </div>
  
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.interval-box {
  width: 100%;
  height: 10px;
}
.app_main{
  width: 100%;
  height: calc(100% - 93px)
}

.app-main {
  width: calc(100% - 20px);
  height: 100%;
  margin: auto;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
      -moz-border-radius: 3px;
      -webkit-border-radius: 3px;
      background-color: #c3c3c3;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}
.fixed-header + .app-main {
  padding-top: 50px;
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
