import { render, staticRenderFns } from "./waitForReceiving.vue?vue&type=template&id=aa80e2d0&scoped=true"
import script from "./waitForReceiving.vue?vue&type=script&lang=js"
export * from "./waitForReceiving.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa80e2d0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev_638_admin_web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('aa80e2d0')) {
      api.createRecord('aa80e2d0', component.options)
    } else {
      api.reload('aa80e2d0', component.options)
    }
    module.hot.accept("./waitForReceiving.vue?vue&type=template&id=aa80e2d0&scoped=true", function () {
      api.rerender('aa80e2d0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/order/medical/nationwide/waitForReceiving.vue"
export default component.exports