var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-box" }, [
    _c(
      "div",
      { staticClass: "head-search-row" },
      [
        _c(
          "el-form",
          {
            staticClass: "demo-form-inline",
            attrs: { inline: true, size: "medium" },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "活动ID" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入活动ID" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.queryListInfo($event)
                    },
                  },
                  model: {
                    value: _vm.query.id,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "id", $$v)
                    },
                    expression: "query.id",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "活动名称" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入活动名称" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.queryListInfo($event)
                    },
                  },
                  model: {
                    value: _vm.query.activityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.query, "activityName", $$v)
                    },
                    expression: "query.activityName",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "状态" } },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "form-select",
                    attrs: { placeholder: "请选择", clearable: "" },
                    model: {
                      value: _vm.query.activityStatus,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "activityStatus", $$v)
                      },
                      expression: "query.activityStatus",
                    },
                  },
                  _vm._l(_vm.$api.activityStatus, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.name, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "适用产品" } },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "form-select",
                    attrs: { placeholder: "请选择", clearable: "" },
                    model: {
                      value: _vm.query.productClassify,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "productClassify", $$v)
                      },
                      expression: "query.productClassify",
                    },
                  },
                  _vm._l(_vm.$api.applicableProducts, function (item) {
                    return _c("el-option", {
                      key: item.value,
                      attrs: { label: item.name, value: item.value },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("el-form-item", { attrs: { label: "开始时间" } }, [
              _c(
                "div",
                { staticClass: "flex-box" },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "请选择日期范围",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    on: { change: _vm.queryListInfo },
                    model: {
                      value: _vm.query.tcDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "tcDate", $$v)
                      },
                      expression: "query.tcDate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.queryListInfo },
                  },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "added" },
      [
        _c(
          "el-button",
          {
            attrs: { size: "small", type: "primary" },
            on: { click: _vm.addActivity },
          },
          [_vm._v("添加活动")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-main",
          [
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.dataSource, border: "", height: "330px" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                (_vm.pageParam.pageNum - 1) *
                                  _vm.pageParam.pageSize +
                                  (scope.$index + 1)
                              ) + "\n            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "activityName",
                      label: "活动名称",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.activityDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.activityName))]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "id",
                      label: "活动ID",
                      "min-width": "100",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orientedPopulationDesc",
                      label: "适用产品",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.findEnumName(
                                    _vm.$api.applicableProducts,
                                    scope.row.productClassify
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "couponStatus",
                      label: "状态",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.findEnumName(
                                    _vm.$api.activityStatus,
                                    scope.row.activityStatus
                                  )
                                ) +
                                "\n            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "beginTime",
                      label: "开始时间",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "创建时间",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "操作",
                      "min-width": "100",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.activityStatus !== 3
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "operation-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.activityDetails(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.activityStatus !== 3
                              ? _c(
                                  "span",
                                  { staticClass: "operation-btn_partition" },
                                  [_vm._v(" / ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.activityStatus !== 3
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "operation-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.endActivity(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("结束")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            scope.row.activityStatus === 3
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "operation-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delActivity(scope.row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "current-page": _vm.pageParam.pageNum,
                    "page-sizes": _vm.$api.pageSizes,
                    "page-size": _vm.pageParam.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.pageParam.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }