var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quotaChooseProduct" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择关联产品",
            visible: _vm.dialogVisible,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "quote_relate_search" }, [
            _c("div", { staticClass: "search_item" }, [
              _c("span", { staticClass: "item_label" }, [_vm._v("搜索产品：")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请输入产品名称",
                      clearable: "",
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.updateList($event)
                      },
                    },
                    model: {
                      value: _vm.searchInputVal,
                      callback: function ($$v) {
                        _vm.searchInputVal = $$v
                      },
                      expression: "searchInputVal",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search_item" },
              [
                _c("span", { staticClass: "item_label" }, [
                  _vm._v("产品类别："),
                ]),
                _vm._v(" "),
                _c("el-cascader", {
                  attrs: {
                    props: _vm.cascaderProps,
                    options: _vm.productTypeList,
                    clearable: "",
                  },
                  model: {
                    value: _vm.searchSelectVal,
                    callback: function ($$v) {
                      _vm.searchSelectVal = $$v
                    },
                    expression: "searchSelectVal",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "search_item search_button" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.updateList },
                  },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("TableList", {
            ref: "quoteRealtTable",
            staticClass: "tableList",
            attrs: {
              height: "350px",
              "is-show-index": false,
              "is-selection": true,
              "reserve-selection": true,
              "row-key-name": "productId",
              "table-header": _vm.tableHead,
              "table-data": _vm.list,
              total: _vm.total,
              loading: _vm.loading,
            },
            on: {
              setPage: function ($event) {
                return _vm.pageChange($event)
              },
              setSize: function ($event) {
                return _vm.sizeChange($event)
              },
              delXuan: _vm.selectQuoteRealte,
              handleSelectAll: _vm.selectQuoteRealte,
            },
            scopedSlots: _vm._u([
              {
                key: "sellingPice",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _vm._v(
                      "\n        ￥" +
                        _vm._s(data.productPriceMin) +
                        " ~ ￥" +
                        _vm._s(data.productPriceMax) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "productStatus",
                fn: function (ref) {
                  var data = ref.data
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          data.productStatus == "on_shelves" ? "在售" : "-"
                        ) +
                        "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelSelect } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSelect },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }