<!-- 医品医院派送->售后订单列表 -->
<template>
  <div class="main-box">
    <medical-list orderStatus="completed" deliveryMethod="hospital" :thirtyDaysAgo="beforeDate"></medical-list>
  </div>
</template>

<script>
import medicalList from '@/components/order/medicalList.vue'

export default {
  components: {
    medicalList
  },
  data() {
    return {
      beforeDate: null
    };
  },
  created() {
    this.getThirtyDaysAgoDateTimeString()
  },
  methods: {
    getThirtyDaysAgoDateTimeString() {
      const currentDate = new Date();
      // 减去30天
      currentDate.setDate(currentDate.getDate() - 30);
      // 转换为 "yyyy-MM-dd HH:mm" 格式的字符串
      const formattedDateTime = currentDate.toISOString().slice(0, 16).replace('T', ' ');
      this.beforeDate = formattedDateTime;
    }
  },
};
</script>

<style lang="scss" scoped></style>
