<template>
    <!-- 电商品-已买断订单 -->
    <div class="main-box">
        <lease-list orderStatus="buyout"></lease-list>
    </div>
</template>

<script>
import leaseList from '@/components/order/leaseList.vue'

export default {
    components: {
        leaseList
    },
    data() {
        return {
        
        };
    },
    methods: {
       
    },
};
</script>

<style lang="scss" scoped></style>
