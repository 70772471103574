var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "screenBox",
    { attrs: { id: "largeScreen", width: "1920", height: "1080" } },
    [
      _c("div", { ref: "largeScreen", staticClass: "large-box" }, [
        _c("div", { staticClass: "chart-box" }, [
          _c("div", { staticClass: "title-img" }, [
            _c("div", { staticClass: "title-content" }),
            _vm._v(" "),
            _c("div", { staticClass: "title-bottom" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sales-title" },
              [
                _c(
                  "el-select",
                  {
                    staticClass: "selectDate",
                    attrs: { "popper-append-to-body": false },
                    on: { change: _vm.disposeTimeShow },
                    model: {
                      value: _vm.inquire.dayOrMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "dayOrMonth", $$v)
                      },
                      expression: "inquire.dayOrMonth",
                    },
                  },
                  [
                    _c("el-option", { attrs: { label: "按日统计", value: 1 } }),
                    _vm._v(" "),
                    _c("el-option", { attrs: { label: "按月统计", value: 2 } }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("el-date-picker", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inquire.dayOrMonth === 1,
                      expression: "inquire.dayOrMonth === 1",
                    },
                  ],
                  ref: "datePicker",
                  staticClass: "picker-date",
                  attrs: {
                    "append-to-body": false,
                    type: "datetimerange",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    "default-time": ["00:00:00", "23:59:59"],
                    "range-separator": "-",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "prefix-icon": "el-icon-date",
                    "picker-options": _vm.pickerOptions,
                  },
                  on: { change: _vm.changeDate },
                  model: {
                    value: _vm.daterange,
                    callback: function ($$v) {
                      _vm.daterange = $$v
                    },
                    expression: "daterange",
                  },
                }),
                _vm._v(" "),
                _c("el-date-picker", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.inquire.dayOrMonth === 2,
                      expression: "inquire.dayOrMonth === 2",
                    },
                  ],
                  staticClass: "picker-month",
                  attrs: {
                    "append-to-body": false,
                    "value-format": "yyyy-MM-dd",
                    type: "monthrange",
                    "range-separator": "-",
                    "start-placeholder": "开始月份",
                    "end-placeholder": "结束月份",
                  },
                  on: { change: _vm.changeDate },
                  model: {
                    value: _vm.daterange,
                    callback: function ($$v) {
                      _vm.daterange = $$v
                    },
                    expression: "daterange",
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "center-box" }, [
            _c("div", { staticClass: "content" }, [
              _c("div", { staticClass: "center-top" }, [
                _c("div", { attrs: { id: "total-data" } }, [
                  _c("div", { staticClass: "tip" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/image/wenhao.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { ref: "poup", staticClass: "poup" }, [
                      _c("div", { staticClass: "txt1" }, [
                        _vm._v(
                          "\n                    医院总数：累计至今正在使用中的医院数量"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    骑手总数：累计至今的商家端数量"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    用户总数：累计至今已注册的用户数量"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    下单用户总数：累计至今已购买过的用户数量\n                  "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "date" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "data-box" }, [
                    _c("div", { staticClass: "data-item" }, [
                      _c("div", { staticClass: "data-left" }, [
                        _c("img", {
                          attrs: {
                            src: "https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/8/1cc92c3f-6b51-49d0-a458-888c08c81bc9.png",
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "data-txt" }, [
                        _c("div", { staticClass: "data" }, [
                          _vm._v(
                            _vm._s(_vm.platformBasicData.hospitalTotality)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "data-title" }, [
                          _vm._v("医院总数"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "data-item" }, [
                      _c("div", { staticClass: "data-left" }, [
                        _c("img", {
                          attrs: {
                            src: "https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/8/6ea3240c-c53d-4b81-bb19-ea3a3055db79.png",
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "data-txt" }, [
                        _c("div", { staticClass: "data" }, [
                          _vm._v(_vm._s(_vm.platformBasicData.riderTotality)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "data-title" }, [
                          _vm._v("骑手总数"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "data-item" }, [
                      _c("div", { staticClass: "data-left" }, [
                        _c("img", {
                          attrs: {
                            src: "https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/b5bfbf36-99af-4598-ae73-8bce69a68104.png",
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "data-txt" }, [
                        _c("div", { staticClass: "data" }, [
                          _vm._v(_vm._s(_vm.platformBasicData.userTotality)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "data-title" }, [
                          _vm._v("用户总数"),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "data-item" }, [
                      _c("div", { staticClass: "data-left" }, [
                        _c("img", {
                          attrs: {
                            src: "https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/169a3986-1f07-4bf3-b98d-d0fcf64b2257.png",
                            alt: "",
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "data-txt" }, [
                        _c("div", { staticClass: "data" }, [
                          _vm._v(
                            _vm._s(_vm.platformBasicData.orderUserTotality)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "data-title" }, [
                          _vm._v("下单用户总数"),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { attrs: { id: "data-top" } }, [
                  _c("div", { staticClass: "tip" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/image/wenhao.png"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { ref: "poup", staticClass: "poup" }, [
                      _c("div", { staticClass: "txt1" }, [
                        _vm._v(
                          "\n                    访问人数：所选时间段内访问638医品的用户总数"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    下单人数：所选时间段内已下单的用户总数"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    新增用户：所选时间段内首次注册登录的用户总数"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                    转化率=下单人数/访问人数"
                        ),
                        _c("br"),
                        _c("br"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "txt2" }, [
                        _vm._v(
                          "\n                    注：\n                    "
                        ),
                        _c("div", { staticClass: "txt" }, [
                          _vm._v(
                            "\n                      无法查看2023-01-01之前的访问次数、访问人数、"
                          ),
                          _c("br"),
                          _vm._v(
                            "新增用户、转化率的数据\n                    "
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "data-box" }, [
                    _c("div", { staticClass: "data-item" }, [
                      _c("div", { staticClass: "data-txt" }, [
                        _c("div", { staticClass: "data" }, [
                          _vm._v(_vm._s(_vm.getDailyActive.accessSum)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "data-title" }, [
                          _vm._v("访问人数"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "data-right" }, [
                        _c("img", {
                          attrs: {
                            src: "https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/976bf1a5-fc10-43a9-93a8-3a88ce3275b7.png",
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "data-item" }, [
                      _c("div", { staticClass: "data-txt" }, [
                        _c("div", { staticClass: "data" }, [
                          _vm._v(
                            _vm._s(_vm.getDailyActive.dailyOrderingUsersSum)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "data-title" }, [
                          _vm._v("下单人数"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "data-right" }, [
                        _c("img", {
                          attrs: {
                            src: "https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/ec2e0371-ede1-403e-8af3-c5722876765c.png",
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "data-item" }, [
                      _c("div", { staticClass: "data-txt" }, [
                        _c("div", { staticClass: "data" }, [
                          _vm._v(_vm._s(_vm.getDailyActive.dailyNewUsers)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "data-title" }, [
                          _vm._v("新增用户"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "data-right" }, [
                        _c("img", {
                          attrs: {
                            src: "https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/c79f7392-db99-4d04-9117-634fc28f96c6.png",
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "data-item" }, [
                      _c("div", { staticClass: "data-txt" }, [
                        _c("div", { staticClass: "data" }, [
                          _vm._v(
                            _vm._s(_vm.getDailyActive.averageConversionRate)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "data-title" }, [
                          _vm._v("转化率"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "data-right" }, [
                        _c("img", {
                          attrs: {
                            src: "https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/90706cd2-3a54-4aac-8c56-9a5e787d64c8.png",
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center-bottom" }, [
                _c("div", { staticClass: "left pub-flex-column-between" }, [
                  _c(
                    "div",
                    { attrs: { id: "business-data" } },
                    [
                      _c("div", { staticClass: "business-title" }, [
                        _c("div", { staticClass: "title" }, [
                          _c("div", { staticClass: "tip" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/image/wenhao.png"),
                                alt: "",
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { ref: "poup", staticClass: "poup" }, [
                              _c("div", { staticClass: "txt1" }, [
                                _vm._v(
                                  "\n                          统计所选时间段内大区/片区/办事处/个人/产品的销售数据\n                        "
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "icon" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/image/pt.png"),
                                alt: "",
                              },
                            }),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "business" }, [
                            _vm._v("平台业务数据"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _vm.managerType == "all"
                          ? _c(
                              "div",
                              { staticClass: "nav-list" },
                              _vm._l(_vm.list, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.value,
                                    staticClass: "item",
                                    class: _vm.current == index ? "active" : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getSelect(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.name) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.managerType == "region"
                          ? _c(
                              "div",
                              { staticClass: "nav-list" },
                              _vm._l(_vm.list1, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.value,
                                    staticClass: "item",
                                    class: _vm.current == index ? "active" : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getSelect(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.name) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.managerType == "district"
                          ? _c(
                              "div",
                              { staticClass: "nav-list" },
                              _vm._l(_vm.list2, function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: item.value,
                                    staticClass: "item",
                                    class: _vm.current == index ? "active" : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getSelect(index)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.name) +
                                        "\n                    "
                                    ),
                                  ]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("dv-scroll-board", {
                        ref: "scrollBoard",
                        staticClass: "carouselList",
                        attrs: { config: _vm.config_1 },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pie-box" },
                    [
                      _c("pieChart", {
                        ref: "pieChart",
                        attrs: { "manager-type": _vm.managerType },
                        on: { selectRadio: _vm.selectRadio },
                        model: {
                          value: _vm.ratioCurrent,
                          callback: function ($$v) {
                            _vm.ratioCurrent = $$v
                          },
                          expression: "ratioCurrent",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "center" }, [
                  _c("div", { staticClass: "brange" }, [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.barrage,
                            expression: "barrage",
                          },
                        ],
                        staticClass: "scrolling",
                      },
                      [
                        _c("div", { staticClass: "first" }, [
                          _vm._v(_vm._s(_vm.attacheName)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "one" }, [
                          _vm._v(_vm._s(_vm.barrage)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "two" }, [
                          _vm._v(" " + _vm._s(_vm.product)),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "three" }, [
                          _vm._v(_vm._s(_vm.price)),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { id: "sales-data" } }, [
                    _c("div", { staticClass: "data-content" }, [
                      _c("div", { staticClass: "top-module" }, [
                        _c("div", { staticClass: "name1" }, [
                          _vm._v("总销售额"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "name2" }, [
                          _vm._v("总订单量"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "bottom-module" },
                        [
                          _c("module", {
                            attrs: { value: _vm.marketing.totalPrice, time: 2 },
                          }),
                          _vm._v(" "),
                          _c("module", {
                            staticClass: "order-module",
                            attrs: { value: _vm.marketing.totalOrder, time: 2 },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "bottom" },
                    [
                      _c("mapChart", {
                        ref: "mapChart",
                        staticClass: "map-chart",
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right pub-flex-column-between" }, [
                  _c(
                    "div",
                    { staticClass: "border1" },
                    [_c("interviewChart", { ref: "interview" })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "order-box" },
                    [_c("brokenLineChart", { ref: "orderChart" })],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }