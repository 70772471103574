<template>
<!-- 已下架产品列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="产品名称">
          <div class="flex-box">
            <el-input v-model="inquire.productName" placeholder="输入产品名称" @keyup.enter.native="searchList"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="产品类别">
          <el-cascader v-model="inquire.productCategoryId" :props="props" :options="productTypeList"></el-cascader>
        </el-form-item>
        
        <el-form-item label="价格">
          <div class="flex-box">
            <el-input v-model="inquire.productPriceMin" placeholder="0" class="width-110"></el-input>
            <span class="span-link"> - </span>
            <el-input v-model="inquire.productPriceMax" placeholder="0" class="width-110"></el-input>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="productCode" label="产品编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                 <span class="operation-btn" @click="handleEdit(scope.row)">{{scope.row.productCode}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="productCategoryName" label="所属类别" min-width="160"></el-table-column>
            <el-table-column prop="productPriceMin" label="销售价" min-width="100"></el-table-column>
            <el-table-column prop="" label="租赁价" min-width="160" show-overflow-tooltip>
              <template slot-scope="scope">
                {{'￥' + scope.row.productPriceMin + ' ~ ￥' + scope.row.productPriceMax}}
              </template>
            </el-table-column>
            <el-table-column prop="productInventoryTotal" label="总库存" min-width="100"></el-table-column>
            <el-table-column prop="productOrder" label="排序" min-width="100"></el-table-column>
            <el-table-column prop="productStatus" label="状态" min-width="100">
              <template slot-scope="scope">
                {{ $api.getNeedValue(productState, scope.row.productStatus, 'valueCode', 'valueName') }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span> 
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="putaway(scope.row)">上架</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
import { getValue_ } from '@/utils/public'
export default {
  data() {
    return {
      input: "",
      input2: [],

      // 产品类别 级联选项数据
      productTypeList: [],
      props: { 
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true, 
        emitPath: false 
      },

      // 查询参数
      inquire: {
        productClassify: 'lease',
        productStatus:'off_shelves',
        productName: '',
        productCategoryId: '',
        productPriceMin:'',
        productPriceMax:'',
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [],  
      productState: [], // 产品状态
    };
  },
  created() {
    if(sessionStorage.getItem("cacheParamTi")){
      this.inquire = JSON.parse(sessionStorage.getItem("cacheParamTi"))
    }
    // 获取产品列表
    this.getProductList()
    // 获取产品类别列表
    this.getProductType()
    // 获取产品状态列表
    this.getProductState()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getProductList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getProductList()
    },

    // 获取产品列表
    getProductList() {
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/product/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
        sessionStorage.removeItem("cacheParamTi")
      }).catch(err => {
        this.loading = false;
        sessionStorage.removeItem("cacheParamTi")
        console.log(err)
      })
    },

    // 获取产品类型
    getProductType() {
      this.$api.productTypeList({}).then(res => {
        this.productTypeList = res.data;
      })
    },
    // 获取产品状态
    getProductState() {
      this.$api.dictionaries('product_status').then(res => {
        this.productState = res.data;
      })
    },

    
    // 编辑
    handleEdit(row) {
      sessionStorage.setItem("cacheParamTi", JSON.stringify(this.inquire))
      this.$router.push({
        path: '/product/lease/addProduct',
        query: {
          id: row.productId
        }
      })
    },

    // 上架
    putaway(row) {
      this.$confirm('是否确定上架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.requestPut({url:'/product/on/' + row.productId})
          .then(res => {
            this.getProductList();
            this.$message({
              type: 'success',
              message: '操作成功!'
            });
          })
      }).catch(() => {});
    },

  },
};
</script>

<style lang="scss" scoped>
  
</style>
