<template>
    <!-- 产品类别列表 -->
    <div class="main-box">
        <div class="head-search-row">
            <el-form :inline="true" class="demo-form-inline" size="medium">
                <el-form-item label="类别名称">
                    <div class="flex-box">
                        <el-input v-model="inquire.categoryName" placeholder="类别名称" @keyup.enter.native="getProductList"></el-input>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" @click="getProductList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- <div style="margin: 20px 0 0 20px">
          <el-button size="small" type="primary" @click="addClass">新增类别</el-button>
        </div> -->

        <div class="table">
            <el-main>
                <template>
                    <el-table v-loading="loading" :data="tableData" :tree-props="{children: 'childes'}" border
                              row-key="categoryId" style="width: 100%">
                        <el-table-column label="" prop="" width="60"></el-table-column>
                        <el-table-column label="类别名称" min-width="160" prop="categoryName"></el-table-column>
                        <el-table-column label="类别描述" min-width="160" prop="categoryDescription"></el-table-column>
                        <el-table-column label="新增时间" min-width="160" prop="createTime"></el-table-column>
                        <el-table-column fixed="right" label="操作" min-width="200" prop="">
                            <template slot-scope="scope">
                                <span v-show="scope.row.categoryLevel !== 2" class="operation-btn"
                                      @click="addChilClass(scope.row)">添加子类</span>
                                <span v-show="scope.row.categoryLevel !== 2" class="operation-btn_partition"> / </span>
                                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                                <span v-show="scope.row.categoryLevel !== 0" class="operation-btn_partition"> / </span>
                                <span v-show="scope.row.categoryLevel !== 0" class="operation-btn"
                                      @click="deleteProduct(scope.row)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-main>
        </div>

        <!-- 弹框 -->
        <el-dialog
            :before-close="closeDialog"
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="500px">
            <div class="Popout_content">
                <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="80px">
                    <el-form-item :label="addChil?'父级类别':'类别名称'" prop="categoryName">
                        <el-input v-model="submitForm.categoryName" maxlength="12" :placeholder="addChil?'父类名称':'类别名称'"
                                  :readonly='addChil'></el-input>
                    </el-form-item>
                    <el-form-item v-show="addChil" :prop="addChil?'categoryName2':''" label="子类名称">
                        <el-input v-model="submitForm.categoryName2" maxlength="12" placeholder="子类名称"></el-input>
                    </el-form-item>
                    <el-form-item label="产品描述" prop="categoryDescription">
                        <el-input
                            v-model="submitForm.categoryDescription"
                            :rows="3"
                            placeholder="请输入内容"
                            maxlength="100"
                            type="textarea">
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitClass">确 定</el-button>
    </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 查询参数
            inquire: {
                categoryName: '',
            },
            // 表格数据
            tableData: [],
            loading: false,

            dialogTitle: '添加类别', // 添加弹窗标题
            dialogVisible: false, // 添加弹窗
            addChil: false,  // 判断是否添加子类
            submitForm: {
                categoryName: '',
                categoryName2: '',
                categoryDescription: '',
            },
            submitRules: {
                categoryName: [{required: true, message: '请输入类别名称', trigger: 'blur'}],
                categoryName2: [{required: true, message: '请输入子类名称', trigger: 'blur'}],
                categoryDescription: [{required: true, message: '请输入产品描述', trigger: 'blur'}],

            },
            isAddClass: true, // 是否为添加
            editRow: {},
        };
    },
    created() {
        // 获取产品列表
        this.getProductList()

    },
    methods: {
        // 获取产品列表
        getProductList() {
            this.loading = true;
            this.$http.requestPost({
                url: '/product/customization/category/list',
                param: this.inquire,
                loading: false
            }).then(res => {
                this.loading = false;
                this.tableData = res.data;
            }).catch(err => {
                this.loading = false;
                console.log(err)
            })
        },


        // 添加产品类别
        // addClass() {
        //   this.dialogTitle = '添加类别';
        //   this.addChil = false;
        //   this.dialogVisible = true;
        //   this.isAddClass = true;
        //   this.editRow = {};
        // },
        // 打开添加子类弹窗
        addChilClass(row) {
            this.dialogTitle = '添加子类';
            this.addChil = true;
            this.dialogVisible = true;
            this.isAddClass = true;
            this.editRow = row;
            this.submitForm.categoryName = row.categoryName;
        },
        // 修改类别
        handleEdit(row) {
            this.dialogTitle = '编辑类别';
            this.addChil = false;
            this.isAddClass = false;
            this.dialogVisible = true;
            this.editRow = row;
            this.submitForm = {
                categoryName: row.categoryName,
                categoryDescription: row.categoryDescription,
            }
        },
        // 提交类别添加
        submitClass() {
            this.$refs.submitForm.validate(valid => {
                if (valid) {
                    let param = {
                        categoryName: this.submitForm.categoryName,
                        categoryDescription: this.submitForm.categoryDescription,
                        categoryLevel: this.isAddClass ? this.editRow.categoryLevel ? ++this.editRow.categoryLevel : 1 : this.editRow.categoryLevel
                    };
                    if (!this.isAddClass) {
                        param.categoryId = this.editRow.categoryId;
                    }
                    if (this.addChil) {
                        param.categoryParentId = this.editRow.categoryId;
                        param.categoryName = this.submitForm.categoryName2;
                    }
                    this.submit(param)
                } else {
                    return false
                }
            })
        },
        // 提交
        submit(param) {
            this.$http.requestPost({
                url: '/product/customization/category/save',
                param: param
            }).then(res => {
                this.getProductList()
                this.$message({
                    message: '操作成功',
                    type: 'success'
                })
                this.closeDialog()
            })
        },

        // 关闭弹窗
        closeDialog() {
            this.$refs.submitForm && (this.$refs.submitForm.resetFields());
            this.dialogVisible = false;
            this.submitForm = {
                categoryName: '',
                categoryName2: '',
                categoryDescription: '',
            }
        },

        // 删除
        deleteProduct(row) {
            this.$confirm('确定删除该类别?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
                    this.pageParam.pageNum--
                }
                this.$http.requestDel({
                    url: '/product/customization/category/del/' + row.categoryId,
                    param: ''
                }).then(res => {
                    this.getProductList();
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                })
            })
        }
    },
};
</script>

<style lang="scss" scoped>

</style>
