/* eslint-disable no-undef */
import { Loading, Message } from 'element-ui'
// 导入 token 的删除 token
import { getSessionStorage, setSessionStorage } from '@/utils/auth.js'

import axios from 'axios'
import Qs from 'qs'
import router from '@/router'
let requestUrlNew = ''
let domainNew = ''
import { saveAs } from 'file-saver'

// 环境的切换
if (process.env.NODE_ENV == 'development') {
  console.log('development')
  axios.defaults.baseURL = $publicUrlDev
  requestUrlNew = $publicUrlDev
  domainNew = $domainDev
} else if (process.env.NODE_ENV == 'staging') {
  console.log('staging')
  axios.defaults.baseURL = $publicUrlTest
  requestUrlNew = $publicUrlTest
  domainNew = $domainTest
} else if (process.env.NODE_ENV == 'production') {
  console.log('production')
  axios.defaults.baseURL = $publicUrlProd
  requestUrlNew = $publicUrlProd
  domainNew = $domainProd
}

// axios.defaults.baseURL = $publicUrl;
axios.defaults.timeout = 10 * 60 * 1000
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
axios.defaults.headers.put['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'

// 拦截器
// 添加请求拦截器
axios.interceptors.request.use(
  function(config) {
    if (getSessionStorage('userToken')) {
      config.headers.Authorization = getSessionStorage('userToken')
    }
    // 使用天地度api时，不传token
    if (config.url.indexOf('https://api.tianditu.gov.cn/v2/') !== -1) {
      delete config.headers.Authorization
      // config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
    }

    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  function(response) {
    if (response.data.code == 200 || !response.data.code) {
      return response
    } else if (response.data.status === '1' || response.data.status === '0') {
      return response
    } else if (response.data.code == 401) {
      console.log('router.app._route.path', router.app._route.path)
      Message.warning(response.data.message)
      if (router.app._route.path === '/largeScreenLogin') {
      } else if (
        router.app._route.path !== '/' &&
        (router.app._route.path !== '/smallProportion' &&
          router.app._route.path !== '/largeProportion' &&
          router.app._route.path !== '/middleProportion')
      ) {
        setTimeout(() => {
          router.push('/')
        }, 1500)
      } else if (
        router.app._route.path === '/smallProportion' ||
        router.app._route.path === '/largeProportion' ||
        router.app._route.path === '/middleProportion'
      ) {
        setTimeout(() => {
          router.push('/largeScreenLogin')
        }, 1500)
      }
      return Promise.reject(response.data)
    } else if (response.data.code == 503) {
      console.log(503)
      Message.error(response.data.message)
      return Promise.reject(response.data)
    } else {
      console.log(response)
      Message.error(response.data.message)
      return Promise.reject(response.data)
    }
  },
  function(error) {
    console.log('error', error)
    if (error.toString().indexOf('timeout of') !== -1) {
      Message.error('请求超时！')
    } else {
      Message.error('服务器错误！')
    }
    // return Promise.reject(error)
  }
)

const http = {
  // 请求路径
  requestUrl: requestUrlNew,
  domain: domainNew,

  // GET 请求
  requestGet: ({ url, param = {}, loading = true }) => {
    return new Promise((resolve, reject) => {
      let loadingInstance = null
      if (loading) {
        loadingInstance = Loading.service({
          background: 'rgba(0, 0, 0, 0)'
        })
      }

      axios({
        url: url,
        method: 'GET',
        data: param
      })
        .then(res => {
          if (loading) http.closeLoading(loadingInstance)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
          if (loading) http.closeLoading(loadingInstance)
        })
    })
  },
  // Post 请求
  requestPostQs: ({ url, param = {}, loading = true }) => {
    return new Promise((resolve, reject) => {
      let loadingInstance = null
      if (loading) {
        loadingInstance = Loading.service({
          background: 'rgba(0, 0, 0, 0)'
        })
      }

      axios({
        url: url,
        method: 'POST',
        data: Qs.stringify(param)
      })
        .then(res => {
          if (loading) http.closeLoading(loadingInstance)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
          if (loading) http.closeLoading(loadingInstance)
        })
    })
  },
  // post请求 参数不需要进过qs 转换
  requestPost: ({ url, param = {} || [], loading = true }) => {
    return new Promise((resolve, reject) => {
      let loadingInstance = null
      if (loading) {
        loadingInstance = Loading.service({
          background: 'rgba(0, 0, 0, 0)'
        })
      }

      axios({
        url: url,
        method: 'POST',
        data: param
      })
        .then(res => {
          if (loading) http.closeLoading(loadingInstance)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
          if (loading) http.closeLoading(loadingInstance)
        })
    })
  },
  // DELETE 请求
  requestDel: ({ url, param = {}, loading = true }) => {
    return new Promise((resolve, reject) => {
      let loadingInstance = null
      if (loading) {
        loadingInstance = Loading.service({
          background: 'rgba(0, 0, 0, 0)'
        })
      }

      axios({
        url: url,
        method: 'DELETE',
        data: param
      })
        .then(res => {
          if (loading) http.closeLoading(loadingInstance)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
          if (loading) http.closeLoading(loadingInstance)
        })
    })
  },
  // Put请求
  requestPut: ({ url, param = {}, loading = true }) => {
    return new Promise((resolve, reject) => {
      let loadingInstance = null
      if (loading) {
        loadingInstance = Loading.service({
          background: 'rgba(0, 0, 0, 0)'
        })
      }
      axios({
        url: url,
        method: 'PUT',
        data: param
      })
        .then(res => {
          if (loading) http.closeLoading(loadingInstance)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
          if (loading) http.closeLoading(loadingInstance)
        })
    })
  },

  // post请求 参数不需要进过qs 转换
  requestPutQs: ({ url, param = {}, loading = true }) => {
    return new Promise((resolve, reject) => {
      let loadingInstance = null
      if (loading) {
        loadingInstance = Loading.service({
          background: 'rgba(0, 0, 0, 0)'
        })
      }

      axios({
        url: url,
        method: 'PUT',
        data: Qs.stringify(param)
      })
        .then(res => {
          if (loading) http.closeLoading(loadingInstance)
          resolve(res.data)
        })
        .catch(err => {
          reject(err)
          if (loading) http.closeLoading(loadingInstance)
        })
    })
  },

  download: ({ url, param = {}, method = 'GET', loading = true }) => {
    console.log('[param]', param)
    return new Promise((resolve, reject) => {
      let loadingInstance = null
      if (loading) {
        loadingInstance = Loading.service({
          background: 'rgba(0, 0, 0, 0)'
        })
      }
      axios({
        url: url,
        method,
        data: param,
        responseType: 'blob'
      })
        .then(res => {
          if (loading) http.closeLoading(loadingInstance)
          resolve(res)
        })
        .catch(err => {
          reject(err)
          if (loading) http.closeLoading(loadingInstance)
        })
    })
  },

  directDownload: ({ url, param = {}, method = 'GET', loading = true, filename }) => {
    return new Promise((resolve, reject) => {
      let loadingInstance = null
      if (loading) {
        loadingInstance = Loading.service({
          background: 'rgba(0, 0, 0, 0)'
        })
      }
      axios({
        url: url,
        method,
        data: param,
        responseType: 'blob'
      })
        .then(async res => {
          const isBlob = res.data.type !== 'application/json'
          console.log(res, '下载')
          if (loading) http.closeLoading(loadingInstance)
          if (isBlob && (res.code == 200 || res.status == 200)) {
            const blob = new Blob([res.data])
            saveAs(blob, filename)
            resolve(res)
          } else {
            Message.error('下载失败')
            reject(res)
          }
        })
        .catch(err => {
          Message.error('下载失败')
          if (loading) http.closeLoading(loadingInstance)
          reject(err)
        })
    })
  },
  // 关闭加载
  closeLoading: function(loadingInstance) {
    setTimeout(() => {
      loadingInstance.close()
    }, 300)
  }
}

export default http
