<template>
  <div class="d-preview">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :modal="false"
      :before-close="close"
      width="70%"
      top="5vh"
    >
      <div class="Popout_content">
        <div v-if="fileType === 'image'" class="image-box">
          <img :src="preview" alt="">
        </div>
        <div v-else>
          <video id="video" autoplay :src="preview" controls="controls" width="100%"> 视频描述</video>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    name: 'DPreview',
    props: {
        showDialog: {
            // 是否显示
            type: Boolean,
            default: false
        },
        title: {
            // 允许上传数量
            type: String,
            default: '图片预览'
        },
        fileUrl: {
            // 文件路径
            type: String,
            default: ''
        },
    },
    data() {
        return {
            preview: '', // 预览图片路径
            fileType: '', // 文件类型
            dialogVisible: false, // 预览弹窗
        }
    },
    watch: {
        'showDialog': function(news, old) {
            if (news) {
                this.handlePreview()
            } else {
                this.dialogVisible = false
            }
        }
    },
    methods: {
        // 预览图片
        handlePreview() {
            if (this.fileUrl) {
                const imgT = ['.gif', '.jpeg', '.jpg', '.png']
                const videoT = ['.wmv', '.asf', '.asx', '.rm', '.rmvb', '.rmvb', '.mpg', '.mpeg', '.mpe', '.3gp', '.mov', '.mp4', '.m4v']
                const ind = this.fileUrl.lastIndexOf('.')
                const type = this.fileUrl.slice(ind)
                if (imgT.indexOf(type) !== -1) {
                    this.fileType = 'image'
                } else if (videoT.indexOf(type) !== -1) {
                    this.fileType = 'video'
                }
                this.preview = this.fileUrl
                this.dialogVisible = true
            } else {
                console.error('error: "fileUrl" not for null')
            }

            // 将文件转换成base64格式渲染
            // if (file.raw) {
            //     const type = file.raw.type.split('/')
            //     this.fileType = type[0]

            //     const reader = new FileReader()
            //     reader.readAsDataURL(file.raw)
            //     reader.onload = () => {
            //         // _base64
            //         this.preview = reader.result
            //     }
            // }
        },
        
        // 关闭弹窗
        close() {
            this.$emit('update:close', false)
        }
    }
}
</script>

<style lang="scss" scoped>
.image-box {
    img {
        width: 100%;
        height: 100%;
    }
}
</style>
