<!-- 平台 & 业务员共享列表 -->
<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="优惠券ID">
          <el-input v-model="query.couponCode" placeholder="请输入优惠券ID" @keyup.enter.native="queryListInfo" />
        </el-form-item>
        <el-form-item label="优惠券名称">
          <el-input v-model="query.couponName" placeholder="请输入优惠券名称" @keyup.enter.native="queryListInfo" />
        </el-form-item>
        <el-form-item label="透出时间">
          <div class="flex-box">
            <el-date-picker
              v-model="query.tcDate"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="请选择日期范围"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="queryListInfo"
            />
          </div>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="query.couponStatus" placeholder="请选择" clearable class="form-select">
            <el-option v-for="item in $api.couponStatusOptions" :key="item.value" :label="item.name" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="适用人群">
          <el-select v-model="query.couponUsePerson" placeholder="请选择" clearable class="form-select">
            <el-option v-for="item in $api.couponUsePerson" :key="item.value" :label="item.name" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item label="使用渠道">
          <el-select v-model="query.useChannel" placeholder="请选择" clearable class="form-select">
            <el-option v-for="item in $api.useChannel" :key="item.value" :label="item.name" :value="item.value"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryListInfo">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="added">
      <el-button size="small" type="primary" @click="addCoupon">添加优惠券</el-button>
    </div>
    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="tableLoading" :data="dataSource" border height="330px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="couponName" label="优惠券名称" min-width="160" show-overflow-tooltip />
            <el-table-column prop="couponCode" label="优惠券ID" min-width="180" show-overflow-tooltip />
            <el-table-column prop="orientedPopulationDesc" label="适用人群" min-width="100">
              <!-- <template slot-scope="scope">
                {{ findEnumName($api.couponUsePerson, scope.row.orientedPopulationDesc) }}
              </template> -->
            </el-table-column>
            <el-table-column prop="preferenceMode" label="优惠方式" min-width="100">
              <template slot-scope="scope">
                {{ findEnumName($api.preferenceMode, scope.row.preferenceMode) }}
              </template>
            </el-table-column>
            <el-table-column prop="useChannelDesc" label="使用渠道" min-width="100" />
            <el-table-column prop="couponStatus" label="状态" min-width="100">
              <template slot-scope="scope">
                {{ findEnumName($api.couponStatus, scope.row.couponStatus) }}
              </template>
            </el-table-column>
            <el-table-column v-if="couponType === 'salesperson'" prop="qrCodeCount" label="二维码数量" min-width="100" />
            <!--            <el-table-column prop="couponSurplus" label="优惠券剩余量" min-width="120" />-->
            <el-table-column prop="penetrateTime" label="透出时间" min-width="160" />
            <el-table-column prop="" label="已领取" min-width="110">
              <template slot-scope="scope">
                <div>{{ scope.row.alreadyReceived }}张</div>
              </template>
            </el-table-column>
            <el-table-column prop="" label="已使用" min-width="110">
              <template slot-scope="scope">
                <div>{{ scope.row.haveBeenUsed }}张</div>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="添加时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="180" fixed="right">
              <template slot-scope="scope">
                <div style="display: flex;">
                  <div v-if="scope.row.couponStatus !== '4'">
                    <span class="operation-btn" @click="editCoupon(scope.row)">编辑</span>
                    <span class="operation-btn_partition">/</span>
                    <span class="operation-btn" @click="endCoupon(scope.row)">结束</span>
                  </div>
                  <div v-else>
                    <span class="operation-btn" @click="delCoupon(scope.row.couponId)">删除</span>
                    <span class="operation-btn_partition">/</span>
                    <span class="operation-btn" @click="editCoupon(scope.row)">查看</span>
                  </div>
                  <div v-if="couponType === 'salesperson'" style="margin-left: 4px;">
                    <span class="operation-btn_partition">/</span>
                    <span class="operation-btn" @click="openQrcodeList(scope.row)">二维码列表</span>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            :total="pageParam.total"
            @size-change="handleSizeChange($event, 'couponTable')"
            @current-change="handleCurrentChange($event, 'couponTable')"
          />
        </div>
      </el-main>
    </div>

    <!-- 弹窗相关 -start -->
    <el-dialog :title="`${qrcodeData.couponCode} - 二维码列表`" :visible.sync="qrcodeData.visible" width="82%" top="6vh" @close="closeQrcodeDialog">
      <div class="main-box qrcode_list">
        <!-- 搜索 -->
        <div class="head-search-row search_box">
          <el-form class="demo-form-inline" :inline="true" size="medium">
            <el-form-item label="业务员姓名">
              <el-input
                v-model="qrcodeData.params.intendantName"
                placeholder="业务员姓名"
                clearable
                @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
            <el-form-item v-if="!thirdPartyPermissions" label="所属区域">
              <el-cascader
                v-if="$public.isAllAreaPower()"
                v-model="qrcodeData.params.areaId"
                :props="addressConfigure"
                :options="addressList"
                clearable
                placeholder="请选择地址"
              />
              <d-area v-else :values.sync="qrcodeData.params.areaId" />
            </el-form-item>
            <el-form-item v-if="!thirdPartyPermissions" label="商家端名称">
              <el-input
                v-model="qrcodeData.params.merchantName"
                placeholder="商家端名称"
                clearable
                @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
            <el-form-item label="业务员编号">
              <el-input
                v-model="qrcodeData.params.intendantCode"
                placeholder="业务员编号"
                clearable
                @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
            <el-form-item label="二维码编号">
              <el-input
                v-model="qrcodeData.params.qrCodeSerial"
                placeholder="二维码编号"
                clearable
                @keyup.enter.native="updateList('qrcodeTable')"
              />
            </el-form-item>
          </el-form>
          <div style="margin-bottom: 20px">
            <el-button size="small" type="primary" @click="updateList('qrcodeTable')">查询</el-button>
            <el-button size="small" type="primary" @click="bindQrcode(2)">绑定二维码</el-button>
            <el-button size="small" type="primary" @click="unbindQrcode(2)">解绑二维码</el-button>
          </div>
        </div>
        <!-- 表格 -->
        <div class="qrcodeTable_box">
          <TableList
            ref="qrcodeTable"
            class="table_list"
            height="400px"
            :table-header="qrcodeData.tableHeader"
            :loading="qrcodeData.loading"
            :is-selection="true"
            row-key-name="qrCodeId"
            :table-data="qrcodeData.list"
            :total="qrcodeData.total"
            @setPage="handleCurrentChange($event, 'qrcodeTable')"
            @setSize="handleSizeChange($event, 'qrcodeTable')"
            @delXuan="selectionChange"
          >
            <template #operation="{data}">
              <el-button class="btn" type="text" @click="openPreviewQrcode(data)">二维码</el-button>
              <template v-if="!qrcodeData.showConfirmBtn">
                <span style="color:#4e9aef;">/</span>
                <el-button class="btn" type="text" @click="unbindQrcode(1, data)">解绑</el-button>
              </template>
            </template>
          </TableList>
        </div>
      </div>
      <span v-if="qrcodeData.showConfirmBtn" slot="footer" style="padding-top:0px">
        <el-button @click="confirmOperateQrcode(0)">取 消</el-button>
        <el-button type="primary" @click="confirmOperateQrcode(1)">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看二维码弹窗 -->
    <el-dialog title="查看二维码" :visible.sync="qrcodeData.previewQrcodeDialog" width="400px">
      <div style="padding:0 0 10px; text-align:center">
        <img :src="qrcodeData.currentRowData.qrCodeUrl" alt="" />
      </div>
    </el-dialog>
    <!-- 弹窗相关 -end -->
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import { queryQrCodeListByCouponId, batchBoundCouponQrCode, batchUntieCouponQrCode } from '@/api/user.js'
export default {
  name: 'ShareList',
  components: {},
  props: {
    // 优惠券类型: platform-平台优惠券 salesperson-业务员优惠券
    couponType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      thirdPartyPermissions: false, // 是不是第三方权限组

      addressConfigure: {
        // 区域选择下拉配置
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },
      // 区域数据
      addressList: [],

      query: {
        // 查询参数
        couponCode: '',
        couponName: '',
        tcDate: '',
        couponStatus: '',
        couponUsePerson: '',
        useChannel: ''
      },
      dataSource: [{}], // 列表数据源
      tableLoading: false,
      pageParam: {
        // 分页参数
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      getRoute: '',

      // 二维码相关数据
      qrcodeData: {
        visible: false,
        loading: false,
        couponId: '', // 优惠券id
        couponCode: '', // 优惠券编号
        showConfirmBtn: false, // 是否展示确认/取消按钮
        previewQrcodeDialog: false, // 二维码预览弹窗
        currentRowData: '', // 当前点击的项
        isBoundAction: false, // 是不是绑定动作：false-解绑 true-绑定

        // 表格请求参数
        params: {
          pageNum: 1, // 分页参数
          pageSize: 10, // 分页参数
          couponId: '', // 优惠券id
          isBound: 1, // 是否绑定 1.已绑定 2.未绑定
          intendantCode: '', // 业务员编码
          intendantName: '', // 业务员姓名
          merchantName: '', // 商家名称
          qrCodeSerial: '', // 二维码编号
          areaId: '' // 区域id
        },
        tableHeader: [
          // 表头
          { label: '二维码编号', prop: 'qrCodeSerial', width: '200px' },
          { label: '业务员编号', prop: 'intendantCode', width: '200px' },
          { label: '业务员姓名', prop: 'intendantName', width: '160px' },
          { label: '业务员手机号', prop: 'intendantPhone', width: '160px' },
          { label: '所属大区', prop: 'regionName', width: '160px' },
          { label: '所属片区', prop: 'districtName', width: '160px' },
          { label: '所属办事处', prop: 'agencyName', width: '160px' },
          { label: '商家端编号', prop: 'merchantCode', width: '200px' },
          { label: '商家端名称', prop: 'merchantName', width: '160px' },
          { label: '操作', type: 'slot', slotName: 'operation', fixed: 'right', width: '160px' }
        ],
        list: [], // 返回的列表数据
        total: 0,

        selectData: [], // 当前选中的数据
        selectedQrCodeIdList: [], // 当前选中的二维码id数组
        detail: {} // 详情数据
      }
    }
  },
  created() {
    this.getRoute = this.$route.name
    this.queryListInfo()

    // 获取用户信息
    const userInfo = getSessionStorage('userInfo') || {}
    // 判断是不是第三方权限
    this.thirdPartyPermissions = userInfo && userInfo.rightsGroupNames.length == 1 && '第三方权限组'.includes(userInfo.rightsGroupNames)

    if (userInfo.managerType === 'all') {
      this.getAreaList()
    }
  },
  methods: {
    // 第三方权限限制
    handlerLimit() {
      /**
       * 对第三方管理权限组，二维码列表中需隐藏“所属区域”、“商家端名称”筛选条件，
       * 隐藏列表中“所属大区” “所属片区” “所属办事处”“商家端编号” “商家端名称”等字段；
       */
      const hideKeyName = ['regionName', 'districtName', 'agencyName', 'merchantCode', 'merchantName']
      hideKeyName.forEach(value1 => {
        this.qrcodeData.tableHeader.some((value2, index2) => {
          if (value1 === value2.prop) {
            value2.isHide = true
          }
        })
      })
    },
    // 创建方法处理枚举数组查找对应名称
    // 封装方法(枚举方法)
    findEnumName(enumArray, targetValue) {
      const enumFound = enumArray.find(item => item.value === targetValue)
      return enumFound ? enumFound.name : ''
    },

    // 获取列表数据
    queryListInfo() {
      // 共享列表，通过当前路径来筛选不同的数据 PlatformCoupon  SalesmanCoupon
      const { tcDate, couponUsePerson } = this.query
      const time = tcDate && tcDate.length > 0

      const params = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: {
          ...this.query,
          penetrateBeginTime: time ? tcDate[0] + ' 00:00:00' : '',
          penetrateEndTime: time ? tcDate[1] + ' 23:59:59' : '',
          orientedPopulation: couponUsePerson || '',
          couponType: this.getRoute === 'PlatformCoupon' ? 1 : 2
        }
      }

      this.tableLoading = true
      this.$http
        .requestPost({
          url: '/marketing/queryCouponList',
          loading: false,
          param: params
        })
        .then(res => {
          if (res.code === 200) {
            this.dataSource = res.data.list
            this.pageParam.total = res.data.total
            this.tableLoading = false
          }
        })
        .catch(err => {
          this.tableLoading = false
          console.log(err)
        })
    },

    // 新增优惠券
    addCoupon() {
      this.$router.push('/operation/marketingTool/addCoupon?route=' + this.getRoute)
    },
    // 编辑优惠券
    editCoupon(row) {
      this.$router.push({
        path: '/operation/marketingTool/addCoupon',
        query: {
          id: row.couponId,
          route: this.getRoute,
          detailFlag: row.couponStatus == '4' // '4'表示优惠券已结束，则跳转至编辑页不展示确定按钮
        }
      })
    },

    // 结束优惠券
    endCoupon(row) {
      const invalid = '优惠券还未生效，是否确认结束?'
      const Claiming = '优惠券正在领取中，未到结束时间，是否确认结束?'
      this.$confirm(row.couponStatus === '1' ? invalid : Claiming, '确认结束', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .requestPut({
              url: `/marketing/endCoupon/${row.couponId}`,
              loading: false
            })
            .then(res => {
              if (res.code === 200) {
                this.queryListInfo()
                this.$message({
                  type: 'success',
                  message: '已结束!'
                })
              }
            })
        })
        .catch(err => {})
    },
    // 删除优惠券
    delCoupon(id) {
      this.$confirm('是否确认删除?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .requestDel({
              url: '/marketing/removeCoupon/' + id,
              loading: false
            })
            .then(res => {
              if (res.code === 200) {
                this.queryListInfo()
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              }
            })
        })
        .catch(err => {})
    },

    // 分页事件
    handleSizeChange(size, type) {
      switch (type) {
        case 'couponTable': // 优惠券列表
          this.pageParam.pageNum = 1
          this.pageParam.pageSize = size
          this.queryListInfo()
          break

        case 'qrcodeTable': // 二维码列表
          this.qrcodeData.params.pageNum = 1
          this.qrcodeData.params.pageSize = size
          this.getQueryQrCodeListByCouponId()
          break

        default:
          break
      }
    },
    handleCurrentChange(page, type) {
      switch (type) {
        case 'couponTable': // 优惠券列表
          this.pageParam.pageNum = page
          this.queryListInfo()
          break

        case 'qrcodeTable': // 二维码列表
          this.qrcodeData.params.pageNum = page
          this.getQueryQrCodeListByCouponId()
          break

        default:
          break
      }
    },
    // 刷新列表
    updateList(type) {
      switch (type) {
        case 'qrcodeTable': // 二维码列表
          this.qrcodeData.params.pageNum = 1
          this.getQueryQrCodeListByCouponId()
          break

        default:
          break
      }
    },

    // 获取区域级联数据
    getAreaList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data
      })
    },

    // 打开二维码列表弹窗
    openQrcodeList(data) {
      if (this.thirdPartyPermissions) {
        this.handlerLimit()
      }

      this.qrcodeData.visible = true
      this.qrcodeData.couponId = data.couponId
      this.qrcodeData.couponCode = data.couponCode
      this.qrcodeData.params.couponId = data.couponId
      this.qrcodeData.params.isBound = 1 // 是否绑定 1.已绑定 2.未绑定
      // 获取二维码列表
      this.getQueryQrCodeListByCouponId()
    },
    // 关闭二维码列表弹窗
    closeQrcodeDialog() {
      // 重置数据
      this.qrcodeData = Object.assign({}, this.$options.data().qrcodeData)
      // 清空选中的数据
      this.$refs.qrcodeTable.clearSelection()
    },
    // 打开二维码预览弹窗
    openPreviewQrcode(row) {
      this.qrcodeData.currentRowData = row
      this.qrcodeData.previewQrcodeDialog = true
    },

    // 批量选中
    selectionChange(rows) {
      this.qrcodeData.selectData = rows
    },
    // 批量绑定二维码
    bindQrcode(actionType, data) {
      // actionType: 1-单个绑定 2-批量绑定
      this.isBoundAction = true // 是不是绑定动作：false-解绑 true-绑定

      if (actionType === 1) {
        // 单个绑定
      } else if (actionType === 2) {
        // 批量绑定
        if (this.qrcodeData.showConfirmBtn) return

        // 清空选中的数据
        this.$refs.qrcodeTable.clearSelection()
        this.qrcodeData.selectData = []

        this.qrcodeData.showConfirmBtn = true
        this.qrcodeData.params.isBound = 2 // 是否绑定 1.已绑定 2.未绑定

        // 获取未绑定的二维码列表
        this.getQueryQrCodeListByCouponId()
      }
    },
    // 批量/单个 解绑二维码
    unbindQrcode(actionType, data) {
      // actionType: 1-单个解绑 2-批量解绑
      this.isBoundAction = false // 是不是绑定动作：false-解绑 true-绑定

      if (actionType === 1) {
        // 单个解绑
        // 清空选中的数据
        this.$refs.qrcodeTable.clearSelection()

        this.selectedQrCodeIdList = [data.couponQrCodeId]
        this.confirmOperateQrcode(1, 'singleUnbind')
      } else if (actionType === 2) {
        // 批量解绑
        if (this.qrcodeData.showConfirmBtn) return

        if (!this.qrcodeData.selectData.length) {
          return this.$message.warning('请至少选择一个二维码')
        }
        // 获取当前选中的二维码id
        this.selectedQrCodeIdList = []
        this.qrcodeData.selectData.forEach(value => {
          this.selectedQrCodeIdList.push(value.couponQrCodeId)
        })

        this.qrcodeData.showConfirmBtn = true
        // this.qrcodeData.params.isBound = 1 //是否绑定 1.已绑定 2.未绑定
        // // 获取已绑定的二维码列表
        // this.getQueryQrCodeListByCouponId()
      }
    },

    // 确定绑定/解绑二维码
    async confirmOperateQrcode(btnType, actionType) {
      // btnType:按钮操作类型 0-取消 1-确定
      let updateFlag = true // 是否更新列表标识

      if (btnType) {
        if (this.isBoundAction) {
          // 绑定二维码操作前验证数据
          if (!this.qrcodeData.selectData.length) {
            return this.$message.warning('请至少选择一个二维码')
          }
          // 获取当前选中的二维码id
          this.selectedQrCodeIdList = []
          this.qrcodeData.selectData.forEach(value => {
            this.selectedQrCodeIdList.push(value.qrCodeId)
          })
        }

        const warningText = this.isBoundAction
          ? '绑定后用户扫描二维码可获取到达透出时间的优惠券，是否确认绑定？'
          : '解绑后，用户扫描二维码将无法获取优惠券，是否解绑？'
        await this.$confirm(warningText, `确认${this.isBoundAction ? '绑定' : '解绑'}`, {
          cancelButtonText: '取消',
          confirmButtonText: '确定',
          type: 'warning'
        })
          .then(async res => {
            if (this.isBoundAction) {
              // 绑定
              await this.getBatchBoundCouponQrCode()
            } else {
              // 解绑
              await this.getBatchUntieCouponQrCode()
            }
          })
          .catch(err => {
            // 解绑 取消操作不需要更新列表
            if (actionType === 'singleUnbind' || !this.isBoundAction) {
              updateFlag = false
            }
          })
      } else {}

      if (!updateFlag) return
      // 清空选中的数据
      this.$refs.qrcodeTable.clearSelection()
      this.qrcodeData.showConfirmBtn = false
      this.qrcodeData.params.pageNum = 1
      this.qrcodeData.params.isBound = 1 // 是否绑定 1.已绑定 2.未绑定
      // 获取二维码列表
      this.getQueryQrCodeListByCouponId()
    },

    // 获取业务员二维码列表
    async getQueryQrCodeListByCouponId() {
      this.qrcodeData.loading = true
      this.qrcodeData.list = []
      const params = { ...this.qrcodeData.params }
      const res = await queryQrCodeListByCouponId(params)
      if (res.code === 200) {
        this.qrcodeData.list = res.data.list
        this.qrcodeData.total = res.data.total
      }
      this.qrcodeData.loading = false
    },

    // 绑定优惠券与二维码
    async getBatchBoundCouponQrCode() {
      const params = {
        couponId: this.qrcodeData.couponId, // 二维码id
        qrCodeIds: this.selectedQrCodeIdList // 需要绑定的二维码id数组
      }
      const res = await batchBoundCouponQrCode(params)
      if (res.code === 200) {
        this.$message.success('绑定成功')
      } else {
        console.log('绑定失败==>>', res)
      }
    },

    // 解绑优惠券与二维码
    async getBatchUntieCouponQrCode() {
      const params = this.selectedQrCodeIdList

      const res = await batchUntieCouponQrCode(params)
      if (res.code === 200) {
        this.$message.success('解绑成功')
      } else {
        console.log('解绑失败==>>', res)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.added {
  margin: 20px 0 0 20px;
}
.qrcodeTable_box {
  padding: 0 20px 10px;
  .table_list {
    width: 100%;
  }
}
</style>
