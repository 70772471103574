var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "steps-box bg_white" }, [
        _c("h4", [
          _vm._v(
            "申购状态：" +
              _vm._s(
                _vm.$api.getValue(
                  _vm.$api.purchaseRequisitionStatus,
                  _vm.pageData.purchaseStatus,
                  "name"
                )
              )
          ),
        ]),
        _vm._v(" "),
        _vm.pageData.purchaseStatus == "unaudited"
          ? _c("h4", { staticStyle: { "margin-top": "24px" } }, [
              _vm._v("驳回原因：" + _vm._s(_vm.pageData.rejectReason || "无")),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_3 bg_white" }, [
        _c("h4", [_vm._v("申购商品")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.pageData.items, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "序号", prop: "date", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.$index + 1) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品信息",
                    "min-width": "220",
                    prop: "available",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "flex-box" }, [
                            scope.row.productImg
                              ? _c("img", {
                                  staticClass: "img-box",
                                  attrs: { src: scope.row.productImg, alt: "" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "hospital-name" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "txt_box",
                                  class: {
                                    "flex-box": !scope.row.productImg,
                                    "flex-center": !scope.row.productImg,
                                  },
                                },
                                [
                                  scope.row.isGifts
                                    ? _c("img", {
                                        staticClass: "icon_box",
                                        class: {
                                          no_img: !scope.row.productImg,
                                        },
                                        attrs: {
                                          src: require("@/assets/image/gift_icon.png"),
                                          alt: "",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(scope.row.productName) +
                                      "\n                "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "批次号",
                    "min-width": "100",
                    prop: "batchNo",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.batchNo) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "发货数量",
                    "min-width": "100",
                    prop: "productNum",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.productNum) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "有效期", "min-width": "150" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.produceTime) +
                              " " +
                              _vm._s("~") +
                              " " +
                              _vm._s(scope.row.expireTime) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "单价",
                    "min-width": "100",
                    prop: "itemPrice",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s("￥" + scope.row.itemPrice) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "数量", "min-width": "100", prop: "itemNum" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "小计", "min-width": "100", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                "￥" +
                                  (
                                    scope.row.itemPrice * scope.row.itemNum
                                  ).toFixed(2) || "0"
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "show-money" }, [
          _c("ul", [
            _c("li", [
              _vm._v("\n          商品金额：\n          "),
              _c("span", { staticClass: "block_span" }, [
                _vm._v("￥ " + _vm._s(_vm.goodsMoney.toFixed(2) || "0.00")),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_4 bg_white" }, [
        _c("h4", [_vm._v("商家/医院信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家名称：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.merchantName) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家编号：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.merchantCode) + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_5 row_4 bg_white" }, [
        _c("h4", [_vm._v("申购单信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("申购单编号：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.orderCode) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("申购时间：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.purchaseTime) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("收货人：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.receiptName) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("收货人手机号：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.receiptPhoneNumber) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("收货地址：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.address) + "\n    "),
        ]),
        _vm._v(" "),
        _vm.pageData.purchaseStatus !== "auditing"
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("审核时间：")]),
              _vm._v("\n      " + _vm._s(_vm.pageData.auditTime) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.pageData.purchaseStatus !== "auditing"
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("审核人：")]),
              _vm._v("\n      " + _vm._s(_vm.pageData.auditByName) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.pageData.purchaseStatus == "unaudited"
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [
                _vm._v("审核不通过原因："),
              ]),
              _vm._v("\n      " + _vm._s(_vm.pageData.rejectReason) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("备注：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.remark || "无") + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _vm.showBtn(_vm.pageData.purchaseStatus)
        ? _c("div", { staticClass: "row_6 row_4 bg_white" }, [
            _c("h4", [_vm._v("物流信息")]),
            _vm._v(" "),
            _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("物流公司：")]),
              _vm._v(
                "\n      " + _vm._s(_vm.pageData.logisticsCompany) + "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("物流单号：")]),
              _vm._v(
                "\n      " + _vm._s(_vm.pageData.logisticsCode) + "\n    "
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "color_light-blue" }, [
              _c(
                "span",
                {
                  staticClass: "title",
                  on: {
                    click: function ($event) {
                      return _vm.handleLogistics(_vm.pageData)
                    },
                  },
                },
                [_vm._v("查看物流")]
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.pageData.purchaseStatus === "auditing"
        ? _c("div", { staticClass: "row_7 row_4 bg_white" }, [
            _c(
              "div",
              { staticClass: "flex-box" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.pass } },
                  [_vm._v("通过")]
                ),
                _vm._v(" "),
                _c("el-button", { on: { click: _vm.reject } }, [
                  _vm._v("驳回"),
                ]),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.close,
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible_1,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.auditState === 1,
                          expression: "auditState === 1",
                        },
                      ],
                      attrs: {
                        prop: _vm.auditState === 1 ? "logisticsCompany" : "",
                        label: "物流公司",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-inherit",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.submitForm.logisticsCompany,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "logisticsCompany", $$v)
                            },
                            expression: "submitForm.logisticsCompany",
                          },
                        },
                        _vm._l(_vm.logisticsCompanyList, function (item) {
                          return _c("el-option", {
                            key: item.companyId,
                            attrs: {
                              label: item.companyName,
                              value: item.companyId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.auditState === 1,
                          expression: "auditState === 1",
                        },
                      ],
                      attrs: {
                        prop: _vm.auditState === 1 ? "logisticsCode" : "",
                        label: "物流单号",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", placeholder: "物流单号" },
                        model: {
                          value: _vm.submitForm.logisticsCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "logisticsCode", $$v)
                          },
                          expression: "submitForm.logisticsCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.auditState === 2,
                          expression: "auditState === 2",
                        },
                      ],
                      attrs: {
                        prop: _vm.auditState === 2 ? "rejectReason" : "",
                        label: "驳回原因",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          rows: "6",
                          maxlength: "150",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.submitForm.rejectReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "rejectReason", $$v)
                          },
                          expression: "submitForm.rejectReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("d-dialog", {
        ref: "logisticsDialog",
        attrs: {
          "logistics-list": _vm.logisticsList,
          "data-obj": _vm.dataObj,
          "route-info": _vm.routeInfo,
        },
        on: { handleClose: _vm.handleClose },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }