<template>
  <!-- 待付款订单 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="订单编号">
          <el-input v-model="inquire.orderCode" placeholder="订单编号" @keyup.enter.native="searchList"></el-input>
        </el-form-item>

        <el-form-item label="下单时间">
          <el-date-picker
            v-model="datetimerange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            end-placeholder="结束日期"
            start-placeholder="开始日期"
            type="daterange"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="">
          <div class="flex-box">
            <el-cascader v-model="categoryId" :props="props" :options="productTypeList" @change="categoryChange" clearable></el-cascader>
            <el-select v-model="inquire.productIds" multiple v-show="productList.length > 0" placeholder="请选择产品" class="width-260">
              <el-option v-for="item in productList" :key="item.productId" :label="item.productName" :value="item.productId" />
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="优惠券ID">
          <el-input v-model.trim="inquire.couponCode" placeholder="优惠券ID" @keyup.enter.native="searchList" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="generalExport">导出</el-button>
      <el-button size="small" type="primary" @click="batchExport">按批次导出</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            ref="tables"
            v-loading="loading"
            :data="tableData"
            :expand-row-keys="expands"
            border
            height="400px"
            row-key="orderId"
            style="width: 100%"
            @expand-change="expandChange"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table :data="scope.row.items" border style="width: 100%">
                  <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex-box">
                        <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                        <div class="hospital-name">
                          <div class="txt_box" :class="{ 'flex-box': !scope.row.productImg, 'flex-center': !scope.row.productImg }">
                            <img
                              v-if="scope.row.isGifts"
                              src="@/assets/image/gift_icon.png"
                              alt=""
                              class="icon_box"
                              :class="{ no_img: !scope.row.productImg }"
                            />
                            {{ scope.row.productName }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="产品编号" min-width="200" prop="productCode" show-overflow-tooltip></el-table-column>
                  <el-table-column label="产品规格" min-width="120" prop="productNorms">
                    <template slot-scope="scope">
                      {{ scope.row.productNorms || '/' }}
                    </template>
                  </el-table-column>
                  <el-table-column label="单价" min-width="120" prop="itemPrice">
                    <template slot-scope="scope">
                      {{ '￥' + scope.row.itemPrice }}
                    </template>
                  </el-table-column>
                  <el-table-column label="数量" min-width="120" prop="itemNum"></el-table-column>
                  <el-table-column label="购买总价" min-width="120" prop="">
                    <template slot-scope="scope">
                      {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>

            <el-table-column label="订单编号" min-width="200" prop="orderCode">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleParticulars(scope.row)">{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column :label="tableTileS" min-width="160" prop="" show-overflow-tooltip>
              <template slot-scope="scope">
                {{
                  scope.row.orderDelivery === 'self'
                    ? ((self = false), scope.row.memberNickName)
                    : ((self = true), scope.row.memberNickName + '/' + scope.row.recipient)
                }}
              </template>
            </el-table-column>
            <el-table-column label="总金额" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + scope.row.orderTotalPrice.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="运费" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + scope.row.shippingFee.toFixed(2) }}
              </template>
            </el-table-column>

            <el-table-column label="下单时间" min-width="160" prop="orderTime"></el-table-column>
            <el-table-column v-if="orderStatus == 'pending_ship'" label="付款时间" min-width="160" prop="paymentTime"></el-table-column>
            <el-table-column v-else-if="orderStatus == 'pending_receipt'" label="发货时间" min-width="160" prop="shipTime"></el-table-column>
            <el-table-column v-else-if="orderStatus == 'pending_evaluate'" label="确认收货时间" min-width="160" prop="receiptTime"></el-table-column>

            <el-table-column label="订单状态" min-width="140" prop="orderStatus">
              <template slot-scope="scope">
                <span>{{ $api.getValue($api.orderState, scope.row.orderStatus, 'name') }}</span>
                <span
                  v-if="
                    scope.row.deliveryMethod == 'hospital' &&
                      scope.row.orderStatus == 'pending_receipt' &&
                      scope.row.returnStatus &&
                      scope.row.returnStatus.length > 0
                  "
                >
                  （审核中）
                </span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="所属专员" min-width="120" prop="attacheName"></el-table-column> -->
            <!-- <el-table-column label="收货地址" min-width="200" prop="recipientAddressDetail"></el-table-column> -->
            <el-table-column fixed="right" label="操作" min-width="160" prop="">
              <template slot-scope="scope">
                <!-- <span v-show="scope.row.orderStatus ==='pending_payment'" class="operation-btn" @click="handleEdit(scope.row)">修改价格</span> -->
                <!-- <span v-show="scope.row.orderStatus ==='pending_ship' && deliveryMethod == 'national'" class="operation-btn" @click="handleShipments(scope.row)">发货</span>
                                <span v-show="scope.row.orderStatus ==='pending_ship' && deliveryMethod == 'national'" class="operation-btn_partition"> / </span> -->
                <span class="operation-btn" @click="handleParticulars(scope.row)">订单详情</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-size="pageParam.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog :visible.sync="dialogVisible_1" title="修改价格" top="2vh" width="70%">
      <div class="Popout_content">
        <div class="table">
          <el-table :data="tableData" border height="400px" style="width: 100%">
            <el-table-column label="序号" prop="date" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column label="产品信息" min-width="120" prop="orderTotalPrice">
              <template></template>
            </el-table-column>
            <el-table-column label="单价" min-width="100" prop="orderTotalPrice"></el-table-column>
            <el-table-column label="数量" min-width="100" prop="orderTotalPrice"></el-table-column>
            <el-table-column label="小计" min-width="100" prop="orderTotalPrice"></el-table-column>
            <el-table-column label="加价/减价" min-width="120" prop="orderTotalPrice">
              <template slot-scope="scope">
                <div class="flex-box flex-center">
                  ￥
                  <el-input v-model="scope.row.orderTotalPrice" placeholder=""></el-input>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="实收金额" min-width="100" prop="orderTotalPrice"></el-table-column>
          </el-table>
        </div>
        <div class="show-money">
          <ul>
            <li>
              商品金额：
              <span class="block_span">￥200.00</span>
            </li>
            <li>
              运费：
              <span class="block_span">+￥20.00</span>
            </li>
            <li>
              加价/减价：
              <span class="block_span color_red">-￥20.00</span>
            </li>
            <li class="line-box"></li>
            <li class="bold">
              实付总额：
              <span class="block_span color_red">￥180.00</span>
            </li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_1 = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible_2" title="发货" width="400px">
      <div class="Popout_content">
        <el-form ref="submitForm_2" :model="submitForm_2" :rules="submitRules_2" label-width="80px" size="medium">
          <el-form-item label="订单编号">
            <el-input v-model="submitForm_2.orderCode" disabled placeholder="订单编号"></el-input>
          </el-form-item>

          <el-form-item label="物流公司" prop="logisticsCompany">
            <el-select v-model="submitForm_2.logisticsCompany" class="form-select" placeholder="请选择">
              <el-option v-for="item in logisticsCompanyList" :key="item.companyId" :label="item.companyName" :value="item.companyId"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="物流单号" prop="logisticsCode">
            <el-input v-model="submitForm_2.logisticsCode" maxlength="30" placeholder="物流单号"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmSendOn">确 定</el-button>
      </span>
    </el-dialog>

    <d-export
      :inquire="inquire"
      :showDialog="showExportDialog"
      :medicalType="inquire.deliveryMethod"
      :title="typeTitle"
      :isNewTemplate="isNewTemplate"
      :close.sync="showExportDialog"
    />
  </div>
</template>

<script>
import dExport from '@/components/order/d-export'
import dayjs from 'dayjs'
import { getLastThreeMonthDate } from '@/utils/latelyTime'

export default {
  components: {
    dExport,
  },
  props: {
    orderStatus: {
      type: String,
      default: 'pending_payment',
    },
    deliveryMethod: {
      type: String,
      default: 'hospital',
    },
    thirtyDaysAgo: {
      //只有售后订单列表才需要该时间
      type: String,
      default: '',
    },
    datePickerLimit: {
      //开启时间选择器的默认时间
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 产品分类
      productTypeList: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        // checkStrictly: true,
        emitPath: true,
      },
      productList: [],
      categoryId: '',

      // medicine：医药
      // lease：租赁
      inquire: {
        orderClassify: 'medicine',
        isReturnOrder: false,
        orderStatus: '',
        orderTimeBegin: '',
        orderTimeEnd: '',
        deliveryMethod: '',
        orderCode: '',
        productIds: [],
        categoryId: '',
        couponCode: '',
        thirtyDaysAgo: this.thirtyDaysAgo ? new Date(this.thirtyDaysAgo) : '',
      },
      datetimerange: [],

      // 分页
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [],
      loading: false,
      expands: [], // 当前展示行

      editRow: {},

      // 修改价格弹窗1
      dialogVisible_1: false,

      // 发货
      dialogVisible_2: false,
      submitForm_2: {
        //
        orderCode: '',
        logisticsCompany: '',
        logisticsCode: '',
      },
      submitRules_2: {
        logisticsCompany: [{ required: true, message: '请选择物流公司', trigger: 'change' }],
        logisticsCode: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
          { pattern: /^[0-9a-zA-Z]+$/, message: '请输入字母和数字', trigger: 'blur' },
        ],
      },
      logisticsCompanyList: [], // 物流公司列表

      self: true,

      // ========= 导出弹窗 =========
      showExportDialog: false,
      typeTitle: '订单导出',
      isNewTemplate: false,
    }
  },
  computed: {
    tableTileS() {
      return this.self ? '买家/收货人' : '买家'
    },
  },
  created() {
    if (this.datePickerLimit) {
      this.datetimerange = getLastThreeMonthDate()
    }

    if (sessionStorage.getItem('cacheParamC')) {
      this.inquire = JSON.parse(sessionStorage.getItem('cacheParamC'))
      this.datetimerange[0] = this.inquire.orderTimeBegin || ''
      this.datetimerange[1] = this.inquire.orderTimeEnd || ''
    }
    if (sessionStorage.getItem('m_order')) {
      this.inquire.orderCode = JSON.parse(sessionStorage.getItem('m_order'))
    }
    this.inquire.orderStatus = this.orderStatus
    this.inquire.deliveryMethod = this.deliveryMethod

    this.getTableData()
    this.getProductType()
    if (this.orderStatus === 'pending_ship') this.getLogisticsCompanyList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.getTableData()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getTableData()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableData()
    },
    // table 手风琴
    expandChange(news, old) {
      if (old.length === 0) {
        return (this.expands = [])
      }
      this.expands = [news.orderId]
    },
    // 获取物流公司列表
    getLogisticsCompanyList() {
      let param = {
        pageNum: 1,
        pageSize: 10000,
      }
      this.$http.requestPost({ url: '/system/logistics/company/page', param, loading: false }).then(res => {
        this.logisticsCompanyList = res.data.list
      })
    },
    // 获取产品类型
    getProductType() {
      this.$http.requestGet({ url: '/product/category', loading: false }).then(res => {
        this.productTypeList = res.data
      })
    },
    // 产品分类改变
    categoryChange(e) {
      this.productList = []
      this.inquire.productIds = []
      if (e.length > 1) {
        this.getProductList(e[1])
        this.inquire.categoryId = ''
      } else {
        this.inquire.categoryId = e[0]
      }
    },
    // 根据分类id获取产品列表
    getProductList(id) {
      this.$http.requestGet({ url: '/customer/order/category/' + id, loading: false }).then(res => {
        this.productList = res.data
      })
    },

    // 导出
    fnExportOrderList() {
      if (!this.datetimerange) this.datetimerange = []
      this.inquire.orderTimeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : ''
      this.inquire.orderTimeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : ''
      this.inquire.categoryId = this.categoryId
      this.showExportDialog = true
    },

    generalExport() {
      this.typeTitle = '订单导出'
      this.isNewTemplate = false
      this.fnExportOrderList()
    },

    batchExport() {
      this.typeTitle = '订单按批次导出'
      this.isNewTemplate = true
      this.fnExportOrderList()
    },

    getToPage() {
      this.inquire.orderTimeBegin = ''
      this.inquire.orderTimeEnd = ''
      this.inquire.productIds = []
      let data = {
        pageNum: 1,
        pageSize: 10,
        param: JSON.parse(JSON.stringify(this.inquire)),
      }
      delete data.param.categoryId
      delete data.param.orderStatus
      this.$http
        .requestPost({
          url: '/customer/order/page',
          param: data,
          loading: false,
        })
        .then(res => {
          if (res.code == 200) {
            // 医品-医药配送
            if (res.data.list.length > 0 && res.data.list[0].orderClassify == 'medicine' && res.data.list[0].deliveryMethod == 'hospital') {
              if (res.data.list[0].orderStatus == 'pending_stock') {
                this.$confirm('是否确定跳转到医品待发货订单页面?', '订单跳转确认', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  this.$router.push({ path: '/order/medical/hospitalDelivery/waitForSending' })
                  sessionStorage.setItem('m_order', JSON.stringify(this.inquire.orderCode))
                })
              } else if (res.data.list[0].orderStatus == 'pending_receipt') {
                this.$confirm('是否确定跳转到医品待收货订单页面?', '订单跳转确认', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  this.$router.push({ path: '/order/medical/hospitalDelivery/waitForReceiving' })
                  sessionStorage.setItem('m_order', JSON.stringify(this.inquire.orderCode))
                })
              } else if (res.data.list[0].orderStatus == 'completed') {
                this.$confirm('是否确定跳转到医品已完成订单页面?', '订单跳转确认', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  this.$router.push({ path: '/order/medical/hospitalDelivery/completed' })
                  sessionStorage.setItem('m_order', JSON.stringify(this.inquire.orderCode))
                })
              } else if (res.data.list[0].orderStatus == 'cancel') {
                this.$confirm('是否确定跳转到医品已取消订单页面?', '订单跳转确认', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  this.$router.push({ path: '/order/medical/hospitalDelivery/cancel' })
                  sessionStorage.setItem('m_order', JSON.stringify(this.inquire.orderCode))
                })
              }
            } else if (res.data.list.length > 0 && res.data.list[0].orderClassify == 'medicine' && res.data.list[0].deliveryMethod == 'national') {
              if (res.data.list[0].orderStatus == 'pending_stock') {
                this.$confirm('是否确定跳转到全国待发货订单页面?', '订单跳转确认', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  this.$router.push({ path: '/order/medical/nationwideDelivery/waitForSending' })
                  sessionStorage.setItem('m_order', JSON.stringify(this.inquire.orderCode))
                })
              } else if (res.data.list[0].orderStatus == 'pending_receipt') {
                this.$confirm('是否确定跳转到全国待收货订单页面?', '订单跳转确认', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  this.$router.push({ path: '/order/medical/nationwideDelivery/waitForReceiving' })
                  sessionStorage.setItem('m_order', JSON.stringify(this.inquire.orderCode))
                })
              } else if (res.data.list[0].orderStatus == 'completed') {
                this.$confirm('是否确定跳转到全国已完成订单页面?', '订单跳转确认', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  this.$router.push({ path: '/order/medical/nationwideDelivery/completed' })
                  sessionStorage.setItem('m_order', JSON.stringify(this.inquire.orderCode))
                })
              } else if (res.data.list[0].orderStatus == 'cancel') {
                this.$confirm('是否确定跳转到全国已取消订单页面?', '订单跳转确认', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  this.$router.push({ path: '/order/medical/nationwideDelivery/cancel' })
                  sessionStorage.setItem('m_order', JSON.stringify(this.inquire.orderCode))
                })
              }
            } else {
              this.$alert('请检查输入订单号是否正确或业务类型是否匹配', '无订单提示', {
                confirmButtonText: '关闭',
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 获取列表数据
    getTableData() {
      if (sessionStorage.getItem('m_order')) {
        sessionStorage.removeItem('m_order')
      }
      if (!this.datetimerange) this.datetimerange = []
      this.inquire.orderTimeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : ''
      this.inquire.orderTimeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : ''
      this.orderStatus = this.inquire.orderStatus

      //处理售后订单列表参数
      // let dataObj = {
      //     ...this.inquire,
      //     ...(this.thirtyDaysAgo && {thirtyDaysAgo: new Date(this.thirtyDaysAgo)})
      // }
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire)),
      }
      delete data.param.categoryId

      this.loading = true
      this.$http
        .requestPost({
          url: '/customer/order/page',
          param: data,
          loading: false,
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
          // 4.8.3需求添加订单跳转弹框
          if (
            this.tableData.length === 0 &&
            this.inquire.orderCode.length > 0 &&
            this.inquire.orderTimeBegin.length === 0 &&
            this.inquire.orderTimeEnd.length === 0 &&
            this.inquire.productIds.length === 0
          ) {
            this.getToPage()
          }
          sessionStorage.removeItem('cacheParamC')
        })
        .catch(err => {
          this.loading = false
          sessionStorage.removeItem('cacheParamC')
          console.log(err)
        })
    },

    // 修改价格
    handleEdit(row) {
      this.dialogVisible_1 = true
    },

    // 发货
    handleShipments(row) {
      this.editRow = row
      if (row.deliveryMethod === 'national') {
        this.submitForm_2 = {
          orderCode: row.orderCode,
          logisticsCompany: '',
          logisticsCode: '',
        }
        this.dialogVisible_2 = true
      } else {
        this.$confirm('确定已发货?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
          .then(() => {
            let param = {
              orderId: row.orderId,
            }
            this.sendOut(param)
          })
          .catch(() => {})
      }
    },
    // 确认发货
    confirmSendOn() {
      this.$refs.submitForm_2.validate(valid => {
        if (valid) {
          let param = {
            orderId: this.editRow.orderId,
            logisticsCompany: this.submitForm_2.logisticsCompany,
            logisticsCode: this.submitForm_2.logisticsCode,
          }
          this.sendOut(param)
        } else {
          return false
        }
      })
    },

    // 发货
    sendOut(param) {
      this.$http.requestPut({ url: '/customer/order/medicine/ship', param }).then(res => {
        console.log(res)
        if (res.code == 200 && res.data.errorCode == '-1') {
          this.$message.error(res.data.description)
          this.dialogVisible_2 && (this.dialogVisible_2 = false)
          return
        }
        this.$message.success('操作成功！')
        this.getTableData()
        this.dialogVisible_2 && (this.dialogVisible_2 = false)
      })
    },

    // 订单详情
    handleParticulars(row) {
      sessionStorage.setItem('cacheParamC', JSON.stringify(this.inquire))
      let type = this.$api.orderState.findIndex(item => item.value === row.orderStatus) + 1
      type = type >= 4 ? --type : type
      this.$router.push({
        path: '/order/medical/hospitalDelivery/particulars',
        query: {
          type: type,
          id: row.orderId,
          name: row.memberNickName,
          isAfterSale: this.thirtyDaysAgo ? true : false, //售后订单列表标识
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
  width: 375px;
}

.el-head-search-row {
  .flex-box {
    width: 326px;
  }
}

.show-money {
  padding: 10px 0;

  ul {
    li {
      display: flex;
      justify-content: flex-end;
      line-height: 30px;
      font-size: 16px;
    }

    .line-box {
      margin-left: 50%;
      border-bottom: 1px solid #ccc;
    }

    .bold {
      font-weight: bolder;
    }
  }
}

.block_span {
  display: inline-block;
  width: 108px;
  text-align: right;
}

.img-box {
  width: 58px;
  height: 58px;
}

.icon_box {
  width: 21px;
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  &.no_img {
    margin-right: 3px;
    position: static;
  }
}

.txt_box {
  position: relative;
  line-height: 1;
}
</style>
