<template>
  <div class="main-box">
    <div class="head-search-row">
      <!-- 营养师问卷列表 -->
      <el-form
        :model="queryParams"
        :inline="true"
        class="demo-form-inline"
         size="medium"
      >
        <el-form-item label="创建时间">
          <div class="flex-box">
            <el-date-picker
              v-model="time"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </div>
        </el-form-item>
        <el-form-item label="问卷名称">
          <el-input
            v-model="queryParams.title"
            placeholder="问卷名称"
            clearable
            style="width: 220px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="模板编号">
          <el-input
            v-model="queryParams.questionnaireCode"
            placeholder="模板编号"
            clearable
            style="width: 220px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary"  @click="handleQuery"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="primary"  @click="showDialog = true"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            :data="tableData"
            v-loading="loading"
            border
            height="400px"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              width="60"
              align="center"
              type="index"
            />
            <el-table-column
              label="问卷编号"
              align="center"
              prop="questionnaireId"
            />
            <el-table-column
              label="问卷模板名称"
              align="center"
              prop="title"
            />
            <el-table-column
              label="问卷模板编号"
              align="center"
              prop="questionnaireCode"
            />
            <el-table-column
              label="成员名称"
              align="center"
              prop="memberName"
            />
            <el-table-column
              label="成员编号"
              align="center"
              prop="memberCode"
            />
            <el-table-column
              label="填写时间"
              align="center"
              prop="questionnaireFillInTime"
            />
            <el-table-column fixed="right" label="操作"  align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="goDetail(scope.row)">查看详情</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :tableHeader="tableHeader"
            :current-page="queryParams.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="queryParams.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog
        title="营养档案导出"
        destroy-on-close
        :visible.sync="showDialog"
        width="50%"
        append-to-body
        @open="openEvent"
        @close="closeEvent"
        class="export-table"
    >
      <el-form label-width="100px" :inline="true">
        <el-form-item label="模板名称" style="width: 550px">
          <el-select
              v-model="formData.questionnaireId"
              placeholder="请选择"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="模板编号" style="width: 550px">
          <el-input
              v-model="formData.questionnaireCode"
              placeholder="模板编号"
              class="input"
          />
        </el-form-item>
        <br />
        <el-form-item label="创建时间">
          <el-date-picker
              class="picker"
              v-model="formData.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button type="primary" @click="exportTable"> 导出 </el-button>
        <el-button @click="showDialog = false"> 关闭 </el-button>
      </div>
    </el-dialog>

    <!-- 问卷详情弹窗 -->
    <el-dialog
        :title="currentTitle"
        :visible.sync="showProDialog"
        width="70%"
        top="5vh" append-to-body
        class="productList"
    >
      <div class="content">
        <el-table ref="proTable" :data="questionnaireData" height="350" border style="margin-top: 10px"
                  :header-cell-style="{background:'#409eff',color:'#ffffff'}">
          <el-table-column type="index" label="序号" align="center" width="55"/>
          <el-table-column label="问题" align="center" prop="content"/>
          <el-table-column label="内容" align="center" prop="answer"/>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button @click="showProDialog = false">取 消</el-button>
         </span>
    </el-dialog>
  </div>
</template>

<script>
import {exportMemberQuestionnaire} from "@/api/consult";
import dayjs from "dayjs";

export default {
  data() {
    return {
      time: [],
      // 问卷编号不知道
      tableHeader: [
        {
          label: "问卷编号",
          prop: "dietitianCode",
          width: "100",
        },
        {
          label: "问卷模板名称",
          prop: "title",
        },
        {
          label: "问卷模板编号",
          prop: "questionnaireCode",
        },
        {
          label: "成员名称",
          prop: "memberName",
        },
        {
          label: "成员编号",
          prop: "memberCode",
        },
        {
          label: "填写时间",
          prop: "questionnaireFillInTime",
        },
        {
          label: "操作",
          type: "slot",
          slotName: "operation",
        },
      ],
      queryParams: {
        pageNum: 1, 
        pageSize: 10, 
        title: "", 
        questionnaireCode: "", 
        memberName: "", 
        type: 1, 
        createBeginTime: "", 
        createEndTime: "", 
        fillOutBeginTime: "", 
        fillOutEndTime: "" 
      },
      loading: false,
      tableData: [], //营养师问卷列表数据
      showProDialog:false,//查看详情弹框
      currentTitle:'',
      questionnaireData:[],//查看详情列表数据
      total: 0,
      // 弹窗表格
      showDialog:false,
      formData: {
        questionnaireCode: "",
        userQuestionnaireId: "",
        time: [],
      },
      options: [],
      type: 1, //问卷类型：1.问卷，2.营养师app端自创问卷,3.营养档案 4.营养记录
    };
  },
  created() {
    this.dietitianRecordList();
  },
  methods: {
    // 营养师问卷列表
    dietitianRecordList() {
      this.$http
        .requestPost({
          url: "/consult/queryUserQuestionnaireList",
          param: this.queryParams,
          loading: false,
        })
        .then((res) => {
          console.log(res, "营养师列表");
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.options = [];
          // 导出的选择项
          this.tableData.filter((item, index, self) => {
            if (self.findIndex(t => t.questionnaireId === item.questionnaireId) === index) {
              // 导出的选择项
              this.options.push({
                value: item.questionnaireId,
                label: item.title,
              });
            }
          });
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 查看详情
    goDetail(row){
      this.$router.push({
        name: "userQuestionnaireDetails",
        params: {
          row,
        },
      });
    },
    // 营养师问卷列表查询
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.dietitianRecordList();
    },
    // 营养师问卷列表页码改变
    handleSizeChange(size) {
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = size;
      this.dietitianRecordList();
    },
    handleCurrentChange(page) {
      this.queryParams.pageNum = page;
      this.dietitianRecordList();
    },
    // 弹窗关闭
    closeEvent() {
      // 数据重置
      this.formData = {
        questionnaireCode: "",
        questionnaireId: "",
        time: [],
      };
    },
    openEvent(){

    },
    // 导出表格
    async exportTable() {
      console.log(this.formData);

      let param = {
        questionnaireId: this.formData.questionnaireId,
        questionnaireCode: this.formData.questionnaireCode,
        createBeginTime: this.formData.time.length
            ? dayjs(this.formData.time[0]).format("YYYY-MM-DD HH:mm:ss")
            : null,
        createEndTime: this.formData.time.length
            ? dayjs(this.formData.time[1]).format("YYYY-MM-DD HH:mm:ss")
            : null,
        type: this.type,
      };
      exportMemberQuestionnaire(
          param,
          'post',
          `questionnaire_${this.type}_${new Date().getTime()}.xlsx`)
          .then((res) => {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.showDialog = false;
          }).catch((err) => {
        console.error('导出报错', err)
      })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
