var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "broken-line" }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "txt_box" }, [
        _c("div", { staticClass: "tip" }, [
          _c("img", {
            attrs: { src: require("../../assets/image/wenhao.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", { ref: "poup", staticClass: "poup" }, [
            _c("div", { staticClass: "txt" }, [
              _vm._v(
                "\n            分析所选时间段内的每日销售情况\n          "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("@/assets/image/top.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.title))]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { ref: "broken", attrs: { id: "broken-line" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }