<template>
  <!-- 医品-在售产品列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="产品名称">
          <div class="flex-box">
            <el-input v-model="inquire.productName" placeholder="输入产品名称" @keyup.enter.native="searchList" />
          </div>
        </el-form-item>

        <el-form-item label="产品类别">
          <el-cascader v-model="inquire.productCategoryId" :props="props" :options="productTypeList" />
        </el-form-item>

        <el-form-item label="价格">
          <div class="flex-box">
            <el-input v-model="inquire.productPriceMin" placeholder="0" class="width-110" />
            <span class="span-link"> - </span>
            <el-input v-model="inquire.productPriceMax" placeholder="0" class="width-110" />
          </div>
        </el-form-item>

        <el-form-item label="销量">
          <div class="flex-box">
            <el-input v-model.number="inquire.productSalesMin" placeholder="0" class="width-110" />
            <span class="span-link"> - </span>
            <el-input v-model.number="inquire.productSalesMax" placeholder="0" class="width-110" />
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addProduct">新增产品</el-button>
      <!-- <el-button size="small" type="primary">导入</el-button>
      <el-button size="small" type="primary">导出</el-button> -->
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="productCode" label="产品编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row, 'read')">{{ scope.row.productCode }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="200" show-overflow-tooltip />
            <el-table-column prop="productCategoryName" label="所属类别" min-width="160" />
            <el-table-column prop="" label="销售价" min-width="160">
              <template slot-scope="scope">
                {{ '￥' + scope.row.productPriceMin + ' ~ ￥' + scope.row.productPriceMax }}
              </template>
            </el-table-column>
            <el-table-column prop="productInventoryTotal" label="总库存" min-width="100" />
            <el-table-column prop="hospitalsOnSaleNum" label="在售医院数" min-width="100" />
            <!-- <el-table-column prop="monthlySales" label="月销" min-width="100">
              <template slot-scope="scope">
                {{ scope.row.monthlySales || '0' }}
              </template>
            </el-table-column>
            <el-table-column prop="sumSales" label="总销" min-width="100"></el-table-column> -->
            <el-table-column prop="productOrder" label="排序" min-width="70" />
            <el-table-column prop="onSaleTime" label="上架时间" min-width="160" />
            <el-table-column prop="productStatus" label="状态" min-width="80">
              <template slot-scope="scope">
                {{ $api.getNeedValue(productState, scope.row.productStatus, 'valueCode', 'valueName') }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="300" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row, 'edit')">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleRun(scope.row)">运营范围</span>
                <span class="operation-btn_partition"> / </span>
                <span v-show="!scope.row.whetherRecommend" class="operation-btn" @click="recommend(scope.row, 'Y')">推荐</span>
                <span v-show="scope.row.whetherRecommend" class="operation-btn" @click="recommend(scope.row, 'N')">取消推荐</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="openManageMerchants(scope.row)">管理商家</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="UnShelve(scope.row)">下架</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 运营范围弹窗 -->
    <operatingCity v-if="showCity" :show-city="showCity" @setCityValue="getCityValue" />
    <!-- 管理商家弹窗 -->
    <manageMerchants ref="manageMerchants" />
  </div>
</template>

<script>
import { getValue_ } from '@/utils/public'
import { removeToken } from '@/utils/auth'
import operatingCity from '@/components/operatingCity/index.vue'
import manageMerchants from '../components/manageMerchants'
export default {
  components: {
    operatingCity, manageMerchants
  },
  data() {
    return {
      input: '',
      input2: [],

      // 产品类别 级联选项数据
      productTypeList: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: false
      },

      // 查询参数
      inquire: {
        isGifts: 0,
        productClassify: 'medicine',
        productStatus: 'on_shelves',
        productName: '',
        productCategoryId: '',
        productPriceMin: '',
        productPriceMax: '',
        productSalesMin: '',
        productSalesMax: ''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      productState: [], // 产品状态
      showCity: false
    }
  },
  created() {
    // 获取产品查询参数
    const productForm = this.$store.state.product.productForm
    if (productForm && Object.keys(productForm).length > 0) {
      if (productForm.productClassify === 'medicine') {
        this.inquire = { ...productForm }
      } else {
        this.$store.dispatch('product/setProductForm', {})
      }
    }
    // 获取产品列表
    this.getProductList()
    // 获取产品类别列表
    this.getProductType()
    // 获取产品状态列表
    this.getProductState()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getProductList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getProductList()
    },

    // 获取产品列表
    getProductList() {
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http.requestPost({
        url: '/product/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.pageParam.total = res.data.total
        this.$store.dispatch('product/setProductForm', {})
      }).catch(err => {
        this.loading = false
        this.$store.dispatch('product/setProductForm', {})
        console.log(err)
      })
    },

    // 获取产品类型
    getProductType() {
      this.$api.productTypeList({}).then(res => {
        this.productTypeList = res.data
      })
    },
    // 获取产品状态
    getProductState() {
      this.$api.dictionaries('product_status').then(res => {
        this.productState = res.data
      })
    },

    // 添加产品
    addProduct() {
      this.$store.dispatch('product/setProductForm', this.inquire)
      removeToken('productId')
      this.$router.push('/product/medicine/addProduct')
    },

    // 编辑
    handleEdit(row, type) {
      this.$store.dispatch('product/setProductForm', this.inquire)
      this.$router.push({
        path: '/product/medicine/addProduct',
        query: {
          id: row.productId,
          type: type
        }
      })
    },

    // 订单详情
    UnShelve(row) {
      this.$confirm('是否确定下架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.requestPut({ url: '/product/off/' + row.productId })
          .then(res => {
            this.getProductList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
      }).catch(() => {})
    },

    // 推荐
    recommend(row, type) {
      const name = type === 'Y' ? '推荐' : '取消推荐'
      this.$confirm('是否确定' + name + '该商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.requestPut({ url: '/product/recommend/' + row.productId }).then(res => {
          this.getProductList()
          this.$message.success('操作成功')
        })
      }).catch(() => {})
    },
    handleRun(val) {
      this.showCity = !this.showCity
      const cityId = val.productId
      sessionStorage.setItem('cityId', JSON.stringify(cityId))
    },
    getCityValue(val) {
      this.showCity = val
    },

    // 管理商家
    openManageMerchants(row) {
      this.$refs.manageMerchants.open(row)
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
