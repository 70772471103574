<template>
  <!-- 产品单位列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="单位名称">
          <div class="flex-box">
            <el-input v-model="inquire.unitsName" placeholder="单位名称" @keyup.enter.native="searchList"/>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addClass">添加单位</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border row-key="tableData" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="unitsName" label="单位名称" min-width="100">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">{{ scope.row.unitsName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="unitsComment" label="备注" min-width="100" />
            <el-table-column prop="unitsOrder" label="排序" min-width="100" />
            <el-table-column prop="createTime" label="添加时间" min-width="160" />
            <el-table-column prop="unitsStatus" label="显示状态" min-width="100">
              <template slot-scope="scope">
                {{ $api.getValue(showState, scope.row.unitsStatus, 'name') }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="200" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span v-show="scope.row.unitsStatus === 'display'" class="operation-btn" @click="setState(scope.row, 'h')">隐藏</span>
                <span v-show="scope.row.unitsStatus === 'hide'" class="operation-btn" @click="setState(scope.row, 's')">显示 </span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="deleteUnit(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="500px"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="80px" :model="submitForm" :rules="submitRules">
          <el-form-item label="单位名称" prop="unitsName">
            <el-input v-model="submitForm.unitsName" maxlength="20" placeholder="单位名称" />
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="submitForm.unitsComment"
              type="textarea"
              :rows="3"
              maxlength="150"
              placeholder="请输入内容"
            />
          </el-form-item>
          <el-form-item label="排序" prop="unitsOrder">
            <el-input v-model.number="submitForm.unitsOrder" maxlength="5" placeholder="排序" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitClass">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        unitsName: ''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      showState: [
        { name: '显示', value: 'display' },
        { name: '隐藏', value: 'hide' }
      ],

      dialogTitle: '添加单位', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      submitForm: {
        unitsName: '',
        unitsComment: '',
        unitsOrder: 0
      },
      submitRules: {
        unitsName: [{ required: true, message: '请输入类别名称', trigger: 'blur' }],
        unitsOrder: [{ required: true, message: '请输入排序', trigger: 'blur' },
        { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }]
      },
      isAddClass: true, // 是否为添加
      editRow: {}
    }
  },
  created() {
    // 获取产品列表
    this.getProductList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getProductList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getProductList()
    },

    // 获取产品列表
    getProductList() {
      const unitsName = this.inquire.unitsName !== ''
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize
      }
      unitsName && (data.param = this.inquire)

      this.loading = true
      this.$http.requestPost({
        url: '/product/units/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.pageParam.total = res.data.total
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    // 添加产品类别
    addClass() {
      this.dialogTitle = '添加单位'
      this.dialogVisible = true
      this.isAddClass = true
    },
    // 修改类别
    handleEdit(row) {
      this.dialogTitle = '编辑单位'
      this.isAddClass = false
      this.submitForm = {
        unitsName: row.unitsName,
        unitsComment: row.unitsComment,
        unitsOrder: row.unitsOrder
      }
      this.dialogVisible = true
      this.editRow = row
    },
    // 提交类别添加
    submitClass() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            unitsName: this.submitForm.unitsName,
            unitsComment: this.submitForm.unitsComment,
            unitsOrder: this.submitForm.unitsOrder
          }
          if (!this.isAddClass) {
            param.unitsId = this.editRow.unitsId
          }
          this.submit(param)
        } else {
          return false
        }
      })
    },
    // 提交
    submit(param) {
      this.$http.requestPost({
        url: '/product/units/save',
        param: param
      }).then(res => {
        this.getProductList()
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.closeDialog()
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields())
      this.dialogVisible = false
      this.submitForm = {
        unitsName: '',
        unitsComment: '',
        unitsOrder: ''
      }
    },

    // 修改状态
    setState(row, type) {
      const name = type === 'h' ? '隐藏' : '显示'
      this.$confirm('是否确定' + name + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        const param = {
          unitsId: row.unitsId,
          unitsStatus: type === 'h' ? 'hide' : 'display'
        }
        this.submit(param)
      })
    },

    // 删除
    deleteUnit(row) {
      this.$confirm('确定删除该单位?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
          this.pageParam.pageNum--
        }
        this.$http.requestDel({ url: '/product/units/del/' + row.unitsId, param: '' }).then(res => {
          this.getProductList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
