var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "申购单编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "申购单编号" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.orderCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "orderCode", $$v)
                      },
                      expression: "inquire.orderCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "申购时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "end-placeholder": "结束日期",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.daterange,
                      callback: function ($$v) {
                        _vm.daterange = $$v
                      },
                      expression: "daterange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "产品名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.productName,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "productName", $$v)
                      },
                      expression: "inquire.productName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "商家名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "商家名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.merchantName,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "merchantName", $$v)
                      },
                      expression: "inquire.merchantName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.exportFile },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    ref: "tables",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "expand-row-keys": _vm.expands,
                      border: "",
                      height: "400px",
                      "row-key": "orderCode",
                    },
                    on: { "expand-change": _vm.expandChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: scope.row.items, border: "" },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品信息",
                                      "min-width": "200",
                                      prop: "",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "flex-box" },
                                                [
                                                  scope.row.productImg
                                                    ? _c("img", {
                                                        staticClass: "img-box",
                                                        attrs: {
                                                          src: scope.row
                                                            .productImg,
                                                          alt: "",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "hospital-name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.productName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品编号",
                                      "min-width": "200",
                                      prop: "productCode",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品规格",
                                      "min-width": "120",
                                      prop: "productNorms",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    scope.row.productNorms ||
                                                      "/"
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "申购单价",
                                      "min-width": "120",
                                      prop: "itemPrice",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "￥" + scope.row.itemPrice
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "申购数量",
                                      "min-width": "120",
                                      prop: "itemNum",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "申购总价",
                                      "min-width": "120",
                                      prop: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "￥" +
                                                      (
                                                        parseInt(
                                                          scope.row.itemNum
                                                        ) * scope.row.itemPrice
                                                      ).toFixed(2)
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "退款单编号",
                        "min-width": "200",
                        prop: "orderCode",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAudit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.orderCode))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "商家",
                        "min-width": "160",
                        prop: "merchantName",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "商家编号",
                        "min-width": "200",
                        prop: "merchantCode",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "申购总价",
                        "min-width": "120",
                        prop: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" + _vm.getTotalPrices(scope.row.items)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "退货理由",
                        "min-width": "200",
                        prop: "reasonForReturn",
                        formatter: _vm.formatter,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "申购时间",
                        "min-width": "160",
                        prop: "purchaseTime",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        "min-width": "120",
                        prop: "remark",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleAudit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam.pageNum,
                      "page-size": _vm.pageParam.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.pageParam.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("product-export", {
        ref: "isProductExport",
        attrs: {
          "show-dialog": _vm.showExportDialog,
          close: _vm.showExportDialog,
          "purchase-status": _vm.inquire.purchaseStatus,
          "product-type": "purchaseReturn",
          title: "待审核退货单导出",
          "page-type": "return",
        },
        on: {
          "update:close": function ($event) {
            _vm.showExportDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }