var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "订单编号" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.orderCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "orderCode", $$v)
                      },
                      expression: "inquire.orderCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "下单时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.datetimerange,
                      callback: function ($$v) {
                        _vm.datetimerange = $$v
                      },
                      expression: "datetimerange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-cascader", {
                      attrs: {
                        props: _vm.props,
                        options: _vm.productTypeList,
                        clearable: "",
                      },
                      on: { change: _vm.categoryChange },
                      model: {
                        value: _vm.categoryId,
                        callback: function ($$v) {
                          _vm.categoryId = $$v
                        },
                        expression: "categoryId",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.productList.length > 0,
                            expression: "productList.length > 0",
                          },
                        ],
                        staticClass: "width-260",
                        attrs: { multiple: "", placeholder: "请选择产品" },
                        model: {
                          value: _vm.inquire.productIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "productIds", $$v)
                          },
                          expression: "inquire.productIds",
                        },
                      },
                      _vm._l(_vm.productList, function (item) {
                        return _c("el-option", {
                          key: item.productId,
                          attrs: {
                            label: item.productName,
                            value: item.productId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "优惠券ID" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.couponCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inquire,
                          "couponCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "inquire.couponCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.externalProductFlag
                ? _c(
                    "el-form-item",
                    { attrs: { label: "寄送样本状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.inquire.sampleLogisticsStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.inquire,
                                "sampleLogisticsStatus",
                                $$v
                              )
                            },
                            expression: "inquire.sampleLogisticsStatus",
                          },
                        },
                        _vm._l(_vm.$api.sendSampleStatus, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.externalProductFlag
                ? _c(
                    "el-form-item",
                    { attrs: { label: "检测报告状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.inquire.reportStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.inquire, "reportStatus", $$v)
                            },
                            expression: "inquire.reportStatus",
                          },
                        },
                        _vm._l(_vm.$api.reportStatus, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.name, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.fnExportOrderList },
            },
            [_vm._v("导出")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.batchExport },
            },
            [_vm._v("按批次导出")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "tables",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "expand-row-keys": _vm.expands,
                      border: "",
                      height: "400px",
                      "row-key": "orderId",
                    },
                    on: { "expand-change": _vm.expandChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: scope.row.items, border: "" },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品信息",
                                      "min-width": "200",
                                      prop: "",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "flex-box" },
                                                [
                                                  scope.row.productImg
                                                    ? _c("img", {
                                                        staticClass: "img-box",
                                                        attrs: {
                                                          src: scope.row
                                                            .productImg,
                                                          alt: "",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "hospital-name",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "txt_box",
                                                          class: {
                                                            "flex-box":
                                                              !scope.row
                                                                .productImg,
                                                            "flex-center":
                                                              !scope.row
                                                                .productImg,
                                                          },
                                                        },
                                                        [
                                                          scope.row.isGifts
                                                            ? _c("img", {
                                                                staticClass:
                                                                  "icon_box",
                                                                class: {
                                                                  no_img:
                                                                    !scope.row
                                                                      .productImg,
                                                                },
                                                                attrs: {
                                                                  src: require("@/assets/image/gift_icon.png"),
                                                                  alt: "",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                scope.row
                                                                  .productName
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品编号",
                                      "min-width": "160",
                                      prop: "productCode",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品规格",
                                      "min-width": "120",
                                      prop: "productNorms",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    scope.row.productNorms ||
                                                      "/"
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "单价",
                                      "min-width": "120",
                                      prop: "itemPrice",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "￥" + scope.row.itemPrice
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "数量",
                                      "min-width": "120",
                                      prop: "itemNum",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "购买总价",
                                      "min-width": "120",
                                      prop: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "￥" +
                                                      (
                                                        parseInt(
                                                          scope.row.itemNum
                                                        ) * scope.row.itemPrice
                                                      ).toFixed(2)
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "订单编号",
                        "min-width": "200",
                        prop: "orderCode",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleParticulars(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.orderCode))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "买家/收货人",
                        "min-width": "160",
                        prop: "",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    scope.row.memberNickName +
                                      "/" +
                                      scope.row.recipient
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "总金额", "min-width": "120", prop: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" + scope.row.orderTotalPrice.toFixed(2)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "运费", "min-width": "120", prop: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" + scope.row.shippingFee.toFixed(2)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "下单时间",
                        "min-width": "160",
                        prop: "orderTime",
                      },
                    }),
                    _vm._v(" "),
                    _vm.orderStatus == "pending_ship"
                      ? _c("el-table-column", {
                          attrs: {
                            label: "付款时间",
                            "min-width": "160",
                            prop: "paymentTime",
                          },
                        })
                      : _vm.orderStatus == "pending_receipt"
                      ? _c("el-table-column", {
                          attrs: {
                            label: "发货时间",
                            "min-width": "160",
                            prop: "shipTime",
                          },
                        })
                      : _vm.orderStatus == "pending_evaluate"
                      ? _c("el-table-column", {
                          attrs: {
                            label: "确认收货时间",
                            "min-width": "160",
                            prop: "receiptTime",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "订单状态",
                        "min-width": "120",
                        prop: "orderStatus",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$api.getValue(
                                      _vm.$api.EcommerceOrderState,
                                      scope.row.orderStatus,
                                      "name"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _vm.externalProductFlag
                      ? _c("el-table-column", {
                          attrs: {
                            label: "寄送样本状态",
                            "min-width": "120",
                            prop: "orderStatus",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$api.getValue(
                                            _vm.$api.sendSampleStatus,
                                            scope.row.sampleLogisticsStatus
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3410196130
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.externalProductFlag
                      ? _c("el-table-column", {
                          attrs: {
                            label: "检测报告状态",
                            "min-width": "120",
                            prop: "orderStatus",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$api.getValue(
                                            _vm.$api.reportStatus,
                                            scope.row.reportStatus
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3099388621
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        "min-width": "160",
                        prop: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.orderStatus ===
                                        "pending_ship",
                                      expression:
                                        "scope.row.orderStatus === 'pending_ship'",
                                    },
                                  ],
                                  staticClass: "operation-btn_partition",
                                },
                                [_vm._v("/")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleParticulars(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("订单详情")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.operationPermissions(
                                        scope.row,
                                        true
                                      ),
                                      expression:
                                        "operationPermissions(scope.row, true)",
                                    },
                                  ],
                                  staticClass: "operation-btn_partition",
                                },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _vm.operationPermissions(scope.row, true)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "operation-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.sendLogistics(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("寄送样本")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.operationPermissions(
                                        scope.row
                                      ),
                                      expression:
                                        "operationPermissions(scope.row)",
                                    },
                                  ],
                                  staticClass: "operation-btn_partition",
                                },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _vm.operationPermissions(scope.row)
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "operation-btn",
                                      on: {
                                        click: function ($event) {
                                          return _vm.pdfListShow(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("检测报告")]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam.pageNum,
                      "page-size": _vm.pageParam.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.pageParam.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_1,
            title: "修改价格",
            top: "2vh",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c("div", { staticClass: "Popout_content" }, [
            _c(
              "div",
              { staticClass: "table" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "", height: "400px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "序号", prop: "date", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    (_vm.pageParam.pageNum - 1) *
                                      _vm.pageParam.pageSize +
                                      (scope.$index + 1)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "产品信息",
                          "min-width": "120",
                          prop: "orderTotalPrice",
                        },
                      },
                      [void 0],
                      2
                    ),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "单价",
                        "min-width": "100",
                        prop: "orderTotalPrice",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "数量",
                        "min-width": "100",
                        prop: "orderTotalPrice",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "小计",
                        "min-width": "100",
                        prop: "orderTotalPrice",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "加价/减价",
                        "min-width": "120",
                        prop: "orderTotalPrice",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                { staticClass: "flex-box flex-center" },
                                [
                                  _vm._v(
                                    "\n                ￥\n                "
                                  ),
                                  _c("el-input", {
                                    attrs: { placeholder: "" },
                                    model: {
                                      value: scope.row.orderTotalPrice,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "orderTotalPrice",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.orderTotalPrice",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "实收金额",
                        "min-width": "100",
                        prop: "orderTotalPrice",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "show-money" }, [
              _c("ul", [
                _c("li", [
                  _vm._v("\n            商品金额：\n            "),
                  _c("span", { staticClass: "block_span" }, [
                    _vm._v("￥200.00"),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("\n            运费：\n            "),
                  _c("span", { staticClass: "block_span" }, [
                    _vm._v("+￥20.00"),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("\n            加价/减价：\n            "),
                  _c("span", { staticClass: "block_span color_red" }, [
                    _vm._v("-￥20.00"),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "line-box" }),
                _vm._v(" "),
                _c("li", { staticClass: "bold" }, [
                  _vm._v("\n            实付总额：\n            "),
                  _c("span", { staticClass: "block_span color_red" }, [
                    _vm._v("￥180.00"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_2,
            title: "发货",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm_2",
                  attrs: {
                    model: _vm.submitForm_2,
                    rules: _vm.submitRules_2,
                    "label-width": "80px",
                    size: "medium",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "订单编号" },
                        model: {
                          value: _vm.submitForm_2.orderCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm_2, "orderCode", $$v)
                          },
                          expression: "submitForm_2.orderCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流公司", prop: "logisticsCompany" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form-select",
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.submitForm_2.logisticsCompany,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.submitForm_2,
                                "logisticsCompany",
                                $$v
                              )
                            },
                            expression: "submitForm_2.logisticsCompany",
                          },
                        },
                        _vm._l(_vm.logisticsCompanyList, function (item) {
                          return _c("el-option", {
                            key: item.companyId,
                            attrs: {
                              label: item.companyName,
                              value: item.companyId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流单号", prop: "logisticsCode" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", placeholder: "物流单号" },
                        model: {
                          value: _vm.submitForm_2.logisticsCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm_2, "logisticsCode", $$v)
                          },
                          expression: "submitForm_2.logisticsCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSendOn },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "testReportPopup",
          attrs: {
            visible: _vm.testReportPopupVisible,
            title: _vm.testReportData.orderCode + " - 检测报告",
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.testReportPopupVisible = $event
            },
          },
        },
        [
          _vm._l(_vm.testReportData.testReportList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "line flex flex_row_justify flex_column_center",
              },
              [
                _c("p", { staticClass: "title" }, [
                  _vm._v(
                    "睿长太@miR-92a无创结直肠癌检测报告" + _vm._s(index + 1)
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "btn_box" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.previewPDF(item)
                          },
                        },
                      },
                      [_vm._v("预览")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.downLoadPDF(item, index)
                          },
                        },
                      },
                      [_vm._v("下载")]
                    ),
                  ],
                  1
                ),
              ]
            )
          }),
          _vm._v(" "),
          _vm.testReportData.testReportList.length <= 0
            ? _c("div", { staticClass: "moDataTag" }, [_vm._v("暂无检测报告")])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "roll_dialog",
          attrs: {
            visible: _vm.sendVisible,
            width: "50%",
            "before-close": function () {
              return (_vm.sendVisible = false)
            },
            title: _vm.sendOrderCode,
          },
          on: {
            "update:visible": function ($event) {
              _vm.sendVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "sendContainer" },
            _vm._l(_vm.sampleLogisticsState, function (item, ind) {
              return _c("div", { key: ind, staticClass: "sendMain" }, [
                _c("div", { staticClass: "sendTitle" }, [
                  _vm._v("物流单号: " + _vm._s(item)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "sendBtn",
                    on: {
                      click: function ($event) {
                        return _vm.sendChange(item)
                      },
                    },
                  },
                  [_vm._v("寄送样本物流")]
                ),
              ])
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _c("d-export", {
        attrs: {
          title: _vm.typeTitle,
          "is-new-template": _vm.isNewTemplate,
          inquire: _vm.inquire,
          type: _vm.inquire.orderClassify,
          "show-dialog": _vm.showExportDialog,
          close: _vm.showExportDialog,
        },
        on: {
          "update:close": function ($event) {
            _vm.showExportDialog = $event
          },
        },
      }),
      _vm._v(" "),
      _vm.exportTags
        ? _c("d-export", {
            attrs: {
              inquire: _vm.inquire,
              "general-export-flag": _vm.generalExportDialog,
              "show-dialog": _vm.generalExportDialog,
              close: _vm.generalExportDialog,
              type: _vm.inquire.orderClassify,
            },
            on: {
              "update:close": function ($event) {
                _vm.generalExportDialog = $event
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("z-dialog", {
        ref: "sendRefs",
        attrs: {
          config: {
            sendCode: this.sendOrderCode,
            logisticsCode: this.logisticsCode,
          },
          logisticsData: _vm.logisticsInfo,
        },
        on: { handleClose: _vm.handleCloseChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }