<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="日期">
          <el-date-picker
            v-model="daterange"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="商家">
          <el-input v-model="inquire.merchantName" placeholder="商家"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary">导出报表</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="merchantName" label="商家" min-width="160">
              <template slot-scope="scope">{{scope.row.merchantName ? scope.row.merchantName : '总部（未指定商家）'}}</template>
            </el-table-column>
            <el-table-column prop="messengerName" label="商家信息员" min-width="160">
              <template slot-scope="scope">{{scope.row.messengerName ? scope.row.messengerName : '无'}}</template>
            </el-table-column>
            <el-table-column prop="merchantContactSphoneNumber" label="手机号码" min-width="160">
              <template slot-scope="scope">{{scope.row.merchantContactSphoneNumber ? scope.row.merchantContactSphoneNumber : '无'}}</template>
            </el-table-column>
            <el-table-column prop="orderTotalPrice" label="销售金额" min-width="100">
              <template slot-scope="scope">{{ '￥' + scope.row.orderTotalPrice.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="count" label="订单数量" min-width="100"></el-table-column>
            <el-table-column prop="memberCount" label="交易会员数" min-width="100"></el-table-column>
            <el-table-column prop="amount" label="提成金额" min-width="100">
              <template slot-scope="scope">{{ '￥' + scope.row.amount.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="status" label="提成收款" min-width="100">
              <template slot-scope="scope">{{ '￥' + scope.row.status == '1' ? '已确认' : '待确认' }}</template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="120" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="details(scope.row)">订单明细</span> 
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        merchantName: '',
        payTime: '',
        payTimeEnd: '',
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [],  
    };
  },
  created() {
    this.daterange = [this.$public.getAssignMonth(new Date())[0] + ' 00:00:00', this.$public.getAssignDate(0) + ' 23:59:59']
    // 获取产品列表
    this.getProductList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getProductList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getProductList()
    },

    // 获取产品列表
    getProductList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.payTime = this.daterange.length > 0 ? this.daterange[0] : '';
      this.inquire.payTimeEnd = this.daterange.length > 0 ? this.daterange[1] : '';

      this.loading = true;
      this.$http.requestPost({
        url: '/wait/payment/merchant/commission/comprehensive/merchantStatisticsList?pageNum=' + this.pageParam.pageNum + '&pageSize=' + this.pageParam.pageSize,
        param: this.inquire,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.data.list;
        this.pageParam.total = res.data.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    // 详细
    details(row) {
      this.$router.push({
        path: '/financial/financialStatement/orderDetails',
        query: {
          type: 'merchant',
          merchantId: row.merchantId,
          param: JSON.stringify({})
        }
      })
    }
    
  },
};
</script>

<style lang="scss" scoped>
  
</style>
