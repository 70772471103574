var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_2 bg_white flex-box" }, [
        _c("div", { staticClass: "row_left" }, [
          _c("h4", [_vm._v("配送信息")]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("配送方式：" + _vm._s("商家配送" + "1")),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("买家姓名：" + _vm._s("商家配送")),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("买家手机号：" + _vm._s("商家配送")),
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { height: "12px" } }),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("收货人姓名：" + _vm._s("商家配送")),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("收货人手机号：" + _vm._s("商家配送")),
          ]),
          _vm._v(" "),
          _c(
            "p",
            { staticClass: "color_light-black" },
            [
              _vm._v("\n        收货人地址：\n        "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    "广东省深圳市宝安西乡街道固戍地铁口D口机器人产业园B栋401"
                  )
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { staticStyle: { padding: "0" }, attrs: { type: "text" } },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticStyle: { height: "24px" } }),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("备注信息：" + _vm._s("无")),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row_right" }, [
          _c("h4", [
            _vm._v("\n        订单状态：\n        "),
            _c("span", [_vm._v(_vm._s(_vm.orderType[_vm.active]))]),
          ]),
          _vm._v(" "),
          _vm.active == 0
            ? _c(
                "div",
                [
                  _c("el-button", { attrs: { plain: "", size: "medium" } }, [
                    _vm._v("取消订单"),
                  ]),
                ],
                1
              )
            : _vm.active == 1
            ? _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { plain: "", size: "medium" },
                      on: { click: _vm.sendOut },
                    },
                    [_vm._v("发货")]
                  ),
                ],
                1
              )
            : _vm.active == 4
            ? _c("div", { staticClass: "color_light-black" }, [
                _vm._v("\n        超时未支付订单系统自动取消\n      "),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_3 bg_white" }, [
        _c("h4", [_vm._v("商品信息")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.tableData, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "序号", prop: "date", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                (_vm.pageParam.pageNum - 1) *
                                  _vm.pageParam.pageSize +
                                  (scope.$index + 1)
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c(
                  "el-table-column",
                  {
                    attrs: {
                      label: "产品信息",
                      "min-width": "120",
                      prop: "available",
                    },
                  },
                  [void 0],
                  2
                ),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "单价",
                    "min-width": "100",
                    prop: "available",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "数量",
                    "min-width": "100",
                    prop: "available",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "小计",
                    "min-width": "100",
                    prop: "available",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "实收金额",
                    "min-width": "100",
                    prop: "available",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "提成比例",
                    "min-width": "100",
                    prop: "available",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "提成金额",
                    "min-width": "100",
                    prop: "available",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_4 bg_white" }, [
        _c("h4", [_vm._v("商家/医院信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家名称：")]),
          _vm._v("\n      " + _vm._s("润泽堂药行业") + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家编号：")]),
          _vm._v("\n      " + _vm._s("S0001") + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("医院名称：")]),
          _vm._v("\n      " + _vm._s("润泽堂药行业西乡分店") + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("医院编号：")]),
          _vm._v("\n      " + _vm._s("Y00008") + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_4 bg_white" }, [
        _c("h4", [_vm._v("订单信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("订单类型：")]),
          _vm._v("\n      " + _vm._s("医药品订单") + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("订单编号：")]),
          _vm._v("\n      " + _vm._s("医药品订单") + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("下单时间：")]),
          _vm._v("\n      " + _vm._s("医药品订单") + "\n    "),
        ]),
        _vm._v(" "),
        _vm.active > 0 && _vm.active < 4
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("付款时间：")]),
              _vm._v("\n      " + _vm._s("医药品订单") + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.active > 1 && _vm.active < 4
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("发货时间：")]),
              _vm._v("\n      " + _vm._s("医药品订单") + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.active > 2 && _vm.active < 4
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [
                _vm._v("确认收货时间："),
              ]),
              _vm._v("\n      " + _vm._s("医药品订单") + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.active == 4
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("取消时间：")]),
              _vm._v("\n      " + _vm._s("医药品订单") + "\n    "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_5 row_4 bg_white" }, [
        _c("h4", [_vm._v("专员信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("专员姓名：")]),
          _vm._v("\n      " + _vm._s("医药品订单") + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("专员编号：")]),
          _vm._v("\n      " + _vm._s("医药品订单") + "\n    "),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_1,
            title: "修改价格",
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c("div", { staticClass: "Popout_content" }, [
            _c(
              "div",
              { staticClass: "table" },
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData_1, border: "" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "序号", prop: "date", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    (_vm.pageParam.pageNum - 1) *
                                      _vm.pageParam.pageSize +
                                      (scope.$index + 1)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "产品信息",
                          "min-width": "120",
                          prop: "available",
                        },
                      },
                      [void 0],
                      2
                    ),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "单价",
                        "min-width": "100",
                        prop: "available",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "数量",
                        "min-width": "100",
                        prop: "available",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "小计",
                        "min-width": "100",
                        prop: "available",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-table-column",
                      {
                        attrs: {
                          label: "加价/减价",
                          "min-width": "120",
                          prop: "available",
                        },
                      },
                      [
                        [
                          _c(
                            "div",
                            { staticClass: "flex-box flex-center" },
                            [
                              _vm._v("\n                ￥\n                "),
                              _c("el-input", {
                                attrs: { placeholder: "" },
                                model: {
                                  value: _vm.tableData_1.input,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.tableData_1, "input", $$v)
                                  },
                                  expression: "tableData_1.input",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "实收金额",
                        "min-width": "100",
                        prop: "available",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "show-money" }, [
              _c("ul", [
                _c("li", [
                  _vm._v("\n            商品金额：\n            "),
                  _c("span", { staticClass: "block_span" }, [
                    _vm._v("￥200.00"),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("\n            运费：\n            "),
                  _c("span", { staticClass: "block_span" }, [
                    _vm._v("+￥20.00"),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", [
                  _vm._v("\n            加价/减价：\n            "),
                  _c("span", { staticClass: "block_span color_red" }, [
                    _vm._v("-￥20.00"),
                  ]),
                ]),
                _vm._v(" "),
                _c("li", { staticClass: "line-box" }),
                _vm._v(" "),
                _c("li", { staticClass: "bold" }, [
                  _vm._v("\n            实付总额：\n            "),
                  _c("span", { staticClass: "block_span color_red" }, [
                    _vm._v("￥180.00"),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_1 = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible_2,
            title: "发货",
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单编号" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", placeholder: "订单编号" },
                        model: {
                          value: _vm.submitForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "name", $$v)
                          },
                          expression: "submitForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流公司", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "物流公司" },
                        model: {
                          value: _vm.submitForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "name", $$v)
                          },
                          expression: "submitForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "物流单号", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "30", placeholder: "物流单号" },
                        model: {
                          value: _vm.submitForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "name", $$v)
                          },
                          expression: "submitForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_2 = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "show-money" }, [
      _c("ul", [
        _c("li", [
          _vm._v("\n          商品金额：\n          "),
          _c("span", { staticClass: "block_span" }, [_vm._v("￥200.00")]),
        ]),
        _vm._v(" "),
        _c("li", [
          _vm._v("\n          运费：\n          "),
          _c("span", { staticClass: "block_span" }, [_vm._v("+￥20.00")]),
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "line-box" }),
        _vm._v(" "),
        _c("li", { staticClass: "bold" }, [
          _vm._v("\n          实付总额：\n          "),
          _c("span", { staticClass: "block_span color_red" }, [
            _vm._v("￥180.00"),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }