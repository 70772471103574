<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item v-if="!thirdPartyPermissions" label="订单类型">
          <div class="flex-box">
            <el-select v-model="inquire.orderClassify" placeholder="请选择" class="width-140" clearable>
              <el-option
                v-for="(item, index) in $api.orderClassify"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="订单编号/微信昵称">
          <el-input v-model="inquire.orderCode" placeholder="订单编号/微信昵称" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item label="下单时间">
          <el-date-picker
            v-model="daterange"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>

        <el-form-item label="">
          <div class="flex-box">
            <el-cascader
              v-model="categoryId"
              :props="props"
              :options="productTypeList"
              clearable
              @change="categoryChange"
            />
            <el-select
              v-show="productList.length > 0"
              v-model="inquire.productIds"
              multiple
              placeholder="请选择产品"
              class="width-260"
            >
              <el-option
                v-for="item in productList"
                :key="item.productId"
                :label="item.productName"
                :value="item.productId"
              />
            </el-select>
          </div>
        </el-form-item>

        <el-form-item v-if="!thirdPartyPermissions" label="区域">
          <el-cascader
            v-if="$public.isAllAreaPower()"
            v-model="inquire.areaIdSelect"
            :props="props_1"
            :options="addressList"
            clearable
            placeholder="请选择地址"
          />
          <d-area v-else :values.sync="inquire.areaIdSelect" />
        </el-form-item>
      </el-form>

      <div style="margin-bottom: 20px">
        <el-button type="primary" size="small" @click="searchList">查询</el-button>
        <el-button type="primary" size="small" @click="exportTableData">导出</el-button>
        <el-button type="primary" size="small" @click="importOrExportData.importDialog = true">批量出库</el-button>
      </div>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="orderCode" label="订单编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="memberNickName" label="微信昵称" min-width="120" show-overflow-tooltip />
            <el-table-column prop="orderClassify" label="订单类型" min-width="120">
              <template slot-scope="scope">
                {{ orderClassifyText(scope.row.orderClassify) }}
              </template>
            </el-table-column>
            <el-table-column prop="orderTotalPrice" label="总金额" min-width="120">
              <template slot-scope="scope">
                {{ "￥" + scope.row.orderTotalPrice.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="orderStatus" label="订单状态" min-width="120">待出库</el-table-column>
            <el-table-column prop="orderTime" label="下单时间" min-width="160" />
            <el-table-column
              prop="recipientAddressDetail"
              label="收货地址"
              min-width="200"
              show-overflow-tooltip
            />
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="handleOutbound(scope.row)">出库</span>
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              background
              :current-page="pageParam.pageNum"
              :page-sizes="$api.pageSizes"
              :page-size="pageParam.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageParam.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </template>
      </el-main>
    </div>

    <!-- 出库弹框 -->
    <el-dialog title="出库" :visible.sync="dialogVisible" width="80%" top="5vh">
      <div class="Popout_content">
        <el-form ref="submitForm_2" class="demo-form-inline" size="medium" :model="submitForm_2" :rules="submitRules_2">
          <div v-for="(item, index) in detailInfo.items" :key="index">
            <el-form-item label="产品信息">
              <el-table v-loading="loading" :data="[detailInfo.items[index]]" border class="table-box">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column label="产品信息" width="200" prop="" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div class="flex-box">
                      <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box">
                      <div class="hospital-name">
                        <div class="txt_box" :class="{'flex-box':!scope.row.productImg,'flex-center':!scope.row.productImg}">
                          <img v-if="scope.row.isGifts" src="@/assets/image/gift_icon.png" alt="" class="icon_box" :class="{no_img:!scope.row.productImg}">
                          {{ scope.row.productName }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="单价（￥）" width="120" prop="itemPrice" />
                <el-table-column label="购买数量" width="120" prop="itemNum" />
                <el-table-column label="退货数量" width="120" prop="returnNum">
                  <template slot-scope="scope">
                    {{ scope.row.returnNum || "0" }}
                  </template>
                </el-table-column>
                <el-table-column label="小计" width="120" prop="">
                  <template slot-scope="scope">
                    {{ "￥" + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column label="实收金额" width="120" prop="">
                  <template slot-scope="scope">
                    {{ "￥" + (scope.row.paidAmount || scope.row.itemAmount || 0)}}
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="批次信息">
              <el-button type="primary" size="small" @click="openSelectBatch(index)">选择批次</el-button>
            </el-form-item>
            <el-form-item label="">
              <el-table v-loading="loading" :data="detailInfo.items[index].batchList" border style="width: 1132px">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column label="批次" width="150" prop="batchNo" />
                <el-table-column label="库存" width="120" prop="count" />
                <el-table-column label="生产日期" width="140" prop="produceTime" />
                <el-table-column label="到期日期" width="140" prop="expireTime" />
                <el-table-column label="有效期（天）" width="120" prop="termOfValidity" />
                <el-table-column label="入库时间" width="140" prop="createTime" />
                <el-table-column label="出库数量" width="160" prop="">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.num" @blur="verifyInt(scope.row.num, index, scope.$index)" />
                  </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="100" fixed="right">
                  <template slot-scope="scope">
                    <span class="operation-btn" @click="deleteBatch(index, scope.row.$index)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </div>
          <el-form-item label="订单编号">
            <el-input v-model="submitForm_2.orderCode" disabled placeholder="订单编号" />
          </el-form-item>

          <el-form-item v-if="detailInfo.orderClassify === 'external'" label="发货方式" prop="wechatDeliveryMethod">
            <el-select v-model="submitForm_2.wechatDeliveryMethod" class="form-select" placeholder="请选择">
              <el-option :value="3" label="虚拟发货" />
              <el-option :value="2" label="同城配送" />
              <el-option :value="1" label="物流配送" />
            </el-select>
          </el-form-item>

          <el-form-item label="物流公司" prop="logisticsCompany">
            <el-select v-model="submitForm_2.logisticsCompany" class="form-select" placeholder="请选择">
              <el-option
                v-for="item in logisticsCompanyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="物流单号" prop="logisticsCode">
            <el-input v-model="submitForm_2.logisticsCode" maxlength="30" placeholder="物流单号" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmOutbound">出 库</el-button>
      </span>
    </el-dialog>

    <!-- 选择批次弹窗 -->
    <el-dialog title="选择批次" :visible.sync="dialogVisible_batch" width="80%" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="批次">
            <el-input v-model="inquire_batch.batchNo" placeholder="批次" clearable />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="searchList_batch">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="batchTable"
          v-loading="loading"
          :data="batchTable"
          border
          height="400px"
          @selection-change="handleBatchChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label="批次" min-width="160" prop="batchNo" />
          <el-table-column label="库存" min-width="120" prop="count" />
          <el-table-column label="生产日期" min-width="160" prop="produceTime" />
          <el-table-column label="到期日期" min-width="160" prop="expireTime" />
          <el-table-column label="有效期（天）" min-width="120" prop="termOfValidity" />
          <el-table-column label="入库时间" min-width="160" prop="createTime" />
          <el-table-column label="操作人" min-width="160" prop="operatorName" />
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_batch.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_batch.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_batch.total"
            @size-change="handleSizeChange_batch"
            @current-change="handleCurrentChange_batch"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_batch = false">取 消</el-button>
        <el-button type="primary" @click="confirmBatchSelect">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 批量导入弹窗 -->
    <el-dialog title="批量导入" width="25%" :visible.sync="importOrExportData.importDialog" :close-on-click-modal="false">
      <el-form ref="importForm" label-width="100px">
        <el-form-item label="批量导入">
          <d-upload
            :action="`${importOrExportData.requestUrl}`"
            accept=".xls,.xlsx"
            :show-file-list="false"
            :file-list="importOrExportData.fileList"
            @beforeUpload="beforeUpload"
            @uploadSuccess="importFile"
            @uploadError="uploadError"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </d-upload>
          <div>
            <p v-if="importOrExportData.importStatus === 1" class="progress color_success">导入完成，具体结果请在表格中查看</p>
            <p v-else-if="importOrExportData.importStatus === 2" class="progress color_red">导入失败，请检查导入模版是否正确！</p>
            <p v-else-if="importOrExportData.importStatus === 3" class="progress">正在导入中...</p>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importOrExportData.importDialog = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import dArea from '@/components/d-area'
import dUpload from '@/components/d-upload'
export default {
  components: {
    dArea, dUpload
  },
  data() {
    return {
      thirdPartyPermissions: false, // 是不是第三方权限组
      // 产品分类
      productTypeList: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        // checkStrictly: true,
        emitPath: true
      },
      productList: [],

      // 片区
      addressList: [],
      props_1: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },

      // 查询参数
      categoryId: '',
      inquire: {
        areaIdSelect: '',
        orderStatus: 'pending_stock', // 待出库
        isReturnOrder: false,
        orderCode: '',
        orderTimeBegin: '',
        orderTimeEnd: '',
        isOutboundOrder: true
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      commentId: '', // 主键id

      // 出库
      dialogVisible: false, // 出库弹窗
      detailInfo: {
        items: []
      },
      currentIndex: 0, // 选择批次的产品下标

      // 选择批次弹窗
      dialogVisible_batch: false,
      inquire_batch: {
        inventoryId: '',
        merchantId: '',
        batchNo: '',
        batchIds: []
      },
      pageParam_batch: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      batchTable: [],
      selectBatch: [], // 选择的批次

      // ========= 单个发货弹窗 =========
      dialogVisible_2: false,
      submitForm_2: {
        orderCode: '', // 订单编号
        logisticsCompany: '', // 物流公司
        logisticsCode: '', // 物流编号
        wechatDeliveryMethod: 3 // 发货方式
      },
      submitRules_2: {
        logisticsCompany: [{ required: true, message: '请选择物流公司', trigger: 'change' }],
        logisticsCode: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
          { pattern: /^[0-9a-zA-Z]+$/, message: '请输入字母和数字', trigger: 'blur' }
        ]
      },
      logisticsCompanyList: [], // 物流公司列表

      // 导入数据
      importOrExportData: {
        requestUrl: '/customer/order/batchOutbound',
        importDialog: false, // 导入弹窗
        importStatus: 0, // 导入状态 1-导出成功 2-导入失败 3-导入中
        fileList: []
      }
    }
  },
  created() {
    const userInfo = getSessionStorage('userInfo') || {}
    // 判断是不是第三方权限
    this.thirdPartyPermissions = userInfo && userInfo.rightsGroupNames.length == 1 && userInfo.rightsGroupNames.includes('第三方权限组')

    this.getProductType()
    // 获取数据列表
    this.getTableList()

    // this.getLogisticsCompanyList();

    this.getDistrictIdList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取物流公司列表
    getLogisticsCompanyList() {
      const param = {
        pageNum: 1,
        pageSize: 10000
      }
      this.$http.requestPost({ url: '/system/logistics/company/page', param, loading: false }).then((res) => {
        this.logisticsCompanyList = res.data.list
        if (res.data.list.length > 0) {
          const arr = res.data.list.filter((item) => item.companyName === '顺丰速运')
          if (arr.length > 0) {
            this.submitForm_2.logisticsCompany = arr[0].companyId
          }
        }
      })
    },
    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = []
      this.inquire.orderTimeBegin = this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire.orderTimeEnd = this.daterange.length > 0 ? this.daterange[1] : ''
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http
        .requestPost({
          url: '/customer/order/page',
          param: data,
          loading: false
        })
        .then((res) => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
        })
        .catch((err) => {
          this.loading = false
          console.log('err', err)
        })
    },

    // 查看详情
    handleDetails(row) {
      let path = ''
      switch (row.orderClassify) {
        case 'electronic':
          path = '/order/commerce/particulars'
          break
        case 'medicine':
          path = '/order/medical/hospitalDelivery/particulars'
          break
        case 'external':
          path = '/order/ExternalProductOrder/particulars'
          break
        case 'lease':
          path = '/order/lease/particulars'
          break
        default:
          return
      }
      this.$router.push({
        path,
        query: {
          id: row.orderId
        }
      })
    },

    // ================ 出库 ================
    // 打开出库弹窗
    handleOutbound(row) {
      this.getLogisticsCompanyList()
      this.detailInfo = JSON.parse(JSON.stringify(row))
      this.detailInfo.items.map(v => {
        v.batchList = []
      })
      this.dialogVisible = true

      this.submitForm_2 = {
        orderCode: row.orderCode,
        logisticsCompany: '',
        logisticsCode: '',
        shippingMethod: 0,
        wechatDeliveryMethod: 3
      }
      this.dialogVisible_2 = true
      this.editRow = row
      this.inquire_batch.merchantId = row.deliveryMethod === 'hospital' ? row.merchantId : ''
    },
    // 打开选择批次弹窗
    openSelectBatch(index) {
      this.inquire_batch.inventoryId = this.detailInfo.items[index].inventoryId
      this.currentIndex = index
      this.dialogVisible_batch = true
      this.searchList_batch()
    },
    // 删除出库批次
    deleteBatch(index1, index2) {
      this.detailInfo.items[index1].batchList.splice(index2, 1)
    },
    // 出库数量输入值检测
    verifyInt(v, i1, i2) {
      // 判断是否为正整数
      if (!this.$validate.verifyInt(v)) {
        this.detailInfo.items[i1].batchList[i2].num = ''
        return
      }

      // 判断当前出库数量是否大于实际购买数量
      const itemNum = this.detailInfo.items[i1].itemNum
      const returnNum = this.detailInfo.items[i1].returnNum || 0
      const num = itemNum - returnNum
      const totalNum = this.detailInfo.items[i1].batchList.reduce((t, n) => t + parseInt(n.num || 0), 0)
      if (totalNum > num) {
        this.detailInfo.items[i1].batchList[i2].num = ''
        return
      }

      // 将类似 '01' 的字符串转换为 1
      this.detailInfo.items[i1].batchList[i2].num = parseInt(v)
    },
    // 确定出库
    confirmOutbound() {
      const items = this.detailInfo.items
      let require = true
      const batch = []
      for (let i = 0; i < items.length; i++) {
        const itemNum = items[i].itemNum - parseInt(items[i].returnNum || 0)
        const outboundNum = items[i].batchList.reduce((t, v) => {
          if (parseInt(v.num) > 0) {
            const curBatch = {
              customerOrderId: this.detailInfo.orderId,
              batchId: v.batchId,
              deliverCount: v.num,
              customerOrderItemId: items[i].itemId
            }
            batch.push(curBatch)
          }
          return t + parseInt(v.num)
        }, 0)
        if (outboundNum < itemNum) {
          this.$message.warning(`"${items[i].productName}" 产品的批次出库数量不足`)
          require = false
          break
        } else if (outboundNum > itemNum) {
          this.$message.warning(`"${items[i].productName}" 产品的批次出库数量超出`)
          require = false
          break
        }
      }

      if (require) {
        if (batch.length <= 0) {
          return this.$message.warning(`请填写出库数量`)
        }
        this.$refs.submitForm_2.validate(valid => {
          if (valid) {
            const param = {
              batch: batch,
              shipDto: {
                orderId: this.editRow.orderId,
                logisticsCompany: this.submitForm_2.logisticsCompany,
                logisticsCode: this.submitForm_2.logisticsCode,
                wechatDeliveryMethod: this.submitForm_2.wechatDeliveryMethod
              }
            }

            if (this.detailInfo.orderClassify !== 'external') {
              delete param.shipDto.wechatDeliveryMethod
            }

            this.$http
              .requestPost({
                url: '/customer/order/electronic/stock',
                param: param
              })
              .then((res) => {
                const { data, message } = res
                if (data == false) {
                  return this.$message.error(message)
                }
                if (data) {
                  this.$message.success('出库成功')
                  this.searchList()
                  this.dialogVisible = false
                }
              })
          } else {
            return false
          }
        })
      }
    },

    // ================ 选择批次 ================
    // 页容量改变
    handleSizeChange_batch(size) {
      this.pageParam_batch.pageNum = 1
      this.pageParam_batch.pageSize = size
      // 刷新(数据)
      this.getBatchList()
    },
    // 页码改变
    handleCurrentChange_batch(page) {
      this.pageParam_batch.pageNum = page
      // 刷新(页码)
      this.getBatchList()
    },
    // 批次列表查询
    searchList_batch() {
      this.pageParam_batch.pageNum = 1
      this.getBatchList()
    },
    // 获取批次列表
    getBatchList() {
      const data = {
        pageNum: this.pageParam_batch.pageNum,
        pageSize: this.pageParam_batch.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire_batch))
      }

      // 获取要筛选掉的批次id
      const len = this.detailInfo.items[this.currentIndex].batchList.length
      if (len > 0) {
        const batchList = this.detailInfo.items[this.currentIndex].batchList
        data.param.batchIds = batchList.map((v) => v.batchId)
      }

      this.$http
        .requestPost({
          url: '/customer/order/pageMerchantBatch',
          param: data,
          loading: false
        })
        .then((res) => {
          const { list, total } = res.data
          list.map((v) => {
            const d = new Date(v.expireTime)
            const timeStamp = d - new Date(v.produceTime)
            const termOfValidity = timeStamp / (24 * 60 * 60 * 1000)

            v.termOfValidity = termOfValidity.toFixed(0)
          })
          this.batchTable = list
          this.pageParam_batch.total = total
        })
    },
    // 选择批次
    handleBatchChange(e) {
      this.selectBatch = e
    },
    // 确定批次的选择
    confirmBatchSelect() {
      const arr = JSON.parse(JSON.stringify(this.selectBatch))
      arr.map(v => v.num = 0)
      this.detailInfo.items[this.currentIndex].batchList.push(...arr)
      this.$refs.batchTable && this.$refs.batchTable.toggleRowSelection()
      this.dialogVisible_batch = false
    },

    // ================ 其它 ================
    // 获取产品类型
    getProductType() {
      this.$http.requestGet({ url: '/product/category', loading: false }).then(res => {
        this.productTypeList = res.data
      })
    },
    // 产品分类改变
    categoryChange(e) {
      this.productList = []
      this.inquire.productIds = []
      if (e.length > 1) {
        this.getProductList(e[1])
      }
    },
    // 根据分类id获取产品列表
    getProductList(id) {
      this.$http.requestGet({ url: '/customer/order/category/' + id, loading: false }).then((res) => {
        this.productList = res.data
      })
    },

    // 获取区域级联列表
    getDistrictIdList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then((res) => {
        this.addressList = res.data
      })
    },

    // ==================== 导入导出 start ====================
    // 导入之前
    beforeUpload() {
      this.importOrExportData.importStatus = 3
    },
    // 导入失败
    uploadError(response, file, fileList) {
      if (response.code !== 200) {
        this.importOrExportData.importStatus = 2
        this.$message.error('上传失败')
      }
      this.importOrExportData.fileList = []
    },
    // 导入待出库订单模板
    importFile(response, file, fileList) {
      // 文件下载地址
      const fileUrl = response.data
      const myHeaders = new Headers({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/plain'
      })

      // 发起 Fetch 请求
      fetch(fileUrl, { method: 'GET', headers: myHeaders, mode: 'cors' })
        .then(response => response.blob())
        .then(blob => {
          const fileName = `结果反馈表格`
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)// 将图片的src赋值给a节点的href
          a.download = fileName // 下载的文件名称
          a.click()
          a.remove()

          this.importOrExportData.importStatus = 1
          this.$message.success('上传成功！')
          this.importOrExportData.fileList = []
          this.getTableList()
        })
        .catch(error => console.error('下载失败：', error))
    },

    // 导出待出库订单
    exportTableData() {
      const requestUrl = '/customer/order/exportOutOrder'
      let fileName = `待导出_${new Date().getTime()}`
      this.$http.download({
        url: requestUrl,
        param: { ...this.inquire },
        method: 'POST'
      }).then(res => {
        console.log('download_res==>', res)
        if (res.headers['content-disposition']) {
          fileName = res.headers['content-disposition']
          const utfReg = /^([\x00-\x7F]|[\xC2-\xDF][\x80-\xBF]|\xE0[\xA0-\xBF][\x80-\xBF]|[\xE1-\xEC\xEE\xEF][\x80-\xBF]{2}|\xED[\x80-\x9F][\x80-\xBF]|\xF0[\x90-\xBF][\x80-\xBF]{2}|[\xF1-\xF3][\x80-\xBF]{3}|\xF4[\x80-\x8F][\x80-\xBF]{2})*$/g
          if (utfReg.test(fileName)) {
            fileName = this.$public.revertUTF8(fileName)
          }
        }
        this.$public.downloadFile(res, fileName)
      }).catch(err => {
        this.$message.error(err)
        console.error('导出失败：', err)
      })
    },

    orderClassifyText(text) {
      const textObj = {
        electronic: '电商品',
        medicine: '医品',
        external: '经销产品'
      }
      return textObj[text] || '租赁品'
    }

    // ==================== 导入导出 end =========================
  }
}
</script>

<style lang="scss" scoped>
.table-box {
  display: initial;
}

.img-box {
  width: 58px;
  height: 58px;
}

.icon_box {
  width: 21px;
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  &.no_img{
    margin-right: 3px;
    position: static;
  }
}

.txt_box {
  position: relative;
  line-height: 1;
}
</style>
