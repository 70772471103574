<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="地址名称">
          <el-input v-model="inquire.addressName" placeholder="地址名称" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增退货地址</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="addressName" label="地址名称" min-width="160" show-overflow-tooltip />
            <el-table-column prop="addressDetail" label="退货地址" min-width="200" show-overflow-tooltip />
            <el-table-column prop="contacts" label="联系人" min-width="100" />
            <el-table-column prop="phoneNumber" label="手机" min-width="160" />
            <el-table-column prop="whetherDefault" label="默认退货地址" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.whetherDefault ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="添加时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="200" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span v-show="!scope.row.whetherDefault" class="operation-btn_partition"> / </span>
                <span
                  v-show="!scope.row.whetherDefault"
                  class="operation-btn"
                  @click="handleDel(scope.row)"
                >删除</span>
                <span v-show="!scope.row.whetherDefault" class="operation-btn_partition"> / </span>
                <span
                  v-show="!scope.row.whetherDefault"
                  class="operation-btn"
                  @click="stateSwitch(scope.row)"
                >设为默认</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加弹框 -->
    <el-dialog
      :title="dialogTitle + '退货地址'"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="650px"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="120px" :model="submitForm" :rules="submitRules">
          <el-form-item label="地址名称" prop="addressName">
            <el-input v-model="submitForm.addressName" maxlength="20" placeholder="地址名称" />
          </el-form-item>
          <el-form-item label="退货地址" prop="addressDetail">
            <el-input v-model="submitForm.addressDetail" maxlength="30" placeholder="退货地址" />
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input v-model="submitForm.contacts" maxlength="10" placeholder="联系人" />
          </el-form-item>
          <el-form-item label="手机号" prop="phoneNumber">
            <el-input v-model="submitForm.phoneNumber" maxlength="11" placeholder="手机号" />
          </el-form-item>
          <el-form-item v-show="isAdd" label="默认退货地址">
            <el-checkbox v-model="submitForm.whetherDefault" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
    data() {
        return {
            // 查询参数
            inquire: {
                addressName: ''
            },
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            loading: false,
            // 表格数据
            tableData: [],

            // 添加
            dialogTitle: '', // 添加弹窗标题
            dialogVisible: false, // 添加弹窗
            submitForm: {
                addressName: '',
                addressDetail: '',
                contacts: '',
                phoneNumber: '',
                whetherDefault: false
            },
            submitRules: {
                addressName: [{ required: true, message: '请输入地址名称', trigger: 'blur' }],
                addressDetail: [{ required: true, message: '请输入退货地址', trigger: 'blur' }],
                contacts: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
                phoneNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' },
                    { pattern: /^1[3456789]\d{9}$/, message: '手机格式错误', trigger: 'blur' }
                ]
            },
            isAdd: true, // 是否为添加
            editRow: {}

        }
    },
    created() {
        // 获取数据列表
        this.getTableList()
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size
            // 刷新(数据)
            this.getTableList()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page
            // 刷新(页码)
            this.getTableList()
        },
        // 刷新
        searchList() {
          this.pageParam.pageNum = 1
          this.getTableList()
        },

        // 获取数据列表
        getTableList() {
            const data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            }

            this.loading = true
            this.$http.requestPost({
                url: '/system/return/address/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false
                this.tableData = res.data.list
                this.pageParam.total = res.data.total
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },

        // 添加
        addition() {
            this.dialogTitle = '新增'
            this.dialogVisible = true
            this.isAdd = true
        },
        // 修改
        handleEdit(row) {
            this.dialogTitle = '编辑'
            this.isAdd = false
            this.$http.requestGet({ url: '/system/return/address/get/' + row.addressId, loading: false }).then(res => {
                const data = res.data
                this.submitForm = {
                    addressName: data.addressName,
                    addressDetail: data.addressDetail,
                    contacts: data.contacts,
                    phoneNumber: data.phoneNumber,
                    whetherDefault: data.whetherDefault
                }
            })
            this.dialogVisible = true
            this.editRow = row
        },
        // 提交办事处添加
        submitAdd() {
            this.$refs.submitForm.validate(valid => {
                if (valid) {
                    const param = {
                        addressName: this.submitForm.addressName,
                        addressDetail: this.submitForm.addressDetail,
                        contacts: this.submitForm.contacts,
                        phoneNumber: this.submitForm.phoneNumber,
                        whetherDefault: this.submitForm.whetherDefault
                    }
                    if (!this.isAdd) {
                        param.addressId = this.editRow.addressId
                    }
                    this.submit(param)
                } else {
                    return false
                }
            })
        },
        // 添加，修改提交
        submit(param) {
            this.$http.requestPost({
                url: '/system/return/address/save',
                param: param
            }).then(res => {
                this.getTableList()
                this.$message({
                    message: '操作成功',
                    type: 'success'
                })
                this.closeDialog()
            })
        },

        // 关闭弹窗
        closeDialog() {
            this.$refs.submitForm && (this.$refs.submitForm.resetFields())
            this.dialogVisible = false
            this.submitForm = {
                addressName: '',
                addressDetail: '',
                contacts: '',
                phoneNumber: '',
                whetherDefault: false
            }

            this.dialogVisible_2 && (this.dialogVisible_2 = false)
        },

        // 删除
        handleDel(row) {
            this.$confirm('确定删除该物流公司?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
                    this.pageParam.pageNum--
                }
                this.$http.requestDel({ url: '/system/return/address/del/' + row.addressId }).then(res => {
                    this.$message.success('操作成功')
                    this.getTableList()
                })
            }).catch(err => {
            })
        },

        // ============ 设为默认 ============
        stateSwitch(row) {
            this.$confirm('确定将该公司设置为默认物流公司?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$http.requestGet({ url: '/system/return/address/default/' + row.addressId }).then(res => {
                    this.$message.success('操作成功')
                    this.getTableList()
                })
            }).catch(err => {
            })
        }

    }
}
</script>

<style lang="scss" scoped>
.address-list {
    flex-wrap: wrap;

    & > div {
        display: inline-flex;
    }
}

.text {
    padding: 0 5px;
}

.text-left {
    min-width: 125px;
    text-align: right;
}
</style>
