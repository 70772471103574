<template>
  <el-dialog :visible.sync="visible" :title="groupDialogTitle" :close-on-click-modal="false"
             width="550px" top="10vh"
  >
    <div class="Popout_content">
      <el-form ref="submitFormRef" label-width="100px" :model="submitForm" :rules="ruleFormRules">
        <el-form-item label="分组名称" prop="categoryName">
          <el-input v-model="submitForm.categoryName" placeholder="请输入分组名称" show-word-limit maxlength="30"/>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button :loading="groupButtonLoading" @click="visible = false">取 消</el-button>
        <el-button :loading="groupButtonLoading" type="primary" @click="groupSubmitAddOrEdit">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import { saveOrUpdateTalentCategory } from '@/api/user'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    editForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      groupButtonLoading: false,
      submitForm: {},
      groupDialogTitle: '新增分组'
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },

    ruleFormRules() {
      return {
        categoryName: [{ required: true, message: '请输入分组名称', trigger: 'blur' }]
      }
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.groupDialogTitle = this.editForm.id ? '编辑分组' : '新增分组'
        this.submitForm = { ...this.editForm }
      } else {
        this.groupCloseDialog()
      }
    }
  },

  methods: {
    // 关闭弹窗-重置
    groupCloseDialog() {
      this.groupDialogTitle = '新增分组'
      this.submitForm = {
        groupName: ''
      }
      this.$refs.submitFormRef && this.$refs.submitFormRef.resetFields()
    },
    // 新增或编辑人员
    groupSubmitAddOrEdit() {
      this.$refs.submitFormRef.validate(async(valid) => {
        if (!valid) return false
        const param = {
          categoryName: this.submitForm.categoryName
        }
        // 编辑必传id
        if (this.submitForm.id) {
          param.id = this.submitForm.id
        }
        this.groupButtonLoading = true
        const res = await saveOrUpdateTalentCategory(param)
        this.groupButtonLoading = false
        if (!res || res.errorCode) {
          this.$message.error(res.data.description)
          return
        }
        this.$message.success('操作成功')
        this.visible = false
        this.$emit('edit-done')
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
