var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c("el-form-item", { attrs: { label: "产品名称" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "输入产品名称" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.inquire.productName,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "productName", $$v)
                        },
                        expression: "inquire.productName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品类别" } },
                [
                  _c("el-cascader", {
                    attrs: { props: _vm.props, options: _vm.productTypeList },
                    model: {
                      value: _vm.inquire.productCategoryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "productCategoryId", $$v)
                      },
                      expression: "inquire.productCategoryId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "价格" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.inquire.productPriceMin,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "productPriceMin", $$v)
                        },
                        expression: "inquire.productPriceMin",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "span-link" }, [_vm._v(" - ")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.inquire.productPriceMax,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "productPriceMax", $$v)
                        },
                        expression: "inquire.productPriceMax",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "销量" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.inquire.productSalesMin,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "productSalesMin", _vm._n($$v))
                        },
                        expression: "inquire.productSalesMin",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "span-link" }, [_vm._v(" - ")]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.inquire.productSalesMax,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "productSalesMax", _vm._n($$v))
                        },
                        expression: "inquire.productSalesMax",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addProduct },
            },
            [_vm._v("新增产品")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "", height: "400px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "序号", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productCode",
                        label: "产品编号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row, "read")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.productCode))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "产品名称",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productCategoryName",
                        label: "所属类别",
                        "min-width": "160",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "", label: "销售价", "min-width": "160" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" +
                                      scope.row.productPriceMin +
                                      " ~ ￥" +
                                      scope.row.productPriceMax
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productInventoryTotal",
                        label: "总库存",
                        "min-width": "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "hospitalsOnSaleNum",
                        label: "在售医院数",
                        "min-width": "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productOrder",
                        label: "排序",
                        "min-width": "70",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "onSaleTime",
                        label: "上架时间",
                        "min-width": "160",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productStatus",
                        label: "状态",
                        "min-width": "80",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$api.getNeedValue(
                                      _vm.productState,
                                      scope.row.productStatus,
                                      "valueCode",
                                      "valueName"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "300",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row, "edit")
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleRun(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("运营范围")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !scope.row.whetherRecommend,
                                      expression: "!scope.row.whetherRecommend",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.recommend(scope.row, "Y")
                                    },
                                  },
                                },
                                [_vm._v("推荐")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.whetherRecommend,
                                      expression: "scope.row.whetherRecommend",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.recommend(scope.row, "N")
                                    },
                                  },
                                },
                                [_vm._v("取消推荐")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.openManageMerchants(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("管理商家")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.UnShelve(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下架")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCity
        ? _c("operatingCity", {
            attrs: { "show-city": _vm.showCity },
            on: { setCityValue: _vm.getCityValue },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("manageMerchants", { ref: "manageMerchants" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }