<template>
  <!-- 租赁品-订单详情 -->
  <div class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>

    <!-- <div class="steps-box bg_white">
          <el-steps :active="active">
            <el-step
              v-for="item in steps"
              :key="item.title"
              :title="item.title"
              :description="item.time"
            ></el-step>
          </el-steps>
        </div> -->

    <div class="row_2 bg_white flex-box">
      <div class="row_left">
        <h4>配送信息</h4>
        <p class="color_light-black">配送方式：{{ $api.getValue($api.deliveryMethod, pageData.deliveryMethod, 'name') }}</p>
        <p class="color_light-black">收货人姓名：{{ pageData.recipient }}</p>
        <p class="color_light-black">收货人手机号：{{ pageData.recipientPhoneNumber }}</p>
        <p class="color_light-black">
          收货人地址：
          <span>{{ pageData.recipientAddressDetail }}</span>
          <el-button
            v-if="pageData.orderStatus === 'pending_payment' || pageData.orderStatus === 'pending_ship'"
            style="padding: 0"
            type="text"
            @click="dialogVisible_4 = true"
          >
            修改
          </el-button>
        </p>
        <!-- <p v-if="!isBuyOut" class="color_light-black">上门安装时间：<span class="color_red">{{
          pageData.serviceTime
        }}</span></p> -->
        <p v-if="isBuyOut" style="height: 12px"></p>
        <p class="color_light-black">备注信息：{{ pageData.orderRemark || '无' }}</p>
      </div>

      <div class="row_right">
        <h4>
          订单状态：
          <span>{{ $api.getValue($api.leaseOrderState, pageData.orderStatus, 'name') }}</span>
        </h4>
        <div v-if="pageData.orderStatus == 'pending_payment'">
          <!-- <el-button size="medium" type="primary" @click="amendPrice">修改价格</el-button> -->
          <el-button plain size="medium" @click="cancelOrder">取消订单</el-button>
        </div>
        <div v-else-if="pageData.orderStatus == 'pending_ship'">
          <el-button plain size="medium" @click="sendOut">发货</el-button>
        </div>
        <div v-else-if="pageData.orderStatus == 'pending_receipt' && !isBuyOut">
          <p class="color_light-black">起租时间：{{ '' }}</p>
          <p style="height: 12px"></p>
          <p class="color_light-black">到期时间：{{ '' }}</p>
          <p style="height: 12px"></p>
          <el-button plain size="medium" @click="rent">展期</el-button>
        </div>
      </div>
    </div>

    <div class="row_3 bg_white">
      <h4>商品信息</h4>
      <div class="table">
        <el-table :data="pageData.items" border style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="220" prop="available" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-box">
                <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                <div class="hospital-name">{{ scope.row.productName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="租赁单价" min-width="120" prop="productPrice">
            <template slot-scope="scope">
              {{ scope.row.leaseTime ? '￥' + scope.row.productPrice : '/' }}
            </template>
          </el-table-column>
          <el-table-column label="商品单价" min-width="120" prop="productPrice">
            <template slot-scope="scope">
              {{ scope.row.leaseTime ? '/' : '￥' + scope.row.productPrice }}
            </template>
          </el-table-column>
          <el-table-column label="租赁时长" min-width="120" prop="leaseTime">
            <template slot-scope="scope">
              <div v-if="scope.row.leaseTime">{{ scope.row.leaseTime }}</div>
              <div v-else>/</div>
            </template>
          </el-table-column>
          <el-table-column label="租赁区间" min-width="200" prop="">
            <template slot-scope="scope">
              <div v-if="scope.row.leaseTime">{{ scope.row.leaseTimeBegin + ' ~ ' + scope.row.leaseTimeEnd }}</div>
              <div v-else>/</div>
            </template>
          </el-table-column>
          <el-table-column label="服务时间" min-width="100" prop="serviceTime" />
          <el-table-column label="提成比例" min-width="100" prop="brokerageRate" />
          <el-table-column label="提成金额" min-width="100" prop="paidAmount">
            <template slot-scope="scope">
              {{
                scope.row.leaseTime
                  ? '￥' + (scope.row.productPrice * scope.row.leaseTime * (scope.row.brokerageRate / 100)).toFixed(2)
                  : '￥' + (scope.row.productPrice * (scope.row.brokerageRate / 100)).toFixed(2)
              }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="show-money">
        <ul>
          <li>
            商品金额：
            <span class="block_span">￥ {{ (goodsMoney || 0).toFixed(2) }}</span>
          </li>
          <li>
            运费：
            <span class="block_span">+￥ {{ (pageData.shippingFee || 0).toFixed(2) }}</span>
          </li>
          <li>
            押金：
            <span class="block_span">+￥ {{ (pageData.depositPrice || 0).toFixed(2) }}</span>
          </li>
          <li v-if="pageData.orderStatus == 'completed'">
            已退押金：
            <span class="block_span">-￥ {{ (pageData.depositPriceReturned || 0).toFixed(2) }}</span>
          </li>
          <li class="line-box"></li>
          <li class="bold">
            总额：
            <span class="block_span color_red">
              ￥
              {{
                goodsMoney + pageData.shippingFee
                  ? (goodsMoney + pageData.shippingFee + (pageData.depositPrice || 0) - (pageData.depositPriceReturned || 0)).toFixed(2)
                  : '0.00'
              }}
            </span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row_4 bg_white">
      <h4>商家/医院信息</h4>
      <p class="color_light-black">
        <span class="title-box">商家名称：</span>
        {{ pageData.merchantName }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家编号：</span>
        {{ pageData.merchantCode }}
      </p>
      <p class="color_light-black">
        <span class="title-box">医院名称：</span>
        {{ pageData.hospitalName }}
      </p>
      <p class="color_light-black">
        <span class="title-box">医院编号：</span>
        {{ pageData.hospitalCode }}
      </p>
    </div>

    <div class="row_4 bg_white">
      <h4>订单信息</h4>
      <p class="color_light-black">
        <span class="title-box">订单类型：</span>
        {{ $api.getValue($api.orderClassify, pageData.orderClassify, 'name') }}
      </p>
      <p class="color_light-black">
        <span class="title-box">订单编号：</span>
        {{ pageData.orderCode }}
      </p>
      <p class="color_light-black">
        <span class="title-box">下单时间：</span>
        {{ pageData.orderTime }}
      </p>
      <p
        v-if="
          pageData.orderStatus !== 'pending_payment' &&
            pageData.orderStatus !== 'expired' &&
            pageData.orderStatus !== 'pending_evaluate' &&
            pageData.orderStatus !== 'completed' &&
            pageData.orderStatus !== 'cancel'
        "
        class="color_light-black"
      >
        <span class="title-box">付款时间：</span>
        {{ pageData.paymentTime }}
      </p>
      <p v-if="pageData.orderStatus == 'pending_receipt' && pageData.orderStatus == 'leased'" class="color_light-black">
        <span class="title-box">发货时间：</span>
        {{ pageData.shipTime }}
      </p>
      <!-- <p v-if="isBuyOut" class="color_light-black"><span class="title-box">确认收货时间：</span>{{
        pageData.receiptTime
      }}</p> -->
    </div>

    <div class="row_5 row_4 bg_white">
      <h4>专员信息</h4>
      <p class="color_light-black">
        <span class="title-box">专员姓名：</span>
        {{ pageData.attacheName || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">专员编号：</span>
        {{ pageData.attacheCode || '无' }}
      </p>
    </div>
    <div class="row_5 row_4 bg_white">
      <h4>业务员信息</h4>
      <p class="color_light-black">
        <span class="title-box">业务员姓名：</span>
        {{ pageData.intendantName || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">业务员编号：</span>
        {{ pageData.intendantCode || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">二维码编号：</span>
        {{ pageData.qrCodeSerial || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家端名称：</span>
        {{ pageData.qrMerchantName || '无' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家端编号：</span>
        {{ pageData.qrMerchantCode || '无' }}
      </p>
    </div>

    <!-- 弹框-修改价格 -->
    <!-- <el-dialog title="修改价格" :visible.sync="dialogVisible_1" width="70%">
          <div class="Popout_content">
            <div class="table">
              <el-table :data="tableData_1" border style="width: 100%">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
                </el-table-column>
                <el-table-column prop="available" label="产品信息" min-width="120">
                  <template> </template>
                </el-table-column>
                <el-table-column
                  prop="available"
                  label="单价"
                  min-width="100"
                ></el-table-column>
                <el-table-column
                  prop="available"
                  label="数量"
                  min-width="100"
                ></el-table-column>
                <el-table-column
                  prop="available"
                  label="小计"
                  min-width="100"
                ></el-table-column>
                <el-table-column prop="available" label="加价/减价" min-width="120">
                  <template>
                    <div class="flex-box flex-center">
                      ￥
                      <el-input
                        v-model="tableData_1.input"
                        placeholder=""
                      ></el-input>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="available"
                  label="实收金额"
                  min-width="100"
                ></el-table-column>
              </el-table>
            </div>
            <div class="show-money">
              <ul>
                <li>商品金额：<span class="block_span">￥200.00</span></li>
                <li>运费：<span class="block_span">+￥20.00</span></li>
                <li>
                  加价/减价：<span class="block_span color_red">-￥20.00</span>
                </li>
                <li class="line-box"></li>
                <li class="bold">
                  实付总额：<span class="block_span color_red">￥180.00</span>
                </li>
              </ul>
            </div>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible_1 = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible_1 = false"
              >确 定</el-button
            >
          </span>
        </el-dialog> -->

    <!-- 弹框-展期 -->
    <el-dialog :visible.sync="dialogVisible_3" title="展期" width="500px">
      <div class="Popout_content">
        <el-form ref="submitForm_3" :model="submitForm_3" :rules="submitRules_3" label-width="80px" size="medium">
          <el-form-item label="订单编号">
            <el-input v-model="submitForm_3.name" disabled placeholder="订单编号" />
          </el-form-item>

          <el-form-item label="到期时间">
            <el-date-picker v-model="submitForm_3.name" class="width-inherit" disabled placeholder="选择日期" type="date" />
          </el-form-item>

          <el-form-item label="展期时间" prop="name">
            <el-date-picker v-model="submitForm_3.name" class="width-inherit" placeholder="选择日期" type="date" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_3 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_3 = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改收货地址 -->
    <el-dialog :visible.sync="dialogVisible_4" :before-close="closeAddresCPM" title="修改收货地址" width="600px">
      <div class="Popout_content">
        <el-form ref="changeAddressForm" :model="changeAddressForm" :rules="changeAddressRules" label-width="80px">
          <el-form-item label="所在地址" prop="addressId">
            <el-cascader
              v-model="changeAddressForm.addressId"
              :props="props_add"
              :options="addressList_add"
              placeholder="请选项地址"
              class="width-inherit"
            />
          </el-form-item>

          <el-form-item label="地址详情" prop="address">
            <el-input v-model="changeAddressForm.address" type="textarea" maxlength="150" placeholder="地址详情" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAddresCPM">取 消</el-button>
        <el-button type="primary" @click="amendAddress">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ids: null,
      // 步骤条
      steps: [],
      active: 0,
      // 订单类型
      // orderType: ["待付款", "待发货", "已发货", "已完成", "已取消"],
      // 非已取消订单
      // stepsL_1: [
      //     { title: '买家下单', time: '' },
      //     { title: '买家付款', time: '' },
      //     { title: '商家发货', time: '' },
      //     { title: '租赁中', time: '' },
      //     { title: '已到期', time: '' },
      //     { title: '已评论', time: '' },
      //     { title: '已完成', time: '' }
      // ],
      // // 买断订单
      // stepsL_2: [
      //     { title: '买家下单', time: '' },
      //     { title: '买家付款', time: '' },
      //     { title: '商家发货', time: '' },
      //     { title: '已买断', time: '' },
      //     { title: '已评论', time: '' },
      //     { title: '已完成', time: '' }
      // ],

      // 商品信息
      pageData: {},
      goodsMoney: 0,

      //  ============= 代付款 =============
      // 修改价格
      // dialogVisible_1: false,
      // tableData_1: [],

      //  ============= 租赁中 =============
      dialogVisible_3: false,
      submitForm_3: {
        //
        name: ''
      },
      submitRules_3: {},

      //  ============= 已买断 =============
      isBuyOut: false,

      // ============= 修改收货地址 =============
      addressList_add: [],
      props_add: {
        label: 'addressName',
        value: 'addressName',
        children: 'child'
        // checkStrictly: true,
        // emitPath: false
      },
      dialogVisible_4: false,
      changeAddressForm: {
        addressId: [],
        address: ''
      },
      changeAddressRules: {
        addressId: [{ required: true, message: '请选择所在地址', trigger: 'change' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.ids = this.$route.query.id
    // 判断当前订单状态
    this.active = parseInt(this.$route.query.type) - 1
    // if (this.active < 5 || this.active > 5) {
    //     this.steps = this.stepsL_1
    // } else if (this.active === 5) {
    //     this.active = this.active - 2
    //     this.isBuyOut = true
    //     this.steps = this.stepsL_2
    // }

    this.getOrderInfo()
    this.getAddress()
  },
  mounted() {},
  methods: {
    // 获取省市区
    getAddress() {
      this.$http.requestGet({ url: '/common/address', loading: false }).then(res => {
        this.addressList_add = res.data
      })
    },

    // 获取详情数据
    getOrderInfo() {
      this.$http.requestGet({ url: '/customer/order/get/' + this.ids }).then(res => {
        this.pageData = res.data
        this._statisticsOrderCode(this.pageData.orderCode)
        this.goodsMoney = this.pageData.items.reduce((num, cur) => {
          return cur.leaseTime ? (num += cur.productPrice * cur.leaseTime) : cur.productPrice
        }, 0)

        if (this.pageData.orderStatus === 'buyout') {
          this.isBuyOut = true
        }

        // this.stepsL_1[0].time = this.pageData.orderTime
        // this.stepsL_1[1].time = this.pageData.paymentTime
        // this.stepsL_1[2].time = this.pageData.shipTime
        // this.stepsL_1[3].time = this.pageData.shipTime
        // this.stepsL_1[4].time = this.pageData.expireTime
        // this.stepsL_1[5].time = this.pageData.evaluateTime
        // this.stepsL_1[6].time = this.pageData.completeTime

        // this.stepsL_1[0].time = this.pageData.orderTime
        // this.stepsL_1[1].time = this.pageData.paymentTime
        // this.stepsL_1[2].time = this.pageData.shipTime
        // this.stepsL_1[3].time = this.pageData.buyoutTime
        // this.stepsL_1[4].time = this.pageData.evaluateTime
        // this.stepsL_1[5].time = this.pageData.completeTime
      })
    },

    //  ========================== 代付款 ==========================
    // 修改价格
    amendPrice() {
      this.dialogVisible_1 = true
    },

    //  ========================== 代发货 ==========================
    // 发货
    sendOut() {
      this.$confirm('确定已发货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http.requestPut({ url: '/customer/order/lease/ship/' + this.pageData.orderId }).then(res => {
            this.$message.success('操作成功！')
            this.getOrderInfo()
          })
        })
        .catch(() => {})
    },

    // =========================== 租赁中 ==========================
    rent() {
      this.dialogVisible_3 = true
    },

    //  ========================== 修改发货地址 ==========================
    amendAddress() {
      this.$refs.changeAddressForm.validate(valid => {
        if (valid) {
          const param = {
            address: this.changeAddressForm.addressId.join('') + this.changeAddressForm.address,
            orderId: this.pageData.orderId
          }
          this.$http
            .requestPut({
              url: '/customer/order/changeAddress',
              param
            })
            .then(res => {
              this.$message.success('操作成功！')
              this.closeAddresCPM()
              this.getOrderInfo()
            })
        } else {
          return false
        }
      })
    },
    // 关闭发货地址
    closeAddresCPM() {
      this.changeAddressForm = {
        addressId: '',
        address: ''
      }
      this.dialogVisible_4 = false
    },

    //  ========================== 取消订单 ==========================
    cancelOrder() {
      this.$http.requestPut({ url: '/customer/order/cancel/' + this.pageData.orderId }).then(res => {
        if (res.data) {
          this.$message.success('操作成功')
          this.getOrderInfo()
        }
      })
    },
    // 埋点
    _statisticsOrderCode(id) {
      this.$http
        .requestPost({
          url: `/listenerLog/orderDetails?orderCode=${id}`,
          loading: false
        })
        .then(res => {})
        .catch(err => {
          console.log('【埋点失败】', err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;

  & >>> .steps-box {
    padding: 30px 40px;
    margin-bottom: 10px;

    .el-step__head.is-process {
      border-color: #429fff;
      color: #429fff;
    }

    .el-step__title.is-process {
      color: #429fff;
    }

    .el-step__description.is-process {
      color: #429fff;
    }
  }

  .row_2 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    .row_left {
      width: 60%;

      & p {
        padding: 5px 0;
      }

      .color_wathet {
        cursor: pointer;
      }
    }

    .row_right {
      width: 40%;
    }

    h4 {
      padding-bottom: 10px;
    }

    .color_light-black {
      font-size: 15px;
    }
  }

  .row_3 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    & h4 {
      padding-bottom: 10px;
    }
  }

  .row_4 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;
    font-size: 15px;

    & h4 {
      padding-bottom: 10px;
    }

    & p {
      padding-top: 5px;
    }
  }
}

.title-box {
  display: inline-block;
  width: 116px;
  text-align: right;
}

.show-money {
  padding: 10px 0;

  ul {
    li {
      display: flex;
      justify-content: flex-end;
      line-height: 30px;
      font-size: 16px;
    }

    .line-box {
      margin-left: 70%;
      padding-top: 20px;
      border-bottom: 1px solid #ccc;
    }

    .bold {
      font-weight: bolder;
    }
  }
}
/deep/.el-table tbody tr:hover > td {
  background-color: #ffffff !important;
}
</style>
