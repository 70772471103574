var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box", attrs: { id: "main-scroll" } },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav-bar bg_white" },
        _vm._l(_vm.navList, function (item, index) {
          return _c(
            "a",
            {
              key: item.id,
              class: { active: item.active },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.skip(index)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_1" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "basicRef",
                attrs: {
                  model: _vm.basicInfo,
                  rules: _vm.basicInfoRules,
                  "label-width": "100px",
                  size: "medium",
                  disabled: _vm.check,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "name" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "名称", prop: "activityName" } },
                      [
                        _c("el-input", {
                          staticClass: "inputW",
                          attrs: {
                            disabled: _vm.edit,
                            placeholder: "请输入名称",
                            maxlength: "10",
                            "show-word-limit": "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.basicInfo.activityName,
                            callback: function ($$v) {
                              _vm.$set(_vm.basicInfo, "activityName", $$v)
                            },
                            expression: "basicInfo.activityName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text" }, [
                      _vm._v("不在用户端表达，仅用于管理员区分活动"),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "活动时间", prop: "useDate" } },
                  [
                    _c(
                      "div",
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetimerange",
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            format: "yyyy-MM-dd HH:mm:ss",
                            disabled: _vm.edit,
                            "range-separator": " - ",
                            "picker-options": _vm.pickerOptions,
                            "start-placeholder": "开始时间",
                            "end-placeholder": "结束时间",
                            clearable: false,
                            "default-time": ["00:00:00", "23:59:59"],
                          },
                          on: { change: _vm.useDateChange },
                          model: {
                            value: _vm.basicInfo.useDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.basicInfo, "useDate", $$v)
                            },
                            expression: "basicInfo.useDate",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "textColor textColorRules" }, [
                          _vm._v("不得早于当前日期"),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "满赠门槛", required: true } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.edit },
                        model: {
                          value: _vm.basicInfo.mzMK,
                          callback: function ($$v) {
                            _vm.$set(_vm.basicInfo, "mzMK", $$v)
                          },
                          expression: "basicInfo.mzMK",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("金额"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 2 } }, [
                          _vm._v("数量"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_2" } }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "getAddressForm",
                attrs: {
                  model: _vm.getAddressForm.param,
                  "label-width": "100px",
                  size: "medium",
                },
              },
              [
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.check },
                        on: { change: _vm.addressRadioChange },
                        model: {
                          value: _vm.getAddressForm.param.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.getAddressForm.param, "address", $$v)
                          },
                          expression: "getAddressForm.param.address",
                        },
                      },
                      [
                        _c("el-radio", { attrs: { label: 0 } }, [
                          _vm._v("全国"),
                        ]),
                        _vm._v(" "),
                        _c("el-radio", { attrs: { label: 1 } }, [
                          _vm._v("区域"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.addressAttribute
                  ? _c(
                      "div",
                      { staticClass: "form_box" },
                      [
                        _vm.edit && _vm.longHospitalList.length > 0
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "item_box",
                                attrs: {
                                  label: "医院名称",
                                  prop: "hospitalName",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "inputW",
                                  attrs: {
                                    placeholder: "请输入名称",
                                    "show-word-limit": "",
                                    clearable: "",
                                  },
                                  model: {
                                    value:
                                      _vm.getAddressForm.param.hospitalName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.getAddressForm.param,
                                        "hospitalName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "getAddressForm.param.hospitalName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.edit && _vm.longHospitalList.length > 0
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "item_box items_box",
                                attrs: {
                                  label: "商家名称",
                                  prop: "merchantName",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "inputW",
                                  attrs: {
                                    placeholder: "请输入名称",
                                    "show-word-limit": "",
                                    clearable: "",
                                  },
                                  model: {
                                    value:
                                      _vm.getAddressForm.param.merchantName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.getAddressForm.param,
                                        "merchantName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "getAddressForm.param.merchantName",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.edit && _vm.longHospitalList.length > 0
                          ? _c(
                              "el-form-item",
                              {
                                staticClass: "item_box",
                                staticStyle: { height: "23px" },
                                attrs: { label: "所在区域" },
                              },
                              [
                                _c("el-cascader", {
                                  staticClass: "form-select inputW",
                                  attrs: {
                                    props: _vm.props_List,
                                    options: _vm.addressList_add,
                                    placeholder: "请选择所在区域",
                                    clearable: "",
                                    "collapse-tags": "",
                                  },
                                  model: {
                                    value: _vm.getAddressForm.param.addressIds,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.getAddressForm.param,
                                        "addressIds",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "getAddressForm.param.addressIds",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "item_box",
                            class: { edit_item: !_vm.edit },
                          },
                          [
                            _vm.edit
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getHospital(
                                          this.getAddressForm
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("查询\n            ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.check
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delHospital([], "batch")
                                      },
                                    },
                                  },
                                  [_vm._v("批量删除")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.check
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary", size: "small" },
                                    on: { click: _vm.addFullHospitalList },
                                  },
                                  [_vm._v("添加")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.addressAttribute
                  ? _c(
                      "el-main",
                      [
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.productLoading,
                                  expression: "productLoading",
                                },
                              ],
                              ref: "hospitalList",
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.hospitalList,
                                "row-key": function (row) {
                                  return row.hospitalId
                                },
                                border: "",
                                height: "400px",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  type: "selection",
                                  align: "center",
                                  "reserve-selection": true,
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "hospitalCode",
                                  label: "医院编号",
                                  "min-width": "180",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "hospitalName",
                                  label: "医院名称",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "province",
                                  label: "省份",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "city",
                                  label: "城市",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "area",
                                  label: "区/县",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "merchantName",
                                  label: "商家名称",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              !_vm.check
                                ? _c("el-table-column", {
                                    attrs: {
                                      prop: "",
                                      label: "操作",
                                      width: "150",
                                      fixed: "right",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "operation-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delHospital(
                                                        [scope.row],
                                                        "single"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3724643053
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.addressAttribute
                  ? _c(
                      "div",
                      { staticClass: "pagination" },
                      [
                        _c("el-pagination", {
                          attrs: {
                            background: "",
                            "current-page": _vm.getAddressForm.pageNum,
                            "page-sizes": _vm.$api.pageSizes,
                            "page-size": _vm.getAddressForm.pageSize,
                            layout: "total, sizes, prev, pager, next, jumper",
                            total: _vm.addressTotal,
                          },
                          on: {
                            "size-change": _vm.getHospitalSizeChange,
                            "current-change": _vm.getHospitalCurrentChange,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_3" } }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "discountsRef",
                attrs: {
                  model: _vm.getMainProductFrom,
                  "label-width": "100px",
                  size: "medium",
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "商品范围", required: true } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        attrs: {
                          disabled: _vm.check || _vm.edit,
                          value: _vm.productClassify,
                        },
                        on: { input: _vm.productCategory },
                      },
                      _vm._l(_vm.$api.applicableProducts, function (item, ind) {
                        return _c(
                          "el-radio",
                          { key: ind, attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { type: "primary", disabled: _vm.check || _vm.edit },
                    on: { click: _vm.selectMainProduct },
                  },
                  [_vm._v("选择商品\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "el-main",
                  [
                    [
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.mainProductLoading,
                              expression: "mainProductLoading",
                            },
                          ],
                          ref: "mainProductList",
                          staticStyle: { width: "100%" },
                          attrs: {
                            data: _vm.mainProductList,
                            "row-key": function (row) {
                              return row.valueId
                            },
                            border: "",
                            height: "400px",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "序号",
                              type: "index",
                              width: "50",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productCode",
                              label: "产品编号",
                              "min-width": "120",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productName",
                              label: "产品名称",
                              "min-width": "100",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "valueName",
                              label: "产品规格",
                              "min-width": "100",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productCategoryName",
                              label: "产品类别",
                              "min-width": "100",
                            },
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "",
                              label: "产品单价",
                              "min-width": "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                  ￥" +
                                        _vm._s(
                                          scope.row.productPriceMin || 0.01
                                        ) +
                                        " - ￥" +
                                        _vm._s(scope.row.productPriceMax) +
                                        "\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          _c("el-table-column", {
                            attrs: {
                              prop: "productStatus",
                              label: "状态",
                              "min-width": "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$api.getNeedValue(
                                            _vm.productState,
                                            scope.row.productStatus,
                                            "valueCode",
                                            "valueName"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _vm._v(" "),
                          !_vm.check && !_vm.edit
                            ? _c("el-table-column", {
                                attrs: {
                                  prop: "",
                                  label: "操作",
                                  width: "150",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "operation-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.delMainProduct(
                                                    [scope.row],
                                                    "single"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  347527929
                                ),
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pagination" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "current-page": _vm.getMainProductFrom.pageNum,
                        "page-sizes": _vm.$api.pageSizes,
                        "page-size": _vm.getMainProductFrom.pageSize,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.mainProductTotal,
                      },
                      on: {
                        "size-change": _vm.mainProductHandleSizeChange,
                        "current-change": _vm.mainProductHandleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row_box bg_white", attrs: { id: "row_4" } },
        [
          _vm._m(3),
          _vm._v(" "),
          _vm._l(_vm.giftProductList, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "content" },
              [
                _c(
                  "el-form",
                  {
                    ref: "giftsRef",
                    refInFor: true,
                    attrs: {
                      model: _vm.giftFrom,
                      rules: _vm.giftFromRules,
                      "label-width": "100px",
                      size: "medium",
                      disabled: _vm.edit,
                    },
                  },
                  [
                    _c("div", { staticClass: "pulltitle" }, [
                      _c("p", [
                        _vm._v("满赠门槛及内容——层级" + _vm._s(index + 1)),
                      ]),
                      _vm._v(" "),
                      !_vm.check && !_vm.edit
                        ? _c(
                            "p",
                            {
                              staticClass: "del_btn",
                              on: {
                                click: function ($event) {
                                  return _vm.delGiftLevel(index)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "满赠门槛" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            disabled: _vm.check,
                            "show-word-limit": "",
                            clearable: "",
                          },
                          model: {
                            value: item.activityThreshold,
                            callback: function ($$v) {
                              _vm.$set(item, "activityThreshold", $$v)
                            },
                            expression: "item.activityThreshold",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.basicInfo.mzMK == 1 ? "元" : "件")
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "赠品内容", required: true } },
                      [
                        !_vm.check && !_vm.edit
                          ? _c(
                              "span",
                              {
                                staticClass: "setup",
                                on: {
                                  click: function ($event) {
                                    return _vm.openGiftProductPopup(index)
                                  },
                                },
                              },
                              [_vm._v("设置赠品")]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-main",
                      [
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.productLoading,
                                  expression: "productLoading",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: item.products,
                                border: "",
                                height: "400px",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productCode",
                                  label: "产品编号",
                                  "min-width": "180",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productName",
                                  label: "产品名称",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productStatus",
                                  label: "产品状态",
                                  "min-width": "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$api.getNeedValue(
                                                  _vm.productState,
                                                  scope.row.productStatus,
                                                  "valueCode",
                                                  "valueName"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productCategoryName",
                                  label: "产品类别",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "",
                                  label: "产品单价",
                                  "min-width": "80",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n                  ￥" +
                                              _vm._s(
                                                scope.row.productPriceMin ||
                                                  0.01
                                              ) +
                                              " - ￥" +
                                              _vm._s(
                                                scope.row.productPriceMax
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "",
                                  label: "数量",
                                  "min-width": "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            attrs: {
                                              type: "number",
                                              min: 0,
                                              disabled: _vm.check,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.giftMax(
                                                  arguments[0],
                                                  scope.row
                                                    .productInventoryTotal,
                                                  index,
                                                  scope.row.productId
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.giftsNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "giftsNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.giftsNumber",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "productInventoryTotal",
                                  label: "产品库存",
                                  "min-width": "100",
                                },
                              }),
                              _vm._v(" "),
                              !_vm.check && !_vm.edit
                                ? _c("el-table-column", {
                                    attrs: {
                                      prop: "",
                                      label: "操作",
                                      width: "80",
                                      fixed: "right",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "operation-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delGiftProduct(
                                                        [scope.row],
                                                        index,
                                                        scope.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          !_vm.check && !_vm.edit
            ? _c(
                "span",
                { staticClass: "addHierachy", on: { click: _vm.addHierachy } },
                [_vm._v("添加层级")]
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "submit-btn" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", disabled: _vm.check },
              on: { click: _vm.submit },
            },
            [_vm._v("确 定")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { disabled: _vm.check },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取 消")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "未选择产品列表",
            visible: _vm.mainProductVisible,
            "before-close": _vm.mainProductDialogClose,
            width: "70%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.mainProductVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "搜索产品" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入产品名称" },
                        model: {
                          value: _vm.mainProductPopupForm.param.productName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mainProductPopupForm.param,
                              "productName",
                              $$v
                            )
                          },
                          expression: "mainProductPopupForm.param.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品类别" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: _vm.productProps,
                          options: _vm.productTypeData,
                          placeholder: "请选择产品类别",
                          clearable: "",
                        },
                        model: {
                          value: _vm.mainProductPopupForm.param.categoryId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.mainProductPopupForm.param,
                              "categoryId",
                              $$v
                            )
                          },
                          expression: "mainProductPopupForm.param.categoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.getUnassociatedMainProductList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.mainProductPopupLoading,
                      expression: "mainProductPopupLoading",
                    },
                  ],
                  ref: "mainPradoutTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.unassociatedMainProductList,
                    "row-key": function (row) {
                      return row.valueId
                    },
                    "tooltip-effect": "dark",
                    border: "",
                    height: "400px",
                    "highlight-current-row": "",
                  },
                  on: { "selection-change": _vm.mainProductPopupChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      "reserve-selection": true,
                      selectable: function (row) {
                        return row.isThreeFavorable ? false : true
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCode",
                      label: "产品编号",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "valueName",
                      label: "产品规格",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCategoryName",
                      label: "产品类别",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "产品单价", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            ￥" +
                                _vm._s(scope.row.productPriceMin || 0.01) +
                                " - ￥" +
                                _vm._s(scope.row.productPriceMax) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productInventoryTotal",
                      label: "产品库存",
                      "min-width": "80",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.mainProductPopupForm.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.mainProductPopupForm.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.mainProductPopupForm.total,
                    },
                    on: {
                      "size-change": _vm.mainProductPopupSizeChange,
                      "current-change": _vm.mainProductPopupCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.mainProductDialogClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading, type: "primary" },
                  on: { click: _vm.mainProductDialogSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "未选择赠品列表",
            visible: _vm.giftProductPopup,
            "before-close": _vm.giftProductDialogClose,
            width: "70%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.giftProductPopup = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "搜索产品" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入产品名称" },
                        model: {
                          value: _vm.giftProductPopupForm.param.productName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.giftProductPopupForm.param,
                              "productName",
                              $$v
                            )
                          },
                          expression: "giftProductPopupForm.param.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品类别" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: _vm.productProps,
                          options: _vm.productTypeData,
                          placeholder: "请输入产品名称",
                          clearable: "",
                        },
                        model: {
                          value: _vm.giftProductPopupForm.param.categoryId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.giftProductPopupForm.param,
                              "categoryId",
                              $$v
                            )
                          },
                          expression: "giftProductPopupForm.param.categoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.getUnassociatedGiftProductList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.giftProductPopupLoading,
                      expression: "giftProductPopupLoading",
                    },
                  ],
                  ref: "giftProductTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.unassociatedGiftProductList,
                    "row-key": function (row) {
                      return row.productId
                    },
                    "tooltip-effect": "dark",
                    border: "",
                    height: "400px",
                    "header-cell-class-name": "gift_header",
                  },
                  on: { "selection-change": _vm.giftProductPopupChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      width: "55",
                      "reserve-selection": true,
                      selectable: _vm.giftProductSelect,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCode",
                      label: "产品编号",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productName",
                      label: "产品名称",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productStatus",
                      label: "产品状态",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$api.getNeedValue(
                                    _vm.productState,
                                    scope.row.productStatus,
                                    "valueCode",
                                    "valueName"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productCategoryName",
                      label: "产品类别",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "产品单价", "min-width": "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            ￥" +
                                _vm._s(scope.row.productPriceMin || 0.01) +
                                " - ￥" +
                                _vm._s(scope.row.productPriceMax) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "productInventoryTotal",
                      label: "产品库存",
                      "min-width": "80",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.giftProductPopupForm.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.giftProductPopupForm.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.giftProductPopupForm.total,
                    },
                    on: {
                      "size-change": _vm.giftProductPopupSizeChange,
                      "current-change": _vm.giftProductPopupCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.giftProductDialogClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.btnLoading, type: "primary" },
                  on: { click: _vm.giftProductDialogSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增运营范围",
            visible: _vm.hospitalPullVisible,
            "before-close": _vm.hospitalDialogClose,
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.hospitalPullVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                { attrs: { inline: true, "label-position": "right" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "医院名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: { placeholder: "请输入医院名称" },
                        model: {
                          value: _vm.addAddressForm.param.hospitalName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addAddressForm.param,
                              "hospitalName",
                              $$v
                            )
                          },
                          expression: "addAddressForm.param.hospitalName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { "margin-left": "6%" },
                      attrs: { label: "商家端名称：" },
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "260px" },
                        attrs: { placeholder: "请输入商家端名称" },
                        model: {
                          value: _vm.addAddressForm.param.merchantName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addAddressForm.param,
                              "merchantName",
                              $$v
                            )
                          },
                          expression: "addAddressForm.param.merchantName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                { attrs: { inline: true, "label-position": "right" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "所在区域：" } },
                    [
                      _vm.addressList_add.length
                        ? _c("el-cascader", {
                            staticStyle: { width: "260px" },
                            attrs: {
                              props: _vm.props_List,
                              options: _vm.addressList_add,
                              placeholder: "请选项地址",
                              "collapse-tags": "",
                              clearable: "",
                            },
                            model: {
                              value: _vm.addAddressForm.param.addressIds,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addAddressForm.param,
                                  "addressIds",
                                  $$v
                                )
                              },
                              expression: "addAddressForm.param.addressIds",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { staticStyle: { "margin-left": "6%" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.addFullHospitalList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "hospitalPullTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.unassociatedHospitalList,
                    "row-key": function (row) {
                      return row.hospitalId
                    },
                    border: "",
                    height: "400px",
                  },
                  on: { "selection-change": _vm.hospitalPopupChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      align: "center",
                      "reserve-selection": true,
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "医院编号",
                      prop: "hospitalCode",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "医院名称",
                      prop: "hospitalName",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "省份",
                      prop: "province",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "城市", prop: "city", align: "center" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "区/县",
                      prop: "area",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "商家名称",
                      prop: "merchantName",
                      align: "center",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.addAddressForm.pageNum,
                      "page-size": _vm.addAddressForm.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.addAddressForm.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.addHospitalSizeChange,
                      "current-change": _vm.addHospitalCurrentChange,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    "padding-bottom": "20px",
                    "margin-top": "20px",
                    "text-align": "right",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "90px" },
                      on: { click: _vm.hospitalDialogClose },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "90px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.hospitalDialogSubmit },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("基本信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("运营范围")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("主商品管理")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("满赠设置")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }