<!-- @format -->

<!-- 短信信息设置 -->
<template>
  <div class="main-box">
    <!-- 短信设置列表 -->
    <el-main>
      <template>
        <el-table :data="settingDataSource" border align="center" style="width: 50%">
          <el-table-column prop="id" label="序号" width="100" align="center" />
          <el-table-column prop="businessName" label="业务名称" min-width="240" align="center" />
          <el-table-column label="操作" min-width="180" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="handleClick(scope.row)">配置</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-main>

    <!-- 联系人列表弹窗 -->
    <el-dialog title="联系人列表" :visible.sync="contactVisible" :before-close="cancelChange" width="50%" top="5vh">
      <!-- 联系人列表弹窗 -->
      <div class="head-search-row">
        <el-form ref="contactForm" :model="inquire" :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="姓名" prop="contactName">
            <el-input v-model="inquire.contactName" placeholder="请输入" clearable @keyup.enter.native="searchList" />
          </el-form-item>

          <el-form-item label="手机号" prop="contactPhone">
            <el-input v-model="inquire.contactPhone" placeholder="请输入" maxlength="11" clearable @keyup.enter.native="searchList" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="searchList">查询</el-button>
            <el-button type="primary" size="small" @click="receivedByVisible = true">添加接收人</el-button>
            <el-button type="primary" size="small" @click="delReceivedByChange">删除</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="table">
        <el-main>
          <template>
            <el-table
              ref="tableRef"
              v-loading="contactLoading"
              :data="contactDataSource"
              border
              height="250px"
              style="width: 100%"
              :row-key="row => row.id"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="80" align="center" :reserve-selection="true" />
              <el-table-column prop="name" label="接收人" min-width="150" />
              <el-table-column prop="phone" label="手机号" min-width="180" />
              <el-table-column prop="createTime" label="添加时间" min-width="180" />
            </el-table>
          </template>
          <div class="pagination">
            <el-pagination
              background
              :current-page="pageParam.pageNum"
              :page-sizes="$api.pageSizes"
              :page-size="pageParam.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageParam.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-main>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="confirmLoading" @click="confirmContactList">
          确 定
        </el-button>
        <el-button @click="cancelChange">关 闭</el-button>
      </span>
    </el-dialog>

    <!-- 添加接收人弹窗 -->
    <el-dialog title="新增接收人" :visible.sync="receivedByVisible" :before-close="receivedByCancelChange" width="25%" top="3vh">
      <div class="receivedBy">
        <el-form ref="receivedByForm" :model="receivedByForm" :rules="receivedByRules" label-width="80px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="receivedByForm.name" maxlength="10" clearable placeholder="请输入姓名" />
          </el-form-item>

          <el-form-item label="手机号" prop="phone">
            <el-input v-model="receivedByForm.phone" maxlength="11" clearable placeholder="请输入手机号" />
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button :loading="btnLoading" type="primary" @click="submitReceivedBy">
          确 定
        </el-button>
        <el-button @click="receivedByCancelChange">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 短信设置列表
      settingDataSource: [{ id: 1, businessName: '物流配送订单通知' }, { id: 2, businessName: '意见反馈通知' }],
      typeId: '', // 业务类型id

      // 联系人列表弹窗
      contactVisible: false,
      btnLoading: false,
      contactDataSource: [], // 列表数据源
      contactLoading: false,
      inquire: {
        contactName: '',
        contactPhone: null
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      confirmLoading: false,

      // 添加接收人弹窗
      receivedByVisible: false,
      receivedByForm: {
        name: '',
        phone: ''
      },
      receivedByRules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3-9]\d{9}$|^(\d{3,4}-)?\d{7,8}$/, // 手机和座机正则
            message: '请输入正确的手机号格式',
            trigger: ['blur', 'change'] // 可以在失焦或输入时进行验证
          }
        ]
      },
      SelectionArray: [] // table选择的数据
    }
  },

  created() {},

  methods: {
    // 配置事件
    handleClick(row) {
      this.contactVisible = true
      this.typeId = row.id
      this.$nextTick(() => {
        this.searchList()
      })
    },
    // 重置筛选
    resetSiftForm(refName) {
      this.$refs[refName].resetFields()
    },

    // 联系人列表弹窗
    // 查询列表
    async searchList() {
      try {
        this.contactLoading = true
        const params = {
          pageNum: this.pageParam.pageNum,
          pageSize: this.pageParam.pageSize,
          param: {
            type: this.typeId,
            name: this.inquire.contactName,
            phone: this.inquire.contactPhone
          }
        }
        const response = await this.$http.requestPost({
          url: '/systemSms/getList',
          param: params,
          loading: false
        })
        if (response.code == 200) {
          this.contactDataSource = response.data.list
          this.pageParam.total = response.data.total
        }
      } catch (error) {
        console.log('获取数据失败： ', error)
      } finally {
        this.contactLoading = false
      }
    },
    // 分页事件
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.searchList()
    },
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.searchList()
    },

    // 列表选择框选择
    handleSelectionChange(row) {
      // console.log("选择的数据：", row);
      this.SelectionArray = row.map(item => item.id)
    },
    // 联系人弹窗确认事件
    confirmContactList() {
      this.confirmLoading = true
      setTimeout(() => {
        this.confirmLoading = false
        this.contactVisible = false
        this.resetSiftForm('contactForm')
        this.$refs.tableRef.clearSelection() // 清除所有选中行
      }, 800)
    },
    // 关闭联系人列表弹窗事件
    cancelChange() {
      this.contactVisible = false
      this.$refs.tableRef.clearSelection() // 清除所有选中行
      this.resetSiftForm('contactForm')
    },

    // 删除接收人事件
    async delReceivedByChange() {
      if (this.SelectionArray.length < 1) {
        return this.$message.warning('请选择需要删除的联系人数据')
      }
      // 弹出确认对话框
      const confirmResult = await this.$confirm('是否确定删除?', '删除接收人', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })

      if (confirmResult === 'confirm') {
        try {
          const response = await this.$http.requestPost({
            url: '/systemSms/del',
            param: this.SelectionArray || [],
            loading: false
          })
          if (response.code === 200 && response.data) {
            this.searchList()
            this.$message.success('删除成功!')
          }
        } catch (error) {
          console.error('删除失败: ', error)
        }
      } else {
        // 用户取消删除，给出取消提示
        this.$message.warning('取消删除!')
      }
    },

    // 关闭弹窗事件
    receivedByCancelChange() {
      this.receivedByVisible = false
      this.resetSiftForm('receivedByForm')
    },
    // 提交事件
    submitReceivedBy() {
      this.$refs.receivedByForm.validate(async valid => {
        if (valid) {
          try {
            this.btnLoading = true
            const data = {
              ...this.receivedByForm,
              type: this.typeId,
              id: '' // 固定传空（编辑时需要的字段）
            }
            const res = await this.$http.requestPost({
              url: '/systemSms/saveOrUpdate',
              param: data,
              loading: false
            })
            if (res.code === 200 && res.data) {
              this.searchList() // 添加后刷新列表
              this.$message.success('添加成功!')
            }
          } catch (error) {
            console.log('新增接收人失败：', error)
          } finally {
            this.btnLoading = false
            this.receivedByVisible = false
            this.receivedByForm = {}
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.receivedBy {
  width: 80%;
  margin-top: 20px;
}
</style>
