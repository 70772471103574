<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        
        <el-form-item label="操作时间">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="操作内容">
          <el-input v-model="inquire.logContent" placeholder="操作内容" @keyup.enter.native="searchList"></el-input>
        </el-form-item>

        <el-form-item label="操作者">
          <el-input v-model="inquire.logByName" placeholder="操作者" @keyup.enter.native="searchList"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="logTime" label="操作时间" min-width="160"></el-table-column>
            <el-table-column prop="logContent" label="操作内容" min-width="400"></el-table-column>
            <el-table-column prop="logByName" label="操作者" min-width="120"></el-table-column>
            <el-table-column prop="operatingPlatform" label="操作平台" min-width="120"></el-table-column>
            <el-table-column prop="logByTelephone" label="操作者手机号" min-width="120"></el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        createTimeBegin:'',
        createTimeEnd:'',
        logContent: '',
        logByName: '',
      },
      daterange: [],

      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [], 

    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    

    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : '';
      this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : '';
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/system/operation/log/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    
    // 清空数据
    removeData() {
      const h = this.$createElement;
      let num = [2345, 234];
      var msg = `
        系统当前有 ${num[0]} 条数据，共 ${num[1]} M，确认要清空吗？清空数据后，所有数据不可恢复，正常情况下，执行这一操作表现对系统的培训、演示、体验工作结束，要正式上线运营使用了。
        `;
        this.$msgbox({
          title: '系统数据清空',
          message: h('div', null, [
            h('div', null, msg),
            h('div', { style: 'color: #000; font-weight: 600; padding: 20px 0' }, '请再次确认要清空系统数据吗？')
          ]),
          showCancelButton: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              this.$message({
                type: 'info',
                message: 'action: ' + action
              });
            } else {
              done();
            }
          }
        }).then(action => {
          this.$message({
            type: 'info',
            message: 'action: ' + action
          });
        });
    },
    
  },
};
</script>

<style lang="scss" scoped>
  .address-list {
    flex-wrap: wrap;
    
    & > div {
      display: inline-flex;
    }
  }
  .text {
    padding: 0 5px;
  }
  .text-left {
    min-width: 125px;
    text-align: right;
  }
</style>
