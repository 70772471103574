<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="selectKey" placeholder="请选择" @change="selectValue = ''" class="width-140">
              <el-option label="仓库名称" value="name"></el-option>
              <el-option label="仓库编号" value="no"></el-option>
            </el-select>
            <el-input v-model="selectValue" placeholder="" class="width-180" @keyup.enter.native="searchList"></el-input>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
          <!-- <el-button type="primary" size="small" @click="addWarehouse">添加</el-button> -->
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="no" label="仓库编号" min-width="120" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">{{ scope.row.no }}</span>
              </template>
            </el-table-column>
            
            <el-table-column prop="name" label="仓库名称" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="managerName" label="仓管员" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="phone" label="联系方式" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="address" label="地址" min-width="200"></el-table-column>
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
          </div>
        </template>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog title="添加" :visible.sync="dialogVisible" :before-close="closeDialog" width="500px">
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="100px" :model="submitForm" :rules="submitRules">
          <el-form-item label="仓库名称" prop="name">
            <el-input v-model="submitForm.name" placeholder="仓库名称" />
          </el-form-item>
          <el-form-item label="地区" prop="regionId">
            <el-cascader v-model="submitForm.regionId" :props="props_add" :options="addressList_add" placeholder="请选项地址" class="width-inherit" />
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="submitForm.address" type="textarea" :rows="3" maxlength="150" placeholder="详细地址" />
          </el-form-item>
          <el-form-item label="仓管员" prop="managerId">
            <div class="flex-box">
              <span v-if="submitForm.managerId">{{selectStaff.managerName}}&nbsp;&nbsp;{{selectStaff.managerPhoneNumber}}&nbsp;&nbsp;</span>
              <el-button type="primary" size="mini" @click="openManagePopups">选择 </el-button>
            </div>
          </el-form-item>
          <el-form-item label="联系方式" prop="phone">
            <el-input v-model="submitForm.phone" maxlength="11" placeholder="联系方式" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">保 存 </el-button>
      </span>
    </el-dialog>

    <!-- 选择仓管员弹窗 -->
    <el-dialog title="选择" :visible.sync="dialogVisible_staff" width="70%">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="姓名/手机号">
            <el-input v-model="inquire_staff.name" placeholder="姓名/手机号"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="manageSearch">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table :data="tableData_staff" highlight-current-row @current-change="handleStaffChange" v-loading="loading_staff" border height="400px" style="width: 100%">
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{( pageParam_staff.pageNum - 1) * pageParam_staff.pageSize + (scope.$index + 1) }}</template>
          </el-table-column>
          <el-table-column prop="managerName" label="姓名" min-width="140" show-overflow-tooltip></el-table-column>
          <el-table-column prop="managerPhoneNumber" label="手机号" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="" label="权限组" min-width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.managerId == '0'">超级管理员</div>
              <div v-else>{{ scope.row.rightsGroupNames ? scope.row.rightsGroupNames.join() : scope.row.rightsGroupNames }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="accountStatus" label="状态" min-width="120">
            <template slot-scope="scope">
              {{ scope.row.accountStatus === 'normal' ? '启用' : '锁定' }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="添加时间" min-width="160"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination background :current-page="pageParam_staff.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam_staff.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam_staff.total" @size-change="handleSizeChange_staff" @current-change="handleCurrentChange_staff" />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_staff = false">取 消</el-button>
        <el-button type="primary" @click="confirmSelect">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      // 查询参数
      inquire: {
        // name: '',
        // no: ''
      },
      selectKey: "name",
      selectValue: "",
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [],
      commentId: '',// 主键id

      dialogVisible: false, // 添加弹窗
      submitForm: {
        id: '',
        name: '',
        regionId: '',
        address: '',
        managerId: '',
        phone: '',
      },
      submitRules: {
        name: [{ required: true, message: "请输入仓库名称", trigger: "blur" }],
        regionId: [{ required: true, message: "请选择地区", trigger: "change" }],
        address: [{ required: true, message: "请输入详细地址", trigger: "blur" }],
        managerId: [{ required: true, message: "请选择仓管员", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }]
      },
      // 省市区
      addressList_add: [],
      props_add: {
        label: "addressName",
        value: "addressId",
        children: "child",
        emitPath: false,
      },

      // 选择仓管员弹窗
      dialogVisible_staff: false,
      inquire_staff: {
        name: ''
      },
      pageParam_staff: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableData_staff: [],
      loading_staff: false,
      selectStaff: {},
    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
    this.getAddress()
  },
  methods: {
    // ================== 仓库 ==================
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    // 获取数据列表
    getTableList() {
      let param = JSON.parse(JSON.stringify(this.inquire))
      param[this.selectKey] = this.selectValue;
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/warehouse/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log('err', err)
      })
    },

    // 添加仓库
    addWarehouse() {
      this.dialogVisible = true;
    },
    // 编辑 
    async handleEdit(row) {
      this.dialogVisible = true;

      this.$http.requestGet({
        url: `/warehouse/get/${row.id}`,
      }).then(res => {
        const {data} = res;
        this.submitForm = {
          id: data.id,
          name: data.name,
          regionId: data.regionId ? data.regionId.toString() : '',
          address: data.address,
          managerId: data.managerId,
          phone: data.phone
        }
        this.selectStaff = data;
      })
    },
    // 添加仓库表单提交
    submitAdd() {
      console.log(this.submitForm);
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          let param = JSON.parse(JSON.stringify(this.submitForm))
          console.log(param);
          this.$http.requestPost({
            url: '/warehouse/save',
            param: param
          }).then(res => {
            this.searchList();
            this.$message({ message: '操作成功', type: 'success' })
            this.closeDialog();
          })
        } else {
          return false
        }
      })
    },
    // 关闭添加仓库弹窗
    closeDialog() {
      this.$refs.submitForm.resetFields();
      this.dialogVisible = false;
      this.submitForm = {
        name: '',
        regionId: '',
        address: '',
        managerId: '',
        phone: '',
        id: '',
      }
      this.selectStaff = {};
    },

    // ================== 仓管员弹窗 ==================
    // 打开仓管员弹窗
    openManagePopups() {
      this.getManageList();
      this.dialogVisible_staff = true;
    },
    // 仓管员改变 
    handleStaffChange(e) {
      if (e) this.selectStaff = e;
    },
    // 确定选择仓管员
    confirmSelect() {
      this.submitForm.managerId = this.selectStaff.managerId;
      this.dialogVisible_staff = false;
    },
    // 仓管员查询
    manageSearch() {
      this.pageParam_staff.pageNum = 1;
      this.getManageList();
    },
    // 页容量改变
    handleSizeChange_staff(size) {
      this.pageParam_staff.pageNum = 1;
      this.pageParam_staff.pageSize = size;
      // 刷新(数据)
      this.getManageList()
    },
    // 页码改变
    handleCurrentChange_staff(page) {
      this.pageParam_staff.pageNum = page;
      // 刷新(页码)
      this.getManageList();
    },
    // 获取数据列表
    getManageList() {
      let data = {
        pageNum: this.pageParam_staff.pageNum,
        pageSize: this.pageParam_staff.pageSize,
        param: this.inquire_staff
      }

      this.loading_staff = true;
      this.$http.requestPost({
        url: '/system/manager/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading_staff = false;
        this.tableData_staff = res.data.list;
        this.pageParam_staff.total = res.data.total;
      }).catch(err => {
        this.loading_staff = false;
        console.log(err)
      })
    },

    // ================== 其它数据 ==================
    // 获取省市区
    getAddress() {
      this.$http
        .requestGet({ url: "/common/address", loading: false })
        .then((res) => {
          this.addressList_add = res.data;
        });
    },

  },
};
</script>

<style lang="scss" scoped>
.comment-score {
  height: 40px;
  line-height: 40px;
  align-items: center;
}

.img-box {
  width: 32px;
  height: 32px;
}

.img-box-show {
  margin: 0 5px 5px 0;
  cursor: pointer;
}
</style>
