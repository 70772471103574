var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            "before-close": _vm.closeDialog,
            visible: _vm.dialogShow,
            top: "12vh",
            width: "75%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              _vm.selectValue = ""
                            },
                          },
                          model: {
                            value: _vm.selectKey,
                            callback: function ($$v) {
                              _vm.selectKey = $$v
                            },
                            expression: "selectKey",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "产品编号", value: "productCode" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "产品名称", value: "productName" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "width-180",
                        attrs: { placeholder: "", clearable: "" },
                        model: {
                          value: _vm.selectValue,
                          callback: function ($$v) {
                            _vm.selectValue = $$v
                          },
                          expression: "selectValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: _vm.productProps,
                          options: _vm.productTypeList,
                          clearable: "",
                        },
                        model: {
                          value: _vm.inquire_1.productCategoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_1, "productCategoryId", $$v)
                          },
                          expression: "inquire_1.productCategoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              ;(_vm.pageParam_1.pageNum = 1),
                                _vm.getHasLinkedProductListByMerchantId()
                            },
                          },
                        },
                        [_vm._v("查询\n          ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _vm.operation
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.addProduct },
                            },
                            [_vm._v("添加产品")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.operation && _vm.pageType === "system"
                        ? _c(
                            "el-button",
                            {
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.delProduct },
                            },
                            [_vm._v("删除产品")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_1,
                      expression: "loading_1",
                    },
                  ],
                  ref: "msgTabel",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.productList, border: "", height: "400px" },
                  on: { "selection-change": _vm.currentDelChange },
                },
                [
                  _vm.pageType === "system"
                    ? _c("el-table-column", {
                        attrs: { type: "selection", width: "55" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "序号", prop: "date", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.pageParam_1.pageNum - 1) *
                                    _vm.pageParam_1.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编号",
                      "min-width": "170",
                      prop: "productCode",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      "min-width": "160",
                      prop: "productName",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "txt_box",
                                class: {
                                  "flex-box": !scope.row.productImg,
                                  "flex-center": !scope.row.productImg,
                                },
                              },
                              [
                                scope.row.isGifts
                                  ? _c("img", {
                                      staticClass: "icon_box",
                                      class: { no_img: !scope.row.productImg },
                                      attrs: {
                                        src: require("@/assets/image/gift_icon.png"),
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.productName) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属归类",
                      "min-width": "140",
                      prop: "productCategoryName",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.pageType !== "system"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "库存",
                          "min-width": "120",
                          prop: "productInventoryTotal",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "140", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                            _vm._v(" "),
                            _vm.operation
                              ? _c(
                                  "span",
                                  { staticClass: "operation-btn_partition" },
                                  [_vm._v(" / ")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.operation
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "operation-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.delProduct(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam_1.pageNum,
                      "page-size": _vm.pageParam_1.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.pageParam_1.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_1,
                      "current-change": _vm.handleCurrentChange_1,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticStyle: { "padding-bottom": "20px" } }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": function () {
              return (_vm.dialogVisible_2 = false)
            },
            visible: _vm.dialogVisible_2,
            title: "未选择产品列表",
            top: "12vh",
            width: "75%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "width-140",
                          attrs: { placeholder: "请选择" },
                          on: {
                            change: function ($event) {
                              _vm.selectValue = ""
                            },
                          },
                          model: {
                            value: _vm.selectKey,
                            callback: function ($$v) {
                              _vm.selectKey = $$v
                            },
                            expression: "selectKey",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "产品编号", value: "productCode" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "产品名称", value: "productName" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("el-input", {
                        staticClass: "width-180",
                        attrs: { placeholder: "", clearable: "" },
                        model: {
                          value: _vm.selectValue,
                          callback: function ($$v) {
                            _vm.selectValue = $$v
                          },
                          expression: "selectValue",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          props: _vm.productProps,
                          options: _vm.productTypeList,
                          clearable: "",
                        },
                        model: {
                          value: _vm.inquire_2.productCategoryId,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_2, "productCategoryId", $$v)
                          },
                          expression: "inquire_2.productCategoryId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function ($event) {
                              ;(_vm.pageParam_2.pageNum = 1),
                                _vm.getUnLinkedProductListByMerchantId()
                            },
                          },
                        },
                        [_vm._v("查询\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_2,
                      expression: "loading_2",
                    },
                  ],
                  ref: "msgTabel",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.productList_2,
                    border: "",
                    height: "400px",
                  },
                  on: { "selection-change": _vm.currentChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "序号", prop: "date", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.pageParam_2.pageNum - 1) *
                                    _vm.pageParam_2.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编号",
                      "min-width": "170",
                      prop: "productCode",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      "min-width": "160",
                      prop: "productName",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "txt_box",
                                class: {
                                  "flex-box": !scope.row.productImg,
                                  "flex-center": !scope.row.productImg,
                                },
                              },
                              [
                                scope.row.isGifts
                                  ? _c("img", {
                                      staticClass: "icon_box",
                                      class: { no_img: !scope.row.productImg },
                                      attrs: {
                                        src: require("@/assets/image/gift_icon.png"),
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(scope.row.productName) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "所属归类",
                      "min-width": "140",
                      prop: "productCategoryName",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _vm.pageType !== "system"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "销售价(￥)",
                          "min-width": "140",
                          prop: "messengerPhoneNumber",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        scope.row.productPriceMin +
                                          " ~ " +
                                          scope.row.productPriceMax
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3370297149
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pageType !== "system"
                    ? _c("el-table-column", {
                        attrs: {
                          label: "库存",
                          "min-width": "120",
                          prop: "productInventoryTotal",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "操作", "min-width": "140", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDetails(scope.row)
                                  },
                                },
                              },
                              [_vm._v("详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam_2.pageNum,
                      "page-size": _vm.pageParam_2.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.pageParam_2.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_2,
                      "current-change": _vm.handleCurrentChange_2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }