var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "roll_dialog",
      attrs: {
        width: "55%",
        top: "50px",
        visible: _vm.showDialog,
        "before-close": _vm.handleClose,
        title: _vm.config.sendCode,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c("div", { staticClass: "main" }, [
        _c("div", { staticClass: "header" }, [
          _vm._v("物流单号: " + _vm._s(_vm.config.logisticsCode)),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "logistics" },
          [
            _c("div", { staticClass: "title" }, [
              _c("span", [_vm._v("时间")]),
              _vm._v(" "),
              _c("span", [_vm._v("跟踪进度")]),
            ]),
            _vm._v(" "),
            _vm._l(_vm.logistics, function (row, index) {
              return _c("div", { key: row.id, staticClass: "logistics_row" }, [
                _c("div", { staticClass: " left_box" }, [
                  _vm._v("\n        " + _vm._s(row.date) + "\n      "),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { flex: "1" } },
                  _vm._l(row.child, function (child, childInd) {
                    return _c(
                      "div",
                      { key: child.id, staticClass: "right_box" },
                      [
                        _c("span", {
                          staticClass: "tags",
                          class: { active: index == 0 && childInd == 0 },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "cont" }, [
                          _c("div", { staticClass: "cont_date" }, [
                            _vm._v(_vm._s(child.time)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "cont_info" }, [
                            _vm._v(_vm._s(child.context)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }