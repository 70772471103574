<!-- 平台优惠券列表 -->
<template>
  <div>
    <ShareList couponType='platform'/>
  </div>
</template>

<script>
import ShareList from './ShareList'

export default {
  name: 'PlatformCoupon',
  components: {
    ShareList
  },
  data() {
    return {}
  },
  created() {

  },
  methods: {}
}

</script>

<style scoped></style>
