<template>
  <div class="broken-line">
    <div class="title">
      <div class="txt_box">
        <div class="text">{{ title }}</div>
        <img src="@/assets/image/top.png" alt="" class="icon">
        <div class="tip">
          <img src="../../assets/image/wenhao.png" alt="">
          <div ref="poup" class="poup">
            <div class="txt">
              分析所选时间段内的24小时订单数量
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 封装通用的折线图 -->
    <div id="broken-line" ref="broken" />
  </div>
</template>
<script>
// 引入echarts
import * as echarts from 'echarts'
export default {
   data() {
      return {
        title: '订单24小时趋势分析'
      }
   },
   computed: {
   },
   created() {
   },
   methods: {
      // 获取折线图数据图表
  getDataOrder(title, hourList, todayList, header) {
    var chartDom = this.$refs.broken
    var myChart = echarts.init(chartDom)
    window.addEventListener('resize', function() {
          myChart.resize()
      })
    var option
    const colors = ['rgba(57, 147, 255, 0.6)']
option = {
  color: colors,
  // title: {
  //   text: title,
  //   top: '20',
  //   left:'20',
  //   textStyle:{
  //     fontSize:16,//字体大小
  //     fontWeight: '600',
  //     color:'#fff',
  //   },
  // },
  grid: {
    right: '5%',
    left: '7%',
    top: '20',
    bottom: '20'
  },
  tooltip: {
    trigger: 'axis'
    // axisPointer: {
    //   type: 'cross'
    // }
  },
  // legend: {
  //   icon: 'rect',
  //   top:'5%',
  //   right: '5%',
  //   itemWidth: 20,     // 图例标记的图形宽度
  //   itemHeight: 7,   // 图例标记的图形高度 在修改图例标记图形的时候可以用到。比如矩形变成直线
  //   itemGap: 13,//间距
  //   data: header,
  //   textStyle:{
  //     fontSize:12,//文字大小
  //     padding:[0,10,0,5],//文字与图形之间的左右间距
  //     color:'#fff',
  //   }
  // },
  xAxis: {
    type: 'category',
    data: hourList,
    axisLine: {
     lineStyle: {
        type: 'solid',
        color: '#027DB4', // 左边线的颜色
        width: '3' // 坐标线的宽度
     },
     show: false
    },
    axisLabel: {
        // x轴文字的配置
        show: true,
        interval: 0, // 使x轴文字显示全
        color: '#B0F9FD', // 更改坐标轴文字颜色
        fontSize: 12// 文字大小
    },
    axisTick: {
           show: false
       }
  },
  yAxis: {
    type: 'value',
    splitLine: {
        show: true,
        lineStyle: {
              show: true,
              color: ['rgba(101, 165, 221, 0.08)'],
              width: 1,
              type: 'solid'
        }
    },
    axisLabel: {
      formatter: function(value, index) {
      var value
      if (value >= 1000) {
      value = value / 1000 + 'k'
      } else if (value < 1000) {
      value = value
      }
      return value
      },
      textStyle: {
        color: '#65A5DD',
        fontSize: 12// 文字大小
      }
    },
      // 设置坐标轴字体颜色和宽度
      axisLine: {
      lineStyle: {
          color: '#fff'
      }
    }
  },
  series: [
    {
      name: header[0],
      // data: [0,2,3,7,4,2,3,4,5,6,6,7,8,3,2,7,8,6,411,121,33,433,5222,61111],
      data: todayList,
      type: 'line',
      smooth: true,
      showSymbol: false
    }
    // {
    //   name: header[1],
    //   data: last7List,
    //   type: 'line',
    //   smooth: true
    // },
    // {
    //   name: header[2],
    //   data: last14List,
    //   type: 'line',
    //   smooth: true
    // }
  ]
}
option && myChart.setOption(option)
  }
   }
}
</script>
<style lang="scss" scoped>
.broken-line{
  // width: 100%;
  // height: 100%;
  width: 615px;
  height: 375px;
  padding: 20px;
  background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/1ce855cb-bd25-4c27-8224-1d12a268f038.png") no-repeat center 0px;
  background-size: cover;
  display: flex;
  flex-direction: column;
  .title {
    display: flex;
    justify-content: flex-end;
    padding: 5px 0 28px;
    .text {
      background-image: linear-gradient(0deg, #56FEFF, #E2F3FB);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 16px;
    }
    .icon {
      width: 18px;
      height: 18px;
      margin-left: 10px;
    }
    .txt_box{
      display: flex;
      align-self: center;
      align-items: center;
      align-content: center;
      &:hover{
        .tip{
          .poup {
            display: flex;
          }
        }
      }
    }
  }

  #broken-line{
    // width: 100%;
    // height: 100%;
    // width: 590px;
    // height: 360px;
    flex: 1;
  }
}

.tip{
  width: 28px;
  height: 28px;
  position: relative;
  margin-left: 10px;
  img{
    width: 100%;
    height: 100%;
  }
}
.poup{
  background: url('../../assets/image/tip.png') no-repeat center center;
  padding: 20px;
  text-align: left;
  right: 10px;
  z-index: 100;
  position: absolute;
  width: 302px;
  // height: 71px;
  color: #c0e2ff;
  font-weight: 400;
  font-size: 12px;
  font-face: Microsoft YaHei;
  line-height: 20px;
  display: none;
}
</style>
