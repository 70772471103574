var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "width-140",
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            _vm.selectValue = ""
                          },
                        },
                        model: {
                          value: _vm.selectKey,
                          callback: function ($$v) {
                            _vm.selectKey = $$v
                          },
                          expression: "selectKey",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "仓库名称", value: "name" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "仓库编号", value: "no" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-180",
                      attrs: { placeholder: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.selectValue,
                        callback: function ($$v) {
                          _vm.selectValue = $$v
                        },
                        expression: "selectValue",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "", height: "400px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "序号", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "no",
                        label: "仓库编号",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.no))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "name",
                        label: "仓库名称",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "managerName",
                        label: "仓管员",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "phone",
                        label: "联系方式",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "address",
                        label: "地址",
                        "min-width": "200",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "100",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pagination" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        background: "",
                        "current-page": _vm.pageParam.pageNum,
                        "page-sizes": _vm.$api.pageSizes,
                        "page-size": _vm.pageParam.pageSize,
                        layout: "total, sizes, prev, pager, next, jumper",
                        total: _vm.pageParam.total,
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                      },
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "添加",
            visible: _vm.dialogVisible,
            "before-close": _vm.closeDialog,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    "label-width": "100px",
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓库名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "仓库名称" },
                        model: {
                          value: _vm.submitForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "name", $$v)
                          },
                          expression: "submitForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地区", prop: "regionId" } },
                    [
                      _c("el-cascader", {
                        staticClass: "width-inherit",
                        attrs: {
                          props: _vm.props_add,
                          options: _vm.addressList_add,
                          placeholder: "请选项地址",
                        },
                        model: {
                          value: _vm.submitForm.regionId,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "regionId", $$v)
                          },
                          expression: "submitForm.regionId",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "详细地址", prop: "address" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          maxlength: "150",
                          placeholder: "详细地址",
                        },
                        model: {
                          value: _vm.submitForm.address,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "address", $$v)
                          },
                          expression: "submitForm.address",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "仓管员", prop: "managerId" } },
                    [
                      _c(
                        "div",
                        { staticClass: "flex-box" },
                        [
                          _vm.submitForm.managerId
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.selectStaff.managerName) +
                                    "  " +
                                    _vm._s(_vm.selectStaff.managerPhoneNumber) +
                                    "  "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.openManagePopups },
                            },
                            [_vm._v("选择 ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系方式", prop: "phone" } },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "11", placeholder: "联系方式" },
                        model: {
                          value: _vm.submitForm.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "phone", $$v)
                          },
                          expression: "submitForm.phone",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                [_vm._v("保 存 ")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择",
            visible: _vm.dialogVisible_staff,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_staff = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名/手机号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "姓名/手机号" },
                        model: {
                          value: _vm.inquire_staff.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_staff, "name", $$v)
                          },
                          expression: "inquire_staff.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.manageSearch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_staff,
                      expression: "loading_staff",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData_staff,
                    "highlight-current-row": "",
                    border: "",
                    height: "400px",
                  },
                  on: { "current-change": _vm.handleStaffChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                (_vm.pageParam_staff.pageNum - 1) *
                                  _vm.pageParam_staff.pageSize +
                                  (scope.$index + 1)
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "managerName",
                      label: "姓名",
                      "min-width": "140",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "managerPhoneNumber",
                      label: "手机号",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "权限组", "min-width": "200" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.managerId == "0"
                              ? _c("div", [_vm._v("超级管理员")])
                              : _c("div", [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.rightsGroupNames
                                        ? scope.row.rightsGroupNames.join()
                                        : scope.row.rightsGroupNames
                                    )
                                  ),
                                ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "accountStatus",
                      label: "状态",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  scope.row.accountStatus === "normal"
                                    ? "启用"
                                    : "锁定"
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "添加时间",
                      "min-width": "160",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_staff.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_staff.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_staff.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_staff,
                      "current-change": _vm.handleCurrentChange_staff,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_staff = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSelect },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }