<template>
    <!-- 申购单管理 -->
    <div>
        <router-view/>
    </div>
</template>

<script>
export default {}
</script>

<style>
</style>
