<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item>
          <el-button type="primary" size="small" @click="getTableList">查询</el-button>
        </el-form-item>

      </el-form>
    </div> 

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ scope.$index + 1 }}</template>
            </el-table-column> 
            <el-table-column prop="protocolName" label="协议名称" min-width="180" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">{{scope.row.protocolName}}</span> 
              </template>
            </el-table-column>
            <el-table-column prop="protocolContent" label="协议内容" min-width="300" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.protocolContent ? scope.row.protocolContent.length > 100 ? scope.row.protocolContent.slice(0, 100) + '...' : scope.row.protocolContent : '' }}
              </template>
            </el-table-column>
            <el-table-column prop="updateTime" label="更新时间" min-width="160"></el-table-column>
            <el-table-column prop="addByName" label="操作人" min-width="160"></el-table-column>
          </el-table>
        </template>
      </el-main>
    </div>

    <!-- 编辑弹框 -->
    <el-dialog
    title="编辑协议"
    :visible.sync="dialogVisible"
    :before-close="closeDialog"
    width="70%"
    top="5vh">
      <div class="Popout_content">
        <el-form label-width="80px" ref="submitForm" :model="submitForm" :rules="submitRules">
            <el-form-item label="协议名称">
              <el-input v-model="submitForm.protocolName" placeholder="协议名称" disabled></el-input>
            </el-form-item>

            <el-form-item label="协议" prop="protocolContent">
              <div class="editor">
                <mavon-editor 
                    ref="md"
                    v-model="editor"
                    @imgAdd="handleEditorImgAdd"
                    @imgDel="handleEditorImgDel"
                    @change="editorChange"
                    :toolbars="toolbars"/>
              </div>
            </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 表格数据
      tableData: [], 

      // 编辑
      dialogVisible: false, // 编辑弹窗
      submitForm: {
        protocolName:  '',
        protocolContent:'',
      },
      submitRules: {
        protocolContent: [{required: true, message: '请输入协议内容', trigger: 'change'}],
      },
      editRow: {},

      toolbars: {         // 编辑器配置
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
      editor:'',
    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 富文本 图片添加
    handleEditorImgAdd(pos, file) {
        let formdata = new FormData()
        formdata.append('file', file)
        this.$http.requestPost({
            url: '/common/file/upload',
            param: formdata
        }).then(res => {
            // 暂时自己拼接
            this.$refs.md.$img2Url(pos, res.data)
        })
    },
    // 富文本 图片删除
    handleEditorImgDel(pos) {
        console.log(pos)
    },
    // 富文本改变后的内容   
    editorChange(val, render) {
        this.submitForm.protocolContent = render;
    },

    // 获取数据列表
    getTableList() {
      this.loading = true;
      this.$http.requestGet({
        url: '/operation/protocol',
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },
    

    // 修改
    handleEdit(row) {
      this.$http.requestGet({url:'/operation/protocol/' + row.protocolId, loading: false}).then(res => {
        this.submitForm = {
          protocolName:  res.data.protocolName,
          protocolContent: res.data.protocolContent
        }
        this.editor = this.$public.parseHTML_ForMarkdown(res.data.protocolContent)
      })
      this.dialogVisible = true;
      this.editRow = row;
    },
    // 提交添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          let param = {
            protocolName: this.submitForm.protocolName,
            protocolContent: this.submitForm.protocolContent,
            protocolId: this.editRow.protocolId
          };

          this.$http.requestPost({
            url: '/operation/protocol/save',
            param: param
          }).then(res => {
            this.getTableList()
            this.$message.success('操作成功！')
            this.closeDialog()
          })
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields());
      this.dialogVisible = false;
      this.submitForm = {
        navName:  '',
        navUri:'',
        navImg:'',
        navOrder: '',
        navStatus:'display'
      }
    }

    
  },
};
</script>

<style lang="scss" scoped>
  .editor {
        width: 100%;
        max-height: 420px;
        .v-note-wrapper.shadow {
            height: 420px;
        }
    }
</style>
