<template>
  <div class="main-box">
      <div class="head-search-row">
        <groupChatList :type="type"></groupChatList>
      </div>
  </div>
</template>

<script>
  import groupChatList from '@/components/consult/groupChatList.vue'
export default {
  components:{
    groupChatList,
  },
  data() {
      return {
        type:'dissolved',
      };
  },
  created() {
    
  },
  methods: {
     
  },
};
</script>

<style lang="scss" scoped>

</style>
