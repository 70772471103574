<template>
  <!-- 待付款订单 -->    <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="订单编号">
          <el-input v-model="inquire.orderCode" placeholder="订单编号" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item label="下单时间">
          <el-date-picker
            v-model="datetimerange"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>

        <el-form-item label="">
          <div class="flex-box">
            <el-cascader v-model="categoryId" :props="props" :options="productTypeList" clearable @change="categoryChange" />
            <el-select v-show="productList.length > 0" v-model="inquire.productIds" multiple placeholder="请选择产品" class="width-260">
              <el-option v-for="item in productList" :key="item.productId" :label="item.productName" :value="item.productId" />
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="优惠券ID">
          <el-input v-model.trim="inquire.couponCode" placeholder="优惠券ID" @keyup.enter.native="searchList" />
        </el-form-item>


        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="fnExportOrderList">导出</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table ref="tables" v-loading="loading" :data="tableData" :expand-row-keys="expands" border height="400px" row-key="orderId" style="width: 100%" @expand-change="expandChange">
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table :data="scope.row.items" border style="width: 100%">
                  <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex-box">
                        <img
                          v-if="scope.row.productImg"
                          :src="scope.row.productImg"
                          alt=""
                          class="img-box"
                        >
                        <div class="hospital-name">
                          <div class="txt_box" :class="{'flex-box':!scope.row.productImg,'flex-center':!scope.row.productImg}">
                            <img v-if="scope.row.isGifts" src="@/assets/image/gift_icon.png" alt="" class="icon_box" :class="{no_img:!scope.row.productImg}">
                            {{ scope.row.productName }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="产品编号"
                    min-width="160"
                    prop="productCode"
                    show-overflow-tooltip
                  />
                  <el-table-column label="产品规格" min-width="120" prop="productNorms">
                    <template slot-scope="scope">{{ scope.row.productNorms || '/' }}</template>
                  </el-table-column>
                  <el-table-column label="单价" min-width="120" prop="itemPrice">
                    <template slot-scope="scope">{{ '￥' + scope.row.itemPrice }}</template>
                  </el-table-column>
                  <el-table-column label="数量" min-width="120" prop="itemNum" />
                  <el-table-column label="购买总价" min-width="120" prop="">
                    <template slot-scope="scope">
                      {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>

            <el-table-column label="订单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
              <template slot-scope="scope">
                <span
                  class="operation-btn"
                  @click="handleParticulars(scope.row)"
                >{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="买家/收货人" min-width="160" prop="" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.memberNickName + '/' + scope.row.recipient }}
              </template>
            </el-table-column>
            <el-table-column label="总金额" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + scope.row.orderTotalPrice.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column label="运费" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + scope.row.shippingFee.toFixed(2) }}
              </template>
            </el-table-column>

            <el-table-column label="下单时间" min-width="160" prop="orderTime" />
            <!-- <el-table-column v-if="orderStatus == 'pending_payment' || orderStatus == 'pending_pick'" label="下单时间" min-width="160" prop="orderTime"></el-table-column> -->
            <el-table-column v-if="orderStatus == 'pending_ship'" label="付款时间" min-width="160" prop="paymentTime" />
            <el-table-column v-else-if="orderStatus == 'pending_receipt'" label="发货时间" min-width="160" prop="shipTime" />
            <el-table-column v-else-if="orderStatus == 'pending_evaluate'" label="确认收货时间" min-width="160" prop="receiptTime" />

            <el-table-column label="订单状态" min-width="120" prop="orderStatus">
              <template slot-scope="scope">
                {{ $api.getValue($api.EcommerceOrderState, scope.row.orderStatus, 'name') }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="所属专员" min-width="120" prop="attacheName" /> -->
            <!-- <el-table-column label="所属区域" min-width="200" prop="recipientAddressDetail" show-overflow-tooltip /> -->
            <el-table-column fixed="right" label="操作" min-width="160" prop="">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleParticulars(scope.row)">订单详情</span>
                <span v-show="scope.row.orderStatus ==='pending_stock'" class="operation-btn_partition"> / </span>
                <span v-show="scope.row.orderStatus ==='pending_stock'" class="operation-btn" @click="handleShipments(scope.row)">发货</span>
                <span v-show="scope.row.orderStatus ==='pending_receipt' || scope.row.orderStatus ==='pending_evaluate' || scope.row.orderStatus ==='completed'" class="operation-btn_partition"> / </span>
                <span v-show="scope.row.orderStatus ==='pending_receipt' || scope.row.orderStatus ==='pending_evaluate' || scope.row.orderStatus ==='completed'" class="operation-btn" @click="handleLogistics(scope.row)">查看物流</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-size="pageParam.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog
      :visible.sync="dialogVisible_1"
      title="修改价格"
      top="2vh"
      width="70%"
    >
      <div class="Popout_content">
        <div class="table">
          <el-table :data="tableData" border height="400px" style="width: 100%">
            <el-table-column label="序号" prop="date" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column label="产品信息" min-width="120" prop="orderTotalPrice">
              <template />
            </el-table-column>
            <el-table-column label="单价" min-width="100" prop="orderTotalPrice" />
            <el-table-column label="数量" min-width="100" prop="orderTotalPrice" />
            <el-table-column label="小计" min-width="100" prop="orderTotalPrice" />
            <el-table-column label="加价/减价" min-width="120" prop="orderTotalPrice">
              <template slot-scope="scope">
                <div class="flex-box flex-center">
                  ￥
                  <el-input v-model="scope.row.orderTotalPrice" placeholder="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="实收金额" min-width="100" prop="orderTotalPrice" />
          </el-table>
        </div>
        <div class="show-money">
          <ul>
            <li>商品金额：<span class="block_span">￥200.00</span></li>
            <li>运费：<span class="block_span">+￥20.00</span></li>
            <li>加价/减价：<span class="block_span color_red">-￥20.00</span></li>
            <li class="line-box" />
            <li class="bold">实付总额：<span class="block_span color_red">￥180.00</span></li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_1 = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible_2"
      title="发货"
      width="400px"
    >
      <div class="Popout_content">
        <el-form
          ref="submitForm_2"
          :model="submitForm_2"
          :rules="submitRules_2"
          label-width="80px"
          size="medium"
        >
          <el-form-item label="订单编号">
            <el-input v-model="submitForm_2.orderCode" disabled placeholder="订单编号" />
          </el-form-item>

          <el-form-item label="物流公司" prop="logisticsCompany">
            <el-select v-model="submitForm_2.logisticsCompany" class="form-select" placeholder="请选择">
              <el-option
                v-for="item in logisticsCompanyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="物流单号" prop="logisticsCode">
            <el-input v-model="submitForm_2.logisticsCode" maxlength="30" placeholder="物流单号" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmSendOn">确 定</el-button>
      </span>
    </el-dialog>

    <d-export :inquire="inquire" :show-dialog="showExportDialog" :close.sync="showExportDialog" :type="inquire.orderClassify" />
    <!-- v4.4.5物流信息弹出框 -->
    <d-dialog
      ref="logisticsDialog"
      :logistics-list="logisticsList"
      :data-obj="dataObj"
      :route-info="routeInfo"
      @handleClose="handleClose"
    />
  </div>
</template>

<script>
import dExport from '@/components/order/d-export'
import DDialog from '@/components/d-dialog'
export default {
    components: {
        dExport,
        DDialog
    },
    props: {
        orderStatus: {
            type: String,
            default: 'pending_payment'
        }
    },
    data() {
        return {
            logisticsList: [], // 物流信息
            dataObj: {}, // 物流
            routeInfo: {},
            // 产品分类
            productTypeList: [],
            props: {
                label: 'categoryName',
                value: 'categoryId',
                children: 'child',
                // checkStrictly: true,
                emitPath: true
            },
            productList: [],
            categoryId: '',

            inquire: {
                orderClassify: 'nutrition',
                isReturnOrder: false,
                orderStatus: '',
                orderTimeBegin: '',
                orderTimeEnd: '',
                deliveryMethod: '',
                orderCode: '',
                productIds: [],
                categoryId: '',
                couponCode: ''
            },
            datetimerange: [],

            // 分页
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            // 表格数据
            tableData: [],
            loading: false,
            expands: [], // 当前展示行

            editRow: {},

            // ========= 修改价格弹窗 =========
            dialogVisible_1: false,

            // ========= 单个发货弹窗 =========
            dialogVisible_2: false,
            submitForm_2: { //
                orderCode: '',
                logisticsCompany: '',
                logisticsCode: ''
            },
            submitRules_2: {
                logisticsCompany: [{ required: true, message: '请选择物流公司', trigger: 'change' }],
                logisticsCode: [{ required: true, message: '请输入物流单号', trigger: 'blur' },
                    { pattern: /^[0-9a-zA-Z]+$/, message: '请输入字母和数字', trigger: 'blur' }
                ]
            },
            logisticsCompanyList: [], // 物流公司列表

            // ========= 导出弹窗 =========
            showExportDialog: false

        }
    },
    created() {
        if (sessionStorage.getItem('cacheParamA')) {
            this.inquire = JSON.parse(sessionStorage.getItem('cacheParamA'))
            this.datetimerange[0] = this.inquire.orderTimeBegin || ''
            this.datetimerange[1] = this.inquire.orderTimeEnd || ''
        }
        if (sessionStorage.getItem('n_order')) {
            this.inquire.orderCode = JSON.parse(sessionStorage.getItem('n_order'))
        }
        this.inquire.orderStatus = this.orderStatus
        this.getTableData()
        this.getProductType()
        if (this.orderStatus === 'pending_ship') this.getLogisticsCompanyList()
    },
    methods: {
  handleClose() {
      this.$refs.logisticsDialog.showDialog = false
      //  关闭时置空
      this.logisticsList = []
    },
    // 查看物流
    async handleLogistics(row) {
      //    发起查看物理轨迹请求
      try {
        await this.getReturnPostInfo(row.orderId)
        console.info('查看物流', row)
      } catch (error) {

      }
    },
    // 查询物流轨迹
    async getReturnPostInfo(code) {
      try {
        const res = await this.$http.requestGet({
          url: `/customer/order/logistics/${code}`,
          loading: false
        })
        if (!res.data || (res.code == 200 && res.data.status != 200)) {
          this.$message({
                    message: '查无结果，请检查单号和快递公司是否有误',
                    type: 'warning',
                    duration: 2500
                })
        } else {
          this.dataObj = res.data
          this.routeInfo = res.data.routeInfo
          this.logisticsList = res.data.data
          this.$refs.logisticsDialog.showDialog = true
        }
      } catch (error) {}
    },
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1
            this.pageParam.pageSize = size
            this.getTableData()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page
            this.getTableData()
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1
            this.getTableData()
        },
        // table 手风琴
        expandChange(news, old) {
            if (old.length === 0) {
                return this.expands = []
            }
            this.expands = [news.orderId]
        },
        // 获取物流公司列表
        getLogisticsCompanyList() {
            const param = {
                pageNum: 1,
                pageSize: 10000
            }
            this.$http.requestPost({ url: '/system/logistics/company/page', param, loading: false }).then(res => {
                this.logisticsCompanyList = res.data.list
            })
        },
        // 获取产品类型
        getProductType() {
            this.$http.requestGet({ url: '/product/category', loading: false }).then(res => {
                this.productTypeList = res.data
            })
        },
        // 产品分类改变
        categoryChange(e) {
            this.productList = []
            this.inquire.productIds = []
            if (e.length > 1) {
                this.getProductList(e[1])
            }
        },
        // 根据分类id获取产品列表
        getProductList(id) {
            this.$http.requestGet({ url: '/customer/order/category/' + id, loading: false }).then(res => {
                this.productList = res.data
            })
        },
        // 导出
        fnExportOrderList() {
            if (!this.datetimerange) this.datetimerange = []
            this.inquire.orderTimeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : ''
            this.inquire.orderTimeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : ''
            this.inquire.categoryId = this.categoryId
            this.showExportDialog = true
        },

        getToPage() {
            this.inquire.orderTimeBegin = ''
            this.inquire.orderTimeEnd = ''
            this.inquire.productIds = []
            const data = {
                pageNum: 1,
                pageSize: 10,
                param: JSON.parse(JSON.stringify(this.inquire))
            }
            delete data.param.categoryId
            delete data.param.orderStatus
            this.$http.requestPost({
                url: '/customer/order/page',
                param: data,
                loading: false
            }).then(res => {
                if (res.code == 200) {
                    // 营养餐-全国配送
                    if (res.data.list.length > 0 && res.data.list[0].orderClassify == 'nutrition' && res.data.list[0].deliveryMethod == 'national') {
                        if (res.data.list[0].orderStatus == 'pending_stock') {
                            this.$confirm('是否确定跳转到全国待发货订单页面?', '订单跳转确认', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消'
                            }).then(() => {
                                this.$router.push({ path: '/order/NutritionOrder/waitForSending' })
                                sessionStorage.setItem('n_order', JSON.stringify(this.inquire.orderCode))
                            })
                        } else if (res.data.list[0].orderStatus == 'pending_receipt') {
                            this.$confirm('是否确定跳转到全国待收货订单页面?', '订单跳转确认', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消'
                            }).then(() => {
                                this.$router.push({ path: '/order/NutritionOrder/waitForReceiving' })
                                sessionStorage.setItem('n_order', JSON.stringify(this.inquire.orderCode))
                            })
                        } else if (res.data.list[0].orderStatus == 'completed') {
                            this.$confirm('是否确定跳转到全国已完成订单页面?', '订单跳转确认', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消'
                            }).then(() => {
                                this.$router.push({ path: '/order/NutritionOrder/completed' })
                                sessionStorage.setItem('n_order', JSON.stringify(this.inquire.orderCode))
                            })
                        } else if (res.data.list[0].orderStatus == 'cancel') {
                            this.$confirm('是否确定跳转到全国已取消订单页面?', '订单跳转确认', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消'
                            }).then(() => {
                                this.$router.push({ path: '/order/NutritionOrder/cancel' })
                                sessionStorage.setItem('n_order', JSON.stringify(this.inquire.orderCode))
                            })
                        }
                    } else {
                        this.$alert('请检查输入订单号是否正确或业务类型是否匹配', '无订单提示', {
                        confirmButtonText: '关闭'
                        })
                    }
                }
            }).catch(err => {
                console.log(err)
            })
        },

        // 获取列表数据
        getTableData() {
            if (sessionStorage.getItem('n_order')) {
                sessionStorage.removeItem('n_order')
            }
            if (!this.datetimerange) this.datetimerange = []
            this.inquire.orderTimeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : ''
            this.inquire.orderTimeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : ''
            this.orderStatus = this.inquire.orderStatus
            const data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: JSON.parse(JSON.stringify(this.inquire))
            }
            delete data.param.categoryId

            this.loading = true
            this.$http.requestPost({
                url: '/customer/order/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false
                this.tableData = res.data.list
                this.pageParam.total = res.data.total
                // 4.8.3需求添加订单跳转弹框
                if (this.tableData.length === 0 && this.inquire.orderCode.length > 0 && this.inquire.orderTimeBegin.length === 0 && this.inquire.orderTimeEnd.length === 0 && this.inquire.productIds.length === 0) {
                    this.getToPage()
                }
                sessionStorage.removeItem('cacheParamA')
            }).catch(err => {
                this.loading = false
                sessionStorage.removeItem('cacheParamA')
                console.log(err)
            })
        },

        // 修改价格
        handleEdit(row) {
            this.dialogVisible_1 = true
        },

        // 打开发货弹框
        handleShipments(row) {
            this.submitForm_2 = {
                orderCode: row.orderCode,
                logisticsCompany: '',
                logisticsCode: ''
            }
            this.dialogVisible_2 = true
            this.editRow = row
        },
        // 确认发货
        confirmSendOn() {
            this.$refs.submitForm_2.validate(valid => {
                if (valid) {
                    const param = {
                        orderId: this.editRow.orderId,
                        logisticsCompany: this.submitForm_2.logisticsCompany,
                        logisticsCode: this.submitForm_2.logisticsCode
                    }
                    this.$http.requestPut({ url: '/customer/order/nutrition/ship', param }).then(res => {
                        if (res.code == 200 && res.data.errorCode) {
                            this.dialogVisible_2 = false
                            this.$message.error(res.data.description)
                            return
                        }
                        this.$message.success('操作成功!')
                        this.dialogVisible_2 = false
                        this.getTableData()
                    })
                } else {
                    return false
                }
            })
        },

        // 订单详情
        handleParticulars(row) {
            sessionStorage.setItem('cacheParamA', JSON.stringify(this.inquire))
            this.$router.push({
                path: '/order/NutritionOrder/particulars',
                query: {
                    id: row.orderId
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
    width: 375px;
}

.el-head-search-row {
    .flex-box {
        width: 326px;
    }
}

.show-money {
    padding: 10px 0;

    ul {
        li {
            display: flex;
            justify-content: flex-end;
            line-height: 30px;
            font-size: 16px;
        }

        .line-box {
            margin-left: 50%;
            border-bottom: 1px solid #ccc;
        }

        .bold {
            font-weight: bolder;
        }
    }
}

.block_span {
    display: inline-block;
    width: 108px;
    text-align: right;
}

.img-box {
  width: 58px;
  height: 58px;
}

.icon_box {
  width: 21px;
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  &.no_img{
    margin-right: 3px;
    position: static;
  }
}

.txt_box {
  position: relative;
  line-height: 1;
}
</style>
