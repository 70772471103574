<!-- 经销产品订单-待退款退货单 -->
<template>
  <div class="main-box">
    <returned-goods orderStatus="returning" :externalProductFlag="true"></returned-goods>
  </div>
</template>

<script>
import returnedGoods from '@/components/order/returnedGoods_e'

export default {
  components: {
    returnedGoods
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped></style>
