var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "interview" } }, [
    _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "txt_box" }, [
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("@/assets/image/top.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "tip" }, [
          _c("img", {
            attrs: { src: require("../../assets/image/wenhao.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", { ref: "poup", staticClass: "poup" }, [_vm._m(0)]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "chart" } }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "txt" }, [
      _vm._v("\n            统计最近七天的历史访问数据(不含当日)"),
      _c("br"),
      _vm._v("\n            日访问次数：平台的单日访问总次数"),
      _c("br"),
      _vm._v("\n            日新增用户：但是新注册的用户总数"),
      _c("br"),
      _vm._v("\n            日访问人数：但是授权登录的童虎总数\n          "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }