import $http from "@/utils/request";

/**
 *
 *  高德 api
 */
// 高德 key
const GD_Key = '6637de874721b7116ca7fe1f63891f26'


/**
 *
 * 分页
 */
const pageSizes = [10, 20, 30, 50, 100];

/**
 *
 * 版本号
 */
const versionsCode = $versionsCode;

/**
 *  公共Api接口 - 产品
 */
// 产品类别下拉框 api
const productTypeList = (p) => $http.requestGet({ url: "/product/category/tree", param: {}, loading: false });

// 产品单位下拉框 api
const productUnitList = () => $http.requestGet({ url: "/product/units", param: {}, loading: false });

// 产品集合列表 api
const productList = () => $http.requestGet({ url: "/product/units", param: {}, loading: false });

//收款商户列表 api
const weChatMerchantList = () => $http.requestPost({ url: "/weChatMerchant/getList", param: {}, loading: false });

// 字典查询
const dictionaries = (p) => $http.requestPost({ url: "/common/constant/value/list/" + p, param: "", loading: false });

// 用户身份
const identityListState = [{ value: 0, label: "全部" }, { value: 2, label: "专家" }, { value: 3, label: "营养师" }];
// 来源
const articleSource = [{ value: 1, label: "638医品" }, { value: 2, label: "营养学社" }];

/**
 *  固定数据
 */
// 商品/订单类型
const orderClassify = [
  { name: "医药品", value: "medicine" },
  { name: "电商品", value: "electronic" },
  { name: "租赁品", value: "lease" },
  { name: "营养配餐", value: "nutrition" },
  { name: "经销产品", value: "external" },
];
// 配送方式 - 字典：delivery_method
const deliveryMethod = [
  { name: "快递配送", value: "national" },
  { name: "全市配送", value: "citywide" },
  { name: "医院配送", value: "hospital" },
  { name: "自提", value: "self" },
];
// 订单类型 - 电商品 - 字典：sales_order_status
const EcommerceOrderState = [
  { name: "待付款", value: "pending_payment" },
  { name: "待发货", value: "pending_ship" },
  { name: "待收货", value: "pending_receipt" },
  { name: "待评价", value: "pending_evaluate" },
  { name: "已完成", value: "completed" },
  { name: "已取消", value: "cancel" },
  { name: "待发货", value: "pending_stock" },
];
// 订单类型 - 医品
const orderState = [
  { name: "待付款", value: "pending_payment" },
  { name: "待发货", value: "pending_ship" },
  { name: "待收货", value: "pending_receipt" },
  { name: "待自提", value: "pending_pick" },
  { name: "待评价", value: "pending_evaluate" },
  { name: "已完成", value: "completed" },
  { name: "已取消", value: "cancel" },
  { name: "待发货", value: "pending_stock" },
];
// 订单类型 - 租赁订单 - 字典：lease_order_status
const leaseOrderState = [
  { name: "待付款", value: "pending_payment" },
  { name: "待发货", value: "pending_ship" },
  { name: "租赁中", value: "leased" },
  { name: "已到期", value: "expired" },
  { name: "已买断", value: "buyout" },
  { name: "待评价", value: "pending_evaluate" },
  { name: "已完成", value: "completed" },
  { name: "已取消", value: "cancel" },
];
// 订单类型 - 营养餐订单 - 字典：nutrition_order_status
const nutritionOrderState = [
  { name: "待付款", value: "pending_payment" },
  { name: "待发货", value: "pending_ship" },
  { name: "租赁中", value: "leased" },
  { name: "已到期", value: "expired" },
  { name: "已买断", value: "buyout" },
  { name: "待评价", value: "pending_evaluate" },
  { name: "已完成", value: "completed" },
  { name: "已取消", value: "cancel" },
];
// 订单类型 - 电商品 - 退还单
const returnOrderState = [
  { name: "待审核", value: "auditing" },
  { name: "待退货", value: "returning" },
  { name: "待签收", value: "pending_sign" },
  { name: "已退款", value: "returned" },
  { name: "未过审", value: "unaudited" },
];
// 订单类型 - 医品 - 退还单
const medicalReturnOrderState = [
  { name: "待审核", value: "auditing" },
  { name: "待退货", value: "returning" },
  { name: "已退款", value: "returned" },
  { name: "未过审", value: "unaudited" },
  { name: "待签收", value: "pending_sign" },
];
// 订单状态 （不包括退还单状态）
const orderStatusList = [
  { name: "待付款", value: "pending_payment" },
  { name: "待发货", value: "pending_ship" },
  { name: "待收货", value: "pending_receipt" },
  { name: "待评价", value: "pending_evaluate" },
  { name: "已完成", value: "completed" },
  { name: "待自提", value: "pending_pick" },
  { name: "租赁中", value: "leased" },
  { name: "已到期", value: "expired" },
  { name: "已买断", value: "buyout" },
  { name: "待审核", value: "auditing" },
  { name: "未过审", value: "unaudited" },
  { name: "待发货", value: "pending_stock" },
];
// 评论 - 回复状态
const replyStatus = [{ name: "已回复", value: "replied" }, { name: "未回复", value: "no_reply" }];
// 评论 - 评分
const score = [
  { name: "1分", value: "1" },
  { name: "2分", value: "2" },
  { name: "3分", value: "3" },
  { name: "4分", value: "4" },
  {
    name: "5分",
    value: "5",
  },
];
// 申购单
const purchaseRequisitionStatus = [
  { name: "待审核", value: "auditing" },
  { name: "审核已通过", value: "audited" },
  { name: "已完成", value: "completed" },
  {
    name: "未通过",
    value: "unaudited",
  },
  { name: "已出库", value: "deducted" },
];
// 申购单退还单
const purchaseRequisitionReturnStatus = [
  { name: "待审核", value: "auditing" },
  { name: "审核已通过", value: "pending_return" },
  { name: "待签收", value: "returned" },
  {
    name: "已完成",
    value: "completed",
  },
  { name: "未通过", value: "unaudited" },
];
// 所属用户端
const terminalUnitList = [{ name: "会员", value: "member" }, { name: "商家", value: "merchant" }];
// 启用/锁定状态
const lockingStatus = [
    {name:'启用', value:'normal'}, {name:'锁定', value:'locking'}
]

//所属平台
const platformOptions = [
  { name: '贝美吉', value: 1 },
  { name: 'U药', value: 2 },
]

//申购状态
const subscribeStatesOptions = [
  { name: '开启申购', value: '0' },
  { name: '关闭申购', value: '1' },
]

// 优惠券模块
// 优惠券透出状态
const couponStatus = [
  { name: '未透出', value: '1' },
  { name: '进行中', value: '2' },
  { name: '进行中', value: '3' },
  { name: '已结束', value: '4' }
]
const couponStatusOptions = [
    { name: '未透出', value: '1' },
    { name: '进行中', value: '3' },
    { name: '已结束', value: '4' }
]
// 优惠券使用人群
const couponUsePerson = [
    { name: '所有用户', value: '0' },
    { name: '新客', value: '1' },
    { name: '复购', value: '2' },
    { name: '会员', value: '3' },
]
// 优惠券使用渠道
const useChannel = [
    { name: '全平台', value: '0' },
    { name: '小程序', value: '1' },
    { name: 'APP', value: '2' },
]
const consumePerson = [
    { name: '新客', value: '1' },
    { name: '复购', value: '2' },
    { name: '会员', value: '3' },
]
//优惠方式
const preferenceMode = [
    { name: '扣减', value: '1' },
    { name: '折扣', value: '2' },
]
//关联产品列表状态
const productStatus = [
    { name: '上架', value: 'on_shelves' },
    { name: '待发售', value: 'for_sale' },
    { name: '下架', value: 'off_shelves' },
]
//用户 -- 用户等级
const userLevel = [
    { name: '普通用户', value: 1 },
    { name: '银星用户', value: 2 },
    { name: '玉星用户', value: 3 },
    { name: '金星用户', value: 4 },
]
//身份
const identity = [
  { name: '用户', value: 1 },
  { name: '员工', value: 2 },
]

//满赠模块
//状态
const activityStatus = [
    { name: '未开始', value: 1 },
    { name: '进行中', value: 2 },
    { name: '已结束', value: 3 }
]
const applicableProducts = [
    { name: '医药品', value: 'medicine' }, { name: '电商品', value: 'electronic' }, { name: '吞咽餐', value: 'nutrition' },
]

// 经销产品售后订单状态
// 1-待审核，2-审核通过，3-审核不通过
const afterSalesOrderStatus = [
  { name: '待审核', value: 1 },
  { name: '审核通过', value: 2 },
  { name: '审核不通过', value: 3 }
]
//寄送样本状态
const sendSampleStatus = [
    { name: '未寄送', value: '1' },
    { name: '样本运送中', value: '2' },
    { name: '部分已签收', value: '3' },
    { name: '全部已签收', value: '4' },
]
//检测报告状态
const reportStatus = [
    { name: '未出具', value: '1' },
    { name: '部分已出具', value: '2' },
    { name: '全部已出具', value: '3' },
]

/**
 *  方法
 */
// 获取数组对象内值
const getValue = function(arr, name, val = "name") {
  if (!Array.isArray(arr) || !name || !val) return name;
  return arr[arr.findIndex((item) => item.value === name)] ? arr[arr.findIndex((item) => item.value === name)][val] : name;
};

// 获取数组对象内元素指定值
const getNeedValue = function(arr, obj, name, val) {
  if (!Array.isArray(arr) || !obj || !val) return obj;
  return arr[arr.findIndex((item) => item[name] === obj)] ? arr[arr.findIndex((item) => item[name] === obj)][val] : obj;
};

export default {
    // 分页配置
    pageSizes,
    versionsCode,// 版本号
    // 常用api
    productTypeList,// 产品类别下拉框 api
    productUnitList,// 产品单位下拉框 api
    productList,// 产品集合列表 api
    weChatMerchantList,

    // 高德api
    GD_Key,

    dictionaries,// 字典查询

    // 自定义方法
    getValue,// 获取数组对象内值
    getNeedValue,// 获取数组对象内元素指定值

    // 固定数据
    deliveryMethod,// 配送方式
    orderState,// 订单类型
    orderStatusList,// 订单类型列表（不包括退还单状态）
    EcommerceOrderState,//订单类型-电商品
    nutritionOrderState,//订单类型-营养餐
    returnOrderState,//订单类型-电商品-退还订单
    medicalReturnOrderState,//订单类型-医品-退还订单
    leaseOrderState,//租赁订单类型
    orderClassify,// 商品/订单类型
    replyStatus,//评论的回复状态
    score,//评论的分类列表
    terminalUnitList,//所属用户端
    purchaseRequisitionStatus,//申购单
    purchaseRequisitionReturnStatus,//申购单退还单
    identityListState,//用户身份类型
    articleSource, //来源
    platformOptions,
    subscribeStatesOptions,
    //优惠券模块
    couponStatus,
    couponStatusOptions,
    couponUsePerson,
    useChannel,
    consumePerson,
    preferenceMode,
    productStatus,
    userLevel,
    identity,
    // 满赠模块
    activityStatus,
    applicableProducts,
    articleSource,//来源
    afterSalesOrderStatus,
    sendSampleStatus,
    reportStatus,
}
