<template>
    <div class="main-box">
        <div class="head-search-row">
            <el-form :inline="true" class="demo-form-inline" size="medium">
                <el-form-item label="产品类别">
                    <el-cascader v-model="inquire.customizationCategoryId" :options="categoryList" :props="props" clearable placeholder="请选择"></el-cascader>
                </el-form-item>

                <el-form-item label="姓名">
                    <el-input v-model="inquire.customizationFullName" placeholder="姓名" @keyup.enter.native="searchList"></el-input>
                </el-form-item>

                <el-form-item label="手机">
                    <el-input v-model="inquire.customizationPhoneNumber" placeholder="手机" @keyup.enter.native="searchList"></el-input>
                </el-form-item>

                <el-form-item label="描述">
                    <el-input v-model="inquire.customizationDescription" placeholder="描述" @keyup.enter.native="searchList"></el-input>
                </el-form-item>

                <el-form-item label="提交时间">
                    <el-date-picker
                        v-model="daterange"
                        end-placeholder="结束日期"
                        start-placeholder="开始日期"
                        type="daterange"
                        value-format="yyyy-MM-dd">
                    </el-date-picker>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" @click="searchList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="table">
            <el-main>
                <template>
                    <el-table :data="tableData" border height="400px" style="width: 100%">
                        <el-table-column label="序号" prop="date" width="60">
                            <template v-slot="scope">
                                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="产品类别" min-width="180" prop="customizationCategoryName" show-overflow-tooltip></el-table-column>
                        <el-table-column label="产品描述" min-width="300" prop="customizationDescription" show-overflow-tooltip=""></el-table-column>
                        <el-table-column label="是否知道产品名称" min-width="140" prop="whetherKnow">
                            <template slot-scope="scope">
                                {{ scope.row.whetherKnow ? '是' : '否' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="姓名" min-width="120" prop="customizationFullName"></el-table-column>
                        <el-table-column label="手机" min-width="150" prop="customizationPhoneNumber"></el-table-column>
                        <el-table-column label="提交时间" min-width="160" prop="customizationTime"></el-table-column>
                        <el-table-column label="提交会员微信昵称" min-width="160" prop="memberNickname"></el-table-column>
                        <el-table-column fixed="right" label="操作" min-width="120" prop="">
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination
                        :current-page="pageParam.pageNum"
                        :page-size="pageParam.pageSize"
                        :page-sizes="$api.pageSizes"
                        :total="pageParam.total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </el-main>
        </div>

        <el-dialog
            :before-close="closeDialog_1"
            :visible.sync="dialogVisible_1"
            title="详情"
            top="5vh"
            width="600px">
            <div class="Popout_content">
                <el-form :model="submitForm" label-width="130px">
                    <el-form-item label="产品类别">
                        <el-cascader v-model="submitForm.customizationCategoryId" :options="categoryList" :props="props" class="width-inherit" disabled placeholder="请选择"></el-cascader>
                    </el-form-item>
                    <el-form-item label="是否知道产品名称">
                        {{ submitForm.whetherKnow ? '是' : '否' }}
                    </el-form-item>
                    <el-form-item label="产品描述">
                        <el-input v-model="submitForm.customizationDescription" :rows="3" disabled placeholder="请输入内容" type="textarea"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input v-model="submitForm.customizationFullName" disabled placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="手机">
                        <el-input v-model="submitForm.customizationPhoneNumber" disabled maxlength="11" placeholder="手机"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input v-model="submitForm.customizationEmail" disabled placeholder="邮箱"></el-input>
                    </el-form-item>
                    <el-form-item label="地址">
                        <el-input v-model="submitForm.customizationAddress" disabled placeholder="地址"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog_1">关闭</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            categoryList: [],
            props: {
                label: 'categoryName',
                value: 'categoryId',
                children: 'childes',
                checkStrictly: true,
                emitPath: false
            },
            // 查询
            inquire: {
                customizationCategoryId: '',
                customizationFullName: '',
                customizationPhoneNumber: '',
                customizationDescription: '',
                customizationTimeBegin: '',
                customizationTimeEnd: '',
            },
            daterange: [],// 提交时间
            // 分页
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            // 表格数据
            tableData: [],
            loading: false,


            // ======== 详情 ========
            dialogVisible_1: false,
            submitForm: {
                customizationCategoryId: '',
                whetherKnow: '',
                customizationFullName: '',
                customizationPhoneNumber: '',
                customizationEmail: '',
                customizationAddress: '',
                customizationDescription: ''
            },
            submitRules: {},

        };
    },
    created() {
        this.getDataList()
        // 获取产品类别
        this.getCategoryList()
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size;
            this.getDataList()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page;
            this.getDataList();
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1
            this.getDataList()
        },
        // 获取产品类别
        getCategoryList() {
            this.$http.requestPost({url: '/product/customization/category/list', loading: false}).then(res => {
                this.categoryList = res.data;
            })
        },

        // 获取数据列表
        getDataList() {
            if (!this.daterange) this.daterange = [];
            this.inquire.customizationTimeBegin = this.daterange.length > 0 ? this.daterange[0] : '';
            this.inquire.customizationTimeEnd = this.daterange.length > 0 ? this.daterange[1] : '';

            let param = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            }
            this.$http.requestPost({url: '/product/customization/page', param: param, loading: false}).then(res => {
                this.loading = false;
                this.tableData = res.data.list;
                this.pageParam.total = res.data.total;
            }).catch(err => {
                this.loading = false;
                console.log(err)
            })
        },

        // 详情
        handleDetails(row) {
            this.$http.requestGet({
                url: '/product/customization/get/' + row.customizationId,
                loading: false
            }).then(res => {
                let data = res.data;
                this.submitForm = {
                    customizationCategoryId: data.customizationCategoryId,
                    whetherKnow: data.whetherKnow,
                    customizationFullName: data.customizationFullName,
                    customizationPhoneNumber: data.customizationPhoneNumber,
                    customizationEmail: data.customizationEmail,
                    customizationAddress: data.customizationAddress,
                    customizationDescription: data.customizationDescription
                }
            })
            this.dialogVisible_1 = true;
        },

        // 关闭
        closeDialog_1() {
            this.dialogVisible_1 = false;
        }
    },
};
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
    width: 375px;
}

.el-head-search-row {
    .flex-box {
        width: 326px;
    }
}
</style>
