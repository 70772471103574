<template>
  <!-- 地图 -->
  <div id="map">
    地图展示
  </div>
</template>
<script>
// import area from '@/components/largeScreen/json/东北大区.json'
// 引入echarts
var option = {
  geo: [
    {
      type: 'map',
      silent: true,
      map: 'area',
      zlevel: 0,
      zoom: 1.2, // 当前视角的缩放比例
      roam: false, // 是否开启平游或缩放
      center: undefined,
      show: true,
      label: {
        normal: {
          show: false
        },
        emphasis: {
          show: false
        }
      },
      itemStyle: {
        normal: {
          borderColor: '#3973b8',
          borderWidth: 1,
          areaColor: '#031d6b'
        },
        emphasis: {
          show: false,
          areaColor: '#00bcdf', // 悬浮区背景
          shadowColor: '#00bcdf',
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 10
        }
      },
      select: { // 地图选中区域样式
        label: { // 选中区域的label(文字)样式
          color: '#fff',
          show: false
        },
        itemStyle: {// 选中区域的默认样式
          areaColor: '#00bcdf'
        }
      }
    },
    // //第一层投影
    // {
    //   map: 'area',
    //   zlevel: -1,
    //   zoom: 1.22, //当前视角的缩放比例
    //   roam: false, //是否开启平游或缩放
    //   center: undefined,
    //   show: true,
    //   label: {
    //     normal: {
    //       show: false,
    //     },
    //     emphasis: {
    //       show: false,
    //     },
    //   },
    //   itemStyle: {
    //     normal: {
    //       borderJoin: "round",
    //       borderColor: "rgba(176,228,252,1)",
    //       borderWidth: 3,
    //       areaColor: "rgba(133,188,232,1)",
    //       shadowColor: "rgba(133,188,232,.7)",
    //       shadowOffsetX: 0,
    //       shadowOffsetY: 0,
    //       shadowBlur: 25,
    //     },
    //     emphasis: {
    //       show: false,
    //     },
    //   },
    // },
    // 第二层投影
    {
      map: 'area',
      zlevel: -2,
      zoom: 1.2, // 当前视角的缩放比例
      roam: false, // 是否开启平游或缩放
      center: undefined,
      show: true,
      label: {
        normal: {
          show: false
        },
        emphasis: {
          show: false
        }
      },
      itemStyle: {
        normal: {
          borderJoin: 'round',
          borderColor: '#98dcfb',
          borderWidth: 3,
          areaColor: '#0162aa',
          shadowColor: '#0162aa',
          shadowOffsetX: 0,
          shadowOffsetY: 8
        },
        emphasis: {
          show: false
        }
      }
    }
  ],
  series: [
    {
      name: 'city',
      type: 'map',
      geoIndex: 0,
      // 不显示当前的散点图的信息，用label中的formatter不可识别html标签，tooltip可识别
      label: {
        normal: {
          show: false
        }
      },
      itemStyle: {
        normal: {
          borderColor: '#fff',
          borderWidth: 1
        },
        emphasis: {
          areaColor: '#ff6600'
        }
      },
      // 获取数据
      data: [
        {
          name: '',
          selected: true
        }
      ]

    }
  ]
}
import * as echarts from 'echarts'

export default {
  name: 'Map',
  data() {
    return {
      myChart: null
    }
  },
  computed: {},
  created() {
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart(JSON) {
      // 需要注册地区，注意这个名称就是后边引用地图名称QD
      // 用ref,注意一定要节点 初始化完成
      var chartDom = document.getElementById('map')
      this.myChart = echarts.init(chartDom)
      echarts.registerMap('area', { geoJSON: JSON })
      window.addEventListener('resize', function() {
        this.myChart && this.myChart.resize()
      })
      this.myChart.setOption(option)
    },
    updateChart(data) {
      // 判断是否为东部沿海省，是则弹窗显示在左侧
      const province = ['吉林', '辽宁', '黑龙江', '河北', '北京', '天津', '上海', '山东', '江苏', '浙江', '台湾', '山西', '河南', '安徽', '江西', '福建', '广东', '香港', '澳门']
      let direction = ''
      province.includes(data[0].name) ? direction = 'right_direction' : direction = ''
      const option = this.myChart.getOption()
      // console.log('option: ' , option);
      option.series[0].data = data
      option.tooltip = {
        trigger: 'item',
        triggerOn: 'click',
        showDelay: 0,
        hideDelay: 0, // 隐藏延迟时间为0
        confine: true, //
        alwaysShowContent: true,
        formatter: function(params) {
          // console.log('params: ' , params);
          const item = data[0]
          let res = ''
          res = `<div class='tooltip-style ${direction}'>
              <div class="tooltip-box">
              <div class="tooltip-left"><span style="color: #FFFFFF;">${item.title}：</span></div>
              <div class="tooltip-right"><span style="color: #65A5DD;">${item.attachedName}</span></div>
              </div>
              <div class="tooltip-box" style="margin-top:4px">
              <div class="tooltip-left"><span style="color: #FFFFFF;">区域：</span></div>
              <div class="tooltip-right"><span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: #65A5DD;">${item.region}</span></div>
              </div>
              <div class="tooltip-box" style="margin-top:4px">
              <div class="tooltip-left"><span style="color: #FFFFFF;">产品：</span></div>
              <div class="tooltip-right"><span style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: #65A5DD;">${item.product}</span></div>
              </div>
              <div class="tooltip-box" style="margin-top:4px">
              <div class="tooltip-left"><span style="color: #FFFFFF;">金额：</span></div>
              <div class="tooltip-right"><span style="color: #65A5DD;">￥</span><span style="color: #65A5DD;">${item.amount}</span</div>
              </div>
            </div>`
          return res
        }
      }
      this.myChart.setOption(option)
      console.log('option: ', this.myChart.getOption())
    },
    async setMap(JSON, geo, data) {
      echarts.registerMap('area', { geoJSON: JSON })
      if (geo) {
        // 更新图表数据
        await this.updateChart(data)
        //  this.myChart.dispatchAction({
        //    type: "hideTip",
        //  });
        //   this.myChart.dispatchAction({
        //     type: "downplay",
        //     name: geo,
        //   });
        this.myChart.dispatchAction({
          type: 'highlight',
          name: geo
        })
        this.myChart.dispatchAction({
          type: 'showTip',
          seriesIndex: 0, // 这行不能省
          name: geo
        })
        // let timer = null
        // timer = setTimeout(() => {
        //   this.myChart.dispatchAction({
        //   type:'hideTip',
        // })
        //   this.myChart.dispatchAction({
        //     type: 'downplay',
        //     name: geo,
        //   })
        //   clearTimeout(timer)
        // }, 8000);
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#map {
  width: 100%;
  height: 100%;
}

/deep/ div {
  padding: 0 !important;
}

/deep/ .tooltip-style {
  width: 294px;
  height: 152px;
  padding: 10px !important;
  position: absolute;
  bottom: 0;
  left: 20px;
  // background: #1d1f2d;
  // background: url(./image/dt1.png);
  // background-size: cover;
  background: #102387;
  &.right_direction{
    right: 20px;
    left: auto;
  }
}

/deep/ .tooltip-box {
  width: 274px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .tooltip-left {
  font-weight: 700;
  font-size: 14px;
  color: #8CCCE5;
  width: 60px;
  height: 30px;
  background: url(./image/dt2.png);
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .tooltip-right {
  width: 212px;
  height: 30px;
  font-weight: 400;
  font-size: 14px;
  color: #A2ECFE;
  background: url(./image/dt3.png);
  background-size: cover;
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
  }
}
</style>
