<template>
    <!-- 电商品-待发货订单 -->
    <div class="main-box">
        <e-commerce orderStatus="pending_ship"></e-commerce>
    </div>
</template>

<script>
import eCommerce from '@/components/order/eCommerce.vue'

export default {
    components: {
        eCommerce
    },
    data() {
        return {
        
        };
    },
    methods: {
       
    },
};
</script>

<style lang="scss" scoped></style>
