<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="大区名称">
          <el-input v-model="inquire.regionName" placeholder="大区名称" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item label="片区名称">
          <el-input v-model="inquire.districtName" placeholder="片区名称" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item label="办事处名称">
          <el-input v-model="inquire.agencyName" placeholder="办事处名称" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item label="增加时间">
          <div class="flex-box">
            <el-date-picker
              v-model="daterange"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增办事处</el-button>
      <!-- <el-button size="small" type="primary">导入</el-button>
      <el-button size="small" type="primary">导出</el-button> -->
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="agencyName" label="办事处名称" min-width="160" />
            <el-table-column prop="regionName" label="所属大区" min-width="120" />
            <el-table-column prop="districtName" label="所属片区" min-width="120" />
            <el-table-column prop="agencyComment" label="备注" min-width="160" show-overflow-tooltip />
            <el-table-column prop="hospitalCount" label="医院数量" min-width="100" />
            <el-table-column prop="principal" label="负责人" min-width="120" />
            <el-table-column prop="agencyOrder" label="排序" min-width="120" />
            <el-table-column prop="createTime" label="增加时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="200" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="hospitalList(scope.row)">医院列表</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="deleteData(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加办事处弹框 -->
    <el-dialog
      :title="dialogTitle + '办事处'"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="650px"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="110px" :model="submitForm" :rules="submitRules">
          <el-form-item label="所属片区" prop="districtId">
            <el-cascader
              v-model="submitForm.districtId"
              :props="props_add"
              :options="addressList_add"
              placeholder="所属片区"
              class="width-inherit"
            />
          </el-form-item>
          <el-form-item label="办事处名称" prop="agencyName">
            <el-input v-model="submitForm.agencyName" maxlength="20" placeholder="办事处名称" />
          </el-form-item>
          <el-form-item label="关联地区" prop="addressIds">
            <el-select v-model="submitForm.addressIds" filterable multiple placeholder="请选择" class="width-inherit">
              <el-option
                v-for="item in relatedAreasList"
                :key="item.addressId"
                :label="item.addressName"
                :value="item.addressId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="agencyComment">
            <el-input v-model="submitForm.agencyComment" type="textarea" maxlength="50" :rows="2" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="排序" prop="agencyOrder">
            <el-input v-model="submitForm.agencyOrder" maxlength="6" placeholder="排序" />
          </el-form-item>
          <el-form-item label="负责人" prop="managerIds">
            <div>
              <el-button type="primary" size="small" @click="openAttacheList">选择</el-button>
            </div>
            <div>
              <el-table :data="attacheTable" border style="width: 100%">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">
                    {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
                  </template>
                </el-table-column>
                <el-table-column prop="managerName" label="姓名" min-width="100" />
                <el-table-column prop="managerPhoneNumber" label="手机号" min-width="120" />
                <el-table-column prop="" label="操作" min-width="120" fixed="right">
                  <template slot-scope="scope">
                    <span class="operation-btn" @click="delUser(scope.row, scope.$index)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 负责列表弹框 -->
    <el-dialog
      title="选择负责人"
      :visible.sync="dialogVisible_2"
      :before-close="() => dialogVisible_2 = false"
      width="80%"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="姓名">
            <el-input v-model="inquire_2.managerName" placeholder="" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="getUserList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="multipleTable"
          v-loading="loading_2"
          :data="tableData_2"
          border
          height="400px"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{
              (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
            }}
            </template>
          </el-table-column>
          <el-table-column prop="managerName" label="名称" min-width="120" />
          <el-table-column prop="managerTelephone" label="手机号" min-width="160" />
        </el-table>

        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_2.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_2.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_2.total"
            @size-change="handleSizeChange_2"
            @current-change="handleCurrentChange_2"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmMerchant">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 医院列表弹框 -->
    <el-dialog
      :title="dialogTitle_3 + '关联医院列表'"
      :visible.sync="dialogVisible_3"
      :before-close="() => dialogVisible_3 = false"
      width="80%"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="医院名称">
            <el-input v-model="inquire_3.hospitalName" placeholder="医院名称" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="getHospitalList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table v-loading="loading_3" :data="tableData_3" border height="400px" style="width: 100%">
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{
              (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
            }}
            </template>
          </el-table-column>
          <el-table-column prop="hospitalCode" label="医院编号" min-width="200" show-overflow-tooltip/>
          <el-table-column prop="hospitalName" label="医院名称" min-width="200" show-overflow-tooltip/>
          <el-table-column prop="regionName" label="所属大区" min-width="120" show-overflow-tooltip/>
          <el-table-column prop="addressName" label="所在地区" min-width="200" show-overflow-tooltip/>
          <el-table-column prop="address" label="详细地址" min-width="200" show-overflow-tooltip/>
          <el-table-column prop="merchantName" label="所属商家" min-width="120" />
        </el-table>

        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_3.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_3.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_3.total"
            @size-change="handleSizeChange_3"
            @current-change="handleCurrentChange_3"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_3 = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
    data() {
        return {
            // 查询参数
            inquire: {
                regionName: '',
                districtName: '',
                agencyName: '',
                createTimeBegin: '',
                createTimeEnd: ''
            },
            daterange: [], // 增加时间
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            loading: false,
            // 表格数据
            tableData: [],

            selectType: [
                { name: '药房名称', id: '药房名称' },
                { name: '联系人姓名', id: '联系人姓名' },
                { name: '手机号', id: '手机号' },
                { name: '电话', id: '电话' },
                { name: '药房编号', id: '药房编号' },
                { name: '所属商家', id: '所属商家' },
                { name: '业务员姓名', id: '业务员姓名' }
            ],
            // 添加办事处地址
            dialogTitle: '添加', // 添加弹窗标题
            dialogVisible: false, // 添加弹窗
            submitForm: {
                districtId: '',
                agencyName: '',
                agencyComment: '',
                agencyOrder: '',
                addressIds: [],
                managerIds: ''
            },
            submitRules: {
                districtId: [{ required: true, message: '请选择所属片区', trigger: 'blur' }],
                agencyName: [{ required: true, message: '请输入办事处名称', trigger: 'blur' }],
                addressIds: [{ required: true, message: '请选择关联地区', trigger: 'blur' }],
                // agencyComment: [{ required: true, message: '请输入备注', trigger: 'blur' }],
                agencyOrder: [{ required: true, message: '请输入排序', trigger: 'blur' },
                  { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
                ],
                managerIds: [{ required: true, message: '请选择负责人', trigger: 'blur' }]
            },
            attacheTable: [],
            isAdd: true, // 是否为添加
            editRow: {},

            // 区域列表
            addressList_add: [],
            props_add: {
                label: 'areaName',
                value: 'areaId',
                children: 'childes',
                // checkStrictly: true,
                emitPath: false
            },
            // 关联地区
            relatedAreasList: [],
            props_related: {
                label: 'addressName',
                value: 'addressId',
                children: 'child',
                // checkStrictly: true,
                emitPath: false
            },

            selectRow: [], // 选择当前行
            // ==== 负责人弹框 ====
            dialogVisible_2: false,
            inquire_2: {
                managerName: ''
            },
            loading_2: false,
            tableData_2: [],
            pageParam_2: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },

            // ==== 关联医院弹框 ====
            dialogVisible_3: false,
            inquire_3: {
                hospitalName: ''
            },
            loading_3: false,
            tableData_3: [],
            pageParam_3: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            dialogTitle_3: ''

        }
    },
    created() {
        // 获取数据列表
        this.getTableList()
        // 获取大区-片区
        this.getDistrictTree()
        // 获取关联地区 - 市列表
        this.getRelatedAreas()
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size
            // 刷新(数据)
            this.getTableList()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page
            // 刷新(页码)
            this.getTableList()
        },
        // 刷新
        searchList() {
          this.pageParam.pageNum = 1
          this.getTableList()
        },
        // 获取大区-片区
        getDistrictTree() {
            this.$http.requestGet({ url: '/operation/area/district/tree', loading: false }).then(res => {
                this.addressList_add = res.data
            })
        },
        // 获取关联地区 - 市列表
        getRelatedAreas() {
            // this.$http.requestGet({
            //     url: '/common/address',
            //     loading: false
            // }).then(res => {
            //     this.relatedAreasList = res.data
            // })

            this.$http.requestPost({
                url: '/common/address/list',
                param: { addressLevel: 2 },
                loading: false
            }).then(res => {
                this.relatedAreasList = res.data
            })
        },

        // 获取数据列表
        getTableList() {
            if (!this.daterange) this.daterange = []
            this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : ''
            this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : ''
            const data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            }

            this.loading = true
            this.$http.requestPost({
                url: '/operation/area/agency/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false
                this.tableData = res.data.list
                this.pageParam.total = res.data.total
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },

        // 添加办事处
        addition() {
            this.dialogTitle = '添加'
            this.dialogVisible = true
            this.isAdd = true
        },
        // 修改办事处
        handleEdit(row) {
            this.$http.requestGet({ url: '/operation/area/agency/get/' + row.agencyId, loading: false }).then(res => {
                const data = res.data
                this.submitForm = {
                    districtId: data.districtId,
                    agencyName: data.agencyName,
                    addressIds: data.addresses,
                    agencyComment: data.agencyComment,
                    agencyOrder: data.agencyOrder,
                    managerIds: data.managers
                }
                this.attacheTable = res.data.managers
            })
            this.dialogTitle = '编辑'
            this.isAdd = false

            this.dialogVisible = true
            this.editRow = row
        },
        // 提交办事处添加
        submitAdd() {
            this.$refs.submitForm.validate(valid => {
                if (valid) {
                    const ids = this.attacheTable.map(item => item.managerId)
                    const param = {
                        districtId: this.submitForm.districtId,
                        agencyName: this.submitForm.agencyName,
                        addressIds: this.submitForm.addressIds,
                        managerIds: ids,
                        agencyComment: this.submitForm.agencyComment,
                        agencyOrder: this.submitForm.agencyOrder
                    }
                    if (!this.isAdd) {
                        param.agencyId = this.editRow.agencyId
                    }
                    this.$http.requestPost({
                        url: '/operation/area/agency/save',
                        param: param
                    }).then(res => {
                        this.getTableList()
                        this.$message({
                            message: '操作成功',
                            type: 'success'
                        })
                        this.closeDialog()
                    })
                } else {
                    return false
                }
            })
        },

        // 关闭弹窗
        closeDialog() {
            this.$refs.submitForm && (this.$refs.submitForm.resetFields())
            this.dialogVisible = false
            this.submitForm = {
                agencyName: '',
                managerIds: '',
                addressIds: '',
                agencyComment: '',
                agencyOrder: ''
            }
            this.attacheTable = []
        },

        // 删除
        deleteData(row) {
            this.$confirm('确定删除该办事处?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
                    this.pageParam.pageNum--
                }
                this.$http.requestDel({ url: '/operation/area/agency/del/' + row.agencyId }).then(res => {
                    this.getTableList()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    })
                })
            })
        },

        // ========== 负责人 ==========
        // 页容量改变
        handleSizeChange_2(size) {
            this.pageParam_2.pageNum = 1;
            this.pageParam_2.pageSize = size
            // 刷新(数据)
            this.getUserList()
        },
        // 页码改变
        handleCurrentChange_2(page) {
            this.pageParam_2.pageNum = page
            // 刷新(页码)
            this.getUserList()
        },

        // 打开负责人列表
        openAttacheList() {
            this.dialogVisible_2 = true
            this.getUserList()
        },

        // 查询负责人列表
        getUserList() {
            const data = {
                pageNum: this.pageParam_2.pageNum,
                pageSize: this.pageParam_2.pageSize,
                param: {
                    name: this.inquire_2.managerName
                }
            }

            this.loading_2 = true
            this.$http.requestPost({
                url: '/system/manager/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading_2 = false
                this.tableData_2 = res.data.list
                this.pageParam_2.total = res.data.total
                if (this.attacheTable.length > 0) {
                    this.$nextTick(() => {
                        const lenArr = []
                        this.attacheTable.forEach(e => {
                            this.tableData_2.forEach((v, i) => {
                                if (e.managerId === v.managerId) {
                                    lenArr.push(i)
                                }
                            })
                        })

                        lenArr.forEach(v => {
                            this.$refs.multipleTable.toggleRowSelection(this.tableData_2[v])
                        })
                    })
                }
            }).catch(err => {
                this.loading_2 = false
                console.log(err)
            })
        },

        // 确定负责人的选择
        confirmMerchant() {
            this.submitForm.managerIds = '123'
            this.attacheTable = this.selectRow
            this.dialogVisible_2 = false
        },

        // 表格多选
        handleSelectionChange(news) {
            this.selectRow = news
        },

        // 删除当前选择负责人
        delUser(row, index) {
            this.selectRow.splice(index, 1)
        },

        // ========== 医院列表 ==========
        // 页容量改变
        handleSizeChange_3(size) {
            this.pageParam_3.pageNum = 1;
            this.pageParam_3.pageSize = size
            // 刷新(数据)
            this.getHospitalList()
        },
        // 页码改变
        handleCurrentChange_3(page) {
            this.pageParam_3.pageNum = page
            // 刷新(页码)
            this.getHospitalList()
        },

        // 打开关联医院列表
        hospitalList(row) {
            this.dialogTitle_3 = row.agencyName
            this.dialogVisible_3 = true
            this.editRow = row;
            this.getHospitalList()
        },

        // 查询关联医院列表
        getHospitalList() {
            const data = {
                pageNum: this.pageParam_3.pageNum,
                pageSize: this.pageParam_3.pageSize,
                param: {
                  agencyId: this.editRow.agencyId,
                  hospitalName: this.inquire_3.hospitalName
                }
            }

            this.loading_3 = true
            this.$http.requestPost({
                url: '/hospital/medical/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading_3 = false
                this.tableData_3 = res.data.list
                this.pageParam_3.total = res.data.total
            }).catch(err => {
                this.loading_3 = false
                console.log(err)
            })
        }

    }
}
</script>

<style lang="scss" scoped>
.address-hierarchy {
    flex-wrap: wrap;
}
</style>
