import defaultSettings from '@/settings'

const { showSettings, fixedHeader, sidebarLogo } = defaultSettings

const state = {
    showSettings: showSettings,
    fixedHeader: fixedHeader,
    sidebarLogo: sidebarLogo,
    windowWidth1260: true,
    windowWidth768: true
}

const mutations = {
    CHANGE_SETTING: (state, { key, value }) => {
        if (state.hasOwnProperty(key)) {
            state[key] = value
        }
    },
    setWindowWidth: (state, value) => {
        state.windowWidth1260 = window.innerWidth < 920
        state.windowWidth768 = window.innerWidth < 768
    }
}

const actions = {
    changeSetting({ commit }, data) {
        commit('CHANGE_SETTING', data)
    },
    setWindowWidth({ commit }, data) {
        commit('setWindowWidth', data)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

