<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form class="demo-form-inline" ref="submitForm" :model="submitForm" :rules="submitRules" size="medium">
        <el-form-item label="生产单编号" class="color_light-grey">生产单编号在入库成功后自动生产</el-form-item>

        <el-form-item label="入库仓库" prop="warehouseId">
          <el-select v-model="submitForm.warehouseId" placeholder="请选择">
            <el-option v-for="(item,index) in warehouseList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="入库产品" prop="batchItems">
          <div style="display:flex; justify-content:flex-end;">
            
              <el-button type="primary" @click="handleSelectBatch">选择批次</el-button>
              <el-button type="primary" @click="addProduct">添加批次</el-button>
          </div>
        </el-form-item>


        <el-form-item label="">
          <el-table :data="submitForm.batchItems" border>
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="inventoryId" label="SKU编号" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="productCode" label="SPU编号" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="productCategoryName" label="所属类别" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="count" label="入库数量" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="no" label="批次" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="produceTime" label="生产日期" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="expireTime" label="到期时间" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="termOfValidity" label="有效期（天）" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="deleteProduct(scope.row.$index)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item>
          <div class="flex-box flex-justify submit-btn">
            <el-button type="primary" @click="submitData">保 存</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <!-- 添加批次弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :before-close="closeDialog" width="80%" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="产品类别">
            <el-cascader v-model="productCategoryId" :props="props" :options="productTypeList" clearable></el-cascader>
          </el-form-item>

          <el-form-item label="增加时间">
            <div class="flex-box">
              <el-date-picker v-model="daterange" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
            </div>
          </el-form-item>

          <el-form-item label="">
            <div class="flex-box">
              <el-select v-model="selectKey" placeholder="请选择" @change="selectValue = ''" class="width-140">
                <el-option label="产品编号" value="productNo"></el-option>
                <el-option label="产品名称" value="productName"></el-option>
              </el-select>
              <el-input v-model="selectValue" placeholder="" class="width-180"></el-input>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="searchList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table ref="sku_list" :data="sku_list" @selection-change="handleProductChange" v-loading="loading" :border="true" height="300px" style="width: 100%">
          <el-table-column type="selection" :selectable="selectable" width="55"></el-table-column>
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
          </el-table-column>
          <el-table-column prop="inventoryId" label="SKU编号" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="skuInfo" label="规格信息" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productCode" label="产品编号" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productName" label="产品名称" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productCategoryName" label="所属类别" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productInventoryTotal" label="库存" min-width="120"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
        <p style="padding-top: 30px"></p>

        <el-form ref="addProductForm" :model="addProductForm" :rules="addProductRules" label-width="100px" class="form demo-form-inline">
          <el-form-item label="批次" prop="no">
            <el-input v-model="addProductForm.no" maxlength="50" placeholder="批次"></el-input>
          </el-form-item>
          <el-form-item label="入库数量" prop="count">
            <el-input v-model="addProductForm.count" @input="verifyInt($event, 'count')" max="8" placeholder="入库数量"></el-input>
          </el-form-item>
          <el-form-item label="生产日期">
            <el-date-picker v-model="addProductForm.produceTime" type="datetime" class="width-inherit" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"></el-date-picker>
          </el-form-item>
          <el-form-item label="到期时间" prop="expireTime">
            <el-date-picker v-model="addProductForm.expireTime" type="datetime" class="width-inherit" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>


     <!-- 选择批次/ 添加批次弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible_1" :before-close="closeDialog" width="90%" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="">
            <div class="flex-box">
              <el-select v-model="selectKey_1" placeholder="请选择" @change="selectValue_1 = ''" class="width-140">
                <el-option label="批次" value="batchNo"></el-option>
                <el-option label="产品编号" value="productCode"></el-option>
                <el-option label="产品名称" value="productName"></el-option>
              </el-select>
              <el-input v-model="selectValue_1" placeholder="" class="width-180"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="" >
            <div class="flex-box">
              <el-select v-model="selectKey_2" placeholder="请选择" @change="selectValue_2 = ''" class="width-140">
                  <el-option label="生产时间" value="produceTime"></el-option>
                  <el-option label="到期时间" value="expireTime"></el-option>
                </el-select>
              <div class="flex-box">
                <el-date-picker v-model="daterange" type="daterange" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
              </div>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="searchList_1">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table ref="carTable" :data="batchList" @selection-change="handleProductChange_1" v-loading="loading" :border="true" height="350px" style="width: 100%">
          <el-table-column type="selection" :selectable="selectable" width="55"></el-table-column>
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{( pageParam_1.pageNum - 1) * pageParam_1.pageSize + (scope.$index + 1) }}</template>
          </el-table-column>
          <el-table-column prop="inventoryId" label="SKU编号" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productId" label="SPU编号" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="norm" label="规格" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productName" label="产品名称" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="categoryName" label="所属类别" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="batchNo" label="批次" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="produceTime" label="生产日期" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="expireTime" label="到期时间" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="termOfValidity" label="有效期" min-width="120"></el-table-column>
          <el-table-column prop="createTime" label="入库时间" min-width="180"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination background :current-page="pageParam_1.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam_1.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam_1.total" @size-change="handleSizeChange_1" @current-change="handleCurrentChange_1" />
        </div>
        <p style="padding-top: 30px"></p>

        <el-form ref="batchForm" :model="batchForm" :rules="batchFormRules" label-width="100px" class="form demo-form-inline">
          <el-form-item label="入库数量" prop="count">
            <el-input v-model="batchForm.count" @input="verifyInt($event, 'count')" max="8" placeholder="入库数量"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd_1">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 录入生产单
      warehouseList: [],// 仓库列表
      submitForm: {
        warehouseId: '',
        batchItems: []
      },
      submitRules: {
        warehouseId: [{ required: true, message: "请选择仓库", trigger: "change" }],
        batchItems: [{ required: true, message: "请添加产品", trigger: "change" }],
      },

      // 添加产品
      dialogVisible: false,
      // 产品类别 级联选项数据
      productTypeList: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        // emitPath: false 
      },
      productCategoryId: [],
      inquire: {
        categoryId1: '',
        categoryId2: '',
        productNo: '',
        productName: '',
        startTime:'',
        endTime:''
      },
      selectKey: 'productNo',
      selectValue: '',
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      sku_list: [], // 产品sku列表
      selectProduct: [],// 选择的产品sku列表
      loading: false,
      addProductForm: {
        id: '',
        productId: '',// 产品ID（SPU）
        inventoryId: '',// 库存ID（SKU）
        productName: '',
        no: '',
        count: '',
        produceTime: '',
        expireTime: ''
      },
      addProductRules: {
        no: [{ required: true, message: "请输入批次", trigger: "blur" }],
        count: [{ required: true, message: "请输入入库数量", trigger: "blur" }],
        expireTime: [{ required: true, message: "请选择到期时间", trigger: "change" }],
      },


      /* 选择批次  */
      dialogVisible_1:false,
      selectKey_1: 'batchNo', // 批次类别
      selectValue_1: '',
      selectKey_2:'produceTime',
      selectValue_2:'',
      batchForm:{
        count: '' 
      },
      batchFormRules: {
        count: [{ required: true, message: "请输入入库数量", trigger: "blur" }],
      },
      inquire_1:{
          typeKey:'',
          timeType:'',
          typeValue:'',
          endTime:'',
          beginTime:''
      },
      pageParam_1: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      batchList:[],
      daterange:[],
      batchId:'',  // 选择的批次id
      selectionRow:[],
      batchTitle: '',
      dialogTitle: ''
    }
  },
  created() {
    this.getWarehouseList();
    this.getProductType();
  },
  methods: {
    // 保存
    submitData() {
      let that = this;
      that.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            warehouseId: this.submitForm.warehouseId,
            batchItems: []
          }
          that.submitForm.batchItems.map(v => {
            let obj = {
              no: v.no,
              expireTime: v.expireTime,
              productName: v.productName,
              productId: v.productId,
              count: v.count,
              inventoryId: v.inventoryId,
              produceTime: v.produceTime,
              productCode:v.productCode
            }
            param.batchItems.push(obj)
          })
          that.$confirm('是否确认保存？', '保存批次', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then((res) => {
              that.$http.requestPost({
                url: '/produce/record/save',
                param
              }).then(res => {
                that.$message.success("录入成功")
                setTimeout(() => {
                  that.$router.push({
                    path: '/warehouse/warehousing/warehousedProductionOrder',
                    query: {}
                  })
                }, 1500);
              })
            })
          
        } else { return false }
      })

    },
    // 删除添加的产品
    deleteProduct(index) {
      this.submitForm.batchItems.splice(index, 1)
    },

    // ================ 添加产品 ================
    // 添加产品
    addProduct() {
      this.$refs.submitForm.validateField('warehouseId', (valid) => {
        if (!valid) {
          this.batchTitle = '';
          if(this.warehouseList.find(item => item.id)){
            this.batchTitle = ' 仓库：' + this.warehouseList.find(item => item.id == this.submitForm.warehouseId).name || ''
          }
          this.dialogTitle = '添加批次' + this.batchTitle;
          this.dialogVisible = true;
          this.inquire.startTime = '';
          this.inquire.beginTime = '';
          this.getProductSKU_list();
        }
      })
    },
    // 获取产品sku列表
    getProductSKU_list() {
      let le = this.productCategoryId.length;
      this.inquire.categoryId1 = le > 0 ? this.productCategoryId[0] : '';
      this.inquire.categoryId2 = le > 1 ? this.productCategoryId[1] : '';
      let param = JSON.parse(JSON.stringify(this.inquire))
      param[this.selectKey] = this.selectValue;
      console.log(this.daterange)
      if(this.daterange.length>=1){
        param.beginTime = this.daterange[0] + ' 00:00:00';
        param.endTime = this.daterange[1] + ' 00:00:00';
      }

      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param
      }
      this.loading = true;
      this.$http.requestPost({
        url: '/product/getSKUs',
        param: data,
        loading: false
      }).then(res => {
        const { list, total } = res.data;
        this.sku_list = list;
        this.pageParam.total = total;
        this.loading = false;
      }).catch(err => {
        this.loading = false;
      })
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getProductSKU_list()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getProductSKU_list();
    },
    // 搜索
    searchList() {
      this.pageParam.pageNum = 1;
      this.getProductSKU_list()
    },
    // 添加产品 - 选择产品
    handleProductChange(e) {
      this.selectProduct = e;
    },
    // 添加产品 - 关闭弹窗
    closeDialog() {
      this.dialogVisible = false;
      this.addProductForm = {
        no: "",
        count: "",
        produceTime: "",
        expireTime: ""
      };
      this.$refs.addProductForm && this.$refs.addProductForm.resetFields();
      
      this.dialogVisible_1 = false;
      this.inquire_1 = {
          typeKey:'',
          timeType:'',
          typeValue:'',
          endTime:'',
          beginTime:''
      }
      this.selectKey_1 = 'batchNo'
      this.selectValue_1 = ''
    },
    // 添加产品弹窗 - 判断当前项是否可选
    selectable(row, index) {
      // 当有已选择产品sku，将已选择产品sku在新增弹窗禁止勾选
      if (this.submitForm.batchItems.length > 0) {
        let arr = this.submitForm.batchItems.map(item => item.inventoryId)
        if (arr.indexOf(row.inventoryId) != -1) {
          return false;
        }
      }
      return true
    },
    // 添加产品
    submitAdd() {
      this.$refs.addProductForm.validate(valid => {
        if (valid) {
          if (this.selectProduct.length < 1) {
            this.$message.warning("请选择产品")
            return
          }
          let form = this.addProductForm;
          let arr = JSON.parse(JSON.stringify(this.selectProduct));

          let d = new Date(form.expireTime);
          let timeStamp = d - new Date(form.produceTime);
          let termOfValidity = timeStamp / (24 * 60 * 60 * 1000);

          arr.map(v => {
            v.no = form.no,
              v.count = form.count,
              v.produceTime = form.produceTime,
              v.expireTime = form.expireTime,
              v.termOfValidity = isNaN(termOfValidity) ? '' : termOfValidity.toFixed(0)
          })
          this.submitForm.batchItems.push(...arr)
          this.$refs.sku_list.clearSelection();
          this.closeDialog()
        } else { return false }
      })
    },


    // ================ 其它 ================
    // 获取仓库列表
    getWarehouseList() {
      let data = {
        pageNum: 1,
        pageSize: 10000
      }
      this.$http.requestPost({
        url: '/warehouse/page',
        param: data,
        loading: false
      }).then(res => {
        this.warehouseList = res.data.list;
      })
    },
    // 获取产品类型
    getProductType() {
      this.$api.productTypeList({}).then(res => {
        this.productTypeList = res.data;
      })
    },
    // 校验是否正整数
    verifyInt(e, name) {
      let verify = this.$validate.verifyInt(e)
      if (!verify) {
        this.addProductForm[name] = '';
      }
    },


    /**===========  批次  ===============  */
    handleSelectBatch(){
      this.$refs.submitForm.validateField('warehouseId', (valid) => {
        if (!valid) {
          this.batchTitle = '';
          if(this.warehouseList.find(item => item.id)){
            this.batchTitle = ' 仓库：' + this.warehouseList.find(item => item.id == this.submitForm.warehouseId).name || ''
          }
          this.dialogTitle = '选择批次' + this.batchTitle;
          this.dialogVisible_1 = true;
          this.getBatchList();
        }
      })
    },
    getBatchList(){
      let param = JSON.parse(JSON.stringify(this.inquire_1))
      param.typeKey = this.selectKey_1;
      param.typeValue = this.selectValue_1;
      param.timeType = this.selectKey_2;
      param.beginTime = this.daterange[0];
      param.endTime = this.daterange[1];
      param.warehouseId = this.submitForm.warehouseId;
      let data = {
        pageNum: this.pageParam_1.pageNum,
        pageSize: this.pageParam_1.pageSize,
        param
      }
      this.$http.requestPost({
        url:'/produce/record/headBatchList',
        param:data
      }).then(res=>{
        const { list, total } = res.data;
        this.batchList = list;
        this.pageParam_1.total = total;
        this.loading = false;

        list.map(v => {
          let d = new Date(v.expireTime);
          let timeStamp = d - new Date(v.produceTime);
          let termOfValidity = timeStamp / (24 * 60 * 60 * 1000);

          v.termOfValidity = isNaN(termOfValidity) ? '' : termOfValidity.toFixed(0);
        })
      }).catch(err => {
        this.loading = false;
      })
    },
    // 页容量改变
    handleSizeChange_1(size) {
      this.pageParam_1.pageNum = 1;
      this.pageParam_1.pageSize = size;
      // 刷新(数据)
      this.getBatchList()
    },
    // 页码改变
    handleCurrentChange_1(page) {
      this.pageParam_1.pageNum = page;
      // 刷新(页码)
      this.getBatchList();
    },
    // 搜索
    searchList_1() {
      this.pageParam_1.pageNum = 1;
      this.getBatchList()
    },
    // 添加批次 - 选择批次
    handleProductChange_1(selection) {
      console.log(selection)
      if(selection.length>1){
        this.$refs.carTable.clearSelection();
        this.$refs.carTable.toggleRowSelection(selection.pop());
        return;
      }
      this.selectionRow = selection;
    },
    submitAdd_1(){
      let that = this;
      that.$refs.batchForm.validate(valid => {
        if (valid) {
          if(that.selectionRow.length<=0){
            return that.$message.warning('请选择批次')
          };
          let data = {
            count: that.batchForm.count,
            id: that.selectionRow[0].batchId
          }
           that.$confirm('是否确认选择', '选择完批次', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then((res) => {
              console.log(res)
              if(res == 'confirm'){
                that.$http.requestPost({
                  url:'/produce/record/addBatchCount',
                  param: data
                }).then(res=>{
                  if(res.data){
                    that.$message.success('操作成功');

                    that.$refs.carTable.clearSelection();
                    that.closeDialog();
                    setTimeout(()=>{
                        that.$router.push('/warehouse/warehousing/warehousedProductionOrder')
                    },2000)
                  }
                })
              }
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form {
  width: 400px;
}
</style>