var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c(
      "div",
      { staticClass: "login-new" },
      [
        _c(
          "el-form",
          {
            ref: "loginForm",
            staticClass: "form",
            attrs: {
              model: _vm.loginForm,
              rules: _vm.loginRules,
              "auto-complete": "on",
              "label-position": "left",
            },
          },
          [
            _c("div", { staticClass: "title" }, [
              _vm._v("\n            638医品平台数据监控大屏\n        "),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bottom" }, [
              _c("div", {
                staticClass: "border_corner border_corner_left_top",
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "border_corner border_corner_right_top",
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "border_corner border_corner_left_bottom",
              }),
              _vm._v(" "),
              _c("div", {
                staticClass: "border_corner border_corner_right_bottom",
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "content-box" },
                [
                  _c("div", { staticClass: "content-title" }, [
                    _vm._v("\n                用户登录\n             "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "managerPhoneNumber" } },
                    [
                      _c("div", { staticClass: "img" }, [
                        _c("img", {
                          attrs: {
                            alt: "",
                            src: require("../../icons/lgperson.png"),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        ref: "managerPhoneNumber",
                        attrs: {
                          name: "managerPhoneNumber",
                          placeholder: "请输入手机号",
                          maxlength: "11",
                          tabindex: "1",
                          type: "text",
                        },
                        model: {
                          value: _vm.loginForm.managerPhoneNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "managerPhoneNumber", $$v)
                          },
                          expression: "loginForm.managerPhoneNumber",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "password" } },
                    [
                      _c("div", { staticClass: "img" }, [
                        _c("img", {
                          attrs: {
                            alt: "",
                            src: require("../../icons/lglock.png"),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("el-input", {
                        key: _vm.passwordType,
                        ref: "password",
                        attrs: {
                          type: _vm.passwordType,
                          name: "password",
                          placeholder: "请输入密码",
                          tabindex: "2",
                        },
                        model: {
                          value: _vm.loginForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.loginForm, "password", $$v)
                          },
                          expression: "loginForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "selected" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择分辨率" },
                          on: { change: _vm.change },
                          model: {
                            value: _vm.loginForm.selected,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "selected", $$v)
                            },
                            expression: "loginForm.selected",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "3840*1080", value: "2" },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            attrs: { label: "1920*1080自适应", value: "4" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        width: "100%",
                        "margin-bottom": "0.6rem",
                        color: "#000",
                        background: "#00fefe",
                        "margin-top": "2rem",
                      },
                      attrs: { loading: _vm.loading, type: "primary" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.handleLogin($event)
                        },
                      },
                    },
                    [_vm._v("登录 ")]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }