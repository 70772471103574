<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form
        :model="queryParams"
        class="demo-form-inline"
        size="medium"
        :inline="true"
      >
        <el-form-item label="营养师名称">
          <el-input
            v-model="queryParams.dietitianName"
            placeholder="请输入营养师名称"
            clearable
            style="width: 210px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="营养师编号">
          <el-input
            v-model="queryParams.dietitianCode"
            placeholder="请输入营养师编号"
            clearable
            style="width: 210px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="营养师手机号">
          <el-input
            v-model="queryParams.phoneNumber"
            placeholder="请输入营养师手机号"
            clearable
            style="width: 210px"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
      </el-form>
      <el-form
        :model="queryParams"
        class="demo-form-inline"
        size="medium"
        :inline="true"
      >
        <el-form-item label="所属地区">
          <el-cascader
            v-model="queryParams.regionId"
            :options="addressList_add"
            :props="props_List"
            clearable=""
            placeholder="请选择地址"
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item label="身份">
          <el-select
            v-model="queryParams.dietitianIdentity"
            placeholder="请选择"
            style="width: 240px"
          >
            <el-option
              v-for="item in $api.identityListState"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleQuery"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addDietitian"
        >添加营养师</el-button
      >
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            :data="tableData"
            v-loading="loading"
            border
            height="400px"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              width="60"
              align="center"
              type="index"
            />
            <el-table-column
              label="营养师编号"
              align="center"
              prop="dietitianCode"
              width="120"
            />
            <el-table-column
              label="营养师名称"
              align="center"
              prop="dietitianName"
              width="120"
            />
            <el-table-column
              label="营养师身份"
              align="center"
              prop="dietitianIdentityDesc"
              width="120"
            />
            <el-table-column label="省份" align="center" prop="province" />
            <el-table-column label="城市" align="center" prop="city" />
            <el-table-column label="区/县" align="center" prop="district" />
            <el-table-column
              label="状态"
              align="center"
              prop="serviceStatus"
              :formatter="formatter"
            />
            <el-table-column
              label="手机号"
              align="center"
              prop="phoneNumber"
              width="120"
            />
            <el-table-column
              label="所属组织"
              align="center"
              prop="dietitianOrganization"
              width="120"
            />
            <el-table-column
              label="添加时间"
              align="center"
              prop="createTime"
              width="120"
            />
            <el-table-column
              fixed="right"
              label="操作"
              align="center"
              width="250"
            >
              <template slot-scope="scope">
                <span
                  class="operation-btn"
                  type="text"
                  @click="handleDetail(scope.row)"
                  >营养师档案</span
                >
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  type="text"
                  size="small"
                  @click="handleStart(scope.row)"
                  >{{ scope.row.serviceStatus == 1 ? "停用" : "启用" }}</span
                >
                <span class="operation-btn_partition"> / </span>
                <span
                  @click="handleProduct(scope.row)"
                  class="operation-btn"
                  type="text"
                  size="small"
                  name="list"
                  >产品列表</span
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :tableHeader="tableHeader"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="queryParams.pageSize"
            layout=" total, sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-main>
    </div>
    <!-- 添加营养师弹框 -->
    <el-dialog
      :before-close="handleClose"
      :title="title"
      destroy-on-close
      :visible.sync="showDialog"
      width="60%"
      append-to-body
    >


    <el-form :inline="true"  v-if="this.title == '变更客服'">
        <el-form-item label="营养师名称">
          <el-input
            v-model.trim="queryParams.dietitianName"
            placeholder="营养师名称"
          />
        </el-form-item>
        <el-form-item label="营养师编号">
          <el-input
            v-model.trim="queryParams.dietitianCode"
            placeholder="营养师编号"
          />
        </el-form-item>
        <el-form-item label="所属区域">
          <el-cascader
            v-model="queryParams.regionId"
            :options="addressList_add"
            :props="props_List"
            clearable=""
            placeholder="请选择"
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleQuery"
            >查找</el-button
          >
        </el-form-item>
      </el-form>


      <el-form :inline="true"  v-if="this.title == '选择营养师'">
        <el-form-item label="营养师名称">
          <el-input
            v-model.trim="formData.dietitianName"
            placeholder="营养师名称"
          />
        </el-form-item>
        <el-form-item label="营养师编号">
          <el-input
            v-model.trim="formData.dietitianCode"
            placeholder="营养师编号"
          />
        </el-form-item>
        <el-form-item label="所属区域">
          <el-cascader
            v-model="formData.regionId"
            :options="addressList_add"
            :props="props_List"
            clearable=""
            placeholder="请选择"
            style="width: 240px"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleQuery_1"
            >查找</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        ref="mainTable"
        :row-key="getRowKey"
        highlight-current-row
        :data="tableList"
        @row-click="handleRowClick"
        height="350"
        border
        style="margin-top: 10px"
        :header-cell-style="{ background: '#409eff', color: '#ffffff' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="index" label="" align="center" width="55">
          <template slot-scope="scope">
            <el-radio
              v-model="currentRowId"
              :label="scope.row.id"
              @change="changeRedio($event, scope.row)"
              ><span style="margin-left: -10px"></span
            ></el-radio>
          </template>
        </el-table-column>
        <el-table-column label="序号" width="60" align="center" type="index" />
        <el-table-column
          label="营养师编号"
          align="center"
          prop="dietitianCode"
          width="200"
        />
        <el-table-column
          label="营养师名称"
          align="center"
          prop="dietitianName"
        />
        <el-table-column
          label="营养师身份"
          align="center"
          prop="dietitianIdentityDesc"
        />
        <el-table-column label="省份" align="center" prop="province" />
        <el-table-column label="城市" align="center" prop="city" />
        <el-table-column label="区/县" align="center" prop="district" />
      </el-table>

      <div class="pagination"  v-if="this.title == '选择营养师'">
        <el-pagination
          :tableHeader="tableHeader"
          @size-change="handleSizeChange_2"
          @current-change="handleCurrentChange_2"
          :current-page="formData.pageNum"
          :page-sizes="$api.pageSizes"
          :page-size="formData.pageSize"
          layout=" total, sizes, prev, pager, next, jumper"
          :total="total_2"
        >
        </el-pagination>
      </div>

      <div class="pagination" v-if="this.title == '变更客服'">
        <el-pagination
            :tableHeader="tableHeader" 
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryParams.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="queryParams.pageSize"
            layout=" total, sizes, prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitDie">{{
          title == "变更客服" ? "变更" : "添加"
        }}</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 产品列表弹框 -->
    <el-dialog
      title="产品列表"
      :before-close="handleClose_1"
      destroy-on-close
      :visible.sync="showProDialog"
      width="60%"
      append-to-body
    >
      <div class="content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="产品名称">
            <el-input
              v-model="productData.productName"
              placeholder="产品名称"
            />
          </el-form-item>
          <el-form-item label="产品编号">
            <el-input
              v-model="productData.productCode"
              placeholder="产品编号"
            />
          </el-form-item>
          <br />
          <el-form-item label="产品类别">
            <el-select
              v-model="productData.productClassify"
              placeholder="请选择"
            >
              <el-option
                v-for="item in $api.orderClassify"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery_2">查找</el-button>
            <el-button type="primary" @click="addProduct">添加产品</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="proTable"
          :data="productList"
          height="350"
          border
          style="margin-top: 10px"
          :header-cell-style="{ background: '#409eff', color: '#ffffff' }"
        >
          <el-table-column
            label=""
            align="center"
            type="index"
            prop="productCode"
          />
          <el-table-column
            label="产品编号"
            align="center"
            prop="productCode"
            width="200"
          />
          <el-table-column label="产品名称" align="center" prop="productName" />
          <el-table-column label="分类" align="center" prop="productClassify">
            <template slot-scope="scope">{{
              $api.getValue($api.orderClassify, scope.row.productClassify)
            }}</template>
          </el-table-column>
          <el-table-column
            label="所属类别"
            align="center"
            prop="productCategoryName"
          />
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="proDetails(scope.row)"
                >详情</el-button
              >
              <span class="operation-btn_partition"> / </span>
              <el-button type="text" @click="delProduct(scope.row)"
                >删除</el-button
              >
              <span
                class="operation-btn_partition"
                v-if="scope.row.productClassify === 'medicine'"
              >
                /
              </span>
              <el-button
                type="text"
                @click="handleBusiness(scope.row)"
                v-if="scope.row.productClassify === 'medicine'"
                >商家列表</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="productData.pageNum"
            :page-size="productData.pageSize"
            :page-sizes="$api.pageSizes"
            :total="total_1"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_1"
            @current-change="handleCurrentChange_1"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose_1">取 消</el-button>
        <el-button type="primary" @click="handleClose_1">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加产品弹窗 -->
    <el-dialog
      title="添加产品"
      :before-close="handleClose_4"
      destroy-on-close
      :visible.sync="showproductDialog"
      width="60%"
      append-to-body
    >
      <div class="content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="产品名称">
            <el-input
              v-model.trim="addProductData.productName"
              placeholder="产品名称"
            />
          </el-form-item>
          <el-form-item label="产品编号">
            <el-input
              v-model="addProductData.productCode"
              placeholder="产品编号"
            />
          </el-form-item>
          <br />
          <el-form-item label="产品类别">
            <el-select
              v-model="addProductData.productClassify"
              placeholder="请选择"
            >
              <el-option
                v-for="item in $api.orderClassify"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery_4">查找</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="proTable"
          :data="addproductList"
          @selection-change="handleSelectionChange_1"
          height="350"
          border
          style="margin-top: 10px"
          :header-cell-style="{ background: '#409eff', color: '#ffffff' }"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            label=""
            align="center"
            type="index"
            prop="productCode"
          />
          <el-table-column
            label="产品编号"
            align="center"
            prop="productCode"
            width="200"
          />
          <el-table-column label="产品名称" align="center" prop="productName" />
          <el-table-column label="分类" align="center" prop="productClassify">
            <template slot-scope="scope">{{
              $api.getValue($api.orderClassify, scope.row.productClassify)
            }}</template>
          </el-table-column>
          <el-table-column
            label="所属类别"
            align="center"
            prop="productCategoryName"
          />
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="proDetails(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="addProductData.pageNum"
            :page-size="addProductData.pageSize"
            :page-sizes="$api.pageSizes"
            :total="total_4"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_4"
            @current-change="handleCurrentChange_4"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose_4">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 商家管理弹窗 -->
    <el-dialog
      title="商家管理"
      :before-close="handleClose_2"
      :visible.sync="showbusDialog"
      width="60%"
      append-to-body
    >
      <div class="content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="商家名称" style="width: 400px">
            <el-input
              v-model="businessDate.merchantName"
              placeholder="商家名称"
            />
          </el-form-item>
          <el-form-item label="商家编号" style="width: 400px">
            <el-input
              v-model="businessDate.merchantCode"
              placeholder="商家编号"
            />
          </el-form-item>
          <br />
          <el-form-item label="所属地区" style="width: 350px">
            <el-cascader
              v-model="businessDate.agencyId"
              :props="props"
              :options="addressList"
              clearable
              placeholder="请选择"
              v-if="$public.isAllAreaPower()"
            />
            <d-area :values.sync="businessDate.areaId" v-else />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery_3">查找</el-button>
            <el-button type="primary" @click="addbusines">添加商家</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="proTable"
          :data="businessList"
          height="350"
          border
          style="margin-top: 10px"
          :header-cell-style="{ background: '#409eff', color: '#ffffff' }"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            label="序号"
            align="center"
            type="index"
            prop="productId"
            width="60"
          />
          <el-table-column
            label="商家编号"
            align="center"
            prop="merchantCode"
          />
          <el-table-column
            label="商家名称"
            align="center"
            prop="merchantName"
          />
          <el-table-column label="大区" align="center" prop="regionName" />
          <el-table-column label="片区" align="center" prop="districtName" />
          <el-table-column label="办事处" align="center" prop="agencyName" />
          <el-table-column fixed="right" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="delBus(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="businessDate.pageNum"
            :page-size="businessDate.pageSize"
            :page-sizes="$api.pageSizes"
            :total="total_3"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_3"
            @current-change="handleCurrentChange_3"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose_2">取 消</el-button>
        <el-button type="primary" @click="handleClose_2">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加商家弹窗 -->
    <el-dialog
      title="添加商家"
      :before-close="handleClose_5"
      :visible.sync="showbusinDialog"
      width="60%"
      append-to-body
    >
      <div class="content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="商家名称" style="width: 400px">
            <el-input
              v-model="addBusinessDate.merchantName"
              placeholder="商家名称"
            />
          </el-form-item>
          <el-form-item label="商家编号" style="width: 400px">
            <el-input
              v-model="addBusinessDate.merchantCode"
              placeholder="商家编号"
            />
          </el-form-item>
          <br />
          <el-form-item label="所属地区" style="width: 350px">
            <el-cascader
              v-model="addBusinessDate.agencyId"
              :props="props"
              :options="addressList"
              clearable
              placeholder="请选择"
              v-if="$public.isAllAreaPower()"
            />
            <d-area :values.sync="addBusinessDate.areaId" v-else />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery_5">查找</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="proTable"
          :data="addbusinesList"
          @selection-change="handleSelectionChange_2"
          height="350"
          border
          style="margin-top: 10px"
          :header-cell-style="{ background: '#409eff', color: '#ffffff' }"
        >
          <el-table-column
            type="selection"
            width="55"
            align="center"
          ></el-table-column>
          <el-table-column
            label="序号"
            align="center"
            type="index"
            prop="productId"
            width="60"
          />
          <el-table-column
            label="商家编号"
            align="center"
            prop="merchantCode"
          />
          <el-table-column
            label="商家名称"
            align="center"
            prop="merchantName"
          />
          <el-table-column label="大区" align="center" prop="regionName" />
          <el-table-column label="片区" align="center" prop="districtName" />
          <el-table-column label="办事处" align="center" prop="agencyName" />
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="addBusinessDate.pageNum"
            :page-size="addBusinessDate.pageSize"
            :page-sizes="$api.pageSizes"
            :total="total_5"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_5"
            @current-change="handleCurrentChange_5"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose_5">取 消</el-button>
        <el-button type="primary" @click="submitbus">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentRowId: "", //单选按钮
      editRow: {},
      selectedRows: [], // 选中的行
      tableHeader: [
        {
          label: "营养师编号",
          prop: "dietitianCode",
          width: "100",
        },
        {
          label: "营养师名称",
          prop: "dietitianName",
        },
        {
          label: "营养师身份",
          prop: "dietitianIdentityDesc",
        },
        {
          label: "省份",
          prop: "province",
        },
        {
          label: "城市",
          prop: "city",
        },
        {
          label: "区/县",
          prop: "district",
        },
        {
          label: "状态",
          prop: "serviceStatus",
        },
        {
          label: "操作",
          type: "slot",
          slotName: "operation",
        },
      ],

      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dietitianName: "",
        dietitianCode: "",
        phoneNumber: "",
        regionId: "",
        dietitianIdentity: "",
        serviceStatus: null, //1.启用 2停用
      },
      formData: {
        dietitianStatus: 3,
        isDelete: 0,
        dietitianName: "",
        dietitianCode: "",
        phoneNumber: "",
        regionId: "",
        dietitianIdentity: null,
        pageNum: 1, //分页参数
        pageSize: 10, //分页参数
      },
      //商家管理
      businessDate: {
        isBound: 1, //是否绑定不能为空 1.绑定列表 2.未绑定列表
        dietitianId: "", //营养师id （isBound为1不能为空）
        merchantCode: "",
        merchantName: "",
        agencyId: "", //商户办事处id
        productId: "", //产品id（isBound=2时不能为空）
        pageNum: 1,
        pageSize: 10,
      },
      // 添加商家
      addBusinessDate: {
        isBound: 2, //是否绑定不能为空 1.绑定列表 2.未绑定列表
        dietitianId: "", //营养师id （isBound为1不能为空）
        merchantCode: "",
        merchantName: "",
        agencyId: "", //商户办事处id
        productId: "", //产品id（isBound=2时不能为空）
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [], // 营养师列表数据数据
      tableList: [], //添加营养师列表
      businessList: [], //商家管理列表
      addproductList: [], //添加产品列表数据
      addbusinesList: [], //添加商家列表数据
      selectList: [], // 产品table的选择项
      selectBusList: [], // 商家table的选择项
      selectedItems_1: [], //批量删除
      dietitianId: "", //产品列表id
      busid: "",
      total: 0,
      busin: "",
      // 产品弹窗数据
      productData: {
        isBound: 1,
        productCode: "",
        productName: "",
        productClassify: "",
        pageNum: 1,
        pageSize: 10,
        dietitianId: "",
      },
      // 添加产品弹窗数据
      addProductData: {
        isBound: 2,
        productCode: "",
        productName: "",
        productClassify: "",
        pageNum: 1,
        pageSize: 10,
        dietitianId: "",
      },
      // 产品弹窗列表数据
      productList: [],
      loading: false,
      title: "",
      // 省市区
      addressList_add: [],
      props_List: {
        label: "addressName",
        value: "regionId",
        children: "child",
        emitPath: false,
      },
      addressList: [], //片区联动
      props: {
        label: "areaName",
        value: "areaId",
        children: "childes",
        checkStrictly: true,
        emitPath: false,
      },
      showDialog: false, //添加营养师弹窗
      showProDialog: false, //产品列表弹窗
      showbusDialog: false, //商家管理弹窗
      showproductDialog: false, //添加产品弹窗
      showbusinDialog: false, //添加商家弹窗
      originalDietitianId: "",
      currentRowId: null,
      multipleSelection: [],
      total_1: 0,
      total_2: 0,
      total_3: 0,
      total_4: 0,
      total_5: 0,
    };
  },
  created() {
    this.getDietitianList(); //营养师列表
    this.getAddress(); //获取省市区
    this.getDistrictIdList(); //片区联动
  },
  methods: {
    // 营养师列表
    getDietitianList() {
      this.$http
        .requestPost({
          url: "/consult/queryCustomServiceList",
          param: this.queryParams,
          loading: false,
        })
        .then((res) => {
          console.log(res, "营养师列表");
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    changeRedio(event, row) {
      console.log("event,row:", event, row);
      this.currentRowId = event; //id
    },
    // 确认添加营养师
    submitDie() {
      if (this.title == "选择营养师") {
        let data = [];
        if (this.currentRowId) {
          data.push(this.currentRowId);
        } else if (this.multipleSelection.lenght) {
          this.multipleSelection.forEach((item) => {
            data.push(item.id);
          });
        }
        this.$http
          .requestPut({
            url: "/consult/addCustomService",
            param: [this.selectedRows.id],
            loading: false,
          })
          .then((res) => {
            this.showDialog = false;
            this.$message.success("操作成功");
            this.queryParams.pageNum = 1;
            this.getDietitianList();
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        let param = {
          originalDietitianId: this.originalDietitianId,
          transferDietitianId: this.currentRowId,
        };
        // 调取停用接口
        this.$http
          .requestPut({
            url: "/consult/outOfService",
            param: {
              originalDietitianId: this.originalDietitianId,
              transferDietitianId: this.currentRowId,
            },
            loading: false,
          })
          .then((res) => {
            this.showDialog = false;
            this.$message.success("操作成功");
            this.queryParams.serviceStatus = "";
            this.getDietitianList();
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    //待添加营养师列表
    addDietitianList() {
      if (this.title == "选择营养师") {
        this.$http
          .requestPost({
            url: "/consult/queryDietitianCustomerServiceList",
            param: this.formData,
            loading: false,
          })
          .then((res) => {
            this.tableList = res.data.list;
            this.total_2 = res.data.total;
            console.log(res);
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        this.queryParams.serviceStatus = 1;
        this.$http
          .requestPost({
            url: "/consult/queryCustomServiceList",
            param: this.queryParams,
            loading: false,
          })
          .then((res) => {
            this.loading = false;
            this.tableList = res.data.list.filter(
              (item) => item.id != this.originalDietitianId
            );
            this.total_2 = res.data.total;
          });
      }
    },
    // 添加营养师的id
    handleRowClick(row) {
      this.currentRowId = row.id;
      this.selectedRows = row;
      console.log("单选======", this.selectedRows);
    },
    // 变更客服的id
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("val", val);
    },
    // 行数据的Key，用来优化Table的渲染
    getRowKey(row) {
      return row.id;
    },
    // 启用/停用功能
    handleStart(row) {
      console.log(row, "启用");
      this.originalDietitianId = row.id;
      let title =
        row.serviceStatus == 1
          ? "停用客服账号，需变更其负责区域的客服负责人"
          : "确认启用客服账号";
      let topic = row.serviceStatus == 1 ? "停用客服" : "启用客服";
      if (row.serviceStatus == 1) {
        this.$confirm(title, topic, {
          cancelButtonClass: "btn-custom-cancel",
          confirmButtonText: "去变更",
          cancelButtonText: "取消",
        })
          .then(() => {
            this.title = "变更客服";
            this.showDialog = true;
            // 获取营养师列表
            // this.queryParams.customerServiceId = row.id
            this.addDietitianList();
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$confirm(title, topic, {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
          .then(() => {
            //  启用接口
            this.$http
              .requestPut({
                url: `/consult/enableService/${row.id}`,
                param: {},
                loading: false,
              })
              .then((res) => {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.getDietitianList();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    initQuery() {
      // 打开弹窗时重置radio状态
      this.selectedRows = null;
      // 打开弹窗时重置勾选状态
      this.$refs.mainTable && this.$refs.mainTable.clearSelection();
      this.queryParams.pageNum = 1;
      this.addDietitianList();
    },
    //产品列表数据
    getProduct(id) {
      this.productData.dietitianId = id;
      this.$http
        .requestPost({
          url: "/consult/productList",
          param: this.productData,
          loading: false,
        })
        .then((res) => {
          this.productList = res.data.list;
          this.total_1 = res.data.total;
          console.log(res, "产品列表");
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 添加产品
    addProduct() {
      this.showproductDialog = true;
      this.addProductData.dietitianId = this.dietitianId;
      this.$http
        .requestPost({
          url: "/consult/productList",
          param: this.addProductData,
          loading: false,
        })
        .then((res) => {
          console.log(res);
          this.addproductList = res.data.list;
          this.total_4 = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 产品列表删除
    delProduct(row) {
      this.$confirm("确定删除该产品?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$http
            .requestDel({
              url: `/consult/removeProductLink/${row.linkId}`,
            })
            .then((res) => {
              console.log(res, "chanchu1");
              this.getProduct(this.productData.dietitianId);
              this.$message({
                message: "操作成功",
                type: "success",
              });
            });
        })
        .catch(() => {});
    },
    // 删除商家已绑定产品
    delBus(row) {
      console.log(row, "删除商家");
      this.$confirm("确定删除该商家?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.$http
          .requestDel({
            url: "/consult/removeMerchantLink/" + row.linkId,
          })
          .then((res) => {
            if (res.data) {
              // this.pageParam.pageNum = 1;
              this.businessDateList();
              this.$message.success("操作成功");
            }
          });
      });
    },
    // 商家table选中的产品id
    handleSelectionChange_2(val) {
      this.selectBusList = val;
      console.log(val);
    },
    // 产品列表
    handleProduct(row) {
      this.dietitianId = row.id; //营养师的id
      this.businessDate.dietitianId = row.id;
      this.showProDialog = true;
      this.getProduct(row.id); //产品列表
    },
    // 产品table选中的产品id
    handleSelectionChange_1(news) {
      this.selectList = news;
      console.log(news, "0000");
    },
    // 添加产品确认
    determine() {
      if (this.selectList.length < 1) {
        return this.$message.warning("请选择要添加的产品");
      }
      let ids = [];
      this.selectList.forEach((e) => {
        ids.push(e.productId);
      });

      this.$http
        .requestPut({
          url: "/consult/batchAddProductLink",
          param: {
            dietitianId: this.dietitianId,
            productIds: ids,
          },
          loading: false,
        })
        .then((res) => {
          console.log(res, "7777");
          this.showproductDialog = false;
          this.$message.success("添加成功");
          this.productData.pageNum = 1;
          this.getProduct(this.productData.dietitianId);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 产品详情
    proDetails(row) {
      console.log(row, "111112");
      this.$router.push({
        name: "productdetail",
        params: {
          id: row.productId,
        },
      });
    },
    //查看营养师档案
    handleDetail(row) {
      console.log(row, "111111");
      this.$router.push({ name: "dafiles", params: { queryFrom: row } });
    },
    // 商家列表数据
    businessDateList() {
      this.$http
        .requestPost({
          url: "/consult/merchantList",
          param: this.businessDate,
          loading: false,
        })
        .then((res) => {
          this.businessList = res.data.list;
          this.total_3 = res.data.total;
          console.log(res, 2222);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 添加商家
    addbusines() {
      this.showbusinDialog = true;
      this.addBusinessDate.productId = this.businessDate.productId;
      this.addBusinessDate.dietitianId = this.businessDate.dietitianId;
      this.$http
        .requestPost({
          url: "/consult/merchantList",
          param: this.addBusinessDate,
          loading: false,
        })
        .then((res) => {
          this.addbusinesList = res.data.list;
          this.total_5 = res.data.total;
          console.log(res, 1111);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 添加商家确认
    submitbus() {
      if (this.selectBusList.length < 1) {
        return this.$message.warning("请选择要添加的商家");
      }
      let merchantIds = [];
      this.selectBusList.forEach((e) => {
        merchantIds.push(e.merchantId);
        console.log(merchantIds, "商家数组");
      });
      this.$http
        .requestPut({
          url: "/consult/batchAddMerchantLink",
          param: {
            productId: this.businessDate.productId,
            dietitianId: this.dietitianId,
            merchantIds: merchantIds,
          },
          loading: false,
        })
        .then((res) => {
          this.showbusinDialog = false;
          this.$message.success("添加成功");
          this.businessDateList();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 商家管理弹窗
    handleBusiness(row) {
      this.businessDate.productId = row.productId;
      this.businessDate.dietitianId = this.dietitianId;
      this.showbusDialog = true;
      this.businessDateList(); //商家管理列表
    },
    // 获取区域级联列表
    getDistrictIdList() {
      this.$http
        .requestGet({ url: "/operation/area/agency/tree", loading: false })
        .then((res) => {
          this.addressList = res.data;
          console.log(res, "片区联动");
        });
    },
    //获取省市区
    getAddress() {
      this.$http
        .requestGet({
          url: "/common/address/getDietitianAllWithTree",
          loading: false,
        })
        .then((res) => {
          console.log(res);
          this.addressList_add = res.data;
          console.log(res, "省市区");
        });
    },

    formatter(row) {
      const MAP = {
        1: "启用",
        2: "停用",
      };
      return MAP[row.serviceStatus];
    },
    // // 点击跳转商家管理
    BusinList() {
      this.showbusDialog = true;
      this.businessDateList();
    },
    // 营养师列表页码改变
    handleSizeChange(size) {
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = size;
      this.getDietitianList();
    },
    handleCurrentChange(page) {
      this.queryParams.pageNum = page;
      this.getDietitianList();
    },
    // 产品列表页码改变
    handleSizeChange_1(size) {
      this.productData.pageNum = 1;
      this.productData.pageSize = size;
      this.getProduct();
    },
    handleCurrentChange_1(page) {
      this.productData.pageNum = page;
      this.getProduct();
    },
    //添加营养师列表页码改变
    handleSizeChange_2(size) {
      this.formData.pageNum = 1;
      this.formData.pageSize = size;
      this.addDietitianList();
    },
    handleCurrentChange_2(page) {
      this.formData.pageNum = page;
      this.addDietitianList();
    },
    // 商家管理列表
    handleSizeChange_3(size) {
      this.businessDate.pageNum = 1;
      this.businessDate.pageSize = size;
      this.businessDateList();
    },
    handleCurrentChange_3(page) {
      this.businessDate.pageNum = page;
      this.businessDateList();
    },
    // 添加产品列表
    handleCurrentChange_4(page) {
      this.addProductData.pageNum = page;
      this.addProduct();
    },
    handleSizeChange_4(size) {
      this.addProductData.pageNum = 1;
      this.addProductData.pageSize = size;
      this.addProduct();
    },
    // 添加商品列表
    handleCurrentChange_5(page) {
      this.addBusinessDate.pageNum = page;
      this.addbusines();
    },
    handleSizeChange_5(size) {
      this.addBusinessDate.pageNum = 1;
      this.addBusinessDate.pageSize = size;
      this.addbusines();
    },
    // 营养师列表查询
    handleQuery() {
      if(this.title == '变更客服'){
        this.queryParams.pageNum = 1;
        this.addDietitianList();
      }else{
        this.queryParams.pageNum = 1;
        this.getDietitianList();
      }
      console.log(this.title,'0000000000000')
    },
    // 添加营养师列表查询
    handleQuery_1() {
      this.formData.pageNum = 1;
      this.addDietitianList();
    },
    // 产品列表查询
    handleQuery_2() {
      this.productData.pageNum = 1;
      this.getProduct(this.productData.dietitianId);
    },
    // 商家管理列表查询
    handleQuery_3() {
      this.businessDate.pageNum = 1;
      this.businessDateList();
    },
    // 添加商家列表查询
    handleQuery_5() {
      this.addBusinessDate.pageNum = 1;
      this.addbusines();
    },
    // 添加产品查询
    handleQuery_4() {
      this.addProductData.pageNum = 1;
      this.addProduct();
    },
    addDietitian() {
      // 添加营养师
      // this.initQuery();
      this.showDialog = true;
      this.title = "选择营养师";
      this.addDietitianList(); //添加营养师列表
    },

    // 关闭添加营养师弹窗
    handleClose() {
      this.showDialog = false;
      this.formData = {
        dietitianStatus: 3,
        isDelete: 0,
        dietitianName: "",
        dietitianCode: "",
        phoneNumber: "",
        regionId: "",
        dietitianIdentity: null,
        pageNum: 1, //分页参数
        pageSize: 10, //分页参数
      };
    },

    handleClose() {
      // 关闭时清除查询条件
      if (this.title == "选择营养师") {
        this.formData = {
          dietitianName: "", //营养师名称
          dietitianCode: "", //营养师编号
          phoneNumber: "", //营养师手机号
          regionId: null, //所属地区
          dietitianStatus: null, //营养师状态  1:未完善个人资料 2:待审核 3:审核通过 4:审核驳回
          dietitianIdentity: null, //营养师身份 1:游客 2:专家 3:营养师
          isDelete: 0, //是否删除 0:未删除 1:已删除
          pageNum: 1, //分页参数
          pageSize: 10, //分页参数
        };
      } else {
        this.queryParams = {
          pageNum: 1,
          pageSize: 10,
          dietitianName: "",
          dietitianCode: "",
          phoneNumber: "",
          regionId: "",
          customerServiceId: "",
          dietitianIdentity: "",
          serviceStatus: null, //1.启用 2停用
        };
      }
      this.showDialog = false;
      this.title = "";
      this.currentRowId = "";
      this.multipleSelection = [];
    },
    //关闭产品弹窗
    handleClose_1() {
      this.showProDialog = false;
      this.productData = {
        isBound: 1,
        productCode: "",
        productName: "",
        productClassify: "",
        pageNum: 1,
        pageSize: 100,
        dietitianId: "",
      };
    },
    //关闭商家管理弹窗
    handleClose_2() {
      this.showbusDialog = false;
      this.businessDate = {
        isBound: 1, //是否绑定不能为空 1.绑定列表 2.未绑定列表
        dietitianId: "", //营养师id （isBound为1不能为空）
        merchantCode: "",
        merchantName: "",
        agencyId: "", //商户办事处id
        productId: "", //产品id（isBound=2时不能为空）
        pageNum: 1,
        pageSize: 10,
      };
    },
    //关闭商家管理弹窗
    handleClose_5() {
      this.showbusinDialog = false;
      this.addBusinessDate = {
        isBound: 2, //是否绑定不能为空 1.绑定列表 2.未绑定列表
        dietitianId: "", //营养师id （isBound为1不能为空）
        merchantCode: "",
        merchantName: "",
        agencyId: "", //商户办事处id
        productId: "", //产品id（isBound=2时不能为空）
        pageNum: 1,
        pageSize: 10,
      };
    },
    // 关闭添加产品弹窗
    handleClose_4() {
      this.showproductDialog = false;
      this.addProductData = {
        isBound: 2,
        productCode: "",
        productName: "",
        productClassify: "",
        pageNum: 1,
        pageSize: 100,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
