<template>
    <!-- 医品-已取消订单 -->
    <div class="main-box">
        <medical-list orderStatus="cancel" deliveryMethod="national"></medical-list>
    </div>
</template>

<script>
import medicalList from '@/components/order/medicalList.vue'

export default {
    components: {
        medicalList
    },
    data() {
        return {
        
        };
    },
    methods: {
       
    },
};
</script>

<style lang="scss" scoped></style>
