<template>
    <div class="main-box">
        <div class="head-search-row">
            <el-form size="medium" label-width="120px" ref="common" :model="common" :rules="commonRules">
                <el-form-item label="总部客服电话" prop="serviceNumber">
                    <el-input v-model="common.serviceNumber" maxlength="13" placeholder="0000-00000000"></el-input>
                </el-form-item>

                <el-form-item label="JA医药简介" prop="introduction">
                    <el-input type="textarea" maxlength="150" :rows="6" placeholder="请输入内容" v-model="common.introduction"></el-input>
                </el-form-item>

                <el-form-item label="">
                    <el-button type="primary" @click="submitAdd">保 存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // 表单
            common: {
                serviceNumber: '0000-00000000',
                introduction: '',
            },
            commonRules: {
                serviceNumber: [{required: true, message: '请输入总部客服电话', trigger: 'blur'},
                { pattern: /^0\d{2,3}-\d{7,8}$/, message: '电话格式错误', trigger: 'change' }],
                introduction: [{required: true, message: '请输入JA医药简介', trigger: 'blur'}],
            },
        };
    },
    created() {
        // 获取数据列表
        this.getTableList()
    },
    methods: {
        // 获取数据列表
        getTableList() {
            this.$http.requestGet({
                url: '/system/basic/config/simple/getInformation',
                loading: false
            }).then(res => {
                let data = res.data;
                this.common = {
                    introduction: data.introduction,
                    serviceNumber: data.serviceNumber,
                }
            }).catch(err => {})
        },


        // 提交保存
        submitAdd() {
            this.$refs.common.validate(valid => {
                if (valid) {
                    this.$http.requestPut({
                        url: '/system/basic/config/simple/saveInformation',
                        param: this.common
                    }).then(res => {
                        this.getTableList()
                        this.$message.success('操作成功')
                    })
                } else {
                    return false
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.head-search-row .el-form {
    max-width: 800px;
}
</style>
