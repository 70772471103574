var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-preview" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.dialogVisible,
            modal: false,
            "before-close": _vm.close,
            width: "70%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "Popout_content" }, [
            _vm.fileType === "image"
              ? _c("div", { staticClass: "image-box" }, [
                  _c("img", { attrs: { src: _vm.preview, alt: "" } }),
                ])
              : _c("div", [
                  _c(
                    "video",
                    {
                      attrs: {
                        id: "video",
                        autoplay: "",
                        src: _vm.preview,
                        controls: "controls",
                        width: "100%",
                      },
                    },
                    [_vm._v(" 视频描述")]
                  ),
                ]),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [_c("el-button", { on: { click: _vm.close } }, [_vm._v("关 闭")])],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }