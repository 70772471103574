<template>
  <div class="main-box">
    <!-- 营养档案模板 -->
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="模板名称">
          <el-input v-model="inquire.title" placeholder="模板名称"></el-input>
        </el-form-item>
        <el-form-item label="模板编号">
          <el-input v-model="inquire.questionnaireCode" placeholder="模板编号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60" align="center">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="questionnaireCode" label="模板编号" align="center"></el-table-column>
            <el-table-column prop="title" label="模板名称" align="center"></el-table-column>
            <el-table-column prop="createTime" label="添加时间" align="center"></el-table-column>
            <el-table-column label="状态" min-width="120" align="center">
              <template slot-scope="scope">
                <span>{{ getStatus(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetail(scope.row)">查看</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam.total"></el-pagination>
        </div>
      </el-main>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        title: '',
        questionnaireCode: '',
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [],

    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    getStatus(row) {
      if (row.questionnaireStatus == 1) {
        return '启动';
      } else if (row.questionnaireStatus == 2) {
        return '禁用';
      }
    },
    handleDetail(row) {
      this.$router.push({
        path: '/consult/questionnaireList/nutritionArchivesDetail',
        query: {
          id: row.id,
          type: 3
        }
      })
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },


    // 获取数据列表
    getTableList() {
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        title: this.inquire.title,
        questionnaireCode: this.inquire.questionnaireCode,
        type: 3, //问卷类型：1.问卷，2.营养师app端自创问卷,3.营养档案 4.营养记录
      }
      this.loading = true;
      this.$http.requestPost({
        url: '/consult/queryQuestionnaireList',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        //适用平台 1,638,2 U药
        let list = res.data.list.filter(item=> item.platform == 1)
        this.tableData = list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

  },
};
</script>

<style lang="scss" scoped>
</style>
