<!-- 满赠活动 -->
<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="活动ID">
          <el-input v-model="query.id" placeholder="请输入活动ID" @keyup.enter.native="queryListInfo" />
        </el-form-item>
        <el-form-item label="活动名称">
          <el-input v-model="query.activityName" placeholder="请输入活动名称" @keyup.enter.native="queryListInfo" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="query.activityStatus" placeholder="请选择" clearable class="form-select">
            <el-option
              v-for="item in $api.activityStatus"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="适用产品">
          <el-select v-model="query.productClassify" placeholder="请选择" clearable class="form-select">
            <el-option
              v-for="item in $api.applicableProducts"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <div class="flex-box">
            <el-date-picker
              v-model="query.tcDate"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="请选择日期范围"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="queryListInfo"
            />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryListInfo">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="added">
      <el-button size="small" type="primary" @click="addActivity">添加活动</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="tableLoading" :data="dataSource" border height="330px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="activityName" label="活动名称" min-width="160" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="activityDetails(scope.row)">{{ scope.row.activityName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="活动ID" min-width="100" show-overflow-tooltip />
            <el-table-column prop="orientedPopulationDesc" label="适用产品" min-width="100">
              <template slot-scope="scope">
                {{ findEnumName($api.applicableProducts, scope.row.productClassify) }}
              </template>
            </el-table-column>
            <el-table-column prop="couponStatus" label="状态" min-width="100">
              <template slot-scope="scope">
                {{ findEnumName($api.activityStatus, scope.row.activityStatus) }}
              </template>
            </el-table-column>
            <el-table-column prop="beginTime" label="开始时间" min-width="160" />
            <el-table-column prop="createTime" label="创建时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.activityStatus !== 3"
                  class="operation-btn"
                  @click="activityDetails(scope.row)"
                >编辑</span>
                <span v-if="scope.row.activityStatus !== 3" class="operation-btn_partition"> / </span>
                <span
                  v-if="scope.row.activityStatus !== 3"
                  class="operation-btn"
                  @click="endActivity(scope.row)"
                >结束</span>
                <span
                  v-if="scope.row.activityStatus === 3"
                  class="operation-btn"
                  @click="delActivity(scope.row.id)"
                >删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FullGiftPromotion',
  data() {
    return {
      query: { // 查询参数
        toDate: '',
        id: '',
        activityName: '',
        activityStatus: null, // 活动状态
        productClassify: '',
        beginTime: '',
        endTime: ''
      },
      dataSource: [], // 列表数据源
      tableLoading: false,
      pageParam: { // 分页参数
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  created() {
    this.queryListInfo()
  },
  methods: {
    // 创建方法处理枚举数组查找对应名称
    // 封装方法(枚举方法)
    findEnumName(enumArray, targetValue) {
      const enumFound = enumArray.find(item => item.value === targetValue)
      return enumFound ? enumFound.name : ''
    },
    // 获取列表数据
    queryListInfo() {
      const { tcDate } = this.query
      const time = tcDate && tcDate.length > 0
      const params = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: {
          ...this.query,
          beginTime: time ? tcDate[0] + ' 00:00:00' : '',
          endTime: time ? tcDate[1] + ' 23:59:59' : ''
        }
      }

      this.tableLoading = true
      this.$http.requestPost({
        url: '/giftActivity/getActivityList',
        loading: false,
        param: params
      })
        .then((res) => {
          if (res.code === 200) {
            this.dataSource = res.data.list
            this.pageParam.total = res.data.total
            this.tableLoading = false
          }
        })
        .catch((err) => {
          this.tableLoading = false
          console.log(err)
        })
    },
    // 新增活动
    addActivity() {
      this.$router.push({
        path: '/operation/marketingTool/fullGift',
        query: {
          edit: false
        }
      })
    },

    // 查看活动
    activityDetails(row) {
      const query = { id: row.id }

      if (row.activityStatus === 3) {
        query.check = true
      } else {
        query.edit = true
      }

      this.$router.push({
        path: '/operation/marketingTool/fullGift',
        query: query
      })
    },
    // 结束活动
    endActivity(row) {
      const invalid = '活动还未生效，是否确认结束?'
      const Claiming = '活动正在进行中，未到结束时间，是否确认结束?'
      this.$confirm(row.couponStatus === '1' ? invalid : Claiming, '确认结束', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http.requestGet({
            url: `/giftActivity/endActivity/${row.id}`,
            loading: false
          })
            .then((res) => {
              if (res.code === 200) {
                this.queryListInfo()
                this.$message({
                  type: 'success',
                  message: '已结束!'
                })
              }
            })
        })
        .catch((err) => {
          console.log('【结束失败】', err)
        })
    },
    // 删除活动
    delActivity(id) {
      this.$confirm('是否确认删除?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$http.requestGet({
          url: '/giftActivity/deleteActivity/' + id,
          loading: false
        }).then((res) => {
          if (res.code === 200) {
            this.queryListInfo()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          }
        })
      }).catch((err) => {
        console.log('【删除失败】', err)
      })
    },
    // 分页事件
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.queryListInfo()
    },
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.queryListInfo()
    }
  }
}

</script>

<style scoped>
.added {
  margin: 20px 0 0 20px;
}</style>
