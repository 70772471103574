var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-preview" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            isNewTemplate: _vm.isNewTemplate,
            visible: _vm.dialogVisible,
            type: _vm.type,
            modal: false,
            "before-close": _vm.close,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            open: _vm.open,
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                { attrs: { model: _vm.formData, "label-width": "90px" } },
                [
                  !_vm.generalExportFlag && _vm.userInfo.managerType != "agency"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "区域" } },
                        [
                          _vm.$public.isAllAreaPower()
                            ? _c("el-cascader", {
                                ref: "cascaderAddr",
                                attrs: {
                                  props: _vm.addressProps,
                                  options: _vm.addressList,
                                  clearable: "",
                                  placeholder: "请选择地址",
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.formData.areaId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "areaId", $$v)
                                  },
                                  expression: "formData.areaId",
                                },
                              })
                            : _c("d-area", {
                                attrs: { values: _vm.formData.areaId },
                                on: {
                                  "update:values": function ($event) {
                                    return _vm.$set(
                                      _vm.formData,
                                      "areaId",
                                      $event
                                    )
                                  },
                                },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form-select",
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.formData.orderStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "orderStatus", $$v)
                            },
                            expression: "formData.orderStatus",
                          },
                        },
                        _vm._l(_vm.orderStatusList, function (group) {
                          return _c(
                            "el-option-group",
                            { key: group.name, attrs: { label: group.name } },
                            _vm._l(group.options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.name, value: item.value },
                              })
                            }),
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "start-placeholder": "开始日期",
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.datetimerange,
                          callback: function ($$v) {
                            _vm.datetimerange = $$v
                          },
                          expression: "datetimerange",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发货时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled:
                            _vm.formData.orderStatus === "pending_receipt" ||
                            _vm.formData.orderStatus === "completed"
                              ? false
                              : true,
                          "end-placeholder": "结束日期",
                          format: "yyyy-MM-dd",
                          "start-placeholder": "开始日期",
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.datetimeship,
                          callback: function ($$v) {
                            _vm.datetimeship = $$v
                          },
                          expression: "datetimeship",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c("el-cascader", {
                          attrs: {
                            props: _vm.props,
                            options: _vm.productTypeList,
                            clearable: "",
                          },
                          on: { change: _vm.categoryChange },
                          model: {
                            value: _vm.categoryId,
                            callback: function ($$v) {
                              _vm.categoryId = $$v
                            },
                            expression: "categoryId",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "el-select",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.productList.length > 0,
                                expression: "productList.length > 0",
                              },
                            ],
                            staticClass: "width-260",
                            attrs: { multiple: "", placeholder: "请选择产品" },
                            model: {
                              value: _vm.formData.productIds,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "productIds", $$v)
                              },
                              expression: "formData.productIds",
                            },
                          },
                          _vm._l(_vm.productList, function (item) {
                            return _c("el-option", {
                              key: item.productId,
                              attrs: {
                                label: item.productName,
                                value: item.productId,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportFile } },
                [_vm._v("导出")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关 闭")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }