<template>
  <div class="chartNum">
    <div class="box-item">
      <div
        :class="{ 'number-item': !isNaN(item), 'mark-item': isNaN(item) }"
        v-for="(item, index) in orderNum"
        :key="index"
      >
        <div v-if="!isNaN(item)">
          <div ref="numberItem" :data-item="item">0123456789</div>
        </div>
        <span class="comma" v-else>{{ item }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number, // 具体数值
      default() {
        return 0;
      },
    },
    time: {
      type: Number, // 滚动要花的时间，单位秒
      default() {
        return 3;
      },
    },
  },
  data() {
    return {
      orderNum: ["0", "0", "0", ",", "0", "0", "0", ",", "0", "0", "0"],
    };
  },
  watch: {
    value(newVal, oldVal) {
      this.toOrderNum(newVal); // 这里输入数字即可调用
      this.increaseNumber(this.time);
    },
  },
  mounted() {
    this.toOrderNum(this.value); // 这里输入数字即可调用
    this.increaseNumber(this.time);
  },
  methods: {
    // 设置文字滚动
    // 定时增长数字
    increaseNumber(time) {
      let self = this;
      this.timer = setTimeout(() => {
        self.setNumberTransform();
      }, time * 1000);
    },
    setNumberTransform() {
      this.$nextTick().then(() => {
        const numberItems = this.$refs.numberItem; // 拿到数字的ref，计算元素数量
        console.log(numberItems);
        const numberArr = this.orderNum.filter((item) => !isNaN(item));
        //  .filter(item => !isNaN(item))
        console.log("numberArr", numberArr);
        // 结合CSS 对数字字符进行滚动,显示订单数量
        for (let index = 0; index < numberItems.length; index++) {
          const elem = numberItems[index];
          //  console.log('elem',elem)
          //  console.log('asds',numberArr[index] * 10)
          //  console.log(elem.getAttribute('data-item'));
          //  console.log('--------------------');
          elem.style.transform = `translate(-50%, -${+elem.getAttribute(
            "data-item"
          ) * 10}%)`;
        }
      });
    },
    getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    // 处理传过来的具体值value
    toOrderNum(num) {
      // 具体值value中加入逗号
      // if (Number.isInteger(num)) {
      //   // 判断是否为整数
      //   this.orderNum = num.toLocaleString().split(""); // 返回千位分隔符格式的整数
      //   console.log("this.orderNum", this.orderNum);
      // } else {
      //   // 小数点后保留一位
      //   this.orderNum = num
      //     .toFixed(1)
      //     .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
      //     .split("");
      // }
      this.orderNum = num
          .toFixed(0)
          .replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")
          .split("");
    },
  },
};
</script>
<style scoped lang="scss">
/*具体值value总量滚动数字设置*/
.box-item {
  color: #fff;
  position: relative;
  height: 100px;
  font-weight: 900;
  font-size: 52px;
  text-align: center;
  list-style: none;
  writing-mode: vertical-lr;
  text-orientation: upright;
  /*文字禁止编辑*/
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
  /* overflow: hidden; */
}
/* 默认逗号设置 */
.mark-item {
  width: 10px;
  height: 100px;
  margin-right: 5px;
  line-height: 10px;
  font-size: 48px;
  position: relative;
  & > span {
    position: absolute;
    width: 100%;
    bottom: 30px;
    writing-mode: vertical-rl;
    text-orientation: upright;
  }
}
/*滚动数字设置*/
.number-item {
  font-weight: 700;
  width: 43px;
  height: 71px;
  list-style: none;
  margin-right: 7px;
  background: url('../../assets/image/fp.png') no-repeat center center;
  background-size: cover;
  border-radius: 4px;
  & > div {
    position: relative;
    margin-right: 10px;
    width: 100%;
    height: 100%;
    writing-mode: vertical-rl;
    text-orientation: upright;
    overflow: hidden;
    & > div {
      font-style: normal;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translate(-50%, 0);
      transition: transform 1s ease-in-out;
      letter-spacing: 10px;
    }
  }
}
.number-item:last-child {
  margin-right: 0;
}
.comma {
  font-family: Arial;
  font-weight: 700;
  font-size: 32px;
  color: #009DFF;
}
</style>
