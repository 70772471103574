<template>
  <!-- 健康信息 -->
  <div class="page">
    <el-button type="primary" @click="goBack(-1)" class="back-btn">返回</el-button>
    <div class="top-box">
      <div class="title">成员信息</div>
      <div class="message">
        <div class="label"><span>姓名</span>{{ memberVo.memberName }}</div>
        <div class="label">
          <span>性别</span>{{ memberVo.memberGender == 1 ? "男" : "女" }}
        </div>
        <div class="label"><span>年龄</span>{{ memberVo.memberAge }}岁</div>
        <div class="label"><span>体重</span>{{ memberVo.memberWeight }}kg</div>
        <div class="label"><span>咨询时间</span>{{ memberVo.updateTime }}</div>
      </div>
    </div>
    <div class="bottom-box">
      <el-tabs type="border-card" @tab-click="tabClick">
        <el-tab-pane label="营养档案">
          <div class="questionnaire" v-for="(item, index) in singleList" :key="index">
            <div class="inner-title">
              <div class="left">{{item.content}}</div>
            </div>
            <div v-for="(option, optionIndex) in item.optionList" :key="optionIndex" :name="option.id">
              <div :class="option.id == singleMap[item.id] ? 'isCorrect' : ''">{{ option.content }}</div>
            </div>
          </div>
          <div class="questionnaire" v-for="(item, index) in multipleList" :key="index">
            <div class="inner-title">
              <div class="left">{{item.content}}</div>
            </div>
            <div v-for="(option, optionIndex) in item.optionList" :key="optionIndex" :name="option.content">
              <div :class="option.isCorrect ? 'isCorrect' : ''">{{ option.content }}</div>
            </div>
          </div>
          <div class="questionnaire" v-for="(item, index) in answerList" :key="index">
            <div class="inner-title">
              <div class="left">{{item.content}}</div>
            </div>
            <div>{{item.answer}}</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="营养记录">
          <div v-if="!nutritionalRecordList.length">暂无营养记录</div>
          <div class="table" v-else>
            <el-main>
              <template>
                <el-table :data="nutritionalRecordList" ref="recordTable" :tableData="nutritionalRecordList" height="400px" style="width: 100%">
                  <el-table-column label="序号" width="60" align="center" type="index" />
                  <el-table-column label="营养记录模板名称" align="center" prop="title" width="auto" />
                  <el-table-column label="营养记录模板编号" align="center" prop="questionnaireCode" width="250" />
                  <el-table-column label="填写时间" align="center" prop="questionnaireFillInTime" width="150" />
                  <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                      <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-main>
          </div>
        </el-tab-pane>
        <el-tab-pane label="问卷列表">
          <div v-if="!questionnaireList.length">暂无问卷列表</div>
          <div class="table" v-else>
            <el-main>
              <template>
                <el-table :data="questionnaireList" ref="questionTable"  height="400px" style="width: 100%">
                  <el-table-column label="序号" width="60" align="center" type="index" />
                  <el-table-column label="问卷模板名称" align="center" prop="title" width="auto" />
                  <el-table-column label="问卷模板编号" align="center" prop="questionnaireCode" width="250" />
                  <el-table-column label="填写时间" align="center" prop="questionnaireFillInTime" width="150" />
                  <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                      <el-button type="text" size="small" @click="handleDetail(scope.row)">详情</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-main>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <!-- 详情 -->
    <el-dialog :title="detailTableTitle" :visible.sync="detailDialog" @close="clearDialog" width="60%">
      <el-table :data="detailTableData" height="500px" :header-cell-style="{
          background: '#4e9aef',
          color: '#fff',
          textAlign: 'center',
        }" :border="true" ref="dialogTable">
        <el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
        <template v-for="item in datailTableHeader">
          <el-table-column :property="item.prop" :label="item.label" :key="item" align="center"></el-table-column>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { getQuestionnaireDetailPlatform } from "@/api/order";
export default {
  data() {
    return {
      id: "",
      data: {}, // 从列表传入的数据
      memberVo: {
        type: 3,
        platform: 1,
        memberId: "",
        fillInBeginTime: "",
        fillInEndTime: "",
        memberName: "",
        memberGender: "",
        memberAge: "",
        memberWeight: "",
        updateTime: "",
      }, // 成员信息
      nutritionalArchivesList: [], // 营养档案信息
      questionnaireList: [], // 问卷列表信息
      nutritionalRecordList: [], // 营养记录信息
      detailTableTitle: "",
      detailDialog: false,
      // 详情弹窗的表头
      datailTableHeader: [
        {
          label: "问题",
          prop: "content",
        },
        {
          label: "答案",
          prop: "answer",
        },
      ],
      loading_1: false,
      loading_2: false,
      detailTableData: [],
      singleList: [],
      multipleList: [],
      answerList: [],
      singleMap: {},
      multipleMap: {},
      answerMap: {},
      userQuestionVos: []
    };
  },
  async created() {
    this.memberVo.memberId = this.$route.params.id;

    await this.getData();
    await this.getQuestionnaireDetailPlatform();
    // this.getDetail();
  },
  methods: {
    async getQuestionnaireDetailPlatform() {
      await this.$http.requestGet({ url: "/consult/getQuestionnaireDetailPlatform/1" }).then((res) => {
        if (res.code == 200) {
          if (res.data && res.data.questionDetailVoList && res.data.questionDetailVoList.length > 0) {
            this.questionnaireId = res.data.id;
            // 单选题
            this.singleList = res.data.questionDetailVoList.filter(item => item.type === 1);
            let arr1 = this.userQuestionVos.filter(item => item.type === 1);
            this.singleList.map(item1 => {
              arr1.map(item2 => {
                if (item1.id == item2.questionId) {
                  this.singleMap[item1.id] = item2.optionId
                }
              })
            })
            // 多选题
            this.multipleList = res.data.questionDetailVoList.filter(item => item.type === 2);
            let arr2 = this.userQuestionVos.filter(item => item.type === 2);
            let multipleArr = [];
            arr2.map(item => {
              multipleArr.push(item.optionId)
            })
            this.multipleList.map(item1 => {
              item1.optionList.map(item2 => {
                if (multipleArr.includes(item2.id)) {
                  item2.isCorrect = true
                } else {
                  item2.isCorrect = false
                }
              })
            })
            // 问答题
            this.answerList = res.data.questionDetailVoList.filter(item => item.type === 3);
            let arr3 = this.userQuestionVos.filter(item => item.type === 3);
            this.answerList.map(item1 => {
              arr3.map(item2 => {
                if (item1.id == item2.questionId) {
                  item1.answer = item2.answer;
                }
              })
            })
          }
        }
      })
        .catch((err) => {
          console.log(err);
        });
    },
    async getData() {
      let param = {
        type: 3,
        platform: 1,
        memberId: this.memberVo.memberId,
        fillInBeginTime: "",
        fillInEndTime: "",
        memberName: ""
      }
      await this.$http
        .requestPost({
          url: "/consult/healthInformation",
          param: param,
          loading: false,
        })
        .then((res) => {
          this.loading = false;
          this.memberVo.memberName = res.data.memberName;
          this.memberVo.memberGender = res.data.memberGender;
          this.memberVo.memberAge = res.data.memberAge;
          this.memberVo.memberWeight = res.data.memberWeight;
          this.memberVo.updateTime = res.data.updateTime;
          this.userQuestionVos = res.data.userQuestionVos
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    async getNutrition(type = '') {
      let param = {
        platform: 1,
        memberId: this.memberVo.memberId,
        fillInBeginTime: "",
        fillInEndTime: "",
        memberName: ""
      }
      param.type = type == 'question' ? 1 : 4;
      await this.$http
        .requestPost({
          url: "/consult/healthInformation",
          param: param,
          loading: false,
        })
        .then((res) => {
          if(res.code == 200){
            if (type == 'question') {
              this.questionnaireList = res.data;
              this.$refs.questionTable.doLayout();
            } else {
              this.nutritionalRecordList = res.data;
              this.$refs.recordTable.doLayout();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getQuestion() {
      let param = {
        type: 1,
        platform: 1,
        memberId: this.memberVo.memberId,
        fillInBeginTime: "",
        fillInEndTime: "",
        memberName: ""
      }
      await this.$http
        .requestPost({
          url: "/consult/healthInformation",
          param: param,
          loading: false,
        })
        .then((res) => {
          this.questionnaireList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 返回按钮
    goBack() {
      this.$router.go(-1);
    },
    // 弹窗关闭
    clearDialog() { },
    // 唤起详情弹窗
    async handleDetail(row) {
      this.detailTableTitle = row.title;
      await this.$http
          .requestGet({
            url: "/consult/getUserQuestionnaireDetailAdminMore/" + row.userQuestionnaireId,
            loading: false,
          })
          .then((res) => {
            if (res.code == 200) {
              // type为1或者2content是答案answer是问题 type为3的时候content是问题而answer是答案
              res.data.forEach(item => {
                if (item.type == 1 || item.type == 2) {
                  item.content = [item.answer, item.answer = item.content][0]
                }
              })
              this.detailTableData = res.data;
            }
          })
          .catch((err) => {
            console.error('获取营养记录详情出错' + err.msg)
          });

      this.detailDialog = true;
    },
    // tab切换
    tabClick(event) {
      let { index } = event;
      if (index == 1) {
        // 营养档案
        this.getNutrition()
      } else if (index == 2) {
        // 问卷列表
        this.getNutrition('question')
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 20px 30px;
  position: relative;
  background-color: #fff;
  .back-btn {
    position: absolute;
    right: 40px;
    top: 25px;
  }
}
.top-box {
  border: 1px solid #888;
  padding: 20px;
  .title {
    border-bottom: 1px solid #888;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: left;
  }
  .message {
    span {
      padding-left: 30px;
      margin-right: 20px;
      width: 100px;
      text-align: right;
      font-weight: 550;
      display: inline-block;
    }
    .label {
      height: 35px;
    }
  }
}
.bottom-box {
  margin-top: 20px;
  border: 1px solid #888;
  .questionnaire {
    background: RGBA(211, 211, 211, 0.4);
    border-bottom: 1px solid RGBA(211, 211, 211, 1);
    padding: 10px;
    .inner-title {
      display: flex;
      color: #333;
      margin-bottom: 10px;
      // justify-content: space-between;
      .left {
        margin-right: 290px;
        font-weight: 550;
      }
      .right {
        color: #555;
      }
    }
    .inner-question {
      font-size: 14px;
      .question-item {
        height: 50px;
      }
      .ques {
        font-weight: 550;
      }
      .ans {
        color: #555;
        margin-left: 22px;
      }
    }
  }
}
.isCorrect {
  color: #409eff;
}
</style>