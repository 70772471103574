var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "订单编号" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "订单编号" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.orderCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "orderCode", $$v)
                      },
                      expression: "inquire.orderCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$api.getNeedValue(
                      _vm.timeType,
                      _vm.orderStatus,
                      "type",
                      "name"
                    ),
                  },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "end-placeholder": "结束日期",
                      format: "yyyy-MM-dd",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.datetimerange,
                      callback: function ($$v) {
                        _vm.datetimerange = $$v
                      },
                      expression: "datetimerange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-cascader", {
                      attrs: {
                        props: _vm.props,
                        options: _vm.productTypeList,
                        clearable: "",
                      },
                      on: { change: _vm.categoryChange },
                      model: {
                        value: _vm.categoryId,
                        callback: function ($$v) {
                          _vm.categoryId = $$v
                        },
                        expression: "categoryId",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.productList.length > 0,
                            expression: "productList.length > 0",
                          },
                        ],
                        staticClass: "width-260",
                        attrs: { multiple: "", placeholder: "请选择产品" },
                        model: {
                          value: _vm.inquire.productIds,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "productIds", $$v)
                          },
                          expression: "inquire.productIds",
                        },
                      },
                      _vm._l(_vm.productList, function (item) {
                        return _c("el-option", {
                          key: item.productId,
                          attrs: {
                            label: item.productName,
                            value: item.productId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "优惠券ID" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.couponCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.inquire,
                          "couponCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "inquire.couponCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.fnExportOrderList },
            },
            [_vm._v("导出")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    ref: "tables",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "expand-row-keys": _vm.expands,
                      border: "",
                      height: "400px",
                      "row-key": "orderId",
                    },
                    on: { "expand-change": _vm.expandChange },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: scope.row.items, border: "" },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品信息",
                                      "min-width": "200",
                                      prop: "",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "flex-box" },
                                                [
                                                  scope.row.productImg
                                                    ? _c("img", {
                                                        staticClass: "img-box",
                                                        attrs: {
                                                          src: scope.row
                                                            .productImg,
                                                          alt: "",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "hospital-name",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "txt_box",
                                                          class: {
                                                            "flex-box":
                                                              !scope.row
                                                                .productImg,
                                                            "flex-center":
                                                              !scope.row
                                                                .productImg,
                                                          },
                                                        },
                                                        [
                                                          scope.row.isGifts
                                                            ? _c("img", {
                                                                staticClass:
                                                                  "icon_box",
                                                                class: {
                                                                  no_img:
                                                                    !scope.row
                                                                      .productImg,
                                                                },
                                                                attrs: {
                                                                  src: require("@/assets/image/gift_icon.png"),
                                                                  alt: "",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(
                                                                scope.row
                                                                  .productName
                                                              ) +
                                                              "\n                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品编号",
                                      "min-width": "200",
                                      prop: "productCode",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品规格",
                                      "min-width": "120",
                                      prop: "productNorms",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    scope.row.productNorms ||
                                                      "/"
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "单价",
                                      "min-width": "120",
                                      prop: "itemPrice",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "￥" + scope.row.itemPrice
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "数量",
                                      "min-width": "120",
                                      prop: "itemNum",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "购买总价",
                                      "min-width": "120",
                                      prop: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "￥" +
                                                      (
                                                        parseInt(
                                                          scope.row.itemNum
                                                        ) * scope.row.itemPrice
                                                      ).toFixed(2)
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "订单编号",
                        "min-width": "200",
                        prop: "orderCode",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleParticulars(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.orderCode))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "买家/收货人",
                        "min-width": "160",
                        prop: "",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    scope.row.memberNickName +
                                      "/" +
                                      scope.row.recipient
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "总金额", "min-width": "120", prop: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" + scope.row.orderTotalPrice.toFixed(2)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "运费", "min-width": "120", prop: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" + scope.row.shippingFee.toFixed(2)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _vm.orderStatus == "auditing"
                      ? _c("el-table-column", {
                          attrs: {
                            label: "退货申请时间",
                            "min-width": "160",
                            prop: "applyReturnTime",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.orderStatus == "returning" ||
                    _vm.orderStatus == "unaudited"
                      ? _c("el-table-column", {
                          attrs: {
                            label: "退货审核时间",
                            "min-width": "160",
                            prop: "auditTime",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.orderStatus == "pending_sign"
                      ? _c("el-table-column", {
                          attrs: {
                            label: "退货发货时间",
                            "min-width": "160",
                            prop: "returningTime",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.orderStatus == "returned"
                      ? _c("el-table-column", {
                          attrs: {
                            label: "退货退款时间",
                            "min-width": "160",
                            prop: "returnTime",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "订单状态",
                        "min-width": "120",
                        prop: "orderStatus",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$api.getValue(
                                      _vm.$api.returnOrderState,
                                      scope.row.orderStatus,
                                      "name"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        "min-width": "160",
                        prop: "remark",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.orderStatus ===
                                        "pending_sign",
                                      expression:
                                        "scope.row.orderStatus === 'pending_sign'",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSignFor(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("签收")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.orderStatus ===
                                        "pending_sign",
                                      expression:
                                        "scope.row.orderStatus === 'pending_sign'",
                                    },
                                  ],
                                  staticClass: "operation-btn_partition",
                                },
                                [_vm._v("/")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleParticulars(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.orderStatus === "auditing"
                                        ? "审核"
                                        : "详情"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam.pageNum,
                      "page-size": _vm.pageParam.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.pageParam.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "签收", visible: _vm.dialogVisible_2, width: "70%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { size: "medium", "label-position": "top" },
                },
                [
                  _vm._l(_vm.detailInfo.items, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "产品信息" } },
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "842px" },
                                attrs: {
                                  data: _vm.detailInfo.productList,
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "date",
                                    label: "序号",
                                    width: "60",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(scope.$index + 1) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "产品信息",
                                    width: "200",
                                    prop: "",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "flex-box" },
                                              [
                                                scope.row.productImg
                                                  ? _c("img", {
                                                      staticClass: "img-box",
                                                      attrs: {
                                                        src: scope.row
                                                          .productImg,
                                                        alt: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "hospital-name",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "txt_box",
                                                        class: {
                                                          "flex-box":
                                                            !scope.row
                                                              .productImg,
                                                          "flex-center":
                                                            !scope.row
                                                              .productImg,
                                                        },
                                                      },
                                                      [
                                                        scope.row.isGifts
                                                          ? _c("img", {
                                                              staticClass:
                                                                "icon_box",
                                                              class: {
                                                                no_img:
                                                                  !scope.row
                                                                    .productImg,
                                                              },
                                                              attrs: {
                                                                src: require("@/assets/image/gift_icon.png"),
                                                                alt: "",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              scope.row
                                                                .productName
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "单价（￥）",
                                    width: "120",
                                    prop: "itemPrice",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "数量",
                                    width: "120",
                                    prop: "itemNum",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "小计",
                                    width: "120",
                                    prop: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  "￥" +
                                                    (
                                                      parseInt(
                                                        scope.row.itemNum
                                                      ) * scope.row.itemPrice
                                                    ).toFixed(2)
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "实收金额",
                                    width: "120",
                                    prop: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  "￥" +
                                                    (
                                                      parseInt(
                                                        scope.row.itemNum
                                                      ) * scope.row.itemPrice
                                                    ).toFixed(2)
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      prop: "",
                                      label: "操作",
                                      width: "100",
                                      fixed: "right",
                                    },
                                  },
                                  [
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "operation-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSelectBatch(index)
                                            },
                                          },
                                        },
                                        [_vm._v("选择批次")]
                                      ),
                                    ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.detailInfo.giftList.length > 0
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "赠品信息" } },
                              [
                                _c(
                                  "el-table",
                                  {
                                    staticStyle: { width: "842px" },
                                    attrs: {
                                      data: _vm.detailInfo.giftList,
                                      border: "",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        prop: "date",
                                        label: "序号",
                                        width: "60",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(scope.$index + 1) +
                                                    "\n                "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "产品信息",
                                        width: "200",
                                        prop: "",
                                        "show-overflow-tooltip": "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c(
                                                  "div",
                                                  { staticClass: "flex-box" },
                                                  [
                                                    scope.row.productImg
                                                      ? _c("img", {
                                                          staticClass:
                                                            "img-box",
                                                          attrs: {
                                                            src: scope.row
                                                              .productImg,
                                                            alt: "",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "hospital-name",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row
                                                              .productName
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "单价（￥）",
                                        width: "120",
                                        prop: "itemPrice",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "数量",
                                        width: "120",
                                        prop: "itemNum",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "小计",
                                        width: "120",
                                        prop: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      "￥" +
                                                        (
                                                          parseInt(
                                                            scope.row.itemNum
                                                          ) *
                                                          scope.row.itemPrice
                                                        ).toFixed(2)
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "实收金额",
                                        width: "120",
                                        prop: "",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      "￥" +
                                                        (
                                                          parseInt(
                                                            scope.row.itemNum
                                                          ) *
                                                          scope.row.itemPrice
                                                        ).toFixed(2)
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "el-table-column",
                                      {
                                        attrs: {
                                          prop: "",
                                          label: "操作",
                                          width: "100",
                                          fixed: "right",
                                        },
                                      },
                                      [
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "operation-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openSelectBatch(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择批次")]
                                          ),
                                        ],
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "退货信息" } },
                    _vm._l(_vm.detailInfo.items, function (item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("div", { staticStyle: { width: "100%" } }, [
                            _vm._v(_vm._s(item.productName)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "872px" },
                              attrs: {
                                data: _vm.detailInfo.items[index].batchList,
                                border: "",
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "date",
                                  label: "序号",
                                  width: "60",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(scope.$index + 1) +
                                              "\n                "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "批次",
                                  width: "150",
                                  prop: "batchNo",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "有效期（天）",
                                  width: "120",
                                  prop: "termOfValidity",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "出库数量",
                                  width: "120",
                                  prop: "deliverCount",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "商品编号",
                                  width: "140",
                                  prop: "productCode",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "添加时间",
                                  width: "140",
                                  prop: "expireTime",
                                },
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  label: "数量",
                                  width: "160",
                                  prop: "",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-input", {
                                            on: {
                                              blur: function ($event) {
                                                return _vm.verifyInt(
                                                  scope.row.num,
                                                  index,
                                                  scope.$index
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.num,
                                              callback: function ($$v) {
                                                _vm.$set(scope.row, "num", $$v)
                                              },
                                              expression: "scope.row.num",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "",
                                  label: "操作",
                                  width: "100",
                                  fixed: "right",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "operation-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteBatch(
                                                    index,
                                                    scope.row.$index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("删除")]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmRefund },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择批次",
            visible: _vm.dialogVisible_batch,
            width: "80%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_batch = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "批次" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "批次", clearable: "" },
                        model: {
                          value: _vm.inquire_batch.batchNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_batch, "batchNo", $$v)
                          },
                          expression: "inquire_batch.batchNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.searchList_batch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "batchTable",
                  attrs: { data: _vm.batchTable, border: "", height: "400px" },
                  on: { "selection-change": _vm.handleBatchChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.pageParam_batch.pageNum - 1) *
                                    _vm.pageParam_batch.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "批次",
                      "min-width": "160",
                      prop: "batchNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "库存", "min-width": "120", prop: "count" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产日期",
                      "min-width": "160",
                      prop: "produceTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "到期日期",
                      "min-width": "160",
                      prop: "expireTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "有效期（天）",
                      "min-width": "120",
                      prop: "termOfValidity",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "入库时间",
                      "min-width": "160",
                      prop: "createTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作人",
                      "min-width": "160",
                      prop: "operatorName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_batch.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_batch.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_batch.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_batch,
                      "current-change": _vm.handleCurrentChange_batch,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_batch = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmBatchSelect },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("d-export", {
        attrs: {
          inquire: _vm.inquire,
          "show-dialog": _vm.showExportDialog,
          close: _vm.showExportDialog,
        },
        on: {
          "update:close": function ($event) {
            _vm.showExportDialog = $event
          },
        },
      }),
      _vm._v(" "),
      _vm.exportTags
        ? _c("d-export", {
            attrs: {
              inquire: _vm.inquire,
              "general-export-flag": _vm.generalExportDialog,
              "show-dialog": _vm.generalExportDialog,
              close: _vm.generalExportDialog,
            },
            on: {
              "update:close": function ($event) {
                _vm.generalExportDialog = $event
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("d-export", {
        attrs: {
          title: _vm.typeTitle,
          "is-new-template": _vm.isNewTemplate,
          inquire: _vm.inquire,
          type: _vm.inquire.orderClassify,
          "show-dialog": _vm.showExportDialog,
          close: _vm.showExportDialog,
        },
        on: {
          "update:close": function ($event) {
            _vm.showExportDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }