<template>
  <div class="main-box">
    <!-- 营养记录模板 -->
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="模板名称">
          <el-input v-model="inquire.title" placeholder="模板名称"></el-input>
        </el-form-item>
        <el-form-item label="模板编号">
          <el-input
            v-model="inquire.questionnaireCode"
            placeholder="模板编号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            :data="tableData"
            v-loading="loading"
            border
            height="400px"
            style="width: 100%"
          >
            <el-table-column prop="date" label="序号" width="60" align="center">
              <template v-slot="scope">{{
                (pageParam.pageNum - 1) * pageParam.pageSize +
                (scope.$index + 1)
              }}</template>
            </el-table-column>
            <el-table-column
              prop="questionnaireCode"
              label="模板编号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="title"
              label="模板名称"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="添加时间"
              align="center"
            ></el-table-column>
            <el-table-column label="状态" min-width="120" align="center">
              <template slot-scope="scope">
                <span>{{ getStatus(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetail(scope.row)"
                  >查看</span
                >
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleProduct(scope.row)"
                  >产品列表</span
                >
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handlePush(scope.row)"
                  >推送频率</span
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>
    <!-- 产品列表弹框 -->
    <el-dialog
      title="产品列表"
      :before-close="handleClose_1"
      destroy-on-close
      :visible.sync="showProDialog"
      width="70%"
      top="5vh"
      append-to-body
    >
      <div class="content">
        <el-form label-width="80px" :inline="true">
          <el-form-item label="产品名称">
            <el-input
              v-model="productData.productName"
              placeholder="产品名称"
            />
          </el-form-item>
          <el-form-item label="产品编号">
            <el-input
              v-model="productData.productCode"
              placeholder="产品编号"
            />
          </el-form-item>
          <el-form-item label="产品类别">
            <el-select
              v-model="productData.productClassify"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in $api.orderClassify"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleQuery">查找</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="proTable"
          :data="productList"
          height="350"
          border
          style="margin-top: 10px"
          :header-cell-style="{ background: '#409eff', color: '#ffffff' }"
        >
          <el-table-column
            label="序号"
            align="center"
            type="index"
            prop="productCode"
            width="60"
          />
          <el-table-column
            label="产品编号"
            align="center"
            prop="productCode"
            width="200"
          />
          <el-table-column label="产品名称" align="center" prop="productName" />
          <el-table-column label="分类" align="center" prop="productClassify">
            <template slot-scope="scope">{{
              $api.getValue($api.orderClassify, scope.row.productClassify)
            }}</template>
          </el-table-column>
          <el-table-column
            label="所属类别"
            align="center"
            prop="productCategoryName"
          />
          <el-table-column align="center" label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="text" @click="proDetails(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="productData.pageNum"
            :page-size="productData.pageSize"
            :page-sizes="$api.pageSizes"
            :total="total_1"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_1"
            @current-change="handleCurrentChange_1"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose_1">取 消</el-button>
        <el-button type="primary" @click="handleClose_1">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 推送频率弹窗 -->
    <el-dialog
      title="营养记录推送频率设置"
      :visible.sync="pushDialogVisible"
      width="40%"
      :before-close="pushHandleClose"
    >
      <div style="display: flex; align-items: center">
        <div>频率：</div>
        <div>
          <el-input
            v-model="pushNum.frequency"
            placeholder="天数"
            disabled
          ></el-input>
        </div>
        <div style="margin-left: 6px">天 一次</div>
      </div>
      <div style="margin-top: 10px; margin-left: 40px">
        请输入大于0小于120的整数
      </div>
      <div style="display: flex; align-items: center; margin-top: 10px; padding: 10px 0 ">
        <div>是否启用：</div>
        <div>
          <el-radio-group v-model="pushNum.pushStatus">
            <el-radio label="1" disabled>是</el-radio>
            <el-radio label="2" disabled>否</el-radio>
          </el-radio-group>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        title: "",
        questionnaireCode: "",
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      pushNum: {
        frequency: "",
        pushStatus: "",
        questionnaireId: "",
      },
      loading: false,
      // 表格数据
      tableData: [],
      productList: [], //产品列表数据
      showProDialog: false, //产品列表弹窗
      pushDialogVisible: false, //推送频率弹窗
      // 产品弹窗数据
      productData: {
        isBound: 2,
        productCode: "",
        productName: "",
        productCategoryId: "",
        pageNum: 1,
        pageSize: 10,
        questionnaireId: '',//问卷id
      },
      total_1: 0,
    };
  },
  created() {
    // 获取数据列表
    this.getTableList();
  },
  methods: {
    getStatus(row) {
      if (row.questionnaireStatus == 1) {
        return "启动";
      } else if (row.questionnaireStatus == 2) {
        return "禁用";
      }
    },
    handleDetail(row) {
      this.$router.push({
        path: "/consult/questionnaireList/nutritionArchivesDetail",
        query: {
          id: row.id,
          type: 4,
        },
      });
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList();
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1;
      this.getTableList();
    },

    // 获取数据列表
    getTableList() {
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        title: this.inquire.title,
        questionnaireCode: this.inquire.questionnaireCode,
        type: 4, //问卷类型：1.问卷，2.营养师app端自创问卷,3.营养档案 4.营养记录
      };
      this.loading = true;
      this.$http
        .requestPost({
          url: "/consult/queryQuestionnaireList",
          param: data,
          loading: false,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.pageParam.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 产品列表
    handleProduct(row) {
      this.questionnaireId = row.id; 
      this.showProDialog = true;
      this.getProduct(row.id); //产品列表
    },
    //产品列表数据
    getProduct(id) {
      this.productData.questionnaireId = id
      this.$http
        .requestPost({
          url: "/consult/productList",
          param: this.productData,
          loading: false,
        })
        .then((res) => {
          this.productList = res.data.list;
          this.total_1 = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
    // 产品列表查询
    handleQuery() {
      this.productData.pageNum = 1;
      this.getProduct();
    },
    // 产品列表页码改变
    handleSizeChange_1(size) {
      this.productData.pageNum = 1;
      this.productData.pageSize = size;
      this.getProduct();
    },
    handleCurrentChange_1(page) {
      this.productData.pageNum = page;
      this.getProduct();
    },
    //关闭产品弹窗
    handleClose_1() {
      this.showProDialog = false;
      this.productData = {
        isBound: 2,
        productCode: "",
        productName: "",
        productCategoryId: "",
        pageNum: 1,
        pageSize: 10,
        questionnaireId: '',
      };
    },
    // 产品详情
    proDetails(row) {
      this.$router.push({
        name: "productdetail",
        params: {
          id: row.productId,
        },
      });
    },
    handlePush(row) {
      console.log(row,'推送频率')
      this.pushDialogVisible = true;
      if(row.pushStatus){
        this.pushNum.pushStatus = String(row.pushStatus)
      }
      if (row.frequency) {
        this.pushNum.frequency = row.frequency;
      }
      this.pushNum.questionnaireId = row.id;
    },
    pushHandleClose() {
      this.pushDialogVisible = false;
    },
    pushHandleSubmit() {
      this.pushDialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
