<template>
  <div class="d-preview">
    <el-dialog
      :title="title"
      :isNewTemplate="isNewTemplate"
      :visible.sync="dialogVisible"
      :type="type"
      :modal="false"
      :before-close="close"
      width="500px"
      @open="open"
    >
      <div class="Popout_content">
        <el-form :model="formData" label-width="90px">

            <el-form-item label="区域" v-if="!generalExportFlag && userInfo.managerType != 'agency'">
                <el-cascader ref="cascaderAddr" v-model="formData.areaId" @change="handleChange" :props="addressProps" :options="addressList" clearable placeholder="请选择地址" v-if="$public.isAllAreaPower()" />
                <d-area :values.sync="formData.areaId" v-else />
            </el-form-item>

            <!-- <el-form-item label="产品归类">
                <el-select v-model="formData.orderClassify" @change="classifyChanage" placeholder="请选择" clearable class="form-select">
                    <el-option v-for="item in $api.orderClassify" :key="item.value" :label="item.name" :value="item.value"></el-option>
                </el-select>
            </el-form-item> -->

            <el-form-item label="订单类型">
                <el-select v-model="formData.orderStatus" placeholder="请选择" clearable class="form-select">
                    <el-option-group
                    v-for="group in orderStatusList"
                    :key="group.name"
                    :label="group.name">
                        <el-option
                            v-for="item in group.options"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-option-group>
                </el-select>
            </el-form-item>

            <el-form-item label="下单时间">
                <el-date-picker
                    v-model="datetimerange"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    type="daterange"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="发货时间">
                <el-date-picker
                    :disabled="(formData.orderStatus === 'pending_receipt' || formData.orderStatus === 'completed') ? false : true"
                    v-model="datetimeship"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    type="daterange"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </el-form-item>

            <el-form-item label="">
                <div class="flex-box">
                    <el-cascader v-model="categoryId" :props="props" :options="productTypeList" @change="categoryChange" clearable></el-cascader>
                    <el-select v-model="formData.productIds" multiple v-show="productList.length > 0" placeholder="请选择产品" class="width-260">
                        <el-option v-for="item in productList" :key="item.productId" :label="item.productName" :value="item.productId" />
                    </el-select>
                </div>
            </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportFile">导出</el-button>
        <el-button @click="close">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSessionStorage } from "@/utils/auth";
import { exportOrderList,exportNutritionList, externalExportOrdersList } from "@/api/order";
import dArea from '@/components/d-area'

export default {
    name: 'DExport',
    components: {
        dArea
    },
    props: {
        showDialog: {
            // 是否显示
            type: Boolean,
            default: false
        },
        title: {
            // 标题
            type: String,
            default: '订单导出'
        },
        isNewTemplate: {
            // 标题
            type: Boolean,
            default: false
        },
        fileUrl: {
            // 文件路径
            type: String,
            default: ''
        },
        medicalType: {
            // 医院配送/全国配送
            type: String,
            default: 'hospital'
        },
        inquire: {
            // 查询条件
            type: Object,
            default: () => {}
        },
        type:{
            // 用于区分是营养餐点击的导出还是其他订单
            type: String,
            default: ''
        },
        generalExportFlag: {  //经销订单普通导出
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            dialogVisible: false, // 弹窗
            userInfo: {},
            // 地址
            addressList: [],
            addressProps: {
                label: 'areaName',
                value: 'areaId',
                children: 'childes',
                checkStrictly: true,
                emitPath: false
            },
            formData: {
                orderClassify: '',
                orderStatus: '',
                areaId: '',
                addressHierarchy: null,
                deliveryMethod: '',
                productIds: [],
                orderTimeBegin: '',
                orderTimeEnd: '',
                shipTimeBegin: '',
                shipTimeEnd: ''
            },
            datetimerange: [],
            datetimeship: [],
            orderStatusList: [],
            // 产品分类
            productTypeList: [],
            props: { 
                label: 'categoryName',
                value: 'categoryId',
                children: 'child',
                // checkStrictly: true, 
                emitPath: true 
            },
            productList: [],
            categoryId: '',

            // 电商品
            EOrderState: [
                {
                    name: '普通订单',
                    options: [
                        {name: '待付款', value: 'pending_payment'}, 
                        {name: '待发货', value: 'pending_ship'},
                        {name: '待收货', value: 'pending_receipt'}, 
                        {name: '已完成', value: 'completed' },
                        {name: '已取消', value: 'cancel' }
                    ]
                },
                {
                    name: '退款订单',
                    options: [
                        {name: '待审核', value: 'auditing'}, 
                        {name: '待退货', value: 'returning'}, 
                        {name: '待签收', value: 'pending_sign'},
                        {name: '已退款', value: 'returned'}, 
                        {name: '未过审', value: 'unaudited'},
                    ]
                },
            ],
            // 医品
            MOrderState: [
                {
                    name: '普通订单',
                    options: [
                        {name: '待付款', value: 'pending_payment'}, 
                        {name: '待发货', value: 'pending_ship'},
                        {name: '待收货', value: 'pending_receipt'}, 
                        {name: '待自提', value: 'pending_pick'}, 
                        {name: '已完成', value: 'completed'},
                        {name: '已取消', value: 'cancel' }
                    ]
                },
                {
                    name: '退款订单',
                    options: [
                        {name: '待审核', value: 'auditing'}, 
                        {name: '待退货', value: 'returning'}, 
                        {name: '已退款', value: 'returned'}, 
                        {name: '未过审', value: 'unaudited' },
                        {name: '待签收', value: 'pending_sign' } 
                    ]
                },
            ],
            // 租赁品
            LOrderState: [
                {
                    name: '普通订单',
                    options: [
                        {name: '待付款', value: 'pending_payment'}, 
                        {name: '待发货', value: 'pending_ship'},
                        {name: '租赁中', value: 'leased'}, 
                        {name: '已到期', value: 'expired'}, 
                        {name: '已买断', value: 'buyout'}, 
                        {name: '已完成', value: 'completed'},
                        {name: '已取消', value: 'cancel' }
                    ]
                },
            ],
             // 营养餐
             NOrderState: [
                {
                    name: '普通订单',
                    options: [
                        {name: '待付款', value: 'pending_payment'}, 
                        {name: '待发货', value: 'pending_ship'},
                        {name: '待收货', value: 'pending_receipt'}, 
                        {name: '已完成', value: 'completed' },
                        {name: '已取消', value: 'cancel' }
                    ]
                },
                {
                    name: '退款订单',
                    options: [
                        {name: '待审核', value: 'auditing'}, 
                        {name: '待退货', value: 'returning'}, 
                        {name: '待签收', value: 'pending_sign'},
                        {name: '已退款', value: 'returned'}, 
                        {name: '未过审', value: 'unaudited'},
                    ]
                },
            ],

        }
    },
    watch: {
        'showDialog': function(news, old) {
            if (news) {
                this.classifyChanage(this.inquire.orderClassify)
                this.dialogVisible = news
                let orderTimeBegin = '',
                    orderTimeEnd = ''
                
                if (this.inquire.categoryId) {
                    this.categoryChange(this.inquire.categoryId)
                }
                Object.keys(this.inquire).forEach(e => {
                    if (this.inquire[e]) {
                        if (e == 'orderTimeBegin') {
                            orderTimeBegin = this.inquire[e]
                        } else if (e == 'orderTimeEnd') {
                            orderTimeEnd = this.inquire[e]
                        } else if (e == 'categoryId') {
                            this.categoryId = this.inquire[e]
                        }
                        this.formData[e] = this.inquire[e]
                    }
                })
                if (orderTimeBegin) {
                    this.datetimerange = [orderTimeBegin, orderTimeEnd]
                }
            } else {
                this.dialogVisible = false
            }
        },
    },
    created() {
        // this.userInfo = getSessionStorage('userInfo')
        //     // this.getProductType()
        //   if (this.$public.isAllAreaPower()) {
        //     this.getArea()
        // }
    },
    methods: {
        open(){
            this.userInfo = getSessionStorage('userInfo')
            this.getProductType()
          if (this.$public.isAllAreaPower() && !this.generalExportFlag) {  //增加一个判断条件,经销订单普通导出不需要调用区域接口
            this.getArea()
        }
        },
        // 产品归类改变
        classifyChanage(e) {
            const orderStateMap = {
                medicine: this.MOrderState,
                electronic: this.EOrderState,
                lease: this.LOrderState,
                nutrition: this.NOrderState,
                external: this.EOrderState,
            };
            this.orderStatusList = orderStateMap[e] || [];
            
            this.formData.orderStatus = ""
        },
        // 获取产品类型
        getProductType() {
            this.$http.requestGet({url:'/product/category', loading: false}).then(res => {
                this.productTypeList = res.data;
            })
        },
        // 产品分类改变
        categoryChange(e) {
            this.productList = []
            this.formData.productIds = []
            if (e.length > 1) {
                this.getProductList(e[1])
            }
        },
        // 根据分类id获取产品列表
        getProductList(id) {
            this.$http.requestGet({url:'/customer/order/category/' + id, loading: false}).then(res => {
                this.productList = res.data
            })
        },

        // 导出
        async exportFile() {
            // 下单时间
            if (!this.datetimerange) this.datetimerange = []
            this.formData.orderTimeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : ''
            this.formData.orderTimeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : ''
            // 发货时间
            if (!this.datetimeship) this.datetimeship = []
            this.formData.shipTimeBegin = this.datetimeship.length > 0 ? this.datetimeship[0] : ''
            this.formData.shipTimeEnd = this.datetimeship.length > 0 ? this.datetimeship[1] : ''
            if(this.formData.orderTimeBegin == '' && this.formData.orderTimeEnd == '' && this.formData.shipTimeBegin == '' && this.formData.shipTimeEnd == ''){
                return this.$message.error('请选择订单导出时间范围！');
            }

            if (!this.formData.areaId) this.formData.areaId = this.userInfo.areaId
            if (!this.$public.isAllAreaPower()) {
                if (this.formData.orderClassify == 'medicine') {
                    this.formData.deliveryMethod = this.medicalType
                } else{
                    this.formData.deliveryMethod = ""
                }
            }
            // if(this.formData.orderClassify){
            //     delete this.formData.orderClassify;
            // }
            this.formData.isNewTemplate = this.isNewTemplate;
            const param = JSON.parse(JSON.stringify(this.formData))
            delete param.categoryId

            if(this.type === 'nutrition'){
                await exportNutritionList(param, 'post');
                this.handleExportSuccess();
            }else if(this.generalExportFlag && this.type === 'external'){
                //经销产品普通导出
                await externalExportOrdersList(param, 'post');
                this.handleExportSuccess();
            }else if(!this.generalExportFlag && this.type === 'external'){
                //经销产品管理员导出
                const data = { ...param, isExternal: true }
                await exportOrderList(data, 'post');
                this.handleExportSuccess();
            }else {
                await exportOrderList(param, 'post');
                this.handleExportSuccess();
            }
        },
        async handleExportSuccess() {
            this.$message.success('导出任务已经启动，请稍后到[导出文件列表]中下载文件');
            this.close();
        },
        
        // 关闭弹窗
        close() {
            this.$emit('update:close', false)
        },

        // 获取区域级联数据
        getArea() {
            this.$http.requestGet({ url: "/operation/area/agency/tree", loading: false }).then((res) => {
                this.addressList = res.data;
            });
        },

        handleChange(value) {
            this.$nextTick(() => {
                if(this.$refs["cascaderAddr"].presentText && this.$refs["cascaderAddr"].presentText.length > 0){
                    this.formData.addressHierarchy = (this.$refs["cascaderAddr"].presentText.match(/\//g) || []).length + 1;
                }else{
                    this.formData.addressHierarchy = null
                }
            });
        }

    }
}
</script>

<style lang="scss" scoped>

</style>
