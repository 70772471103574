<template>
  <!-- 订单详情 -->
  <div class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- 配送信息 -->
    <div class="row_2 bg_white flex-box">
      <div class="row_left">
        <h4>配送信息</h4>
        <p class="color_light-black">配送方式：{{ $api.getValue($api.deliveryMethod, pageData.deliveryMethod, 'name') }}</p>
        <p class="color_light-black">收货人姓名：{{ pageData.recipient }}</p>
        <p class="color_light-black">收货人手机号：{{ pageData.recipientPhoneNumber }}</p>
        <p class="color_light-black">
          收货人地址：
          <span>{{ pageData.recipientAddressDetail }}</span>
          <el-button v-if="pageData.orderStatus === 'pending_payment' || pageData.orderStatus === 'pending_ship'" style="padding: 0" type="text" @click="dialogVisible_3 = true">修改</el-button>
        </p>
        <p style="height: 24px"></p>
        <div class="color_light-black flex-box"><span class="row-title">购物凭证：</span>
          <div v-show="imageUrl">
            <d-upload upload-type="picture-card" :file-list="fileList" accept=".gif,.jpeg,.jpg,.png">
              <div class="tip-box">
                <i class="el-icon-plus"></i>
              </div>
            </d-upload>
          </div>
          <span v-show="!imageUrl">无</span>
        </div>
        <p class="color_light-black">备注信息：{{ pageData.orderRemark || '无' }}</p>
      </div>

      <div class="row_right">
        <h4>
          订单状态：<span>{{ $api.getValue($api.afterSalesOrderStatus, auditStatus, 'name') }}</span>
        </h4>
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="row_3 bg_white">
      <h4>商品信息</h4>
      <div class="table">
        <el-table :data="pageData.items" border style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="220" prop="available" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-box">
                <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                <div class="hospital-name">
                  <div class="txt_box" :class="{'flex-box':!scope.row.productImg,'flex-center':!scope.row.productImg}">
                    <img v-if="scope.row.isGifts" src="@/assets/image/gift_icon.png" alt="" class="icon_box" :class="{no_img:!scope.row.productImg}" />
                    {{ scope.row.productName }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="单价" min-width="100" prop="productPrice">
            <template slot-scope="scope">
              {{ '￥' + scope.row.productPrice || 0 }}
            </template>
          </el-table-column>
          <el-table-column label="数量" min-width="100" prop="itemNum" />
          <el-table-column label="小计" min-width="100" prop="itemAmount">
            <template slot-scope="scope">
              <!-- {{ '￥' + scope.row.productPrice * scope.row.itemNum }} -->
              {{ `￥${scope.row.itemAmount || 0 }` }}
            </template>
          </el-table-column>
          <el-table-column label="优惠金额" min-width="100" prop="">
            <template slot-scope="scope">
              {{ scope.row.couponDiscountAmount ? `￥${scope.row.couponDiscountAmount}` : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="实收金额" min-width="100" prop="">
            <template slot-scope="scope">
              {{ `￥${scope.row.paidInAmountCoupon || 0 }` }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="show-money">
        <ul>
          <!-- <li>商品金额：<span class="block_span">￥{{ goodsMoney }}</span></li> -->
          <li>商品金额：<span class="block_span">￥{{ pageData.orderTotalPrice || 0 }}</span></li>
          <li>运费：<span class="block_span">+￥{{ pageData.shippingFee || 0 }}</span></li>
          <li class="line-box"></li>
          <li class="bold">
            <!-- 实付总额：<span class="block_span color_red">￥{{ (goodsMoney + pageData.shippingFee) ? (goodsMoney + pageData.shippingFee).toFixed(2) : '0.00' }}</span> -->
            实付总额：<span class="block_span color_red">￥{{ pageData.paidInAmount || pageData.orderTotalPrice || 0 }}</span>
          </li>
          <li v-if="pageData.returnAmount" class="bold">
            退款总额：<span class="block_span color_red">￥{{ pageData.returnAmount }}</span>
          </li>
        </ul>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="row_4 bg_white">
      <h4>订单信息</h4>
      <p class="color_light-black"><span
        class="title-box"
      >订单类型：</span>{{ $api.getValue($api.orderClassify, pageData.orderClassify, 'name') }}
      </p>
      <p class="color_light-black"><span class="title-box">订单编号：</span>{{ pageData.orderCode }}</p>
      <p class="color_light-black"><span class="title-box">下单时间：</span>{{ pageData.orderTime }}</p>
      <p v-if="pageData.orderStatus !== 'pending_payment' && pageData.orderStatus !== 'completed' && pageData.orderStatus !== 'cancel'" class="color_light-black"><span class="title-box">付款时间：</span>{{ pageData.paymentTime }}</p>
      <p v-if="pageData.orderStatus === 'pending_receipt' || pageData.orderStatus === 'pending_evaluate'" class="color_light-black"><span class="title-box">发货时间：</span>{{ pageData.shipTime }}</p>
      <p v-if="pageData.orderStatus === 'pending_evaluate'" class="color_light-black"><span class="title-box">确认收货时间：</span>{{ pageData.receiptTime }}</p>
    </div>
    <!-- 出库批次信息 -->
    <div v-if="pageData.orderStatus != 'pending_payment'" class="row_4 bg_white">
      <h4>出库批次信息</h4>
      <div v-for="(item,index) in pageData.items" :key="index" style="margin-bottom:10px">
        <div v-if="item.batch.length >= 1">
          <div>商品名称：{{ item.productName }}</div>
          <div v-for="(batchItem,batchIndex) in item.batch" :key="batchIndex">
            <p class="color_light-black"><span class="title-box">批次号：</span> {{ batchItem.batchNo }}</p>
            <p class="color_light-black"><span class="title-box">发货数量：</span>{{ batchItem.deliverCount }}</p>
            <p class="color_light-black"><span class="title-box">有效期：</span>{{ batchItem.produceTime }} ~ {{ batchItem.expireTime }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 售后信息 -->
    <div v-if="pageData.orderAfterSalesReissue" class="row_4 bg_white afterSales_info">
      <h4>售后信息</h4>
      <p class="color_light-black"><span class="title-box">申请补发数量：</span>{{ pageData.orderAfterSalesReissue.applyQuantity || 0 }}</p>
      <p class="color_light-black"><span class="title-box">补发说明：</span>{{ pageData.orderAfterSalesReissue.reissueReason || '无' }}</p>
      <p class="color_light-black"><span class="title-box">申请时间：</span>{{ pageData.orderAfterSalesReissue.applyTime || '无' }}</p>
      <p class="color_light-black certificate_item">
        <span class="title-box">售后凭证：</span>
        <img
          v-if="pageData.orderAfterSalesReissue.reissueCertificate"
          :src="pageData.orderAfterSalesReissue.reissueCertificate"
          alt=""
        />
        <span v-else>无</span>
      </p>
      <p v-if="pageData.orderAfterSalesReissue.auditStatus === 3" class="color_light-black">
        <span class="title-box">审核时间：</span>{{ pageData.orderAfterSalesReissue.auditTime || '无' }}
      </p>
      <p v-if="pageData.orderAfterSalesReissue.auditStatus === 3" class="color_light-black">
        <span class="title-box">驳回原因：</span>{{ pageData.orderAfterSalesReissue.rejectReason || '无' }}
      </p>
    </div>
    <!-- 专员信息 -->
    <div class="row_4 bg_white">
      <h4>专员信息</h4>
      <p class="color_light-black"><span class="title-box">专员姓名：</span>{{ pageData.attacheName || '无' }}</p>
      <p class="color_light-black"><span class="title-box">专员编号：</span>{{ pageData.attacheCode || '无' }}</p>
    </div>
    <!-- 业务员信息 -->
    <div class="row_4 bg_white">
      <h4>业务员信息</h4>
      <p class="color_light-black"><span class="title-box">业务员姓名：</span>{{ pageData.intendantName || '无' }}</p>
      <p class="color_light-black"><span class="title-box">业务员编号：</span>{{ pageData.intendantCode || '无' }}</p>
      <p class="color_light-black"><span class="title-box">二维码编号：</span>{{ pageData.qrCodeSerial || '无' }}</p>
      <p class="color_light-black"><span class="title-box">二维码备注：</span>{{ pageData.qrCodeRemark || '无' }}</p>
      <p class="color_light-black"><span class="title-box">商家端名称：</span>{{ pageData.qrMerchantName || '无' }}</p>
      <p class="color_light-black"><span class="title-box">商家端编号：</span>{{ pageData.qrMerchantCode || '无' }}</p>
    </div>
    <!-- 优惠明细-start -->
    <div class="row_4 bg_white">
      <h4>优惠信息</h4>
      <p class="color_light-black" style="margin:0 0 10px 50px;">
        <span style="margin-right:50px">优惠券名称：{{ pageData.couponName || '-' }}</span>
        <span style="margin-right:50px">
          优惠券ID：{{ (pageData.customerCoupon && pageData.customerCoupon.couponCode) ? pageData.customerCoupon.couponCode : '-' }}
        </span>
        <span style="margin-right:50px">会员等级：{{ $public.getVipLevel(pageData.vipLevel) }}</span>
      </p>
      <p v-if="pageData.couponIntendantVo" class="color_light-black" style="margin:0 0 10px 50px">
        <span style="margin-right:50px">业务员编号：{{ pageData.couponIntendantVo.intendantCode || '无' }}</span>
        <span style="margin-right:50px">业务员姓名：{{ pageData.couponIntendantVo.intendantName || '无' }}</span>
        <span style="margin-right:50px">二维码编号：{{ pageData.couponIntendantVo.qrCodeSerial || '无' }}</span>
      </p>
      <div class="table">
        <el-table :data="pageData.items" :border="true" style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="220" prop="available" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-box" style="align-items:center">
                <img v-if="scope.row.productImg" class="img-box" :src="scope.row.productImg" alt="" />
                <span style="margin-left:5px">{{ scope.row.productName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="规格" min-width="100" prop="productNorms" />
          <el-table-column label="单价" min-width="100" prop="productPrice">
            <template slot-scope="scope">
              {{ `￥${scope.row.productPrice}` }}
            </template>
          </el-table-column>
          <el-table-column label="数量" min-width="100" prop="itemNum" />
          <el-table-column label="优惠方式" min-width="100" prop="couponMode">
            <template slot-scope="scope">
              {{ scope.row.couponMode || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="优惠金额" min-width="100" prop="">
            <template slot-scope="scope">
              {{ scope.row.amountCouponInformation ? `￥${scope.row.amountCouponInformation}` : 0 }}
            </template>
          </el-table-column>
          <el-table-column label="优惠价" min-width="100" prop="">
            <template slot-scope="scope">
              {{ scope.row.priceCouponInformation ? `￥${scope.row.priceCouponInformation}` : 0 }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 优惠明细-end -->
    <!-- 底部按钮 -->
    <div v-if="pageData.orderAfterSalesReissue && pageData.orderAfterSalesReissue.auditStatus === 1" class="fixBottom_button">
      <el-button type="primary" @click="openExamineDialog(2)">通过</el-button>
      <el-button @click="openExamineDialog(3)">驳回</el-button>
    </div>

    <!-- 审核/驳回通过弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="examineDialog" width="500px" :before-close="closeDialog">
      <div>
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px">
          <el-form-item v-show="submitForm.auditStatus === 2" :prop="submitForm.auditStatus === 2 ? 'logisticsCompany' : ''" label="物流公司">
            <el-select v-model="submitForm.logisticsCompany" class="width-inherit" placeholder="请选择" @change="changeLogisticsCompany">
              <el-option
                v-for="item in logisticsCompanyList"
                :key="item.companyId"
                :label="item.companyName"
                :value="item.companyId"
              />
            </el-select>
          </el-form-item>

          <el-form-item v-show="submitForm.auditStatus === 2" :prop="submitForm.auditStatus === 2 ? 'logisticsNo' : ''" label="物流单号">
            <el-input v-model="submitForm.logisticsNo" maxlength="30" placeholder="物流单号" />
          </el-form-item>

          <el-form-item v-show="submitForm.auditStatus === 3" :prop="submitForm.auditStatus === 3 ? 'rejectReason' : ''" label="驳回原因">
            <el-input
              v-model="submitForm.rejectReason"
              placeholder="请输入"
              rows="6"
              maxlength="150"
              type="textarea"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="toExamine">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dUpload from '@/components/d-upload'
import { modifyAfterSalesOrderStatus } from '@/api/order'

export default {
  components: {
    dUpload
  },
  data() {
    return {
      orderId: null, // 订单id
      auditStatus: null, // 售后订单状态

      // 购物凭证
      imageUrl: [],
      fileList: [],
      pageData: {}, // 页面数据

      //  ============= 审核数据 =============
      examineDialog: false, // 审核弹窗
      dialogTitle: '',
      submitForm: {
        orderId: '', // 订单id
        auditStatus: null, // 审核状态：1-待审核，2-审核通过，3-审核不通过
        logisticsCompany: '', // 物流公司（当auditStatus为3时不传）
        logisticsNo: '', // 物流单号（当auditStatus为3时不传）
        rejectReason: '' // 驳回原因（当auditStatus为2时不传）
      },
      submitRules: {
        logisticsCompany: [{ required: true, message: '请选择物流公司', trigger: 'change' }],
        logisticsNo: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
          { pattern: /^[0-9a-zA-Z]+$/, message: '请输入字母和数字', trigger: 'blur' }
        ],
        rejectReason: [{ required: true, message: '请输入驳回原因', trigger: 'blur' }]
      },
      logisticsCompanyList: [] // 物流公司列表
    }
  },
  watch: {
    'imageUrl': (old, news) => {
      if (old) {
        const upEl = document.getElementsByClassName('el-upload')[0]
        upEl.style.display = 'none'
      }
    }
  },
  created() {
    this.orderId = this.$route.query.orderId
    this.auditStatus = Number(this.$route.query.auditStatus)

    this.getOrderInfo()
    this.getLogisticsCompanyList()
  },
  mounted() {
  },
  methods: {
    // 获取详情数据
    getOrderInfo() {
      this.$http.requestGet({ url: '/customer/order/get/' + this.orderId }).then(res => {
        this.pageData = res.data
        this._statisticsOrderCode(this.pageData.orderCode)
        this.imageUrl = res.data.prescriptionUrl ? res.data.prescriptionUrl.split(',') : []
        this.fileList = []
        if (this.imageUrl.length > 0) {
          this.imageUrl.forEach(e => {
            this.fileList.push({
              name: e.slice(e.lastIndexOf('/')),
              url: e
            })
          })
        }
      })
    },

    // 获取物流公司列表
    getLogisticsCompanyList() {
      const param = {
        pageNum: 1,
        pageSize: 10000
      }
      this.$http.requestPost({ url: '/system/logistics/company/page', param, loading: false }).then(res => {
        this.logisticsCompanyList = res.data.list
      })
    },
    // 更换物流公司
    changeLogisticsCompany(data, data2) {
      const arr = this.logisticsCompanyList.filter(item => item.companyId === data)
      this.submitForm.logisticsCompany = arr[0].companyName
    },
    // 打开审核弹窗
    openExamineDialog(auditStatus) {
      this.dialogTitle = auditStatus === 2 ? '通过' : '驳回'
      this.submitForm.auditStatus = auditStatus
      this.examineDialog = true
    },
    // 关闭审核弹窗
    closeDialog() {
      this.examineDialog = false
      this.$refs.submitForm.resetFields()
    },
    // 审核通过 / 驳回
    async toExamine() {
      this.submitForm.orderId = this.orderId
      this.$refs.submitForm.validate(async(valid) => {
        if (valid) {
          const res = await modifyAfterSalesOrderStatus(this.submitForm)
          // console.log('res=====>>>>>', res)
          if (res.code === 200) {
            this.$message.success('操作成功')
            this.$router.go(-1)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

    // 记录埋点
    _statisticsOrderCode(orderCode) {
      this.$http.requestPost({
        url: `/listenerLog/orderDetails?orderCode=${orderCode}`,
        loading: false
      }).then(res => {
      }).catch(err => {
        console.log('【埋点失败】', err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;
  padding-bottom: 50px;
  position: static;

  &>>>.steps-box {
    padding: 30px 40px;
    margin-bottom: 10px;

    .el-step__head.is-process {
      border-color: #429fff;
      color: #429fff;
    }

    .el-step__title.is-process {
      color: #429fff;
    }

    .el-step__description.is-process {
      color: #429fff;
    }
  }

  .row_2 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    .row_left {
      width: 60%;

      & p {
        padding: 5px 0;
      }

      .color_wathet {
        cursor: pointer;
      }
    }

    .row_right {
      width: 40%;
    }

    h4 {
      padding-bottom: 10px;
    }

    .color_light-black {
      font-size: 15px;
    }
  }

  .row_3 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    & h4 {
      padding-bottom: 10px;
    }
  }

  .row_4 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;
    font-size: 15px;

    & h4 {
      padding-bottom: 10px;
    }

    & p {
      padding-top: 5px;
    }
  }
  .title-box {
    display: inline-block;
    width: 116px;
    text-align: right;
  }

  .show-money {
    padding: 10px 0;

    ul {
      li {
        display: flex;
        justify-content: flex-end;
        line-height: 30px;
        font-size: 16px;
      }

      .line-box {
        margin-left: 70%;
        padding-top: 20px;
        border-bottom: 1px solid #ccc;
      }

      .bold {
        font-weight: bolder;
      }
    }
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: #ffffff !important;
  }

  .img-box {
    width: 58px;
    height: 58px;
  }

  .icon_box {
    width: 21px;
    position: absolute;
    left: 0;
    top: calc(100% + 2px);

    &.no_img {
      margin-right: 3px;
      position: static;
    }
  }

  .txt_box {
    position: relative;
    line-height: 1;
  }

  .afterSales_info {
    .certificate_item {
      display: flex;
      img {
        width: 120px;
        height: 120px;
        display: block;
      }
    }
  }

  .fixBottom_button {
    width: calc(100% - 23px);
    padding: 20px 0;
    background-color: #fff;
    display: flex;
    align-content: center;
    justify-content: center;

    position: absolute;
    bottom: 0;
    left: 10px;
    z-index: 999;
  }
}

</style>
