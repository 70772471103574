<template>
  <!-- 申购单-已完成申购单 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="申购单编号">
          <el-input v-model="inquire.orderCode" placeholder="申购单编号" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item label="审核时间">
          <el-date-picker v-model="daterange" end-placeholder="结束日期" start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd" />
        </el-form-item>

        <el-form-item label="产品名称">
          <el-input v-model="inquire.productName" placeholder="产品名称" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item label="商家名称">
          <el-input v-model="inquire.merchantName" placeholder="商家名称" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item label="区域">
          <el-cascader
            v-if="$public.isAllAreaPower()"
            v-model="inquire.searchAreaId"
            :props="areaProps"
            :options="areaList"
            clearable
            placeholder="请选择地址"
            style="width: 300px;"
          />
          <d-area v-else :values.sync="inquire.searchAreaId" />
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="exportFile">导出</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            ref="tables"
            v-loading="loading"
            :data="tableData"
            :expand-row-keys="expands"
            border
            height="400px"
            row-key="orderCode"
            style="width: 100%"
            @expand-change="expandChange"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table :data="scope.row.items" border style="width: 100%">
                  <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex-box">
                        <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                        <div class="hospital-name">
                          {{ scope.row.productName }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="产品编号" min-width="160" prop="productCode" show-overflow-tooltip />
                  <el-table-column label="产品规格" min-width="120" prop="productNorms" />
                  <el-table-column label="申购单价" min-width="120" prop="itemPrice">
                    <template slot-scope="scope">
                      {{ '￥' + scope.row.itemPrice }}
                    </template>
                  </el-table-column>
                  <el-table-column label="申购数量" min-width="120" prop="itemNum" />
                  <el-table-column label="申购总价" min-width="120" prop="">
                    <template slot-scope="scope">
                      {{ '￥' + (scope.row.itemNum * scope.row.itemPrice).toFixed(2) }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="申购单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="商家" min-width="160" prop="merchantName" />
            <el-table-column label="商家编号" min-width="200" prop="merchantCode" />
            <el-table-column label="申购总价" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + getTotalPrices(scope.row.items) }}
              </template>
            </el-table-column>
            <el-table-column label="审核时间" min-width="160" prop="auditTime" />
            <el-table-column label="审核人" min-width="120" prop="auditByName" />
            <el-table-column label="物流公司" min-width="160" prop="logisticsCompany" show-overflow-tooltip />
            <el-table-column label="物流单号" min-width="200" prop="logisticsCode" show-overflow-tooltip />
            <el-table-column fixed="right" label="操作" min-width="120" prop="">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-size="pageParam.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>
    <!-- 产品导出 -->
    <product-export
      ref="isProductExport"
      :show-dialog="showExportDialog"
      :close.sync="showExportDialog"
      :purchase-status="inquire.purchaseStatus"
      product-type="purchase"
      title="已完成申购单导出"
      sg-date-title="申购时间"
    />
  </div>
</template>

<script>
import dArea from '@/components/d-area'
import dUpload from '@/components/d-upload'
import { getLastThreeMonthDate } from '@/utils/latelyTime'

import { setSessionStorage, getSessionStorage } from '@/utils/auth'
import productExport from '@/components/productExport'
export default {
  components: {
    dUpload,
    productExport,
    dArea
  },
  data() {
    return {
      // 查询
      inquire: {
        purchaseStatus: 'completed',
        auditTimeBegin: '',
        auditTimeEnd: '',
        productName: '',
        orderCode: '',
        merchantName: '',
        searchAreaId: ''
      },
      daterange: [],
      areaProps: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },
      areaList: [], // 区域数据源

      // 分页
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      loading: true,
      expands: [], // 当前展示行

      stateList: [
        { name: '审核中', value: 'auditing' },
        { name: '已审核', value: 'audited' },
        { name: '未通过', value: 'unaudited' },
        { name: '已完成', value: 'completed' }
      ],
      // ========== 导出数据 ============
      dialogVisible: false,
      // 分页
      pageParam1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      showExportDialog: false,
      userInfo: {} // 用户信息
    }
  },
  created() {
    this.userInfo = getSessionStorage('userInfo')
    if (this.userInfo.managerType !== 'all') {
      this.inquire.areaId = this.userInfo.areaId
    }

    this.daterange = getLastThreeMonthDate()

    const page = getSessionStorage('completedPage')
    if (page && Object.keys(page).length > 0) {
      this.pageParam.pageNum = page.pageNum
      this.pageParam.pageSize = page.pageSize
    }
    if (sessionStorage.getItem('cacheParamLd')) {
      this.inquire = JSON.parse(sessionStorage.getItem('cacheParamLd'))
      this.daterange[0] = this.inquire.auditTimeBegin || ''
      this.daterange[1] = this.inquire.auditTimeEnd || ''
    }

    this.getDataList()
    // 获取区域
    this.getDistrictIdList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.getDataList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getDataList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getDataList()
    },
    // 添加医院-添加图片文件
    uploadSuccess(res, file, fileList) {
      this.submitForm.hospitalAvatar = file
    },
    // 添加医院-删除图片文件
    removeFile(file, fileList) {
      this.submitForm.hospitalAvatar = ''
    },
    // table 手风琴
    expandChange(news, old) {
      if (old.length === 0) {
        return (this.expands = [])
      }
      this.expands = [news.orderCode]
    },
    // 计算总价
    getTotalPrices(arr = []) {
      let prices = 0
      arr.forEach(v => {
        prices += parseInt(v.itemNum) * v.itemPrice
      })
      return prices.toFixed(2)
    },
    // 导出
    exportFile() {
      this.showExportDialog = true
      this.$refs.isProductExport.open()
    },

    // 获取区域级联列表
    getDistrictIdList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        if (this.userInfo.managerType === 'region') {
          this.areaList = res.data.filter(item => item.areaId == this.userInfo.areaId)
        } else {
          this.areaList = res.data
        }
      })
    },

    // 获取数据列表
    getDataList() {
      if (!this.daterange) this.daterange = []
      this.inquire.auditTimeBegin = this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire.auditTimeEnd = this.daterange.length > 0 ? this.daterange[1] : ''
      if (getSessionStorage('userInfo').managerType !== null && getSessionStorage('userInfo').managerType !== '') {
        this.inquire.managerType = getSessionStorage('userInfo').managerType
      }
      const param = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire))
      }
      this.loading = true
      this.$http
        .requestPost({
          url: '/customer/purchase/order/newPage',
          param: param,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
          sessionStorage.removeItem('cacheParamLd')
        })
        .catch(err => {
          this.loading = false
          sessionStorage.removeItem('cacheParamLd')
          console.log(err)
        })
    },

    // 审核
    handleDetails(row) {
      setSessionStorage('completedPage', this.pageParam)
      sessionStorage.setItem('cacheParamLd', JSON.stringify(this.inquire))
      this.$router.push({
        path: '/form/purchaseRequisition/particulars',
        query: {
          type: 4,
          id: row.orderCode
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
  width: 375px;
}

.el-head-search-row {
  .flex-box {
    width: 326px;
  }
}
</style>
