<template>
  <div class="circle">
    <div class="title">
      <div class="txt_box">
        <div class="text">用户购买力分析</div>
        <img src="@/assets/image/top.png" alt="" class="icon">
        <div class="tip">
          <img src="@/assets/image/wenhao.png" alt="">
          <div class="poup">
            <div class="txt">
              分析所选时间段内用户购买次数的情况
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="item">
        <el-progress class="progress" type="circle" :width="155" :stroke-width="8" :percentage="list.between1And2" color="#264EFF" stroke-linecap="square" />
        <div class="count">{{ list.countBetween1And2 }}</div>
        <div class="txt">购买1~2次</div>
        <!-- <div class="tip">{{list.countBetween1And2}}人</div> -->
      </div>
      <div class="item">
        <el-progress class="progress" type="circle" :width="155" :stroke-width="8" :percentage="list.between3And5" color="#26B99B" stroke-linecap="square" />
        <div class="count">{{ list.countBetween3And5 }}</div>
        <div class="txt">购买3~5次</div>
        <!-- <div class="tip">{{list.countBetween3And5}}人</div> -->
      </div>
      <div class="item">
        <el-progress class="progress" type="circle" :width="155" :stroke-width="8" :percentage="list.greaterThan5" color="#FC7226" stroke-linecap="square" />
        <div class="count">{{ list.countGreaterThan5 }}</div>
        <div class="txt">购买5次以上</div>
        <!-- <div class="tip">{{list.countGreaterThan5}}人</div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
      list: {
        type: Object,
        default: () => {
            between1And2 = 0,
            between3And5 = 0,
            greaterThan5 = 0
        }
      }
    },
   data() {
      return {
      }
   },
   computed: {
   },
   created() {
   },
   methods: {
   }
}
</script>
<style lang="scss" scoped>
/deep/ .el-progress {
    position: relative;
    .el-progress__text {
        font-size: 30px !important;
        font-weight: 700;
        width: 114px;
        height: 114px;
        position: absolute;
        left: 20.5px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
 /deep/ .item:nth-child(1) {
    .el-progress path:first-child { // 修改进度条背景色
        stroke: #0D1966;
    }
    .el-progress__text {
        color: #6182FF !important;
        border: 1px dashed #0D1966;
        background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/c8a6998f-2075-4171-8fdb-393365836052.png") no-repeat center 0px;
        background-size: cover;
    }
}
/deep/ .item:nth-child(2) {
    .el-progress path:first-child { // 修改进度条背景色
        stroke: #0B374F;
    }
    .el-progress__text {
        color: #8CF2D9 !important;
        border: 1px dashed #0B374F;
        background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/7a655acd-9832-4451-bc19-403f59c08365.png") no-repeat center 0px;
        background-size: cover;
    }
}

/deep/ .item:nth-child(3) {
    .el-progress path:first-child { // 修改进度条背景色
        stroke: #4D262B;
    }
    .el-progress__text {
        border: 1px dashed #4D262B;
        color: #FFC4A3 !important;
        background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/81827532-bbfb-499f-aa07-fe5b1a167f69.png") no-repeat center 0px;
        background-size: cover;
    }
}
// .progress{
//    cursor: pointer;
// }

.circle {
    // margin: 0px 20px 16px 16px;
    // width: 100%;
    // height: 100%;
    width: 665px;
    height: 375px;
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/4428fe71-4ee9-465a-90c0-230f1ca62489.png") no-repeat center 0px;
    background-size: cover;
    .title{
        // margin-left: 15px;
        // flex: .2;
        margin-right: 28px;
        // margin-bottom: 23px;
        color: #fff;
        display: flex;
        justify-content: flex-end;
        // align-items: center;
        padding-top: 20px;
        height: 63px;
        // text-shadow:  #0000004d 0 2px;;
      .text {
        font-size: 16px;
        font-weight: 700;
        font-family: Microsoft YaHei;
        background-image: linear-gradient(0deg, #56FEFF, #E2F3FB);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
        .icon {
            width: 18px;
            height: 18px;
            margin-left: 8px;
        }
      .tip{
        width: 28px;
        height: 28px;
        position: relative;
        margin-left: 10px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .poup{
        background: url('../../assets/image/tip.png') no-repeat center center;
        padding: 20px;
        text-align: left;
        right: 10px;
        z-index: 100;
        position: absolute;
        width: 302px;
        // height: 71px;
        color: #c0e2ff;
        font-weight: 400;
        font-size: 12px;
        font-face: Microsoft YaHei;
        line-height: 20px;
        display: none;
        .txt{
          font-face: Microsoft YaHei;
          line-height: 20px;
          color: #c0e2ff;
          font-weight: 400;
          font-size: 12px;
        }
      }
      .txt_box{
        display: flex;
        align-self: center;
        align-items: center;
        align-content: center;
        &:hover{
          .tip{
            .poup {
              display: flex;
            }
          }
        }
      }
    }
    .content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-around;
        // margin-right: 30px;
      .tip {
        top: -35px;
        position: absolute;
        opacity: 0;
        padding: 6px 15px;
        border-radius: 8px;
        background: #fff;
        font-size: 14px;
      }

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .count {
          // color: #fff;
          margin-top: 20px;
          background-image: linear-gradient(0deg, #56FEFF, #E2F3FB);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 700;
          font-size: 28px;
        }

        &:hover {
          .tip {
            opacity: 1;
          }
        }

        .txt {
          margin-top: 15px;
          color: #65A5DD;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
}
::v-deep .el-progress__text{
    color: #fff;
}

</style>
