<template>
  <div id="tables" class="table">
    <!-- table 表格 -->
    <el-table
      id="ou"
      ref="multipleTable"
      v-loading="loading"
      style="width: 100%"
      :height="isFixHeight ? height : null"
      :max-height="maxHeight"
      :border="true"
      :highlight-current-row="highlightCurrentRow"
      :data="tableData"
      :cell-style="{ textAlign: 'center' }"
      :header-cell-style="{ background: '#4e9aef', color: '#fff', textAlign: 'center' }"
      :row-key="
        row => {
          return row[rowKeyName]
        }
      "
      @selection-change="handleSelectionChange"
      @select-all="handleSelectAll"
      @row-click="rowClick"
    >
      <el-table-column v-if="isSelection" fixed type="selection" width="55" :reserve-selection="reserveSelection" />
      <el-table-column v-if="isShowIndex" fixed type="index" label="序号" width="55" :index="hIndex" />
      <template v-for="(item, index) in tableHeader">
        <el-table-column
          v-if="!item.isHide"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :min-width="item.width"
          :sortable="item.sortable == true"
          :fixed="item.fixed || false"
        >
          <!-- <template v-if="item.type === 'slot'" #default="{ row }">
            <slot :name="item.slotName" :data="row" :index="row.$index"></slot>
          </template> -->
          <template v-if="item.type === 'slot'" #default="scope">
            <slot :name="item.slotName" :data="scope.row" :index="scope.$index"></slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <!-- table end -->
    <!-- 分页器 -->
    <div v-if="isShowPage" class="pagination">
      <el-pagination
        :current-page="currentPage"
        :page-sizes="pageSize"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 表格数据
    tableData: {
      type: Array,
      default: () => []
    },
    // 表格头部
    tableHeader: {
      type: Array,
      default: () => []
    },
    // 是否开启表格固定高度，默认开启
    isFixHeight: {
      type: Boolean,
      default: true
    },
    // 表格高度
    height: {
      type: String,
      default: '500px'
    },
    // 表格最大高度
    maxHeight: {
      type: String || Number,
      default: null
    },
    // 开启加载
    loading: {
      type: Boolean,
      default: false
    },
    // 分页器页数
    // pageNum: {
    //   type: Number,
    //   default: 0,
    // },
    // 总条数
    total: {
      type: Number,
      default: 0
    },
    // 是否显示分页器
    isShowPage: {
      type: Boolean,
      default: true
    },
    // 是否显示表格首列勾选框
    isSelection: {
      type: Boolean,
      default: false
    },
    // 是否保留之前选中的数据，与row-Key搭配使用
    reserveSelection: {
      type: Boolean,
      default: true
    },
    // 行数据的 Key，用来优化 Table 的渲染
    rowKeyName: {
      // 在使用 reserve-selection 功能与显示树形数据时，该属性是必填的
      type: String,
      default: 'id'
    },
    // 是否显示表格序号
    isShowIndex: {
      type: Boolean,
      default: true
    },
    // 是否要高亮当前行，只需要配置highlight-current-row属性即可实现单选
    highlightCurrentRow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      multipleSelection: '',
      currentPage: 1, // 当前页数
      pageSize: this.$api.pageSizes, // 可选页数
      size: 10 // 每页大小
    }
  },
  watch: {
    pageNum: {
      deep: true,
      handler(newVal) {
        this.currentPage = newVal
      }
    }
  },
  updated() {
    if (this.$refs.multipleTable && this.$refs.multipleTable.doLayout) {
      this.$refs.multipleTable.doLayout()
    }
  },
  methods: {
    hIndex(index) {
      // console.log('index: ' + index);
      // index索引从零开始，index +1即为当前数据序号
      this.currentPage <= 0 ? (this.currentPage = 1) : this.currentPage
      // console.log('this.currentPage',this.currentPage);
      // 如果当前不是第一页数据
      if (this.currentPage != 1) {
        // index + 1 + (( 当前页 - 1) * 每页展示条数)
        // 比如是第二页数据 1 + ((2 - 1)*5) = 6,第二页数据也就是从序号6开始
        return index + 1 + (this.currentPage - 1) * this.size
      }
      // 否则直接返回索引+1作为序号
      return index + 1
    },
    // 每页条数
    async handleSizeChange(val) {
      this.size = val
      await this.$emit('setSize', val)
      // await this.$parent.setSize(val);
    },
    // 当前页数/页数切换
    async handleCurrentChange(val, emitData = true) {
      // isArtificialOperate: 手动操作
      this.currentPage = val
      if (emitData) {
        await this.$emit('setPage', val)
      }
      // await this.$parent.setPage(val);
    },
    // 选择项
    handleSelectionChange(val) {
      this.multipleSelection = val
      this.$emit('delXuan', val)
      // this.$parent.delXuan(val);
      // console.log(val);
    },
    // 勾选全选
    handleSelectAll(val) {
      this.$emit('selectAll', val)
    },
    // 切换行的选中状态
    toggleRowSelection(row, status = true) {
      this.$refs.multipleTable.toggleRowSelection(row, status)
    },
    // 清空用户的选择
    clearSelection() {
      this.$refs.multipleTable.clearSelection()
    },
    // 当某一行被点击时会触发该事件
    rowClick(row, column, event) {
      this.$emit('rowClick', row)
    },
    // 设定某一行为选中行，如果调用时不加参数，则会取消目前高亮行的选中状态。
    setCurrentRow(row) {
      this.$refs.multipleTable.setCurrentRow(row)
    }
  }
}
</script>

<style scoped lang="scss">
.table {
  margin: 10px auto;
  width: 95%;
}

.pagination {
  margin-top: 10px;
  text-align: end;
}
</style>
