<!-- 产品库【电商品管理 -- 新增编辑产品】 -->
<template>
  <div class="main-box">
    <ShareAddProduct />
  </div>
</template>

<script>
import ShareAddProduct from '../components/shareAddProduct'

export default {
  components: {
    ShareAddProduct
  },
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped></style>
