<template>
<div class="main-box">
    <!-- 搜索区域 -->
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="优惠名称">
          <el-input v-model="inquire.discountName" placeholder="请输入优惠名称" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
     
     <!-- 表格区域 -->
    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="discountName" label="名称" min-width="160" />
            <el-table-column prop="sort" label="排序" min-width="100" />
            <el-table-column prop="createTime" label="上架时间" min-width="160" />
            <!-- <el-table-column prop="couponStatus" label="状态" min-width="100">
               <template slot-scope="scope">{{ scope.row.couponStatus | getStatus }}</template>
              </el-table-column> -->
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <!-- <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" v-if="scope.row.couponStatus == 1" @click="failureData(scope.row , 0)">失效</span>
                <span class="operation-btn" v-if="scope.row.couponStatus == 0" @click="failureData(scope.row , 1)">生效</span> -->
              </template>
            </el-table-column>
          </el-table>
        </template>
        <!-- <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div> -->
      </el-main>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            // 查询参数
        inquire: {
        discountName: ''
      },
          // 分页参数
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      // 表格数据
      tableData: [],
        }
    },
      // 过滤器
    filters:{
    // 状态处理
   getStatus(val){
     let newTxt = val == 0 ? "失效" : "生效"
     return newTxt
    }
    },
    created() {
    // 获取数据列表
    this.getTableList()
  },
    methods: {
    // 获取数据列表
    getTableList() {
      this.loading = true
      this.$http.requestPost({
        url: '/coupon/getDiscounts',
        param: this.inquire,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },
    // 查询功能
    searchList() {
      this.getTableList()
    },
      // 页容量改变
      handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 冻结或开启优惠
    failureData(row,status){
      let name = status == 0 ? '禁用'  : '启用'
        this.$confirm(`是否确定${name}优惠?`, `${name}优惠确认`, {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http.requestGet({url:'/coupon/modifyStatus/' + row.couponCode + '/' + status})
          .then(res => {
            this.getTableList();
            this.$message({
              type: 'success',
              message: '操作成功!'
            });

          }).catch(() => {})
      }).catch(() => {})
    },
    // 跳转到编辑页面
    handleEdit(row){
      this.$router.push({
                path: '/product/nutrition/editDiscounts',
                query: {
                    id: row.couponCode
                }
            })
    }
    },
}
</script>

<style>
</style>