var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            _vm.selectValue = ""
                          },
                        },
                        model: {
                          value: _vm.selectKey,
                          callback: function ($$v) {
                            _vm.selectKey = $$v
                          },
                          expression: "selectKey",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "订单编号", value: "orderCode" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "收货人姓名", value: "recipient" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "专员姓名", value: "attacheName" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.selectValue,
                        callback: function ($$v) {
                          _vm.selectValue = $$v
                        },
                        expression: "selectValue",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "申请时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "range-separator": "至",
                      "end-placeholder": "结束日期",
                      "start-placeholder": "开始日期",
                      type: "daterange",
                    },
                    model: {
                      value: _vm.daterange,
                      callback: function ($$v) {
                        _vm.daterange = $$v
                      },
                      expression: "daterange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "产品名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.searchList($event)
                      },
                    },
                    model: {
                      value: _vm.inquire.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "name", $$v)
                      },
                      expression: "inquire.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticStyle: {
            margin: "20px 0 0 20px",
            display: "flex",
            "align-items": "center",
          },
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.fnExportOrderList },
            },
            [_vm._v("导出")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleBtnYp },
            },
            [_vm._v("推送至诺税通")]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-left": "20px" } }, [
            _vm._v("本月已完成开票："),
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { "font-size": "18px" } }, [
            _vm._v(_vm._s(_vm.ybTotal)),
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { "margin-left": "10px" } }, [_vm._v("张")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "tables",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "expand-row-keys": _vm.expands,
                      border: "",
                      height: "400px",
                      "row-key": "invoiceId",
                    },
                    on: {
                      "expand-change": _vm.expandChange,
                      "selection-change": _vm.handleSelectionChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "50",
                        selectable: _vm.checkSelectable,
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { type: "expand" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { data: scope.row.items, border: "" },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品信息",
                                      "min-width": "200",
                                      prop: "",
                                      "show-overflow-tooltip": "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "flex-box" },
                                                [
                                                  scope.row.productImg
                                                    ? _c("img", {
                                                        staticClass: "img-box",
                                                        attrs: {
                                                          src: scope.row
                                                            .productImg,
                                                          alt: "",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "hospital-name",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.productName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品编号",
                                      "min-width": "160",
                                      prop: "productCode",
                                      "show-overflow-tooltip": "",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "产品规格",
                                      "min-width": "120",
                                      prop: "productNorms",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.productNorms || "/"
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "单价",
                                      "min-width": "120",
                                      prop: "itemPrice",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                _vm._s(
                                                  "￥" + scope.row.itemPrice
                                                )
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "数量",
                                      "min-width": "120",
                                      prop: "itemNum",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "购买总价",
                                      "min-width": "120",
                                      prop: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    "￥" +
                                                      (
                                                        parseInt(
                                                          scope.row.itemNum
                                                        ) * scope.row.itemPrice
                                                      ).toFixed(2)
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "订单编号",
                        "min-width": "200",
                        prop: "orderCode",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleParticulars(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.orderCode))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "买家/收货人",
                        "min-width": "160",
                        prop: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    scope.row.customerOrder.recipient
                                      ? scope.row.customerOrder.memberNickName +
                                          "/" +
                                          scope.row.customerOrder.recipient
                                      : scope.row.customerOrder.memberNickName +
                                          "（自提）"
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "开票金额",
                        "min-width": "120",
                        prop: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" +
                                      (scope.row.customerOrder.invoiceAmount
                                        ? scope.row.customerOrder.invoiceAmount.toFixed(
                                            2
                                          )
                                        : "0.00")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { label: "运费", "min-width": "120", prop: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    "￥" +
                                      (scope.row.customerOrder.shippingFee
                                        ? scope.row.customerOrder.shippingFee.toFixed(
                                            2
                                          )
                                        : "0.00")
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "开票申请时间",
                        "min-width": "160",
                        prop: "createTime",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "订单状态",
                        "min-width": "120",
                        prop: "orderStatus",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.$api.getValue(
                                      _vm.$api.EcommerceOrderState,
                                      scope.row.customerOrder.orderStatus,
                                      "name"
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "所属专员",
                        "min-width": "120",
                        prop: "attacheName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.customerOrder.attacheName) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "大区",
                        "min-width": "120",
                        prop: "regionName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.regionName) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "片区",
                        "min-width": "120",
                        prop: "districtName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.districtName) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "办事处",
                        "min-width": "120",
                        prop: "agencyName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.agencyName) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "医院",
                        "min-width": "120",
                        prop: "hospitalName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.hospitalName) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "商家",
                        "min-width": "120",
                        prop: "merchantName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.customerOrder.merchantName) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "发票抬头",
                        "min-width": "120",
                        prop: "invoiceName",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.invoiceName) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "开票状态",
                        "min-width": "120",
                        prop: "invoiceStatus",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(scope.row.invoiceStatus) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        "min-width": "160",
                        prop: "remark",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.invoiceStatus === "待审核" ||
                                        scope.row.invoiceStatus === "开票失败",
                                      expression:
                                        "scope.row.invoiceStatus === '待审核' || scope.row.invoiceStatus === '开票失败'",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSH(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.invoiceStatus === "待审核" ||
                                        scope.row.invoiceStatus === "开票失败",
                                      expression:
                                        "scope.row.invoiceStatus === '待审核' || scope.row.invoiceStatus === '开票失败'",
                                    },
                                  ],
                                  staticClass: "operation-btn_partition",
                                },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.invoiceStatus === "待审核" ||
                                        scope.row.invoiceStatus === "开票失败",
                                      expression:
                                        "scope.row.invoiceStatus === '待审核' || scope.row.invoiceStatus === '开票失败'",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleWait(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("开票")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.invoiceStatus === "待审核" ||
                                        scope.row.invoiceStatus === "开票失败",
                                      expression:
                                        "scope.row.invoiceStatus === '待审核' || scope.row.invoiceStatus === '开票失败'",
                                    },
                                  ],
                                  staticClass: "operation-btn_partition",
                                },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        scope.row.invoiceStatus === "待审核" ||
                                        scope.row.invoiceStatus === "开票中" ||
                                        scope.row.invoiceStatus === "开票失败",
                                      expression:
                                        "scope.row.invoiceStatus === '待审核' || scope.row.invoiceStatus === '开票中' || scope.row.invoiceStatus === '开票失败'",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleParticulars(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("订单详情")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam.pageNum,
                      "page-size": _vm.pageParam.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.pageParam.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "开票",
            width: "500px",
            "before-close": _vm.closeDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c("div", { staticClass: "product_wrapper" }, [
                _c("div", [_vm._v("产品信息：")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "product_item_box" },
                  _vm._l(_vm.waitInfo.items, function (item, itemsIndex) {
                    return _c(
                      "div",
                      { key: itemsIndex, staticClass: "product_item" },
                      [
                        _c("img", { attrs: { src: item.productImg, alt: "" } }),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(item.productName))]),
                          _vm._v(" "),
                          _c("div", [_vm._v("数量：" + _vm._s(item.itemNum))]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v("单价：" + _vm._s(item.itemPrice)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "wait-info" }, [
                _c("ul", [
                  _c("li", { staticClass: "wait-li flex-box" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("订单编号：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.waitInfo.orderCode)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "wait-li flex-box" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("开票金额：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s("￥" + _vm.waitInfo.invoicePrice)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "wait-li flex-box" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("抬头类型：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(
                        _vm._s(_vm.waitInfo.invoiceType == 1 ? "企业" : "个人")
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "wait-li flex-box" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("发票抬头：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.waitInfo.invoiceName)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.waitInfo.invoiceType == 1
                    ? _c("li", { staticClass: "wait-li flex-box" }, [
                        _c("div", { staticClass: "lable" }, [_vm._v("税号：")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.waitInfo.taxNumber)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.waitInfo.invoiceType == 1
                    ? _c("li", { staticClass: "wait-li flex-box" }, [
                        _c("div", { staticClass: "lable" }, [
                          _vm._v("开户银行："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.waitInfo.openBankName || "无")),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.waitInfo.invoiceType == 1
                    ? _c("li", { staticClass: "wait-li flex-box" }, [
                        _c("div", { staticClass: "lable" }, [
                          _vm._v("开户账号："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.waitInfo.openBankNnumber || "无")),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.waitInfo.invoiceType == 1
                    ? _c("li", { staticClass: "wait-li flex-box" }, [
                        _c("div", { staticClass: "lable" }, [
                          _vm._v("企业地址："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(
                            _vm._s(_vm.waitInfo.enterpriseAddress || "无")
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                    "label-width": "100px",
                    size: "medium",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "发票图片：", prop: "invoiceUrl" } },
                    [
                      _c(
                        "d-upload",
                        {
                          attrs: {
                            "upload-type": "picture-card",
                            "file-list": _vm.fileList,
                            accept: ".gif,.jpeg,.jpg,.png",
                          },
                          on: {
                            removeFile: _vm.removeFile,
                            uploadSuccess: _vm.uploadSuccess,
                          },
                        },
                        [
                          _c("div", { staticClass: "tip-box" }, [
                            _c("i", { staticClass: "el-icon-plus" }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "tip",
                                attrs: { slot: "tip" },
                                slot: "tip",
                              },
                              [_vm._v("图片大小：210*297")]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 3,
                          maxlength: "150",
                          placeholder: "请输入内容",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.submitForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "remark", $$v)
                          },
                          expression: "submitForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogSH,
            title: "审核",
            width: "500px",
            "before-close": _vm.closeDialogSH,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSH = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c("div", { staticClass: "product_wrapper" }, [
                _c("div", [_vm._v("产品信息：")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "product_item_box" },
                  _vm._l(_vm.waitInfo.items, function (item, itemsIndex) {
                    return _c(
                      "div",
                      { key: itemsIndex, staticClass: "product_item" },
                      [
                        _c("img", { attrs: { src: item.productImg, alt: "" } }),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(item.productName))]),
                          _vm._v(" "),
                          _c("div", [_vm._v("数量：" + _vm._s(item.itemNum))]),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v("单价：" + _vm._s(item.itemPrice)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "wait-info" }, [
                _c("ul", [
                  _c("li", { staticClass: "wait-li flex-box" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("订单编号：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.waitInfo.orderCode)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "wait-li flex-box" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("开票金额：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s("￥" + _vm.waitInfo.invoicePrice)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "wait-li flex-box" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("抬头类型：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(
                        _vm._s(_vm.waitInfo.invoiceType == 1 ? "企业" : "个人")
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", { staticClass: "wait-li flex-box" }, [
                    _c("div", { staticClass: "lable" }, [_vm._v("发票抬头：")]),
                    _vm._v(" "),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.waitInfo.invoiceName)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.waitInfo.invoiceType == 1
                    ? _c("li", { staticClass: "wait-li flex-box" }, [
                        _c("div", { staticClass: "lable" }, [_vm._v("税号：")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.waitInfo.taxNumber)),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.waitInfo.invoiceType == 1
                    ? _c("li", { staticClass: "wait-li flex-box" }, [
                        _c("div", { staticClass: "lable" }, [
                          _vm._v("开户银行："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.waitInfo.openBankName || "无")),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.waitInfo.invoiceType == 1
                    ? _c("li", { staticClass: "wait-li flex-box" }, [
                        _c("div", { staticClass: "lable" }, [
                          _vm._v("开户账号："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(_vm._s(_vm.waitInfo.openBankNnumber || "无")),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.waitInfo.invoiceType == 1
                    ? _c("li", { staticClass: "wait-li flex-box" }, [
                        _c("div", { staticClass: "lable" }, [
                          _vm._v("企业地址："),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "content" }, [
                          _vm._v(
                            _vm._s(_vm.waitInfo.enterpriseAddress || "无")
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    model: _vm.submitForm,
                    "label-width": "100px",
                    size: "medium",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 3,
                          maxlength: "150",
                          placeholder: "请输入内容",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.submitForm.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "remark", $$v)
                          },
                          expression: "submitForm.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.waitInfo.invoiceStatus === "4",
                      expression: "waitInfo.invoiceStatus === '4'",
                    },
                  ],
                  attrs: { "label-width": "140px" },
                },
                [
                  _c("el-form-item", { attrs: { label: "诺税通驳回原因：" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.waitInfo.failCause || "无"))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.waitInfo.invoiceStatus === "0" ||
                        _vm.waitInfo.invoiceStatus === "3" ||
                        _vm.waitInfo.invoiceStatus === "4",
                      expression:
                        "waitInfo.invoiceStatus === '0' || waitInfo.invoiceStatus === '3' || waitInfo.invoiceStatus === '4'",
                    },
                  ],
                  on: { click: _vm.rejectSH },
                },
                [_vm._v("驳 回")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.waitInfo.invoiceStatus === "0" ||
                        _vm.waitInfo.invoiceStatus === "3",
                      expression:
                        "waitInfo.invoiceStatus === '0' || waitInfo.invoiceStatus === '3'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSH },
                },
                [_vm._v("通 过")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.waitInfo.invoiceStatus === "4",
                      expression: "waitInfo.invoiceStatus === '4'",
                    },
                  ],
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmCX },
                },
                [_vm._v("重新开票")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.dialogYb, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogYb = $event
            },
          },
        },
        [
          _c("span", [_vm._v("是否全部审核通过并推送至诺税通开票？")]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.handleBtnQx } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleBtnQr } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }