<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="层级名称">
          <el-input v-model="inquire.hierarchyName" placeholder="层级名称" />
        </el-form-item>

        <el-form-item label="所属医院" prop="hospitalId">
          <el-select v-model="inquire.hospitalId" filterable placeholder="请选择" class="form-select">
            <el-option v-for="item in hospitalList" :key="item.hospitalId" :label="item.hospitalName" :value="item.hospitalId" />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addAddress">添加地址层级</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="hierarchyName" label="地址层级名称" min-width="160" />
            <el-table-column prop="hierarchyDescription" label="描述" min-width="160" />
            <el-table-column prop="hierarchyOrder" label="排序" min-width="120" />
            <el-table-column prop="hierarchyStatus" label="状态" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.hierarchyStatus === 'enable' ? '启用' : '禁用' }}
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="增加时间" min-width="160" />
            <!-- <el-table-column prop="createByName" label="添加人" min-width="120"></el-table-column> -->
            <el-table-column prop="" label="操作" min-width="160" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span v-show="scope.row.hierarchyStatus === 'disable'" class="operation-btn" @click="switchs(scope.row,'s')">启用</span>
                <span v-show="scope.row.hierarchyStatus === 'enable'" class="operation-btn" @click="switchs(scope.row, 'h')">禁用</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleDel(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加自提地址弹框 -->
    <el-dialog
      :title="dialogTitle + '地址层级'"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="650px"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="110px" :model="submitForm" :rules="submitRules">
          <el-form-item label="所属医院" prop="hospitalId">
            <el-select v-model="submitForm.hospitalId" filterable placeholder="请选择" class="form-select">
              <el-option v-for="item in hospitalList" :key="item.hospitalId" :label="item.hospitalName" :value="item.hospitalId" />
            </el-select>
          </el-form-item>
          <el-form-item label="层级名称" prop="hierarchyName">
            <el-input v-model="submitForm.hierarchyName" maxlength="20" placeholder="层级名称" @keyup.enter.native="searchList" />
          </el-form-item>
          <el-form-item label="描述" prop="hierarchyDescription">
            <el-input v-model="submitForm.hierarchyDescription" maxlength="30" placeholder="描述" />
          </el-form-item>
          <!-- <el-form-item label="填写规则" prop="hierarchyFillRule">
              <el-select v-model="submitForm.hierarchyFillRule" placeholder="请选择" class="form-select">
                <el-option label="必填" value="required"></el-option>
                <el-option label="选填" value="optional"></el-option>
              </el-select>
            </el-form-item> -->
          <el-form-item label="排序" prop="hierarchyOrder">
            <el-input v-model.number="submitForm.hierarchyOrder" placeholder="排序" />
          </el-form-item>
          <el-form-item label="状态" prop="hierarchyStatus">
            <el-select v-model="submitForm.hierarchyStatus" placeholder="请选择" class="form-select">
              <el-option label="禁用" value="disable" />
              <el-option label="启用" value="enable" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        hierarchyName: '',
        hospitalId: ''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      loading: false,

      // 添加配送地址
      dialogTitle: '添加', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      submitForm: {
        hospitalId: '',
        hierarchyName: '',
        hierarchyDescription: '',
        // hierarchyFillRule:  '',
        hierarchyOrder: '',
        hierarchyStatus: ''
      },
      submitRules: {
        hospitalId: [{ required: true, message: '请选择所属医院', trigger: 'change' }],
        hierarchyName: [{ required: true, message: '请输入层级名称', trigger: 'blur' }],
        hierarchyDescription: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        // hierarchyFillRule: [{required: true, message: '请选择填写规则', trigger: 'change'}],
        hierarchyOrder: [
          { required: true, message: '请输入排序', trigger: 'blur' },
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ],
        hierarchyStatus: [{ required: true, message: '请选择状态', trigger: 'change' }]

      },
      isAdd: true, // 是否为添加
      editRow: {},
      hospitalList: [] // 医院列表
    }
  },
  created() {
    // 获取医院列表
    this.getHospitalList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    // 获取省市区
    getHospitalList() {
      this.$http.requestPost({ url: '/hospital/medical/list', loading: false }).then(res => {
        this.hospitalList = res.data
        this.inquire.hospitalId = res.data[0].hospitalId
        // 获取列表
        this.getTableList()
      })
    },

    // 获取自提地址列表
    getTableList() {
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: {
          hierarchyName: this.inquire.hierarchyName ? this.inquire.hierarchyName : undefined,
          hospitalId: this.inquire.hospitalId
        }
      }
      this.loading = true
      this.$http.requestPost({
        url: '/hospital/address/hierarchy/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.pageParam.total = res.data.total
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    // 添加产品类别
    addAddress() {
      this.dialogTitle = '添加'
      this.dialogVisible = true
      this.isAdd = true
    },
    // 修改类别
    handleEdit(row) {
      this.dialogTitle = '编辑'
      this.isAdd = false
      this.submitForm = {
        hospitalId: row.hospitalId,
        hierarchyName: row.hierarchyName,
        hierarchyDescription: row.hierarchyDescription,
        // hierarchyFillRule:  row.hierarchyFillRule,
        hierarchyOrder: row.hierarchyOrder,
        hierarchyStatus: row.hierarchyStatus
      }
      this.dialogVisible = true
      this.editRow = row
    },
    // 提交类别添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            hospitalId: this.submitForm.hospitalId,
            hierarchyName: this.submitForm.hierarchyName,
            hierarchyDescription: this.submitForm.hierarchyDescription,
            // hierarchyFillRule:  this.submitForm.hierarchyFillRule,
            hierarchyOrder: this.submitForm.hierarchyOrder,
            hierarchyStatus: this.submitForm.hierarchyStatus
          }
          if (!this.isAdd) {
            param.hierarchyId = this.editRow.hierarchyId
          }
          this.$http.requestPost({
            url: '/hospital/address/hierarchy/save',
            param: param
          }).then(res => {
            this.getTableList()
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.closeDialog()
          })
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields())
      this.dialogVisible = false
      this.submitForm = {
        hierarchyName: '',
        hierarchyDescription: '',
        // hierarchyFillRule:  '',
        hierarchyOrder: '',
        hierarchyStatus: ''
      }
    },

    // 启用禁用
    switchs(row, type) {
      const name = type === 's' ? '启用' : '禁用'
      const state = type === 's' ? 'enable/' : 'disable/'
      this.$confirm('是否确定' + name + '?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$http.requestPut({ url: '/hospital/address/hierarchy/' + state + row.hierarchyId })
          .then(res => {
            this.getTableList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
      }).catch(() => {})
    },

    handleDel(row) {
      this.$confirm('确定删除该地址层级?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$http.requestDel({ url: '/hospital/address/hierarchy/del/' + row.hierarchyId })
          .then(res => {
            this.getTableList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
      }).catch(() => {})
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
