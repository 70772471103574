<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="最近消费时间">
          <div class="flex-box">
            <el-date-picker
              v-model="lastConsumeTime"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
            />
          </div>
        </el-form-item>

        <el-form-item label="累计消费">
          <div class="flex-box">
            <el-input v-model.number="inquire.consumeSumBegin" class="width-110" placeholder="0" />
            <span class="span-link">-</span>
            <el-input v-model.number="inquire.consumeSumEnd" class="width-110" placeholder="0" />
          </div>
        </el-form-item>

        <el-form-item label="消费次数">
          <div class="flex-box">
            <el-input v-model.number="inquire.consumeCountBegin" class="width-110" placeholder="0" />
            <span class="span-link">-</span>
            <el-input v-model.number="inquire.consumeCountEnd" class="width-110" placeholder="0" />
          </div>
        </el-form-item>

        <el-form-item label="注册时间">
          <div class="flex-box">
            <el-date-picker
              v-model="registerTime"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
            />
          </div>
        </el-form-item>

        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="selectKey" placeholder="请选择" class="width-140" @change="selectValue = ''">
              <el-option label="微信昵称" value="nickname" />
              <el-option label="手机号" value="memberPhoneNumber" />
              <el-option label="会员编号" value="memberCode" />
            </el-select>
            <el-input v-model="selectValue" placeholder="" class="width-180" @keyup.enter.native="searchList" />
          </div>
        </el-form-item>

        <el-form-item label="来源">
          <el-input v-model="inquire.sourceWx" class="width-110" placeholder="" />
        </el-form-item>

        <el-form-item label="会员等级">
          <el-select v-model="inquire.vipLevel" placeholder="请选择" clearable class="form-select width-110">
            <el-option v-for="item in $api.userLevel" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>

        <el-form-item label="所属分组">
          <el-select v-model="inquire.categoryId" placeholder="请选择" clearable class="form-select width-110">
            <el-option v-for="item in groupData" :key="item.id" :label="item.categoryName" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="handleAdd(1)">设置所属专员</el-button>
      <el-button size="small" type="primary" @click="handleAdd(2)">设置营养师</el-button>
    </div>

    <div class="table">
      <el-main>
        <el-table v-loading="loading" :data="tableData" border height="400" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label="微信昵称" min-width="200" prop="memberNickname" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-box flex-center">
                <img v-if="scope.row.memberAvatar" :src="scope.row.memberAvatar" class="img-box-show box-m-r" alt="" />
                <div>{{ scope.row.memberNickname }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="会员编号" min-width="200" prop="memberCode" />
          <el-table-column label="最近消费时间" min-width="160" prop="lastConsumeTime" />
          <el-table-column label="累计消费" min-width="120" prop="consumeSum">
            <template slot-scope="scope">
              {{ '￥' + scope.row.consumeSum.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="消费次数" min-width="120" prop="consumeCount" />
          <el-table-column label="注册方式" min-width="120" prop="source">
            <template slot-scope="scope">
              <div v-if="scope.row.source == 1">APP</div>
              <div v-else-if="scope.row.source == 2">小程序</div>
            </template>
          </el-table-column>
          <el-table-column label="注册手机号" min-width="120" prop="memberTelephone" />
          <el-table-column label="注册时间" min-width="140" prop="registerTime" />
          <el-table-column label="注册时长（天）" min-width="120" prop="registerDuration" />
          <el-table-column label="最近登录时间" min-width="160" prop="lastLoginTime" />
          <el-table-column label="所属专员" min-width="120" prop="attacheName" />
          <el-table-column label="所属营养师" min-width="120" prop="dietitianName" />
          <el-table-column label="会员等级" min-width="120" prop="vipLevel">
            <template slot-scope="scope">
              {{ findEnumName($api.userLevel, scope.row.vipLevel) }}
            </template>
          </el-table-column>
          <el-table-column label="所属业务员" min-width="120" prop="intendantName" />
          <el-table-column label="业务员编号" min-width="200" prop="intendantCode" />
          <el-table-column label="二维码编号" min-width="200" prop="qrCodeSerial" />
          <el-table-column label="姓名" min-width="120" prop="name" />
          <el-table-column label="身份证号码" min-width="200" prop="idCardNumber" />
          <el-table-column label="所属分组" min-width="120" prop="categoryName" />
          <!-- <el-table-column label="购物状态" min-width="120" prop="name">
            <template slot-scope="scope">
              {{ scope.row.name && scope.row.idCardNumber ? '海淘' : '普通' }}
            </template>
          </el-table-column> -->
          <el-table-column label="来源" min-width="120" prop="sourceWx" />
          <el-table-column fixed="right" label="操作" min-width="240" prop="">
            <template slot-scope="scope">
              <!-- <span class="operation-btn" @click="handleEdit(scope.row)">会员档案</span>
              <span class="operation-btn_partition">/</span> -->
              <span class="operation-btn" @click="openSellDialog(scope.row)">消费记录</span>
              <span class="operation-btn_partition">/</span>
              <span class="operation-btn" @click="openSiteDialog(scope.row)">浏览记录</span>
              <!-- <span class="operation-btn_partition"> / </span> -->
              <!-- <span class="operation-btn" @click="handleLockt(scope.row)">锁定</span> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-size="pageParam.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 会员档案 -->
    <el-dialog :before-close="closeDialog_1" :title="dialogTitle + ' - 会员档案'" :visible.sync="dialogVisible_1" top="5vh" width="600px">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px">
          <el-form-item label="微信昵称">
            <div class="flex-box">
              <img v-if="submitForm.memberAvatar" :src="submitForm.memberAvatar" alt="" class="img-box-show box-m-r" />
              <div>{{ submitForm.memberNickname }}</div>
            </div>
          </el-form-item>
          <el-form-item label="会员编号">
            <el-input v-model="submitForm.memberCode" disabled placeholder="会员编号" />
          </el-form-item>
          <el-form-item label="姓名">
            <el-input v-model="submitForm.memberName" placeholder="姓名" />
          </el-form-item>
          <el-form-item label="性别">
            <el-radio v-model="submitForm.memberGender" label="boy">男</el-radio>
            <el-radio v-model="submitForm.memberGender" label="girl">女</el-radio>
          </el-form-item>
        </el-form>

        <div v-if="submitForm.overseaItem && submitForm.overseaItem.recipient" class="ht-info">
          <div class="ht-info-title">海淘信息</div>
          <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px">
            <el-form-item label="收件人">
              <div class="flex-box">
                <div>{{ submitForm.overseaItem.recipient }}</div>
              </div>
            </el-form-item>
            <el-form-item label="联系方式">
              <div class="flex-box">
                <div>{{ submitForm.overseaItem.recipientPhoneNumber }}</div>
              </div>
            </el-form-item>
          </el-form>
          <div class="flex-box ht-info-img">
            <img :src="submitForm.overseaItem.idCardFrontUrl" />
            <img :src="submitForm.overseaItem.idCardBackUrl" />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog_1">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 设置所属弹框 -->
    <el-dialog :before-close="closeCPM" :visible.sync="dialogVisible_2" :title="dialogName1" width="600px">
      <div v-if="dialogName1 == '设置所属专员'">
        <div class="Popout_content">
          <el-form ref="submitForm_2" :model="submitForm_2" :rules="submitRules_2" label-width="100px">
            <el-form-item label="已选会员">
              <div class="flex-box user-list">
                <div v-for="(item, index) in selectList" :key="index" class="flex-box">
                  <img v-if="item.memberAvatar" :src="item.memberAvatar" alt="" />
                  <div>{{ item.memberNickname }}</div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="所属专员" prop="attacheId">
              <div><el-button type="primary" size="small" @click="openAttacheList(1)">选择</el-button></div>
              <div>
                <el-table :data="attacheTable" border style="width: 100%">
                  <el-table-column prop="attacheCode" label="专员编号" min-width="160" />
                  <el-table-column prop="attacheName" label="专员姓名" min-width="100" />
                  <el-table-column prop="attachePhoneNumber" label="手机号" min-width="120" />
                </el-table>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div style="text-align: end;padding-bottom: 20px;">
          <el-button @click="closeCPM">取 消</el-button>
          <el-button type="primary" @click="submitEdit">确 定</el-button>
        </div>
      </div>
      <div v-else>
        <div class="Popout_content">
          <el-form ref="submitForm_3" :model="submitForm_3" :rules="submitRules_3" label-width="100px">
            <el-form-item label="已选会员">
              <div class="flex-box user-list">
                <div v-for="(item, index) in selectList" :key="index" class="flex-box">
                  <img v-if="item.memberAvatar" :src="item.memberAvatar" alt="" />
                  <div>{{ item.memberNickname }}</div>
                </div>
              </div>
            </el-form-item>
            <el-form-item label="所属营养师" prop="dietitianId">
              <div><el-button type="primary" size="small" @click="openAttacheList(2)">选择</el-button></div>
              <div>
                <el-table :data="nutritionTable" border style="width: 100%">
                  <el-table-column prop="dietitianCode" label="营养师编号" min-width="160" />
                  <el-table-column prop="dietitianName" label="营养师" min-width="100" />
                  <el-table-column prop="phoneNumber" label="手机号" min-width="120" />
                </el-table>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div style="text-align: end;padding-bottom: 20px;">
          <el-button @click="closeCPM">取 消</el-button>
          <el-button type="primary" @click="submitNut">确 定</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 专员列表弹框 -->
    <el-dialog :title="dialogName2" :visible.sync="dialogVisible_5" :before-close="() => (dialogVisible_5 = false)" width="750px" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item>
            <el-input v-model="inquire_4.attacheName" placeholder="名称" />
          </el-form-item>

          <el-form-item>
            <el-input v-model="inquire_4.attacheCode" placeholder="编号" />
          </el-form-item>

          <el-form-item>
            <el-input v-model="inquire_4.attachePhoneNumber" placeholder="手机号" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="getAttacheList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="attacheTable"
          v-loading="loading_4"
          :data="tableData_4"
          border
          height="400px"
          style="width: 100%"
          highlight-current-row
          @current-change="handleSelectChange"
        >
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column prop="attacheCode" label="专员编号" min-width="160" />
          <el-table-column prop="attacheName" label="专员姓名" min-width="120" />
          <el-table-column prop="attachePhoneNumber" label="手机号" min-width="120" />
        </el-table>

        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_4.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_4.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_4.total"
            @size-change="handleSizeChange_4"
            @current-change="handleCurrentChange_4"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_5 = false">取 消</el-button>
        <el-button type="primary" @click="confirmAttache">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 营养师列表弹框 -->
    <el-dialog :title="dialogName2" :visible.sync="dialogVisible_6" :before-close="() => (dialogVisible_6 = false)" width="750px" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item>
            <el-input v-model="inquire_6.attacheName" placeholder="名称" />
          </el-form-item>

          <el-form-item>
            <el-input v-model="inquire_6.attacheCode" placeholder="编号" />
          </el-form-item>

          <!-- <el-form-item>
            <el-input v-model="inquire_6.attachePhoneNumber" placeholder="手机号"></el-input>
          </el-form-item> -->

          <el-form-item>
            <el-button type="primary" size="small" @click="getNutritionList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table
          ref="nutritionTable"
          v-loading="loading_6"
          :data="tableData_6"
          border
          height="400px"
          style="width: 100%"
          highlight-current-row
          @current-change="handleNutritionChange"
        >
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column prop="dietitianCode" label="营养师编号" min-width="160" />
          <el-table-column prop="dietitianName" label="营养师" min-width="120" />
          <el-table-column prop="phoneNumber" label="手机号" min-width="120" />
        </el-table>

        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_6.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_6.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_6.total"
            @size-change="handleSizeChange_6"
            @current-change="handleCurrentChange_6"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_6 = false">取 消</el-button>
        <el-button type="primary" @click="confirmNutrition">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 消费记录弹框 -->
    <el-dialog
      :title="sellTitle + ' - 消费记录'"
      :visible.sync="dialogVisible_3"
      :before-close="() => (dialogVisible_3 = false)"
      width="80%"
      top="5vh"
    >
      <div class="Popout_content">
        <div class="flex-box">
          <el-form :inline="true" class="demo-form-inline" size="medium">
            <el-form-item label="订单编号">
              <el-input v-model="inquire_2.orderCode" placeholder="订单编号" />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="small" @click="getSellList">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <el-table v-loading="loading_2" :data="tableData_2" border height="400px" style="width: 100%">
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table :data="scope.row.items" border style="width: 100%">
                <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div class="flex-box">
                      <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                      <div class="hospital-name">{{ scope.row.productName }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="产品编号" min-width="160" prop="productCode" show-overflow-tooltip />
                <el-table-column label="产品规格" min-width="120" prop="productNorms">
                  <template slot-scope="scope">
                    {{ scope.row.productNorms || '/' }}
                  </template>
                </el-table-column>
                <el-table-column label="单价" min-width="120" prop="itemPrice">
                  <template slot-scope="scope">
                    {{ '￥' + scope.row.itemPrice }}
                  </template>
                </el-table-column>
                <el-table-column label="数量" min-width="120" prop="itemNum" />
                <el-table-column label="购买总价" min-width="120" prop="">
                  <template slot-scope="scope">
                    {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column label="订单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
            <template slot-scope="scope">
              <span class="operation-btn" @click="handleParticulars(scope.row)">{{ scope.row.orderCode }}</span>
            </template>
          </el-table-column>
          <el-table-column label="买家/收货人" min-width="160" prop="">
            <template slot-scope="scope">
              {{ scope.row.memberNickName + '/' + scope.row.recipient }}
            </template>
          </el-table-column>
          <el-table-column label="总金额" min-width="120" prop="">
            <template slot-scope="scope">
              {{ '￥' + scope.row.orderTotalPrice.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="实付金额" min-width="120" prop="">
            <template slot-scope="scope">
              {{ '￥' + scope.row.paidInAmount.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="运费" min-width="120" prop="">
            <template slot-scope="scope">
              {{ '￥' + scope.row.shippingFee.toFixed(2) }}
            </template>
          </el-table-column>

          <el-table-column label="下单时间" min-width="160" prop="orderTime" />
          <el-table-column label="订单状态" min-width="120" prop="orderStatus">
            <template slot-scope="scope">
              {{ $api.getValue($api.EcommerceOrderState, scope.row.orderStatus, 'name') }}
            </template>
          </el-table-column>
          <el-table-column label="所属专员" min-width="120" prop="attacheName" />
          <el-table-column label="收货地址" min-width="200" prop="recipientAddressDetail" show-overflow-tooltip />
          <el-table-column prop="" label="操作" min-width="140" fixed="right">
            <template slot-scope="scope">
              <span class="operation-btn" @click="handleParticulars(scope.row)">订单详情</span>
            </template>
          </el-table-column>
        </el-table>
        <p style="padding-top:30px;"></p>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_3 = false">取 消</el-button>
      </span> -->
    </el-dialog>

    <!-- 配送地址列表弹框 -->
    <el-dialog
      :title="siteTitle + ' - 浏览记录'"
      :visible.sync="dialogVisible_4"
      :before-close="() => (dialogVisible_4 = false)"
      width="80%"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="浏览时间">
            <el-date-picker
              v-model="daterange"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="getSiteList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table v-loading="loading_3" :data="tableData_3" border height="400px" style="width: 100%">
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column prop="lastBrowseTime" label="最新浏览时间" min-width="160" />
          <el-table-column prop="productName" label="产品名称" min-width="160">
            <template slot-scope="scope">
              <div class="flex-box">
                <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                <div class="hospital-name">{{ scope.row.productName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="browseCount" label="浏览次数" min-width="100" />
          <el-table-column prop="productCategoryName" label="所属类别" min-width="160" />
          <el-table-column prop="productClassify" label="所属类型" min-width="160">
            <template slot-scope="scope">
              {{ $api.getValue($api.orderClassify, scope.row.productClassify, 'name') }}
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_3.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_3.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_3.total"
            @size-change="handleSizeChange_3"
            @current-change="handleCurrentChange_3"
          />
        </div>
        <p style="padding-top:30px;"></p>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_4 = false">取 消</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询
      inquire: {
        lastConsumeTimeBegin: '',
        lastConsumeTimeEnd: '',
        consumeSumBegin: '',
        consumeSumEnd: '',
        consumeCountBegin: '',
        consumeCountEnd: '',
        registerTimeBegin: '',
        registerTimeEnd: '',
        sourceWx: '', // 来源
        vipLevel: '',
        categoryId: ''
      },
      groupData: [],
      lastConsumeTime: [],
      registerTime: [],
      selectKey: 'nickname',
      selectValue: '',
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      loading: false,

      // ==================== 会员档案 ====================
      dialogVisible_1: false, // 弹窗
      dialogTitle: '',
      submitForm: {
        memberAvatar: '',
        memberNickname: '',
        memberCode: '',
        memberName: '',
        memberGender: '',
        overseaItem: {}
      },
      submitRules: {
        // memberName: [{required: true, message: '请输入姓名', trigger: 'blur'}],
        // memberGender: [{required: true, message: '请选择性别', trigger: 'blur'}],
      },
      editRow: {},

      // ==================== 设置所属专员 ====================
      dialogVisible_2: false, // 弹窗
      submitForm_2: {
        attacheId: '',
        memberIds: []
      },
      submitRules_2: {
        attacheId: [{ required: true, message: '请选择所属专员', trigger: 'blur' }]
      },
      selectList: [], // 会员的选择项
      attacheTable: [], // 选择专业
      submitForm_3: {
        dietitianId: '',
        memberIds: []
      },
      submitRules_3: {
        dietitianId: [{ required: true, message: '请选择所属营养师', trigger: 'blur' }]
      },
      nutritionTable: [], // 选择专业

      // ==================== 销售详情 ====================
      sellTitle: '', // 当前商家名称
      dialogVisible_3: false,
      inquire_2: {
        orderCode: ''
      },
      tableData_2: [],
      loading_2: false,

      // ==================== 浏览记录列表 ====================
      siteTitle: '', // 当前商家名称
      dialogVisible_4: false,
      inquire_3: {
        memberId: '',
        createTimeBegin: '',
        createTimeEnd: ''
      },
      daterange: [],
      tableData_3: [],
      loading_3: false,
      pageParam_3: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      // ==================== 专员列表 ====================
      dialogVisible_5: false,
      inquire_4: {
        attacheName: '',
        attacheCode: '',
        attachePhoneNumber: ''
      },
      loading_4: false,
      tableData_4: [],
      pageParam_4: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      selectRow: {},
      dialogName1: '',
      dialogName2: '',
      // ==================== 营养师列表 ====================
      dialogVisible_6: false,
      inquire_6: {
        attacheName: '',
        attacheCode: '',
        attachePhoneNumber: ''
      },
      loading_6: false,
      tableData_6: [],
      pageParam_6: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      selectRow6: {}
    }
  },
  created() {
    // 获取产品列表
    this.getTableList();
    //所属分组列表
    this.getGroupList();
  },
  methods: {
    // 封装方法(枚举方法)
    findEnumName(enumArray, targetValue) {
      const enumFound = enumArray.find(item => item.value === targetValue)
      return enumFound ? enumFound.name : ''
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      if (!this.lastConsumeTime) this.lastConsumeTime = []
      if (!this.registerTime) this.registerTime = []
      this.inquire.lastConsumeTimeBegin = this.lastConsumeTime.length > 0 ? this.lastConsumeTime[0] : ''
      this.inquire.lastConsumeTimeEnd = this.lastConsumeTime.length > 0 ? this.lastConsumeTime[1] : ''
      this.inquire.registerTimeBegin = this.registerTime.length > 0 ? this.registerTime[0] : ''
      this.inquire.registerTimeEnd = this.registerTime.length > 0 ? this.registerTime[1] : ''
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire))
      }
      data.param[this.selectKey] = this.selectValue

      this.loading = true
      this.$http
        .requestPost({
          url: '/customer/member/memberList',
          param: data,
          loading: false
        })
        .then(res => {
          console.log(res, '所属营养师')
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },

    //所属分组列表
    async getGroupList(){
      try {
        const params = {pageNum: 1, pageSize:100, categoryName: "" };
        const res = await this.$http.requestPost({ url: '/customer/talent/queryTalentCategoryList', param: params });
        if(res.code === 200){
          const list = res.data.list;
          this.groupData = list || [];
        };
      } catch (error) {
        this.$message.error('获取分组数据失败！');
      }
    },

    // ==================== 会员档案 start ====================
    // 修改类别
    handleEdit(row) {
      this.$http.requestGet({ url: '/customer/member/get/' + row.memberId }).then(res => {
        const data = res.data
        this.submitForm = {
          memberAvatar: data.memberAvatar,
          memberNickname: data.memberNickname,
          memberCode: data.memberCode,
          memberName: data.overseaItem.recipient,
          memberGender: data.memberGender,
          overseaItem: data.overseaItem
        }
      })
      this.dialogTitle = row.memberNickname
      this.dialogVisible_1 = true
      this.editRow = row
    },
    // 提交会员档案的修改
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            memberId: this.editRow.memberId,
            memberName: this.submitForm.memberName,
            memberGender: this.submitForm.memberGender
          }

          this.submit(param)
        } else {
          return false
        }
      })
    },
    // 提交
    submit(param) {
      this.$http
        .requestPost({
          url: '/customer/member/save',
          param: param
        })
        .then(res => {
          this.getTableList()
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.closeDialog_1()
        })
    },

    // 关闭弹窗
    closeDialog_1() {
      this.$refs.submitForm && this.$refs.submitForm.resetFields()
      this.dialogVisible_1 = false
      this.submitForm = {
        memberAvatar: '',
        memberNickname: '',
        memberCode: '',
        memberName: '',
        memberGender: ''
      }
    },

    // ==================== 设置所属专员 start ====================
    // table 选择的回调
    handleSelectionChange(val) {
      console.log(val)
      this.selectList = val
    },
    // 设置所属专员
    handleAdd(val) {
      if (this.selectList.length === 0) {
        return this.$message({ message: '请选择会员！', type: 'warning' })
      }
      if (val == 1) {
        this.dialogName1 = '设置所属专员'
        this.$refs.submitForm_2 && this.$refs.submitForm_2.resetFields()
      } else {
        this.dialogName1 = '设置所属营养师'
        this.$refs.submitForm_3 && this.$refs.submitForm_3.resetFields()
      }
      this.dialogVisible_2 = true
    },

    // 页容量改变
    handleSizeChange_4(size) {
      this.pageParam_4.pageNum = 1
      this.pageParam_4.pageSize = size
      this.getAttacheList()
    },
    // 页码改变
    handleCurrentChange_4(page) {
      this.pageParam_4.pageNum = page
      this.getAttacheList()
    },
    // 页容量改变
    handleSizeChange_6(size) {
      this.pageParam_6.pageNum = 1
      this.pageParam_6.pageSize = size
      this.getNutritionList()
    },
    // 页码改变
    handleCurrentChange_6(page) {
      this.pageParam_6.pageNum = page
      this.getNutritionList()
    },
    // 打开专员列表
    openAttacheList(val) {
      console.log(val, 'val')
      if (val == 1) {
        this.dialogName2 = '设置专员'
        this.dialogVisible_5 = true
        this.getAttacheList()
      } else {
        this.dialogName2 = '设置营养师'
        this.dialogVisible_6 = true
        this.getNutritionList()
      }
    },
    // 查询专员列表
    getAttacheList() {
      const data = {
        pageNum: this.pageParam_4.pageNum,
        pageSize: this.pageParam_4.pageSize,
        param: this.inquire_4
      }

      this.loading_4 = true
      this.$http
        .requestPost({
          url: '/customer/attache/page',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading_4 = false
          this.tableData_4 = res.data.list
          this.pageParam_4.total = res.data.total
          if (this.attacheTable.length > 0) {
            const index = this.tableData_4.findIndex(item => item.attacheCode === this.attacheTable[0].attacheCode)
            this.$refs.attacheTable.setCurrentRow(this.tableData_4[index])
          }
        })
        .catch(err => {
          this.loading_4 = false
          console.log(err)
        })
    },
    getNutritionList() {
      const data = {
        pageNum: this.pageParam_6.pageNum,
        pageSize: this.pageParam_6.pageSize,
        dietitianName: this.inquire_6.attacheName,
        dietitianCode: this.inquire_6.attacheCode,
        regionId: null,
        dietitianIdentity: null,
        serviceStatus: 1
      }
      console.log(data, 'data')
      this.loading_6 = true
      this.$http
        .requestPost({
          url: '/consult/queryCustomServiceList',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading_6 = false
          this.tableData_6 = res.data.list
          this.pageParam_6.total = res.data.total
          if (this.nutritionTable.length > 0) {
            const index = this.tableData_6.findIndex(item => item.attacheCode === this.nutritionTable[0].attacheCode)
            this.$refs.nutritionTable.setCurrentRow(this.tableData_4[index])
          }
        })
        .catch(err => {
          this.loading_6 = false
          console.log(err)
        })
    },
    // 确定医院的选择
    confirmAttache() {
      this.attacheTable = []
      this.attacheTable.push(this.selectRow)
      this.submitForm_2.attacheId = this.selectRow.attacheId
      this.dialogVisible_5 = false
    },
    confirmNutrition() {
      this.nutritionTable = []
      this.nutritionTable.push(this.selectRow6)
      this.submitForm_3.dietitianId = this.selectRow6.id
      this.dialogVisible_6 = false

      console.log('1111111111111==>', this.submitForm_3.dietitianId)
    },
    // 表格单选
    handleSelectChange(news, old) {
      this.selectRow = news
    },
    handleNutritionChange(news, old) {
      this.selectRow6 = news
    },

    // 提交设置专员
    submitEdit() {
      this.$refs.submitForm_2.validate(valid => {
        if (valid) {
          var memberIds = []
          this.selectList.forEach(obj => memberIds.push(obj.memberId))
          this.submitForm_2.memberIds = memberIds
          this.$http
            .requestPut({
              url: '/customer/member/setAttache',
              param: this.submitForm_2
            })
            .then(res => {
              this.$message.success('操作成功！')
              this.closeCPM()
              this.getTableList()
            })
        } else {
          return false
        }
      })
    },
    // 关闭设置专员弹窗
    closeCPM() {
      this.dialogVisible_2 = false
      this.submitForm_2.attacheId = ''
      this.attacheTable = []
      this.submitForm_3.dietitianId = ''
      this.nutritionTable = []
    },
    // 提交设置营养师
    submitNut() {
      this.$refs.submitForm_3.validate(valid => {
        if (valid) {
          const param = {
            dietitianDtoList: [],
            dietitianId: this.submitForm_3.dietitianId
          }
          this.selectList.forEach(value => {
            param.dietitianDtoList.push({ memberId: value.memberId })
          })
          this.$http
            .requestPut({
              url: '/customer/member/setDietitian',
              param
            })
            .then(res => {
              this.$message.success('操作成功！')
              this.closeCPM()
              this.getTableList()
            })
        } else {
          return false
        }
      })
    },

    // ==================== 会员消费列表 start ====================
    // 打开销售记录弹窗
    openSellDialog(row) {
      this.editRow = row
      this.getSellList()
      this.sellTitle = row.memberNickname
      this.dialogVisible_3 = true
    },

    // 获取销售列表
    getSellList() {
      this.loading_2 = true
      this.$http
        .requestGet({
          url: '/customer/member/getConsumeRecords?memberId=' + this.editRow.memberId + '&orderCode=' + this.inquire_2.orderCode,
          loading: false
        })
        .then(res => {
          this.loading_2 = false
          this.tableData_2 = res.data
        })
        .catch(err => {
          this.loading_2 = false
          console.log(err)
        })
    },

    // 订单详情
    handleParticulars(row) {
      const type = this.$api.EcommerceOrderState.findIndex(item => item.value === row.orderStatus)
      this.$router.push({
        path: '/order/commerce/particulars',
        query: {
          type: type,
          id: row.orderId
        }
      })
    },

    // ==================== 浏览记录 start ====================
    // 页容量改变
    handleSizeChange_3(size) {
      this.pageParam_3.pageNum = 1
      this.pageParam_3.pageSize = size
      // 刷新(数据)
      this.getSiteList()
    },
    // 页码改变
    handleCurrentChange_3(page) {
      this.pageParam_3.pageNum = page
      this.getSiteList()
    },
    // 打开浏览记录弹窗
    openSiteDialog(row) {
      this.inquire_3.memberId = row.memberId
      this.getSiteList()
      this.$nextTick(() => {
        this.siteTitle = row.memberNickname
        this.dialogVisible_4 = true
      })
    },

    // 获取用户浏览记录
    getSiteList() {
      console.log(this.daterange)
      if (!this.daterange) this.daterange = []
      this.inquire_3.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire_3.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : ''
      const data = {
        pageNum: this.pageParam_3.pageNum,
        pageSize: this.pageParam_3.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire_3))
      }

      this.loading_3 = true
      this.$http
        .requestPost({
          url: '/customer/member/browse/record/page',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading_3 = false
          this.tableData_3 = res.data.list
          this.pageParam_3.total = res.data.total
        })
        .catch(err => {
          this.loading_3 = false
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.Popout_content {
  .flex-box .demo-form-inline {
    flex: 1;
  }

  .total-money {
    min-width: 200px;
    font-size: 18px;
    line-height: 2;
    text-align: right;

    span {
      color: #fd6040;
    }
  }
}

.user-list {
  justify-content: flex-start;
  flex-wrap: wrap;

  & > div {
    align-items: center;
    padding-right: 10px;
    min-width: 125px;

    img {
      width: 48px;
      height: 48px;
      border-radius: 10%;
      margin-right: 5px;
    }
  }
}

.ht-info {
  .ht-info-title {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border-bottom: 1px solid #e8e6e6;
    margin-bottom: 10px;
  }

  .ht-info-img {
    margin-left: 30px;

    img {
      width: 220px;
      height: 150px;
      margin: 0 10px 10px 0;
    }
  }
}
</style>
