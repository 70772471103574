var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "circle" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "item" },
        [
          _c("el-progress", {
            staticClass: "progress",
            attrs: {
              type: "circle",
              width: 155,
              "stroke-width": 8,
              percentage: _vm.list.between1And2,
              color: "#264EFF",
              "stroke-linecap": "square",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "count" }, [
            _vm._v(_vm._s(_vm.list.countBetween1And2)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "txt" }, [_vm._v("购买1~2次")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item" },
        [
          _c("el-progress", {
            staticClass: "progress",
            attrs: {
              type: "circle",
              width: 155,
              "stroke-width": 8,
              percentage: _vm.list.between3And5,
              color: "#26B99B",
              "stroke-linecap": "square",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "count" }, [
            _vm._v(_vm._s(_vm.list.countBetween3And5)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "txt" }, [_vm._v("购买3~5次")]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "item" },
        [
          _c("el-progress", {
            staticClass: "progress",
            attrs: {
              type: "circle",
              width: 155,
              "stroke-width": 8,
              percentage: _vm.list.greaterThan5,
              color: "#FC7226",
              "stroke-linecap": "square",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "count" }, [
            _vm._v(_vm._s(_vm.list.countGreaterThan5)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "txt" }, [_vm._v("购买5次以上")]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("div", { staticClass: "txt_box" }, [
        _c("div", { staticClass: "text" }, [_vm._v("用户购买力分析")]),
        _vm._v(" "),
        _c("img", {
          staticClass: "icon",
          attrs: { src: require("@/assets/image/top.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "tip" }, [
          _c("img", {
            attrs: { src: require("@/assets/image/wenhao.png"), alt: "" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "poup" }, [
            _c("div", { staticClass: "txt" }, [
              _vm._v(
                "\n            分析所选时间段内用户购买次数的情况\n          "
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }