<template>
    <div class="login-container">
        <div class="bg01">
            <div class="content">
                <div>
                    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" auto-complete="on" class="login-form" label-position="left">
                        <div>
                            <img alt="" src="../../icons/logo.png" style="width: 40px; height: 40px"/>
                        </div>
                        <div class="title-container">
                            <span class="title">欢迎您登录638医品管理系统</span>
                        </div>

                        <el-form-item prop="managerPhoneNumber">
                            <img alt="" class="svg-container" src="../../icons/zhanghao@3x.png"/>
                            <el-input ref="managerPhoneNumber" v-model="loginForm.managerPhoneNumber" auto-complete="on" name="managerPhoneNumber" placeholder="请输入手机号" maxlength="11" tabindex="1" type="text"/>
                        </el-form-item>

                        <el-form-item prop="password">
                            <img alt="" class="svg-container" src="../../icons/mima@3x.png"/>
                            <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType" auto-complete="on" name="password" placeholder="请输入密码" tabindex="2" @keyup.enter.native="handleLogin"/>
                            <span class="show-pwd" @click="showPwd">
                                <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"/>
                            </span>
                        </el-form-item>

                        <el-button :loading="loading" style="width: 100%; margin-bottom: 30px" type="primary" @click.native.prevent="handleLogin">登录 </el-button>

                        <div class="tips">
                            <span>竭诚为患者服务，为医药、卫生事业服务</span>
                            <div class="lintliet"></div>
                            <div class="lintright"></div>
                        </div>
                    </el-form>
                </div>
            </div>
        </div>
        <div class="login-new">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" auto-complete="on" label-position="left">
        <div style="margin-left: 26%;width: 8rem;height:8rem">
          <img alt="" src="../../icons/logo3.png" style="width: 100%; height: 100%" />
        </div>
        <div style="margin-top: 2rem;margin-bottom: 2rem;display: flex;justify-content: center;">
          <span style="font-size: 1.2rem;color: #FFFFFF;">欢迎您登录638医品管理系统</span>
        </div>

        <el-form-item prop="managerPhoneNumber">
          <img style="width: 1.2rem;margin-left: 0.5rem;margin-bottom: -0.2rem;" alt="" src="../../icons/zhanghao@3x.png" />
          <el-input ref="managerPhoneNumber" v-model="loginForm.managerPhoneNumber" auto-complete="on" name="managerPhoneNumber" placeholder="请输入手机号" maxlength="11" tabindex="1" type="text" />
        </el-form-item>

        <el-form-item prop="password">
          <img style="width: 1.2rem;margin-left: 0.5rem;margin-bottom: -0.2rem;" alt="" src="../../icons/mima@3x.png" />
          <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType" auto-complete="on" name="password" placeholder="请输入密码" tabindex="2" />
          <!-- <span @click="showPwd">
            <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
          </span> -->
        </el-form-item>

        <el-button :loading="loading" style="width: 100%; margin-bottom: 0.6rem" round type="primary" @click.native.prevent="handleLogin">登录 </el-button>
        <div style="display: flex;justify-content: center;">
          <span style="font-size: 0.8rem;color: #FFFFFF;">竭诚为患者服务，为医药、卫生事业服务</span>
          <div></div>
          <div></div>
        </div>
      </el-form>
    </div>
    <div class="limit-bg" v-if="limit">
        <div class="tips-box">
            <div class="title">网站更新提示</div>
            <div class="tips-text">638管理中台网址已更新，请保存并使用下方链接进行登录！</div>
            <a :href="toUrl" class="url">{{ toUrl }}</a>
            <!-- <div class="copy" @click="copyToClip(toUrl)">复制链接</div> -->
            <div class="copy" @click="toClip(toUrl)">点击跳转</div>
        </div>
    </div>
    </div>
</template>

<script>
import {setSessionStorage} from '@/utils/auth.js'

export default {
    name: "Login",
    data() {
        return {
            loginForm: {
                managerPhoneNumber: "",
                password: "",
            },
            loginRules: {
                managerPhoneNumber: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                    {pattern:/^1[3456789]\d{9}$/, message: '手机格式错误', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: "密码不能少于6位", trigger: "blur"},
                    {min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur"}
                ],
            },
            loading: false,
            passwordType: "password",
            redirect: undefined,
            limit: true,
            toUrl: ""
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true,
        },
    },
    methods: {
        showPwd() {
            if (this.passwordType === "password") {
                this.passwordType = "";
            } else {
                this.passwordType = "password";
            }
            this.$nextTick(() => {
                this.$refs.password.focus();
            });
        },
        handleLogin() {
            if(!window.navigator.onLine){
                this.$message({message: '当前网络异常，请检查网络设置', type: 'warning'})
                return
            }
            let Base64 = require('js-base64').Base64;
            let password = Base64.encode(this.loginForm.password)
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$http.requestPost({
                        url: '/login',
                        param: {
                            managerPhoneNumber:this.loginForm.managerPhoneNumber,
                            password:password
                        },
                        loading: false
                    }).then(res => {
                        setSessionStorage('userToken', res.data.token)
                        setSessionStorage('userInfo', res.data)
                        this.getMenuTree(res.data)
                    }).catch(err => {
                        this.loading = false;
                    })

                } else {
                    console.log("error submit!!");
                    return false;
                }
            });

        },

        // 获取用户菜单
        getMenuTree(data) {
            this.$http.requestGet({ url:'/login/getMenuTree/' + data.managerId, loading:false }).then(res => {
                this.routes = res.data
                this.$store.commit('user/SET_MENU_LIST', res.data)
                this.loading = false;
                
                let url = '';
                if (this.routes.length < 1) return this.$message.warning('该用户没有权限，请先去设置权限!') 
                url = this.getUrl(this.routes[0].children, this.routes[0].path)
                this.$router.push(url);
            })
        },


        getUrl(arr, str) {
            if (arr.length == 0) return ''
            str += '/' + arr[0].path
            if (arr[0].children.length > 0) {
                return this.getUrl(arr[0].children, str)
            } else {
                return str
            }
        },
        // 复制到剪切板
        copyToClip(content, message) {
            var aux = document.createElement("input"); 
            aux.setAttribute("value", content); 
            document.body.appendChild(aux); 
            aux.select();
            document.execCommand("copy"); 
            document.body.removeChild(aux);
            if (message == null) {
                this.$message({
                    message: "已复制到粘贴板",
                    type: "success",
                });
            } else{
                this.$message({
                    message,
                    type: "success",
                });
            }
        },
        toClip(val){
            window.location.href = val;
        }
    },
    created(){
        let host = location.hostname.split('.');
        let env = process.env.NODE_ENV;
        console.log(host,'host')
        if( host[0] === 'localhost' || host[1] === $hostname ){
            // console.log('放行逻辑');
            this.limit = false;
        }else{
            // console.log('限制逻辑');
            if(env == 'development'){
                this.toUrl = $domainDev;
            }else if(env == 'staging'){
                this.toUrl = $domainTest;
            }else if(env == 'production'){
                this.toUrl = $domainProd;
            }
        }
        
    }
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

// $bg: #283443;
$light_gray: #000;
$cursor: #000;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input {
        color: $cursor;
    }
}

/* reset element-ui css */
.login-container {
    .el-input {
        display: inline-block;
        height: 47px;
        width: 85%;

        input {
            background: transparent !important;
            border: 0px;
            -webkit-appearance: none;
            border-radius: 0px;
            padding: 12px 5px 12px 15px;
            color: $light_gray;
            height: 47px;
            caret-color: $cursor;

            &:-webkit-autofill {
                // box-shadow: 0 0 0px 1000px $bg inset !important;
                -webkit-text-fill-color: $cursor !important;
            }
        }
    }

    .el-form-item {
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: #f7f7f7;
        border-radius: 5px;
        color: #000;
    }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #f5f5f5;
$light_gray: #000;
$blueness: #3c8fee;

@media  screen and (max-width: 900px){ 
    .bg01{
        display: none;
    }
}

.login-new{
    display: flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    background: url(../../icons/bg01.png) no-repeat;
    background-size: cover;
}

.login-container {
    z-index: 99;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    .bg01 {
        position: relative;
        width: 100%;
        /* 无限高 */
        min-height: 100vh;
        background: url(../../icons/bg01.png) no-repeat;
        background-size: cover;
        overflow: hidden;

        .content {
            position: relative;
            display: flex;
            width: 60%;
            min-width: 900px;
            height: 410px;
            margin: 212px auto 0;
            background: url(../../icons/bg02.png) no-repeat;
            background-size: cover;
        }

        .login-form {
            position: absolute;
            right: -5px;
            top: -120px;
            width: 380px;
            padding: 160px 35px 0;
            margin: 0 auto;
            overflow: hidden;
        }

        .tips {
            position: relative;
            font-size: 12px;
            color: $blueness;
            margin: -10px 40px 10px;

            .lintliet {
                position: absolute;
                top: 6px;
                left: -30px;
                width: 20px;
                height: 1px;
                background-color: $blueness;
            }

            .lintright {
                position: absolute;
                top: 6px;
                right: -20px;
                width: 20px;
                height: 1px;
                background-color: $blueness;
            }
        }

        .svg-container {
            margin: 0 0 5px 10px;
            color: $dark_gray;
            vertical-align: middle;
            width: 15px;
            height: 15px;
            display: inline-block;
        }

        .title-container {
            position: relative;
            margin: 10px auto 20px;

            .title {
                font-size: 12px;
                color: #333333;
                text-align: center;
            }
        }

        .show-pwd {
            position: absolute;
            right: 10px;
            top: 7px;
            font-size: 16px;
            color: $dark_gray;
            cursor: pointer;
            user-select: none;
        }
    }
}

.limit-bg {
    background: rgba(0,0,0,.5);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    .tips-box {
        background: #fff;
        width: 50%;
        height: 35%;
        margin: 200px auto;
        text-align: center;
        padding: 20px 0;
        padding-top: 50px;
        .title {
            color: #ec2222;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        .tips-text {
            margin: 20px 0;
        }
        .url {
            font-weight: 700;
            font-size: 20px;
        }
        .copy {
            border: 1px solid #666;
            width: 30%;
            height: 40px;
            line-height: 40px;
            margin: 30px auto;
            cursor: pointer;
        }
        .url:hover, .copy:hover {
            color: #3c8fee;
        }
    }
}
</style>
