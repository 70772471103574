<template>
  <div id="main-scroll" class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="nav-bar bg_white">
      <a
        v-for="(item, index) in navList"
        :key="item.id"
        :class="{ 'active': item.active }"
        href="javascript:void(0)"
        @click="skip(index)"
      >{{ item.name }}</a>
    </div>
    <!-- 基本信息 -->
    <div id="row_1" class="row_box bg_white">
      <div class="header">
        <span>基本信息</span>
      </div>
      <div class="content">
        <el-form
          ref="basicRef"
          :model="basicInfo"
          :rules="basicInfoRules"
          label-width="100px"
          size="medium"
          :disabled="check"
        >
          <div class="name">
            <el-form-item label="名称" prop="activityName">
              <el-input
                v-model="basicInfo.activityName"
                :disabled="edit"
                placeholder="请输入名称"
                maxlength="10"
                class="inputW"
                show-word-limit
                clearable
              />
            </el-form-item>
            <p class="text">不在用户端表达，仅用于管理员区分活动</p>
          </div>
          <el-form-item label="活动时间" prop="useDate">
            <div>
              <el-date-picker
                v-model="basicInfo.useDate"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                :disabled="edit"
                range-separator=" - "
                :picker-options="pickerOptions"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :clearable="false"
                :default-time="['00:00:00', '23:59:59']"
                @change="useDateChange"
              />
              <div class="textColor textColorRules">不得早于当前日期</div>
            </div>
          </el-form-item>
          <el-form-item label="满赠门槛" :required="true">
            <el-radio-group v-model="basicInfo.mzMK" :disabled="edit">
              <el-radio :label="1">金额</el-radio>
              <el-radio :label="2">数量</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 运营范围 -->
    <div id="row_2" class="row_box bg_white">
      <div class="header">
        <span>运营范围</span>
      </div>
      <div class="content">
        <el-form
          ref="getAddressForm"
          :model="getAddressForm.param"
          label-width="100px"
          size="medium"
        >
          <el-form-item>
            <el-radio-group v-model="getAddressForm.param.address" :disabled="check" @change="addressRadioChange">
              <el-radio :label="0">全国</el-radio>
              <el-radio :label="1">区域</el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="addressAttribute" class="form_box">
            <el-form-item
              v-if="edit && longHospitalList.length > 0"
              class="item_box"
              label="医院名称"
              prop="hospitalName"
            >
              <el-input
                v-model="getAddressForm.param.hospitalName"
                placeholder="请输入名称"
                class="inputW"
                show-word-limit
                clearable
              />
            </el-form-item>
            <el-form-item
              v-if="edit && longHospitalList.length > 0"
              class="item_box items_box"
              label="商家名称"
              prop="merchantName"
            >
              <el-input
                v-model="getAddressForm.param.merchantName"
                placeholder="请输入名称"
                class="inputW"
                show-word-limit
                clearable
              />
            </el-form-item>
            <el-form-item
              v-if="edit && longHospitalList.length > 0"
              class="item_box"
              label="所在区域"
              style="height: 23px;"
            >
              <el-cascader
                v-model="getAddressForm.param.addressIds"
                :props="props_List"
                :options="addressList_add"
                placeholder="请选择所在区域"
                class="form-select inputW"
                clearable
                collapse-tags
              />
            </el-form-item>
            <div class="item_box" :class="{edit_item:!edit}">
              <el-button v-if="edit" type="primary" size="small" @click="getHospital(this.getAddressForm)">查询
              </el-button>
              <el-button v-if="!check" type="primary" size="small" @click="delHospital([],'batch')">批量删除</el-button>
              <el-button v-if="!check" type="primary" size="small" @click="addFullHospitalList">添加</el-button>
            </div>
          </div>
          <el-main v-if="addressAttribute">
            <template>
              <el-table
                ref="hospitalList"
                v-loading="productLoading"
                :data="hospitalList"
                :row-key="(row) => row.hospitalId"
                border
                height="400px"
                style="width: 100%"
              >
                <el-table-column type="selection" align="center" :reserve-selection="true" />
                <el-table-column prop="hospitalCode" label="医院编号" min-width="180" />
                <el-table-column prop="hospitalName" label="医院名称" min-width="100" />
                <el-table-column prop="province" label="省份" min-width="100" />
                <el-table-column prop="city" label="城市" min-width="100" />
                <el-table-column prop="area" label="区/县" min-width="100" />
                <el-table-column prop="merchantName" label="商家名称" min-width="100" />
                <el-table-column v-if="!check" prop="" label="操作" width="150" fixed="right">
                  <template slot-scope="scope">
                    <span class="operation-btn" @click="delHospital([scope.row],'single')">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-main>
          <div v-if="addressAttribute" class="pagination">
            <el-pagination
              background
              :current-page="getAddressForm.pageNum"
              :page-sizes="$api.pageSizes"
              :page-size="getAddressForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="addressTotal"
              @size-change="getHospitalSizeChange"
              @current-change="getHospitalCurrentChange"
            />
          </div>
        </el-form>
      </div>
    </div>
    <!-- 主商品管理 -->
    <div id="row_3" class="row_box bg_white">
      <div class="header">
        <span>主商品管理</span>
      </div>
      <div class="content">
        <el-form ref="discountsRef" :model="getMainProductFrom" label-width="100px" size="medium">
          <el-form-item label="商品范围" :required="true">
            <el-radio-group :disabled="check || edit" :value="productClassify" @input="productCategory">
              <el-radio v-for="(item, ind) in $api.applicableProducts" :key="ind" :label="item.value">{{ item.name }}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-button style="margin-left:20px" type="primary" :disabled="check || edit " @click="selectMainProduct">选择商品
          </el-button>
          <el-main>
            <template>
              <el-table
                ref="mainProductList"
                v-loading="mainProductLoading"
                :data="mainProductList"
                :row-key="(row) => row.valueId"
                border
                height="400px"
                style="width: 100%"
              >
                <el-table-column label="序号" type="index" width="50" />
                <el-table-column prop="productCode" label="产品编号" min-width="120" />
                <el-table-column prop="productName" label="产品名称" min-width="100" />
                <el-table-column prop="valueName" label="产品规格" min-width="100" />
                <el-table-column prop="productCategoryName" label="产品类别" min-width="100" />
                <el-table-column prop="" label="产品单价" min-width="80">
                  <template slot-scope="scope">
                    ￥{{ scope.row.productPriceMin || 0.01 }} - ￥{{ scope.row.productPriceMax }}
                  </template>
                </el-table-column>
                <el-table-column prop="productStatus" label="状态" min-width="100">
                  <template slot-scope="scope">
                    {{ $api.getNeedValue(productState, scope.row.productStatus, 'valueCode', 'valueName') }}
                  </template>
                </el-table-column>
                <el-table-column v-if="!check && !edit" prop="" label="操作" width="150" fixed="right">
                  <template slot-scope="scope">
                    <span class="operation-btn" @click="delMainProduct([scope.row],'single')">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-main>
          <div class="pagination">
            <el-pagination
              background
              :current-page="getMainProductFrom.pageNum"
              :page-sizes="$api.pageSizes"
              :page-size="getMainProductFrom.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="mainProductTotal"
              @size-change="mainProductHandleSizeChange"
              @current-change="mainProductHandleCurrentChange"
            />
          </div>
        </el-form>
      </div>
    </div>
    <!-- 满赠设置 -->
    <div id="row_4" class="row_box bg_white">
      <div class="header">
        <span>满赠设置</span>
      </div>
      <div v-for="(item,index) in giftProductList" :key="index" class="content">
        <el-form
          ref="giftsRef"
          :model="giftFrom"
          :rules="giftFromRules"
          label-width="100px"
          size="medium"
          :disabled="edit"
        >
          <div class="pulltitle">
            <p>满赠门槛及内容——层级{{ index + 1 }}</p>
            <p v-if="!check && !edit" class="del_btn" @click="delGiftLevel(index)">删除</p>
          </div>
          <el-form-item label="满赠门槛">
            <el-input v-model="item.activityThreshold" :disabled="check" style="width:200px" show-word-limit clearable />
            <span>&nbsp;{{ basicInfo.mzMK == 1 ? '元' : '件' }}</span>
          </el-form-item>
          <el-form-item label="赠品内容" :required="true">
            <span v-if="!check && !edit" class="setup" @click="openGiftProductPopup(index)">设置赠品</span>
          </el-form-item>
          <el-main>
            <template>
              <el-table v-loading="productLoading" :data="item.products" border height="400px" style="width: 100%">
                <el-table-column prop="productCode" label="产品编号" min-width="180" />
                <el-table-column prop="productName" label="产品名称" min-width="100" />
                <el-table-column prop="productStatus" label="产品状态" min-width="100">
                  <template slot-scope="scope">
                    {{ $api.getNeedValue(productState, scope.row.productStatus, 'valueCode', 'valueName') }}
                  </template>
                </el-table-column>
                <el-table-column prop="productCategoryName" label="产品类别" min-width="100" />
                <el-table-column prop="" label="产品单价" min-width="80">
                  <template slot-scope="scope">
                    ￥{{ scope.row.productPriceMin || 0.01 }} - ￥{{ scope.row.productPriceMax }}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="数量" min-width="150">
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.giftsNumber"
                      type="number"
                      :min="0"
                      :disabled="check"
                      @input="giftMax(arguments[0],scope.row.productInventoryTotal,index,scope.row.productId)"
                    />
                  </template>
                </el-table-column>
                <el-table-column prop="productInventoryTotal" label="产品库存" min-width="100" />
                <el-table-column v-if="!check && !edit" prop="" label="操作" width="80" fixed="right">
                  <template slot-scope="scope">
                    <span class="operation-btn" @click="delGiftProduct([scope.row],index,scope.$index)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-main>
        </el-form>
      </div>
      <span v-if="!check && !edit" class="addHierachy" @click="addHierachy">添加层级</span>
    </div>
    <!-- 底部事件 -->
    <div class="submit-btn">
      <el-button type="primary" :disabled="check" @click="submit">确 定</el-button>
      <el-button :disabled="check" @click="$router.go(-1)">取 消</el-button>
    </div>

    <!-- 产品管理（未选择产品列表）弹窗列表 -->
    <el-dialog
      title="未选择产品列表"
      :visible.sync="mainProductVisible"
      :before-close="mainProductDialogClose"
      width="70%"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="搜索产品">
            <el-input v-model="mainProductPopupForm.param.productName" placeholder="请输入产品名称" />
          </el-form-item>
          <el-form-item label="产品类别">
            <el-cascader
              v-model="mainProductPopupForm.param.categoryId"
              :props="productProps"
              :options="productTypeData"
              placeholder="请选择产品类别"
              clearable
            />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="getUnassociatedMainProductList">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="mainPradoutTable"
          v-loading="mainProductPopupLoading"
          :data="unassociatedMainProductList"
          :row-key="(row) => row.valueId"
          tooltip-effect="dark"
          border
          height="400px"
          style="width: 100%"
          highlight-current-row
          @selection-change="mainProductPopupChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
            :selectable="(row) => row.isThreeFavorable ? false : true"
          />
          <el-table-column prop="productCode" label="产品编号" min-width="120" />
          <el-table-column prop="productName" label="产品名称" min-width="100" />
          <el-table-column prop="valueName" label="产品规格" min-width="100" />
          <el-table-column prop="productCategoryName" label="产品类别" min-width="100" />
          <el-table-column prop="" label="产品单价" min-width="80">
            <template slot-scope="scope">
              ￥{{ scope.row.productPriceMin || 0.01 }} - ￥{{ scope.row.productPriceMax }}
            </template>
          </el-table-column>
          <!--          <el-table-column prop="hospitalsOnSaleNum" label="在售医院数" min-width="80" />-->
          <el-table-column prop="productInventoryTotal" label="产品库存" min-width="80" />
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            :current-page="mainProductPopupForm.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="mainProductPopupForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="mainProductPopupForm.total"
            @size-change="mainProductPopupSizeChange"
            @current-change="mainProductPopupCurrentChange"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mainProductDialogClose">取 消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="mainProductDialogSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 赠品列表弹窗列表 -->
    <el-dialog
      title="未选择赠品列表"
      :visible.sync="giftProductPopup"
      :before-close="giftProductDialogClose"
      width="70%"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="搜索产品">
            <el-input v-model="giftProductPopupForm.param.productName" placeholder="请输入产品名称" />
          </el-form-item>
          <el-form-item label="产品类别">
            <el-cascader
              v-model="giftProductPopupForm.param.categoryId"
              :props="productProps"
              :options="productTypeData"
              placeholder="请输入产品名称"
              clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getUnassociatedGiftProductList">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="giftProductTable"
          v-loading="giftProductPopupLoading"
          :data="unassociatedGiftProductList"
          :row-key="(row) => row.productId"
          tooltip-effect="dark"
          border
          height="400px"
          style="width: 100%"
          :header-cell-class-name="'gift_header'"
          @selection-change="giftProductPopupChange"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
            :selectable="giftProductSelect"
          />
          <el-table-column prop="productCode" label="产品编号" min-width="120" />
          <el-table-column prop="productName" label="产品名称" min-width="100" />
          <el-table-column prop="productStatus" label="产品状态" min-width="100">
            <template slot-scope="scope">
              {{ $api.getNeedValue(productState, scope.row.productStatus, 'valueCode', 'valueName') }}
            </template>
          </el-table-column>
          <el-table-column prop="productCategoryName" label="产品类别" min-width="100" />
          <el-table-column prop="" label="产品单价" min-width="80">
            <template slot-scope="scope">
              ￥{{ scope.row.productPriceMin || 0.01 }} - ￥{{ scope.row.productPriceMax }}
            </template>
          </el-table-column>
          <el-table-column prop="productInventoryTotal" label="产品库存" min-width="80" />
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            :current-page="giftProductPopupForm.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="giftProductPopupForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="giftProductPopupForm.total"
            @size-change="giftProductPopupSizeChange"
            @current-change="giftProductPopupCurrentChange"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="giftProductDialogClose">取 消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="giftProductDialogSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加医院列表 -->
    <el-dialog title="新增运营范围" :visible.sync="hospitalPullVisible" :before-close="hospitalDialogClose" width="60%">
      <div>
        <el-form :inline="true" label-position="right">
          <el-form-item label="医院名称：">
            <el-input v-model="addAddressForm.param.hospitalName" style="width:260px;" placeholder="请输入医院名称" />
          </el-form-item>
          <el-form-item label="商家端名称：" style="margin-left:6%">
            <el-input v-model="addAddressForm.param.merchantName" style="width:260px;" placeholder="请输入商家端名称" />
          </el-form-item>
        </el-form>
        <el-form :inline="true" label-position="right">
          <el-form-item label="所在区域：">
            <el-cascader
              v-if="addressList_add.length"
              v-model="addAddressForm.param.addressIds"
              style="width:260px;"
              :props="props_List"
              :options="addressList_add"
              placeholder="请选项地址"
              collapse-tags
              clearable
            />
          </el-form-item>
          <el-form-item style="margin-left:6%">
            <el-button type="primary" @click="addFullHospitalList">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="hospitalPullTable"
          :data="unassociatedHospitalList"
          :row-key="(row) => row.hospitalId"
          border
          height="400px"
          style="width: 100%"
          @selection-change="hospitalPopupChange"
        >
          <el-table-column type="selection" align="center" :reserve-selection="true" />
          <el-table-column label="医院编号" prop="hospitalCode" align="center" show-overflow-tooltip />
          <el-table-column label="医院名称" prop="hospitalName" align="center" show-overflow-tooltip />
          <el-table-column label="省份" prop="province" align="center" show-overflow-tooltip />
          <el-table-column label="城市" prop="city" align="center" />
          <el-table-column label="区/县" prop="area" align="center" show-overflow-tooltip />
          <el-table-column label="商家名称" prop="merchantName" align="center" show-overflow-tooltip />
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="addAddressForm.pageNum"
            :page-size="addAddressForm.pageSize"
            :page-sizes="$api.pageSizes"
            :total="addAddressForm.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="addHospitalSizeChange"
            @current-change="addHospitalCurrentChange"
          />
        </div>
        <div style="padding-bottom: 20px;margin-top: 20px;text-align: right;">
          <el-button style="width:90px;" @click="hospitalDialogClose">取 消</el-button>
          <el-button type="primary" style="width:90px;" @click="hospitalDialogSubmit">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  data() {
    return {
      id: '', // 活动id
      navList: [ // 导航栏
        { name: '基本信息', id: 'row_1', active: true },
        { name: '运营范围', id: 'row_2', active: false },
        { name: '主商品管理', id: 'row_3', active: false },
        { name: '满赠设置', id: 'row_4', active: false }
      ],
      // --------------基本信息--------------
      pickerOptions: {
        disabledDate(time) {
          // 可选时间为当前时间至当前时间后180天 不得早于当前日期
          const days = 180
          const date = new Date()
          date.setTime(date.getTime() + days * 24 * 3600 * 1000)
          return time.getTime() > date.getTime() || time.getTime() < Date.now() - 8.64e7 // 8.64e7毫秒为一天
        }
      },
      // 基本信息校验
      basicInfoRules: {
        activityName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        useDate: [{
          type: 'array', // 表示字段值应为数组类型
          required: true, // 整个数组必须存在
          validator: (rule, value, callback) => { // 自定义验证函数
            if (!value || !value.length || value.every(item => item === '')) {
              callback(new Error('请选择使用时间'))
            } else {
              callback() // 如果通过校验，则调用callback无参表示校验通过
            }
          },
          trigger: 'change' // 触发校验的时机，这里选择在值变化时进行校验
        }]
      },
      basicInfo: {
        activityName: '', // 名称
        useDate: ['', ''], // 使用时间
        mzMK: 1 // 满赠门槛
      },

      check: false, // 仅限查看
      // -------------------运营范围----------------
      // 关联城市列表
      addressList_add: [],
      props_List: {
        label: 'addressName',
        value: 'regionId',
        children: 'child',
        emitPath: false,
        multiple: true
      },
      discountsInfo: {
        address: [],
        hospitalName: '', // 医院名称
        merchantName: '', // 商家名称
        regionId: '' // 所在区域
      },
      edit: false,
      productLoading: false,
      productDataSource: [],
      productPageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      multipleSelection: [],

      addressAttribute: false, // 是否显示运营范围详细
      hospitalPullVisible: false, // 新增运营范围弹窗
      unassociatedHospitalList: [], // 未绑定区域数据
      addAddressSelectedForm: [], // 已关联选中数据
      addAddressForm: { // 未关联区域搜索条件
        pageNum: 1,
        pageSize: 10,
        total: 0,
        param: {
          queryType: 0, // 是否绑定 0：否，1：是
          typeId: JSON.parse(sessionStorage.getItem('cityId')) || '', // 主键Id
          type: '5', // 类型：1:产品 2：banner 3:资讯 4:icon 5:活动
          addressIds: [], // 行政划码
          hospitalName: '', // 医院名称
          merchantName: '' // 商家名称
        }
      },
      longHospitalList: [], // 绑定区域数据
      getAddressForm: { // 已关联区域搜索条件
        pageNum: 1,
        pageSize: 10,
        param: {
          address: 0,
          queryType: 1, // 是否绑定 0：否，1：是
          typeId: '', // 主键Id
          type: '5', // 类型：1:产品 2：banner 3:资讯 4:icon 5:活动
          addressIds: [], // 行政划码
          hospitalName: '', // 医院名称
          merchantName: '' // 商家名称
        }
      },
      temporaryHospitalData: [], // 选中关联区域数据
      // -------------------满赠设置----------------
      giftFromRules: {
        activityThreshold: [{ required: true, message: '请输入数值', trigger: 'blur' }]
      },
      giftFrom: {
        cash: '',
        cash1: ''
      },
      loading3: false,
      pageParam3: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      pullInquire: { // 赠品列表
        productName: '',
        productType: ''
      },
      pullVisible: false,
      dialogVisible: false, // 确认弹窗

      productState: [], // 产品状态
      editGiftProductIndex: 0, // 赠品层级索引

      // -------------------主商品管理----------------
      mainProductVisible: false, // 添加主商品弹窗
      getMainProductFrom: { // 已关联主产品搜索条件
        pageNum: 1,
        pageSize: 10,
        total: 0,
        param: {
          id: ''
        }
      },
      mainProductPagination: { // 前端分页
        pageNum: 1,
        pageSize: 10
      },
      selectQuoteProducts: [], // 已选中的限购关联产品
      // 选择产品弹窗
      visible: false,
      loading2: false,
      pageParam2: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      dataSource2: [],
      productTypeData: [],
      productClassify: 'medicine', // 选择产品范围
      mainProductPopupForm: { // 未关联主产品列表筛选参数
        pageNum: 1,
        pageSize: 10,
        total: 0,
        param: {
          id: '',
          productClassify: '',
          productName: '',
          categoryId: '',
          productIds: []
        }
      },
      productProps: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: false
      },
      btnLoading: false,
      longMainProductList: [], // 主商品已关联数据
      mainProductLoading: false, // 主产品列表加载
      mainProductPopupLoading: false, // 主产品弹窗加载
      unassociatedMainProductList: [], // 未关联主产品
      temporaryMainProductData: [], // 新增关联主产品
      addressDataType: false,
      mainProductDataType: false,

      giftProductList: [{
        'id': '',
        'activityId': '',
        'activityType': '',
        'activityThreshold': '',
        'hierarchyOrder': '',
        'products': []
      }], // 赠品回显数据
      giftProductPopup: false,
      giftProductPopupForm: { // 未关联主产品列表筛选参数
        pageNum: 1,
        pageSize: 10,
        total: 0,
        param: {
          productClassify: '',
          productName: '',
          categoryId: '',
          productIds: []
        }
      },
      longGiftProductList: [], // 赠品产品已关联数据
      giftProductLoading: false, // 赠品产品列表加载
      giftProductPopupLoading: false, // 赠品产品弹窗加载
      unassociatedGiftProductList: [], // 未关联赠品产品
      temporaryGiftProductData: [], // 新增关联赠品产品
      giftProductDataType: false,
      giftProductPopupData: []
    }
  },
  computed: {
    hospitalList() { // 运营范围
      let data = [...this.longHospitalList, ...this.temporaryHospitalData]
      this.pageNumCount(this.getAddressForm.pageNum, this.getAddressForm.pageSize, this.addressTotal, 'getAddressForm.pageNum')
      const param = [
        { hospitalName: this.getAddressForm.param.hospitalName },
        { addressIds: this.getAddressForm.param.addressIds },
        { merchantName: this.getAddressForm.param.merchantName }]
      data = this.search(data, param)
      return data.slice((this.getAddressForm.pageNum - 1) * this.getAddressForm.pageSize, (this.getAddressForm.pageNum * this.getAddressForm.pageSize))
    },
    addressTotal() { // 运营范围总数
      const data = [...this.longHospitalList, ...this.temporaryHospitalData]
      return data.length
    },
    mainProductList() { // 主产品列表
      const data = [...this.longMainProductList, ...this.temporaryMainProductData]
      this.pageNumCount(this.getMainProductFrom.pageNum, this.getMainProductFrom.pageSize, this.mainProductTotal, 'getMainProductFrom.pageNum')
      return data.slice((this.getMainProductFrom.pageNum - 1) * this.getMainProductFrom.pageSize, (this.getMainProductFrom.pageNum * this.getMainProductFrom.pageSize))
    },
    mainProductTotal() { // 主产品总数
      const data = [...this.longMainProductList, ...this.temporaryMainProductData]
      return data.length
    }
  },
  created() {
    // 是否为编辑
    this.edit = this.$route.query.edit
    console.log(!!this.$route.query.check, '!!this.$route.query.check')
    this.check = !!this.$route.query.check
    this.id = this.$route.query.id ? this.$route.query.id : ''
    // 获取产品状态列表
    this.getProductState()
    if (this.id) {
      this.getAddressForm.param.id = this.id
      this.getMainProductFrom.param.id = this.id
      this.getActivityDetails()
    }
  },
  mounted() {
    this.mainEl = document.getElementsByClassName('app-main')[0]
    this.mainEl.addEventListener('scroll', this.scrollChange)
    this.skip(0)
    this.getAddress()
    this.getproductCategory()
  },
  beforeDestroy() {
    this.mainEl.removeEventListener('scroll', this.scrollChange)
  },
  methods: {
    // 多条件搜索
    search(data, condition) {
      return data
    },
    // 计算当前分页是否符合并切换
    pageNumCount(num, size, total, name) {
      const names = name.split('.')
      const pageTotal = num * size - total
      let pageNum = 0
      if (pageTotal > 0) {
        pageNum = Math.ceil(total / size)
      }
      this[names[0]][names[1]] = pageNum || this[names[0]][names[1]]
    },

    // 获取产品状态
    getProductState() {
      this.$api.dictionaries('product_status').then(res => {
        this.productState = res.data
      })
    },
    // 获取活动详情
    getActivityDetails() {
      this.$http
        .requestGet({ url: '/giftActivity/getActivityById/' + this.id })
        .then(res => {
          console.log(res, '详情')
          if (res.data.activityStatus === 1) {
            this.edit = false
          }
          this.basicInfo.activityName = res.data.activityName
          this.basicInfo.mzMK = res.data.activityType
          this.basicInfo.useDate = [res.data.beginTime, res.data.endTime]
          this.productClassify = res.data.productClassify
          this.longGiftProductList = [...res.data.giftActivityHierarchyRespDto]
          this.getAddressForm.param.typeId = this.id
          this.getMainProductFrom.param.id = this.id
          this.getMainProductList()
          this.giftProductProcessingData()
          this.handleIsCount()
          this.getHospital(this.getAddressForm)
        })
    },

    // ****************基本信息*******************************
    // 使用时间选择事件 -- 清空透出时间
    useDateChange(val) {
      if (!(val && val.length > 0)) {
        this.basicInfo.tcDate = ''
      }
      // 使用时间再次选择时如何小于透出时间则清空透出时间
      const d1 = new Date(val[0])
      const d2 = new Date(this.basicInfo.tcDate)
      if (d1 < d2) {
        this.basicInfo.tcDate = ''
      }
    },
    // 获取产品分类
    getproductCategory() {
      // 一并获取产品分类列表
      this.$http
        .requestGet({ url: '/product/category', loading: false })
        .then((res) => {
          if (res.code === 200) {
            this.productTypeData = res.data
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // ****************运营范围********************************
    // 所在区域选择
    getAddress() {
      this.$http
        .requestGet({
          url: '/common/address/getDietitianAllWithTree',
          loading: false
        })
        .then((res) => {
          console.log(res)
          this.addressList_add = res.data
        })
    },
    // 查询
    searchList() {

    },
    // 新增运营范围
    addFullHospitalList() {
      this.hospitalPullVisible = true
      this.getHospital(this.addAddressForm)
    },
    // ****************满赠设置**********************
    // 添加层级
    addHierachy() {
      if (this.giftProductList.length === 5) {
        return this.$message({
          type: 'warning',
          message: '最多设置5个优惠层级'
        })
      }
      this.giftProductList.push({
        'id': '',
        'activityId': '',
        'activityType': '',
        'activityThreshold': '',
        'hierarchyOrder': '',
        'products': []
      })
    },
    // 提交参加活动
    submit() {
      this.$refs['basicRef'].validate((valid) => {
        if (!valid) {
          throw '表单错误'
        }
      })

      // 删除无用字段
      const delData = (val, name) => {
        const list = {}
        for (const key of name) {
          list[key] = val[key]
        }
        return list
      }
      // 主产品数据处理
      const mainProductIdList = this.longMainProductList.map(item => item.valueId)
      mainProductIdList.push(...this.temporaryMainProductData.map(item => item.valueId))

      const param = {
        id: this.id,
        beginTime: this.basicInfo.useDate[0],
        endTime: this.basicInfo.useDate[1],
        activityName: this.basicInfo.activityName,
        activityType: this.basicInfo.mzMK,
        productClassify: this.productClassify,
        productNorms: mainProductIdList,
        systemDistrictDto: {
          typeId: this.id || '',
          type: 5,
          displayType: this.getAddressForm.param.address,
          districtList: [...this.longHospitalList, ...this.temporaryHospitalData]
        },
        giftActivityHierarchyReqDtoList: [...this.giftProductList]
      }

      // 赠品数据处理
      param.giftActivityHierarchyReqDtoList.forEach((item, index) => {
        item.activityType = this.basicInfo.mzMK
        item.hierarchyOrder = index
        if (item.products.length > 0) {
          item.products.forEach((items, indexs) => {
            item.products[indexs].id = items.productId
            item.products[indexs] = delData(items, ['id', 'giftsNumber'])
          })
        }
      })
      // 区域数据处理
      param.systemDistrictDto.districtList.forEach((item, index) => {
        param.systemDistrictDto.districtList[index] = delData(item, ['hospitalId'])
      })

      if (param.productNorms.length <= 0) {
        this.$message({
          type: 'error',
          message: '请选择参与活动的主商品'
        })
        return false
      }

      for (let i = 0; i < param.giftActivityHierarchyReqDtoList.length; i++) {
        if (!param.giftActivityHierarchyReqDtoList[i].activityThreshold) {
          this.$message({
            type: 'error',
            message: '请填写满赠门槛'
          })
          return false
        }
        if (param.giftActivityHierarchyReqDtoList[i].products.length <= 0) {
          this.$message({
            type: 'error',
            message: '请选择参与活动的赠品'
          })
          return false
        }
      }

      console.log(param, 'param')
      // return false
      this.$http
        .requestPost({
          url: '/giftActivity/addEditActivity',
          param,
          loading: false
        })
        .then((res) => {
          console.log(res, '返回值')
          if (res.code !== 200) {
            return false
          }
          this.$message({
            type: 'success',
            message: '添加成功!'
          })
          this.$router.push({ path: '/operation/marketingTool/fullGiftPromotion' })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // navbar跳转
    skip(ind) {
      this.navbarChange(ind)
      const id = this.navList[ind].id
      document.querySelector('#' + id).scrollIntoView(true)
    },
    // nav改变
    navbarChange(ind) {
      const i1 = this.navList.findIndex(item => item.active)
      this.navList[i1].active = false
      this.navList[ind].active = true
    },
    // 函数防抖
    debounce(fn, interval = 200) {
      if (this.timer) clearTimeout(this.timer)
      const args = arguments
      this.timer = setTimeout(() => {
        fn.apply(this, args)
      }, interval)
    },
    // 滚动条改变
    scrollChange() {
      const scrollTop = this.mainEl.scrollTop
      const height = this.mainEl.offsetHeight
      this.debounce(function() {
        const rowHeight = document.getElementById('row_2').offsetHeight
        const scroll = scrollTop + height
        if (scroll <= 600) {
          this.navbarChange(0)
        } else if (scroll > 600 && scroll <= (900 + rowHeight)) {
          this.navbarChange(1)
        } else if (scroll > (900 + rowHeight) && scroll <= (600 + rowHeight + 900)) {
          this.navbarChange(2)
        } else {
          this.navbarChange(3)
        }
      }, 100)
    },

    // 商品类别
    productCategory(e) {
      this.$confirm('切换后，主商品设置和赠品设置将被清空，是否确认切换？', '确认切换范围', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.productClassify = e
        this.longMainProductList = []
        this.temporaryMainProductData = []
        this.delGiftLevelData('all')
      }).catch(err => {
        console.log('取消切换', err)
      })
    },

    // 获取区域/全国
    handleIsCount() {
      const param = {
        typeId: this.id,
        type: '5'
      }
      this.$http
        .requestPost({
          url: '/systemDistrict/isCountry',
          param,
          loading: false
        })
        .then((res) => {
          if (res.data) {
            this.getAddressForm.param.address = 0
            this.addressAttribute = false
          } else {
            this.getAddressForm.param.address = 1
            this.addressAttribute = true
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // 切换地区/全国
    addressRadioChange(e) {
      console.log(e == 1, 'e')
      this.addressAttribute = e == 1
    },

    // 获取未关联/关联医院
    getHospital(data) {
      const param = { ...data }
      this.$http
        .requestPost({
          url: '/systemDistrict/hospital/district/list',
          param,
          loading: false
        })
        .then((res) => {
          if (res.code !== 200) {
            return false
          }
          if (param.param.queryType === 0) {
            this.unassociatedHospitalList = res.data.list
            this.addAddressForm.total = res.data.total
            if (!this.addressDataType || this.temporaryHospitalData.length > 0) {
              this.$refs.hospitalPullTable.clearSelection()
              this.temporaryHospitalData.forEach(item => {
                this.$refs.hospitalPullTable.toggleRowSelection(item, true)
              })
            }
          } else {
            this.longHospitalList = res.data.list
            this.getAddressForm.total = res.data.total
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    hospitalPopupChange(e) {
      if (e.length > 0) {
        this.addressDataType = true
      }
    },
    // 关闭新增区域弹窗
    hospitalDialogClose() {
      this.hospitalPullVisible = false
      this.addressDataType = false
      this.$refs.hospitalList.doLayout()
      this.addAddressForm.pageNum = 1
      this.addAddressForm.pageSize = 10
    },
    // 新增区域弹窗提交
    hospitalDialogSubmit() {
      // 拷贝
      const dataList = this.$refs.hospitalPullTable.selection.concat()
      this.temporaryHospitalData = dataList
      this.hospitalDialogClose()
    },
    // 新增区域弹窗分页切换
    addHospitalSizeChange(size) {
      this.addAddressForm.pageNum = 1
      this.addAddressForm.pageSize = size
      this.getHospital(this.addAddressForm)
    },
    // 新增区域弹窗分页切换
    addHospitalCurrentChange(page) {
      this.addAddressForm.pageNum = page
      this.getHospital(this.addAddressForm)
    },
    // 绑定区域分页切换
    getHospitalSizeChange(size) {
      this.getAddressForm.pageNum = 1
      this.getAddressForm.pageSize = size
    },
    // 绑定区域分页数量
    getHospitalCurrentChange(page) {
      this.getAddressForm.pageNum = page
    },
    // 删除绑定医院
    delHospital(data, type) {
      const dataList = type === 'single' ? data : this.$refs.hospitalList.selection.concat()
      if (dataList.length <= 0) {
        return false
      }
      dataList.forEach(item => {
        for (let i = this.temporaryHospitalData.length - 1; i >= 0; i--) {
          if (this.temporaryHospitalData[i].hospitalId === item.hospitalId) {
            this.temporaryHospitalData.splice(i, 1)
            this.$refs.hospitalList.toggleRowSelection(item, false)
            this.$refs.hospitalPullTable.toggleRowSelection(item, false)
          }
        }
        for (let i = this.longHospitalList.length - 1; i >= 0; i--) {
          if (this.longHospitalList[i].hospitalId === item.hospitalId) {
            this.longHospitalList.splice(i, 1)
            this.$refs.hospitalList.toggleRowSelection(item, false)
            this.$refs.hospitalPullTable.toggleRowSelection(item, false)
          }
        }
      })
    },
    // 选择商品
    selectMainProduct() {
      this.mainProductVisible = true
      this.getUnassociatedMainProductList()
    },
    // 存储是否有临时数据
    mainProductPopupChange(e) {
      if (e.length > 0) {
        this.mainProductDataType = true
      }
    },
    // 获取关联主产品
    async getMainProductList() {
      const param = { ...this.getMainProductFrom }
      param.pageNum = 1
      param.pageSize = 10000
      await this.$http.requestPost({
        url: '/giftActivity/getActivityProduct',
        loading: false,
        param
      }).then((res) => {
        if (res.code === 200) {
          this.longMainProductList = res.data.list
          this.mainProductPopupForm.param.productIds = res.data.list.map(item => item.valueId)
        }
      }).catch((err) => {
        console.log(err)
      })
      // console.log(this.dataCheck, 'this.dataCheck')
    },
    // 获取未关联主产品
    getUnassociatedMainProductList() {
      this.mainProductPopupLoading = true
      const param = { ...this.mainProductPopupForm }
      param.param.productClassify = this.productClassify
      this.$http
        .requestPost({
          url: '/giftActivity/getActivityUnassociatedProduct',
          param,
          loading: false
        })
        .then((res) => {
          if (res.code !== 200) {
            return false
          }
          this.unassociatedMainProductList = res.data.list
          this.mainProductPopupForm.total = res.data.total
          if (!this.mainProductDataType && this.temporaryMainProductData.length > 0) {
            this.$refs.mainPradoutTable.clearSelection()
            this.temporaryMainProductData.forEach(item => {
              this.$refs.mainPradoutTable.toggleRowSelection(item, true)
            })
          }
          this.mainProductPopupLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.mainProductPopupLoading = false
        })
    },
    // 删除绑定主产品
    delMainProduct(data, type) {
      const dataList = type === 'single' ? data : this.$refs.mainProductList.selection.concat()
      if (dataList.length <= 0) {
        return false
      }
      dataList.forEach(item => {
        const id = item.id ? item.id : 0
        if (this.edit) {
          this.$http
            .requestGet({ url: '/giftActivity/deleteLinkProductByLinkId/' + id })
            .then(res => {
              if (res.code === 200) {
                this.pageNumCount(this.getMainProductFrom.pageNum, this.getMainProductFrom.pageSize, this.mainProductTotal, 'getMainProductFrom.pageNum')
                this.getMainProductList()
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              }
            })
        }
        for (let i = this.temporaryMainProductData.length - 1; i >= 0; i--) {
          if (this.temporaryMainProductData[i].valueId === item.valueId) {
            this.temporaryMainProductData.splice(i, 1)
            this.$refs.mainProductList.toggleRowSelection(item, false)
          }
        }
      })
    },
    // 新增主商品弹窗提交
    mainProductDialogSubmit() {
      // 拷贝
      const dataList = this.$refs.mainPradoutTable.selection.concat()
      this.temporaryMainProductData = dataList
      this.mainProductDialogClose()
    },
    // 关闭新增主产品弹窗
    mainProductDialogClose() {
      this.mainProductVisible = false
      this.mainProductDataType = false
      this.mainProductPopupForm.pageNum = 1
      this.mainProductPopupForm.pageSize = 10
    },
    // 绑定主产品单页数量切换
    mainProductPopupSizeChange(size) {
      this.mainProductPopupForm.pageNum = 1
      this.mainProductPopupForm.pageSize = size
      this.getUnassociatedMainProductList()
    },
    // 绑定主产品分页切换
    mainProductPopupCurrentChange(page) {
      this.mainProductPopupForm.pageNum = page
      this.getUnassociatedMainProductList()
    },
    // 绑定主产品单页数量切换
    mainProductHandleSizeChange(size) {
      this.getMainProductFrom.pageNum = 1
      this.getMainProductFrom.pageSize = size
    },
    // 绑定主产品分页切换
    mainProductHandleCurrentChange(page) {
      this.getMainProductFrom.pageNum = page
    },

    // 获取未关联赠送产品
    getUnassociatedGiftProductList(index) {
      this.giftProductPopupLoading = true
      const param = { ...this.giftProductPopupForm }
      param.param.productClassify = this.productClassify
      if (this.longGiftProductList[index]) {
        if (this.longGiftProductList[index].products.length > 0) {
          param.param.productIds = this.longGiftProductList[index].products.map(item => item.productId)
        }
      }
      this.$http
        .requestPost({
          url: '/giftActivity/getActivityUnassociatedGifts',
          param,
          loading: false
        })
        .then((res) => {
          if (res.code !== 200) {
            return false
          }
          this.unassociatedGiftProductList = res.data.list
          this.giftProductPopupForm.total = res.data.total
          if (!this.giftProductDataType && this.temporaryGiftProductData[index]) {
            this.temporaryGiftProductData[index].forEach(item => {
              this.$refs.giftProductTable.toggleRowSelection(item, true)
            })
          }
          this.giftProductPopupLoading = false
        })
        .catch((err) => {
          console.log(err)
          this.giftProductPopupLoading = false
        })
    },
    // 删除绑定赠送产品
    delGiftProduct(data, index, scopeIndex) {
      const dataList = data
      if (dataList.length <= 0) {
        return false
      }
      dataList.forEach(item => {
        // console.log(this.giftProductList[index].products)
        // return false
        if (this.edit) {
          const id = item.id ? item.id : 0
          this.$http
            .requestGet({ url: '/giftActivity/deleteLinkProductByLinkId/' + id })
            .then(res => {
              if (res.code === 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
              }
            })
        }
        // this.giftProductList[index].products.splice(scopeIndex, 1)
        if (this.giftProductList[index]) {
          for (let i = this.giftProductList[index].products.length - 1; i >= 0; i--) {
            if (this.giftProductList[index].products[i].productId === item.productId) {
              this.giftProductList[index].products.splice(i, 1)
            }
          }
        }
        if (this.temporaryGiftProductData[index]) {
          for (let i = this.temporaryGiftProductData[index].length - 1; i >= 0; i--) {
            if (this.temporaryGiftProductData[index][i].productId === item.productId) {
              this.temporaryGiftProductData[index].splice(i, 1)
            }
          }
        }
        if (this.longGiftProductList[index]) {
          for (let i = this.longGiftProductList[index].products.length - 1; i >= 0; i--) {
            if (this.longGiftProductList[index].products[i].productId === item.productId) {
              this.longGiftProductList[index].products.splice(i, 1)
            }
          }
        }

        this.giftProductProcessingData()
      })
    },
    // 新增赠送产品弹窗提交
    giftProductDialogSubmit() {
      // 拷贝
      const dataList = this.$refs.giftProductTable.selection.concat()
      dataList.forEach(item => {
        // 保持数据的数量默认值
        if (this.giftProductList[this.editGiftProductIndex].products) {
          this.giftProductList[this.editGiftProductIndex].products.forEach(item2 => {
            if (item.productId === item2.productId) {
              item.giftsNumber = item2.giftsNumber ? item2.giftsNumber : 0
            }
          })
        } else {
          this.giftProductList[this.editGiftProductIndex].products = [...dataList]
        }
      })
      console.log(this.giftProductList,'this.giftProductList')
      this.temporaryGiftProductData[this.editGiftProductIndex] = dataList
      this.giftProductProcessingData()
      this.giftProductDialogClose()
    },
    // 关闭新增赠送产品弹窗
    giftProductDialogClose() {
      this.giftProductPopup = false
      this.giftProductDataType = false
      this.giftProductPopupForm.pageNum = 1
      this.giftProductPopupForm.pageSize = 10
      this.$refs.giftProductTable.clearSelection()
      this.giftProductPopupData = []
    },
    // 绑定赠送产品单页数量切换
    giftProductPopupSizeChange(size) {
      this.giftProductPopupForm.pageNum = 1
      this.giftProductPopupForm.pageSize = size
      this.getUnassociatedGiftProductList(this.editGiftProductIndex)
    },
    // 绑定赠送产品分页切换
    giftProductPopupCurrentChange(page) {
      this.giftProductPopupForm.pageNum = page
      this.getUnassociatedGiftProductList(this.editGiftProductIndex)
    },
    // 打开赠品弹窗
    openGiftProductPopup(index) {
      this.giftProductPopup = true
      this.editGiftProductIndex = index
      this.getUnassociatedGiftProductList(this.editGiftProductIndex)
    },
    // 处理赠品数据回显
    giftProductProcessingData() {
      console.log('处理数据')
      let data = []
      let length = []
      const temporaryGiftProductData = JSON.parse(JSON.stringify(this.temporaryGiftProductData))
      console.log(temporaryGiftProductData,'temporaryGiftProductData')
      let longGiftProductList = []
      const giftProductList = this.giftProductList.concat() // 深拷贝
      try {
        if (temporaryGiftProductData) {
          length.push(Object.keys(temporaryGiftProductData).length)
        }
        if (this.longGiftProductList) {
          longGiftProductList = this.longGiftProductList.concat()
          length.push(longGiftProductList.length)
        }
        length.push(giftProductList.length)
        length = Math.max(...length)
        console.log(this.temporaryGiftProductData, this.giftProductList, this.longGiftProductList, 'length')
        for (let i = 0; i < length; i++) {
          data[i] = {
            'id': '',
            'activityId': '',
            'activityType': '',
            'activityThreshold': '',
            'hierarchyOrder': '',
            'products': []
          }
          if (temporaryGiftProductData[i]) {
            const { products, ...item } = { ...JSON.parse(JSON.stringify(giftProductList[i])) }
            data[i] = { ...item }
            if (temporaryGiftProductData[i].length > 0) {
              data[i].products = JSON.parse(JSON.stringify(temporaryGiftProductData[i]))
            }
          }
          if (longGiftProductList[i]) {
            const { products, ...item } = JSON.parse(JSON.stringify(longGiftProductList[i]))
            data[i] = { products: data[i].products ? data[i].products : [], ...item }
            if (products.length > 0) {
              data[i].products.push(...products)
            }
          }
        }

        data = data.concat()
        console.log(length, data)
        this.giftProductList = data
      } catch (err) {
        console.log(err)
      }
    },
    // 临时存储赠品勾选数据
    giftProductPopupChange(data) {
      this.giftProductPopupData = data
      if (data.length > 0) {
        this.giftProductDataType = true
      }
    },
    // 判断赠品已选数量超过5
    giftProductSelect(row) {
      const index = this.editGiftProductIndex
      let idList = []
      let callBack = true

      if (this.longGiftProductList[index]) {
        if (this.longGiftProductList[index].products) {
          idList.push(...this.longGiftProductList[index].products.map(item => {
            return item.productId
          }))
        }
      }
      if (this.temporaryGiftProductData[index]) {
        idList.push(...this.temporaryGiftProductData[index].map(item => {
          return item.productId
        }))
      }
      if (this.giftProductPopupData) {
        idList.push(...this.giftProductPopupData.map(item => {
          return item.productId
        }))
      }
      // console.log(idList, 'this.longGiftProductList[index]')
      idList = Array.from(new Set(idList))
      // console.log(idList, 'idList')
      if (idList.length >= 5) {
        callBack = !!idList.find(item => item === row.productId)
      }
      return callBack
    },
    // 限制赠品数量最大值
    giftMax(val, maxNum, index, id) {
      const number = Number(val)
      maxNum = Number(maxNum)
      const key = this.giftProductList[index].products.map((item) => item.productId).indexOf(id)
      if (number <= 0) {
        if (key !== -1) {
          this.giftProductList[index].products[key].giftsNumber = 0
          this.temporaryGiftProductData[index][key].giftsNumber = 0
        }
      } else if (number >= maxNum) {
        if (key !== -1) {
          this.giftProductList[index].products[key].giftsNumber = maxNum
          this.temporaryGiftProductData[index][key].giftsNumber = maxNum
        }
      } else {
        if (key !== -1) {
          this.giftProductList[index].products[key].giftsNumber = number
          this.temporaryGiftProductData[index][key].giftsNumber = number
        }
      }
    },
    // 删除赠品层级
    delGiftLevel(index) {
      this.$confirm(`是否确认赠品层级${index + 1}删除?`, '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.delGiftLevelData(index)
      })
    },
    // 删除赠品层级数据
    delGiftLevelData(index) {
      if (index === 'all') {
        if (this.longGiftProductList) {
          if (this.longGiftProductList.length > 0) {
            this.longGiftProductList = []
            this.$set(this, 'longGiftProductList', [])
          }
        }
        if (this.temporaryGiftProductData) {
          if (this.temporaryGiftProductData.length > 0) {
            this.temporaryGiftProductData = {}
            this.$set(this, 'temporaryGiftProductData', {})
            console.log(this.temporaryGiftProductData, 'this.temporaryGiftProductData')
          }
        }
        if (this.giftProductList) {
          if (this.giftProductList.length > 0) {
            const test = [{
              'id': '',
              'activityId': '',
              'activityType': '',
              'activityThreshold': '',
              'hierarchyOrder': '',
              'products': []
            }]
            this.giftProductList = test
            this.$set(this, 'giftProductList', test)
          }
        }
      } else {
        if (this.longGiftProductList[index]) {
          this.longGiftProductList.splice(index, 1)
        }
        if (this.temporaryGiftProductData[index]) {
          this.temporaryGiftProductData.splice(index, 1)
        }
        if (this.giftProductList[index]) {
          this.giftProductList.splice(index, 1)
        }
      }

      this.giftProductProcessingData()
    }
  }
}
</script>

<style lang="scss" scoped>
.inputW {
  width: 400px;
}

.main-box {
  background-color: #f5f5f5;
  position: static;
}

.nav-bar {
  padding: 12px 20px;
  margin-bottom: 10px;
  position: absolute;
  top: 68px;
  left: 10px;
  z-index: 999;
  width: calc(100% - 23px);
  box-shadow: 0px 2px 1px 0px #f5f5f5;

  & > a {
    margin-right: 30px;
    font-size: 14px;
    padding: 10px 0;
    border-top: 3px solid transparent;
  }

  .active {
    border-color: #1890ff;
    color: #1890ff;
  }
}

.row_box {
  margin-bottom: 10px;
  font-size: 14px;

  .header {
    padding: 18px 20px 10px;
    border-bottom: 1px solid #cccccc;
  }

  .content {
    padding: 10px;
  }
}

#row_1 {
  .name {
    display: -webkit-box;
  }

  .el-form {
    max-width: 600px;
  }

  .text {
    margin-top: 12px;
    font-size: 12px;
    color: #c0c4cc;
    margin-left: 10px;
  }

  .textColor {
    height: 12px;
    font-size: 12px;
    color: #c0c4cc;
  }

  .textColorRules {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1;
  }

  .consumeP {
    display: flex;

    .dCheck {
      padding-top: 10px;
      margin-left: 12px;
      margin-right: -25px;
    }

    .VIPCheck {
      display: flex;
      padding: 10px 0 0 30px;
      height: 36px;
    }

    .textW {
      width: 450px;
      height: 30px;
      padding-left: 100px;
      margin-bottom: 10px;
    }
  }

  .ditchBox {
    padding-left: 10px;

    strong {
      padding-right: 8px;
    }

    .ditchText {
      width: 460px;
      height: 35px;
      padding-top: 5px;
      padding-left: 92px;
    }
  }

}

.function {
  display: flex;
}

.pulltitle {
  margin: 15px 25px;
  display: flex;
  justify-content: space-between;

  .del_btn {
    cursor: pointer;
    font-size: 14px;
    color: #009bff;
  }
}

.setup {
  color: #5f9fef;
  font-weight: 700;
}

.addHierachy {
  color: #5f9fef;
  font-weight: 700;
  margin-left: 20px;
  margin-bottom: 15px;
}

.submit-btn {
  display: flex;
  justify-content: center;
  padding: 10px 0 20px 0;
}

.form_box {
  display: flex;
  flex-wrap: wrap;
}

.item_box {
  width: 49%;

  &.items_box {
    ::v-deep .el-form-item__label {
      text-align: left;
    }
  }
}

.tableList {
  margin: 0;
  padding: 20px;
  width: 100%;
}

.el-pagination {
  padding: 2px 20px;
}

::v-deep .gift_header {
  &:first-child {
    pointer-events: none;

    .cell {
      display: none !important;
    }
  }
}

.edit_item {
  margin-left: 20px;
}
</style>
