var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-box" }, [
    _c(
      "div",
      { staticClass: "head-search-row" },
      [
        _c(
          "el-form",
          {
            ref: "common",
            attrs: {
              size: "medium",
              "label-width": "120px",
              model: _vm.common,
              rules: _vm.commonRules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "总部客服电话", prop: "serviceNumber" } },
              [
                _c("el-input", {
                  attrs: { maxlength: "13", placeholder: "0000-00000000" },
                  model: {
                    value: _vm.common.serviceNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.common, "serviceNumber", $$v)
                    },
                    expression: "common.serviceNumber",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "JA医药简介", prop: "introduction" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    maxlength: "150",
                    rows: 6,
                    placeholder: "请输入内容",
                  },
                  model: {
                    value: _vm.common.introduction,
                    callback: function ($$v) {
                      _vm.$set(_vm.common, "introduction", $$v)
                    },
                    expression: "common.introduction",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "" } },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                  [_vm._v("保 存")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }