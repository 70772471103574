<template>
  <!-- 医院销售统计报表明细 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="">
          <el-date-picker v-model="inquire.year" type="year" value-format="yyyy" placeholder="年份" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary">导出报表</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="month" label="月份" min-width="100">
              <template slot-scope="scope">
                {{ scope.row.month + '月' }}
              </template>
            </el-table-column>
            <el-table-column prop="hospitalName" label="医院" min-width="200" show-overflow-tooltip />
            <el-table-column prop="orderTotalPrice" label="销售金额" min-width="120">
              <template slot-scope="scope">
                {{ '￥' + scope.row.orderTotalPrice }}
              </template>
            </el-table-column>
            <el-table-column prop="count" label="订单数量" min-width="120" />
            <el-table-column prop="memberCount" label="交易会员数" min-width="100" />
            <el-table-column prop="amount" label="提现金额" min-width="100">
              <template slot-scope="scope">
                {{ '￥' + scope.row.amount }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="details(scope.row)">订单明细</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        year: new Date().getFullYear().toString(),
        hospitalId: ''
      },
      hospitalId: '',
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      // 表格数据
      tableData: [],
      monthList: [
        { name: '1月', id: 1 },
        { name: '2月', id: 2 },
        { name: '3月', id: 3 },
        { name: '4月', id: 4 },
        { name: '5月', id: 5 },
        { name: '6月', id: 6 },
        { name: '7月', id: 7 },
        { name: '8月', id: 8 },
        { name: '9月', id: 9 },
        { name: '10月', id: 10 },
        { name: '11月', id: 11 },
        { name: '12月', id: 12 }
      ]
    }
  },
  created() {
    this.inquire.hospitalId = this.$route.query.hospitalId
    this.inquire.year = this.$route.query.year
    this.hospitalId = this.$route.query.hospitalId
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      var time = []
      if (this.inquire.year) {
        time = [this.inquire.year + '-01-01', this.inquire.year + '-12-31']
      } else {
        this.inquire.year = new Date().getFullYear()
        time = [this.inquire.year + '-01-01', this.inquire.year + '-12-31']
      }

      var param = {
        payTime: time[0] + ' 00:00:00',
        payTimeEnd: time[1] + ' 23:59:59',
        hospitalId: this.inquire.hospitalId
      }
      this.loading = true
      this.$http
        .requestPost({
          url:
            'wait/payment/merchant/commission/comprehensive/hospitalChildStatisticsList?pageNum=' +
            this.pageParam.pageNum +
            '&pageSize=' +
            this.pageParam.pageSize,
          param,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data.data.list
          this.pageParam.total = res.data.data.total
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },

    // 订单明细
    details(row) {
      this.$router.push({
        path: '/financial/financialStatement/orderDetails',
        query: {
          type: 'hospital',
          year: this.inquire.year,
          month: row.month,
          hospitalId: this.hospitalId,
          param: JSON.stringify({})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.address-list {
  flex-wrap: wrap;

  & > div {
    display: inline-flex;
  }
}
.text {
  padding: 0 5px;
}
.text-left {
  min-width: 125px;
  text-align: right;
}
</style>
