<template>
  <div class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-form label-width="120px" ref="submitForm" :label-position="'left'">

      <el-form-item label="模板名称" prop="title">
        <el-input v-model="templateName" maxlength="20" placeholder="模板名称" clearable style="max-width:600px;" disabled></el-input>
      </el-form-item>

      <el-form-item label="">
        <div class="flex-box flex-wrap" v-for="(item, index) in singleList" :key="index">
          <div class="topic-box">
            <!-- 单选题 -->
            <template>
              <div class="topic-type">
                <label>{{index + 1 }}、单选题</label>
              </div>
              <div class="topic-row flex-box">
                <label class="label-box">题目</label>
                <div class="content-box">
                  <el-input v-model="item.content" maxlength="30" placeholder="题目" disabled />
                </div>
              </div>
              <div class="topic-row flex-box" v-for="(option, optionIndex) in item.optionList" :key="optionIndex">
                <label class="label-box">选项</label>
                <div class="option-box">
                  <div class="option-li flex-box">
                    <el-input v-model="option.content" maxlength="30" :placeholder="'选项'" disabled />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="">
        <div class="flex-box flex-wrap" v-for="(item, index) in multipleList" :key="index">
          <div class="topic-box">
            <!-- 多选题 -->
            <template>
              <div class="topic-type">
                <label>{{index + 1 }}、多选题</label>
              </div>
              <div class="topic-row flex-box">
                <label class="label-box">题目</label>
                <div class="content-box">
                  <el-input v-model="item.content" maxlength="30" placeholder="题目" disabled />
                </div>
              </div>
              <div class="topic-row flex-box" v-for="(option, optionIndex) in item.optionList" :key="optionIndex">
                <label class="label-box">选项</label>
                <div class="option-box">
                  <div class="option-li flex-box">
                    <el-input v-model="option.content" maxlength="30" :placeholder="'选项'" disabled />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="">
        <div class="flex-box flex-wrap" v-for="(item, index) in answerList" :key="index">
          <div class="topic-box">
            <!-- 简答题 -->
            <template>
              <div class="topic-type">
                <label>{{index + 1 }}、简答题</label>
              </div>
              <div class="topic-row flex-box">
                <label class="label-box">题目</label>
                <div class="content-box">
                  <el-input v-model="item.content" maxlength="30" placeholder="题目" disabled />
                </div>
              </div>
            </template>

          </div>
        </div>
      </el-form-item>

      <!-- <el-form-item>
        <el-button type="primary" @click="goBack">保存</el-button>
      </el-form-item> -->
    </el-form>

  </div>
</template>

<script>

export default {
  data() {
    return {
      tempId: '',
      tempType: '',
      templateName: '',
      singleList: [],
      multipleList: [],
      answerList: [],
    };
  },
  created() {
    this.tempId = this.$route.query.id
    this.tempType = this.$route.query.type
  },
  mounted() {
    this.getQuestionnaireDetail()
  },
  methods: {
    getQuestionnaireDetail() {
      this.$http.requestGet({ url: "/consult/getQuestionnaireDetail/" + this.tempId + '/' + this.tempType })
        .then((res) => {
          if (res.code == 200) {
            this.templateName = res.data.title;
            this.singleList = res.data.questionDetailVoList.filter(item => item.type === 1);
            this.multipleList = res.data.questionDetailVoList.filter(item => item.type === 2);
            this.answerList = res.data.questionDetailVoList.filter(item => item.type === 3);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // goBack() {
    //   this.$router.go(-1);
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-button--small.is-circle {
    height: 32px;
    margin: 4px 0 4px 4px;
  }
  .el-button--text {
    padding-left: 10px;
    padding-right: 10px;
  }
}
$light-gray: #dcdfe6;

.main-box {
  padding: 20px 20px;
}

.topic-box {
  width: 352px;
  border: 1px solid $light-gray;
  padding: 5px 10px 10px 0;
  border-radius: 5px;
  margin: 0 10px 10px 0;
  .topic-type {
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
  }
  .topic-row {
    margin-bottom: 10px;
    .label-box {
      width: 62px;
      display: block;
      text-align: right;
      margin-right: 10px;
    }
    .content-box {
      flex: 1;
    }
    .option-box {
      padding: 5px 5px 0 5px;
      border: 1px solid $light-gray;
      border-radius: 5px;
      flex: 1;
      .option-li {
        margin-bottom: 5px;
      }
      .add-option {
        text-align: center;
        color: #409eff;
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.enclosure {
  width: 160px;
  height: 120px;
  border: 1px dotted #dcdfe6;
  border-radius: 10px;
}
.enclosureEmpty {
  display: flex;
  i {
    font-size: 36px;
    margin: auto;
  }
}
// .enclosureUrl {
//   width: 100%;
//   height: 220px;
//   border: 1px solid #dcdfe6;
//   border-radius: 10px;
// }
.select-dietitian {
  display: flex;
  .placeholder {
    width: 150px;
    border: 1px solid #c0c0c0;
    border-radius: 3px;
    color: #777;
    padding-left: 15px;
  }
  .btn {
    margin-left: 20px;
  }
}

::v-deep .el-form-item__label:before {
  content: "*";
  color: #ff4949;
  margin-right: 4px;
}
</style>
