<!-- 医院配送订单(退货单管理 - 待审核售后单) -->
<template>
  <div class="main-box">
    <returned-goods orderStatus="auditing" deliveryMethod="hospital" :isAfterSale="true"></returned-goods>
  </div>
</template>

<script>
import returnedGoods from '@/components/order/returnedGoods_m'
export default {
  components: {
    returnedGoods
  },
  data() {
    return {

    }
  }
}
</script>

<style lang="scss" scoped></style>