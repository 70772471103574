// 最近1周、最近1月、最近3个月 时分秒为00:00:00
// 明天的时间
var tomorrow = new Date()
tomorrow.setTime(tomorrow.getTime() + 24 * 60 * 60 * 1000)
// var end = tomorrow
// 今天的时间
var end = new Date()
var year = end.getFullYear()
var month = end.getMonth() + 1 // 0-11表示 1-12月
var day = end.getDate()
var h = end.getHours();
var mm = end.getMinutes();
var s = end.getSeconds();
var dateObj = {}
dateObj.start = null
dateObj.end = year + '-' + add0(month) + '-' + add0(day)
// dateObj.end = `${year}-${month}-${day} ${add0(h)}:${add0(mm)}:${add0(s)}`
var endMonthDay = new Date(year,month,0).getDate() // 当前月的总天数

// 补零
function add0(m){return m<10?'0'+m:m }

// 获取近一周日期范围
export function getLastWeekDate() {
  if(day - 7 <= 0) { // 如果在当月7日之前
    const startMonthDay = new Date(year,(parseInt(month) - 1),0).getDate() // 1周前所在月的总天数
    if(month - 1 <= 0) { // 如果在当年的1月份
      dateObj.start = (year - 1) + '-' + 12 + '-' + add0(31 - (7 - day))
    } else {
      dateObj.start = year + '-' + add0(month - 1) + '-' + add0(startMonthDay - (7 - day))
    }
  } else {
    dateObj.start = year + '-' + add0(month) + '-' + add0(day - 7)
  }
  // const lastWeekDate = [`${dateObj.start} 00:00:00`,`${dateObj.end} 23:59:59`]
  const lastWeekDate = [`${dateObj.start}`,`${dateObj.end}`]
  return lastWeekDate
}

// 获取近一个月日期范围
export function getLastMonthDate() {
  if(month - 1 <= 0) { // 如果是1月，年数往前推一年<br>
    dateObj.start = (year - 1) + '-' + 12 + '-' + day
  } else {
    const startMonthDay = new Date(year,(parseInt(month) - 1),0).getDate()
    if(startMonthDay < day) { // 1个月前所在月的总天数小于现在的天日期
      if(day < endMonthDay) { // 当前天日期小于当前月总天数
        dateObj.start = year + '-' + add0(month - 1) + '-' + add0(startMonthDay - (endMonthDay - day))
      } else {
        dateObj.start = year + '-' + add0(month - 1) + '-' + add0(startMonthDay)
      }
    } else {
      dateObj.start = year + '-' + add0(month - 1) + '-' + add0(day)
    }
  }
  // const lastWeekDate = [`${dateObj.start} 00:00:00`,`${dateObj.end} 23:59:59`]
  const newMonthDate = [`${dateObj.start}`,`${dateObj.end}`]
  return newMonthDate
}

// 获取近三个月日期范围
export function getLastThreeMonthDate() {
  if(month - 3 <= 0) { // 如果是1、2、3月，年数往前推一年
    const start3MonthDay = new Date((year - 1),(12 - (3 - parseInt(month))),0).getDate() // 3个月前所在月的总天数
    if(start3MonthDay < day) { // 3个月前所在月的总天数小于现在的天日期
      dateObj.start = (year - 1) + '-' + (12 - (3 - month)) + '-' + start3MonthDay
    } else {
      dateObj.start = (year - 1) + '-' + (12 - (3 - month)) + '-' + day
    }
  } else {
    const start3MonthDay = new Date(year,(parseInt(month) - 3),0).getDate() // 3个月前所在月的总天数
    if(start3MonthDay < day) { // 3个月前所在月的总天数小于现在的天日期
      if(day < endMonthDay) { // 当前天日期小于当前月总天数,2月份比较特殊的月份
        dateObj.start = year + '-' + add0(month - 3) + '-' + add0(start3MonthDay - (endMonthDay - day))
      } else {
        dateObj.start = year + '-' + add0(month - 3) + '-' + add0(start3MonthDay)
      }
    } else {
      dateObj.start = year + '-' + add0(month - 3) + '-' + add0(day)
    }
  }
  // const lastWeekDate = [`${dateObj.start} 00:00:00`,`${dateObj.end} 23:59:59`]
  const newThreeMonthDate = [`${dateObj.start}`,`${dateObj.end}`]
  return newThreeMonthDate
}