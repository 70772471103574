exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"variables.scss","sourceRoot":""}]);

// exports
exports.locals = {
	"menuText": "#fff",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#fff",
	"menuBg": "#2f87eb",
	"menuHover": "#1065c7",
	"subMenuBg": "#2f87eb",
	"subMenuHover": "#1065c7",
	"sideBarWidth": "210px"
};