<template>
  <div class="main-box">
    <!-- 申购单 - 待签收退货单 -->
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="申购单编号">
          <el-input v-model="inquire.orderCode" placeholder="申购单编号" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item label="审核时间">
          <el-date-picker
            v-model="daterange"
            end-placeholder="结束日期"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>

        <el-form-item label="产品名称">
          <el-input v-model="inquire.productName" placeholder="产品名称" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item label="商家名称">
          <el-input v-model="inquire.merchantName" placeholder="商家名称" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="exportFile">导出</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            ref="tables"
            :data="tableData"
            :expand-row-keys="expands"
            border
            height="400px"
            row-key="orderCode"
            style="width: 100%"
            @expand-change="expandChange"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table :data="scope.row.items" border style="width: 100%">
                  <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div class="flex-box">
                        <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                        <div class="hospital-name">
                          {{ scope.row.productName }}
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="产品编号" min-width="200" prop="productCode" show-overflow-tooltip />
                  <el-table-column label="产品规格" min-width="120" prop="productNorms">
                    <template slot-scope="scope">
                      {{ scope.row.productNorms || '/' }}
                    </template>
                  </el-table-column>
                  <el-table-column label="申购单价" min-width="120" prop="itemPrice">
                    <template slot-scope="scope">
                      {{ '￥' + scope.row.itemPrice }}
                    </template>
                  </el-table-column>
                  <el-table-column label="申购数量" min-width="120" prop="itemNum" />
                  <el-table-column label="申购总价" min-width="120" prop="">
                    <template slot-scope="scope">
                      {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="退款单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column label="商家" min-width="160" prop="merchantName" />
            <el-table-column label="商家编号" min-width="200" prop="merchantCode" />
            <el-table-column label="申购总价" min-width="120" prop="">
              <template slot-scope="scope">
                {{ '￥' + getTotalPrices(scope.row.items) }}
              </template>
            </el-table-column>
            <el-table-column label="退还理由" min-width="160" prop="reasonForReturn" :formatter="formatter" />
            <el-table-column label="审核时间" min-width="160" prop="auditTime" />
            <el-table-column label="审核人" min-width="160" prop="auditByName" />
            <el-table-column fixed="right" label="操作" min-width="160" prop="remark">
              <template slot-scope="scope">
                <span v-if="scope.row.reasonForReturn == 1" class="operation-btn" @click="receiptOfGoods(scope.row)">确认收货</span>
                <span v-if="scope.row.reasonForReturn == 1" class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="handleLogistics(scope.row)">查看物流</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-size="pageParam.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>
    <!-- 产品导出 -->
    <product-export
      ref="isProductExport"
      :show-dialog="showExportDialog"
      :close.sync="showExportDialog"
      :purchase-status="inquire.purchaseStatus"
      product-type="purchaseReturn"
      title="待签收退货单导出"
      page-type="return"
    />
    <!-- v4.4.5物流信息弹出框 -->
    <d-dialog ref="logisticsDialog" :logistics-list="logisticsList" :data-obj="dataObj" :route-info="routeInfo" @handleClose="handleClose" />
  </div>
</template>

<script>
import { setSessionStorage, getSessionStorage } from '@/utils/auth'
import productExport from '@/components/productExport'
import DDialog from '@/components/d-dialog'
export default {
  components: {
    productExport,
    DDialog
  },
  data() {
    return {
      // 查询
      inquire: {
        purchaseStatus: 'returned',
        auditTimeBegin: '',
        auditTimeEnd: '',
        productName: '',
        merchantName: '',
        orderCode: '',
        reasonForReturn: ''
      },
      daterange: [],
      logisticsList: [], // 物流信息
      dataObj: {}, // 物流
      routeInfo: {},
      // 分页
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      loading: false,
      expands: [], // 当前展示行
      showExportDialog: false
    }
  },
  created() {
    const page = getSessionStorage('returnedPageR')
    if (page && Object.keys(page).length > 0) {
      this.pageParam.pageNum = page.pageNum
      this.pageParam.pageSize = page.pageSize
    }
    if (sessionStorage.getItem('cacheParamLg')) {
      this.inquire = JSON.parse(sessionStorage.getItem('cacheParamLg'))
      this.daterange[0] = this.inquire.auditTimeBegin || ''
      this.daterange[1] = this.inquire.auditTimeEnd || ''
    }

    this.getDataList()
  },
  methods: {
    handleClose() {
      this.$refs.logisticsDialog.showDialog = false
      //  关闭时置空
      this.logisticsList = []
    },
    // 查看物流
    async handleLogistics(row) {
      //    发起查看物理轨迹请求
      try {
        await this.getReturnPostInfo(row.orderCode)
        console.info('查看物流', row)
      } catch (error) {}
    },
    // 查询物流轨迹
    async getReturnPostInfo(code) {
      try {
        const res = await this.$http.requestGet({
          url: `/customer/purchase/order/return/postInfo/${code}`,
          loading: false
        })
        if (!res.data || (res.code == 200 && res.data.status != 200)) {
          this.$message({
            message: '查无结果，请检查单号和快递公司是否有误',
            type: 'warning',
            duration: 2500
          })
        } else {
          this.dataObj = res.data
          this.routeInfo = res.data.routeInfo
          this.logisticsList = res.data.data
          this.$refs.logisticsDialog.showDialog = true
        }
      } catch (error) {}
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.getDataList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getDataList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getDataList()
    },
    // table 手风琴
    expandChange(news, old) {
      if (old.length === 0) {
        return (this.expands = [])
      }
      this.expands = [news.orderCode]
    },
    // 计算总价
    getTotalPrices(arr = []) {
      let prices = 0
      arr.forEach(v => {
        prices += parseInt(v.itemNum) * v.itemPrice
      })
      return prices.toFixed(2)
    },

    // 获取数据列表
    getDataList() {
      if (!this.daterange) this.daterange = []
      this.inquire.auditTimeBegin = this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire.auditTimeEnd = this.daterange.length > 0 ? this.daterange[1] : ''
      if (getSessionStorage('userInfo').managerType !== null && getSessionStorage('userInfo').managerType !== '') {
        this.inquire.managerType = getSessionStorage('userInfo').managerType
      }
      if (getSessionStorage('userInfo').areaId !== null && getSessionStorage('userInfo').areaId !== '') {
        this.inquire.areaId = getSessionStorage('userInfo').areaId
      }
      const param = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire))
      }
      this.$http
        .requestPost({
          url: '/customer/return/order/newPage',
          param: param,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
          sessionStorage.removeItem('cacheParamLg')
        })
        .catch(err => {
          this.loading = false
          sessionStorage.removeItem('cacheParamLg')
          console.log(err)
        })
    },
    formatter(row) {
      const MAP = {
        1: '售后',
        2: '调货至商家',
        3: '转样品',
        4: '退还至总部',
        5: '差异调整'
      }
      return MAP[row.reasonForReturn]
    },

    // 详情
    handleDetails(row) {
      setSessionStorage('returnedPageR', this.pageParam)
      sessionStorage.setItem('cacheParamLg', JSON.stringify(this.inquire))
      this.$router.push({
        path: '/form/returnedGoods/particulars',
        query: {
          type: 3,
          id: row.orderCode
        }
      })
    },

    // 确认收货
    receiptOfGoods(row) {
      console.log(row, '===========')
      this.$confirm('是否确认收货?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .requestPut({
              url: '/customer/return/order/confirm/receipt/' + row.orderCode
            })
            .then(res => {
              this.$message.success('操作成功')
              this.getDataList()
            })
        })
        .catch(() => {})
    },
    // 导出
    exportFile() {
      this.showExportDialog = true
      this.$refs.isProductExport.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
  width: 375px;
}

.el-head-search-row {
  .flex-box {
    width: 326px;
  }
}
</style>
