<!-- 申购单中导出功能2.0，1.0导出功能保留在productExportCopy中 -->
<template>
    <div class="d-preview">
        <el-dialog :title="title" :visible.sync="showDialog" :modal="false" :before-close="close" width="30%">
            <div class="Popout_content">
                <el-form :model="inquire" label-width="100px" class="demo-dynamic">
                    <el-form-item label="商家名称">
                        <el-input v-model="inquire.merchantName" placeholder="商家名称" style="width: 90%" />
                    </el-form-item>
                    <el-form-item label="产品名称">
                        <el-input v-model="inquire.productName" placeholder="产品名称"  style="width: 90%"  />
                    </el-form-item>
                    <el-form-item label="订单类型">
                        <el-select v-model="inquire.purchaseStatus" placeholder="请选择" class="form-select"  style="width: 90%" clearable >
                            <el-option
                                v-for="item in (this.productType == 'purchase' ? purchaseOption : purchaseReturnOption)"
                                :key="item.key" :label="item.value" :value="item.key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="sgDateTitle">
                        <el-date-picker v-model="inquire.sgDate" end-placeholder="结束日期" start-placeholder="开始日期"
                            type="daterange" value-format="yyyy-MM-dd" style="width: 90%" />
                    </el-form-item>
                    <el-form-item label="审核时间">
                        <el-date-picker v-model="inquire.daterange" end-placeholder="结束日期" start-placeholder="开始日期"
                            type="daterange" value-format="yyyy-MM-dd" style="width: 90%" />
                    </el-form-item>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="close">关 闭</el-button>
                <el-button type="primary" @click="exportFile">导出</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { newExportPurchaseOrders, newExportReturnPurchaseOrders } from "@/api/order";

export default {
    name: "DExport",
    props: {
        showDialog: {
            // 是否显示
            type: Boolean,
            default: false
        },
        title: {
            // 标题
            type: String,
            default: "订单导出"
        },
        productType: { // 产品类型purchase申购单  purchaseReturn退货单
            type: String,
            default: ""
        },
        purchaseStatus: {
            // 订单状态
            type: String,
            default: ""
        },
        sgDateTitle: {
            type: String,
            default: "退还时间"
        }
    },
    data() {
        return {
            daterange: [],
            sgDate:[],
            inquire: {
                merchantName: '',
                productName: '',
                purchaseStatus: '',
                daterange: '',
                sgDate:'',
                type: '', //类型判断产品申购单、产品退货单
            },
            //申购单枚举值
            purchaseOption: [
                { key: 'auditing', value: '审核中' },
                { key: 'audited', value: '已审核' },
                { key: 'completed', value: '已完成' },
                { key: 'unaudited', value: '未通过' },
            ],
            purchaseReturnOption: [
                { key: 'auditing', value: '审核中' },
                { key: 'completed', value: '已完成' },
                { key: 'pending_return', value: '待退还' },
                { key: 'returned', value: '已退还' },
                { key: 'unaudited', value: '未通过' },
            ]
        };
    },
    created() {
        this.inquire.purchaseStatus = this.purchaseStatus
        this.inquire.type = this.productType;
    },
    methods: {
        open() {
            this.clearData()
        },
        clearData() {
            this.inquire.productName = ""
            this.inquire.merchantName = ""
            this.inquire.daterange = []
            this.inquire.sgDate = []
        },
        // 导出
        async exportFile() {
            if (!this.inquire.daterange) this.inquire.daterange = [];
            if (!this.inquire.sgDate) this.inquire.sgDate = []
            const { daterange, sgDate } = this.inquire
            const data = {
                ...this.inquire,
                auditTimeBegin: daterange.length > 0 ? daterange[0] : '',
                auditTimeEnd: daterange.length > 0 ? daterange[1] : '',
                purchaseTimeBegin: sgDate.length > 0 ? sgDate[0] : '',
                purchaseTimeEnd: sgDate.length > 0 ? sgDate[1] : ''
            }
            const param = JSON.parse(JSON.stringify(data))

            // 根据 productType 调用相应的 API 函数并传入参数
            if (this.productType === 'purchase') {
                this.shareExport(newExportPurchaseOrders, param);
            } else {
                this.shareExport(newExportReturnPurchaseOrders, param);
            }
        },
        //导出公用函数
        async shareExport(apiFunction, param) {
            try {
                const res = await apiFunction(param, "post");
                console.log('res===>', res);
                // this.$public.downloadFile(res);
                this.$message.success('导出任务已经启动，请稍后到[导出文件列表]中下载文件');
                setTimeout(() => {
                    this.clearData();
                    this.close();
                }, 200);
            } catch (err) {
                console.log(err);
            }
        },

        // 关闭弹窗
        close() {
            this.$emit("update:close", false);
        },
    }
};
</script>

<style lang="scss" scoped></style>
