<template>
  <!-- 产品单位列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="增加时间">
          <div class="flex-box">
            <el-date-picker
              v-model="daterange"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </div>
        </el-form-item>

        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="selectKey" placeholder="请选择" class="width-140" @change="selectValue = ''">
              <el-option label="医院名称" value="hospitalName" />
              <el-option label="医院编号" value="hospitalCode" />
              <!-- <el-option label="所属商家" value="merchantName"></el-option> -->
            </el-select>
            <el-input v-model="selectValue" placeholder="" class="width-180" @keyup.enter.native="searchList" />
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button v-if="userInfo.managerType == 'all'" size="small" type="primary" @click="handleAdd">新增医院</el-button>
      <el-button v-if="userInfo.managerType == 'all'" size="small" type="primary" @click="dialogVisible_import = true">
        导入
      </el-button>
      <el-button size="small" type="primary" @click="exportHospital">导出</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template slot-scope="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="hospitalCode" label="医院编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">{{ scope.row.hospitalCode }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="hospitalName" label="医院名称" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="flex-box">
                  <img v-if="scope.row.hospitalAvatar" class="img-box-show box-m-r" :src="scope.row.hospitalAvatar" alt="" />
                  <div class="hospital-name">{{ scope.row.hospitalName }}</div>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="regionName" label="所属大区" min-width="120" show-overflow-tooltip />
            <el-table-column prop="districtName" label="所属片区" min-width="120" show-overflow-tooltip />
            <el-table-column prop="agencyName" label="所属办事处" min-width="120" /> -->
            <el-table-column prop="merchantName" label="商家数量" min-width="120" />
            <el-table-column prop="addressName" label="所在地区" min-width="200" />
            <el-table-column prop="address" label="详细地址" min-width="200" show-overflow-tooltip />
            <el-table-column prop="createTime" label="增加时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="260" fixed="right">
              <template slot-scope="scope">
                <span v-if="userInfo.managerType == 'all'" class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span v-if="userInfo.managerType == 'all'" class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="openSellDialog(scope.row)">销售记录</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="handleShippingAddress(scope.row)">配送地址</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="merchantManage(scope.row)">商家管理</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="handleStop(scope.row)">停用</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加医院弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible_1" :before-close="closeDialog_1" width="600px" top="5vh" @open="openHospital">
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="100px" :model="submitForm" :rules="submitRules">
          <el-form-item v-if="!isAdd" label="医院编号">
            <el-input v-model="submitForm.hospitalCode" placeholder="医院编号" readonly />
          </el-form-item>
          <el-form-item label="所在地址" prop="addressId">
            <el-cascader
              v-model="submitForm.addressId"
              :props="props_add"
              :options="addressList_add"
              placeholder="请选项地址"
              class="width-inherit"
              @change="submitForm.hospitalName = ''"
            />
          </el-form-item>
          <el-form-item label="医院名称" prop="hospitalName">
            <el-autocomplete
              v-model="submitForm.hospitalName"
              class="inline-input"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              value-key="name"
              :trigger-on-focus="false"
              @select="handleSelect"
            />
            <p v-show="submitForm.hospitalName && submitForm.hospitalName === hospitalInfo.name">
              {{
                !!hospitalInfo.location
                  ? typeof hospitalInfo.address === 'string'
                    ? hospitalInfo.address
                    : hospitalInfo.location
                  : '无效地址，请重选'
              }}
            </p>
          </el-form-item>
          <el-form-item v-if="isAdd" label="所属商家" prop="merchantIds">
            <el-select v-model="submitForm.merchantIds" filterable multiple placeholder="请选择" class="form-select" @change="changeMerchant">
              <el-option
                v-for="item in merchantList"
                :key="item.merchantId"
                :label="item.merchantName"
                :value="item.merchantId"
                :disabled="item.disabled"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input v-model="submitForm.address" placeholder="详细地址" />
          </el-form-item>
          <el-form-item label="经纬度" prop="LogAndlat">
            <div style="display:flex;">
              <el-input v-model.number="submitForm.longitude" type="number" style="flex:1; margin-right:20px" placeholder="经度" />
              <el-input v-model.number="submitForm.latitude" type="number" style="flex:1;" placeholder="维度" />
            </div>
          </el-form-item>
          <el-form-item label="介绍" prop="hospitalIntroduction">
            <el-input v-model="submitForm.hospitalIntroduction" type="textarea" :rows="3" maxlength="150" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="医院图片" prop="hospitalAvatar">
            <d-upload
              upload-type="picture-card"
              :file-list="fileList"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccess"
              @removeFile="removeFile_1"
            >
              <div class="tip-box">
                <i class="el-icon-plus"></i>
                <span slot="tip" class="tip">图片大小：60*60</span>
              </div>
            </d-upload>
          </el-form-item>
          <el-form-item label="面积">
            <el-input v-model="submitForm.hospitalArea" placeholder="面积" />
          </el-form-item>
          <el-form-item label="开业时间">
            <el-date-picker
              v-model="submitForm.hospitalOpeningTime"
              class="width-inherit"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            />
          </el-form-item>
          <el-form-item label="营业时间">
            <el-time-picker
              v-model="businessHours"
              class="width-inherit"
              is-range
              format="HH:mm"
              value-format="HH:mm"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
            />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog_1">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 商家信息弹框 -->
    <el-dialog
      title="查看商家信息"
      :visible.sync="dialogVisible_2"
      :before-close="() => (dialogVisible_2 = false)"
      width="600px"
      top="5vh"
      @open="openInfo"
    >
      <div class="Popout_content">
        <el-form ref="submitForm_2" label-width="100px" :model="submitForm_2">
          <el-form-item label="商家名称">
            <el-input v-model="submitForm_2.merchantName" placeholder="商家名称" disabled />
          </el-form-item>
          <el-form-item label="商家编号">
            <el-input v-model="submitForm_2.merchantCode" placeholder="商家编号" disabled />
          </el-form-item>
          <el-form-item label="所属办事处">
            <el-cascader
              v-model="submitForm_2.agencyId"
              :props="props"
              :options="addressList"
              placeholder="请选项地址"
              class="width-inherit"
              disabled
            />
          </el-form-item>
          <el-form-item label="商家对接人">
            <div>
              <el-table :data="shopsMessenger" border style="width: 100%">
                <el-table-column prop="messengerName" label="对接人" min-width="120" />
                <el-table-column prop="messengerPhoneNumber" label="手机号" min-width="120" />
              </el-table>
            </div>
          </el-form-item>
          <el-form-item label="规模">
            <el-select v-model="submitForm_2.merchantScale" placeholder="请选择" class="form-select" disabled>
              <el-option v-for="item in scaleList" :key="item.id" :label="item.name" :value="item.name" />
            </el-select>
          </el-form-item>
          <el-form-item label="成立时间">
            <el-date-picker v-model="submitForm_2.merchantEstablishedTime" class="width-inherit" type="date" disabled placeholder="选择日期" />
          </el-form-item>
          <el-form-item label="公司网址">
            <el-input v-model="submitForm_2.merchantWebsite" placeholder="公司网址" disabled />
          </el-form-item>
          <el-form-item label="联系人姓名">
            <el-input v-model="submitForm_2.merchantContactsName" placeholder="联系人姓名" disabled />
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="submitForm_2.merchantContactsPhoneNumber" placeholder="联系人手机号" disabled />
          </el-form-item>
          <el-form-item label="微信">
            <el-input v-model="submitForm_2.merchantContactsWechat" placeholder="微信" disabled />
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="submitForm_2.merchantContactsEmail" placeholder="邮箱" disabled />
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="submitForm_2.merchantDescription" type="textarea" :rows="3" disabled maxlength="150" placeholder="请输入内容" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 销售记录弹框 -->
    <el-dialog :title="sellTitle + ' - 销售记录'" :visible.sync="dialogVisible_3" width="80%" top="5vh">
      <div class="Popout_content">
        <div class="flex-box">
          <el-form :inline="true" class="demo-form-inline" size="medium">
            <el-form-item label="订单编号">
              <el-input v-model="inquire_2.orderCode" placeholder="订单编号" />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="small" @click="getSellList">查询</el-button>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="small" @click="showSalesRecordsDialog=true">导出</el-button>
            </el-form-item>
          </el-form>
          <!-- <div class="total-money">
            提成金额：
            <span>{{ totalAmount.toFixed(2) }}</span>
            元
          </div> -->
        </div>

        <el-table v-loading="loading_2" :data="tableData_2" border height="400px" style="width: 100%">
          <el-table-column prop="date" label="序号" width="60">
            <template slot-scope="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column prop="orderCoId" label="订单编号" min-width="200" show-overflow-tooltip />
          <el-table-column prop="hospitalName" label="产品名称" min-width="200">
            <template slot-scope="scope">
              {{ scope.row.productNames.join(', ') }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="amountNeedToPay" label="应付金额" min-width="120">
                        <template slot-scope="scope">{{ "￥" + scope.row.amountNeedToPay }}</template>
                    </el-table-column> -->
          <el-table-column prop="amountPaid" label="实付金额" min-width="120">
            <template slot-scope="scope">
              {{ '￥' + scope.row.amountPaid }}
            </template>
          </el-table-column>
          <!-- <el-table-column prop="brokerageAmount" label="提成金额" min-width="120">
                        <template slot-scope="scope">{{ "￥" + scope.row.brokerageAmount }}</template>
                    </el-table-column> -->
          <el-table-column prop="payTime" label="付款时间" min-width="160" />
          <el-table-column prop="memberNickName" label="会员微信昵称" min-width="120" />
          <el-table-column prop="" label="操作" min-width="140" fixed="right">
            <template slot-scope="scope">
              <span class="operation-btn" @click="toDetails(scope.row)">订单详情</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_2.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_2.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_2.total"
            @size-change="handleSizeChange_2"
            @current-change="handleCurrentChange_2"
          />
        </div>
        <p style="padding-top: 30px"></p>
      </div>
    </el-dialog>

    <!-- 配送地址弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible_4" width="650px">
      <div class="Popout_content">
        <el-tree node-key="shippingId" lazy :load="loadNode" :default-expand-all="false" :props="defaultProps" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_4 = false">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 商家管理 -->
    <el-dialog title="商家管理" :visible.sync="dialogVisible_5" top="5vh" width="70%">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item>
            <el-button size="small" type="primary" @click="addMerchant">添加商家</el-button>
          </el-form-item>
        </el-form>

        <el-table v-loading="loading_5" :data="businessTableList" border height="400px" style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template slot-scope="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column prop="merchantCode" label="商家编号" min-width="200" show-overflow-tooltip />
          <el-table-column prop="merchantName" label="商家名称" min-width="200" />
          <el-table-column prop="productCount" label="负责产品" min-width="120" />
          <el-table-column prop="messengerName" label="商家信息员" min-width="120" />
          <el-table-column fixed="right" label="操作" min-width="200" prop="">
            <template slot-scope="scope">
              <span class="operation-btn" @click="merchantInfo(scope.row)">商家档案</span>
              <span class="operation-btn_partition">/</span>
              <span class="operation-btn" @click="openProductList(scope.row)">产品列表</span>
              <span class="operation-btn_partition">/</span>
              <span class="operation-btn" @click="delMerchant(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            :current-page="pageParam_5.pageNum"
            :page-size="pageParam_5.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam_5.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_5"
            @current-change="handleCurrentChange_5"
          />
        </div>
      </div>
    </el-dialog>

    <!-- 添加商家 -->
    <el-dialog title="添加商家" :visible.sync="dialogVisible_6" top="5vh" width="70%">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item>
            <el-select v-model="merchantSelectKey" placeholder="请选择" class="width-140" @change="merchantSelectValue = ''">
              <el-option label="商家名称" value="merchantName" />
              <el-option label="商家编号" value="" />
              <el-option label="商家信息员" value="" />
            </el-select>
            <el-input v-model="merchantSelectValue" placeholder="" class="width-180" clearable />
          </el-form-item>

          <el-form-item>
            <el-button size="small" type="primary" @click="merchantSearchList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table v-loading="loading_5" :data="noBusinessTableList" border height="400px" style="width: 100%" @selection-change="currentChange">
          <el-table-column type="selection" width="55" :selectable="selectable" />
          <el-table-column label="序号" prop="date" width="60">
            <template slot-scope="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column prop="merchantCode" label="商家编号" min-width="200" show-overflow-tooltip />
          <el-table-column prop="merchantName" label="商家名称" min-width="200" />
          <el-table-column prop="businessCoverage" label="业务覆盖" min-width="200">
            <template slot-scope="scope">
              {{ scope.row.businessCoverage.length > 0 ? scope.row.businessCoverage.join() : '' }}
            </template>
          </el-table-column>
          <el-table-column prop="messengerName" label="商家信息员" min-width="120" />
          <el-table-column fixed="right" label="操作" min-width="120" prop="">
            <template slot-scope="scope">
              <span class="operation-btn" @click="merchantInfo(scope.row)">详情</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            :current-page="pageParam_6.pageNum"
            :page-size="pageParam_6.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam_6.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_6"
            @current-change="handleCurrentChange_6"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click=";(dialogVisible_6 = false), (merchantSelectValue = '')">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 商家档案 -->
    <shops-details
      ref="shopsDetails"
      :dialog-show="shopsDetailsDialog"
      :dialog-visible.sync="shopsDetailsDialog"
      :disable="true"
      :title="dialogTitle"
    />

    <!-- 产品列表 -->
    <product-table
      ref="productTable"
      :dialog-show="productTableDialog"
      :dialog-visible.sync="productTableDialog"
      :title="dialogTitle"
      :operation="false"
    />

    <!-- 导入 -->
    <el-dialog :visible.sync="dialogVisible_import" title="导入" width="650" :close-on-click-modal="false">
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="100px">
          <el-form-item label="导入模板">
            <el-button size="small" type="primary" @click="downloadTemplate">下载批量导入模板文件</el-button>
          </el-form-item>

          <el-form-item label="批量导入">
            <d-upload
              action="/hospital/medical/importHospitals"
              :show-file-list="false"
              :file-list="fileList"
              accept=".xls,.xlsx"
              @beforeUpload="beforeUpload"
              @uploadSuccess="importFile"
              @uploadError="uploadError"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </d-upload>
            <div>
              <p v-if="importStatus === 1" class="progress color_success">
                导入成功！
              </p>
              <p v-else-if="importStatus === 2" class="progress color_red">
                导入医院失败，请检查导入模版是否正确！
              </p>
              <p v-else-if="importStatus === 3" class="progress">
                正在导入中...
              </p>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_import = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 产品导出 -->
    <hospital-export ref="isProductExport" :show-dialog="showExportDialog" :close.sync="showExportDialog" product-type="hospital" title="医院导出" />
    <!-- 销售记录导出 -->
    <d-export2 :show-dialog.sync="showSalesRecordsDialog" :show-region="false" :show-order-type="false" product-order-type="medicine" @exportFiles="exportSalesRecords" />
  </div>
</template>

<script>
import dUpload from '@/components/d-upload'
import dArea from '@/components/d-area'
import dExport2 from '@/components/order/d-export2'
import shopsDetails from '@/components/shops/shopsDetails.vue'
import productTable from '@/components/shops/productTable.vue'
import { getSessionStorage } from '@/utils/auth'
import hospitalExport from '@/components/hospitalExport/index1.vue'

export default {
  components: {
    dUpload,
    // eslint-disable-next-line vue/no-unused-components
    dArea,
    dExport2,
    shopsDetails,
    productTable,
    hospitalExport
  },
  data() {
    return {
      // 地址
      addressList: [],
      props: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },
      // 省市区
      addressList_add: [],
      props_add: {
        label: 'addressName',
        value: 'addressId',
        children: 'child',
        emitPath: false
      },

      // 查询
      daterange: [], // 增加时间
      inquire: {
        createTimeBegin: '',
        createTimeEnd: '',
        agencyId: '',
        enable: 1
      },
      selectKey: 'hospitalName',
      selectValue: '',
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      // 表格数据
      tableData: [],
      userInfo: {},

      // ==================== 添加医院 ====================
      dialogTitle: '添加医院', // 添加弹窗标题
      inputtipsTypeList: [
        '090000',
        '090100',
        '090101',
        '090102',
        '090200',
        '090201',
        '090202',
        '090203',
        '090204',
        '090205',
        '090206',
        '090207',
        '090208',
        '090209',
        '090210',
        '090211',
        '090300',
        '090400',
        '090500',
        '090600',
        '090601',
        '090602',
        '090700',
        '090701',
        '090702'
      ], // 提示类别
      dialogVisible_1: false, // 添加弹窗
      submitForm: {
        hospitalCode: '',
        hospitalName: '',
        merchantIds: '',
        address: '',
        hospitalIntroduction: '',
        hospitalAvatar: '',
        addressId: '',
        hospitalArea: '',
        hospitalOpeningTime: '',
        hospitalBusinessTimeBegin: '',
        hospitalBusinessTimeEnd: '',
        longitude: '', // 经度
        latitude: '' // 纬度
      },
      submitRules: {
        hospitalName: [{ required: true, message: '请输入医院名称', trigger: 'blur' }],
        merchantIds: [{ required: true, message: '请选择所属商家', trigger: 'change' }],
        address: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
        LogAndlat: [
          {
            required: true,
            trigger: ['blur', 'change'],
            validator: (rule, value, callback) => {
              if (!this.submitForm.longitude) {
                return callback(new Error('请输入经度'))
              } else {
                if (this.submitForm.longitude < -180 || this.submitForm.longitude > 180) {
                  return callback(new Error('经度超过±180，请输入正确值'))
                }
              }
              if (!this.submitForm.latitude) {
                return callback(new Error('请输入纬度'))
              } else {
                if (this.submitForm.latitude < -90 || this.submitForm.latitude > 90) {
                  return callback(new Error('纬度超过±90，请输入正确值'))
                }
              }
              return callback()
            }
          }
        ],
        hospitalIntroduction: [{ required: true, message: '请输入介绍', trigger: 'blur' }],
        hospitalAvatar: [{ required: true, message: '请选择医院图片', trigger: 'blur' }],
        addressId: [{ required: true, message: '请选择所在地址', trigger: 'change' }]
      },
      businessHours: ['00:00', '23:59'], // 营业时间
      isAdd: true, // 是否为添加
      editRow: {},
      merchantList: [], // 商人列表
      hospitalInfo: {}, // 医院信息
      fileList: [], // 医院图片

      // ==================== 商家详情 ====================
      dialogVisible_2: false, // 详情弹窗
      shopsMessenger: '',
      submitForm_2: {
        merchantName: '',
        merchantCode: '',
        merchantServiceCoverage: '',
        agencyId: '',
        merchantScaleCode: '',
        merchantEstablishedTime: '',
        merchantWebsite: '',
        merchantContactsName: '',
        merchantContactsMobilePhone: '',
        merchantContactsWechat: '',
        merchantContactsTelephone: '',
        merchantContactsEmail: ''
      },
      scaleList: [
        { name: '10人以下', id: '1' },
        { name: '10-50人', id: '2' },
        { name: '50-100人', id: '3' },
        { name: '100-300人', id: '4' },
        { name: '300-500人', id: '5' },
        { name: '500-1000人', id: '6' },
        { name: '1000人以上', id: '7' }
      ], // 规模

      // ==================== 销售详情 ====================
      sellTitle: '', // 当前商家名称
      dialogVisible_3: false,
      inquire_2: {
        orderCode: ''
      },
      tableData_2: [],
      totalAmount: 0,
      loading_2: false,
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },

      // ==================== 配送地址 ====================
      dialogVisible_4: false,
      defaultProps: {
        children: 'childes',
        label: 'addressName',
        isLeaf: 'leaf'
      },
      currentHospitalId: '',
      currentTreeNode: '',
      currentTreeResolve: '',

      // ==================== 商家管理 ====================
      dialogVisible_5: false,
      pageParam_5: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      businessTableList: [],
      loading_5: false,
      // 添加商家
      merchantSelectKey: 'merchantName',
      merchantSelectValue: '',
      dialogVisible_6: false,
      pageParam_6: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      noBusinessTableList: [],
      selectList: [],
      shopsList: [], // 所有已绑定商家
      // 商家档案
      shopsDetailsDialog: false,
      // 产品列表
      productTableDialog: false,

      // ==================== 批量导入 ====================
      dialogVisible_import: false,
      loadingInstance: null,
      importStatus: 0,

      // 导出
      showExportDialog: false,
      showSalesRecordsDialog: false // 销售记录导出弹窗
    }
  },
  created() {
    this.userInfo = getSessionStorage('userInfo')

    // 获取省市区
    // this.getAddress();
    // 获取产品列表
    this.getTableList()
    // 获取商家列表
    this.getMerchantList()
  },
  methods: {
    openHospital() {
      // 获取省市区
      this.getAddress()
    },
    openInfo() {
      // 获取区域级联数据
      this.getArea()
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    // 获取省市区
    getAddress() {
      this.$http.requestGet({ url: '/common/address', loading: false }).then(res => {
        console.log(res.data, '行政区')
        this.addressList_add = res.data
      })
    },
    // 获取商人列表
    getMerchantList() {
      this.$http.requestGet({ url: '/customer/merchant/getHospitalMerchantList', loading: false }).then(res => {
        const list = res.data
        for (let i = 0, len = list.length; i < len; i++) {
          list[i].disabled = false
        }
        this.merchantList = list
      })
    },
    // 获取区域级联数据
    getArea() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data
      })
    },

    // 添加医院-添加图片文件
    uploadSuccess(res, file, fileList) {
      this.submitForm.hospitalAvatar = res.data
    },
    // 添加医院-删除图片文件
    removeFile_1(file, fileList) {
      this.submitForm.hospitalAvatar = ''
    },

    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = []
      this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : ''

      if (!this.inquire.agencyId) this.inquire.agencyId = this.userInfo.areaId
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire))
      }
      data.param[this.selectKey] = this.selectValue

      this.loading = true
      this.$http
        .requestPost({
          url: '/hospital/medical/page',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },

    // ==================== 添加医院 start ====================
    // 输入查询
    querySearch(queryString, callback) {
      if (!this.submitForm.addressId) {
        callback([])
        this.submitForm.hospitalName = ''
        return this.$message({ message: '请先选择所属地址', type: 'warning' })
      }
      const city = this.submitForm.addressId
      console.log(city, queryString)

      // 天地图
      const TKey = 'd7bdbf82606f9e8deb19b970e100d8a0' // 天地图key
      const param = encodeURI(
        JSON.stringify({
          keyWord: queryString, // 关键词
          queryType: 12, // 搜索类型，12行政区划区域搜索
          start: 0,
          count: 99,
          dataTypes: '170101,170102,170105', // 综合医院,专科医院,急诊
          specify: '156' + city, // 行政区划编码，156代表中国，天地图需要9位国标码
          show: 2 // 1返回基本信息，2返回详细信息
        })
      )
      this.$http
        .requestGet({
          url: 'https://api.tianditu.gov.cn/v2/search?postStr=' + param + '&type=query&tk=' + TKey,
          loading: false
        })
        .then(res => {
          if (res.status) {
            console.log(res, '天地图获取错误')
          } else {
            console.log(res, '天地图获取')
            const dataList = []
            for (const resKey in res) {
              if (res[resKey].pois !== undefined) {
                dataList.push(...res[resKey].pois)
              }
            }
            const arrList = [...dataList]
            arrList.forEach((item, index) => {
              if (queryString.includes(item.name)) {
                const data = dataList[index]
                dataList.splice(index, 1)
                dataList.unshift(data)
              }
            })
            console.log(dataList, 'dataList')
            callback(dataList)
            // console.log(dataList, '天地图数据处理')
          }
        })
        .catch(err => {
          console.log(err, '天地图获取失败')
        })

      // 高德api限制参数
      // const type = "&type=090000|090100|090101|090102|090200|090201|090202|090203|090204|090205|090206|090207|090208|090209|090210|090211|090300|090400|090500|090600|090601|090602|090700|090701|090702&datatype=poi&citylimit=true";
      // const type = '&datatype=poi&citylimit=true'
      // // 调用高德地图输入地址查询api
      // this.$http
      //     .requestGet({
      //       url:
      //           'https://restapi.amap.com/v3/assistant/inputtips?keywords=' +
      //           queryString +
      //           '&key=' +
      //           this.$api.GD_Key +
      //           '&city=' +
      //           city +
      //           type,
      //       loading: false
      //     })
      //     .then(res => {
      //       if (res.status === '1') {
      //         // 筛选医院类型
      //         // console.log(res,'高德地图')
      //         const tipsList = res.tips.filter(val => {
      //           return this.inputtipsTypeList.includes(val.typecode)
      //         })
      //
      //         callback(tipsList)
      //       } else {
      //         callback([])
      //       }
      //     })
      //     .catch(err => {
      //       callback([])
      //     })
    },
    // 选择医院
    handleSelect(item) {
      console.log(item.countyCode.slice(3), item, item.lonlat, '选择的医院')
      const countyCode = item.countyCode.slice(3)
      if (countyCode !== this.submitForm.addressId) {
        this.submitForm.addressId = countyCode
      }
      let lonlat = item.lonlat.split(',')
      // eslint-disable-next-line no-unused-vars
      const x_PI = (3.14159265358979324 * 3000.0) / 180.0
      const PI = 3.14159265358979324
      const a = 6378245.0
      const ee = 0.00669342162296594323

      /**
       * 判断是否在国内，不在国内则不做偏移
       * @param {*} lng
       * @param {*} lat
       */
      const outOfChina = (lng, lat) => {
        return lng < 72.004 || lng > 137.8347 || (lat < 0.8293 || lat > 55.8271 || false)
      }

      /**
       * 经度转换
       * @param { Number } lng
       * @param { Number } lat
       */
      function transformlat(lng, lat) {
        var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng))
        ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
        ret += ((20.0 * Math.sin(lat * PI) + 40.0 * Math.sin((lat / 3.0) * PI)) * 2.0) / 3.0
        ret += ((160.0 * Math.sin((lat / 12.0) * PI) + 320 * Math.sin((lat * PI) / 30.0)) * 2.0) / 3.0
        return ret
      }

      /**
       * 纬度转换
       * @param { Number } lng
       * @param { Number } lat
       */
      function transformlng(lng, lat) {
        var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng))
        ret += ((20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0) / 3.0
        ret += ((20.0 * Math.sin(lng * PI) + 40.0 * Math.sin((lng / 3.0) * PI)) * 2.0) / 3.0
        ret += ((150.0 * Math.sin((lng / 12.0) * PI) + 300.0 * Math.sin((lng / 30.0) * PI)) * 2.0) / 3.0
        return ret
      }

      /**
       * WGS84坐标系转火星坐标系GCj02 / 即WGS84 转谷歌、高德
       * @param { Number } lng:需要转换的经纬
       * @param { Number } lat:需要转换的纬度
       * @return { Array } result: 转换后的经纬度数组
       */
      const wgs84togcj02 = (lng, lat) => {
        if (outOfChina(lng, lat)) {
          return [lng, lat]
        } else {
          var dlat = transformlat(lng - 105.0, lat - 35.0)
          var dlng = transformlng(lng - 105.0, lat - 35.0)
          var radlat = (lat / 180.0) * PI
          var magic = Math.sin(radlat)
          magic = 1 - ee * magic * magic
          var sqrtmagic = Math.sqrt(magic)
          dlat = (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI)
          dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI)
          const mglat = lat + dlat
          const mglng = lng + dlng
          return [mglng, mglat]
        }
      }

      // console.log(lonlat, 'lonlat')
      lonlat = wgs84togcj02(Number(lonlat[0]), Number(lonlat[1]))
      this.submitForm.longitude = lonlat[0]
      this.submitForm.latitude = lonlat[1]

      // console.log(lonlat, 'lonlat123')
      const result = {
        adcode: countyCode,
        address: item.address,
        location: lonlat,
        name: item.name,
        typeCode: item.typeCode
      }
      console.log(result)
      this.hospitalInfo = result
    },
    // 选择商家
    changeMerchant(e) {
      if (e.length < 1) return
      let ids = [] // 已选择商家产品列表
      const list = this.merchantList // 商家列表

      // 获取已选商家所有产品id数组
      for (let i = 0, len = e.length; i < len; i++) {
        const obj = list.find(item => item.merchantId === e[i])
        ids = [...ids, ...obj.productList]
      }

      // 遍历商家列表
      for (let i = 0, len = list.length; i < len; i++) {
        // 跳过已选择的商家
        if (e.indexOf(list[i].merchantId) !== -1) {
          list[i].disabled = false
          continue
        }

        // 遍历未选择的商家产品列表
        let duplication = false // 是否重复
        for (let l = 0, PLen = list[i].productList.length; l < PLen; l++) {
          // 判断当前商家是否与已选择商家有重复产品
          if (ids.indexOf(list[i].productList[l]) !== -1) {
            duplication = true
            break
          }
        }
        // 当有重复商品时，该商家不可选
        if (duplication && !list[i].disabled) {
          list[i].disabled = true
        } else if (!duplication && list[i].disabled) {
          list[i].disabled = false
        }
      }
    },
    // 添加产品类别
    handleAdd() {
      this.dialogTitle = '添加医院'
      this.dialogVisible_1 = true
      this.isAdd = true
      const _this = this
      setTimeout(() => {
        _this.submitForm = {
          hospitalCode: '',
          hospitalName: '',
          merchantId: '',
          hospitalIntroduction: '',
          hospitalAvatar: '',
          hospitalArea: '',
          hospitalOpeningTime: '',
          hospitalBusinessTimeBegin: '',
          hospitalBusinessTimeEnd: ''
        }
        _this.businessHours = ['00:00', '23:59']
        _this.fileList = []
        _this.$refs.submitForm && _this.$refs.submitForm.resetFields()
      }, 0)
    },
    // 修改类别
    handleEdit(row) {
      this.dialogTitle = '编辑医院'
      this.isAdd = false
      this.$http
        .requestGet({
          url: '/hospital/medical/get/' + row.hospitalId,
          loading: false
        })
        .then(res => {
          const data = res.data
          this.submitForm = {
            hospitalCode: data.hospitalCode,
            hospitalName: data.hospitalName,
            merchantId: data.merchantId,
            address: data.address,
            hospitalIntroduction: data.hospitalIntroduction,
            hospitalAvatar: data.hospitalAvatar,
            addressId: data.addressId,
            hospitalArea: data.hospitalArea,
            hospitalOpeningTime: data.hospitalOpeningTime,
            hospitalBusinessTimeBegin: data.hospitalBusinessTimeBegin,
            hospitalBusinessTimeEnd: data.hospitalBusinessTimeEnd,
            longitude: data.longitude, // 经度
            latitude: data.latitude // 纬度
          }
          this.businessHours = []
          if (data.hospitalBusinessTimeBegin) {
            this.businessHours.push(data.hospitalBusinessTimeBegin.substring(0, 5))
            this.businessHours.push(data.hospitalBusinessTimeEnd.substring(0, 5))
          }
          if (data.hospitalAvatar) {
            const imgArr = data.hospitalAvatar.split('/')
            this.fileList = [{ name: imgArr[imgArr.length - 1], url: data.hospitalAvatar }]
          }
          this.editRow = data
          this.dialogVisible_1 = true
        })
    },
    // 提交医院添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = JSON.parse(JSON.stringify(this.submitForm))

          // param.hospitalAvatar = '1231234124214124.png';
          if (this.submitForm.hospitalAvatar.response) {
            param.hospitalAvatar = this.submitForm.hospitalAvatar.response.data
          }
          if (this.hospitalInfo.location) {
            param.longitude = Number(this.hospitalInfo.location[0].toFixed(6))
            param.latitude = Number(this.hospitalInfo.location[1].toFixed(6))
          }
          if (this.businessHours.length > 0) {
            param.hospitalBusinessTimeBegin = this.businessHours[0]
            param.hospitalBusinessTimeEnd = this.businessHours[1]
          }
          param.hospitalCode = undefined
          if (!this.isAdd) {
            param.hospitalId = this.editRow.hospitalId
            if (!param.longitude && !param.latitude) {
              param.longitude = this.editRow.longitude
              param.latitude = this.editRow.latitude
            }
          }
          this.submit(param)
        } else {
          return false
        }
      })
    },
    // 提交
    submit(param) {
      this.$http
        .requestPost({
          url: '/hospital/medical/save',
          param: param
        })
        .then(res => {
          this.getTableList()
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.closeDialog_1()
        })
    },

    // 关闭弹窗
    closeDialog_1() {
      this.dialogVisible_1 = false
      this.submitForm = {
        hospitalCode: '',
        hospitalName: '',
        merchantId: '',
        hospitalIntroduction: '',
        hospitalAvatar: '',
        hospitalArea: '',
        hospitalOpeningTime: '',
        hospitalBusinessTimeBegin: '',
        hospitalBusinessTimeEnd: ''
      }
      this.businessHours = ['00:00', '23:59']
      this.fileList = []
      this.$refs.submitForm && this.$refs.submitForm.resetFields()
    },

    // ==================== 所属商家详情 start ====================
    // 商家详情
    handleDetails(row) {
      this.$http.requestGet({ url: '/customer/merchant/get/' + row.merchantId }).then(res => {
        this.submitForm_2 = res.data
        this.shopsMessenger = [
          {
            messengerId: res.data.messengerId,
            messengerName: res.data.messengerName,
            messengerPhoneNumber: res.data.messengerPhoneNumber
          }
        ]
        this.dialogVisible_2 = true
      })
    },

    // ==================== 商家销售列表 start ====================
    // 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1
      this.pageParam_2.pageSize = size
      this.getSellList()
    },
    // 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page
      this.getSellList()
    },

    // 打开销售记录弹窗
    openSellDialog(row) {
      this.editRow = row
      this.getSellList()
      this.$nextTick(() => {
        this.sellTitle = row.hospitalName
        this.dialogVisible_3 = true
      })
    },

    // 获取销售列表
    getSellList() {
      const data = {
        pageNum: this.pageParam_2.pageNum,
        pageSize: this.pageParam_2.pageSize,
        param: {
          hospitalId: this.editRow.hospitalId,
          orderCode: this.inquire_2.orderCode
        }
      }

      this.loading_2 = true
      this.$http
        .requestPost({
          url: '/hospital/medical/getSalesRecords',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading_2 = false
          this.tableData_2 = res.data.list
          this.pageParam_2.total = res.data.total
          this.totalAmount = this.tableData_2.reduce((total, item) => total + item.brokerageAmount, 0)
        })
        .catch(err => {
          this.loading_2 = false
          console.log(err)
        })
    },

    // 跳转订单详情
    toDetails(row) {
      let path = ''
      let index = 0
      switch (row.orderClassify) {
        case 'electronic':
          path = '/order/commerce/particulars'
          index = this.$api.EcommerceOrderState.findIndex(item => item.value === row.orderStatus)
          break
        case 'medicine':
          path = '/order/medical/hospitalDelivery/particulars'
          index = this.$api.orderState.findIndex(item => item.value === row.orderStatus) + 1
          break
        case 'lease':
          path = '/order/lease/particulars'
          index = this.$api.leaseOrderState.findIndex(item => item.value === row.orderStatus)
          break
        default:
          return
      }
      this.$router.push({
        path,
        query: {
          type: index,
          id: row.orderId
        }
      })
    },

    // ==================== 配送地址 ====================
    handleShippingAddress(row) {
      this.dialogTitle = row.hospitalName + ' - 配送地址'
      this.editRow = row

      if (row.hospitalId !== this.currentHospitalId && this.currentHospitalId !== '') {
        this.removeTreeNode(row)
      }
      this.dialogVisible_4 = true
    },

    // 获取配送地址数据
    removeTreeNode(row) {
      this.currentTreeNode.childNodes = []
      this.loadNode(this.currentTreeNode, this.currentTreeResolve)
    },

    // 懒加载医院配送地址
    loadNode(node, resolve) {
      if (node.level === 0) {
        this.currentTreeNode = node
        this.currentTreeResolve = resolve
        this.currentHospitalId = this.editRow.hospitalId
        this.$http
          .requestGet({
            url: '/hospital/shipping/address/getTheFirstLevelAddresses/' + this.editRow.hospitalId
          })
          .then(res => {
            const data = res.data
            return resolve(data)
          })
      } else if (node.level > 0) {
        this.$http
          .requestGet({
            url: '/hospital/shipping/address/selectTheOtherLevelAddresses/' + node.data.shippingId
          })
          .then(res => {
            const data = res.data
            data.map(e => {
              e.shippingParentId = node.data.shippingId
              if (e.attacheName) {
                e.addressName += ' (' + e.attacheName + ' - ' + e.attacheCode + ')'
                e.leaf = true
              }
            })
            resolve(data)
          })
      }
    },

    // 递归设置最底层拼接 专员名称 + 专员编号
    handleTreeData(arr) {
      arr.map(e => {
        if (e.attacheName) {
          e.address += ' (' + e.attacheName + ' - ' + e.attacheCode + ')'
        }
        if (e.childes.length > 0) {
          this.handleTreeData(e.childes)
        }
      })
    },

    // ==================== 商家管理 ====================
    merchantManage(row) {
      this.dialogVisible_5 = true
      this.editRow = row
      this.getMerchantTableList()
    },
    // 页容量改变
    handleSizeChange_5(size) {
      this.pageParam_5.pageNum = 1
      this.pageParam_5.pageSize = size
      this.getMerchantTableList()
    },
    // 页码改变
    handleCurrentChange_5(page) {
      this.pageParam_5.pageNum = page
      this.getMerchantTableList()
    },
    getMerchantTableList() {
      const param = {
        pageNum: this.pageParam_5.pageNum,
        pageSize: this.pageParam_5.pageSize,
        param: {
          hospitalId: this.editRow.hospitalId
        }
      }
      this.loading_5 = true
      this.$http
        .requestPost({
          url: '/customer/merchant/getHasLinkedMerchantListByHospitalId',
          param,
          loading: false
        })
        .then(res => {
          this.businessTableList = res.data.list
          this.pageParam_5.total = res.data.total
          this.loading_5 = false
        })
        .catch(() => (this.loading_5 = false))
    },

    // 添加商家
    async addMerchant() {
      const { data } = await this.getShopsList()
      this.shopsList = data.list
      this.dialogVisible_6 = true
      this.merchantSearchList()
    },
    merchantSearchList() {
      this.pageParam_6.pageNum = 1
      this.getNoMerchantTableList()
    },
    // 页容量改变
    handleSizeChange_6(size) {
      this.pageParam_6.pageNum = 1
      this.pageParam_6.pageSize = size
      this.getNoMerchantTableList()
    },
    // 页码改变
    handleCurrentChange_6(page) {
      this.pageParam_6.pageNum = page
      this.getNoMerchantTableList()
    },
    // 获取未绑定是商家列表
    getNoMerchantTableList() {
      const data = {
        pageNum: this.pageParam_6.pageNum,
        pageSize: this.pageParam_6.pageSize,
        param: {
          hospitalId: this.editRow.hospitalId
        }
      }
      if (this.merchantSelectValue) data.param[this.merchantSelectKey] = this.merchantSelectValue

      this.loading_5 = true
      this.$http
        .requestPost({
          url: '/customer/merchant/getUnLinkedMerchantListByHospitalId',
          param: data,
          loading: false
        })
        .then(res => {
          const noList = res.data.list
          let ids = [] // 已选择商家产品列表
          const list = JSON.parse(JSON.stringify(this.shopsList)) // 已绑定商家列表

          // 获取已选商家所有产品id数组
          for (let i = 0, len = list.length; i < len; i++) {
            ids = [...ids, ...list[i].productList]
          }

          // 遍历未绑定商家产品列表
          // for (let i = 0, len = noList.length; i < len; i++) {
          noList.map(item => {
            item.select = true
            if (item.productList.length > 0) {
              for (let l = 0, lens = item.productList.length; l < lens; l++) {
                if (ids.indexOf(item.productList[l]) !== -1) {
                  item.select = false
                  break
                }
              }
            }
          })
          // }
          this.noBusinessTableList = noList
          this.pageParam_6.total = res.data.total
          this.loading_5 = false
        })
        .catch(() => (this.loading_5 = false))
    },
    // table多选
    currentChange(val) {
      let ids = [] // 已选择商家产品列表
      const merchantIds = []
      const list = JSON.parse(JSON.stringify(this.shopsList)) // 已绑定商家列表

      // 获取已选商家所有产品id数组
      for (let i = 0, len = list.length; i < len; i++) {
        ids = [...ids, ...list[i].productList]
      }
      // 获取当前选择的商家产品id
      for (let i = 0, len = val.length; i < len; i++) {
        ids = [...ids, ...val[i].productList]
        merchantIds.push(val[i].merchantId)
      }

      // 遍历未绑定商家产品列表
      this.noBusinessTableList.map(item => {
        item.select = true
        // 商家产品 > 0 && 当前商家非选择商家
        if (item.productList.length > 0 && merchantIds.indexOf(item.merchantId) == -1) {
          item.select = true
          for (let l = 0, lens = item.productList.length; l < lens; l++) {
            if (ids.indexOf(item.productList[l]) !== -1) {
              item.select = false
              break
            }
          }
        }
      })
      this.selectList = val
    },
    // 确定选择
    confirm() {
      if (this.selectList.length < 1) {
        return this.$message.warning('请选择商家')
      }
      const ids = []
      this.selectList.forEach(v => {
        ids.push(v.merchantId)
      })
      this.$http
        .requestPut({
          url: '/customer/merchant/saveHospitalMerchant/' + this.editRow.hospitalId + '/' + ids.join()
        })
        .then(res => {
          this.merchantSelectValue = ''
          this.dialogVisible_6 = false
          this.$message.success('操作成功')
          this.getMerchantTableList()
          this.searchList()
        })
    },
    // 获取所有已绑定商家
    getShopsList() {
      const param = {
        pageNum: 1,
        pageSize: 1000000,
        param: {
          hospitalId: this.editRow.hospitalId
        }
      }
      return this.$http.requestPost({
        url: '/customer/merchant/getHasLinkedMerchantListByHospitalId',
        param,
        loading: false
      })
    },

    // 未绑定商家是否可选处理
    selectable(row, index) {
      // console.log(row.select);
      return row.select
    },

    // 商家档案
    merchantInfo(row) {
      this.shopsDetailsDialog = true
      this.dialogTitle = row.merchantName + ' - 商家档案'
      this.$refs.shopsDetails.getDetails(row.merchantId)
    },
    // 产品列表
    openProductList(row) {
      this.dialogTitle = row.merchantName + ' - 产品列表'
      this.productTableDialog = true
      this.$refs.productTable.initData(row.merchantId)
    },
    // 删除绑定商家
    delMerchant(row) {
      this.$confirm('确定删除该商家?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .requestPut({
              url: '/customer/merchant/delHospitalMerchant/' + row.hospitalLinkMerchantId
            })
            .then(res => {
              this.getMerchantTableList()
              this.searchList()
              this.$message({
                message: '操作成功',
                type: 'success'
              })
            })
        })
        .catch(() => {})
    },

    // ==================== 导入导出 ====================
    // 导入
    importFile(response, file, fileList) {
      if (response.code === 200) {
        this.importStatus = 1
        this.$message.success('上传成功！')
      }
      this.fileList = []
    },
    uploadError(response, file, fileList) {
      if (response.code !== 200) {
        this.importStatus = 2
        this.$message.error('上传失败')
      }
      this.fileList = []
    },
    // 导入之前
    beforeUpload() {
      this.importStatus = 3
    },
    // 下载导入模板
    downloadTemplate() {
      window.location.href = this.$http.domain + '/download/hospitalTemplate.xls'
    },
    // 导出医院
    exportHospital() {
      this.showExportDialog = true
      this.$refs.isProductExport.open()
    },
    // 导出
    exportFile() {
      this.showExportDialog = true
      this.$refs.isProductExport.open()
    },
    handleStop(row) {
      this.$confirm('是否确定停用该医院?', '停用医院确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .requestGet({ url: `/hospital/medical/isEnable?hospitalId=${row.hospitalId}&hospitalName=${row.hospitalName}&isEnable=0` })
            .then(res => {
              if (res.code === 200) {
                this.searchList()
                return this.$message.success('操作成功！')
              }
            })
        })
        .catch(() => {})
    },
    // 导出销售记录
    exportSalesRecords(data) {
      // console.log('data===>>>>', data, this.editRow)
      const { productNames } = data
      const paramData = {
        hospitalId: this.editRow.hospitalId, // 医院id
        orderCode: '', // 订单编号
        orderBeginTime: data.orderTimeBegin, // 下单开始时间
        orderEndTime: data.orderTimeEnd, // 下单结束时间
        shipBeginTime: data.shipTimeBegin, // 发货开始时间
        shipEndTime: data.shipTimeEnd, // 发货结束时间
        productNames // 产品名称集合
      }
      const requestUrl = '/hospital/medical/exportSalesRecords'
      const fileName = `销售记录导出_${new Date().getTime()}.xls`
      this.$http.download({
        url: requestUrl,
        param: paramData,
        method: 'POST'
      }).then(res => {
        if (res) {
          this.$public.downloadFile(res, fileName)
        }
      }).catch(err => {
        // this.$message.error(err)
        console.error('导出失败：', err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Popout_content {
  .flex-box .demo-form-inline {
    flex: 1;
  }

  .total-money {
    min-width: 200px;
    font-size: 18px;
    line-height: 2;
    text-align: right;

    span {
      color: #fd6040;
    }
  }
}

.hospital-name {
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.inline-input {
  width: 100%;
}
</style>
