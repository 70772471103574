<template>
    <div class="login-container">
        <div class="login-new">
        <el-form ref="loginForm" class="form" :model="loginForm" :rules="loginRules" auto-complete="on" label-position="left">
        <div class="title">
            638医品平台数据监控大屏
        </div>
        <div class="bottom">
  <!-- 四个角的边框效果 -->
    <div class="border_corner border_corner_left_top"></div>
    <div class="border_corner border_corner_right_top"></div>
    <div class="border_corner border_corner_left_bottom"></div>
    <div class="border_corner border_corner_right_bottom"></div>
    <div class="content-box">
    <div class="content-title">
                用户登录
             </div>
             <el-form-item prop="managerPhoneNumber">
          <div class="img"> <img alt="" src="../../icons/lgperson.png" /></div>
          <el-input ref="managerPhoneNumber" v-model="loginForm.managerPhoneNumber" name="managerPhoneNumber" placeholder="请输入手机号" maxlength="11" tabindex="1" type="text" />
        </el-form-item>

        <el-form-item prop="password">
         <div class="img"> <img alt="" src="../../icons/lglock.png" /></div>
          <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType"  name="password" placeholder="请输入密码" tabindex="2" />
        </el-form-item>
        <el-form-item prop="selected">
            <el-select v-model="loginForm.selected" placeholder="请选择分辨率" @change="change">
<!--                <el-option label="1920*1080" value="1"></el-option>-->
                <el-option label="3840*1080" value="2"></el-option>
<!--                <el-option label="1450*535" value="3"></el-option>-->
                <el-option label="1920*1080自适应" value="4"></el-option>
           </el-select>
        </el-form-item>
        <el-button :loading="loading" style="width: 100%; margin-bottom: 0.6rem;color:#000;background:#00fefe; margin-top:2rem;"  type="primary" @click.native.prevent="handleLogin">登录 </el-button>
      </div>
   </div>
      </el-form>
    </div>
    </div>
</template>

<script>
import {setSessionStorage} from '@/utils/auth.js'

export default {
    name: "Login",
    data() {
        return {
            multiScreen: [], // 多屏数据存储
            loginForm: {
                managerPhoneNumber: "",
                password: "",
                selected:'',
            },
            loginRules: {
                managerPhoneNumber: [
                    {required: true, message: "请输入手机号", trigger: "blur"},
                    {pattern:/^1[3456789]\d{9}$/, message: '手机格式错误', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: "密码不能少于6位", trigger: "blur"},
                    {min: 6, max: 16, message: "长度在 6 到 16 个字符", trigger: "blur"}
                ],
                selected:[
                    {required: true, message: "请选择分辨率", trigger: "blur"},
                ]
            },
            loading: false,
            passwordType: "password",
            redirect: undefined,
        };
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect;
            },
            immediate: true,
        },
    },
    mounted(){
        this.getScreensData();
    },
    methods: {
         // 获取当前电脑屏幕信息
    getScreensData() {
      if (!window.getScreens) {
        // 处理浏览器不兼容情况
      } else {
        let getScreensProsime = window.getScreens();
        getScreensProsime.then((c) => {
          if(c instanceof Array){
            this.multiScreen = c;
          }else if(c instanceof Object){
            this.multiScreen = c.screens;
          }else{
            this.multiScreen = [];
          }
        });
      }
      if(!window.getScreenDetails){
        // 处理浏览器不兼容情况
      }else{
        let getScreensProsime1 = window.getScreenDetails();
        getScreensProsime1.then((c) => {
          if(c instanceof Array){
            this.multiScreen = c;
          }else if(c instanceof Object){
            this.multiScreen = c.screens;
          }else{
            this.multiScreen = [];
          }
        });
      }
    },
    // 开启新页面
    openNewWindow(url, name) {
      // 判断浏览器是否兼容
      // 高版本的谷歌，edge不支持跨屏，需要降低浏览器版本86.0版
      if(!window.getScreens && !window.getScreenDetails){
        message.warning('你的浏览器版本不支持多屏展示功能！', 3);
        let openWindow = window.open(url, name);
        return;
      }
      // 判断是否2个屏幕
      if (this.multiScreen.length < 2) {
        message.warning('请接入多个显示屏！', 3);
        let openWindow = window.open(url, name);
        return;
      }
      // 获取当前屏幕availLeft信息和所有信息比对，取另一个屏幕数据
      let currentAvailLeft = screen.availLeft?screen.availLeft:'0';
      let newCurr = this.multiScreen.find((t) => t.availLeft !== currentAvailLeft);
      let fulls = '';
      for(let key in newCurr){
        fulls += `${key}=${(newCurr[key] || newCurr[key] === 0) ? newCurr[key] : 0},`;
      }
      let openWindow = window.open(url, name, fulls);
    },
    // 谷歌跳转
    openChomOA(flod_id, name) {
      let url = `www.baidu.com`;
      this.openNewWindow(url, '');
    },
        change(e){
          this.loginForm.selected = e
        },
        showPwd() {
            if (this.passwordType === "password") {
                this.passwordType = "";
            } else {
                this.passwordType = "password";
            }
            this.$nextTick(() => {
                this.$refs.password.focus();
            });
        },
        handleLogin() {
            if(!window.navigator.onLine){
                this.$message({message: '当前网络异常，请检查网络设置', type: 'warning'})
                return
            }
            let Base64 = require('js-base64').Base64;
            let password = Base64.encode(this.loginForm.password)
            this.$refs.loginForm.validate((valid) => {
                console.log('valid',valid);
                if (valid) {
                    this.loading = true;
                    this.$http.requestPost({
                        url: '/login/largeScreenLogin',
                        param: {
                            managerPhoneNumber:this.loginForm.managerPhoneNumber,
                            password:password
                        },
                        loading: false
                    }).then(res => {
                        setSessionStorage('userToken', res.data.token)
                        setSessionStorage('userInfo', res.data)
                        // this.getMenuTree(res.data)
                        if(this.loginForm.selected == 1){
                            // 跳转至1920*1080
                            this.$router.push({
                                path:'/smallProportion',
                                query: {
                                  areaId:res.data.areaId,
                                  managerType: res.data.managerType,
                                  regionName:res.data.regionName
                                }
                            })
                        }else if (this.loginForm.selected == 2){
                            // 跳转至3840*1080
                            this.$router.push({
                                path:'/largeProportion',
                                query: {
                                    areaId:res.data.areaId,
                                    managerType: res.data.managerType,
                                    regionName:res.data.regionName
                                }
                            })
                        }
                        else if (this.loginForm.selected == 3){
                            // 跳转至1450*535
                            this.$router.push({
                                path:'/middleProportion',
                                query: {
                                    areaId:res.data.areaId,
                                    managerType: res.data.managerType,
                                    regionName:res.data.regionName
                                }
                            })
                        }
                        else if (this.loginForm.selected == 4){
                            // 跳转至1450*535
                            this.$router.push({
                                path:'/flexProportion',
                                query: {
                                    areaId:res.data.areaId,
                                    managerType: res.data.managerType,
                                    regionName:res.data.regionName
                                }
                            })
                        }
                    }).catch(err => {
                        this.loading = false;
                    })

                } else {
                    console.log("error submit!!");
                    return false;
                }
            });

        },

        // 获取用户菜单
        getMenuTree(data) {
            this.$http.requestGet({ url:'/login/getMenuTree/' + data.managerId, loading:false }).then(res => {
                this.routes = res.data
                this.$store.commit('user/SET_MENU_LIST', res.data)
                this.loading = false;
                
                let url = '';
                if (this.routes.length < 1) return this.$message.warning('该用户没有权限，请先去设置权限!') 
                url = this.getUrl(this.routes[0].children, this.routes[0].path)
                this.$router.push(url);
            })
        },


        getUrl(arr, str) {
            if (arr.length == 0) return ''
            str += '/' + arr[0].path
            if (arr[0].children.length > 0) {
                return this.getUrl(arr[0].children, str)
            } else {
                return str
            }
        }
    },
};
</script>
<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

// $bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input {
        color: $cursor;
    }
}

/* reset element-ui css */
.login-container {
    .el-input {
        display: inline-block;
        height: 47px;
        width: 85%;

        input {
            background: transparent;
            border: 0px;
            -webkit-appearance: none;
            border-radius: 0px;
            padding: 12px 5px 12px 15px;
            color: $light_gray;
            height: 47px;
            caret-color: $cursor;

            &:-webkit-autofill , textarea:-webkit-autofill, select:-webkit-autofill{
                // box-shadow: 0 0 0px 1000px $bg inset !important;
                -webkit-text-fill-color: $cursor !important;
                background-color:transparent;
                background-image: none;
                transition: background-color 50000s ease-in-out 0s; //背景色透明  生效时长  过渡效果  启用时延迟的时间
            }
        }
    }

    .el-form-item {
        border: 1px solid rgba(3, 92, 158, 0.6) ;
        background:rgba(3, 92, 158, 0.6) ;
        border-radius: 5px;
        color: #fff;
    }
}
</style>
<style lang="scss" scoped>
::v-deep .el-form-item__content{
    display: flex;
    padding-left: 0.5rem;
    border: 1px solid rgba(2, 108, 174,.8);
    background: rgba(2, 108, 174,.8);
}
    .img{
    width: 1.2rem;
    height:1.2rem;
    margin-right: 0.5rem;
    margin-top: 0.2rem;
    img{
        width: 100%;
        height: 100%;
    }
    }
//     /deep/.el-input__inner {
//     background:rgba(3, 92, 158, 0.6) ;
//     border: none !important;
//     color: #fff;
// }
::v-deep .el-select{
        width: 100%;
        .el-input{
          width: 100% !important;
        }
}
.login-new{
    display: flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    background: url(../../icons/lgbg.png) no-repeat;
    background-size: cover;
    .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    }
    .title{
        text-align: center;
        font-size: 50px;
        font-weight: 600;
        color: #00ffff;
        margin-bottom: 2.5rem;
    }
    // 用户登录部分
    .bottom{
    width: 380px;
    position: relative;
    border: 1px solid #00ffff;
    .main{
        width: 100%;
        height: 550px;
        border: 2px solid #00ffff;
        border-radius: 4px;
    }
    .border_corner{
        z-index: 2500;
        position: absolute;
        width: 24px;
        height: 24px;
        background: rgba(0,0,0,0);
        border: 4px solid #00ffff;
    }
    .border_corner_left_top{
        top: -4px;
        left: -4px;
        border-right: none;
        border-bottom: none;
        border-top-left-radius: 4px;
    }
    .border_corner_right_top{
        top: -4px;
        right: -4px;
        border-left: none;
        border-bottom: none;
        border-top-right-radius: 4px;
    }
    .border_corner_left_bottom{
        bottom: -4px;
        left: -4px;
        border-right: none;
        border-top: none;
        border-bottom-left-radius: 4px;
    }
    .border_corner_right_bottom{
        bottom: -4px;
        right: -4px;
        border-left: none;
        border-top: none;
        border-bottom-right-radius: 4px;
    }
  }
  .content-box{
    padding: 2rem;
    background:rgba(3, 50, 120, 0.8);
    .content-title{
        text-align: center;
        color: #00f0e7;
        font-size: 1.2rem;
        margin-bottom: 1.5rem;
    }
}
}


.login-container {
    z-index: 99999999;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}
</style>
