<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item v-if="!thirdPartyPermissions" label="订单类型">
          <div class="flex-box">
            <el-select v-model="inquire.orderClassify" placeholder="请选择" class="width-140" clearable>
              <el-option v-for="(item,index) in $api.orderClassify" :key="index" :label="item.name" :value="item.value"></el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="订单编号/微信昵称">
          <el-input v-model="inquire.orderCode" placeholder="订单编号/微信昵称" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item label="下单时间">
          <el-date-picker v-model="daterange" end-placeholder="结束日期" format="yyyy-MM-dd" start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd" />
        </el-form-item>

        <el-form-item label="">
          <div class="flex-box">
            <el-cascader v-model="categoryId" :props="props" :options="productTypeList" clearable @change="categoryChange" />
            <el-select v-show="productList.length > 0" v-model="inquire.productIds" multiple placeholder="请选择产品" class="width-260">
              <el-option v-for="item in productList" :key="item.productId" :label="item.productName" :value="item.productId" />
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="区域" v-if="!thirdPartyPermissions">
          <el-cascader v-model="inquire.areaIdSelect" :props="props_1" :options="addressList" clearable placeholder="请选择地址" v-if="$public.isAllAreaPower()" />
          <d-area :values.sync="inquire.areaIdSelect" v-else />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="orderCode" label="订单编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="memberNickName" label="微信昵称" min-width="120" show-overflow-tooltip />
            <el-table-column prop="orderClassify" label="订单类型" min-width="120">
              <template slot-scope="scope">
                {{ orderClassifyText(scope.row.orderClassify)}}
              </template>
            </el-table-column>
            <el-table-column prop="orderTotalPrice" label="总金额" min-width="120">
              <template slot-scope="scope">
                {{ '￥' + scope.row.orderTotalPrice.toFixed(2) }}
              </template>
            </el-table-column>
            <el-table-column prop="whetherSelection" label="订单状态" min-width="120">待收货</el-table-column>
            <el-table-column prop="orderTime" label="下单时间" min-width="160" />
            <el-table-column prop="recipientAddressDetail" label="所属区域" min-width="200" show-overflow-tooltip />
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span>
<!--                <span class="operation-btn_partition"> / </span>-->
<!--                <span class="operation-btn" @click="handleOutbound(scope.row)">出库</span> -->
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
          </div>
        </template>
      </el-main>
    </div>

    <!-- 出库弹框 -->
    <el-dialog
      title="出库"
      :visible.sync="dialogVisible"
      width="80%"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form class="demo-form-inline" size="medium">

          <div v-for="(item,index) in detailInfo.items" :key="index">
            <el-form-item label="产品信息">
              <el-table v-loading="loading" :data="[detailInfo.items[index]]" border class="table-box">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="产品信息" width="200" prop="" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div class="flex-box">
                      <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box">
                      <div class="hospital-name">
                        <div class="txt_box" :class="{'flex-box':!scope.row.productImg,'flex-center':!scope.row.productImg}">
                          <img v-if="scope.row.isGifts" src="@/assets/image/gift_icon.png" alt="" class="icon_box" :class="{no_img:!scope.row.productImg}">
                          {{ scope.row.productName }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="单价（￥）" width="120" prop="productPrice">
                  <!-- <template slot-scope="scope">{{ '￥' + scope.row.productPrice }}</template> -->
                </el-table-column>
                <el-table-column label="购买数量" width="120" prop="itemNum" />
                <el-table-column label="退货数量" width="120" prop="returnNum">
                  <template slot-scope="scope">
                    {{ scope.row.returnNum || '0' }}
                  </template>
                </el-table-column>
                <el-table-column label="小计" width="120" prop="">
                  <template slot-scope="scope">
                    {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.productPrice).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column label="实收金额" width="120" prop="">
                  <template slot-scope="scope">
                    {{ '￥' + ((parseInt(scope.row.itemNum) - parseInt(scope.row.returnNum || 0)) * scope.row.productPrice).toFixed(2) }}
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="批次信息">
              <el-table v-loading="loading" :data="detailInfo.items[index].batch" border class="table-box">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="批次" width="150" prop="batchNo" show-overflow-tooltip />
                <el-table-column label="库存" width="120" prop="count" />
                <el-table-column label="生产日期" width="140" prop="produceTime" />
                <el-table-column label="到期日期" width="140" prop="expireTime" />
                <el-table-column label="有效期（天）" width="120" prop="termOfValidity" />
                <el-table-column label="入库时间" width="140" prop="createTime" />
                <el-table-column label="出库数量" width="100" prop="deliverCount" />
              </el-table>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import dArea from '@/components/d-area'
import { getSessionStorage } from "@/utils/auth";

export default {
  components: {
    dArea
  },
  data() {
    return {
      thirdPartyPermissions: false, //是不是第三方权限组
      loading: false,
      // 产品分类
      productTypeList: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        // checkStrictly: true,
        emitPath: true
      },
      categoryId: '',
      productList: [],

      // 查询参数
      inquire: {
        areaIdSelect: '', // 区域
        orderStatus: 'pending_hasStock', // 待发货
        isReturnOrder: false,
        orderCode: '',
        orderTimeBegin: '',
        orderTimeEnd: '',
        isOutboundOrder: true,
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      commentId: '', // 主键id

      // 出库
      dialogVisible: false, // 出库弹窗
      detailInfo: {
        items: []
      },

      // 片区
      addressList: [],
      props_1: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      }

    }
  },
  created() {
    let userInfo = getSessionStorage("userInfo") || {};
    // 判断是不是第三方权限
    this.thirdPartyPermissions = userInfo && userInfo.rightsGroupNames.length == 1 && userInfo.rightsGroupNames.includes('第三方权限组')

    this.getProductType();
    // 获取数据列表
    this.getTableList()

    this.getDistrictIdList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = []
      this.inquire.orderTimeBegin = this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire.orderTimeEnd = this.daterange.length > 0 ? this.daterange[1] : ''
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http.requestPost({
        url: '/customer/order/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.pageParam.total = res.data.total
      }).catch(err => {
        this.loading = false
        console.log('err', err)
      })
    },
    // 获取产品类型
    getProductType() {
      this.$http.requestGet({ url: '/product/category', loading: false }).then(res => {
        this.productTypeList = res.data
      })
    },
    // 产品分类改变
    categoryChange(e) {
      this.productList = []
      this.inquire.productIds = []
      if (e.length > 1) {
        this.getProductList(e[1])
      }
    },
    // 根据分类id获取产品列表
    getProductList(id) {
      this.$http.requestGet({ url: '/customer/order/category/' + id, loading: false }).then(res => {
        this.productList = res.data
      })
    },

    // 查看详情
    handleDetails(row) {
      let path = "";
      switch (row.orderClassify) {
        case "electronic":
          path = "/order/commerce/particulars";
          break;
        case "medicine":
          path = "/order/medical/hospitalDelivery/particulars";
          break;
        case "external":
          path = "/order/ExternalProductOrder/particulars";
          break;
        case "lease":
          path = "/order/lease/particulars";
          break;
        default:
          return;
      }
      this.$router.push({
        path,
        query: {
          id: row.orderId,
        },
      });
    },

    // 出库
    handleOutbound(row) {
      this.dialogVisible = true
      this.$http.requestGet({
        url: '/customer/order/getBatchDetail/' + row.orderId
      }).then(res => {
        const { data } = res

        // 获取有效期数据
        data.items.map(v => {
          v.batch.map(k => {
            const d = new Date(k.expireTime)
            const timeStamp = d - new Date(k.produceTime)
            const termOfValidity = timeStamp / (24 * 60 * 60 * 1000)
            k.termOfValidity = termOfValidity.toFixed(0)
          })
        })
        this.detailInfo = data
      })
    },

    // 获取区域级联列表
    getDistrictIdList() {
        this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
            this.addressList = res.data
        })
    },

    orderClassifyText(text){
      const textObj = {
        electronic: "电商品",
        medicine: "医品",
        external: "经销产品",
      }
      return textObj[text] || "租赁品"
    },

  },
};
</script>

<style lang="scss" scoped>
  .table-box {
    display: initial
  }
  .img-box {
    width: 58px;
    height: 58px;
  }

  .icon_box {
    width: 21px;
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    &.no_img{
      margin-right: 3px;
      position: static;
    }
  }

  .txt_box {
    position: relative;
    line-height: 1;
  }
</style>
