<template>
  <div class="main-box">
    <div class="row_box bg_white">
      <div class="header flex-box">
        <span>平台销售额趋势分析</span>
        <!-- <span class="operation-btn">查看明细</span> -->
      </div>
      <div class="content ">
        <div class="search-row">
          <el-form :inline="true" class="demo-form-inline" size="medium">
            <el-form-item label="时间段">
              <el-select v-model="inquire_1.dayOrMonth" placeholder="请选择" @change="disposeTimeShow(1)">
                <el-option label="按日统计" :value="0" />
                <el-option label="按月统计" :value="1" />
              </el-select>
            </el-form-item>

            <el-form-item label="">
              <el-date-picker
                v-show="inquire_1.dayOrMonth === 0"
                v-model="daterange_1"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />

              <el-date-picker
                v-show="inquire_1.dayOrMonth === 1"
                v-model="daterange_1"
                type="monthrange"
                value-format="yyyy-MM"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
              />
            </el-form-item>

            <el-form-item v-if="userInfo.managerType != 'agency'" label="区域">
              <el-cascader v-if="$public.isAllAreaPower()" v-model="address_1" :options="addressList" :props="props" clearable />
              <d-area v-else ref="area_1" :values.sync="address_1" all-val />
            </el-form-item>

            <el-form-item label="产品类别">
              <el-cascader v-model="categoryId" :props="productProps" :options="productTypeList" clearable @change="categoryChange" />
              <el-select v-show="productList.length > 0" v-model="inquire_1.productId" placeholder="请选择产品" class="width-260" clearable>
                <el-option v-for="item in productList" :key="item.productId" :label="item.productName" :value="item.productId" />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="small" @click="platformSalesTrendAnalysis">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="flex-box">
          <div class="echars-box">
            <div id="sales-platform_box"></div>
          </div>

          <div class="data-statistics">
            <label>数据统计</label>
            <ul>
              <li>销售额：{{ salesPlatformData.totalPrice || '0:00' }}元</li>
              <li>订单数：{{ salesPlatformData.orderCount }}单</li>
              <li>商家数：{{ salesPlatformData.merchant }}家</li>
              <li>下单用户量：{{ salesPlatformData.userCount }}位</li>
              <li>平均送达时长：{{ salesPlatformData.arriveTime || 0 }}/m</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row_box bg_white" >
      <div class="header flex-box">
        <span>平台租赁额趋势分析</span>
      </div>
      <div class="content ">
        <div class="search-row">
          <el-form :inline="true" class="demo-form-inline" size="medium">

            <el-form-item label="时间段">
              <el-select v-model="inquire_2.dayOrMonth" @change="disposeTimeShow(2)" placeholder="请选择">
                <el-option label="按日统计" :value="0"></el-option>
                <el-option label="按月统计" :value="1"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="">
              <el-date-picker
                v-show="inquire_2.dayOrMonth === 0"
                v-model="daterange_2"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>

              <el-date-picker
                v-show="inquire_2.dayOrMonth === 1"
                v-model="daterange_2"
                type="monthrange"
                value-format="yyyy-MM"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份">
              </el-date-picker>
            </el-form-item>

            <el-form-item label="区域" v-if="userInfo.managerType != 'agency' ">
              <el-cascader v-model="address_2" :options="addressList" :props="props" clearable v-if="$public.isAllAreaPower()"></el-cascader>
              <d-area ref="area_2" :values.sync="address_2" allVal v-else />
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="small" @click="platformRentalTrendAnalysis">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="flex-box">
          <div class="echars-box">
            <div id="Leasing-platform_box"></div>
          </div>

          <div class="data-statistics">
            <label >数据统计</label>
            <ul>
              <li>租赁额：{{ platformLeaseData.totalPrice || '0:00' }}元</li>
              <li>订单数：{{ platformLeaseData.orderCount }}单</li>
              <li>商家数：{{ platformLeaseData.merchant }}家</li>
              <li>在租产品数：{{ platformLeaseData.sellingProduct }}种</li>
              <li>下单用户量：{{ platformLeaseData.userCount }}位</li>
              <li>平均送达时长：{{ salesPlatformData.arriveTime || 0 }}/m</li>
            </ul>
          </div>
        </div>

      </div>
    </div> -->

    <div class="row_box bg_white">
      <div class="header flex-box">
        <span>平台用户趋势图</span>
        <!-- <span class="operation-btn">查看明细</span> -->
      </div>
      <div class="content ">
        <div class="search-row">
          <el-form :inline="true" class="demo-form-inline" size="medium">
            <el-form-item label="时间段">
              <el-select v-model="inquire_3.dayOrMonth" placeholder="请选择" @change="disposeTimeShow(3)">
                <el-option label="按日统计" :value="0" />
                <el-option label="按月统计" :value="1" />
              </el-select>
            </el-form-item>

            <el-form-item label="">
              <el-date-picker
                v-show="inquire_3.dayOrMonth === 0"
                v-model="daterange_3"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />

              <el-date-picker
                v-show="inquire_3.dayOrMonth === 1"
                v-model="daterange_3"
                type="monthrange"
                value-format="yyyy-MM"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
              />
            </el-form-item>

            <el-form-item v-if="userInfo.managerType != 'agency'" label="区域">
              <el-cascader v-if="$public.isAllAreaPower()" v-model="address_3" :options="addressList" :props="props" clearable />
              <d-area v-else ref="area_3" :values.sync="address_3" all-val />
            </el-form-item>

            <el-form-item label="业务">
              <el-select v-model="inquire_3.productClassify" placeholder="请选择">
                <el-option v-for="(item, index) in businessList" :key="index" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" size="small" @click="platformUserTrendChart">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div class="flex-box">
          <div class="echars-box">
            <div id="user-platform_box"></div>
          </div>

          <div class="data-statistics">
            <label>数据统计</label>
            <ul>
              <li>消费者：{{ platFormUserData.getCountMember }}位</li>
              <!-- <li>转化率：20%</li> -->
              <li>平均消费金额：{{ platFormUserData.getAVGManey }}元</li>
              <li>平均消费次数：{{ platFormUserData.getAVGTime }}次</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- 数据 table -->
    <div class="bg_white box-m-b box-p-t">
      <div class="search-row">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="时间段">
            <el-date-picker
              v-model="daterange_4"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>

          <el-form-item v-if="userInfo.managerType != 'agency'" label="区域">
            <el-cascader v-if="$public.isAllAreaPower()" v-model="address_4" :options="addressList" :props="props" clearable />
            <d-area v-else ref="area_4" :values.sync="address_4" all-val />
          </el-form-item>

          <!-- <el-form-item label="业务">
              <el-select v-model="inquire_4.type" placeholder="请选择">
                <el-option v-for="(item,index) in businessList" :key="index" :label="item.name"  :value="item.id"></el-option>
              </el-select>
            </el-form-item> -->

          <el-form-item>
            <el-button type="primary" size="small" @click="getPOT5DataList">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="flex-box table-row ">
      <div class="row_box bg_white table-box">
        <div class="header flex-box">
          <span>医药品销售TOP5</span>
          <span v-if="isPermission('pharmaceuticalSalesDataStatement')" class="operation-btn">
            <router-link to="/financial/financialStatement/pharmaceuticalSalesDataStatement">查看明细</router-link>
          </span>
        </div>
        <div class="content ">
          <el-table v-loading="top5Loading" :data="pharmaceuticalSalesList" height="277px" style="width: 100%">
            <el-table-column prop="date" label="排名" min-width="60" align="center">
              <template v-slot="scope">
                <div v-if="scope.$index < 3" class="flex-box flex-justify">
                  <div class="img-box"><img :src="rankingList[scope.$index]" alt="" /></div>
                </div>
                <div v-else>{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="160" show-overflow-tooltip />
            <el-table-column prop="salesCount" label="销售数量" min-width="100" align="center">
              <template slot-scope="scope">
                <div class="color-red">{{ scope.row.salesCount }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

      <div class="row_box bg_white table-box">
        <div class="header flex-box">
          <span>电商品销售TOP5</span>
          <span v-if="isPermission('electronicCommoditySalesDataReport')" class="operation-btn">
            <router-link to="/financial/financialStatement/electronicCommoditySalesDataReport">查看明细</router-link>
          </span>
        </div>
        <div class="content ">
          <el-table v-loading="top5Loading" :data="salesOfElectronicGoodsList" height="277px" style="width: 100%">
            <el-table-column prop="date" label="排名" min-width="60" align="center">
              <template v-slot="scope">
                <div v-if="scope.$index < 3" class="flex-box flex-justify">
                  <div class="img-box"><img :src="rankingList[scope.$index]" alt="" /></div>
                </div>
                <div v-else>{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="160" show-overflow-tooltip />
            <el-table-column prop="salesCount" label="销售数量" min-width="100" align="center">
              <template slot-scope="scope">
                <div class="color-red">{{ scope.row.salesCount }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="flex-box table-row ">
      <div class="row_box bg_white table-box">
        <div class="header flex-box">
          <span>医院销售TOP5</span>
          <span v-if="isPermission('hospitalSalesStatistics')" class="operation-btn">
            <router-link to="/financial/financialStatement/hospitalSalesStatistics">查看明细</router-link>
          </span>
        </div>
        <div class="content ">
          <el-table v-loading="top5Loading" :data="thePharmacySalesList" height="277px" style="width: 100%">
            <el-table-column prop="date" label="排名" min-width="60" align="center">
              <template v-slot="scope">
                <div v-if="scope.$index < 3" class="flex-box flex-justify">
                  <div class="img-box"><img :src="rankingList[scope.$index]" alt="" /></div>
                </div>
                <div v-else>{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="hospitalName" label="医院名称" min-width="160" show-overflow-tooltip />
            <el-table-column prop="salesAmount" label="销售额" min-width="100" align="center">
              <template slot-scope="scope">
                <div class="color-red">{{ scope.row.salesAmount }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="row_box bg_white table-box">
        <div class="header flex-box">
          <span>区域销售TOP5</span>
          <span v-if="isPermission('regionalSalesStatistics')" class="operation-btn">
            <router-link to="/financial/financialStatement/regionalSalesStatistics">查看明细</router-link>
          </span>
        </div>
        <div class="content ">
          <el-table v-loading="top5Loading" :data="regionalSalesList" height="277px" style="width: 100%">
            <el-table-column prop="date" label="排名" min-width="60" align="center">
              <template v-slot="scope">
                <div v-if="scope.$index < 3" class="flex-box flex-justify">
                  <div class="img-box"><img :src="rankingList[scope.$index]" alt="" /></div>
                </div>
                <div v-else>{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="areaName" label="区域名称" min-width="160" show-overflow-tooltip />
            <el-table-column prop="salesAmount" label="销售额" min-width="100" align="center">
              <template slot-scope="scope">
                <div class="color-red">{{ scope.row.salesAmount }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="flex-box table-row ">
      <div class="row_box bg_white table-box">
        <div class="header flex-box">
          <span>商家销售TOP5</span>
          <span v-if="isPermission('salesStatistics')" class="operation-btn">
            <router-link to="/financial/financialStatement/salesStatistics">查看明细</router-link>
          </span>
        </div>
        <div class="content ">
          <el-table v-loading="top5Loading" :data="merchantsSellList" height="277px" style="width: 100%">
            <el-table-column prop="date" label="排名" min-width="60" align="center">
              <template v-slot="scope">
                <div v-if="scope.$index < 3" class="flex-box flex-justify">
                  <div class="img-box"><img :src="rankingList[scope.$index]" alt="" /></div>
                </div>
                <div v-else>{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="merchantName" label="商家名称" min-width="160" show-overflow-tooltip />
            <el-table-column prop="salesAmount" label="销售额" min-width="100" align="center">
              <template slot-scope="scope">
                <div class="color-red">{{ scope.row.salesAmount }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="row_box bg_white table-box">
        <div class="header flex-box">
          <span>专员业绩TOP5</span>
          <span v-if="isPermission('specialistSalesStatistics')" class="operation-btn">
            <router-link to="/financial/financialStatement/specialistSalesStatistics">查看明细</router-link>
          </span>
        </div>
        <div class="content ">
          <el-table v-loading="top5Loading" :data="attacheSalesList" height="277px" style="width: 100%">
            <el-table-column prop="date" label="排名" min-width="60" align="center">
              <template v-slot="scope">
                <div v-if="scope.$index < 3" class="flex-box flex-justify">
                  <div class="img-box"><img :src="rankingList[scope.$index]" alt="" /></div>
                </div>
                <div v-else>{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="attacheName" label="专员姓名" min-width="160" show-overflow-tooltip />
            <el-table-column prop="salesAmount" label="销售额" min-width="100" align="center">
              <template slot-scope="scope">
                <div class="color-red">{{ scope.row.salesAmount }}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dArea from '@/components/d-area'
import { setSessionStorage, getSessionStorage } from '@/utils/auth'

export default {
  components: {
    dArea
  },
  data() {
    const first = require('../../assets/image/first.png')
    const second = require('../../assets/image/second.png')
    const third = require('../../assets/image/third.png')
    return {
      // echarts图表
      echarts: undefined,
      menuList: [],
      // 级联选项
      area: [], // 区域
      addressList: [], // 区域
      props: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true
        // emitPath: false
      },

      productTypeList: [], // 产品分类
      productProps: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        // checkStrictly: true,
        emitPath: true
      },
      categoryId: [],
      productList: [], // 产品列表
      businessList: [{ name: '销售', id: 0 }, { name: '租赁', id: 1 }], // 业务列表

      option: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: []
      },

      // ============ 平台销售额趋势分析 ============
      inquire_1: {
        productClassify: 0,
        dayOrMonth: 0,
        startTime: '',
        endTime: '',
        mainAreaId: '',
        middleAreaId: '',
        baseAreaId: '',
        productId: ''
      },
      daterange_1: [],
      address_1: [],
      salesPlatformData: {},

      // ============ 平台租赁额趋势分析 ============
      inquire_2: {
        productClassify: 1,
        dayOrMonth: 0,
        startTime: '',
        endTime: '',
        mainAreaId: '',
        middleAreaId: '',
        baseAreaId: ''
        // productId:'',
      },
      daterange_2: [],
      address_2: [],
      platformLeaseData: {},

      // ============ 平台用户趋势图 ============
      inquire_3: {
        dayOrMonth: 0,
        startTime: '',
        endTime: '',
        productClassify: '',
        mainAreaId: '',
        middleAreaId: '',
        baseAreaId: ''
      },
      daterange_3: [],
      address_3: [],
      platFormUserData: {},

      // =============== 数据列表 ===============
      rankingList: [first, second, third],
      inquire_4: {
        startTime: '',
        endTime: '',
        type: 0,
        regionId: '',
        districtId: '',
        agencyId: '',
        sortType: 1
      },
      daterange_4: [],
      address_4: [],
      top5Loading: false,

      // 医药品销售TOP5
      pharmaceuticalSalesList: [],
      // 电商品销售TOP5
      salesOfElectronicGoodsList: [],
      // 租赁品销售TOP5
      saleOfLeaseholdList: [],
      // 医院销售TOP5
      thePharmacySalesList: [],
      // 区域销售TOP5
      regionalSalesList: [],
      // 专员业绩TOP5
      attacheSalesList: [],
      // 商家销售TOP5
      merchantsSellList: [],
      // 信息员销售TOP5
      informationSalesList: [],

      // 用户信息
      userInfo: {},
      nb: false
    }
  },
  computed: {},
  created() {
    this.echarts = require('echarts')

    this.disposeTimeShow(1)
    this.disposeTimeShow(2)
    this.disposeTimeShow(3)
    this.disposeTimeShow(4)

    this.userInfo = getSessionStorage('userInfo')

    // 平台销售额趋势分析
    this.platformSalesTrendAnalysis()
    // 平台租赁额趋势分析
    this.platformRentalTrendAnalysis()
    // 平台用户趋势图
    this.platformUserTrendChart()
    // 排行榜
    this.getPOT5DataList()

    // 区域列表
    this.getArea()
    // 产品类别
    this.getProductCategory()
    // 收款商户
    this.weChatMerchantList()
  },
  mounted() {
    this.menuList = this.$store.state.user.menuList
  },
  methods: {
    // 获取收款商户列表
    weChatMerchantList() {
      this.$api.weChatMerchantList().then(res => {
        if (res.code === 200) {
          setSessionStorage('weChatMerchant', res.data)
        }
      })
    },
    // 获取区域级联数据
    getArea() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data
      })
    },
    // 获取产品类别
    getProductCategory() {
      this.$http
        .requestGet({
          url: '/product/category',
          loading: false
        })
        .then(res => {
          this.productTypeList = res.data
        })
    },
    // 产品分类改变
    categoryChange(e) {
      this.productList = []
      this.inquire_1.productId = ''
      if (e.length > 1) {
        this.nb = true
        this.getProductList(e[1])
      } else {
        this.nb = false
      }
    },
    // 根据分类id获取产品列表
    getProductList(id) {
      this.$http.requestGet({ url: '/customer/order/category/' + id, loading: false }).then(res => {
        this.productList = res.data
      })
    },

    // 渲染柱状图表
    renderingEcharts(option, el) {
      // 基于准备好的dom，初始化echarts实例
      var myChart = this.echarts.init(document.getElementById(el))
      // 绘制图表
      myChart.setOption(option, true)
    },
    // 处理日期时间
    disposeDate(type, time, name) {
      if (type === 0) {
        this[name].startTime = time.length > 0 ? time[0] : ''
        this[name].endTime = time.length > 0 ? time[1] : ''
      } else {
        const endTime = time.length > 0 ? this.$public.getAssignMonth(time[1])[1] + ' 23:59:59' : ''
        this[name].startTime = time.length > 0 ? this.$public.getAssignMonth(time[0])[0] + ' 00:00:00' : ''
        this[name].endTime = endTime
      }
    },
    // 处理区域
    disposeAddress(index, arr, name) {
      const ind_1 = [1, 2, 3]
      if (ind_1.includes(index)) {
        this[name].mainAreaId = arr.length > 0 ? arr[0] : ''
        this[name].middleAreaId = arr.length > 1 ? arr[1] : ''
        this[name].baseAreaId = arr.length > 2 ? arr[2] : ''
      } else {
        this[name].regionId = arr.length > 0 ? arr[0] : ''
        this[name].districtId = arr.length > 1 ? arr[1] : ''
        this[name].agencyId = arr.length > 2 ? arr[2] : ''
      }
    },
    // 处理区域 非全部区域权限
    disposeAddressTwo(ind, name) {
      let region = ''
      let district = ''
      let agency = ''
      if (this.userInfo.managerType == 'region') {
        region = this.userInfo.areaId
        district = this.$refs['area_' + ind] ? this.$refs['area_' + ind].district : ''
        agency = this.$refs['area_' + ind] ? this.$refs['area_' + ind].agency : ''
      } else if (this.userInfo.managerType == 'district') {
        district = this.userInfo.areaId
        agency = this.$refs['area_' + ind] ? this.$refs['area_' + ind].agency : ''
      } else if (this.userInfo.managerType == 'agency') {
        agency = this.userInfo.areaId
      }
      const ind_1 = [1, 2, 3]
      if (ind_1.includes(ind)) {
        this[name].mainAreaId = region
        this[name].middleAreaId = district
        this[name].baseAreaId = agency
      } else {
        this[name].regionId = region
        this[name].districtId = district
        this[name].agencyId = agency
      }
    },
    // 处理时间默认时间的显示
    disposeTimeShow(index) {
      this['daterange_' + index] = []
      const ind_1 = [1, 2, 3]
      if (ind_1.includes(index)) {
        // 日
        if (this['inquire_' + index].dayOrMonth === 0) {
          this['daterange_' + index][0] = this.$public.getAssignDate(-6) + ' 00:00:00'
          this['daterange_' + index][1] = this.$public.getAssignDate(0) + ' 23:59:59'
        } else {
          // 月
          this['daterange_' + index] = this.$public.getAssignMonthScope(-6)
        }
      } else {
        this['daterange_' + index][0] = this.$public.getAssignDate(-6) + ' 00:00:00'
        this['daterange_' + index][1] = this.$public.getAssignDate(0) + ' 23:59:59'
      }
    },

    // 平台销售额趋势分析
    platformSalesTrendAnalysis() {
      if (!this.daterange_1) this.daterange_1 = []
      this.disposeDate(this.inquire_1.dayOrMonth, this.daterange_1, 'inquire_1')
      if (this.$public.isAllAreaPower()) {
        this.disposeAddress(1, this.address_1, 'inquire_1')
      } else {
        this.disposeAddressTwo(1, 'inquire_1')
      }
      const arr1 = []
      arr1.push(this.inquire_1.productId)
      const param = {
        productClassify: this.inquire_1.productClassify,
        dayOrMonth: this.inquire_1.dayOrMonth,
        startTime: this.inquire_1.startTime,
        endTime: this.inquire_1.endTime,
        mainAreaId: this.inquire_1.mainAreaId,
        middleAreaId: this.inquire_1.middleAreaId,
        baseAreaId: this.inquire_1.baseAreaId,
        productId: arr1
      }
      if (!this.nb) {
        delete param.productId
      } else {
        param.productId = []
        if (this.inquire_1.productId === '' && this.productList.length > 0) {
          this.productList.map((item, index) => {
            param.productId.push(item.productId)
          })
        } else {
          param.productId.push(this.inquire_1.productId)
        }
      }
      this.$http
        .requestPost({
          url: '/index/sales',
          param: param,
          loading: false
        })
        .then(res => {
          var list = res.data
          var typeL = []
          var xAxis = []
          var series = []
          list[0].list.forEach(e => {
            xAxis.push(e.receiptTime)
          })
          list.forEach(e => {
            const numL = []
            typeL.push(e.name)
            e.list.forEach(o => {
              numL.push(o.sales)
            })
            series.push({
              name: e.name,
              type: 'line',
              // stack: '总量',
              data: numL
            })
          })
          var option = JSON.parse(JSON.stringify(this.option))
          option.legend.data = typeL
          option.xAxis.data = xAxis
          option.series = series
          this.$nextTick(() => {
            this.renderingEcharts(option, 'sales-platform_box')
          })
        })

      // 右侧数据统计
      this.$http
        .requestPost({
          url: '/index/dates',
          param: param,
          loading: false
        })
        .then(res => {
          this.salesPlatformData = res.data
        })
    },

    // 平台租赁额趋势分析
    platformRentalTrendAnalysis() {
      if (!this.daterange_2) this.daterange_2 = []
      this.disposeDate(this.inquire_2.dayOrMonth, this.daterange_2, 'inquire_2')
      if (this.$public.isAllAreaPower()) {
        this.disposeAddress(2, this.address_2, 'inquire_2')
      } else {
        this.disposeAddressTwo(2, 'inquire_2')
      }

      this.$http
        .requestPost({
          url: '/index/sales',
          param: this.inquire_2,
          loading: false
        })
        .then(res => {
          var list = res.data
          var typeL = []
          var xAxis = []
          var series = []
          list[0].list.forEach(e => {
            xAxis.push(e.receiptTime)
          })
          list.forEach(e => {
            const numL = []
            typeL.push(e.name)
            e.list.forEach(o => {
              numL.push(o.sales)
            })
            series.push({
              name: e.name,
              type: 'line',
              // stack: '总量',
              data: numL
            })
          })
          var option = JSON.parse(JSON.stringify(this.option))
          option.legend.data = typeL
          option.xAxis.data = xAxis
          option.series = series
          this.$nextTick(() => {
            this.renderingEcharts(option, 'Leasing-platform_box')
          })
        })

      // 右侧数据统计
      this.$http
        .requestPost({
          url: '/index/dates',
          param: this.inquire_2,
          loading: false
        })
        .then(res => {
          this.platformLeaseData = res.data
        })
    },

    // 平台用户趋势图
    platformUserTrendChart() {
      if (!this.daterange_3) this.daterange_3 = []
      this.disposeDate(this.inquire_3.dayOrMonth, this.daterange_3, 'inquire_3')
      if (this.$public.isAllAreaPower()) {
        this.disposeAddress(3, this.address_3, 'inquire_3')
      } else {
        this.disposeAddressTwo(3, 'inquire_3')
      }

      this.$http
        .requestPost({
          url: '/index/user',
          param: this.inquire_3,
          loading: false
        })
        .then(res => {
          var list = res.data
          var typeL = []
          var xAxis = []
          var series = []
          list[0].list.forEach(e => {
            xAxis.push(e.receiptTime)
          })
          list.forEach(e => {
            const numL = []
            typeL.push(e.name)
            e.list.forEach(o => {
              numL.push(o.sales)
            })
            series.push({
              name: e.name,
              type: 'line',
              // stack: '总量',
              data: numL
            })
          })
          var option = JSON.parse(JSON.stringify(this.option))
          option.legend.data = typeL
          option.xAxis.data = xAxis
          option.series = series
          this.$nextTick(() => {
            this.renderingEcharts(option, 'user-platform_box')
          })
        })

      // 右侧数据统计
      this.$http
        .requestPost({
          url: '/index/getCountMember',
          param: this.inquire_3,
          loading: false
        })
        .then(res => {
          this.platFormUserData = res.data
        })
    },

    // 销售排行榜
    getPOT5DataList() {
      if (!this.daterange_4) this.daterange_4 = []
      let address_4 = []
      // id仅传选中层级
      if (this.address_4.length > 0) {
        address_4 = this.address_4.map((item, index) => (index < this.address_4.length - 1 ? '' : item))
      }

      this.disposeDate(0, this.daterange_4, 'inquire_4')
      if (this.$public.isAllAreaPower()) {
        this.disposeAddress(4, address_4, 'inquire_4')
      } else {
        this.disposeAddressTwo(4, 'inquire_4')
      }
      this.top5Loading = true
      this.$http
        .requestPost({
          url: '/index/getTop5s',
          param: this.inquire_4
        })
        .then(res => {
          this.top5Loading = false
          var data = res.data
          this.pharmaceuticalSalesList = data.medicineTop5s
          this.salesOfElectronicGoodsList = data.electronicTop5s
          this.saleOfLeaseholdList = data.leaseTop5s
          this.thePharmacySalesList = data.hospitalTop5s
          this.regionalSalesList = data.areaTop5s
          this.attacheSalesList = data.attacheTop5s
          this.merchantsSellList = data.merchantTop5s
          this.informationSalesList = data.messengerTop5s
        })
        .catch(res => {
          this.top5Loading = false
        })
    },

    // 是否有权限
    isPermission(path) {
      if (this.menuList.length > 0) {
        const item = this.menuList.find(item => item.path == '/financial')
        if (item && item.children.length > 0) {
          const children = item.children.find(item => item.path == 'financialStatement')
          if (children && children.children.length > 0) {
            const index = children.children.findIndex(item => item.path == path)
            if (index !== -1) {
              return true
            }
          }
        }
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;
}
.search-row {
  padding: 5px 20px 0px;
}

.row_box {
  margin-bottom: 10px;
  font-size: 16px;

  .header {
    font-weight: 700;
    padding: 18px 0px 10px;
    margin: 0 20px 0;
    border-bottom: 1px solid #cccccc;
    justify-content: space-between;

    .operation-btn {
      font-weight: 100;
      font-size: 14px;
    }
  }

  .content {
    padding: 10px 19px 20px 19px;
    overflow-y: auto;

    .echars-box {
      flex: 1;
      & > div {
        width: 100%;
        height: 360px;
      }
    }

    .data-statistics {
      min-width: 225px;
      padding: 10px 20px 10px;
      background-color: #f7f7f7;
      border-radius: 10px;

      & > label {
        font-size: 16px;
        padding-bottom: 14px;
        display: inline-block;
      }

      & > ul li {
        font-size: 14px;
        color: #4e4e4e;
        padding-bottom: 14px;
      }
    }
  }
}

.table-row {
  justify-content: space-between;

  & > div:nth-child(1) {
    margin-right: 5px;
  }
  & > div:nth-last-child(1) {
    margin-left: 5px;
  }
}
.table-box {
  width: 50%;
}

.img-box {
  width: 24px;
  height: 24px;

  & > img {
    width: 100%;
    height: 100%;
  }
}

.color-red {
  color: #f23030;
}
</style>
