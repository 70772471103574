<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="权限组名称">
          <el-input v-model="inquire.name" placeholder="权限组名称" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增权限组</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="groupName" label="权限组名称" min-width="160" show-overflow-tooltip/>
            <el-table-column prop="groupDesc" label="备注" min-width="220" show-overflow-tooltip />
            <el-table-column prop="createTime" label="添加时间" min-width="160" />
            <el-table-column prop="addByName" label="添加人" min-width="160" />
            <el-table-column prop="" label="操作" min-width="200" fixed="right">
              <!-- <template slot-scope="scope" > -->
              <template slot-scope="scope" v-if="scope.row.groupName !== '超级管理员'">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="setGroup(scope.row)">权限设置</span>
                <span class="operation-btn_partition"> / </span>
                <!-- <span class="operation-btn" >成员列表</span>  -->
                <!-- <span class="operation-btn_partition"> / </span> -->
                <span class="operation-btn" @click="deleteData(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加权限组弹框 -->
    <el-dialog
      :title="dialogTitle + '权限组'"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="650px"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="110px" :model="submitForm" :rules="submitRules">
          <el-form-item label="权限组名称" prop="groupName">
            <el-input v-model="submitForm.groupName" maxlength="12" placeholder="权限组名称" />
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="submitForm.groupDesc" maxlength="150" type="textarea" :rows="3" placeholder="权限组名称" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 设置权限弹框 -->
    <el-dialog
      title="设置权限"
      :visible.sync="dialogVisible_2"
      :before-close="closeDialog"
      width="650"
    >
      <div class="Popout_content">
        <el-tree
          ref="groupTree"
          :data="groupList"
          show-checkbox
          node-key="menuId"
          :default-expanded-keys="checkedShow"
          :default-checked-keys="checkedShow"
          :props="defaultProps" 
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="confirmResetPwd">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
    data() {
        return {
            // 查询参数
            inquire: {
                name: ''
            },
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            loading: false,
            // 表格数据
            tableData: [],

            // 添加
            dialogTitle: '', // 添加弹窗标题
            dialogVisible: false, // 添加弹窗
            submitForm: {
                groupName: '',
                groupDesc: ''
            },
            submitRules: {
                groupName: [{ required: true, message: '请输入权限组名称', trigger: 'blur' }]
            },
            isAdd: true, // 是否为添加
            editRow: {},

            // ==== 设置权限 ====
            dialogVisible_2: false,
            groupList: [], // 权限树配置
            defaultProps: {
                children: 'children',
                label: 'menuName',
            },
            checkedGroup: [], // 选择权限
            checkedShow: []

        }
    },
    created() {
        // 获取数据列表
        this.getTableList()
        // 获取权限列表
        this.getRightsList()
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size
            // 刷新(数据)
            this.getTableList()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page
            // 刷新(页码)
            this.getTableList()
        },
        // 刷新
        searchList() {
          this.pageParam.pageNum = 1
          this.getTableList()
        },
        // 获取权限集合
        getRightsList() {
            this.$http.requestPost({url:'/system/menu/list',loading:false}).then(res => {
              this.groupList = res.data;
              if (this.groupList.length > 0) {
                this.groupList.map(v => {
                  if (v.menuUri === '/system' && v.children.length > 0) {
                    v.children.map(k => {
                      if (k.menuUri === 'admin' ||  k.menuUri === 'group') {
                        k.disabled = true
                      }
                    })
                  }
                })
              }
            })
        },
      
        // 获取数据列表
        getTableList() {
            const data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            }

            this.loading = true
            this.$http.requestPost({
                url: '/system/rights/group/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false
                this.tableData = res.data.list
                this.pageParam.total = res.data.total
            }).catch(err => {
                this.loading = false
                console.log(err)
            })
        },

        // 添加
        addition() {
            this.dialogTitle = '新增'
            this.dialogVisible = true
            this.isAdd = true
        },
        // 修改
        handleEdit(row) {
            this.dialogTitle = '编辑'
            this.isAdd = false
            this.submitForm = {
                groupName: row.groupName,
                groupDesc: row.groupDesc
            }
            this.dialogVisible = true
            this.editRow = row
        },
        // 添加
        submitAdd() {
            this.$refs.submitForm.validate(valid => {
                if (valid) {
                    const param = {
                        groupName: this.submitForm.groupName,
                        groupDesc: this.submitForm.groupDesc
                    }
                    if (!this.isAdd) {
                        param.groupId = this.editRow.groupId
                    }
                    this.submit(param)
                } else {
                    return false
                }
            })
        },
        // 添加，修改提交
        submit(param) {
            this.$http.requestPost({
                url: '/system/rights/group/save',
                param: param
            }).then(res => {
                this.getTableList()
                this.$message({
                    message: '操作成功',
                    type: 'success'
                })
                this.closeDialog()
            })
        },

        // 关闭弹窗
        closeDialog() {
          if (this.$refs.submitForm) {
            this.$refs.submitForm.resetFields()
            this.dialogVisible = false
            this.submitForm = {
                groupName: '',
                groupDesc: ''
            }
          }
          if (this.dialogVisible_2) {
            this.checkedShow = [];
            this.dialogVisible_2 = false
            this.$refs.groupTree.setCheckedKeys([])
            
            Object.keys(this.$refs.groupTree.store.nodesMap).forEach(e => {
              if (this.$refs.groupTree.store.nodesMap[e].expanded) {
                this.$refs.groupTree.store.nodesMap[e].expanded = false
              }
            })
          }
        },

        // 删除
        deleteData(row) {
          this.$confirm('确定删除该权限组?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
              this.pageParam.pageNum--
            }
            this.$http.requestDel({ url: '/system/rights/group/del/' + row.groupId }).then(res => {
              this.getTableList()
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            })
          })
        },

        // 设置权限
        setGroup(row) {
          console.log(row)
          this.editRow = row;
          this.checkedShow = row.systemMenuIds;
          this.dialogVisible_2 = true
        },
        // 确定设置权限
        confirmResetPwd() {
            this.checkedGroup = this.$refs.groupTree.getCheckedKeys()
            if (this.checkedGroup.length === 0) return this.$message({ message: '请选择权限', type: 'warning' })
            let param = {
              groupId: this.editRow.groupId,
              systemMenuIds: this.checkedGroup,
            };
            this.submit(param)
            this.dialogVisible_2 = false
        }

    }
}
</script>

<style lang="scss" scoped>

</style>
