import { render, staticRenderFns } from "./addNutrition.vue?vue&type=template&id=1487d7c8&scoped=true"
import script from "./addNutrition.vue?vue&type=script&lang=js"
export * from "./addNutrition.vue?vue&type=script&lang=js"
import style0 from "./addNutrition.vue?vue&type=style&index=0&id=1487d7c8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1487d7c8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/dev_638_admin_web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1487d7c8')) {
      api.createRecord('1487d7c8', component.options)
    } else {
      api.reload('1487d7c8', component.options)
    }
    module.hot.accept("./addNutrition.vue?vue&type=template&id=1487d7c8&scoped=true", function () {
      api.rerender('1487d7c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/product/nutrition/addNutrition.vue"
export default component.exports