<template>
    <div class="main-box">
        <div class="head-search-row">
            <el-form :inline="true" class="demo-form-inline" size="medium">
                <el-form-item label="">
                    <div class="flex-box">
                        <el-select
                            v-model="inquire.searchKey"
                            placeholder="请选择"
                            @change="inquire.searchValue = ''"
                            class="width-140"
                        >
                            <el-option label="标题" value="1"></el-option>
                            <el-option label="作者" value="2"></el-option>
                        </el-select>
                        <el-input v-model="inquire.searchValue" placeholder="" class="width-180"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <div class="flex-box">
                        <el-select v-model="inquire.type" placeholder="请选择类别" class="width-140">
                            <el-option label="请选择" value=""></el-option>
                            <el-option
                                v-for="(item, index) in categoryTree"
                                :key="index"
                                :label="item.name"
                                :value="item.id"
                            ></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <div class="flex-box">
                        <el-select
                            v-model="inquire.timeKey"
                            @change="inquire.daterange = []"
                            placeholder="请选择类别"
                            class="width-140"
                        >
                            <el-option label="添加时间" value="1"></el-option>
                            <el-option label="删除时间" value="2"></el-option>
                        </el-select>
                        <el-date-picker
                            v-model="daterange"
                            type="daterange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" size="small" @click="searchList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div style="margin: 20px 0 0 20px">
            <el-button size="small" type="primary" @click="addition">新增内容</el-button>
        </div>

        <div class="table">
            <el-main>
                <template>
                    <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
                        <el-table-column prop="date" label="序号" width="60">
                            <template v-slot="scope">{{
                                (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
                            }}</template>
                        </el-table-column>
                        <el-table-column
                            prop="title"
                            label="标题"
                            min-width="200"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column
                            prop="typeName"
                            label="类别"
                            min-width="160"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column prop="author" label="作者" min-width="100"></el-table-column>
                        <el-table-column prop="browseCount" label="浏览数" min-width="100"></el-table-column>
                        <el-table-column prop="agreeCount" label="点赞数" min-width="160"></el-table-column>
                        <el-table-column prop="commentCount" label="评论数" min-width="160"></el-table-column>
                        <el-table-column prop="showTime" label="显示时间" min-width="160"></el-table-column>
                        <el-table-column prop="createTime" label="添加时间" min-width="160"></el-table-column>
                        <el-table-column prop="" label="操作" min-width="140" fixed="right">
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleEdit(scope.row)">详情</span>
                                <span class="operation-btn_partition"> / </span>
                                <span class="operation-btn" @click="toComment(scope.row)">评论</span>
                                <span class="operation-btn_partition"> / </span>
                                <span class="operation-btn" @click="delData(scope.row)">恢复</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        background
                        :current-page="pageParam.pageNum"
                        :page-sizes="$api.pageSizes"
                        :page-size="pageParam.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageParam.total"
                    ></el-pagination>
                </div>
            </el-main>
        </div>

        <!-- 添加弹框 -->
        <el-dialog
            :title="dialogTitle + '内容'"
            :visible.sync="dialogVisible"
            :before-close="closeDialog"
            width="70%"
            top="5vh"
        >
            <div class="Popout_content">
                <el-form label-width="110px" ref="submitForm" :model="submitForm" :rules="submitRules">
                    <el-form-item label="标题" prop="title">
                        <el-input v-model="submitForm.title" maxlength="30" placeholder="标题"></el-input>
                    </el-form-item>
                    <el-form-item label="所属类别" prop="type">
                        <el-cascader
                            v-model="submitForm.type"
                            :props="props_add"
                            :options="categoryTree"
                            placeholder="请选择"
                            class="width-inherit"
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label="封面图片" prop="coverImg">
                        <d-upload
                            upload-type="picture-card"
                            :file-list="primaryImagesList"
                            accept=".gif,.jpeg,.jpg,.png"
                            @uploadSuccess="uploadSuccess"
                            @removeFile="removeFile"
                        >
                            <div class="tip-box">
                                <i class="el-icon-plus" />
                                <span slot="tip" class="tip">建议360px*360px</span>
                            </div>
                        </d-upload>
                    </el-form-item>
                    <el-form-item label="顶部视频" prop="topVideo">
                        <d-upload
                            :size="500"
                            :file-list="submitForm.topVideo"
                            :autoUpload="autoUpload"
                            :action="actionUrl"
                            accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
                            @uploadSuccess="uploadSuccess_1"
                            @handleChange="handleChange_1"
                            @removeFile="removeFile_1"
                        >
                            <div class="tip-box">
                                <el-button size="small" type="primary">视频上传</el-button>
                            </div>
                        </d-upload>
                        <el-progress v-if="progressFlag1" :percentage="percentage1"></el-progress>
                    </el-form-item>
                    <el-form-item label="底部视频" prop="bottonVideo">
                        <d-upload
                            :size="500"
                            :file-list="submitForm.bottonVideo"
                            :autoUpload="autoUpload"
                            :action="actionUrl"
                            accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
                            @uploadSuccess="uploadSuccess_2"
                            @handleChange="handleChange_2"
                            @removeFile="removeFile_2"
                        >
                            <div class="tip-box">
                                <el-button size="small" type="primary">视频上传</el-button>
                            </div>
                        </d-upload>
                        <el-progress v-if="progressFlag2" :percentage="percentage2"></el-progress>
                    </el-form-item>
                    <el-form-item label="详情" prop="content">
                        <div class="editor">
                            <mavon-editor
                                ref="md"
                                v-model="editor"
                                @imgAdd="handleEditorImgAdd"
                                @imgDel="handleEditorImgDel"
                                @change="editorChange"
                                :toolbars="toolbars"
                            />
                        </div>
                    </el-form-item>
                    <el-form-item label="作者" prop="author">
                        <el-input v-model="submitForm.author" placeholder="作者"></el-input>
                    </el-form-item>
                    <el-form-item label="显示时间" prop="showTime">
                        <el-date-picker
                            class="width-inherit"
                            v-model="submitForm.showTime"
                            type="datetime"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                            placeholder="选择日期时间"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input v-model="submitForm.sort" placeholder="排序"></el-input>
                    </el-form-item>
                    <el-form-item label="展示用户" prop="viewPermission">
                        <el-radio-group v-model="submitForm.viewPermission">
                            <el-radio :label="0">全部</el-radio>
                            <el-radio :label="1">会员</el-radio>
                            <el-radio :label="2">商家</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="submitAdd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import dUpload from "@/components/d-upload";
import { parse } from "path-to-regexp";
import axios from "axios";
function getSignature() {
    return axios
        .post("/common/file/upload/getQCloudSignature", JSON.stringify({ action: "GetUgcUploadSign" }))
        .then(function(response) {
            return response.data.data;
        });
}
export default {
    components: {
        dUpload
    },
    data() {
        return {
            actionUrl: "/common/file/upload/video",
            // 类别
            categoryTree: [],
            props: {
                label: "categoryName",
                value: "categoryId",
                children: "childes",
                checkStrictly: true,
                emitPath: false
            },
            props_add: {
                label: "name",
                value: "id",
                emitPath: false
            },
            // 查询参数
            inquire: {
                searchValue: "", // 搜索值
                type: "", // 类别
                timeKey: "1", // 搜索时间类型：1：添加时间，2：删除时间
                searchKey: "1", // 搜索词：1：标题，2：作者
                endTime: "", // 结束时间
                startTime: "", // 起始时间
                isDeleted: 1 // 是否删除 0 不删除， 1 删除
            },
            daterange: [],
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            loading: false,
            // 表格数据
            tableData: [],

            // 添加区域地址
            dialogTitle: "添加", // 添加弹窗标题
            dialogVisible: false, // 添加弹窗
            submitForm: {
                coverImg: "",
                author: "",
                showTime: "",
                sort: "",
                type: "",
                title: "",
                content: "",
                viewPermission: "0",
                topVideo: [], // 顶部详情视频
                bottonVideo: [] // 底部详情视频
            },
            submitRules: {
                title: [{required: true, message: '请输入标题', trigger: 'change'}],
                coverImg: [{required: true, message: '请上传封面图片', trigger: 'change'}],
                author: [{ required: true, message: "请输入作者名", trigger: "blur" },],
                showTime: [{ required: true, message: "请输入显示时间", trigger: "change" }],
                sort: [{required: true, message: '请输入排序', trigger: 'blur'}],
                type: [{required: true, message: '请选择类别', trigger: 'change'}],
                content: [{required: true, message: '请输入内容', trigger: 'change'}],
                viewPermission: [{required: true, message: '请选择会员', trigger: 'change'}],
            },
            isAdd: true, // 是否为添加
            editRow: {},

            toolbars: {
                // 编辑器配置
                bold: true, // 粗体
                italic: true, // 斜体
                header: true, // 标题
                underline: true, // 下划线
                strikethrough: true, // 中划线
                mark: true, // 标记
                superscript: true, // 上角标
                subscript: true, // 下角标
                quote: true, // 引用
                ol: true, // 有序列表
                ul: true, // 无序列表
                link: true, // 链接
                imagelink: true, // 图片链接
                code: true, // code
                table: true, // 表格
                fullscreen: true, // 全屏编辑
                readmodel: true, // 沉浸式阅读
                htmlcode: true, // 展示html源码
                help: true, // 帮助
                /* 1.3.5 */
                undo: true, // 上一步
                redo: true, // 下一步
                trash: true, // 清空
                save: false, // 保存（触发events中的save事件）
                /* 1.4.2 */
                navigation: true, // 导航目录
                /* 2.1.8 */
                alignleft: true, // 左对齐
                aligncenter: true, // 居中
                alignright: true, // 右对齐
                /* 2.2.1 */
                subfield: true, // 单双栏模式
                preview: true // 预览
            },
            editor: "",

            selectRow: [], // 选择当前行
            // ==== 产品弹框 ====
            dialogVisible_2: false,
            inquire_2: {
                productName: ""
            },
            loading_2: false,
            tableData_2: [],
            pageParam_2: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            productTable: [],
            // 手动上传视频
            autoUpload: false, // 是否自动上传
            percentage: 0,
            progressFlag: false,

            percentage1: 0,
            progressFlag1: false,

            percentage2: 0,
            progressFlag2: false,
            uploader: "",
            uploader1: "", // 顶部视频上传盒子
            uploader2: "", //  底部视频上传盒子
            primaryImagesList: []
        };
    },
    created() {
        // 获取数据列表
        this.getTableList();
        this.getCategoryTree();
        this.tcVod = new this.$TcVod({
            getSignature: getSignature
        });
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size;
            // 刷新(数据)
            this.getTableList();
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page;
            // 刷新(页码)
            this.getTableList();
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1;
            this.getTableList();
        },
        // 富文本 图片添加
        handleEditorImgAdd(pos, file) {
            let formdata = new FormData();
            formdata.append("file", file);
            this.$http
                .requestPost({
                    url: "/common/file/upload",
                    param: formdata
                })
                .then(res => {
                    this.$refs.md.$img2Url(pos, res.data);
                });
            this.clearUploadStatus();
        },
        // 富文本 图片删除
        handleEditorImgDel(pos) {
            console.log(pos);
        },
        // 富文本改变后的内容
        editorChange(val, render) {
            this.submitForm.content = render;
        },
        // 获取分类列表
        getCategoryTree() {
            this.$http.requestGet({ url: "/article/getArticleTypeList", loading: false }).then(res => {
                this.categoryTree = res.data;
            });
        },
        // 如果分类列表里没有对应的详情id 则清空分类id
        selectTree(arr) {
            let contentCategoryId = this.submitForm.type;
            let flag = false;
            let hasContentCategoryId = "";
            if (arr) {
                hasContentCategoryId = arr.filter(v => {
                    return contentCategoryId == v.id;
                });
                if (hasContentCategoryId.length >= 1) {
                    flag = true;
                    return false;
                }
            }
            if (flag == false) {
                this.submitForm.type = "";
            }
        },

        // 获取数据列表
        getTableList() {
            if (!this.daterange) this.daterange = [];
            this.inquire.startTime = this.daterange.length > 0 ? this.daterange[0] + " 00:00:00" : "";
            this.inquire.endTime = this.daterange.length > 0 ? this.daterange[1] + " 23:59:59" : "";
            let data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            };

            this.loading = true;
            this.$http
                .requestPost({
                    url: "/article/getList",
                    param: data,
                    loading: false
                })
                .then(res => {
                    this.loading = false;
                    this.tableData = res.data.list;
                    this.pageParam.total = res.data.total;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        },

        // 添加
        addition() {
            // 清空视频上传状态盒子
            this.clearUploadStatus();
            this.dialogTitle = "添加";
            this.dialogVisible = true;
            this.isAdd = true;
        },
        // 修改
        handleEdit(row) {
            console.log(row);
            this.dialogTitle = "编辑";
            this.isAdd = false;
            this.primaryImagesList = [];
            this.clearUploadStatus();
            this.$http.requestGet({ url: "/article/get/" + row.id }).then(res => {
                let data = res.data;
                console.log(data);
                this.submitForm = {
                    author: data.author,
                    showTime: data.showTime,
                    sort: data.sort,
                    id: data.id,
                    type: data.type,
                    title: data.title,
                    viewPermission: data.viewPermission,
                    coverImg: '',
                    topVideo: [],
                    bottonVideo: []
                };
                // 如果没有对应类别id 则清空所属的类别
                if(this.categoryTree.length >= 1){
                  this.selectTree(this.categoryTree);
                }
                console.log(data);
                if (data.coverImg && data.coverImg.length > 0) {
                    let videosObj = {
                        url: data.coverImg,
                        name: data.coverImg.substring(data.coverImg.lastIndexOf("/") + 1)
                    };
                    this.primaryImagesList.push(videosObj);
                    console.log(this.primaryImagesList);
                }
                if (data.topVideo && data.topVideo.length > 0) {
                    let videosObj = {
                        url: data.topVideo,
                        name: data.topVideo.substring(data.topVideo.lastIndexOf("/") + 1)
                    };
                    this.submitForm.topVideo.push(videosObj);
                }
                if (data.bottonVideo && data.bottonVideo.length > 0) {
                    let videosObj = {
                        url: data.bottonVideo,
                        name: data.bottonVideo.substring(data.bottonVideo.lastIndexOf("/") + 1)
                    };

                    this.submitForm.bottonVideo.push(videosObj);
                }
                this.editor = this.$public.parseHTML_ForMarkdown(data.content);
            });

            this.dialogVisible = true;
            this.editRow = row;

            this.clearUploadStatus();
        },
        // 提交添加
        submitAdd() {
            if (this.primaryImagesList.length > 0) {
                const videos = this.primaryImagesList[0];
                console.log(videos);
                if (!videos.response) {
                    this.submitForm.coverImg = videos.url;
                } else {
                    this.submitForm.coverImg = videos.response.data;
                }
            }
            this.$refs.submitForm.validate(valid => {
                if (valid) {
                    let param = {
                        content: this.submitForm.content,
                        author: this.submitForm.author,
                        showTime: this.submitForm.showTime,
                        sort: this.submitForm.sort,
                        type: this.submitForm.type,
                        title: this.submitForm.title,
                        viewPermission: this.submitForm.viewPermission,
                        bottonVideo: "",
                        coverImg: this.submitForm.coverImg,
                        topVideo: ""
                    };
                    // 详情顶部视频
                    if (this.submitForm.topVideo.length > 0) {
                        const videos = this.submitForm.topVideo[0];
                        console.log(videos);
                        if (!videos.response) {
                            param.topVideo = videos.url;
                        } else {
                            param.topVideo = videos.response.data;
                        }
                    }

                    // 详情底部视频
                    if (this.submitForm.bottonVideo.length > 0) {
                        const videos = this.submitForm.bottonVideo[0];
                        if (!videos.response) {
                            param.bottonVideo = videos.url;
                        } else {
                            param.bottonVideo = videos.response.data;
                        }
                    }
                    if (!this.isAdd) {
                        param.id = this.editRow.id;
                    }
                    this.$http
                        .requestPost({
                            url: "/article/save",
                            param: param
                        })
                        .then(res => {
                            this.getTableList();
                            this.$message({
                                message: "操作成功",
                                type: "success"
                            });
                            this.closeDialog();
                        });
                } else {
                    return false;
                }
            });
        },

        // 关闭弹窗
        closeDialog() {
            this.$refs.submitForm && this.$refs.submitForm.resetFields();
            this.dialogVisible = false;
            this.submitForm = {
                author: "",
                showTime: "",
                sort: "",
                type: "",
                title: "",
                viewPermission: "",
                bottonVideo: [],
                coverImg: '',
                topVideo: []
            };
            this.primaryImagesList = [];
            this.editor = "";
        },

        // 删除
        delData(row) {
            this.$confirm("确定恢复该内容?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
                        this.pageParam.pageNum--;
                    }
                    this.$http
                        .requestGet({
                            url: "/article/modifyArticleStatus/" + row.id
                        })
                        .then(res => {
                            this.getTableList();
                            this.$message({
                                type: "success",
                                message: "恢复成功!"
                            });
                        });
                })
                .catch(err => {});
        },
        toComment(row) {
            console.log(row);
            this.$router.push({
                path: "comment",
                query: {
                    id: row.id
                }
            });
        },
        // 是否隐藏
        handleHide(row, type) {
            let name = type === "s" ? "显示" : "隐藏";
            this.$confirm("确定" + name + "该内容?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    this.$http
                        .requestPut({ url: "/operation/message/content/change/status/" + row.contentId })
                        .then(res => {
                            this.getTableList();
                            this.$message({
                                type: "success",
                                message: "操作成功!"
                            });
                        });
                })
                .catch(err => {});
        },
        // 上传视频-详情顶部视频
        uploadSuccess(res, file, fileList) {
            console.log(fileList);
            this.primaryImagesList = fileList;
        },
        removeFile(file, fileList) {
            this.primaryImagesList = fileList;
        },
        // 上传视频-详情顶部视频
        uploadSuccess_1(res, file, fileList) {
            console.log(fileList);
            this.submitForm.topVideo = fileList;
        },
        removeFile_1(file, fileList) {
            this.submitForm.topVideo = fileList;
            this.progressFlag1 = false;
            this.percentage1 = 0;
            if (this.uploader1) {
                this.uploader1.cancel();
                this.uploader2 = "";
            }
        },
        // 上传视频-详情底部视频
        uploadSuccess_2(res, file, fileList) {
            this.submitForm.bottonVideo = fileList;
        },
        // 删除底部视频
        removeFile_2(file, fileList) {
            this.submitForm.bottonVideo = fileList;
            this.progressFlag2 = false;
            this.percentage2 = 0;
            if (this.uploader2) {
                this.uploader2.cancel();
                this.uploader2 = "";
            }
        },
        // 详情顶部视频
        handleChange_1(file, fileList) {
            // 基本信息配置
            this.uploader1 = this.tcVod.upload({
                mediaFile: file.raw // 媒体文件
            });
            // 进度
            this.uploaderMediaProgress("topVideo", this.uploader1);
            // 视频上传后 处理
            this.uploaderDoneFn("topVideo", fileList, this.uploader1);
        },
        // 详情底部视频
        handleChange_2(file, fileList) {
            // 基本信息配置
            this.uploader2 = this.tcVod.upload({
                mediaFile: file.raw // 媒体文件
            });
            // 进度
            this.uploaderMediaProgress("bottonVideo", this.uploader2);
            // 视频上传后 处理
            this.uploaderDoneFn("bottonVideo", fileList, this.uploader2);
        },
        // 视频上传成功后，将fileId，fileUrl传给后台
        uploaderDoneFn(objName, fileList, uploader) {
            let that = this;
            uploader
                .done()
                .then(function(doneResult) {
                    let videosObj = [
                        {
                            url: doneResult.video.url,
                            name: fileList[0].name
                        }
                    ];
                    that.$http
                        .requestPostQs({
                            url: "/common/file/upload/saveQCloud",
                            param: {
                                fileId: doneResult.fileId,
                                fileUrl: doneResult.video.url
                            }
                        })
                        .then(res => {
                            console.log(res);
                        });

                    if (objName == "topVideo") {
                        that.submitForm.topVideo = videosObj;
                    }
                    if (objName == "bottonVideo") {
                        that.submitForm.bottonVideo = videosObj;
                    }
                })
                .catch(function(err) {
                    // deal with error
                });
        },
        // 视频上传进度
        uploaderMediaProgress(objName, uploader) {
            let that = this;
            uploader.on("media_progress", function(info) {
                let percentage = parseInt(info.percent * 100);
                if (objName == "topVideo") {
                    console.log(objName);
                    that.progressFlag1 = true; // 显示进度条
                    console.log("progressFlag1", that.progressFlag1);
                    that.percentage1 = percentage >= 100 ? 100 : percentage; // 动态获取文件上传进度
                    console.log(percentage);
                }
                if (objName == "bottonVideo") {
                    that.progressFlag2 = true; // 显示进度条
                    that.percentage2 = percentage >= 100 ? 100 : percentage; // 动态获取文件上传进度
                }

                if (percentage >= 100) {
                    setTimeout(() => {
                        if (objName == "topVideo") {
                            that.progressFlag1 = false;
                        }
                        if (objName == "bottonVideo") {
                            that.progressFlag2 = false;
                        }
                    }, 1000); // 一秒后关闭进度条
                }
            });
        },
        // 清空上传视频数据
        clearUploadStatus() {
            this.uploader1 = "";
            this.uploader2 = "";
        }
    }
};
</script>

<style lang="scss" scoped>
.editor {
    max-width: 1000px;
    max-height: 420px;
    .v-note-wrapper.shadow {
        height: 420px;
    }
}
</style>

