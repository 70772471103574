<template>
  <!-- 新增产品 -->
  <div id="main-scroll" class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>

    <div class="nav-bar bg_white">
      <a v-for="(item, index) in navList" :key="item.id" :class="{'active' : item.active }" href="javascript:void(0)" @click="skip(index)">{{ item.name }}</a>
    </div>

    <div id="row_1" class="row_box bg_white">
      <div class="header">
        <span>基本信息</span>
      </div>
      <div class="content">
        <el-form ref="common" size="medium" label-width="120px" :rules="commonRules">
          <el-form-item label="优惠名称" prop="discountName">
            <el-input v-model="discountName" maxlength="30" placeholder="优惠名称" />
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div id="row_2" class="row_box bg_white">
      <div class="header">
        <span>使用门槛</span>
      </div>
      <div class="content">
        <el-form size="medium" label-width="120px" :rules="commonRules">
          <el-form-item label="使用门槛" prop="couponType">
           <el-radio-group v-model="couponType"  @input="changeRadio">
            <el-radio  v-for="item in discountList" :key="item.id" :label="item.value">{{ item.name }}</el-radio>
           </el-radio-group>
          </el-form-item>
        </el-form>

       <div class="table-box">
        <el-table
        v-loading="loading" :data="couponList" style="width:95%" border  highlight-current-row>
      <el-table-column
        prop="date"
        label="序号"
        width="60">
        <template slot-scope="scope">
            <div>{{ scope.$index + 1 }}</div>
          </template>
      </el-table-column>
      <el-table-column
        prop="couponName"
        label="名称"
        min-width="150"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="threshold"
        :label="getTxt"
        width="150">
      </el-table-column>
      <el-table-column
        prop="couponDiscount"
        label="折扣(折)"
        width="120">
      </el-table-column>
      <el-table-column
        prop="couponAmount"
        label="面额(元)"
        width="120">
      </el-table-column>
      <el-table-column
        prop="couponStatus"
        label="状态"
        width="120">
        <template slot-scope="scope">{{ scope.row.couponStatus | getStatus }}</template>
      </el-table-column>
      <el-table-column
        prop=""
        label="操作"
        min-width="180">
        <template v-slot="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="deleteData(scope.row)">删除</span>
              </template>
      </el-table-column>
    </el-table>
    <i class="el-icon-circle-plus-outline add-icon1"  @click="handleEdit"/>
       </div>
      </div>
    </div>

    <div id="row_3" class="row_box bg_white">
      <div class="header">
        <span>产品列表</span>
      </div>
      <div class="content" style="margin-bottom: 80px;">
      <div class="add-btn">
        <el-button type="primary" size="small" @click="addProduct">添加产品</el-button>
        <span style="color:red;" v-if="!productStandards.length && flag">请选择使用优惠的产品</span>
      </div>
      <el-table
        v-loading="loading" :data="productStandards" style="width:75%;margin-left: 45px;" border highlight-current-row>
      <el-table-column
        prop="date"
        label="序号"
        width="60">
        <template slot-scope="scope">
            <div>{{ scope.$index + 1 }}</div>
          </template>
      </el-table-column>
      <el-table-column
        prop="productCode"
        label="产品编号"
        min-width="180"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="productName"
        label="产品名称"
        min-width="150"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="产品类别" min-width="140" prop="productCategoryName" show-overflow-tooltip/>
        <el-table-column prop="" label="产品单价" min-width="160">
              <template slot-scope="scope">
                {{'￥' + scope.row.productPriceMin + ' ~ ￥' + scope.row.productPriceMax}}
              </template>
            </el-table-column>
      <el-table-column
        prop="onSaleTime"
        label="添加时间"
        min-width="180">
      </el-table-column>
      <el-table-column
        prop=""
        label="操作"
        min-width="180">
        <template v-slot="scope">
                <span class="operation-btn" @click="deleteData_1(scope.row.productId)">删除</span>
              </template>
      </el-table-column>
    </el-table>

    <div class="flex-box submit-btn bg_white">
                    <el-button type="primary" @click="comfirm">确 定</el-button>
                    <el-button @click="$router.go(-1)">取 消</el-button>
                </div>
      </div>
    </div>


    <!-- 添加/编辑优惠弹框 -->
    <el-dialog
      :title="dialogTitle + '内容'"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="500px"
      center
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="80px" :model="submitForm" :rules="submitRules">
          <el-form-item label="名称" prop="couponName">
            <el-input v-model="submitForm.couponName" maxlength="20" placeholder="请输入名称" :disabled="disabled" />
          </el-form-item>
          <el-form-item label="享用额度" prop="threshold">
            <el-input v-model="submitForm.threshold" maxlength="20" type="number" :disabled="disabled" placeholder="请输入额度" />
          </el-form-item>
          <el-form-item label="折扣" prop="couponDiscount">
            <el-input v-model="submitForm.couponDiscount" type="number" :disabled="disabled" placeholder="请输入折扣" />
          </el-form-item>
          <el-form-item label="面额" prop="couponAmount">
            <el-input v-model="submitForm.couponAmount" type="number" :disabled="disabled" placeholder="请输入面额" />
          </el-form-item>
          <el-form-item label="状态" prop="couponStatus">
            <el-radio v-model="submitForm.couponStatus" :label="1">生效</el-radio>
            <el-radio v-model="submitForm.couponStatus" :label="0">失效</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>
     
       <!-- 添加产品弹框 -->
       <el-dialog
        title="未选择产品列表"
        :before-close="closeDialog_1"
        :visible.sync="dialogShow"
        top="12vh"
        width="60%">
        <div class="Popout_content">
            <el-form :inline="true" class="demo-form-inline" ref="proDialog" size="medium" label-width="120px">
        <el-form-item label="搜索产品">
          <el-input v-model.trim="inquire.productName" placeholder="请输入产品名称" @keyup.enter.native="searchList"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form> 
      <el-table ref="msgTabel" v-loading="loading_1" :data="productList" border height="400px"  style="width: 100%"  @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55">
         </el-table-column>
        <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
                {{ (pageParam_1.pageNum - 1) * pageParam_1.pageSize + (scope.$index + 1) }}
            </template>
        </el-table-column>
        <el-table-column label="产品编号" min-width="170" prop="productCode" show-overflow-tooltip/>
        <el-table-column label="产品名称" min-width="160" prop="productName" show-overflow-tooltip/>
        <el-table-column label="产品类别" min-width="140" prop="productCategoryName" show-overflow-tooltip/>
        <el-table-column prop="" label="产品单价" min-width="160">
              <template slot-scope="scope">
                {{'￥' + scope.row.productPriceMin + ' ~ ￥' + scope.row.productPriceMax}}
              </template>
            </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination
                    :current-page="pageParam_1.pageNum"
                    :page-size="pageParam_1.pageSize"
                    :page-sizes="$api.pageSizes"
                    :total="pageParam_1.total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange_1"
                    @current-change="handleCurrentChange_1"
                ></el-pagination>
            </div>
           <div class="btn-box">
            <span slot="footer" class="dialog-footer">
               <el-button @click="closeDialog_1">取 消</el-button>
              <el-button type="primary" @click="submitAdd_1">确 定</el-button>
           </span>
           </div>
            <div style="padding-bottom: 10px;"></div>
        </div>
    </el-dialog>


  </div>
</template>

<script>
export default {
    data() {
        return {
            // 导航栏
            navList: [
                { name: '基本信息', id: 'row_1', active: true },
                { name: '使用门槛', id: 'row_2', active: false },
                { name: '产品列表', id: 'row_3', active: false },
            ],
            mainEl: null,
            couponCode:'', //优惠单号
            // 基本信息
            discountName: '',
            // 基本信息表单验证
            commonRules: {
            discountName: [{ required: true, message: '请输入优惠名称', trigger: 'blur' }],
            couponType: [{ required: true, message: '请选择使用门槛', trigger: 'input' }],
            },
            // 使用门槛的类别
            couponType: 0,
            type: 0, //用于保存回显时的类别
            temArr:[], //用于临时保存回显时的优惠数组
            discountList:[
                {id:1,name:'无门槛',value:3},
                {id:2,name:'满件数享优惠',value:1},
                {id:3,name:'满额享优惠',value:2},
            ],//优惠类别
            submitForm:{
                id:'', 
                couponName:'',//名称
                threshold:'', //件数
                couponDiscount:'',//折扣
                couponAmount:'', //面额
                couponStatus: 1, //状态  0：失效 1：生效
                tempId:Date.now(),
            },//使用门槛字段
            submitRules: {
                couponName: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                threshold: [{ required: true, message: '请输入内容', trigger: 'blur' }],
                couponDiscount: [{ required: true, message: '请输入内容', trigger: 'blur' },],
                couponAmount: [{ required: true, message: '请输入内容', trigger: 'blur' },],
                couponStatus: [{ required: true, message: '请勾选状态', trigger: 'blur' },],
             },
            couponList: [],//使用门槛数据
            dialogTitle: '添加', // 添加优惠弹窗标题
            dialogVisible: false, // 添加优惠弹窗
            inquire: {
                productClassify: "nutrition",
                productName: "",
                productStatus: "on_shelves",
                productIds:[], //上一个页面携带来的id数
            },
            productStandards:[], //产品数据
            dialogShow:false,//产品列表弹框
           pageParam_1: {
            pageNum: 1,
            pageSize: 10,
            total: 0,
           },
           productList:[],//产品列表
           multipleSelection: [],
           loading:false,
           loading_1:false,
           productIdList:[],//已添加产品数组
           flag:false,
           disabled:false,//控制输入框是否可以编辑
        }
    },
    created() {
        this.getDiscountDetail(this.$route.query.id)
        this.flag = true
    },
    mounted() {
        this.mainEl = document.getElementsByClassName("app-main")[0];
        this.mainEl.addEventListener("scroll", this.chanageScroll);
        this.skip(0);
    },
    beforeDestroy() {
        this.mainEl.removeEventListener('scroll', this.chanageScroll)
    },
    // 过滤器
    filters:{
    // 状态处理
   getStatus(val){
    console.log('val: ' + val)
     let newTxt = val == 0 ? "失效 " : "生效"
     return newTxt
    }
    },
    computed:{
      // 计算属性获得文案
      getTxt(){
        let txt 
        if(this.couponType == 1){
           txt ='享用额度(件)'
        }else{
           txt = '享用额度(元)'
        }
        return txt
      },
    },
    methods: {
        // 获取详情数据
        getDiscountDetail(couponCode){
            this.$http.requestGet({url:`/coupon/query/${couponCode}`}).then(res=>{
                console.log('res.',res)
                this.discountName = res.data.discountName
                this.couponCode = res.data.couponCode
                this.couponType = res.data.couponType
                // 设置一个变量用于保存回显时的类别
                this.type = res.data.couponType
                this.couponList = res.data.couponList
                console.log('couponList',this.couponList)
                // 设置一个变量用于保存回显时的优惠数据
                this.temArr = res.data.couponList
                this.productStandards = res.data.productStandards
            })
        },
        // 使用门槛单选框改变时弹出提示框
        changeRadio(e){
          // 判断选择的值是否为当前回显的值，如果不是就弹出提示框
            if(this.couponType != this.type){
              this.couponList = []
            }else{
              return this.couponList = this.temArr
            }
                },
            // 关闭弹窗
        closeDialog() {
            this.$refs.submitForm && (this.$refs.submitForm.resetFields())
            this.dialogVisible = false
            this.submitForm = {
                id:'',
                couponName:'',
                threshold:'',
                couponDiscount:'',
                couponAmount:'',
                couponStatus:1,
                tempId:Date.now(),
            },
            // 重置disabled
            this.disabled = false
        },
        // 添加/编辑优惠
        handleEdit(row){
            console.log('row',row);
            if(row.couponName){
                this.dialogTitle = '编辑'
                this.submitForm = {
                id:row.id,
                couponName:row.couponName,
                threshold:row.threshold,
                couponDiscount:row.couponDiscount,
                couponAmount:row.couponAmount,
                couponStatus:row.couponStatus,
                tempId:row.tempId,
            }
            this.disabled = row.id ? true : false
            }else{
                this.dialogTitle = '添加'
                this.closeDialog()
            }
            this.dialogVisible = true
        },
           // 提交添加/编辑优惠
        submitAdd() {
           this.$refs.submitForm.validate(valid => {
            if (valid) {
            //   将输入框里的内容赋值到表格中
            // 先判断是否有id 如果有id 通过id进行判断是编辑还是新增
            let list = JSON.parse(JSON.stringify(this.submitForm))
            if(list.id){
            let bol = this.couponList.some(item=>item.id == list.id)
            console.log('bol:', bol)
            bol ? this.couponList = this.couponList.map(item => item.id == list.id ? list : item) : this.couponList.push(list)
            this.dialogVisible = false
            }else{
            // 如果没有id  则通过新增的tempId进行判断
            let bol = this.couponList.some(item=>item.tempId == list.tempId)
            console.log('bol:', bol)
            bol ? this.couponList = this.couponList.map(item => item.tempId == list.tempId ? list : item) : this.couponList.push(list)
            this.dialogVisible = false
            }
            } else {
                return false
            }
      })
       },
      //  优惠门槛删除
        deleteData(row){
          // 有id通过id进行删除 没id通过tempId进行删除
          if(row.id){
          this.couponList = this.couponList.filter((item) => {
          return item.id != row.id;
        });
          }else{
          this.couponList = this.couponList.filter((item) => {
          return item.tempId != row.tempId;
        })
          }
        },
                // 关闭产品列表弹框
        closeDialog_1(){
        this.dialogShow =false
        this.inquire = {
            productClassify: "nutrition",
            productName: "",
            productStatus: "on_shelves",
            productIds:[],
        }
        },
        // 多选框
        handleSelectionChange(val) {
        this.multipleSelection = val;
      },
         // 提交添加产品   
       submitAdd_1() {
        if (!this.multipleSelection.length) {
            return this.$message.warning('请选择产品')
        }
        console.log('this.multipleSelection', this.multipleSelection)
        // 去重处理
        // let setArr = [...this.multipleSelection,...this.productStandards]
        // const map = new Map();
        // this.productStandards = setArr.filter(v => !map.has(v.productId) && map.set(v.productId, 1));
        this.productStandards = [...this.multipleSelection,...this.productStandards]
        this.closeDialog_1()
        // console.log('this.productStandards',this.productStandards)
       },
        // 查询产品
        searchList(){
           this.pageParam_1.pageNum = 1
           this.getProductList()
        },
        // 添加产品
        addProduct(){
            this.getProductList()
            this.dialogShow = true
        },
        //  产品列表删除
        deleteData_1(id){
          this.productStandards = this.productStandards.filter((item) => {
          return item.productId != id;
        });
        console.log('deleteData_1',this.productStand)
        },
         // 获取产品列表
        getProductList() {
          let productIds = []
          this.productStandards.forEach(item=>{
            productIds.push(item.productId)
          })
          this.inquire.productIds = productIds
        let data = {
            pageNum: this.pageParam_1.pageNum,
            pageSize: this.pageParam_1.pageSize,
            param: this.inquire,
        }

        this.loading_1 = true;
        this.$http.requestPost({
            url: '/product/page',
            param: data,
            loading: false
        }).then(res => {
            this.loading_1 = false;
            this.productList = res.data.list;
            this.pageParam_1.total = res.data.total;
        }).catch(err => {
            this.loading_1 = false;
            console.log(err)
        })
        },
          // 产品列表页容量改变
        handleSizeChange_1(size) {
            this.pageParam_1.pageNum = 1;
            this.pageParam_1.pageSize = size;
            this.getProductList()
        },
        // 产品列表页码改变
        handleCurrentChange_1(page) {
            this.pageParam_1.pageNum = page;
            this.getProductList()
        },
      //  点击确认提交所有表单按钮
      comfirm(){
        // 进行表单校验
        if(!this.couponList.length){
          return this.$message.warning('请添加优惠')
        }
        if(!this.discountName || !this.couponCode || !this.productStandards.length){
          return
        }else{
          this.productStandards.forEach(v => {
                  this.productIdList.push(v.productId)
               })
          let param = {
          discountName:this.discountName,
          couponType:this.couponType,
          couponCode:this.couponCode,
          couponList:this.couponList,
          productStandards:this.productIdList
        }
        this.$http.requestPost({
            url: '/coupon/save',
            param: param,
            loading: false
        }).then(res=>{
          this.$message.success('编辑成功！')
          this.$router.go(-1);
        }).catch()
        }
       
      },
        // nav bar 跳转
        skip(index) {
            const i1 = this.navList.findIndex(item => item.active)
            this.navList[i1].active = false;
            this.navList[index].active = true;

            // 获取选择tabs要跳转元素的id,设置滚动条偏移到该元素的位置，类似锚链接
            const id = this.navList[index].id
            document.querySelector('#' + id).scrollIntoView(true)
        },
        // nav改变
        changeNav(index) {
          const i1 = this.navList.findIndex(item => item.active)
          this.navList[i1].active = false
          this.navList[index].active = true
        },
        // 函数防抖
        debounce(fn, interval = 200) {
          if (this.timer) clearTimeout(this.timer);
          let args = arguments
          this.timer = setTimeout(() => {
                fn.apply(this, args)
          }, interval)
        },
        // 滚动条改变
        chanageScroll() {
            var scrollHeight = this.mainEl.scrollHeight
            var scrollTop = this.mainEl.scrollTop
            var height = this.mainEl.offsetHeight
            this.debounce(function() {
                var rowHight = document.getElementById('row_2').offsetHeight
                let scroll = scrollTop + height;
                if (scroll <= 637) {
                    this.changeNav(0)
                } else if (scroll > 637 && scroll <= (637 + rowHight)) {
                    this.changeNav(1)
                } else if (scroll > (637 + rowHight) && scroll <= (637 + rowHight + 1297)) {
                    this.changeNav(2)
                } else if (scroll > (637 + rowHight + 1297) && scroll <= (637 + rowHight + 1297 + 125)) {
                    this.changeNav(3)
                } else if (scroll > (637 + rowHight + 1297 + 125) && scroll <= (637 + rowHight + 1297 + 125 + 125)) {
                    this.changeNav(4)
                } else {
                    this.changeNav(5)
                }
            }, 100)
        },
    }
}
</script>

<style lang="scss" scoped >
    .main-box {
        background-color: #f5f5f5;
        position: static;
    }

    .nav-bar {
        padding: 12px 20px;
        margin-bottom: 10px;
        position: absolute;
        top: 68px;
        left: 10px;
        z-index: 2000;
        width: calc(100% - 23px);
        box-shadow: 0px 2px 1px 0px #f5f5f5;

        & >a {
            margin-right: 30px;
            font-size: 14px;
            padding: 10px 0;
            border-top: 3px solid transparent;
        }

        .active {
            border-color: #1890ff;
            color: #1890ff;
        }
    }

    .row_box {
        margin-bottom: 10px;
        font-size: 14px;

        .header {
            padding: 18px 20px 10px;
            border-bottom: 1px solid #cccccc;
        }

        .content {
            padding: 10px;
        }
    }

    // 基础信息
    #row_1 {
        padding-top: 50px;
        .el-form {
            max-width: 600px;
        }
    }

    // 销售信息
    #row_2 {
        .size_box {
            & > label, & div > label {
               min-width: 100px;
               padding-right: 10px;
               text-align: right;
            }

            & > div.flex-box {
               flex: 1;
            }
        }
    }

    // 图文描述
    // 上传
    .tip-box {
        position: relative;
        .tip {
            position: absolute;
            top: 26px;
            left: 18%;
            font-size: 12px;
            color: #ccc;
        }
    }
    .editor {
        max-width: 1000px;
        max-height: 420px;
        .v-note-wrapper.shadow {
            height: 420px;
        }
    }

    //产品参数
    .form-list {
        max-width: 1000px;
        flex-wrap: wrap;

        .form-item {
            width: 320px;
            margin: 0 10px 10px 0;
            position: relative;

            label {
                width: 120px;
                text-align: right;
                font-weight: 300;
            }

            .form-item-content {
                flex: 1;
            }
        }
    }

    // 搜索标签
    #row_4, #row_5 {
        .content {
            & > div {
                flex-wrap: wrap;
                padding: 14px 42px;
                .el-tag {
                    margin: 0 8px 5px 0;
                }
            }
        }
    }

    #row_6 {
        padding-bottom: 60px;
    }

    .submit-btn {
        justify-content: center;
        padding: 20px 0;
        position: absolute;
        bottom: 0;
        left: 10px;
        width: calc(100% - 23px);
        z-index: 2000;
        box-shadow: 0px -3px 0px 0px #f5f5f5;

        button {
            padding: 12px 35px;
        }
    }
    .close_ben{
        position: absolute;
        right: -10px;
        top: -10px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        font-size: 20px;
    }
    .close_ben:hover{
        color: #1890ff;
        cursor: pointer;
    }
    
    // 使用门槛表格
    .table-box{
        width: 60%;
        margin-left: 45px;
        position: relative;
        .add-icon{
            position: absolute;
            bottom: 0;
            right: -30px;
            color:#409eff;
            font-size: 24px
        }
        .add-icon1{
            position: absolute;
            bottom: 0;
            right: 5px;
            color:#409eff;
            font-size: 24px
        }
    }

    // 新增产品按钮
    .add-btn{
      margin: 20px;
    }
    // 产品列表确定取消按钮
    .btn-box{
        text-align: right;
        margin: 20px;
    }
</style>
