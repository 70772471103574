<template>
<!-- 产品类别列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="类别名称">
          <el-input v-model="inquire.categoryName" placeholder="类别名称" @keyup.enter.native="searchList"></el-input>
        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="inquire.categoryStatus" placeholder="请选择" clearable class="form-select">
            <el-option v-for="item in showState" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addClass">添加类别</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border row-key="categoryId" :tree-props="{children: 'childes'}" height="400px" style="width: 100%">
            <el-table-column prop="" label="" width="60"></el-table-column>
            <el-table-column prop="categoryName" label="类别名称" min-width="160"></el-table-column>
            <el-table-column prop="categoryDescription" label="备注" min-width="160"></el-table-column>
            <el-table-column prop="categoryOrder" label="排序" min-width="100"></el-table-column>
            <el-table-column prop="createTime" label="添加时间" min-width="160"></el-table-column>
            <el-table-column prop="categoryStatus" label="显示状态" min-width="100">
              <template slot-scope="scope">
                {{ $api.getValue(showState, scope.row.categoryStatus, 'name') }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="200" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="addChilClass(scope.row)" v-if="scope.row.categoryLevel == 1">添加子类</span> 
                <span class="operation-btn_partition" v-if="scope.row.categoryLevel == 1"> / </span>
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span> 
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="setState(scope.row, 'h')" v-show="scope.row.categoryStatus === 'display'">隐藏</span>
                <span class="operation-btn" @click="setState(scope.row, 's')" v-show="scope.row.categoryStatus === 'hide'">显示 </span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog
    :title="dialogTitle"
    :visible.sync="dialogVisible"
    :before-close="closeDialog"
    width="500px">
    <div class="Popout_content">
        <el-form label-width="80px" ref="submitForm" :model="submitForm" :rules="submitRules">
            <el-form-item :label="addChil?'父类名称':'类别名称'" prop="categoryName">
                <el-input v-model="submitForm.categoryName" maxlength="20" :placeholder="addChil?'父类名称':'类别名称'" :readonly='addChil'></el-input>
            </el-form-item>
            <el-form-item label="子类名称" :prop="addChil?'categoryName2':''" v-show="addChil">
                <el-input v-model="submitForm.categoryName2" maxlength="20" placeholder="子类名称"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input
                  type="textarea"
                  :rows="3"
                  maxlength="150"
                  placeholder="请输入内容"
                  v-model="submitForm.categoryDescription">
                </el-input>
            </el-form-item>
            <el-form-item label="排序" prop="categoryOrder">
                <el-input v-model.number="submitForm.categoryOrder" maxlength="5" placeholder="排序"></el-input>
            </el-form-item>
        </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitClass">确 定</el-button>
    </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        categoryName: '',
        categoryStatus: '',
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [],  
      showState:[
        {name: '显示', value: "display"},
        {name: '隐藏', value: "hide"},
      ],
      loading: false,

      dialogTitle: '添加类别', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      addChil: false,  // 判断是否添加子类
      submitForm: {
        categoryName:  '',
        categoryName2:  '',
        categoryDescription: '',
        categoryOrder: '',
      },
      submitRules: {
        categoryName: [{required: true, message: '请输入类别名称', trigger: 'blur'}],
        categoryName2: [{required: true, message: '请输入子类名称', trigger: 'blur'}],
        categoryOrder: [
          {required: true, message: '请输入排序', trigger: 'blur'},
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ]
      },
      isAddClass: true, // 是否为添加 
      editRow: {},
    };
  },
  created() {
    // 获取产品列表
    this.getDataList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getDataList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getDataList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getDataList()
    },

    // 获取产品列表
    getDataList() {
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/operation/message/category/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    // 添加产品类别
    addClass() {
      this.dialogTitle = '添加类别';
      this.addChil = false;
      this.dialogVisible = true;
      this.isAddClass = true;
      this.editRow = {};
    },
    // 打开添加子类弹窗
    addChilClass(row) {
      this.dialogTitle = '添加子类';
      this.addChil = true;
      this.dialogVisible = true;
      this.isAddClass = true;
      this.editRow = row;
      this.submitForm.categoryName = row.categoryName;
    },
    // 修改类别
    handleEdit(row) {
      this.dialogTitle = '编辑类别';
      this.addChil = false;
      this.isAddClass = false;
      this.dialogVisible = true;
      this.editRow = row;
      this.submitForm = {
        categoryName:  row.categoryName,
        categoryDescription: row.categoryDescription,
        categoryOrder: row.categoryOrder,
      }
    },
    // 提交类别添加
    submitClass() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          let param = {
            categoryName: this.submitForm.categoryName,
            categoryDescription: this.submitForm.categoryDescription,
            categoryOrder: this.submitForm.categoryOrder,
            categoryLevel: this.isAddClass ? this.editRow.categoryLevel ? (this.editRow.categoryLevel + 1) : 1 : this.editRow.categoryLevel
          };
          if (!this.isAddClass) {
            param.categoryId = this.editRow.categoryId;
          }
          if (this.addChil) {
            param.categoryParentId = this.editRow.categoryId;
            param.categoryName = this.submitForm.categoryName2;
          }
          this.submit(param)
        } else {
          return false
        }
      })
    },
    // 提交
    submit(param) {
      this.$http.requestPost({
        url: '/operation/message/category/save',
        param: param
      }).then(res => {
        this.getDataList()
        this.$message({
          message: '操作成功!',
          type: 'success'
        })
        this.closeDialog()
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields());
      this.dialogVisible = false;
      this.submitForm = {
        categoryName:  '',
        categoryName2:  '',
        categoryDescription: '',
        categoryOrder: '',
      }
    },

    // 修改状态
    setState(row, type) {
      let name = type === 'h'? '隐藏' : '显示';
      this.$confirm('是否确定'+ name +'?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let param = {
          categoryId: row.categoryId,
          categoryStatus: type === 'h'? 'hide' : 'display'
        }
        this.$http.requestPut({url:'/operation/message/category/change/status/' + row.categoryId}).then(res => {
          this.getDataList()
          this.$message.success('操作成功!')
        })
      }).catch(err => {})
    },

  },
};
</script>

<style lang="scss" scoped>
  
</style>
