var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_2 bg_white flex-box" }, [
        _c("div", { staticClass: "row_left" }, [
          _c("h4", [_vm._v("退货单信息")]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v(
              "\n        退货单状态：" +
                _vm._s(
                  _vm.$api.getValue(
                    _vm.$api.returnOrderState,
                    _vm.pageData.orderStatus,
                    "name"
                  )
                ) +
                " " +
                _vm._s(_vm.pageData.afterSalesRequestReport ? "(售后)" : "") +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("申请时间：" + _vm._s(_vm.pageData.applyReturnTime)),
          ]),
          _vm._v(" "),
          _vm.pageData.orderStatus != "auditing"
            ? _c("p", { staticClass: "color_light-black" }, [
                _vm._v("审核时间：" + _vm._s(_vm.pageData.auditTime)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pageData.orderStatus == "returned"
            ? _c("p", { staticClass: "color_light-black" }, [
                _vm._v("签收时间：" + _vm._s(_vm.pageData.signTime || "无")),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pageData.orderStatus == "returned"
            ? _c("p", { staticClass: "color_light-black" }, [
                _vm._v("退款时间：" + _vm._s(_vm.pageData.returnTime)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pageData.orderStatus == "returned" ||
          _vm.pageData.orderStatus == "pending_sign"
            ? _c("p", { staticClass: "color_light-black" }, [
                _vm._v(
                  "\n        退货物流单号：" +
                    _vm._s(_vm.pageData.returnLogisticsCode || "无") +
                    "\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pageData.orderStatus == "returned" ||
          _vm.pageData.orderStatus == "pending_sign"
            ? _c("p", { staticClass: "color_light-black" }, [
                _vm._v(
                  "\n        退货地址：" +
                    _vm._s(_vm.pageData.returnAddressDetail || "无") +
                    "\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pageData.orderStatus == "unaudited"
            ? _c("p", { staticClass: "color_light-black" }, [
                _vm._v(
                  "不通过原因：" + _vm._s(_vm.pageData.rejectReason || "无")
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v(
              "配送方式：" +
                _vm._s(
                  _vm.$api.getValue(
                    _vm.$api.deliveryMethod,
                    _vm.pageData.deliveryMethod,
                    "name"
                  )
                )
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("收货人姓名：" + _vm._s(_vm.pageData.recipient)),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v(
              "收货人手机号：" + _vm._s(_vm.pageData.recipientPhoneNumber)
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("\n        收货人地址：\n        "),
            _c("span", [_vm._v(_vm._s(_vm.pageData.recipientAddressDetail))]),
          ]),
          _vm._v(" "),
          _c("p", { staticStyle: { height: "24px" } }),
          _vm._v(" "),
          _c("div", { staticClass: "color_light-black flex-box" }, [
            _c("span", { staticClass: "row-title" }, [_vm._v("购物凭证：")]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imageUrl,
                    expression: "imageUrl",
                  },
                ],
              },
              [
                _c(
                  "d-upload",
                  {
                    attrs: {
                      "upload-type": "picture-card",
                      "file-list": _vm.fileList,
                      accept: ".gif,.jpeg,.jpg,.png",
                    },
                  },
                  [
                    _c("div", { staticClass: "tip-box" }, [
                      _c("i", { staticClass: "el-icon-plus" }),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.imageUrl,
                    expression: "!imageUrl",
                  },
                ],
              },
              [_vm._v("无")]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "color_light-black flex-box" }, [
            _c("span", { staticClass: "row-title" }, [_vm._v("退货凭证：")]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.returnProof,
                    expression: "returnProof",
                  },
                ],
                staticClass: "image_wrapper",
              },
              _vm._l(_vm.returnProof, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "image_box",
                    on: {
                      click: function ($event) {
                        return _vm.perviewImg(item)
                      },
                    },
                  },
                  [_c("img", { attrs: { src: item, alt: "" } })]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.returnProof,
                    expression: "!returnProof",
                  },
                ],
              },
              [_vm._v("无")]
            ),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "color_light-black" }, [
            _vm._v("备注信息：" + _vm._s(_vm.pageData.orderRemark || "无")),
          ]),
          _vm._v(" "),
          _vm.pageData.orderStatus == "returned"
            ? _c("p", { staticClass: "color_light-black" }, [
                _vm._v(
                  "请示报告号：" +
                    _vm._s(_vm.pageData.afterSalesRequestReport || "无")
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_3 bg_white" }, [
        _c("h4", [_vm._v("商品信息")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.pageData.items, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "序号", prop: "date", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.$index + 1) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品信息",
                    "min-width": "220",
                    prop: "available",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "flex-box" }, [
                            scope.row.productImg
                              ? _c("img", {
                                  staticClass: "img-box",
                                  attrs: { src: scope.row.productImg, alt: "" },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "hospital-name" }, [
                              _vm._v(_vm._s(scope.row.productName)),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "单价",
                    "min-width": "100",
                    prop: "productPrice",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s("￥" + scope.row.productPrice || 0) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "数量", "min-width": "100", prop: "itemNum" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "小计",
                    "min-width": "100",
                    prop: "itemAmount",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                "" +
                                  (scope.row.subtotalCoupon
                                    ? "￥" + scope.row.subtotalCoupon
                                    : 0)
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "优惠金额", "min-width": "100", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                "" +
                                  (scope.row.couponDiscountAmount
                                    ? "￥" + scope.row.couponDiscountAmount
                                    : 0)
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "实收金额", "min-width": "100", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                "" +
                                  (scope.row.paidInAmountCoupon
                                    ? "￥" + scope.row.paidInAmountCoupon
                                    : 0)
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "show-money" }, [
          _c("ul", [
            _c("li", [
              _vm._v("\n          商品金额：\n          "),
              _c("span", { staticClass: "block_span" }, [
                _vm._v("￥ " + _vm._s(_vm.pageData.orderTotalPrice || 0)),
              ]),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "line-box" }),
            _vm._v(" "),
            _c("li", { staticClass: "bold" }, [
              _vm._v("\n          退款金额：\n          "),
              _c("span", { staticClass: "block_span color_red" }, [
                _vm._v("￥ " + _vm._s(_vm.pageData.paidInAmount || 0)),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.pageData.merchantName
        ? _c("div", { staticClass: "row_4 bg_white" }, [
            _c("h4", [_vm._v("商家/医院信息")]),
            _vm._v(" "),
            _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("商家名称：")]),
              _vm._v("\n      " + _vm._s(_vm.pageData.merchantName) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("商家编号：")]),
              _vm._v("\n      " + _vm._s(_vm.pageData.merchantCode) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("医院名称：")]),
              _vm._v("\n      " + _vm._s(_vm.pageData.hospitalName) + "\n    "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("医院编号：")]),
              _vm._v("\n      " + _vm._s(_vm.pageData.hospitalCode) + "\n    "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row_4 bg_white" }, [
        _c("h4", [_vm._v("订单信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("订单类型：")]),
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$api.getValue(
                  _vm.$api.orderClassify,
                  _vm.pageData.orderClassify,
                  "name"
                )
              ) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("订单编号：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.orderCode) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("下单时间：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.orderTime) + "\n    "),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("付款时间：")]),
          _vm._v("\n      " + _vm._s(_vm.pageData.paymentTime) + "\n    "),
        ]),
        _vm._v(" "),
        _vm.pageData.arriveTime
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [_vm._v("送达时间：")]),
              _vm._v("\n      " + _vm._s(_vm.pageData.arriveTime) + "\n    "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.pageData.afterSalesRequestReport
          ? _c("p", { staticClass: "color_light-black" }, [
              _c("span", { staticClass: "title-box" }, [
                _vm._v("确认收货时间："),
              ]),
              _vm._v("\n      " + _vm._s(_vm.pageData.receiptTime) + "\n    "),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row_5 row_4 bg_white" },
        [
          _c("h4", [_vm._v("出库批次信息")]),
          _vm._v(" "),
          _vm._l(_vm.pageData.items, function (item, index) {
            return _c(
              "div",
              { key: index, staticStyle: { "margin-bottom": "10px" } },
              [
                item.batch.length >= 1
                  ? _c(
                      "div",
                      [
                        _c("div", [
                          _vm._v("商品名称：" + _vm._s(item.productName)),
                        ]),
                        _vm._v(" "),
                        _vm._l(item.batch, function (batchItem, batchIndex) {
                          return _c("div", { key: batchIndex }, [
                            _c("p", { staticClass: "color_light-black" }, [
                              _c("span", { staticClass: "title-box" }, [
                                _vm._v("批次号："),
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(batchItem.batchNo) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "color_light-black" }, [
                              _c("span", { staticClass: "title-box" }, [
                                _vm._v("发货数量："),
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(batchItem.deliverCount) +
                                  "\n          "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "color_light-black" }, [
                              _c("span", { staticClass: "title-box" }, [
                                _vm._v("有效期："),
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(batchItem.produceTime) +
                                  " ~ " +
                                  _vm._s(batchItem.expireTime) +
                                  "\n          "
                              ),
                            ]),
                          ])
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row_5 row_4 bg_white" },
        [
          _c("h4", [_vm._v("退货批次信息")]),
          _vm._v(" "),
          _vm._l(_vm.pageData.items, function (item, index) {
            return _c(
              "div",
              { key: index, staticStyle: { "margin-bottom": "10px" } },
              [
                item.returnBatch.length >= 1
                  ? _c(
                      "div",
                      [
                        _c("div", [
                          _vm._v("商品名称：" + _vm._s(item.productName)),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          item.returnBatch,
                          function (batchItem, batchIndex) {
                            return _c("div", { key: batchIndex }, [
                              _c("p", { staticClass: "color_light-black" }, [
                                _c("span", { staticClass: "title-box" }, [
                                  _vm._v("批次号："),
                                ]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(batchItem.batchNo) +
                                    "\n          "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "color_light-black" }, [
                                _c("span", { staticClass: "title-box" }, [
                                  _vm._v("发货数量："),
                                ]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(batchItem.deliverCount) +
                                    "\n          "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", { staticClass: "color_light-black" }, [
                                _c("span", { staticClass: "title-box" }, [
                                  _vm._v("有效期："),
                                ]),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(batchItem.produceTime) +
                                    " ~ " +
                                    _vm._s(batchItem.expireTime) +
                                    "\n          "
                                ),
                              ]),
                            ])
                          }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_5 row_4 bg_white" }, [
        _c("h4", [_vm._v("专员信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("专员姓名：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.attacheName || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("专员编号：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.attacheCode || "无") + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_5 row_4 bg_white" }, [
        _c("h4", [_vm._v("业务员信息")]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("业务员姓名：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.intendantName || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("业务员编号：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.intendantCode || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("二维码编号：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.qrCodeSerial || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家端名称：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.qrMerchantName || "无") + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "color_light-black" }, [
          _c("span", { staticClass: "title-box" }, [_vm._v("商家端编号：")]),
          _vm._v(
            "\n      " + _vm._s(_vm.pageData.qrMerchantCode || "无") + "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_6 row_4 bg_white" }, [
        _c("h4", [_vm._v("优惠信息")]),
        _vm._v(" "),
        _c(
          "p",
          {
            staticClass: "color_light-black",
            staticStyle: { margin: "0 0 10px 50px" },
          },
          [
            _c("span", { staticStyle: { "margin-right": "50px" } }, [
              _vm._v("优惠券名称：" + _vm._s(_vm.pageData.couponName || "-")),
            ]),
            _vm._v(" "),
            _c("span", { staticStyle: { "margin-right": "50px" } }, [
              _vm._v(
                "\n        优惠券ID：" +
                  _vm._s(
                    _vm.pageData.customerCoupon &&
                      _vm.pageData.customerCoupon.couponCode
                      ? _vm.pageData.customerCoupon.couponCode
                      : "-"
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticStyle: { "margin-right": "50px" } }, [
              _vm._v(
                "会员等级：" +
                  _vm._s(_vm.$public.getVipLevel(_vm.pageData.vipLevel))
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.pageData.couponIntendantVo
          ? _c(
              "p",
              {
                staticClass: "color_light-black",
                staticStyle: { margin: "0 0 10px 50px" },
              },
              [
                _c("span", { staticStyle: { "margin-right": "50px" } }, [
                  _vm._v(
                    "业务员编号：" +
                      _vm._s(
                        _vm.pageData.couponIntendantVo.intendantCode || "无"
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-right": "50px" } }, [
                  _vm._v(
                    "业务员姓名：" +
                      _vm._s(
                        _vm.pageData.couponIntendantVo.intendantName || "无"
                      )
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-right": "50px" } }, [
                  _vm._v(
                    "二维码编号：" +
                      _vm._s(
                        _vm.pageData.couponIntendantVo.qrCodeSerial || "无"
                      )
                  ),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table" },
          [
            _c(
              "el-table",
              {
                staticStyle: { width: "100%" },
                attrs: { data: _vm.pageData.items, border: true },
              },
              [
                _c("el-table-column", {
                  attrs: { label: "序号", prop: "date", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.$index + 1) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品信息",
                    "min-width": "220",
                    prop: "available",
                    "show-overflow-tooltip": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass: "flex-box",
                              staticStyle: { "align-items": "center" },
                            },
                            [
                              scope.row.productImg
                                ? _c("img", {
                                    staticClass: "img-box",
                                    attrs: {
                                      src: scope.row.productImg,
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "margin-left": "5px" } },
                                [_vm._v(_vm._s(scope.row.productName))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "规格",
                    "min-width": "100",
                    prop: "productNorms",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "单价",
                    "min-width": "100",
                    prop: "productPrice",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s("￥" + scope.row.productPrice) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "数量", "min-width": "100", prop: "itemNum" },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "优惠方式",
                    "min-width": "100",
                    prop: "couponMode",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(scope.row.couponMode || "-") +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "优惠金额", "min-width": "100", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.amountCouponInformation
                                  ? "￥" + scope.row.amountCouponInformation
                                  : 0
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { label: "优惠价", "min-width": "100", prop: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                scope.row.priceCouponInformation
                                  ? "￥" + scope.row.priceCouponInformation
                                  : 0
                              ) +
                              "\n          "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.pageData.orderStatus == "auditing"
        ? _c(
            "div",
            { staticClass: "flex-box bottom-btn" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.pass } },
                [_vm._v("通过")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.reject } }, [_vm._v("驳回")]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.close,
            visible: _vm.dialogVisible_1,
            title: "驳回原因",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_1 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "驳回原因", prop: "rejectReason" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          rows: "6",
                          type: "textarea",
                          maxlength: "150",
                        },
                        model: {
                          value: _vm.submitForm.rejectReason,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "rejectReason", $$v)
                          },
                          expression: "submitForm.rejectReason",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: { title: "通过", visible: _vm.dialogVisible_2, width: "70%" },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_2 = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { size: "medium", "label-position": "top" },
                },
                [
                  _vm._l(_vm.detailInfo.items, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "产品信息" } },
                          [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "842px" },
                                attrs: {
                                  data: [_vm.detailInfo.items[index]],
                                  border: "",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "date",
                                    label: "序号",
                                    width: "60",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(scope.$index + 1) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "产品信息",
                                    width: "200",
                                    prop: "",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "flex-box" },
                                              [
                                                scope.row.productImg
                                                  ? _c("img", {
                                                      staticClass: "img-box",
                                                      attrs: {
                                                        src: scope.row
                                                          .productImg,
                                                        alt: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "hospital-name",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.productName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "单价（￥）",
                                    width: "120",
                                    prop: "productPrice",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "数量",
                                    width: "120",
                                    prop: "itemNum",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "小计",
                                    width: "120",
                                    prop: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  "" +
                                                    (scope.row.subtotalCoupon
                                                      ? "￥" +
                                                        scope.row.subtotalCoupon
                                                      : 0)
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "实收金额",
                                    width: "120",
                                    prop: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  "" +
                                                    (scope.row
                                                      .paidInAmountCoupon
                                                      ? "￥" +
                                                        scope.row
                                                          .paidInAmountCoupon
                                                      : 0)
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c(
                                  "el-table-column",
                                  {
                                    attrs: {
                                      prop: "",
                                      label: "操作",
                                      width: "100",
                                      fixed: "right",
                                    },
                                  },
                                  [
                                    _vm.batchShow
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "operation-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openSelectBatch(
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("选择批次")]
                                          ),
                                        ]
                                      : [
                                          _vm._v(
                                            "\n                  —\n                "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _vm.batchShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "退货信息" } },
                        _vm._l(_vm.detailInfo.items, function (item, index) {
                          return _c(
                            "div",
                            { key: index },
                            [
                              _c("div", { staticStyle: { width: "100%" } }, [
                                _vm._v(_vm._s(item.productName)),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticStyle: { width: "872px" },
                                  attrs: {
                                    data: _vm.detailInfo.items[index].batchList,
                                    border: "",
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "date",
                                      label: "序号",
                                      width: "60",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(scope.$index + 1) +
                                                  "\n                "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "批次",
                                      width: "150",
                                      prop: "batchNo",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "有效期（天）",
                                      width: "120",
                                      prop: "termOfValidity",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "出库数量",
                                      width: "120",
                                      prop: "deliverCount",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "商品编号",
                                      width: "140",
                                      prop: "produceTime",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "添加时间",
                                      width: "140",
                                      prop: "expireTime",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "数量",
                                      width: "160",
                                      prop: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c("el-input", {
                                                on: {
                                                  blur: function ($event) {
                                                    return _vm.verifyInt(
                                                      scope.row.num,
                                                      index,
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.num,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "num",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "scope.row.num",
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "",
                                      label: "操作",
                                      width: "100",
                                      fixed: "right",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "operation-btn",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteBatch(
                                                        index,
                                                        scope.row.$index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmRefund },
                },
                [_vm._v("确 认")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择批次",
            visible: _vm.dialogVisible_batch,
            width: "80%",
            top: "5vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_batch = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "批次" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "批次", clearable: "" },
                        model: {
                          value: _vm.inquire_batch.batchNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_batch, "batchNo", $$v)
                          },
                          expression: "inquire_batch.batchNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.searchList_batch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "batchTable",
                  attrs: { data: _vm.batchTable, border: "", height: "400px" },
                  on: { "selection-change": _vm.handleBatchChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  (_vm.pageParam_batch.pageNum - 1) *
                                    _vm.pageParam_batch.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "批次",
                      "min-width": "160",
                      prop: "batchNo",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "库存", "min-width": "120", prop: "count" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "生产日期",
                      "min-width": "160",
                      prop: "produceTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "到期日期",
                      "min-width": "160",
                      prop: "expireTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "有效期（天）",
                      "min-width": "120",
                      prop: "termOfValidity",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "入库时间",
                      "min-width": "160",
                      prop: "createTime",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作人",
                      "min-width": "160",
                      prop: "operatorName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_batch.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_batch.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_batch.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_batch,
                      "current-change": _vm.handleCurrentChange_batch,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible_batch = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmBatchSelect },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("d-preview", {
        attrs: {
          "show-dialog": _vm.showDialog,
          "file-url": _vm.fileUrl,
          close: _vm.showDialog,
        },
        on: {
          "update:close": function ($event) {
            _vm.showDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }