<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="inquire.searchType" placeholder="请选择" class="width-140" @change="selectValue = ''">
              <el-option label="订单编号" value="orderCode" />
              <el-option label="商家编号" value="merchantCode" />
              <el-option label="商家" value="merchantName " />
            </el-select>
            <el-input v-model="inquire.searchValue" placeholder="" class="width-180" @keyup.enter.native="searchList" />
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
          <el-button type="primary" size="small" @click="exportTable">导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="600px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="merchantCode" label="商家编号" min-width="200" show-overflow-tooltip />
            <el-table-column prop="orderCode" label="申购单编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">{{ scope.row.orderCode }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="merchantName" label="商家" min-width="200" show-overflow-tooltip />
            <el-table-column prop="total" label="申购总价（￥）" min-width="200">
              <template slot-scope="scope">
                {{ '￥' + getTotalPrices(scope.row.items) }}
              </template>
            </el-table-column>
            <el-table-column prop="whetherSelection" label="申购单状态" min-width="120">已出库</el-table-column>
            <el-table-column prop="purchaseTime" label="申购时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span> 
<!--                <span class="operation-btn_partition"> / </span>-->
<!--                <span class="operation-btn" @click="handleOutbound(scope.row)">出库</span> -->
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
          </div>
        </template>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog
      title="出库"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <div class="Popout_content">
        <el-form class="demo-form-inline" size="medium">
          <div v-for="(item,index) in detailInfo.items" :key="index">
            <el-form-item label="产品信息">
              <el-table v-loading="loading" :data="[detailInfo.items[index]]" border class="table-box">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="产品信息" width="200" prop="" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <div class="flex-box">
                      <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box">
                      <div class="hospital-name">
                        <div class="txt_box" :class="{'flex-box':!scope.row.productImg,'flex-center':!scope.row.productImg}">
                          <img v-if="scope.row.isGifts" src="@/assets/image/gift_icon.png" alt="" class="icon_box" :class="{no_img:!scope.row.productImg}">
                          {{ scope.row.productName }}
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="单价（￥）" width="120" prop="itemPrice" />
                <el-table-column label="购买数量" width="120" prop="itemNum" />
                <el-table-column label="退货数量" width="120" prop="returnNum">
                  <template slot-scope="scope">
                    {{ scope.row.returnNum || '0' }}
                  </template>
                </el-table-column>
                <el-table-column label="小计" width="120" prop="">
                  <template slot-scope="scope">
                    {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                  </template>
                </el-table-column>
                <el-table-column label="实收金额" width="120" prop="">
                  <template slot-scope="scope">
                    {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
            <el-form-item label="批次信息">
              <el-table v-loading="loading" :data="[detailInfo.items[index].batch]" border class="table-box">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="批次" width="160" prop="batchNo" />
                <el-table-column label="库存" width="120" prop="count" />
                <el-table-column label="生产日期" width="160" prop="produceTime" />
                <el-table-column label="到期日期" width="160" prop="expireTime" />
                <el-table-column label="有效期（天）" width="120" prop="effectiveDays" />
                <el-table-column label="入库时间" width="160" prop="createTime" />
                <el-table-column label="出库数量" width="120" prop="deliverCount" />
              </el-table>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { exportPurchaseOrderOutbound } from '@/api/order'
export default {
  components: {
  },
  data() {
    return {
      // 查询参数
      inquire: {
        purchaseStatus: 'deducted', // 已出库
        searchType: 'orderCode',
        searchValue: ''
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],

      // 出库
      dialogVisible: false, // 出库弹窗
      detailInfo: {
        items: []
      }

    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http.requestPost({
        url: '/customer/purchase/order/waitOutboundList',
        param: data,
        loading: false
      }).then(res => {
        const { list, total } = res.data
        this.loading = false
        this.tableData = list
        this.pageParam.total = total
      }).catch(err => {
        this.loading = false
        console.log('err', err)
      })
    },

    // 查看详情
    handleDetails(row) {
      this.$router.push({
          path: '/form/purchaseRequisition/particulars',
          query: {
              type: 2,
              id: row.orderCode
          }
      })
    },

    // 出库
    handleOutbound(row) {
      setTimeout(() => {
        this.dialogVisible = true
      }, 1000)
      this.$http.requestGet({
        url: '/customer/purchase/order/deduct/page/' + row.orderCode
      }).then(res => {
        this.detailInfo = res.data
      })
    },

    // ================ 其它 ================
    // 列表导出
    exportTable() {
      const param = JSON.parse(JSON.stringify(this.inquire))
      exportPurchaseOrderOutbound(param).then(res => {
          this.$public.downloadFile(res)
      })
    },
    // 计算总价
    getTotalPrices(arr = []) {
        let prices = 0
        arr.forEach(v => {
            prices += parseInt(v.itemNum) * v.itemPrice
        })
        return prices.toFixed(2)
    }

  }
}
</script>

<style lang="scss" scoped>
  .table-box{
    display: initial
  }

  .img-box-show {
    margin: 0 5px 5px 0;
    cursor: pointer;
  }

  .img-box {
    width: 58px;
    height: 58px;
  }

  .icon_box {
    width: 21px;
    position: absolute;
    left: 0;
    top: calc(100% + 2px);
    &.no_img{
      margin-right: 3px;
      position: static;
    }
  }

  .txt_box {
    position: relative;
    line-height: 1;
  }
</style>
