<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="标题">
          <el-input v-model="inquire.bannerTitle" placeholder="标题" @keyup.enter.native="searchList"/>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="bannerTitle" label="标题" min-width="120" />
            <el-table-column prop="bannerImg" label="图片/视屏(750*375)" min-width="250">
              <template slot-scope="scope">
                <div v-if="scope.row.bannerImg" class="table-view-box">
                  <img v-if="getType(scope.row.bannerImg) === 'image'" :src="scope.row.bannerImg" alt="">
                  <video v-if="getType(scope.row.bannerImg) === 'video'" :src="scope.row.bannerImg" controls />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="bannerOrder" label="排序" min-width="100" />
            <el-table-column prop="updateTime" label="最后修改时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="deleteData(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加banner弹框 -->
    <el-dialog
      :title="dialogTitle + 'banner'"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="650px"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="110px" :model="submitForm" :rules="submitRules">
          <el-form-item label="标题" prop="bannerTitle">
            <el-input v-model="submitForm.bannerTitle" maxlength="20" placeholder="标题" />
          </el-form-item>
          <el-form-item label="图片/视频" prop="bannerImg">
            <d-upload :file-list="fileList" :size="100" accept=".gif,.jpeg,.jpg,.png,.wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v" tip="最佳尺寸：750 * 375" @uploadSuccess="uploadSuccess" @removeFile="removeFile">
              <el-button size="small" type="primary">点击上传</el-button>
            </d-upload>
          </el-form-item>
          <el-form-item label="排序" prop="bannerOrder">
            <el-input v-model="submitForm.bannerOrder" placeholder="排序" />
          </el-form-item>
          <el-form-item label="链接地址" prop="contentId">
            <div class="flex-box">
              <el-cascader
                v-model="category"
                :options="categoryTree"
                :props="props"
                clearable
                placeholder="请选择类型"
                @change="cascaderChange"
              />
              <el-select v-model="submitForm.contentId" placeholder="请选择链接地址">
                <el-option v-for="item in contentList" :key="item.contentId" :label="item.contentTitle" :value="item.contentId" />
              </el-select>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import dUpload from '@/components/d-upload'
export default {
  components: {
    dUpload
  },
  data() {
    return {
      imgType: ['.gif', '.jpeg', '.jpg', '.png'],
      videoType: ['.wmv', '.asf', '.asx', '.rm', '.rmvb', '.rmvb', '.mpg', '.mpeg', '.mpe', '.3gp', '.mov', '.mp4', '.m4v'],

      categoryTree: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'childes',
        // checkStrictly: true,
        emitPath: false
      },
      category: '',

      // 查询参数
      inquire: {
        bannerTitle: ''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      // 表格数据
      tableData: [],

      // 添加区域地址
      dialogTitle: '添加', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      submitForm: {
        bannerTitle: '',
        bannerImg: '',
        bannerOrder: '',
        contentId: ''
      },
      submitRules: {
        bannerTitle: [{ required: true, message: '请输入标题', trigger: 'blur' }],
        bannerImg: [{ required: true, message: '请选择图片/视屏', trigger: 'blur' }],
        bannerOrder: [{ required: true, message: '请输入排序', trigger: 'blur' },
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ],
        contentId: [{ required: true, message: '请选择链接地址', trigger: 'blur' }]
      },
      contentList: [], // 内容列表
      attacheTable: [],
      isAdd: true, // 是否为添加
      editRow: {},
      fileList: [],// 文件列表
    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
    // 获取树形列表
    this.getCategoryTree()
    // 获取资讯内容列表
    this.getContentList('')
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    // 图片上传成功
    uploadSuccess(res, file, fileList) {
      this.submitForm.bannerImg = res.data
    },
    // 删除文件
    removeFile(file, fileList) {
      this.submitForm.bannerImg = ''
    },
    // 获取内容分类
    getCategoryTree() {
      this.$http.requestGet({ url: '/operation/message/category/tree', loading: false }).then(res => {
          this.categoryTree = res.data
      })
    },
    // 获取资讯内容列表
    getContentList(id) {
      const param = { contentCategoryId: id }
      this.$http.requestPost({ url: '/operation/message/content/list', param, loading: false }).then(res => {
          this.contentList = res.data
      })
    },
    // 内容类别改变后
    cascaderChange(old, news) {
      this.getContentList(old)
    },

    // 获取数据列表
    getTableList() {
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http.requestPost({
        url: '/operation/banner/merchant/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.pageParam.total = res.data.total
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    // 添加
    addition() {
      this.dialogTitle = '添加'
      this.dialogVisible = true
      this.isAdd = true
      this.fileList = []
    },
    // 修改
    handleEdit(row) {
      this.fileList = []
      this.dialogTitle = '编辑'
      this.isAdd = false
      this.$http.requestGet({ url: '/operation/banner/merchant/get/' + row.bannerId, loading: false }).then(res => {
        const data = res.data
        this.submitForm = {
            bannerTitle: data.bannerTitle,
            bannerImg: data.bannerImg,
            bannerOrder: data.bannerOrder,
            contentId: data.contentId
        }
        this.category = data.contentCategoryId
        if (data.bannerImg) {
            const arr = data.bannerImg.split('/')
            this.fileList.push({
                name: arr[arr.length - 1],
                url: data.bannerImg
            })
        }
      })

      this.dialogVisible = true
      this.editRow = row
    },
    // 提交添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const param = {
            bannerTitle: this.submitForm.bannerTitle,
            bannerImg: this.submitForm.bannerImg,
            bannerOrder: this.submitForm.bannerOrder,
            contentId: this.submitForm.contentId
          }
          if (!this.isAdd) {
            param.bannerId = this.editRow.bannerId
          }
          this.$http.requestPost({
            url: '/operation/banner/merchant/save',
            param: param
          }).then(res => {
            this.getTableList()
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.closeDialog()
          })
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields())
      this.dialogVisible = false
      this.submitForm = {
        regionId: '',
        districtName: '',
        districtComment: '',
        districtOrder: ''
      }
    },

    // 删除
    deleteData(row) {
      this.$confirm('确定删除该banner?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
          this.pageParam.pageNum--
        }
        this.$http.requestDel({ url: '/operation/banner/merchant/del/' + row.bannerId }).then(res => {
          this.getTableList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
          // eslint-disable-next-line handle-callback-err
      }).catch(err => {})
    },

    getType(url) {
      if (url) {
        const ind = url.lastIndexOf('.')
        const type = url.slice(ind)
        if (this.videoType.indexOf(type) !== -1) {
          return 'video'
        } else if (this.imgType.indexOf(type) !== -1) {
          return 'image'
        }
        return false
      } else {
        return false
      }
    },

  }
}
</script>

<style lang="scss" scoped>
  .address-hierarchy {
    flex-wrap: wrap;
  }

  .table-view-box {
    width: 225px;
    height: 112px;
    margin: auto;
    & > img {
      width: 100%;
      height: 100%;
    }

    & > video {
      width: 100%;
      height: 100%;
    }
  }
</style>
