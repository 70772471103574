<template>
  <!-- 租赁品-新增产品 -->
  <div id="main-scroll" class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>

    <!-- 头部导航 -->
    <div class="nav-bar bg_white">
      <a v-for="(item, index) in navList" :key="item.id" :class="{ active: item.active }" href="javascript:void(0)" @click="skip(index)">
        {{ item.name }}
      </a>
    </div>

    <!-- 基本信息 -->
    <div id="row_1" class="row_box bg_white">
      <div class="header">
        <span>基本信息</span>
      </div>
      <div class="content">
        <el-form ref="common" size="medium" label-width="120px" :model="common" :rules="commonRules">
          <el-form-item v-if="!isAdd" label="产品编号">
            <el-input v-model="common.productCode" placeholder="产品编号" readonly />
          </el-form-item>

          <el-form-item label="产品名称" prop="productName">
            <el-input v-model="common.productName" maxlength="30" placeholder="产品名称" />
          </el-form-item>

          <el-form-item label="通用名称" prop="productCommonName">
            <el-input v-model="common.productCommonName" maxlength="30" placeholder="通用名称" />
          </el-form-item>

          <el-form-item label="所有类别" prop="productCategoryId">
            <el-cascader v-model="common.productCategoryId" :options="productTypeList" :props="props" class="width-inherit" />
          </el-form-item>

          <el-form-item label="产品亮点" prop="productHighlight">
            <el-input v-model="common.productHighlight" maxlength="30" placeholder="产品亮点" />
          </el-form-item>

          <el-form-item v-if="!isAdd" label="总库存">
            <el-input v-model.number="common.productInventoryTotal" placeholder="总库存" disabled />
          </el-form-item>

          <el-form-item label="单位" prop="productUnitsId" class="form-item">
            <el-select v-model="common.productUnitsId" placeholder="请选择" class="form-select">
              <el-option v-for="item in productUnitList" :key="item.unitsId" :label="item.unitsName" :value="item.unitsId" />
            </el-select>
          </el-form-item>

          <el-form-item label="生产厂商" prop="productManufacturer">
            <el-input v-model="common.productManufacturer" maxlength="50" placeholder="生产厂商" />
          </el-form-item>

          <el-form-item label="排序" prop="productOrder">
            <el-input v-model.number="common.productOrder" maxlength="5" placeholder="排序" />
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 销售信息 -->
    <div id="row_2" class="row_box bg_white">
      <div class="header">
        <span>销售信息</span>
      </div>
      <div class="content">
        <el-form ref="lease" size="medium" label-width="120px" :model="lease" :rules="leaseRules">
          <el-form-item label="允许销售方式" :prop="whetherSalesManner">
            <el-checkbox v-model="lease.whetherLease" @change="salesEdit">租赁</el-checkbox>
            <el-checkbox v-model="lease.whetherBuyout" @change="salesEdit">买断</el-checkbox>
            <el-checkbox v-model="lease.whetherSales" @change="salesEdit">销售</el-checkbox>
          </el-form-item>

          <!-- 租赁 start -->
          <el-form-item v-if="lease.whetherLease" label="最低租赁时长" prop="leaseTimeMin">
            <div class="flex-box">
              <el-input
                v-model.number="lease.leaseTimeMin"
                placeholder="最低租赁时长"
                maxlength="10"
                class="width-140"
                :disabled="lease.prices[0].leaseTimeEnd !== ''"
                @change="leaseTimeChange"
              />
              <div class="font-size-12 box-m-l color_light-black">天</div>
            </div>
          </el-form-item>

          <el-form-item v-if="lease.whetherLease" label="租赁价" prop="prices">
            <div class="size_box box-m-b">
              <el-table :data="lease.prices" border style="width: 664px;">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="租赁时长范围" min-width="300">
                  <template slot-scope="scope">
                    <div class="flex-box">
                      <el-input v-model="scope.row.leaseTimeBegin" maxlength="10" placeholder="开始" @change="salesEdit" class="width-110" disabled />
                      <span class="span-link">-</span>
                      <el-input
                        v-model="scope.row.leaseTimeEnd"
                        maxlength="10"
                        placeholder="结束"
                        class="width-110"
                        :disabled="!lease.leaseTimeMin || (lease.prices[scope.$index + 1] && lease.prices[scope.$index + 1].leaseTimeEnd !== null)"
                        @change="maxDayChange(scope.row, scope.$index)"
                      />
                      <span class="span-link">天</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="" label="租赁价格" min-width="200">
                  <template slot-scope="scope">
                    <div class="flex-box">
                      <el-input
                        v-model="scope.row.leasePrice"
                        maxlength="10"
                        placeholder="租赁价格"
                        class="width-110"
                        @blur="scope.row.leasePrice = scope.row.leasePrice.replace(/[^0-9.]/g, '')"
                        @change="salesEdit"
                      />
                      <span class="span-link">元 / 天</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="" label="操作" min-width="100">
                  <template slot-scope="scope">
                    <span
                      v-show="scope.$index !== 0 && scope.$index === lease.prices.length - 1"
                      class="operation-btn"
                      @click="deleteRow(scope.$index)"
                    >
                      删除
                    </span>
                  </template>
                </el-table-column>
              </el-table>

              <div>
                <el-button type="primary" size="small" @click="addLeasePrices">增加售价范围</el-button>
              </div>
            </div>
          </el-form-item>

          <el-form-item v-if="lease.whetherLease" label="是否需要押金" prop="whetherDepositRequire">
            <el-radio-group v-model="lease.whetherDepositRequire">
              <el-radio :label="true" @change="salesEdit">是</el-radio>
              <el-radio :label="false" @change="salesEdit">否</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item v-if="lease.whetherLease && lease.whetherDepositRequire" label="租赁押金" prop="depositPrice">
            <div class="flex-box">
              <el-input v-model="lease.depositPrice" @change="salesEdit" placeholder="租赁押金" maxlength="10" class="width-110" />
              <div class="font-size-12 box-m-l color_light-black">元</div>
            </div>
          </el-form-item>
          <!-- 租赁 end -->

          <el-form-item v-if="lease.whetherBuyout" label="买断价" prop="buyoutPrice">
            <div class="flex-box">
              <el-input v-model="lease.buyoutPrice" @change="salesEdit" placeholder="买断价" maxlength="10" class="width-110" />
              <div class="font-size-12 box-m-l color_light-black">元</div>
            </div>
          </el-form-item>

          <el-form-item label="销售价" prop="salesPrice">
            <div class="flex-box">
              <el-input v-model="lease.salesPrice" @change="salesEdit" placeholder="销售价" maxlength="10" class="width-110" />
              <div class="font-size-12 box-m-l color_light-black">元</div>
            </div>
          </el-form-item>

          <!-- 租赁/买断库存 -->
          <!-- <el-form-item label="租赁/买断库存" prop="leaseInventory" v-if="lease.whetherLease || lease.whetherBuyout">
            <el-input v-model="lease.leaseInventory" placeholder="租赁/买断库存" class="width-110"></el-input>
          </el-form-item> -->

          <!-- 销售库存 -->
          <!-- <el-form-item label="销售库存" prop="salesInventory" v-if="lease.whetherSales">
            <el-input v-model="lease.salesInventory" placeholder="销售库存" class="width-110"></el-input>
          </el-form-item> -->

          <el-form-item label="库存" prop="inventory">
            <el-input v-model="lease.inventory" @change="salesEdit" placeholder="库存" maxlength="12" class="width-110" />
          </el-form-item>

          <el-form-item label="可安装时间段" prop="time">
            <el-time-picker
              v-model="leaseTime"
              is-range
              format="HH:mm"
              value-format="HH:mm"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
              placeholder="选择时间范围"
              @change="timeChange"
            ></el-time-picker>
            <!-- <el-time-picker
            is-range
            v-model="value1"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围">
          </el-time-picker> -->
          </el-form-item>

          <!-- <el-form-item label="信用积分" prop="whetherCreditScoreVerify">
            <el-radio-group v-model="lease.whetherCreditScoreVerify">
              <el-radio :label="true">需验证</el-radio>
              <el-radio :label="false">免验证</el-radio>
            </el-radio-group>

            <div v-show="lease.whetherCreditScoreVerify">
              <div class="flex-box">
                <el-input v-model="lease.depositFreeScore" class="width-110"></el-input>
                <div class="font-size-12 box-m-l color_light-black">积分以上免押金</div>
              </div>
              <div class="flex-box">
                <el-input v-model="lease.leaseFreeScore" class="width-110"></el-input>
                <div class="font-size-12 box-m-l color_light-black">积分以上可租赁</div>
              </div>
            </div>
          </el-form-item> -->

          <el-form-item label="初始销量" prop="initialSales">
            <div class="flex-box">
              <el-input v-model.number="lease.initialSales" @change="salesEdit" placeholder="初始销量" maxlength="10" class="width-110" />
              <div class="font-size-12 box-m-l color_light-black">设置后，您的用户看到的销量=初始销量+最近30天真实销量</div>
            </div>
          </el-form-item>

          <el-form-item label="商家默认提成" prop="defaultMerchantBrokerage">
            <el-input
              v-model="lease.defaultMerchantBrokerage"
              @change="salesEdit"
              placeholder="商家默认提成"
              class="width-110"
              @input="lease.defaultMerchantBrokerage = lease.defaultMerchantBrokerage.replace(/[^0-9.]/g, '')"
            />
            %
          </el-form-item>

          <el-form-item label="专员默认提成" prop="defaultAttacheBrokerage">
            <el-input
              v-model="lease.defaultAttacheBrokerage"
              @change="salesEdit"
              placeholder="专员默认提成"
              class="width-110"
              @input="lease.defaultAttacheBrokerage = lease.defaultAttacheBrokerage.replace(/[^0-9.]/g, '')"
            />
            %
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 图文描述 -->
    <div id="row_3" class="row_box bg_white">
      <div class="header">
        <span>图文描述</span>
      </div>
      <div class="content">
        <el-form ref="graphic" size="medium" label-width="140px" :model="graphic" :rules="graphicRules">
          <el-form-item label="产品图片(主图)" prop="primaryImages">
            <d-upload
              upload-type="picture-card"
              :file-list="primaryImagesList"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccess"
              @removeFile="removeFile"
            >
              <div class="tip-box">
                <i class="el-icon-plus" />
                <span slot="tip" class="tip">建议360px*360px</span>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="产品图片(次图)" prop="images">
            <d-upload
              upload-type="picture-card"
              :file-list="imagesList"
              :limit="9"
              multiple
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccess_1"
              @removeFile="removeFile_1"
            >
              <div class="tip-box">
                <i class="el-icon-plus" />
                <span slot="tip" class="tip">建议360px*360px</span>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="主图视频(默认)">
            <d-upload
              :size="100"
              :file-list="primaryVideosList"
              :autoUpload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @uploadSuccess="uploadSuccess_2"
              @handleChange="handleChange_2"
              @removeFile="removeFile_2"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag2" :percentage="percentage2"></el-progress>
          </el-form-item>

          <el-form-item label="主图视频(医院)">
            <el-button size="small" type="primary" @click="openVideos">视频上传</el-button>
            <d-upload
              :size="100"
              :file-list="hospitalFileList"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @uploadSuccess="uploadSuccess_3"
              @removeFile="removeFile_3"
            />
          </el-form-item>

          <el-form-item label="详情顶部视频">
            <d-upload
              :size="500"
              :file-list="graphic.topVideo"
              :autoUpload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @uploadSuccess="uploadSuccess_5"
              @handleChange="handleChange_5"
              @removeFile="removeFile_5"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag5" :percentage="percentage5"></el-progress>
          </el-form-item>
          <el-form-item label="详情底部视频">
            <d-upload
              :size="500"
              :file-list="graphic.bottomVideo"
              :autoUpload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @uploadSuccess="uploadSuccess_6"
              @handleChange="handleChange_6"
              @removeFile="removeFile_6"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag6" :percentage="percentage6"></el-progress>
          </el-form-item>

          <el-form-item label="产品详情" prop="detail">
            <div class="editor">
              <mavon-editor
                ref="md"
                v-model="editor"
                :toolbars="toolbars"
                @imgAdd="handleEditorImgAdd"
                @imgDel="handleEditorImgDel"
                @change="editorChange"
              />
            </div>
          </el-form-item>

          <el-form-item label="产品参数">
            <div class="form-list flex-box">
              <div v-for="(item, index) in graphic.param" :key="index" class="form-item flex-box">
                <div class="form-item flex-box">
                  <label>
                    <el-input v-model="item.paramKey" maxlength="10" placeholder="参数名称" />
                  </label>
                  <div class="form-item-content">
                    <el-input v-model="item.paramValue" maxlength="10" placeholder="参数值" />
                  </div>
                  <div class="close_ben" @click="delectParam(index)"><i class="el-icon-circle-close" /></div>
                </div>
              </div>

              <div class="form-item flex-box">
                <div class="form-item-content">
                  <el-button type="primary" size="medium" @click="addParam">添加参数行</el-button>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 搜索标签 -->
    <div id="row_4" class="row_box bg_white">
      <div class="header">
        <span>搜索标签</span>
      </div>
      <div class="content">
        <div class="flex-box">
          <el-tag v-for="(tag, index) in formData.tags" :key="tag.tagId ? tag.tagId : tag.tagName" closable @close="closeLabel(index)">
            {{ tag.tagName }}
          </el-tag>
          <el-button type="primary" size="small" @click="addLabel">添加</el-button>
        </div>
      </div>
    </div>

    <!-- 服务保障 -->
    <div id="row_5" class="row_box bg_white">
      <div class="header">
        <span>服务保障</span>
      </div>
      <div class="content">
        <div class="flex-box">
          <el-tag
            v-for="(tag, index) in formData.services"
            :key="tag.serviceId ? tag.serviceId : tag.serviceName"
            closable
            @close="closeServe(index)"
          >
            {{ tag.serviceName }}
          </el-tag>
          <el-button type="primary" size="small" @click="addServe">添加</el-button>
        </div>
      </div>
    </div>

    <!-- 发布设置 -->
    <div id="row_6" class="row_box bg_white">
      <div class="header">
        <span>发布设置</span>
      </div>
      <div class="content">
        <el-form size="medium" label-width="80px">
          <el-form-item label="上架">
            <el-radio v-model="formData.productStatus" label="on_shelves">上架出售</el-radio>
            <el-radio v-model="formData.productStatus" label="for_sale">放入待出售</el-radio>
            <el-radio v-if="!isAdd" v-model="formData.productStatus" label="off_shelves">下架</el-radio>
          </el-form-item>
        </el-form>
        <div class="flex-box submit-btn bg_white" v-if="!details">
          <el-button type="primary" @click="submitData">确 定</el-button>
          <el-button @click="$router.go(-1)">取 消</el-button>
        </div>
      </div>
    </div>

    <!-- 添加标签弹框 -->
    <el-dialog :title="'添加' + labelTitle" :visible.sync="dialogVisible" width="400px">
      <div class="Popout_content">
        <el-form ref="submitForm" size="medium" label-width="80px" :model="submitForm" :rules="submitRules">
          <el-form-item :label="labelTitle" prop="labels">
            <el-input v-model="submitForm.labels" maxlength="12" :placeholder="labelTitle" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加医院视频弹框 -->
    <el-dialog title="上传视频" :visible.sync="dialogVisible_1" width="500px">
      <div class="Popout_content">
        <el-form ref="submitVideos" size="medium" label-width="80px" :model="submitVideos" :rules="submitVideosRules">
          <el-form-item label="选择视频" prop="videos">
            <d-upload
              :size="100"
              :file-list="hospitalVideosList"
              :autoUpload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @uploadSuccess="uploadSuccess_4"
              @handleChange="handleChange_4"
              @removeFile="removeFile_4"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag4" :percentage="percentage4"></el-progress>
          </el-form-item>
          <el-form-item label="绑定医院" prop="hospitalId">
            <el-select v-model="submitVideos.hospitalId" placeholder="请选择" class="width-inherit">
              <el-option v-for="item in hospitalList" :key="item.hospitalId" :label="item.hospitalName" :value="item.hospitalId" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_1 = false">取 消</el-button>
        <el-button type="primary" @click="videosConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dUpload from "@/components/d-upload/index";
import axios from "axios";

function getSignature() {
  return axios.post("/common/file/upload/getQCloudSignature", JSON.stringify({ action: "GetUgcUploadSign" })).then(function(response) {
    return response.data.data;
  });
}
export default {
  components: {
    dUpload,
  },
  data() {
    return {
      actionUrl: "/common/file/upload/video",
      // 导航栏
      navList: [
        { name: "基本信息", id: "row_1", active: true },
        { name: "销售信息", id: "row_2", active: false },
        { name: "图文描述", id: "row_3", active: false },
        { name: "搜索标签", id: "row_4", active: false },
        { name: "服务保障", id: "row_5", active: false },
        { name: "发布设置", id: "row_6", active: false },
      ],
      mainEl: null,
      timer: null,
      details: false,

      // form 数据
      formData: {
        // 主键id
        productId: undefined,
        // 产品归类 - 租赁
        productClassify: "lease",
        // 产品状态 - 上架
        productStatus: "on_shelves",
        // 基础信息
        common: {},
        // 销售信息-租赁
        lease: {},
        // 图文描述
        graphic: {},
        // 搜索标签
        tags: [
          // { tagName:'', tagId: ''}
        ],
        // 服务保障
        services: [
          // { serviceName:'', serviceId: ''}
        ],
      },
      // 是否添加
      isAdd: false,

      // ================== 基础信息 ==================
      // 产品类别级联选项
      props: {
        // 配置
        label: "categoryName",
        value: "categoryId",
        children: "child",
        emitPath: false,
      },
      // 产品类别级联选项数据
      productTypeList: [],
      // 产品单位列表
      productUnitList: [],

      // 基础信息表单
      common: {
        productCode: "", // 编辑只读
        productName: "",
        productCommonName: "",
        productCategoryId: "",
        productHighlight: "",
        productInventoryTotal: "", // 编辑只读
        productUnitsId: "",
        productManufacturer: "",
        productOrder: "",
      },
      commonRules: {
        productName: [{ required: true, message: "请输入产品名称", trigger: "blur" }],
        productCommonName: [{ required: true, message: "请输入通用名称", trigger: "blur" }],
        productCategoryId: [{ required: true, message: "请选择所有类别", trigger: "change" }],
        productHighlight: [{ required: true, message: "请输入产品亮点", trigger: "blur" }],
        productUnitsId: [{ required: true, message: "请选择单位", trigger: "change" }],
        productManufacturer: [{ required: true, message: "请输入生产厂商", trigger: "blur" }],
        productOrder: [
          { required: true, message: "请输入排序", trigger: "blur" },
          { pattern: /^[+]{0,1}(\d+)$/, message: "请输入正整数", trigger: "blur" },
        ],
      },

      // ================== 销售信息 ==================
      lease: {
        whetherLease: true, // 租赁
        whetherBuyout: true, // 买断
        whetherSales: true, // 销售
        leaseTimeMin: null, // 最低租赁时长
        prices: [{ leaseTimeBegin: "", leaseTimeEnd: "", leasePrice: "" }], // 租赁价
        whetherDepositRequire: true, // 是否需要押金
        depositPrice: "", // 租赁押金
        buyoutPrice: null, // 买断价
        salesPrice: null, // 销售价
        // leaseInventory: 0, //租赁/买断库存
        // salesInventory: 0, //销售库存
        inventory: 0, // 库存

        // 改功能暂时不要
        // whetherCreditScoreVerify: true,//信用积分
        // depositFreeScore: 0,//免押金积分
        // leaseFreeScore: 0,//可租赁积分

        initialSales: 0, // 初始销量
        defaultMerchantBrokerage: "", // 商家默认提成
        defaultAttacheBrokerage: "", // 专员默认提成

        time: [], // 可安装时间段
        serviceTimeBegin: "",
        serviceTimeEnd: "",
      },
      leaseTime: [new Date(2021, 1, 1, 0, 0), new Date(2021, 1, 1, 23, 59)],
      leaseRules: {
        leaseTimeMin: [
          { required: true, message: "请输入最低租赁时长", trigger: "blur" },
          { type: "number", message: "请输入整数", trigger: "blur" },
        ],
        prices: [{ required: true, message: "请填写租赁价", trigger: "blur" }],
        whetherDepositRequire: [{ required: true, message: "请选择", trigger: "blur" }],
        depositPrice: [{ required: true, message: "请输入租赁押金", trigger: "blur" }],
        buyoutPrice: [{ required: true, message: "请输入买断价", trigger: "blur" }],
        salesPrice: [{ required: true, message: "请输入销售价", trigger: "blur" }],
        // leaseInventory:[{required: true, message: '请输入租赁/买断库存', trigger: 'blur'}],
        // salesInventory:[{required: true, message: '请输入销售库存', trigger: 'blur'}],
        inventory: [{ required: true, message: "请输入库存", trigger: "blur" }],

        // whetherCreditScoreVerify:[{required: true, message: '请选择', trigger: 'blur'}], // 暂时不需要
        // depositFreeScore:[{required: true, message: '请输入免押金积分', trigger: 'blur'}],
        // leaseFreeScore:[{required: true, message: '请输入可租赁积分', trigger: 'blur'}],

        initialSales: [
          { type: "number", message: "请输入整数", trigger: "blur" },
          { pattern: /^[+]{0,1}(\d+)$/, message: "请输入正整数", trigger: "change" },
        ],
        defaultMerchantBrokerage: [
          { required: true, message: "请输入商家默认提成", trigger: "blur" },
          { pattern: /^100$|^0\.\d[1-9]$|^0\.[1-9]\d$|^[1-9](\.\d{1,2})?$|^[1-9]\d(\.\d{1,2})?$/, message: "请输入正确的格式", trigger: "change" },
        ],
        defaultAttacheBrokerage: [
          { required: true, message: "请输入商家默认提成", trigger: "blur" },
          { pattern: /^100$|^0\.\d[1-9]$|^0\.[1-9]\d$|^[1-9](\.\d{1,2})?$|^[1-9]\d(\.\d{1,2})?$/, message: "请输入正确的格式", trigger: "change" },
        ],
        time: [{ required: true, message: "请选择", trigger: "blur" }],
        whetherSales: [{ required: true, message: "请选择允许销售方式", trigger: "blur" }],
      },

      isEditLease: false, // 判断是否修改

      // ================== 图片描述======================
      graphic: {
        images: [],
        primaryImages: [],
        videos: [],
        primaryVideos: [],
        detail: "",
        param: [],
        topVideo: [],
        bottomVideo: [],
      },
      graphicRules: {
        images: [{ required: true, message: "请选择产品图片（次图）", trigger: "blur" }],
        primaryImages: [{ required: true, message: "请选择产品图片（主图）", trigger: "blur" }],
        // images: [{ required: true, message: '请选择产品图片', trigger: 'blur' }],
        detail: [{ required: true, message: "请输入产品详情", trigger: "blur" }],
      },
      toolbars: {
        // 编辑器配置
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true, // 预览
      },
      editor: "",
      primaryImagesList: [], // 主图返现
      imagesList: [], // 次图返现
      primaryVideosList: [], // 默认视屏

      // 添加视频弹窗
      dialogVisible_1: false,
      submitVideos: {
        videos: [],
        hospitalId: "",
      },
      submitVideosRules: {
        videos: [{ required: true, message: "请选择视频", trigger: "blur" }],
        hospitalId: [{ required: true, message: "请选择医院", trigger: "change" }],
      },
      hospitalList: [], // 医院列表
      hospitalFileList: [], // 医院视屏文件返显列表
      hospitalVideosList: [], // 医院弹窗视屏返显列表

      // ====================== 搜索标签 ==================
      dialogVisible: false, // 添加标签弹窗
      labelTitle: "搜索标签",
      addType: true, // 添加类型
      submitForm: {
        labels: "",
      },
      submitRules: {
        labels: [{ required: true, message: "请输入", trigger: "blur" }],
      },

      // ================== 服务保障 ==================

      // ================== 发布设置 ==================
      autoUpload: false, // 是否自动上传
      percentage2: 0,
      progressFlag2: false,
      uploader2: "",

      percentage4: 0,
      progressFlag4: false,
      uploader4: "",

      percentage5: 0,
      progressFlag5: false,
      uploader5: "",

      percentage6: 0,
      progressFlag6: false,
      uploader6: "",
    };
  },
  computed: {
    whetherSalesManner() {
      if (!this.lease.whetherLease && !this.lease.whetherBuyout && !this.lease.whetherSales) {
        return "whetherSales";
      }
      return "";
    },
  },
  watch: {
    "graphic.primaryImages": (news, old) => {
      const upEl = document.getElementsByClassName("el-upload")[0];
      if (news.length > 0) {
        upEl.style.display = "none";
      } else {
        upEl.style.display = "block";
      }
    },
    "lease.whetherLease": function(news, old) {
      if (!news && this.lease.whetherBuyout) {
        this.lease.whetherBuyout = false;
      }
    },
    "lease.whetherBuyout": function(news, old) {
      if (news && !this.lease.whetherLease) {
        this.lease.whetherLease = true;
      }
    },
  },
  created() {
    this.isAdd = typeof this.$route.query.id === "undefined";
    if (!this.isAdd) {
      this.formData.productId = this.$route.query.id;
      this.getDetailInfo();
    }
    if (this.$route.query.details) {
      this.details = this.$route.query.details;
    }

    this.getProductUnitList();
    this.getProductTypeList();
    this.getHospitalList();

    // 腾讯视频web上传
    this.tcVod = new this.$TcVod({
      getSignature: getSignature,
    });
    this.clearUploadStatus();
  },
  mounted() {
    this.mainEl = document.getElementsByClassName("app-main")[0];
    this.mainEl.addEventListener("scroll", this.chanageScroll);

    this.skip(0);
  },
  beforeDestroy() {
    this.mainEl.removeEventListener("scroll", this.chanageScroll);
  },
  methods: {
    // nav bar 跳转
    skip(index) {
      const i1 = this.navList.findIndex((item) => item.active);
      this.navList[i1].active = false;
      this.navList[index].active = true;

      // 获取选择tabs要跳转元素的id,设置滚动条偏移到该元素的位置，类似锚链接
      const id = this.navList[index].id;
      document.querySelector("#" + id).scrollIntoView(true);
    },
    // nav改变
    changeNav(index) {
      const i1 = this.navList.findIndex((item) => item.active);
      this.navList[i1].active = false;
      this.navList[index].active = true;
    },
    // 函数防抖
    debounce(fn, interval = 200) {
      if (this.timer) clearTimeout(this.timer);
      let args = arguments;
      this.timer = setTimeout(() => {
        fn.apply(this, args);
      }, interval);
    },
    // 滚动条改变
    chanageScroll() {
      var scrollHeight = this.mainEl.scrollHeight;
      var scrollTop = this.mainEl.scrollTop;
      var height = this.mainEl.offsetHeight;
      this.debounce(function() {
        var rowHight = document.getElementById("row_2").offsetHeight;
        let scroll = scrollTop + height;
        if (scroll <= 637) {
          this.changeNav(0);
        } else if (scroll > 637 && scroll <= 1409) {
          this.changeNav(1);
        } else if (scroll > 1409 && scroll <= 2706) {
          this.changeNav(2);
        } else if (scroll > 2706 && scroll <= 2831) {
          this.changeNav(3);
        } else if (scroll > 2831 && scroll <= 2956) {
          this.changeNav(4);
        } else {
          this.changeNav(5);
        }
      }, 100);
    },

    // 获取产品单位列表
    getProductUnitList() {
      this.$api.productUnitList().then((res) => {
        this.productUnitList = res.data;
      });
    },
    // 获取产品类别列表
    getProductTypeList() {
      this.$api.productTypeList().then((res) => {
        this.productTypeList = res.data;
      });
    },
    // 获取医院列表
    getHospitalList() {
      this.$http.requestPost({ url: "/hospital/medical/list", loading: false }).then((res) => {
        this.hospitalList = res.data;
      });
    },
    // 销售信息改变
    salesEdit(d = {}) {
      console.log(d);
      this.isEditLease = true;
    },

    // 获取页面详情信息
    getDetailInfo() {
      this.$http.requestGet({ url: "/product/get/" + this.formData.productId }).then((res) => {
        const data = res.data;
        this.formData = {
          productId: data.productId,
          productClassify: "lease",
          productStatus: data.productStatus,

          common: data.common, // 基础信息
          lease: {}, // 销售信息
          graphic: data.graphic, // 图文描述
          tags: data.tags, // 搜索标签
          services: data.services, // 符文保障
        };
        this.common = data.common;
        this.lease = data.lease;
        this.lease.time = [data.lease.serviceTimeBegin, data.lease.serviceTimeEnd];
        this.leaseTime = [data.lease.serviceTimeBegin, data.lease.serviceTimeEnd];
        (this.graphic = {
          images: [],
          primaryImages: [],
          videos: [],
          primaryVideos: [],
          detail: "",
          param: [],
          topVideo: [],
          bottomVideo: [],
        }), // 图文描述
          (this.editor = this.$public.parseHTML_ForMarkdown(data.graphic.detail));
        this.graphic.detail = data.graphic.detail;
        this.graphic.param = data.productParams;
        data.graphic.images.forEach((v, i) => {
          const imgObj = {
            url: v.graphicUri,
            name: v.graphicUri.substring(v.graphicUri.lastIndexOf("/") + 1),
            graphicId: v.graphicId,
            whetherDefault: true,
          };
          if (i === 0) {
            imgObj.whetherDefault = true;
            this.graphic.primaryImages.push(imgObj);
            this.primaryImagesList.push(imgObj);
          } else {
            this.graphic.images.push(imgObj);
            this.imagesList.push(imgObj);
          }
        });

        if (data.graphic.videos.length > 0) {
          data.graphic.videos.forEach((v, i) => {
            const videosObj = {
              url: v.graphicUri,
              name: v.graphicUri.substring(v.graphicUri.lastIndexOf("/") + 1),
              graphicId: v.graphicId,
              hospitalId: v.hospitalId,
            };
            if (i === 0) {
              videosObj.whetherDefault = true;
              this.graphic.primaryVideos.push(videosObj);
              this.primaryVideosList.push(videosObj);
            } else {
              this.hospitalFileList.push(videosObj);
            }
          });
        }
        if (data.graphic.topVideo && data.graphic.topVideo.length > 0) {
          let videosObj = {
            url: data.graphic.topVideo,
            name: data.graphic.topVideo.substring(data.graphic.topVideo.lastIndexOf("/") + 1),
          };
          this.graphic.topVideo.push(videosObj);
        }
        if (data.graphic.bottomVideo && data.graphic.bottomVideo.length > 0) {
          let videosObj = {
            url: data.graphic.bottomVideo,
            name: data.graphic.bottomVideo.substring(data.graphic.bottomVideo.lastIndexOf("/") + 1),
          };
          this.graphic.bottomVideo.push(videosObj);
        }
      });
    },

    // 表单提交
    submitData() {
      // 验证基础信息表单
      this.$refs.common.validate((valid1) => {
        if (valid1) {
          this.formData.common = this.common;

          // 验证销售信息表单
          this.$refs.lease.validate((valid2) => {
            if (valid2) {
              this.lease.serviceTimeBegin = this.leaseTime[0];
              this.lease.serviceTimeEnd = this.leaseTime[1];

              if (!this.lease.whetherLease && !this.lease.whetherSales) {
                return this.disposeWarnInfo("请选择允许销售方式", 1);
              }

              if (this.lease.prices.length > 0) {
                const len = this.lease.prices;
                for (let i = 0; i < len.length; i++) {
                  if (len[i].leaseTimeBegin === "") {
                    return this.disposeWarnInfo("请输入租赁时长范围开始值", 1);
                  }
                  if (len[i].leaseTimeEnd === "") {
                    return this.disposeWarnInfo("请输入租赁时长范围结束值", 1);
                  }
                  if (len[i].leasePrice === "") {
                    return this.disposeWarnInfo("请输入租赁价格", 1);
                  }
                  if (Number(len[i].leasePrice) <= 0) {
                    return this.disposeWarnInfo("第" + (i + 1) + "行商品租赁价格不能小于等于零", 1);
                  }
                  if (len.length - 1 === i) {
                    if (len[i].leaseTimeEnd <= len[i].leaseTimeBegin) {
                      return this.disposeWarnInfo("第 " + len.length + " 行的结束时间项必须大于 " + len[i].leaseTimeBegin, 1);
                    }
                  }
                }
              }
              if (this.lease.buyoutPrice) {
                if (Number(this.lease.buyoutPrice) <= 0) {
                  return this.disposeWarnInfo("买断价不能小于等于零", 1);
                }
              }
              if (this.lease.salesPrice) {
                if (Number(this.lease.salesPrice) <= 0) {
                  return this.disposeWarnInfo("销售价不能小于等于零", 1);
                }
              }
              if (this.lease.inventory) {
                if (Number(this.lease.inventory < 0)) {
                  return this.disposeWarnInfo("产品库存不能为负", 1);
                }
              }
              this.formData.lease = this.lease;

              // 判断是否修改租赁信息
              if (!this.isEditLease) {
                this.formData.lease = undefined;
              }

              // 验证图文描述表单
              this.$refs.graphic.validate((valid2) => {
                if (valid2) {
                  var param = {
                    detail: this.graphic.detail,
                    images: [],
                    videos: [],
                  };

                  // 主图
                  const primaryImg = this.graphic.primaryImages[0];
                  if (!primaryImg.response) {
                    param.images.push({
                      graphicUri: primaryImg.url,
                      // graphicUri: "https://jiananyiyao-admin.dev1.eims.co/images/3a006b24-94c9-477a-94e9-2313aa4bd9e2.jpg",
                      whetherDefault: true,
                      graphicId: primaryImg.graphicId ? primaryImg.graphicId : undefined,
                    });
                  } else {
                    param.images.push({
                      // graphicUri: "https://jiananyiyao-admin.dev1.eims.co/images/3a006b24-94c9-477a-94e9-2313aa4bd9e2.jpg",
                      graphicUri: primaryImg.response.data,
                      whetherDefault: true,
                    });
                  }

                  this.graphic.images.forEach((v) => {
                    if (!v.response) {
                      param.images.push({
                        // graphicUri: "https://jiananyiyao-admin.dev1.eims.co/images/3a006b24-94c9-477a-94e9-2313aa4bd9e2.jpg",
                        graphicUri: v.url,
                        whetherDefault: false,
                        graphicId: v.graphicId ? v.graphicId : undefined,
                      });
                    } else {
                      param.images.push({
                        // graphicUri: "https://jiananyiyao-admin.dev1.eims.co/images/3a006b24-94c9-477a-94e9-2313aa4bd9e2.jpg",
                        graphicUri: v.response.data,
                        whetherDefault: false,
                      });
                    }
                  });

                  // 视频
                  if (this.graphic.primaryVideos.length > 0) {
                    const videos = this.graphic.primaryVideos[0];
                    if (!videos.response) {
                      param.videos.push({
                        graphicId: videos.graphicId ? videos.graphicId : undefined,
                        graphicUri: videos.url,
                        whetherDefault: true,
                      });
                    } else {
                      param.videos.push({
                        graphicUri: videos.response.data,
                        hospitalId: videos.hospitalId,
                      });
                    }
                  }
                  if (this.graphic.videos.length > 0) {
                    this.graphic.videos.forEach((v) => {
                      if (!v.response) {
                        param.videos.push({
                          graphicId: v.graphicId ? v.graphicId : undefined,
                          graphicUri: v.url,
                          hospitalId: v.hospitalId,
                        });
                      } else {
                        param.videos.push({
                          graphicUri: v.response.data,
                          hospitalId: v.hospitalId,
                        });
                      }
                    });
                  }
                  if (this.hospitalFileList.length > 0) {
                    this.hospitalFileList.forEach((v) => {
                      if (!v.response) {
                        param.videos.push({
                          graphicId: v.graphicId ? v.graphicId : undefined,
                          graphicUri: v.url,
                          hospitalId: v.hospitalId,
                        });
                      } else {
                        param.videos.push({
                          graphicUri: v.response.data,
                          hospitalId: v.hospitalId,
                        });
                      }
                    });
                  }

                  if (this.graphic.param.some((v) => v.key)) {
                    param.param = [];
                    this.graphic.param.forEach((v) => {
                      if (v.key) {
                        param.param.push({
                          paramKey: v.key,
                          paramValue: v.val,
                        });
                      }
                    });
                  }
                  // 详情顶部视频
                  if (this.graphic.topVideo.length > 0) {
                    const videos = this.graphic.topVideo[0];
                    console.log(videos);
                    if (!videos.response) {
                      param.topVideo = videos.url;
                    } else {
                      param.topVideo = videos.response.data;
                    }
                  }

                  // 详情底部视频
                  if (this.graphic.bottomVideo.length > 0) {
                    const videos = this.graphic.bottomVideo[0];
                    if (!videos.response) {
                      param.bottomVideo = videos.url;
                    } else {
                      param.bottomVideo = videos.response.data;
                    }
                  }
                  this.formData.productParams = this.graphic.param;
                  this.formData.graphic = param;

                  var data = JSON.parse(JSON.stringify(this.formData));
                  if (data.lease) data.lease.time = undefined;
                  this.$http
                    .requestPost({
                      url: "/product/save",
                      param: data,
                    })
                    .then((res) => {
                      if (res.code == 200 && res.data && res.data.errorCode == "-1") {
                        return this.disposeWarnInfo(res.data.description, 1);
                      }
                      this.$message({
                        message: "操作成功",
                        type: "success",
                      });
                      this.$router.go(-1);
                    });
                } else {
                  this.skip(2);
                  return false;
                }
              });
            } else {
              this.skip(1);
              return false;
            }
          });
        } else {
          this.skip(0);
          return false;
        }
      });
    },

    // 处理提示信息
    disposeWarnInfo(text, num) {
      this.skip(num);
      this.$message({ message: text, type: "warning" });
    },

    // ======================== 基础信息 ========================

    // ======================== 销售信息 ========================
    // 增加售价范围
    addLeasePrices() {
      const len = this.lease.prices.length;
      var leaseTimeBegin = Number(this.lease.prices[len - 1].leaseTimeBegin);
      var leaseTimeEnd = Number(this.lease.prices[len - 1].leaseTimeEnd);
      if (!leaseTimeEnd) {
        return this.$message.warning({ message: "请输入第" + len + "行的结束时间" });
      }
      if (leaseTimeEnd <= leaseTimeBegin) {
        return this.$message.warning({ message: "第" + len + "行的结束时间项必须大于 " + leaseTimeBegin });
      }
      this.lease.prices.push({
        leaseTimeBegin: this.lease.prices[len - 1].leaseTimeEnd,
        leaseTimeEnd: Number(this.lease.prices[len - 1].leaseTimeEnd) + 1,
        leasePrice: "",
      });
      this.salesEdit();
    },
    // 删除
    deleteRow(index) {
      this.lease.prices.splice(index, 1);
      this.salesEdit();
    },
    // 最低租赁时长改变后
    leaseTimeChange(e) {
      var leaseTime = "";
      if (e) {
        leaseTime = parseInt(e.replace(/[^0-9]/g, ""));
      }
      this.lease.prices[0].leaseTimeBegin = leaseTime;
      this.salesEdit();
    },
    // 修改可安装时间
    timeChange(e) {
      this.lease.time = e;
      this.salesEdit();
    },

    // 租赁结束日期天数改变后
    maxDayChange(row, index) {
      this.lease.prices[index].leaseTimeEnd = row.leaseTimeEnd.replace(/[^0-9.]/g, "");
      const leaseTimeEnd = Number(this.lease.prices[index].leaseTimeEnd);
      const leaseTimeBegin = Number(this.lease.prices[index].leaseTimeBegin);
      if (leaseTimeEnd <= leaseTimeBegin) {
        return this.$message.warning("该项必须大于 " + leaseTimeBegin);
      }
      const len = this.lease.prices.length - 1;
      if (index > len) {
        const leaseTimeEndLast = this.lease.prices[index - 1].leaseTimeEnd;
        if (leaseTimeEnd <= leaseTimeEndLast) {
          this.lease.prices[index].leaseTimeEnd = 0;
          return this.$message.warning("该项不能小于 " + leaseTimeEndLast);
        }
      } else if (index < len) {
        const leaseTimeEndLast = this.lease.prices[index - 1].leaseTimeEnd;
        const leaseTimeEndNext = this.lease.prices[index + 1].leaseTimeEnd;
        if (leaseTimeEnd <= leaseTimeEndLast || leaseTimeEnd >= leaseTimeEndNext) {
          this.lease.prices[index].leaseTimeEnd = 0;
          return this.$message.warning("该项不能小于 " + leaseTimeEndLast + " 且不能大于 " + leaseTimeEndNext);
        }
      }
      this.salesEdit();
    },

    // ======================== 图文描述 ========================
    // 上传图片-主图
    uploadSuccess(res, file, fileList) {
      this.graphic.primaryImages = fileList;
    },
    // 删除图片-主图
    removeFile(file, fileList) {
      this.graphic.primaryImages = fileList;
    },
    // 上传图片-次图
    uploadSuccess_1(res, file, fileList) {
      this.graphic.images = fileList;
    },
    // 删除图片-次图
    removeFile_1(file, fileList) {
      this.graphic.images = fileList;
    },
    // 上传视频-默认
    uploadSuccess_2(res, file, fileList) {
      this.graphic.primaryVideos = fileList;
    },
    // 删除视频-默认
    removeFile_2(file, fileList) {
      this.graphic.primaryVideos = fileList;
      this.progressFlag2 = false;
      this.percentage2 = 0;
      if (this.uploader2) {
        this.uploader2.cancel();
        this.uploader2 = "";
      }
    },
    // 上传视频-医院
    uploadSuccess_3(res, file, fileList) {
      this.graphic.videos = fileList;
    },
    // 删除视频-医院
    removeFile_3(file, fileList) {
      this.graphic.videos = fileList;
    },
    // 上传视频-弹窗
    uploadSuccess_4(res, file, fileList) {
      this.submitVideos.videos = fileList;
    },
    // 删除视频-弹窗
    removeFile_4(file, fileList) {
      this.submitVideos.videos = fileList;
      this.progressFlag4 = false;
      this.percentage4 = 0;
      if (this.uploader4) {
        this.uploader4.cancel();
        this.uploader4 = "";
      }
    },

    // 打开视频选择框
    openVideos() {
      this.dialogVisible_1 = true;
      this.clearUploadStatus();
    },
    // 确定视频提交
    videosConfirm() {
      this.submitVideos.videos[0].hospitalId = this.submitVideos.hospitalId;
      const videoArr = [];
      videoArr.push({
        name: this.submitVideos.videos[0].name,
        url: this.submitVideos.videos[0].url,
        hospitalId: this.submitVideos.hospitalId,
      });
      this.hospitalFileList = videoArr;

      // 关闭弹框
      this.dialogVisible_1 = false;
      this.submitVideos = {
        videos: [],
        hospitalId: "",
      };
      this.hospitalVideosList = [];
      this.$refs.submitVideos.resetFields();
    },

    // 富文本 图片添加
    handleEditorImgAdd(pos, file) {
      const formdata = new FormData();
      formdata.append("file", file);
      this.$http
        .requestPost({
          url: "/common/file/upload",
          param: formdata,
        })
        .then((res) => {
          this.$refs.md.$img2Url(pos, res.data);
        });
    },
    // 富文本 图片删除
    handleEditorImgDel(pos) {
      console.log(pos);
    },
    // 富文本改变后的内容
    editorChange(val, render) {
      this.graphic.detail = render;
    },

    // 产品参数
    // 添加产品参数列
    addParam() {
      if (this.graphic.param > 100) return;
      this.graphic.param.push({ key: "", val: "" });
    },

    // ======================== 搜索标签 ========================
    // 添加搜索标签
    addLabel() {
      this.addType = true;
      this.labelTitle = "搜索标签";
      this.dialogVisible = true;
    },
    // 删除标签
    closeLabel(i) {
      this.formData.tags.splice(i, 1);
    },

    // ======================== 服务保障 ========================
    // 添加
    addServe() {
      this.addType = false;
      this.labelTitle = "服务保障";
      this.dialogVisible = true;
    },
    // 删除服务保障
    closeServe(i) {
      this.formData.services.splice(i, 1);
    },

    // 确定
    confirm() {
      // 搜索标签
      if (this.addType) {
        this.formData.tags.push({
          tagName: this.submitForm.labels,
        });
      } else {
        // 服务保障
        this.formData.services.push({
          serviceName: this.submitForm.labels,
        });
      }
      this.dialogVisible = false;
      this.submitForm.labels = "";
    },
    // 删除单个产品参数
    delectParam(index) {
      this.graphic.param.splice(index, 1);
    },
    // 视频顶部详情
    uploadSuccess_5(res, file, fileList) {
      this.graphic.topVideo = fileList;
    },
    // 删除视频-弹窗
    removeFile_5(file, fileList) {
      this.graphic.topVideo = fileList;
      this.progressFlag5 = false;
      this.percentage5 = 0;
      if (this.uploader5) {
        this.uploader5.cancel();
        this.uploader5 = "";
      }
    },
    // 视频底部详情
    uploadSuccess_6(res, file, fileList) {
      this.graphic.bottomVideo = fileList;
    },
    // 删除视频-弹窗
    removeFile_6(file, fileList) {
      this.graphic.bottomVideo = fileList;
      this.progressFlag6 = false;
      this.percentage6 = 0;
      if (this.uploader6) {
        this.uploader6.cancel();
        this.uploader6 = "";
      }
    },
    // 视频主图
    handleChange_2(file, fileList) {
      // 基本信息配置
      this.uploader2 = this.tcVod.upload({
        mediaFile: file.raw, // 媒体文件
      });
      // 进度
      this.uploaderMediaProgress("primaryVideosList", this.uploader2);
      // 视频上传后 处理
      this.uploaderDoneFn("primaryVideosList", fileList, this.uploader2);
    },
    // 视频主图（医院）
    handleChange_4(file, fileList) {
      // 基本信息配置
      this.uploader4 = this.tcVod.upload({
        mediaFile: file.raw, // 媒体文件
      });
      // 进度
      this.uploaderMediaProgress("hospitalVideosList", this.uploader4);
      // 视频上传后 处理
      this.uploaderDoneFn("hospitalVideosList", fileList, this.uploader4);
    },
    // 顶部视频
    handleChange_5(file, fileList) {
      // 基本信息配置
      this.uploader5 = this.tcVod.upload({
        mediaFile: file.raw, // 媒体文件
      });
      // 进度
      this.uploaderMediaProgress("topVideo", this.uploader5);
      // 视频上传后 处理
      this.uploaderDoneFn("topVideo", fileList, this.uploader5);
    },
    // 底部视频
    handleChange_6(file, fileList) {
      // 基本信息配置
      this.uploader6 = this.tcVod.upload({
        mediaFile: file.raw, // 媒体文件
      });
      // 进度
      this.uploaderMediaProgress("bottomVideo", this.uploader6);
      // 视频上传后 处理
      this.uploaderDoneFn("bottomVideo", fileList, this.uploader6);
    },
    // 视频云上传配置
    uploadChangeFn(file) {
      let that = this;
      that.uploader = that.tcVod.upload({
        mediaFile: file.raw, // 媒体文件
      });
    },
    // 视频上传成功后，将fileId，fileUrl传给后台
    uploaderDoneFn(objName, fileList, uploader) {
      let that = this;
      uploader
        .done()
        .then(function(doneResult) {
          let videosObj = [
            {
              url: doneResult.video.url,
              name: fileList[0].name,
            },
          ];
          that.$http
            .requestPostQs({
              url: "/common/file/upload/saveQCloud",
              param: {
                fileId: doneResult.fileId,
                fileUrl: doneResult.video.url,
              },
            })
            .then((res) => {
              console.log(res);
            });

          if (objName == "topVideo") {
            that.graphic.topVideo = videosObj;
          }
          if (objName == "bottomVideo") {
            that.graphic.bottomVideo = videosObj;
          }
          if (objName == "primaryVideosList") {
            that.primaryVideosList = videosObj;
            that.graphic.primaryVideos = videosObj;
          }
          if (objName == "hospitalVideosList") {
            that.hospitalVideosList = videosObj;
            that.submitVideos.videos = videosObj;
          }
        })
        .catch(function(err) {
          // deal with error
        });
    },
    // 视频上传进度
    uploaderMediaProgress(objName, uploader) {
      let that = this;
      uploader.on("media_progress", function(info) {
        let percentage = parseInt(info.percent * 100);
        if (objName == "topVideo") {
          that.progressFlag5 = true; // 显示进度条
          that.percentage5 = percentage >= 100 ? 100 : percentage; // 动态获取文件上传进度
        }
        if (objName == "bottomVideo") {
          that.progressFlag6 = true; // 显示进度条
          that.percentage6 = percentage >= 100 ? 100 : percentage; // 动态获取文件上传进度
        }
        if (objName == "primaryVideosList") {
          that.progressFlag2 = true; // 显示进度条
          that.percentage2 = percentage >= 100 ? 100 : percentage; // 动态获取文件上传进度
        }
        if (objName == "hospitalVideosList") {
          that.progressFlag4 = true; // 显示进度条
          that.percentage4 = percentage >= 100 ? 100 : percentage; // 动态获取文件上传进度
        }

        if (percentage >= 100) {
          setTimeout(() => {
            if (objName == "primaryVideosList") {
              that.progressFlag2 = false;
            }
            if (objName == "hospitalVideosList") {
              that.progressFlag4 = false;
            }
            if (objName == "topVideo") {
              that.progressFlag5 = false;
            }
            if (objName == "bottomVideo") {
              that.progressFlag6 = false;
            }
          }, 1000); // 一秒后关闭进度条
        }
      });
    },
    // 清空上传视频数据
    clearUploadStatus() {
      if (this.uploader2) {
        this.uploader2.cancel();
      }
      if (this.uploader4) {
        this.uploader4.cancel();
      }
      if (this.uploader5) {
        this.uploader5.cancel();
      }
      if (this.uploader6) {
        this.uploader6.cancel();
      }
      this.uploader2 = "";
      this.uploader4 = "";
      this.uploader5 = "";
      this.uploader6 = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;
  position: static;
}

.nav-bar {
  padding: 12px 20px;
  margin-bottom: 10px;
  position: absolute;
  top: 68px;
  left: 10px;
  z-index: 2000;
  width: calc(100% - 23px);
  box-shadow: 0px 2px 1px 0px #f5f5f5;

  & > a {
    margin-right: 30px;
    font-size: 14px;
    padding: 10px 0;
    border-top: 3px solid transparent;
  }

  .active {
    border-color: #1890ff;
    color: #1890ff;
  }
}

.row_box {
  margin-bottom: 10px;
  font-size: 14px;

  .header {
    padding: 18px 20px 10px;
    border-bottom: 1px solid #cccccc;
  }

  .content {
    padding: 10px;
  }
}

// 基础信息
#row_1 {
  padding-top: 50px;
  .el-form {
    max-width: 600px;
  }
}

// 销售信息
#row_2 {
  .size_box {
    & > label,
    & div > label {
      min-width: 100px;
      padding-right: 10px;
      text-align: right;
    }

    & > div.flex-box {
      flex: 1;
    }
  }
}

// 图文描述
// 上传
.tip-box {
  position: relative;
  .tip {
    position: absolute;
    top: 26px;
    left: 18%;
    font-size: 12px;
    color: #ccc;
  }
}
.editor {
  max-width: 1000px;
  max-height: 420px;
  .v-note-wrapper.shadow {
    height: 420px;
  }
}

//产品参数
.form-list {
  max-width: 1000px;
  flex-wrap: wrap;

  .form-item {
    width: 320px;
    margin: 0 10px 10px 0;
    position: relative;

    label {
      width: 120px;
      text-align: right;
      font-weight: 300;
    }

    .form-item-content {
      flex: 1;
    }
  }
}

// 搜索标签
#row_4,
#row_5 {
  .content {
    & > div {
      flex-wrap: wrap;
      padding: 14px 42px;
      .el-tag {
        margin: 0 8px 5px 0;
      }
    }
  }
}

#row_6 {
  padding-bottom: 60px;
}

.submit-btn {
  justify-content: center;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  left: 10px;
  width: calc(100% - 23px);
  z-index: 2000;
  box-shadow: 0px -3px 0px 0px #f5f5f5;

  button {
    padding: 12px 35px;
  }
}
.span-link {
  line-height: 36px;
}
.close_ben {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  font-size: 20px;
}
.close_ben:hover {
  color: #1890ff;
  cursor: pointer;
}
</style>
