<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        
        <el-form-item label="回复状态">
          <el-select v-model="inquire.commentReplyStatus" placeholder="请选择" class="form-select">
              <el-option v-for="item in $api.replyStatus" :key="item.value" :label="item.name" :value="item.value"></el-option>
            </el-select>
        </el-form-item>

        <el-form-item label="评分">
          <el-select v-model="inquire.commentScore" placeholder="请选择" class="form-select">
            <el-option v-for="item in $api.score" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="评论时间">
          <div class="flex-box">
            <el-date-picker
              v-model="daterange"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
             <el-table-column prop="orderCode" label="订单编号" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="commentContent" label="评论内容" min-width="160" show-overflow-tooltip></el-table-column>
            <el-table-column prop="memberNickname" label="会员微信昵称" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <div class="flex-box">
                  <img v-if="scope.row.memberAvatar" :src="scope.row.memberAvatar" alt="" class="img-box">
                  <div class="hospital-name">{{ scope.row.memberNickname }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="memberCode" label="会员编号" min-width="200"></el-table-column>
            <el-table-column prop="commentScore" label="评分" min-width="150">
              <template slot-scope="scope">
                <el-rate v-model="scope.row.commentScore" disabled></el-rate>
              </template>
            </el-table-column>
            <el-table-column prop="commentTime" label="评论时间" min-width="160"></el-table-column>
            <el-table-column prop="commentReplyStatus" label="回复状态" min-width="100">
              <template slot-scope="scope">
                {{scope.row.commentReplyStatus === 'no_reply'?'未回复':'已回复'}}
              </template>
            </el-table-column>
            <el-table-column prop="whetherSelection" label="是否为精彩评论" min-width="130">
              <template slot-scope="scope">
                {{scope.row.whetherSelection == 1 ?'是':'否'}}
              </template>
            </el-table-column>
            <el-table-column prop="whetherSelection" label="状态" min-width="120">
              <template slot-scope="scope">
                {{scope.row.commentHiddenStatus == 'display' ?'显示':'隐藏'}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="200" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleDetails(scope.row)">详情</span> 
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="reply(scope.row)" v-if="scope.row.commentReplyStatus === 'no_reply'">回复</span>
                <span class="operation-btn_partition" v-if="scope.row.commentReplyStatus === 'no_reply'"> / </span>
                <!-- <span class="operation-btn" @click="yesChoiceness(scope.row)" v-show="scope.row.whetherSelection != 1">设为精选</span> -->
                <!-- <span class="operation-btn" @click="yesChoiceness(scope.row)" v-show="scope.row.whetherSelection == 1">取消精选</span> -->
                <!-- <span class="operation-btn_partition"> / </span> -->
                <span class="operation-btn" @click="handleHide(scope.row, true)" v-show="scope.row.commentHiddenStatus == 'hide'">显示</span>
                <span class="operation-btn" @click="handleHide(scope.row, false)" v-show="scope.row.commentHiddenStatus == 'display'">隐藏</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 弹框 -->
    <el-dialog
    :title="detailsTitle"
    :visible.sync="dialogVisible"
    :before-close="closeDialog"
    width="650px"
    top="5vh">
      <div class="Popout_content">
        <el-form label-width="110px" ref="submitForm" :model="submitForm" :rules="submitRules">
            <el-form-item label="订单编号">
              <el-input v-model="submitForm.orderCode" placeholder="产品编号" disabled></el-input>
            </el-form-item>
            <el-form-item label="订单编号">
              <div class="flex-box" v-for="item in submitForm.products" :key="item.productCode">
                <el-input v-model="item.productName" placeholder="产品名称" disabled></el-input>
                <el-input v-model="item.productCode" placeholder="产品编号" disabled></el-input>
              </div>
            </el-form-item>
            <el-form-item label="评论内容">
              <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="submitForm.commentContent" maxlength="150" disabled></el-input>
            </el-form-item>
            <el-form-item label="评论图片">
              <div class="flex-box">
                <div class="img-box-show" v-for="(item,index) in submitForm.images" :key="index">
                  <img :src="item" alt="" @click="preview(item)">
                </div>
              </div>
            </el-form-item>
            <el-form-item label="评分">
              <div class="flex-box comment-score">
                  <el-rate v-model="submitForm.commentScore" disabled></el-rate>
              </div>
            </el-form-item>
            <el-form-item label="评论时间">
              <el-date-picker v-model="submitForm.commentTime" class="width-inherit" type="date" placeholder="选择日期" disabled></el-date-picker>
            </el-form-item>
            <el-form-item label="会员微信昵称">
              <el-input v-model="submitForm.memberNickname" placeholder="会员微信昵称" disabled></el-input>
            </el-form-item>
            <el-form-item label="会员编号">
              <el-input v-model="submitForm.memberCode" placeholder="会员ID" disabled></el-input>
            </el-form-item>
            <el-form-item label="回复评论" :prop="replyBool?'commentReply':''">
              <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="submitForm.commentReply" maxlength="150" :disabled="!replyBool"></el-input>
            </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button @click="closeDialog">取 消</el-button>
          <el-button type="primary" @click="confirmSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <d-preview :showDialog="showDialog" :fileUrl="fileUrl" :close.sync="showDialog"></d-preview> 

  </div>
</template>

<script>
import dPreview from "@/components/d-preview";
export default {
  components: {
    dPreview
  },
  data() {
    return {
      // 查询参数
      daterange: [],
      inquire: {
        commentHiddenStatus: 'hide',
        commentReplyStatus: '',
        commentScore:'',
        commentTimeBegin: '',
        commentTimeEnd: '',
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [], 
      commentId: '',// 主键id

      dialogVisible: false, // 详情弹窗
      submitForm: {
        orderCode:  '',
        products: [],
        images: [],
        commentContent: '',
        commentScore: 0,
        commentTime: '',
        memberNickname: '',
        memberCode: '',
        commentReply: '',
      },
      submitRules: {
        commentReply: [{required: true, message: '请输入回复评论', trigger: 'blur'}],
      },
      detailsTitle:'评论详情', // 弹窗标题
      replyBool: false, // 是否回复
      
      showDialog: false, // 预览图片弹窗
      fileUrl: '', // 预览图片路径
    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.commentTimeBegin =  this.daterange.length > 0 ? this.daterange[0] : '';
      this.inquire.commentTimeEnd = this.daterange.length > 0 ? this.daterange[1]: '';
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/operation/product/comment/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    // 查看详情
    handleDetails(row) {
      this.detailsTitle = '评论详情';
      this.replyBool = false;
      this.openDetails(row)
    },

    // 回复
    reply(row) {
      this.detailsTitle = '回复评论';
      this.replyBool = true;
      this.openDetails(row)
    },

    // 打开详情弹窗
    openDetails(row) {
      this.commentId = row.commentId;
      this.$http.requestGet({url:'/operation/product/comment/get/' + row.commentId}).then(res => {
        let data = res.data;
        this.submitForm = {
          orderCode:  data.orderCode,
          products: data.products,
          images: data.images,
          commentContent: data.commentContent,
          commentScore: data.commentScore,
          commentTime: data.commentTime,
          memberNickname: data.memberNickname,
          memberCode: data.memberCode,
          commentReply: data.commentReply,
        }
        this.dialogVisible = true;
      })
    },

    // 预览
    preview(url) {
      this.fileUrl = url;
      this.showDialog = true;
    },

    // 回复提交
    confirmSubmit(row) {
      if (this.replyBool) {
        this.$refs.submitForm.validate((valid) => {
          if (valid) {  
            this.$http.requestPut({url:'/operation/product/comment/reply/' + this.commentId + '?commentReply=' + this.submitForm.commentReply }).then(res => {
              this.$message({message:'操作成功', type: 'success'})
              this.getTableList();
              this.closeDialog()
            })
          } else {
            return false 
          }
        })
      } else {
        this.dialogVisible = false;
      }
    },
    
    // 设置/取消 精选 
    yesChoiceness(row) {
      let name = row.whetherSelection == 1 ? '取消精选' : '设为精选';
      this.$confirm('确定将该评论'+ name +'?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http.requestPut({url:'/operation/product/comment/concentration/' + row.commentId}).then(res => {
          this.getTableList()
          this.$message.success("操作成功!")
        })
      }).catch(() => {});
    },

    // 设置为显示/隐藏
    handleHide(row, type) {
      let name = type ? '显示' : '隐藏';
      this.$confirm('确定' + name + '该评论?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.$http.requestPut({url:'/operation/product/comment/change/status/' + row.commentId}).then(res => {
          this.getTableList()
          this.$message.success("操作成功!")
        })
      }).catch(err => {})
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm.resetFields();
      this.dialogVisible = false;
      this.submitForm = {
        orderCode:  '',
        products: [],
        images: [],
        commentContent: '',
        commentScore: 0,
        commentTime: '',
        memberNickname: '',
        memberCode: '',
        commentReply: '',
      }
    }
    
  },
};
</script>

<style lang="scss" scoped>
  .comment-score {
    height: 40px;
    line-height: 40px;
    align-items: center;
  }

  .img-box {
    width: 32px;
    height: 32px;
  }

  .img-box-show {
    margin: 0 5px 5px 0;
    cursor: pointer;
  }
</style>
