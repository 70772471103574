import { constantRoutes } from '@/router'

const state = {
    routes: [],
    addRoutes: [],
    currentRoutes: {}, // PC端二级路由
    allRoutes: {} // 手机端全部路由
}

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoutes = routes
        state.routes = constantRoutes.concat(routes)
    },
    SET_ALLROUTES: (state, routes) => {
        state.allRoutes = routes
    },
    SET_CURRENT_ROUTES: (state, routes) => {
        state.currentRoutes = routes
    }
}

export default {
    namespaced: true,
    state,
    mutations
}
