<!-- 订单操作日志 -->
<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        
        <el-form-item label="导出时间">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="操作者">
          <el-input v-model="inquire.creatorName" placeholder="操作者" @keyup.enter.native="searchList" clearable></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="fileName" label="文件名" min-width="160"></el-table-column>
            <el-table-column prop="createTime" label="导出时间" min-width="160"></el-table-column>
            <el-table-column prop="creatorName" label="操作者" min-width="120"></el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        beginTime:'',
        endTime:'',
        creatorName: '',
      },
      daterange: [],

      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [], 

    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    

    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = [];
      this.inquire.beginTime = this.daterange.length > 0 ? this.daterange[0] : '';
      this.inquire.endTime = this.daterange.length > 0 ? this.daterange[1] : '';
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/exportfile/exportLog',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },
    
  },
};
</script>

<style lang="scss" scoped>
  .address-list {
    flex-wrap: wrap;
    
    & > div {
      display: inline-flex;
    }
  }
  .text {
    padding: 0 5px;
  }
  .text-left {
    min-width: 125px;
    text-align: right;
  }
</style>

