<template>
  <!-- 产品单位列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="增加时间">
          <div class="flex-box">
            <el-date-picker
              v-model="daterange"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
            />
          </div>
        </el-form-item>

        <el-form-item label="商家名称">
          <div class="flex-box">
            <el-input
              v-model="inquire.merchantName"
              placeholder="商家名称"
              @keyup.enter.native="searchList"
            />
          </div>
        </el-form-item>

        <el-form-item label="产品名称">
          <div class="flex-box">
            <el-input
              v-model="inquire.productName"
              placeholder="产品名称"
              @keyup.enter.native="searchList"
            />
          </div>
        </el-form-item>

        <el-form-item label="商家账号">
          <div class="flex-box">
            <el-input
              v-model="inquire.merchantAccount"
              placeholder="商家账号"
              @keyup.enter.native="searchList"
            />
          </div>
        </el-form-item>

        <el-form-item label="状态">
          <el-select
            v-model="inquire.stopPurchase"
            placeholder="全部"
            @change="searchList"
          >
            <el-option
              v-for="item in $api.subscribeStatesOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="区域">
          <el-cascader
            v-if="$public.isAllAreaPower()"
            v-model="inquire.areaId"
            :props="props"
            :options="addressList"
            clearable
            placeholder="请选择地址"
          />
          <d-area v-else :values.sync="inquire.areaId" />
        </el-form-item>

        <el-form-item>
          <el-button
            size="small"
            type="primary"
            @click="searchList"
          >查询
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button
        size="small"
        type="primary"
        @click="handleAdd"
      >新增商家
      </el-button>
      <el-button
        size="small"
        type="primary"
        @click="exportFilex"
      >导出
      </el-button>
      <el-button
        v-if="userInfo.managerType == 'all'"
        size="small"
        type="primary"
        @click="exportTJ"
      >使用统计导出
      </el-button>
      <el-button
        v-if="userInfo.managerType == 'all'"
        size="small"
        type="primary"
        @click="batchOperation"
      >批量操作
      </el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table
            ref="multipleTable"
            v-loading="loading"
            :data="tableData"
            border
            height="500px"
            style="width: 100%"
            :row-key="(row) => row.merchantId"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" :reserve-selection="true" />
            <el-table-column label="序号" prop="date" width="60">
              <template v-slot="scope">
                {{
                  (pageParam.pageNum - 1) * pageParam.pageSize +
                    (scope.$index + 1)
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="商家编号"
              min-width="220"
              prop="merchantCode"
              show-overflow-tooltip
            />
            <el-table-column
              label="商家名称"
              min-width="160"
              prop="merchantName"
              show-overflow-tooltip
            />
            <el-table-column
              label="医院数量"
              min-width="100"
              prop="hospitalCount"
            />
            <el-table-column
              label="所属大区"
              min-width="120"
              prop="regionName"
            />
            <el-table-column
              label="所属片区"
              min-width="120"
              prop="districtName"
            />
            <el-table-column
              label="所属办事处"
              min-width="120"
              prop="agencyName"
            />
            <el-table-column
              label="状态"
              min-width="120"
              prop=""
            >
              <template v-slot="scope">
                {{ !scope.row.stopPurchase ? '允许申购' : '禁止申购' }}
              </template>
            </el-table-column>
            <el-table-column
              label="增加时间"
              min-width="160"
              prop="createTime"
            />
            <el-table-column
              label="最近登录时间"
              min-width="160"
              prop="lastLoginTime"
            />
            <el-table-column fixed="right" label="操作" min-width="330" prop="">
              <template slot-scope="scope">
                <span
                  class="operation-btn"
                  @click="handleEdit(scope.row)"
                >商家档案</span>
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  @click="handleAdmin(scope.row)"
                >业务员</span>
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  @click="handlePeo(scope.row)"
                >联系人</span>
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  @click="openProduct(scope.row)"
                >产品列表</span>
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  @click="openSellDialog(scope.row)"
                >销售记录</span>
                <!-- <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="openCommission(scope.row)"
                  >产品提成比例</span
                > -->
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  @click="resetPwd(scope.row)"
                >重置密码</span>
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  @click="inventory(scope.row)"
                >查看库存</span>
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  @click="handleDisable(scope.row)"
                >{{ scope.row.deleted === 1 ? '启用' : '停用' }}</span>
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  @click="handleModify(scope.row)"
                >变更</span>
                <span class="operation-btn_partition"> / </span>
                <span
                  class="operation-btn"
                  @click="handleExport(scope.row)"
                >导出库存表</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam.pageNum"
            :page-size="pageParam.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 商家档案 -->
    <shops-details
      ref="shopsDetails"
      :dialog-show="dialogVisible_1"
      :dialog-visible.sync="dialogVisible_1"
      :title="dialogTitle"
      :is-add="isAdd"
      :address-list.sync="addressList"
      @submit="submitAdd"
    />

    <!-- 产品列表 -->
    <product-table
      ref="productTable"
      :dialog-show="dialogVisible_2"
      :dialog-visible.sync="dialogVisible_2"
      :title="dialogTitle"
    />

    <!-- 销售记录弹框 -->
    <el-dialog
      :before-close="() => (dialogVisible_3 = false)"
      :title="sellTitle + ' - 销售记录'"
      :visible.sync="dialogVisible_3"
      top="5vh"
      width="80%"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="订单编号">
            <el-input
              v-model="inquire_3.orderCode"
              placeholder="订单编号"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              @click="getSellList"
            >查询
            </el-button>
          </el-form-item>

          <!-- <el-form-item>
            <el-button size="small" type="primary">导出</el-button>
          </el-form-item> -->
        </el-form>

        <el-table
          v-loading="loading_3"
          :data="tableData_3"
          border
          height="400px"
          style="width: 100%"
        >
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">{{
              (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
            }}
            </template>
          </el-table-column>
          <el-table-column
            prop="orderCoId"
            label="订单编号"
            min-width="200"
            show-overflow-tooltip
          />
          <el-table-column prop="hospitalName" label="产品名称" min-width="200">
            <template slot-scope="scope">
              {{ scope.row.productNames.join(', ') }}
            </template>
          </el-table-column>
          <el-table-column
            prop="amountNeedToPay"
            label="应付金额"
            min-width="120"
          >
            <template slot-scope="scope">{{
              '￥' + scope.row.amountNeedToPay
            }}
            </template>
          </el-table-column>
          <el-table-column prop="amountPaid" label="实付金额" min-width="120">
            <template slot-scope="scope">{{
              '￥' + scope.row.amountPaid
            }}
            </template>
          </el-table-column>
          <el-table-column
            prop="brokerageAmount"
            label="提成金额"
            min-width="120"
          >
            <template slot-scope="scope">{{
              '￥' + scope.row.brokerageAmount
            }}
            </template>
          </el-table-column>
          <el-table-column
            label="付款时间"
            min-width="160"
            prop="payTime"
          />
          <el-table-column
            label="下单会员微信昵称"
            min-width="160"
            prop="memberNickName"
          />
          <el-table-column
            label="配送医院"
            min-width="200"
            prop="hospitalName"
            show-overflow-tooltip
          />
          <el-table-column fixed="right" label="操作" min-width="200" prop="">
            <template slot-scope="scope">
              <span
                class="operation-btn"
                @click="toDetails(scope.row)"
              >订单详情</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            :current-page="pageParam_3.pageNum"
            :page-size="pageParam_3.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam_3.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_3"
            @current-change="handleCurrentChange_3"
          />
        </div>
        <p style="padding-top: 30px" />
      </div>
    </el-dialog>

    <!-- 产品提成比设置弹框 -->
    <el-dialog
      :before-close="() => (dialogVisible_4 = false)"
      :title="sellTitle + ' - 产品提成比设置'"
      :visible.sync="dialogVisible_4"
      top="5vh"
      width="80%"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="订单编号">
            <el-input
              v-model="inquire_4.orderCode"
              placeholder="订单编号"
            />
          </el-form-item>

          <el-form-item>
            <el-button
              size="small"
              type="primary"
              @click="getCommissionList"
            >查询
            </el-button>
          </el-form-item>

          <!-- <el-form-item>
                        <el-button size="small" type="primary">导入产品提成比例</el-button>
                        <el-button size="small" type="primary">导出</el-button>
                    </el-form-item> -->
        </el-form>

        <el-table
          v-loading="loading_4"
          :data="tableData_4"
          border
          height="400px"
          style="width: 100%"
        >
          <el-table-column label="序号" prop="date" width="60">
            <template
              v-slot="scope"
            >{{
              (pageParam.pageNum - 1) * pageParam.pageSize +
                (scope.$index + 1)
            }}
            </template>
          </el-table-column>
          <el-table-column
            label="产品编号"
            min-width="200"
            prop="productCode"
            show-overflow-tooltip
          />
          <el-table-column
            label="产品名称"
            min-width="180"
            prop="productName"
            show-overflow-tooltip
          />
          <el-table-column
            label="所属类别"
            min-width="120"
            prop="productCategoryName"
            show-overflow-tooltip
          />
          <el-table-column
            label="所属类型"
            min-width="120"
            prop="productClassify"
          >
            <template slot-scope="scope">{{
              $api.getValue($api.orderClassify, scope.row.productClassify)
            }}
            </template>
          </el-table-column>
          <el-table-column
            label="提成比例（%）"
            min-width="120"
            prop="defaultMerchantBrokerage"
          />
          <el-table-column
            label="首单提成比例（%）"
            min-width="150"
            prop="firstMerchantBrokerage"
          />
          <el-table-column fixed="right" label="操作" min-width="160" prop="">
            <template slot-scope="scope">
              <span
                class="operation-btn"
                @click="amendCommission(scope.row)"
              >修改提成比例</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination
            :current-page="pageParam_4.pageNum"
            :page-size="pageParam_4.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam_4.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_4"
            @current-change="handleCurrentChange_4"
          />
        </div>
        <p style="padding-top: 30px" />
      </div>
    </el-dialog>

    <!-- 修改提成比例 -->
    <el-dialog
      :before-close="() => (dialogVisible_5 = false)"
      :visible.sync="dialogVisible_5"
      title="修改提成比例 "
      width="600px"
    >
      <div class="Popout_content">
        <el-form
          ref="submitForm_1"
          label-width="180px"
          :model="submitForm_1"
          :rules="submitRules_1"
          class="demo-form-inline"
        >
          <el-form-item label="当前首单提成比例">
            {{ submitForm_1.oldFirstMerchantBrokerage + ' %' }}
          </el-form-item>

          <el-form-item label="修改首单提成比例" prop="firstMerchantBrokerage">
            <div class="flex-box">
              <el-input
                v-model="submitForm_1.firstMerchantBrokerage"
                class="width-180"
                placeholder="修改提成比例"
              />
              %
            </div>
          </el-form-item>

          <el-form-item label="当前提成比例">
            {{ submitForm_1.oldDefaultMerchantBrokerage + ' %' }}
          </el-form-item>

          <el-form-item label="修改提成比例" prop="defaultMerchantBrokerage">
            <div class="flex-box">
              <el-input
                v-model="submitForm_1.defaultMerchantBrokerage"
                class="width-180"
                placeholder="修改提成比例"
              />
              %
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_5 = false">取 消</el-button>
        <el-button type="primary" @click="confirm_2">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看库存弹框 -->
    <el-dialog
      :before-close="() => (dialogVisible_6 = false)"
      :title="sellTitle + ' - 商品库存'"
      :visible.sync="dialogVisible_6"
      width="80%"
    >
      <div class="Popout_content">
        <el-table
          v-loading="loading_6"
          :data="tableData_6"
          border
          height="400px"
          style="width: 100%"
          :expand-row-keys="expands"
          row-key="productCode"
          @expand-change="expandChange"
        >
          <el-table-column type="expand">
            <template slot-scope="scope">
              <el-table :data="scope.row.skus" border style="width: 100%">
                <el-table-column
                  label="规格名称"
                  min-width="160"
                  prop="normValues"
                  show-overflow-tooltip
                >
                  <template slot-scope="scope2">
                    {{
                      scope2.row.normValues.length > 0
                        ? scope2.row.normValues.join('+')
                        : '无（租赁品无规格）'
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="规格库存"
                  min-width="120"
                  prop="inventoryTotal"
                />
                <el-table-column
                  label="规格货值（￥）"
                  min-width="120"
                  prop="skuValue"
                />
                <el-table-column
                  fixed="right"
                  label="操作"
                  min-width="120"
                  prop=""
                >
                  <template slot-scope="scope">
                    <span
                      class="operation-btn"
                      @click="handleBatch(scope.row)"
                    >批次</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </el-table-column>

          <el-table-column
            label="产品名称"
            min-width="180"
            prop="productName"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div class="flex-box">
                <img
                  v-if="scope.row.productImg"
                  :src="scope.row.productImg"
                  alt=""
                  class="img-box"
                >
                <div class="hospital-name">
                  <div class="txt_box" :class="{'flex-box':!scope.row.productImg,'flex-center':!scope.row.productImg}">
                    <img
                      v-if="scope.row.isGifts"
                      src="@/assets/image/gift_icon.png"
                      alt=""
                      class="icon_box"
                      :class="{no_img:!scope.row.productImg}"
                    >
                    {{ scope.row.productName }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="产品编号"
            min-width="200"
            prop="productCode"
            show-overflow-tooltip
          />
          <el-table-column
            label="总库存"
            min-width="120"
            prop="productInventoryTotal"
          />
          <el-table-column
            label="总货值"
            min-width="120"
            prop="productValue"
          />
        </el-table>
        <p style="padding-top: 30px" />
      </div>
    </el-dialog>

    <!-- 批次 -->
    <el-dialog title="批次" :visible.sync="dialogVisible_batch" width="70%">
      <div class="Popout_content">
        <el-table
          ref="batchTable"
          v-loading="loading_batch"
          :data="batchTable"
          border
          height="400px"
        >
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{
              (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
            }}
            </template>
          </el-table-column>
          <el-table-column
            label="批次"
            min-width="160"
            prop="batchNo"
          />
          <el-table-column
            label="库存"
            min-width="110"
            prop="count"
          />
          <el-table-column
            label="生产日期"
            min-width="150"
            prop="produceTime"
          />
          <el-table-column
            label="到期日期"
            min-width="150"
            prop="expireTime"
          />
          <el-table-column
            label="有效期（天）"
            min-width="110"
            prop="effectiveDays"
          />
          <el-table-column
            label="入库时间"
            min-width="150"
            prop="createTime"
          />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_batch = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 4.4.5添加联系人弹框需求 -->
    <el-dialog
      class="people_qly_dialog"
      width="35%"
      title="联系人列表"
      :visible.sync="dialogTableVisible_people"
    >
      <div style="position: reletive" class="people_qly_btn">
        <el-button
          style="position: absolute; left: 131px; top: 13px"
          type="primary"
          @click="add_peo_qly"
        >添加
        </el-button>
      </div>
      <el-table
        class="people_qly_table"
        style="margin-top: 20px"
        :data="peopleqlyData"
      >
        <el-table-column
          property="merchantContactsPhoneName"
          label="联系人姓名"
        />
        <el-table-column
          property="merchantContactsPhoneNumber"
          label="联系方式"
        />
        <el-table-column width="200" property="address" label="操作">
          <template slot-scope="scope">
            <span
              style="color: #409eff; cursor: pointer"
              class="btn_do"
              @click="edit_peo_qly(scope.row)"
            >编辑</span>
            <span
              style="color: #409eff; margin-left: 20px; cursor: pointer"
              class="btn_do"
              @click="del_peo_qly(scope.row)"
            >删除</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 20px 20px; height: 70px" class="people_qly_btn">
        <el-button
          style="margin: 6px -20px 20px 0; float: right"
          type="primary"
          @click="dialogTableVisible_people = false"
        >确认
        </el-button>
      </div>

      <!-- 内层的添加/编辑联系人弹窗 -->
      <el-dialog
        width="20%"
        :title="peo_title"
        :visible.sync="peo_qly_innerVisible"
        append-to-body
      >
        <el-form
          ref="editPeoRuleForm"
          :rules="edit_peo_form_rules"
          :model="edit_peo_form"
        >
          <el-form-item
            label="姓名"
            prop="merchantContactsPhoneName"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="edit_peo_form.merchantContactsPhoneName"
              maxlength="10"
              autocomplete="off"
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item
            label="手机号"
            prop="merchantContactsPhoneNumber"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="edit_peo_form.merchantContactsPhoneNumber"
              placeholder="请输入手机号"
              autocomplete="off"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="add_peo_enter('editPeoRuleForm')"
          >确认
          </el-button>
        </div>
      </el-dialog>
    </el-dialog>

    <!-- 业务员弹窗 -->
    <el-dialog
      class="people_qly_dialog"
      width="50%"
      title="业务员列表"
      :visible.sync="dialogTableVisible_admin"
    >
      <div style="position: reletive" class="people_qly_btn">
        <div class="flex-box">
          <el-select
            v-model="selectKey"
            placeholder="请选择"
            class="width-140"
            @change="selectValue = ''"
          >
            <el-option label="业务员姓名" value="intendantName" />
            <el-option label="业务员手机号" value="intendantPhone" />
          </el-select>
          <el-input
            v-model="selectValue"
            placeholder=""
            class="width-180"
            @keyup.enter.native="initAdminList"
          />
          <div style="margin-left: 50px">
            <el-button type="primary" @click="initAdminList">查询</el-button>
            <el-button type="primary" @click="add_admin_qly">添加</el-button>
          </div>
        </div>
      </div>
      <el-table
        class="people_qly_table"
        style="margin-top: 20px"
        :data="adminqlyData"
      >
        <el-table-column
          property="intendantCode"
          label="业务员编号"
        />
        <el-table-column
          property="intendantName"
          label="业务员姓名"
        />
        <el-table-column
          property="intendantPhone"
          label="联系方式"
        />
        <el-table-column
          property="createTime"
          label="添加时间"
        />

        <el-table-column width="200" property="address" label="操作">
          <template slot-scope="scope">
            <span
              style="color: #409eff; cursor: pointer"
              class="btn_do"
              @click="edit_admin_qly(scope.row)"
            >编辑</span>
            <span
              style="color: #409eff; cursor: pointer"
              class="btn_do"
              @click="start_admin_qly(scope.row)"
            >{{ scope.row.isLock == 1 ? '锁定' : '启用' }}</span>
            <span style="color: #409eff; cursor: pointer" class="btn_do" @click="del_admin_qly(scope.row)">删除</span>
            <span
              style="color: #409eff; cursor: pointer"
              class="btn_do"
              @click="history_admin_qly(scope.row)"
            >登录历史</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 20px 20px; height: 70px" class="people_qly_btn">
        <div style="float: right">
          <el-pagination
            :current-page="admin_page.pageNum"
            :page-size="admin_page.pageSize"
            :page-sizes="$api.pageSizes"
            :total="admin_page.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_admin"
            @current-change="handleCurrentChange_admin"
          />
        </div>
      </div>

      <!-- 内层的业务员添加/编辑弹窗 -->
      <el-dialog
        width="20%"
        :title="admin_title"
        :visible.sync="admin_qly_innerVisible"
        append-to-body
      >
        <el-form
          ref="editAdminRuleForm"
          :rules="edit_admin_form_rules"
          :model="edit_admin_form"
        >
          <el-form-item
            label="姓名"
            prop="intendantName"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model="edit_admin_form.intendantName"
              maxlength="10"
              autocomplete="off"
              placeholder="请输入姓名"
            />
          </el-form-item>
          <el-form-item
            label="手机号"
            prop="intendantPhone"
            :label-width="formLabelWidth"
          >
            <el-input
              v-model.trim="edit_admin_form.intendantPhone"
              placeholder="请输入手机号"
              autocomplete="off"
            />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            type="primary"
            @click="add_admin_enter('editAdminRuleForm')"
          >确认
          </el-button>
        </div>
      </el-dialog>
      <!-- 内层的业务员 登录历史弹窗 -->
      <el-dialog
        width="50%"
        :title="intendantNameQly"
        :visible.sync="login_qly_innerVisible"
        append-to-body
      >
        <!-- 登录时间查询 -->
        <div class="block">
          <el-date-picker
            v-model="loginValue"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            @change="gettime"
          />
          <div style="margin-left: 50px; display: inline-block">
            <el-button type="primary" @click="searchLogin">查询</el-button>
          </div>
        </div>
        <el-table
          class="people_qly_table"
          style="margin-top: 20px; height: 400px"
          :data="loginqlyData"
        >
          <el-table-column
            property="loginTime"
            label="登陆时间"
          />
          <el-table-column property="ip" label="ip" />
        </el-table>
        <div style="margin: 20px 20px; height: 70px" class="people_qly_btn">
          <div style="float: right">
            <el-pagination
              :current-page="login_page.pageNum"
              :page-size="login_page.pageSize"
              :page-sizes="$api.pageSizes"
              :total="login_page.total"
              background
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange_login"
              @current-change="handleCurrentChange_login"
            />
          </div>
        </div>
      </el-dialog>
    </el-dialog>

    <!-- 4.7.2添加商家列表变更弹框需求 弹框模板 -->
    <el-dialog :title="titleNew" :visible.sync="dialogVisible_New" :before-close="dialogCloseNew" width="60%">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="医院名称">
          <el-input v-model="hospitalNew" placeholder="请输入" @keyup.enter.native="handleQueryNew" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleQueryNew">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleChangeNew">变更为</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableDataNew"
        :row-key="getRowKeyNew"
        border
        height="400px"
        style="width: 100%"
        @selection-change="handleSelectionChangeNew"
      >
        <el-table-column type="selection" width="60" align="center" />
        <el-table-column label="医院编号" prop="hospitalCode" align="center" />
        <el-table-column label="医院" prop="hospitalName" align="center" show-overflow-tooltip />
        <el-table-column label="增加时间" prop="createTime" align="center" />
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageParamNew.pageNum"
          :page-size="pageParamNew.pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 1000]"
          :total="pageParamNew.total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChangeNew"
          @current-change="handleCurrentChangeNew"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCloseNew">取 消</el-button>
      </span>
    </el-dialog>

    <el-dialog title="选择变更商家" :visible.sync="dialogVisible_SJ" width="60%">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="商家名称">
          <el-input v-model="merchantNameSJ" placeholder="请输入" @keyup.enter.native="handleQuerySJ" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleQuerySJ">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableDataSJ"
        :row-key="getRowKeySJ"
        :highlight-current-row="true"
        border
        height="400px"
        style="width: 100%"
        @row-click="handleSelectionChangeSJ"
      >
        <el-table-column width="55" align="center">
          <template slot-scope="scope">
            <el-radio v-model="selectedMerchantId" :label="scope.row.merchantId">
              <span />
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column label="商家编号" prop="merchantCode" align="center" />
        <el-table-column label="商家名称" prop="merchantName" align="center" />
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="pageParamSJ.pageNum"
          :page-size="pageParamSJ.pageSize"
          :page-sizes="[10, 20, 50, 100, 200, 1000]"
          :total="pageParamSJ.total"
          background
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChangeSJ"
          @current-change="handleCurrentChangeSJ"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_SJ = false">取 消</el-button>
        <el-button type="primary" @click="handleMerchantSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 批量操作弹窗 -->
    <el-dialog title="是否关闭商家申购权限？" :visible.sync="subscribeVisible" width="30%">
      <el-form
        ref="subscribeForm"
        :model="subscribeForm"
        :rules="subscribeRules"
        :inline="true"
        class="demo-form-inline"
        size="medium"
        style="margin-top: 20px;"
      >
        <el-form-item label="关闭时间" prop="closeDate">
          <el-date-picker
            v-model="subscribeForm.closeDate"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="subscribeBtnLoading" type="primary" @click="confirmSubscribeChange">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="是否开启商家申购权限？" :visible.sync="openSubscribeVisible" width="30%">
      <el-form
        ref="openSubscribeForm"
        :inline="true"
        class="demo-form-inline"
        size="medium"
        style="margin-top: 20px;"
      />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmSubscribeChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dArea from '@/components/d-area'
import shopsDetails from '@/components/shops/shopsDetails.vue'
import productTable from '@/components/shops/productTable.vue'
import { getSessionStorage } from '@/utils/auth'
import { exportMerchantInventoryRecords } from '@/api/order'
import { startAndStopMerchant, stopPurchaseApi } from '@/api/user'

export default {
  components: {
    dArea,
    shopsDetails,
    productTable
  },
  data() {
    return {
      merchantListData: [],
      // 增加业务员功能
      dialogTableVisible_admin: false,
      admin_qly_innerVisible: false,
      login_qly_innerVisible: false,
      selectKey: 'intendantName',
      loginPicker: {
        beginTime: '',
        endTime: ''
      },
      intendantNameQly: '',
      loginValue: [],
      loginParams: {},
      selectValue: '',
      admin_title: '',
      edit_admin_form: {
        intendantName: '', // 该商户管理员姓名
        intendantPhone: '' // 该商户管理员手机号
      },
      loginIntendantId: '',
      intendantId: '',
      currentPage4: 4,
      login_page: {
        pageSize: 20,
        pageNum: 1,
        total: 0
      },
      admin_page: {
        // 管理人分页
        pageSize: 20,
        pageNum: 1,
        total: 0,
        merchantId: ''
      },
      adminqlyData: [],
      loginqlyData: [],
      // 增加联系人增删改功能
      peopleqlyData: [], // 联系人列表
      merchantId_peo: '', // 联系人新增id
      phoneLinkId_peo: '', // 联系人编辑id
      peo_title: '',
      peo_qly_innerVisible: false, // 联系人内层弹窗
      dialogTableVisible_people: false, // 联系人外层弹窗
      edit_peo_form: {
        merchantContactsPhoneName: '',
        merchantContactsPhoneNumber: ''
      },
      peo_page: {
        // 联系人分页
        pageSize: 1000, // 联系人本版本不做分页  后台规定暂且传1000
        pageNum: 1,
        merchantId: ''
      },
      formLabelWidth: '80px',
      edit_admin_form_rules: {
        intendantName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        intendantPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机格式错误",
          //   trigger: "blur",
          // },
        ]
      },
      edit_peo_form_rules: {
        merchantContactsPhoneName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        merchantContactsPhoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '手机格式错误',
            trigger: 'blur'
          }
        ]
      },
      userInfo: {},
      // 片区
      addressList: [],
      props: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },

      // 查询
      inquire: {
        createTimeBegin: '',
        createTimeEnd: '',
        productName: '',
        areaId: '',
        merchantName: '',
        merchantAccount: '',
        stopPurchase: '0'
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      loading: false,

      // ==================== 商家档案 ====================
      dialogVisible_1: false, // 弹窗
      dialogTitle: '',
      editRow: {},
      isAdd: true,

      // ==================== 产品列表 ====================
      dialogVisible_2: false,

      // ==================== 销售记录 ====================
      inquire_3: {
        orderCode: ''
      },
      dialogVisible_3: false, // 弹窗
      pageParam_3: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData_3: [],
      loading_3: false,
      sellTitle: '', // 弹窗标题

      // ==================== 产品提成比例 ====================
      inquire_4: {
        merchantId: '',
        orderCode: ''
      },
      dialogVisible_4: false, // 弹窗
      pageParam_4: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData_4: [],
      loading_4: false,

      // 修改提成比例
      dialogVisible_5: false, // 弹窗
      submitForm_1: {
        oldFirstMerchantBrokerage: 0,
        firstMerchantBrokerage: 0,
        oldDefaultMerchantBrokerage: 0,
        defaultMerchantBrokerage: 0
      },
      submitRules_1: {
        firstMerchantBrokerage: [
          {
            required: true,
            message: '请输入修改首单提成比例',
            trigger: 'blur'
          },
          {
            pattern:
                /^0$|^(100|100.00)$|^0\.\d[1-9]$|^0\.[1-9]\d$|^[1-9](\.\d{1,2})?$|^[1-9]\d(\.\d{1,2})?$/,
            message: '请输入正确的百分比格式',
            trigger: 'blur'
          }
        ],
        defaultMerchantBrokerage: [
          { required: true, message: '请输入修改提成比例', trigger: 'blur' },
          {
            pattern:
                /^0$|^(100|100.00)$|^0\.\d[1-9]$|^0\.[1-9]\d$|^[1-9](\.\d{1,2})?$|^[1-9]\d(\.\d{1,2})?$/,
            message: '请输入正确的百分比格式',
            trigger: 'blur'
          }
        ]
      },

      // ==================== 查看库存 ====================
      dialogVisible_6: false, // 弹窗
      pageParam_6: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData_6: [],
      loading_6: false,
      expands: [], // 当前展示行
      // 批次
      dialogVisible_batch: false,
      batchTable: [],
      loading_batch: false,
      // 4.7.2商家列表批量变更医院地址
      dialogVisible_New: false,
      pageParamNew: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableDataNew: [],
      hospitalNew: '',
      merchantIdNew: '',
      titleNew: '',
      hospitalList: [],
      dialogVisible_SJ: false,
      pageParamSJ: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableDataSJ: [],
      merchantNameSJ: '',
      hospitalListSJ: [],
      selectedMerchantId: null,
      // 批量操作 -- 关闭申购权限
      subscribeVisible: false,
      subscribeBtnLoading: false,
      subscribeForm: {
        closeDate: ''
      },
      subscribeRules: {
        closeDate: [{ required: true, message: '请选择日期', trigger: 'change' }]
      },
      // 批量操作 -- 打开申购权限
      openSubscribeVisible: false,
      openSubscribeForm: {}
    }
  },
  created() {
    this.userInfo = getSessionStorage('userInfo')
    console.log(this.userInfo)
    // 获取列表
    this.getTableList()
    this.getDistrictIdList()
  },
  methods: {
    dialogCloseNew() {
      this.searchList()
      this.dialogVisible_New = false
    },
    handleSelectionChangeNew(row) {
      this.hospitalList = row
    },
    getRowKeyNew(row) {
      return row.hospitalId
    },
    handleQueryNew() {
      const data = {
        pageNum: this.pageParamNew.pageNum,
        pageSize: this.pageParamNew.pageSize,
        param: {
          merchantId: this.merchantIdNew,
          hospitalName: this.hospitalNew
        }
      }
      this.$http.requestPost({
        url: '/customer/merchant/getHospitalByMerchantId',
        param: data
      }).then((res) => {
        this.tableDataNew = res.data.list
        this.pageParamNew.total = res.data.total
      }).catch(err => {
        console.log(err)
      })
    },
    // 修改每页展示条数
    handleSizeChangeNew(size) {
      this.pageParamNew.pageNum = 1
      this.pageParamNew.pageSize = size
      this.handleQueryNew()
    },
    // 下一页
    handleCurrentChangeNew(page) {
      this.pageParamNew.pageNum = page
      this.handleQueryNew()
    },
    // 变更按钮
    handleChangeNew() {
      if (this.hospitalList.length > 0) {
        this.pageParamSJ.pageNum = 1
        this.merchantNameSJ = ''
        this.handleQuerySJ()
        this.dialogVisible_SJ = true
      } else {
        return this.$message.warning('请选择需要变更的医院')
      }
    },
    handleSelectionChangeSJ(row) {
      this.selectedMerchantId = row.merchantId
      this.hospitalListSJ = row
    },
    getRowKeySJ(row) {
      return row.merchantId
    },
    handleQuerySJ() {
      const data = {
        pageNum: this.pageParamSJ.pageNum,
        pageSize: this.pageParamSJ.pageSize,
        param: {
          merchantName: this.merchantNameSJ,
          areaId: this.userInfo.areaId,
          merchantId: this.merchantIdNew,
          deleted: this.$route.name === 'startShops' ? 0 : 1 // 0：未停用 1：已停用
        }
      }
      this.$http.requestPost({
        url: '/customer/merchant/page',
        param: data
      }).then((res) => {
        this.tableDataSJ = res.data.list
        this.pageParamSJ.total = res.data.total
      }).catch(err => {
        console.log(err)
      })
    },
    // 修改每页展示条数
    handleSizeChangeSJ(size) {
      this.pageParamSJ.pageNum = 1
      this.pageParamSJ.pageSize = size
      this.handleQuerySJ()
    },
    // 下一页
    handleCurrentChangeSJ(page) {
      this.pageParamSJ.pageNum = page
      this.handleQuerySJ()
    },
    handleMerchantSubmit() {
      const hospitalArr = []
      if (this.hospitalList.length > 0) {
        this.hospitalList.map(item => {
          hospitalArr.push(item.hospitalId)
        })
      }
      const param = {
        oldMerchantId: this.merchantIdNew,
        newMerchantId: this.selectedMerchantId,
        hospitalIds: hospitalArr
      }
      this.$confirm('是否确认变更绑定?', '变更绑定确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http.requestPost({
            url: '/customer/merchant/changeHospitalMerchant',
            param: param
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success('操作成功!')
              this.dialogVisible_SJ = false
              this.pageParamNew.pageNum = 1
              this.hospitalNew = ''
              this.handleQueryNew()
            } else {
              this.$message.error(res.data.description || '变更失败!')
            }
          }).catch(err => {
            console.log(err)
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 4.4.5需求   增删改查业务员
    handleAdmin(row) {
      this.dialogTableVisible_admin = true
      this.merchantIdAdmin = row.merchantId
      this.initAdminList()
    },
    gettime(value1) {
      console.log(
        '开始时间:',
        this.loginPicker.beginTime,
        '结束时间:',
        this.loginPicker.endTime
      )
    },
    // 查询登录历史
    searchLogin() {
      // console.log(this.loginPicker);
      console.log('打印的值---', this.loginValue)
      this.initLoginList()
    },
    add_admin_qly() {
      this.admin_title = '添加业务员'
      this.admin_qly_innerVisible = true
      // 清除表单内容  清除校验规则
      this.edit_admin_form = {}
      if (this.$refs.editAdminRuleForm) {
        this.$refs.editAdminRuleForm.clearValidate()
      }
    },
    edit_admin_qly(row) {
      this.admin_title = '编辑业务员'
      this.admin_qly_innerVisible = true
      this.intendantId = row.intendantId
      console.log('row----', row)
      // 清除表单内容  清除校验规则
      this.edit_admin_form = {}
      if (this.$refs.editAdminRuleForm) {
        this.$refs.editAdminRuleForm.clearValidate()
      }
      // this.edit_admin_form = row;
      this.edit_admin_form = JSON.parse(JSON.stringify(row))
    },
    // 禁用启用业务员
    start_admin_qly(row) {
      console.log(row)
      console.log('row.isLock--', row.isLock)
      const text = row.isLock == 1 ? '锁定' : '启用'
      const flag = row.isLock == 0 ? 1 : 0
      console.log('text的值----', text)
      this.$confirm(
        `是否确认${text}业务员       ${row.intendantName}`,
        `确认${text}业务员`,
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      ).then(() => {
        const params = new URLSearchParams()
        params.append('intendantId', row.intendantId)
        params.append('isLock', flag) // 0锁定 1启用
        this.$http
          .requestPost({
            url: '/customer/merchant/lockIntendant',
            param: params,
            loading: false
          })
          .then((res) => {
            this.initAdminList()
            this.$message.success('设置成功')
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    del_admin_qly(row) {
      console.log('row的值', row)
      // 是否确认删除业务员${row.intendantName}
      this.$confirm(
        `是否确认删除业务员       ${row.intendantName}`,
        '确认删除业务员',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }
      ).then(() => {
        this.$http
          .requestDel({
            url: '/customer/merchant/removeIntendant/' + row.intendantId
          })
          .then((res) => {
            this.initAdminList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
      })
    },
    // 给业务员添加登录历史
    history_admin_qly(row) {
      console.log('row的值--', row)
      this.loginIntendantId = row.intendantId
      this.intendantNameQly = `登录记录 - ${row.intendantName}`
      this.login_qly_innerVisible = true
      this.initLoginList()
    },
    // 初始化登录历史
    initLoginList() {
      const params = {
        intendantId: this.loginIntendantId, // 业务员id
        pageNum: 1, // 分页参数
        pageSize: 10 // 分页参数
      }
      console.log(this.loginPicker)
      if (this.loginValue != null) {
        this.loginPicker.beginTime = this.loginValue[0]
        this.loginPicker.endTime = this.loginValue[1]
        this.loginParams = Object.assign(params, this.loginPicker)
      } else {
        this.loginParams = params
      }
      this.$http
        .requestPost({
          url: '/customer/merchant/loginHistoricalRecord',
          param: this.loginParams,
          loading: false
        })
        .then((res) => {
          this.loginqlyData = res.data.list
          this.login_page.total = res.data.total
        })
        .catch((err) => {
          console.log(err)
        })
    },
    add_admin_enter(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.admin_title == '添加业务员') {
            const merchantId = {
              merchantId: this.merchantIdAdmin
            }
            const param_add = Object.assign(this.edit_admin_form, merchantId)
            this.$http
              .requestPut({
                url: '/customer/merchant/addIntendant',
                param: param_add
              })
              .then((res) => {
                this.initAdminList()
                this.$message.success('添加成功')
                this.admin_qly_innerVisible = false
              })
          } else if (this.admin_title == '编辑业务员') {
            const intendantId = {
              intendantId: this.intendantId
            }
            const params = Object.assign(this.edit_admin_form, intendantId)
            this.$http
              .requestPost({
                url: '/customer/merchant/editIntendant',
                param: params,
                loading: false
              })
              .then((res) => {
                this.$message.success('编辑成功')
                this.initAdminList()
                this.admin_qly_innerVisible = false
              })
              .catch((err) => {
                console.log(err)
              })
          }
        } else {
          return false
        }
      })
    },
    // 初始化业务员
    initAdminList() {
      console.log(this.selectKey)
      this.admin_page[this.selectKey] = this.selectValue
      this.admin_page.merchantId = this.merchantIdAdmin
      this.$http
        .requestPost({
          url: '/customer/merchant/intendantList',
          param: this.admin_page,
          loading: false
        })
        .then((res) => {
          console.log('res----', res)
          this.adminqlyData = res.data.list
          this.admin_page.total = res.data.total
        })
        .catch((err) => {
          console.log(err)
        })
    },
    handleSizeChange_admin(val) {
      this.admin_page.pageNum = 1
      this.admin_page.pageSize = val
      this.initAdminList()
    },
    handleCurrentChange_admin(val) {
      this.admin_page.pageNum = val
      this.initAdminList()
    },
    handleSizeChange_login() {
      this.login_page.pageNum = 1
      this.login_page.pageSize = val
      this.initLoginList()
    },
    handleCurrentChange_login(val) {
      this.login_page.pageNum = val
      this.initLoginList()
    },
    // 4.4.5需求 增删改查联系人
    handlePeo(row) {
      this.dialogTableVisible_people = true
      this.merchantId_peo = row.merchantId
      this.initPeoList()
    },
    // 初始化联系人
    initPeoList() {
      this.peo_page.merchantId = this.merchantId_peo
      this.$http
        .requestPost({
          url: '/customer/merchant/contactPersonList',
          param: this.peo_page,
          loading: false
        })
        .then((res) => {
          console.log('res----', res)
          this.peopleqlyData = res.data.list
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 联系人确定事件
    add_peo_enter(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.peo_qly_innerVisible = false
          if (this.peo_title == '添加联系人') {
            const merchantId = {
              merchantId: this.merchantId_peo
            }
            const param_add = Object.assign(this.edit_peo_form, merchantId)
            this.$http
              .requestPut({
                url: '/customer/merchant/addContactPerson',
                param: param_add
              })
              .then((res) => {
                this.initPeoList()
                this.$message.success('添加成功')
              })
          } else if (this.peo_title == '编辑联系人') {
            const phoneLinkId_peo = {
              phoneLinkId: this.phoneLinkId_peo
            }
            const params = Object.assign(this.edit_peo_form, phoneLinkId_peo)
            this.$http
              .requestPost({
                url: '/customer/merchant/editContactPerson',
                param: params,
                loading: false
              })
              .then((res) => {
                this.initPeoList()
                this.$message.success('编辑成功')
                console.log('编辑的res----', res)
              })
              .catch((err) => {
                console.log(err)
              })
          }
        } else {
          // this.$message.error("请完整信息在进行添加");

          return false
        }
      })
    },
    // 添加联系人
    add_peo_qly() {
      this.peo_qly_innerVisible = true
      this.peo_title = '添加联系人'
      // 清除表单内容  清除校验规则
      this.edit_peo_form = {}
      if (this.$refs.editPeoRuleForm) {
        this.$refs.editPeoRuleForm.clearValidate()
      }
    },
    // 编辑联系人
    edit_peo_qly(row) {
      this.phoneLinkId_peo = row.phoneLinkId
      this.peo_qly_innerVisible = true
      this.peo_title = '编辑联系人'
      // 清除表单内容  清除校验规则
      this.edit_peo_form = {}
      if (this.$refs.editPeoRuleForm) {
        this.$refs.editPeoRuleForm.clearValidate()
      }
      console.log('row的值', row)
      // this.edit_peo_form = row;
      this.edit_peo_form = JSON.parse(JSON.stringify(row))
      // this.$data.contract=JSON.parse(JSON.stringify(row))
    },
    // 删除联系人
    del_peo_qly(row) {
      console.log('row的值', row)
      this.$confirm('是否确认删除联系人?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$http
          .requestDel({
            url: '/customer/merchant/removeContactPerson/' + row.phoneLinkId
          })
          .then((res) => {
            this.initPeoList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
          })
      })
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    // 获取区域级联列表
    getDistrictIdList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data
      })
    },

    // 获取数据列表
    getTableList() {
      if (!this.inquire.areaId) {
        this.inquire.areaId = this.userInfo.areaId || ''
      }
      if (!this.daterange) this.daterange = []
      this.inquire.createTimeBegin =
          this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire.createTimeEnd =
          this.daterange.length > 0 ? this.daterange[1] : ''
      // 区分使用和停用列表
      this.inquire.deleted = this.$route.name === 'startShops' ? 0 : 1
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http
        .requestPost({
          url: '/customer/merchant/page',
          param: data,
          loading: false
        })
        .then((res) => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },

    // ==================== 商家档案 start ====================
    // 添加商家
    handleAdd() {
      this.dialogTitle = '新增商家'
      this.dialogVisible_1 = true
      this.isAdd = true
    },

    // 选择列表中每条数据事件
    handleSelectionChange(row) {
      this.merchantListData = [...row]
    },

    // 修改商家档案
    handleEdit(row) {
      this.isAdd = false
      this.dialogTitle = row.merchantName + ' - 商家档案'
      this.$refs.shopsDetails.getDetails(row.merchantId)
      this.dialogVisible_1 = true
      this.editRow = row
    },
    // 提交商家档案的修改
    submitAdd(param) {
      if (!this.isAdd) {
        param.merchantId = this.editRow.merchantId
      }
      this.submit(param)
    },
    // 提交
    submit(param) {
      this.$http
        .requestPost({
          url: '/customer/merchant/save',
          param: param
        })
        .then((res) => {
          this.getTableList()
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.$refs.shopsDetails.closeDialog()
        })
    },

    // ==================== 产品列表 ======================
    openProduct(row) {
      this.editRow = row
      this.dialogTitle = row.merchantName + ' - 产品列表'
      this.dialogVisible_2 = true
      this.$refs.productTable.initData(row.merchantId)
    },

    // ==================== 商家销售记录 start ====================
    // 页容量改变
    handleSizeChange_3(size) {
      this.pageParam_3.pageNum = 1
      this.pageParam_3.pageSize = size
      this.getSellList()
    },
    // 页码改变
    handleCurrentChange_3(page) {
      this.pageParam_3.pageNum = page
      this.getSellList()
    },

    // 打开销售记录弹窗
    openSellDialog(row) {
      this.editRow = row
      this.getSellList()
      this.$nextTick(() => {
        this.sellTitle = row.merchantName
        this.dialogVisible_3 = true
      })
    },

    // 获取销售列表
    getSellList() {
      const data = {
        pageNum: this.pageParam_3.pageNum,
        pageSize: this.pageParam_3.pageSize,
        param: {
          merchantId: this.editRow.merchantId,
          orderCode: this.inquire_3.orderCode
        }
      }

      this.loading_3 = true
      this.$http
        .requestPost({
          url: '/customer/merchant/getSalesRecords',
          param: data,
          loading: false
        })
        .then((res) => {
          this.loading_3 = false
          this.tableData_3 = res.data.list
          this.pageParam_3.total = res.data.total
        })
        .catch((err) => {
          this.loading_3 = false
          console.log(err)
        })
    },

    // 跳转订单详情
    toDetails(row) {
      let path = ''
      let index = 0
      switch (row.orderClassify) {
        case 'electronic':
          path = '/order/commerce/particulars'
          index = this.$api.EcommerceOrderState.findIndex(
            (item) => item.value === row.orderStatus
          )
          break
        case 'medicine':
          path = '/order/medical/hospitalDelivery/particulars'
          index =
              this.$api.orderState.findIndex(
                (item) => item.value === row.orderStatus
              ) + 1
          break
        case 'lease':
          path = '/order/lease/particulars'
          index = this.$api.leaseOrderState.findIndex(
            (item) => item.value === row.orderStatus
          )
          break
        default:
          return
      }
      this.$router.push({
        path,
        query: {
          type: index,
          id: row.orderId
        }
      })
    },
    // ==================== 商家销售记录 end ====================

    // ==================== 产品提成比例 start ====================
    // 页容量改变
    handleSizeChange_4(size) {
      this.pageParam_4.pageNum = 1
      this.pageParam_4.pageSize = size
      this.getCommissionList()
    },
    // 页码改变
    handleCurrentChange_4(page) {
      this.pageParam_4.pageNum = page
      this.getCommissionList()
    },
    // 打开产品提成比弹窗
    openCommission(row) {
      this.inquire_4.merchantId = row.merchantId
      this.sellTitle = row.merchantName
      this.getCommissionList()
      this.dialogVisible_4 = true
    },

    // 获取产品提成比列表
    getCommissionList() {
      const data = {
        pageNum: this.pageParam_4.pageNum,
        pageSize: this.pageParam_4.pageSize,
        param: this.inquire_4
      }

      this.loading_4 = true
      this.$http
        .requestPost({
          url: '/customer/merchant/getMerchantBrokerage',
          param: data,
          loading: false
        })
        .then((res) => {
          this.loading_4 = false
          this.tableData_4 = res.data.list
          this.pageParam_4.total = res.data.total
        })
        .catch((err) => {
          this.loading_4 = false
          console.log(err)
        })
    },
    // 修改提成
    amendCommission(row) {
      this.editRow = row
      this.submitForm_1 = {
        oldDefaultMerchantBrokerage: row.defaultMerchantBrokerage,
        defaultMerchantBrokerage: 0,
        oldFirstMerchantBrokerage: row.firstMerchantBrokerage,
        firstMerchantBrokerage: 0
      }
      this.dialogVisible_5 = true
    },

    // 确定修改提成
    confirm_2() {
      this.$refs.submitForm_1.validate((valid) => {
        if (valid) {
          console.log(this.submitForm_1)
          var param = {
            productId: this.editRow.productId,
            merchantId: this.editRow.merchantId,
            defaultMerchantBrokerage:
            this.submitForm_1.defaultMerchantBrokerage,
            firstMerchantBrokerage: this.submitForm_1.firstMerchantBrokerage
          }
          this.$http
            .requestPost({
              url: '/customer/merchant/setMerchantBrokerage',
              param
            })
            .then((res) => {
              this.$message.success('操作成功！')
              this.dialogVisible_5 = false
              this.getCommissionList()
            })
        } else {
          return false
        }
      })
    },

    // ==================== 重置密码 start ====================
    // 打开重置密码弹窗
    resetPwd(row) {
      this.$confirm('确定重置当前商家密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .requestPut({
              url: '/customer/merchant/reset/password/' + row.merchantId
            })
            .then((res) => {
              this.$message.success('重置成功!')
            })
            .catch(() => this.$message.success('重置失败!'))
        })
        .catch(() => {
        })
    },

    // ==================== 查看库存 start ====================
    // 打开库存弹窗
    inventory(row) {
      this.editRow = row
      this.loading_6 = true
      this.sellTitle = row.merchantName
      this.$http
        .requestGet({
          url: '/customer/merchant/getInventory/' + row.merchantId
        })
        .then((res) => {
          this.loading_6 = false
          this.tableData_6 = res.data
          this.dialogVisible_6 = true
        })
        .catch(() => {
          this.loading_6 = false
        })
    },

    // table 手风琴
    expandChange(news, old) {
      if (old.length === 0) {
        return (this.expands = [])
      }
      this.expands = [news.productCode]
    },
    // 库存
    handleBatch(row) {
      console.log(row)
      this.$http
        .requestPost({
          url: '/customer/merchant/getBatchList/' + row.inventoryId
        })
        .then((res) => {
          console.log(res)
          this.batchTable = res.data
          this.dialogVisible_batch = true
        })

      //   this.$prompt('请输入库存', '修改库存', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     inputPattern: /^[+]{0,1}(\d+)$/,
      //     inputErrorMessage: '请输入正整数',
      //     inputPlaceholder: row.inventoryTotal,
      //     inputValue: row.inventoryTotal,
      //   }).then(({ value }) => {
      //     this.$http.requestPut({ url: '/customer/merchant/changeInventoryMerchant/' + row.inventoryId + '/' + Number(value) }).then(res => {
      //       this.inventory(this.editRow)
      //       this.$message.success("操作成功！")
      //     })
      //   }).catch(() => { });
    },

    // ==================== 其它 start ====================
    // 变更
    handleModify(row) {
      this.merchantIdNew = row.merchantId
      if (row && row.merchantName) {
        this.titleNew = '医院列表 - ' + row.merchantName
      } else {
        this.titleNew = '医院列表'
      }
      this.$confirm('是否开始变更该商家的医院市场?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.dialogVisible_New = true
          this.pageParamNew.pageNum = 1
          this.hospitalNew = ''
          this.handleQueryNew()
        })
        .catch((err) => {
          console.log(err)
        })
    },

    // 启用停用事件
    handleDisable(row) {
      const { deleted, merchantId, merchantName } = row
      // 启用就传启用的状态
      const del = deleted === 1 ? 0 : 1
      // 启用则跳转到启用列表路由（startShops启用、stopShops停用）
      const pathText = del === 0 ? 'startShops' : 'stopShops'
      const operationText = deleted === 1 ? '启用' : '停用'
      const confirmMessage = `是否${operationText}该商家账号？`

      this.$confirm(confirmMessage, `【${merchantName}】${operationText}操作提示`, {
        confirmButtonText: '确定',
        type: 'warning'
      }).then(async() => {
        try {
          const res = await startAndStopMerchant({ deleted: del, merchantId })
          if (res.code === 200) {
            this.$message.success(`该商家已${operationText}!`)
          } else {
            this.$message.error('操作失败，请重试')
          }
          // 操作后跳转路由刷新列表
          this.$router.push({ path: `/user/shops/${pathText}` })
        } catch (error) {
          console.error('出现错误:', error)
        }
      })
    },

    // 导出
    exportFilex() {
      const param = JSON.parse(JSON.stringify(this.inquire))
      this.$http
        .download({
          url: '/customer/merchant/exportMerchant',
          param,
          method: 'POST'
        })
        .then((res) => {
          this.$public.downloadFile(res)
        })
    },

    exportTJ() {
      this.$http
        .download({
          url: '/customer/merchant/exportMerchantLogonInfo',
          method: 'GET'
        })
        .then((res) => {
          console.log('res========', res)
          this.$public.downloadFile(res)
        })
    },

    // 批量操作
    batchOperation() {
      const visible = this.merchantListData.length > 0
      if (!visible) {
        this.$message.warning('请选择商家再操作')
      }
      if (Number(this.inquire.stopPurchase) === 0) {
        this.subscribeVisible = visible
      } else {
        this.openSubscribeVisible = visible
      }
    },

    // 确认关闭申购权限事件
    async confirmSubscribeChange() {
      const stopPurchase = Number(this.inquire.stopPurchase)
      if (!this.subscribeForm.closeDate && stopPurchase === 0) {
        this.$message.warning('请选择日期')
        return
      }
      try {
        // 提交时增加确定按钮loading状态

        const params = {
          merchantIds: this.merchantListData.map(item => item.merchantId)
        }
        if (stopPurchase === 0) {
          this.subscribeBtnLoading = true
          // 与当前筛选状态取反
          params.stopPurchase = 1
          params.stopStartTime = `${this.subscribeForm.closeDate[0]} 00:00:00`
          params.stopEndTime = `${this.subscribeForm.closeDate[1]} 23:59:59`
        } else {
          params.stopPurchase = 0
        }
        const res = await stopPurchaseApi(params)
        if (res.code === 200) {
          this.$message.success('操作成功')
          setTimeout(() => {
            this.subscribeBtnLoading = false
            this.subscribeVisible = false
            this.openSubscribeVisible = false
            // 清空列表中的复选框选择
            this.$refs.multipleTable.clearSelection()
            this.searchList()
          }, 500)
        }
      } catch (error) {
        console.error('出现错误:', error)
        this.subscribeBtnLoading = false
      }
    },

    // 导出库存表
    handleExport(row) {
      exportMerchantInventoryRecords(row.merchantId).then(res => {
        this.$public.downloadFile(res)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Popout_content {
  .flex-box .demo-form-inline {
    flex: 1;
  }

  .btn_do {
    color: #409eff;
    z-index: 99;
  }

  .people_qly_btn {
    margin: 20px 20px;
    float: right;
  }

  .people_qly_dialog {
    .people_qly_table {
      margin-top: 20px;
    }
  }

  .total-money {
    min-width: 200px;
    font-size: 18px;
    line-height: 2;
    text-align: right;

    span {
      color: #fd6040;
    }
  }

}

.img-box {
  width: 58px;
  height: 58px;
}

.icon_box {
  width: 21px;
  position: absolute;
  left: 0;
  top: calc(100% + 2px);

  &.no_img {
    margin-right: 3px;
    position: static;
  }
}

.txt_box {
  position: relative;
  line-height: 1;
}
</style>

