var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-box" }, [
    _c(
      "div",
      { staticClass: "head-search-row" },
      [
        _c(
          "el-form",
          {
            staticClass: "demo-form-inline",
            attrs: { inline: true, size: "medium" },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "交易时间" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "datetimerange",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    "range-separator": "至",
                    "default-time": ["00:00:00", "23:59:59"],
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                  },
                  model: {
                    value: _vm.daterange,
                    callback: function ($$v) {
                      _vm.daterange = $$v
                    },
                    expression: "daterange",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "订单类型" } },
              [
                _c(
                  "el-select",
                  {
                    attrs: { placeholder: "请选择" },
                    model: {
                      value: _vm.inquire.orderClassify,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "orderClassify", $$v)
                      },
                      expression: "inquire.orderClassify",
                    },
                  },
                  [
                    _c("el-option", {
                      attrs: { label: "电商品订单", value: "electronic" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "医药品订单", value: "medicine" },
                    }),
                    _vm._v(" "),
                    _c("el-option", {
                      attrs: { label: "租赁品订单", value: "lease" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.searchList },
                  },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticStyle: { margin: "20px 0 0 20px" } },
      [
        _c("el-button", { attrs: { size: "small", type: "primary" } }, [
          _vm._v("导出报表"),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-main",
          [
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, border: "", height: "400px" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                (_vm.pageParam.pageNum - 1) *
                                  _vm.pageParam.pageSize +
                                  (scope.$index + 1)
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "times", label: "日期", "min-width": "160" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderTotalPrice",
                      label: "收款金额",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  "￥" + (scope.row.orderTotalPrice || 0)
                                ) +
                                "\n            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderCount",
                      label: "订单数量",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(_vm._s(scope.row.orderCount || 0))]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "memberCount",
                      label: "交易会员数",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [_vm._v(_vm._s(scope.row.memberCount || 0))]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderReturnTotalPrice",
                      label: "退款金额",
                      "min-width": "120",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                "￥" + (scope.row.orderReturnTotalPrice || 0)
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderReturnCount",
                      label: "退款订单数",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(_vm._s(scope.row.orderReturnCount || 0)),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "memberReturnCount",
                      label: "退款会员数",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(_vm._s(scope.row.memberReturnCount || 0)),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "操作",
                      "min-width": "120",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.details(scope.row)
                                  },
                                },
                              },
                              [_vm._v("交易明细")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "current-page": _vm.pageParam.pageNum,
                    "page-sizes": _vm.$api.pageSizes,
                    "page-size": _vm.pageParam.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.pageParam.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }