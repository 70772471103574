<template>
  <!-- 产品列表-管理商家弹窗 -->
  <div class="main-box manageMerchants">
    <el-dialog :title="`${isBind ? '' : '新增'}管理商家`" :visible.sync="dialogVisible" width="65%" top="7vh" :before-close="handleClose">
      <div class="head-search-row search_box">
        <el-form :inline="true" class="demo-form-inline" size="small">
          <el-form-item label="商家端名称">
            <div class="flex-box">
              <el-input v-model="queryData.param.merchantName" placeholder="输入商家端名称" clearable @keyup.enter.native="updateList('mainTable')" />
            </div>
          </el-form-item>
          <el-form-item label="所属区域">
            <el-cascader
              v-if="$public.isAllAreaPower()"
              v-model="queryData.param.areaId"
              :props="addressConfigure"
              :options="addressList"
              clearable
              placeholder="请选择区域"
            />
            <d-area v-else :values.sync="queryData.param.areaId" />
          </el-form-item>
          <el-form-item style="margin-left: 15px;">
            <el-button type="primary" @click="updateList('mainTable')">查询</el-button>
            <!-- <el-button type="primary" @click="batchDelMerchants">批量删除</el-button> -->
            <el-button type="primary" @click="addMerchants">添加</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 表格 -->
      <div class="table_box">
        <TableList
          ref="mainTable"
          v-loading="tableData.loading"
          class="tableList"
          row-key-name="merchantId"
          height="400px"
          :table-header="tableData.tableHeader"
          :is-selection="true"
          :table-data="tableData.list"
          :total="tableData.total"
          @setPage="pageChange($event, 'mainTable')"
          @setSize="sizeChange($event, 'mainTable')"
          @delXuan="selectionChange"
        >
          <template #operation="{data}">
            <el-button class="operate_btn" type="text" @click="singleDel(data)">删除</el-button>
          </template>
        </TableList>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-show="!isBind" @click="handlerCancel">取 消</el-button>
        <el-button type="primary" @click="handlerConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import { getMerchantByProductId, saveProductMerchant, delMerchantProduct } from '@/api/api'

export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      rowData: {}, // 行数据
      isBind: true, // true-已绑定的商家 false-未绑定的商家

      // 区域选择下拉配置
      addressConfigure: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },
      // 区域数据
      addressList: [],
      // 请求数据
      queryData: {
        pageNum: 1,
        pageSize: 10,
        param: {
          productId: '', // 产品id
          merchantName: '', // 商家名称
          areaId: '', // 区域id
          isBind: '' // 是否查询绑定商家 true：查询绑定商家 false：查询未绑定商家
        }
      },
      // 表格数据
      tableData: {
        loading: false,
        tableHeader: [
          // 表头
          { label: '商家端编号', prop: 'merchantCode', width: '200px' },
          { label: '商家端名称', prop: 'merchantName', width: '160px' },
          { label: '所属大区', prop: 'regionName', width: '160px' },
          { label: '所属片区', prop: 'districtName', width: '160px' },
          { label: '所属办事处', prop: 'agencyName', width: '160px' },
          { label: '操作', type: 'slot', slotName: 'operation', fixed: 'right', isHide: false, width: '160px' }
        ],
        list: [], // 返回的列表数据
        total: 0
      },
      selectData: '' // 选中的数据
    }
  },
  computed: {},
  watch: {},
  created() {
    // 获取用户信息
    const userInfo = getSessionStorage('userInfo') || {}
    if (userInfo.managerType === 'all') {
      this.getAreaList()
    }
  },
  methods: {
    open(data) {
      console.log('data===>>>>', data)
      this.dialogVisible = true
      this.rowData = data
      this.isBind = true
      this.resetTable()
    },
    handleClose(done) {
      // 重置数据
      this.queryData = Object.assign({}, this.$options.data().queryData)
      // 清空选中的数据
      this.$refs.mainTable.clearSelection()
      done()
    },
    // 获取区域级联数据
    getAreaList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data
      })
    },

    // 改变页码
    pageChange(pageVal, type) {
      switch (type) {
        case 'mainTable': // 二维码列表
          this.queryData.pageNum = pageVal
          this.getMerchantList()
          break

        default:
          break
      }
    },
    // 改变页数
    sizeChange(sizeVal, type) {
      switch (type) {
        case 'mainTable':
          this.queryData.pageNum = 1
          this.queryData.pageSize = sizeVal
          this.getMerchantList()
          break

        default:
          break
      }
    },
    // 搜索
    updateList(type) {
      switch (type) {
        case 'mainTable':
          this.queryData.pageNum = 1
          this.getMerchantList()
          break

        default:
          break
      }
    },
    // 多选
    selectionChange(rows) {
      this.selectData = rows
    },
    // 重置表格
    resetTable(isHide = false) {
      // 重置数据
      this.queryData = Object.assign({}, this.$options.data().queryData)
      this.selectData = []
      // 显示操作项
      this.tableData.tableHeader[this.tableData.tableHeader.length - 1].isHide = isHide
      this.updateList('mainTable')
      // 回到第一页
      this.$nextTick(() => {
        this.$refs.mainTable.handleCurrentChange(1, false)
        this.$refs.mainTable.clearSelection()
      })
    },
    // 添加
    addMerchants() {
      this.isBind = false
      this.resetTable(true)
    },
    // 取消按钮
    handlerCancel() {
      if (!this.isBind) {
        this.isBind = true
        this.resetTable()
      } else {
        this.dialogVisible = false
      }
    },
    // 确定按钮
    handlerConfirm() {
      if (!this.isBind) {
        if (!this.selectData.length) {
          return this.$message.warning('请选择商家')
        }
        // 确定添加商家
        this.addMerchant()
      } else {
        this.dialogVisible = false
      }
    },

    // 获取已绑定商家列表
    async getMerchantList() {
      this.tableData.loading = true
      this.tableData.list = []
      // 是否查询绑定商家 true：查询绑定商家 false：查询未绑定商家
      this.queryData.param.isBind = this.isBind
      this.queryData.param.productId = this.rowData.productId

      const res = await getMerchantByProductId(this.queryData)
      // console.log('res===>>>>', res)
      if (res.code === 200) {
        this.tableData.total = res.data.total
        this.tableData.list = res.data.list
        this.tableData.loading = false
      } else {
        this.tableData.loading = false
        console.log('getMerchantByProductId:Error===>>>>', res)
      }
    },
    // 添加商家
    async addMerchant() {
      let merchantIdList = []
      merchantIdList = this.selectData.map(item => {
        return item.merchantId
      })
      const res = await saveProductMerchant(this.rowData.productId, merchantIdList.join(','))
      if (res.code === 200) {
        this.$message.success('添加成功')
        this.isBind = true
        this.resetTable()
      } else {
        this.$message.error('添加失败')
        console.log('saveProductMerchant:Error===>>>>', res)
      }
    },
    // 单个删除商家
    singleDel(row) {
      this.$confirm(`确认是否删除该商家`, `提示`, {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning'
      })
        .then(async res => {
          const result = await delMerchantProduct(row.id)
          if (result.code === 200) {
            if(result.data) {
              this.$message.success('删除成功')
            } else {
              this.$message.warning('当前商家库存大于零，不可删除')
            }
            this.resetTable()
          } else {
            this.$message.error('删除失败')
            console.log('delMerchantProduct:Error===>>>>', res)
          }
        })
        .catch(err => {})
    }
  }
}
</script>
<style lang="scss" scoped>
.manageMerchants {
  .table_box {
    .tableList {
      width: 100%;
      margin: 0;
      padding: 20px 20px 10px;
      /deep/ .el-button {
        padding: 0;
      }
      /deep/ .el-button + .el-button {
        margin: 0;
      }
      .operate_btn {
        &::before {
          content: '/';
          font-size: 12px;
          color: #4e9aef;
          vertical-align: top;
          margin-right: 5px;
        }
        &:first-child::before {
          content: '';
          margin: 0;
        }
      }
    }
  }
}
</style>
