var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chartNum" }, [
    _c(
      "div",
      { staticClass: "box-item" },
      _vm._l(_vm.orderNum, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            class: { "number-item": !isNaN(item), "mark-item": isNaN(item) },
          },
          [
            !isNaN(item)
              ? _c("div", [
                  _c(
                    "div",
                    {
                      ref: "numberItem",
                      refInFor: true,
                      attrs: { "data-item": item },
                    },
                    [_vm._v("0123456789")]
                  ),
                ])
              : _c("span", { staticClass: "comma" }, [_vm._v(_vm._s(item))]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }