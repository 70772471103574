<template>
  <div class="main-box">
      <div class="head-search-row">
        <el-form :model="queryParams" size="small" :inline="true" label-width="96px">
      <el-form-item label="咨询时间">
        <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="营养师名称">
        <el-input v-model.trim="queryParams.dietitianName" placeholder="请输入营养师名称" clearable style="width: 240px;" @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="营养师编号">
        <el-input v-model.trim="queryParams.dietitianCode" placeholder="请输入营养师编号" clearable style="width: 240px;" @keyup.enter.native="handleQuery" />
      </el-form-item>
    </el-form>
    <el-form :model="queryParams" size="small" :inline="true" label-width="96px">
    <el-form-item label="用户编号">
        <el-input v-model.trim="queryParams.userCode" placeholder="请输入用户编号" clearable style="width: 240px;" @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>
      </div>
      <div class="table">
            <el-main>
                <template>
                    <el-table :data="tableData" border height="400px" style="width: 100%">
                        <el-table-column label="序号" prop="date" width="60">
                            <template v-slot="scope">
                                {{ (queryParams.pageNum - 1) * queryParams.pageSize + (scope.$index + 1) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="用户编号" min-width="180" prop="userId" show-overflow-tooltip></el-table-column>
                        <el-table-column label="关联营养师" min-width="300" prop="dietitianName" show-overflow-tooltip=""></el-table-column>
                        <el-table-column label="最近咨询时间" min-width="160" prop="lastSendTime"></el-table-column>
                        <el-table-column fixed="right" label="操作" min-width="120" prop="">
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleDetails(scope.row)">咨询详情</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination
                        :current-page="queryParams.pageNum"
                        :page-size="queryParams.pageSize"
                        :page-sizes="$api.pageSizes"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </el-main>
        </div>
        <!-- 点击出现详情弹框 -->
      <el-dialog :title="title" destroy-on-close :visible.sync="showDialog" width="60%" append-to-body @open="openEvent" @close="closeEvent">
      <el-form  :inline="true" >
         <el-form-item label="发送时间">
         <el-date-picker v-model="dateRange_1" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
         </el-form-item>
         <el-form-item label="咨询内容">
         <el-input v-model.trim="formData.msgContent" placeholder="请输入内容" />
         </el-form-item>
      <el-form-item>
         <el-button type="primary" size="mini" @click="handleQuery_1">查询</el-button>
      </el-form-item>
      </el-form>
      <el-table ref="mainTable" v-loading="loading_1" 
      :data="tableList" height="400" border style="margin-top: 30px" 
      :header-cell-style="{background:'#409eff',color:'#ffffff'}">
       <el-table-column label="序号" prop="date" width="60">
          <template v-slot="scope">
              {{ (formData.pageNum - 1) * formData.pageSize + (scope.$index + 1) }}
          </template>
        </el-table-column>
         <el-table-column label="发送者" align="center" prop="userName" width="200" />
         <el-table-column label="咨询时间" align="center" prop="sendTime" />
         <el-table-column label="内容" align="center" prop="msgContent" min-width="200"/>
      </el-table>
      <div class="pagination">
            <el-pagination
                :current-page="formData.pageNum"
                :page-size="formData.pageSize"
                :page-sizes="$api.pageSizes"
                :total="total_1"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange_1"
                @current-change="handleCurrentChange_1"
            ></el-pagination>
        </div>
   </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
      return {
        title:"咨询详情",
        dateRange:[],
         queryParams:{
          pageNum: 1, 
          pageSize: 10, 
          consultationBeginTime: null, 
          consultationEndTime: null, 
          dietitianName: "", 
          dietitianCode: "", 
          userCode: "" 
         },
        // 表格数据
        tableData: [],
        loading: true,
        total:0,
        dateRange_1:[],
        showDialog:false,
        formData:{
          pageNum:1,
          pageSize:10,
          sendBeginTime:null,
          sendEndTime:null,
          msgContent:'',
          groupId:''
        },
        loading_1:true,
        tableList:[],
        total_1:0,
      };
  },
  created() {
    this.getDataList()
  },
  methods: {
      // 页容量改变
    handleSizeChange(size) {
        this.queryParams.pageNum = 1;
        this.queryParams.pageSize = size;
        this.getDataList()
    },
    // 页码改变
    handleCurrentChange(page) {
        this.queryParams.pageNum = page;
        this.getDataList();
    },
      // 刷新
     handleQuery() {
      this.queryParams.pageNum = 1
      this.getDataList()
   },
    // 获取数据列表
    getDataList() {
      if (!this.dateRange) this.dateRange = [];
    this.queryParams.consultationBeginTime = this.dateRange.length > 0 ? this.dateRange[0] : '';
    this.queryParams.consultationEndTime = this.dateRange.length > 0 ? this.dateRange[1] : '';
    this.$http.requestPost({url: '/consult/userConsultationRecord', param: this.queryParams, loading: false}).then(res => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.total;
    }).catch(err => {
        this.loading = false;
        console.log(err)
    })
   },
   handleDetails(row){
    this.showDialog = true
    this.formData.groupId = row.groupId
   },
    openEvent(){
      this.getTableList()
    },
    closeEvent(){
      this.formData = {
        pageNum:1,
        pageSize:10,
        sendBeginTime:null,
        sendEndTime:null,
        msgContent:'',
        groupId:''
      }
    },
    getTableList(){
      if (!this.dateRange_1) this.dateRange_1 = [];
    this.formData.sendBeginTime = this.dateRange_1.length > 0 ? this.dateRange_1[0] : '';
    this.formData.sendEndTime = this.dateRange_1.length > 0 ? this.dateRange_1[1] : '';
    this.$http.requestPost({url: '/consult/userConsultDetail', param: this.formData, loading: false}).then(res => {
        this.loading_1 = false;
        this.tableList = res.data.list;
        this.total_1 = res.data.total;
    }).catch(err => {
        this.loading_1 = false;
        console.log(err)
    })
    },
    handleQuery_1(){
      this.getTableList()
    },
      // 页码改变
    handleCurrentChange_1(page) {
      this.formData.pageNum = page;
      this.getTableList();
    },
      // 页容量改变
    handleSizeChange_1(size) {
      this.formData.pageNum = 1;
      this.formData.pageSize = size;
      this.getTableList()
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
