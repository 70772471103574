var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-box" }, [
    _c(
      "div",
      { staticClass: "head-search-row" },
      [
        _c(
          "el-form",
          {
            staticClass: "demo-form-inline",
            attrs: { inline: true, size: "medium" },
          },
          [
            _c("div", { staticClass: "form-buttons" }, [
              _c(
                "div",
                [
                  _vm.showType
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "year",
                              "value-format": "yyyy",
                              placeholder: "年份",
                            },
                            model: {
                              value: _vm.inquire.year,
                              callback: function ($$v) {
                                _vm.$set(_vm.inquire, "year", $$v)
                              },
                              expression: "inquire.year",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showType
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "月份", clearable: "" },
                              model: {
                                value: _vm.inquire.month,
                                callback: function ($$v) {
                                  _vm.$set(_vm.inquire, "month", $$v)
                                },
                                expression: "inquire.month",
                              },
                            },
                            _vm._l(_vm.monthList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showType
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.searchList },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pageType === "attache"
                    ? _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: { click: _vm.handleExport },
                            },
                            [_vm._v("导出明细列表")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "medium" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("返回")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-main",
          [
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "tables",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    "expand-row-keys": _vm.expands,
                    "row-key": "orderId",
                    height: "400px",
                  },
                  on: { "expand-change": _vm.expandChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "expand" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-table",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { data: scope.row.items, border: "" },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    label: "产品信息",
                                    "min-width": "200",
                                    prop: "",
                                    "show-overflow-tooltip": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "flex-box" },
                                              [
                                                scope.row.productImg
                                                  ? _c("img", {
                                                      staticClass: "img-box",
                                                      attrs: {
                                                        src: scope.row
                                                          .productImg,
                                                        alt: "",
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "hospital-name",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        scope.row.productName
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "产品编号",
                                    "min-width": "160",
                                    prop: "productCode",
                                    "show-overflow-tooltip": "",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "产品规格",
                                    "min-width": "120",
                                    prop: "productNorms",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              _vm._s(
                                                scope.row.productNorms || "/"
                                              )
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "单价",
                                    "min-width": "120",
                                    prop: "itemPrice",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              _vm._s("￥" + scope.row.itemPrice)
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "数量",
                                    "min-width": "120",
                                    prop: "itemNum",
                                  },
                                }),
                                _vm._v(" "),
                                _c("el-table-column", {
                                  attrs: {
                                    label: "购买总价",
                                    "min-width": "120",
                                    prop: "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _vm._v(
                                              "\n                              " +
                                                _vm._s(
                                                  "￥" +
                                                    (
                                                      parseInt(
                                                        scope.row.itemNum
                                                      ) * scope.row.itemPrice
                                                    ).toFixed(2)
                                                ) +
                                                "\n                          "
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderCode",
                      label: "订单编号",
                      "min-width": "200",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "买家/收货人",
                      "min-width": "160",
                      prop: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      scope.row.memberNickName &&
                                      scope.row.recipient,
                                    expression:
                                      "scope.row.memberNickName && scope.row.recipient",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      scope.row.memberNickName +
                                        "/" +
                                        scope.row.recipient
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !scope.row.recipient,
                                    expression: "!scope.row.recipient",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(scope.row.memberNickName) +
                                    "\n                "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "总金额", "min-width": "120", prop: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  "￥" + scope.row.orderTotalPrice.toFixed(2)
                                ) +
                                "\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "completeTime",
                      label: "完成时间",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "订单状态",
                      "min-width": "120",
                      prop: "orderStatus",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm.$api.getValue(
                                    _vm.$api.orderStatusList,
                                    scope.row.orderStatus,
                                    "name"
                                  )
                                ) +
                                "\n              "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "attacheName",
                      label: "所属专员",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "brokerageAmount",
                      label: "提成金额",
                      "min-width": "100",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                "￥" +
                                  (scope.row.items.length > 0
                                    ? scope.row.items
                                        .reduce(function (n, e) {
                                          return n + e.brokerageAmount
                                        }, 0)
                                        .toFixed(2)
                                    : "0")
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "操作",
                      "min-width": "140",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.details(scope.row)
                                  },
                                },
                              },
                              [_vm._v("订单详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pagination" },
              [
                _c("el-pagination", {
                  attrs: {
                    background: "",
                    "current-page": _vm.pageParam.pageNum,
                    "page-sizes": _vm.$api.pageSizes,
                    "page-size": _vm.pageParam.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.pageParam.total,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }