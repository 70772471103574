<template>
  <!-- 咨询列表 -->
    <div><router-view /></div>
  </template>
  
  <script>
  export default {}
  </script>
  
  <style>
  </style>