<template>
  <div class="d-upload">
    <el-upload
      class="upload-demo"
      :action="$http.requestUrl + action"
      :list-type="uploadType"
      :before-upload="beforeUpload"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :multiple="multiple"
      :limit="limit"
      :on-exceed="handleExceed"
      :drag="drag"
      :accept="accept"
      :file-list="fileList"
      :data="param"
      :on-success="uploadSuccess"
      :headers="headers"
      :show-file-list="showFileList"
      :on-error="uploadError"
      :auto-upload="autoUpload"
      :on-change="handleChange"
      :disabled="disabled"
    >
      <slot ></slot>
      <div v-if="tip" slot="tip" class="el-upload__tip">{{ tip }}</div>
    </el-upload>

    <!-- 弹框 -->
    <el-dialog title="图片预览" :visible.sync="dialogVisible_1" :modal="false" width="60%" top="5vh">
      <div class="Popout_content">
        <div v-if="fileType === 'image'" class="image-box">
          <img :src="preview" alt="" />
        </div>
        <div v-else>
          <video id="video" autoplay :src="preview" controls="controls" width="100%" :height="videoHeight">视频描述</video>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_1 = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSessionStorage, setSessionStorage } from '@/utils/auth.js'

export default {
  name: 'DUpload',
  props: {
    action: {
      type: String,
      default: '/common/file/upload'
    },
    param: {
      type: Object,
      default: () => {}
    },
    multiple: {
      // 是否多选
      type: Boolean,
      default: false
    },
    showFileList: {
      // 是否显示文件列表
      type: Boolean,
      default: true
    },
    drag: {
      // 是否拖拽上传
      type: Boolean,
      default: false
    },
    limit: {
      // 允许上传数量
      type: Number,
      default: 1
    },
    accept: {
      // 接受上传文件类型
      type: String,
      default: ''
    },
    fileList: {
      // 上传文件列表
      type: Array,
      default: () => []
    },
    size: {
      // 限制上传文件大小
      type: Number,
      default: 10
    },
    uploadType: {
      type: String,
      default: ''
    },
    tip: {
      type: String,
      default: ''
    },
    headers: {
      type: Object,
      default: () => {
        return {
          Authorization: getSessionStorage('userToken')
        }
      }
    },
    autoUpload: {
      type: Boolean,
      default: true
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    // 视频的高度
    videoHeight: {
      type: String,
      default: '100%'
    }
  },
  data() {
    return {
      preview: '', // 预览图片路径
      fileType: '', // 文件类型
      dialogVisible_1: false, // 预览弹窗
      removeB: true
    }
  },
  methods: {
    //
    handleChange(file, fileList) {
      console.log(file)
      this.removeB = true
      const fileSize = file.size / 1024 / 1024 > this.size
      if (fileSize) {
        this.$message({
          message: '上传文件的大小不能大于' + this.size + 'M',
          type: 'warning'
        })
        this.removeB = false
        return false
      } else {
        this.$emit('handleChange', file, fileList)
        this.removeB = false
      }
    },
    // 文件上传之前
    beforeUpload(file) {
      this.removeB = true
      const fileSize = file.size / 1024 / 1024 > this.size
      if (fileSize) {
        this.$message({
          message: '上传文件的大小不能大于' + this.size + 'M',
          type: 'warning'
        })
        this.removeB = false
        return false
      } else {
        this.$emit('beforeUpload', file)
        this.removeB = false
      }
    },
    // 删除文件
    handleRemove(file, fileList) {
      this.$emit('removeFile', file, fileList)
    },
    // 预览图片
    handlePreview(file) {
      if (file.raw) {
        console.log('file.raw', file.raw)
        const type = file.raw.type.split('/')
        this.fileType = type[0]

        const reader = new FileReader()
        reader.readAsDataURL(file.raw)
        reader.onload = e => {
          console.log(e)
          // _base64
          this.preview = reader.result
          console.log('reader.result', reader.result)
        }
      } else if (file.url) {
        const imgT = ['.gif', '.jpeg', '.jpg', '.png', '.PNG']
        const videoT = ['.wmv', '.asf', '.asx', '.rm', '.rmvb', '.rmvb', '.mpg', '.mpeg', '.mpe', '.3gp', '.mov', '.mp4', '.m4v']
        const ind = file.url.lastIndexOf('.')
        const type = file.url.slice(ind)
        if (imgT.indexOf(type) !== -1) {
          this.fileType = 'image'
        } else if (videoT.indexOf(type) !== -1) {
          this.fileType = 'video'
        }
        this.preview = file.url
      }

      this.dialogVisible_1 = true
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 ${this.limit} 个文件`)
    },
    // 删除文件之前
    beforeRemove(file, fileList) {
      if (this.removeB) {
        return this.$confirm(`确定移除 ${file.name || ''}？`)
      } else {
        return true
      }
    },
    // 上传成功
    uploadSuccess(response, file, fileList) {
      if (response.code == 200) {
        this.$emit('uploadSuccess', response, file, fileList)
      } else {
        this.$emit('uploadError', response, file, fileList)
      }
    },
    uploadError(err, file, fileList) {
      console.log(err)
    }
  }
}
</script>

<style lang="scss" scoped>
.image-box {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
