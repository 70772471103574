<template>
  <!-- 申购-订单详情 -->
  <div class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>

    <div class="steps-box bg_white">
      <h4>申购状态：{{ $api.getValue($api.purchaseRequisitionStatus, pageData.purchaseStatus, 'name') }}</h4>
      <h4 v-if="pageData.purchaseStatus == 'unaudited'" style="margin-top: 24px;">驳回原因：{{ pageData.rejectReason || '无' }}</h4>
    </div>

    <div class="row_3 bg_white">
      <h4>申购商品</h4>
      <div class="table">
        <el-table :data="pageData.items" border style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="220" prop="available" show-overflow-tooltip>
            <template slot-scope="scope">
              <div class="flex-box">
                <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box" />
                <div class="hospital-name">
                  <div class="txt_box" :class="{ 'flex-box': !scope.row.productImg, 'flex-center': !scope.row.productImg }">
                    <img
                      v-if="scope.row.isGifts"
                      src="@/assets/image/gift_icon.png"
                      alt=""
                      class="icon_box"
                      :class="{ no_img: !scope.row.productImg }"
                    />
                    {{ scope.row.productName }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="批次号" min-width="100" prop="batchNo">
            <template slot-scope="scope">
              {{ scope.row.batchNo }}
            </template>
          </el-table-column>
          <el-table-column label="发货数量" min-width="100" prop="productNum">
            <template slot-scope="scope">
              {{ scope.row.productNum }}
            </template>
          </el-table-column>
          <el-table-column label="有效期" min-width="150">
            <template slot-scope="scope">
              {{ scope.row.produceTime }}&nbsp;{{ '~' }}&nbsp;{{ scope.row.expireTime }}
            </template>
          </el-table-column>
          <el-table-column label="单价" min-width="100" prop="itemPrice">
            <template slot-scope="scope">
              {{ '￥' + scope.row.itemPrice }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="商家该产品货值" min-width="140" prop="productValue">
                        <template slot-scope="scope">
                            {{ '￥' + (scope.row.productValue || 0) }}
                        </template>
                    </el-table-column> -->
          <el-table-column label="数量" min-width="100" prop="itemNum" />
          <el-table-column label="小计" min-width="100" prop="">
            <template slot-scope="scope">
              {{ '￥' + (scope.row.itemPrice * scope.row.itemNum).toFixed(2) || '0' }}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="show-money">
        <ul>
          <li>
            商品金额：
            <span class="block_span">￥ {{ goodsMoney.toFixed(2) || '0.00' }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row_4 bg_white">
      <h4>商家/医院信息</h4>
      <p class="color_light-black">
        <span class="title-box">商家名称：</span>
        {{ pageData.merchantName }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家编号：</span>
        {{ pageData.merchantCode }}
      </p>
      <!-- <p class="color_light-black"><span class="title-box">商家所有产品总货值：</span>{{ '￥' + pageData.merchantAllValue }}</p> -->
    </div>

    <div class="row_5 row_4 bg_white">
      <h4>申购单信息</h4>
      <p class="color_light-black">
        <span class="title-box">申购单编号：</span>
        {{ pageData.orderCode }}
      </p>
      <p class="color_light-black">
        <span class="title-box">申购时间：</span>
        {{ pageData.purchaseTime }}
      </p>
      <p class="color_light-black">
        <span class="title-box">收货人：</span>
        {{ pageData.receiptName }}
      </p>
      <p class="color_light-black">
        <span class="title-box">收货人手机号：</span>
        {{ pageData.receiptPhoneNumber }}
      </p>
      <p class="color_light-black">
        <span class="title-box">收货地址：</span>
        {{ pageData.address }}
      </p>
      <p v-if="pageData.purchaseStatus !== 'auditing'" class="color_light-black">
        <span class="title-box">审核时间：</span>
        {{ pageData.auditTime }}
      </p>
      <p v-if="pageData.purchaseStatus !== 'auditing'" class="color_light-black">
        <span class="title-box">审核人：</span>
        {{ pageData.auditByName }}
      </p>
      <p v-if="pageData.purchaseStatus == 'unaudited'" class="color_light-black">
        <span class="title-box">审核不通过原因：</span>
        {{ pageData.rejectReason }}
      </p>
      <p class="color_light-black">
        <span class="title-box">备注：</span>
        {{ pageData.remark || '无' }}
      </p>
    </div>

    <!-- <div class="row_5 row_4 bg_white" v-if="pageData.batches">
      <h4>批次信息</h4>
      <div v-for="(item, index) in pageData.batches" :key="index">
        <p class="color_light-black">
          <span class="title-box">批次号：</span> {{ item.batchNo }}
        </p>
        <p class="color_light-black">
          <span class="title-box">发货数量：</span>{{ item.productNum }}
        </p>
        <p class="color_light-black">
          <span class="title-box">有效期：</span>{{ item.produceTime }} ~
          {{ item.expireTime }}
        </p>
      </div>
    </div> -->

    <div v-if="showBtn(pageData.purchaseStatus)" class="row_6 row_4 bg_white">
      <h4>物流信息</h4>
      <p class="color_light-black">
        <span class="title-box">物流公司：</span>
        {{ pageData.logisticsCompany }}
      </p>
      <p class="color_light-black">
        <span class="title-box">物流单号：</span>
        {{ pageData.logisticsCode }}
      </p>
      <p class="color_light-blue">
        <span class="title" @click="handleLogistics(pageData)">查看物流</span>
      </p>
    </div>

    <div v-if="pageData.purchaseStatus === 'auditing'" class="row_7 row_4 bg_white">
      <!-- <h4>商家近3个月销售额概况</h4>
            <div id="echars-box">

            </div> -->
      <div class="flex-box">
        <el-button type="primary" @click="pass">通过</el-button>
        <el-button @click="reject">驳回</el-button>
      </div>
    </div>

    <el-dialog :before-close="close" :title="dialogTitle" :visible.sync="dialogVisible_1" width="500px">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px">
          <el-form-item v-show="auditState === 1" :prop="auditState === 1 ? 'logisticsCompany' : ''" label="物流公司">
            <el-select v-model="submitForm.logisticsCompany" class="width-inherit" placeholder="请选择">
              <el-option v-for="item in logisticsCompanyList" :key="item.companyId" :label="item.companyName" :value="item.companyId" />
            </el-select>
          </el-form-item>

          <el-form-item v-show="auditState === 1" :prop="auditState === 1 ? 'logisticsCode' : ''" label="物流单号">
            <el-input v-model="submitForm.logisticsCode" maxlength="30" placeholder="物流单号" />
          </el-form-item>

          <el-form-item v-show="auditState === 2" :prop="auditState === 2 ? 'rejectReason' : ''" label="驳回原因">
            <el-input v-model="submitForm.rejectReason" placeholder="请输入" rows="6" maxlength="150" type="textarea" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- v4.4.5物流信息弹出框 -->
    <d-dialog ref="logisticsDialog" :logistics-list="logisticsList" :data-obj="dataObj" :route-info="routeInfo" @handleClose="handleClose" />
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth.js'
import DDialog from '@/components/d-dialog'
export default {
  components: {
    DDialog
  },
  data() {
    return {
      Id_: '', // 主键id
      active: 0,
      userInfo: {},
      // 申购单类型
      orderType: ['待审核', '审核已通过', '审核未通过', '已完成'],

      pageData: {}, // 页面数据
      goodsMoney: 0,

      // 申购信息
      tableData: [],
      logisticsList: [], // 物流信息

      // 审核
      dialogVisible_1: false, // 审核弹窗
      dialogTitle: '',
      submitForm: {
        logisticsCompany: '',
        logisticsCode: '',
        rejectReason: ''
      },
      submitRules: {
        logisticsCompany: [{ required: true, message: '请选择物流公司', trigger: 'blur' }],
        logisticsCode: [
          { required: true, message: '请输入物流单号', trigger: 'blur' },
          {
            pattern: /^[0-9a-zA-Z]+$/,
            message: '请输入字母和数字',
            trigger: 'blur'
          }
        ],
        rejectReason: [{ required: true, message: '请输入驳回原因', trigger: 'blur' }]
      },
      auditState: 1, // 1 通过， 2 驳回
      logisticsCompanyList: [], // 物流公司列表
      dataObj: {},
      routeInfo: {}
    }
  },
  created() {
    this.Id_ = this.$route.query.id
    // this.active = parseInt(this.$route.query.type) - 1;

    this.userInfo = getSessionStorage('userInfo')

    this.getPageData()
    this.getLogisticsCompany()
  },
  mounted() {},
  methods: {
    // 是否显示按钮
    showBtn(purchaseStatus) {
      if (purchaseStatus === 'deducted') {
        return true
      }
      if (purchaseStatus === 'completed') {
        return true
      } else {
        return false
      }
    },
    handleClose() {
      this.$refs.logisticsDialog.showDialog = false
      //  关闭时置空
      this.logisticsList = []
    },
    async handleLogistics(row) {
      //    发起查看物理轨迹请求
      try {
        await this.getPurchasePostInfo(row.orderCode)
        console.info('查看物流', row)
      } catch (error) {}
    },
    // 查询物流轨迹
    async getPurchasePostInfo(code) {
      try {
        const res = await this.$http.requestGet({
          url: `/customer/purchase/order/purchase/postInfo/${code}`,
          loading: false
        })
        if (!res.data || (res.code == 200 && res.data.status != 200)) {
          this.$message({
            message: '查无结果，请检查单号和快递公司是否有误',
            type: 'warning',
            duration: 3000
          })
        } else {
          this.dataObj = res.data
          this.routeInfo = res.data.routeInfo
          this.logisticsList = res.data.data
          this.$refs.logisticsDialog.showDialog = true
        }
      } catch (error) {}
    },
    // 获取物流公司列表
    getLogisticsCompany() {
      this.$http.requestPost({ url: '/system/logistics/company/list' }).then(res => {
        this.logisticsCompanyList = res.data
        // 默认选中领送送
        if (res.data.length > 0) {
          const arr = res.data.filter(item => item.companyName === '领送送')
          if (arr.length > 0) {
            this.submitForm.logisticsCompany = arr[0].companyId
          }
        }
      })
    },

    // 获取页面数据
    getPageData() {
      this.$http.requestGet({ url: '/customer/purchase/order/get/' + this.Id_ }).then(res => {
        this.pageData = res.data
        this.pageData.items.map((item1, index1) => {
          this.pageData.batches.map((item2, index2) => {
            if (item1.itemId == item2.itemId) {
              item1.batchNo = item2.batchNo || ''
              item1.expireTime = item2.expireTime || ''
              item1.produceTime = item2.produceTime || ''
              item1.productNum = item2.productNum || ''
            }
          })
        })
        // console.log('this.pageData========', this.pageData.items);
        const money = this.pageData.items.reduce((num, cur) => {
          return (num += cur.itemPrice * cur.itemNum)
        }, 0)
        this.goodsMoney = money
        // if (this.pageData.purchaseStatus === 'auditing') {
        //     this.$nextTick(() => {
        //         this.renderingEcharts()
        //     })
        // }
      })
    },

    // 渲染柱状图表
    renderingEcharts() {
      var echarts = require('echarts/lib/echarts') // 引入 ECharts 主模块
      require('echarts/lib/chart/bar') // 引入柱状图
      require('echarts/lib/component/tooltip') // 引入提示框和标题组件
      require('echarts/lib/component/title')
      // require('echarts/lib/component/trigger');
      require('echarts/lib/component/axisPointer')

      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('echars-box'))
      // 绘制图表
      myChart.setOption({
        tooltip: {},
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
        },
        xAxis: {
          data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
        },
        yAxis: {},
        series: [
          {
            name: '销量',
            type: 'bar',
            label: {
              show: true,
              position: 'top'
            },
            // itemStyle: {
            //     color:'#fd6040'
            // },
            data: [5, 20, 36, 10, 10, 20]
          }
        ]
      })
    },

    // 通过
    pass() {
      this.dialogTitle = '通过'
      this.auditState = 1
      this.dialogVisible_1 = true
    },
    // 驳回
    reject() {
      this.dialogTitle = '驳回原因'
      this.auditState = 2
      this.dialogVisible_1 = true
    },
    // 审核确定
    confirm() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          var url = '/customer/purchase/order/pass/'
          var param = null
          if (this.auditState === 2) {
            // 驳回
            url = '/customer/purchase/order/reject/'
            param = {
              rejectReason: this.submitForm.rejectReason,
              managerId: this.userInfo.managerId
            }
          } else {
            // 通过
            param = {
              logisticsCompany: this.submitForm.logisticsCompany,
              logisticsCode: this.submitForm.logisticsCode,
              managerId: this.userInfo.managerId
            }
          }
          this.$http.requestPut({ url: url + this.Id_, param }).then(res => {
            this.$message.success('操作成功')
            this.$router.go(-1)
          })
        } else {
          return false
        }
      })
    },
    // 关闭弹窗
    close() {
      this.$refs.submitForm.resetFields()
      this.dialogVisible_1 = false
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;

  & >>> .steps-box {
    padding: 30px 40px;
    margin-bottom: 10px;

    .el-step__head.is-process {
      border-color: #429fff;
      color: #429fff;
    }

    .el-step__title.is-process {
      color: #429fff;
    }

    .el-step__description.is-process {
      color: #429fff;
    }
  }

  .row_2 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    .row_left {
      width: 60%;

      & p {
        padding: 5px 0;
      }

      .color_wathet {
        cursor: pointer;
      }
    }

    .row_right {
      width: 40%;
    }

    h4 {
      padding-bottom: 10px;
    }

    .color_light-black {
      font-size: 15px;
    }
  }
  .color_light-blue {
    margin-top: 10px;
    .title {
      font-size: 16px;
      color: #4f96e6;
      width: 141px;
      display: inline-block;
      text-align: right;
      cursor: pointer;
    }
  }

  .row_3 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    & h4 {
      padding-bottom: 10px;
    }

    .show-money {
      padding: 10px 0;

      ul {
        li {
          display: flex;
          justify-content: flex-end;
          line-height: 30px;
          font-size: 16px;
        }

        .line-box {
          margin-left: 70%;
          padding-top: 20px;
          border-bottom: 1px solid #ccc;
        }

        .bold {
          font-weight: bolder;
        }
      }
    }
  }

  .row_4 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;
    font-size: 15px;

    & h4 {
      padding-bottom: 10px;
    }

    & p {
      padding-top: 5px;
    }
  }

  .row_7 {
    .flex-box {
      justify-content: center;

      button {
        padding: 12px 34px;
      }
    }
  }
}

.title-box {
  display: inline-block;
  width: 152px;
  text-align: right;
}

#echars-box {
  width: 100%;
  height: 300px;
}

/deep/.el-table tbody tr:hover > td {
  background-color: #ffffff !important;
}
.img-box {
  width: 58px;
  height: 58px;
}

.icon_box {
  width: 21px;
  position: absolute;
  left: 0;
  top: calc(100% + 2px);
  &.no_img {
    margin-right: 3px;
    position: static;
  }
}

.txt_box {
  position: relative;
  line-height: 1;
}
</style>
