<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="大区名称">
          <el-input v-model="inquire.regionName" placeholder="大区名称" />
        </el-form-item>

        <el-form-item label="增加时间">
          <div class="flex-box">
            <el-date-picker
              v-model="daterange"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增大区</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="regionName" label="大区名称" min-width="160" />
            <el-table-column prop="regionComment" label="备注" min-width="160" show-overflow-tooltip />
            <el-table-column prop="districtCount" label="片区数量" min-width="100" />
            <el-table-column prop="agencyCount" label="办事处数量" min-width="100" />
            <el-table-column prop="hospitalCount" label="医院数量" min-width="100" />
            <el-table-column prop="principal" label="负责人" min-width="100" />
            <el-table-column prop="regionOrder" label="排序" min-width="100" />
            <el-table-column prop="createTime" label="增加时间" min-width="160" />
            <el-table-column prop="" label="操作" min-width="180" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="deleteData(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加区域弹框 -->
    <el-dialog
      :title="dialogTitle + '区域'"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="650px"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="110px" :model="submitForm" :rules="submitRules">
          <el-form-item label="区域名称" prop="regionName">
            <el-input v-model="submitForm.regionName" maxlength="20" placeholder="片区名称" />
          </el-form-item>
          <el-form-item label="备注" prop="regionComment">
            <el-input v-model="submitForm.regionComment" type="textarea" maxlength="50" :rows="2" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="排序" prop="regionOrder">
            <el-input v-model="submitForm.regionOrder" maxlength="6" placeholder="排序" />
          </el-form-item>
          <el-form-item label="负责人" prop="managerIds">
            <div>
              <el-button type="primary" size="small" @click="openAttacheList">选择</el-button>
            </div>
            <div>
              <el-table :data="attacheTable" border style="width: 100%">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
                </el-table-column>
                <el-table-column prop="managerName" label="姓名" min-width="100" />
                <el-table-column prop="managerPhoneNumber" label="手机号" min-width="120" />
                <el-table-column prop="" label="操作" min-width="120" fixed="right">
                  <template slot-scope="scope">
                    <span class="operation-btn" @click="delUser(scope.row, scope.$index)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 负责列表弹框 -->
    <el-dialog
      title="选择负责人"
      :visible.sync="dialogVisible_2"
      :before-close="() => dialogVisible_2 = false"
      width="80%"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="姓名">
            <el-input v-model="inquire_2.managerName" placeholder="" />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="getUserList">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table ref="multipleTable" v-loading="loading_2" :data="tableData_2" border height="400px" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
          </el-table-column>
          <el-table-column prop="managerName" label="名称" min-width="120" />
          <el-table-column prop="managerPhoneNumber" label="手机号" min-width="160" />
        </el-table>

        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_2.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_2.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_2.total"
            @size-change="handleSizeChange_2"
            @current-change="handleCurrentChange_2"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirmMerchant">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        regionName: '',
        createTimeBegin: '',
        createTimeEnd: ''
      },
      daterange: [], // 增加时间
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      // 表格数据
      tableData: [],

      // 添加区域地址
      dialogTitle: '添加', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      submitForm: {
        regionName: '',
        regionComment: '',
        regionOrder: '',
        managerIds: ''
      },
      submitRules: {
        regionName: [{ required: true, message: '请输入区域名称', trigger: 'blur' }],
        // regionComment: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        regionOrder: [{ required: true, message: '请输入排序', trigger: 'blur' },
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ],
        managerIds: [{ required: true, message: '请选择负责人', trigger: 'blur' }]
      },
      attacheTable: [],
      isAdd: true, // 是否为添加
      editRow: {},

      selectRow: [], // 选择当前行
      // ==== 负责人弹框 ====
      dialogVisible_2: false,
      inquire_2: {
        managerName: ''
      },
      loading_2: false,
      tableData_2: [],
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }

    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      if (!this.daterange) this.daterange = []
      this.inquire.createTimeBegin = this.daterange.length > 0 ? this.daterange[0] : ''
      this.inquire.createTimeEnd = this.daterange.length > 0 ? this.daterange[1] : ''
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http.requestPost({
        url: '/operation/area/region/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.pageParam.total = res.data.total
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    // 添加片区
    addition() {
      this.dialogTitle = '添加'
      this.dialogVisible = true
      this.isAdd = true
    },
    // 修改片区
    handleEdit(row) {
      this.$http.requestGet({ url: '/operation/area/region/get/' + row.regionId, loading: false }).then(res => {
        this.submitForm = {
          regionName: res.data.regionName,
          regionComment: res.data.regionComment,
          regionOrder: res.data.regionOrder,
          managerIds: res.data.managers
        }
        this.attacheTable = res.data.managers
        this.editRow = res.data
        })
      this.dialogVisible = true
      this.dialogTitle = '编辑'
      this.isAdd = false
    },
    // 提交片区添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          const ids = this.attacheTable.map(item => item.managerId)
          const param = {
            regionName: this.submitForm.regionName,
            regionComment: this.submitForm.regionComment,
            regionOrder: this.submitForm.regionOrder,
            managerIds: ids
          }
          if (!this.isAdd) {
            param.regionId = this.editRow.regionId
          }
          this.$http.requestPost({
            url: '/operation/area/region/save',
            param: param
          }).then(res => {
            this.getTableList()
            this.$message({
              message: '操作成功',
              type: 'success'
            })
            this.closeDialog()
          })
        } else {
          return false
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields())
      this.dialogVisible = false
      this.submitForm = {
        regionId: '',
        districtName: '',
        districtComment: '',
        districtOrder: '',
        managerIds: ''
      }
      this.attacheTable = []
    },

    // 删除
    deleteData(row) {
      this.$confirm('确定删除该片区?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
          this.pageParam.pageNum--
        }
        this.$http.requestDel({ url: '/operation/area/region/del/' + row.regionId }).then(res => {
          this.getTableList()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        })
      })
    },

    // ========== 负责人 ==========
    // 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1;
      this.pageParam_2.pageSize = size
      // 刷新(数据)
      this.getUserList()
    },
    // 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page
      // 刷新(页码)
      this.getUserList()
    },

    // 打开负责人列表
    openAttacheList() {
      this.dialogVisible_2 = true
      this.getUserList()
    },

    // 查询负责人列表
    getUserList() {
      const data = {
        pageNum: this.pageParam_2.pageNum,
        pageSize: this.pageParam_2.pageSize,
        param: {
          name: this.inquire_2.managerName
        }
      }

      this.loading_2 = true
      this.$http.requestPost({
        url: '/system/manager/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading_2 = false
        this.tableData_2 = res.data.list
        this.pageParam_2.total = res.data.total
        if (this.attacheTable.length > 0) {
          this.$nextTick(() => {
            const lenArr = []
            this.attacheTable.forEach(e => {
              this.tableData_2.forEach((v, i) => {
                if (e.managerId === v.managerId) {
                  lenArr.push(i)
                }
              })
            })

            lenArr.forEach(v => {
              this.$refs.multipleTable.toggleRowSelection(this.tableData_2[v])
            })
          })
        }
      }).catch(err => {
        this.loading_2 = false
        console.log(err)
      })
    },

    // 确定负责人的选择
    confirmMerchant() {
      this.submitForm.managerIds = '1'
      this.attacheTable = this.selectRow
      this.dialogVisible_2 = false
    },

    // 表格多选
    handleSelectionChange(news) {
      this.selectRow = news
    },

    // 删除当前选择负责人
    delUser(row, index) {
      this.attacheTable.splice(index, 1)
      this.selectRow.splice(index, 1)
    }

  }
}
</script>

<style lang="scss" scoped>
  .address-hierarchy {
    flex-wrap: wrap;
  }
</style>
