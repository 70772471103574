var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-box" }, [
    _c(
      "div",
      { staticClass: "head-search-row" },
      [
        _c(
          "el-form",
          {
            ref: "submitForm",
            attrs: {
              "label-width": "40px",
              model: _vm.submitForm,
              rules: _vm.submitRules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "", prop: "merchantProductInventoryWarn" } },
              [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("div", { staticClass: "box-p-r" }, [
                      _vm._v("商家产品库存警戒线默认值"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.submitForm.merchantProductInventoryWarn,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.submitForm,
                            "merchantProductInventoryWarn",
                            _vm._n($$v)
                          )
                        },
                        expression: "submitForm.merchantProductInventoryWarn",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                attrs: { label: "", prop: "headquartersProductInventoryWarn" },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("div", { staticClass: "box-p-r" }, [
                      _vm._v("总部产品库存警戒线默认值"),
                    ]),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-110",
                      attrs: { placeholder: "0" },
                      model: {
                        value: _vm.submitForm.headquartersProductInventoryWarn,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.submitForm,
                            "headquartersProductInventoryWarn",
                            _vm._n($$v)
                          )
                        },
                        expression:
                          "submitForm.headquartersProductInventoryWarn",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                  [_vm._v("保存")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }