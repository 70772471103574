<template>
  <div class="main-box">
    <div class="table">
      <el-main>
        <template>
          <el-table
              :data="tableData"
              v-loading="loading"
              border
              height="400px"
              style="width: 100%"
          >
            <el-table-column
                label="序号"
                width="60"
                align="center"
                type="index"
            />
            <el-table-column
                label="问卷模板名称"
                align="center"
                prop="title"
            />
            <el-table-column
                label="问卷模板编号"
                align="center"
                prop="questionnaireCode"
            />
            <el-table-column
                label="填写时间"
                align="center"
                prop="fillInTime"
            />
            <el-table-column fixed="right" label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text"  @click="detail(scope.row)"
                >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
              background
              :current-page="queryParams.pageNum"
              :page-sizes="$api.pageSizes"
              :page-size="queryParams.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 营养记录模板名称 -->
    <el-dialog
        :title="currentTitle"
        :visible.sync="showProDialog"
        width="70%"
        top="5vh" append-to-body
        class="productList"
    >
      <div class="content">
        <el-table ref="proTable" :data="questionnaireData" height="350" border style="margin-top: 10px"
                  :header-cell-style="{background:'#409eff',color:'#ffffff'}">
          <el-table-column type="index" label="序号" align="center" width="55" />
          <el-table-column label="问题" align="center" prop="content" />
          <el-table-column label="内容" align="center" prop="answer" />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button @click="showProDialog = false">取 消</el-button>
         </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dietitianName: "",
        dietitianCode: "",
        dietitianIdentity: "",
      },
      loading: false,
      showProDialog:false,
      tableData: [], //营养师记录列表详情数据
      dieRecordDetList:[],
      currentTitle:"",
      questionnaireData:[],//营养记录模板名称
      total: 0,
      questionnaireId: null,
      memberId: null
    };
  },
  created() {
    this.dieRecordDetList = this.$route.params.row;
    console.log(this.dieRecordDetList,'canshu1')
    this.questionnaireId = this.dieRecordDetList.questionnaireId;
    this.memberId = !!this.dieRecordDetList.memberId ? this.dieRecordDetList.memberId : this.dieRecordDetList.userId;
    this.dieRecordDet()
  },
  methods: {
    dieRecordDet(){
      this.$http
          .requestPost({
            url: "/consult/queryUserRecordQuestionnaireList/",
            param : {
              "questionnaireId": this.questionnaireId,
              "memberId": this.memberId,
              "pageNum": this.queryParams.pageNum,
              "pageSize": this.queryParams.pageSize
            }
          })
          .then((res) => {
            if(res.code == 200){
              this.tableData = res.data.list
              this.total = res.data.total
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    async detail(row) {
      this.currentTitle = row.title;
      await this.$http
          .requestGet({
            url: "/consult/getUserQuestionnaireDetailAdminMore/" + row.userQuestionnaireId,
            loading: false,
          })
          .then((res) => {
            console.log(res, 'res')
            if (res.code == 200) {
              // type为1或者2content是答案answer是问题 type为3的时候content是问题而answer是答案
              res.data.forEach(item => {
                if (item.type == 1 || item.type == 2) {
                  item.content = [item.answer, item.answer = item.content][0]
                }
              })
              this.questionnaireData = res.data;
            }
          })
          .catch((err) => {
            console.error('获取营养记录详情出错' + err.msg)
          });
      this.showProDialog = true;
    },
    // 营养师记录列表页码改变
    handleSizeChange(size) {
      this.queryParams.pageNum = 1;
      this.queryParams.pageSize = size;
    },
    handleCurrentChange(page) {
      this.queryParams.pageNum = page;
    },
  },
};
</script>

<style>
</style>