<!-- 优惠券新增 -->
<template>
  <div id="main-scroll" class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>

    <div class="nav-bar bg_white">
      <a v-for="(item, index) in navList" :key="item.id" :class="{ 'active': item.active }" href="javascript:void(0)"
        @click="skip(index)">{{ item.name }}</a>
    </div>

    <!-- 基本信息 -->
    <div id="row_1" class="row_box bg_white">
      <div class="header">
        <span>基本信息</span>
      </div>
      <div class="content">
        <el-form ref="basicRef" :model="basicInfo" :rules="basicInfoRules" label-width="100px" size="medium">
          <el-form-item label="名称" prop="couponName">
            <el-input v-model="basicInfo.couponName" :disabled="editDisable" placeholder="请输入名称" maxlength="10"
              class="inputW" show-word-limit clearable />
          </el-form-item>
          <el-form-item label="使用时间" prop="useDate">
            <div>
              <el-date-picker v-model="basicInfo.useDate" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss" @change="useDateChange" :disabled="editDisable" range-separator=" - "
                :picker-options="pickerOptions" start-placeholder="开始时间" end-placeholder="结束时间" :clearable="false"
                @focus="handleFocus(0)">
              </el-date-picker>
              <!-- <div class="textColor textColorRules">最长只能选择180天</div> -->
            </div>
          </el-form-item>
          <div class="consumeP">
            <el-checkbox v-model="consumePersonCheck" @change="consumePersonCheckChange" :disabled="editDisable"
              class="dCheck"></el-checkbox>
            <div>
              <el-form-item label="定向人群" style="height: 18px;">
                <el-select v-model="basicInfo.consumePerson" @change="consumePersonChange"
                  :disabled="editDisable || !consumePersonCheck" placeholder="请选择定向人群" class="form-select inputW">
                  <el-option v-for="item in $api.consumePerson" :key="item.value" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <div class="textColor textW">选择定向人群后优惠券会向指定消费者透出，非定向人群无法使用此优惠券；若不选这一项，则优惠券会向平台所有用户透出；</div>
            </div>
            <el-checkbox-group v-show="isShowVIPType" :disabled="editDisable" class="VIPCheck" v-model="VipType">
              <el-checkbox :label="3">银星会员</el-checkbox>
              <el-checkbox :label="4">玉星会员</el-checkbox>
              <el-checkbox :label="5">金星会员</el-checkbox>
            </el-checkbox-group>
          </div>

          <div class="consumeP">
            <div>
              <el-form-item label="禁用分组" style="height: 18px;">
                <el-select @change="disableGroupListChange" :disabled="editDisable" placeholder="请选择" class="form-select inputW">
                  <el-option v-for="item in groupOptions" :key="item.id" :label="item.categoryName" :value="item.id" />
                </el-select>
              </el-form-item>
              <div class="group-box" v-if="basicInfo.disableGroupList.length > 0">
                <el-tag class="group-tag" v-for="(tag, ind) in basicInfo.disableGroupList" :key="tag.categoryName" :type="tag.id" 
                :closable="disableClosable" @close="removeDisable(ind)">
                  {{tag.categoryName}}
                </el-tag>
              </div>
              <div class="textColor textW">选择禁用分组后，优惠券面向人群中属于该分组的用户不能查看此优惠券</div>
            </div>
          </div>
          <div class="consumeP">
            <div>
              <el-form-item label="定向分组" style="height: 18px;">
                <el-select @change="orientedGroupListChange" :disabled="editDisable" placeholder="请选择" class="form-select inputW">
                  <el-option v-for="item in groupOptions" :key="item.id" :label="item.categoryName" :value="item.id" />
                </el-select>
              </el-form-item>
              <div class="group-box" v-if="basicInfo.orientedGroupList.length > 0">
                <el-tag class="group-tag" v-for="tag in basicInfo.orientedGroupList" :key="tag.categoryName" :type="tag.id" 
                :closable="orientedClosable" @close="removeConsume(ind)">
                  {{tag.categoryName}}
                </el-tag>
              </div>
              <div class="textColor textW">选择定向分组后优惠券会向优惠券面向人群中指定分组人员透出，非指定分组人员无法查看此优惠券；若不选这一项，则优惠券会向优惠券面向人群透出；</div>
            </div>
          </div>

          <el-form-item label="透出时间" prop="tcDate">
            <div>
              <el-date-picker v-model="basicInfo.tcDate" type="datetime" value-format="yyyy-MM-dd HH:mm"
                :disabled="editDisable || !(basicInfo.useDate && basicInfo.useDate[0].length > 0)"
                format="yyyy-MM-dd HH:mm" :picker-options="tcPickerOptions" placeholder="请选择时间" class="inputW"
                @focus="handleFocus(1)">
              </el-date-picker>
              <div class="textColor textColorRules">不得晚于优惠券开始时间；不能早于优惠开始前60天</div>
            </div>
          </el-form-item>

          <div class="ditchBox">
            <el-checkbox v-model="useChannelCheck" @change="bool => !bool && (basicInfo.useChannel = '')"
              :disabled="editDisable">
              <strong>使用渠道</strong>
            </el-checkbox>
            <el-radio-group v-model="basicInfo.useChannel" :disabled="editDisable || !useChannelCheck">
              <el-radio :label="1">小程序</el-radio>
              <el-radio :label="2">APP</el-radio>
            </el-radio-group>
            <div class="textColor ditchText">若勾选此项，则优惠券会在小程序或 APP当中一条渠道使用；若不勾选此项，则默认从小程序和 APP皆可使用，同时计入用户使用次数。</div>
          </div>
          <el-form-item label="优惠门槛">
            <el-radio-group v-model="basicInfo.yhMK" :disabled="editDisable" @change="changeThresholdWay">
              <el-radio :label="1">金额</el-radio>
              <el-radio :label="2">数量</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="优惠方式">
            <el-radio-group v-model="basicInfo.yhWay"
              @change="() => this.wayText = this.basicInfo.yhWay === 1 ? '优惠金额' : '优惠折扣'" :disabled="editDisable">
              <el-radio :label="1">扣减</el-radio>
              <el-radio :label="2">折扣</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 优惠信息 -->
    <div id="row_2" class="row_box bg_white">
      <div class="header">
        <span>优惠信息</span>
      </div>
      <div class="content">
        <el-form ref="discountsRef" :model="discountsInfo" :rules="discountsInfoRules" label-width="100px" size="medium">
          <el-form-item :label="wayText" prop="preferenceAmount">
            <el-input
              v-model="discountsInfo.preferenceAmount" clearable placeholder="请输入"
              :disabled="editDisable" class="inputW"
            />
            <span>&nbsp;{{ basicInfo.yhWay === 1 ? '元' : '折' }}</span>
          </el-form-item>
          <el-form-item label="使用门槛" required>
            <el-radio-group
              class="condition" v-model="useThresholdRadio" :disabled="editDisable"
              @change="useThresholdChange"
            >
              <el-radio :label="1">
                满&nbsp;
                <el-form-item ref="threshold" prop="threshold" style="display: inline-block; padding-bottom: 3px;">
                  <el-input
                    class="inputW"
                    v-model="discountsInfo.useThreshold"
                    :disabled="useThresholdFlag"
                    clearable
                    placeholder="请输入"
                  />
                </el-form-item>
                <span>&nbsp;{{ basicInfo.yhMK === 1 ? '元' : '件' }}</span>
              </el-radio>
              <el-radio :label="2" class="cRadio2">{{ basicInfo.yhMK === 1 ? '满 0.01元（无门槛）' : '满1件（无门槛）' }}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item :label="getRoute === 'PlatformCoupon' ? '发行量' : '二维码额度'" prop="circulation">
            <el-input-number v-model="discountsInfo.circulation" :min='1' :max='10000' controls-position="right"
              :disabled="editDisable" placeholder="需大于1，不超过10000张" class="inputW" /><span>&nbsp; 张</span>
          </el-form-item>

          <el-form-item label="每人限领" prop="collarLimit">
            <el-input-number v-model="discountsInfo.collarLimit" :min='1' :max='20' controls-position="right"
              :disabled="editDisable" placeholder="请输入" class="inputW" /><span>&nbsp; 张</span><span
              style="color:#c0c4cc; padding-left: 15px;">1-20张</span>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 产品管理 -->
    <div id="row_3" class="row_box bg_white">
      <div class="header">
        <span>产品管理</span>
      </div>
      <div class="content">
        <div>商品范围：<el-button @click="selectProduct" type="primary">选择商品</el-button></div>
        <el-main>
          <template>
            <el-table :data="productDataSource" v-loading="productLoading" border height="400px" style="width: 100%">
              <el-table-column prop="date" label="序号" min-width="60">
                <template v-slot="scope">
                  {{ (productPageParam.pageNum - 1) * productPageParam.pageSize + (scope.$index + 1) }}
                </template>
              </el-table-column>
              <el-table-column prop="productCode" label="产品编号" min-width="180" />
              <el-table-column prop="productName" label="产品名称" min-width="100" />
              <el-table-column prop="productCategoryName" label="所属类别" min-width="100" />
              <el-table-column prop="price" label="销售价" min-width="100">
                <template slot-scope="scope">
                  ￥{{ scope.row.productPriceMin }} - ￥{{ scope.row.productPriceMax }}
                </template>
              </el-table-column>
              <el-table-column prop="onSaleHospitalNumber" label="在售医院数" min-width="100" />
              <el-table-column prop="productStatus" label="状态" min-width="100">
                <template slot-scope="scope">
                  {{ findEnumName($api.productStatus, scope.row.productStatus) }}
                </template>
              </el-table-column>
              <el-table-column prop="" label="操作" width="150" fixed="right">
                <template slot-scope="scope">
                  <span class="operation-btn" @click="delProduct(scope.row)">删除</span>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-main>
        <div class="pagination">
          <el-pagination @size-change="productHandleSizeChange" @current-change="productHandleCurrentChange" background
            :current-page="productPageParam.pageNum" :page-sizes="$api.pageSizes" :page-size="productPageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="productPageParam.total"></el-pagination>
        </div>
      </div>
    </div>

    <!-- 使用说明 -->
    <div id="row_4" class="row_box bg_white">
      <div class="header">
        <span>使用说明</span>
      </div>
      <div class="content">
        <div class="editor">
          <mavon-editor ref="md" v-model="editor" :toolbars="toolbars" @change="editorChange" @imgAdd="handleEditorImgAdd" @imgDel="handleEditorImgDel" />
        </div>
      </div>
    </div>

    <!-- 底部事件(查看事件不展示) -->
    <div v-if="!($route.query.detailFlag)" class="submit-btn">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="$router.go(-1)">取 消</el-button>
    </div>

    <!-- 产品管理（选择商品）弹窗列表 -->
    <el-dialog title="选择商品" :visible.sync="visible" :before-close="cancelChange" width="70%" top="5vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="搜索产品">
            <el-input v-model="inquire.productName" placeholder="请输入产品名称"></el-input>
          </el-form-item>
          <el-form-item label="产品类别">
            <el-cascader v-model="inquire.productType" :props="productProps" :options="productTypeData"
              placeholder="请输入产品名称"></el-cascader>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" size="small" @click="getProductList">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="dataSource2" @selection-change="handleSelectionChange"
          :row-key="(row) => row.productId" tooltip-effect="dark" v-loading="loading2" border height="400px"
          style="width: 100%" highlight-current-row>
          <el-table-column type="selection" width="55"
            :reserve-selection="true" :selectable="(row) => row.isThreeFavorable ? false : true" />
          <el-table-column prop="productCode" label="产品编号" min-width="120" />
          <el-table-column prop="productName" label="产品名称" min-width="100">
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row.productName }}</span>
                <span style="color: red; padding-left: 8px;" v-if="scope.row.isThreeFavorable">已有6个优惠</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="productCategoryName" label="产品类别" min-width="100" />
          <el-table-column prop="" label="产品单价" min-width="80">
            <template slot-scope="scope">
              ￥{{ scope.row.productPriceMin }} - ￥{{ scope.row.productPriceMax }}
            </template>
          </el-table-column>
          <el-table-column prop="onSaleHospitalNumber" label="在售医院数" min-width="80" />
          <el-table-column prop="productInventoryTotal" label="产品库存" min-width="80" />
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" background
            :current-page="pageParam2.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam2.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="pageParam2.total"></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelChange">取 消</el-button>
        <el-button :loading="btnLoading" type="primary" @click="confirmProductList">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>

export default {
  name: "operationAddCoupon",
  data() {
    return {
      dataSource2Value: [],
      couponDetailId: this.$route.query.id,  //获取优惠券详情id
      getRoute: this.$route.query.route,  //获取当前页面路由
      couponDetailInfo: '',  //编辑获取优惠券详情数据
      mainEl: null,
      timer: null,
      btnLoading: false,
      editDisable: false,
      navList: [  //导航栏
        { name: '基本信息', id: 'row_1', active: true },
        { name: '优惠信息', id: 'row_2', active: false },
        { name: '产品管理', id: 'row_3', active: false },
        { name: '使用说明', id: 'row_4', active: false },
      ],

      //************************优惠信息****************
      basicInfo: {
        couponName: '',   //名称
        useDate: ['', ''],     //使用时间
        consumePerson: '',       //定向人群
        disableGroupList: [],  //禁用分组
        orientedGroupList: [],  //定向分组
        tcDate: '',   //透出时间
        useChannel: '',   //使用渠道
        yhMK: 1, //优惠门槛
        yhWay: 1 //优惠方法
      },
      groupOptions: [],  //分组option
      copyGroupOptions: [],  //拷贝一份分组option数据
      disableClosable: true,  //tags是否支持移除操作
      orientedClosable: true,  //tags是否支持移除操作
      isShowVIPType: false,
      //基本信息校验
      basicInfoRules: {
        couponName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        tcDate: [{ required: true, message: '请选择透出时间', trigger: 'blur' }],
        useDate: [{ required: true, message: '请选择使用时间', trigger: 'blur' }],
        useDate: [{
          type: 'array', // 表示字段值应为数组类型
          required: true, // 整个数组必须存在
          validator: (rule, value, callback) => { // 自定义验证函数
            if (!value || !value.length || value.every(item => item === '')) {
              callback(new Error('请选择使用时间'));
            } else {
              callback(); // 如果通过校验，则调用callback无参表示校验通过
            }
          },
          trigger: 'change' // 触发校验的时机，这里选择在值变化时进行校验
        }],
      },
      consumePersonCheck: false,  //是否定向人群
      useChannelCheck: false,  //是否使用渠道
      VipType: [],  //会员类型
      pickerOptions: {
        disabledDate(time) {
          // 需求一：可选时间为当前时间至当前时间后180天
          // let days = 180;
          // const date = new Date();
          // date.setTime(date.getTime() + days * 24 * 3600 * 1000);
          // return time.getTime() > date.getTime() || time.getTime() < Date.now() - 8.64e7; // 8.64e7毫秒为一天

          //需求二：放开180天限制，当天往后都能选
          return time.getTime() < Date.now() - 8.64e7;
        }
      },

      //**************************优惠信息****************
      discountsInfo: {
        preferenceAmount: '',  //优惠金额
        useThreshold: '', //使用门槛
        circulation: undefined, //发行数量
        collarLimit: undefined, //每人限领
      },
      useThresholdRadio: 2,  //使用门槛radio
      wayText: "优惠金额",   //动态label
      useThresholdFlag: true,  //使用门槛input在没选择时禁用
      //优惠信息校验
      discountsInfoRules: {
        preferenceAmount: [{
          validator: (rule, value, callback) => {
            let reg = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/
            if(!reg.test(value)) {
              return callback(new Error('请输入一个大于0，且最多保留2位小数的数字'))
            }
            callback()
          }, trigger: ['blur', 'change'], required: true
        }],
        threshold: [{
          validator: (rule, value, callback) => {
            if(this.useThresholdRadio == 1) {
              const reg1 = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/
              const reg2 = /^\+?[1-9][0-9]*$/
              if(this.basicInfo.yhMK === 1) { //金额
                if(!reg1.test(this.discountsInfo.useThreshold)) {
                  return callback(new Error('请输入一个大于0，且最多保留2位小数的数字'))
                }
              } else { //数量
                if(!reg2.test(this.discountsInfo.useThreshold)) {
                  return callback(new Error('请输入一个正整数'))
                }
              }
            }
            callback()
          }, trigger: ['blur', 'change'], required: true
        }],
        circulation: [{ required: true, message: '请输入', trigger: 'blur' }],
        collarLimit: [{ required: true, message: '请输入', trigger: 'blur' }]
      },

      //**************************产品管理数据****************
      productDataSource: [],
      productLoading: false,
      productPageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      dataCheck: [],  //选择未关联列表数据
      //选择产品弹窗
      visible: false,
      productTypeData: [],
      inquire: {  //未关联的产品列表筛选参数
        productName: '',
        productType: '',
      },
      dataSource2: [],
      loading2: false,
      pageParam2: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      productProps: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: false
      },

      //**************************使用说明****************
      //使用说明配置
      editor: '',
      editorHtml: '', // 富文本html语法
      toolbars: { // 编辑器配置
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true // 预览
      },

      selectedRowKeys: [], // 用于临时存储当前页需要选中的行key
    };
  },
  created() {
    this.getGroupList();
    // 获取优惠券详情
    if (this.couponDetailId) {
      setTimeout(()=>{
        this.disableClosable = false; //编辑时不能移除
        this.orientedClosable = false; //编辑时不能移除
        this.getEditInfo();
      },200)
    }
  },
  mounted() {
    //在app-main元素内滚动时触发监听滚动事件
    this.mainEl = document.getElementsByClassName('app-main')[0]
    this.mainEl.addEventListener('scroll', this.scrollChange)
    this.skip(0)
  },
  watch: {
    'visible': {
      handler(val) {
        if (val) {
          // console.log('执行');
          this.getProductList(true) //代表是第一次打开页面

          //一并获取产品分类列表
          this.$http
            .requestGet({ url: "/product/category", loading: false })
            .then((res) => {
              if (res.code === 200) {
                this.productTypeData = res.data
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }
  },

  computed: {

    tcPickerOptions() {
      const minDate = new Date(this.basicInfo.useDate[0]);
      const tcDate = new Date(minDate).getTime();

      let three = 60 * 24 * 3600 * 1000;
      let threeMonths = tcDate - three;

      return {
        disabledDate(time) {
          return time.getTime() > tcDate || time.getTime() < threeMonths;
        }
      };
    }
  },

  methods: {
    findEnumName(enumArray, targetValue) {
      const enumFound = enumArray.find(item => item.value === targetValue);
      return enumFound ? enumFound.name : '';
    },
    //提交创建优惠券
    submit() {
      const { useDate: [useBeginTime, useEndTime], consumePerson, tcDate, yhMK, yhWay, disableGroupList, orientedGroupList } = this.basicInfo;
      const orientedPopulation = consumePerson === '3' ? this.VipType.join(',') : consumePerson;
      //基本信息参数
      const basicParam = {
        ...this.basicInfo,
        useBeginTime,  //使用开始时间
        useEndTime,    //使用结束时间
        orientedPopulation, //定向人群
        penetrateTime: tcDate, //透出时间
        preferenceMode: yhWay, //优惠方式
        preferenceThreshold: yhMK, //优惠门槛
        disableGroupList: disableGroupList.map(disable => disable.id) || [],   //禁用分组
        orientedGroupList: orientedGroupList.map(oriented => oriented.id) || [],  //定向分组
      };
      //优惠信息参数
      const discounts = {
        ...this.discountsInfo,
        useThreshold: (
          this.useThresholdRadio !== 2
            ? this.discountsInfo.useThreshold
            : ({ 1: '0.01', 2: '1' }[this.basicInfo.yhMK] || this.discountsInfo.useThreshold)
        )
      };
      //关联产品管理
      const productData = this.productDataSource.map(item => {
        return {
          productId: item.productId,
          productName: item.productName,
          productCode: item.productCode
        }
      })

      const params = {
        useInstructions: this.editorHtml,
        productBasicsList: productData,
        couponType: this.getRoute === 'PlatformCoupon' ? 1 : 2,
      }
      const newParams = {
        ...basicParam,
        ...discounts,
        ...params,
        couponId: '',
      }
      const editParams = {
        ...params,
        couponId: this.couponDetailId,
      }

      if (productData.length < 1) {
        return this.$message({
          type: "warning",
          message: "请先选择商品，再提交！",
        });
      }

      this.$refs.basicRef.validate((basicValid) => {
        if (basicValid) {
          this.$refs.discountsRef.validate((discountsValid) => {
            if (discountsValid) {
              this.btnLoading = true
              this.$http.requestPut({
                url: '/marketing/addOrUpdateCoupon',
                param: this.couponDetailId ? editParams : newParams
              }).then(res => {
                if (res.code === 200) {
                  this.btnLoading = false
                  this.$router.go(-1)  //成功刷新
                  this.$message.success('保存成功')
                }
              })
            }
          })
        }
      })

    },

    //新增获取详情数据
    async getEditInfo() {
      await this.$http.requestGet({
        url: "/marketing/couponDetail/" + this.couponDetailId,
        loading: false
      })
        .then((res) => {
          if (res.code === 200) {
            this.editDisable = true  //编辑 - 标识为编辑时优惠信息和基本信息禁用
            this.couponDetailInfo = res.data
            this.queryProductList()  //请求成功调用产品管理列表数据
            //基本信息
            this.basicInfo.couponName = res.data.couponName
            //使用时间
            this.basicInfo.useDate = [res.data.useBeginTime, res.data.useEndTime]
            //禁用分组
            const strOne = res.data.disableGroup;
            if(strOne){  //多做一层处理避免报错
              const disableStrArr = strOne.split(',');
              const disableGroup = this.copyGroupOptions.filter(item => disableStrArr.includes(item.id));
              this.basicInfo.disableGroupList = disableGroup;
            }
            //定向分组
            const steTwo = res.data.orientedGroup;
            if(steTwo){
              const orientedStrArr = steTwo.split(',');
              const orientedGroup = this.copyGroupOptions.filter(item => orientedStrArr.includes(item.id));
              this.basicInfo.orientedGroupList = orientedGroup;
            }

            let isVip = res.data.orientedPopulation
            //定向人群有值必须赋值为true，选中状态
            if (isVip && isVip.length > 0) {
              this.consumePersonCheck = true
            }
            this.basicInfo.consumePerson = isVip.length > 1 
              ? '3' : isVip == '0' ? '所有用户' : isVip == '4' || isVip == '5' ? '会员' : isVip
            //定向人群下拉列表的选择
            if ((isVip == '3' || isVip == '4' || isVip == '5') || (isVip && isVip.length > 1)) {
              this.isShowVIPType = true
              this.VipType = isVip.split(',').map(Number)
            }
            this.basicInfo.tcDate = res.data.penetrateTime
            //使用渠道有值必须选中
            if (res.data.useChannel) {
              this.useChannelCheck = true
            }
            this.basicInfo.useChannel = res.data.useChannel
            this.basicInfo.yhMK = res.data.preferenceThreshold
            this.basicInfo.yhWay = res.data.preferenceMode
            //优惠信息
            this.discountsInfo.preferenceAmount = res.data.preferenceAmount
            //同时满足情况
            let isSpecialConditionMet = (res.data.preferenceThreshold === 1 && res.data.useThreshold === '0,01') ||
                            (res.data.preferenceThreshold === 2 && res.data.useThreshold === '1');
            this.useThresholdRadio = isSpecialConditionMet ? 2 : 1;
            this.discountsInfo.useThreshold = isSpecialConditionMet ? '' : res.data.useThreshold;
            this.discountsInfo.circulation = res.data.circulation
            this.discountsInfo.collarLimit = res.data.collarLimit
            //产品管理

            //使用说明
            this.editor = this.$public.parseHTML_ForMarkdown(res.data.useInstructions)
            this.editorHtml = res.data.useInstructions
          }
        })
        .catch((err) => {
          this.editDisable = true
          console.log(err);
        });
    },

    //*****************基本信息*********************
    //使用时间选择事件 -- 清空透出时间
    useDateChange(val) {
      if (!(val && val.length > 0)) {
        this.basicInfo.tcDate = ''
      }
      //使用时间再次选择时如何小于透出时间则清空透出时间
      const d1 = new Date(val[0])
      const d2 = new Date(this.basicInfo.tcDate)
      if (d1 < d2) {
        this.basicInfo.tcDate = ''
      }
    },
    //定向人群check
    consumePersonCheckChange(bool) {
      this.basicInfo.consumePerson = bool ? this.$api.consumePerson[0].value : '';
      if (!bool) {
        this.isShowVIPType = false;
      }
    },
    //定向人群选择
    consumePersonChange(val) {
      this.basicInfo.consumePerson = val
      this.isShowVIPType = val === '3';
      if (val !== '3') {
        this.VipType = [];
      }
    },
    // 改变优惠门槛
    changeThresholdWay(val) {
      this.discountsInfo.useThreshold = ''
      this.$refs.threshold.clearValidate()
    },
    //获取分组数据
    async getGroupList(){
      try {
        const pageObj = {pageNum: 1, pageSize:100, categoryName: "" };
        //编辑时加传isDetail字段: 编辑时需展示删除的分组数据
        const params = this.couponDetailId ? {...pageObj, isDetail: 1} : pageObj; 
        const res = await this.$http.requestPost({ 
          url: '/customer/talent/queryTalentCategoryList', 
          param: params 
        });
        if(res.code === 200){
          const list = res.data.list;
          this.groupOptions = list || [];
          this.copyGroupOptions = JSON.parse(JSON.stringify(this.groupOptions));
        };
      } catch (error) {
        this.$message.error('获取分组数据失败！');
      }
    },
    //禁用分组事件
    disableGroupListChange(val){
      //选择：将匹配到数据添加给tags
      const find = this.groupOptions.find(fd => fd.id === val);
      if(find){
        this.basicInfo.disableGroupList.push(find);
        //push完后将对应options的数据清掉
        this.groupOptions = this.groupOptions.filter(fl => fl.id !== val);
      }
    },
    //移除禁用分组
    removeDisable(ind){
      this.basicInfo.disableGroupList.splice(ind, 1);
      //在删除时：将删除的数据再次交回给options
      //this.basicInfo.disableGroupList当前剩余的tags数据，this.copyGroupOptions拷贝源数据
      let arr;
      arr = this.copyGroupOptions.filter(fl => this.basicInfo.disableGroupList.every(cfl => cfl.id !== fl.id));
      //过滤禁用分组数据后，还需检测定向分组是否有数据，有数据需再次过滤定向分组数据
      if(this.basicInfo.orientedGroupList.length > 0){
        arr = arr.filter(item => this.basicInfo.orientedGroupList.every(e => item.id !== e.id));
      }
      this.groupOptions = arr;
    },

    //定向分组事件
    orientedGroupListChange(val){
      const find = this.groupOptions.find(fd => fd.id === val);
      if(find){
        this.basicInfo.orientedGroupList.push(find);
        this.groupOptions = this.groupOptions.filter(fl => fl.id !== val);
      }
    },
    //移除定向分组
    removeConsume(ind){
      this.basicInfo.orientedGroupList.splice(ind, 1);
      let arr;
      arr = this.copyGroupOptions.filter(fl => this.basicInfo.orientedGroupList.every(cfl => cfl.id !== fl.id));
      //过滤定向分组数据后，还需检测禁用分组是否有数据，有数据需再次过滤禁用分组数据
      if(this.basicInfo.disableGroupList.length > 0){
        arr = arr.filter(item => this.basicInfo.disableGroupList.every(e => item.id !== e.id));
      }
      this.groupOptions = arr;
    },

    //****************优惠信息****************
    //使用门槛
    useThresholdChange(val) {
      if (val === 1) {
        this.useThresholdRadio = 1
        this.useThresholdFlag = false

      } else {
        this.useThresholdRadio = 2
        this.useThresholdFlag = true
        this.discountsInfo.useThreshold = ''

        // 清空门槛输入验证规则
        this.$refs.threshold.clearValidate()
      }
    },

    //****************产品管理****************
    //选择商品事件
    async selectProduct() {
      this.getProductList()

      await this.$nextTick()

      this.$nextTick(() => {
        this.$refs.multipleTable && this.$refs.multipleTable.clearSelection()
        // console.log(this.selectedRowKeys);
        if (this.selectedRowKeys.length > 0) {
          const selectedRows = this.dataSource2.filter(row => this.selectedRowKeys.includes(row.productId));
          selectedRows.forEach(row => {
            this.$refs.multipleTable && this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
      })

      this.$nextTick(() => {
        this.visible = true
      })
    },
    //获取产品列表 -- 新增时不调用该列表，编辑必须调用
    async queryProductList() {
      //只有编辑才走以下逻辑
      if (this.couponDetailId) {
        const { couponId, productName, productCategoryId } = this.couponDetailInfo
        const data = {
          pageNum: this.productPageParam.pageNum,
          pageSize: this.productPageParam.pageSize,
          param: {
            couponId,
            // productName,
            // productCategoryId
          }
        }
        this.productLoading = true;
        await this.$http.requestPost({
          url: "marketing/queryProductListByCouponId",
          loading: false,
          param: data
        })
          .then((res) => {
            if (res.code === 200) {
              this.productDataSource = res.data.list
              this.productPageParam.total = res.data.total;
              this.productLoading = false;
            }
          })
          .catch((err) => {
            this.productLoading = false;
            console.log(err);
          });
      }
    },
    //产品删除事件
    // 如果新增和编辑逻辑分开 -- 删除也一样需要分开处理
    delProduct(row) {
      const { couponProductRelId, productName, productId } = row

      this.$confirm(`是否确认删除产品名称：${productName}`, "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          if (this.couponDetailId) {
            this.$http.requestDel({
              url: "/marketing/removeCouponProductByRelId/" + couponProductRelId,
              loading: false
            })
              .then((res) => {
                if (res.code === 200) {
                  this.queryProductList();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                }
              });
          } else {

            this.selectedRowKeys = this.selectedRowKeys.filter(item => item !== productId)
            // this.productDataSource = this.productDataSource.filter(item => item.productId !== id)
            this.dataSource2Value = this.dataSource2Value.filter(item => item.productId !== productId)
            this.dataCheck = this.dataCheck.filter(item => item.productId !== productId)
            this.productHandleCurrentChange(1)
            this.productPageParam.total = this.dataCheck.length  //总数
          }

        })
        .catch((err) => { });
    },
    //分页
    productHandleSizeChange(size) {
      this.productPageParam.pageNum = 1;
      this.productPageParam.pageSize = size;
      if (this.couponDetailId) {
        this.queryProductList();
      }
    },
    productHandleCurrentChange(page) {
      this.productPageParam.pageNum = page;
      if (this.couponDetailId) {
        this.queryProductList();
      } else {
        this.productDataSource = this.dataCheck.slice(
          page * this.productPageParam.pageSize - this.productPageParam.pageSize,
          page * this.productPageParam.pageSize
        )
      }
    },

    //弹窗列表
    //获取弹窗内的列表数据
    async getProductList(flag) {
      const data = {
        pageNum: this.pageParam2.pageNum,
        pageSize: this.pageParam2.pageSize,
        param: {
          couponId: this.couponDetailId || '',
          productName: this.inquire.productName,
          productCategoryId: this.inquire.productType,
          couponType: this.getRoute === 'PlatformCoupon' ? 1 : 2,
        }
      }
      this.loading2 = true
      await this.$http.requestPost({
        url: "/marketing/queryNoBoundProductListByCouponId",
        loading: false,
        param: data,
      })
        .then((res) => {
          if (res.code === 200) {
            this.dataSource2 = res.data.list;
            this.pageParam2.total = res.data.total;

            //再次打开选择弹窗时，将原来存储的选中数据还原并赋值
            this.$nextTick(() => {
              this.dataSource2Value.forEach(item => {
                this.$refs.multipleTable.toggleRowSelection(item)
              })
            })

            this.loading2 = false
          }
        })
        .catch((err) => {
          this.loading2 = false
          console.log(err);
        });
    },
    //弹窗 -- 确认选择的列表数据
    async confirmProductList() {
      //只有新增才这么处理 -- 编辑时调用编辑接口将未关联数据赋值到关联数据中
      if (this.dataCheck.length < 1) {
        this.$message({
          type: "warning",
          message: "此次确认您未添加商品",
        })
      }
      if (this.couponDetailId && this.dataCheck.length > 0) {
        const data = {
          couponId: this.couponDetailId,
          productIds: this.dataCheck.map(item => item.productId)
        }
        this.$http.requestPut({ url: '/marketing/addProductCouponRel', param: data }).then(res => {
          if (res.code === 200) {
            this.queryProductList();
          }
        })

      } else {
        this.selectedRowKeys = this.dataCheck.map(item => item.productId)
        this.productDataSource = this.dataCheck.slice(0, this.productPageParam.pageSize)
        this.productPageParam.total = this.dataCheck.length  //总数
        this.dataSource2Value = this.dataCheck
      }
      this.cancelChange() //关闭弹窗清空筛选数据
    },

    //弹窗取消事件
    cancelChange() {
      this.visible = false
      this.pageParam2 = {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
      this.inquire = {
        productName: '',
        productType: ''
      }
    },

    //列表checkbox的选中事件，全选为当前页选中的数据，所有数据需特殊处理
    handleSelectionChange(selection) {
      this.dataCheck = selection
      const uniqueArr = this.dataCheck.filter((item, index) =>
        this.dataCheck.findIndex(i => i.productId === item.productId) === index);

      this.dataCheck = uniqueArr
      this.selectedRowKeys = this.dataCheck.map(item => item.productId)

      // console.log(this.selectedRowKeys);

      this.$nextTick(() => {
        // this.$refs.multipleTable && this.$refs.multipleTable.clearSelection()
        if (this.selectedRowKeys.length > 0) {
          const selectedRows = this.dataSource2.filter(row => this.selectedRowKeys.includes(row.productId));
          selectedRows.forEach(row => {
            this.$refs.multipleTable && this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
      })

    },

    //弹窗列表分页
    handleSizeChange2(size) {
      this.pageParam2.pageNum = 1;
      this.pageParam2.pageSize = size;
      this.getProductList();
    },
    handleCurrentChange2(page) {
      this.pageParam2.pageNum = page;
      this.getProductList();
    },

    //****************使用说明****************
    // 富文本 图片添加
    handleEditorImgAdd(pos, file) {
      const formdata = new FormData()
      formdata.append('file', file)
      this.$http.requestPost({
        url: '/common/file/upload',
        param: formdata
      }).then(res => {
        this.$refs.md.$img2Url(pos, res.data)
      })
    },
    // 富文本 图片删除
    handleEditorImgDel(pos) { },
    // 富文本改变后的内容
    editorChange(val, render) {
      this.editorHtml = render
    },
    //navbar跳转
    skip(ind) {
      this.navbarChange(ind)
      const id = this.navList[ind].id
      document.querySelector('#' + id).scrollIntoView(true)
    },
    // nav改变
    navbarChange(ind) {
      const i1 = this.navList.findIndex(item => item.active)
      this.navList[i1].active = false
      this.navList[ind].active = true
    },
    // 函数防抖
    debounce(fn, interval = 200) {
      if (this.timer) clearTimeout(this.timer);
      let args = arguments
      this.timer = setTimeout(() => {
        fn.apply(this, args)
      }, interval)
    },
    // 滚动条改变
    scrollChange() {
      var scrollHeight = this.mainEl.scrollHeight
      var scrollTop = this.mainEl.scrollTop
      var height = this.mainEl.offsetHeight
      // 2035 580 581    2035 535 581
      // 2035 919 581         870
      //      1454
      this.debounce(function () {
        var rowHight = document.getElementById('row_2').offsetHeight
        let scroll = scrollTop + height;
        if (scroll <= 600) {
          this.navbarChange(0)
        } else if (scroll > 600 && scroll <= (900 + rowHight)) {
          this.navbarChange(1)
        } else if (scroll > (900 + rowHight) && scroll <= (600 + rowHight + 900)) {
          this.navbarChange(2)
        } else {
          this.navbarChange(3)
        }
      }, 100)
    },

    handleFocus(panelIndex) {
      setTimeout(() => {
          const panel = document.getElementsByClassName('el-picker-panel')[panelIndex];
          if (panel) {
              const button = panel.getElementsByClassName('el-button')[0];
              if (button) {
                  button.style.display = 'none';
              }
          }
      }, 100);
    }

  },

  beforeDestroy() {
    this.mainEl.removeEventListener('scroll', this.scrollChange)
  },
};


</script>

<style lang="scss" scoped>
.inputW {
  width: 400px;
}

.main-box {
  background-color: #f5f5f5;
  position: static;
}

.nav-bar {
  padding: 12px 20px;
  margin-bottom: 10px;
  position: absolute;
  top: 68px;
  left: 10px;
  z-index: 999;
  width: calc(100% - 23px);
  box-shadow: 0px 2px 1px 0px #f5f5f5;

  &>a {
    margin-right: 30px;
    font-size: 14px;
    padding: 10px 0;
    border-top: 3px solid transparent;
  }

  .active {
    border-color: #1890ff;
    color: #1890ff;
  }
}

.row_box {
  margin-bottom: 10px;
  font-size: 14px;

  .header {
    padding: 18px 20px 10px;
    border-bottom: 1px solid #cccccc;
  }

  .content {
    padding: 10px;
  }
}

#row_1 {
  padding-top: 50px;

  .el-form {
    max-width: 600px;
  }

  .textColor {
    height: 12px;
    font-size: 12px;
    color: #c0c4cc;
  }

  .textColorRules {
    margin-top: -10px;
    margin-bottom: 10px;
  }

  .consumeP {
    display: flex;

    .dCheck {
      padding-top: 10px;
      margin-left: 12px;
      margin-right: -25px;
    }

    .VIPCheck {
      display: flex;
      padding: 10px 0 0 30px;
      height: 36px;
    }

    .textW {
      width: 510px;
      height: 30px;
      padding-left: 100px;
      margin-bottom: 10px;
    }
    .group-box {
      width: 510px; 
      padding:5px 0 5px 100px;

      .group-tag {
        margin:0 5px 5px 0;
      }
    }
  }

  .ditchBox {
    padding-left: 10px;

    strong {
      padding-right: 8px;
    }

    .ditchText {
      width: 460px;
      height: 35px;
      padding-top: 5px;
      padding-left: 92px;
    }
  }

}

.condition {
  display: flex;
  flex-direction: column;

  .cRadio2 {
    height: 30px;
    line-height: 45px;
  }
}

.editor {
  max-width: 1000px;
  max-height: 430px;

  .v-note-wrapper.shadow {
    height: 430px;
  }
}

.submit-btn {
  display: flex;
  justify-content: center;
  padding: 10px 0 20px 0;
}

</style>
