<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="管理员名称/手机号">
          <el-input v-model="inquire.name" placeholder="管理员名称/手机号" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增管理员</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column label="序号" prop="date" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column label="姓名" min-width="120" prop="managerName" />
            <el-table-column label="手机号" min-width="120" prop="managerPhoneNumber" />
            <el-table-column label="权限组" min-width="300" prop="" show-overflow-tooltip>
              <template slot-scope="scope">
                <div v-if="scope.row.managerId == '0'">超级管理员</div>
                <div v-else>{{ scope.row.rightsGroupNames ? scope.row.rightsGroupNames.join() : scope.row.rightsGroupNames }}</div>
              </template>
            </el-table-column>
            <el-table-column label="状态" min-width="100" prop="accountStatus">
              <template slot-scope="scope">
                {{ scope.row.accountStatus === 'normal' ? '启用' : '锁定' }}
              </template>
            </el-table-column>
            <el-table-column label="添加时间" min-width="160" prop="createTime" />
            <el-table-column label="添加人" min-width="140" prop="addByName" show-overflow-tooltip />
            <el-table-column fixed="right" label="操作" min-width="200" prop="">
              <template v-if="scope.row.managerId !== '0'" slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="resetPwd(scope.row)">重置密码</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="adminListEMail(scope.row)">管理邮箱</span>
                <span class="operation-btn_partition"> / </span>
                <span v-show="scope.row.accountStatus === 'normal'" class="operation-btn" @click="stateSwitch(scope.row, 'h')">锁定</span>
                <span v-show="scope.row.accountStatus !== 'normal'" class="operation-btn" @click="stateSwitch(scope.row, 's')">启用</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="loginHistory(scope.row)">登录历史</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination :current-page="pageParam.pageNum" :page-size="pageParam.pageSize" :page-sizes="$api.pageSizes" :total="pageParam.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
      </el-main>
    </div>

    <!-- 添加弹框 -->
    <el-dialog :before-close="closeDialog" :title="dialogTitle + '管理员'" :visible.sync="dialogVisible" width="650px">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="110px">
          <el-form-item label="姓名" prop="managerName">
            <el-input v-model="submitForm.managerName" placeholder="姓名" maxlength="10" />
          </el-form-item>
          <el-form-item label="手机号" prop="managerPhoneNumber">
            <el-input v-model="submitForm.managerPhoneNumber" maxlength="11" placeholder="手机号" />
          </el-form-item>
          <el-form-item label="权限组" prop="rightsGroupIds">
            <el-select v-model="submitForm.rightsGroupIds" class="width-inherit" multiple placeholder="请选择">
              <el-option v-for="item in rightsList" :key="item.groupId" :label="item.groupName" :value="item.groupId" />
            </el-select>
          </el-form-item>
          <el-form-item v-show="isAdd" :prop="isAdd?'managerPassword':''" label="密码">
            <el-input v-model="submitForm.managerPassword" placeholder="密码" show-password maxlength="20" type="password" />
          </el-form-item>
          <el-form-item v-show="isAdd" :prop="isAdd?'managerPasswordConfirm':''" label="输入重复密码">
            <el-input v-model="submitForm.managerPasswordConfirm" placeholder="输入重复密码" show-password maxlength="20" type="password" />
          </el-form-item>
          <el-form-item label="区域权限" prop="managerType">
            <el-select v-model="submitForm.managerType" class="width-inherit" placeholder="请选择" @change="typeChange">
              <el-option v-for="item in managerTypeList" :key="item.id" :label="item.name" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-form-item label="" v-if="showType !== 0">
            <el-select v-show="showType > 0" v-model="region" class="width-164" placeholder="请选择大区" @change="areaChanage($event, 1)">
              <el-option v-for="item in regionList" :key="item.regionId" :label="item.regionName" :value="item.regionId" />
            </el-select>
            <el-select v-show="showType > 1" v-model="district" class="width-164" placeholder="请选择片区" @change="areaChanage($event, 2)">
              <el-option v-for="item in districtList" :key="item.districtId" :label="item.districtName" :value="item.districtId" />
            </el-select>
            <el-select v-show="showType > 2" v-model="agency" class="width-164" placeholder="请选择办事处">
              <el-option v-for="item in agencyList" :key="item.agencyId" :label="item.agencyName" :value="item.agencyId" />
            </el-select>
          </el-form-item>
          <el-form-item label="产品权限" prop="managerType" v-show="!isAdd">
            <el-button type="primary"  @click="openProduct()" >管理产品权限</el-button>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 登录历史弹框 -->
    <el-dialog :title="historyTitle" :visible.sync="dialogHistory" width="60%">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="登录时间">
          <el-date-picker v-model="daterange" end-placeholder="结束日期" format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd" />
        </el-form-item>
        <el-form-item>
          <el-button size="small" type="primary" @click="handleQueryForm">查询</el-button>
        </el-form-item>
      </el-form>
      <!-- 普通表格 -->
      <el-table :data="dataTable" border height="400px" style="width: 100%">
        <el-table-column label="序号" width="60" align="center">
          <template v-slot="scope">{{ ( historyParam.pageNum - 1) * historyParam.pageSize + (scope.$index + 1) }}</template>
        </el-table-column>
        <el-table-column label="登录时间" prop="loginTime" align="center" />
        <el-table-column label="ip" prop="ip" align="center" />
        <el-table-column label="登录人员" prop="managerName" align="center" />
        <el-table-column label="浏览器型号" prop="browser" align="center" />
        <el-table-column label="登录平台" prop="loginPlatform" align="center" />
      </el-table>
      <div class="pagination">
        <el-pagination :current-page="historyParam.pageNum" :page-size="historyParam.pageSize" :page-sizes="[10, 20, 50, 100, 200, 1000]" :total="historyParam.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeHistory" @current-change="handleCurrentHistory" />
      </div>
      <span slot="footer">
        <el-button @click="dialogHistory = false">取 消</el-button>
        <el-button type="primary" @click="dialogHistory = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 管理邮箱弹窗 -->
    <el-dialog title="管理员邮箱列表" :visible.sync="eMailListVisible" width="60%">
      <el-button type="primary" @click="addEMailVisible = true" style="margin: 20px 0;" >添加邮箱地址</el-button>
      <!-- 普通表格 -->
      <el-table :data="EMailDataSource" v-loading="emailLoading" border height="400px" style="width: 100%">
        <el-table-column label="序号" width="60" align="center">
          <template v-slot="scope">{{ ( eMailListParam.pageNum - 1) * eMailListParam.pageSize + (scope.$index + 1) }}</template>
        </el-table-column>
        <el-table-column label="管理员" prop="createBy" align="center" min-width="100" />
        <el-table-column label="邮箱地址" prop="email" align="center" min-width="180" />
        <el-table-column label="添加时间" prop="createTime" align="center" min-width="160" />
        <el-table-column fixed="right" label="操作" min-width="80">
          <template slot-scope="scope">
            <span class="operation-btn" @click="delEMail(scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="eMailListVisible = false">关 闭</el-button>
        <el-button type="primary" @click="eMailListVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加邮箱 -->
    <el-dialog title="新增邮箱" :visible.sync="addEMailVisible" width="60%">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="邮箱" >
          <el-input v-model="emailInfo" placeholder="请输入" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" align="center">
        <el-button @click="addEMailVisible = false">关 闭</el-button>
        <el-button type="primary" @click="addEMailChange">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 产品列表 -->
    <product-table
      ref="productTable"
      :dialog-show="productDialog"
      :dialog-visible.sync="productDialog"
      :title="productDialogTitle"
      page-type="system"
    />
  </div>
</template>

<script>
import productTable from '@/components/shops/productTable.vue'
export default {
  components: {
    productTable
  },
  data() {
    var validatePwd = (rule, value, callback) => {
      value = value.trim()
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.submitForm.managerPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      // 查询参数
      inquire: {
        name: ''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      // 表格数据
      tableData: [],

      // 添加办事处地址
      dialogTitle: '', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      submitForm: {
        managerName: '',
        managerPhoneNumber: '',
        managerPassword: '',
        managerPasswordConfirm: '',
        rightsGroupIds: [],
        managerType: 'all',
        areaId: ''
      },
      submitRules: {
        managerName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        managerPhoneNumber: [{ required: true, message: '请输入手机号', trigger: 'blur' },
        { pattern: /^1[3456789]\d{9}$/, message: '手机格式错误', trigger: 'blur' }
        ],
        managerPassword: [{ required: true, message: '请输入密码', trigger: 'blur' },
        { min: 6, max: 18, message: '密码长度为 6 ~ 18 位', trigger: 'blur' }
        ],
        managerPasswordConfirm: [
          { required: true, message: '请输入重复密码', trigger: 'blur' },
          { validator: validatePwd, trigger: 'blur' }
        ],
        rightsGroupIds: [{ required: true, message: '请选择权限组', trigger: 'change' }],
        managerType: [{ required: true, message: '请选择区域权限', trigger: 'change' }]
      },
      rightsList: [], // 权限组列表
      isAdd: true, // 是否为添加
      editRow: {},
      managerTypeList: [
        { name: '所有权限', id: 'all' },
        { name: '大区权限', id: 'region' },
        { name: '片区权限', id: 'district' },
        { name: '办事处权限', id: 'agency' }
      ],
      regionList: [],
      districtList: [],
      agencyList: [],
      region: '',
      district: '',
      agency: '',
      showType: 0,
      dialogHistory: false, // 弹框
      historyParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      dataTable: [], // 表格的数据
      daterange: [], // 输入框查询条件
      historyTitle: '',
      managerId: null,
      productDialog: false, // 产品弹窗显示
      productDialogTitle: '产品权限',// 产品弹窗title
      //邮箱管理
      eMailListVisible:false,
      eMailListParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      EMailDataSource: [], //email数据源
      emailInfo: '', //email信息
      addEMailVisible: false, //添加email弹窗
      managerData: '', //获取管理员参数
      emailLoading: false
    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
    // 获取权限组列表
    this.getRightsList()
  },
  methods: {
    // 打开产品权限弹窗
    openProduct() {
      // this.productDialogTitle = "产品列表";
      this.productDialog = true
      this.$refs.productTable.initData(this.editRow.managerId)
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    // 区域权限改变
    typeChange(e) {
      this.showType = this.managerTypeList.findIndex(it => it.id == e)
      if (e != 'all') {
        const url = '/system/manager/getRegion'
        this.getAreaList(url, 0, false)
      }
    },
    // 区域改变
    areaChanage(e, num, b = false) {
      let url = ''
      if (num == 1) {
        url = '/system/manager/getDistrict/' + e
      } else if (num == 2) {
        url = '/system/manager/getAgency/' + e
      }
      this.getAreaList(url, num, b)
    },
    // 获取区域列表
    getAreaList(url, num, bool = true) {
      this.$http.requestGet({ url, loading: false }).then(res => {
        const data = res.data
        if (num == 0) {
          this.regionList = data
          if (bool) {
            this.district = ''
            this.districtList = []
            return
          }
          if (this.region) {
            this.areaChanage(this.region, 1)
          }
        } else if (num == 1) {
          this.districtList = data
          if (bool) {
            this.district = ''
            this.agency = ''
            this.agencyList = []
            return
          }
          if (this.district) {
            this.areaChanage(this.district, 2)
          }
        } else {
          this.agencyList = data
          if (bool) {
            this.agency = ''
          }
        }
      })
    },

    // 获取权限组
    getRightsList() {
      this.$http.requestPost({ url: '/system/rights/group/list', loading: false }).then(res => {
        this.rightsList = res.data
      })
    },

    // 获取数据列表
    getTableList() {
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http.requestPost({
        url: '/system/manager/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.pageParam.total = res.data.total
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    // 添加
    addition() {
      this.dialogTitle = '新增'
      this.dialogVisible = true
      this.isAdd = true
      this.editRow = {}
    },
    // 修改
    handleEdit(row) {
      this.dialogTitle = '编辑'
      this.isAdd = false
      this.$http.requestGet({ url: '/system/manager/get/' + row.managerId }).then(res => {
        const data = res.data
        this.submitForm = {
          managerName: data.managerName,
          managerPhoneNumber: data.managerPhoneNumber,
          rightsGroupIds: row.rightsGroupIds,
          managerType: data.managerType,
          areaId: data.areaId
        }
        this.disposeData(data)
        this.dialogVisible = true
        this.editRow = row
      })
    },
    // 处理数据返显
    disposeData(res) {
      const index = this.managerTypeList.findIndex(v => v.id == res.managerType)
      if (index > 0) {
        this.typeChange(res.managerType)
        this.region = res.areas[0].regionId
      }
      if (index > 1) {
        this.areaChanage(res.areas[0].regionId, 1, false)
        this.district = res.areas[1].districtId
      }
      if (index > 2) {
        this.areaChanage(res.areas[1].districtId, 2, false)
        this.agency = res.areas[2].agencyId
      }
    },
    // 提交办事处添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          if (this.showType > 0) {
            if (!this.region) return this.$message.warning('请选择大区！')
          }
          if (this.showType > 1) {
            if (!this.district) return this.$message.warning('请选择片区！')
          }
          if (this.showType > 2) {
            if (!this.agency) return this.$message.warning('请选择办事处！')
          }
          if (this.showType == 1) {
            this.submitForm.areaId = this.region
          } else if (this.showType == 2) {
            this.submitForm.areaId = this.district
          } else if (this.showType == 3) {
            this.submitForm.areaId = this.agency
          }

          const Base64 = require('js-base64').Base64
          const param = {
            managerName: this.submitForm.managerName,
            managerPhoneNumber: this.submitForm.managerPhoneNumber,
            managerPassword: Base64.encode(this.submitForm.managerPassword),
            managerPasswordConfirm: Base64.encode(this.submitForm.managerPasswordConfirm),
            rightsGroupIds: this.submitForm.rightsGroupIds,
            managerType: this.submitForm.managerType,
            areaId: this.submitForm.areaId
          }
          if (!this.isAdd) {
            param.managerId = this.editRow.managerId
            param.managerPassword = undefined
            param.managerPasswordConfirm = undefined
          }
          this.submit(param)
        } else {
          return false
        }
      })
    },
    // 添加，修改提交
    submit(param) {
      this.$http.requestPost({
        url: '/system/manager/save',
        param: param
      }).then(res => {
        this.getTableList()
        this.$message({
          message: '操作成功',
          type: 'success'
        })
        this.closeDialog()
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields())
      this.dialogVisible = false
      this.submitForm = {
        managerName: '',
        managerPhoneNumber: '',
        managerPassword: '',
        managerPasswordConfirm: '',
        rightsGroupIds: [],
        managerType: 'all',
        areaId: ''
      }
      this.showType = 0
      this.region = ''
      this.district = ''
      this.agency = ''
      this.districtList = []
      this.agencyList = []
    },

    // 锁定
    stateSwitch(row, type) {
      const name = type === 'h' ? '锁定' : '启用'
      this.$confirm('确定' + name + '该管理员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$http.requestPut({ url: '/system/manager/updateStatus/' + row.managerId }).then(res => {
          this.$message.success('操作成功！')
          this.getTableList()
        })
        // 状态的值还不确定
        this.submit(param)
      }).catch(err => {
      })
    },

    // 重置密码
    resetPwd(row) {
      this.$confirm('确定重置该管理员密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.$http.requestPut({ url: '/system/manager/resetPassword/' + row.managerId }).then(res => {
          this.$message.success('操作成功！')
          this.getTableList()
        })
        // 状态的值还不确定
        this.submit(param)
      }).catch(err => {
      })
    },

    //管理邮箱
    adminListEMail(row){
      this.eMailListVisible = true
      const data = { managerId: row.managerId, email: '' }
      this.managerData = data
      this.emailList(data)
    },
    //获取管理员邮箱列表
    emailList(data){
      this.emailLoading = true
      this.$http.requestPostQs({ url: '/system/manager/managerEmailList', param: data  }).then(res => {
        if(res.code === 200){
          this.EMailDataSource = res.data
          this.emailLoading = false
        }
      })
    },
    //添加邮箱
    addEMailChange(){
      if(this.EMailDataSource.length > 4){
        this.$message({
          message: "邮箱最多添加5个",
          type: "warning",
        });
        return false
      }
      //校验邮箱
      if (!/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/.test(this.emailInfo)) {
        this.$message({
          message: "邮箱输入有误",
          type: "warning",
        });
        return false;
      }

      this.$http.requestPutQs({
        url: '/system/manager/saveManagerEmail', 
        param: {email: this.emailInfo, managerId: this.managerData.managerId}  
      })
        .then(res => {
           this.addEMailVisible = false,
           this.emailInfo = '',
           this.emailList(this.managerData)
      })
    },
    //删除邮箱
    delEMail(row){
      console.log(row);
      this.$confirm(`邮箱: ${row.email}`, "确认删除该邮箱?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.$http
            .requestDel({
              url: `/system/manager/batchRemoveManagerEmail`,
              param: [row.id]
            })
            .then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.emailList(this.managerData)
            });
        })
        .catch(() => {});
    },


    loginHistory(row) {
      this.historyTitle = '登录记录 - ' + row.managerName
      this.managerId = row.managerId
      this.initQuery()
      this.dialogHistory = true
    },

    initQuery() {
      this.historyParam.pageNum = 1
      this.daterange = []
      this.handleQueryForm()
    },
    // 查询表格
    handleQueryForm() {
      if (!this.daterange) {
        this.daterange = []
      }
      const requestData = {
        pageNum: this.historyParam.pageNum,
        pageSize: this.historyParam.pageSize,
        param: {
          managerId: this.managerId,
          startTime: this.daterange.length > 0 ? this.daterange[0] : '',
          endTime: this.daterange.length > 0 ? this.daterange[1] : ''
        }
      }
      this.$http
        .requestPost({
          url: '/system/manager/getLoginRecord',
          param: requestData
        })
        .then((res) => {
          this.dataTable = res.data.list
          this.historyParam.total = res.data.total
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // pageSize改变时会触发
    handleSizeHistory(size) {
      this.historyParam.pageNum = 1
      this.historyParam.pageSize = size
      this.handleQueryForm()
    },
    // currentPage改变时会触发
    handleCurrentHistory(page) {
      this.historyParam.pageNum = page
      this.handleQueryForm()
    }

  }
}
</script>

<style lang="scss" scoped>
.width-164 {
  width: 164px;
}
</style>
