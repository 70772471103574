<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <div class="form-buttons">
        <div>
        <el-form-item label="" v-if="showType">
          <el-date-picker
            v-model="inquire.year"
            type="year"
            value-format="yyyy"
            placeholder="年份">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="" v-if="showType">
          <el-select v-model="inquire.month" placeholder="月份" clearable>
            <el-option v-for="item in monthList" :key="item.id" :label="item.name"  :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="showType">
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
        <el-form-item v-if="pageType === 'attache'">
          <el-button type="primary" size="small" @click="handleExport">导出明细列表</el-button>
        </el-form-item>
        </div>
        <div>
         <el-form-item>
          <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
        </el-form-item>
        </div>
        </div>
      </el-form>
    </div> 

    <!-- <div >
      <el-button size="small" type="primary" style="margin: 20px 0 0 20px">导出报表</el-button>
    </div> -->

    <div class="table">
      <el-main>
        <template>
          <el-table ref="tables" :data="tableData" v-loading="loading" border :expand-row-keys="expands" row-key="orderId" @expand-change="expandChange" height="400px" style="width: 100%">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-table :data="scope.row.items" border style="width: 100%">
                        <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <div class="flex-box">
                                    <img v-if="scope.row.productImg" :src="scope.row.productImg" alt=""
                                          class="img-box">
                                    <div class="hospital-name">{{ scope.row.productName }}</div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="产品编号" min-width="160" prop="productCode"
                                          show-overflow-tooltip></el-table-column>
                        <el-table-column label="产品规格" min-width="120" prop="productNorms">
                                        <template slot-scope="scope">{{ scope.row.productNorms || '/' }}</template>
                                    </el-table-column>
                        <el-table-column label="单价" min-width="120" prop="itemPrice">
                            <template slot-scope="scope">{{ '￥' + scope.row.itemPrice }}</template>
                        </el-table-column>
                        <el-table-column label="数量" min-width="120" prop="itemNum"></el-table-column>
                        <el-table-column label="购买总价" min-width="120" prop="">
                            <template slot-scope="scope">
                                {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column prop="orderCode" label="订单编号" min-width="200"></el-table-column>
            <el-table-column label="买家/收货人" min-width="160" prop="">
                <template slot-scope="scope">
                  <span v-show="scope.row.memberNickName && scope.row.recipient">
                    {{ scope.row.memberNickName + '/' + scope.row.recipient }}
                  </span>
                  <span v-show="!scope.row.recipient">
                    {{ scope.row.memberNickName }}
                  </span>
                </template>
            </el-table-column>
            <el-table-column label="总金额" min-width="120" prop="">
                <template slot-scope="scope">
                    {{ '￥' + scope.row.orderTotalPrice.toFixed(2) }}
                </template>
            </el-table-column>
            <el-table-column prop="completeTime" label="完成时间" min-width="160"></el-table-column>
            <el-table-column label="订单状态" min-width="120" prop="orderStatus">
                <template slot-scope="scope">
                    {{ $api.getValue($api.orderStatusList, scope.row.orderStatus, 'name') }}
                </template>
            </el-table-column>
            <el-table-column prop="attacheName" label="所属专员" min-width="100"></el-table-column>
            <el-table-column prop="brokerageAmount" label="提成金额" min-width="100">
                <template slot-scope="scope">{{ '￥' + (scope.row.items.length > 0 ? scope.row.items.reduce((n, e) => n + e.brokerageAmount, 0).toFixed(2) : '0')  }}</template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="details(scope.row)">订单详情</span> 
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        year: new Date().getFullYear().toString(),
        month: new Date().getMonth() + 1,
      },
      param: {},
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [], 
      expands: [],// 当前展示行
      monthList:[
        { name: '1月', id: 1 },
        { name: '2月', id: 2 },
        { name: '3月', id: 3 },
        { name: '4月', id: 4 },
        { name: '5月', id: 5 },
        { name: '6月', id: 6 },
        { name: '7月', id: 7 },
        { name: '8月', id: 8 },
        { name: '9月', id: 9 },
        { name: '10月', id: 10 },
        { name: '11月', id: 11 },
        { name: '12月', id: 12 },
      ],

      param: '',
      apiUrl: '',
      showType: true,
      pageType: ''
    };
  },
  created() {
    var query = this.$route.query;
    query.param = JSON.parse(query.param)
    if (Object.keys(query.param).length > 0) {
      Object.keys(query.param).forEach(v => {
        this.param += '&'+ v +'=' + query.param[v]
      })
    }
    this.pageType = query.type || '';
    if(query.zuanyuan === 'zuanyuan'){
      sessionStorage.setItem("zuanyuan", JSON.stringify(query))
    }
    this.transferStation(query)
  },
  mounted() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    handleExport(){
      const { attacheId = '', month = '', year = '' } = this.$route.query;
      const requestUrl = "/wait/payment/merchant/commission/comprehensive/exportOrders";
      const requestData = { attacheId, month, year }
      this.$http.requestPost({url: requestUrl, param: requestData,}).then((res) => {
        if(res.code === 200 && res.data !== '' && res.data !== null){
          window.location.href = res.data;
        }else{
          console.log(res);
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    // table 手风琴
    expandChange(news, old) {
        if (old.length === 0) {
            return this.expands = [];
        }
        this.expands = [news.orderId];
    },

    transferStation(query) {
      var time = [];
      if (query.year) {
        if (query.month) {
          time = this.$public.getAssignMonth(query.year + '-' + this.$public.disposeDate(query.month) + '-01')
        } else {
          time = [query.year + '-01-01', query.year + '-12-31']
        }
      } else {
        query.year = new Date().getFullYear()
        time = [query.year + '-01-01', query.year + '-12-31']
      }

      switch (query.type) {
        case 'merchant':
          // 商家销售订单明细
          this.apiUrl = '/wait/payment/merchant/commission/comprehensive/merchantStatisticsDetailList';
          this.showType = true;
          this.inquire.merchantId = query.merchantId
          break;
        case 'hospital':
          // 医院销售订单明细
          this.apiUrl = '/wait/payment/merchant/commission/comprehensive/hospitalChildStatisticsDetailList';
          this.showType = false;
          this.inquire = {
            year: query.year,
            month: query.month,
            hospitalId: query.hospitalId,
          }
          break;
        case 'product':
          // 电/医药/租赁商品销售订单明细
          this.apiUrl = 'wait/payment/merchant/commission/comprehensive/electronicStatisticsChildDetailList';
          this.showType = false;
          
          this.inquire = {
            payTime: time[0] + ' 00:00:00',
            payTimeEnd: time[1] + ' 23:59:59',
            productId: query.productId,
          }
          break;
        case 'attache':
          // 专员员销售订单明细
          this.apiUrl = '/wait/payment/merchant/commission/comprehensive/attacheStatisticsChildList';
          this.showType = false;
          this.inquire = {
            pageNum: this.pageParam.pageNum,
            pageSize: this.pageParam.pageSize,
            param: {
              year: query.year,
              month: query.month,
              attacheId: query.attacheId,
            }
          }
          break;
        case 'area':
          // 区域销售订单明细
          this.apiUrl = '/wait/payment/merchant/commission/comprehensive/regionStatisticsChildDetailList';
          this.showType = false;
          this.inquire = {
            payTime: time[0] + ' 00:00:00',
            payTimeEnd: time[1] + ' 23:59:59',
            regionType: query.regionType,
            regionId: query.regionId
          }
          break;
      }
    },

    // 获取数据列表
    getTableList() {
      this.loading = true;
      this.$http.requestPost({
        url: this.apiUrl + '?pageNum=' + this.pageParam.pageNum + '&pageSize=' + this.pageParam.pageSize + this.param,
        param: this.inquire,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.data.list;
        this.pageParam.total = res.data.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    
    // 详情
    details(row) {
      let path = '';
      switch (row.orderClassify) {
          case 'electronic':
              if (row.orderStatus !== "auditing" && row.orderStatus !== "unaudited") {
                path = '/order/commerce/particulars'
              } else {
                path = '/order/commerce/returnedGoods/particulars'
              }
              // index = this.$api.EcommerceOrderState.findIndex(item => item.value === row.orderStatus);
              break;
          case 'medicine':
              if (row.orderStatus !== "auditing" && row.orderStatus !== "unaudited") {
                path = '/order/medical/hospitalDelivery/particulars'
              } else {
                path = '/order/medical/hospitalDelivery/returnedGoods/particulars'
              }
              // index = this.$api.orderState.findIndex(item => item.value === row.orderStatus) + 1;
              break;
          case 'lease':
              path = '/order/lease/particulars'
              // index = this.$api.leaseOrderState.findIndex(item => item.value === row.orderStatus);
              break;
          default:
              return
      }
      this.$router.push({
          path,
          query: {
              // type: index,
              id: row.orderId
          }
      })
    },

  },
};
</script>

<style lang="scss" scoped>
  .address-list {
    flex-wrap: wrap;
    
    & > div {
      display: inline-flex;
    }
  }
  .text {
    padding: 0 5px;
  }
  .text-left {
    min-width: 125px;
    text-align: right;
  }
  .form-buttons{
    display: flex;
    justify-content: space-between;
  }
</style>
