var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "优惠券ID" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入优惠券ID" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.queryListInfo($event)
                      },
                    },
                    model: {
                      value: _vm.query.couponCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "couponCode", $$v)
                      },
                      expression: "query.couponCode",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "优惠券名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入优惠券名称" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.queryListInfo($event)
                      },
                    },
                    model: {
                      value: _vm.query.couponName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "couponName", $$v)
                      },
                      expression: "query.couponName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "透出时间" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-date-picker", {
                      attrs: {
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        placeholder: "请选择日期范围",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      on: { change: _vm.queryListInfo },
                      model: {
                        value: _vm.query.tcDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "tcDate", $$v)
                        },
                        expression: "query.tcDate",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-select",
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.query.couponStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "couponStatus", $$v)
                        },
                        expression: "query.couponStatus",
                      },
                    },
                    _vm._l(_vm.$api.couponStatusOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "适用人群" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-select",
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.query.couponUsePerson,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "couponUsePerson", $$v)
                        },
                        expression: "query.couponUsePerson",
                      },
                    },
                    _vm._l(_vm.$api.couponUsePerson, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "使用渠道" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "form-select",
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.query.useChannel,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "useChannel", $$v)
                        },
                        expression: "query.useChannel",
                      },
                    },
                    _vm._l(_vm.$api.useChannel, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.name, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.queryListInfo },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "added" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.addCoupon },
            },
            [_vm._v("添加优惠券")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.dataSource,
                      border: "",
                      height: "330px",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "序号", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    (_vm.pageParam.pageNum - 1) *
                                      _vm.pageParam.pageSize +
                                      (scope.$index + 1)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponName",
                        label: "优惠券名称",
                        "min-width": "160",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponCode",
                        label: "优惠券ID",
                        "min-width": "180",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orientedPopulationDesc",
                        label: "适用人群",
                        "min-width": "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "preferenceMode",
                        label: "优惠方式",
                        "min-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.findEnumName(
                                      _vm.$api.preferenceMode,
                                      scope.row.preferenceMode
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "useChannelDesc",
                        label: "使用渠道",
                        "min-width": "100",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponStatus",
                        label: "状态",
                        "min-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.findEnumName(
                                      _vm.$api.couponStatus,
                                      scope.row.couponStatus
                                    )
                                  ) +
                                  "\n            "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _vm.couponType === "salesperson"
                      ? _c("el-table-column", {
                          attrs: {
                            prop: "qrCodeCount",
                            label: "二维码数量",
                            "min-width": "100",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "penetrateTime",
                        label: "透出时间",
                        "min-width": "160",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "", label: "已领取", "min-width": "110" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(scope.row.alreadyReceived) + "张"
                                ),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "", label: "已使用", "min-width": "110" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [
                                _vm._v(_vm._s(scope.row.haveBeenUsed) + "张"),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "createTime",
                        label: "添加时间",
                        "min-width": "160",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "180",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                scope.row.couponStatus !== "4"
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "operation-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editCoupon(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("编辑")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "operation-btn_partition",
                                        },
                                        [_vm._v("/")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "operation-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.endCoupon(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("结束")]
                                      ),
                                    ])
                                  : _c("div", [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "operation-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.delCoupon(
                                                scope.row.couponId
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "operation-btn_partition",
                                        },
                                        [_vm._v("/")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "operation-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editCoupon(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("查看")]
                                      ),
                                    ]),
                                _vm._v(" "),
                                _vm.couponType === "salesperson"
                                  ? _c(
                                      "div",
                                      { staticStyle: { "margin-left": "4px" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "operation-btn_partition",
                                          },
                                          [_vm._v("/")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "operation-btn",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openQrcodeList(
                                                  scope.row
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("二维码列表")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam.pageSize,
                      total: _vm.pageParam.total,
                    },
                    on: {
                      "size-change": function ($event) {
                        return _vm.handleSizeChange($event, "couponTable")
                      },
                      "current-change": function ($event) {
                        return _vm.handleCurrentChange($event, "couponTable")
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.qrcodeData.couponCode + " - 二维码列表",
            visible: _vm.qrcodeData.visible,
            width: "82%",
            top: "6vh",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.qrcodeData, "visible", $event)
            },
            close: _vm.closeQrcodeDialog,
          },
        },
        [
          _c("div", { staticClass: "main-box qrcode_list" }, [
            _c(
              "div",
              { staticClass: "head-search-row search_box" },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "demo-form-inline",
                    attrs: { inline: true, size: "medium" },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务员姓名" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "业务员姓名", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.updateList("qrcodeTable")
                            },
                          },
                          model: {
                            value: _vm.qrcodeData.params.intendantName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.qrcodeData.params,
                                "intendantName",
                                $$v
                              )
                            },
                            expression: "qrcodeData.params.intendantName",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    !_vm.thirdPartyPermissions
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "所属区域" } },
                          [
                            _vm.$public.isAllAreaPower()
                              ? _c("el-cascader", {
                                  attrs: {
                                    props: _vm.addressConfigure,
                                    options: _vm.addressList,
                                    clearable: "",
                                    placeholder: "请选择地址",
                                  },
                                  model: {
                                    value: _vm.qrcodeData.params.areaId,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.qrcodeData.params,
                                        "areaId",
                                        $$v
                                      )
                                    },
                                    expression: "qrcodeData.params.areaId",
                                  },
                                })
                              : _c("d-area", {
                                  attrs: {
                                    values: _vm.qrcodeData.params.areaId,
                                  },
                                  on: {
                                    "update:values": function ($event) {
                                      return _vm.$set(
                                        _vm.qrcodeData.params,
                                        "areaId",
                                        $event
                                      )
                                    },
                                  },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.thirdPartyPermissions
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "商家端名称" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "商家端名称",
                                clearable: "",
                              },
                              nativeOn: {
                                keyup: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.updateList("qrcodeTable")
                                },
                              },
                              model: {
                                value: _vm.qrcodeData.params.merchantName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.qrcodeData.params,
                                    "merchantName",
                                    $$v
                                  )
                                },
                                expression: "qrcodeData.params.merchantName",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "业务员编号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "业务员编号", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.updateList("qrcodeTable")
                            },
                          },
                          model: {
                            value: _vm.qrcodeData.params.intendantCode,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.qrcodeData.params,
                                "intendantCode",
                                $$v
                              )
                            },
                            expression: "qrcodeData.params.intendantCode",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-form-item",
                      { attrs: { label: "二维码编号" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "二维码编号", clearable: "" },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.updateList("qrcodeTable")
                            },
                          },
                          model: {
                            value: _vm.qrcodeData.params.qrCodeSerial,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.qrcodeData.params,
                                "qrCodeSerial",
                                $$v
                              )
                            },
                            expression: "qrcodeData.params.qrCodeSerial",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "margin-bottom": "20px" } },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.updateList("qrcodeTable")
                          },
                        },
                      },
                      [_vm._v("查询")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.bindQrcode(2)
                          },
                        },
                      },
                      [_vm._v("绑定二维码")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.unbindQrcode(2)
                          },
                        },
                      },
                      [_vm._v("解绑二维码")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "qrcodeTable_box" },
              [
                _c("TableList", {
                  ref: "qrcodeTable",
                  staticClass: "table_list",
                  attrs: {
                    height: "400px",
                    "table-header": _vm.qrcodeData.tableHeader,
                    loading: _vm.qrcodeData.loading,
                    "is-selection": true,
                    "row-key-name": "qrCodeId",
                    "table-data": _vm.qrcodeData.list,
                    total: _vm.qrcodeData.total,
                  },
                  on: {
                    setPage: function ($event) {
                      return _vm.handleCurrentChange($event, "qrcodeTable")
                    },
                    setSize: function ($event) {
                      return _vm.handleSizeChange($event, "qrcodeTable")
                    },
                    delXuan: _vm.selectionChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "operation",
                      fn: function (ref) {
                        var data = ref.data
                        return [
                          _c(
                            "el-button",
                            {
                              staticClass: "btn",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.openPreviewQrcode(data)
                                },
                              },
                            },
                            [_vm._v("二维码")]
                          ),
                          _vm._v(" "),
                          !_vm.qrcodeData.showConfirmBtn
                            ? [
                                _c(
                                  "span",
                                  { staticStyle: { color: "#4e9aef" } },
                                  [_vm._v("/")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.unbindQrcode(1, data)
                                      },
                                    },
                                  },
                                  [_vm._v("解绑")]
                                ),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.qrcodeData.showConfirmBtn
            ? _c(
                "span",
                {
                  staticStyle: { "padding-top": "0px" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.confirmOperateQrcode(0)
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.confirmOperateQrcode(1)
                        },
                      },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看二维码",
            visible: _vm.qrcodeData.previewQrcodeDialog,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.qrcodeData, "previewQrcodeDialog", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "0 0 10px", "text-align": "center" } },
            [
              _c("img", {
                attrs: {
                  src: _vm.qrcodeData.currentRowData.qrCodeUrl,
                  alt: "",
                },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }