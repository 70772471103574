<!-- 产品库【跨境产品管理 -- 待发售产品列表】 -->
<template>
  <div class="main-box">
    <pendingProductList :product-type="'cross_border'" />
  </div>
</template>

<script>
import pendingProductList from '../components/pendingProductList.vue'

export default {
  components: {
    pendingProductList
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
