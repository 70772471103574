<template>
    <!-- 电商品-待签收退货单 -->
    <div class="main-box">
        <returned-goods orderStatus="pending_sign"></returned-goods>
    </div>
</template>

<script>
import returnedGoods from '@/components/order/returnedGoods_e'

export default {
    components: {
        returnedGoods
    },
    data() {
        return {
        
        };
    },
    methods: {
       
    },
};
</script>

<style lang="scss" scoped></style>
