var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "", height: "400px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        width: "60",
                        align: "center",
                        type: "index",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "问卷模板名称",
                        align: "center",
                        prop: "title",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "问卷模板编号",
                        align: "center",
                        prop: "questionnaireCode",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "填写时间",
                        align: "center",
                        prop: "fillInTime",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { fixed: "right", label: "操作", align: "center" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.detail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("详情")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.queryParams.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.queryParams.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          staticClass: "productList",
          attrs: {
            title: _vm.currentTitle,
            visible: _vm.showProDialog,
            width: "70%",
            top: "5vh",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showProDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-table",
                {
                  ref: "proTable",
                  staticStyle: { "margin-top": "10px" },
                  attrs: {
                    data: _vm.questionnaireData,
                    height: "350",
                    border: "",
                    "header-cell-style": {
                      background: "#409eff",
                      color: "#ffffff",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: "序号",
                      align: "center",
                      width: "55",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "问题", align: "center", prop: "content" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "内容", align: "center", prop: "answer" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showProDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }