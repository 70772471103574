<template>
  <div class="schedule-box">
    <div class="top">
      <div class="title">
        <div class="txt_box">
          <div class="tip">
            <img src="../../assets/image/wenhao.png" alt="">
            <div class="poup">
              <div class="txt">
                分析各大区年度/季度/月度的销售情况
              </div>
            </div>
          </div>
          <div class="icon">
            <img src="@/assets/image/top.png" alt="">
            <!-- <div class="border"></div> -->
          </div>
         <div class="text">{{ title }}</div>
        </div>
      </div>
      <div class="right">
        <div class="nav-list">
          <div v-for="(item,index) in list" :key="item.value" class="item" :class="current == index ? 'active' : ''" @click="getActive(index)">
            <span>
              {{ item.name }}
            </span>
          </div>
        </div>
        <div class="select-box">
          <el-select v-if="current == 1" v-model="getSelectQ" class="select" :popper-append-to-body="false" @change="getTableType">
            <el-option v-for="(item,index) in selectListQ" :key="index" :label="item.label" :value="item.value" />
          </el-select>
          <el-select v-if="current == 2" v-model="getSelectM" class="select" :popper-append-to-body="false" @change="getTableType">
            <el-option v-for="(item,index) in selectListM" :key="index" :label="item.label" :value="item.value" />
          </el-select>
        </div>
      </div>
    </div>

    <!-- 下方进度条 -->
    <div class="bottom">
      <div v-for="(item,index) in progressList" :key="index" class="progress-box">
        <div class="left">
          {{ item.name }}
        </div>
        <el-progress :percentage="100" color="#00bfbf" :show-text="false" />
        <div class="message-outer">
          <div class="message-text" :style="{right: (100 - ( 100))+'%'}">
            <span class="order">{{ item.orderTotalPrice }}</span>
            <span class="aim"> {{ 100 + '%' }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '年度业绩达标情况'
        },
        current: {
            type: Number,
            default: 0
        },
        progressList: {
            type: Array,
            default: () => []
        },
        selectQ: {
            type: Number,
            default: 0
        }, // 选择季度的值
        selectM: {
            type: Number,
            default: 0
        }// 选择月度的值
        },
   data() {
      return {
        list: [
      { name: '年度', value: 1 },
      { name: '季度', value: 2 },
      { name: '月度', value: 3 }
      ],
      selectListQ: [
                { label: '第一季度', value: 1 },
                { label: '第二季度', value: 2 },
                { label: '第三季度', value: 3 },
                { label: '第四季度', value: 4 }
            ],
      selectListM: [
                { label: '一月', value: 1 },
                { label: '二月', value: 2 },
                { label: '三月', value: 3 },
                { label: '四月', value: 4 },
                { label: '五月', value: 5 },
                { label: '六月', value: 6 },
                { label: '七月', value: 7 },
                { label: '八月', value: 8 },
                { label: '九月', value: 9 },
                { label: '十月', value: 10 },
                { label: '十一月', value: 11 },
                { label: '十二月', value: 12 }
            ]
    //   progressList2:[
    //     {name:'华南大区',percentage:100,orderTotalPrice:8679545},
    //     {name:'华中大区',percentage:50,orderTotalPrice:8679545},
    //     {name:'华北大区',percentage:40,orderTotalPrice:5464646},
    //     {name:'中南大区',percentage:90,orderTotalPrice:4564566},
    //     {name:'东北大区',percentage:30,orderTotalPrice:8679545},
    //     {name:'西南大区',percentage:60,orderTotalPrice:8679545},
    //     {name:'华南大区',percentage:2,orderTotalPrice:8679545},
    //     {name:'华南大区',percentage:60,orderTotalPrice:8679545},
    //     {name:'华南大区',percentage:25,orderTotalPrice:6445564},
    //     {name:'华南大区',percentage:80,orderTotalPrice:6666555},
    //   ]
      }
   },
   computed: {
    getSelectQ: {
            get() {
                return this.selectQ
            },
            set(val) {
                this.$emit('update:selectQ', val)
            }
        }, // 选择季度的值
    getSelectM: {
        get() {
                return this.selectM
            },
            set(val) {
                this.$emit('update:selectM', val)
            }
    }// 选择月度的值
   },
   created() {
   },
   methods: {
    // 处理进度条后面显示文字
    // format(num,percentage) {
    //     return () => {
    //          return num + '\t' + percentage + '%'
    // 	}
    //   },
   // 点击切换tab栏
    getActive(index) {
        this.$emit('getActive', index)
    },
    // 选择对应的值
    getTableType(e) {
       console.log('e', e)
       this.$emit('getTableType', e)
    }
   }
}
</script>
<style lang="scss" scoped>
::v-deep .el-select-dropdown{
background:#0a1c4a !important;
.el-select-dropdown__item{
    color: #f5f5f5;
}
.el-select-dropdown__item.hover{
    background: none !important;
    // color: #409EFF;
    // font-weight: 700;
}
.el-select-dropdown__item.selected {
    color: #26ddfc;
    font-weight: 700;
}
}
::v-deep .el-picker-panel__body{
background:#0a1c4a !important;
color: #f5f5f5;
.el-picker-panel__icon-btn{
    color: #f5f5f5!important;
}
}
// 修改进度条样式
::v-deep .el-progress{
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    .el-progress-bar{
        flex: 1;
    }
    .el-progress-bar__outer{
        height: 18px !important;
        margin-right: 10px;
    }
    .el-progress__text{
        font-size: 10px  !important;
        color: #fff;
    }
}
.schedule-box{
    box-sizing: border-box;
    width: 665px;
    height: 665px;
    display: flex;
    flex-direction: column;
    background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/12/bb8ee66f-615c-40f8-85a0-cb4b7a8d01b2.png") no-repeat center 0px;
    background-size: cover;
    padding: 16px;
    // margin-top: 11px;
    margin-bottom: 17px;
    // margin-left: 8px;
    padding-left: 28px;
    box-sizing: border-box;
    .top{
        width: 100%;
        // flex: .2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding-left: 7px;
        // padding-bottom: 34px;
        // padding-right: 110px;
        padding: 7px 110px 30px 2px;
        .title{
           display: flex;
          .text{
            font-size: 16px;
            font-weight: 700;
            background-image: linear-gradient(0deg, #56FEFF, #E2F3FB);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
           .icon {
            margin-right: 10px;
            position: relative;
            img {
                width: 18px;
                height: 18px;
            }
           }
          .txt_box{
            display: flex;
            align-self: center;
            align-items: center;
            align-content: center;
            &:hover{
              .tip{
                .poup {
                  display: flex;
                }
              }
            }
          }
        }
        .right{
            display: flex;
           ::v-deep .select{
               width: 106px;
               height: 30px;
               margin-right: 10px;
               .el-input{
                background: url("../../assets/image/bg-select.png") no-repeat center 0px;
                background-size: cover;

               }
               .el-input__inner{
                        background: none;
                        padding-left: 10px !important;
                        padding-right: 17px !important;
                        height: 30px;
                        font-size: 10px;
                        border-radius: 10px !important;
                        border: none !important;
                        font-weight: 700;
                        font-size: 14px;
                        // 给文字加渐变
                        background-image: linear-gradient(0deg, #83C6FF, #CAE6FF, #FFFFFF);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                .el-input__icon {
                    height: 100%;
                    line-height: normal;
                    font-size: 12px;
                    width: 14px !important;
                }
                .el-select__caret {
                    color: #fff;
                    font-weight: 700;
                }
            }
        .nav-list{
            display: flex;
            border-radius: 8px;
            margin-right: 10px;
            .item{
                cursor: pointer;
                position: relative;
                margin-right:10px;
                width: 60px;
                height: 30px;
                font-weight: 400;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: url("../../assets/image/bg.png") no-repeat center 0px;
                background-size: cover;
                margin-right: 1px;
                span {
                    color: #4C89CD;
                }
            }
            .active{
                span {
                    background-image: linear-gradient(0deg, #83C6FF, #CAE6FF, #FFFFFF);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                font-weight: 700;
                background: url("../../assets/image/bg-active.png") no-repeat center 0px;
                background-size: cover;
            }
        }
        .select-box {
            width: 106px;
            height: 30px;
            ::v-deep .el-scrollbar{
                width: 110px;
                .el-select-dropdown__item{
                    padding: 0 0 0 18px;
                }
            }
        }
        }
          }
     .bottom{
        // margin: 15px;
        margin-bottom: 0;;
        margin-top: 13px;
        flex: .8;
        background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/10/f4882464-af09-4d49-8d3e-026f6b7b1a57.png")no-repeat center 0px;
        background-size: contain;
        .progress-box{
            display: flex;
            align-items: center;
            margin-bottom: 37px;
            position: relative;
            width: 600px;
            .left{
                color: rgba($color: #d5f9fb, $alpha: .8);
                font-size: 14px;
                margin-right: 19px;
                width: 60px;
            }
            ::v-deep .el-progress-bar {
                .el-progress-bar__outer {
                    height: 6px !important;
                    border-radius: 0;
                    background-color: transparent;

                }
                .el-progress-bar__inner{
                    background-color: unset;
                    border-radius: 0;
                }
            }
            .message-outer {
                // position: relative;
                position: absolute;
                width: 80%;
                height: 20px;
                margin-left: 136px;
            }
            .message-text {
                color: #fff;
                position: absolute;
                right: 7px;
                bottom: -19px;
                font-weight: 700;
                white-space: nowrap;
                .order {
                    font-size: 14px;
                    color: #55AFFF;
                    margin-right: 5px;
                    font-family: Microsoft YaHei;
                }
                .aim {
                    font-size: 18px;
                    background-image: linear-gradient(0deg, #83C6FF, #CAE6FF, #FFFFFF);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-family: Bahnschrift;
                }
            }
        }
        .progress-box:nth-child(odd){
            ::v-deep .el-progress-bar__inner{
                background-image: linear-gradient(to right, #010730 , #4B9CFE);
            }
        }
        .progress-box:nth-child(even){
            ::v-deep .el-progress-bar__inner{
                background-image: linear-gradient(to right, #010730 , #3CD0AB);
            }
        }
     }
    }
.tip{
  width: 28px;
  height: 28px;
  position: relative;
  margin-right: 10px;
  img{
    width: 100%;
    height: 100%;
  }
}
.poup{
  background: url('../../assets/image/tip.png') no-repeat center center;
  padding: 20px;
  text-align: left;
  left: 10px;
  z-index: 100;
  position: absolute;
  width: 262px;
  // height: 71px;
  color: #c0e2ff;
  font-weight: 400;
  font-size: 12px;
  font-face: Microsoft YaHei;
  line-height: 20px;
  display: none;
}
</style>
