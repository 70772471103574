<template>
  <!-- 开票管理-订单详情 -->
  <div class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>

    <!-- <div class="steps-box bg_white">
      <el-steps :active="active">
        <el-step v-for="item in steps" :key="item.title" :description="item.time" :title="item.title" />
      </el-steps>
    </div> -->

    <div class="row_2 bg_white flex-box">
      <div class="row_left">
        <h4>配送信息</h4>
        <p class="color_light-black">配送方式：{{ '商家配送' + '1' }}</p>
        <p class="color_light-black">买家姓名：{{ '商家配送' }}</p>
        <p class="color_light-black">买家手机号：{{ '商家配送' }}</p>
        <p style="height: 12px"></p>
        <p class="color_light-black">收货人姓名：{{ '商家配送' }}</p>
        <p class="color_light-black">收货人手机号：{{ '商家配送' }}</p>
        <p class="color_light-black">
          收货人地址：
          <span>{{ '广东省深圳市宝安西乡街道固戍地铁口D口机器人产业园B栋401' }}</span>
          <el-button style="padding: 0" type="text">修改</el-button>
        </p>
        <p style="height: 24px"></p>
        <p class="color_light-black">备注信息：{{ '无' }}</p>
      </div>

      <div class="row_right">
        <h4>
          订单状态：
          <span>{{ orderType[active] }}</span>
        </h4>
        <div v-if="active == 0">
          <!-- <el-button size="medium" type="primary" @click="amendPrice">修改价格</el-button> -->
          <el-button plain size="medium">取消订单</el-button>
        </div>
        <div v-else-if="active == 1">
          <el-button plain size="medium" @click="sendOut">发货</el-button>
        </div>
        <div v-else-if="active == 4" class="color_light-black">
          超时未支付订单系统自动取消
        </div>
      </div>
    </div>

    <div class="row_3 bg_white">
      <h4>商品信息</h4>
      <div class="table">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label="产品信息" min-width="120" prop="available">
            <template></template>
          </el-table-column>
          <el-table-column label="单价" min-width="100" prop="available" />
          <el-table-column label="数量" min-width="100" prop="available" />
          <el-table-column label="小计" min-width="100" prop="available" />
          <el-table-column label="实收金额" min-width="100" prop="available" />
          <el-table-column label="提成比例" min-width="100" prop="available" />
          <el-table-column label="提成金额" min-width="100" prop="available" />
        </el-table>
      </div>

      <div class="show-money">
        <ul>
          <li>
            商品金额：
            <span class="block_span">￥200.00</span>
          </li>
          <li>
            运费：
            <span class="block_span">+￥20.00</span>
          </li>
          <li class="line-box"></li>
          <li class="bold">
            实付总额：
            <span class="block_span color_red">￥180.00</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="row_4 bg_white">
      <h4>商家/医院信息</h4>
      <p class="color_light-black">
        <span class="title-box">商家名称：</span>
        {{ '润泽堂药行业' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">商家编号：</span>
        {{ 'S0001' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">医院名称：</span>
        {{ '润泽堂药行业西乡分店' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">医院编号：</span>
        {{ 'Y00008' }}
      </p>
    </div>

    <div class="row_4 bg_white">
      <h4>订单信息</h4>
      <p class="color_light-black">
        <span class="title-box">订单类型：</span>
        {{ '医药品订单' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">订单编号：</span>
        {{ '医药品订单' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">下单时间：</span>
        {{ '医药品订单' }}
      </p>
      <p v-if="active > 0 && active < 4" class="color_light-black">
        <span class="title-box">付款时间：</span>
        {{ '医药品订单' }}
      </p>
      <p v-if="active > 1 && active < 4" class="color_light-black">
        <span class="title-box">发货时间：</span>
        {{ '医药品订单' }}
      </p>
      <p v-if="active > 2 && active < 4" class="color_light-black">
        <span class="title-box">确认收货时间：</span>
        {{ '医药品订单' }}
      </p>
      <p v-if="active == 4" class="color_light-black">
        <span class="title-box">取消时间：</span>
        {{ '医药品订单' }}
      </p>
    </div>

    <div class="row_5 row_4 bg_white">
      <h4>专员信息</h4>
      <p class="color_light-black">
        <span class="title-box">专员姓名：</span>
        {{ '医药品订单' }}
      </p>
      <p class="color_light-black">
        <span class="title-box">专员编号：</span>
        {{ '医药品订单' }}
      </p>
    </div>

    <!-- 弹框-修改价格 -->
    <el-dialog :visible.sync="dialogVisible_1" title="修改价格" width="70%">
      <div class="Popout_content">
        <div class="table">
          <el-table :data="tableData_1" border style="width: 100%">
            <el-table-column label="序号" prop="date" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column label="产品信息" min-width="120" prop="available">
              <template></template>
            </el-table-column>
            <el-table-column label="单价" min-width="100" prop="available" />
            <el-table-column label="数量" min-width="100" prop="available" />
            <el-table-column label="小计" min-width="100" prop="available" />
            <el-table-column label="加价/减价" min-width="120" prop="available">
              <template>
                <div class="flex-box flex-center">
                  ￥
                  <el-input v-model="tableData_1.input" placeholder="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="实收金额" min-width="100" prop="available" />
          </el-table>
        </div>
        <div class="show-money">
          <ul>
            <li>
              商品金额：
              <span class="block_span">￥200.00</span>
            </li>
            <li>
              运费：
              <span class="block_span">+￥20.00</span>
            </li>
            <li>
              加价/减价：
              <span class="block_span color_red">-￥20.00</span>
            </li>
            <li class="line-box"></li>
            <li class="bold">
              实付总额：
              <span class="block_span color_red">￥180.00</span>
            </li>
          </ul>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_1 = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="dialogVisible_2" title="发货" width="600px">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="80px">
          <el-form-item label="订单编号">
            <el-input v-model="submitForm.name" disabled placeholder="订单编号" />
          </el-form-item>

          <el-form-item label="物流公司" prop="name">
            <el-input v-model="submitForm.name" placeholder="物流公司" />
          </el-form-item>

          <el-form-item label="物流单号" prop="name">
            <el-input v-model="submitForm.name" maxlength="30" placeholder="物流单号" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_2 = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 步骤条
      steps: [],
      active: 0,
      // 订单类型
      orderType: ['待付款', '待发货', '待确认收货', '已完成', '已取消'],
      // 非已取消订单
      stepsL_1: [
        { title: '买家下单', time: '2020-09-10 15:30:20' },
        { title: '买家付款', time: '2020-09-10 15:30:20' },
        { title: '商家发货', time: '2020-09-10 15:30:20' },
        { title: '交易完成', time: '2020-09-10 15:30:20' },
        { title: '已评论', time: '2020-09-10 15:30:20' }
      ],
      // 已取消订单
      stepsL_2: [{ title: '买家下单', time: '2020-09-10 15:30:20' }, { title: '已取消', time: '2020-09-10 15:30:20' }],

      // 商品信息
      tableData: [{ available: '超级管理员' }, { available: '超级管理员' }],

      //  ============= 代付款 =============
      // 修改价格
      dialogVisible_1: false,
      tableData_1: [{ available: '超级管理员' }],

      //  ============= 代发货 =============
      dialogVisible_2: false,
      submitForm: {
        //
        name: ''
      },
      submitRules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }]
      }
    }
  },
  created() {
    // 判断当前订单状态
    this.active = parseInt(this.$route.query.type) - 1
    if (this.active < 4) {
      this.steps = this.stepsL_1
    } else {
      this.steps = this.stepsL_2
    }
  },
  mounted() {},
  methods: {
    //  ========================== 代付款 ==========================
    // 修改价格
    amendPrice() {
      this.dialogVisible_1 = true
    },

    //  ========================== 代发货 ==========================
    // 发货
    sendOut() {
      this.dialogVisible_2 = true
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;

  .steps-box {
    padding: 30px 40px;
    margin-bottom: 10px;

    .el-step__head.is-process {
      border-color: #429fff;
      color: #429fff;
    }

    .el-step__title.is-process {
      color: #429fff;
    }

    .el-step__description.is-process {
      color: #429fff;
    }
  }

  .row_2 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    .row_left {
      width: 60%;

      & p {
        padding: 5px 0;
      }

      .color_wathet {
        cursor: pointer;
      }
    }

    .row_right {
      width: 40%;
    }

    h4 {
      padding-bottom: 10px;
    }

    .color_light-black {
      font-size: 15px;
    }
  }

  .row_3 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;

    & h4 {
      padding-bottom: 10px;
    }
  }

  .row_4 {
    padding: 30px 40px 20px;
    margin-bottom: 10px;
    font-size: 15px;

    & h4 {
      padding-bottom: 10px;
    }

    & p {
      padding-top: 5px;
    }
  }
}

.title-box {
  display: inline-block;
  width: 116px;
  text-align: right;
}

.show-money {
  padding: 10px 0;

  ul {
    li {
      display: flex;
      justify-content: flex-end;
      line-height: 30px;
      font-size: 16px;
    }

    .line-box {
      margin-left: 70%;
      padding-top: 20px;
      border-bottom: 1px solid #ccc;
    }

    .bold {
      font-weight: bolder;
    }
  }
}
</style>
