<template>
  <div :class="classObj" class="app-wrapper">
    <!-- 顶部导航 -->
    <topbar />
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
    <sidebar v-if="!sidebar.hide" class="sidebar-container" />
    <div :class="{sidebarHide: sidebar.hide}" class="main-container">
      <div :class="{'fixed-header':fixedHeader}"  v-if="!store.state.settings.windowWidth1260" >
        <navbar/>
      </div>
      <app-main />
    </div>
  </div>
</template>

<script>
  import {
    Navbar,
    Sidebar,
    AppMain,
    Topbar
  } from './components'
  import ResizeMixin from './mixin/ResizeHandler'
  import store from "@/store";

  export default {
    name: 'Layout',
    components: {
      Navbar,
      Sidebar,
      AppMain,
      Topbar
    },
    mixins: [ResizeMixin],
    computed: {
      store() {
        return store
      },
      sidebar() {
        return this.$store.state.app.sidebar
      },
      device() {
        return this.$store.state.app.device
      },
      fixedHeader() {
        return this.$store.state.settings.fixedHeader
      },
      classObj() {
        return {
          hideSidebar: !this.sidebar.opened,
          openSidebar: this.sidebar.opened,
          withoutAnimation: this.sidebar.withoutAnimation,
          mobile: this.device === 'mobile'
        }
      }
    },
    mounted() {
    },
    methods: {
      handleClickOutside() {
        this.$store.dispatch('app/closeSideBar', {
          withoutAnimation: false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    // height: 100%;
    height: $contentHeight;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }
</style>
