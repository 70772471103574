<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="服务费名称">
          <el-input v-model="inquire.name" placeholder="服务费名称" />
        </el-form-item>

        <el-form-item label="增加时间">
          <el-date-picker
            v-model="inquire.name"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="addition">新增服务费科目</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{ ( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="" label="服务费名称" min-width="100" />
            <el-table-column prop="" label="收费金额" min-width="160" />
            <el-table-column prop="" label="计量单位" min-width="100" />
            <el-table-column prop="" label="备注" min-width="100" />
            <el-table-column prop="" label="绑定医院数" min-width="100" />
            <el-table-column prop="" label="排序" min-width="100" />
            <el-table-column prop="" label="最近修改时间" min-width="160" />
            <el-table-column prop="" label="修改人" min-width="100" />
            <el-table-column prop="" label="操作" min-width="160">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加弹框 -->
    <el-dialog
      :title="dialogTitle + '服务费科目'"
      :visible.sync="dialogVisible"
      :before-close="closeDialog"
      width="650px"
      top="5vh"
    >
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="110px" :model="submitForm" :rules="submitRules">
          <el-form-item label="服务费名称" prop="name">
            <el-input v-model="submitForm.name" placeholder="服务费名称" />
          </el-form-item>
          <el-form-item label="收费金额" prop="name">
            <el-input v-model="submitForm.name" placeholder="收费金额" />
          </el-form-item>
          <el-form-item label="计量单位" prop="name">
            <el-select v-model="submitForm.name" placeholder="请选择" class="width-inherit">
              <el-option label="次" value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="name">
            <el-input v-model="submitForm.name" type="textarea" maxlength="150" placeholder="请输入内容" />
          </el-form-item>
          <el-form-item label="排序" prop="name">
            <el-input v-model="submitForm.name" placeholder="排序" />
          </el-form-item>
          <el-form-item label="绑定医院" prop="">
            <div>
              <el-button type="primary" size="small" @click="openHospitalList">选择医院</el-button>
            </div>
            <div>
              <el-table :data="hospitalTable" border style="width: 100%">
                <el-table-column prop="date" label="序号" width="60">
                  <template v-slot="scope">{{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="messengerName" label="医院编号" min-width="100" />
                <el-table-column prop="messengerName" label="医院名称" min-width="120" />
                <el-table-column prop="messengerName" label="所属大区" min-width="120" />
                <el-table-column prop="messengerName" label="所属商家" min-width="120" />
                <el-table-column prop="" label="操作" min-width="120" fixed="right">
                  <template slot-scope="scope">
                    <span class="operation-btn" @click="delHospital(scope.row, scope.$index)">删除</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-form-item>
          <el-form-item label="订单免额设置" prop="name">
            <div class="flex-box">
              <el-checkbox v-model="submitForm.name" />
              <div class="flex-box">
                订单金额达到
                <el-input v-model="submitForm.name" placeholder="0.00" class="width-110" style="margin:0 5px;" />
                元，减免此额外服务费
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 医院列表 -->
    <el-dialog
      title="选择医院"
      :visible.sync="dialogVisible_2"
      :before-close="() => dialogVisible_2 = false"
      width="75%"
    >
      <div class="Popout_content">
        <!-- <el-form :inline="true" class="demo-form-inline" size="medium"> -->
        <!-- <el-form-item label="配送地址">
            <div class="flex-box">
              <el-select v-model="inquire_2.name" placeholder="请选择" class="form-select">
                <el-option label="药房名称" value="药房名称"></el-option>
                <el-option label="联系人姓名" value="联系人姓名"></el-option>
                <el-option label="手机号" value="手机号"></el-option>
                <el-option label="电话" value="电话"></el-option>
                <el-option label="药房编号" value="药房编号"></el-option>
                <el-option label="所属商家" value="所属商家"></el-option>
                <el-option label="业务员姓名" value="业务员姓名"></el-option>
              </el-select>
              <el-input v-model="inquire_2.name" placeholder=""></el-input>
            </div>
          </el-form-item> -->

        <!-- <el-form-item>
            <el-button type="primary" size="small" @click="getHospitalList">查询</el-button>
          </el-form-item>
        </el-form> -->

        <el-table v-loading="loading_2" :data="hospitalTable" border height="400px" style="width: 100%" @selection-change="currentChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column prop="hospitalCode" label="医院编号" min-width="120" />
          <el-table-column prop="hospitalName" label="医院名称" min-width="160" />
          <el-table-column prop="regionName" label="所属大区" min-width="120" />
          <el-table-column prop="" label="所在地区" min-width="160" />
          <el-table-column prop="hospitalDetailedAddress" label="详细地址" min-width="120" />
          <el-table-column prop="merchantName" label="所属商家" min-width="120" />
        </el-table>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam_2.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam_2.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam_2.total"
            @size-change="handleSizeChange_2"
            @current-change="handleCurrentChange_2"
          />
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_2 = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { removeToken } from '@/utils/auth'
import { parse } from 'path-to-regexp'
export default {
  data() {
    return {
      input: '',

      // 查询参数
      inquire: {
        name: ''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      // 表格数据
      tableData: [],

      // 添加
      dialogTitle: '', // 添加弹窗标题
      dialogVisible: false, // 添加弹窗
      submitForm: {
        name: ''
      },
      submitRules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }]
      },
      isAdd: true,
      editRow: {},

      // ==================== 设置商家信息员 ====================
      dialogVisible_2: false, // 弹窗
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading_2: false,
      hospitalTable: [],
      selectList: []// table的选择项
    }
  },
  created() {
    // 获取产品列表
    this.getProductList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getProductList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getProductList()
    },

    // 获取产品列表
    getProductList() {
      return
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize
        // param: this.inquire
      }

      this.loading = true
      this.$http.requestPost({
        url: '/product/page',
        param: data,
        loading: false
      }).then(res => {
        this.loading = false
        this.tableData = res.data.list
        this.pageParam.total = res.data.total
      }).catch(err => {
        this.loading = false
        console.log(err)
      })
    },

    // 添加产品
    addition() {
      this.dialogTitle = '新增'
      this.dialogVisible = true
      this.isAdd = true
    },
    // 编辑
    handleEdit() {
      this.dialogTitle = '编辑'
      this.isAdd = false
      this.submitForm = {
        name: row.name
      }
      console.log(row)
      this.dialogVisible = true
      this.editRow = row
    },

    // 提交
    submitAdd() {

    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && (this.$refs.submitForm.resetFields())
      this.dialogVisible = false
      this.submitForm = {
        name: ''
      }
    },

    // 删除
    handleEdit(row) {
      this.$confirm('是否确定删除该服务费?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // this.$http.requestPost({url:'/product/standard/off/' + row.productId})
        //   .then(res => {
        //     this.getProductList();
        //     this.$message({
        //       type: 'success',
        //       message: '操作成功!'
        //     });

        //   })
      }).catch(() => {})
    },

    // ============ 选择医院 ============
    // 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1;
      this.pageParam_2.pageSize = size
      this.getHospitalList()
    },
    // 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page
      this.getHospitalList()
    },

    // 获取医院列表
    getHospitalList() {

    },

    // 打开医院列表
    openHospitalList() {
      this.getHospitalList()
      this.dialogVisible_2 = true
    },
    // table单选
    currentChange(news, old) {
      this.selectList = news
      console.log('news', news)
      console.log('old', old)
    },
    // 确定选择
    confirm() {
      this.dialogVisible_2 = false
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
