var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.singleList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "questionnaire" },
          [
            _c("div", { staticClass: "inner-title" }, [
              _c("div", { staticClass: "left" }, [
                _vm._v(_vm._s(item.content)),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(item.optionList, function (option, optionIndex) {
              return _c(
                "div",
                { key: optionIndex, attrs: { name: option.id } },
                [
                  _c(
                    "div",
                    {
                      class:
                        option.id == _vm.singleMap[item.id] ? "isCorrect" : "",
                    },
                    [
                      _vm._v(
                        _vm._s(option.content) + _vm._s(_vm.singleMap[item.id])
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.multipleList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "questionnaire" },
          [
            _c("div", { staticClass: "inner-title" }, [
              _c("div", { staticClass: "left" }, [
                _vm._v(_vm._s(item.content)),
              ]),
            ]),
            _vm._v(" "),
            _vm._l(item.optionList, function (option, optionIndex) {
              return _c(
                "div",
                { key: optionIndex, attrs: { name: option.content } },
                [
                  _c("div", { class: option.isCorrect ? "isCorrect" : "" }, [
                    _vm._v(_vm._s(option.content)),
                  ]),
                ]
              )
            }),
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm._l(_vm.answerList, function (item, index) {
        return _c("div", { key: index, staticClass: "questionnaire" }, [
          _c("div", { staticClass: "inner-title" }, [
            _c("div", { staticClass: "left" }, [_vm._v(_vm._s(item.content))]),
          ]),
          _vm._v(" "),
          _c("div", [_vm._v(_vm._s(item.answer))]),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }