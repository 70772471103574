<!-- 新增和编辑产品【电商品 & 经销产品共用】 -->
<template>
  <!-- 新增产品 -->
  <div id="main-scroll" class="main-box">
    <div class="back-box">
      <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
    </div>
    <!-- 头部导航 -->
    <div class="nav-bar bg_white">
      <a
        v-for="(item, index) in navList"
        :key="item.id"
        class="nav_item"
        :class="{ active: index === currentNavIndex }"
        href="javascript:void(0)"
        @click="skipTo(index)"
      >{{ item.name }}</a>
    </div>

    <!-- 基本信息 -->
    <div id="row_1" class="row_box bg_white">
      <div class="header">
        <span>基本信息</span>
      </div>
      <div class="content">
        <el-form ref="common" size="medium" label-width="120px" :model="common" :rules="commonRules">
          <el-form-item v-if="!isAdd" label="产品编号">
            <el-input v-model="common.productCode" placeholder="产品编号" readonly />
          </el-form-item>

          <el-form-item label="产品名称" prop="productName">
            <el-input v-model="common.productName" maxlength="30" placeholder="产品名称" />
          </el-form-item>

          <el-form-item label="通用名称" prop="productCommonName">
            <el-input v-model="common.productCommonName" maxlength="30" placeholder="通用名称" />
          </el-form-item>

          <el-form-item label="所有类别" prop="productCategoryId">
            <el-cascader v-model="common.productCategoryId" :options="productTypeList" :props="props" class="width-inherit" />
          </el-form-item>

          <el-form-item label="产品亮点" prop="productHighlight">
            <el-input v-model="common.productHighlight" maxlength="30" placeholder="产品亮点" />
          </el-form-item>

          <el-form-item v-if="!isAdd" label="总库存">
            <el-input v-model.number="common.productInventoryTotal" placeholder="总库存" disabled />
          </el-form-item>

          <!-- <el-form-item label="单位" prop="productUnitsId" class="form-item">
            <el-select v-model="common.productUnitsId" placeholder="请选择" class="form-select">
              <el-option v-for="item in productUnitList" :key="item.unitsId" :label="item.unitsName" :value="item.unitsId" />
            </el-select>
          </el-form-item> -->

          <el-form-item label="生产厂商" prop="productManufacturer">
            <el-input v-model="common.productManufacturer" maxlength="50" placeholder="生产厂商" />
          </el-form-item>
          <el-form-item label="收款商户" prop="wechatMerchantId">
            <el-select v-model="common.wechatMerchantId" placeholder="请选择" style="width: 100%;">
              <el-option v-for="item in weChatMerchantInfo" :key="item.id" :label="item.mchName" :value="String(item.id)" />
            </el-select>
          </el-form-item>
          <el-form-item label="排序" prop="productOrder">
            <el-input v-model.number="common.productOrder" maxlength="5" placeholder="排序" />
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 销售信息 -->
    <div id="row_2" class="row_box bg_white">
      <div class="header">
        <span>销售信息</span>
      </div>
      <div class="content">
        <el-form ref="sales" size="medium" label-width="140px" :model="sales" :rules="salesRules">
          <el-form-item class="required" label="添加规格">
            <div class="size_box flex-box box-m-b">
              <label>规格名称</label>
              <div class="flex-box">
                <div class="size-name">
                  <el-tag
                    v-for="(tag, index) in norms"
                    :key="tag.id"
                    class="box-m-r"
                    closable
                    effect="dark"
                    @click="amendTag('father', [index])"
                    @close="removeSizeName(index)"
                  >
                    {{ tag.normName }}
                  </el-tag>
                </div>
                <div class="add-size flex-box">
                  <el-input
                    v-model="normName"
                    placeholder="规格名称"
                    :disabled="norms.length > 0 && isGifts"
                    maxlength="10"
                    class="width-110 box-m-r"
                  />
                  <el-button type="primary" size="small" :disabled="norms.length > 0 && isGifts" @click="addSizeName">添加新规格</el-button>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in norms" :key="item.id" class="size_box">
              <div v-if="item.normName" class="flex-box box-m-b">
                <label class="font-normal">{{ item.normName }}:</label>
                <div>
                  <el-input v-model="item.attrName" placeholder="设置属性" maxlength="10" class="width-110 box-m-r" />
                  <el-button type="primary" size="small" :disabled="item.normValues.length > 0 && isGifts" @click="addAttr(index)">添加</el-button>
                </div>
              </div>
              <div class="flex-box specs">
                <div class="size-name ">
                  <div v-for="(tag, index2) in item.normValues" :key="tag.valueName + index2" class="size-item">
                    <el-tag closable size="small" class="tag" @click="amendTag('child', [index, index2])" @close="closeAttr(index, index2)">
                      {{ tag.valueName }}
                    </el-tag>
                    <div v-if="tag.isSell === 0" class="sell">
                      <el-button v-model="tag.isSell" :disabled="!tag.valueId" size="mini" type="primary" @click.stop="handleIsSell(tag)">
                        发售
                      </el-button>
                    </div>
                    <div v-else class="sell">
                      <el-button v-model="tag.isSell" :disabled="!tag.valueId" size="mini" type="danger" @click.stop="handleNoSell(tag)">
                        停售
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-for="(item, index) in sizeTypeList" :key="'one_' + index" class="size_box flex-box box-m-b">
              <label class="font-normal">
                <span v-for="(item2, index2) in item.normValues" :key="'one_' + index + index2">
                  {{ index2 === item.normValues.length - 1 ? item2 : item2 + '+' }}
                </span>
                :
              </label>
              <div class="flex-box">
                <el-input
                  v-model="item.inventorySalesPrice"
                  maxlength="7"
                  placeholder="销售价（选填）"
                  class="width-110 box-m-r"
                  @blur="
                    () =>
                      $validate.verifyMoney(item.inventorySalesPrice)
                        ? (item.inventorySalesPrice = Number(item.inventorySalesPrice))
                        : (item.inventorySalesPrice = '')
                  "
                  @change="salesEdit"
                />
                <el-input
                  v-model="item.inventoryCostPrice"
                  maxlength="7"
                  placeholder="成本价"
                  class="width-110 box-m-r"
                  @blur="
                    () =>
                      $validate.verifyMoney(item.inventoryCostPrice)
                        ? (item.inventoryCostPrice = Number(item.inventoryCostPrice))
                        : (item.inventoryCostPrice = '')
                  "
                  @change="salesEdit"
                />
                <el-input
                  v-model="item.inventoryMarketPrice"
                  maxlength="7"
                  placeholder="市场价"
                  class="width-110 box-m-r"
                  @blur="
                    () =>
                      $validate.verifyMoney(item.inventoryMarketPrice)
                        ? (item.inventoryMarketPrice = Number(item.inventoryMarketPrice))
                        : (item.inventoryMarketPrice = '')
                  "
                  @change="salesEdit"
                />
                <!-- <el-input v-model="item.inventoryTotal" @blur="() => $validate.verifyInt(item.inventoryTotal) ? '': item.inventoryTotal = ''" @change="salesEdit" maxlength="7" placeholder="库存" class="width-110" /> -->
                <span v-if="index === 0" class="color_light-grey box-m-l">价格保留两位小数</span>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="初始销量" prop="initialSales">
            <div class="flex-box">
              <el-input v-model.number="sales.initialSales" maxlength="10" placeholder="初始销量" class="width-110" @change="salesEdit" />
              <div class="font-size-12 box-m-l color_light-black">
                设置后，您的用户看到的销量=初始销量+最近30天真实销量
              </div>
            </div>
          </el-form-item>

          <template v-if="productType !== 'cross_border'">
            <!-- 是否为海淘产品 =============================================== -->
            <el-form-item label="是否为海淘产品" prop="uploadPrescription">
              <el-radio v-model="common.isOversea" :label="1">是</el-radio>
              <el-radio v-model="common.isOversea" :label="0">否</el-radio>
            </el-form-item>

            <el-form-item label="是否需要购物凭证" prop="uploadPrescription">
              <el-radio v-model="sales.uploadPrescription" :label="1" @change="salesEdit">是</el-radio>
              <el-radio v-model="sales.uploadPrescription" :label="0" @change="salesEdit">否</el-radio>
            </el-form-item>

            <el-form-item label="商家默认提成" prop="defaultMerchantBrokerage">
              <el-input
                v-model="sales.defaultMerchantBrokerage"
                placeholder="商家默认提成"
                class="width-140"
                @change="salesEdit"
                @blur="sales.defaultMerchantBrokerage = sales.defaultMerchantBrokerage.replace(/[^0-9.]/g, '')"
              />
              %
            </el-form-item>

            <el-form-item label="商家默认首单提成" prop="firstMerchantBrokerage">
              <el-input
                v-model="sales.firstMerchantBrokerage"
                placeholder="商家默认首单提成"
                class="width-140"
                @change="salesEdit"
                @blur="sales.firstMerchantBrokerage = sales.firstMerchantBrokerage.replace(/[^0-9.]/g, '')"
              />
              %
            </el-form-item>

            <el-form-item label="专员默认提成" prop="defaultAttacheBrokerage">
              <el-input
                v-model="sales.defaultAttacheBrokerage"
                placeholder="专员默认提成"
                class="width-140"
                @change="salesEdit"
                @blur="sales.defaultAttacheBrokerage = sales.defaultAttacheBrokerage.replace(/[^0-9.]/g, '')"
              />
              %
            </el-form-item>

            <el-form-item label="专员默认首单提成" prop="firstAttacheBrokerage">
              <el-input
                v-model="sales.firstAttacheBrokerage"
                placeholder="专员默认首单提成"
                class="width-140"
                @change="salesEdit"
                @blur="sales.firstAttacheBrokerage = sales.firstAttacheBrokerage.replace(/[^0-9.]/g, '')"
              />
              %
            </el-form-item>

            <el-form-item label="是否为第三方产品" prop="uploadPrescription">
              <el-radio v-model="common.isFranchisedProduct" :label="1">是</el-radio>
              <el-radio v-model="common.isFranchisedProduct" :label="0">否</el-radio>
            </el-form-item>
          </template>
        </el-form>
        <div v-if="common.isFranchisedProduct" class="add-btn">
          <el-button type="primary" size="small" @click="addProduct">选择</el-button>
          <span v-if="!productStandards.length && flag" style="color:red;">请选择需要加盟的产品</span>
        </div>
        <!-- 选择后的列表 -->
        <el-table
          v-if="common.isFranchisedProduct"
          v-loading="loading"
          :data="productStandards"
          style="width:50%;margin-left: 45px;margin:20px 0;"
          border
          highlight-current-row
        >
          <el-table-column prop="date" label="序号" width="60">
            <template slot-scope="scope">
              <div>{{ scope.$index + 1 }}</div>
            </template>
          </el-table-column>
          <el-table-column label="所属平台" min-width="60" show-overflow-tooltip>
            <template v-slot="scope">
              {{ findEnumName($api.platformOptions, scope.row.productBrand) }}
            </template>
          </el-table-column>
          <el-table-column prop="productCode" label="产品编号" min-width="160" show-overflow-tooltip />
          <el-table-column prop="productName" label="产品名称" min-width="100" show-overflow-tooltip />
        </el-table>

        <!-- 限购相关 -->
        <el-form v-if="!isGifts" ref="quota" size="medium" label-width="140px" :model="sales" :rules="salesRules">
          <el-form-item label="是否限购" prop="buyingQuota">
            <div class="buyingQuota_content">
              <div>
                <el-radio v-model="formData.buyingQuota" :label="1">是</el-radio>
                <el-radio v-model="formData.buyingQuota" :label="0">否</el-radio>
              </div>
              <div v-show="formData.buyingQuota" class="buyingQuota_input">
                <el-form-item label="" prop="buyingQuotaTotal">
                  <el-input v-model.number="formData.buyingQuotaTotal" class="width-110" placeholder="限购数量" maxlength="3" />
                </el-form-item>
                <span class="color_light-grey box-m-l" style="margin-right:15px">设置后，用户的购买数量不能超过限购数量</span>
                <el-form-item label="" prop="buyingQuotaTime">
                  <el-date-picker
                    v-model="formData.buyingQuotaTime"
                    type="datetime"
                    format="yyyy-MM-dd HH:mm"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    default-time="00:00:00"
                    placeholder="请选择限购开始时间"
                  />
                </el-form-item>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <!-- 开启限购-关联产品 -->
        <div v-show="formData.buyingQuota" class="quote_link_product">
          <p><el-button type="text" @click="openQuoteRelateDialog">设置关联产品</el-button></p>
          <TableList
            class="tableList"
            :is-fix-height="false"
            max-height="450px"
            :is-show-page="false"
            :table-header="quoteTableHead"
            :table-data="selectQuoteProducts"
          >
            <template #sellingPice="{data}">
              ￥{{ data.productPriceMin }} ~ ￥{{ data.productPriceMax }}
            </template>
            <template #productStatus="{data}">
              {{ data.productStatus == 'on_shelves' ? '在售' : '-' }}
            </template>
            <template v-slot:operation="scope">
              <el-button type="text" class="btn" @click="delSelectQuoteRelate(scope.data, scope.index)">删除</el-button>
            </template>
          </TableList>
        </div>
      </div>
    </div>

    <!-- 链接专区 -->
    <div v-if="productType === 'cross_border'" id="row_link" class="row_box bg_white link_box">
      <div class="header">
        <span>链接专区</span>
      </div>
      <div class="content link_content">
        <el-form ref="linkForm" size="medium" label-width="140px" :model="linkFormData" :rules="linkFormRules">
          <el-form-item class="link_item" label="产品链接" required>
            <el-form-item label="设置链接：" label-width="100px" prop="crossBorderUrl">
              <el-input v-model="linkFormData.crossBorderUrl" class="item_input" placeholder="请输入产品链接" clearable />
            </el-form-item>
          </el-form-item>
          <el-form-item label="优惠券">
            <el-button type="primary" @click="addCouponLink">添加优惠券</el-button>
          </el-form-item>
          <el-form-item v-for="(item, index) in linkFormData.couponData" :key="index" class="link_item">
            <el-form-item
              class="linkItem_coupon"
              label="优惠券ID："
              label-width="100px"
              :prop="`couponData.${index}.couponLinkId`"
              :rules="linkFormRules.couponLinkIdRule"
            >
              <el-input v-model="item.couponLinkId" class="item_input" placeholder="请输入优惠券ID" clearable @focus="focusCouponLink($event, index)" />
              <el-button @click="deleteCouponLink(index)">删除</el-button>
            </el-form-item>
            <el-form-item
              class="linkItem_uploadImg"
              label="链接图片："
              label-width="100px"
              :prop="`couponData.${index}.couponImg`"
              :rules="linkFormRules.couponImgRule"
            >
              <d-upload
                :class="`couponLinkIdUpload${index}`"
                upload-type="picture-card"
                :limit="1"
                :file-list="item.couponImg"
                accept=".gif,.jpeg,.jpg,.png"
                @uploadSuccess="uploadSuccessFun(arguments, 'upload_linkImg', index)"
                @removeFile="removeFileFun(arguments, 'remove_linkImg', index)"
              >
                <div class="tip-box">
                  <i class="el-icon-plus"></i>
                  <span slot="tip" class="tip">建议750px*357px</span>
                </div>
              </d-upload>
            </el-form-item>
          </el-form-item>
          <el-form-item class="link_item" label="客服二维码" prop="serverQrcodeImg">
            <d-upload
              class="server_uploadImg"
              upload-type="picture-card"
              :limit="1"
              :file-list="linkFormData.serverQrcodeImg"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_serverQrcode')"
              @removeFile="removeFileFun(arguments, 'remove_serverQrcode')"
            >
              <div class="tip-box">
                <i class="el-icon-plus"></i>
                <span slot="tip" class="tip">建议330px*440px</span>
              </div>
            </d-upload>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 图文描述 -->
    <div id="row_3" class="row_box bg_white">
      <div class="header">
        <span>图文描述</span>
      </div>
      <div class="content">
        <el-form ref="graphic" size="medium" label-width="140px" :model="graphic" :rules="graphicRules">
          <el-form-item label="产品图片(主图)" prop="primaryImages">
            <d-upload
              class="graphicImg_upload"
              upload-type="picture-card"
              :file-list="primaryImagesList"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_mainPicture')"
              @removeFile="removeFileFun(arguments, 'remove_mainPicture')"
            >
              <div class="tip-box">
                <i class="el-icon-plus"></i>
                <span slot="tip" class="tip">建议360px*360px</span>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="产品图片(次图)" prop="images">
            <d-upload
              upload-type="picture-card"
              :file-list="imagesList"
              :limit="9"
              multiple
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_vicePicture')"
              @removeFile="removeFileFun(arguments, 'remove_vicePicture')"
            >
              <div class="tip-box">
                <i class="el-icon-plus"></i>
                <span slot="tip" class="tip">建议360px*360px</span>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="主图视频(默认)">
            <d-upload
              :size="100"
              :file-list="primaryVideosList"
              :auto-upload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @handleChange="handleChange_2"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_defaultVideo')"
              @removeFile="removeFileFun(arguments, 'remove_defaultVideo')"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag2" :percentage="percentage2" />
          </el-form-item>

          <el-form-item v-if="primaryVideosList.length > 0" label="视频封面" prop="videoImage1">
            <d-upload
              upload-type="picture-card"
              :file-list="graphic.videoImage1"
              :limit="1"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_defaultVideoCover')"
              @removeFile="removeFileFun(arguments, 'remove_defaultVideoCover')"
            >
              <div class="tip-box">
                <i class="el-icon-plus"></i>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="主图视频(医院)">
            <el-button size="small" type="primary" @click="openVideos">视频上传</el-button>
            <d-upload
              :size="100"
              :file-list="hospitalFileList"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_hospitalVideo')"
              @removeFile="removeFileFun(arguments, 'remove_hospitalVideo')"
            />
          </el-form-item>

          <el-form-item label="详情顶部视频">
            <d-upload
              :size="500"
              :file-list="graphic.topVideo"
              :auto-upload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @handleChange="handleChange_5"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_topVideo')"
              @removeFile="removeFileFun(arguments, 'remove_topVideo')"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag5" :percentage="percentage5" />
          </el-form-item>
          <el-form-item v-if="graphic.topVideo.length > 0" label="视频封面" prop="videoImage2">
            <d-upload
              upload-type="picture-card"
              :file-list="graphic.videoImage2"
              :limit="1"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_topVideoCover')"
              @removeFile="removeFileFun(arguments, 'remove_topVideoCover')"
            >
              <div class="tip-box">
                <i class="el-icon-plus"></i>
              </div>
            </d-upload>
          </el-form-item>
          <el-form-item label="详情底部视频">
            <d-upload
              :size="500"
              :file-list="graphic.bottomVideo"
              :auto-upload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @handleChange="handleChange_6"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_bottomVideo')"
              @removeFile="removeFileFun(arguments, 'remove_bottomVideo')"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag6" :percentage="percentage6" />
          </el-form-item>
          <el-form-item v-if="graphic.bottomVideo.length > 0" label="视频封面" prop="videoImage3">
            <d-upload
              upload-type="picture-card"
              :file-list="graphic.videoImage3"
              :limit="1"
              accept=".gif,.jpeg,.jpg,.png"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_bottomVideoCover')"
              @removeFile="removeFileFun(arguments, 'remove_bottomVideoCover')"
            >
              <div class="tip-box">
                <i class="el-icon-plus"></i>
              </div>
            </d-upload>
          </el-form-item>

          <el-form-item label="产品详情" prop="detail">
            <div class="editor">
              <mavon-editor
                ref="md"
                v-model="editor"
                :toolbars="toolbars"
                @imgAdd="handleEditorImgAdd"
                @imgDel="handleEditorImgDel"
                @change="editorChange"
              />
            </div>
          </el-form-item>

          <el-form-item label="产品参数">
            <div class="form-list flex-box">
              <div v-for="(item, index) in graphic.param" :key="index">
                <div class="form-item flex-box">
                  <label>
                    <el-input v-model="item.paramKey" maxlength="10" placeholder="参数名称" />
                  </label>
                  <div class="form-item-content">
                    <el-input v-model="item.paramValue" maxlength="10" placeholder="参数值" />
                  </div>
                  <div class="close_ben" @click="delectParam(index)">
                    <i class="el-icon-circle-close"></i>
                  </div>
                </div>
              </div>

              <div class="form-item flex-box">
                <div class="form-item-content">
                  <el-button type="primary" size="medium" @click="addParam">添加参数行</el-button>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 搜索标签 -->
    <div id="row_4" class="row_box bg_white">
      <div class="header">
        <span>搜索标签</span>
      </div>
      <div class="content">
        <div class="flex-box">
          <el-tag v-for="(tag, index) in formData.tags" :key="tag.tagId ? tag.tagId : tag.tagName" closable @close="closeLabel(index)">
            {{ tag.tagName }}
          </el-tag>
          <el-button type="primary" size="small" @click="addLabel">添加</el-button>
        </div>
      </div>
    </div>

    <!-- 服务保障 -->
    <div id="row_5" class="row_box bg_white">
      <div class="header">
        <span>服务保障</span>
      </div>
      <div class="content">
        <div class="flex-box">
          <el-tag
            v-for="(tag, index) in formData.services"
            :key="tag.serviceId ? tag.serviceId : tag.serviceName"
            closable
            @close="closeServe(index)"
          >
            {{ tag.serviceName }}
          </el-tag>
          <el-button type="primary" size="small" @click="addServe">添加</el-button>
        </div>
      </div>
    </div>

    <!-- 发布设置 -->
    <div id="row_6" class="row_box bg_white">
      <div class="header">
        <span>发布设置</span>
      </div>
      <div class="content">
        <el-form size="medium" label-width="80px">
          <el-form-item label="上架">
            <el-radio v-model="formData.productStatus" label="on_shelves">上架出售</el-radio>
            <el-radio v-model="formData.productStatus" label="for_sale">放入待出售</el-radio>
            <el-radio v-if="!isAdd" v-model="formData.productStatus" label="off_shelves">下架</el-radio>
          </el-form-item>
        </el-form>
        <div class="flex-box submit-btn bg_white">
          <el-button type="primary" @click="submitData">确 定</el-button>
          <el-button @click="$router.go(-1)">取 消</el-button>
        </div>
      </div>
    </div>

    <!-- 添加标签弹框 -->
    <el-dialog :title="'添加' + labelTitle" :visible.sync="dialogVisible" width="400px">
      <div class="Popout_content">
        <el-form ref="submitForm" size="medium" label-width="80px" :model="submitForm" :rules="submitRules">
          <el-form-item :label="labelTitle" prop="labels">
            <el-input v-model="submitForm.labels" maxlength="12" :placeholder="labelTitle" />
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 添加医院视频弹框 -->
    <el-dialog title="上传视频" :visible.sync="dialogVisible_1" width="500px" @close="closeHospitalVideoDialog">
      <div class="Popout_content">
        <el-form ref="submitVideosRef" size="medium" label-width="80px" :model="submitVideos" :rules="submitVideosRules">
          <el-form-item label="选择视频" prop="videos">
            <d-upload
              :size="100"
              :file-list="hospitalVideosList"
              :auto-upload="autoUpload"
              :action="actionUrl"
              accept=".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v"
              @handleChange="handleChange_4"
              @uploadSuccess="uploadSuccessFun(arguments, 'upload_hospitalVideoDialog')"
              @removeFile="removeFileFun(arguments, 'remove_hospitalVideoDialog')"
            >
              <div class="tip-box">
                <el-button size="small" type="primary">视频上传</el-button>
              </div>
            </d-upload>
            <el-progress v-if="progressFlag4" :percentage="percentage4" />
          </el-form-item>
          <el-form-item label="绑定医院" prop="hospitalId">
            <el-select v-model="submitVideos.hospitalId" filterable placeholder="请选择" class="width-inherit">
              <el-option v-for="item in hospitalList" :key="item.hospitalId" :label="item.hospitalName" :value="item.hospitalId" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeHospitalVideoDialog">取 消</el-button>
        <el-button type="primary" @click="videosConfirm">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加产品弹框 -->
    <el-dialog v-if="dialogShow" title="产品列表" :before-close="closeDialog_1" :visible.sync="dialogShow" top="12vh" width="50%">
      <div class="Popout_content">
        <el-form ref="proDialog" :inline="true" class="demo-form-inline" size="medium" label-width="80px">
          <el-form-item label="所属平台">
            <el-select v-model="inquire.productBrand" placeholder="请选择" class="form-select" @change="() => getProductList()">
              <el-option v-for="item in $api.platformOptions" :key="item.value" :label="item.name" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="搜索产品">
            <el-input v-model.trim="inquire.productName" placeholder="请输入产品名称" @keyup.enter.native="searchList" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="searchList">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="msgTabel"
          v-loading="loading_1"
          class="single-select-table"
          :data="productList"
          border
          height="400px"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">
              {{ (pageParam_1.pageNum - 1) * pageParam_1.pageSize + (scope.$index + 1) }}
            </template>
          </el-table-column>
          <el-table-column label="所属平台" min-width="60" show-overflow-tooltip>
            <template v-slot="scope">
              {{ findEnumName($api.platformOptions, scope.row.productBrand) }}
            </template>
          </el-table-column>
          <el-table-column label="产品编号" min-width="160" prop="productCode" show-overflow-tooltip />
          <el-table-column label="产品名称" min-width="100" prop="productName" show-overflow-tooltip />
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="pageParam_1.pageNum"
            :page-size="pageParam_1.pageSize"
            :page-sizes="$api.pageSizes"
            :total="pageParam_1.total"
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange_1"
            @current-change="handleCurrentChange_1"
          />
        </div>
        <div class="btn-box">
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog_1">取 消</el-button>
            <el-button type="primary" @click="submitAdd_1">确 定</el-button>
          </span>
        </div>
        <div style="padding-bottom: 10px;"></div>
      </div>
    </el-dialog>

    <!-- 添加限购关联产品弹窗 -->
    <quotaChooseProduct ref="quotaChooseProduct" :link-product-ids="selectQuoteProducts" @confirmSelect="confirmSelectQuoteRelate" />
  </div>
</template>

<script>
import dUpload from '@/components/d-upload/index'
import axios from 'axios'
import { getSessionStorage } from '@/utils/auth'

import quotaChooseProduct from './quotaChooseProduct.vue'
function getSignature() {
  return axios.post('/common/file/upload/getQCloudSignature', JSON.stringify({ action: 'GetUgcUploadSign' })).then(function(response) {
    return response.data.data
  })
}
export default {
  components: {
    dUpload,
    quotaChooseProduct
  },
  props: {
    // 是不是经销品
    externalFlag: {
      type: Boolean,
      default: false
    },
    // 产品类型
    productType: {
      type: String,
      default: '' // cross_border:跨境产品
    }
  },
  data() {
    return {
      flag: false,
      loading: false,
      productStandards: [], // 确认选择加盟的产品
      loading_1: false,
      dialogShow: false, // 经销产品弹框
      pageParam_1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      multipleSelection: [],
      productList: [], // 产品列表
      inquire: {
        productBrand: 2,
        productName: '' // 经销产品查询条件
      },
      actionUrl: '/common/file/upload/video',

      navbarHeight: 0,
      currentNavIndex: 0, // 记录导航栏下标
      scrollHeight: [], // 存放navbar的滚动距离
      userAction: 'scroll',
      // 导航栏
      navList: [
        { name: '基本信息', id: 'row_1', active: true },
        { name: '销售信息', id: 'row_2', active: false },
        { name: '图文描述', id: 'row_3', active: false },
        { name: '搜索标签', id: 'row_4', active: false },
        { name: '服务保障', id: 'row_5', active: false },
        { name: '发布设置', id: 'row_6', active: false }
      ],
      mainEl: null,
      timer: null,

      productClassify: '',
      // form 数据
      formData: {
        // 主键id
        productId: undefined,
        // 产品归类 - 电商
        productClassify: '',
        // 产品状态 - 上架
        productStatus: 'on_shelves',
        // 基础信息
        common: {},
        // 销售信息
        sales: {},
        // 图文描述
        graphic: {},
        // 搜索标签
        tags: [
          // { tagName:'', tagId: ''}
        ],
        // 服务保障
        services: [
          // { serviceName:'', serviceId: ''}
        ],

        // ----------新增字段 限购
        buyingQuota: 0, // 是否限购0:否 1:是，默认不限购
        buyingQuotaTotal: '', // 限购数量
        linkProductIds: [], // 限购关联产品的id
        buyingQuotaTime: '', // 限购开始时间
        crossBorderUrl: '', // 跨境产品产品链接
        productCouponLinkList: [] // 跨境产品优惠链接数据
      },
      // 是否添加
      isAdd: false,

      // ================== 基础信息 ==================
      // 产品类别级联选项
      props: {
        // 配置
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        emitPath: false
      },
      // 产品类别级联选项数据
      productTypeList: [],
      // 产品单位列表
      productUnitList: [],

      // 基础信息表单
      common: {
        productCode: '', // 编辑只读
        productName: '',
        productCommonName: '',
        productCategoryId: '',
        productHighlight: '',
        productInventoryTotal: '', // 编辑只读
        productUnitsId: '',
        productManufacturer: '',
        productOrder: '',
        isOversea: 0, // 是否为海淘产品
        isFranchisedProduct: 0, // 是否为加盟
        franchisedProductId: '', // 选择加盟的产品id
        franchisedProductCode: '', // 选择经销产品的编号
        franchisedProductName: '', // 选择经销产品的名字
        wechatMerchantId: '' // 收款商户
      },
      commonRules: {
        productName: [{ required: true, message: '请输入产品名称', trigger: 'blur' }],
        productCommonName: [{ required: true, message: '请输入通用名称', trigger: 'blur' }],
        productCategoryId: [{ required: true, message: '请选择所有类别', trigger: 'change' }],
        productHighlight: [{ required: true, message: '请输入产品亮点', trigger: 'blur' }],
        productUnitsId: [{ required: true, message: '请选择单位', trigger: 'change' }],
        productManufacturer: [{ required: true, message: '请输入生产厂商', trigger: 'blur' }],
        productOrder: [
          { required: true, message: '请输入排序', trigger: 'blur' },
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ],
        wechatMerchantId: [{ required: true, message: '请选择收款商户', trigger: 'change' }]
      },

      // ================== 销售信息 ==================
      sales: {
        initialSales: 0,
        defaultMerchantBrokerage: '',
        firstMerchantBrokerage: '',
        defaultAttacheBrokerage: '',
        firstAttacheBrokerage: '',
        uploadPrescription: 0,
        norms: []
      },
      salesRules: {
        initialSales: [
          { type: 'number', message: '请输入整数', trigger: 'blur' },
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'change' }
        ],
        defaultMerchantBrokerage: [
          { required: true, message: '请输入商家默认提成', trigger: 'blur' },
          {
            pattern: /^0$|^100$|^0\.\d[1-9]$|^0\.[1-9]\d$|^[1-9](\.\d{1,2})?$|^[1-9]\d(\.\d{1,2})?$/,
            message: '请输入正确的格式',
            trigger: 'change'
          }
        ],
        firstMerchantBrokerage: [
          { required: true, message: '请输入商家默认首单提成', trigger: 'blur' },
          {
            pattern: /^0$|^100$|^0\.\d[1-9]$|^0\.[1-9]\d$|^[1-9](\.\d{1,2})?$|^[1-9]\d(\.\d{1,2})?$/,
            message: '请输入正确的格式',
            trigger: 'change'
          }
        ],
        defaultAttacheBrokerage: [
          { required: true, message: '请输入专员默认提成', trigger: 'blur' },
          {
            pattern: /^0$|^100$|^0\.\d[1-9]$|^0\.[1-9]\d$|^[1-9](\.\d{1,2})?$|^[1-9]\d(\.\d{1,2})?$/,
            message: '请输入正确的格式',
            trigger: 'change'
          }
        ],
        firstAttacheBrokerage: [
          { required: true, message: '请输入专员默认首单提成', trigger: 'blur' },
          {
            pattern: /^0$|^100$|^0\.\d[1-9]$|^0\.[1-9]\d$|^[1-9](\.\d{1,2})?$|^[1-9]\d(\.\d{1,2})?$/,
            message: '请输入正确的格式',
            trigger: 'change'
          }
        ],
        uploadPrescription: [{ required: true, message: '请选择', trigger: 'blur' }],

        norms: [{ required: true, message: '请添加规格', trigger: 'blur' }],

        buyingQuota: [
          {
            validator: (rule, value, callback) => {
              if (!this.formData.buyingQuota && this.formData.buyingQuota != 0) {
                return callback(new Error('请选择是否限购'))
              }
              callback()
            },
            trigger: 'blur',
            required: true
          }
        ],
        buyingQuotaTotal: [
          {
            validator: (rule, value, callback) => {
              const reg = /^[0-9]+.?[0-9]*/
              if (this.formData.buyingQuota) {
                if (!reg.test(this.formData.buyingQuotaTotal)) {
                  return callback(new Error('请输入一个数值类型的值'))
                }
                if (this.formData.buyingQuotaTotal <= 0 || this.formData.buyingQuotaTotal > 100) {
                  return callback(new Error('请输入大于0,小于100的整数'))
                }
              }
              callback()
            },
            trigger: ['blur', 'change'],
            required: true
          }
        ],
        buyingQuotaTime: [
          {
            validator: (rule, value, callback) => {
              if (this.formData.buyingQuota && !this.formData.buyingQuotaTime) {
                return callback(new Error('请选择限购开始时间'))
              }
              callback()
            },
            trigger: 'change',
            required: true
          }
        ]
      },
      norms: [
        // {
        //     normName: '',     // 规格名称
        //     normValues: [],   // 规格属性列表
        //     attrName:'',      // 规格添加属性名称字段
        //     id: $public.guid()
        // }
      ], // 规格列表
      sizeTypeList: [], // 类型列表
      normName: '', // 规格名称
      sizeTypeCopy: [], // 副本
      isEditSales: false, // 判断是否修改

      // -----------------------------------------------链接专区 start-----------------------------------------------
      currentCouponLinkIndex: 0, // 当前聚焦的优惠链接输入框下标
      linkFormRules: {
        crossBorderUrl: [
          { required: true, message: '请输入产品链接', trigger: ['blur', 'change'] },
          {
            pattern: /(https?|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/,
            message: '请输入正确的链接格式',
            trigger: ['blur', 'change']
          }
        ],
        couponLinkIdRule: [{ required: true, message: '请输入优惠券ID', trigger: ['blur', 'change'] }],
        couponImgRule: [
          {
            required: true,
            trigger: ['change', 'blur'],
            validator: (rule, value, callback) => {
              if (!value.length) {
                return callback(new Error('请添加链接图片'))
              }
              callback()
            }
          }
        ],
        serverQrcodeImg: [
          {
            required: true,
            trigger: ['change', 'blur'],
            validator: (rule, value, callback) => {
              if (!value.length) {
                return callback(new Error('请添加客服二维码'))
              }
              callback()
            }
          }
        ]
      },
      linkFormData: {
        crossBorderUrl: '', // 产品链接
        couponData: [
          // { couponLinkId: '', couponImg: [] }
        ],
        serverQrcodeImg: [] // 客服二维码
      },
      // -----------------------------------------------链接专区 end-------------------------------------------------

      // ---------------------------限购数据相关 star----------------------
      quoteTableHead: [
        // 限购表格
        { label: '产品编号', prop: 'productCode' },
        { label: '产品名称', prop: 'productName' },
        { label: '所属类别', prop: 'productCategoryName' },
        { label: '销售价', type: 'slot', slotName: 'sellingPice' },
        { label: '状态', type: 'slot', slotName: 'productStatus' },
        { label: '操作', type: 'slot', slotName: 'operation', width: '120px' }
      ],
      selectQuoteProducts: [], // 已选中的限购关联产品
      // ---------------------------限购数据相关 end----------------------

      // ================== 图片描述======================
      graphic: {
        images: [],
        primaryImages: [],
        videos: [],
        primaryVideos: [],
        detail: '',
        param: [
          // { paramKey: '', paramValue: '' },
        ],
        topVideo: [], // 顶部视频
        bottomVideo: [], // 底部视频
        videoImage1: [],
        videoImage2: [],
        videoImage3: []
      },
      productParams: [],
      graphicRules: {
        images: [{ required: true, message: '请选择产品图片（次图）', trigger: 'blur' }],
        primaryImages: [{ required: true, message: '请选择产品图片（主图）', trigger: 'blur' }],
        videoImage1: [{ required: true, message: '请为视频添加封面', trigger: 'blur' }],
        videoImage2: [{ required: true, message: '请为视频添加封面', trigger: 'blur' }],
        videoImage3: [{ required: true, message: '请为视频添加封面', trigger: 'blur' }],
        images: [{ required: true, message: '请选择产品图片', trigger: 'blur' }],
        detail: [{ required: true, message: '请输入产品详情', trigger: 'blur' }]
      },
      toolbars: {
        // 编辑器配置
        bold: true, // 粗体
        italic: true, // 斜体
        header: true, // 标题
        underline: true, // 下划线
        strikethrough: true, // 中划线
        mark: true, // 标记
        superscript: true, // 上角标
        subscript: true, // 下角标
        quote: true, // 引用
        ol: true, // 有序列表
        ul: true, // 无序列表
        link: true, // 链接
        imagelink: true, // 图片链接
        code: true, // code
        table: true, // 表格
        fullscreen: true, // 全屏编辑
        readmodel: true, // 沉浸式阅读
        htmlcode: true, // 展示html源码
        help: true, // 帮助
        /* 1.3.5 */
        undo: true, // 上一步
        redo: true, // 下一步
        trash: true, // 清空
        save: false, // 保存（触发events中的save事件）
        /* 1.4.2 */
        navigation: true, // 导航目录
        /* 2.1.8 */
        alignleft: true, // 左对齐
        aligncenter: true, // 居中
        alignright: true, // 右对齐
        /* 2.2.1 */
        subfield: true, // 单双栏模式
        preview: true // 预览
      },
      editor: '',
      primaryImagesList: [], // 主图返现
      imagesList: [], // 次图返现
      primaryVideosList: [], // 默认视屏

      // 添加视频弹窗
      dialogVisible_1: false,
      submitVideos: {
        videos: [],
        hospitalId: ''
      },
      submitVideosRules: {
        videos: [{ required: true, message: '请选择视频', trigger: 'change' }],
        hospitalId: [{ required: true, message: '请选择医院', trigger: 'change' }]
      },
      hospitalList: [], // 医院列表
      hospitalFileList: [], // 医院视屏文件返显列表
      hospitalVideosList: [], // 医院弹窗视屏返显列表

      // ====================== 搜索标签 ==================
      dialogVisible: false, // 添加标签弹窗
      labelTitle: '搜索标签',
      addType: true, // 添加类型
      submitForm: {
        labels: ''
      },
      submitRules: {
        labels: [{ required: true, message: '请输入', trigger: 'blur' }]
      },

      // ================== 服务保障 ==================

      // ================== 发布设置 ==================
      autoUpload: false, // 是否自动上传
      percentage2: 0,
      progressFlag2: false,
      uploader2: '',

      percentage4: 0,
      progressFlag4: false,
      uploader4: '',

      percentage5: 0,
      progressFlag5: false,
      uploader5: '',

      percentage6: 0,
      progressFlag6: false,
      uploader6: '',
      weChatMerchantInfo: [],

      isGifts: false // 是否为赠品
    }
  },
  watch: {
    'graphic.primaryImages': (old, news) => {
      const upEl = document.querySelector('.graphicImg_upload .el-upload')
      if (old.length > 0) {
        upEl.style.display = 'none'
      } else {
        upEl.style.display = 'block'
      }
    },
    'linkFormData.couponData': {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal.length) {
          this.$nextTick(() => {
            let element = ''
            newVal.forEach((item, index) => {
              element = document.querySelector(`.couponLinkIdUpload${index} .el-upload`)
              if (item.couponImg.length >= 1) {
                element.style.display = 'none'
              } else {
                element.style.display = 'block'
              }
            })
          })
        }
      }
    }
  },
  created() {
    this.isAdd = typeof this.$route.query.id === 'undefined'
    this.isGifts = !!this.$route.query.isGifts

    if (this.externalFlag) { // 经销品
      this.productClassify = 'external'
    } else if (this.productType === 'cross_border') { // 跨境产品
      this.productClassify = 'cross_border'
    } else {
      this.productClassify = 'electronic' // 电商品
    }
    this.formData.productClassify = this.productClassify

    if (!this.isAdd) {
      this.formData.productId = this.$route.query.id
      this.getDetailInfo()
    }
    this.flag = true
    this.getProductUnitList()
    this.getProductTypeList()
    this.getHospitalList()
    // 腾讯视频web上传
    this.tcVod = new this.$TcVod({
      getSignature: getSignature
    })
    this.clearUploadStatus()
    this.weChatMerchantInfo = getSessionStorage('weChatMerchant')
  },
  mounted() {
    this.mainEl = document.getElementsByClassName('app-main')[0]
    this.mainEl.addEventListener('scroll', this.changeScroll)
    this.skipTo(0)
    if (this.productType === 'cross_border') {
      this.navList.splice(2, 0, { name: '链接专区', id: 'row_link' })
    }
  },
  beforeDestroy() {
    this.mainEl.removeEventListener('scroll', this.changeScroll)
  },
  methods: {
    // nav bar 跳转
    skipTo(index, idName = '') {
      this.userAction = 'click'
      if (!idName) {
        this.currentNavIndex = index
        // 获取选择tabs要跳转元素的id,设置滚动条偏移到该元素的位置，类似锚链接
        const id = this.navList[index].id
        document.querySelector('#' + id).scrollIntoView(true)
      } else {
        this.currentNavIndex = this.navList.findIndex(item => item.id === idName)
        document.querySelector('#' + idName).scrollIntoView(true)
      }
    },
    // 函数防抖
    debounce(fn, interval = 200) {
      if (this.timer) clearTimeout(this.timer)
      const args = arguments
      this.timer = setTimeout(() => {
        fn.apply(this, args)
      }, interval)
    },
    // 滚动条改变
    changeScroll() {
      if (this.userAction !== 'click') {
        this.debounce(() => {
          const scrollHeight = []
          let navIndex = 0
          const scrollTop = this.mainEl.scrollTop
          const navbarHeight = document.getElementsByClassName('nav-bar')[0].offsetHeight + 10 + 68 // 10:间距 68:row_1距离顶部的偏移量

          this.navList.map(item => {
            scrollHeight.push(document.getElementById(item.id).offsetTop)
          })
          if (scrollTop > scrollHeight[scrollHeight.length - 1]) {
            this.currentNavIndex = scrollHeight.length - 1
          } else {
            scrollHeight.map((item, index) => {
              if (scrollTop >= item - navbarHeight) {
                navIndex = index
              }
            })
          }
          this.currentNavIndex = navIndex
        }, 50)
      } else {
        this.userAction = 'scroll'
      }
    },
    // 处理枚举值
    findEnumName(enumArray, targetValue) {
      const enumFound = enumArray.find(item => item.value === targetValue)
      return enumFound ? enumFound.name : ''
    },
    // 选择经销产品
    addProduct() {
      this.getProductList()
      this.dialogShow = true
    },
    // 经销产品单选
    handleSelectionChange(val) {
      console.log('---', val)
      // 单选
      if (val.length > 1) {
        this.$refs.msgTabel.clearSelection()
        this.$refs.msgTabel.toggleRowSelection(val.pop())
      }
      this.multipleSelection = val
    },
    // 提交添加已经销产品
    submitAdd_1() {
      if (!this.multipleSelection.length) {
        return this.$message.warning('请选择产品')
      }
      this.productStandards = this.multipleSelection
      this.closeDialog_1()
    },
    // 查询产品
    searchList() {
      this.pageParam_1.pageNum = 1
      this.getProductList()
    },
    // 获取产品列表
    getProductList() {
      // 基础参数对象
      const baseParam = {
        pageNum: this.pageParam_1.pageNum,
        pageSize: this.pageParam_1.pageSize,
        param: {
          productStatus: 'on_shelves',
          productName: this.inquire.productName,
          productCategoryId: '',
          productPriceMin: '',
          productPriceMax: '',
          productSalesMin: '',
          productSalesMax: '',
          productBrand: this.inquire.productBrand,
          productClassify: this.productClassify
        }
      }

      this.loading_1 = true
      this.$http
        .requestPost({
          url: '/product/getFranchisedProductList',
          param: baseParam,
          loading: false
        })
        .then(res => {
          this.loading_1 = false
          this.productList = res.data.list
          this.pageParam_1.total = res.data.total
        })
        .catch(err => {
          this.loading_1 = false
          console.log(err)
        })
    },
    // 产品列表页容量改变
    handleSizeChange_1(size) {
      this.pageParam_1.pageNum = 1
      this.pageParam_1.pageSize = size
      this.getProductList()
    },
    // 产品列表页码改变
    handleCurrentChange_1(page) {
      this.pageParam_1.pageNum = page
      this.getProductList()
    },
    // 关闭产品列表弹框
    closeDialog_1() {
      this.dialogShow = false
      this.inquire = {
        productBrand: 2,
        productName: ''
      }
      this.multipleSelection = []
    },

    // 销售信息改变
    salesEdit(d = {}) {
      this.isEditSales = true
    },

    // 获取产品单位列表
    getProductUnitList() {
      this.$api.productUnitList().then(res => {
        this.productUnitList = res.data
      })
    },
    // 获取产品类别列表
    getProductTypeList() {
      this.$api.productTypeList().then(res => {
        this.productTypeList = res.data
      })
    },
    // 获取医院列表
    getHospitalList() {
      this.$http.requestPost({ url: '/hospital/medical/list', loading: false }).then(res => {
        this.hospitalList = res.data
      })
    },

    // 获取页面详情信息
    getDetailInfo() {
      let data = ''
      this.$http.requestGet({ url: '/product/get/' + this.formData.productId }).then(res => {
        data = res.data
        this.formData = {
          productId: data.productId,
          productClassify: this.productClassify,
          productStatus: data.productStatus,
          common: data.common, // 基础信息
          sales: data.sales, // 销售信息
          graphic: data.graphic, // 图文描述
          tags: data.tags, // 搜索标签
          services: data.services, // 符文保障

          buyingQuota: data.buyingQuota || 0, // 是否限购0:否 1:是，默认不限购
          buyingQuotaTotal: data.buyingQuotaTotal || '', // 限购数量
          // linkProductIds: data.linkProducts || [], // 限购关联产品
          buyingQuotaTime: data.buyingQuotaTime || '' // 限购开始时间
        }
        this.selectQuoteProducts = data.linkProducts || []

        // 处理链接专区数据
        this.linkFormData.crossBorderUrl = (data.common && data.common.crossBorderUrl) || ''
        this.linkFormData.couponData = []
        if (data.productCouponLinkList && data.productCouponLinkList.length) {
          data.productCouponLinkList.forEach(item => {
            this.linkFormData.couponData.push({
              couponLinkId: item.linkCouponId,
              couponImg: [{ url: item.linkImgUrl }]
            })
          })
        }
        this.linkFormData.serverQrcodeImg = [{ url: (data.common && data.common.customerServiceUrl) || '' }]
        // console.log('详情信息==>', res.data)

        this.common = data.common
        if (data.common.isFranchisedProduct == 1 && data.common.franchisedProductCode) {
          const obj = {
            productCode: data.common.franchisedProductCode,
            productName: data.common.franchisedProductName,
            productBrand: data.common.franchisedBrand
          }
          this.productStandards.push(obj)
        }
        this.sales = data.sales
        this.norms = this.sales.norms
        this.sizeTypeList = this.sales.skus

        this.editor = this.$public.parseHTML_ForMarkdown(data.graphic.detail)
        this.graphic.detail = data.graphic.detail
        // 获取封面
        if (data.graphic.masterCover && data.graphic.masterCover !== null) {
          this.graphic.videoImage1.push({ url: data.graphic.masterCover })
        } else {
          this.graphic.videoImage1 = []
        }
        if (data.graphic.topCover && data.graphic.topCover !== null) {
          this.graphic.videoImage2.push({ url: data.graphic.topCover })
        } else {
          this.graphic.videoImage2 = []
        }
        if (data.graphic.bottomCover && data.graphic.bottomCover !== null) {
          this.graphic.videoImage3.push({ url: data.graphic.bottomCover })
        } else {
          this.graphic.videoImage3 = []
        }
        this.graphic.param = data.productParams
        data.graphic.images.forEach((v, i) => {
          const imgObj = {
            url: v.graphicUri,
            name: v.graphicUri.substring(v.graphicUri.lastIndexOf('/') + 1),
            graphicId: v.graphicId,
            whetherDefault: true
          }
          if (i === 0) {
            imgObj.whetherDefault = true
            this.graphic.primaryImages.push(imgObj)
            this.primaryImagesList.push(imgObj)
          } else {
            this.graphic.images.push(imgObj)
            this.imagesList.push(imgObj)
          }
        })
        if (data.graphic.videos.length > 0) {
          data.graphic.videos.forEach((v, i) => {
            const videosObj = {
              url: v.graphicUri,
              name: v.graphicUri.substring(v.graphicUri.lastIndexOf('/') + 1),
              graphicId: v.graphicId,
              hospitalId: v.hospitalId
            }
            if (v.whetherDefault) {
              this.graphic.primaryVideos.push(videosObj)
              this.primaryVideosList.push(videosObj)
            } else {
              this.hospitalFileList.push(videosObj)
              this.submitVideos = {
                videos: [videosObj],
                hospitalId: v.hospitalId
              }
              this.hospitalVideosList = [videosObj]
            }
          })
        }
        if (data.graphic.topVideo && data.graphic.topVideo.length > 0) {
          const videosObj = {
            url: data.graphic.topVideo,
            name: data.graphic.topVideo.substring(data.graphic.topVideo.lastIndexOf('/') + 1)
          }
          this.graphic.topVideo.push(videosObj)
        }
        if (data.graphic.bottomVideo && data.graphic.bottomVideo.length > 0) {
          const videosObj = {
            url: data.graphic.bottomVideo,
            name: data.graphic.bottomVideo.substring(data.graphic.bottomVideo.lastIndexOf('/') + 1)
          }
          this.graphic.bottomVideo.push(videosObj)
        }
      })
    },

    // 表单提交
    async submitData() {
      // 验证通过标识
      let validResult = true
      // 验证基础信息表单
      await this.$refs.common.validate(valid1 => {
        if (valid1) {
          if (this.common.isFranchisedProduct == 1 && !this.productStandards.length) {
            return this.$message({
              message: '请选择加盟的产品',
              type: 'error'
            })
          }
          if (this.productStandards.length) {
            this.common.franchisedProductId = this.productStandards[0].productId
            this.common.franchisedProductCode = this.productStandards[0].productCode
            this.common.franchisedProductName = this.productStandards[0].productName
            this.common.franchisedBrand = this.productStandards[0].productBrand
          }
          this.formData.common = this.common
          validResult = true
        } else {
          this.skipTo(0)
          validResult = false
          return false
        }
      })
      // 验证不通过，中止代码
      if (!validResult) return false

      // 验证销售信息表单
      await this.$refs.sales.validate(async valid2 => {
        if (valid2) {
          const norms = []
          if (this.norms.length > 0) {
            let normsBool = false
            this.norms.forEach(v => {
              const normValues = []
              if (v.normName) normsBool = true
              v.normValues.forEach(e => {
                if (!e.valueId) {
                  e.isSell = 1
                }
                normValues.push(e)
              })
              norms.push({
                normName: v.normName,
                normValues: normValues,

                normId: v.normId ? v.normId : undefined
              })
            })
            if (!normsBool) {
              validResult = false
              return this.disposeWarnInfo('请添加规格', 1)
            }

            if (this.sizeTypeList.length > 0) {
              for (let i = 0; i < this.sizeTypeList.length; i++) {
                this.sizeTypeList[i].inventoryTotal = 0
                // if (this.sizeTypeList[i].inventorySalesPrice === "") {
                //     return this.disposeWarnInfo("请输入销售价", 1);
                // }
                if (this.sizeTypeList[i].inventorySalesPrice && this.sizeTypeList[i].inventorySalesPrice !== '') {
                  if (Number(this.sizeTypeList[i].inventorySalesPrice) <= 0) {
                    return this.disposeWarnInfo('商品销售价不能小于等于零', 1)
                  }
                }
                if (this.sizeTypeList[i].inventoryCostPrice && this.sizeTypeList[i].inventoryCostPrice !== '') {
                  if (Number(this.sizeTypeList[i].inventoryCostPrice) <= 0) {
                    return this.disposeWarnInfo('成本价不能小于等于零', 1)
                  }
                }
                if (this.sizeTypeList[i].inventoryMarketPrice && this.sizeTypeList[i].inventoryMarketPrice !== '') {
                  if (Number(this.sizeTypeList[i].inventoryMarketPrice) <= 0) {
                    return this.disposeWarnInfo('市场价不能小于等于零', 1)
                  }
                }
                // if (this.sizeTypeList[i].inventoryTotal === '') {
                //     return this.disposeWarnInfo('请输入库存', 1)
                // }
              }
            } else {
              validResult = false
              return this.disposeWarnInfo('请设置规格属性', 1)
            }
          } else {
            validResult = false
            return this.disposeWarnInfo('请添加规格', 1)
          }
          try {
            if (norms[0].normValues.every(item => item.isSell === 0)) {
              validResult = false
              return this.$message.error('多规格至少有一个发售，请修改后再保存')
            }
          } catch (err) {
            console.log(err)
          }

          this.formData.sales = this.sales
          this.formData.sales.norms = norms
          this.formData.sales.skus = this.sizeTypeList

          // 判断是否修改销售信息
          if (!this.isEditSales) {
            this.formData.sales = undefined
          }

          let validQuota = true
          if (this.$refs.quota) {
            await this.$refs.quota.validate(valid_quota => {
              if (valid_quota) {
                validQuota = true
                // 处理限购关联产品数据
                if (this.formData.buyingQuota) {
                  const idList = []
                  if (this.selectQuoteProducts.length) {
                    this.selectQuoteProducts.forEach(item => {
                      idList.push(item.productId)
                    })
                  }
                  this.formData.linkProductIds = idList
                  // 拼接秒数
                  this.formData.buyingQuotaTime = this.formData.buyingQuotaTime.replace(this.formData.buyingQuotaTime.substr(this.formData.buyingQuotaTime.length - 2, 2), '00')
                } else {
                  this.formData.buyingQuotaTotal = 0
                  this.formData.linkProductIds = []
                  this.formData.buyingQuotaTime = ''
                }
              } else {
                validQuota = false
              }
            })
          }
          if (!validQuota) {
            validResult = false
            this.skipTo(1)
            return false
          }

          validResult = true
        } else {
          this.skipTo(1)
          validResult = false
          return false
        }
      })
      // 验证不通过，中止代码
      if (!validResult) return false

      // 验证链接专区表单
      if (this.productType === 'cross_border') {
        await this.$refs.linkForm.validate(valid => {
          if (valid) {
            this.formData.crossBorderUrl = this.linkFormData.crossBorderUrl
            this.formData.customerServiceUrl = this.linkFormData.serverQrcodeImg[0].url
            this.formData.productCouponLinkList = []
            if (this.linkFormData.couponData.length) {
              this.linkFormData.couponData.forEach(item => {
                this.formData.productCouponLinkList.push({
                  linkCouponId: item.couponLinkId,
                  linkImgUrl: item.couponImg[0].url
                })
              })
            }
            validResult = true
          } else {
            validResult = false
            this.skipTo('', 'row_link')
            return false
          }
        })
      }
      // 验证不通过，中止代码
      if (!validResult) return false

      // 验证图文描述表单
      await this.$refs.graphic.validate(valid2 => {
        if (valid2) {
          var param = {
            detail: this.graphic.detail,
            images: [],
            videos: [],
            topVideo: '',
            bottomVideo: '',
            masterCover: '',
            topCover: '',
            bottomCover: ''
          }
          this.graphic.videoImage1.forEach(v => {
            if (v.response) {
              param.masterCover = v.response.data
            } else {
              param.masterCover = v.url
            }
          })
          this.graphic.videoImage2.forEach(v => {
            if (v.response) {
              param.topCover = v.response.data
            } else {
              param.topCover = v.url
            }
          })
          this.graphic.videoImage3.forEach(v => {
            if (v.response) {
              param.bottomCover = v.response.data
            } else {
              param.bottomCover = v.url
            }
          })

          // 主图
          const primaryImg = this.graphic.primaryImages[0]
          if (!primaryImg.response) {
            param.images.push({
              graphicUri: primaryImg.url,
              whetherDefault: true,
              graphicId: primaryImg.graphicId ? primaryImg.graphicId : undefined
            })
          } else {
            param.images.push({
              graphicUri: primaryImg.response.data,
              whetherDefault: true
            })
          }

          this.graphic.images.forEach(v => {
            if (!v.response) {
              param.images.push({
                graphicUri: v.url,
                whetherDefault: false,
                graphicId: v.graphicId ? v.graphicId : undefined
              })
            } else {
              param.images.push({
                graphicUri: v.response.data,
                whetherDefault: false
              })
            }
          })

          // 视频
          if (this.graphic.primaryVideos.length > 0) {
            const videos = this.graphic.primaryVideos[0]
            if (!videos.response) {
              param.videos.push({
                graphicId: videos.graphicId ? videos.graphicId : undefined,
                graphicUri: videos.url,
                whetherDefault: true
              })
            } else {
              param.videos.push({
                graphicUri: videos.response.data,
                hospitalId: videos.hospitalId
              })
            }
          }
          if (this.graphic.videos.length > 0) {
            this.graphic.videos.forEach(v => {
              if (!v.response) {
                param.videos.push({
                  graphicId: v.graphicId ? v.graphicId : undefined,
                  graphicUri: v.url,
                  hospitalId: v.hospitalId
                })
              } else {
                param.videos.push({
                  graphicUri: v.response.data,
                  hospitalId: v.hospitalId
                })
              }
            })
          }
          if (this.hospitalFileList.length > 0) {
            this.hospitalFileList.forEach(v => {
              if (!v.response) {
                param.videos.push({
                  graphicId: v.graphicId ? v.graphicId : undefined,
                  graphicUri: v.url,
                  hospitalId: v.hospitalId
                })
              } else {
                param.videos.push({
                  graphicUri: v.response.data,
                  hospitalId: v.hospitalId
                })
              }
            })
          }

          if (this.graphic.param.some(v => v.key)) {
            param.param = []
            this.graphic.param.forEach(v => {
              if (v.paramValue) {
                param.param.push({
                  paramKey: v.paramValue,
                  paramValue: v.paramValue,
                  paramId: v.paramId ? v.paramId : undefined
                })
              }
            })
          }
          // 详情顶部视频
          if (this.graphic.topVideo.length > 0) {
            const videos = this.graphic.topVideo[0]
            console.log(videos)
            if (!videos.response) {
              param.topVideo = videos.url
            } else {
              param.topVideo = videos.response.data
            }
          }

          // 详情底部视频
          if (this.graphic.bottomVideo.length > 0) {
            const videos = this.graphic.bottomVideo[0]
            if (!videos.response) {
              param.bottomVideo = videos.url
            } else {
              param.bottomVideo = videos.response.data
            }
          }
          this.formData.productParams = this.graphic.param
          this.formData.graphic = param

          this.formData.isGifts = this.isGifts ? 1 : 0

          validResult = true
        } else {
          this.skipTo('', 'row_3')
          validResult = false
          return false
        }
      })
      // 验证不通过，中止代码
      if (!validResult) return false
      // console.log('this.formData===>>>>', this.formData)

      // 提交
      this.$http
        .requestPost({
          url: '/product/save',
          param: this.formData
        })
        .then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          })
          this.$router.go(-1)
        })
    },
    // 处理提示信息
    disposeWarnInfo(text, num) {
      this.skipTo(num)
      this.$message({ message: text, type: 'warning' })
    },

    // ======================== 基础信息 ========================

    // ======================== 销售信息 ========================
    // 添加规格
    addSizeName() {
      if (!this.normName) return
      if (this.norms.length > 0) {
        return this.$message.warning('只能添加单个规格名称')
        // let bool = this.norms.findIndex(item => item.normName === this.normName);
        // if (bool != -1) {
        //     return this.$message.warning("不能添加相同的规格！");
        // }
      }
      this.norms.push({
        normName: this.normName,
        normValues: [],
        attrName: '',
        id: this.$public.guid()
      })
      this.normName = ''
      this.calculateSize()
      this.salesEdit()
    },
    // 删除规格
    removeSizeName(i) {
      this.norms.splice(i, 1)
      this.calculateSize()
      this.salesEdit()
    },
    // 添加规格的属性
    addAttr(i) {
      const attrName = this.norms[i].attrName
      if (!attrName) {
        return this.$message({
          message: '请输入属性名称！',
          type: 'error'
        })
      }
      if (this.norms[i].normValues.length > 0) {
        const indVal = this.norms[i].normValues.findIndex(item => item.valueName === attrName)
        if (indVal != -1) {
          return this.$message.warning('不能添加相同的属性！')
        }
      }
      this.norms[i].normValues.push({ valueName: attrName })
      this.norms[i].attrName = ''
      this.calculateSize()
      this.salesEdit()
    },
    // 删除规格的属性
    closeAttr(i1, i2) {
      let type = true
      if (this.norms[i1].normValues.length <= 1) {
        type = false
      }
      const val = this.norms[i1].normValues[i2].valueName
      this.norms[i1].normValues.splice(i2, 1)

      // 删除规则属性时处理
      if (type) {
        for (let i = 0; i < this.sizeTypeList.length; i++) {
          const bool = this.sizeTypeList[i].normValues.includes(val)
          if (bool) {
            this.sizeTypeList.splice(i, 1)
            --i
          }
        }
        return
      } else {
        this.calculateSize()
      }
      this.salesEdit()
    },
    // 根据规格数据计算类型列表
    calculateSize() {
      if (this.sizeTypeList.length > 0) {
        this.sizeTypeCopy = JSON.parse(JSON.stringify(this.sizeTypeList))
      }
      this.sizeTypeList = []

      for (let i = 0; i < this.norms.length; i++) {
        if (this.norms[i].normName === '') continue

        if (this.norms[i].normValues.length > 0) {
          for (let i2 = 0; i2 < this.norms[i].normValues.length; i2++) {
            const name = this.norms[i].normValues[i2].valueName
            const valArr = []
            valArr.push(name)
            if (name) {
              this.cycleCalculateSize(this.norms, i + 1, valArr)
            }
          }
          break
        }
      }

      // 对旧数据进行返显
      if (this.sizeTypeList.length > 0 && this.sizeTypeCopy.length > 0) {
        // 判断是否为添加删除规格，是则退出执行
        if (this.sizeTypeCopy[0].normValues.length != this.sizeTypeList[0].normValues.length) return

        // 处理旧数据的
        for (let i = 0, len = this.sizeTypeCopy.length; i < len; i++) {
          const list = this.sizeTypeCopy[i].normValues
          const bool = true
          let index = 0

          index = this.sizeTypeList.findIndex(item => {
            let num = 0
            for (let i2 = 0, len2 = list.length; i2 < len2; i2++) {
              num = item.normValues.findIndex(item2 => item2 == list[i2])
              if (num == -1) {
                break
              }
            }
            return num != -1
          })

          if (index != -1) {
            const item = this.sizeTypeCopy[i]
            this.sizeTypeList[index].inventoryCostPrice = item.inventoryCostPrice
            this.sizeTypeList[index].inventoryId = item.inventoryId
            this.sizeTypeList[index].inventoryMarketPrice = item.inventoryMarketPrice
            this.sizeTypeList[index].inventorySalesPrice = item.inventorySalesPrice
            this.sizeTypeList[index].inventoryTotal = item.inventoryTotal
            this.sizeTypeList[index].productSales = item.productSales
            this.sizeTypeList[index].whetherHeadquarters = item.whetherHeadquarters
          }
        }
      }
    },
    // 计算类型列表
    cycleCalculateSize(arr, ind, arrVal) {
      // 当数组的长度小于或等于当前要运行的下标时
      // 数组无法继续进行运行，将当前 'arrVal' 添加进 this.sizeTypeList 内
      if (arr.length <= ind) {
        this.sizeTypeList.push({
          normValues: arrVal,
          inventorySalesPrice: '',
          inventoryCostPrice: '',
          inventoryMarketPrice: '',
          inventoryTotal: ''
        })
        return
      }

      for (let i = ind; i < arr.length; i++) {
        // 判断当前子元素 normValues 长度是否可遍历
        if (arr[i].normValues.length > 0) {
          for (let i1 = 0; i1 < arr[i].normValues.length; i1++) {
            const name = arr[i].normValues[i1].valueName
            var arrVal2 = JSON.parse(JSON.stringify(arrVal))

            arrVal2.push(name)
            if (ind < arr.length - 1) {
              this.cycleCalculateSize(arr, ind + 1, arrVal2)
            } else {
              this.sizeTypeList.push({
                normValues: arrVal2,
                inventorySalesPrice: '',
                inventoryCostPrice: '',
                inventoryMarketPrice: '',
                inventoryTotal: ''
              })
            }
          }
          break
        } else if (ind < arr.length - 1) {
          // 当前子元素不可遍历时，判断父亲元素长度是否可以继续遍历，
          // 当父亲元素可以遍历就跳入到下一次遍历
          this.cycleCalculateSize(arr, ind + 1, arrVal)
        }
        break
      }
    },
    // 修改规格名称
    amendTag(type, index) {
      const msg = type === 'father' ? '规格名称' : '属性名称'
      const attrVal = type === 'father' ? this.norms[index[0]].normName : this.norms[index[0]].normValues[index[1]].valueName
      this.$prompt('请输入' + msg, '修改', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\S/,
        inputPlaceholder: '请输入' + msg,
        inputErrorMessage: msg + '的长度不能大于10位小于1',
        inputValue: attrVal,
        inputValidator: e => {
          if (e.length > 10 || e.length <= 0) {
            return false
          }
        },
        beforeClose: (action, instance, done) => {
          const value = instance.inputValue
          if (action == 'confirm') {
            if (type === 'father') {
              const bool = this.norms.findIndex(item => item.normName === value)
              if (bool != -1) {
                return this.$message.warning('不能添加相同的规格！')
              }
              this.norms[index[0]].normName = value
            } else {
              if (this.norms[index[0]].normValues.length > 0) {
                const indVal = this.norms[index[0]].normValues.findIndex(item => item.valueName === value)
                if (indVal != -1) {
                  return this.$message.warning('不能添加相同的属性！')
                }
              }
              this.norms[index[0]].normValues[index[1]].valueName = value
            }
            this.sizeTypeList.forEach((v, i) => {
              v.normValues.forEach((e, j) => {
                if (e === attrVal) {
                  this.sizeTypeList[i].normValues[j] = value
                }
              })
            })
            this.salesEdit()
            done()
          } else {
            done()
          }
        }
      })
    },

    // ------------------------限购功能相关 star -----------------------
    // 打开限购设置关联产品弹窗
    openQuoteRelateDialog() {
      this.$refs.quotaChooseProduct.open(this.formData.productId)
    },
    // 删除已选的限购关联产品
    delSelectQuoteRelate(row, index) {
      this.selectQuoteProducts.splice(index, 1)
    },
    // 提交已选限购产品
    confirmSelectQuoteRelate(data) {
      this.selectQuoteProducts = data
    },
    // ------------------------限购功能相关 end -----------------------

    // ----------------------------------------链接专区 start----------------------------------------
    // 添加优惠链接
    addCouponLink() {
      this.linkFormData.couponData.push({
        couponLinkId: '',
        couponImg: []
      })
    },
    deleteCouponLink(index) {
      this.linkFormData.couponData[index].couponImg = []
      this.linkFormData.couponData.splice(index, 1)
    },
    focusCouponLink(e, index) {
      this.currentCouponLinkIndex = index
    },
    // ----------------------------------------链接专区 end------------------------------------------

    // 上传图片/视频
    uploadSuccessFun(argument, type, index) {
      // argument: res, file, fileList
      switch (type) {
        case 'upload_linkImg': // 链接图片
          this.linkFormData.couponData[index].couponImg = [{ url: argument[0].data }]
          // 移除表单验证提示
          this.$refs.linkForm.validateField(`couponData.${index}.couponImg`)
          break

        case 'upload_serverQrcode': // 客服二维码
          this.linkFormData.serverQrcodeImg = [{ url: argument[0].data }]
          // 移除表单验证提示
          this.$refs.linkForm.validateField(`serverQrcodeImg`)
          break

        case 'upload_mainPicture': // 产品主图
          this.graphic.primaryImages = argument[2]
          break

        case 'upload_vicePicture': // 产品次图
          this.graphic.images = argument[2]
          break

        case 'upload_defaultVideo': // 主图视频(默认)
          this.graphic.primaryVideos = argument[2]
          break

        case 'upload_defaultVideoCover': // 主图视频(默认)封面
          this.graphic.videoImage1 = argument[2]
          break

        case 'upload_hospitalVideo': // 主图视频(医院)
          this.graphic.videos = argument[2]
          break

        case 'upload_hospitalVideoDialog': // 主图视频(医院)-弹窗
          this.submitVideos.videos = argument[2]
          break

        case 'upload_topVideo': // 顶部视频
          this.graphic.topVideo = argument[2]
          break

        case 'upload_topVideoCover': // 顶部视频封面
          this.graphic.videoImage2 = argument[2]
          break

        case 'upload_bottomVideo': // 底部视频
          this.graphic.bottomVideo = argument[2]
          break

        case 'upload_bottomVideoCover': // 底部视频封面
          this.graphic.videoImage3 = argument[2]
          break

        default:
          break
      }
    },
    // 删除图片/视频
    removeFileFun(argument, type, index) {
      // argument: file, fileList
      switch (type) {
        case 'remove_linkImg': // 链接图片
          this.linkFormData.couponData[index].couponImg = []
          break

        case 'remove_serverQrcode': // 客服二维码
          this.linkFormData.serverQrcodeImg = []
          break

        case 'remove_mainPicture': // 产品主图
          this.graphic.primaryImages = []
          break

        case 'remove_vicePicture': // 产品次图
          this.graphic.images = argument[1]
          break

        case 'remove_defaultVideo': // 主图视频(默认)
          this.primaryVideosList = []
          this.graphic.videoImage1 = []
          this.graphic.primaryVideos = argument[1]
          this.progressFlag2 = false
          this.percentage2 = 0
          if (this.uploader2) {
            this.uploader2.cancel()
            this.uploader2 = ''
          }
          break

        case 'remove_defaultVideoCover': // 主图视频(默认)封面
          this.graphic.videoImage1 = []
          break

        case 'remove_hospitalVideo': // 主图视频(医院)
          this.submitVideos = {
            videos: [],
            hospitalId: ''
          }
          this.graphic.videos = []
          this.hospitalFileList = []
          this.hospitalVideosList = []

          break

        case 'remove_hospitalVideoDialog': // 主图视频(医院)-弹窗
          this.submitVideos.videos = argument[1]
          this.progressFlag4 = false
          this.percentage4 = 0
          if (this.uploader4) {
            this.uploader4.cancel()
            this.uploader4 = ''
          }
          break

        case 'remove_topVideo': // 顶部视频
          this.graphic.videoImage2 = []
          this.graphic.topVideo = argument[1]
          this.progressFlag5 = false
          this.percentage5 = 0
          if (this.uploader5) {
            this.uploader5.cancel()
            this.uploader5 = ''
          }
          break

        case 'remove_topVideoCover': // 顶部视频封面
          this.graphic.videoImage2 = []
          break

        case 'remove_bottomVideo': // 底部视频
          this.graphic.videoImage3 = []
          this.graphic.bottomVideo = argument[1]
          this.progressFlag6 = false
          this.percentage6 = 0
          if (this.uploader6) {
            this.uploader6.cancel()
            this.uploader6 = ''
          }
          break

        case 'remove_bottomVideoCover': // 底部视频封面
          this.graphic.videoImage3 = []
          break

        default:
          break
      }
    },

    // ======================== 图文描述 ========================
    // 打开视频选择框
    openVideos() {
      this.dialogVisible_1 = true
      this.clearUploadStatus()
    },
    // 关闭主图医院视频弹窗
    closeHospitalVideoDialog() {
      if (!this.hospitalFileList.length) {
        this.submitVideos = {
          videos: [],
          hospitalId: ''
        }
        this.hospitalVideosList = []
      }
      this.dialogVisible_1 = false
    },
    // 确定视频提交
    videosConfirm() {
      this.$refs.submitVideosRef.validate(valid => {
        if (valid) {
          this.submitVideos.videos[0].hospitalId = this.submitVideos.hospitalId
          const videoArr = []
          videoArr.push({
            name: this.submitVideos.videos[0].name,
            url: this.submitVideos.videos[0].url,
            hospitalId: this.submitVideos.hospitalId
          })
          this.hospitalFileList = videoArr
          this.dialogVisible_1 = false
        } else {
          console.log('error submit!!!')
        }
      })
    },

    // 富文本 图片添加
    handleEditorImgAdd(pos, file) {
      const formdata = new FormData()
      formdata.append('file', file)
      this.$http
        .requestPost({
          url: '/common/file/upload',
          param: formdata
        })
        .then(res => {
          this.$refs.md.$img2Url(pos, res.data)
        })
    },
    // 富文本 图片删除
    handleEditorImgDel(pos) {
      console.log(pos)
    },
    // 富文本改变后的内容
    editorChange(val, render) {
      this.graphic.detail = render
    },

    // 产品参数
    // 添加产品参数列
    addParam() {
      if (this.graphic.param > 100) return
      this.graphic.param.push({ paramKey: '', paramValue: '' })
    },

    // ======================== 搜索标签 ========================
    // 添加搜索标签
    addLabel() {
      this.addType = true
      this.labelTitle = '搜索标签'
      this.dialogVisible = true
    },
    // 删除标签
    closeLabel(i) {
      this.formData.tags.splice(i, 1)
    },

    // ======================== 服务保障 ========================
    // 添加
    addServe() {
      this.addType = false
      this.labelTitle = '服务保障'
      this.dialogVisible = true
    },
    // 删除服务保障
    closeServe(i) {
      this.formData.services.splice(i, 1)
    },

    // 确定
    confirm() {
      // 搜索标签
      if (this.addType) {
        this.formData.tags.push({
          tagName: this.submitForm.labels
        })
      } else {
        // 服务保障
        this.formData.services.push({
          serviceName: this.submitForm.labels
        })
      }
      this.dialogVisible = false
      this.submitForm.labels = ''
    },
    // 删除单个产品参数
    delectParam(index) {
      this.graphic.param.splice(index, 1)
    },

    // 视频主图
    handleChange_2(file, fileList) {
      // 基本信息配置
      this.uploader2 = this.tcVod.upload({
        mediaFile: file.raw // 媒体文件
      })
      // 进度
      this.uploaderMediaProgress('primaryVideosList', this.uploader2)
      // 视频上传后 处理
      this.uploaderDoneFn('primaryVideosList', fileList, this.uploader2)
    },
    // 视频主图（医院）
    handleChange_4(file, fileList) {
      // 基本信息配置
      this.uploader4 = this.tcVod.upload({
        mediaFile: file.raw // 媒体文件
      })
      // 进度
      this.uploaderMediaProgress('hospitalVideosList', this.uploader4)
      // 视频上传后 处理
      this.uploaderDoneFn('hospitalVideosList', fileList, this.uploader4)
    },
    // 顶部视频
    handleChange_5(file, fileList) {
      // 基本信息配置
      this.uploader5 = this.tcVod.upload({
        mediaFile: file.raw // 媒体文件
      })
      // 进度
      this.uploaderMediaProgress('topVideo', this.uploader5)
      // 视频上传后 处理
      this.uploaderDoneFn('topVideo', fileList, this.uploader5)
    },
    // 底部视频
    handleChange_6(file, fileList) {
      // 基本信息配置
      this.uploader6 = this.tcVod.upload({
        mediaFile: file.raw // 媒体文件
      })
      // 进度
      this.uploaderMediaProgress('bottomVideo', this.uploader6)
      // 视频上传后 处理
      this.uploaderDoneFn('bottomVideo', fileList, this.uploader6)
    },
    // 视频上传成功后，将fileId，fileUrl传给后台
    uploaderDoneFn(objName, fileList, uploader) {
      const that = this
      uploader
        .done()
        .then(function(doneResult) {
          const videosObj = [
            {
              url: doneResult.video.url,
              name: fileList[0].name
            }
          ]
          that.$http
            .requestPostQs({
              url: '/common/file/upload/saveQCloud',
              param: {
                fileId: doneResult.fileId,
                fileUrl: doneResult.video.url
              }
            })
            .then(res => {
              console.log(res)
            })

          if (objName == 'topVideo') {
            that.graphic.topVideo = videosObj
          }
          if (objName == 'bottomVideo') {
            that.graphic.bottomVideo = videosObj
          }
          if (objName == 'primaryVideosList') {
            that.primaryVideosList = videosObj
            that.graphic.primaryVideos = videosObj
          }
          if (objName == 'hospitalVideosList') {
            that.hospitalVideosList = videosObj
            that.submitVideos.videos = videosObj
          }
        })
        .catch(function(err) {
          // deal with error
        })
    },
    // 视频上传进度
    uploaderMediaProgress(objName, uploader) {
      const that = this
      uploader.on('media_progress', function(info) {
        const percentage = parseInt(info.percent * 100)
        console.log('percentage', percentage)
        if (objName == 'topVideo') {
          that.progressFlag5 = true // 显示进度条
          that.percentage5 = percentage >= 100 ? 100 : percentage // 动态获取文件上传进度
        }
        if (objName == 'bottomVideo') {
          that.progressFlag6 = true // 显示进度条
          that.percentage6 = percentage >= 100 ? 100 : percentage // 动态获取文件上传进度
        }
        if (objName == 'primaryVideosList') {
          that.progressFlag2 = true // 显示进度条
          that.percentage2 = percentage >= 100 ? 100 : percentage // 动态获取文件上传进度
        }
        if (objName == 'hospitalVideosList') {
          that.progressFlag4 = true // 显示进度条
          that.percentage4 = percentage >= 100 ? 100 : percentage // 动态获取文件上传进度
        }

        if (percentage >= 100) {
          setTimeout(() => {
            if (objName == 'primaryVideosList') {
              that.progressFlag2 = false
            }
            if (objName == 'hospitalVideosList') {
              that.progressFlag4 = false
            }
            if (objName == 'topVideo') {
              that.progressFlag5 = false
            }
            if (objName == 'bottomVideo') {
              that.progressFlag6 = false
            }
          }, 1000) // 一秒后关闭进度条
        }
      })
    },
    // 清空上传视频数据
    clearUploadStatus() {
      if (this.uploader2) {
        this.uploader2.cancel()
      }
      if (this.uploader4) {
        this.uploader4.cancel()
      }
      if (this.uploader5) {
        this.uploader5.cancel()
      }
      if (this.uploader6) {
        this.uploader6.cancel()
      }
      this.uploader2 = ''
      this.uploader4 = ''
      this.uploader5 = ''
      this.uploader6 = ''
    },

    handleNoSell(val) {
      this.$confirm('是否确认停售该商品规格?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          val.isSell = 0
          this.salesEdit()
        })
        .catch(err => {
          console.log(err)
        })
    },

    handleIsSell(val) {
      this.$confirm('是否确认发售该商品规格?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          val.isSell = 1
          this.salesEdit()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.main-box {
  background-color: #f5f5f5;
  position: static;
}

//经销产品
.add-btn {
  margin-left: 20px;
}

::v-deep .single-select-table .el-table__header-wrapper .el-checkbox {
  display: none;
}

::v-deep .single-select-table .el-checkbox__inner {
  border-radius: 50%;
}

.btn-box {
  text-align: right;
  margin: 20px;
}

.nav-bar {
  padding: 12px 20px;
  margin-bottom: 10px;
  position: absolute;
  top: 68px;
  left: 10px;
  z-index: 2000;
  width: calc(100% - 23px);
  box-shadow: 0px 2px 1px 0px #f5f5f5;

  & > a {
    margin-right: 30px;
    font-size: 14px;
    padding: 10px 0;
    border-top: 3px solid transparent;
  }

  .active {
    border-color: #1890ff;
    color: #1890ff;
  }
}

.row_box {
  margin-bottom: 10px;
  font-size: 14px;

  .header {
    padding: 18px 20px 10px;
    border-bottom: 1px solid #cccccc;
  }

  .content {
    padding: 10px;
  }
}

// 基础信息
#row_1 {
  padding-top: 50px;

  .el-form {
    max-width: 600px;
  }
}

// 销售信息
#row_2 {
  .size_box {
    & > label,
    & div > label {
      min-width: 100px;
      padding-right: 10px;
      text-align: right;
    }

    & > div.flex-box {
      flex: 1;
    }

    .specs {
      display: flex;
      flex-direction: column;

      .size-name {
        padding-left: 100px;
      }

      .size-item {
        display: flex;
        align-items: center;
        width: 180px;
        justify-content: space-between;
      }
    }
  }
  .content {
    .buyingQuota_content {
      display: flex;
      align-items: center;
      .buyingQuota_input {
        margin-left: 30px;
        display: flex;
        .buyingQuota_tip {
          color: #808080;
          font-size: 12px;
          margin-left: 15px;
        }
      }
    }
    .quote_link_product {
      margin-left: 30px;
    }
  }
}

// 图文描述
// 上传
.tip-box {
  position: relative;

  .tip {
    position: absolute;
    top: 26px;
    left: 18%;
    font-size: 12px;
    color: #ccc;
  }
}

.editor {
  max-width: 1000px;
  max-height: 420px;

  .v-note-wrapper.shadow {
    height: 420px;
  }
}

//产品参数
.form-list {
  max-width: 1000px;
  flex-wrap: wrap;

  .form-item {
    width: 320px;
    margin: 0 10px 10px 0;
    position: relative;

    label {
      width: 120px;
      text-align: right;
      font-weight: 300;
    }

    .form-item-content {
      flex: 1;
    }
  }
}

// 搜索标签
#row_4,
#row_5 {
  .content {
    & > div {
      flex-wrap: wrap;
      padding: 14px 42px;

      .el-tag {
        margin: 0 8px 5px 0;
      }
    }
  }
}

.submit-btn {
  justify-content: center;
  padding: 20px 0;

  button {
    padding: 12px 35px;
  }
}

#row_6 {
  padding-bottom: 60px;
}

// 链接专区
.link_box {
  .link_content {
    .link_item {
      .item_label {
        font-weight: bold;
        &::before {
          content: '*';
          color: red;
          margin-right: 5px;
        }
      }
      .item_input {
        width: 60%;
        margin-right: 15px;
      }
      .linkItem_coupon {
        margin-bottom: 20px;
      }
      .linkItem_uploadImg {
        /deep/ .el-upload--picture-card {
          width: 750px;
          height: 357px;
        }
        /deep/ .el-upload-list--picture-card .el-upload-list__item {
          width: 750px;
          height: 357px;
        }
        .coupon_img {
          width: 750px;
          height: 357px;
        }
      }
      .server_uploadImg {
        /deep/ .el-upload--picture-card {
          width: 330px;
          height: 440px;
        }
        /deep/ .el-upload-list--picture-card .el-upload-list__item {
          width: 330px;
          height: 440px;
        }
        .coupon_img {
          width: 330px;
          height: 440px;
        }
      }
      .tip-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tip {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -30%);
        }
      }
    }
  }
}

.submit-btn {
  justify-content: center;
  padding: 20px 0;
  position: absolute;
  bottom: 0;
  left: 10px;
  width: calc(100% - 23px);
  z-index: 2000;
  box-shadow: 0px -3px 0px 0px #f5f5f5;

  button {
    padding: 12px 35px;
  }
}

.required {
  & >>> .el-form-item__label::before {
    content: '*';
    color: #f56c6c;
    margin-right: 4px;
  }
}

.close_ben {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  font-size: 20px;
}

.close_ben:hover {
  color: #1890ff;
  cursor: pointer;
}
</style>
