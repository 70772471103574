<template>
  <el-dialog :visible.sync="visible" title="导入" :close-on-click-modal="false"
             width="400px" top="10vh"
  >
    <div class="Popout_content">
      <el-form label-width="100px">
        <el-form-item label="导入模板">
          <el-button size="small" type="primary" @click="downloadTemplate">下载批量导入模板文件</el-button>
        </el-form-item>
        <el-form-item label="批量导入">
          <el-upload
              class="upload-demo"
              action="#"
              :http-request="customUpload"
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
              :show-file-list="false"
          >
            <el-button slot="trigger" size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div>
            <p v-if="importStatus === 1" class="progress color_success">
              导入成功！
            </p>
            <p v-else-if="importStatus === 2" class="progress color_red">
              导入失败!
            </p>
            <p v-else-if="importStatus === 3" class="progress">
              正在导入中...
            </p>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { exportImportTemplateExcel, importTalentExcel } from '@/api/user'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    editForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  data() {
    return {
      importStatus: 0
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },

  watch: {
    visible(val) {
      if (val) {
        this.resetDialog()
      }
    }
  },

  methods: {
    // 自定义上传
    async customUpload(file) {
      try {
        const formData = new FormData()
        formData.append('file', file.file) // 获取原始文件对象
        const res = await importTalentExcel(formData)
        this.handleSuccess(res)
      } catch (e) {
        this.importStatus = 2
        console.log(e)
      }
    },

    // 上传前检查
    beforeUpload(file) {
      // 文件大小&类型
      this.importStatus = 3
      return true
    },

    // 上传成功回调
    handleSuccess(response, file, fileList) {
      this.importStatus = 1
      this.$public.downloadFile(response, '导入人员_结果反馈.xls')
      this.$message.success('上传成功!')
      setTimeout(() => {
        this.visible = false
        this.$emit('edit-done')
        this.$message.success('反馈结果已下载 !')
      }, 1000)
    },

    // 下载导入模板
    async downloadTemplate() {
      try {
        const res = await exportImportTemplateExcel()
        this.$public.downloadFile(res, '导入人员模板.xls')
      } catch (e) {
        console.log(e)
        this.$message.error('下载模板失败')
      }
    },
    // 关闭弹窗-重置
    resetDialog() {
      this.importStatus = 0
    }

  }
}
</script>

<style lang="scss" scoped>

</style>
