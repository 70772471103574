<template>
    <!-- 文章列表 -->
    <div class="main-box">
        <div class="head-search-row">
            <el-form :model="queryParams" size="small" :inline="true" label-width="68px">
                <el-form-item label="文章标题">
                    <el-input v-model="queryParams.articleTitle" placeholder="请输入文章标题" clearable style="width: 240px;"
                        @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="类别">
                    <el-select v-model="queryParams.categoryId" placeholder="请选择" clearable style="width: 350px;">
                        <el-option v-for="(item, index) in queryCategoryList" :key="index" :label="item.categoryName" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="来源">
                    <el-select v-model="queryParams.articleSource" placeholder="请选择" clearable style="width: 240px">
                        <el-option v-for="item in $api.articleSource" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="作者名称">
                    <el-input v-model="queryParams.articleAuthor" placeholder="请输入作者名称" clearable style="width: 240px;"
                        @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item label="添加时间">
                    <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange"
                        range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" @click="handleQuery">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="margin: 20px 0 0 20px">
            <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
        </div>
        <div class="table">
            <el-main>
                <template>
                    <el-table ref="tables" :data="list" height="400" border
                        :header-cell-style="{ background: '#409eff', color: '#ffffff' }">
                        <el-table-column label="序号" width="60" align="center" type="index" />
                        <el-table-column label="标题" align="center" prop="articleTitle" />
                        <el-table-column label="类别" align="center" prop="articleCategoryName" />
                        <el-table-column label="作者" align="center" prop="articleAuthor" />
                        <el-table-column label="来源" align="center" prop="articleSource">
                            <template slot-scope="scope">
                                <span>{{ sourceStatus(scope.row) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" align="center" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                <span>{{ getStatus(scope.row) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="添加时间" align="center" prop="createTime" width="200px" />
                        <el-table-column label="浏览量" align="center" prop="articleView" />
                        <el-table-column label="操作" align="center" class-name="small-padding fixed-width"
                            min-width="200">
                            <template slot-scope="scope">
                                <el-button type="text" @click="handleEdit(scope.row)">查看详情</el-button>
                                <span class="btn-partition"> / </span>

                                <el-button type="text" @click="toContent(scope.row)">关联文章</el-button>
                                <span class="btn-partition"> / </span>
                                <el-button type="text" @click="recommendArticle(scope.row)">
                                    {{scope.row.recommendedStatus === 2 ? '取消推荐' : '推荐'}}
                                </el-button>
                                <span class="btn-partition"> / </span>
                                <el-button type="text" @click="deleteArticle(scope.row.id, 1)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="queryParams.pageNum" :page-sizes="$api.pageSizes"
                        :page-size="queryParams.pageSize" layout=" total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </el-main>
        </div>
        <!-- 关联文章弹窗 -->
        <el-dialog title="关联文章" :visible.sync="openEdit" append-to-body @close="close">
            <div class="content">
                <el-form :model="relatedParams" size="small" :inline="true" style="margin-top: 20px;">
                    <el-form-item label="标题">
                        <el-input v-model="relatedParams.articleTitle" placeholder="请输入文章标题" clearable
                            style="width: 240px;" @keyup.enter.native="handleQuery_1" />
                    </el-form-item>
                    <el-form-item label="作者">
                        <el-input v-model="relatedParams.articleAuthor" placeholder="请输入作者名称" clearable
                            style="width: 240px;" @keyup.enter.native="handleQuery_1" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="mini" @click="handleQuery_1">查询</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="mini" @click="addArticle">新增</el-button>
                    </el-form-item>
                </el-form>
                <el-table ref="tables" :data="contentList" height="400" border
                    :header-cell-style="{ background: '#409eff', color: '#ffffff' }">
                    <el-table-column label="序号" width="60" align="center" type="index" />
                    <el-table-column label="标题" align="center" prop="articleTitle" />
                    <el-table-column label="作者" align="center" prop="articleAuthor" />
                    <el-table-column label="操作" align="center" class-name="small-padding fixed-width" min-width="200">
                        <template slot-scope="scope">
                            <el-button type="text" @click="associatedGroupChat(scope.row)">查看详情</el-button>
                            <span class="btn-partition"> / </span>
                            <el-button type="text" @click="deleteSource(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination @size-change="handleSizeChange_1" @current-change="handleCurrentChange_1"
                        :current-page="relatedParams.pageNum" :page-sizes="$api.pageSizes"
                        :page-size="relatedParams.pageSize" layout=" total, sizes, prev, pager, next, jumper"
                        :total="total_1">
                    </el-pagination>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="close">取 消</el-button>
            </div>
        </el-dialog>
        <!-- 新增关联文章弹窗 -->
        <el-dialog title="新增关联文章" :visible.sync="openAddEdit" append-to-body @close="close1">
            <div class="content">
                <el-form :model="addRelatedParams" size="small" :inline="true" style="margin-top: 20px;">
                    <el-form-item label="标题">
                        <el-input v-model="addRelatedParams.articleTitle" placeholder="请输入文章标题" clearable
                            @keyup.enter.native="handleQuery_2" />
                    </el-form-item>
                    <el-form-item label="作者">
                        <el-input v-model="addRelatedParams.articleAuthor" placeholder="请输入作者名称" clearable
                            @keyup.enter.native="handleQuery_2" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="mini" @click="handleQuery_2">查询</el-button>
                    </el-form-item>
                </el-form>
                <el-table ref="tablelist" @selection-change="handleSelectionChange" :data="tableList" height="400"
                    border style="margin-top: 30px;" :header-cell-style="{ background: '#409eff', color: '#ffffff' }">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="序号" width="60" align="center" type="index" />
                    <el-table-column label="标题" align="center" prop="articleTitle" />
                    <el-table-column label="作者" align="center" prop="articleAuthor" />
                    <el-table-column label="操作" align="center" class-name="small-padding fixed-width" min-width="160">
                        <template slot-scope="scope">
                            <el-button type="text" @click="associatedGroupChat(scope.row)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination @size-change="handleSizeChange_2" @current-change="handleCurrentChange_2"
                        :current-page="addRelatedParams.pageNum" :page-sizes="$api.pageSizes"
                        :page-size="addRelatedParams.pageSize" layout=" total, sizes, prev, pager, next, jumper"
                        :total="total_2">
                    </el-pagination>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFormAdd">确 定</el-button>
                <el-button @click="close1">取 消</el-button>
            </div>
        </el-dialog>

        <!-- 查看详情弹窗 -->
        <el-dialog title="文章详情" :visible.sync="openDetail" append-to-body @close="openDetail=false">
            <div class="content">
                <el-form label-width="80px">
                    <el-form-item label="标题" style="width:500px;" :required="true">
                        <el-input v-model="formData.articleTitle" :disabled="true" />
                    </el-form-item>
                    <el-form-item label="类别" :required="true">
                        <el-select v-model="formData.articleCategoryId" placeholder="请选择" :disabled="true">
                            <el-option v-for="(item, index) in queryCategoryList" :key="index" :label="item.categoryName"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="作者" style="width:500px;" :required="true">
                        <el-input v-model="formData.articleAuthor" :disabled="true" />
                    </el-form-item>
                    <el-form-item label="认证信息" style="width:500px;">
                        <el-input v-model="formData.organization" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="封面" style="width:500px;" :required="true">
                        <el-image style="width: 100px; height: 100px" :src="formData.coverUrl"></el-image>
                    </el-form-item>
                    <el-form-item label="内容" :required="true">
                        <div class="editor">
                            <div v-html="formData.articleContent"></div>
                            <!-- <mavon-editor ref="md" v-model="formData.articleContent" :disabled="true" /> -->
                        </div>
                    </el-form-item>
                    <el-form-item label="顶部视频" v-if="formData.topVideo != ''">
                        <div class="tip-box">
                            <div class="vedio" style="cursor: pointer;" v-if="formData.topVideo">
                                <span @click="toVedio('top')" style="display:inline-block;width:800px;cursor: pointer; color:#666;">
                                    {{ formData.topVideo }}
                                </span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="底部视频" v-if="formData.buttonVideo != ''">
                        <div class="tip-box">
                            <div class="vedio" style="cursor: pointer;" v-if="formData.buttonVideo">
                                <span @click="toVedio('bottom')" style="display:inline-block;width:800px;cursor: pointer; color:#666;">
                                    {{ formData.buttonVideo }}
                                </span>
                            </div>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <!-- 视频在线预览弹框 -->
        <el-dialog :visible.sync="showDialog_2" width="60%" append-to-body @close="closeEvent_2">
            <video v-if="showDialog_2" class="video" ref="video" controls preload="auto" playsinline="true">
                <source :src="vedioUrl" type="video/mp4">
            </video>
        </el-dialog>
    </div>
</template>

<script>
import dUpload from "@/components/d-upload/index";
export default {
    components: { dUpload },
    data() {
        return {
            showDialog_2: false,//视频回显弹框
            vedioUrl: '',//视频回显地址
            id: '',
            formData: {},
            dateRange: [],//发布时间
            queryCategoryList: [],//分类列表
            // 总条数
            total: 0,
            total_1: 0,
            total_2: 0,
            list: [],//文章列表表格数据
            contentList: [],//关联文章表格数据
            tableList: [],//新增关联文章表格数据
            // 查询参数
            queryParams: {
                id:'',
                pageNum: 1,
                pageSize: 10,
                articleTitle: "", //文章标题
                categoryId: "", //分类id
                articleSource: null, //文章来源，1.638医品，2.营养学社
                articleAuthor: "", //作者
                createBeginTime: "", //创建时间-开始
                createEndTime: "", //创建时间-结束
                isDelete: '' //是否删除 0.未删除 1.已删除
            },
            relatedParams: {
                id: '',
                pageNum: 1,
                pageSize: 10,
                articleTitle: '',
                articleAuthor: ''
            },
            addRelatedParams: {
                pageNum: 1,
                pageSize: 10,
                articleTitle: '',
                articleAuthor: ''
            },
            openEdit: false,
            openAddEdit: false,
            openDetail: false,
            multipleSelection: [],//多选
        };
    },
    created() {
        this.getListArticle();
        this.getList();
    },
    methods: {
        getStatus(row) {
            if (row.articleStatus == 1) {
                return "显示";
            } else if (row.articleStatus == 2) {
                return "隐藏";
            }
        },
        //来源
        sourceStatus(row) {
            if (row.articleSource == 1) {
                return "638医品";
            } else if (row.articleSource == 2) {
                return "营养学社";
            }
        },
        // 文章列表数据
        getListArticle() {
            this.loading = true;
            let param = {
                articleTitle: this.queryParams.articleTitle,
                categoryId: this.queryParams.categoryId,
                articleSource: this.queryParams.articleSource,
                articleAuthor: this.queryParams.articleAuthor,
                createBeginTime: (this.dateRange && this.dateRange[0]) || "",
                createEndTime: (this.dateRange && this.dateRange[1]) || "",
                isDelete: 0,
            };
            this.$http
                .requestPost({
                    url: "/knowledge/queryKnowledgeArticleList",
                    param: {
                        pageNum: this.queryParams.pageNum,
                        pageSize: this.queryParams.pageSize,
                        param: param
                    },
                    loading: false,
                })
                .then((res) => {
                    this.loading = false;
                    this.list = res.data.list;
                    this.total = res.data.total;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        //文章列表搜索
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getListArticle();
        },
        //关联文章搜索
        handleQuery_1() {
            this.relatedParams.pageNum = 1;
            this.association();
        },
        recommendArticle(row) {
            // 设置、取消推荐
            let titleName = row.recommendedStatus == 2 ? '取消推荐' : '设为推荐';
            let status = row.recommendedStatus == 2 ? 1 : 2
            this.$confirm("是否确认" + titleName, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                this.$http.requestPut({ url: `/knowledge/modifyKnowledgeArticleRecommended/${row.id}/${status}` }).then(res => {
                    this.$message({
                        type: 'success',
                        message: "操作成功"
                    })
                    this.getListArticle();
                })
            }).catch((err) => {
                console.log(err);
            });
        },
        //新增关联文章搜索
        handleQuery_2() {
            this.addRelatedParams.pageNum = 1;
            this.addAssociation();
        },
        handleSelectionChange(val) {
            // 选择关联
            console.log('val', val);
            this.multipleSelection = val;
        },
        //删除文章列表
        deleteArticle(row, type) {
            console.log(row, '888888')
            this.$confirm("是否确认删除文章？", "删除文章", {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$http.requestDel({ url: '/knowledge/removeKnowledgeArticle/' + row + '/' + type }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    })
                    this.getListArticle();
                })
            }).catch((err) => {
                console.log(err);
            });
        },
        submitForm() {
            THIS.openEdit = false
        },
        //新增关联文章确认
        submitFormAdd() {
            if (this.multipleSelection.length < 1) {
                return this.$message.warning("请选择要新增的文章");
            }
            let ids = []
            this.multipleSelection.forEach((e) => {
                ids.push(e.articleId)
            })
            this.$http
                .requestPut({
                    url: "/knowledge/addKnowledgeArticleRel",
                    param: {
                        articleId: this.id,
                        relArticleIdList: ids,
                    },
                    loading: false,
                })
                .then((res) => {
                    this.$message({
                        type: 'success',
                        message: '添加成功!'
                    })
                    console.log(res, '新增关联文章')
                    this.openAddEdit = false
                    this.association();
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        //新增关联文章查看详情
        associatedGroupChat(row) {
            console.log(row, '1111111111111111')
            this.openDetail = true
            this.$http
                .requestGet({
                    url: "/knowledge/knowledgeArticleDetail/" + row.articleId,
                    loading: false,
                })
                .then((res) => {
                    console.log(res, '查看详情')
                    this.formData = res.data
                    this.loading = false;
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err)
                });
        },
        // 获取类别
        getList() {
            this.$http.requestPost({
                url: "/knowledge/queryKnowledgeCategoryList",
                param: {
                    pageNum: 1,
                    pageSize: 1000,
                    param: {
                        categoryName: "" //知识库分类名称
                    }
                },
                loading: false,
            }).then((res) => {
                if(res.code === 200) {
                    this.queryCategoryList = res.data.list
                }
            }).catch((err) => {
                console.log(err)
            });
        },
		toVedio(type) {
			//  点击回显视频到弹框上
			if (type == 'top') {
				this.vedioUrl = this.formData.topVideo
			} else if (type == 'bottom') {
				this.vedioUrl = this.formData.buttonVideo
			} 
			this.showDialog_2 = true
		},
        closeEvent_2() {
            //  关闭视频在线预览弹框
            this.vedioUrl = ''
        },
        //删除关联文章列表
        deleteSource(row) {
            this.$confirm("是否确认删除文章？", "删除文章", {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$http.requestDel({ url: '/knowledge/removeKnowledgeArticleRel/' + row.relId }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    })
                    this.association();
                })
            }).catch((err) => {
                console.log(err);
            });
        },
        // 查看详情
        handleEdit(row) {
            this.$router.push({
                name: 'addArticle',
                query: {
                    id: row.id
                }
            });
        },
        //关联文章弹窗
        toContent(row) {
            this.id = row.id
            this.openEdit = true;
            this.association()
        },
        //关联文章
        association() {
            this.$http
                .requestPost({
                    url: '/knowledge/queryKnowledgeArticleRelList',
                    param: {
                        pageNum: this.relatedParams.pageNum,
                        pageSize: this.relatedParams.pageSize,
                        param: {
                            relType: 1, //关联类型 1.已关联 2.未关联
                            articleId: this.id, //文章主键id
                            articleTitle: this.relatedParams.articleTitle, //文章标题
                            articleAuthor: this.relatedParams.articleAuthor //作者
                        }
                    },
                })
                .then((res) => {
                    console.log('关联文章', res);
                    this.contentList = res.data.list
                    this.total_1 = res.data.total
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //新增
        addArticle() {
            this.openAddEdit = true
            this.addAssociation()
        },
        //关联文章新增
        addAssociation() {
            this.$http
                .requestPost({
                    url: '/knowledge/queryKnowledgeArticleRelList',
                    param: {
                        pageNum: this.addRelatedParams.pageNum,
                        pageSize: this.addRelatedParams.pageSize,
                        param: {
                            relType: 2, //关联类型 1.已关联 2.未关联
                            articleId: this.id, //文章主键id
                            articleTitle: this.addRelatedParams.articleTitle, //文章标题
                            articleAuthor: this.addRelatedParams.articleAuthor //作者
                        }
                    },
                })
                .then((res) => {
                    console.log('关联文章', res);
                    this.tableList = res.data.list
                    this.total_2 = res.data.total
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //关闭关联弹窗
        close() {
            this.openEdit = false;
            this.relatedParams.articleTitle = '';
            this.relatedParams.articleAuthor = '';
        },        
        close1() {
            this.openAddEdit = false;
            this.addRelatedParams.articleTitle = '';
            this.addRelatedParams.articleAuthor = '';
        },
        //新增文章跳转
        handleAdd() {
            this.$router.push({
                name: 'addArticle',
            });
        },
        // 文章列表页码改变
        handleSizeChange(size) {
            this.queryParams.pageNum = 1;
            this.queryParams.pageSize = size;
            this.getListArticle();
        },
        handleCurrentChange(page) {
            this.queryParams.pageNum = page;
            this.getListArticle();
        },
        // 关联文章页码改变
        handleSizeChange_1(size) {
            this.relatedParams.pageNum = 1;
            this.relatedParams.pageSize = size;
            this.association();
        },
        handleCurrentChange_1(page) {
            this.relatedParams.pageNum = page;
            this.association();
        },
        // 新增关联文章页码改变
        handleSizeChange_2(size) {
            this.addRelatedParams.pageNum = 1;
            this.addRelatedParams.pageSize = size;
            this.addAssociation();
        },
        handleCurrentChange_2(page) {
            this.addRelatedParams.pageNum = page;
            this.addAssociation();
        },
    },
}
</script>

<style lang="scss" scoped>
.border-section {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.btn-partition {
    color: #009bff;
}
.content {
    padding-bottom: 10px;
}
.editor {
	max-width: 90%;
	max-height: 400px;
	overflow-y: auto;
    line-height: 23px;
    padding: 10px 15px;
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    /deep/ img {
        width: 100%;
    }
}
.video {
	width: 100%;
	height: 500px;
}

.tip-box {
	position: relative;

	.tip {
		position: absolute;
		top: 26px;
		left: 34%;
		font-size: 12px;
		color: #ccc;
	}
}
</style>