var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.groupDialogTitle,
        "close-on-click-modal": false,
        width: "550px",
        top: "10vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "Popout_content" },
        [
          _c(
            "el-form",
            {
              ref: "submitFormRef",
              attrs: {
                "label-width": "100px",
                model: _vm.submitForm,
                rules: _vm.ruleFormRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分组名称", prop: "categoryName" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入分组名称",
                      "show-word-limit": "",
                      maxlength: "30",
                    },
                    model: {
                      value: _vm.submitForm.categoryName,
                      callback: function ($$v) {
                        _vm.$set(_vm.submitForm, "categoryName", $$v)
                      },
                      expression: "submitForm.categoryName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.groupButtonLoading },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.groupButtonLoading, type: "primary" },
              on: { click: _vm.groupSubmitAddOrEdit },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }