<template>
  <div>
    <el-dialog title="运营范围" :visible.sync="dialog_1" width="60%">
      <el-form>
        <el-form-item>
          <el-radio-group v-model="radio">
            <el-radio label="0">全国</el-radio>
            <el-radio label="1">区域</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div v-show="radio == '1'">
        <el-form :inline="true" label-position="right">
          <el-form-item label="医院名称：">
            <el-input style="width:260px;" v-model="hospitalName_1" placeholder="请输入医院名称"></el-input>
          </el-form-item>
          <el-form-item label="商家端名称：" style="margin-left:6%">
            <el-input style="width:260px;" v-model="merchantName_1" placeholder="请输入商家端名称"></el-input>
          </el-form-item>
        </el-form>
        <el-form :inline="true" label-position="right">
          <el-form-item label="所在区域：">
            <el-cascader v-if="addressList_1.length" style="width:260px;" v-model="addressModel_1" :props="props_1" :options="addressList_1" placeholder="请选项地址" collapse-tags clearable />
          </el-form-item>
          <el-form-item style="margin-left:6%">
            <el-button type="primary" @click="handleFormQuery_1">查询</el-button>
            <el-button type="primary" @click="handleBatchDelete_1">批量删除</el-button>
            <el-button type="primary" @click="handleAdd_1">添加</el-button>
          </el-form-item>
        </el-form>
        <el-table ref="multipleTable1" v-loading="loading_1" :data="tableData_1" @selection-change="handleSelectionChange_1" :row-key="getRowKeys_1" border height="400px" style="width: 100%">
          <el-table-column type="selection" align="center" :reserve-selection="true"></el-table-column>
          <el-table-column label="医院编号" prop="hospitalCode" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="医院名称" prop="hospitalName" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="省份" prop="province" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="城市" prop="city" align="center"></el-table-column>
          <el-table-column label="区/县" prop="area" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="商家名称" prop="merchantName" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="operation-btn" @click="handleDelete_1(scope.row)">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination :current-page="pageParam_1.pageNum" :page-size="pageParam_1.pageSize" :page-sizes="[10, 20, 50, 100, 1000]" :total="pageParam_1.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange_1" @current-change="handleCurrentChange_1"></el-pagination>
        </div>
      </div>
      <div style="padding-bottom: 20px;margin-top: 20px;text-align: right;">
        <el-button @click="handleDialogClose_1" style="width:90px;">取 消</el-button>
        <el-button type="primary" @click="handleDialogSubmit_1" style="width:90px;">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增运营范围" :visible.sync="dialog_2" width="60%">
      <div>
        <el-form :inline="true" label-position="right">
          <el-form-item label="医院名称：">
            <el-input style="width:260px;" v-model="hospitalName_2" placeholder="请输入医院名称"></el-input>
          </el-form-item>
          <el-form-item label="商家端名称：" style="margin-left:6%">
            <el-input style="width:260px;" v-model="merchantName_2" placeholder="请输入商家端名称"></el-input>
          </el-form-item>
        </el-form>
        <el-form :inline="true" label-position="right">
          <el-form-item label="所在区域：">
            <el-cascader v-if="addressList_2.length" style="width:260px;" v-model="addressModel_2" :props="props_2" :options="addressList_2" placeholder="请选项地址" collapse-tags clearable />
          </el-form-item>
          <el-form-item style="margin-left:6%">
            <el-button type="primary" @click="handleFormQuery_2">查询</el-button>
          </el-form-item>
        </el-form>
        <el-table ref="multipleTable2" v-loading="loading_2" :data="tableData_2" @selection-change="handleSelectionChange_2" :row-key="getRowKeys_2" border height="400px" style="width: 100%">
          <el-table-column type="selection" align="center" :reserve-selection="true"></el-table-column>
          <el-table-column label="医院编号" prop="hospitalCode" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="医院名称" prop="hospitalName" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="省份" prop="province" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="城市" prop="city" align="center"></el-table-column>
          <el-table-column label="区/县" prop="area" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="商家名称" prop="merchantName" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination :current-page="pageParam_2.pageNum" :page-size="pageParam_2.pageSize" :page-sizes="[10, 20, 50, 100, 1000]" :total="pageParam_2.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange_2" @current-change="handleCurrentChange_2"></el-pagination>
        </div>
        <div style="padding-bottom: 20px;margin-top: 20px;text-align: right;">
          <el-button @click="handleDialogClose_2" style="width:90px;">取 消</el-button>
          <el-button type="primary" @click="handleDialogSubmit_2" style="width:90px;">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showCity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 单选框 城市和全国
      radio: "0",
      // 已关联的城市弹框
      dialog_1: false,
      hospitalName_1: "",
      merchantName_1: "",
      addressList_1: [],
      addressModel_1: [],
      props_1: {
        label: "addressName",
        value: "addressId",
        children: "child",
        multiple: true,
        emitPath: false,
      },
      pageParam_1: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableData_1: [],
      selectedItems_1: [],
      loading_1: false,
      // 未关联的城市弹框
      dialog_2: false,
      hospitalName_2: "",
      merchantName_2: "",
      addressList_2: [],
      addressModel_2: [],
      props_2: {
        label: "addressName",
        value: "addressId",
        children: "child",
        multiple: true,
        emitPath: false,
      },
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableData_2: [],
      selectedItems_2: [],
      loading_2: false,
    };
  },
  created() {},
  mounted() {
    this.dialog_1 = this.showCity;
    if(this.dialog_1){
      this.$refs.multipleTable1 && this.$refs.multipleTable1.clearSelection();
    }
    this.handleIsCount();
    this.handleGetAddress_1();
  },
  methods: {
    handleDialogSubmit_1() {
      if(this.radio == '0'){
      let param = {
        typeId: JSON.parse(sessionStorage.getItem("cityId")) || "",
        type: "2",
        displayType: 0,
        districtList: [],
      };
      this.$http
        .requestPost({
          url: "/systemDistrict/editDistrictCity",
          param,
          loading: false,
        })
        .then((res) => {
          console.log(res);
        })
      }
      this.dialog_1 = false;
      this.$emit("setCityValue", false);
    },
    handleDialogClose_1() {
      this.dialog_1 = false;
      this.$emit("setCityValue", false);
    },
    handleIsCount(){
      let param = {
          typeId: JSON.parse(sessionStorage.getItem("cityId")) || "",
          type: "2",
      }
      this.$http
        .requestPost({
          url: "/systemDistrict/isCountry",
          param,
          loading: false,
        })
        .then((res) => {
          if(res.data){
            this.radio = '0'
          }else{
            this.radio = '1'
            this.handleInit_1();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 弹框1
    handleInit_1() {
      let param = {
        pageNum: this.pageParam_1.pageNum,
        pageSize: this.pageParam_1.pageSize,
        param: {
          queryType: "1",
          typeId: JSON.parse(sessionStorage.getItem("cityId")) || "",
          type: "2",
        },
      };
      this.$http
        .requestPost({
          url: "/systemDistrict/hospital/district/list",
          param,
          loading: false,
        })
        .then((res) => {
          this.tableData_1 = res.data.list;
          this.pageParam_1.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleGetAddress_1() {
      this.$http
        .requestGet({ url: "/common/address", loading: false })
        .then((res) => {
          this.addressList_1 = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询表格
    handleFormQuery_1() {
      this.loading_1 = true;
      let param = {
        pageNum: this.pageParam_1.pageNum,
        pageSize: this.pageParam_1.pageSize,
        param: {
          queryType: "1",
          typeId: JSON.parse(sessionStorage.getItem("cityId")) || "",
          type: "2",
          hospitalName: this.hospitalName_1,
          merchantName: this.merchantName_1,
          addressIds: this.addressModel_1,
        },
      };
      this.$http
        .requestPost({
          url: "/systemDistrict/hospital/district/list",
          param,
          loading: false,
        })
        .then((res) => {
          this.tableData_1 = res.data.list;
          this.pageParam_1.total = res.data.total;
          this.loading_1 = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 页容量改变
    handleSizeChange_1(size) {
      this.pageParam_1.pageNum = 1;
      this.pageParam_1.pageSize = size;
      // 刷新
      this.handleFormQuery_1();
    },
    // 页码改变
    handleCurrentChange_1(page) {
      this.pageParam_1.pageNum = page;
      // 刷新
      this.handleFormQuery_1();
    },
    // 勾选数据
    handleSelectionChange_1(val) {
      this.selectedItems_1 = val;
    },
    getRowKeys_1(row) {
      return row.hospitalId;
    },
    handleDelete_1(val) {
      let unbindList = [];
      unbindList.push({
        hospitalId: val.hospitalId,
      });
      let param = {
        typeId: JSON.parse(sessionStorage.getItem("cityId")) || "",
        type: "2",
        districtList: unbindList,
      };
      this.$confirm("是否确认删除医院?", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.$http
          .requestPost({
            url: "/systemDistrict/delDistrictCity",
            param,
            loading: false,
          })
          .then((res) => {
            this.handleFormQuery_1();
            this.$message({
              message: "操作成功",
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    handleBatchDelete_1() {
      let unbindList = [];
      if (this.selectedItems_1.length === 0) {
        this.$message.error("请选择需要批量删除的数据!");
        return;
      }
      this.selectedItems_1.map((item, index) => {
        unbindList.push({
          hospitalId: item.hospitalId,
        });
      });
      let param = {
        typeId: JSON.parse(sessionStorage.getItem("cityId")) || "",
        type: "2",
        districtList: unbindList,
      };
      this.$confirm("是否确认批量删除医院?", "确认删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.$http
          .requestPost({
            url: "/systemDistrict/delDistrictCity",
            param,
            loading: false,
          })
          .then((res) => {
            this.handleFormQuery_1();
            this.$message({
              message: "操作成功",
              type: "success",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    handleAdd_1() {
      this.hospitalName_2 = '';
      this.merchantName_2 = '';
      this.addressModel_2 = [];
      this.dialog_2 = true;
      this.$refs.multipleTable2 && this.$refs.multipleTable2.clearSelection();
      this.handleGetAddress_2();
      this.handleInit_2();
    },

    // 弹框2
    handleInit_2() {
      let param = {
        pageNum: this.pageParam_1.pageNum,
        pageSize: this.pageParam_1.pageSize,
        param: {
          queryType: "2",
          typeId: JSON.parse(sessionStorage.getItem("cityId")) || "",
          type: "2",
        },
      };
      this.$http
        .requestPost({
          url: "/systemDistrict/hospital/district/list",
          param,
          loading: false,
        })
        .then((res) => {
          this.tableData_2 = res.data.list;
          this.pageParam_2.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleGetAddress_2() {
      this.$http
        .requestGet({ url: "/common/address", loading: false })
        .then((res) => {
          this.addressList_2 = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询表格
    handleFormQuery_2() {
      this.loading_2 = true;
      let param = {
        pageNum: this.pageParam_2.pageNum,
        pageSize: this.pageParam_2.pageSize,
        param: {
          queryType: "2",
          typeId: JSON.parse(sessionStorage.getItem("cityId")) || "",
          type: "2",
          hospitalName: this.hospitalName_2,
          merchantName: this.merchantName_2,
          addressIds: this.addressModel_2,
        },
      };
      this.$http
        .requestPost({
          url: "/systemDistrict/hospital/district/list",
          param,
          loading: false,
        })
        .then((res) => {
          this.tableData_2 = res.data.list;
          this.pageParam_2.total = res.data.total;
          this.loading_2 = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1;
      this.pageParam_2.pageSize = size;
      // 刷新
      this.handleFormQuery_2();
    },
    // 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page;
      // 刷新
      this.handleFormQuery_2();
    },
    // 勾选数据
    handleSelectionChange_2(val) {
      this.selectedItems_2 = val;
    },
    getRowKeys_2(row) {
      return row.hospitalId;
    },
    handleDialogSubmit_2() {
      let bindList = [];
      if (this.selectedItems_2.length === 0) {
        this.$message.error("请选择需要绑定的数据!");
        return;
      }
      this.selectedItems_2.map((item, index) => {
        bindList.push({
          hospitalId: item.hospitalId,
        });
      });
      let param = {
        typeId: JSON.parse(sessionStorage.getItem("cityId")) || "",
        type: "2",
        displayType: 1,
        districtList: bindList,
      };
      this.$http
        .requestPost({
          url: "/systemDistrict/editDistrictCity",
          param,
          loading: false,
        })
        .then((res) => {
          this.handleFormQuery_1();
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialog_2 = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDialogClose_2() {
      this.dialog_2 = false;
    },
  },
};
</script>