var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-box" }, [
    _c("div", { staticClass: "row_box bg_white" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "content " }, [
        _c(
          "div",
          { staticClass: "search-row" },
          [
            _c(
              "el-form",
              {
                staticClass: "demo-form-inline",
                attrs: { inline: true, size: "medium" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "时间段" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            return _vm.disposeTimeShow(1)
                          },
                        },
                        model: {
                          value: _vm.inquire_1.dayOrMonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_1, "dayOrMonth", $$v)
                          },
                          expression: "inquire_1.dayOrMonth",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "按日统计", value: 0 },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "按月统计", value: 1 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-date-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.inquire_1.dayOrMonth === 0,
                          expression: "inquire_1.dayOrMonth === 0",
                        },
                      ],
                      attrs: {
                        type: "datetimerange",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        "default-time": ["00:00:00", "23:59:59"],
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.daterange_1,
                        callback: function ($$v) {
                          _vm.daterange_1 = $$v
                        },
                        expression: "daterange_1",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-date-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.inquire_1.dayOrMonth === 1,
                          expression: "inquire_1.dayOrMonth === 1",
                        },
                      ],
                      attrs: {
                        type: "monthrange",
                        "value-format": "yyyy-MM",
                        "range-separator": "至",
                        "start-placeholder": "开始月份",
                        "end-placeholder": "结束月份",
                      },
                      model: {
                        value: _vm.daterange_1,
                        callback: function ($$v) {
                          _vm.daterange_1 = $$v
                        },
                        expression: "daterange_1",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.userInfo.managerType != "agency"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "区域" } },
                      [
                        _vm.$public.isAllAreaPower()
                          ? _c("el-cascader", {
                              attrs: {
                                options: _vm.addressList,
                                props: _vm.props,
                                clearable: "",
                              },
                              model: {
                                value: _vm.address_1,
                                callback: function ($$v) {
                                  _vm.address_1 = $$v
                                },
                                expression: "address_1",
                              },
                            })
                          : _c("d-area", {
                              ref: "area_1",
                              attrs: { values: _vm.address_1, "all-val": "" },
                              on: {
                                "update:values": function ($event) {
                                  _vm.address_1 = $event
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "产品类别" } },
                  [
                    _c("el-cascader", {
                      attrs: {
                        props: _vm.productProps,
                        options: _vm.productTypeList,
                        clearable: "",
                      },
                      on: { change: _vm.categoryChange },
                      model: {
                        value: _vm.categoryId,
                        callback: function ($$v) {
                          _vm.categoryId = $$v
                        },
                        expression: "categoryId",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "el-select",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.productList.length > 0,
                            expression: "productList.length > 0",
                          },
                        ],
                        staticClass: "width-260",
                        attrs: { placeholder: "请选择产品", clearable: "" },
                        model: {
                          value: _vm.inquire_1.productId,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_1, "productId", $$v)
                          },
                          expression: "inquire_1.productId",
                        },
                      },
                      _vm._l(_vm.productList, function (item) {
                        return _c("el-option", {
                          key: item.productId,
                          attrs: {
                            label: item.productName,
                            value: item.productId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.platformSalesTrendAnalysis },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-box" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "data-statistics" }, [
            _c("label", [_vm._v("数据统计")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "销售额：" +
                    _vm._s(_vm.salesPlatformData.totalPrice || "0:00") +
                    "元"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "订单数：" + _vm._s(_vm.salesPlatformData.orderCount) + "单"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "商家数：" + _vm._s(_vm.salesPlatformData.merchant) + "家"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "下单用户量：" +
                    _vm._s(_vm.salesPlatformData.userCount) +
                    "位"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "平均送达时长：" +
                    _vm._s(_vm.salesPlatformData.arriveTime || 0) +
                    "/m"
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row_box bg_white" }, [
      _vm._m(2),
      _vm._v(" "),
      _c("div", { staticClass: "content " }, [
        _c(
          "div",
          { staticClass: "search-row" },
          [
            _c(
              "el-form",
              {
                staticClass: "demo-form-inline",
                attrs: { inline: true, size: "medium" },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "时间段" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            return _vm.disposeTimeShow(3)
                          },
                        },
                        model: {
                          value: _vm.inquire_3.dayOrMonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_3, "dayOrMonth", $$v)
                          },
                          expression: "inquire_3.dayOrMonth",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "按日统计", value: 0 },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "按月统计", value: 1 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "" } },
                  [
                    _c("el-date-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.inquire_3.dayOrMonth === 0,
                          expression: "inquire_3.dayOrMonth === 0",
                        },
                      ],
                      attrs: {
                        type: "datetimerange",
                        "value-format": "yyyy-MM-dd HH:mm:ss",
                        "default-time": ["00:00:00", "23:59:59"],
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期",
                      },
                      model: {
                        value: _vm.daterange_3,
                        callback: function ($$v) {
                          _vm.daterange_3 = $$v
                        },
                        expression: "daterange_3",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-date-picker", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.inquire_3.dayOrMonth === 1,
                          expression: "inquire_3.dayOrMonth === 1",
                        },
                      ],
                      attrs: {
                        type: "monthrange",
                        "value-format": "yyyy-MM",
                        "range-separator": "至",
                        "start-placeholder": "开始月份",
                        "end-placeholder": "结束月份",
                      },
                      model: {
                        value: _vm.daterange_3,
                        callback: function ($$v) {
                          _vm.daterange_3 = $$v
                        },
                        expression: "daterange_3",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.userInfo.managerType != "agency"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "区域" } },
                      [
                        _vm.$public.isAllAreaPower()
                          ? _c("el-cascader", {
                              attrs: {
                                options: _vm.addressList,
                                props: _vm.props,
                                clearable: "",
                              },
                              model: {
                                value: _vm.address_3,
                                callback: function ($$v) {
                                  _vm.address_3 = $$v
                                },
                                expression: "address_3",
                              },
                            })
                          : _c("d-area", {
                              ref: "area_3",
                              attrs: { values: _vm.address_3, "all-val": "" },
                              on: {
                                "update:values": function ($event) {
                                  _vm.address_3 = $event
                                },
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { label: "业务" } },
                  [
                    _c(
                      "el-select",
                      {
                        attrs: { placeholder: "请选择" },
                        model: {
                          value: _vm.inquire_3.productClassify,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_3, "productClassify", $$v)
                          },
                          expression: "inquire_3.productClassify",
                        },
                      },
                      _vm._l(_vm.businessList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: { click: _vm.platformUserTrendChart },
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex-box" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "data-statistics" }, [
            _c("label", [_vm._v("数据统计")]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "消费者：" +
                    _vm._s(_vm.platFormUserData.getCountMember) +
                    "位"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "平均消费金额：" +
                    _vm._s(_vm.platFormUserData.getAVGManey) +
                    "元"
                ),
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "平均消费次数：" +
                    _vm._s(_vm.platFormUserData.getAVGTime) +
                    "次"
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bg_white box-m-b box-p-t" }, [
      _c(
        "div",
        { staticClass: "search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "时间段" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetimerange",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": ["00:00:00", "23:59:59"],
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                    },
                    model: {
                      value: _vm.daterange_4,
                      callback: function ($$v) {
                        _vm.daterange_4 = $$v
                      },
                      expression: "daterange_4",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.userInfo.managerType != "agency"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "区域" } },
                    [
                      _vm.$public.isAllAreaPower()
                        ? _c("el-cascader", {
                            attrs: {
                              options: _vm.addressList,
                              props: _vm.props,
                              clearable: "",
                            },
                            model: {
                              value: _vm.address_4,
                              callback: function ($$v) {
                                _vm.address_4 = $$v
                              },
                              expression: "address_4",
                            },
                          })
                        : _c("d-area", {
                            ref: "area_4",
                            attrs: { values: _vm.address_4, "all-val": "" },
                            on: {
                              "update:values": function ($event) {
                                _vm.address_4 = $event
                              },
                            },
                          }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.getPOT5DataList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex-box table-row " }, [
      _c("div", { staticClass: "row_box bg_white table-box" }, [
        _c("div", { staticClass: "header flex-box" }, [
          _c("span", [_vm._v("医药品销售TOP5")]),
          _vm._v(" "),
          _vm.isPermission("pharmaceuticalSalesDataStatement")
            ? _c(
                "span",
                { staticClass: "operation-btn" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/financial/financialStatement/pharmaceuticalSalesDataStatement",
                      },
                    },
                    [_vm._v("查看明细")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content " },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.top5Loading,
                    expression: "top5Loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.pharmaceuticalSalesList, height: "277px" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "date",
                    label: "排名",
                    "min-width": "60",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.$index < 3
                            ? _c(
                                "div",
                                { staticClass: "flex-box flex-justify" },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.rankingList[scope.$index],
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            : _c("div", [_vm._v(_vm._s(scope.$index + 1))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "产品名称",
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "salesCount",
                    label: "销售数量",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(scope.row.salesCount)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white table-box" }, [
        _c("div", { staticClass: "header flex-box" }, [
          _c("span", [_vm._v("电商品销售TOP5")]),
          _vm._v(" "),
          _vm.isPermission("electronicCommoditySalesDataReport")
            ? _c(
                "span",
                { staticClass: "operation-btn" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/financial/financialStatement/electronicCommoditySalesDataReport",
                      },
                    },
                    [_vm._v("查看明细")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content " },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.top5Loading,
                    expression: "top5Loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: {
                  data: _vm.salesOfElectronicGoodsList,
                  height: "277px",
                },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "date",
                    label: "排名",
                    "min-width": "60",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.$index < 3
                            ? _c(
                                "div",
                                { staticClass: "flex-box flex-justify" },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.rankingList[scope.$index],
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            : _c("div", [_vm._v(_vm._s(scope.$index + 1))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "产品名称",
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "salesCount",
                    label: "销售数量",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(scope.row.salesCount)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex-box table-row " }, [
      _c("div", { staticClass: "row_box bg_white table-box" }, [
        _c("div", { staticClass: "header flex-box" }, [
          _c("span", [_vm._v("医院销售TOP5")]),
          _vm._v(" "),
          _vm.isPermission("hospitalSalesStatistics")
            ? _c(
                "span",
                { staticClass: "operation-btn" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/financial/financialStatement/hospitalSalesStatistics",
                      },
                    },
                    [_vm._v("查看明细")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content " },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.top5Loading,
                    expression: "top5Loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.thePharmacySalesList, height: "277px" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "date",
                    label: "排名",
                    "min-width": "60",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.$index < 3
                            ? _c(
                                "div",
                                { staticClass: "flex-box flex-justify" },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.rankingList[scope.$index],
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            : _c("div", [_vm._v(_vm._s(scope.$index + 1))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "hospitalName",
                    label: "医院名称",
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "salesAmount",
                    label: "销售额",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(scope.row.salesAmount)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white table-box" }, [
        _c("div", { staticClass: "header flex-box" }, [
          _c("span", [_vm._v("区域销售TOP5")]),
          _vm._v(" "),
          _vm.isPermission("regionalSalesStatistics")
            ? _c(
                "span",
                { staticClass: "operation-btn" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/financial/financialStatement/regionalSalesStatistics",
                      },
                    },
                    [_vm._v("查看明细")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content " },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.top5Loading,
                    expression: "top5Loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.regionalSalesList, height: "277px" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "date",
                    label: "排名",
                    "min-width": "60",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.$index < 3
                            ? _c(
                                "div",
                                { staticClass: "flex-box flex-justify" },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.rankingList[scope.$index],
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            : _c("div", [_vm._v(_vm._s(scope.$index + 1))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "areaName",
                    label: "区域名称",
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "salesAmount",
                    label: "销售额",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(scope.row.salesAmount)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "flex-box table-row " }, [
      _c("div", { staticClass: "row_box bg_white table-box" }, [
        _c("div", { staticClass: "header flex-box" }, [
          _c("span", [_vm._v("商家销售TOP5")]),
          _vm._v(" "),
          _vm.isPermission("salesStatistics")
            ? _c(
                "span",
                { staticClass: "operation-btn" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/financial/financialStatement/salesStatistics",
                      },
                    },
                    [_vm._v("查看明细")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content " },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.top5Loading,
                    expression: "top5Loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.merchantsSellList, height: "277px" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "date",
                    label: "排名",
                    "min-width": "60",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.$index < 3
                            ? _c(
                                "div",
                                { staticClass: "flex-box flex-justify" },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.rankingList[scope.$index],
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            : _c("div", [_vm._v(_vm._s(scope.$index + 1))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "merchantName",
                    label: "商家名称",
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "salesAmount",
                    label: "销售额",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(scope.row.salesAmount)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white table-box" }, [
        _c("div", { staticClass: "header flex-box" }, [
          _c("span", [_vm._v("专员业绩TOP5")]),
          _vm._v(" "),
          _vm.isPermission("specialistSalesStatistics")
            ? _c(
                "span",
                { staticClass: "operation-btn" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: "/financial/financialStatement/specialistSalesStatistics",
                      },
                    },
                    [_vm._v("查看明细")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content " },
          [
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.top5Loading,
                    expression: "top5Loading",
                  },
                ],
                staticStyle: { width: "100%" },
                attrs: { data: _vm.attacheSalesList, height: "277px" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "date",
                    label: "排名",
                    "min-width": "60",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.$index < 3
                            ? _c(
                                "div",
                                { staticClass: "flex-box flex-justify" },
                                [
                                  _c("div", { staticClass: "img-box" }, [
                                    _c("img", {
                                      attrs: {
                                        src: _vm.rankingList[scope.$index],
                                        alt: "",
                                      },
                                    }),
                                  ]),
                                ]
                              )
                            : _c("div", [_vm._v(_vm._s(scope.$index + 1))]),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "attacheName",
                    label: "专员姓名",
                    "min-width": "160",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "salesAmount",
                    label: "销售额",
                    "min-width": "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "color-red" }, [
                            _vm._v(_vm._s(scope.row.salesAmount)),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header flex-box" }, [
      _c("span", [_vm._v("平台销售额趋势分析")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "echars-box" }, [
      _c("div", { attrs: { id: "sales-platform_box" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header flex-box" }, [
      _c("span", [_vm._v("平台用户趋势图")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "echars-box" }, [
      _c("div", { attrs: { id: "user-platform_box" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }