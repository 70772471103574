<template>
    <!-- 电商品-待付款订单 -->
    <div class="main-box">
        <x-Nutrition orderStatus="pending_payment"></x-Nutrition>
    </div>
</template>

<script>
import xNutrition from '@/components/order/xNutrition.vue'

export default {
    components: {
        xNutrition
    },
    data() {
        return {
        
        };
    },
    methods: {
       
    },
};
</script>

<style lang="scss" scoped></style>
