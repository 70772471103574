var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-preview" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.showDialog,
            modal: false,
            "before-close": _vm.close,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-dynamic",
                  attrs: { model: _vm.inquire, "label-width": "100px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商家名称" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "90%" },
                        attrs: { placeholder: "商家名称" },
                        model: {
                          value: _vm.inquire.merchantName,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "merchantName", $$v)
                          },
                          expression: "inquire.merchantName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "产品名称" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "90%" },
                        attrs: { placeholder: "产品名称" },
                        model: {
                          value: _vm.inquire.productName,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "productName", $$v)
                          },
                          expression: "inquire.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "form-select",
                          staticStyle: { width: "90%" },
                          attrs: { placeholder: "请选择", clearable: "" },
                          model: {
                            value: _vm.inquire.purchaseStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.inquire, "purchaseStatus", $$v)
                            },
                            expression: "inquire.purchaseStatus",
                          },
                        },
                        _vm._l(
                          this.productType == "purchase"
                            ? _vm.purchaseOption
                            : _vm.purchaseReturnOption,
                          function (item) {
                            return _c("el-option", {
                              key: item.key,
                              attrs: { label: item.value, value: item.key },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.sgDateTitle } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "90%" },
                        attrs: {
                          "end-placeholder": "结束日期",
                          "start-placeholder": "开始日期",
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.inquire.sgDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "sgDate", $$v)
                          },
                          expression: "inquire.sgDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "90%" },
                        attrs: {
                          "end-placeholder": "结束日期",
                          "start-placeholder": "开始日期",
                          type: "daterange",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.inquire.daterange,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire, "daterange", $$v)
                          },
                          expression: "inquire.daterange",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("关 闭")]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.exportFile } },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }