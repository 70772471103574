<template>
    <!-- 待付款订单 -->
    <div class="main-box">
        <div class="head-search-row">
            <el-form :inline="true" class="demo-form-inline" size="medium">
                <el-form-item label="文件名">
                    <el-input v-model="inquire.fileName" placeholder="请输入文件名" @keyup.enter.native="searchList"></el-input>
                </el-form-item>

               
                <el-form-item label="">
                    <div class="flex-box">
                        <!-- <el-cascader v-model="categoryId" :options="productTypeList" @change="categoryChange" clearable></el-cascader> -->
                        <el-select v-model="inquire.status" clearable v-show="productTypeList.length > 0" placeholder="请选择状态" class="width-260">
                            <el-option v-for="(item,i) in productTypeList" :key="i" :label="item.label" :value="item.value" />
                        </el-select>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button size="small" type="primary" @click="searchList">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" @click="batchDelete">删除</el-button>
                </el-form-item>
            </el-form>
        </div>

        <!-- <div style="margin: 20px 0 0 20px">
            <el-button size="small" type="primary" @click="exportOrderList">批量下载</el-button>
        </div> -->

        <div class="table">
            <el-main>
                <template>
                    <el-table ref="multiTable" v-loading="loading" :data="tableData" @selection-change="handleSelectionChange" :row-key="getRowKey" border height="400px" style="width: 100%">
                        <el-table-column type="selection" width="60" :reserve-selection="true" align="center"></el-table-column>
                        <el-table-column label="文件名" min-width="140" prop="fileName" show-overflow-tooltip> </el-table-column>
                        <!-- <el-table-column label="文件地址" min-width="220" prop="fileUrl" show-overflow-tooltip> </el-table-column> -->
                        <el-table-column label="导出时间" prop="createTime"></el-table-column>
                        <el-table-column label="状态" prop="">
                            <template slot-scope="scope">
                                {{scope.row.statusValue}}
                            </template>
                        </el-table-column>
                        <el-table-column fixed="right" label="操作" prop="">
                            <template slot-scope="scope">
                                <span class="operation-btn" v-if="scope.row.status == 'KXZ'"><a :href="scope.row.fileUrl" download>下载</a></span>
                                <span class="operation-btn_partition"  v-if="scope.row.status == 'KXZ'"> / </span>
                                
                                <span class="operation-btn" @click="handleDelete(scope.row)" >删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination
                        :current-page="pageParam.pageNum"
                        :page-size="pageParam.pageSize"
                        :page-sizes="$api.pageSizes"
                        :total="pageParam.total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </el-main>
        </div>


        <productExport :inquire="inquire" :showDialog="showExportDialog" :close.sync="showExportDialog" />
    </div>
</template>

<script>
import productExport from "@/components/productExport";
import {exportfileDownload, deleteLogic, exportfileDownloadBatch} from '@/api/order'
export default {
    components: {
        productExport
    },
    data() {
        return {
            // 产品分类
            productTypeList: [
                {label:'处理中',value:'CLZ'},
                {label:'可下载',value:'KXZ'},
                {label:'导出失败',value:'DCSB'},
            ],
            productList: [],
            categoryId: '',


            inquire: {
                fileName: '',
                // endTime: '',
                // beginTime: '',
                type: 'purchase',
                status: '',
            },
            datetimerange: [],

            // 分页
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            // 表格数据
            tableData: [],
            loading: false,
            expands: [],// 当前展示行

            editRow: {},


            // ========= 导出弹窗 =========
            showExportDialog: false,
            selectedRows: [], // 选中的行
        };
    },
    created() {
        this.getTableData()
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size;
            this.getTableData()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page;
            this.getTableData();
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1
            this.getTableData()
        },
        // table 手风琴
        expandChange(news, old) {
            if (old.length === 0) {
                return this.expands = [];
            }
            this.expands = [news.orderId];
        },
        // 多选
        handleSelectionChange(e){
            this.selectIds = e;
        },
        
        // 导出
        fnExportOrderList() {
            if (!this.datetimerange) this.datetimerange = [];
            this.inquire.orderTimeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : '';
            this.inquire.orderTimeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : '';
            this.inquire.categoryId = this.categoryId
            this.showExportDialog = true;
        },

        // 获取列表数据
        getTableData() {
            // if (!this.datetimerange) this.datetimerange = [];
            // this.inquire.beginTime = this.datetimerange.length > 0 ? this.datetimerange[0] : '';
            // this.inquire.endTime = this.datetimerange.length > 0 ? this.datetimerange[1] : '';
            let data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: JSON.parse(JSON.stringify(this.inquire))
            }
            this.loading = true;
            this.$http.requestPost({
                url: '/exportfile/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false;
                this.tableData = res.data.list;
                this.pageParam.total = res.data.total;
            }).catch(err => {
                this.loading = false;
                console.log(err)
            })
        },

        // 批量下载
        exportOrderList(){
            if(this.selectIds.length <= 0) return;
            let ids = '';
            this.selectIds.forEach((item)=>{
                ids += item.id + ','
            })
            exportfileDownloadBatch({ids:ids}).then(res=>{
                this.$public.downloadFile(res)
            })
        },
        // 文件删除
        handleDelete(row){
            this.$confirm('确定删除该文件?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    deleteLogic(row.id).then(res => {
                        this.$message.success('删除成功')
                        this.getTableData()
                    })
                }).catch(() => {});
            
        },
        getRowKey(row) {
            return row.id;
        },
        // 当选择项发生变化时会触发该事件
        handleSelectionChange(row) {
            this.selectedRows = []
            row.map((item, index) => {
                this.selectedRows.push(item.id);
            });
        },
        batchDelete(){
            let param = this.selectedRows
            if(this.selectedRows.length == 0){
                return this.$message.error("需勾选一个以上的文件数量，才能删除");
            }
            this.$confirm('是否删除已选择的列表文件?', '删除导出列表文件', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$http.requestDel({ url: "/exportfile/deleteByIds", param }).then(res => {
                        if(res.code == 200){
                            this.$message.success("操作成功!");
                            this.getTableData()
                            this.selectedRows = []
                        }
                });
            }).catch(() => {});
        }
    },
};
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
    width: 375px;
}

.el-head-search-row {
    .flex-box {
        width: 326px;
    }
}

.show-money {
    padding: 10px 0;

    ul {
        li {
            display: flex;
            justify-content: flex-end;
            line-height: 30px;
            font-size: 16px;
        }

        .line-box {
            margin-left: 50%;
            border-bottom: 1px solid #ccc;
        }

        .bold {
            font-weight: bolder;
        }
    }
}

.block_span {
    display: inline-block;
    width: 108px;
    text-align: right;
}
</style>
