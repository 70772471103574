import request from '@/utils/request'

// 导出成员
export function exportMember(param = {}, method = 'get',filename) {

    return request.directDownload({
        url: '/consult/exportMember',
        param,
        method,
        filename
    })
}
// 导出档案/营养记录等
export function exportMemberQuestionnaire(param = {}, method = 'post',filename) {
    return request.directDownload({
        url: '/consult/exportMemberQuestionnaire',
        param,
        method,
        filename
    })
}