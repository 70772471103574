<!-- 产品库【经销产品管理 -- 新增编辑产品】 -->
<template>
  <div class="main-box">
    <ShareAddProduct :external-flag="false" :product-type="'cross_border'" />
  </div>
</template>

<script>
import ShareAddProduct from '../components/shareAddProduct'

export default {
  components: {
    ShareAddProduct
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="scss" scoped></style>
