<template>
<!-- 医院管理 -->
  <div><router-view /></div>
</template>

<script>
export default {}
</script>

<style>
</style>