var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "产品类别" } },
                [
                  _c("el-cascader", {
                    attrs: {
                      props: _vm.props,
                      options: _vm.productTypeList,
                      clearable: "",
                    },
                    model: {
                      value: _vm.inquire.categoryId,
                      callback: function ($$v) {
                        _vm.$set(_vm.inquire, "categoryId", $$v)
                      },
                      expression: "inquire.categoryId",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "产品状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "width-140",
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.inquire.productStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "productStatus", $$v)
                        },
                        expression: "inquire.productStatus",
                      },
                    },
                    _vm._l(_vm.productStatusList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-form-item", { attrs: { label: "" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c(
                      "el-select",
                      {
                        staticClass: "width-140",
                        attrs: { placeholder: "请选择" },
                        on: {
                          change: function ($event) {
                            _vm.selectValue = ""
                          },
                        },
                        model: {
                          value: _vm.selectKey,
                          callback: function ($$v) {
                            _vm.selectKey = $$v
                          },
                          expression: "selectKey",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "SKU编号", value: "inventoryId" },
                        }),
                        _vm._v(" "),
                        _c("el-option", {
                          attrs: { label: "产品名称", value: "productName" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("el-input", {
                      staticClass: "width-180",
                      attrs: { placeholder: "" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchList($event)
                        },
                      },
                      model: {
                        value: _vm.selectValue,
                        callback: function ($$v) {
                          _vm.selectValue = $$v
                        },
                        expression: "selectValue",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.searchList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: { data: _vm.tableData, border: "", height: "400px" },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "序号", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  (_vm.pageParam.pageNum - 1) *
                                    _vm.pageParam.pageSize +
                                    (scope.$index + 1)
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventoryId",
                        label: "SKU编号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleInventory(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.inventoryId))]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productCode",
                        label: "SPU编号",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "norm",
                        label: "规格",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productName",
                        label: "产品名称",
                        "min-width": "200",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "categoryName",
                        label: "所属类别",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventorySalesPrice",
                        label: "销售价",
                        "min-width": "120",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "inventoryTotal",
                        label: "库存",
                        "min-width": "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "productStatus",
                        label: "状态",
                        "min-width": "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.getStatusMsg(scope.row))),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "",
                        label: "操作",
                        "min-width": "100",
                        fixed: "right",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleInventory(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("库存")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              "库存-" +
              _vm.selectProductName +
              "（" +
              _vm.selectProductInventory +
              "）",
            visible: _vm.dialogVisible,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c("el-form-item", { attrs: { label: "" } }, [
                    _c(
                      "div",
                      { staticClass: "flex-box" },
                      [
                        _c(
                          "el-select",
                          {
                            staticClass: "width-140",
                            attrs: { placeholder: "请选择" },
                            on: {
                              change: function ($event) {
                                _vm.selectValue_2 = ""
                              },
                            },
                            model: {
                              value: _vm.selectKey_2,
                              callback: function ($$v) {
                                _vm.selectKey_2 = $$v
                              },
                              expression: "selectKey_2",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: {
                                label: "仓库编号",
                                value: "warehouseNo",
                              },
                            }),
                            _vm._v(" "),
                            _c("el-option", {
                              attrs: {
                                label: "仓库名称",
                                value: "warehouseName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("el-input", {
                          staticClass: "width-180",
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.selectValue_2,
                            callback: function ($$v) {
                              _vm.selectValue_2 = $$v
                            },
                            expression: "selectValue_2",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.searchList_2 },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_2,
                      expression: "loading_2",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData_2, border: "", height: "400px" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                (_vm.pageParam_2.pageNum - 1) *
                                  _vm.pageParam_2.pageSize +
                                  (scope.$index + 1)
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "warehouseNo",
                      label: "仓库编号",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "warehouseName",
                      label: "仓库名称",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "inventoryTotal",
                      label: "库存",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address",
                      label: "地区",
                      "min-width": "200",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "managerName",
                      label: "仓管员",
                      "min-width": "160",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "",
                      label: "操作",
                      "min-width": "100",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "operation-btn",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleBatch(scope.row)
                                  },
                                },
                              },
                              [_vm._v("批次")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_2.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_2.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_2.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_2,
                      "current-change": _vm.handleCurrentChange_2,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { "padding-top": "30px" } }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              "库存-批次-" +
              _vm.selectProductName +
              "（" +
              _vm.selectProductInventory +
              "）",
            visible: _vm.dialogVisible_batch,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible_batch = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  staticClass: "demo-form-inline",
                  attrs: { inline: true, size: "medium" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "批次" } },
                    [
                      _c("el-input", {
                        staticClass: "width-180",
                        attrs: { placeholder: "请输入批次" },
                        model: {
                          value: _vm.inquire_batch.keyword,
                          callback: function ($$v) {
                            _vm.$set(_vm.inquire_batch, "keyword", $$v)
                          },
                          expression: "inquire_batch.keyword",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.searchList_batch },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_batch,
                      expression: "loading_batch",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.batchTable, border: "", height: "400px" },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "序号", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              _vm._s(
                                (_vm.pageParam_batch.pageNum - 1) *
                                  _vm.pageParam_batch.pageSize +
                                  (scope.$index + 1)
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "batchNo",
                      label: "批次",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "count",
                      label: "库存",
                      "min-width": "120",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "produceTime",
                      label: "生产日期",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "expireTime",
                      label: "到期日期",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "validityDays",
                      label: "有效期",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createTime",
                      label: "入库时间",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "operatorName",
                      label: "操作人",
                      "min-width": "160",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.pageParam_batch.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.pageParam_batch.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.pageParam_batch.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_batch,
                      "current-change": _vm.handleCurrentChange_batch,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("p", { staticStyle: { "padding-top": "30px" } }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }