<template>
  <div class="main-box">
    <div class="head-search-row">
        <el-form :model="queryParams" size="small" :inline="true" label-width="96px">
      <el-form-item label="创建时间">
        <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
      </el-form-item>
      <el-form-item label="管理员名称">
        <el-input v-model.trim="queryParams.ownerName" placeholder="请输入管理员名称" clearable style="width: 240px;" @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="管理员编号">
        <el-input v-model.trim="queryParams.ownerCode" placeholder="请输入管理员编号" clearable style="width: 240px;" @keyup.enter.native="handleQuery" />
      </el-form-item>
    </el-form>
    <el-form :model="queryParams" size="small" :inline="true" label-width="96px">
    <el-form-item label="群聊名称">
        <el-input v-model.trim="queryParams.groupName" placeholder="请输入群聊名称" clearable style="width: 240px;" @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="mini" @click="handleQuery">查询</el-button>
      </el-form-item>
    </el-form>
      </div>
      <div class="table">
          <el-main>
            <template>
                <el-table :data="tableData" border height="400px" style="width: 100%">
                    <el-table-column label="序号" prop="date" width="60">
                        <template v-slot="scope">
                            {{ (queryParams.pageNum - 1) * queryParams.pageSize + (scope.$index + 1) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="群聊名称" min-width="100" prop="groupName" show-overflow-tooltip></el-table-column>
                    <el-table-column label="管理员名称" min-width="100" prop="ownerName" show-overflow-tooltip=""></el-table-column>
                    <el-table-column label="管理员编号" min-width="200" prop="ownerCode" show-overflow-tooltip=""></el-table-column>
                    <el-table-column label="创建时间" min-width="160" prop="createTime"></el-table-column>
                    <el-table-column fixed="right" label="操作" min-width="120" prop="">
                        <template slot-scope="scope">
                            <span class="operation-btn" @click="handleDetails(scope.row)">聊天记录</span>
                            <span class="operation-btn_partition"> / </span>
                            <span class="operation-btn" @click="handleView(scope.row)">{{type == 'use' ? '管理群成员' : '查看群成员'}}</span>
                            <span v-if="type == 'use'" class="operation-btn_partition"> / </span>
                            <span class="operation-btn" v-if="type == 'use'" @click="handleDissove(scope.row)">解散</span>
                        </template>
                    </el-table-column>
                </el-table>
            </template>
            <div class="pagination">
                <el-pagination
                    :current-page="queryParams.pageNum"
                    :page-size="queryParams.pageSize"
                    :page-sizes="$api.pageSizes"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                ></el-pagination>
            </div>
          </el-main>
        </div>
        <!-- 聊天详情弹框 -->
      <el-dialog title="群聊详情" destroy-on-close :visible.sync="showDialog" width="60%" append-to-body @open="openEvent" @close="closeEvent">
      <el-form  :inline="true" >
        <el-form-item label="内容">
         <el-input v-model.trim="formData.msgContent" placeholder="请输入内容" />
         </el-form-item>
         <el-form-item label="发送者">
         <el-input v-model.trim="formData.fromUserName" placeholder="发送者名称" />
         </el-form-item>
         <el-form-item label="发送时间">
         <el-date-picker v-model="dateRange_1" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
         </el-form-item>
         <el-form-item label="发送者编号">
         <el-input v-model.trim="formData.fromUserId" placeholder="发送者编号" />
         </el-form-item>
      <el-form-item>
         <el-button type="primary" size="mini" @click="handleQuery_1">查询</el-button>
      </el-form-item>
      </el-form>
      <el-table ref="mainTable" v-loading="loading_1" 
      :data="tableList" height="400" border style="margin-top: 30px" 
      :header-cell-style="{background:'#409eff',color:'#ffffff'}">
       <el-table-column label="序号" prop="date" width="60">
          <template v-slot="scope">
              {{ (formData.pageNum - 1) * formData.pageSize + (scope.$index + 1) }}
          </template>
        </el-table-column>
         <el-table-column label="发送者" align="center" prop="userName" width="200" />
         <el-table-column label="咨询时间" align="center" prop="sendTime" />
         <el-table-column label="内容" align="center" prop="msgContent" min-width="200"/>
      </el-table>
      <div class="pagination">
            <el-pagination
                :current-page="formData.pageNum"
                :page-size="formData.pageSize"
                :page-sizes="$api.pageSizes"
                :total="total_1"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange_1"
                @current-change="handleCurrentChange_1"
            ></el-pagination>
        </div>
      </el-dialog>
      <!-- 管理群成员 -->
      <el-dialog title="群成员列表" destroy-on-close :visible.sync="showDialog_2" width="50%" append-to-body @open="openEvent_2" @close="closeEvent_2">
      <el-form  :inline="true" >
        <el-form-item label="群成员名称">
         <el-input v-model.trim="formData_2.groupUserName" placeholder="成员名称" />
         </el-form-item>
         <el-form-item label="会员编号">
         <el-input v-model.trim="formData_2.groupUserCode" placeholder="会员编号" />
         </el-form-item>
         <el-form-item label="加入时间">
         <el-date-picker v-model="dateRange_2" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"></el-date-picker>
         </el-form-item>
         <!-- <el-form-item label="发送者编号">
         <el-input v-model.trim="formData.fromUserId" placeholder="发送者编号" />
         </el-form-item> -->
      <el-form-item>
         <el-button type="primary" size="mini" @click="handleQuery_2">查询</el-button>
      </el-form-item>
      </el-form>
      <el-table ref="mainTable" v-loading="loading_2" 
      :data="tableList_2" height="400" border style="margin-top: 30px" 
      :header-cell-style="{background:'#409eff',color:'#ffffff'}">
       <el-table-column label="序号" prop="date" width="60">
          <template v-slot="scope">
              {{ (formData.pageNum - 1) * formData.pageSize + (scope.$index + 1) }}
          </template>
        </el-table-column>
         <el-table-column label="群成员名称" align="center" prop="groupUserName" width="200" />
         <el-table-column label="会员编号" align="center" prop="groupUserCode" />
         <el-table-column label="加入时间" align="center" prop="joinTime" min-width="200"/>
         <el-table-column fixed="right" label="操作" min-width="120" prop="">
            <template slot-scope="scope">
                <span class="operation-btn" @click="handleDelete(scope.row)">删除</span>
            </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
            <el-pagination
                :current-page="formData_2.pageNum"
                :page-size="formData_2.pageSize"
                :page-sizes="$api.pageSizes"
                :total="total_2"
                background
                layout="total, sizes, prev, pager, next, jumper"
                @size-change="handleSizeChange_2"
                @current-change="handleCurrentChange_2"
            ></el-pagination>
        </div>
      </el-dialog>
  </div>
</template>

<script>
export default {
  props:{
    type:{
      type:String,
      default:'use'
    }
  },
  data() {
      return {
        queryParams:{
          groupStatus:0,
          pageNum:1,
          pageSize:10,
          createBeginTime:null,
          createEndTime:null,
          ownerName:'',
          ownerCode:'',
          groupType:'',
          groupName:'',
        },
        dateRange:[],
        tableData:[],
        loading: true,
        total:0,
        formData:{
          pageNum: 1, 
          pageSize: 10, 
          groupId: '', 
          msgContent: "", 
          fromUserName: "", 
          sendBeginTime: null, 
          sendEndTime: null, 
          fromUserId: null, 
        },
        showDialog:false,
        total_1:0,
        loading_1:true,
        tableList:[],
        dateRange_1:[],
        showDialog_2:false,
        total_2:0,
        loading_2:true,
        tableList_2:[],
        dateRange_2:[],
        formData_2:{
          groupUserName: "", 
          groupUserCode: "", 
          joinBeginTime: null, 
          joinEndTime: null, 
          groupId: '', 
          pageNum: 1, 
          pageSize: 10 
        }
      };
  },
  created() {
    this.queryParams.groupStatus = this.type == 'use' ? 0 : 1
    this.getDataList()
  },
  methods: {
    handleSizeChange(size) {
        this.queryParams.pageNum = 1;
        this.queryParams.pageSize = size;
        this.getDataList()
    },
    // 页码改变
    handleCurrentChange(page) {
        this.queryParams.pageNum = page;
        this.getDataList();
    },
      // 刷新
     handleQuery() {
      this.queryParams.pageNum = 1
      this.getDataList()
   },
       // 获取数据列表
    getDataList() {
      if (!this.dateRange) this.dateRange = [];
      this.queryParams.createBeginTime = this.dateRange.length > 0 ? this.dateRange[0] : '';
      this.queryParams.createEndTime = this.dateRange.length > 0 ? this.dateRange[1] : '';
      this.$http.requestPost({url: '/consult/queryImGroupList', param: this.queryParams, loading: false}).then(res => {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total;
      }).catch(err => {
          this.loading = false;
          console.log(err)
      })
    },
    // 查看聊天记录
    handleDetails(row){
        this.showDialog = true
        this.formData.groupId = row.groupId
    },
    // 查看/管理群成员
    handleView(row){
        this.showDialog_2 = true
        this.formData_2.groupId = row.groupId
    },
    //解散群成员
    handleDissove(row){
      this.$confirm('是否确定解散该群聊?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.requestPut({url: `/consult/closeGroup/${row.groupId}`, loading: false}).then(res => {
          if(res.data){
            this.$message.success('解散成功')
            this.getDataList()
          }
      }).catch(err => {
          console.log(err)
      })
      }).catch(() => {})
    },
    getTableList(){
      if (!this.dateRange_1) this.dateRange_1 = [];
    this.formData.sendBeginTime = this.dateRange_1.length > 0 ? this.dateRange_1[0] : '';
    this.formData.sendEndTime = this.dateRange_1.length > 0 ? this.dateRange_1[1] : '';
    this.$http.requestPost({url: '/consult/groupMsgRecord', param: this.formData, loading: false}).then(res => {
        this.loading_1 = false;
        this.tableList = res.data.list;
        this.total_1 = res.data.total;
    }).catch(err => {
        this.loading_1 = false;
        console.log(err)
    })
    },
    openEvent(){
      this.getTableList()
    },
    closeEvent(){
      this.formData = {
          pageNum: 1, 
          pageSize: 10, 
          groupId: '', 
          msgContent: "", 
          fromUserName: "", 
          sendBeginTime: null, 
          sendEndTime: null, 
          fromUserId: null,
      }
    },
    handleQuery_1(){
      this.getTableList()
    },
      // 页码改变
    handleCurrentChange_1(page) {
      this.formData.pageNum = page;
      this.getTableList();
    },
      // 页容量改变
    handleSizeChange_1(size) {
      this.formData.pageNum = 1;
      this.formData.pageSize = size;
      this.getTableList()
    },
    openEvent_2(){
      this.getTableList_2()
    },
    closeEvent_2(){
      this.formData_2 = {
          groupUserName: "", 
          groupUserCode: "", 
          joinBeginTime: null, 
          joinEndTime: null, 
          groupId: '', 
          pageNum: 1, 
          pageSize: 10 
      }
    },
    handleQuery_2(){
      this.getTableList_2()
    },
    // 删除群成员
    handleDelete(row){
      this.$confirm('是否确定删除该群成员?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.requestPut({url: `/consult/removeGroupUser/${row.groupUserCode}/${this.formData_2.groupId}`, loading: false}).then(res => {
          if(res.data){
            this.$message.success('删除成功')
            this.getTableList_2()
          }
      }).catch(err => {
          console.log(err)
      })
      }).catch(() => {})
    },
      // 页码改变
    handleCurrentChange_2(page) {
      this.formData_2.pageNum = page;
      this.getTableList_2();
    },
      // 页容量改变
    handleSizeChange_2(size) {
      this.formData_2.pageNum = 1;
      this.formData_2.pageSize = size;
      this.getTableList_2()
    },
    getTableList_2(){
      if (!this.dateRange_2) this.dateRange_2 = [];
    this.formData_2.joinBeginTime = this.dateRange_2.length > 0 ? this.dateRange_2[0] : '';
    this.formData_2.joinEndTime = this.dateRange_2.length > 0 ? this.dateRange_2[1] : '';
    this.$http.requestPost({url: '/consult/groupMemberList', param: this.formData_2, loading: false}).then(res => {
        this.loading_2 = false;
        this.tableList_2 = res.data.list;
        this.total_2 = res.data.total;
    }).catch(err => {
        this.loading_2 = false;
        console.log(err)
    })
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
