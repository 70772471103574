<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form label-width="40px" ref="submitForm" :model="submitForm" :rules="submitRules">
        <el-form-item label="" prop="merchantProductInventoryWarn">
          <div class="flex-box">
            <div class="box-p-r">商家产品库存警戒线默认值</div>
            <el-input v-model.number="submitForm.merchantProductInventoryWarn" placeholder="0" class="width-110"></el-input>
          </div>
        </el-form-item>

        <el-form-item label="" prop="headquartersProductInventoryWarn">
          <div class="flex-box" >
            <div class="box-p-r">总部产品库存警戒线默认值</div>
            <el-input v-model.number="submitForm.headquartersProductInventoryWarn" placeholder="0" class="width-110"></el-input>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitAdd">保存</el-button>
        </el-form-item>
      </el-form>
    </div> 
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 编辑
      submitForm: {
        merchantProductInventoryWarn:  0,
        headquartersProductInventoryWarn: 0
      },
      submitRules: {
        merchantProductInventoryWarn: [{required: true, message: '请输入商家产品库存警戒线', trigger: 'blur'},
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ],
        headquartersProductInventoryWarn: [{required: true, message: '请输入总部产品库存警戒线', trigger: 'blur'},
          { pattern: /^[+]{0,1}(\d+)$/, message: '请输入正整数', trigger: 'blur' }
        ],
      },
    };
  },
  created() {
    // 获取数据
    this.getPageData()
  },
  methods: {
    // 获取数据列表
    getPageData() {
      this.$http.requestGet({
        url: '/operation/product/inventory/warn',
      }).then(res => {
        let data = res.data
        this.submitForm = {
          merchantProductInventoryWarn: data.merchantProductInventoryWarn,
          headquartersProductInventoryWarn: data.headquartersProductInventoryWarn,
        }
      }).catch(err => {
        console.log(err)
      })
    },

    // 提交添加
    submitAdd() {
      this.$refs.submitForm.validate(valid => {
        if (valid) {
          let param = {
            merchantProductInventoryWarn:  this.submitForm.merchantProductInventoryWarn,
            headquartersProductInventoryWarn: this.submitForm.headquartersProductInventoryWarn,
          };

          this.$http.requestPut({
            url: '/operation/product/inventory/warn',
            param: param
          }).then(res => {
            this.getPageData()
            this.$message.success('操作成功！')
          })
        } else {
          return false
        }
      })
    }

    

  },
};
</script>

<style lang="scss" scoped>
  .head-search-row {
    padding-top: 40px;
  }
</style>
