<template>
    <!-- 类别管理 -->
    <div class="main-box">
        <div class="head-search-row">
            <el-form :inline="true" size="medium" :model="queryParams" label-width="68px">
                <el-form-item label="类别名称">
                    <el-input v-model="queryParams.categoryName" placeholder="请输入类别名称" clearable style="width: 240px;"
                        @keyup.enter.native="handleQuery" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" @click="handleQuery">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div style="margin: 20px 0 0 20px">
            <el-button size="small" type="primary" @click="handleAdd">新增</el-button>
        </div>
        <div class="table">
            <el-main>
                <template>
                    <el-table ref="tables" v-loading="loading" :data="list" height="400" border
                        :header-cell-style="{ background: '#409eff', color: '#ffffff' }">
                        <el-table-column label="序号" width="60" align="center" type="index" />
                        <el-table-column label="类别名称" align="center" prop="categoryName" />
                        <el-table-column label="备注" align="center" prop="categoryRemark" />
                        <el-table-column label="状态" align="center" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                <span>{{ getStatus(scope.row) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="添加时间" align="center" prop="createTime" />
                        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
                            <template slot-scope="scope">
                                <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
                                <span class="btn-partition"> / </span>
                                <el-button type="text" @click="handleHide(scope.row)">{{ scope.row.categoryStatus == 1 ?
                '隐藏' :
                '显示' }}</el-button>
                                <span class="btn-partition"> / </span>
                                <el-button type="text" @click="handleDelete(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="queryParams.pageNum" :page-sizes="$api.pageSizes"
                        :page-size="queryParams.pageSize" layout=" total, sizes, prev, pager, next, jumper"
                        :total="total">
                    </el-pagination>
                </div>
            </el-main>
        </div>
        <!-- 添加或修改参数配置对话框 -->
        <el-dialog title="新增类别" :visible.sync="openAdd" width="500px" append-to-body>
            <el-form label-width="80px" ref="submitForm">
                <el-form-item label="类别名称">
                    <el-input v-model="formAdd.categoryName" placeholder="请输入名称" maxlength="10" show-word-limit
                        clearable />
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="formAdd.categoryOrder" placeholder="请输入序号" />
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="formAdd.categoryRemark" type="textarea" placeholder="请输入备注" maxlength="20"
                        show-word-limit clearable></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFormAdd">确 定</el-button>
                <el-button @click="cancelAdd">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="编辑类别" :visible.sync="openEdit" width="500px" append-to-body>
            <el-form label-width="80px">
                <el-form-item label="类别名称">
                    <el-input v-model="formEdit.categoryName" placeholder="请输入名称" maxlength="10" show-word-limit
                        clearable />
                </el-form-item>
                <el-form-item label="排序">
                    <el-input v-model="formEdit.categoryOrder" placeholder="请输入序号" />
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="formEdit.categoryRemark" type="textarea" placeholder="请输入备注" maxlength="20"
                        show-word-limit clearable></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFormEdit">确 定</el-button>
                <el-button @click="cancelEdit">取 消</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
export default {
    data() {
        return {
            // 遮罩层
            loading: true,
            // 总条数
            total: 0,
            showTitle: '',
            // 表格数据
            list: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                categoryName: "",
            },
            openEdit: false,
            formEdit: {
                id: "",
                categoryName: "",
                categoryOrder: "",
                categoryRemark: "",
            },
            openAdd: false,
            formAdd: {
                categoryName: "",
                categoryOrder: "",
                categoryRemark: "",
            },
            // rules: {
            // categoryName: [{ required: true, message: "请输入类别名称", trigger: ['change', 'blur'] }],
            // categoryOrder: [{ required: true, message: "请填写排序", trigger: ['change', 'blur'] }],
            // },
            // rules_1: {
            //     categoryName: [{ required: true, message: "请输入类别名称", trigger: ['change', 'blur'] }],
            //     categoryOrder: [{ required: true, message: "请填写排序", trigger: ['change', 'blur'] }],
            // }
        };
    },
    created() {
        this.getList();
    },
    methods: {
        getStatus(row) {
            if (row.categoryStatus == 1) {
                return "显示";
            } else if (row.categoryStatus == 2) {
                return "隐藏";
            }
        },
        getList() {
            this.loading = true;
            this.$http
                .requestPost({
                    url: "/knowledge/queryKnowledgeCategoryList",
                    param: {
                        pageNum: this.queryParams.pageNum,
                        pageSize: this.queryParams.pageSize,
                        param: {
                            categoryName: this.queryParams.categoryName
                        }
                    },
                    loading: false,
                })
                .then((res) => {
                    this.loading = false;
                    this.list = res.data.list;
                    this.total = res.data.total;
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        handleAdd() {
            this.formAdd.categoryName = "";
            this.formAdd.categoryOrder = "";
            this.formAdd.categoryRemark = "";
            this.openAdd = true;
        },
        handleEdit(row) {
            console.log(row, '------------')
            this.formEdit.id = row.id;
            this.formEdit.categoryName = row.categoryName;
            this.formEdit.categoryOrder = row.categoryOrder;;
            this.formEdit.categoryRemark = row.categoryRemark;
            this.openEdit = true;
        },
        handleHide(row) {
            if (row.categoryStatus == 1) {
                this.showTitle = "隐藏";
            } else {
                this.showTitle = "显示";
            }
            let status = row.categoryStatus == 1 ? 2 : 1;
            this.$confirm("是否确认" + this.showTitle + "类别？", this.showTitle + "类别", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(() => {
                this.$http.requestPut({ url: '/knowledge/modifyKnowledgeCategoryStatus/' + row.id + '/' + status }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    })
                    this.getList();
                })
                    .catch((err) => {
                        console.log(err);
                    });
            })
        },
        handleDelete(row) {
            this.$confirm("是否确认删除类别？", "删除类别", {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(() => {
                this.$http.requestDel({ url: '/knowledge/removeKnowledgeCategory/' + row.id }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    })
                    this.getList();
                })
            }).catch((err) => {
                console.log(err);
            });
        },
        submitFormEdit() {
            if (this.formEdit.categoryName == '') {
                return this.$message.warning("请输入名称")
            }
            if (this.formEdit.categoryOrder == '') {
                return this.$message.warning("请输入序号")
            }
            let param = {
                id: this.formEdit.id,
                categoryName: this.formEdit.categoryName,
                categoryOrder: Number(this.formEdit.categoryOrder),
                categoryRemark: this.formEdit.categoryRemark,
            };
            this.$http
                .requestPut({
                    url: "/knowledge/addOrEditKnowledgeCategory",
                    param: param,
                    loading: false,
                })
                .then((res) => {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    this.openEdit = false
                    this.getList();
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        cancelEdit() {
            this.openEdit = false;
        },
        //新增
        submitFormAdd() {
            if (this.formAdd.categoryName == '') {
                return this.$message.warning("请输入名称")
            }
            if (this.formAdd.categoryOrder == '') {
                return this.$message.warning("请输入序号")
            }
            let param = {
                id: '',
                categoryName: this.formAdd.categoryName,
                categoryOrder: Number(this.formAdd.categoryOrder),
                categoryRemark: this.formAdd.categoryRemark,
            };
            this.$http
                .requestPut({
                    url: "/knowledge/addOrEditKnowledgeCategory",
                    param: param,
                    loading: false,
                })
                .then((res) => {
                    this.$message({
                        message: "操作成功",
                        type: "success",
                    });
                    this.openAdd = false
                    this.getList();
                })
                .catch((err) => {
                    this.loading = false;
                });
        },
        cancelAdd() {
            this.openAdd = false;
        },
        // 类别管理页码改变
        handleSizeChange(size) {
            this.queryParams.pageNum = 1;
            this.queryParams.pageSize = size;
            this.getList();
        },
        handleCurrentChange(page) {
            this.queryParams.pageNum = page;
            this.getList();
        },
    },
}
</script>

<style lang="scss" scoped>
.border-section {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
}

.btn-partition {
    color: #009bff;
}
</style>