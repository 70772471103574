<template>
  <screenBox id="largeScreen" :width="'2927'" :height="'1080'">
  <!-- <div id="largeScreen"> -->
    <div  ref="largeScreen" class="large-box">
  <div class="chart-box">
            <!-- 标题 -->
            <div class="title-img">
      <!-- 标题内容 -->
      <div class="title-left"></div>
      <div class="title-content"></div>
      <div class="title-right"></div>
      <div class="title-bottom"></div>
      <div class="sales-title">
        <el-select class="selectDate" v-model="inquire.dayOrMonth" :popper-append-to-body="false"  @change="disposeTimeShow">
            <el-option label="按日统计" :value="1"></el-option>
            <el-option label="按月统计" :value="2"></el-option>
        </el-select>
        <el-date-picker
        ref="datePicker"
        class="picker-date"
        v-show="inquire.dayOrMonth === 1"
        :append-to-body="false"
        v-model="daterange"
        type="datetimerange"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
        range-separator="-"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        prefix-icon="el-icon-date"
        :picker-options="pickerOptions"
        @change="changeDate">
      </el-date-picker>
        <el-date-picker
        class="picker-month"
        v-show="inquire.dayOrMonth === 2"
        :append-to-body="false"
        v-model="daterange"
        value-format="yyyy-MM-dd"
        type="monthrange"
        range-separator="-"
        start-placeholder="开始月份"
        end-placeholder="结束月份"
        @change="changeDate">
    </el-date-picker>
      </div>
    </div>
        <!--左侧整体 -->
    <!-- <div class="left">
      <div class="schedule" :key="Math.random()">
      <scheduleChart ref="scheduleChart" :current="currentData" :selectQ.sync="selectQ" :selectM.sync="selectM" :progressList="progressList" @getActive="getActive" @getTableType="getTableType"></scheduleChart>
    </div >
    <div class="l-bottom">
      <brokenLineChart ref="saleChart"></brokenLineChart>
    </div >
    </div> -->
     <!--中间整体 -->
     <div class="center-box">
      <div class="content">
     <!-- 分为上下两部分 -->
     <div class="center-top">
            <!-- 医院总数以及骑手总数用户 -->
    <div id="total-data">
      <div class="date"></div>
     <div class="data-box">
      <div class="data-item">
        <div class="data-left">
          <img src="https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/8/1cc92c3f-6b51-49d0-a458-888c08c81bc9.png" alt="">
        </div>
          <div class="data-txt">
            <div class="data">{{ platformBasicData.hospitalTotality }}</div>
            <div class="data-title">医院总数</div>
          </div>
        </div>
        <div class="data-item">
          <div class="data-left">
          <img src="https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/8/6ea3240c-c53d-4b81-bb19-ea3a3055db79.png" alt="">
        </div>
          <div class="data-txt">
            <div class="data">{{ platformBasicData.riderTotality }}</div>
            <div class="data-title">骑手总数</div>
          </div>
        </div>
        <div class="data-item">
          <div class="data-left">
          <img src="https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/b5bfbf36-99af-4598-ae73-8bce69a68104.png" alt="">
        </div>
          <div class="data-txt">
            <div class="data">{{ platformBasicData.userTotality }}</div>
            <div class="data-title">用户总数</div>
          </div>
        </div>
        <div class="data-item">
          <div class="data-left">
          <img src="https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/169a3986-1f07-4bf3-b98d-d0fcf64b2257.png" alt="">
        </div>
          <div class="data-txt">
            <div class="data">{{ platformBasicData.orderUserTotality }}</div>
            <div class="data-title">下单用户总数</div>
          </div>
        </div>
     </div>
    </div>
          <!-- 日访问量数据展示 -->
          <div id="data-top">
        <div class="tip">
          <img src="../../assets/image/wenhao.png" alt="">
          <div class="poup" ref="poup">
            注：<div class="txt">
              无法查看2023-01-01之前的访问次数、访问人数、新增用户、转化率的数据
            </div>
          </div>
    </div>
        <div class="data-box">
          <div class="data-item">
            <div class="data-txt">
            <div class="data">{{ getDailyActive.dailyVisits }}</div>
            <div class="data-title">访问次数</div>
          </div>
            <div class="data-right">
          <img src="https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/ec2e0371-ede1-403e-8af3-c5722876765c.png" alt="">
        </div>
        </div>
        <div class="data-item">
          <div class="data-txt">
            <div class="data">{{ getDailyActive.accessSum }}</div>
            <div class="data-title">访问人数</div>
          </div>
          <div class="data-right">
          <img src="https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/976bf1a5-fc10-43a9-93a8-3a88ce3275b7.png" alt="">
        </div>
        </div>
        <div class="data-item">
          <div class="data-txt">
            <div class="data">{{ getDailyActive.dailyNewUsers }}</div>
            <div class="data-title">新增用户</div>
          </div>
          <div class="data-right">
          <img src="https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/c79f7392-db99-4d04-9117-634fc28f96c6.png" alt="">
        </div>
        </div>
        <div class="data-item">
          <div class="data-txt">
            <div class="data">{{ getDailyActive.averageConversionRate }}</div>
            <div class="data-title">转化率</div>
          </div>
          <div class="data-right">
          <img src="https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/9/90706cd2-3a54-4aac-8c56-9a5e787d64c8.png" alt="">
        </div>
        </div>
        </div>
      </div>
     </div>
     <div class="center-bottom">
      <div class="left pub-flex-column-between">
    <!-- 平台业务数据 -->
    <div id="business-data">
      <div class="business-title">
        <div class="icon"><img src="../../assets/image/pt.png" alt=""></div>
        <div class="business">平台业务数据</div>
        <div class="nav-list" v-if="managerType == 'all'">
          <div class="item"  v-for="(item,index) in list" :key="item.value" :class="current == index ? 'active' : ''" @click="getSelect(index)" >{{ item.name }}</div>
        </div>
        <div class="nav-list" v-if="managerType == 'region'">
          <div class="item"  v-for="(item,index) in list1" :key="item.value" :class="current == index ? 'active' : ''" @click="getSelect(index)" >{{ item.name }}</div>
        </div>
        <div class="nav-list" v-if="managerType == 'district'">
          <div class="item"  v-for="(item,index) in list2" :key="item.value" :class="current== index ? 'active' : ''" @click="getSelect(index)" >{{ item.name }}</div>
        </div>
      </div>
        <dv-scroll-board ref="scrollBoard" class="carouselList" :config="config_1"/>
    </div>
    <!-- 产品浏览数据饼图 -->
    <div class="pie-box">
      <pieChart ref="pieChart" :managerType="managerType" @selectRadio="selectRadio" v-model="ratioCurrent"></pieChart>
    </div>
   </div>
    <!-- 中间图表数据 -->
  <div class="center">
    <div class="brange">
        <div class="scrolling" v-show="barrage">
        <div class="first">{{ attacheName }}</div>
        <div class="one">{{ barrage }}</div>
        <div class="two">&nbsp;{{ product }}</div>
        <div class="three">{{ price }}</div>
      </div>
      </div>
    <!-- 总销售额和总订单量数据展示 -->
    <div id="sales-data">
     <div class="data-content">
      <div class="top-module">
        <div class="name1">总销售额</div>
        <div class="name2">总订单量</div>
        </div>
          <!-- <dv-digital-flop :config="saleConfig" class="flop1"/> -->
      <div class="bottom-module">
        <module :value="marketing.totalPrice" :time="2"/>
        <module class="order-module" :value="marketing.totalOrder" :time="2"/>
        <!-- <dv-digital-flop :config="orderConfig"  class="flop"/> -->
      </div>
     </div>
   </div>
   <div class="bottom">
    <!-- 上方弹幕体 -->
     <!-- 地图 -->
    <mapChart ref="mapChart" class="map-chart"></mapChart>
   </div>
  </div>
    <!-- 右侧图表 -->
    <div class="right pub-flex-column-between">
      <!-- 历史访问 -->
      <div class="border1">
        <interviewChart ref="interview"></interviewChart>
        </div>
      <!-- 订单数量趋势分析 -->
    <div class="order-box">
      <brokenLineChart ref="orderChart"></brokenLineChart>
    </div>
   </div>
     </div>
      </div>
    </div>
    <!--右侧整体 -->
    <!-- <div class="right">
      <div class="top">
        <div class="conversion-box">
          <conversionChart ref="conversion"></conversionChart>
        </div>
      </div>
      <div class="bottom">
        <div class="circle-box">
          <circleChart ref="circle" :list="purchasingPowerAnalysis"></circleChart>
        </div>
      </div>
    </div> -->

    </div>
  </div>
<!-- </div> -->
</screenBox>
</template>
  <script>
// 引入echarts
import * as echarts from 'echarts';
// 引入data-v

import { initjson } from '@/components/largeScreen/json/json.js';
import dayjs  from 'dayjs'
// 引入socket
import mapChart from '@/components/largeScreen/mapChart.vue'
import scheduleChart from '@/components/largeScreen/scheduleChart.vue'
import brokenLineChart from '@/components/largeScreen/brokenLineChart.vue';
import conversionChart from '@/components/largeScreen/conversionChart.vue';
import circleChart from '@/components/largeScreen/circleChart.vue'
import pieChart from '@/components/largeScreen/pieChart.vue';
import interviewChart from '@/components/largeScreen/interviewChart.vue';
import module from '@/components/largeScreen/numScroll.vue'
import screenBox from '@/components/largeScreen/screenBox.vue'
export default {
  components:{
    scheduleChart,
    brokenLineChart,
    conversionChart,
    circleChart,
    pieChart,
    interviewChart,
    mapChart,
    module,
    screenBox,
  },
  data() {
    return {
      list:[
      {name:'大区',value:1},
      {name:'片区',value:2},
      {name:'办事处',value:3},
      {name:'个人',value:4},
      ],
      list1:[
      {name:'片区',value:1},
      {name:'办事处',value:2},
      {name:'个人',value:3},
      ],
      list2:[
      {name:'办事处',value:1},
      {name:'个人',value:2},
      ],
      currentData: 0, // 年度切换
      current:0,//大区切换
      config_1:{
      header: [],
      // indexHeader:'排名',
      data: [],
      rowNum:10,//表格行数
      headerHeight: 40, //表头高度
      headerBGC:"linear-gradient(to right,#000e45, #184a94);",//表头背景色
      oddRowBGC: "", //奇数行
      evenRowBGC: "", //偶数行
      // index: true,
      columnWidth: [100],
      align: ["center","center","center","center"],
      waitTime:6000,
      carousel: 'page'
    },
    managerType:'all',//权限
    regionName:'',//拿到对应大区的名字
    config_2:{
      header: ["所属大区", "所属片区" ,"所属办事处" ,"所属专员" ,"产品","总金额","下单时间"],
      headerBGC:"",//表头背景色
      data: [],
      rowNum:5,//表格行数
      headerHeight: 40, //表头高度
      oddRowBGC: "", //奇数行
      evenRowBGC: "#1e4cc3", //偶数行
      columnWidth: [80,80,80,80,120,60,140],
      align: ["center","center","center","center","center","center","center"],
      waitTime:300,
    },
    getDailyActive:{
      accessSum:0,//访问人数
      averageConversionRate:'',//转化率
      dailyNewUsers:0,//新增用户
      dailyVisits:0,//访问次数
    },//访问数值显示
    platformBasicData:{
      hospitalTotality:0,//医院总数
      orderUserTotality:0,//下单用户总数
      riderTotality:0,//骑手总数
      userTotality:0,//用户总数
    },
    purchasingPowerAnalysis:{},//用户购买力分析
    //营销情况
    inquire:{
      dayOrMonth:1,
      startTime: '',
      endTime:  '',
    },
    marketing: {
      totalOrder: 0, //总订单量
      totalPrice: 0 //总销售额
    }, //营销情况
    daterange:[],//日期选择的
    pickerMinDate: "",//获取开始选择时间
    pickerMaxDate:'',//获取结束选择时间
    pickerOptions:{
              // 删除或注释掉shortcuts属性
        onPick:({maxDate,minDate}) => {
          this.pickerMinDate = minDate.getTime()
          if(maxDate){
          this.pickerMaxDate = maxDate.getTime()
          }
          console.log('onPick',maxDate,minDate);
        },
        disabledDate:(time) => {
          // 限制只能选择31天且不能大于当前时间，往前往后都只有31天的日期范围
          const day31 = (31 - 1) *24 *3600 *1000 + 24 * 3600 *1000 - 1000
          if(this.pickerMinDate !== '' && !this.pickerMaxDate){
            let maxTime = this.pickerMinDate + day31
            if(maxTime > new Date()){
              maxTime = new Date()
            }
            return time.getTime() > maxTime || time.getTime() > Date.now()
          }
          if(this.pickerMaxDate !== '' && this.pickerMinDate !== ''){
            let minTime = this.pickerMaxDate - day31
            let maxTime = this.pickerMinDate + day31 //这是为了保证往后限制只有31天显示可选
            if(maxTime >= new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)){
              maxTime = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
            }
            return time.getTime() < minTime || time.getTime() > maxTime
          }
          return time.getTime() > Date.now()
        }
    },
    orderConfig:{
      number:[0],
        formatter	:(number)=>{
        const numbers = number.toString().split('').reverse()
        const segs = []
       while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
       return segs.join(',').split('').reverse().join('')
      },
      style: {
          fontSize: 60,
          fill: '#47a6fd',
        }
    },
    saleConfig:{
      number:[0],
        formatter	:(number)=>{
        const numbers = number.toString().split('').reverse()
        const segs = []
       while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
       return segs.join(',').split('').reverse().join('')
      },
      style: {
          fontSize: 60,
          fill: '#f95159',
        }
    },
    productBrowseOrSales:[],//产品饼图数据
    pieR:null,//产品浏览占比定时器
    ratioTime:null,//饼图切换定时器
    ratioCurrent:0,//产品饼图当前tab
    platformServiceData:[],//平台业务数据
    platTime:null,//平台数据下方数组定时器
    tabTimer:null,//tab切换
    performanceCompliance:{},//业绩达标
    progressList:[],//左侧进度条数据
    progressTimer:null,//左侧进度条定时器
    selectQ:'',//季度选择的值
    selectM:'',//月度选择的值
    barrage:'',//弹幕
    price:'',//价格
    attacheName:'',
    product:'',//中间产品名称
    rec:null,
    socket:null,
    socket1:null,
    socket2:null,
    socket3:null,
    socket4:null,
    socket5:null,
    timeout: 30000,//30秒一次心跳
    timeoutObj: null,//心跳心跳倒计时
    serverTimeoutObj: null,//心跳倒计时
    clientWidth:'',
    clientHeight:'',
    timerW:null,
    timerH:null,
    areaId:'',//区域权限id
    };
  },
watch: {
 clientWidth(val) {
 if(!this.timerW) {
    this.clientWidth= val
    this.timerW = true
    let _this = this
  setTimeout(function () {
    _this.timerW = false
   }, 500)
 }
  // 这里可以添加修改时的方法
 this.windowWidth(val);
 },
//  clientHeight(val) {
//  if(!this.timerH) {
//     this.clientHeight = val
//     this.timerH = true
//     let _this = this
//   setTimeout(function () {
//     _this.timerH = false
//    }, 500)
//  }
//   // 这里可以添加修改时的方法
//  this.windowHeight(val);
//  },
},
 async created() {
    try {
      this.setJurisdiction()
    console.log('进入到created');
      this.windowWidth(document.documentElement.clientWidth);
      this.windowHeight(document.documentElement.clientHeight);
    this.$nextTick(()=>{
      let de = document.getElementById('largeScreen');
      document.querySelector('body').style.backgroundColor = '#000'
      if(de){
          this.fullScreen()
      }
    })
    this.daterange = [ this.$public.getDate(),this.$public.gettime()]
    this.daterange[0] = this.daterange[0] + ' 00:00:00'
    this.daterange[1] = this.daterange[1] + ' 23:59:59'
    this.daterange = [...this.daterange]
    this.inquire.startTime = this.daterange[0]
    this.inquire.endTime = this.daterange[1]
    this.getData()
    this.selectQ = this.getCurrentQuarter()
    this.selectM = this.getCurrentMonth()
    this.setScroll(this.current)
  //  await this.getPerformance()
  //  this.performScroll(this.currentData)
    } catch (error) {

    }
  },
 async mounted() {

    //初始化自适应  ----在刚显示的时候就开始适配一次
    // this.handleScreenAuto();
    //绑定自适应函数   ---防止浏览器栏变化后不再适配
    // window.onresize = () => this.handleScreenAuto();
    // 渲染地图
    this.$refs.mapChart.initChart(initjson('initChart'))
    await this.createSocket();//建立连接
    //  开启心跳
    this.start()
    this.pieOutlineFunc()
    this.getTimeOrders()
  },
  beforeDestroy() {
    window.onresize = null;
    this.oncloseWs()
    this.socket = null
    this.socket1 = null
    this.socket2 = null
    this.socket3 = null
    this.socket4 = null
    this.socket5 = null
    this.clear()
    document.removeEventListener('fullscreenchange', () => {
        return
    })
    window.removeEventListener('resize', () => {
        return
    })
  },
  methods: {
windowWidth(value) {
        this.clientWidth = value
        console.log('value: ' , value);
      },
windowHeight(value) {
        this.clienHeight = value
        console.log('value: ' , value);
      },
      // 处理权限
  setJurisdiction(){
    this.managerType = this.$route.query.managerType
    if(this.managerType == 'all'){
      this.regionName = 'china'
    }else{
      this.regionName = this.$route.query.regionName
    }
  },
    // 获取数据
  async getData(){
        this.areaId = this.$route.query.areaId
        let managerType = this.$route.query.managerType
        let param = {
        orderNumAnalyze: this.inquire.dayOrMonth,
        marketingBeginTime: this.inquire.startTime, //营销情况开始时间
        marketingEndTime: this.inquire.endTime, //营销情况结束时间
        areaId:this.areaId || '',
        managerType:managerType
          }
       this.$http.requestPost({
        url: '/statistics/getBigScreenData',
        param: param,
        loading: false
      }).then(res => {
        let data = res.data
        this.getDailyActive = data.getDailyActive
        this.platformBasicData = data.platformBasicData
        // 历史访问数量图表数据
        this.setDaily(data.historicalAccessData)
        // 订单24小时趋势分析
        this.setOrders(data.orderQuantityAnalysis.orderQuantityAnalysisByDay)
        // // 用户购买力分析
        // if(data.purchasingPowerAnalysis){
        //   this.setBuy(data.purchasingPowerAnalysis)
        // }else{
        //   this.setBuy({})
        // }
        // 销售额趋势额分析
        // this.setSales(data.salesTrendAnalysis)
        // 产品浏览量占比
        this.productBrowseOrSales = data.productBrowseOrSales
        this.setRatio(data.productBrowseOrSales)
        if(this.managerType == 'all'){
        this.ratioTime = setInterval(()=>{
            this.setRatio(this.productBrowseOrSales)
    },60000)
        }
        // 转化率趋势分析
        // this.setConversion(data.conversionRateTrendAnalysis)
        // 中间营销情况
        this.setMarket(data.marketing)
        // 处理平台业务数据
        this.platformServiceData = data.platformServiceData
        this.setPlatForm(data.platformServiceData)
      }).catch(err => {

      })
    },
  // 订单数据接口
  async getTimeOrders(){
      let areaId = this.$route.query.areaId
        let managerType = this.$route.query.managerType
        let param = {
        orderNumAnalyze: this.inquire.dayOrMonth,
        marketingBeginTime: this.inquire.startTime, //营销情况开始时间
        marketingEndTime: this.inquire.endTime, //营销情况结束时间
        areaId:areaId || '',
        managerType:managerType
          }
       this.$http.requestPost({
        url: '/statistics/getRealTimeOrders',
        param: param,
        loading: false
      }).then(res=>{
        // 处理地图的高亮，只要最新的第一条订单
      let order = res.data.realTimeOrder
      //  按照权限展示
      // let order = {
      //   orderClassify:'medicine',
      //   productNameAndQuantityList:[
      //     {productName:'舒复派（红茶味）',itemNum:1}
      //   ],
      //   attacheName:'易烊千玺（',
      //   regionName:'华南大区',
      //   districtName:'什么片区片',
      //   agencyName:'么什么办事处',
      //   orderTotalPrice:'￥0.02',
      //   province:'湖北省',
      //   city:'武汉市'
      // }
      console.log('order',order);
      let data = []
      if(this.managerType == 'all'){
          if(order.orderClassify == 'medicine'){
          if(order.province){
            let list = []
              list = order.productNameAndQuantityList
              this.product = ''
              this.attacheName = ''
              this.barrage = ''
              this.price = ''
              if(list.length){
                list.forEach(item=>{
              if(item.productName){
                this.product += item.productName + " *" + item.itemNum + '、'
              }
              })
              }
            this.product = this.product.slice(0, -1);
            this.price = '（' + order.orderTotalPrice + '元）'
            let obj = {
            name:this.removeSpecialWords(order.province),
            title:'专员',
            attachedName:order.attacheName || '无专员',
            region:order.regionName + " / " + order.districtName + ' / ' + order.agencyName,
            product:this.product,
            amount: order.orderTotalPrice
            }
            data.push(obj)
if(order.attacheName){
      this.attacheName = order.attacheName
      this.barrage = "（" + order.regionName + " / " + order.districtName + ' / ' + order.agencyName
+ "）" + "—"
    }else{
      this.attacheName = '无专员'
      this.barrage = "（" + order.regionName + " / " + order.districtName + ' / ' + order.agencyName
+ "）" + "—"
    }
      }}else{
          if(order.orderCode){
          this.product = ''
          this.barrage = ''
          this.price = ''
          this.attacheName = ''
          let list = []
          list = order.productNameAndQuantityList
          if(list.length){
          list.forEach(item=>{
        if(item.productName){
          this.product += item.productName + " *" + item.itemNum + '、'
        }
        })
        }
        this.product = this.product.slice(0, -1);
        this.attacheName = '电商品'
        this.barrage = "(" + order.province + " / " + order.city + ')' + '—'
        this.price = '（' + order.orderTotalPrice + '元）'
          let obj = {
            name:this.removeSpecialWords(order.province),
            title:'类型',
            attachedName:'电商品',
            region:order.province + " / " + order.city,
            product:this.product,
            amount: order.orderTotalPrice
          }
          data.push(obj)
          }
        }
          if(order.province){
            let province = this.removeSpecialWords(order.province)
            if(province){
              this.$refs.mapChart.setMap(initjson('china'),province,data)
            }
          }
        }else{
          if((this.managerType == 'region' && this.areaId == order.regionId) || (this.managerType == 'district' && this.areaId == order.districtId)){
            console.log('meiswdhjsdhb')
            if(order.orderClassify == 'medicine'){
          if(order.province){
              this.product = ''
              this.attacheName = ''
              this.barrage = ''
              this.price = ''
              let list = []
              list = order.productNameAndQuantityList
              this.product = ''
              if(list.length){
                list.forEach(item=>{
              if(item.productName){
                this.product += item.productName + " *" + item.itemNum + '、'
              }
              })
              }
              this.product = this.product.slice(0, -1);
              this.price = '（' + order.orderTotalPrice + '元）'
            let obj = {
            name:this.removeSpecialWords(order.province),
            title:'专员',
            attachedName:order.attacheName || '无专员',
            region:order.regionName + " / " + order.districtName + ' / ' + order.agencyName,
            product:this.product,
            amount: order.orderTotalPrice
            }
            data.push(obj)
    if(order.attacheName){
      this.attacheName = order.attacheName
      this.barrage = "（" + order.regionName + " / " + order.districtName + ' / ' + order.agencyName
+ "）" + "—"
    }else{
      this.attacheName = '无专员'
      this.barrage = "（" + order.regionName + " / " + order.districtName + ' / ' + order.agencyName
+ "）" + "—"
    }
        }}else{
          if(order.orderCode){
          this.product = ''
          this.barrage = ''
          this.attacheName = ''
          this.price = ''
          let list = []
          list = order.productNameAndQuantityList
          if(list.length){
          list.forEach(item=>{
        if(item.productName){
          this.product += item.productName + " *" + item.itemNum + '、'
        }
        })
        }
        this.product = this.product.slice(0, -1);
        this.attacheName = '电商品'
        this.barrage = "(" + order.province + " / " + order.city + ')' + '—'
        this.price = '（' + order.orderTotalPrice + '元）'
          let obj = {
            name:this.removeSpecialWords(order.province),
            title:'类型',
            attachedName:'电商品',
            region:order.province + " / " + order.city,
            product:this.product,
            amount: order.orderTotalPrice
          }
          data.push(obj)
          }
        }
          if(order.province){
            let province = this.removeSpecialWords(order.province)
            if(province){
              this.$refs.mapChart.setMap(initjson('china'),province,data)
            }
          }
          }
        }
      })
    },
    disposeTimeShow(e){
      console.log('this.daterange',this.daterange);
      this.daterange = [ this.$public.getDate(),this.$public.gettime()]
      if(this.inquire.dayOrMonth == 1){
          //  if(this.daterange[0].includes('00:00:00')){
          //   this.inquire.startTime = this.daterange[0]
          //   this.inquire.endTime = this.daterange[1]
          //  }else{
          //   this.inquire.startTime = this.daterange[0] = this.daterange.length > 0 ? this.daterange[0] + ' 00:00:00':''
          //   this.inquire.endTime = this.daterange[1] = this.daterange.length> 0 ? this.daterange[1] + ' 23:59:59':''
          //   this.daterange = [...this.daterange]
          //  }
          this.daterange[0] = this.daterange[0] + ' 00:00:00'
          this.daterange[1] = this.daterange[1] + ' 23:59:59'
          this.daterange = [...this.daterange]
          this.inquire.startTime = this.daterange[0]
          this.inquire.endTime = this.daterange[1]
          this.clear()
          this.getData()
          this.setScroll(this.current)
        }else if(this.inquire.dayOrMonth == 2){
          // if(!this.inquire.startTime.includes('00:00:00')){
          //   this.inquire.startTime = this.daterange.length > 0 ? this.daterange[0] + ' 00:00:00':''
          //   this.inquire.endTime = this.daterange.length> 0 ? this.daterange[1] + ' 23:59:59':''
          // }
          this.inquire.startTime = this.daterange.length > 0 ? this.daterange[0] + ' 00:00:00':''
          this.inquire.endTime = this.daterange.length> 0 ? this.daterange[1] + ' 23:59:59':''
          this.clear()
          this.getData()
          this.setScroll(this.current)
        }
    },
  // 获取左侧业绩达标情况数据
 async getPerformance(){
      // 默认进来是本年度、本季度、本月
      let param = {
        quarter:this.selectQ,
        month:this.selectM
      }
      this.$http.requestPost({
        url: '/statistics/getSalesTarget',
        param: param,
        loading: false
      }).then(res=>{
        console.log('res',res);
        // 处理业绩数据
        this.performanceCompliance = res.data.performanceCompliance
        this.setPerformance(res.data.performanceCompliance)
      })
  },
  // 选择对应的季度或者月度
getTableType(val){
    if(this.currentData == 1){
       this.selectQ = val
       clearTimeout(this.progressTimer)
       this.getPerformance()
       this.performScroll(this.currentData)
    }else if(this.currentData ==2){
       this.selectM = val
       clearTimeout(this.progressTimer)
       this.getPerformance()
       this.performScroll(this.currentData)
    }
    console.log('this.selectQ',this.selectQ,this.selectM);
  },
setPerformance(res){
    // 处理业绩达标数据
    if(this.currentData == 0){
      let list = res.regionByYear
      list.map(item=>{
        delete item.targetSales
        delete item.regionId
      })
      this.progressList = list
    }else if(this.currentData == 1){
      let list = res.regionByQuarter
      list.map(item=>{
        delete item.targetSales
        delete item.regionId
      })
      this.progressList = list
    }else if(this.currentData == 2){
      let list = res.regionByMonth
      list.map(item=>{
        delete item.targetSales
        delete item.regionId
      })
      this.progressList = list
    }
  },
performScroll(current){
  // 处理业绩达标情况轮播
  switch (current) {
    case 0:
    this.progressTimer = setTimeout(() => {
      this.currentData = 1
      this.performScroll(this.currentData)
      this.setPerformance(this.performanceCompliance)
    }, 60000);
      break;
    case 1:
    this.progressTimer = setTimeout(() => {
      this.currentData = 2
      this.performScroll(this.currentData)
      this.setPerformance(this.performanceCompliance)
    }, 60000);
      break;
    case 2:
    this.progressTimer = setTimeout(() => {
      this.currentData = 0
      this.performScroll(this.currentData)
      this.setPerformance(this.performanceCompliance)
    }, 60000);
      break;
    default:
      break;
  }
},
   // 点击切换业绩达标情况tab栏
getActive(index){
      this.currentData= index
      // 清除定时器
      clearTimeout(this.progressTimer)
      this.performScroll(this.currentData)
      this.setPerformance(this.performanceCompliance)
    },
getCurrentMonth() {
  const now = new Date();
  const month = now.getMonth() + 1;
  return month;
},
getCurrentQuarter() {
  const now = new Date();
  const quarterNum = Math.floor((now.getMonth() + 3) / 3); // 获取当前月份所在季度数
  return quarterNum
},
  // 处理平台业务数据
async setPlatForm(res){
  // 根据上方tab栏变更下方数据
   switch (this.managerType) {
    case 'all':
        if(this.current == 0){
        let arr = res.region
        let header = ['排名','大区','销售额']
        this.getNext(arr,0,header)
        console.log('arr',arr);
      }else if(this.current == 1){
        let districtTopTen1 = res.district.districtTopTen1
        let districtTopTen2 = res.district.districtTopTen2
        let header = ['排名','片区','销售额']
        if(districtTopTen2.length){
          this.getNext(districtTopTen1,0,header)
          this.platTime = setTimeout(()=>{
            this.getNext(districtTopTen2,10,header)
          },30000)
        }else{
          this.getNext(districtTopTen1,0,header)
        }
      }else if(this.current == 2){
        let agencyTopTen1 = res.agency.agencyTopTen1
        let agencyTopTen2 = res.agency.agencyTopTen2
        let header = ['排名','办事处','销售额']
        // agencyTopTen1 = [
        //   {name:'汕头办事处',price:123},
        //   {name:'汕头',price:456},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        // ]
        if(agencyTopTen2.length){
          this.getNext(agencyTopTen1,0,header)
          this.platTime = setTimeout(()=>{
            this.getNext(agencyTopTen2,10,header)
          },30000)
        }else{
          this.getNext(agencyTopTen1,0,header)
        }
      }else if(this.current == 3){
        let attacheTopTen1 = res.attache.attacheTopTen1
        let attacheTopTen2 = res.attache.attacheTopTen2
        let header = ['排名','信息员','所属办事处','销售额']
        // attacheTopTen1 = [
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        // ]
        if(attacheTopTen2.length){
          this.getNext(attacheTopTen1,0,header)
          this.platTime = setTimeout(()=>{
            this.getNext(attacheTopTen2,10,header)
          },30000)
        }else{
          this.getNext(attacheTopTen1,0,header)
        }
      }
          break;
    case'region':
      if(this.current == 0){
        let districtTopTen1 = res.district.districtTopTen1
        let districtTopTen2 = res.district.districtTopTen2
        let header = ['排名','片区','销售额']
        if(districtTopTen2.length){
          this.getNext(districtTopTen1,0,header)
          this.platTime = setTimeout(()=>{
            this.getNext(districtTopTen2,10,header)
          },30000)
        }else{
          this.getNext(districtTopTen1,0,header)
        }
      }else if(this.current == 1){
        let agencyTopTen1 = res.agency.agencyTopTen1
        let agencyTopTen2 = res.agency.agencyTopTen2
        let header = ['排名','办事处','销售额']
        // agencyTopTen1 = [
        //   {name:'汕头办事处',price:123},
        //   {name:'汕头',price:456},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        // ]
        if(agencyTopTen2.length){
          this.getNext(agencyTopTen1,0,header)
          this.platTime = setTimeout(()=>{
            this.getNext(agencyTopTen2,10,header)
          },30000)
        }else{
          this.getNext(agencyTopTen1,0,header)
        }
      }else if(this.current == 2){
        let attacheTopTen1 = res.attache.attacheTopTen1
        let attacheTopTen2 = res.attache.attacheTopTen2
        let header = ['排名','信息员','所属办事处','销售额']
        // attacheTopTen1 = [
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        // ]
        if(attacheTopTen2.length){
          this.getNext(attacheTopTen1,0,header)
          this.platTime = setTimeout(()=>{
            this.getNext(attacheTopTen2,10,header)
          },30000)
        }else{
          this.getNext(attacheTopTen1,0,header)
        }
      }
          break;
    case'district':
   if(this.current == 0){
        let agencyTopTen1 = res.agency.agencyTopTen1
        let agencyTopTen2 = res.agency.agencyTopTen2
        let header = ['排名','办事处','销售额']
        // agencyTopTen1 = [
        //   {name:'汕头办事处',price:123},
        //   {name:'汕头',price:456},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        //   {name:'汕头',price:123},
        // ]
        if(agencyTopTen2.length){
          this.getNext(agencyTopTen1,0,header)
          this.platTime = setTimeout(()=>{
            this.getNext(agencyTopTen2,10,header)
          },30000)
        }else{
          this.getNext(agencyTopTen1,0,header)
        }
      }else if(this.current == 1){
        let attacheTopTen1 = res.attache.attacheTopTen1
        let attacheTopTen2 = res.attache.attacheTopTen2
        let header = ['排名','信息员','所属办事处','销售额']
        // attacheTopTen1 = [
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        //   {name:'莉莉',attache:'深圳',price:12},
        // ]
        if(attacheTopTen2.length){
          this.getNext(attacheTopTen1,0,header)
          this.platTime = setTimeout(()=>{
            this.getNext(attacheTopTen2,10,header)
          },30000)
        }else{
          this.getNext(attacheTopTen1,0,header)
        }
      }
          break;

        default:
          break;
      }
    },
      // 执行顺序
async getNext(data,num,header){
  // 判断数据数组是否为空
  if (data.length) {
    // 转换数据为目标格式
    data = this.$public.transData(data)
    // 遍历数据数组
    for (let i = 0; i < data.length; i++) {
      let color, backgroundColor
      if (i === 0 && num == 0) { // 第一名使用 white 文字和橙色背景
        color = '#FFFFFF'
        backgroundColor = 'url(https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/11/592d7226-4f2c-4fa7-849a-fa7baf4f99ee.png)no-repeat center center;'
      } else if (i === 1 && num == 0) { // 第二名使用 white 文字和深绿色背景
        color = '#FFFFFF'
        backgroundColor = 'url(https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/11/dc4d65d0-5704-48da-b316-5f7c1d7f0530.png)no-repeat center center;'
      } else if (i === 2 && num == 0) { // 第三名使用 white 文字和蓝色背景
        color = '#FFFFFF'
        backgroundColor = 'url(https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/11/d870711e-318f-40d8-8726-1d70ae360adf.png)no-repeat center center;'
      } else { // 其他名次使用默认颜色和背景
        color = '#1D89B1'
        backgroundColor = 'url(https://638.oss-cn-shenzhen.aliyuncs.com/2023/10/11/a98cf9f6-f559-49d9-8c19-c7d3a7f47fb9.png)no-repeat center center;'
      }
      // 定义用于添加到数组前面的 HTML 字符串，其中包含一个圆形图标和一个数字
      let arr = '<span style="height:100%;display:inline-block;border-radius:50%;background:' + backgroundColor + ';width:25px;height:25px;font-size:15px;color:' + color + ';font-family:Arial;font-weight:700;line-height: 25px;">' +
        Number(num+i+1) + '</span>'
      // 将 HTML 字符串添加到当前项的数组前面
      data[i].unshift(arr)
      if(data[i][data[i].length - 1]){
        // 获取最后一个金额字段，将其包裹在 span 标签中，并设置样式属性 color 为白色
        let lastField = '<span style="color: #B0F9FD;font-size: 16px;font-weight: 700;box-shadow: 0 2px 10px 0 #0000004d;">' + data[i][data[i].length - 1] + '</span>'
        // 替换原始的数组元素，以便显示样式化的金额字段
        data[i][data[i].length - 1] = lastField
      }
    }
    // 更新配置信息
    this.config_1.data = data
    this.config_1.header = header
    this.config_1 = {...this.config_1}
  }
},

      // 处理平台的tab数据滚动
setScroll(current){
      switch (current) {
        case 0:
        this.tabTimer = setTimeout(()=> {
             this.current = 1
             this.setScroll(this.current)
             this.setPlatForm(this.platformServiceData)
          },60000)
          break;
        case 1:
        this.tabTimer = setTimeout(()=> {
             if(this.managerType == 'district'){
              this.current = 0
             }else{
              this.current = 2
             }
             this.setScroll(this.current)
             this.setPlatForm(this.platformServiceData)
          },60000)
          break;
        case 2:
        this.tabTimer = setTimeout(()=> {
             if(this.managerType == 'region'){
              this.current = 0
             }else{
              this.current = 3
             }
             this.setScroll(this.current)
             this.setPlatForm(this.platformServiceData)
          },60000)
          break;
        case 3:
        this.tabTimer = setTimeout(()=> {
            this.current = 0
            this.setScroll(this.current)
            this.setPlatForm(this.platformServiceData)
        },60000)
        break;
        default:
          break;
      }
    },
    // 点击大区切换
getSelect(index){
      this.current = index
      // 清除定时器
      clearTimeout(this.tabTimer)
      clearTimeout(this.platTime)
      this.setScroll(this.current)
      this.setPlatForm(this.platformServiceData)
    },
       // 修改日历时确认选择
  changeDate(e){
    console.log('e',e,this.daterange);
    if(e){
      if(this.inquire.dayOrMonth == 2){
      this.daterange[1] = this.$public.getAssignMonth(this.daterange[1])[1]
      this.daterange = [...this.daterange]
      this.inquire.startTime = this.daterange.length > 0 ? this.daterange[0] + ' 00:00:00':''
      this.inquire.endTime = this.daterange.length> 0 ? this.daterange[1] + ' 23:59:59':''
      // 发起请求
      this.clear()
      this.getData()
      this.setScroll(this.current)
    }else if(this.inquire.dayOrMonth == 1){
      this.inquire.startTime = this.daterange[0]
      this.inquire.endTime = this.daterange[1]
      // 发起请求
      this.clear()
      this.getData()
      this.setScroll(this.current)
    }
    }else{
      this.pickerMinDate = ""//获取开始选择时间
      this.pickerMaxDate = ''//获取结束选择时间
      this.$refs.datePicker.pickerVisible = true
      this.daterange = []
    }
  },
  selectRadio(current){
    this.ratioCurrent = current
    clearTimeout(this.pieR)
    clearInterval(this.ratioTime)
    // this.ratioScroll(current)
    // console.log('this.ratioCurrent',this.ratioCurrent);
    this.setRatio(this.productBrowseOrSales)
    this.ratioTime = setInterval(()=>{
        this.setRatio(this.productBrowseOrSales)
    },60000)
    },
  // 处理产品浏览占比以及产品销量占比
  setRatio(res){
    // 一分钟之后修改数据
     if(this.managerType == 'all'){
        if(this.ratioCurrent == 0){
          let title = '产品销量占比'
          let arr = res.salesRatio
          let data = []
          let titleList = []
          arr.forEach(item=>{
            let obj = {
              name:item.productName,
              value:item.productSales
            }
            data.push(obj)
            if(item.productName){
              titleList.push(item.productName)
            }
          })
          this.$refs.pieChart && this.$refs.pieChart.getPieChart(title,titleList,data)
          this.pieR = setTimeout(()=>{
            let title = '产品浏览量占比'
            let arr = res.browseRatio
            let data = []
            let titleList = []
            arr.forEach(item=>{
              let obj = {
                name:item.productName,
                value:item.browseCount
              }
              data.push(obj)
              if(item.productName){
                titleList.push(item.productName)
              }
            })
            this.$refs.pieChart && this.$refs.pieChart.getPieChart(title,titleList,data)
            this.ratioCurrent = 1
          },60000)
        }else if (this.ratioCurrent == 1){
          let title = '产品浏览量占比'
            let arr = res.browseRatio
            let data = []
            let titleList = []
            arr.forEach(item=>{
              let obj = {
                name:item.productName,
                value:item.browseCount
              }
              data.push(obj)
              if(item.productName){
                titleList.push(item.productName)
              }
            })
            this.$refs.pieChart && this.$refs.pieChart.getPieChart(title,titleList,data)
          this.pieR = setTimeout(()=>{
            let title = '产品销量占比'
            let arr = res.salesRatio
            let data = []
            let titleList = []
            arr.forEach(item=>{
              let obj = {
                name:item.productName,
                value:item.productSales
              }
              data.push(obj)
              if(item.productName){
                titleList.push(item.productName)
              }
            })
            this.$refs.pieChart && this.$refs.pieChart.getPieChart(title,titleList,data)
            this.ratioCurrent = 0
          },60000)
        }
     }else{
      let title = '产品销量占比'
      let arr = res.salesRatio
      let data = []
      let titleList = []
      arr.forEach(item=>{
        let obj = {
          name:item.productName,
          value:item.productSales
        }
        data.push(obj)
        if(item.productName){
          titleList.push(item.productName)
        }
      })
      this.$refs.pieChart && this.$refs.pieChart.getPieChart(title,titleList,data)
     }
    },
        // 处理营销情况
  setMarket(res){
    this.marketing = res
    this.saleConfig.number[0] = this.marketing.totalPrice
    this.saleConfig = { ...this.saleConfig}
    this.orderConfig.number[0] = this.marketing.totalOrder
    this.orderConfig = { ...this.orderConfig}
  },
        // 处理日访问数据
  setDaily(res){
      let dataTime = []
      let dailyVisitors = []
      let newMember = []
      let dailyVisits = []
      res.dailyVisitors.forEach(item=>{
        dailyVisitors.push(item.peopleNum)
        dataTime.push(item.time)
        })
        res.newMember.forEach(item=>{
          newMember.push(item.peopleNum)
        })
        res.dailyVisits.forEach(item=>{
          dailyVisits.push(item.peopleNum)
        })
        this.$refs.interview.getDataBottom(dataTime,dailyVisitors,newMember,dailyVisits)
    },
        // 处理销售额趋势分析数据
    setSales(res){
    let title = '销售额趋势分析'
    let current = []
    let hourList = []
    let header = []
    res.forEach(item =>{
      current.push(item.salesRevenue)
      hourList.push(item.stringTime)
    })
    this.$refs.saleChart.getDataOrder(title,hourList,current,header)
  },
  // 处理订单数量24小时趋势分析
  setOrders(res){
    let title = '订单24小时趋势分析'
    let current = []
    let hourList = []
    let header = []
    res.forEach(item =>{
      current.push(item.orderCount)
      hourList.push(item.hour)
    })
    this.$refs.orderChart.getDataOrder(title,hourList,current,header)
  },
  // 用户购买力分析
  setBuy(res){
    for (const key in res) {
      res[key] = Number(res[key])
    }
    this.purchasingPowerAnalysis = res
  },
  // 处理转化率趋势分析
  setConversion(res){
    let conversionRate = []
    let time = []
    let averageConversionRate = []
    res.conversionRate.forEach(item => {
      conversionRate.push(Number(item.conversionRate))
       time.push(item.stringTime)
    })
    // 处理平均值
    time.forEach(item=>{
      averageConversionRate.push(Number(res.averageConversionRate))
    })
    // console.log('averageConversionRate',time,conversionRate,averageConversionRate);
    this.$refs.conversion.getDataChart(time,averageConversionRate,conversionRate)
  },
  // 清除所有定时器
  clear(){
    this.isConnect = false
    clearTimeout(this.pieR)
    clearInterval(this.ratioTime)
    clearTimeout(this.tabTimer)
    clearTimeout(this.platTime)
    },
  // socket 通信
  async createSocket (){
  // 环境的配置
  let baseURL = ''
  let baseURL1 = ''
  let baseURL2 = ''
  let baseURL3 = ''
  let baseURL4 = ''
  let baseURL5 = ''
  if (process.env.NODE_ENV == 'development') {
    // baseURL = 'ws://192.168.3.58:8003/customer/ws/realTime/PB9ys6rhwV0_m4uA';
    // baseURL1 = 'ws://192.168.3.58:8002/pharmacy/ws/realTime/PB9ys6rhwV0_m4uA';
    // baseURL2 = 'ws://192.168.3.58:8001/management/ws/realTime/PB9ys6rhwV0_m4uA';
    // baseURL3 = 'ws://192.168.3.58:8003/customer/wss/realTime/PB9ys6rhwV0_m4uA';
    // baseURL4 = 'ws://192.168.3.58:8002/pharmacy/wss/realTime/PB9ys6rhwV0_m4uA';
    // baseURL5 = 'ws://192.168.3.58:8001/management/wss/realTime/PB9ys6rhwV0_m4uA';
    baseURL = 'ws://test-user-api.638yipin.uyaoku.com/customer/ws/realTime/PB9ys6rhwV0_m4uA';
    baseURL1 = 'ws://test-merchant-api.638yipin.uyaoku.com/pharmacy/ws/realTime/PB9ys6rhwV0_m4uA';
    baseURL2 = 'ws://test-manager-api.638yipin.uyaoku.com/management/ws/realTime/PB9ys6rhwV0_m4uA';
    baseURL3 = 'ws://test-user-api.638yipin.uyaoku.com/customer/wss/realTime/PB9ys6rhwV0_m4uA';
    baseURL4 = 'ws://test-merchant-api.638yipin.uyaoku.com/pharmacy/wss/realTime/PB9ys6rhwV0_m4uA';
    baseURL5 = 'ws://test-manager-api.638yipin.uyaoku.com/management/wss/realTime/PB9ys6rhwV0_m4uA';
  }
  else if (process.env.NODE_ENV == 'staging') {
    baseURL = 'ws://test-user-api.638yipin.uyaoku.com/customer/ws/realTime/PB9ys6rhwV0_m4uA';
    baseURL1 = 'ws://test-merchant-api.638yipin.uyaoku.com/pharmacy/ws/realTime/PB9ys6rhwV0_m4uA';
    baseURL2 = 'ws://test-manager-api.638yipin.uyaoku.com/management/ws/realTime/PB9ys6rhwV0_m4uA';
    baseURL3 = 'ws://test-user-api.638yipin.uyaoku.com/customer/wss/realTime/PB9ys6rhwV0_m4uA';
    baseURL4 = 'ws://test-merchant-api.638yipin.uyaoku.com/pharmacy/wss/realTime/PB9ys6rhwV0_m4uA';
    baseURL5 = 'ws://test-manager-api.638yipin.uyaoku.com/management/wss/realTime/PB9ys6rhwV0_m4uA';
  } else if (process.env.NODE_ENV == 'production') {
    baseURL = 'wss://user-api.'+ $hostname +'.com/ws/realTime/PB9ys6rhwV0_m4uA';
    baseURL1 = 'wss://merchant-api.'+ $hostname +'.com/ws/realTime/PB9ys6rhwV0_m4uA';
    baseURL2 = 'wss://manager-api.'+ $hostname +'.com/ws/realTime/PB9ys6rhwV0_m4uA';
    baseURL3 = 'wss://user-api.'+ $hostname +'.com/wss/realTime/PB9ys6rhwV0_m4uA';
    baseURL4 = 'wss://merchant-api.'+ $hostname +'.com/wss/realTime/PB9ys6rhwV0_m4uA';
    baseURL5 = 'wss://manager-api.'+ $hostname +'.com/wss/realTime/PB9ys6rhwV0_m4uA';
  }
    let socket
    socket = new WebSocket(baseURL);
    this.socket = socket;
    socket.onopen = this.onopenWs;
    socket.onclose = this.oncloseWs;
    socket.onerror = this.onerrorWs;
    socket.onmessage = this.onmessageWs;
    let socket1
    socket1 = new WebSocket(baseURL1);
    this.socket1 = socket1
    socket1.onopen = this.onopenWs;
    socket1.onclose = this.oncloseWs;
    socket1.onerror = this.onerrorWs;
    socket1.onmessage = this.onmessageWs;
    let socket2
    socket2 = new WebSocket(baseURL2);
    this.socket2 = socket2
    socket2.onopen = this.onopenWs;
    socket2.onclose = this.oncloseWs;
    socket2.onerror = this.onerrorWs;
    socket2.onmessage = this.onmessageWs;
    let socket3
    socket3 = new WebSocket(baseURL3);
    this.socket3 = socket3
    socket3.onopen = this.onopenWs;
    socket3.onclose = this.oncloseWs;
    socket3.onerror = this.onerrorWs;
    socket3.onmessage = this.onmessageWs;
    let socket4
    socket4 = new WebSocket(baseURL4);
    this.socket4 = socket4
    socket4.onopen = this.onopenWs;
    socket4.onclose = this.oncloseWs;
    socket4.onerror = this.onerrorWs;
    socket4.onmessage = this.onmessageWs;
    let socket5
    socket5 = new WebSocket(baseURL5);
    this.socket5 = socket5
    socket5.onopen = this.onopenWs;
    socket5.onclose = this.oncloseWs;
    socket5.onerror = this.onerrorWs;
    socket5.onmessage = this.onmessageWs;
    this.$router.afterEach(() => {
      socket.close()
      socket1.close()
      socket2.close()
      socket3.close()
      socket4.close()
      socket5.close()
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      clearTimeout(this.rec)
    })
},
reConnect (){
  this.oncloseWs()
    console.log('尝试重新连接');
    //如果已经连上就不在重连了
    if(this.isConnect) return;
    this.rec&&clearTimeout(this.rec);
    // 延迟5秒重连  避免过多次过频繁请求重连
    this.rec=setTimeout(()=>this.createSocket(),5000);
},
onopenWs (event){
 console.log("链接", event);
//连接建立后修改标识
 this.isConnect=true;
//  this.socket.send("websocket client connect testss");

},
oncloseWs(event) {
 console.log("断开链接", event);
 //连接断开后修改标识
 this.isConnect=false;
 this.cancelFullscreen()
 this.$router.push('/largeScreenLogin')
 // 进行重连
//  setTimeout(()=>{
//     this.createSocket()
//    },5000);
},

onerrorWs (event) {
 console.log("出现错误", event);
 this.isConnect=false;
  //连接错误 需要重连
 this.reConnect();
 setTimeout(()=>{
  this.createSocket()
   },5000);

},
onmessageWs (event){
 console.log('收到消息了----',event)
 let msg = JSON.parse(event.data)
 console.log('msg', msg);
 if(msg.pong){
  return this.reset()
 }
 this.getMsg(msg)
 window.dispatchEvent(new CustomEvent('onmessageWS', {
     detail: {
       data: event,
     }
 }))
},
reset() { // 重置心跳
      // 清除时间
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      // 重启心跳
      this.start();
      },
websocketsend(messsage) {
  this.socket.send(messsage)
  this.socket1.send(messsage)
  this.socket2.send(messsage)
  this.socket3.send(messsage)
  this.socket4.send(messsage)
  this.socket5.send(messsage)
    },
start() { // 开启心跳
  this.timeoutObj && clearTimeout(this.timeoutObj);
  this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
  this.timeoutObj = setTimeout(() => {
    // 这里发送一个心跳，后端收到后，返回一个心跳消息，
    if (this.socket && this.socket.readyState == 1 && this.socket1 && this.socket1.readyState == 1 && this.socket2 && this.socket2.readyState == 1 && this.socket3 && this.socket3.readyState == 1 && this.socket4 && this.socket4.readyState == 1  && this.socket5 && this.socket5.readyState == 1) { // 如果连接正常
      let actions = 'ping';
      console.log('actions: ' , actions);
      this.websocketsend(actions);
    } else { // 否则重连
      this.socket && this.socket1 && this.socket2 && this.socket3 && this.socket4 && this.socket5 && this.reconnect();
    }
    // this.serverTimeoutObj = setTimeout(() => {
    //   //超时关闭
    // this.socket.close()
    // this.socket1.close()
    // this.socket2.close()
    // },this.timeout);
  }, this.timeout)
    },
// 处理收到的消息
// 处理收到的消息
async getMsg(msg){
try {
  // 处理中间区域以及下方订单
if(msg.order){
  //  订单为医品
  let data  = []
// 如果为全国权限或者登录的当前账号权限是管理当前订单区域的，则对应高亮
// 判断是大区账号还是片区账号，与websocket返回的数据对应比对
  if(this.managerType == 'all'){
    if(msg.order.orderClassify == 'medicine'){
    if(msg.order.province){
        let list = []
        list = msg.order.productNameAndQuantityList
        this.product = ''
        this.barrage = ''
        this.attacheName = ''
        this.price = ''
        if(list.length){
          list.forEach(item=>{
        if(item.productName){
          this.product += item.productName + " *" + item.itemNum + '、'
        }
        })
        }
      this.product = this.product.slice(0, -1);
      this.price = "（" + msg.order.orderTotalPrice + "元）"
      let obj = {
      name:this.removeSpecialWords(msg.order.province),
      title:'专员',
      attachedName:msg.order.attacheName || '无专员',
      region:msg.order.regionName + " / " + msg.order.districtName + ' / ' + msg.order.agencyName,
      product:this.product,
      amount: msg.order.orderTotalPrice
       }
       data.push(obj)
  if(msg.order.attacheName){
      this.attacheName = msg.order.attacheName
      this.barrage = "（" + msg.order.regionName + " / " + msg.order.districtName + ' / ' + msg.order.agencyName
+ "）" + "—"
    }else{
      this.attacheName = '无专员'
      this.barrage = '无专员' + "（" + msg.order.regionName + " / " + msg.order.districtName + ' / ' + msg.order.agencyName
+ "）" + "—"
    }
  }}else{
     if(msg.order.orderCode){
     this.product = ''
     this.barrage = ''
     this.attacheName = ''
     this.price = ''
     let list = []
     list = msg.order.productNameAndQuantityList
    if(list.length){
    list.forEach(item=>{
   if(item.productName){
    this.product += item.productName + " *" + item.itemNum + '、'
   }
  })
  }
  this.product = this.product.slice(0, -1);
  this.attacheName = '电商品'
  this.barrage = "(" + msg.order.province + " / " + msg.order.city + ")" + '—'
  this.price = "（" + msg.order.orderTotalPrice + "元）"
    let obj = {
      name:this.removeSpecialWords(msg.order.province),
      title:'类型',
      attachedName:'电商品',
      region:msg.order.province + " / " + msg.order.city,
      product:this.product,
      amount: msg.order.orderTotalPrice
    }
    data.push(obj)
     }
  }
    if(msg.order.province){
      let province = this.removeSpecialWords(msg.order.province)
      if(province){
        this.$refs.mapChart.setMap(initjson('china'),province,data)
      }
    }
  }else{
    if((this.managerType == 'region' && this.areaId == msg.order.regionId) || (this.managerType == 'district' && this.areaId == msg.order.districtId)){
      if(msg.order.orderClassify == 'medicine'){
    if(msg.order.province){
        this.product = ''
        this.barrage = ''
        this.attacheName = ''
        this.price = ''
        let list = []
        list = msg.order.productNameAndQuantityList
        if(list.length){
          list.forEach(item=>{
        if(item.productName){
          this.product += item.productName + " *" + item.itemNum + '、'
        }
        })
        }
        this.product = this.product.slice(0, -1);
        this.price = "（" + msg.order.orderTotalPrice + "元）"
      let obj = {
      name:this.removeSpecialWords(msg.order.province),
      title:'专员',
      attachedName:msg.order.attacheName || '无专员',
      region:msg.order.regionName + " / " + msg.order.districtName + ' / ' + msg.order.agencyName,
      product:this.product,
      amount: msg.order.orderTotalPrice
       }
       data.push(obj)
       if(msg.order.attacheName){
        this.attacheName = msg.order.attacheName
        this.barrage = "（" + msg.order.regionName + " / " + msg.order.districtName + ' / ' + msg.order.agencyName
+ "）" + "—"
    }else{
      this.attacheName = '无专员'
      this.barrage = "（" + msg.order.regionName + " / " + msg.order.districtName + ' / ' + msg.order.agencyName
+ "）" + "—"
    }
  }}else{
     if(msg.order.orderCode){
     this.product = ''
     this.barrage = ''
     this.attacheName = ''
     this.price = ''
     let list = []
     list = msg.order.productNameAndQuantityList
     if(list.length){
    list.forEach(item=>{
   if(item.productName){
    this.product += item.productName + " *" + item.itemNum + '、'
   }
  })
  }
  this.product = this.product.slice(0, -1);
  this.attacheName = '电商品'
  this.barrage = "(" + msg.order.province + " / " + msg.order.city + ")" + '—'
  this.price = "（" + msg.order.orderTotalPrice + "元）"
    let obj = {
      name:this.removeSpecialWords(msg.order.province),
      title:'类型',
      attachedName:'电商品',
      region:msg.order.province + " / " + msg.order.city,
      product:this.product,
      amount: msg.order.orderTotalPrice
    }
    data.push(obj)
     }
  }
    if(msg.order.province){
      let province = this.removeSpecialWords(msg.order.province)
      if(province){
        this.$refs.mapChart.setMap(initjson('china'),province,data)
      }
    }
    }
  }
}
  setTimeout(async ()=>{
    this.clear()
    await this.getData()
    this.setScroll(this.current)
  },500)
} catch (error) {

}
},
removeSpecialWords(str) {
  const pattern = /省|自治区|特别行政区|壮族|回族|市/g;
  return str.replace(pattern, '');
},
removeCityWords(str) {
  const pattern = /城区/g;
  return str.replace(pattern, '市');
},
 pieOutlineFunc () {
      var _this=this
      window.addEventListener('resize', function(e){
        if (!_this.isFullScreen()) {
         // 非全屏状态
         //业务逻辑
        try {
        document.removeEventListener('fullscreenchange', () => {
            return
        })
        window.removeEventListener('resize', this.resize)
        _this.$router.push('/largeScreenLogin')
        } catch (error) {

        }
       }
      })
    },
        // 退出全屏
cancelFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
},
       //判断是否全屏
  isFullScreen() {
    try {
    return !!(document.webkitIsFullScreen || this.fullScreen());
    } catch (error) {

    }
  },
  fullScreen() {
    // let de = document.querySelector('body');
    try {
      let de = document.documentElement;
      if (de && de.requestFullscreen) {
          de.requestFullscreen();
      } else if (de && de.mozRequestFullScreen) {
          de.mozRequestFullScreen();
      } else if (de && de.webkitRequestFullScreen) {
          de.webkitRequestFullScreen();
      }
    } catch (error) {

    }
  },
  },
};
</script>

<style lang="scss" scoped >
@import '../../../src/styles/largeScreen/smallProportion.scss'
</style>
