<!-- 物流弹窗组件 -->
<template>
  <el-dialog 
    class="roll_dialog" 
    width="55%" 
    top="50px"
    :visible.sync="showDialog" 
    :before-close="handleClose" 
    :title="config.sendCode"
  >
    <div class="main">
      <div class="header">物流单号: {{ config.logisticsCode }}</div>
      <div class="logistics">
        <div class="title">
          <span>时间</span>
          <span>跟踪进度</span>
        </div>

        <div class="logistics_row" v-for="(row, index) in logistics" :key="row.id"">
          <div class=" left_box">
          {{ row.date }}
        </div>
        <div style="flex: 1;">
          <div class="right_box" v-for="(child, childInd) in row.child" :key="child.id">
            <span class="tags" :class="{ 'active': index == 0 && childInd == 0 }"></span>
            <div class="cont">
              <div class="cont_date">{{ child.time }}</div>
              <div class="cont_info">{{ child.context }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    logisticsData: {
      type: Array,
      default: () => ([])
    },
    config: {
      type: Object,
      default: () => ({
        sendCode: "寄送样本",
        logisticsCode: "",
      })
    }
  },
  data() {
    return {
      showDialog: false,
      logistics: [],
    }
  },

  watch: {
    logisticsData: {
      handler(val) {
        this.logistics = val;
      },
      immediate: true
    }
  },

  methods: {
    handleClose() {
      this.$emit("handleClose");
    }
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 500px;
  padding: 10px 20px;

  .header {
    margin-bottom: 30px;
  }

  .title {
    display: flex;
    margin-bottom: 20px;
    color: #b2b2b2;

    span:nth-child(1) {
      width: 140px;
      text-align: right;
      padding-right: 30px;
    }

    span:nth-child(2) {
      padding-left: 110px;
    }
  }

  .logistics {
    height: 400px;
    border-bottom: 1px solid #e6e6e6;
    overflow-y: scroll;

    .logistics_row {
      display: flex;
    }

    .left_box {
      width: 140px;
      text-align: right;
      padding-right: 30px;
      border-right: 1px solid #d9d9d9;
    }

    .right_box {
      padding-left: 30px;
      padding-bottom: 20px;
      position: relative;

      .tags {
        display: block;
        width: 6px;
        height: 6px;
        background: #ccc;
        border-radius: 50%;
        position: absolute;
        top: 4px;
        left: -4px;
        box-shadow: 0 0 2px 2px white;
      }

      .active {
        background: #ff3333;
        box-shadow: 0 0 0 4px #ffcccc;
      }

      .cont {
        display: flex;
        margin-bottom: 10px;
      }

      .cont_date {
        width: 80px;
      }

      .cont_info {
        width: 100px;
        flex: 1;
      }
    }

  }

  .logistics::-webkit-scrollbar {
    display: none;
  }

}
</style>