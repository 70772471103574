<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">

        <el-form-item label="产品类别">
          <el-cascader v-model="inquire.categoryId" :props="props" :options="productTypeList" clearable></el-cascader>
        </el-form-item>
        
        <el-form-item label="产品状态">
          <el-select v-model="inquire.productStatus" placeholder="请选择" class="width-140" clearable>
            <el-option v-for="item in productStatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="selectKey" placeholder="请选择" @change="selectValue = ''" class="width-140">
              <el-option label="SKU编号" value="inventoryId"></el-option>
              <el-option label="产品名称" value="productName"></el-option>
            </el-select>
            <el-input v-model="selectValue" placeholder="" class="width-180" @keyup.enter.native="searchList"></el-input>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="inventoryId" label="SKU编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                  <span class="operation-btn" @click="handleInventory(scope.row)">{{ scope.row.inventoryId }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="productCode" label="SPU编号" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="norm" label="规格" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="200" show-overflow-tooltip></el-table-column>
            <el-table-column prop="categoryName" label="所属类别" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="inventorySalesPrice" label="销售价" min-width="120" show-overflow-tooltip></el-table-column>
            <el-table-column prop="inventoryTotal" label="库存" min-width="120"></el-table-column>
            <el-table-column prop="productStatus" label="状态" min-width="120">
              <template slot-scope="scope">
                <span>{{ getStatusMsg(scope.row) }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="100" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleInventory(scope.row)">库存</span> 
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 库存弹框 -->
    <el-dialog
    :title="`库存-${selectProductName}（${selectProductInventory}）`"
    :visible.sync="dialogVisible"
    width="70%">
      <div class="Popout_content">
        <el-form :inline="true"  class="demo-form-inline" size="medium">
          <el-form-item label="">
            <div class="flex-box">
              <el-select v-model="selectKey_2" placeholder="请选择" @change="selectValue_2 = ''" class="width-140">
                <el-option label="仓库编号" value="warehouseNo"></el-option>
                <el-option label="仓库名称" value="warehouseName"></el-option>
              </el-select>
              <el-input v-model="selectValue_2" placeholder="" class="width-180"></el-input>
            </div>
          </el-form-item>

          <el-form-item label="">
            <el-button type="primary" size="small" @click="searchList_2">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table :data="tableData_2" v-loading="loading_2" border height="400px" style="width: 100%">
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{( pageParam_2.pageNum - 1) * pageParam_2.pageSize + (scope.$index + 1) }}</template>
          </el-table-column>
          <el-table-column prop="warehouseNo" label="仓库编号" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="warehouseName" label="仓库名称" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="inventoryTotal" label="库存" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="address" label="地区" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column prop="managerName" label="仓管员" min-width="160"></el-table-column>
          <el-table-column prop="" label="操作" min-width="100" fixed="right">
            <template slot-scope="scope">
              <span class="operation-btn" @click="handleBatch(scope.row)">批次</span> 
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination background :current-page="pageParam_2.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam_2.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam_2.total" @size-change="handleSizeChange_2" @current-change="handleCurrentChange_2" />
        </div>
        <p style="padding-top: 30px"></p>
      </div>
    </el-dialog>

    <!-- 批次弹框 -->
    <el-dialog
    :title="`库存-批次-${selectProductName}（${selectProductInventory}）`"
    :visible.sync="dialogVisible_batch"
    width="70%">
      <div class="Popout_content">
        <el-form :inline="true"  class="demo-form-inline" size="medium">
          <el-form-item label="批次">
            <el-input v-model="inquire_batch.keyword" placeholder="请输入批次" class="width-180"></el-input>
          </el-form-item>

          <el-form-item label="">
            <el-button type="primary" size="small" @click="searchList_batch">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table :data="batchTable" v-loading="loading_batch" border height="400px" style="width: 100%">
          <el-table-column prop="date" label="序号" width="60">
            <template v-slot="scope">{{( pageParam_batch.pageNum - 1) * pageParam_batch.pageSize + (scope.$index + 1) }}</template>
          </el-table-column>
          <el-table-column prop="batchNo" label="批次" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="count" label="库存" min-width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="produceTime" label="生产日期" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="expireTime" label="到期日期" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="validityDays" label="有效期" min-width="120"></el-table-column>
          <el-table-column prop="createTime" label="入库时间" min-width="160" show-overflow-tooltip></el-table-column>
          <el-table-column prop="operatorName" label="操作人" min-width="160" show-overflow-tooltip></el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination background :current-page="pageParam_batch.pageNum" :page-sizes="$api.pageSizes" :page-size="pageParam_batch.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="pageParam_batch.total" @size-change="handleSizeChange_batch" @current-change="handleCurrentChange_batch" />
        </div>
        <p style="padding-top: 30px"></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      // 产品分类
      productTypeList: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        // checkStrictly: true, 
        emitPath: false
      },
      productList: [],
      // 产品状态
      productStatusList: [
        {label: '在售', value: 'on_shelves'},
        {label: '待发售', value: 'for_sale'},
        {label: '已下架', value: 'off_shelves'}
      ],

      // 查询参数
      inquire: {
        categoryId: '',
        productStatus: '',
        inventoryId: '',
        productName: ''
      },
      selectKey: "inventoryId",
      selectValue: "",
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [], 
      commentId: '',// 主键id

      // 库存
      dialogVisible: false, // 库存弹窗
      inquire_2: {
        inventoryId: '',
        warehouseName: '',
        warehouseNo: '',
      },
      selectKey_2: "warehouseName",
      selectValue_2: "",
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading_2: false,
      tableData_2: [],
      selectProductName: '',
      selectProductInventory: '',

      // 批次
      dialogVisible_batch: false, // 批次弹窗
      inquire_batch: {
        warehouseId: '',
        inventoryId: '',
        keyword: '',
      },
      pageParam_batch: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      batchTable: [],
      loading_batch: false,
    };
  },
  created() {
    // 获取数据列表
    this.getTableList();
    this.getProductType();
  },
  methods: {
    getStatusMsg(row) {
      if (row.productStatus === 'on_shelves' && row.isSell == 0) {
        return '停售';
      } else if (row.productStatus === 'on_shelves' && row.isSell == 1) {
        return '在售';
      } else if (row.productStatus === 'for_sale') {
        return '待发售';
      } else if (row.productStatus === 'off_shelves') {
        return '已下架';
      }
      return '';
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      const param = JSON.parse(JSON.stringify(this.inquire));
      param[this.selectKey] = this.selectValue;
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param
      }

      this.loading = true;
      this.$http.requestPost({
        url: '/warehouse/getProductInventoryList',
        param: data,
        loading: false
      }).then(res => {
        const {list, total} = res.data;
        this.loading = false;
        this.tableData = list;
        this.pageParam.total = total;
      }).catch(err => {
        this.loading = false;
        console.log('err', err)
      })
    },
    // 获取产品类型
    getProductType() {
        this.$http.requestGet({url:'/product/category', loading: false}).then(res => {
            this.productTypeList = res.data;
        })
    },

    // ******************* 库存 *******************
    // 库存 - 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1;
      this.pageParam_2.pageSize = size;
      // 刷新(数据)
      this.getTableList()
    },
    // 库存 - 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page;
      // 刷新(页码)
      this.getTableList();
    },
    // 刷新
    searchList_2() {
      this.pageParam_2.pageNum = 1
      this.getProductWarehouseList()
    },
    // 获取仓库产品列表
    getProductWarehouseList() {
      const param = JSON.parse(JSON.stringify(this.inquire_2));
      param[this.selectKey_2] = this.selectValue_2;
      let data = {
        pageNum: this.pageParam_2.pageNum,
        pageSize: this.pageParam_2.pageSize,
        param
      }

      this.loading_2 = true;
      this.$http.requestPost({
        url: '/warehouse/getProductWarehouseList',
        param: data,
        loading: false
      }).then(res => {
        const {list, total} = res.data;
        this.loading_2 = false;
        this.tableData_2 = list;
        this.pageParam_2.total = total;
      }).catch(err => {
        this.loading_2 = false;
        console.log('err', err)
      })
    },
    // 库存 - 打开库存弹窗
    handleInventory(row) {
      this.selectProductName = row.productName;
      this.selectProductInventory = row.inventoryId;
      this.inquire_2.inventoryId = row.inventoryId;
      this.inquire_batch.inventoryId = row.inventoryId;
      this.dialogVisible = true;
      this.searchList_2();
    },
    // 库存 - 打开批次弹窗
    handleBatch(row) {
      this.inquire_batch.warehouseId = row.warehouseId;
      this.dialogVisible_batch = true;
      this.searchList_batch();
    },

    // 库存 - 批次 - 页容量改变
    handleSizeChange_batch(size) {
      this.pageParam_batch.pageNum = 1;
      this.pageParam_batch.pageSize = size;
      // 刷新(数据)
      this.getProductBatchList()
    },
    // 库存 - 批次 - 页码改变
    handleCurrentChange_batch(page) {
      this.pageParam_batch.pageNum = page;
      // 刷新(页码)
      this.getProductBatchList();
    },
    // 库存 - 批次 - 查询
    searchList_batch() {
      this.pageParam_batch.pageNum = 1;
      this.getProductBatchList();
    },
    
    // 获取产品库存批次
    getProductBatchList() {
      let data = {
        pageNum: this.pageParam_batch.pageNum,
        pageSize: this.pageParam_batch.pageSize,
        param: this.inquire_batch
      }

      this.loading_batch = true;
      this.$http.requestPost({
        url: '/warehouse/getProductBatchList',
        param: data,
        loading: false
      }).then(res => {
        const {list, total} = res.data;
        this.loading_batch = false;
        this.batchTable = list;
        this.pageParam_batch.total = total;
      }).catch(err => {
        this.loading_batch = false;
        console.log('err', err)
      })
    }
  },
};
</script>

<style lang="scss" scoped>
  .comment-score {
    height: 40px;
    line-height: 40px;
    align-items: center;
  }

  .img-box {
    width: 32px;
    height: 32px;
  }

  .img-box-show {
    margin: 0 5px 5px 0;
    cursor: pointer;
  }
</style>
