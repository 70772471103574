<template>
  <div class="d-area">
    <el-select
      v-show="showType == 1"
      v-model="district"
      class="width-134"
      clearable
      placeholder="请选择片区"
      @change="areaChanage($event, 2)"
      @clear="clearValue('district')"
    >
      <el-option v-for="item in districtList" :key="item.districtId" :label="item.districtName" :value="item.districtId" />
    </el-select>
    <el-select
      v-show="showType == 1 || showType == 2"
      v-model="agency"
      class="width-134"
      placeholder="请选择办事处"
      clearable
      @change="agencyChanage"
      @clear="clearValue('agency')"
    >
      <el-option v-for="item in agencyList" :key="item.agencyId" :label="item.agencyName" :value="item.agencyId" />
    </el-select>
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
export default {
  name: 'DArea',
  props: {
    showDialog: {
      // 是否显示
      type: Boolean,
      default: false
    },
    allVal: {
      // 返回全部
      type: Boolean,
      default: false
    },
    values: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      userInfo: {},
      showType: 0,
      districtList: [],
      agencyList: [],
      district: '',
      agency: '',
      managerTypeList: [
        { name: '所有权限', id: 'all' },
        { name: '大区权限', id: 'region' },
        { name: '片区权限', id: 'district' },
        { name: '办事处权限', id: 'agency' }
      ]
    }
  },
  watch: {
    values: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.district = ''
        }
      }
    }
  },
  created() {
    this.userInfo = getSessionStorage('userInfo')

    const index = this.managerTypeList.findIndex(e => e.id == this.userInfo.managerType)
    this.showType = index
    if (index == 1) {
      this.areaChanage(this.userInfo.areaId, 1)
    } else if (index == 2) {
      this.areaChanage(this.userInfo.areaId, 2)
    }
  },
  methods: {
    // 区域改变
    areaChanage(e, num, b) {
      if (!e && num == 2) {
        this.agency = ''
        this.agencyList = []
        if (!this.allVal) {
          this.$emit('update:values', e)
        } else {
          this.$emit('update:values', [this.userInfo.areaId, e])
        }
        return
      }
      let url = ''
      if (num == 1) {
        url = '/system/manager/getDistrict/' + e
      } else if (num == 2) {
        url = '/system/manager/getAgency/' + e
        if (!this.allVal) {
          this.$emit('update:values', e)
        } else {
          this.$emit('update:values', [this.userInfo.areaId, e])
        }
      }
      this.getAreaList(url, num, b)
    },
    // 办事处
    agencyChanage(e) {
      // this.$emit("update:values", e)
      if (!this.allVal) {
        this.$emit('update:values', e)
      } else {
        this.$emit('update:values', [this.userInfo.areaId, this.district, e])
      }
      this.$emit('changeHandle', e)
    },
    // 获取区域列表
    getAreaList(url, num, bool = true) {
      this.$http.requestGet({ url, loading: false }).then(res => {
        const data = res.data
        if (num == 1) {
          this.districtList = data
          if (bool) {
            this.district = ''
            this.agency = ''
            this.agencyList = []
          }
        } else {
          this.agencyList = data
          if (bool) {
            this.agency = ''
          }
        }
      })
    },
    // 清空选项
    clearValue(type) {
      if (type === 'district') { // 片区
        this.$emit('update:values', this.userInfo.areaId)
      } else { // 办事处
        this.$emit('update:values', this.district)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
