<template>
  <!-- 医品-待发售产品列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="产品名称">
          <div class="flex-box">
            <el-input v-model="inquire.productName" placeholder="输入产品名称" @keyup.enter.native="searchList" />
          </div>
        </el-form-item>

        <el-form-item label="产品类别">
          <el-cascader v-model="inquire.productCategoryId" :props="props" :options="productTypeList" />
        </el-form-item>

        <el-form-item label="价格">
          <div class="flex-box">
            <el-input v-model="inquire.productPriceMin" placeholder="0" class="width-110" />
            <span class="span-link">-</span>
            <el-input v-model="inquire.productPriceMax" placeholder="0" class="width-110" />
          </div>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="productCode" label="产品编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row, 'read')">{{ scope.row.productCode }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="productName" label="产品名称" min-width="200" show-overflow-tooltip />
            <el-table-column prop="productCategoryName" label="所属类别" min-width="160" />
            <el-table-column prop="" label="销售价" min-width="160">
              <template slot-scope="scope">
                {{ '￥' + scope.row.productPriceMin + ' ~ ￥' + scope.row.productPriceMax }}
              </template>
            </el-table-column>
            <el-table-column prop="productInventoryTotal" label="总库存" min-width="100" />
            <el-table-column prop="productOrder" label="排序" min-width="100" />
            <el-table-column prop="productStatus" label="状态" min-width="100">
              <template slot-scope="scope">
                {{ $api.getNeedValue(productState, scope.row.productStatus, 'valueCode', 'valueName') }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="230" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row, 'edit')">编辑</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="handleRun(scope.row)">运营范围</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="openManageMerchants(scope.row)">管理商家</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="putaway(scope.row)">上架</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>
    <!-- 运营范围弹窗 -->
    <operatingCity v-if="showCity" :show-city="showCity" @setCityValue="getCityValue" />
    <!-- 管理商家弹窗 -->
    <manageMerchants ref="manageMerchants" />
  </div>
</template>

<script>
import { getValue_ } from '@/utils/public'
import operatingCity from '@/components/operatingCity/index.vue'
import manageMerchants from '../components/manageMerchants'

export default {
  components: {
    operatingCity, manageMerchants
  },
  data() {
    return {
      input: '',
      input2: [],

      // 产品类别 级联选项数据
      productTypeList: [],
      props: {
        label: 'categoryName',
        value: 'categoryId',
        children: 'child',
        checkStrictly: true,
        emitPath: false
      },

      // 查询参数
      inquire: {
        isGifts: 0,
        productClassify: 'medicine',
        productStatus: 'for_sale',
        productName: '',
        productCategoryId: '',
        productPriceMin: '',
        productPriceMax: ''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      // 表格数据
      tableData: [],
      productState: [], // 产品状态
      showCity: false
    }
  },
  created() {
    if (sessionStorage.getItem('cacheParamTe')) {
      this.inquire = JSON.parse(sessionStorage.getItem('cacheParamTe'))
    }
    // 获取产品列表
    this.getProductList()
    // 获取产品类别列表
    this.getProductType()
    // 获取产品状态列表
    this.getProductState()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getProductList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getProductList()
    },

    // 获取产品列表
    getProductList() {
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http
        .requestPost({
          url: '/product/page',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
          sessionStorage.removeItem('cacheParamTe')
        })
        .catch(err => {
          this.loading = false
          sessionStorage.removeItem('cacheParamTe')
          console.log(err)
        })
    },

    // 获取产品类型
    getProductType() {
      this.$api.productTypeList({}).then(res => {
        this.productTypeList = res.data
      })
    },
    // 获取产品状态
    getProductState() {
      this.$api.dictionaries('product_status').then(res => {
        this.productState = res.data
      })
    },

    // 编辑
    handleEdit(row, type) {
      sessionStorage.setItem('cacheParamTe', JSON.stringify(this.inquire))
      this.$router.push({
        path: '/product/medicine/addProduct',
        query: {
          id: row.productId,
          type: type
        }
      })
    },

    // 商品上架
    putaway(row) {
      this.$confirm('是否确定上架?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http.requestPut({ url: '/product/on/' + row.productId }).then(res => {
            this.getProductList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            })
          })
        })
        .catch(() => {})
    },

    handleRun(val) {
      this.showCity = !this.showCity
      const cityId = val.productId
      sessionStorage.setItem('cityId', JSON.stringify(cityId))
    },
    getCityValue(val) {
      this.showCity = val
    },

    // 管理商家
    openManageMerchants(row) {
      this.$refs.manageMerchants.open(row)
    }
  }
}
</script>

<style lang="scss" scoped></style>
