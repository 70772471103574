<template>
  <div>
    <div class="questionnaire" v-for="(item, index) in singleList" :key="index">
      <div class="inner-title">
        <div class="left">{{item.content}}</div>
      </div>
      <div v-for="(option, optionIndex) in item.optionList" :key="optionIndex" :name="option.id">
        <div :class="option.id == singleMap[item.id] ? 'isCorrect' : ''">{{ option.content }}{{singleMap[item.id]}}</div>
      </div>
    </div>
    <div class="questionnaire" v-for="(item, index) in multipleList" :key="index">
      <div class="inner-title">
        <div class="left">{{item.content}}</div>
      </div>
      <div v-for="(option, optionIndex) in item.optionList" :key="optionIndex" :name="option.content">
        <div :class="option.isCorrect ? 'isCorrect' : ''">{{ option.content }}</div>
      </div>
    </div>
    <div class="questionnaire" v-for="(item, index) in answerList" :key="index">
      <div class="inner-title">
        <div class="left">{{item.content}}</div>
      </div>
      <div>{{item.answer}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      singleList: [],
      multipleList: [],
      answerList: [],
      singleMap: {},
      multipleMap: {},
      answerMap: {},
      userQuestionVos: [],
      id: null
    }
  },
  async created() {
    this.id = this.$route.params.id;
    await this.getData();
    await this.getQuestionnaireDetailPlatform();

  },
  methods: {
    async getQuestionnaireDetailPlatform() {
      await this.$http.requestGet({ url: "/consult/getQuestionnaireDetailPlatform/1" }).then((res) => {
        if (res.code == 200) {
          if (res.data && res.data.questionDetailVoList && res.data.questionDetailVoList.length > 0) {
            console.log('this.userQuestionVos', this.userQuestionVos);
            // 单选题
            this.singleList = res.data.questionDetailVoList.filter(item => item.type === 1);
            let arr1 = this.userQuestionVos.filter(item => item.type === 1);
            this.singleList.map(item1 => {
              arr1.map(item2 => {
                if (item1.id == item2.questionId) {
                  this.singleMap[item1.id] = item2.optionId
                }
              })
            })
            // 多选题
            this.multipleList = res.data.questionDetailVoList.filter(item => item.type === 2);
            let arr2 = this.userQuestionVos.filter(item => item.type === 2);
            let multipleArr = [];
            arr2.map(item => {
              multipleArr.push(item.optionId)
            })
            this.multipleList.map(item1 => {
              item1.optionList.map(item2 => {
                if (multipleArr.includes(item2.id)) {
                  item2.isCorrect = true
                } else {
                  item2.isCorrect = false
                }
              })
            })
            // 问答题
            this.answerList = res.data.questionDetailVoList.filter(item => item.type === 3);
            let arr3 = this.userQuestionVos.filter(item => item.type === 3);
            this.answerList.map(item1 => {
              arr3.map(item2 => {
                if (item1.id == item2.questionId) {
                  item1.answer = item2.answer;
                }
              })
            })
          }
        }
      })
        .catch((err) => {
          console.log(err);
        });
    },
    async getData() {
      let param = {
        type: 3,
        platform: 1,
        memberId: this.id,
        fillInBeginTime: "",
        fillInEndTime: "",
        memberName: ""
      }
      await this.$http
        .requestPost({
          url: "/consult/healthInformation",
          param: param,
          loading: false,
        })
        .then((res) => {
          this.userQuestionVos = res.data.userQuestionVos
        })
        .catch((err) => {
          console.log(err);
        });
    },
  }
}
</script>

<style lang="scss" scoped>
.isCorrect {
  color: #409eff;
}
.questionnaire {
  background: RGBA(211, 211, 211, 0.4);
  border-bottom: 1px solid RGBA(211, 211, 211, 1);
  padding: 10px;
  .inner-title {
    display: flex;
    color: #333;
    margin-bottom: 10px;
    // justify-content: space-between;
    .left {
      margin-right: 290px;
      font-weight: 550;
    }
    .right {
      color: #555;
    }
  }
  .inner-question {
    font-size: 14px;
    .question-item {
      height: 50px;
    }
    .ques {
      font-weight: 550;
    }
    .ans {
      color: #555;
      margin-left: 22px;
    }
  }
}
</style>