var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-dialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "roll_dialog",
          attrs: {
            visible: _vm.showDialog,
            width: "70%",
            "before-close": _vm.handleClose,
            top: "10px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            open: function ($event) {
              return _vm.init()
            },
          },
        },
        [
          _c("div", { staticClass: "infotitle" }, [
            _c("div", { staticClass: "left_box" }, [
              _c("div", [
                _vm._v("运单号 " + _vm._s(_vm.dataObj ? _vm.dataObj.nu : "")),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "city_box" }, [
                _c("span", [_vm._v(_vm._s(_vm.from))]),
                _vm._v(" "),
                _vm.routeInfo && _vm.routeInfo.to
                  ? _c("span", { staticClass: "iconfont icon" }, [_vm._v("")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.routeInfo && _vm.routeInfo.to
                  ? _c("span", [_vm._v(_vm._s(_vm.to))])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "right_box" }, [
              _vm.isShow(_vm.dataObj ? _vm.dataObj.state : "")
                ? _c("div", { staticClass: "time_box" }, [
                    _c("div", [_vm._v("签收时间")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.time))]),
                  ])
                : _c("div", { staticClass: "status" }, [
                    _vm._v(_vm._s(_vm.status)),
                  ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "map", attrs: { id: "container" } }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "information_box" },
            _vm._l(_vm.logisticsList, function (item, index) {
              return _c("div", { key: index, staticClass: "information" }, [
                _c("div", { staticClass: "info" }, [
                  _vm._v(_vm._s(item.status)),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "time" }, [_vm._v(_vm._s(item.time))]),
                _vm._v(" "),
                _c("div", { staticClass: "context" }, [
                  _vm._v(_vm._s(item.context)),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }