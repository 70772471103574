<template>
    <div class="main-box">
        <div class="back-box">
            <el-button plain size="medium" @click="$router.go(-1)">返回</el-button>
        </div>
        <div class="head-search-row">
            <el-form :inline="true" class="demo-form-inline" size="medium">
                <el-form-item label="">
                    <div class="flex-box">
                        <el-select
                            v-model="inquire.searchKey"
                            placeholder="请选择"
                            @change="inquire.searchValue = ''"
                            class="width-140"
                        >
                            <el-option label="会员编号" value="1"></el-option>
                            <el-option label="微信昵称" value="2"></el-option>
                            <el-option label="评论编号" value="3"></el-option>
                            <el-option label="商家名称" value="4" v-if="inquire.userType == 2"></el-option>
                        </el-select>
                        <el-input v-model="inquire.searchValue" placeholder="" class="width-180"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <div class="flex-box">
                        <el-select v-model="inquire.status" placeholder="请选择类别" class="width-140">
                            <el-option label="请选择" value=" "></el-option>
                            <el-option label="显示" value="0"></el-option>
                            <el-option label="隐藏" value="1"></el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="评论时间">
                    <div class="flex-box">
                        <el-date-picker
                            v-model="daterange"
                            type="daterange"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" size="small" @click="searchList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <el-radio-group v-model="inquire.userType" @change="radioChange" style="padding: 10px 20px;">
            <el-radio-button value="1" label="1">用户评论</el-radio-button>
            <el-radio-button value="2" label="2">商家评论</el-radio-button>
        </el-radio-group>
        <div class="table">
            <el-main>
                <template>
                    <el-table :data="tableData" v-loading="loading" key="id"  ref="singleTable" border height="500px" style="width: 100%">
                        <el-table-column prop="date" label="序号" width="60">
                            <template v-slot="scope">{{
                                (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
                            }}</template>
                        </el-table-column>
                        <el-table-column
                            prop="commentCode"
                            label="评论编号"
                            min-width="160"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column
                            prop="userCode"
                            label="会员编号"
                            min-width="160"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column v-if="showColumn" prop="merchantName" label="商家名称" min-width="160"></el-table-column>
                        <el-table-column prop="nickName" label="微信昵称" min-width="260"></el-table-column>
                        <el-table-column prop="content" label="内容" min-width="160"></el-table-column>
                        <el-table-column prop="createTime" label="评论时间" min-width="160"></el-table-column>
                        <el-table-column prop="status" label="状态" min-width="160"></el-table-column>
                        <el-table-column prop="" label="操作" min-width="160" fixed="right">
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleEdit(scope.row)">详情</span>
                                <span class="operation-btn_partition"> / </span>
                                <span class="operation-btn" @click="handleHide(scope.row, scope.row.status)">
                                    {{ scope.row.status == "1" ? "显示" : "隐藏" }}
                                </span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>

               
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        background
                        :current-page="pageParam.pageNum"
                        :page-sizes="$api.pageSizes"
                        :page-size="pageParam.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageParam.total"
                    ></el-pagination>
                </div>
            </el-main>
        </div>
        <el-dialog :visible.sync="dialogVisible" :before-close="closeAddresCPM" title="评论详情" width="600px">
            <div class="Popout_content">
                <el-form ref="changeAddressForm" :model="commentForm" label-width="80px">
                    <el-form-item label="评论编号" prop="commentCode">
                        <el-input disabled maxlength="150" v-model="commentForm.commentCode" placeholder="评论编号" />
                    </el-form-item>
                    <el-form-item label="会员编号" prop="userCode">
                        <el-input disabled maxlength="150" v-model="commentForm.userCode" placeholder="会员编号" />
                    </el-form-item>
                    <el-form-item label="商家名称" prop="merchantName" v-if="inquire.userType == 2">
                        <el-input disabled maxlength="150" v-model="commentForm.merchantName" placeholder="会员编号" />
                    </el-form-item>
                    <el-form-item label="微信昵称" prop="nickName">
                        <el-input disabled maxlength="150" v-model="commentForm.nickName" placeholder="微信昵称" />
                    </el-form-item>
                    <el-form-item label="评论内容" prop="content">
                        <el-input
                            disabled
                            type="textarea"
                            maxlength="150"
                            v-model="commentForm.content"
                            placeholder="评论内容"
                        />
                    </el-form-item>
                    <el-form-item label="评论时间" prop="createTime">
                        <el-input disabled maxlength="150" v-model="commentForm.createTime" placeholder="评论时间" />
                    </el-form-item>
                    <!-- <el-form-item label="展示用户" prop="sort">
                        <el-radio-group v-model="commentForm.status" disabled>
                            <el-radio label="0">显示</el-radio>
                            <el-radio label="1">隐藏</el-radio>
                        </el-radio-group>
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="closeDialog">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import dUpload from "@/components/d-upload";
import { parse } from "path-to-regexp";
import axios from "axios";
function getSignature() {
    return axios
        .post("/common/file/upload/getQCloudSignature", JSON.stringify({ action: "GetUgcUploadSign" }))
        .then(function(response) {
            return response.data.data;
        });
}
export default {
    components: {
        dUpload
    },
    data() {
        return {
            actionUrl: "/common/file/upload/video",
            // 类别
            categoryTree: [],
            props: {
                label: "categoryName",
                value: "categoryId",
                children: "childes",
                checkStrictly: true,
                emitPath: false
            },
            props_add: {
                label: "categoryName",
                value: "categoryId",
                children: "childes",
                emitPath: false
            },
            // 查询参数
            inquire: {
                articleId: "", // 文章id
                endTime: "", // 结束时间
                startTime: "", // 起始时间
                searchValue: "", // 搜索值
                status: " ",
                searchKey: "1", // 搜索词：1：标题，2：作者
                userType: "1" // tab对象 1: 用户 2：商家
            },
            daterange: [],
            pageParam: {
                pageNum: 1,
                pageSize: 6,
                total: 0
            },
            loading: false,
            // 表格数据
            tableData: [],
            dialogVisible: false,
            commentForm: {
                createTime: "",
                commentCode: "",
                userType: "",
                content: "",
                userCode: "",
                status: ""
            },
            showColumn: false
        };
    },
    created() {
        // 获取数据列表
        this.inquire.articleId = this.$route.query.id;
        this.getTableList();
        this.getCategoryTree();
        this.tcVod = new this.$TcVod({
            getSignature: getSignature
        });
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size;
            // 刷新(数据)
            this.getTableList();
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page;
            // 刷新(页码)
            this.getTableList();
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1;
            this.getTableList();
        },
        // 富文本 图片添加
        handleEditorImgAdd(pos, file) {
            let formdata = new FormData();
            formdata.append("file", file);
            this.$http
                .requestPost({
                    url: "/common/file/upload",
                    param: formdata
                })
                .then(res => {
                    this.$refs.md.$img2Url(pos, res.data);
                });
            this.clearUploadStatus();
        },
        // 富文本 图片删除
        handleEditorImgDel(pos) {
            console.log(pos);
        },
        // 富文本改变后的内容
        editorChange(val, render) {
            this.submitForm.content = render;
        },
        // 获取分类列表
        getCategoryTree() {
            this.$http.requestGet({ url: "/operation/message/category/tree", loading: false }).then(res => {
                this.categoryTree = res.data;
            });
        },
        // 如果分类列表里没有对应的详情id 则清空分类id
        selectTree(arr) {
            let contentCategoryId = this.submitForm.contentCategoryId;
            let flag = false;
            arr.forEach(item => {
                let hasContentCategoryId = "";
                if (item.childes) {
                    hasContentCategoryId = item.childes.filter(v => {
                        return contentCategoryId == v.categoryId;
                    });
                    if (hasContentCategoryId.length >= 1) {
                        flag = true;
                        return false;
                    }
                }
            });
            if (flag == false) {
                this.submitForm.contentCategoryId = "";
            }
        },

        // 获取数据列表
        getTableList() {
            if (!this.daterange) this.daterange = [];
            this.inquire.startTime = this.daterange.length > 0 ? this.daterange[0] + " 00:00:00" : "";
            this.inquire.endTime = this.daterange.length > 0 ? this.daterange[1] + " 23:59:59" : "";
            let data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            };

            this.loading = true;
            this.$http
                .requestPost({
                    url: "/article/getCommentListByArticleId",
                    param: data,
                    loading: false
                })
                .then(res => {
                    this.loading = false;
                    this.tableData = res.data.list;
                    this.pageParam.total = res.data.total;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        },
        // 修改
        handleEdit(row) {
            console.log(row);
            this.dialogVisible = true;
            this.commentForm = row;
        },

        // 关闭弹窗
        closeDialog() {
            this.$refs.submitForm && this.$refs.submitForm.resetFields();
            this.dialogVisible = false;
            this.submitForm = {
                author: "",
                showTime: "",
                sort: "",
                type: "",
                title: "",
                viewPermission: "",
                merchantName: "",
                bottonVideo: [],
                coverImg: [],
                topVideo: []
            };
        },

        // 删除
        delData(row) {
            this.$confirm("确定删除该内容?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
                        this.pageParam.pageNum--;
                    }
                    this.$http
                        .requestDel({
                            url: "/article/del/" + row.id
                        })
                        .then(res => {
                            this.getTableList();
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        });
                })
                .catch(err => {});
        },

        // 是否隐藏
        handleHide(row, type) {
            let id = row.id;
            console.log(typeof row.id);
            let name = type === "1" ? "显示" : "隐藏";
            let status = type === "1" ? "0" : "1";

            this.$confirm("确定" + name + "该评论?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    this.$http
                        .requestPut({
                            url: `/article/modifyArticleCommentStatus/${id}/${status}`
                        })
                        .then(res => {
                            this.getTableList();
                            this.$message({
                                type: "success",
                                message: "操作成功!"
                            });
                        });
                })
                .catch(err => {});
        },
        // 关闭评论详情
        closeAddresCPM() {
            this.dialogVisible = false;
        },
        radioChange(e) {
            this.pageParam.pageNum = 1;
            this.showColumn = e == 2 ? true : false;
            this.getTableList();
            this.$nextTick(() => {
                this.$refs.singleTable.doLayout(); // 解决表格错位
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.editor {
    max-width: 1000px;
    max-height: 420px;
    .v-note-wrapper.shadow {
        height: 420px;
    }
}
</style>
