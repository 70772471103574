var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box", attrs: { id: "main-scroll" } },
    [
      _c(
        "div",
        { staticClass: "back-box" },
        [
          _c(
            "el-button",
            {
              attrs: { plain: "", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "nav-bar bg_white" },
        _vm._l(_vm.navList, function (item, index) {
          return _c(
            "a",
            {
              key: item.id,
              class: { active: item.active },
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.skip(index)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_1" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                ref: "common",
                attrs: {
                  size: "medium",
                  "label-width": "120px",
                  rules: _vm.commonRules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "优惠名称", prop: "discountName" } },
                  [
                    _c("el-input", {
                      attrs: { maxlength: "30", placeholder: "优惠名称" },
                      model: {
                        value: _vm.discountName,
                        callback: function ($$v) {
                          _vm.discountName = $$v
                        },
                        expression: "discountName",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_2" } }, [
        _vm._m(1),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-form",
              {
                attrs: {
                  size: "medium",
                  "label-width": "120px",
                  rules: _vm.commonRules,
                },
              },
              [
                _c(
                  "el-form-item",
                  { attrs: { label: "使用门槛", prop: "couponType" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        on: { input: _vm.changeRadio },
                        model: {
                          value: _vm.couponType,
                          callback: function ($$v) {
                            _vm.couponType = $$v
                          },
                          expression: "couponType",
                        },
                      },
                      _vm._l(_vm.discountList, function (item) {
                        return _c(
                          "el-radio",
                          { key: item.id, attrs: { label: item.value } },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "table-box" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "95%" },
                    attrs: {
                      data: _vm.couponList,
                      border: "",
                      "highlight-current-row": "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { prop: "date", label: "序号", width: "60" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("div", [_vm._v(_vm._s(scope.$index + 1))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponName",
                        label: "名称",
                        "min-width": "150",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "threshold",
                        label: _vm.getTxt,
                        width: "150",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponDiscount",
                        label: "折扣(折)",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponAmount",
                        label: "面额(元)",
                        width: "120",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        prop: "couponStatus",
                        label: "状态",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                _vm._s(
                                  _vm._f("getStatus")(scope.row.couponStatus)
                                )
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: { prop: "", label: "操作", "min-width": "180" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "operation-btn_partition" },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteData(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("i", {
                  staticClass: "el-icon-circle-plus-outline add-icon1",
                  on: { click: _vm.handleEdit },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row_box bg_white", attrs: { id: "row_3" } }, [
        _vm._m(2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content", staticStyle: { "margin-bottom": "80px" } },
          [
            _c(
              "div",
              { staticClass: "add-btn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.addProduct },
                  },
                  [_vm._v("添加产品")]
                ),
                _vm._v(" "),
                !_vm.productStandards.length && _vm.flag
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("请选择使用优惠的产品"),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                staticStyle: { width: "75%", "margin-left": "45px" },
                attrs: {
                  data: _vm.productStandards,
                  border: "",
                  "highlight-current-row": "",
                },
              },
              [
                _c("el-table-column", {
                  attrs: { prop: "date", label: "序号", width: "60" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_c("div", [_vm._v(_vm._s(scope.$index + 1))])]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "productCode",
                    label: "产品编号",
                    "min-width": "180",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "productName",
                    label: "产品名称",
                    "min-width": "150",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "产品类别",
                    "min-width": "140",
                    prop: "productCategoryName",
                    "show-overflow-tooltip": "",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "", label: "产品单价", "min-width": "160" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                "￥" +
                                  scope.row.productPriceMin +
                                  " ~ ￥" +
                                  scope.row.productPriceMax
                              ) +
                              "\n            "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    prop: "onSaleTime",
                    label: "添加时间",
                    "min-width": "180",
                  },
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: { prop: "", label: "操作", "min-width": "180" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "operation-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteData_1(scope.row.productId)
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex-box submit-btn bg_white" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.comfirm } },
                  [_vm._v("确 定")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.$router.go(-1)
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle + "内容",
            visible: _vm.dialogVisible,
            "before-close": _vm.closeDialog,
            width: "500px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    "label-width": "80px",
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称", prop: "couponName" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          placeholder: "请输入名称",
                          disabled: _vm.disabled,
                        },
                        model: {
                          value: _vm.submitForm.couponName,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "couponName", $$v)
                          },
                          expression: "submitForm.couponName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "享用额度", prop: "threshold" } },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "20",
                          type: "number",
                          disabled: _vm.disabled,
                          placeholder: "请输入额度",
                        },
                        model: {
                          value: _vm.submitForm.threshold,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "threshold", $$v)
                          },
                          expression: "submitForm.threshold",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "折扣", prop: "couponDiscount" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          placeholder: "请输入折扣",
                        },
                        model: {
                          value: _vm.submitForm.couponDiscount,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "couponDiscount", $$v)
                          },
                          expression: "submitForm.couponDiscount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "面额", prop: "couponAmount" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          disabled: _vm.disabled,
                          placeholder: "请输入面额",
                        },
                        model: {
                          value: _vm.submitForm.couponAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "couponAmount", $$v)
                          },
                          expression: "submitForm.couponAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "couponStatus" } },
                    [
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 1 },
                          model: {
                            value: _vm.submitForm.couponStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "couponStatus", $$v)
                            },
                            expression: "submitForm.couponStatus",
                          },
                        },
                        [_vm._v("生效")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-radio",
                        {
                          attrs: { label: 0 },
                          model: {
                            value: _vm.submitForm.couponStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitForm, "couponStatus", $$v)
                            },
                            expression: "submitForm.couponStatus",
                          },
                        },
                        [_vm._v("失效")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitAdd } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "未选择产品列表",
            "before-close": _vm.closeDialog_1,
            visible: _vm.dialogShow,
            top: "12vh",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "proDialog",
                  staticClass: "demo-form-inline",
                  attrs: {
                    inline: true,
                    size: "medium",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "搜索产品" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入产品名称" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchList($event)
                          },
                        },
                        model: {
                          value: _vm.inquire.productName,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.inquire,
                              "productName",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "inquire.productName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.searchList },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading_1,
                      expression: "loading_1",
                    },
                  ],
                  ref: "msgTabel",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.productList, border: "", height: "400px" },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "序号", prop: "date", width: "60" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  (_vm.pageParam_1.pageNum - 1) *
                                    _vm.pageParam_1.pageSize +
                                    (scope.$index + 1)
                                ) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品编号",
                      "min-width": "170",
                      prop: "productCode",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品名称",
                      "min-width": "160",
                      prop: "productName",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "产品类别",
                      "min-width": "140",
                      prop: "productCategoryName",
                      "show-overflow-tooltip": "",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "", label: "产品单价", "min-width": "160" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  "￥" +
                                    scope.row.productPriceMin +
                                    " ~ ￥" +
                                    scope.row.productPriceMax
                                ) +
                                "\n            "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.pageParam_1.pageNum,
                      "page-size": _vm.pageParam_1.pageSize,
                      "page-sizes": _vm.$api.pageSizes,
                      total: _vm.pageParam_1.total,
                      background: "",
                      layout: "total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_1,
                      "current-change": _vm.handleCurrentChange_1,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "btn-box" }, [
                _c(
                  "span",
                  {
                    staticClass: "dialog-footer",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("el-button", { on: { click: _vm.closeDialog_1 } }, [
                      _vm._v("取 消"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.submitAdd_1 },
                      },
                      [_vm._v("确 定")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "padding-bottom": "10px" } }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("基本信息")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("使用门槛")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("产品列表")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }