var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { inline: true, size: "medium" },
            },
            [
              _c("el-form-item", { attrs: { label: "类别名称" } }, [
                _c(
                  "div",
                  { staticClass: "flex-box" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "类别名称" },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.getProductList($event)
                        },
                      },
                      model: {
                        value: _vm.inquire.categoryName,
                        callback: function ($$v) {
                          _vm.$set(_vm.inquire, "categoryName", $$v)
                        },
                        expression: "inquire.categoryName",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.getProductList },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tree-props": { children: "childes" },
                      border: "",
                      "row-key": "categoryId",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: { label: "", prop: "", width: "60" },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "类别名称",
                        "min-width": "160",
                        prop: "categoryName",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "类别描述",
                        "min-width": "160",
                        prop: "categoryDescription",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "新增时间",
                        "min-width": "160",
                        prop: "createTime",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        fixed: "right",
                        label: "操作",
                        "min-width": "200",
                        prop: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.categoryLevel !== 2,
                                      expression:
                                        "scope.row.categoryLevel !== 2",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.addChilClass(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("添加子类")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.categoryLevel !== 2,
                                      expression:
                                        "scope.row.categoryLevel !== 2",
                                    },
                                  ],
                                  staticClass: "operation-btn_partition",
                                },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.categoryLevel !== 0,
                                      expression:
                                        "scope.row.categoryLevel !== 0",
                                    },
                                  ],
                                  staticClass: "operation-btn_partition",
                                },
                                [_vm._v(" / ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: scope.row.categoryLevel !== 0,
                                      expression:
                                        "scope.row.categoryLevel !== 0",
                                    },
                                  ],
                                  staticClass: "operation-btn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteProduct(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.closeDialog,
            title: _vm.dialogTitle,
            visible: _vm.dialogVisible,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "Popout_content" },
            [
              _c(
                "el-form",
                {
                  ref: "submitForm",
                  attrs: {
                    model: _vm.submitForm,
                    rules: _vm.submitRules,
                    "label-width": "80px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.addChil ? "父级类别" : "类别名称",
                        prop: "categoryName",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "12",
                          placeholder: _vm.addChil ? "父类名称" : "类别名称",
                          readonly: _vm.addChil,
                        },
                        model: {
                          value: _vm.submitForm.categoryName,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "categoryName", $$v)
                          },
                          expression: "submitForm.categoryName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.addChil,
                          expression: "addChil",
                        },
                      ],
                      attrs: {
                        prop: _vm.addChil ? "categoryName2" : "",
                        label: "子类名称",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { maxlength: "12", placeholder: "子类名称" },
                        model: {
                          value: _vm.submitForm.categoryName2,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "categoryName2", $$v)
                          },
                          expression: "submitForm.categoryName2",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "产品描述", prop: "categoryDescription" },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          rows: 3,
                          placeholder: "请输入内容",
                          maxlength: "100",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.submitForm.categoryDescription,
                          callback: function ($$v) {
                            _vm.$set(_vm.submitForm, "categoryDescription", $$v)
                          },
                          expression: "submitForm.categoryDescription",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.closeDialog } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitClass } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }