<template>
  <!-- 埋点文档导出 -->
  <div class="main-box buriedLog_box">
    <!-- 搜索 -->
    <div class="head-search-row">
      <div class="operate_buttons">
        <el-button type="primary" size="small" @click="openExportDialog(1,'login')">商家端登录埋点导出</el-button>
        <el-button type="primary" size="small" @click="openExportDialog(2,'orderSee')">订单查看埋点导出</el-button>
        <el-button type="primary" size="small" @click="openExportDialog(3,'orderPush')">订单推送埋点导出</el-button>
        <el-button type="primary" size="small" @click="openExportDialog(4,'userOperation')">用户操作埋点导出</el-button>
      </div>
      <el-form ref="tableForm" :model="queryData" :inline="true" size="medium">
        <el-form-item label="导出时间">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeDate('exportDate', $event)"
          />
        </el-form-item>
        <!-- <el-form-item label="操作者">
          <el-input v-model="queryData.param.creatorName" placeholder="操作者" @keyup.enter.native="searchList" />
        </el-form-item> -->
        <el-form-item label="导出状态">
          <el-select v-model="queryData.param.status" placeholder="请选择状态" clearable>
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="main_table">
      <TableList
        ref="mainTable"
        v-loading="tableData.loading"
        class="table_box"
        :table-header="tableData.tableHeader"
        :table-data="tableData.list"
        :total="tableData.total"
        @setPage="pageChange($event, 'mainTable')"
        @setSize="sizeChange($event, 'mainTable')"
      >
        <template #operation="{data}">
          <el-button v-if="data.status === 'KXZ'" class="operate_btn" type="text" @click="singleDownload(data)">下载</el-button>
          <el-button class="operate_btn" type="text" @click="singleDelete(data)">删除</el-button>
        </template>
      </TableList>
    </div>

    <!-- ——————————————————————————弹窗相关 start—————————————————————————— -->
    <el-dialog :title="`${dialogTitle}埋点导出`" :visible.sync="exportDialog" width="550px">
      <el-form :model="exportQueryData" label-width="100px" size="medium">
        <el-form-item label="操作时间">
          <el-date-picker
            v-model="operateDaterange"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="changeDate('operateDate', $event)"
          />
        </el-form-item>
        <el-form-item v-if="dialogType === 3" label="区域">
          <el-cascader
            v-if="$public.isAllAreaPower()"
            v-model="exportQueryData.regionId"
            :props="addressConfigure"
            :options="addressList"
            clearable
            placeholder="请选择地址"
          />
          <d-area v-else :values.sync="exportQueryData.regionId" />
        </el-form-item>
        <el-form-item v-if="dialogType === 1" label="账号">
          <div style="width: 350px;">
            <el-input v-model="exportQueryData.accountName" placeholder="请输入商家账号/管理员账号" clearable />
          </div>
        </el-form-item>
        <el-form-item v-if="dialogType !== 1 && dialogType !== 4" label="订单编号">
          <div style="width: 350px;">
            <el-input v-model="exportQueryData.orderCode" placeholder="请输入订单编号" clearable />
          </div>
        </el-form-item>
        <el-form-item style="text-align: right; padding: 20px 0; margin: 0;">
          <el-button type="primary" @click="exportBuriedLog">导出</el-button>
          <el-button @click="exportDialog = false">关闭</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- ——————————————————————————弹窗相关 end———————————————————————————— -->
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import { exportFilePage, burialDataExport } from '@/api/api'
import { deleteLogic } from '@/api/order'

export default {
  components: {},
  data() {
    return {
      // 区域选择下拉配置
      addressConfigure: {
        label: 'areaName',
        value: 'areaId',
        children: 'childes',
        checkStrictly: true,
        emitPath: false
      },
      // 区域数据
      addressList: [],

      daterange: [], // 导出时间日期范围
      operateDaterange: [], // 操作时间日期范围
      // 文档状态下拉数据
      statusOptions: [
        { value: 'CLZ', label: '处理中' },
        { value: 'KXZ', label: '可下载' },
        { value: 'DCSB', label: '导出失败' }
      ],
      // 请求参数
      queryData: {
        param: {
          fileName: '', // 文件名称
          type: 'burial', // 导出类型 order-订单 purchase-申购 burial-埋点文档
          status: '', // 状态：CLZ-处理中；KXZ-可下载；DCSB-导出失败
          creatorName: '', // 操作者名称
          beginTime: '', // 起始导出时间
          endTime: '' // 结束导出时间
        },
        pageNum: 1,
        pageSize: 10
      },
      tableData: {
        // 表格数据
        loading: false,
        tableHeader: [
          // 表头
          { label: '文件名', prop: 'fileName' },
          { label: '导出时间', prop: 'createTime' },
          { label: '导出状态', prop: 'statusValue' },
          { label: '操作', type: 'slot', slotName: 'operation' }
        ],
        list: [],
        total: 0
      },

      dialogType: '', // 弹窗类型 1-商家登录 2-订单查看 3-订单推送
      exportDialog: false, // 导出弹窗
      dialogTitle: '', // 弹窗标题
      exportQueryData: {
        typeStatus: '', // 导出类型状态 login-登录埋点  orderSee-订单查看  orderPush-订单推送  userOperation-用户操作
        type: 'burial', // 导出类型：order-订单 purchase-申购 burial-埋点文档
        beginTime: '', // 操作时间 - 开始
        endTime: '', // 操作时间 - 结束
        regionId: '', // 区域ID
        accountName: '', // 账号名称
        orderCode: '' // 订单编号
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    // 获取用户信息
    const userInfo = getSessionStorage('userInfo') || {}
    if (userInfo.managerType === 'all') {
      this.getAreaList()
    }
    this.getTableList()
  },
  methods: {
    // 查询
    searchList() {
      this.queryData.pageNum = 1
      this.getTableList()
    },
    // 改变页码
    pageChange(pageVal, type) {
      switch (type) {
        case 'mainTable':
          this.queryData.pageNum = pageVal
          this.getTableList()
          break

        default:
          break
      }
    },
    // 改变页数
    sizeChange(sizeVal, type) {
      switch (type) {
        case 'mainTable':
          this.queryData.pageNum = 1
          this.queryData.pageSize = sizeVal
          this.getTableList()
          break

        default:
          break
      }
    },
    // 获取区域级联数据
    getAreaList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        this.addressList = res.data
      })
    },
    // 选择时间
    changeDate(type, date) {
      if (type === 'exportDate') { // 导出时间
        this.queryData.param.beginTime = date ? date[0] : ''
        this.queryData.param.endTime = date ? date[1] : ''
      } else if (type === 'operateDate') {
        this.exportQueryData.beginTime = date ? date[0] : ''
        this.exportQueryData.endTime = date ? date[1] : ''
      }
    },
    // 获取表格数据
    async getTableList() {
      this.tableData.loading = true
      // console.log('this.queryData===>>>>', this.queryData)
      const res = await exportFilePage({ ...this.queryData })
      if (res.code === 200) {
        this.tableData.list = res.data.list
        this.tableData.total = res.data.total
        this.tableData.loading = false
      } else {
        this.tableData.loading = false
      }
    },
    // 单个下载
    singleDownload(row) {
      // 文件下载地址
      const fileUrl = row.fileUrl
      const fileName = row.fileName
      const myHeaders = new Headers({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'text/plain'
      })
      // 发起 Fetch 请求
      fetch(fileUrl, { method: 'GET', headers: myHeaders, mode: 'cors' })
        .then(response => response.blob())
        .then(blob => {
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)// 将图片的src赋值给a节点的href
          a.download = fileName // 下载的文件名称
          a.click()
          a.remove()
        })
        .catch(error => console.error('下载失败：', error))
    },
    // 单个删除
    singleDelete(row) {
      this.$confirm('确定删除该文件?', '提示', {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          deleteLogic(row.id).then(res => {
            this.$message.success('删除成功')
            this.searchList()
            this.$refs.mainTable.handleCurrentChange(1, false)
          })
        })
        .catch(() => {})
    },
    // 打开导出弹窗
    openExportDialog(index, typeStatus) {
      this.dialogType = index
      this.exportQueryData = Object.assign({}, this.$options.data().exportQueryData)
      this.operateDaterange = []
      switch (index) {
        case 1:
          this.dialogTitle = '商家端登录'
          break
        case 2:
          this.dialogTitle = '订单查看'
          break
        case 3:
          this.dialogTitle = '订单推送'
          break
        case 4:
          this.dialogTitle = '用户操作'
          break
      }
      this.exportQueryData.typeStatus = typeStatus
      this.exportDialog = true
    },
    // 埋点导出
    async exportBuriedLog() {
      const loading = this.$loading({
        fullscreen: true,
        lock: true,
        background: 'rgba(0, 0, 0, 0.7)'
      })
      await burialDataExport({ ...this.exportQueryData })
      setTimeout(() => {
        loading.close()
        this.$message.success('导出任务已经启动')
        this.exportDialog = false
        this.getTableList()
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.buriedLog_box {
  .operate_buttons {
    margin-bottom: 20px;
  }
  .main_table {
    .table_box {
      width: 100%;
      margin: 0;
      padding: 20px 20px 10px;
      /deep/ .el-button {
        padding: 0;
      }
      /deep/ .el-button + .el-button {
        margin: 0;
      }
      .operate_btn {
        &::before {
          content: '/';
          font-size: 12px;
          color: #4e9aef;
          vertical-align: top;
          margin-right: 5px;
        }
        &:first-child::before {
          content: '';
          margin: 0;
        }
      }
    }
  }
}
</style>
