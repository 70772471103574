<template>
  <div class="broken-line">
    <div class="title">
      <div class="txt_box">
        <div class="tip">
          <img src="../../assets/image/wenhao.png" alt="">
          <div ref="poup" class="poup">
            <div class="txt">
              分析所选时间段内的每日销售情况
            </div>
          </div>
        </div>
        <img src="@/assets/image/top.png" alt="" class="icon">
        <div class="text">{{ title }}</div>
      </div>
    </div>
    <!-- 封装通用的折线图 -->
    <div id="broken-line" ref="broken" />
  </div>
</template>
<script>
// 引入echarts
import * as echarts from 'echarts'
export default {
 data() {
    return {
      title: '销售额趋势分析'
    }
 },
 computed: {
 },
 created() {
 },
 methods: {
    // 获取折线图数据图表
getDataOrder(title, hourList, todayList, header) {
  var chartDom = this.$refs.broken
  var myChart = echarts.init(chartDom)
  window.addEventListener('resize', function() {
        myChart.resize()
    })
  var option
  const colors = ['rgba(57, 147, 255, 0.8)']
option = {
color: colors,
grid: {
  right: '0',
  left: '40',
  top: '30',
  bottom: '25'
},
tooltip: {
  trigger: 'axis'
  // axisPointer: {
  //   type: 'cross'
  // }
},
xAxis: {
  type: 'category',
  data: hourList,
  axisLine: {
    show: false
  //  lineStyle: {
  //     type: 'solid',
  //     color: '#027DB4', //左边线的颜色
  //     width: '3' //坐标线的宽度
  //  }
  },
  axisLabel: {
      // x轴文字的配置
      show: true,
      interval: 0, // 使x轴文字显示全
      // color: '#fff',  //更改坐标轴文字颜色
      interval: hourList.length > 15 ? 1 : 0, // 间隔的个数, 超过15个才间隔1
      textStyle: {
          color: '#B0F9FD', // 更改坐标轴文字颜色
          fontSize: 14// 文字大小
      }
  },
  axisTick: {
      show: false
  }
},
yAxis: {
  type: 'value',
  splitLine: {
      show: true,
      lineStyle: {
        color: ['rgba(101, 165, 221, 0.08)'],
        width: 1,
        type: 'solid'
      }
  },
  axisLabel: {
    formatter: function(value, index) {
      var value
      if (value >= 1000) {
      value = value / 1000 + 'k'
      } else if (value < 1000) {
      value = value
      }
      return value
    },
    textStyle: {
      color: '#65A5DD',
      fontSize: 12// 文字大小
    }
  },
    // 设置坐标轴字体颜色和宽度
    axisLine: {
    lineStyle: {
        color: '#fff'
    }
  }
},
series: [
  {
    name: header[0],
    data: todayList,
    type: 'line',
    smooth: true,
    showSymbol: false
  }
  // {
  //   name: header[1],
  //   data: last7List,
  //   type: 'line',
  //   smooth: true
  // },
  // {
  //   name: header[2],
  //   data: last14List,
  //   type: 'line',
  //   smooth: true
  // }
]
}
option && myChart.setOption(option)
}
 }
}
</script>
<style lang="scss" scoped>
.broken-line {
  width: 665px;
  height: 375px;
  background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/d551e498-8e5d-4e25-a424-922499f1da15.png") no-repeat center 0px;
  background-size: cover;

  .title {
    display: flex;
    padding: 30px 0px 0 30px;

    .text {
      background-image: linear-gradient(0deg, #56FEFF, #E2F3FB);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
      font-size: 16px;
    }

    .icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }

    .txt_box {
      display: flex;
      align-self: center;
      align-items: center;
      align-content: center;

      &:hover {
        .tip {
          .poup {
            display: flex;
          }
        }
      }
    }
  }

  #broken-line {
    margin-left: 20px;
    width: 628px;
    height: 270px;
    margin-top: 20px;

  }

  .tip {
    width: 28px;
    height: 28px;
    position: relative;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .poup {
    background: url('../../assets/image/tip.png') no-repeat center center;
    padding: 20px;
    text-align: left;
    left: 10px;
    z-index: 100;
    position: absolute;
    width: 262px;
    // height: 71px;
    color: #c0e2ff;
    font-weight: 400;
    font-size: 12px;
    font-face: Microsoft YaHei;
    line-height: 20px;
    display: none;
  }
}
</style>
