<template>
  <!-- 分组管理列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium" label-suffix=":">
        <el-form-item label="分组名称">
          <div class="flex-box">
            <el-input v-model="inquire.categoryName" placeholder="输入分组名称" @keyup.enter.native="searchList"/>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="handleAddGroup">
        新增分组
      </el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table ref="personListTable" v-loading="loading" :data="tableData" border height="400px"
                    style="width: 100%"
          >
            <el-table-column prop="date" label="序号" width="60">
              <template slot-scope="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="categoryName" label="人员姓名" min-width="200" show-overflow-tooltip/>
            <el-table-column prop="createTime" label="增加时间" min-width="200" show-overflow-tooltip/>
            <el-table-column label="操作" min-width="260" fixed="right">
              <template v-slot="{row}">
                <span class="operation-btn" @click="handleEdit(row)">编辑</span>
                <span class="operation-btn_partition">/</span>
                <span class="operation-btn" @click="handleDeleteGroup(row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination background :current-page="pageParam.pageNum" :page-sizes="$api.pageSizes"
                         :page-size="pageParam.pageSize" layout="total, sizes, prev, pager, next, jumper"
                         :total="pageParam.total" @size-change="handleSizeChange" @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加/编辑人员弹框 -->
    <EditGroupDialog v-model="groupDialogVisible" :edit-form="submitForm" @edit-done="searchList"/>
  </div>
</template>

<script>
import { getSessionStorage } from '@/utils/auth'
import { getTalentCategoryList, removeTalentCategory } from '@/api/user'
import EditGroupDialog from '@/views/talent/components/editGroupDialog'

export default {
  components: { EditGroupDialog },
  data() {
    return {
      // 查询
      inquire: {
        categoryName: ''
      },

      // 表格数据
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      tableData: [],
      userInfo: {},

      // ==================== 新增分组 ====================
      groupDialogVisible: false,
      submitForm: {
        categoryName: ''
      }
    }
  },
  computed: {},
  created() {
    this.userInfo = getSessionStorage('userInfo')

    // 获取分组管理列表
    this.getGroupList()
  },
  methods: {
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getGroupList()
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      this.getGroupList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      this.getGroupList()
    },

    // 获取数据列表
    async getGroupList() {
      this.loading = true
      const params = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        categoryName: this.inquire.categoryName || ''
      }
      const res = await getTalentCategoryList(params)
      this.loading = false
      if (!res || res.code !== 200) {
        this.$message.error(res.data.description)
        return
      }
      this.tableData = res.data.list || []
      this.pageParam.total = res.data.total || 0
    },

    // 新增分组
    handleAddGroup() {
      this.submitForm = { categoryName: '' }
      this.groupDialogVisible = true
    },
    // 修改分组
    handleEdit(row) {
      this.submitForm = { ...row }
      this.groupDialogVisible = true
    },

    handleDeleteGroup(row) {
      this.$confirm(`确认删除分组 【${row.categoryName}】?`, '确认删除分组', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(async() => {
        if (!row.id) return
        const res = await removeTalentCategory(row.id)
        if (res.data === true && res.code === 200) {
          this.searchList()
          this.$message.success('操作成功！')
        } else if (res.data === false) {
          this.$message.error(res.message)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.Popout_content {
  .flex-box .demo-form-inline {
    flex: 1;
  }
}
</style>
