<template>
  <div class="navbar">
    <hamburger
      v-if="!sidebar.hide"
      :is-active="sidebar.opened"
      class="hamburger-container"
      @toggleClick="toggleSideBar"
    >
    </hamburger>
    <breadcrumb class="breadcrumb-container"/>
    <!-- 数据大屏页面点击时的全屏效果 -->
   <!-- <template v-if="$route.path == '/home/largeScreen'">
    <div class="fullScreen">
      <ul>
	   <li @click="clickFullscreen()" style="font-size: 20px;"><i class="el-icon-full-screen" /></li>
     </ul>
    <fullscreen style="background: #f7f8fa;" :fullscreen.sync="fullscreen">
	  <router-view v-if="showMainFlag" :in-screen="!fullscreen" style="width: 100%; height: 100%;" />
    </fullscreen>
    </div>
   </template> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'

export default {
  components: {
    Breadcrumb,
    Hamburger
  },
  inject:['reload'],
  data() {
	    return {
		    fullscreen: false,
		    showMain: false
	    }
  	},
  computed: {
    ...mapGetters(['sidebar']),
    showMainFlag() {
      		return this.showMain
    	},
    windowWidth() {
      return this.$store.state.settings.windowWidth1260
    }
  },
  watch: {
		fullscreen(newVal ,old) {
			// 刷新 进行重新渲染
			if(newVal){
        this.showMain = true
      }else{
        // this.$router.go(0)
        this.reload()
        this.showMain = false
        console.log('this.$route.path',this.$route.path);
        console.log('this.reload',this.reload);
      }
    	}
	},
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    clickFullscreen() {
			this.fullscreen = true
	    },
  }
}
</script>

<style lang="scss" scoped>

</style>
