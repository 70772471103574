var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-box" },
    [
      _c(
        "div",
        { staticClass: "head-search-row" },
        [
          _c(
            "el-form",
            {
              attrs: {
                model: _vm.queryParams,
                size: "small",
                inline: true,
                "label-width": "68px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "文章标题" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入文章标题", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.articleTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "articleTitle", $$v)
                      },
                      expression: "queryParams.articleTitle",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "类别" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "350px" },
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.queryParams.categoryId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "categoryId", $$v)
                        },
                        expression: "queryParams.categoryId",
                      },
                    },
                    _vm._l(_vm.queryCategoryList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.categoryName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "来源" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "240px" },
                      attrs: { placeholder: "请选择", clearable: "" },
                      model: {
                        value: _vm.queryParams.articleSource,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryParams, "articleSource", $$v)
                        },
                        expression: "queryParams.articleSource",
                      },
                    },
                    _vm._l(_vm.$api.articleSource, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "作者名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "240px" },
                    attrs: { placeholder: "请输入作者名称", clearable: "" },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.handleQuery($event)
                      },
                    },
                    model: {
                      value: _vm.queryParams.articleAuthor,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryParams, "articleAuthor", $$v)
                      },
                      expression: "queryParams.articleAuthor",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "添加时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      type: "datetimerange",
                      "range-separator": "-",
                      "start-placeholder": "开始时间",
                      "end-placeholder": "结束时间",
                    },
                    model: {
                      value: _vm.dateRange,
                      callback: function ($$v) {
                        _vm.dateRange = $$v
                      },
                      expression: "dateRange",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { margin: "20px 0 0 20px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v("新增")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "table" },
        [
          _c(
            "el-main",
            [
              [
                _c(
                  "el-table",
                  {
                    ref: "tables",
                    attrs: {
                      data: _vm.list,
                      height: "400",
                      border: "",
                      "header-cell-style": {
                        background: "#409eff",
                        color: "#ffffff",
                      },
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        width: "60",
                        align: "center",
                        type: "index",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "标题",
                        align: "center",
                        prop: "articleTitle",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "类别",
                        align: "center",
                        prop: "articleCategoryName",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "作者",
                        align: "center",
                        prop: "articleAuthor",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "来源",
                        align: "center",
                        prop: "articleSource",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.sourceStatus(scope.row))),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "状态",
                        align: "center",
                        "show-overflow-tooltip": true,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [
                                _vm._v(_vm._s(_vm.getStatus(scope.row))),
                              ]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "添加时间",
                        align: "center",
                        prop: "createTime",
                        width: "200px",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "浏览量",
                        align: "center",
                        prop: "articleView",
                      },
                    }),
                    _vm._v(" "),
                    _c("el-table-column", {
                      attrs: {
                        label: "操作",
                        align: "center",
                        "class-name": "small-padding fixed-width",
                        "min-width": "200",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEdit(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("查看详情")]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "btn-partition" }, [
                                _vm._v(" / "),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.toContent(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("关联文章")]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "btn-partition" }, [
                                _vm._v(" / "),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.recommendArticle(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        scope.row.recommendedStatus === 2
                                          ? "取消推荐"
                                          : "推荐"
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "btn-partition" }, [
                                _vm._v(" / "),
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteArticle(scope.row.id, 1)
                                    },
                                  },
                                },
                                [_vm._v("删除")]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.queryParams.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.queryParams.pageSize,
                      layout: " total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "关联文章",
            visible: _vm.openEdit,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openEdit = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    model: _vm.relatedParams,
                    size: "small",
                    inline: true,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "240px" },
                        attrs: { placeholder: "请输入文章标题", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery_1($event)
                          },
                        },
                        model: {
                          value: _vm.relatedParams.articleTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.relatedParams, "articleTitle", $$v)
                          },
                          expression: "relatedParams.articleTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作者" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "240px" },
                        attrs: { placeholder: "请输入作者名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery_1($event)
                          },
                        },
                        model: {
                          value: _vm.relatedParams.articleAuthor,
                          callback: function ($$v) {
                            _vm.$set(_vm.relatedParams, "articleAuthor", $$v)
                          },
                          expression: "relatedParams.articleAuthor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.handleQuery_1 },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.addArticle },
                        },
                        [_vm._v("新增")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "tables",
                  attrs: {
                    data: _vm.contentList,
                    height: "400",
                    border: "",
                    "header-cell-style": {
                      background: "#409eff",
                      color: "#ffffff",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      width: "60",
                      align: "center",
                      type: "index",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "标题",
                      align: "center",
                      prop: "articleTitle",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "作者",
                      align: "center",
                      prop: "articleAuthor",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      "min-width": "200",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.associatedGroupChat(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看详情")]
                            ),
                            _vm._v(" "),
                            _c("span", { staticClass: "btn-partition" }, [
                              _vm._v(" / "),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteSource(scope.row)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.relatedParams.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.relatedParams.pageSize,
                      layout: " total, sizes, prev, pager, next, jumper",
                      total: _vm.total_1,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_1,
                      "current-change": _vm.handleCurrentChange_1,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增关联文章",
            visible: _vm.openAddEdit,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openAddEdit = $event
            },
            close: _vm.close1,
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    model: _vm.addRelatedParams,
                    size: "small",
                    inline: true,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入文章标题", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery_2($event)
                          },
                        },
                        model: {
                          value: _vm.addRelatedParams.articleTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.addRelatedParams, "articleTitle", $$v)
                          },
                          expression: "addRelatedParams.articleTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作者" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入作者名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.handleQuery_2($event)
                          },
                        },
                        model: {
                          value: _vm.addRelatedParams.articleAuthor,
                          callback: function ($$v) {
                            _vm.$set(_vm.addRelatedParams, "articleAuthor", $$v)
                          },
                          expression: "addRelatedParams.articleAuthor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.handleQuery_2 },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "tablelist",
                  staticStyle: { "margin-top": "30px" },
                  attrs: {
                    data: _vm.tableList,
                    height: "400",
                    border: "",
                    "header-cell-style": {
                      background: "#409eff",
                      color: "#ffffff",
                    },
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      width: "60",
                      align: "center",
                      type: "index",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "标题",
                      align: "center",
                      prop: "articleTitle",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "作者",
                      align: "center",
                      prop: "articleAuthor",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "操作",
                      align: "center",
                      "class-name": "small-padding fixed-width",
                      "min-width": "160",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.associatedGroupChat(scope.row)
                                  },
                                },
                              },
                              [_vm._v("查看详情")]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.addRelatedParams.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.addRelatedParams.pageSize,
                      layout: " total, sizes, prev, pager, next, jumper",
                      total: _vm.total_2,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange_2,
                      "current-change": _vm.handleCurrentChange_2,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitFormAdd },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c("el-button", { on: { click: _vm.close1 } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "文章详情",
            visible: _vm.openDetail,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.openDetail = $event
            },
            close: function ($event) {
              _vm.openDetail = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                { attrs: { "label-width": "80px" } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "500px" },
                      attrs: { label: "标题", required: true },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formData.articleTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "articleTitle", $$v)
                          },
                          expression: "formData.articleTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "类别", required: true } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择", disabled: true },
                          model: {
                            value: _vm.formData.articleCategoryId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "articleCategoryId", $$v)
                            },
                            expression: "formData.articleCategoryId",
                          },
                        },
                        _vm._l(_vm.queryCategoryList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.categoryName, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "500px" },
                      attrs: { label: "作者", required: true },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formData.articleAuthor,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "articleAuthor", $$v)
                          },
                          expression: "formData.articleAuthor",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "500px" },
                      attrs: { label: "认证信息" },
                    },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formData.organization,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "organization", $$v)
                          },
                          expression: "formData.organization",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "500px" },
                      attrs: { label: "封面", required: true },
                    },
                    [
                      _c("el-image", {
                        staticStyle: { width: "100px", height: "100px" },
                        attrs: { src: _vm.formData.coverUrl },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容", required: true } },
                    [
                      _c("div", { staticClass: "editor" }, [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.formData.articleContent),
                          },
                        }),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formData.topVideo != ""
                    ? _c("el-form-item", { attrs: { label: "顶部视频" } }, [
                        _c("div", { staticClass: "tip-box" }, [
                          _vm.formData.topVideo
                            ? _c(
                                "div",
                                {
                                  staticClass: "vedio",
                                  staticStyle: { cursor: "pointer" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "800px",
                                        cursor: "pointer",
                                        color: "#666",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toVedio("top")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.formData.topVideo) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.formData.buttonVideo != ""
                    ? _c("el-form-item", { attrs: { label: "底部视频" } }, [
                        _c("div", { staticClass: "tip-box" }, [
                          _vm.formData.buttonVideo
                            ? _c(
                                "div",
                                {
                                  staticClass: "vedio",
                                  staticStyle: { cursor: "pointer" },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        width: "800px",
                                        cursor: "pointer",
                                        color: "#666",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toVedio("bottom")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.formData.buttonVideo) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.showDialog_2,
            width: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog_2 = $event
            },
            close: _vm.closeEvent_2,
          },
        },
        [
          _vm.showDialog_2
            ? _c(
                "video",
                {
                  ref: "video",
                  staticClass: "video",
                  attrs: { controls: "", preload: "auto", playsinline: "true" },
                },
                [
                  _c("source", {
                    attrs: { src: _vm.vedioUrl, type: "video/mp4" },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }