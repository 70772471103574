var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-box" }, [
    _c(
      "div",
      { staticClass: "search-row bg_white" },
      [
        _c(
          "el-form",
          {
            staticClass: "demo-form-inline",
            attrs: { inline: true, size: "medium" },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "时间" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.active === 0 ? "primary" : "",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setTimeState(0, 0)
                      },
                    },
                  },
                  [_vm._v("今天")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.active === 1 ? "primary" : "",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setTimeState(1, -1)
                      },
                    },
                  },
                  [_vm._v("昨天")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.active === 2 ? "primary" : "",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setTimeState(2, -6)
                      },
                    },
                  },
                  [_vm._v("近7天")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.active === 3 ? "primary" : "",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setTimeState(3, -29)
                      },
                    },
                  },
                  [_vm._v("近30天")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.active === 4 ? "primary" : "",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setTimeState(4, "current")
                      },
                    },
                  },
                  [_vm._v("这个月")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.active === 5 ? "primary" : "",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setTimeState(5, "last")
                      },
                    },
                  },
                  [_vm._v("上个月")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.active === 6 ? "primary" : "",
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setTimeState(6, true)
                      },
                    },
                  },
                  [_vm._v("自定义范围")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.customTimeShow,
                    expression: "customTimeShow",
                  },
                ],
                attrs: { label: "" },
              },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "daterange",
                    "value-format": "yyyy-MM-dd",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                  },
                  model: {
                    value: _vm.daterange,
                    callback: function ($$v) {
                      _vm.daterange = $$v
                    },
                    expression: "daterange",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "业务范围" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.computedBusinessState(1),
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setBusinessState(1, 0)
                      },
                    },
                  },
                  [_vm._v("销售")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: {
                      type: _vm.computedBusinessState(2),
                      size: "small",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.setBusinessState(2, 1)
                      },
                    },
                  },
                  [_vm._v("租赁")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { label: "区域" } },
              [
                _vm.$public.isAllAreaPower()
                  ? _c("el-cascader", {
                      attrs: {
                        options: _vm.addressList,
                        props: _vm.props,
                        clearable: "",
                      },
                      model: {
                        value: _vm.district,
                        callback: function ($$v) {
                          _vm.district = $$v
                        },
                        expression: "district",
                      },
                    })
                  : _c("d-area", {
                      ref: "area_1",
                      attrs: { values: _vm.district, allVal: "" },
                      on: {
                        "update:values": function ($event) {
                          _vm.district = $event
                        },
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.getPageData },
                  },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row_box bg_white" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c(
          "ul",
          { staticClass: "income-box flex-box" },
          _vm._l(_vm.incomeList, function (item, index) {
            return _c("li", { key: index }, [
              _c("div", { staticClass: "title_box" }, [
                _vm._v(_vm._s(item.label)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "money" }, [
                _vm._v(_vm._s(item.money)),
                _c("span", { staticClass: "unit" }, [_vm._v("元")]),
              ]),
            ])
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "header" }, [
      _c("span", [_vm._v("收入概况")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row_box bg_white" }, [
      _c("div", { staticClass: "header" }, [_c("span", [_vm._v("营收趋势")])]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _c("div", { attrs: { id: "echars-box" } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }