<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="通知内容">
          <el-input v-model="inquire.messageContent" placeholder="通知内容" @keyup.enter.native="searchList" />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
          <el-button type="primary" size="small" @click="readAll">全部已读</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column prop="messageContent" label="消息内容" min-width="220" />
            <el-table-column prop="messageTime" label="提醒时间" min-width="160" />
            <el-table-column prop="messageStatus" label="状态" min-width="120">
              <template slot-scope="scope">
                {{ scope.row.messageStatus === 'have_read' ? '已读' : '未读' }}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">查看</span>
                <span v-show="scope.row.orderCode" class="operation-btn_partition">/</span>
                <span v-show="scope.row.orderCode" class="operation-btn" @click="skip(scope.row)">跳转</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-main>
    </div>

    <!-- 添加弹框 -->
    <el-dialog title="查看" :visible.sync="dialogVisible" :before-close="closeDialog" width="650px">
      <div class="Popout_content">
        <el-form ref="submitForm" label-width="90px" :model="submitForm">
          <el-form-item label="消息内容">
            <el-input v-model="submitForm.messageContent" type="textarea" maxlength="150" :rows="5" placeholder="请输入内容" disabled />
          </el-form-item>
        </el-form>
        <div class="box-p-t box-p-b"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        messageContent: ''
      },
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      loading: false,
      // 表格数据
      tableData: [],

      // 添加
      dialogVisible: false, // 添加弹窗
      submitForm: {
        messageContent: ''
      },
      editRow: {}
    }
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1
      this.pageParam.pageSize = size
      // 刷新(数据)
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page
      // 刷新(页码)
      this.getTableList()
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },

    // 获取数据列表
    getTableList() {
      const data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: this.inquire
      }

      this.loading = true
      this.$http
        .requestPost({
          url: '/management/message/page',
          param: data,
          loading: false
        })
        .then(res => {
          this.loading = false
          this.tableData = res.data.list
          this.pageParam.total = res.data.total
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },

    // 查看
    handleEdit(row) {
      this.submitForm = {
        messageContent: row.messageContent
      }
      this.dialogVisible = true
      this.editRow = row

      this.$http
        .requestPut({
          url: '/management/message/readMessage/' + row.messageId,
          loading: false
        })
        .then(res => {
          this.getTableList()
          this.$store.dispatch('user/getCount4Unread')
        })
    },

    // 跳转
    skip(row) {
      console.log('row======', row)
      var type = row.orderCode.slice(0, 2)
      // eslint-disable-next-line one-var
      var path = '', id = ''
      switch (type) {
        case 'EO':
          if (!row.isReturn) {
            path = '/order/commerce/particulars'
          } else {
            path = '/order/commerce/returnedGoods/particulars'
          }
          id = row.orderId
          break
        case 'MO':
          if (!row.isReturn) {
            path = '/order/medical/hospitalDelivery/particulars'
          } else {
            path = '/order/medical/hospitalDelivery/medicalReturnedGoods/medicalParticulars'
          }
          id = row.orderId
          break
        case 'LO':
          path = '/order/lease/particulars'
          id = row.orderId
          break
        case 'PO':
          path = '/form/purchaseRequisition/particulars'
          id = row.orderCode
          break
        case 'RO':
          path = '/form/returnedGoods/particulars'
          id = row.orderCode
          break
        case 'NO':
          if (!row.isReturn) {
            path = '/order/NutritionOrder/particulars'
          } else {
            path = '/order/NutritionOrder/returnedGoods/particulars'
          }
          id = row.orderId
          break
        case 'EX':
          if (!row.isReturn) {
            path = '/order/ExternalProductOrder/particulars'
          } else {
            path = '/order/ExternalProductOrder/returnedGoods/particulars'
          }
          id = row.orderId
          break
      }
      this.$router.push({
        path,
        query: {
          id
        }
      })
    },

    // 关闭弹窗
    closeDialog() {
      this.$refs.submitForm && this.$refs.submitForm.resetFields()
      this.dialogVisible = false
      this.submitForm = {
        messageContent: ''
      }
    },

    // 全部已读
    readAll() {
      this.$confirm('确定设置全部已读?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(() => {
          this.$http
            .requestPut({
              url: '/management/message/readAllMessages',
              loading: false
            })
            .then(res => {
              this.$message.success('操作成功！')
              this.getTableList()
              this.$store.dispatch('user/getCount4Unread')
            })
        })
        .catch(err => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.address-list {
  flex-wrap: wrap;

  & > div {
    display: inline-flex;
  }
}
.text {
  padding: 0 5px;
}
.text-left {
  min-width: 125px;
  text-align: right;
}
</style>
