<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <!-- <el-form-item label="交易时间">
          <el-date-picker
            v-model="daterange"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>  -->

        <!-- <el-form-item label="关联单号">
          <el-input v-model="inquire.name"></el-input>
        </el-form-item>

        <el-form-item label="第三方交易单号">
          <el-input v-model="inquire.name"></el-input>
        </el-form-item> -->
        
        <!-- <el-form-item label="交易金额">
          <div class="flex-box">
            <el-input v-model="inquire.name" placeholder="0" class="width-110"></el-input>
            <span class="span-link"> - </span>
            <el-input v-model="inquire.name" placeholder="0" class="width-110"></el-input>
          </div>
        </el-form-item> -->

        <el-form-item label="收支类型">
          <el-select v-model="inquire.name" clearable placeholder="请选择">
            <el-option label="收入" value="2"></el-option>
            <el-option label="支出" value="3"></el-option>
          </el-select>
        </el-form-item>

        <!-- <el-form-item label="订单类型">
          <el-select v-model="inquire.orderClassify" clearable placeholder="请选择">
            <el-option label="医药品订单" value="medicine"></el-option>
            <el-option label="电商品订单" value="electronic"></el-option>
            <el-option label="租赁品订单" value="lease"></el-option>
          </el-select>
        </el-form-item> -->

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary">导出报表</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="times" label="交易时间" min-width="160"></el-table-column>
            <el-table-column prop="orderStatus" label="收支类型" min-width="120">
              <template slot-scope="scope">
                {{scope.row.orderStatus === "returned" ? '支出' : '收入'}}
              </template>
            </el-table-column>
            <el-table-column prop="orderClassify" label="订单类型" min-width="120">
              <template slot-scope="scope">
                {{ $api.getValue($api.orderClassify, scope.row.orderClassify, 'name') }}
              </template>
            </el-table-column>
            <el-table-column prop="productOrder" label="交易金额（￥）" min-width="120">
              <template slot-scope="scope">
                <div v-if="scope.row.orderStatus === 'returned'">{{ '- ' + scope.row.orderTotalPrice.toFixed(2) }}</div>
                <div v-else>{{ '+ ' + scope.row.orderTotalPrice.toFixed(2) }}</div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>
  </div>
</template>

<script>
import { parse } from 'path-to-regexp'
export default {
  data() {
    return {
      times: '',

      // 查询参数
      inquire: {
        orderClassify: '',
        payTime2: '',
        payTimeEnd2: '',
      },
      daterange: [],
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [],  
    };
  },
  created() {
    this.inquire.payTime2 = this.$route.query.time + ' 00:00:00'
    this.inquire.payTimeEnd2 = this.$route.query.time + ' 23:59:59'
    this.inquire.orderClassify = this.$route.query.orderClassify
    // 获取产品列表
    this.getProductList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getProductList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getProductList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getProductList()
    },

    // 获取产品列表
    getProductList() {
      this.loading = true;
      this.$http.requestPost({
        url: '/wait/payment/merchant/commission/comprehensive/everyDayStatisticsDetail?pageNum=' + this.pageParam.pageNum + '&pageSize=' + this.pageParam.pageSize,
        param: this.inquire,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.data.list;
        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    // 详细
    details(row) {
      this.$router.push({
        path: '/financial/financialStatement/orderDetails',
        query: {
          type: 'daily',
          id: row.id
        }
      })
    }
    
  },
};
</script>

<style lang="scss" scoped>
  
</style>
