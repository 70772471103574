<template>
  <!-- 审核已通过售后单 -->
  <div class="main-box">
    <!-- // 审核状态：1-待审核，2-审核通过，3-审核不通过 -->
    <afterSalesOrder :audit-status="2" />
  </div>
</template>

<script>
import afterSalesOrder from '@/components/order/afterSalesOrder'

export default {
  components: { afterSalesOrder },
  data() {
    return {

    }
  },
  computed: {},
  watch: {},
  created() {},
  methods: {

  }
}
</script>
<style lang="scss" scoped>

</style>
