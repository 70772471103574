import request from '@/utils/request'

// export function login(data) {
//     return request({
//         url: '/vue-admin-template/user/login',
//         method: 'post',
//         data
//     })
// }

export function getCount4Unread(data = {}) {
  return request.requestGet({
    url: '/management/message/getCount4Unread',
    data,
    loading: false
  })
}

// export function getInfo(token) {
//   return request({
//     url: '/vue-admin-template/user/info',
//     method: 'get',
//     params: { token }
//   })
// }

// export function logout() {
//   return request({
//     url: '/vue-admin-template/user/logout',
//     method: 'post'
//   })
// }

/**
 * 获取商家列表
 * @param { Number } pageNum - 分页参数
 * @param { Number } pageSize - 分页参数
 * @param { Object } param - 查询参数
 * @desc param.merchantName:'', //商家端名称
 * @desc param.intendantName:'', //业务员姓名
 * @desc param.isIntendantCode:1, //是否包含业务员编号 1.是 2.否
 * @desc param.filterMerchantIds:[], //商家id列表
 *
*/
export function merchantPage(param) {
  return request.requestPost({
    url: `/customer/merchant/page`,
    method: 'post',
    param,
    loading: false
  })
}

/**
 * 获取产品列表
 * @param { Number } pageNum - 分页参数
 * @param { Number } pageSize - 分页参数
 * @param { Object } param - 查询参数
 * @desc param.productName: "", //产品名称:''
 * @desc param.productClassify: "", //产品类别
 * @desc param.productStatus: "on_shelves", //销售状态(在售：on_shelves)
*/
export function productPage(param) {
  return request.requestPost({
    url: `/product/page`,
    method: 'post',
    param,
    loading: false
  })
}

/**
 * 生成二维码
 * @param { String } merchantId - 商户id
 * @param { Number } specifiedPath - 跳转路径 1.首页 2.产品列表 3.产品详情
 * @param { String } specifiedPathId - 跳转路径id
 * @param { Number } createNum - 创建数量
*/
export function createQrCode(param) {
  return request.requestPost({
    url: '/customer/merchant/createQrCode',
    method: 'post',
    param
  })
}

/**
 * 下载二维码
 * @param { String } intendantName - 业务员名称
 * @param { String } areaId - 所属区域id
 * @param { String } merchantName - 商家名称
 * @param { Number } qrCodeStatus - 状态(1-启用 2-禁用)
 * @param { String } intendantCode - 业务员编号
*/
export function downloadQrCode(param) {
  return request.requestPost({
    url: '/customer/merchant/downloadQrCode',
    method: 'post',
    param
  })
}

/**
 * 二维码列表
 * @param { Number } pageNum - 分页参数
 * @param { Number } pageSize - 分页参数
 * @param { String } intendantName - 业务员名称
 * @param { String } areaId - 所属区域id
 * @param { String } merchantName - 商家名称
 * @param { Number } qrCodeStatus - 状态(1-启用 2-禁用)
 * @param { String } intendantCode - 业务员编号
 */
export function queryQrCodeList(param) {
  return request.requestPost({
    url: '/customer/merchant/queryQrCodeList',
    method: 'post',
    param,
    loading: true
  })
}

// 二维码详情
export function qrCodeDetail(qrCodeId) {
  return request.requestGet({
    url: `/customer/merchant/qrCodeDetail/${qrCodeId}`,
    method: 'get'
  })
}

// 启用和停用商家
export function startAndStopMerchant(param) {
  return request.requestPost({
    url: `/customer/merchant/deleted`,
    method: 'post',
    param
  })
}

// 商家列表批量操作
export function stopPurchaseApi(param) {
  return request.requestPost({
    url: `/customer/merchant/stopPurchase`,
    method: 'post',
    param
  })
}

/**
 * 修改二维码状态
 * @param { String } qrCodeId - 二维码id
 * @param { Number } qrCodeStatus - 状态(1-启用 2-禁用)
*/
export function modifyQrCodeStatus(param) {
  return request.requestPut({
    url: `/customer/merchant/modifyQrCodeStatus/${param.qrCodeId}/${param.qrCodeStatus}`,
    method: 'put'
  })
}

/**
 * 修改二维码路径
 * @param { String } qrCodeId - 二维码id
 * @param { Number } specifiedPath - 跳转路径 1.首页 2.产品列表 3.产品详情
 * @param { String } specifiedPathId - 跳转路径id
*/
export function modifyQrCode(param) {
  return request.requestPut({
    url: `/customer/merchant/modifyQrCode`,
    method: 'put',
    param
  })
}

// 二维码销售记录
export function qrCodeSaleRecord(qrCodeId) {
  return request.requestPostQs({
    url: `/customer/merchant/qrCodeSaleRecord/${qrCodeId}`,
    method: 'post',
    loading: false
  })
}

/**
 * 二维码会员列表
 * @param { String } qrCodeId - 二维码id
 * @param { String } memberNickname - 微信昵称
 * @param { String } memberCode - 会员编号
 * @param { String } memberTelephone -注册手机号
 */
export function qrCodeMemberList(param) {
  return request.requestPost({
    url: '/customer/merchant/qrCodeMemberList',
    method: 'post',
    param,
    loading: false
  })
}

/**
 * 二维码更换商家
 * @param { Array } qrCodeIds - 二维码id列表
 * @param { String } merchantId - //更换后的商家id
 */
export function changeQrCodeMerchant(param) {
  return request.requestPost({
    url: '/customer/merchant/changeQrCodeMerchant',
    method: 'post',
    param,
    loading: true
  })
}

/**
 * 二维码优惠券列表
 * @param { String } qrCodeId - 二维码id
 */
export function qrCodeCouponList(qrCodeId) {
  return request.requestPost({
    url: `/customer/merchant/qrCodeCouponList/${qrCodeId}`,
    method: 'post',
    loading: false
  })
}

/**
 * 根据优惠券分页查询二维码列表
 * @param { Number } pageNum - 分页参数
 * @param { Number } pageSize - 分页参数
 * @param { String } couponId - 优惠券id
 * @param { Number } isBound - 是否绑定 1.已绑定 2.未绑定
 * @param { String } intendantCode - 业务员编码
 * @param { String } intendantName - 业务员姓名
 * @param { String } merchantName - 商家名称
 * @param { String } qrCodeSerial - 二维码编号
 * @param { String } areaId - 区域id
 */
export function queryQrCodeListByCouponId(param) {
  return request.requestPost({
    url: '/marketing/queryQrCodeListByCouponId',
    method: 'post',
    param,
    loading: false
  })
}

/**
 * 批量绑定优惠券与二维码关联关系
 * @param { String } couponId - 优惠券id
 * @param { Array } qrCodeIds - 二维码id数组
 */
export function batchBoundCouponQrCode(param) {
  return request.requestPost({
    url: '/marketing/batchBoundCouponQrCode',
    method: 'post',
    param,
    loading: true
  })
}

/**
 * 批量解绑优惠券与二维码关联关系
 * @param { Array } couponQrCodeIds - 优惠券二维码id数组
 */
export function batchUntieCouponQrCode(couponQrCodeIds) {
  return request.requestPost({
    url: '/marketing/batchUntieCouponQrCode',
    method: 'post',
    param: couponQrCodeIds,
    loading: true
  })
}

/**
 * 更新商家备注信息
 * @param {Object} param - 函数参数对象
 * @param {string} param.qrCodeId - 商家二维码ID
 * @param {string} param.remark - 新的商家备注信息
 * @returns {Promise} 返回一个Promise对象，包含服务器的响应结果
 */
export function updateRemark(param) {
  return request.requestPost({
    url: `/customer/merchant/updateRemark/${param.qrCodeId}`,
    method: 'post',
    param: {
      remark: param.remark
    }
  })
}

/**
 * 根据优惠券分页查询二维码列表
 * @param { Number } intendantName - 业务员名称
 * @param { Number } areaId - 所属区域
 * @param { String } merchantName - 商家名称
 * @param { Number } qrCodeStatus - 二维码状态 1.启用 2.禁用
 * @param { String } intendantCode - 业务员编号
 * @param { String } qrCode - 二维码编号
 * @param { String } claimStatus - 领取状态,1已领取，2未领取
 *
 */
export function exportQrCode(param) {
  return request.requestPost({
    url: '/customer/merchant/exportQrCode',
    method: 'post',
    param,
    loading: true
  })
}

/**
 * 人才库API
 */
/**
 *分组管理列表
 * @param { String } categoryName - 分组名称
 */
export function getTalentCategoryList(param) {
  return request.requestPost({
    url: '/customer/talent/queryTalentCategoryList',
    method: 'post',
    param,
    loading: false
  })
}

/**
 * 新增/编辑人才分组
 * @param { String } categoryName - 分组名称
 * @param { String } id - 主键id
 */
export function saveOrUpdateTalentCategory(param) {
  return request.requestPut({
    url: '/customer/talent/saveOrUpdateTalentCategory',
    method: 'put',
    param,
    loading: true
  })
}

/**
 * 删除人才分组
 * @param { String } id - 主键id
 */
export function removeTalentCategory(id) {
  return request.requestDel({
    url: '/customer/talent/removeTalentCategory/'+id,
    method: 'delete',
    loading: true
  })
}

/**
 * 获取人员列表
 * @param { String } memberNickname - 人员名称,
 * @param { String } memberTelephone - 手机号,
 * @param { String } qyUserId - 员工工号,
 * @param { String } talentCategoryId - 所属分组id,
 * @param { String } memberCode - 会员编号,
 * @param { String } createTimeBegin - 创建时间 开始,
 * @param { String } createTimeEnd - 创建时间 结束,
 * @param { String } qrCodeSerial - 二维码编号,
 * @param { Number } pageNum - 页码,
 * @param { Number } pageSize - 页数,
 */
export function getTalentPersonnelList(param) {
  return request.requestPost({
    url: '/customer/talent/queryTalentList',
    method: 'post',
    param,
    loading: false
  })
}

/**
 * 新增/编辑人员列表
 * @param {String} memberId - 主键id,
 * @param {String} memberNickname - 人员名称,
 * @param {String} memberTelephone - 手机号,
 * @param {String} qyUserId - 员工工号
 * @param {String} talentCategoryId - 所属分组id
 */
export function saveOrUpdateTalentItem(param) {
  return request.requestPost({
    url: '/customer/talent/saveOrUpdateTalent',
    method: 'post',
    param,
    loading: false
  })
}

/**
 * 条件导出人员列表
 * @param { String } memberNickname - 人员名称,
 * @param { String } memberTelephone - 手机号,
 * @param { String } qyUserId - 员工工号,
 * @param { String } talentCategoryId - 所属分组id,
 * @param { String } memberCode - 会员编号,
 * @param { String } createTimeBegin - 创建时间 开始,
 * @param { String } createTimeEnd - 创建时间 结束,
 * @param { String } qrCodeSerial - 二维码编号,
 * @param { Number } pageNum - 页码,
 * @param { Number } pageSize - 页数,
 */
export function exportPersonTalentList(param) {
  return request.download({
    url: '/customer/talent/exportTalentList',
    method: 'post',
    param
  })
}

/**
 * 导出导入模板
 */
export function exportImportTemplateExcel() {
  return request.download({
    url: '/customer/talent/exportImportTemplate',
    method: 'get'
  })
}

/**
 * 导入人员列表数据
 * @param { FormData } excel文件
 */
export function importTalentExcel(param) {
  return request.download({
    url: '/customer/talent/ImportTalent',
    method: 'post',
    param
  })
}

/**
 * 批量创建专属二维码
 * @param { Array[String] } memberId - 主键id数组,
 */
export function createExclusiveQrCodeIds(param) {
  return request.requestPut({
    url: '/customer/talent/createExclusiveQrCode',
    method: 'put',
    param
  })
}
