
const state = () => {
  return {
    giftForm: {}, // 赠品查询条件
    productForm: {} // 产品查询条件
  }
}

const mutations = {
  _setGiftForm: (state, giftForm) => {
    state.giftForm = giftForm
  },
  _setProductForm: (state, productForm) => {
    state.productForm = productForm
  }
}

const actions = {

  // 设置赠品查询条件
  setGiftForm({ commit }, value) {
    commit('_setGiftForm', value)
    // 清空产品查询条件
    commit('_setProductForm', {})
  },
  // 设置产品查询条件
  setProductForm({ commit }, value) {
    commit('_setProductForm', value)
    // 清空赠品查询条件
    commit('_setGiftForm', {})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
