/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * 验证金额
 * @param {string/number} money
 * @returns {Boolean}
 */
function verifyMoney(money = 0) {
    return /^\d+\.?\d{0,2}$/.test(money);
}

/**
 * 验证正整数
 * @param {string/number} num
 * @returns {Boolean}
 */
function verifyInt(num) {
    return /^[+]{0,1}(\d+)$/.test(num);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
// export function validUsername(str) {
//   const valid_map = ['admin', 'editor']
//   return valid_map.indexOf(str.trim()) >= 0
// }


export default {
    isExternal,
    verifyMoney,
    verifyInt
}