<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        
        <el-form-item label="商家名称">
          <el-input v-model="inquire.merchantName" placeholder="商家名称" @keyup.enter.native="searchList"></el-input>
        </el-form-item>

        <el-form-item label="商家编号">
          <el-input v-model="inquire.merchantCode" placeholder="商家编号" @keyup.enter.native="searchList"></el-input>
        </el-form-item>

        <el-form-item label="">
          <el-date-picker
            v-model="inquire.year"
            type="year"
            value-format="yyyy"
            placeholder="年份">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="">
          <el-select v-model="inquire.month" placeholder="月份" clearable>
            <el-option v-for="item in monthList" :key="item.id" :label="item.name"  :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" size="small" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div> 

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary">导入批量付款</el-button>
      <el-button size="small" type="primary">导出</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
            <el-table-column prop="date" label="序号" width="60">
              <template v-slot="scope">{{( pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}</template>
            </el-table-column>
            <el-table-column prop="times" label="年月" min-width="100"></el-table-column>
            <el-table-column prop="merchantCode" label="商家编号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.customerMerchant.merchantCode }}</template>
            </el-table-column>
            <el-table-column prop="merchantName" label="商家名称" min-width="120">
              <template slot-scope="scope">{{ scope.row.customerMerchant.merchantName }}</template>
            </el-table-column>
            <el-table-column prop="messengerName" label="商家信息员" min-width="120">
              <template slot-scope="scope">{{ scope.row.customerMerchant.messengerName }}</template>
            </el-table-column>
            <el-table-column prop="" label="手机号码" min-width="160">
              <template slot-scope="scope">{{ scope.row.customerMerchant.merchantContactsPhoneNumber }}</template>
            </el-table-column>
            <el-table-column prop="" label="银行卡名称" min-width="160">
              <template slot-scope="scope">{{ scope.row.customerMerchantBankCard ? scope.row.customerMerchantBankCard.cardBelong : '/' }}</template>
            </el-table-column>
            <el-table-column prop="" label="持卡人" min-width="120">
              <template slot-scope="scope">{{ scope.row.customerMerchantBankCard ? scope.row.customerMerchantBankCard.cardHolder : '/' }}</template>
            </el-table-column>
            <el-table-column prop="" label="银行账号" min-width="200" show-overflow-tooltip>
              <template slot-scope="scope">{{ scope.row.customerMerchantBankCard ? scope.row.customerMerchantBankCard.cardCode : '/' }}</template>
            </el-table-column>
            <!-- <el-table-column prop="amount" label="提现金额(元)" min-width="120">
              <template slot-scope="scope">{{ scope.row.amount ? '￥' + scope.row.amount.toFixed(2) : '￥ 0.00'  }}</template>
            </el-table-column> -->
            <el-table-column prop="status" label="状态" min-width="100">
              <template slot-scope="scope">{{ scope.row.status == '0' ? '待付款':'已付款' }}</template>
            </el-table-column>
            <el-table-column prop="" label="操作" min-width="140" fixed="right">
              <template slot-scope="scope" >
                <span class="operation-btn" @click="payment(scope.row)">付款</span> 
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            background
            :current-page="pageParam.pageNum"
            :page-sizes="$api.pageSizes"
            :page-size="pageParam.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParam.total"
          ></el-pagination>
        </div>
      </el-main>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        year: new Date().getFullYear().toString(),
        month: '',
        merchantName: '',
        merchantCode: '',
        status: '0'
      },
      yer: '',
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      // 表格数据
      tableData: [], 
      monthList:[
        { name: '1月', id: 1 },
        { name: '2月', id: 2 },
        { name: '3月', id: 3 },
        { name: '4月', id: 4 },
        { name: '5月', id: 5 },
        { name: '6月', id: 6 },
        { name: '7月', id: 7 },
        { name: '8月', id: 8 },
        { name: '9月', id: 9 },
        { name: '10月', id: 10 },
        { name: '11月', id: 11 },
        { name: '12月', id: 12 },
      ],

    };
  },
  created() {
    // 获取数据列表
    this.getTableList()
  },
  methods: {
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      this.getTableList()
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1
      this.getTableList()
    },
    

    // 获取数据列表
    getTableList() {
      var time = [];
      if (this.inquire.year) {
        if (this.inquire.month) {
          time = this.$public.getAssignMonth(this.inquire.year + '-' + this.$public.disposeDate(this.inquire.month) + '-01')
        } else {
          time = [this.inquire.year + '-01-01', this.inquire.year + '-12-31']
        }
      } else {
        this.inquire.year = new Date().getFullYear()
        time = [this.inquire.year + '-01-01', this.inquire.year + '-12-31']
      }
      let param = '?pageNum=' + this.pageParam.pageNum + '&pageSize=' + this.pageParam.pageSize + '&status=' + this.inquire.status + '&beginYear=' + time[0] + ' 00:00:00' + '&endYear=' + time[1] + ' 23:59:59' + '&merchantName=' + this.inquire.merchantName + '&merchantCode=' + this.inquire.merchantCode;
      
      this.loading = true;
      this.$http.requestGet({
        url: '/wait/payment/merchant/commission/commissionList' + param,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.list;

        this.pageParam.total = res.data.total;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },

    
    // 付款
    payment(row) {
      let times = row.times.split('-')
      let Dates = new Date()
      if (Math.abs(times[0]) === Dates.getFullYear()) {
        if (Math.abs(times[1]) >= (new Date().getMonth() + 1)) {
          return this.$message.warning('当月商家提成还不能支付！')
        }
      }
      
      const h = this.$createElement;
      this.$msgbox({
        title: '付款',
        message: h('div', 
        { style: 'padding:0 20px;color: #4a4a4a' }, 
        [
          h('h3', { style: 'padding-bottom: 20px; text-align:center;' }, '是否确定付款？ '),
          h('p', null, '提成时间：'+ times[0] +'年'+ times[1] +'月'),
          h('p', null, '提成金额：￥' + (row.amount ? row.amount : 0)),
          h('p', null, '商家编号：' + (row.customerMerchant ? row.customerMerchant.merchantCode : '') ),
          h('p', null, '商家名称：' + (row.customerMerchant ? row.customerMerchant.merchantName : '')),
          h('p', null, '收款人：' + (row.customerMerchantBankCard ? row.customerMerchantBankCard.cardHolder : '' ))
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            var time = [];
            if (times[0]) {
              time = this.$public.getAssignMonth(times[0] + '-' + times[1] + '-01')
            } 
            let param = {
              beginYear: time[0] + " 00:00:00", 
              endYear: time[1] + " 23:59:59",
              merchantId: row.merchantId,
              status: 1,
            }
            if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
              this.pageParam.pageNum--
            }
            this.$http.requestPost({ url: '/wait/payment/merchant/commission/checkPaymentStatus', param }).then(res => {
              this.$message({message:'操作成功', type:'success'})
              this.getTableList()
              done()
            })
          } else {
            done();
          }
        }
      }).then(action => {
        console.log("成功")
      }).catch(() => {});
    },

    // 添加，修改提交
    submit(param) {
      this.$http.requestPost({
        url: '',
        param: param
      }).then(res => {
        this.getTableList()
        this.dialogVisible = false;
        this.$message({
          message: '操作成功',
          type: 'success'
        })
      })
    },

  },
};
</script>

<style lang="scss" scoped>
  .address-list {
    flex-wrap: wrap;
    
    & > div {
      display: inline-flex;
    }
  }
  .text {
    padding: 0 5px;
  }
  .text-left {
    min-width: 125px;
    text-align: right;
  }
</style>
