<template>
    <!-- 待付款订单 -->
    <div class="main-box">
        <div class="head-search-row">
            <el-form :inline="true" class="demo-form-inline" size="medium">
                <el-form-item label="订单编号">
                    <el-input v-model="inquire.orderCode" placeholder="订单编号" @keyup.enter.native="searchList"></el-input>
                </el-form-item>

                <el-form-item label="下单时间">
                    <el-date-picker
                        v-model="daterange"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        end-placeholder="结束日期"
                        start-placeholder="开始日期"
                        type="daterange">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="">
                    <div class="flex-box">
                        <el-cascader v-model="categoryId" :props="props" :options="productTypeList" @change="categoryChange" clearable></el-cascader>
                        <el-select v-model="inquire.productIds" multiple v-show="productList.length > 0" placeholder="请选择产品" class="width-260">
                            <el-option v-for="item in productList" :key="item.productId" :label="item.productName" :value="item.productId" />
                        </el-select>
                    </div>
                </el-form-item>

              <el-form-item label="优惠券ID">
                <el-input v-model.trim="inquire.couponCode" placeholder="优惠券ID" @keyup.enter.native="searchList" />
              </el-form-item>


              <el-form-item>
                    <el-button size="small" type="primary" @click="searchList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div style="margin: 20px 0 0 20px">
            <el-button size="small" type="primary" @click="fnExportOrderList">导出</el-button>
        </div>

        <div class="table">
            <el-main>
                <template>
                    <el-table ref="tables" v-loading="loading" :data="tableData" :expand-row-keys="expands" border height="400px" row-key="orderId" style="width: 100%" @expand-change="expandChange">
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-table :data="scope.row.items" border style="width: 100%">
                                    <el-table-column label="产品信息" min-width="200" prop="" show-overflow-tooltip>
                                        <template slot-scope="scope">
                                            <div class="flex-box">
                                                <img v-if="scope.row.productImg" :src="scope.row.productImg" alt="" class="img-box">
                                                <div class="hospital-name">{{ scope.row.productName }}</div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="产品编号" min-width="160" prop="productCode" show-overflow-tooltip></el-table-column>
                                    <el-table-column label="产品规格" min-width="120" prop="productNorms">
                                        <template slot-scope="scope">{{ scope.row.productNorms || '/' }}</template>
                                    </el-table-column>
                                    <el-table-column label="单价" min-width="120" prop="itemPrice">
                                        <template slot-scope="scope">{{ '￥' + scope.row.itemPrice }}</template>
                                    </el-table-column>
                                    <el-table-column label="数量" min-width="120" prop="itemNum"></el-table-column>
                                    <el-table-column label="购买总价" min-width="120" prop="">
                                        <template slot-scope="scope">
                                            {{ '￥' + (parseInt(scope.row.itemNum) * scope.row.itemPrice).toFixed(2) }}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>

                        <el-table-column label="订单编号" min-width="200" prop="orderCode" show-overflow-tooltip>
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleParticulars(scope.row)">{{ scope.row.orderCode }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="买家/收货人" min-width="160" prop="" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{ scope.row.memberNickName + '/' + scope.row.recipient }}
                            </template>
                        </el-table-column>
                        <el-table-column label="总金额" min-width="120" prop="">
                            <template slot-scope="scope">
                                {{ '￥' + scope.row.orderTotalPrice.toFixed(2) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="押金" min-width="120" prop="depositPrice">
                            <template slot-scope="scope">
                                {{ '￥ ' + (scope.row.depositPrice || 0).toFixed(2)   }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="orderStatus == 'completed'" label="已退押金" min-width="120" prop="depositPrice">
                            <template slot-scope="scope">
                                {{ '￥ ' + (scope.row.depositPriceReturned || 0).toFixed(2)   }}
                            </template>
                        </el-table-column>

                        <el-table-column label="下单时间" min-width="160" prop="orderTime"></el-table-column>
                        <el-table-column v-if="orderStatus == 'pending_ship'" label="付款时间" min-width="160" prop="paymentTime"></el-table-column>
                        <el-table-column v-else-if="orderStatus == 'pending_receipt'" label="发货时间" min-width="160" prop="shipTime"></el-table-column>
                        <el-table-column v-else-if="orderStatus == 'pending_evaluate'" label="确认收货时间" min-width="160" prop="receiptTime"></el-table-column>
                        <!-- <el-table-column prop="orderTime" label="起租时间" min-width="160" v-else-if="orderStatus == 'leased' || orderStatus == 'expired'"></el-table-column>
                        <el-table-column prop="orderTime" label="到期时间" min-width="160" v-if="orderStatus == 'leased' || orderStatus == 'expired'"></el-table-column> -->

                        <el-table-column label="订单状态" min-width="120" prop="orderStatus">
                            <template slot-scope="scope">
                                {{ $api.getValue($api.leaseOrderState, scope.row.orderStatus, 'name') }}
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="所属专员" min-width="120" prop="attacheName"></el-table-column> -->
                        <!-- <el-table-column label="收货地址" min-width="200" prop="recipientAddressDetail" show-overflow-tooltip></el-table-column> -->
                        <el-table-column fixed="right" label="操作" min-width="160" prop="">
                            <template slot-scope="scope">
                                <!-- <span v-show="scope.row.orderStatus ==='pending_payment'" class="operation-btn" @click="handleEdit(scope.row)">修改价格</span> -->
                                <span v-show="scope.row.orderStatus ==='pending_ship'" class="operation-btn" @click="handleShipments(scope.row)">发货</span>
                                <span v-show="scope.row.orderStatus ==='pending_ship'" class="operation-btn_partition"> / </span>
                                <span class="operation-btn" @click="handleParticulars(scope.row)">订单详情</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination
                        :current-page="pageParam.pageNum"
                        :page-size="pageParam.pageSize"
                        :page-sizes="$api.pageSizes"
                        :total="pageParam.total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                    ></el-pagination>
                </div>
            </el-main>
        </div>

        <!-- 弹框 -->
        <el-dialog
            :visible.sync="dialogVisible_1"
            title="修改价格"
            top="2vh"
            width="70%">
            <div class="Popout_content">
                <div class="table">
                    <el-table :data="tableData" border height="400px" style="width: 100%">
                        <el-table-column label="序号" prop="date" width="60">
                            <template v-slot="scope">
                                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="产品信息" min-width="120" prop="orderTotalPrice">
                            <template>

                            </template>
                        </el-table-column>
                        <el-table-column label="单价" min-width="100" prop="orderTotalPrice"></el-table-column>
                        <el-table-column label="数量" min-width="100" prop="orderTotalPrice"></el-table-column>
                        <el-table-column label="小计" min-width="100" prop="orderTotalPrice"></el-table-column>
                        <el-table-column label="加价/减价" min-width="120" prop="orderTotalPrice">
                            <template slot-scope="scope">
                                <div class="flex-box flex-center">
                                    ￥
                                    <el-input v-model="scope.row.orderTotalPrice" placeholder=""></el-input>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="实收金额" min-width="100" prop="orderTotalPrice"></el-table-column>
                    </el-table>
                </div>
                <div class="show-money">
                    <ul>
                        <li>商品金额：<span class="block_span">￥200.00</span></li>
                        <li>运费：<span class="block_span">+￥20.00</span></li>
                        <li>加价/减价：<span class="block_span color_red">-￥20.00</span></li>
                        <li class="line-box"></li>
                        <li class="bold">实付总额：<span class="block_span color_red">￥180.00</span></li>
                    </ul>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible_1 = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible_1 = false">确 定</el-button>
            </span>
        </el-dialog>

        <d-export :inquire="inquire" :showDialog="showExportDialog" :close.sync="showExportDialog" />
    </div>
</template>

<script>
import dExport from "@/components/order/d-export";

export default {
    components: {
        dExport
    },
    props: {
        orderStatus: {
            type: String,
            default: 'pending_payment'
        }
    },
    data() {
        return {
            // 产品分类
            productTypeList: [],
            props: {
                label: 'categoryName',
                value: 'categoryId',
                children: 'child',
                // checkStrictly: true,
                emitPath: true
            },
            productList: [],
            categoryId: '',

            // medicine：医药
            // lease：租赁
            inquire: {
                orderClassify: 'lease',
                isReturnOrder: false,
                orderStatus: '',
                orderTimeBegin: '',
                orderTimeEnd: '',
                deliveryMethod: '',
                orderCode: '',
                productIds: [],
                categoryId: '',
                couponCode: ''
            },
            daterange: [],
            // 分页
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            // 表格数据
            tableData: [],
            loading: false,
            expands: [],// 当前展示行


            // 修改价格弹窗1
            dialogVisible_1: false,

            // ========= 导出弹窗 =========
            showExportDialog: false,
        };
    },
    watch: {
        tableData(val) {
            this.$nextTick(() => {
                this.$refs.tables.doLayout()
            })
        }
    },
    created() {
        if(sessionStorage.getItem("cacheParamE")){
            this.inquire = JSON.parse(sessionStorage.getItem("cacheParamE"))
            this.daterange[0] = this.inquire.orderTimeBegin || ''
            this.daterange[1] = this.inquire.orderTimeEnd || ''
        }
        this.inquire.orderStatus = this.orderStatus
        this.getTableData()
        this.getProductType()
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size;
            this.getTableData()
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page;
            this.getTableData();
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1
            this.getTableData()
        },
        // table 手风琴
        expandChange(news, old) {
            if (old.length === 0) {
                return this.expands = [];
            }
            this.expands = [news.orderId];
        },
        // 获取产品类型
        getProductType() {
            this.$http.requestGet({url:'/product/category', loading: false}).then(res => {
                this.productTypeList = res.data;
            })
        },
        // 产品分类改变
        categoryChange(e) {
            this.productList = []
            this.inquire.productIds = []
            if (e.length > 1) {
                this.getProductList(e[1])
                this.inquire.categoryId = ""
            } else {
                this.inquire.categoryId = e[0]
            }
        },
        // 根据分类id获取产品列表
        getProductList(id) {
            this.$http.requestGet({url:'/customer/order/category/' + id, loading: false}).then(res => {
                this.productList = res.data
            })
        },
        // 导出
        fnExportOrderList() {
            if (!this.datetimerange) this.datetimerange = [];
            this.inquire.orderTimeBegin = this.datetimerange.length > 0 ? this.datetimerange[0] : '';
            this.inquire.orderTimeEnd = this.datetimerange.length > 0 ? this.datetimerange[1] : '';
            this.inquire.categoryId = this.categoryId
            this.showExportDialog = true;
        },

        // 获取列表数据
        getTableData() {
            if (!this.daterange) this.daterange = [];
            this.inquire.orderTimeBegin = this.daterange.length > 0 ? this.daterange[0] : '';
            this.inquire.orderTimeEnd = this.daterange.length > 0 ? this.daterange[1] : '';
            this.orderStatus = this.inquire.orderStatus;
            let data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: JSON.parse(JSON.stringify(this.inquire))
            }
            delete data.param.categoryId

            this.loading = true;
            this.$http.requestPost({
                url: '/customer/order/page',
                param: data,
                loading: false
            }).then(res => {
                this.loading = false;
                this.tableData = res.data.list;
                this.pageParam.total = res.data.total;
                sessionStorage.removeItem("cacheParamE")
            }).catch(err => {
                this.loading = false;
                sessionStorage.removeItem("cacheParamE")
                console.log(err)
            })
        },


        // 修改价格
        handleEdit(row) {
            this.dialogVisible_1 = true;
            // let arr = [
            //   {}
            // ]
            // arr
        },

        // 发货
        handleShipments(row) {
            this.$confirm('确定已发货?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(() => {
                    this.$http.requestPut({ url: '/customer/order/lease/ship/' + row.orderId }).then(res => {
                        this.$message.success('操作成功！')
                        this.getTableData()
                    })
                }).catch(() => {});
        },

        // 订单详情
        handleParticulars(row) {
            sessionStorage.setItem("cacheParamE", JSON.stringify(this.inquire))
            let type = this.$api.leaseOrderState.findIndex(item => item.value === row.orderStatus);
            this.$router.push({
                path: '/order/lease/particulars',
                query: {
                    type: type,
                    id: row.orderId
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.el-range-editor--medium .el-input__inner {
    width: 375px;
}

.el-head-search-row {
    .flex-box {
        width: 326px;
    }
}

.show-money {
    padding: 10px 0;

    ul {
        li {
            display: flex;
            justify-content: flex-end;
            line-height: 30px;
            font-size: 16px;
        }

        .line-box {
            margin-left: 50%;
            border-bottom: 1px solid #ccc;
        }

        .bold {
            font-weight: bolder;
        }
    }
}

.block_span {
    display: inline-block;
    width: 108px;
    text-align: right;
}
</style>
