<!-- 业务员优惠券列表 -->
<template>
  <div>
    <ShareList couponType='salesperson'/>
  </div>
</template>

<script>
import ShareList from "./ShareList";
export default {
  name: "SalesmanCoupon",
  components: {
    ShareList
  },
  data() {
    return {};
  },
  created() { },
  methods: {}
};


</script>

<style scoped></style>