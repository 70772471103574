<template>
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="">
          <el-date-picker
            v-model="inquire.year"
            type="year"
            value-format="yyyy"
            placeholder="年份">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="">
          <el-select v-model="inquire.month" placeholder="月份" clearable>
            <el-option v-for="item in monthList" :key="item.id" :label="item.name"  :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        
        <el-form-item>
          <el-button type="primary" size="small" @click="getProductList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary">导出报表</el-button>
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table :data="tableData" v-loading="loading" border style="width: 100%">
            <el-table-column prop="month" label="月份" min-width="100">
              <template slot-scope="scope">
                {{ scope.row.month + '月' }}
              </template>
            </el-table-column>
            <el-table-column prop="medicineCount" label="医品订单数量" min-width="120"></el-table-column>
            <el-table-column prop="medicineMoney" label="医品订单金额" min-width="120">
              <template slot-scope="scope">{{ '￥' + scope.row.medicineMoney.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="electronicCount" label="电商品订单数量" min-width="120"></el-table-column>
            <el-table-column prop="electronicMoney" label="电商品订单金额" min-width="120">
              <template slot-scope="scope">{{ '￥' + scope.row.electronicMoney.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="leaseCount" label="租赁品订单数量" min-width="120"></el-table-column>
            <el-table-column prop="leaseMoney" label="租赁品订单金额" min-width="120">
              <template slot-scope="scope">{{ '￥' + scope.row.leaseMoney.toFixed(2) }}</template>
            </el-table-column>
            <el-table-column prop="allCount" label="合计订单数量" min-width="120"></el-table-column>
            <el-table-column prop="allMoney" label="合计订单金额" min-width="120">
              <template slot-scope="scope">{{ '￥' + scope.row.allMoney.toFixed(2) }}</template>
            </el-table-column>
          </el-table>
        </template>
      </el-main>
    </div>
  </div>
</template>

<script>
import { parse } from 'path-to-regexp'
export default {
  data() {
    return {
      // 查询参数
      inquire: {
        year: new Date().getFullYear().toString(),
        month: '',
      },
      loading: false,
      // 表格数据
      tableData: [],  
      monthList:[
        { name: '1月', id: 1 },
        { name: '2月', id: 2 },
        { name: '3月', id: 3 },
        { name: '4月', id: 4 },
        { name: '5月', id: 5 },
        { name: '6月', id: 6 },
        { name: '7月', id: 7 },
        { name: '8月', id: 8 },
        { name: '9月', id: 9 },
        { name: '10月', id: 10 },
        { name: '11月', id: 11 },
        { name: '12月', id: 12 },
      ],
    };
  },
  created() {
    // 获取产品列表
    this.getProductList()
  },
  methods: {
    // 获取产品列表
    getProductList() {
      this.inquire.year = this.inquire.year ? this.inquire.year : ""

      this.loading = true;
      this.$http.requestPost({
        url: '/wait/payment/merchant/commission/comprehensive/orderStatistics',
        param: this.inquire,
        loading: false
      }).then(res => {
        this.loading = false;
        this.tableData = res.data.listDto;
      }).catch(err => {
        this.loading = false;
        console.log(err)
      })
    },
    
  },
};
</script>

<style lang="scss" scoped>
  
</style>
