<template>
  <!-- 产品单位列表 -->
  <div class="main-box">
    <div class="head-search-row">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item label="增加时间">
          <div class="flex-box">
            <el-date-picker v-model="daterange" end-placeholder="结束日期" format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" type="daterange" value-format="yyyy-MM-dd">
            </el-date-picker>
          </div>
        </el-form-item>

        <el-form-item label="">
          <div class="flex-box">
            <el-select v-model="selectKey" placeholder="请选择" @change="selectValue = ''" class="width-140">
              <el-option label="专员姓名" value="attacheName"></el-option>
              <el-option label="手机号" value="attachePhoneNumber"></el-option>
              <el-option label="专员编号" value="attacheCode"></el-option>
            </el-select>
            <el-input v-model="selectValue" placeholder="" class="width-180" @keyup.enter.native="searchList"></el-input>
          </div>
        </el-form-item>

        <!-- <el-form-item label="所属区域">
          <el-cascader v-model="inquire.areaId" :options="areaList" :props="areaProps" style="width: 300px;" clearable></el-cascader>
        </el-form-item> -->
        <el-form-item label="所属区域">
          <el-cascader v-model="inquire.areaId" :props="areaProps" :options="areaList" clearable placeholder="请选择地址" v-if="$public.isAllAreaPower()" style="width: 300px;" />
          <d-area :values.sync="inquire.areaId" v-else />
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="searchList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div style="margin: 20px 0 0 20px">
      <el-button size="small" type="primary" @click="handleAdd">新增专员</el-button>
      <!-- <el-button size="small" type="primary">导入产品提成比例</el-button>
            <el-button size="small" type="primary">导出</el-button> -->
    </div>

    <div class="table">
      <el-main>
        <template>
          <el-table v-loading="loading" :data="tableData" border height="400px" style="width: 100%">
            <el-table-column label="序号" prop="date" width="60">
              <template v-slot="scope">
                {{ (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1) }}
              </template>
            </el-table-column>
            <el-table-column label="专员编号" min-width="200" prop="attacheCode" show-overflow-tooltip></el-table-column>
            <el-table-column label="专员名称" min-width="120" prop="attacheName"></el-table-column>
            <el-table-column label="手机号" min-width="160" prop="attachePhoneNumber"></el-table-column>
            <el-table-column label="负责配送地址数量" min-width="140" prop="shippingAddressCount"></el-table-column>
            <el-table-column label="所属大区" min-width="160" prop="regionName" show-overflow-tooltip></el-table-column>
            <el-table-column label="所属片区" min-width="160" prop="districtName" show-overflow-tooltip></el-table-column>
            <el-table-column label="所属办事处" min-width="160" prop="agencyName" show-overflow-tooltip></el-table-column>
            <el-table-column label="增加时间" min-width="160" prop="createTime"></el-table-column>
            <el-table-column label="操作" min-width="210" prop="">
              <template slot-scope="scope">
                <span class="operation-btn" @click="handleEdit(scope.row)">专员档案</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="shippingAddress(scope.row)">负责配送地址列表</span>
                <span class="operation-btn_partition"> / </span>
                <span class="operation-btn" @click="delAttache(scope.row)">删除</span>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="pagination">
          <el-pagination :current-page="pageParam.pageNum" :page-size="pageParam.pageSize" :page-sizes="$api.pageSizes" :total="pageParam.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 新增专员 -->
    <el-dialog :before-close="closeDialog_1" :title="dialogTitle" :visible.sync="dialogVisible_1" top="5vh" width="600px">
      <div class="Popout_content">
        <el-form ref="submitForm" :model="submitForm" :rules="submitRules" label-width="100px">
          <el-form-item label="专员名称" prop="attacheName">
            <el-input v-model="submitForm.attacheName" maxlength="10" placeholder="专员名称"></el-input>
          </el-form-item>
          <el-form-item v-show="!isAdd" label="专员编号">
            <el-input v-model="attacheCode" disabled placeholder="专员编号"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="attachePhoneNumber">
            <el-input v-model="submitForm.attachePhoneNumber" maxlength="11" placeholder="手机号"></el-input>
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input v-model="submitForm.attacheIdCard" maxlength="18" placeholder="身份证号"></el-input>
          </el-form-item>
          <el-form-item label="所属区域" prop="areaId">
            <div v-if="$public.isPowerNew() === 3">
              <el-select v-model="submitForm.areaId" placeholder="请选择办事处" style="width: 100%;" clearable>
                <el-option v-for="item in areaData" :key="item.agencyId" :label="item.agencyName" :value="item.agencyId">
                </el-option>
              </el-select>
            </div>
            <div v-else>
              <el-cascader v-model="submitForm.areaId" :options="areaList" :props="areaProps" clearable style="width: 100%;"></el-cascader>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog_1">取 消</el-button>
        <el-button type="primary" @click="submitAdd">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 负责配送地址列表 -->
    <el-dialog :before-close="() => dialogVisible_2 = false" :title="dialogTitle + '负责配送地址列表-' + addressPerson" :visible.sync="dialogVisible_2" width="80%" top="10vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="所在地区">
            <el-cascader v-model="inquire_2.addressId" clearable :options="addressList" :props="props" class="width-inherit" placeholder="请选择"></el-cascader>
          </el-form-item>

          <el-form-item label="医院名称">
            <el-input v-model="inquire_2.hospitaName" @keyup.enter.native="queryAddress" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="inquire_2.addressDetail" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button size="small" type="primary" @click="queryAddress">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button size="small" type="primary" @click="changeAddress">变更</el-button>
          </el-form-item>
        </el-form>
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item>
            <el-button type="primary" @click="handleAddPersonList">添加配送地址</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleExport">导出</el-button>
          </el-form-item>
        </el-form>

        <el-table ref="multiTable2" v-loading="loading_2" :data="tableData_2" @selection-change="handleSelectionChange" :row-key="getRowKey2" border height="400px" style="width: 100%">
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column label="所在地区" min-width="200" prop="addressName" show-overflow-tooltip></el-table-column>
          <el-table-column label="详细地址" min-width="200" prop="address" show-overflow-tooltip></el-table-column>
          <el-table-column label="所属医院" min-width="200" prop="hospitalName" show-overflow-tooltip></el-table-column>
          <el-table-column label="绑定时间" min-width="160" prop="createTime"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination :current-page="pageParam_2.pageNum" :page-size="pageParam_2.pageSize" :page-sizes="[10, 100, 500, 1000, 3000]" :total="pageParam_2.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange_2" @current-change="handleCurrentChange_2"></el-pagination>
        </div>
        <div style="text-align: right;padding: 6px 0px 6px 0px;">
          <el-button type="primary" @click="hanldeSubmitUnBind">解除绑定{{unbindNumber}}</el-button>
          <el-button @click="hanldeCancelUnBind">取消</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 新增专员列表弹框 -->
    <el-dialog :before-close="() => dialogVisible_3 = false" :title="dialogTitle + '负责配送地址列表-' + addressPerson" :visible.sync="dialogVisible_3" width="80%" top="10vh">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="所在地区">
            <el-cascader v-model="inquire_3.addressId" clearable :options="addressList" :props="props" class="width-inherit" placeholder="请选择"></el-cascader>
          </el-form-item>

          <el-form-item label="医院名称">
            <el-input v-model="inquire_3.hospitaName" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="inquire_3.addressDetail" placeholder="请输入"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button size="small" type="primary" @click="getBind">查询</el-button>
          </el-form-item>
        </el-form>

        <el-table ref="multiTable3" v-loading="loading_3" :data="tableData_3" @selection-change="handleSelectionDetailChange" :row-key="getRowKey3" border height="400px" style="width: 100%">
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column label="所在地区" min-width="200" prop="addressName" show-overflow-tooltip></el-table-column>
          <el-table-column label="详细地址" min-width="200" prop="address" show-overflow-tooltip></el-table-column>
          <el-table-column label="所属医院" min-width="200" prop="hospitalName" show-overflow-tooltip></el-table-column>
          <el-table-column label="绑定时间" min-width="160" prop="createTime"></el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination :current-page="pageParam_3.pageNum" :page-size="pageParam_3.pageSize" :page-sizes="[10, 100, 500, 1000, 3000]" :total="pageParam_3.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange_3" @current-change="handleCurrentChange_3"></el-pagination>
        </div>
        <div style="text-align: right;padding: 6px 0px 6px 0px;">
          <el-button type="primary" @click="hanldeSubmitBind">确认绑定{{bindNumber}}</el-button>
          <el-button @click="hanldeCancelBind">取消</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 产品提成比设置弹框 -->
    <el-dialog :before-close="() => dialogVisible_4 = false" :title="dialogTitle + ' - 产品提成比设置'" :visible.sync="dialogVisible_4" top="5vh" width="80%">
      <div class="Popout_content">
        <el-form :inline="true" class="demo-form-inline" size="medium">
          <el-form-item label="订单编号">
            <el-input v-model="inquire_4.name" placeholder="订单编号"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button size="small" type="primary" @click="getCommissionList">查询</el-button>
          </el-form-item>

          <el-form-item>
            <el-button size="small" type="primary">导入产品提成比例</el-button>
            <el-button size="small" type="primary">导出</el-button>
          </el-form-item>
        </el-form>

        <el-table v-loading="loading_4" :data="tableData_4" border height="400px" style="width: 100%">
          <el-table-column label="序号" prop="date" width="60">
            <template v-slot="scope">{{
                                (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
                            }}
            </template>
          </el-table-column>
          <el-table-column label="产品编号" min-width="120" prop="hospitalCode"></el-table-column>
          <el-table-column label="产品名称" min-width="120" prop="hospitalName"></el-table-column>
          <el-table-column label="所属类别" min-width="120" prop="regionName"></el-table-column>
          <el-table-column label="所属类型" min-width="120" prop="districtName"></el-table-column>
          <el-table-column label="销售价" min-width="120" prop="agencyName"></el-table-column>
          <el-table-column label="提成比例" min-width="120" prop="agencyName"></el-table-column>
          <el-table-column label="提成金额" min-width="120" prop="agencyName"></el-table-column>
          <el-table-column fixed="right" label="操作" min-width="200" prop="">
            <template slot-scope="scope">
              <span class="operation-btn" @click="amendCommission(scope.row)">修改提成比例</span>
            </template>
          </el-table-column>
        </el-table>

        <div class="pagination">
          <el-pagination :current-page="pageParam_4.pageNum" :page-size="pageParam_4.pageSize" :page-sizes="$api.pageSizes" :total="pageParam_4.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange_4" @current-change="handleCurrentChange_4"></el-pagination>
        </div>
      </div>
    </el-dialog>

    <!-- 修改提成比例 -->
    <el-dialog :before-close="() => dialogVisible_5 = false" :visible.sync="dialogVisible_5" title="修改提成比例" width="600px">
      <div class="Popout_content">
        <el-form ref="submitForm_5" :model="submitForm_5" :rules="submitRules_5" class="demo-form-inline">
          <el-form-item label="当前提成比例">
            {{ submitForm_5.name }}
          </el-form-item>

          <el-form-item label="修改提成比例">
            <div class="flex-box">
              <el-input v-model="submitForm_5.name" class="width-140" placeholder="修改提成比例"></el-input>
              %
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_5 = false">取 消</el-button>
        <el-button type="primary" @click="confirm_2">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="绑定确认" :visible.sync="dialogVisible_6" width="30%">
      <span>是否确定绑定？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hanldeCancelD">取 消</el-button>
        <el-button type="primary" @click="hanldeSubmitD">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="解除绑定确认" :visible.sync="dialogVisible_7" width="30%">
      <span>是否确定解除绑定？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hanldeCancelR">取 消</el-button>
        <el-button type="primary" @click="hanldeSubmitR">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="变更信息" :visible.sync="dialogVisible_New" width="60%">
      <el-form :inline="true" class="demo-form-inline" size="medium">
        <el-form-item>
          <div class="flex-box">
            <el-select v-model="selectAttacheKey" placeholder="请选择" @change="selectAttacheValue = ''" class="width-140">
              <el-option label="专员手机号" value="attachePhoneNumber"></el-option>
              <el-option label="专员姓名" value="attacheName"></el-option>
              <el-option label="专员编号" value="attacheCode"></el-option>
            </el-select>
            <el-input v-model="selectAttacheValue" placeholder="" class="width-180" @keyup.enter.native="searchList"></el-input>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button size="small" type="primary" @click="handleQueryNew">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableDataNew" @row-click="handleSelectionNew" :row-key="getRowKeyNew" :highlight-current-row="true" border height="400px" style="width: 100%">
        <el-table-column width="55" align="center">
          <template slot-scope="scope">
            <el-radio v-model="selectedRowId" :label="scope.row.attacheId">
              <span></span>
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column label="专员姓名" min-width="200" prop="attacheName" show-overflow-tooltip></el-table-column>
        <el-table-column label="专员手机号" min-width="200" prop="attachePhoneNumber" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination :current-page="pageParamNew.pageNum" :page-size="pageParamNew.pageSize" :page-sizes="[10, 100, 500, 1000, 3000]" :total="pageParamNew.total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChangeNew" @current-change="handleCurrentChangeNew"></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handlePhoneCancel">取 消</el-button>
        <el-button type="primary" @click="handlePhoneSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dArea from "@/components/d-area";
import { exportSpecialistAddress } from "@/api/order";
import { getSessionStorage } from "@/utils/auth";
export default {
  components: {
    dArea,
  },
  data() {
    return {
      unbindNumber: "（0）",
      bindNumber: "（0）",
      // 地址
      addressPerson: "",
      addressList: [],
      props: {
        label: "addressName",
        value: "addressId",
        children: "child",
        // checkStrictly: true,
        emitPath: false,
      },

      // 查询
      inquire: {
        attacheName: "",
        createTimeBegin: "",
        createTimeEnd: "",
        areaId: "",
      },
      daterange: [],
      selectKey: "attacheName",
      selectValue: "",
      pageParam: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      // 表格数据
      tableData: [],
      loading: false,

      dialogTitle: "", // 弹窗标题

      // ==================== 专员档案 ====================
      dialogVisible_1: false, // 弹窗
      submitForm: {
        attacheName: "",
        attachePhoneNumber: "",
        attacheIdCard: "",
        areaId: ""
      },
      attacheCode: "",
      submitRules: {
        attacheName: [
          { required: true, message: "请输入专员名称", trigger: "blur" },
        ],
        attachePhoneNumber: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机格式错误",
            trigger: "blur",
          },
        ],
        areaId: [
          { required: true, message: '请选择所属区域', trigger: 'change' }
        ],
        attacheIdCard: [
          { required: true, message: "请输入身份证", trigger: "blur" },
        ],
      },
      editRow: {},
      isAdd: true,

      // ==================== 设置商家信息员 ====================
      dialogVisible_2: false, // 弹窗
      inquire_2: {
        addressId: "",
        hospitaName: "",
        addressDetail: "",
      },
      pageParam_2: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableData_2: [],
      loading_2: false,

      // ==================== 新增专员 ====================
      dialogVisible_3: false, // 弹窗
      inquire_3: {
        attachId: "",
        addressId: "",
        hospitaName: "",
        addressDetail: "",
      },
      pageParam_3: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableData_3: [],
      loading_3: false,
      arrBind: [],
      arrUnBind: [],

      // ==================== 产品提成比例 ====================
      dialogVisible_4: false, // 弹窗
      inquire_4: {
        name: "",
      },
      pageParam_4: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableData_4: [],
      loading_4: false,

      // 修改提成比例
      dialogVisible_5: false, // 弹窗
      submitForm_5: {
        name: "",
      },
      submitRules_5: {
        name,
      },
      dialogVisible_6: false,
      dialogVisible_7: false,
      areaList: [],
      areaProps: {
        label: "areaName",
        value: "areaId",
        children: "childes",
        checkStrictly: true,
        emitPath: false,
      },
      dialogVisible_New: false,
      selectAttacheValue: "",
      selectAttacheKey: "attachePhoneNumber",
      pageParamNew: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      tableDataNew: [],
      selectedRowId: null,
      selectedRow: {},
      exportParam: {
        attacheId: "",
        attacheName: "",
        regionName: "",
        districtName: "",
        agencyName: ""
      },
      userInfo: {},
      areaData: [],
    };
  },
  created() {
    this.userInfo = getSessionStorage("userInfo");
    // 获取省市区
    this.getAddress();
    // 获取列表
    this.getTableList();
    // 获取区域
    this.getDistrictIdList();
    // 获取办事处的数据
    this.getArea();
  },
  methods: {
    changeAddress() {
      if (this.arrUnBind.length > 0) {
        this.handleQueryNew();
        this.dialogVisible_New = true;
      } else {
        this.$message.warning("请选择变更的配送地址");
        return;
      }
    },
    handleQueryNew() {
      let data = {
        pageNum: this.pageParamNew.pageNum,
        pageSize: this.pageParamNew.pageSize,
        param: {}
      };
      data.param[this.selectAttacheKey] = this.selectAttacheValue;
      this.$http
        .requestPost({
          url: "/customer/attache/page",
          param: data,
          loading: false,
        })
        .then((res) => {
          this.tableDataNew = res.data.list;
          this.pageParamNew.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChangeNew(size) {
      this.pageParamNew.pageNum = 1;
      this.pageParamNew.pageSize = size;
      this.handleQueryNew();
    },
    handleCurrentChangeNew(page) {
      this.pageParamNew.pageNum = page;
      this.handleQueryNew();
    },
    handlePhoneCancel() {
      this.dialogVisible_New = false;
    },
    handlePhoneSubmit() {
      let arrList = [];
      this.arrUnBind.map((item, index) => {
        arrList.push({
          shippingId: item.shippingId,
          attacheId: this.selectedRow.attacheId,
        });
      });
      this.$http
        .requestPost({
          url: "/hospital/shipping/address/batchBound",
          param: arrList,
          loading: false,
        })
        .then((res) => {
          this.getShippingAddressList();
          this.dialogVisible_New = false;
          this.$message({
            message: "操作成功",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelectionNew(row) {
      this.selectedRowId = row.attacheId;
      this.selectedRow = row;
    },
    getRowKeyNew(row) {
      return row.attacheId;
    },
    // 获取区域级联列表
    getDistrictIdList() {
      this.$http.requestGet({ url: '/operation/area/agency/tree', loading: false }).then(res => {
        if (this.userInfo.managerType === 'region') {
          this.areaList = res.data.filter(item => item.areaId == this.userInfo.areaId)
        } else {
          this.areaList = res.data;
        }
      })
    },
    // 页容量改变
    handleSizeChange(size) {
      this.pageParam.pageNum = 1;
      this.pageParam.pageSize = size;
      // 刷新(数据)
      this.getTableList();
    },
    // 页码改变
    handleCurrentChange(page) {
      this.pageParam.pageNum = page;
      // 刷新(页码)
      this.getTableList();
    },
    // 刷新
    searchList() {
      this.pageParam.pageNum = 1;
      this.getTableList();
    },
    // 获取省市区
    getAddress() {
      this.$http
        .requestGet({ url: "/common/address", loading: false })
        .then((res) => {
          this.addressList = res.data;
        });
    },

    // 获取数据列表
    getTableList() {
      if (!this.inquire.areaId)
        this.inquire.areaId = this.userInfo.areaId || "";
      if (!this.daterange) this.daterange = [];
      this.inquire.createTimeBegin =
        this.daterange.length > 0 ? this.daterange[0] : "";
      this.inquire.createTimeEnd =
        this.daterange.length > 0 ? this.daterange[1] : "";
      let data = {
        pageNum: this.pageParam.pageNum,
        pageSize: this.pageParam.pageSize,
        param: JSON.parse(JSON.stringify(this.inquire)),
      };
      data.param[this.selectKey] = this.selectValue;

      this.loading = true;
      this.$http
        .requestPost({
          url: "/customer/attache/page",
          param: data,
          loading: false,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.list;
          this.pageParam.total = res.data.total;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },

    // ==================== 专员 start ====================
    // 添加专员
    handleAdd() {
      this.dialogTitle = "新增专员";
      this.dialogVisible_1 = true;
      this.isAdd = true;
    },
    // 修改专员档案
    handleEdit(row) {
      this.isAdd = false;
      this.dialogTitle = row.attacheName + " - 专员档案";
      this.$http
        .requestGet({ url: "/customer/attache/get/" + row.attacheId })
        .then((res) => {
          let data = res.data;
          this.submitForm = {
            attacheName: data.attacheName,
            attachePhoneNumber: data.attachePhoneNumber,
            attacheIdCard: data.attacheIdCard,
            areaId: data.agencyId,
          };
          this.attacheCode = data.attacheCode;
        });
      this.dialogVisible_1 = true;
      this.editRow = row;
    },
    // 提交商家档案的修改
    submitAdd() {
      this.$refs.submitForm.validate((valid) => {
        if (valid) {
          let param = {
            attacheName: this.submitForm.attacheName,
            attachePhoneNumber: this.submitForm.attachePhoneNumber,
            attacheIdCard: this.submitForm.attacheIdCard,
            areaId: this.submitForm.areaId,
          };

          if (!this.isAdd) {
            param.attacheId = this.editRow.attacheId;
          }
          this.submit(param);
        } else {
          return false;
        }
      });
    },
    // 提交
    submit(param) {
      this.$http
        .requestPost({
          url: "/customer/attache/save",
          param: param,
        })
        .then((res) => {
          if (res.code == 200) {
            this.getTableList();
            this.$message.success("操作成功!");
            this.closeDialog_1();
          }
        });
    },

    // 关闭弹窗
    closeDialog_1() {
      this.$refs.submitForm && this.$refs.submitForm.resetFields();
      this.dialogVisible_1 = false;
      this.submitForm = {
        attacheName: "",
        attacheCode: "",
        attacheGender: "1",
      };
    },

    // ==================== 负责配送地址列表 start ====================
    // 页容量改变
    handleSizeChange_2(size) {
      this.pageParam_2.pageNum = 1;
      this.pageParam_2.pageSize = size;
      // 刷新(数据)
      this.getShippingAddressList();
    },
    // 页码改变
    handleCurrentChange_2(page) {
      this.pageParam_2.pageNum = page;
      // 刷新(页码)
      this.getShippingAddressList();
    },
    // 打开配送地址列表
    shippingAddress(row) {
      // 传值
      this.exportParam.attacheId = row.attacheId;
      this.exportParam.attacheName = row.attacheName;
      this.exportParam.regionName = row.regionName;
      this.exportParam.districtName = row.districtName;
      this.exportParam.agencyName = row.agencyName;

      this.dialogVisible_2 = true;
      this.editRow = row;
      this.addressPerson = row.attacheName || "";
      this.tableData_2 = [];
      this.inquire_2 = {
        addressId: "",
        hospitaName: "",
        addressDetail: "",
      };
      this.pageParam_2.pageSize = 1000;
      this.$refs.multiTable2 && this.$refs.multiTable2.clearSelection();
      this.getShippingAddressList();
    },

    // 查询
    queryAddress() {
      this.pageParam_2.pageNum = 1;
      this.getShippingAddressList();
    },
    // 获取配送地址列表
    getShippingAddressList() {
      this.loading_2 = true
      let param = {
        pageNum: this.pageParam_2.pageNum,
        pageSize: this.pageParam_2.pageSize,
        param: {
          attachId: this.editRow.attacheId,
          addressId: this.inquire_2.addressId,
          hospitaName: this.inquire_2.hospitaName,
          addressDetail: this.inquire_2.addressDetail,
        },
      };
      this.$http
        .requestPost({
          url: "/hospital/shipping/address/pageByAttach",
          param,
          loading: false,
        })
        .then((res) => {
          this.tableData_2 = res.data.list;
          this.pageParam_2.total = res.data.total;
          this.loading_2 = false
        });
    },

    // ==================== 新增专员列表弹框 start ====================
    getBind() {
      this.pageParam_3.pageNum = 1;
      this.getBindList();
    },
    // 查询地址列表
    getBindList() {
      this.loading_3 = true
      let param = {
        pageNum: this.pageParam_3.pageNum,
        pageSize: this.pageParam_3.pageSize,
        param: {
          attachId: this.inquire_3.attachId,
          addressId: this.inquire_3.addressId,
          hospitaName: this.inquire_3.hospitaName,
          addressDetail: this.inquire_3.addressDetail,
        },
      };
      this.$http
        .requestPost({
          url: "/hospital/shipping/address/pageUnbound",
          param,
          loading: false,
        })
        .then((res) => {
          this.tableData_3 = res.data.list;
          this.pageParam_3.total = res.data.total;
          this.loading_3 = false
        });
    },
    handleSizeChange_3(size) {
      this.pageParam_3.pageNum = 1;
      this.pageParam_3.pageSize = size;
      this.getBindList();
    },
    handleCurrentChange_3(page) {
      this.pageParam_3.pageNum = page;
      this.getBindList();
    },
    handleAddPersonList() {
      this.dialogVisible_3 = true;
      this.inquire_3.attachId = "";
      this.inquire_3.addressId = "";
      this.inquire_3.hospitaName = "";
      this.inquire_3.addressDetail = "";
      this.pageParam_3.pageSize = 1000
      this.getBindList();
      this.$refs.multiTable3 && this.$refs.multiTable3.clearSelection();
    },
    // 解除绑定-确认
    hanldeSubmitUnBind() {
      this.dialogVisible_7 = true;
    },
    // 解除绑定-取消
    hanldeCancelUnBind() {
      this.getTableList();
      this.dialogVisible_2 = false;
    },
    // 确认绑定-确认
    hanldeSubmitBind() {
      this.dialogVisible_6 = true;
    },

    hanldeSubmitD() {
      if (this.arrBind.length === 0) {
        this.$message.error("请选择需要绑定的数据!");
        return;
      }
      let arrList = [];
      this.arrBind.map((item, index) => {
        arrList.push({
          shippingId: item.shippingId,
          attacheId: this.editRow.attacheId,
        });
      });
      this.$http
        .requestPost({
          url: "/hospital/shipping/address/batchBound",
          param: arrList,
          loading: false,
        })
        .then((res) => {
          this.getShippingAddressList();
          this.dialogVisible_6 = false;
          this.dialogVisible_3 = false;
          this.$message({
            message: "操作成功",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    hanldeCancelD() {
      this.dialogVisible_6 = false;
    },

    hanldeSubmitR() {
      if (this.arrUnBind.length === 0) {
        this.$message.error("请选择需要解除绑定的数据!");
        return;
      }
      let arrList = [];
      this.arrUnBind.map((item, index) => {
        arrList.push({ shippingId: item.shippingId });
      });
      this.$http
        .requestPost({
          url: "/hospital/shipping/address/batchUnbound",
          param: arrList,
          loading: false,
        })
        .then((res) => {
          this.getTableList();
          this.dialogVisible_7 = false;
          this.dialogVisible_2 = false;
          this.$message({
            message: "操作成功",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    hanldeCancelR() {
      this.dialogVisible_7 = false;
    },

    // 确认绑定-取消
    hanldeCancelBind() {
      this.dialogVisible_3 = false;
    },
    // 选中未绑定列表
    handleSelectionChange(val) {
      this.arrUnBind = val;
      this.unbindNumber = "（" + val.length + "）";
      // if (val.length < 100) {
      //   this.unbindNumber = "（" + val.length + "）";
      // } else {
      //   this.unbindNumber = "（99+）";
      // }
    },
    getRowKey2(row) {
      return row.shippingId;
    },
    // 选中绑定列表
    handleSelectionDetailChange(val) {
      this.arrBind = val;
      this.bindNumber = "（" + val.length + "）";
      // if (val.length < 100) {
      //   this.bindNumber = "（" + val.length + "）";
      // } else {
      //   this.bindNumber = "（99+）";
      // }
    },
    getRowKey3(row) {
      return row.shippingId;
    },

    // ==================== 产品提成比例 start ====================
    // 页容量改变
    handleSizeChange_4(size) {
      this.pageParam_4.pageNum = 1;
      this.pageParam_4.pageSize = size;
      // 刷新(数据)
      this.getSellList();
    },
    // 页码改变
    handleCurrentChange_4(page) {
      this.pageParam_4.pageNum = page;
      // 刷新(页码)
      this.getSellList();
    },
    // 打开产品提成比弹窗
    openCommission() {
      this.getCommissionList();
      this.dialogVisible_4 = true;
    },

    // 获取产品提成比列表
    getCommissionList() {
      let data = {
        pageNum: this.pageParam_4.pageNum,
        pageSize: this.pageParam_4.pageSize,
      };
    },
    // 修改提成
    amendCommission(row) {
      this.dialogVisible_5 = true;
    },

    // 导出demo
    async handleExport() {
      // this.arrUnBind 勾选的床位
      this.$confirm('是否导出专员-' + this.addressPerson + '的配送地址', '专员导出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        exportSpecialistAddress(this.exportParam, "post").then((res) => {
          this.$public.downloadFile(res);
          return this.$message.success("导出成功！");
        }).catch((err) => {
          console.log(err);
        });
      }).catch(() => { });
    },

    delAttache(row) {
      if (Number(row.shippingAddressCount) > 0) {
        return this.$message.error("请解绑全部床位再进行删除！");
      } else {
        this.$confirm('删除专员确认', '是否确认删除专员？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(() => {
          this.$http.requestDel({ url: "/customer/attache/deleteByAttacheId/" + row.attacheId }).then(res => {
            if (res.code == 200) {
              this.searchList();
              return this.$message.success("删除成功！");
            }
          });
        }).catch(() => { });
      }
    },

    // 获取办事处的数据
    getArea() {
      let url = '/system/manager/getAgency/' + this.userInfo.areaId;
      this.$http.requestGet({ url, loading: false }).then(res => {
        if (res.code == 200) {
          this.areaData = res.data;
        }
      }).catch(err => {
        console.log(err)
      })
    },
  },

  // 确定修改提成
  confirm_2() { },
};
</script>

<style lang="scss" scoped>
</style>

