var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "bg01" }, [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "loginForm",
                staticClass: "login-form",
                attrs: {
                  model: _vm.loginForm,
                  rules: _vm.loginRules,
                  "auto-complete": "on",
                  "label-position": "left",
                },
              },
              [
                _c("div", [
                  _c("img", {
                    staticStyle: { width: "40px", height: "40px" },
                    attrs: { alt: "", src: require("../../icons/logo.png") },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title-container" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("欢迎您登录638医品管理系统"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "managerPhoneNumber" } },
                  [
                    _c("img", {
                      staticClass: "svg-container",
                      attrs: {
                        alt: "",
                        src: require("../../icons/zhanghao@3x.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      ref: "managerPhoneNumber",
                      attrs: {
                        "auto-complete": "on",
                        name: "managerPhoneNumber",
                        placeholder: "请输入手机号",
                        maxlength: "11",
                        tabindex: "1",
                        type: "text",
                      },
                      model: {
                        value: _vm.loginForm.managerPhoneNumber,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "managerPhoneNumber", $$v)
                        },
                        expression: "loginForm.managerPhoneNumber",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-form-item",
                  { attrs: { prop: "password" } },
                  [
                    _c("img", {
                      staticClass: "svg-container",
                      attrs: {
                        alt: "",
                        src: require("../../icons/mima@3x.png"),
                      },
                    }),
                    _vm._v(" "),
                    _c("el-input", {
                      key: _vm.passwordType,
                      ref: "password",
                      attrs: {
                        type: _vm.passwordType,
                        "auto-complete": "on",
                        name: "password",
                        placeholder: "请输入密码",
                        tabindex: "2",
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleLogin($event)
                        },
                      },
                      model: {
                        value: _vm.loginForm.password,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "password", $$v)
                        },
                        expression: "loginForm.password",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "show-pwd", on: { click: _vm.showPwd } },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "icon-class":
                              _vm.passwordType === "password"
                                ? "eye"
                                : "eye-open",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%", "margin-bottom": "30px" },
                    attrs: { loading: _vm.loading, type: "primary" },
                    nativeOn: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.handleLogin($event)
                      },
                    },
                  },
                  [_vm._v("登录 ")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "tips" }, [
                  _c("span", [_vm._v("竭诚为患者服务，为医药、卫生事业服务")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "lintliet" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "lintright" }),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "login-new" },
      [
        _c(
          "el-form",
          {
            ref: "loginForm",
            attrs: {
              model: _vm.loginForm,
              rules: _vm.loginRules,
              "auto-complete": "on",
              "label-position": "left",
            },
          },
          [
            _c(
              "div",
              {
                staticStyle: {
                  "margin-left": "26%",
                  width: "8rem",
                  height: "8rem",
                },
              },
              [
                _c("img", {
                  staticStyle: { width: "100%", height: "100%" },
                  attrs: { alt: "", src: require("../../icons/logo3.png") },
                }),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "margin-top": "2rem",
                  "margin-bottom": "2rem",
                  display: "flex",
                  "justify-content": "center",
                },
              },
              [
                _c(
                  "span",
                  { staticStyle: { "font-size": "1.2rem", color: "#FFFFFF" } },
                  [_vm._v("欢迎您登录638医品管理系统")]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "managerPhoneNumber" } },
              [
                _c("img", {
                  staticStyle: {
                    width: "1.2rem",
                    "margin-left": "0.5rem",
                    "margin-bottom": "-0.2rem",
                  },
                  attrs: {
                    alt: "",
                    src: require("../../icons/zhanghao@3x.png"),
                  },
                }),
                _vm._v(" "),
                _c("el-input", {
                  ref: "managerPhoneNumber",
                  attrs: {
                    "auto-complete": "on",
                    name: "managerPhoneNumber",
                    placeholder: "请输入手机号",
                    maxlength: "11",
                    tabindex: "1",
                    type: "text",
                  },
                  model: {
                    value: _vm.loginForm.managerPhoneNumber,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "managerPhoneNumber", $$v)
                    },
                    expression: "loginForm.managerPhoneNumber",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-form-item",
              { attrs: { prop: "password" } },
              [
                _c("img", {
                  staticStyle: {
                    width: "1.2rem",
                    "margin-left": "0.5rem",
                    "margin-bottom": "-0.2rem",
                  },
                  attrs: { alt: "", src: require("../../icons/mima@3x.png") },
                }),
                _vm._v(" "),
                _c("el-input", {
                  key: _vm.passwordType,
                  ref: "password",
                  attrs: {
                    type: _vm.passwordType,
                    "auto-complete": "on",
                    name: "password",
                    placeholder: "请输入密码",
                    tabindex: "2",
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { width: "100%", "margin-bottom": "0.6rem" },
                attrs: { loading: _vm.loading, round: "", type: "primary" },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleLogin($event)
                  },
                },
              },
              [_vm._v("登录 ")]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { display: "flex", "justify-content": "center" } },
              [
                _c(
                  "span",
                  { staticStyle: { "font-size": "0.8rem", color: "#FFFFFF" } },
                  [_vm._v("竭诚为患者服务，为医药、卫生事业服务")]
                ),
                _vm._v(" "),
                _c("div"),
                _vm._v(" "),
                _c("div"),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.limit
      ? _c("div", { staticClass: "limit-bg" }, [
          _c("div", { staticClass: "tips-box" }, [
            _c("div", { staticClass: "title" }, [_vm._v("网站更新提示")]),
            _vm._v(" "),
            _c("div", { staticClass: "tips-text" }, [
              _vm._v("638管理中台网址已更新，请保存并使用下方链接进行登录！"),
            ]),
            _vm._v(" "),
            _c("a", { staticClass: "url", attrs: { href: _vm.toUrl } }, [
              _vm._v(_vm._s(_vm.toUrl)),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "copy",
                on: {
                  click: function ($event) {
                    return _vm.toClip(_vm.toUrl)
                  },
                },
              },
              [_vm._v("点击跳转")]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }