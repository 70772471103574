<template>
    <div class="main-box">
        <div class="head-search-row">
            <el-form :inline="true" class="demo-form-inline" size="medium">
                <el-form-item label="">
                    <div class="flex-box">
                        <el-input v-model="inquire.searchValue" placeholder="请输入名称" class="width-180"></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="">
                    <div class="flex-box">
                        <el-select v-model="inquire.status" placeholder="请选择状态" class="width-140">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="正常" value="1"></el-option>
                            <el-option label="锁定" value="2"></el-option>
                        </el-select>
                    </div>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" size="small" @click="searchList">查询</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div style="margin: 20px 0 0 20px">
            <el-button size="small" type="primary" @click="addition">新增内容</el-button>
        </div>

        <div class="table">
            <el-main>
                <template>
                    <el-table :data="tableData" v-loading="loading" border height="400px" style="width: 100%">
                        <el-table-column prop="date" label="序号" width="60">
                            <template v-slot="scope">{{
                                (pageParam.pageNum - 1) * pageParam.pageSize + (scope.$index + 1)
                            }}</template>
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="名称"
                            min-width="200"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column
                            prop="updateTime"
                            label="添加时间"
                            min-width="160"
                            show-overflow-tooltip
                        ></el-table-column>
                        <el-table-column prop="sort" label="排序" min-width="100"></el-table-column>
                        <el-table-column prop="status" label="状态" min-width="100">
                            <template slot-scope="scope">
                                {{ scope.row.status == 1 ? "正常" : "锁定" }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="" label="操作" min-width="140" fixed="right">
                            <template slot-scope="scope">
                                <span class="operation-btn" @click="handleEdit(scope.row)">编辑</span>
                                <span class="operation-btn_partition"> / </span>
                                <span class="operation-btn" @click="handleHide(scope.row, scope.row.status)">{{ scope.row.status == 1 ? '锁定' : '解锁' }}</span>
                                <span class="operation-btn_partition"> / </span>
                                <span class="operation-btn" @click="delData(scope.row)">删除</span>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        background
                        :current-page="pageParam.pageNum"
                        :page-sizes="$api.pageSizes"
                        :page-size="pageParam.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageParam.total"
                    ></el-pagination>
                </div>
            </el-main>
        </div>

        <!-- 添加弹框 -->
        <el-dialog
            :title="dialogTitle + '内容'"
            :visible.sync="dialogVisible"
            :before-close="closeDialog"
            width="70%"
            top="5vh"
        >
            <div class="Popout_content">
                <el-form label-width="110px" ref="submitForm" :model="submitForm" :rules="submitRules">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="submitForm.name" maxlength="30" placeholder="名称"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="封面图片" prop="coverImg">
                        <d-upload
                            upload-type="picture-card"
                            :file-list="primaryImagesList"
                            accept=".gif,.jpeg,.jpg,.png"
                            @uploadSuccess="uploadSuccess"
                            @removeFile="removeFile"
                        >
                            <div class="tip-box">
                                <i class="el-icon-plus" />
                                <span slot="tip" class="tip">建议360px*360px</span>
                            </div>
                        </d-upload>
                    </el-form-item> -->
                    <el-form-item label="备注" prop="mark">
                        <el-input type="textarea" v-model="submitForm.mark" placeholder="备注"></el-input>
                    </el-form-item>
                    <el-form-item label="排序" prop="sort">
                        <el-input v-model="submitForm.sort" placeholder="排序"></el-input>
                    </el-form-item>
                    <el-form-item label="关联分类" prop="productCats">
                        <el-button size="small" type="primary" @click="addMerchant">添加</el-button>
                        <el-table :data="submitForm.productCats" border style="width: 100%">
                            <el-table-column label="序号" prop="date" width="60">
                                <template slot-scope="scope">{{
                                    (pageParam_6.pageNum - 1) * pageParam_6.pageSize + (scope.$index + 1)
                                }}</template>
                            </el-table-column>
                            <el-table-column prop="categoryName" label="类别名称" width="200"></el-table-column>
                            <el-table-column prop="parentCategoryName" label="所属父类" width="200"></el-table-column>
                            <el-table-column prop="categoryComment" label="备注" width="200"></el-table-column>
                            <el-table-column prop="" label="操作" min-width="140" fixed="right">
                                <template slot-scope="scope">
                                    <span class="operation-btn" @click="delRow(scope.row, scope.$index)">删除</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="submitAdd">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 添加类别 -->
        <el-dialog title="关联产品分类" :visible.sync="dialogVisible_6" top="5vh" width="70%">
            <div class="Popout_content">
                <el-form :inline="true" class="demo-form-inline" size="medium">
                    <el-form-item>
                        <el-input v-model="merchantSelectValue" placeholder="" class="width-180" clearable></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button size="small" type="primary" @click="merchantSearchList">查询</el-button>
                    </el-form-item>
                </el-form>

                <el-table
                    v-loading="loading_5"
                    :data="noBusinessTableList"
                    border
                    @selection-change="currentChange"
                    height="400px"
                    style="width: 100%"
                >
                    <el-table-column type="selection" width="55" :selectable="selectable" />
                    <el-table-column label="序号" prop="date" width="60">
                        <template slot-scope="scope">{{
                            (pageParam_6.pageNum - 1) * pageParam_6.pageSize + (scope.$index + 1)
                        }}</template>
                    </el-table-column>
                    <el-table-column prop="categoryName" label="类别名称" min-width="200" show-overflow-tooltip />
                    <el-table-column prop="parentCategoryName" label="所属父类" min-width="200" />
                    <el-table-column prop="categoryComment" label="备注" min-width="120" />
                </el-table>

                <div class="pagination">
                    <el-pagination
                        :current-page="pageParam_6.pageNum"
                        :page-size="pageParam_6.pageSize"
                        :page-sizes="$api.pageSizes"
                        :total="pageParam_6.total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange_6"
                        @current-change="handleCurrentChange_6"
                    ></el-pagination>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="(dialogVisible_6 = false), (merchantSelectValue = '')">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import dUpload from "@/components/d-upload";
import axios from "axios";
function getSignature() {
    return axios
        .post("/common/file/upload/getQCloudSignature", JSON.stringify({ action: "GetUgcUploadSign" }))
        .then(function(response) {
            return response.data.data;
        });
}
export default {
    components: {
        dUpload
    },
    data() {
        return {
            actionUrl: "/common/file/upload/video",
            // 类别
            categoryTree: [],
            props: {
                label: "categoryName",
                value: "categoryId",
                children: "childes",
                checkStrictly: true,
                emitPath: false
            },
            props_add: {
                label: "categoryName",
                value: "categoryId",
                children: "childes",
                emitPath: false
            },
            // 查询参数
            inquire: {
                searchValue: "", // 搜索值
                status: "" // 类别
            },
            pageParam: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            loading: false,
            // 表格数据
            tableData: [],

            // 添加区域地址
            dialogTitle: "添加", // 添加弹窗标题
            dialogVisible: false, // 添加弹窗
            submitForm: {
                productCats: [],
                coverImg: "",
                createTime: "",
                name: "",
                updateTime: "",
                sort: "",
                mark: "",
                status: "",
                id: "",
                productCatIds: []
            },
            submitRules: {
                // coverImg: [{ required: true, message: "请上传封面图片", trigger: "blur" }],
                name: [{ required: true, message: "请输入名称", trigger: "blur" }],
                productCats: [{ required: true, message: "请选择类别", trigger: "blur" }]
            },
            isAdd: true, // 是否为添加
            editRow: {},

            editor: "",
            productTable: [],
            primaryImagesList: [],

            // 添加商家
            loading_5: false,
            merchantSelectKey: "categoryName",
            merchantSelectValue: "",
            dialogVisible_6: false,
            pageParam_6: {
                pageNum: 1,
                pageSize: 10,
                total: 0
            },
            noBusinessTableList: [],
            selectList: [],
            shopsList: [], // 所有已绑定商家
            // 商家档案
            shopsDetailsDialog: false
        };
    },
    created() {
        // 获取数据列表
        this.getTableList();
        this.getCategoryTree();
        this.tcVod = new this.$TcVod({
            getSignature: getSignature
        });
    },
    methods: {
        // 页容量改变
        handleSizeChange(size) {
            this.pageParam.pageNum = 1;
            this.pageParam.pageSize = size;
            // 刷新(数据)
            this.getTableList();
        },
        // 页码改变
        handleCurrentChange(page) {
            this.pageParam.pageNum = page;
            // 刷新(页码)
            this.getTableList();
        },
        // 刷新
        searchList() {
            this.pageParam.pageNum = 1;
            this.getTableList();
        },
        // 获取分类列表
        getCategoryTree() {
            this.$http.requestGet({ url: "/operation/message/category/tree", loading: false }).then(res => {
                this.categoryTree = res.data;
            });
        },
        // 获取数据列表
        getTableList() {
            let data = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                param: this.inquire
            };

            this.loading = true;
            this.$http
                .requestPost({
                    url: "/articleType/getList",
                    param: data,
                    loading: false
                })
                .then(res => {
                    this.loading = false;
                    this.tableData = res.data.list;
                    this.pageParam.total = res.data.total;
                })
                .catch(err => {
                    this.loading = false;
                    console.log(err);
                });
        },

        // 添加
        addition() {
            // 清空视频上传状态盒子
            this.submitForm = {
                name: '',
                sort: '',
                coverImg: '',
                mark: '',
                productCats: [],
                productCatIds: []
            };
            this.primaryImagesList = []
            this.dialogTitle = "添加";
            this.dialogVisible = true;
            this.isAdd = true;
        },
        // 修改
        handleEdit(row) {
            this.dialogTitle = "编辑";
            this.isAdd = false;
            this.primaryImagesList = [];
            this.$http.requestGet({ url: "/articleType/get/" + row.id }).then(res => {
                let data = res.data;
                this.submitForm = {
                    productCats: data.productCats,
                    coverImg: '',
                    createTime: data.createTime,
                    name: data.name,
                    updateTime: data.updateTime,
                    sort: data.sort,
                    mark: data.mark,
                    status: data.status,
                    id: data.id
                };
                if (data.coverImg && data.coverImg.length > 0) {
                    let videosObj = {
                        url: data.coverImg,
                        name: data.coverImg.substring(data.coverImg.lastIndexOf("/") + 1)
                    };
                    this.primaryImagesList.push(videosObj);
                    console.log(this.primaryImagesList);
                }
            });

            this.dialogVisible = true;
            this.editRow = row;
        },
        // 提交添加
        submitAdd() {
            // 详情顶部视频
            if (this.primaryImagesList.length > 0) {
                const videos = this.primaryImagesList[0];
                console.log(videos);
                if (!videos.response) {
                    this.submitForm.coverImg = videos.url;
                } else {
                    this.submitForm.coverImg = videos.response.data;
                }
            }
            this.$refs.submitForm.validate(valid => {
                if (valid) {
                    let productCatIds = [];
                    // 类别id
                    if (this.submitForm.productCats.length >= 1) {
                        this.submitForm.productCats.forEach(item => {
                            productCatIds.push(item.categoryId);
                        });
                    }
                    let param = {
                        coverImg: this.submitForm.coverImg,
                        mark: this.submitForm.mark,
                        status: this.submitForm.status,
                        sort: this.submitForm.sort,
                        name: this.submitForm.name,
                        productCatIds: productCatIds
                    };
                    
                    if (!this.isAdd) {
                        param.id = this.editRow.id;
                    }
                    this.$http
                        .requestPost({
                            url: "/articleType/save",
                            param: param
                        })
                        .then(res => {
                            this.getTableList();
                            this.$message({
                                message: "操作成功",
                                type: "success"
                            });
                            this.closeDialog();
                        });
                } else {
                    return false;
                }
            });
        },

        // 关闭弹窗
        closeDialog() {
            this.$refs.submitForm && this.$refs.submitForm.resetFields();
            this.dialogVisible = false;
            this.submitForm = {
                name: '',
                sort: '',
                coverImg: '',
                mark: '',
                productCats: [],
                productCatIds: []
            };
            this.primaryImagesList = []
        },

        // 删除
        delData(row) {
            this.$confirm("确定删除该内容?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    if (this.tableData.length === 1 && this.pageParam.pageNum > 1) {
                        this.pageParam.pageNum--;
                    }
                    this.$http
                        .requestDel({
                            url: "/articleType/del/" + row.id
                        })
                        .then(res => {
                            this.getTableList();
                            this.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        });
                })
                .catch(err => {});
        },
         // 是否隐藏
         handleHide(row, type) {
            let id = row.id;
            console.log(typeof row.id)
            let name = type === "1" ? "锁定" : "解锁";
            let status = type === "1" ? "2" : "1";

            this.$confirm("确定" + name + "该文章?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    this.$http
                        .requestPut({ 
                            url: `/articleType/modifyArticleTypeStatus/${id}/${status}`
                        })
                        .then(res => {
                            this.getTableList();
                            this.$message({
                                type: "success",
                                message: "操作成功!"
                            });
                        });
                })
                .catch(err => {});
        },
        // 上传封面图
        uploadSuccess(res, file, fileList) {
            console.log(fileList);
            this.primaryImagesList = fileList;
        },
        removeFile(file, fileList) {
            this.primaryImagesList = fileList;
        },

        // 添加分类
        async addMerchant() {
            if(this.isAdd) {
                this.submitForm.productCatIds = []
            }
            this.dialogVisible_6 = true;
            this.merchantSearchList();
        },
        merchantSearchList() {
            this.pageParam_6.pageNum = 1;
            this.getNoMerchantTableList();
        },
        // 页容量改变
        handleSizeChange_6(size) {
            this.pageParam_6.pageNum = 1;
            this.pageParam_6.pageSize = size;
            this.getNoMerchantTableList();
        },
        // 页码改变
        handleCurrentChange_6(page) {
            this.pageParam_6.pageNum = page;
            this.getNoMerchantTableList();
        },
        // 获取未绑定是商家列表
        getNoMerchantTableList() {
            const data = {
                pageNum: this.pageParam_6.pageNum,
                pageSize: this.pageParam_6.pageSize,
                param: {
                    categoryName: this.submitForm.categoryName
                }
            };
            if (this.merchantSelectValue) data.param[this.merchantSelectKey] = this.merchantSelectValue;

            this.loading_5 = true;
            this.$http
                .requestPost({
                    url: "/articleType/getAllUnbindProductCatList",
                    param: data,
                    loading: false
                })
                .then(res => {
                    let noList = res.data.list;
                    console.log("res", res);
                    let ids = [], // 已选择商家产品列表
                        list = JSON.parse(JSON.stringify(this.submitForm.productCats)); // 已绑定商家列表

                    // 获取已选商家所有产品id数组
                    for (let i = 0, len = list.length; i < len; i++) {
                        console.log(list[i]);
                        ids.push(list[i].categoryId);
                    }

                    // 遍历未绑定商家产品列表
                    noList.map(item => {
                        item.select = true;
                        if (ids.indexOf(item.categoryId) !== -1) {
                            item.select = false;
                            return false;
                        }
                    });
                    this.noBusinessTableList = noList;
                    this.pageParam_6.total = res.data.total;
                    this.loading_5 = false;
                })
                .catch(err => (this.loading_5 = false));
        },
        // table多选
        currentChange(val) {
            console.log(val);
            let ids = [], // 已选择商家产品列表
                merchantIds = [],
                list = JSON.parse(JSON.stringify(this.submitForm.productCats)); // 已绑定商家列表

            // // 获取已选商家所有产品id数组
            for (let i = 0, len = list.length; i < len; i++) {
                ids.push(...ids, list[i]);
            }
            // // 获取当前选择的商家产品id
            for (let i = 0, len = val.length; i < len; i++) {
                ids.push(val[i]);
                merchantIds.push(val[i].categoryId);
            }
            this.selectList = ids;
        },
        // 确定选择
        confirm() {
            if (this.selectList.length < 1) {
                return this.$message.warning("请选择商家");
            }
            let ids = [];
            this.selectList.forEach(v => {
                ids.push(v.categoryId);
            });
            this.submitForm.productCats = this.selectList;
            this.dialogVisible_6 = false;
        },
        delRow(row) {
            console.log(row)
            let _this = this;
            _this.$confirm("确定删除该分类?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            }).then(() => {
                _this.submitForm.productCats.forEach((item,index) => {
                    if(row.categoryId == item.categoryId){
                        _this.submitForm.productCats.splice(index, 1)
                    }
                })
            })
        },
        // 未绑定商家是否可选处理
        selectable(row, index) {
            return row.select;
        }
    }
};
</script>

<style lang="scss" scoped>
.editor {
    max-width: 1000px;
    max-height: 420px;
    .v-note-wrapper.shadow {
        height: 420px;
    }
}
</style>

