var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box" },
    [
      _c(
        "el-form",
        {
          ref: "submitForm",
          staticClass: "formData",
          attrs: {
            model: _vm.formData,
            size: "small",
            "label-width": "160px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "是否调用营养学社文章" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.editDisabled },
                  model: {
                    value: _vm.articleRadio,
                    callback: function ($$v) {
                      _vm.articleRadio = $$v
                    },
                    expression: "articleRadio",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("否")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("是")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.articleRadio == 2,
                      expression: "articleRadio==2",
                    },
                  ],
                  staticStyle: { "margin-left": "20px" },
                  attrs: {
                    disabled: _vm.editDisabled,
                    type: "primary",
                    size: "mini",
                  },
                  on: { click: _vm.openArticleDialog },
                },
                [_vm._v("选择文章")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "articleTitle" } },
            [
              _c("el-input", {
                staticClass: "input_rPadding",
                staticStyle: { width: "350px" },
                attrs: {
                  "show-word-limit": "",
                  clearable: "",
                  maxlength: "50",
                  disabled: _vm.editDisabled,
                  placeholder: "请输入标题",
                },
                model: {
                  value: _vm.formData.articleTitle,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "articleTitle", $$v)
                  },
                  expression: "formData.articleTitle",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "类别", prop: "articleCategoryId" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "350px" },
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.formData.articleCategoryId,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "articleCategoryId", $$v)
                    },
                    expression: "formData.articleCategoryId",
                  },
                },
                _vm._l(_vm.queryCategoryList, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.categoryName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "作者", prop: "articleAuthor" } },
            [
              _c("el-input", {
                staticClass: "input_rPadding",
                staticStyle: { width: "350px" },
                attrs: {
                  "show-word-limit": "",
                  clearable: "",
                  maxlength: "50",
                  disabled: _vm.editDisabled,
                  placeholder: "请输入作者",
                },
                model: {
                  value: _vm.formData.articleAuthor,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "articleAuthor", $$v)
                  },
                  expression: "formData.articleAuthor",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "认证信息", prop: "organization" } },
            [
              _c("el-input", {
                staticClass: "input_rPadding",
                staticStyle: { width: "350px" },
                attrs: {
                  "show-word-limit": "",
                  clearable: "",
                  maxlength: "50",
                  disabled: _vm.editDisabled,
                  placeholder: "请输入认证信息",
                },
                model: {
                  value: _vm.formData.organization,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "organization", $$v)
                  },
                  expression: "formData.organization",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "封面图片", prop: "coverUrl" } },
            [
              _c(
                "d-upload",
                {
                  attrs: {
                    "upload-type": "picture-card",
                    "file-list": _vm.coverList,
                    limit: 1,
                    accept: ".gif,.jpeg,.jpg,.png",
                    disabled: _vm.editDisabled,
                  },
                  on: {
                    uploadSuccess: function ($event) {
                      return _vm.uploadSuccess(arguments, "coverImg")
                    },
                    removeFile: function ($event) {
                      return _vm.removeFile(arguments, "coverImg")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "tip-box" }, [
                    _c("i", { staticClass: "el-icon-plus" }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "tip",
                        attrs: { slot: "tip" },
                        slot: "tip",
                      },
                      [_vm._v("上传图片")]
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "内容", prop: "articleContent" } },
            [
              !_vm.editDisabled
                ? _c(
                    "div",
                    { staticClass: "editor" },
                    [
                      _c("mavon-editor", {
                        ref: "mavon_editor",
                        attrs: { toolbars: _vm.toolbars, autofocus: false },
                        on: {
                          imgAdd: _vm.handleEditorImgAdd,
                          imgDel: _vm.handleEditorImgDel,
                          change: _vm.editorChange,
                        },
                        model: {
                          value: _vm.formData.articleContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "articleContent", $$v)
                          },
                          expression: "formData.articleContent",
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "editor-readonly" }, [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.formData.articleContent.replace(/\n/gm, "<br>")
                        ),
                      },
                    }),
                  ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "是否添加提示", prop: "promptRadio" } },
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                  model: {
                    value: _vm.promptRadio,
                    callback: function ($$v) {
                      _vm.promptRadio = $$v
                    },
                    expression: "promptRadio",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("否")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("是")]),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.promptRadio == 2,
                    expression: "promptRadio==2",
                  },
                ],
                staticClass: "input_rPadding",
                staticStyle: { width: "350px", "margin-left": "20px" },
                attrs: {
                  "show-word-limit": "",
                  clearable: "",
                  maxlength: "50",
                  placeholder: "请输入提示语",
                },
                model: {
                  value: _vm.formData.articlePrompt,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "articlePrompt", $$v)
                  },
                  expression: "formData.articlePrompt",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            {
              staticStyle: { height: "70px", "margin-top": "30px" },
              attrs: { label: "顶部视频" },
            },
            [
              _c(
                "d-upload",
                {
                  attrs: {
                    size: 500,
                    autoUpload: false,
                    videoHeight: "400px",
                    "file-list": _vm.topVideoList,
                    action: _vm.actionUrl,
                    accept:
                      ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                  },
                  on: {
                    uploadSuccess: function ($event) {
                      return _vm.uploadSuccess(arguments, "topVideo")
                    },
                    handleChange: function ($event) {
                      return _vm.fileChange(arguments, "topVideo")
                    },
                    removeFile: function ($event) {
                      return _vm.removeFile(arguments, "topVideo")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tip-box" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: _vm.editDisabled,
                          },
                        },
                        [_vm._v("上传视频")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.progressFlag1
                ? _c("el-progress", { attrs: { percentage: _vm.percentage1 } })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { staticStyle: { height: "70px" }, attrs: { label: "底部视频" } },
            [
              _c(
                "d-upload",
                {
                  attrs: {
                    size: 500,
                    autoUpload: false,
                    videoHeight: "400px",
                    "file-list": _vm.bottomVideoList,
                    action: _vm.actionUrl,
                    accept:
                      ".wmv,.asf,.asx,.rm,.rmvb,.rmvb,.mpg,.mpeg,.mpe,.3gp,.mov,.mp4,.m4v",
                  },
                  on: {
                    uploadSuccess: function ($event) {
                      return _vm.uploadSuccess(arguments, "bottomVideo")
                    },
                    handleChange: function ($event) {
                      return _vm.fileChange(arguments, "bottomVideo")
                    },
                    removeFile: function ($event) {
                      return _vm.removeFile(arguments, "bottomVideo")
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "tip-box" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: _vm.editDisabled,
                          },
                        },
                        [_vm._v("上传视频")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.progressFlag2
                ? _c("el-progress", { attrs: { percentage: _vm.percentage2 } })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "btns" }, [
        _c("div", { staticClass: "confirm", on: { click: _vm.submitForm } }, [
          _vm._v("完成"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cancel", on: { click: _vm.cancelSubmit } }, [
          _vm._v("取消"),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择文章",
            visible: _vm.articleDialogVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.articleDialogVisible = $event
            },
            close: function ($event) {
              _vm.articleDialogVisible = false
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-form",
                {
                  staticStyle: { "margin-top": "20px" },
                  attrs: {
                    model: _vm.articleQueryData,
                    size: "small",
                    inline: true,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入文章标题", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchArticle($event)
                          },
                        },
                        model: {
                          value: _vm.articleQueryData.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.articleQueryData, "title", $$v)
                          },
                          expression: "articleQueryData.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "作者" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入作者名称", clearable: "" },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchArticle($event)
                          },
                        },
                        model: {
                          value: _vm.articleQueryData.author,
                          callback: function ($$v) {
                            _vm.$set(_vm.articleQueryData, "author", $$v)
                          },
                          expression: "articleQueryData.author",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.searchArticle },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  ref: "tablelist",
                  staticStyle: { "margin-top": "30px" },
                  attrs: {
                    height: "400",
                    border: "",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "#409eff",
                      color: "#ffffff",
                    },
                    data: _vm.articleList,
                  },
                  on: { "current-change": _vm.articleCurrentChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      label: "序号",
                      width: "60",
                      align: "center",
                      type: "index",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "标题", align: "center", prop: "title" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "作者", align: "center", prop: "author" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.articleQueryData.pageNum,
                      "page-sizes": _vm.$api.pageSizes,
                      "page-size": _vm.articleQueryData.pageSize,
                      total: _vm.total,
                      layout: " total, sizes, prev, pager, next, jumper",
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handlePageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmArticle },
                },
                [_vm._v("确 定")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.articleDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            "destroy-on-close": "",
            visible: _vm.videoDialogVisible,
            width: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.videoDialogVisible = $event
            },
            close: _vm.closeVideoDialog,
          },
        },
        [
          _vm.videoDialogVisible
            ? _c(
                "video",
                {
                  ref: "video",
                  staticClass: "video",
                  attrs: { controls: "", preload: "auto", playsinline: "true" },
                },
                [
                  _c("source", {
                    attrs: { src: _vm.vedioUrl, type: "video/mp4" },
                  }),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }