<template>
  <div class="pie">
    <div class="title">
      <div class="txt_box">
        <div class="tip">
          <img src="../../assets/image/wenhao.png" alt="">
          <div class="poup">
            <div class="txt">
              {{ tip }}
            </div>
          </div>
        </div>
        <div class="icon">
          <img src="@/assets/image/top.png" alt="">
          <!-- <div class="border"></div> -->
        </div>
        <div class="text">{{ title }}</div>
      </div>
    </div>
    <div v-if="managerType == 'all'" class="tab">
      <div v-for="(item,index) in list" :key="item.value" class="item" :class="current == index ? 'active' : ''" @click="getSelect(index)">{{ item.name }}</div>
    </div>
    <div id="pie" />
  </div>
</template>
<script>
// 引入echarts
import * as echarts from 'echarts'
export default {
  props: {
    managerType: {
      type: String,
      default: 'all'
    },
    value: {
      type: Number,
      default: 0
    }
  },
   data() {
      return {
        list: [
          { name: '销量', value: 1 },
          { name: '浏览量', value: 2 }
        ],
        title: '产品销量占比',
        tip: '分析所选时间段内的产品销量情况'
      }
   },
   computed: {
    current: {
      get() {
        return this.value
      },
      set(val) {
        console.log('value', val)
        this.$emit('input', val)
      }
    }
   },
   created() {
   },
   methods: {
    getSelect(index) {
      this.$emit('selectRadio', index)
    },
      // 获取产品销量占比饼图
  getPieChart(title, dataList, titleList) {
    // console.log("00009",dataList,titleList);
    this.title = title
    // 根据标题切换tip提示
    if (title == '产品销量占比') {
      this.tip = '分析所选时间段内的产品销量情况'
    } else {
      this.tip = '分析所选时间段内的产品浏览情况'
    }
    var chartDom = document.getElementById('pie')
    var myChart = echarts.init(chartDom)
    window.addEventListener('resize', function() {
          myChart.resize()
      })
    var option
    const color = '#fff'
option = {
  // title: {
  //   text: title,
  //   left:'2%',
  //   top:'5%',
  //   textStyle:{
  //     fontSize:16,//字体大小
  //     fontWeight: '600',
  //     color:color,
  //   },
  // },
  tooltip: {
    trigger: 'item',
    formatter: '{a} <br/>{b} : {c} ({d}%)',
    confine: true // 限制提示不超出饼图区域
  },
  grid: {
    // right: '20',
    top: '0',
    bottom: '20'
  },
  legend: {
    // orient: 'vertical',
    top: '4',
    right: '10',
    left: 'center',
    itemHeight: 6, // 图例图标的高度
    itemWidth: 6, // 图例图标的宽度
    itemGap: 20, // 图例之间的间距
    textStyle: {
      fontSize: 12, // 文字大小
      padding: [0, 5, -2, 0], // 文字与图形之间的左右间距
      color: '#4C89CD'
    },
    formatter: function(name) {
				return (name.length > 6 ? (name.slice(0, 6) + '...') : name)
			},
    // data:['舒复派(可可味)AAAA','舒复派(原味)','舒复派(红茶味)','儿童牙膏','儿童牙膏2','舒食素']
    data: dataList
  },
  color: [{
              type: 'linear',
              colorStops: [
                { offset: 0, color: '#095665' },
                { offset: 1, color: '#05E0E9' }
              ]
            }, {
              type: 'linear',
              colorStops: [
                { offset: 0, color: '#3381C5' },
                { offset: 1, color: '#8CDBFE' }
              ]
            }, {
              type: 'linear',
              colorStops: [
                { offset: 0, color: '#09628F' },
                { offset: 1, color: '#0EB9F0' }
              ]
            }, {
              type: 'linear',
              colorStops: [
                { offset: 0, color: '#165B36' },
                { offset: 1, color: '#62E698' }
              ]
            }, {
              type: 'linear',
              colorStops: [
                { offset: 0, color: '#61303C' },
                { offset: 1, color: '#FB575F' }
              ]
            }, {
              type: 'linear',
              colorStops: [
                { offset: 0, color: '#624E1B' },
                { offset: 1, color: '#DBAD49' }
              ]
            }],
   // 设置文字的颜色
   label: {
       color: '#fff',
       fontSize: 10
      },
  series: [
    {
            color: ['#000730'], // 内圆颜色
            type: 'pie',
            radius: ['0%', '30%'],
            center: ['50%', '48%'], // 设置圆的位置，两个圆的位置必须一致
            top: -5,
            hoverAnimation: false,
            silent: true,
            data: [
                {
                  value: '8', // 必须有值，否则内圈不显示
                  name: 'inner',
                  label: {
                    show: true,
                    position: 'center',
                    formatter: () => {
                        return `{bg|}`
                    },
                    rich: {
                        bg: {
                            left: 14,
                            width: 93, // 内圈尺寸不能过大，会导致外圈数据指引线异常
                            height: 93,
                            backgroundColor: {
                                image: 'https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/10/74fc1c78-980e-4eef-8306-b970f2f2e82a.png'
                            }
                        }
                    }
                  }
              }
            ],
            emphasis: {
                scale: false // 鼠标悬停到内圆时，取消放大效果
            }
        },
    {
      name: title,
      type: 'pie',
      radius: ['36%', '46%'],
      center: ['50%', '48%'],
      top: -5,
      emphasis: {
        disabled: true,
        focus: 'none'
      },
      label: {
        normal: {
            formatter: '{c|{c}} \n {d|{d}%}',
            // lineHeight: 15,
            rich: {
              c: {
                color: '#CAE6FF',
                fontSize: 21,
                fontWeight: 700,
                lineHeight: 20
              },
              d: {
                color: '#B0F9FD',
                fontSize: 14,
                fontWeight: 400
              }
            }
            }
        },
      // avoidLabelOverlap: false,
      labelLine: {
        minTurnAngle: 0,
        maxSurfaceAngle: 40,
        normal: {
          length: 20,
          length2: 80// 设置第二段延长线的长度
        }
    },
    labelLayout: {
        draggable: true
    },
    data: titleList
      // data:
      // [
      //   { value: 1048, name: '舒复派(可可味)AAAA',
      //   },

      //   { value: 735, name: '舒复派(原味)',
      //   },
      //   { value: 580, name: '舒复派(红茶味)',
      //   },
      //   { value: 484, name: '儿童牙膏',
      //   },
      //     { value: 555, name: '儿童牙膏2',
      //   },
      //   { value: 300, name: '舒食素',
      //   }
      // ],
    }
  ]
}

option && myChart.setOption(option)
  }
   }
}
</script>
<style lang="scss" scoped>
.pie{
    width: 615px;
    height: 375px;
    position: relative;
    padding: 20px;
    // padding-left: 28px;
    background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/7a64904d-cb10-4d63-b2d7-9b418cedfc14.png") no-repeat center 0px;
    background-size: cover;
    .title {
      display: flex;
      // justify-content: flex-end;
      padding: 7px 0 21px;
      padding-left: 8px;
      .txt_box{
        display: flex;
        align-content: center;
        align-items: center;
        align-self: center;
        &:hover{
          .tip{
            .poup{
              display: flex;
            }
          }
        }
      }
      .text {
        background-image: linear-gradient(0deg, #56FEFF, #E2F3FB);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
        font-size: 16px;
      }
      .icon {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .tab{
      z-index: 9999;
      position: absolute;
      // right: 10px;
      left: 251px;
      top: 24px;
      // width: 145px;
      height: 30px;
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      .item{
          cursor: pointer;
          position: relative;
          width: 60px;
          height: 30px;
          background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/2045beaa-f172-44b8-9db3-13b1464306a1.png") no-repeat center 0px;
          background-size: cover;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #4C89CD;
          font-size: 14px;
          font-weight: 400;
          margin-right: 2px;
      }
      .active{
          // color: #21c7e8;
          color: rgba(202, 230, 255, 0.8);
          font-weight: 700;
          background: url("https://chinaja.oss-cn-shenzhen.aliyuncs.com/2023/10/9/eade4982-0046-4b8a-8f88-06bfa5ce9852.png") no-repeat center 0px;
          background-size: cover;
      }
    }
    #pie{
        width: 100%;
        height: 100%;
    }
}
.tip{
  width: 28px;
  height: 28px;
  position: relative;
  margin-right: 10px;
  img{
    width: 100%;
    height: 100%;
  }
}
.poup{
  background: url('../../assets/image/tip.png') no-repeat center center;
  padding: 20px;
  text-align: left;
  left: 10px;
  z-index: 100;
  position: absolute;
  width: 262px;
  // height: 71px;
  color: #c0e2ff;
  font-weight: 400;
  font-size: 12px;
  font-face: Microsoft YaHei;
  line-height: 20px;
  display: none;
}
</style>
